import React, { useEffect, useContext, useState } from 'react';
import PropTypes from 'prop-types';

import { SubmissionSummaryContext } from './context/SubmissionSummaryProvider';

import SuccessPage from './components/SuccessPage';

const SubmissionSummary = ({
  ReviewComponent,
  FooterComponent,
  needSolveMatch,
  needFields
}) => {
  const [loading, setLoading] = useState(true);
  const {
    loadSubmission,
    resolveMatches,
    getSubmissionFields,
    state: { submissionDone }
  } = useContext(SubmissionSummaryContext);

  useEffect(() => {
    async function callLoadSubmission() {
      await loadSubmission();
      setLoading(false);
    }

    async function callResolveMatches() {
      await resolveMatches();
    }

    async function callFields() {
      await getSubmissionFields();
    }

    if (!submissionDone) {
      if (needSolveMatch) {
        callResolveMatches();
      }
      if (needFields) {
        callFields();
      }
      callLoadSubmission();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      {submissionDone ? (
        <SuccessPage />
      ) : (
        ReviewComponent && (
          <ReviewComponent
            FooterComponent={FooterComponent}
            loading={loading}
          />
        )
      )}
    </>
  );
};

SubmissionSummary.defaultProps = {
  FooterComponent: null,
  needSolveMatch: true,
  needFields: false
};

SubmissionSummary.propTypes = {
  FooterComponent: PropTypes.oneOfType([
    PropTypes.elementType, // For React components
    PropTypes.func // For functions that return JSX
  ]),
  ReviewComponent: PropTypes.oneOfType([
    PropTypes.elementType, // For React components
    PropTypes.func // For functions that return JSX
  ]).isRequired,
  needSolveMatch: PropTypes.bool,
  needFields: PropTypes.bool
};

export default SubmissionSummary;
