import React from 'react';

const ExclamationTriangleOutline = () => (
  <svg
    width="22"
    height="20"
    viewBox="0 0 22 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    data-testid="exclamation-triangle-outline"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M0 19.5L11 0.5L22 19.5H0ZM18.5297 17.4983L10.9997 4.48828L3.46973 17.4983H18.5297ZM10 14.5V16.5H12V14.5H10ZM10 8.5H12V12.5H10V8.5Z"
      fill="#E99921"
    />
  </svg>
);

export default ExclamationTriangleOutline;
