import { isNaN, isNumber } from 'lodash';

/**
 * Converts a percentage value to its decimal equivalent.
 * @param {number} percent - The percentage value to be converted.
 * @returns {number|null} The decimal equivalent of the percentage, or null if the input is invalid.
 */
export const percentToDecimal = percent => {
  if (percent === 0) return 0;
  if (!isNumber(percent) || isNaN(percent)) return null;
  return percent / 100;
};

/**
 * Converts a decimal value to its percentage equivalent.
 * @param {number} decimal - The decimal value to be converted.
 * @returns {number|null} The percentage equivalent of the decimal, or null if the input is invalid.
 */
export const decimalToPercent = decimal => {
  if (decimal === 0) return 0;
  if (!isNumber(decimal) || isNaN(decimal)) return null;
  return decimal * 100;
};
