import React, { useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import {
  Button,
  ButtonType,
  Drawer,
  Row
} from 'syngenta-digital-cropwise-react-ui-kit';
import { Formik } from 'formik';
import { DatePicker } from '@agconnections/grow-ui';
import GrowUIFormField from 'components/Forms/GrowUIFormField';
import LoadTypeCheckbox, { loadOptions } from './components/LoadTypeCheckbox';
import FilterSection from './components/FilterSection';
import SaleLocationsFilter from './components/SaleLocationsFilter';
import StorageLocationsFilter from './components/StorageLocationsFilter';
import SelectPropertiesFilter from './components/SelectPropertiesFilter';
import './index.css';

const loadTypeMap = {
  'properties-to-storage': {
    sourceType: 'CROP_ZONE',
    destinationType: 'STORAGE'
  },
  'properties-to-sale': {
    sourceType: 'CROP_ZONE',
    destinationType: 'SALE'
  },
  'storage-to-storage': {
    sourceType: 'STORAGE',
    destinationType: 'STORAGE'
  },
  'storage-to-sale': {
    sourceType: 'STORAGE',
    destinationType: 'SALE'
  }
};

const getLoadTypeCheckboxes = appliedFilters => {
  if (!appliedFilters) return [];
  return appliedFilters
    .map(({ sourceType, destinationType }) =>
      Object.keys(loadTypeMap).find(
        key =>
          loadTypeMap[key].sourceType === sourceType &&
          loadTypeMap[key].destinationType === destinationType
      )
    )
    .filter(key => key !== undefined);
};

const LoadsFilterDrawer = ({
  visible,
  appliedFilters,
  onApplyFilters,
  onClearFilters,
  onClose
}) => {
  const [filters, setFilters] = useState(appliedFilters);

  const onSubmit = values => {
    const mappedValues = {
      ...values,
      loadtype: values?.loadtype?.length
        ? values?.loadtype.map(lt => loadTypeMap[lt])
        : undefined
    };
    onApplyFilters(mappedValues);
    onClose();
  };

  const onReset = () => {
    setFilters({});
    onClearFilters();
    onClose();
  };

  const isSaveButtonDisabled = useMemo(() => {
    return (
      Object.values(appliedFilters).every(filter => filter?.length === 0) &&
      Object.values(filters).every(filter => filter?.length === 0)
    );
  }, [appliedFilters, filters]);

  return (
    <Formik
      key={JSON.stringify(appliedFilters)}
      enableReinitialize
      initialValues={{
        ...appliedFilters,
        loadtype: getLoadTypeCheckboxes(appliedFilters?.loadtype)
      }}
      onSubmit={onSubmit}
      onReset={onReset}
      validate={setFilters}
    >
      {({ handleSubmit, resetForm, values }) => (
        <Drawer
          maskClosable
          open={visible}
          className="cwf-yield-loads-filter-drawer"
          placement="right"
          closable={false}
          onClose={onClose}
          bodyStyle={{ padding: 0 }}
          width="360px"
          footer={
            <Row justify="end">
              <Button
                type={ButtonType.default}
                className="mr-10px bg-transparent border-0"
                onClick={onClose}
              >
                Cancel
              </Button>
              <Button
                type={ButtonType.primary}
                onClick={handleSubmit}
                disabled={isSaveButtonDisabled}
              >
                Apply
              </Button>
            </Row>
          }
        >
          <div className="h-full" data-testid="loads-filter-drawer">
            <header className="flex justify-between pl-5 pb-21px pr-28px pt-26px">
              <h1 className="font-sans text-xl font-semibold leading-7 tracking-tighter">
                Filter
              </h1>
              <Button
                type={ButtonType.outline}
                size="small"
                onClick={resetForm}
                disabled={isSaveButtonDisabled}
              >
                Clear All
              </Button>
            </header>

            <FilterSection title="Date Range">
              <GrowUIFormField
                control={DatePicker}
                id="daterange"
                name="daterange"
                isRangePicker
              />
            </FilterSection>

            <FilterSection title="Load Type">
              <LoadTypeCheckbox
                value="properties-to-storage"
                from={loadOptions.Properties}
                to={loadOptions.Storage}
              />
              <LoadTypeCheckbox
                value="properties-to-sale"
                from={loadOptions.Properties}
                to={loadOptions.Sale}
              />
              <LoadTypeCheckbox
                value="storage-to-storage"
                from={loadOptions.Storage}
                to={loadOptions.Storage}
              />
              <LoadTypeCheckbox
                value="storage-to-sale"
                from={loadOptions.Storage}
                to={loadOptions.Sale}
              />
            </FilterSection>

            <FilterSection
              title="Properties"
              extraInfo={
                <div className="text-normal font-normal leading-5 tracking-tighter">
                  <span className="text-neutral-60 opacity-50 border-r border-solid pr-2">
                    {`${values?.cropZones?.length || 0} crop zone${
                      values?.cropZones?.length === 1 ? '' : 's'
                    }`}
                  </span>
                  <span className="text-neutral-60 opacity-50 pl-2">
                    {values?.totalArea?.toFixed(2) || '0'} ac
                  </span>
                </div>
              }
            >
              <SelectPropertiesFilter />
            </FilterSection>
            <FilterSection title="Storage Locations" collapsible>
              <StorageLocationsFilter filters={values} />
            </FilterSection>
            <FilterSection title="Sale Locations" collapsible>
              <SaleLocationsFilter filters={values} />
            </FilterSection>
          </div>
        </Drawer>
      )}
    </Formik>
  );
};

LoadsFilterDrawer.defaultProps = {
  appliedFilters: {}
};

LoadsFilterDrawer.propTypes = {
  visible: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onApplyFilters: PropTypes.func.isRequired,
  onClearFilters: PropTypes.func.isRequired,
  appliedFilters: PropTypes.objectOf(PropTypes.any)
};

export default LoadsFilterDrawer;
