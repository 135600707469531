import React, { createContext, useMemo, useReducer } from 'react';
import PropTypes from 'prop-types';

import { INTEGRATIONS } from 'screens/Integrations/helpers/constants';
import useIncomingInvoiceApi from 'screens/Integrations/hooks/useIncomingInvoiceApi';

import reducer, { SET_MATCHES, SET_FETCHED_MATCHES } from './reducer';

export const initialState = {
  matches: [],
  fetchedMatches: [],
  errorMessage: '',
  matchEntityType: ''
};

export const AgVendMatchContext = createContext(initialState);

const AgVendMatchProvider = ({ children, onCompleteMatch }) => {
  const [state, dispatch] = useReducer(reducer, {
    ...initialState,
    children,
    matchEntityType: ['Product', 'Company']
  });

  const {
    getMatches,
    createMatches,
    loading: isLoading
  } = useIncomingInvoiceApi();

  const updateMatches = (payload, isFetched = false) => {
    dispatch({
      type: !isFetched ? SET_MATCHES : SET_FETCHED_MATCHES,
      payload
    });
  };

  // eslint-disable-next-line consistent-return
  const submitMatches = async () => {
    const newOrUpdatedMatches = state.matches.filter(
      m =>
        state.fetchedMatches.findIndex(
          fetchedMatch =>
            m.cwfEntityId === fetchedMatch.cwfEntityId &&
            m.vendorEntityId === fetchedMatch.vendorEntityId
        ) === -1
    );
    if (newOrUpdatedMatches.length === 0) {
      return onCompleteMatch();
    }
    const response = await createMatches({
      vendor: INTEGRATIONS.agVend.toLowerCase(),
      matches: newOrUpdatedMatches
    });
    if (response?.status === 201) {
      onCompleteMatch();
    }
  };

  const fetchMatches = async () => {
    const response = await getMatches({
      vendor: INTEGRATIONS.agVend.toLocaleLowerCase(),
      matchTypes: state.matchEntityType
    });

    if (response?.status === 200) {
      updateMatches(response.data, true);
    }
  };

  const memoized = useMemo(
    () => ({
      state,
      integrationContext: INTEGRATIONS.agVend,
      dispatch,
      updateMatches,
      submitMatches,
      fetchMatches,
      isLoading
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [state, isLoading]
  );

  return (
    <AgVendMatchContext.Provider value={memoized}>
      {children}
    </AgVendMatchContext.Provider>
  );
};

AgVendMatchProvider.defaultProps = {
  children: null,
  onCompleteMatch: null
};

AgVendMatchProvider.propTypes = {
  children: PropTypes.node,
  onCompleteMatch: PropTypes.func
};

export default AgVendMatchProvider;
