import React from 'react';
import { Field, useFormikContext } from 'formik';
import {
  Select,
  Row,
  Col,
  Alert
} from 'syngenta-digital-cropwise-react-ui-kit';
import { ReactComponent as ChevronDown } from 'assets/chevron_down.svg';
import './antd-override.css';
import { WEIGHT_UNITS, YIELD_DEFAULT_ROUND } from 'screens/YieldV2/constants';
import { transformWeightUnitToOriginal } from '../../helpers/transformWeightUnit';
import getLabelValue from '../../helpers/getLabelValue';
import FormInputWithPrecisionDisplay from '../FormInputWithPrecisionDisplay';
import useFieldsUpdate from '../../hooks/useFieldsUpdate';
import WeightUnitInfoAlert from './WeightUnitInfoAlert';
import calculateNetWeight from '../../helpers/calculateNetWeight';
import calculateTareWeight from '../../helpers/calculateTareWeight';

const WeightSection = () => {
  const { values, errors } = useFormikContext();
  const { weightUnit } = values || {};

  useFieldsUpdate({
    fieldsToWatch: ['grossWeight', 'tareWeight'],
    updateFn: calculateNetWeight,
    fieldToUpdate: 'netWeight'
  });

  useFieldsUpdate({
    fieldsToWatch: ['netWeight'],
    updateFn: calculateTareWeight,
    fieldToUpdate: 'tareWeight'
  });

  return (
    <div>
      <div className="flex items-center gap-4 mb-4">
        <h1
          data-testid="outside-element"
          className="text-xl leading-7 font-semibold tracking-tight"
        >
          Weight
        </h1>
        <div className="text-info-dark-blue weight-dropdown">
          <Field name="weightUnit">
            {({ field, form }) => (
              <Select
                size="middle"
                onChange={value => {
                  form.setFieldValue(field.name, value);
                  form.validateField(field.name);
                }}
                value={field.value === 'lb' ? 'pounds' : field.value}
                bordered={false}
                suffixIcon={<ChevronDown className="dark-blue" />}
                className="text-info-dark-blue"
                optionLabelProp="label"
                dropdownMatchSelectWidth={false}
                data-testid="select-weight-unit"
                getPopupContainer={triggerNode => triggerNode.parentNode}
              >
                {WEIGHT_UNITS.map(option => (
                  <Select.Option
                    key={option.value}
                    value={option.value}
                    label={getLabelValue(field.value, option)}
                  >
                    {option.label}
                  </Select.Option>
                ))}
              </Select>
            )}
          </Field>
        </div>
      </div>
      <Row gutter={[24, 24]} prefixCls="syt-antd-row">
        <Col xs={24} sm={24} md={12} lg={12} xl={8} prefixCls="syt-antd-col">
          <div className="flex flex-col gap-0.5 weight-input">
            <label
              htmlFor="gross-weight"
              className="text-neutral-60 tracking-tighter text-sm"
            >
              Gross Weight
            </label>
            <FormInputWithPrecisionDisplay
              fieldName="grossWeight"
              inputSuffix={
                <WeightUnitInfoAlert
                  weightUnit={transformWeightUnitToOriginal(weightUnit)}
                  error={errors?.grossWeight}
                />
              }
              id="gross-weight"
              round={YIELD_DEFAULT_ROUND}
            />
          </div>
        </Col>
        <Col xs={24} sm={24} md={12} lg={12} xl={8} prefixCls="syt-antd-col">
          <div className="flex flex-col gap-0.5 weight-input">
            <label
              htmlFor="tare-weight"
              className="text-neutral-60 tracking-tighter text-sm"
            >
              Tare Weight
            </label>
            <FormInputWithPrecisionDisplay
              fieldName="tareWeight"
              inputSuffix={
                <WeightUnitInfoAlert
                  weightUnit={transformWeightUnitToOriginal(weightUnit)}
                  error={errors?.tareWeight}
                />
              }
              id="tare-weight"
              round={YIELD_DEFAULT_ROUND}
            />
          </div>
        </Col>
        <Col xs={24} sm={24} md={12} lg={12} xl={8} prefixCls="syt-antd-col">
          <div className="flex flex-col gap-0.5 weight-input">
            <label
              htmlFor="net-weight"
              className="text-neutral-60 tracking-tighter text-sm"
            >
              Net Weight
            </label>
            <FormInputWithPrecisionDisplay
              fieldName="netWeight"
              inputSuffix={
                <WeightUnitInfoAlert
                  weightUnit={transformWeightUnitToOriginal(weightUnit)}
                  error={errors?.netWeight}
                />
              }
              id="net-weight"
              round={YIELD_DEFAULT_ROUND}
            />
          </div>
        </Col>
        {errors?.netWeight || errors?.grossWeight || errors?.tareWeight ? (
          <Alert
            data-testid="error-banner"
            className="yield-load-alerts w-full ml-2"
            message="Calculation error. Delete one value to reset auto-calculation."
            showIcon
            type="error"
          />
        ) : null}
      </Row>
    </div>
  );
};

export default WeightSection;
