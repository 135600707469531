/* eslint-disable react/prop-types */
import React, { useEffect, useState } from 'react';
import { PropTypes } from 'prop-types';
import usePlans from 'hooks/usePlans';
import useTasks from 'hooks/useTasks';
import useRecommendations from 'hooks/useRecommendations';
import useInvoiceData from 'screens/Invoices/helpers/useInvoiceData';
import { ReportSubTypes } from 'screens/Reports/hooks/useReportMetadata';

import { ReactComponent as AlertIcon } from 'assets/alertIcon.svg';
import { ReactComponent as WarningIcon } from 'assets/warning.svg';

const MultiPrintAlert = ({ activeReport, values }) => {
  const reportName = activeReport.name.replace('Print', '');
  const [printReportName, setPrintReportName] = useState();
  const [cropSeasonId, setCropSeasonId] = useState();
  const [showAlert, setShowAlert] = useState(false);

  const { plans, isLoading: plansLoading, getPlans } = usePlans({});
  const { loadTasks, loading: tasksLoading, tasks } = useTasks({});
  const {
    isLoading: recommendationsLoading,
    recommendations,
    getRecommendations
  } = useRecommendations({});

  const {
    loading: invoicesLoading,
    invoices,
    getReportInvoices
  } = useInvoiceData({});

  useEffect(() => {
    const filteredCropSeasons = values.selectedCropSeasons?.map(
      cropSeason => cropSeason?.id
    );
    setCropSeasonId(filteredCropSeasons);
    setPrintReportName(reportName);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeReport, values.selectedCropSeasons]);

  useEffect(() => {
    if (!cropSeasonId) return;
    if (cropSeasonId && activeReport.subType === ReportSubTypes.PrintPlans) {
      if (values.cropSeasonStartDate && values.cropSeasonEndDate) {
        getPlans({
          dateRange: [values.cropSeasonStartDate, values.cropSeasonEndDate],
          size: -1,
          seasonIdsCommaSeparated: cropSeasonId.join(',')
        });
      } else {
        getPlans({
          size: -1,
          seasonIdsCommaSeparated: cropSeasonId.join(',')
        });
      }
    }

    if (cropSeasonId && activeReport.subType === ReportSubTypes.PrintTasks) {
      loadTasks({
        size: -1,
        seasonIdsCommaSeparated: cropSeasonId.join(',')
      });
    }

    if (
      cropSeasonId &&
      activeReport.subType === ReportSubTypes.PrintRecommendations
    ) {
      getRecommendations(undefined, undefined, cropSeasonId.join(','));
    }

    if (cropSeasonId && activeReport.subType === ReportSubTypes.PrintInvoices) {
      getReportInvoices(cropSeasonId.join(','));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    activeReport,
    cropSeasonId,
    values.cropSeasonStartDate,
    values.cropSeasonEndDate
  ]);

  useEffect(() => {
    if (!tasksLoading) {
      const tasksResult = tasks.filter(
        task =>
          new Date(task?.startDate) >= new Date(values?.cropSeasonStartDate) &&
          new Date(task?.startDate) <= new Date(values?.cropSeasonEndDate)
      );
      setShowAlert(!!tasksResult?.length);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    values.cropSeasonEndDate,
    values.cropSeasonStartDate,
    tasks,
    tasksLoading
  ]);

  useEffect(() => {
    if (!recommendationsLoading) {
      const recommendationsResult = recommendations.filter(
        recommendation =>
          new Date(recommendation?.createdOn) >=
            new Date(values?.cropSeasonStartDate) &&
          new Date(recommendation?.createdOn) <=
            new Date(values?.cropSeasonEndDate)
      );
      setShowAlert(!!recommendationsResult?.length);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    values.cropSeasonEndDate,
    values.cropSeasonStartDate,
    recommendations,
    recommendationsLoading
  ]);

  useEffect(() => {
    if (!invoicesLoading) {
      const invoicesResult = invoices.filter(
        invoice =>
          new Date(invoice?.invoiceDate) >=
            new Date(values?.cropSeasonStartDate) &&
          new Date(invoice?.invoiceDate) <= new Date(values?.cropSeasonEndDate)
      );
      setShowAlert(!!invoicesResult?.length);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    values.cropSeasonEndDate,
    values.cropSeasonStartDate,
    invoices,
    invoicesLoading
  ]);

  useEffect(() => {
    if (plansLoading) return;
    setShowAlert(!!plans.length);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cropSeasonId, plans, plansLoading]);

  const positiveAlert = () => {
    return (
      <div className="z-10 w-100/100">
        <div className="border-banner-alert-blue-30 border rounded bg-banner-alert-blue-10 mb-32px py-12px px-16px">
          <div className="flex content-start pb-4px">
            <div className="h-24px w-24px mt-26px mr-3 ">
              <AlertIcon style={{ height: '24px', width: '24px' }} />
            </div>
            <div className="text-md">
              All {printReportName} for the selected crop season(s) will be
              included in the report
            </div>
          </div>
        </div>
      </div>
    );
  };

  const negativeAlert = () => {
    return (
      <div className="z-10 285px pl-16px">
        <div className="border-banner-alert-yellow-30 border rounded bg-banner-alert-yellow-10 mb-32px">
          <div className="py-12px px-16px">
            <div className="flex content-start">
              <div className="h-24px w-24px mr-3 mt-2">
                <WarningIcon style={{ height: '24px', width: '24px' }} />
              </div>
              <div className="text-md">
                No {printReportName} found in the selected crop season
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };

  const alertSwitch = () => {
    if (!cropSeasonId?.length) return [];
    return showAlert ? positiveAlert() : negativeAlert();
  };

  return alertSwitch();
};

MultiPrintAlert.propTypes = {
  activeReport: PropTypes.shape().isRequired
};

export default MultiPrintAlert;
