import React from 'react';
import PropTypes from 'prop-types';

const CardWrapper = ({ children, header, className }) => {
  return (
    <div className="bg-white rounded-lg shadow-lg">
      {header}
      <div className={`p-4 ${className}`}>{children}</div>
    </div>
  );
};

CardWrapper.defaultProps = {
  className: '',
  header: null
};

CardWrapper.propTypes = {
  children: PropTypes.node.isRequired,
  header: PropTypes.node,
  className: PropTypes.string
};

export default CardWrapper;
