import React, { createContext, useReducer } from 'react';
import PropTypes from 'prop-types';
import { NAVBAR_OPEN, SET_ACTIVE_REPORT, reducer } from '../reducer';

const initialState = {
  navbarOpen: true
};

export const ReportsContext = createContext(initialState);

const ReportsProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialState);

  const toggleNavbar = () => {
    dispatch({
      type: NAVBAR_OPEN,
      payload: !state.navbarOpen
    });
  };

  const setActiveReport = reportSelection => {
    dispatch({
      type: SET_ACTIVE_REPORT,
      payload: reportSelection
    });
  };

  const getActiveReport = () => {
    return state.activeReport;
  };

  return (
    <ReportsContext.Provider
      value={{
        state,
        dispatch,
        toggleNavbar,
        setActiveReport,
        getActiveReport
      }}
    >
      {children}
    </ReportsContext.Provider>
  );
};

ReportsProvider.defaultProps = {
  children: null
};

ReportsProvider.propTypes = {
  children: PropTypes.node
};

export default ReportsProvider;
