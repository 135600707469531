import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Button } from '@agconnections/grow-ui';
import ConfirmationModal from './ConfirmationModal';

const DeleteOrganization = ({ organization }) => {
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const openDeleteModal = () => setDeleteModalOpen(true);
  const closeDeleteModal = () => setDeleteModalOpen(false);

  return (
    <>
      <ConfirmationModal
        open={deleteModalOpen}
        orgName={organization?.name}
        close={closeDeleteModal}
      />
      <div className="flex items-center justify-between pt-6 pb-12 ml-6">
        <div>
          <div className="text-lg text-syngenta-navy">Delete Organization</div>
          <div className="text-sm text-neutral-300">
            Deleting an organization is an irreversible action.
          </div>
        </div>
        <Button danger type="text" onClick={openDeleteModal}>
          Delete
        </Button>
      </div>
    </>
  );
};

DeleteOrganization.defaultProps = {
  organization: null
};

DeleteOrganization.propTypes = {
  organization: PropTypes.shape({
    name: PropTypes.string,
    id: PropTypes.string
  })
};

export default DeleteOrganization;
