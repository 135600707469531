import { get, uniqBy } from 'lodash';

// create a tuple of the form [cropSeason, planItems]
const createCropSeasonGroupTuple = (cropSeason, previousItems, newItem) => [
  cropSeason,
  [...previousItems, newItem]
];

export const addPlanToGroup = (groups, cropSeason, plan) => {
  const groupIndex = groups.findIndex(([name]) => name === cropSeason);

  if (groupIndex === -1) {
    return null;
  }
  const [groupName, groupList] = groups[groupIndex];
  // eslint-disable-next-line no-param-reassign
  groups[groupIndex] = createCropSeasonGroupTuple(groupName, groupList, plan);
  return groups;
};

export const createNewGroup = (groups, cropSeason, plan) => {
  groups.push(createCropSeasonGroupTuple(cropSeason, [], plan));
  return groups;
};

// can't use lodash groupBy because the object keys end up sorted by crop season name
export const groupPlans = plans =>
  plans.reduce((groups, plan) => {
    const cropSeason = get(plan, 'cropSeason.cropSeasonLabel');
    // if the group exists add this plan to that group, otherwise create the group
    return (
      addPlanToGroup(groups, cropSeason, plan) ||
      createNewGroup(groups, cropSeason, plan)
    );
  }, []);

const getPlansByCropSeason = (plans, seasonId) => {
  return plans.filter(({ cropSeasons }) =>
    !seasonId || seasonId === '-1'
      ? !cropSeasons.length
      : cropSeasons.some(({ seasonId: id }) => id === seasonId)
  );
};

export const groupPlansByCropSeason = plans => {
  return uniqBy(
    plans.map(({ cropSeasons }) => cropSeasons).flat(),
    ({ seasonId }) => seasonId
  ).map(({ seasonId, name }) => ({
    id: seasonId,
    name,
    plans: getPlansByCropSeason(plans, seasonId)
  }));
};
