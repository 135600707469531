import { CROPWISE_PROXY_URL } from 'utilities/apiConstants';

const { default: axios } = require('axios');
const { default: catchCancel } = require('helpers/catchCancel');

const getCropSeasonDetails = async (
  seasonId,
  tk,
  orgId,
  setInitialValues,
  setPageError,
  setLoaded
) => {
  const promise = axios.get(
    `${CROPWISE_PROXY_URL}/v2/base/cropseason/${seasonId}`,
    {
      headers: {
        'Content-Type': 'application/json',
        'cwf-context': JSON.stringify({
          organization_id: orgId
        }),
        Authorization: `Bearer ${tk}`
      }
    }
  );

  await promise
    .then(({ data }) => {
      const values = data;
      values.date = [new Date(data.startDate), new Date(data.endDate)];
      const cropzones = [];
      data.cropZones.forEach(cz => cropzones.push(cz.cropzoneId));
      values.cropZones = cropzones;
      values.propertiesGroupBy = 'property';
      setInitialValues(values);
    })
    .catch(catchCancel)
    .catch(err => {
      setInitialValues({});
      setPageError(err.response);
    })
    .finally(() => setLoaded(true));
};

export default getCropSeasonDetails;
