import React, { useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Button, Table } from '@agconnections/grow-ui';
import PencilEditIcon from 'components/Icons/components/PencilEditIcon';
import useSubmissionUrlHandler from 'screens/Integrations/hooks/useSubmissionUrlHandler';

import useSubmissionAnalytics from 'screens/Integrations/hooks/useSubmissionAnalytics';
import ProductNameAndManufacture from './components/ProductNameAndManufacturer';
import { SubmissionSummaryContext } from '../../../../context/SubmissionSummaryProvider';

const FieldProductsTable = ({ products, errors }) => {
  const { events, triggerAnalyticsEvent } = useSubmissionAnalytics();
  const { redirectToTask } = useSubmissionUrlHandler();

  const {
    state: { submissionId, integrationType, isReadOnly }
  } = useContext(SubmissionSummaryContext);

  const [errorsMap, setErrorsMap] = useState({});

  const handleEditClick = product => {
    triggerAnalyticsEvent(events.epic.Integrations.submissionTaskClick, {
      vendor: integrationType
    });
    redirectToTask(product, submissionId, integrationType);
  };

  useEffect(() => {
    let map = {};
    errors.forEach(error => {
      const aux = error.taskIds.reduce((a, v) => ({ ...a, [v]: error }), {});
      map = { ...aux, ...map };
    });
    setErrorsMap(map);
  }, [errors]);

  return (
    <Table>
      <Table.Header>
        {errors && <Table.Cell />}
        <Table.Cell>Date</Table.Cell>
        <Table.Cell>Cropwise Product</Table.Cell>
        <Table.Cell>Product Type</Table.Cell>
        <Table.Cell>Application Method</Table.Cell>
        <Table.Cell>Rate (per acre)</Table.Cell>
        <Table.Cell>Total Applied</Table.Cell>
        {!isReadOnly && <Table.Cell />}
      </Table.Header>
      {products?.map(product => (
        <Table.Row
          key={product.productId}
          className={errors && errorsMap[product.taskId] ? 'bg-red-100' : ''}
        >
          {errors && (
            <Table.Cell>
              {errorsMap[product.taskId] && (
                <span
                  className="bg-red-200 text-red-800 rounded-md text-xs p-2 font-semibold"
                  data-testid="span-error-message"
                >
                  {errorsMap[product.taskId].errorMessage}
                </span>
              )}
            </Table.Cell>
          )}
          <Table.Cell>
            {new Date(product.appliedDate).toLocaleDateString()}
          </Table.Cell>
          <Table.Cell>
            <ProductNameAndManufacture product={product} />
          </Table.Cell>
          <Table.Cell>{product.productType}</Table.Cell>
          <Table.Cell>{product.applicationMethod}</Table.Cell>
          <Table.Cell>
            {product.ratePerArea.toFixed(3)} {product.ratePerAreaUnit}
          </Table.Cell>
          <Table.Cell>
            {product.quantity.toFixed(3)} {product.quantityUnit}
          </Table.Cell>
          {!isReadOnly && (
            <Table.Cell>
              <Button
                type="button"
                className="w-full flex flex-row text-xs"
                onClick={() => handleEditClick(product)}
              >
                <div className="text-right">
                  <PencilEditIcon />
                </div>
                <span className="ml-1">Edit</span>
              </Button>
            </Table.Cell>
          )}
        </Table.Row>
      ))}
    </Table>
  );
};

FieldProductsTable.propTypes = {
  products: PropTypes.arrayOf(PropTypes.object),
  errors: PropTypes.arrayOf(PropTypes.object)
};

FieldProductsTable.defaultProps = {
  products: [],
  errors: []
};

export default FieldProductsTable;
