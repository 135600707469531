import { DESTINATION_TYPES } from 'screens/YieldV2/Loads/constants';
import _ from 'lodash';
import { tabsLabels } from './constants';

const validateDestination = (_tabs, destinationType) => {
  const cloneTabs = _.cloneDeep(_tabs);
  if (destinationType === DESTINATION_TYPES.STORAGE) {
    delete cloneTabs[tabsLabels.saleInfo];
    return cloneTabs;
  }
  return _tabs;
};

export default validateDestination;
