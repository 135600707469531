import React, { useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import orderBy from 'lodash/fp/orderBy';
import { Table, Select } from '@agconnections/grow-ui';
import {
  createEventHandlerWithTransform,
  getEventSelectedKey
} from 'utilities/formHelpers';
import LoadingTableRow from 'components/LoadingTableRow';
import TableHeader from 'components/LandingTable/components/TableHeader';
import usePestData from 'hooks/usePestData';
import ProductsTableRow from './components/ProductsTableRow';

const COLUMN_SORT_FUNCTIONS = {
  name: (direction, products) =>
    orderBy(
      [product => product.productName.toLowerCase()],
      direction,
      products
    ),
  applicationOption: (direction, products) =>
    orderBy(
      [
        product =>
          `${product.productRatePerAreaValue}_${product.productRatePerAreaUnit}`
      ],
      direction,
      products
    ),
  appliedArea: (direction, products) =>
    orderBy(
      [
        product =>
          `${product.productAppliedAreaValue}_${product.productAppliedAreaUnit}`
      ],
      direction,
      products
    ),
  appliedAreaPercent: (direction, products) =>
    orderBy([product => product.coveragePercent], direction, products),
  totalProduct: (direction, products) =>
    orderBy(
      [product => `${product.totalProductValue}_${product.totalProductUnit}`],
      direction,
      products
    )
};

const ProductsSection = ({ recommendation }) => {
  const itemsApplicationStrategy = [
    {
      key: 'ratePerAreaValue',
      value: 'By Rate/Area Application',
      display: 'ByRatePerArea'
    },
    {
      key: 'totalProductValue',
      value: 'By Total Product Application',
      display: 'ByTotalProduct'
    },
    {
      key: 'ratePerTankValue',
      value: 'By Rate/Tank Application',
      display: 'ByRatePerTank'
    }
  ];
  const applicationStrategyFilter = strategy => {
    if (strategy === itemsApplicationStrategy[0].display) {
      return itemsApplicationStrategy[0].key;
    }
    if (strategy === itemsApplicationStrategy[1].display) {
      return itemsApplicationStrategy[1].key;
    }
    if (strategy === itemsApplicationStrategy[2].display) {
      return itemsApplicationStrategy[2].key;
    }
    return itemsApplicationStrategy[0].key;
  };

  const { pests, loading: loadingPests } = usePestData('', 0);
  const [sortBy] = useState();
  const [applicationType, setApplicationType] = useState(
    applicationStrategyFilter(recommendation.applicationStrategy)
  );
  const [sortDirection] = useState();
  const [sortedProducts, setSortedProducts] = useState([]);
  const tableColumns = useMemo(() => {
    return [
      {
        field: 'name',
        title: `Product Name (${recommendation.recommendationProductEntries
          ?.length ?? 0})`,
        sortable: false
      },
      {
        field: 'applicationOption',
        title:
          (applicationType === 'ratePerTankValue'
            ? 'Rate/Tank'
            : 'Rate/Area') ?? '',
        sortable: false
      },
      {
        field: 'appliedArea',
        title: 'Applied Area',
        sortable: false
      },
      {
        field: 'appliedAreaPercent',
        title: 'Applied Area %',
        sortable: false
      },
      {
        field: 'totalProduct',
        title: 'Total Product',
        sortable: false
      },
      {
        field: 'actions',
        title: '',
        sortable: false
      }
    ];
  }, [applicationType, recommendation.recommendationProductEntries?.length]);

  useEffect(() => {
    const sortFunction = COLUMN_SORT_FUNCTIONS[sortBy ?? ''];
    if (sortFunction && sortDirection) {
      setSortedProducts(
        sortFunction(
          sortDirection,
          recommendation.recommendationProductEntries ?? []
        )
      );
    } else {
      setSortedProducts(recommendation.recommendationProductEntries ?? []);
    }
  }, [recommendation.recommendationProductEntries, sortBy, sortDirection]);

  const handleFilterChange = (event, value) => {
    setApplicationType(value);
  };

  const handleSelect = createEventHandlerWithTransform(
    handleFilterChange,
    getEventSelectedKey
  );

  return (
    <div data-testid="products-services-section" className="mb-6">
      <div className="flex justify-between pt-4 mb-8">
        <div data-testid="products-services-section-header">
          <div className="text-2xl font-bold mb-2">Products & Services</div>
          <div className="text-sm text-neutral-600">
            <Select
              value={applicationType}
              onChange={handleSelect}
              items={itemsApplicationStrategy}
            />
          </div>
        </div>
      </div>
      <Table>
        <TableHeader sortBy={sortBy ?? ''} tableColumns={tableColumns} />
        {loadingPests ? (
          <LoadingTableRow numCells={tableColumns.length} />
        ) : (
          sortedProducts?.map((product, index) => (
            <ProductsTableRow
              // eslint-disable-next-line react/no-array-index-key
              key={`${product.productId}_${index}`}
              product={product}
              pests={pests}
              applicationOption={applicationType}
              tankInformation={recommendation?.tankInformation}
            />
          ))
        )}
      </Table>
    </div>
  );
};

ProductsSection.propTypes = {
  recommendation: PropTypes.shape({
    applicationStrategy: PropTypes.string.isRequired,
    recommendationProductEntries: PropTypes.arrayOf(PropTypes.shape({}))
      .isRequired,
    tankInformation: PropTypes.arrayOf(PropTypes.shape({})).isRequired
  }).isRequired
};

export default ProductsSection;
