export const labelTitles = {
  name: 'Name of Location',
  company: 'Company',
  phone: 'Phone Number',
  email: 'Email',
  city: 'City',
  State: 'State',
  zipCode: 'Zip Code',
  addressLineOne: 'Address Line 1',
  addressLineTwo: 'Address Line 2',
  notes: 'Notes'
};

export const LabelInputs = {
  name: 'name',
  company: 'company',
  companyId: 'companyId',
  phone: 'phone',
  email: 'email',
  city: 'city',
  State: 'state',
  zipCode: 'zipCode',
  addressLineOne: 'addressLineOne',
  addressLineTwo: 'addressLineTwo',
  notes: 'notes'
};
