import { capitalize } from 'lodash';
import applyFetchFilters from '../../utils/applyFetchFilters';
import { notFoundResponse } from '../../utils/errorResponses';
import withStandardErrors from './withStandardErrors';
import filterInactive from './filterInactive';

/**
 * creates a get route handler for mirage resource
 * @param {*} server - the Mirage server
 * @param {*} name - the name of the resource (matches model name) singular NOT plural
 */
export const genGetResourceRouteHandler = (server, name) => {
  const resourceName = server.inflector.pluralize(name);
  const routeHandler = (schema, request) => {
    const { default: _default } = request.queryParams;
    const resource = filterInactive(schema[resourceName].all(), _default);
    return applyFetchFilters(resource, request.queryParams);
  };
  return routeHandler;
};

/**
 * creates a get by id route handler for mirage resource
 * @param {*} server - the Mirage server
 * @param {*} name - the name of the resource (matches model name) singular NOT plural
 */
export const genGetResourceByIdRouteHandler = (server, name) => {
  const resourceName = server.inflector.pluralize(name);
  const routeHandler = (schema, request) => {
    const { id } = request.params;
    const resource = schema[resourceName].find(id);
    if (resource) return resource;
    if (process.env.REACT_APP_ENV === 'mirage-development') {
      return schema[resourceName].first();
    }
    return notFoundResponse(id, capitalize(resourceName));
  };
  return routeHandler;
};

/**
 * creates a update route handler for mirage resource
 * @param {*} server - the Mirage server
 * @param {*} name - the name of the resource (matches model name) singular NOT plural
 * @param {*} beforeUpdateHandler - a function that processes the update body before the update is called
 */
export const genUpdateResourceRouteHandler = (
  server,
  name,
  requiredFields,
  beforeUpdateHandler
) => {
  const resourceName = server.inflector.pluralize(name);
  const routeHandler = (schema, { params, requestBody }) => {
    const { id } = params;
    const resource = schema[resourceName].find(id);
    if (resource) {
      const { version, ...rest } = JSON.parse(requestBody);
      const body = beforeUpdateHandler ? beforeUpdateHandler(rest) : rest;
      return resource.update({
        ...body,
        version: version + 1,
        updatedAt: new Date()
      });
    }
    return notFoundResponse(id, capitalize(resourceName));
  };
  return withStandardErrors(routeHandler, requiredFields);
};

/**
 * creates a get route handler for mirage resource
 * @param {*} server - the Mirage server
 * @param {*} name - the name of the resource (matches model name) singular NOT plural
 */
export const genCreateResourceRouteHandler = (server, name, requiredFields) => {
  const resourceName = server.inflector.pluralize(name);
  const routeHandler = (schema, request) => {
    return schema[resourceName].create({
      ...JSON.parse(request.requestBody),
      active: true,
      createdAt: new Date(),
      version: 0
    });
  };
  return withStandardErrors(routeHandler, requiredFields);
};

/**
 * creates a soft-delete route handler for mirage resource
 * @param {*} server - the Mirage server
 * @param {*} name - the name of the resource (matches model name) singular NOT plural
 */
export const genSoftDeleteResourceRouteHandler = (server, name) => {
  const resourceName = server.inflector.pluralize(name);
  const deleteOrgResource = (schema, request) => {
    const { id } = request.params;

    const resource = schema[resourceName].find(id);
    if (!resource) return notFoundResponse(id, capitalize(resourceName));
    return resource.update({ active: false });
  };
  return deleteOrgResource;
};
