import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import CwFPagination from 'components/CwFPagination';
import Paper from 'components/Paper';
import LandingTable from 'components/LandingTable';
import peopleTableSort from 'screens/People/helpers/peopleTableSort';
import PeopleTableRow from './PeopleTableRow';

export const TABLE_COLUMNS = [
  {
    field: 'name',
    title: 'Name',
    sortable: true
  },
  {
    field: 'email',
    title: 'Email',
    sortable: true
  },
  {
    field: 'license',
    title: 'License',
    sortable: true
  },
  {
    field: 'expirationDate',
    title: 'Expiration Date',
    sortable: true
  },
  {
    field: 'permission',
    title: 'Permission',
    sortable: true
  }
];

const PeopleList = ({
  people,
  onAction: handleAction,
  getMenu,
  totalPeople,
  peoplePageNumber,
  handlePageChange
}) => {
  const [sortDir, setSortDir] = useState();
  const [sortField, setSortField] = useState();
  const [filteredPeople, setFilteredPeople] = useState(people);

  const handleSort = (field, dir) => {
    setSortField(field);
    setSortDir(dir);
    setFilteredPeople(peopleTableSort(filteredPeople, field, dir));
  };

  useEffect(() => {
    setFilteredPeople(people);
  }, [people]);

  return (
    <Paper>
      {CwFPagination(totalPeople, peoplePageNumber, handlePageChange)}
      <LandingTable
        listType="People"
        items={filteredPeople}
        tableColumns={TABLE_COLUMNS}
        onSort={handleSort}
        sortBy={sortField}
        sortDir={sortDir}
      >
        {({ rows }) =>
          rows.map(user => (
            <PeopleTableRow
              key={user.id}
              user={user}
              onAction={action => {
                handleAction(action, user);
              }}
              menu={getMenu(user)}
            />
          ))
        }
      </LandingTable>
      {CwFPagination(totalPeople, peoplePageNumber, handlePageChange)}
      <div className="mb-12"> </div>
    </Paper>
  );
};

PeopleList.defaultProps = {
  people: []
};

PeopleList.propTypes = {
  people: PropTypes.arrayOf(PropTypes.object),
  onAction: PropTypes.func.isRequired,
  getMenu: PropTypes.func.isRequired,
  totalPeople: PropTypes.number.isRequired,
  peoplePageNumber: PropTypes.number.isRequired,
  handlePageChange: PropTypes.func.isRequired
};

export default PeopleList;
