import React, { useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useFormikContext } from 'formik';
import ProductsSideBar from 'components/ProductSideBar';
import { AmplitudeContext } from 'utilities/amplitude/useAmplitude';
import TopOptions from './components/TopOptions';
import TaskProductTable from './components/TaskProductTable';

const Products = ({
  memberRole,
  memberFinancialAccess,
  applicationOptions,
  selectedApplicationOption,
  setSelectedApplicationOption,
  isEditing,
  setAmplitudeTabSelection
}) => {
  const { setFieldValue, values, setFieldTouched } = useFormikContext();
  const [customProductCreated, setCustomProductCreated] = useState(false);
  const amplitude = useContext(AmplitudeContext);
  const removeProduct = product => {
    setFieldTouched('remove project');
    const stubbedAddedProductsCopy = values?.products;
    stubbedAddedProductsCopy.splice(
      stubbedAddedProductsCopy.findIndex(
        productCopy => productCopy.productId === product.productId
      ),
      1
    );
    setFieldValue(values?.products, stubbedAddedProductsCopy);
  };

  const editProduct = () => {
    setFieldValue(values?.products, values?.products);
  };

  useEffect(() => {
    amplitude.sendEventToAmplitude(
      amplitude.events.epic.Tasks.selectProductTab
    );
    setAmplitudeTabSelection('Products');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="flex justify-between h-full">
      <div className="w-3/12 mt-16">
        <ProductsSideBar
          memberRole={memberRole}
          memberFinancialAccess={memberFinancialAccess}
          selectedApplicationOption={selectedApplicationOption}
          setCustomProductCreated={setCustomProductCreated}
        />
      </div>
      <div className="ml-4 flex-grow">
        <TopOptions
          selectedApplicationOption={selectedApplicationOption}
          applicationOptions={applicationOptions}
          setSelectedApplicationOption={setSelectedApplicationOption}
          isEditing={isEditing}
        />
        <TaskProductTable
          editProduct={editProduct}
          removeProduct={removeProduct}
          memberRole={memberRole}
          memberFinancialAccess={memberFinancialAccess}
          selectedApplicationOption={selectedApplicationOption}
          setCustomProductCreated={setCustomProductCreated}
          customProductCreated={customProductCreated}
        />
      </div>
    </div>
  );
};

Products.defaultProps = {
  memberRole: '',
  memberFinancialAccess: ''
};

Products.propTypes = {
  memberRole: PropTypes.string,
  memberFinancialAccess: PropTypes.string,
  applicationOptions: PropTypes.arrayOf(
    PropTypes.shape({
      key: PropTypes.string,
      value: PropTypes.string
    })
  ).isRequired,
  selectedApplicationOption: PropTypes.string.isRequired,
  setSelectedApplicationOption: PropTypes.func.isRequired,
  isEditing: PropTypes.bool.isRequired,
  setAmplitudeTabSelection: PropTypes.func.isRequired
};

export default Products;
