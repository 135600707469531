import React, { useState, useEffect, useContext } from 'react';

import { useHistory } from 'react-router-dom';
import { Spinner, Button } from '@agconnections/grow-ui';
import AddRoundedIcon from '@material-ui/icons/AddRounded';

import useLandingPageData from 'hooks/useLandingPageData';
import useOrganizationApi from 'hooks/useOrganizationApi';

import catchCancel from 'helpers/catchCancel';
import { AmplitudeContext } from 'utilities/amplitude/useAmplitude';

import { Context } from 'components/Store';
import EllipseMenu from 'components/EllipseMenu';
import DeleteModal from 'components/Modals/DeleteModal';
import Breadcrumb from 'components/App/AppShell/components/Breadcrumb';
import EmptyLandingOverlay from 'components/EmptyLandingOverlay';

import { paths } from 'routes/paths';
import { PEOPLE_LANDING_MENU } from 'utilities/menus';
import PeopleList from './components/PeopleList';
import PeopleSearchBar from './components/PeopleSearchBar';
import { findUser } from './helpers/dataHelpers';
import { hasAccess, ROLES } from '../../utilities/access';

const People = () => {
  const [peoplePageNumber, setPeoplePageNumber] = useState(0);

  const {
    loaded: isPeopleLoaded,
    items: people,
    setItems: setPeople,
    totalItems: totalPeople,
    setReload
  } = useLandingPageData(
    'worker/people',
    ['contactInfo.name.givenName', 'contactInfo.name.surname'],
    'contactInfo.name.givenName',
    peoplePageNumber
  );

  const history = useHistory();
  const peopleAPI = useOrganizationApi('people');
  const amplitude = useContext(AmplitudeContext);
  const [
    {
      loggedInUserOrgPermission: { role }
    }
  ] = useContext(Context);

  const [searchText, setSearchText] = useState(null);
  const [filteredPeople, setFilteredPeople] = useState([]);

  const [userId, setUserId] = useState(null);
  const [isDeleting, setIsDeleting] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);

  useEffect(() => {
    setFilteredPeople(findUser(people, searchText));
  }, [people, searchText]);

  const isLoading = () => {
    return !isPeopleLoaded || isDeleting;
  };

  const hasCreatePermission = () => {
    return hasAccess({ role }, { minAllowedRole: ROLES.FULL_CONTROL });
  };

  const openDeleteModal = () => {
    setShowDeleteModal(true);
  };

  const closeDeleteModal = () => {
    setShowDeleteModal(false);
  };

  const handleDelete = async () => {
    if (!userId) return;

    amplitude.sendEventToAmplitude(amplitude.events.epic.People.deletePerson);

    setIsDeleting(true);
    closeDeleteModal();

    await peopleAPI
      .delete(userId)
      .promise.then(() => {
        setPeople(newPeople => newPeople.filter(({ id }) => id !== userId));
      })
      .catch(catchCancel)
      .catch(error => {
        // eslint-disable-next-line no-console
        console.error(error); // TODO: needs to pop up a message
      });

    setUserId(null);
    setIsDeleting(false);
  };

  const handleAction = async (action, user) => {
    const actions = {
      view: () => {
        history.push(`people/${user.id}/view`);
      },
      edit: () => {
        amplitude.sendEventToAmplitude(amplitude.events.epic.People.editPerson);
        history.push(`people/${user.id}`);
      },
      delete: async () => {
        setUserId(user.id);
        openDeleteModal();
      },
      print: () => {}
    };
    actions[action]();
  };

  const getAddPersonBtn = () => {
    return hasCreatePermission() ? (
      <Button
        id="add-person-button"
        type="primary"
        icon={<AddRoundedIcon />}
        onClick={() => {
          history.push(`${paths.people}/person`);
        }}
      >
        Add Person
      </Button>
    ) : null;
  };

  const getEllipseMenu = user => {
    return (
      <EllipseMenu
        onAction={action => {
          handleAction(action, user);
        }}
        options={PEOPLE_LANDING_MENU}
      />
    );
  };

  const handlePageChange = page => {
    setPeoplePageNumber(page);
    setReload(true);
  };

  const getView = () => {
    if (filteredPeople.length > 0) {
      return (
        <PeopleList
          people={filteredPeople}
          onAction={handleAction}
          getMenu={getEllipseMenu}
          totalPeople={totalPeople}
          peoplePageNumber={peoplePageNumber}
          handlePageChange={handlePageChange}
        />
      );
    }

    return (
      <EmptyLandingOverlay subtitle="We were unable to find any person under this organization.">
        {getAddPersonBtn()}
      </EmptyLandingOverlay>
    );
  };

  const handleOrgChange = () => {
    setPeoplePageNumber(0);
    setReload(true);
  };

  return (
    <div className="w-full h-full" data-testid="people">
      <Breadcrumb onOrganizationSelect={handleOrgChange} hideCropSeasonDropdown>
        <Breadcrumb.Item title="People" value="All People" isLast />
      </Breadcrumb>
      <PeopleSearchBar onChange={setSearchText}>
        {getAddPersonBtn()}
      </PeopleSearchBar>
      {isLoading() ? <Spinner /> : getView()}
      <DeleteModal
        open={showDeleteModal}
        itemType="Person"
        onCancel={closeDeleteModal}
        onDelete={handleDelete}
      />
    </div>
  );
};

export default People;
