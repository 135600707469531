import { useCallback, useContext, useEffect } from 'react';

import events from 'utilities/amplitude/events';
import { AmplitudeContext } from 'utilities/amplitude/useAmplitude';

const useAnalytics = ({
  onMountTriggerEvent = undefined,
  onUnMountTriggerEvent = undefined
} = {}) => {
  const amplitude = useContext(AmplitudeContext);

  const triggerEvent = useCallback(
    (event, data) => {
      amplitude.sendEventToAmplitude(event, data ?? {});
    },
    [amplitude]
  );

  useEffect(() => {
    if (onMountTriggerEvent) {
      triggerEvent(onMountTriggerEvent.event, onMountTriggerEvent.data);
    }

    return () => {
      if (onUnMountTriggerEvent) {
        triggerEvent(onUnMountTriggerEvent.event, onUnMountTriggerEvent.data);
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return {
    events,
    triggerAnalyticsEvent: triggerEvent
  };
};

export default useAnalytics;
