import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useFormikContext } from 'formik';
import { Switch } from 'syngenta-digital-cropwise-react-ui-kit';
import YieldSection from '../YieldSection';
import YieldShrinkSection from '../YieldShrinkSection';
import './antd-override.css';

const titleClassName = 'text-xl leading-7 font-semibold tracking-tight mb-4';
const YieldSectionContainer = ({ selectedCropName, shrinkEnabled }) => {
  const { setFieldValue } = useFormikContext();
  const [calculateWithShrink, setCalculateWithShrink] = useState(true);

  useEffect(() => {
    if (!calculateWithShrink) {
      setFieldValue('shrink');
      setFieldValue('load');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [calculateWithShrink]);

  if (!shrinkEnabled) {
    return (
      <div>
        <h1 className={titleClassName}>Yield</h1>
        <YieldSection selectedCropName={selectedCropName} />
      </div>
    );
  }

  return (
    <div>
      <div className="flex gap-8">
        <h1 className={titleClassName}>Yield</h1>
        <span className="text-sm">
          <Switch
            size="small"
            checked={calculateWithShrink}
            onChange={() => setCalculateWithShrink(prev => !prev)}
          />
          <span className="ml-2">Calculate with Shrink</span>
        </span>
      </div>
      {calculateWithShrink ? (
        <YieldShrinkSection selectedCropName={selectedCropName} />
      ) : (
        <YieldSection selectedCropName={selectedCropName} />
      )}
    </div>
  );
};

YieldSectionContainer.defaultProps = {
  shrinkEnabled: false
};

YieldSectionContainer.propTypes = {
  selectedCropName: PropTypes.string.isRequired,
  shrinkEnabled: PropTypes.string
};

export default YieldSectionContainer;
