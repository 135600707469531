import { useState } from 'react';

import { genProxyResource } from 'utilities/api';

// TODO: deprecate this hook ASAP
const usePlansApi = resourceName => {
  const [api] = useState(genProxyResource(resourceName, { urlVersion: '/v1' }));

  return api;
};

export default usePlansApi;
