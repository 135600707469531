import React from 'react';
import PropTypes from 'prop-types';
import ProductListItem from './components/ProductListItem';

const ProductList = ({
  products,
  matches,
  matchesWithErrors,
  onSelectMatch
}) => {
  const getMatchedProduct = product => {
    const matchedProduct = matches.find(m => m.cwfId === product.productId);
    if (!matchedProduct) {
      return undefined;
    }

    return {
      ...matchedProduct,
      error: matchesWithErrors.find(e => e.cwfId === product.productId)?.error
    };
  };

  return (
    <div className="grid w-full h-full">
      <div className="mb-5">
        {products?.map(product => (
          <ProductListItem
            key={product.productId}
            product={product}
            matchedProduct={getMatchedProduct(product)}
            handleSelect={onSelectMatch}
          />
        ))}
      </div>
    </div>
  );
};

ProductList.defaultProps = {
  matches: [],
  matchesWithErrors: []
};

ProductList.propTypes = {
  products: PropTypes.arrayOf(
    PropTypes.shape({
      productId: PropTypes.string,
      productName: PropTypes.string
    })
  ).isRequired,
  matches: PropTypes.arrayOf(
    PropTypes.shape({
      cwfId: PropTypes.string,
      vendorId: PropTypes.string,
      vendorPropertyName: PropTypes.string
    })
  ),
  matchesWithErrors: PropTypes.arrayOf(
    PropTypes.shape({
      cwfId: PropTypes.string,
      vendorId: PropTypes.string,
      error: PropTypes.string,
      vendorPropertyName: PropTypes.string
    })
  ),
  onSelectMatch: PropTypes.func.isRequired
};

export default ProductList;
