export const timingKeyValuePair = [
  { key: 'Pre-Plant', value: 'Pre-Plant' },
  { key: 'Planting', value: 'Planting' },
  { key: 'Growing', value: 'Growing' },
  { key: 'Harvest', value: 'Harvest' },
  { key: 'Storage', value: 'Storage' },
  { key: 'Annual', value: 'Annual' }
];

export const warningText = 'Type at least 3 characters to search';
