import React from 'react';
import PropTypes from 'prop-types';

const WarningIcon = ({ size }) => (
  <svg
    viewBox={`0 0 ${size} ${size}`}
    width={30}
    height={30}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    data-testid="warning-icon"
  >
    <path
      width={30}
      height={30}
      d="M11 3.99 18.53 17H3.47L11 3.99ZM11 0 0 19h22L11 0Zm1 14h-2v2h2v-2Zm0-6h-2v4h2V8Z"
      fill="#ECC94B"
    />
  </svg>
);

WarningIcon.defaultProps = {
  size: 27
};

WarningIcon.propTypes = {
  size: PropTypes.number
};

export default WarningIcon;
