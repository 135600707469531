// Mapbox Fix - https://github.com/kturney/ember-mapbox-gl/issues/53
const multiplePassthroughRequestFix = () => {
  const origSend = window.XMLHttpRequest.prototype.send;
  window.XMLHttpRequest.prototype.send = function send() {
    // eslint-disable-next-line prefer-rest-params
    origSend.apply(this, arguments);

    const fakeXhr = this; // eslint-disable-line consistent-this
    const realXhr = this._passthroughRequest;
    if (realXhr) {
      realXhr.onload = event => {
        if (fakeXhr.responseType !== 'arraybuffer') {
          fakeXhr.response = realXhr.response;
        }

        // dispatch event instead of calling the original to prevent a double call bug
        fakeXhr.dispatchEvent(event);
      };
    }
  };
};

export default multiplePassthroughRequestFix;
