import { paths } from 'routes/paths';
import Welcome from 'screens/Welcome';

const routes = [
  {
    id: 'welcome',
    key: 'welcome',
    path: paths.welcome,
    exact: true,
    secure: true,
    component: Welcome
  },
  {
    id: 'welcomeVariation',
    key: 'welcomeVariation',
    path: '/app/welcome/:variation',
    exact: true,
    secure: true,
    component: Welcome
  }
];

export default routes;
