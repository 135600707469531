import React, { useContext, useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';

import { Spinner } from '@agconnections/grow-ui';
import ProgressBar from 'components/ProgressBar';
import { Context } from 'components/Store';
import Breadcrumb from 'components/App/AppShell/components/Breadcrumb';

import { determineIntegrationContext } from 'screens/Integrations/helpers/determineIntegrationContext';
import useSubmissionUrlHandler from 'screens/Integrations/hooks/useSubmissionUrlHandler';
import {
  SUBMISSION_REVIEW_STEP,
  SUBMISSION_CALAG_REVIEW_STEP,
  INTEGRATIONS,
  SUBMISSION_SIMPLOT_REVIEW_STEP
} from 'screens/Integrations/helpers/constants';
import PropertiesMatchProvider from 'screens/Integrations/PropertiesMatch/context/PropertiesMatchProvider';
import { SubmissionSummaryContext } from 'screens/Integrations/SubmissionSummary/context/SubmissionSummaryProvider';
import useSubmissionAnalytics from 'screens/Integrations/hooks/useSubmissionAnalytics';
import { IntegrationsContext } from 'screens/Integrations/context/IntegrationsProvider';
import ProductsMatchProvider from 'screens/Integrations/SimplotIntegration/components/SimplotMatchProducts/context/ProductsMatchProvider';

const BaseIntegration = ({
  progressBarSteps,
  selectPropertiesStep,
  integrationType,
  StepSelector
}) => {
  const {
    state: { submissionFlowId }
  } = useContext(IntegrationsContext);
  const { events, triggerAnalyticsEvent } = useSubmissionAnalytics();
  const [{ error }] = useContext(Context);
  const {
    state: { step, canGoNext },
    gotoStep,
    goForward
  } = useContext(determineIntegrationContext(integrationType));

  const {
    state: { submissionId, loadedSubmission, isReadOnly },
    startSubmission,
    setFormikDataBySubmission,
    loadSubmission
  } = useContext(SubmissionSummaryContext);

  const [startSubmissionDone, setStartSubmissionDone] = useState(false);

  const { comingFromTaskEdition, comingFromView } = useSubmissionUrlHandler();

  const [loadingFromRedirect, setLoadingFromRedirect] = useState(
    comingFromTaskEdition
  );

  const getReviewStepNumber = () => {
    switch (integrationType) {
      case INTEGRATIONS.calAgPermits:
        return SUBMISSION_CALAG_REVIEW_STEP;
      case INTEGRATIONS.simplot:
        return SUBMISSION_SIMPLOT_REVIEW_STEP;
      default:
        return SUBMISSION_REVIEW_STEP;
    }
  };

  const reviewStepNumber = getReviewStepNumber();

  const onCompletPropertiesMatching = () => {
    triggerAnalyticsEvent(
      events.epic.Integrations.submissionPropertiesMatched,
      {
        vendor: integrationType,
        flowId: submissionFlowId
      }
    );
    setStartSubmissionDone(false);
    startSubmission().then(() => {
      setStartSubmissionDone(true);
    });
  };

  const onCompleteProductsMatching = () => {
    triggerAnalyticsEvent(
      events.epic.Integrations.ingestionRecommendationProductsMatch,
      {
        vendor: INTEGRATIONS.agrianRecommendation
      }
    );
  };

  useEffect(() => {
    if (startSubmissionDone && !error) {
      if (integrationType === INTEGRATIONS.simplot) {
        goForward();
      } else {
        gotoStep(reviewStepNumber);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [startSubmissionDone]);

  const isInitialState = useMemo(() => step === 1, [step]);

  useEffect(() => {
    if (submissionId && isInitialState) {
      loadSubmission();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [submissionId, isInitialState]);

  /**
   * This send a put to submision endpoint
   * to update the task values
   * in case that the user is coming from task
   */
  useEffect(() => {
    if (loadedSubmission && comingFromTaskEdition) {
      triggerAnalyticsEvent(events.epic.Integrations.submissionTaskRedirect, {
        vendor: integrationType
      });
      setStartSubmissionDone(false);
      startSubmission().then(() => {
        setFormikDataBySubmission();
        setStartSubmissionDone(true);
        setLoadingFromRedirect(false);
      });
    }

    if (loadedSubmission && comingFromView) {
      setFormikDataBySubmission();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loadedSubmission, comingFromTaskEdition, comingFromView]);

  return (
    <>
      {loadingFromRedirect ? (
        <div className="m-auto">
          <Spinner />
        </div>
      ) : (
        <div data-testid="base-integration">
          <Breadcrumb hideCropSeasonDropdown disabled>
            <Breadcrumb.Item
              title="Integrations"
              value={integrationType}
              isLast
            />
          </Breadcrumb>
          <div
            className={`z-50 min-h-50 h-full left-0 right-0 bottom-0 top-18 flex flex-col items-center ${isReadOnly &&
              'mt-5'}`}
          >
            {!isReadOnly && (
              <ProgressBar
                stepArray={progressBarSteps}
                currentStep={isReadOnly ? reviewStepNumber : step}
                canGoNext={canGoNext && step !== selectPropertiesStep}
                gotoStep={gotoStep}
              />
            )}
            <PropertiesMatchProvider
              integrationType={integrationType}
              onCompleteMatch={onCompletPropertiesMatching}
            >
              <ProductsMatchProvider
                integrationType={integrationType}
                onCompleteMatch={onCompleteProductsMatching}
              >
                <StepSelector
                  fixedStep={isReadOnly ? reviewStepNumber : step}
                />
              </ProductsMatchProvider>
            </PropertiesMatchProvider>
          </div>
        </div>
      )}
    </>
  );
};

BaseIntegration.propTypes = {
  progressBarSteps: PropTypes.arrayOf(
    PropTypes.shape({
      caption: PropTypes.string.isRequired
    })
  ).isRequired,
  selectPropertiesStep: PropTypes.number.isRequired,
  integrationType: PropTypes.string.isRequired,
  StepSelector: PropTypes.func.isRequired
};

export default BaseIntegration;
