const initialValuesYieldDashboard = {
  id: '',
  entryName: '',
  unit: '',
  avgYieldPerAcre: 0,
  salePrice: 0,
  commodityId: '',
  cropZoneIds: [],
  orgId: ''
};

export default initialValuesYieldDashboard;
