import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { ErrorMessage, useFormikContext } from 'formik';
import { Col, Row } from 'syngenta-digital-cropwise-react-ui-kit';
import { Select, Input } from '@agconnections/grow-ui';
import {
  fertilizerLiquidStandardUnitsList,
  fertilizerSolidStandardUnitsList
} from 'components/Modals/CustomProductModal/components/FertilizerProductForm';
import { getAbbreviatedUnit } from 'helpers/unitsAbbreviation';
import {
  convertDensityFromKgPerLToLbsPerGal,
  convertDensityFromLbsPerGalToKgPerL
} from 'screens/ProductList/helpers/convertDensity';
import GrowUIFormField from 'components/Forms/GrowUIFormField';
import FormInputWithPrecisionDisplay from 'screens/YieldV2/AddYield/components/FormInputWithPrecisionDisplay';
import {
  fertCombinedUnits,
  serviceUnits
} from '../EditProductDrawer/editCustomProductUnits';
import FormulationInput from '../FormulationInput';
import productValidationAlert from '../../../../assets/productValidationAlert.svg';
import '../EditProductDrawer/index';

export const handleValidationAlertIcon = (error, field) => {
  return error?.[field] ? <img src={productValidationAlert} alt="" /> : null;
};

const CustomProductListForm = ({ product, setSchema }) => {
  const {
    values,
    setValues,
    setFieldValue,
    errors,
    validateField,
    validateForm,
    setFieldTouched
  } = useFormikContext();
  const [densityLabel, setDensityLabel] = useState();
  const [unitList, setUnitList] = useState();

  const formRowClass = 'mb-6';
  const formLabelClass = 'text-neutral-60 flex';
  const getStdUnit = () => {
    return values.standardUnit ?? values.standardunit;
  };

  const formatListPrice = e => {
    const formattedListPrice = parseFloat(e.target.value).toFixed(2);
    setFieldValue(
      'averagePricePerUnit',
      isNaN(formattedListPrice) ? '' : formattedListPrice
    );
  };

  const handleInputChange = (e, fieldValue) =>
    setFieldValue(fieldValue, e.target.value);

  const handleSelectChange = (e, fieldValue) =>
    setFieldValue(fieldValue, JSON.parse(e.target.value).key);

  const handleProductTypeChange = e => {
    handleSelectChange(e, 'type');
    if (setSchema) {
      setSchema(JSON.parse(e.target.value).key);
    }
  };

  useEffect(() => {
    if (!product.isNew) {
      setValues({
        ...product,
        densityInLbsPerGallon: convertDensityFromKgPerLToLbsPerGal(
          product.density
        )
      });
    }
  }, [setValues, product]);

  useEffect(() => {
    if (!values.formulation) {
      setFieldValue('formulation', {});
    }

    if (values.type === 'SERVICE') {
      setUnitList(serviceUnits);
    }
    if (values.type === 'FERTILIZER') {
      switch (values.makeUp) {
        case 'SOLID':
          setUnitList(fertilizerSolidStandardUnitsList);
          setDensityLabel('');
          validateForm();
          break;
        case 'LIQUID':
          setUnitList(fertilizerLiquidStandardUnitsList);
          setDensityLabel('lb/gal');
          validateForm();

          break;
        default:
          setUnitList(fertCombinedUnits);
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values.type, values.formulation, values.makeUp]);

  useEffect(() => {
    if (!values?.densityInLbsPerGallon) {
      setFieldValue('density');
      setFieldTouched('density');
      validateField('density');
      return;
    }
    setFieldValue(
      'density',
      convertDensityFromLbsPerGalToKgPerL(values.densityInLbsPerGallon)
    );
    setFieldTouched('density');
    validateField('density');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values?.densityInLbsPerGallon, setFieldValue]);

  return (
    <div>
      <div>
        <Col>
          <Row className="mb-6 text-neutral-60">
            <span> Need a custom crop protection or seed product?</span>
            <a
              className="pl-2 text-neutral-60"
              style={{ color: 'blue', textDecoration: 'underline' }}
              href="https://www.syngentadigital.com/us/product-request-form"
              target="_blank"
              rel="noreferrer"
            >
              Request here.
            </a>
          </Row>
        </Col>
      </div>
      <div>
        {' '}
        <Row className={formRowClass} gutter={32}>
          <Col span={12}>
            <div className={formLabelClass}>
              Product Type<div className="text-remove">*</div>
            </div>
            <GrowUIFormField
              control={Select}
              name="type"
              value={values.type ?? values.type}
              onChange={handleProductTypeChange}
              items={[
                {
                  key: 'FERTILIZER',
                  value: 'Fertilizer'
                },
                {
                  key: 'SERVICE',
                  value: 'Service'
                }
              ]}
            />
          </Col>
        </Row>
        <Row className={formRowClass} gutter={32}>
          <Col span={12}>
            <div className={formLabelClass}>
              Product Name<div className="text-remove">*</div>
            </div>

            <GrowUIFormField
              control={Input}
              name="name"
              value={values.name}
              onChange={e => handleInputChange(e, 'name')}
            />
          </Col>
          <Col span={12}>
            <div className={formLabelClass}>
              Manufacturer<div className="text-remove">*</div>
            </div>

            <GrowUIFormField
              control={Input}
              name="manufacturer"
              value={values.manufacturer}
              onChange={e => handleInputChange(e, 'manufacturer')}
            />
          </Col>
        </Row>
        {values.type === 'FERTILIZER' && (
          <Row className={formRowClass} gutter={32}>
            <Col span={12}>
              <div className={formLabelClass}>
                Make-Up<div className="text-remove">*</div>
              </div>
              <GrowUIFormField
                control={Select}
                name="makeUp"
                value={values.makeUp ?? values.makeup ?? 'Solid'}
                onChange={e => handleSelectChange(e, 'makeUp')}
                placeholder={values.makeUp ?? values.makeup ?? ''}
                items={[
                  { key: 'SOLID', value: 'Solid' },
                  { key: 'LIQUID', value: 'Liquid' }
                ]}
              />
            </Col>
            <Col span={12}>
              <div className={formLabelClass}>
                Density
                {values.makeUp === 'LIQUID' ? (
                  <div className="text-remove">*</div>
                ) : null}
              </div>
              <FormInputWithPrecisionDisplay
                id="densityInLbsPerGallon"
                fieldName="densityInLbsPerGallon"
                label={densityLabel}
                inputSuffix={densityLabel}
                onControlledChange={e =>
                  handleInputChange(e, 'densityInLbsPerGallon')
                }
              />
              <ErrorMessage
                name="density"
                component="div"
                className="text-remove text-wrap"
              />
            </Col>
          </Row>
        )}
        <Row className={formRowClass} gutter={32}>
          <Col span={12}>
            <div className={formLabelClass}>List Price</div>
            <GrowUIFormField
              style={{ borderRight: 'none', borderLeft: 'none' }}
              control={Input}
              name="averagePricePerUnit"
              value={
                values.averagePricePerUnit === 0
                  ? undefined
                  : values.averagePricePerUnit
              }
              onChange={e => handleInputChange(e, 'averagePricePerUnit')}
              onBlur={formatListPrice}
              attachLeft="$"
              attachRight={
                <div
                  style={{ backgroundColor: 'white', borderLeft: 'none' }}
                  className="flex w-fit mr-10px"
                >
                  per {getAbbreviatedUnit(values.stdUnit ?? values.stdunit)}
                  {handleValidationAlertIcon(errors, 'averagePricePerUnit')}
                </div>
              }
            />
          </Col>

          <Col span={12}>
            <div className={formLabelClass}>
              Standard Unit<div className="text-remove">*</div>
            </div>
            <GrowUIFormField
              control={Select}
              name="standardUnit"
              value={getStdUnit()}
              onChange={e => handleSelectChange(e, 'standardUnit')}
              placeholder={getStdUnit()}
              items={unitList}
            />
          </Col>
        </Row>
        {values.type === 'FERTILIZER' && (
          <>
            <Row className="font-bold text-lg  mb-6">Major</Row>
            <Row gutter={32} className={formRowClass}>
              <Col span={6}>
                <FormulationInput fieldValue="N" />
              </Col>
              <Col span={6}>
                <FormulationInput fieldValue="P" />
              </Col>
              <Col span={6}>
                <FormulationInput fieldValue="K" />
              </Col>
            </Row>
            <Row className="font-bold text-lg  mb-6">Minor</Row>
            <Row gutter={32} className={formRowClass}>
              <Col span={6}>
                <FormulationInput fieldValue="Ca" />
              </Col>
              <Col span={6}>
                <FormulationInput fieldValue="Mg" />
              </Col>
              <Col span={6}>
                <FormulationInput fieldValue="S" />
              </Col>
            </Row>
            <Row className="font-bold text-lg  mb-6">Micro</Row>
            <Row
              gutter={32}
              style={{ flexFlow: 'nowrap' }}
              className={formRowClass}
            >
              <Col span={6}>
                <FormulationInput fieldValue="Fe" />
              </Col>
              <Col span={6}>
                <FormulationInput fieldValue="Cu" />
              </Col>
              <Col span={6}>
                <FormulationInput fieldValue="Mn" />
              </Col>
              <Col span={6}>
                <FormulationInput fieldValue="Zn" />
              </Col>
            </Row>
            <Row gutter={32} className={formRowClass}>
              <Col span={6}>
                <FormulationInput fieldValue="B" />
              </Col>
              <Col span={6}>
                <FormulationInput fieldValue="Mo" />
              </Col>
              <Col span={6}>
                <FormulationInput fieldValue="Cl" />
              </Col>
            </Row>
          </>
        )}
      </div>
    </div>
  );
};

CustomProductListForm.defaultProps = {
  setSchema: () => null
};

CustomProductListForm.propTypes = {
  product: PropTypes.shape().isRequired,
  setSchema: PropTypes.func
};

export default CustomProductListForm;
