/* eslint-disable no-unused-vars */
import { getLocalStorageApi, STORAGE_KEYS } from 'utilities/localStorage';

const defaultOrgLocalStorage = getLocalStorageApi(STORAGE_KEYS.DEFAULT_ORG, {
  id: process.env.REACT_APP_DEVTEST_ORGANIZATIONID
});

const selectedOrg = localStorage.getItem('selectedOrganizationId');

const blankUser = {
  id: '',
  name: '',
  email: '',
  phone: '',
  address: {
    line1: '',
    line2: '',
    city: '',
    state: '',
    zipCode: '',
    country: '',
    addressId: ''
  },
  applicatorLicenseNumber: '',
  applicatorLicenseExpirationDateEpoch: '',
  applicatorLicenseExpirationDate: '',
  notes: ''
};

export default blankUser;
