/* eslint-disable import/prefer-default-export */

export const isNotMatched = (matches, entity) =>
  matches.findIndex(m => m.vendorEntityId === entity.externalId) === -1;

export const checkMatchesDuplicates = (matches, entitiesToMatch) => {
  let canSubmit = true;

  const cwfIds = matches
    .filter(
      m =>
        entitiesToMatch.findIndex(
          entity => entity.externalId === m.vendorEntityId
        ) >= 0
    )
    .map(match => match.cwfEntityId);
  const uniqueCwfIds = [...new Set(cwfIds)];
  const checkDuplicates = uniqueCwfIds.map(value => [
    value,
    cwfIds.filter(id => id === value).length
  ]);

  checkDuplicates.forEach(check => {
    if (check[1] > 1) {
      canSubmit = false;
    }
  });

  return canSubmit;
};
