import React, { useContext } from 'react';
import { Spinner, Table } from '@agconnections/grow-ui';
import StorageLocationItemRow from './components/StorageLocationItemRow';
import { StorageLocationContext } from '../../context/StorageLocationProvider';

const StorageLocationList = () => {
  const {
    state: { data, isLoading, selectedId },
    selectLocation
  } = useContext(StorageLocationContext);

  return (
    <div
      data-testid="yield-storage-locations-list"
      className="h-full bg-white overflow-y-auto"
    >
      {isLoading ? (
        <Spinner />
      ) : (
        <>
          {!data.length ? (
            <p className="p-4 text-center">No storage locations found.</p>
          ) : (
            <Table>
              {data.map(item => (
                <StorageLocationItemRow
                  key={item.id}
                  item={item}
                  isSelected={selectedId === item.id}
                  onClick={selectLocation}
                />
              ))}
            </Table>
          )}
        </>
      )}
    </div>
  );
};

export default StorageLocationList;
