import { YieldIcon } from 'routes/icons';
import YieldParentProvider from 'screens/Yield/components/YieldParentProvider';
import { paths } from 'routes/paths';
import StorageLocations from 'screens/YieldV2/StorageLocations';
import CreateSaleLocation from 'screens/YieldV2/SaleLocations/components/CreateSaleLocation';
import EditSaleLocation from 'screens/YieldV2/SaleLocations/components/EditSaleLocation';
import CommoditySummary from 'screens/YieldV2/CommoditySummary/index';
import AddYield from 'screens/YieldV2/AddYield';
import EditYield from 'screens/YieldV2/EditYield';
import Loads from 'screens/YieldV2/Loads';
import ParentProviderSaleLocations from 'screens/YieldV2/SaleLocations/components/ParentProviderSaleLocations';
import LoadDetails from 'screens/YieldV2/LoadDetails';

const routes = [
  {
    id: 'yield',
    key: 'yieldV2',
    label: 'YieldV2',
    path: paths.yieldV2,
    exact: true,
    secure: true,
    disabled: false,
    icon: YieldIcon,
    nav: 'leftNav',
    subRoutes: [
      {
        id: 'commodity-summary',
        key: 'commodity-summary',
        label: 'Commodity Summary',
        path: `${paths.yieldV2}/commodity-summary`,
        exact: true,
        secure: true,
        disabled: false
      },
      {
        id: 'loads',
        key: 'loads',
        label: 'Loads',
        path: `${paths.yieldV2}/loads`,
        exact: true,
        secure: true,
        disabled: false
      },
      {
        id: 'storage-locations',
        key: 'storage-locations',
        label: 'Storage Locations',
        path: `${paths.yieldV2}/storage-locations`,
        exact: true,
        secure: true,
        disabled: false
      },
      {
        id: 'sale-locations',
        key: 'sale-locations',
        label: 'Sale Locations',
        path: `${paths.yieldV2}/sale-locations`,
        exact: true,
        secure: true,
        disabled: false
      }
    ]
  },
  {
    id: 'commodity-summary',
    key: 'commodity-summary',
    label: '',
    path: `${paths.yieldV2}/commodity-summary`,
    exact: true,
    secure: true,
    disabled: true,
    component: CommoditySummary
  },
  {
    id: 'create-yield',
    key: 'create-yield',
    label: '',
    path: `${paths.yield}/create`,
    exact: true,
    secure: true,
    disabled: true
  },
  {
    id: 'sale-locations',
    key: 'sale-locations',
    label: '',
    path: `${paths.yieldV2}/sale-locations`,
    exact: true,
    secure: true,
    disabled: true,
    component: ParentProviderSaleLocations
  },
  {
    id: 'create-sale-location',
    key: 'create-sale-locations',
    label: '',
    path: `${paths.yieldV2}/sale-locations/create`,
    exact: true,
    secure: true,
    disabled: true,
    component: CreateSaleLocation
  },
  {
    id: 'edit-sale-location',
    key: 'edit-sale-locations',
    label: '',
    path: `${paths.yieldV2}/sale-locations/:id/edit`,
    exact: true,
    secure: true,
    disabled: true,
    component: EditSaleLocation
  },
  {
    id: 'yieldV2-storage-locations',
    key: 'storage-locations',
    label: 'Storage',
    path: `${paths.yieldV2}/storage-locations`,
    exact: true,
    secure: true,
    disabled: false,
    component: StorageLocations
  },
  {
    id: 'create-storage-location',
    key: 'create-storage-locations',
    label: 'Storage',
    path: `${paths.yieldV2}/storage-locations/:action(create|edit)/:locationId?`,
    exact: true,
    secure: true,
    disabled: false,
    component: StorageLocations
  },
  {
    id: 'edit-yield',
    key: 'edit-yield',
    label: '',
    path: `${paths.yield}/:id/edit`,
    exact: true,
    secure: true,
    disabled: true,
    component: YieldParentProvider
  },
  {
    id: 'loads',
    key: 'loads',
    label: 'Loads',
    path: `${paths.yieldV2}/loads`,
    exact: true,
    secure: true,
    disabled: false,
    component: Loads
  },
  {
    id: 'load-details',
    key: 'load-details',
    label: 'Load Details',
    path: `${paths.yieldV2}/loads/:id/view`,
    exact: true,
    secure: true,
    disabled: false,
    component: LoadDetails
  },
  {
    id: 'add-yield',
    key: 'add-yield',
    label: '',
    path: `${paths.yieldV2}/create`,
    exact: true,
    secure: true,
    disabled: false,
    component: AddYield
  },
  {
    id: 'edit-yield-load',
    key: 'edit-yield-load',
    label: '',
    path: `${paths.yieldV2}/loads/:id/edit`,
    exact: true,
    secure: true,
    disabled: false,
    component: EditYield
  }
];

export default routes;
