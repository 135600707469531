import React from 'react';
import ProfileButton from 'components/ProfileButton';
import HelpButton from 'components/HelpButton';
import MaintenanceMessage from 'components/MaintenanceMessage';
import { useFlags } from 'launchdarkly-react-client-sdk';
import BreadcrumbContainer from '../BreadcrumbContainer';

const TopNavigation = () => {
  const { feLandManagementUpcomingMaintenanceMessaging } = useFlags();

  return (
    <div className="flex flex-shrink-0 px-4 bg-white border-b border-neutral-20">
      <div className="flex justify-between w-full py-3px ml-0 flex-shrink-1">
        <div className="flex w-5/6">
          <BreadcrumbContainer />
        </div>
        <div className="flex items-center ml-4 md:ml-6">
          {feLandManagementUpcomingMaintenanceMessaging !== '' && (
            <MaintenanceMessage
              message={feLandManagementUpcomingMaintenanceMessaging}
            />
          )}
          <HelpButton />
          <div className="ml-3 ">
            <ProfileButton />
          </div>
        </div>
      </div>
    </div>
  );
};

export default TopNavigation;
