import { isNil, set } from 'lodash';
import { getAccessToken } from '../base-auth';

const setAuthorizationHeader = (interceptors, reqConfig) => {
  if (!reqConfig?.headers?.Authorization) {
    set(
      interceptors,
      'request.config.headers.Authorization',
      `Bearer ${getAccessToken()}`
    );
  }
};

export const getCwfContext = (
  interceptors,
  cwfContextRegExpExclusions = [],
  url
) => {
  const contextValue = interceptors.request.contextCallback
    ? interceptors.request.contextCallback()
    : '';
  return cwfContextRegExpExclusions.find(exclusion => exclusion.test(url))
    ? null
    : contextValue;
};

const getCwfContextHeader = (
  interceptors,
  cwfContextRegExpExclusions,
  reqConfig
) => {
  const contextValue = getCwfContext(
    interceptors,
    cwfContextRegExpExclusions,
    reqConfig.url
  );
  return contextValue
    ? {
        'cwf-context': contextValue
      }
    : undefined;
};

const createNewReqConfig = (
  reqConfig,
  interceptorsConfig,
  cwfContextHeader
) => {
  // combine the cwf-context with all the other headers
  const headers = {
    ...(reqConfig?.headers ?? {}),
    ...(interceptorsConfig?.headers ?? {}),
    ...cwfContextHeader
  };
  return {
    ...reqConfig,
    ...interceptorsConfig,
    headers
  };
};

export const defineAxiosRequestInterceptor = (
  axiosContext,
  axiosInstanceKey,
  cwfContextRegExpExclusions = [],
  customCwfContext = false
) => {
  const { interceptors } = axiosContext;
  const axiosInstance = axiosContext.instances[axiosInstanceKey];
  if (isNil(axiosInstance.interceptors)) {
    axiosInstance.interceptors = {
      request: axiosInstance.api.interceptors.request.use(
        reqConfig => {
          setAuthorizationHeader(interceptors, reqConfig);
          const cwfContextHeader = getCwfContextHeader(
            interceptors,
            cwfContextRegExpExclusions,
            reqConfig
          );
          return createNewReqConfig(
            reqConfig,
            interceptors.request.config,
            customCwfContext ? undefined : cwfContextHeader
          );
        },
        err => Promise.reject(err)
      )
    };
  }
};

export default defineAxiosRequestInterceptor;
