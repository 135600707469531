import React from 'react';
import PropTypes from 'prop-types';

const OpenInNewIcon = ({ fill }) => (
  <svg
    width="18"
    height="18"
    viewBox="0 0 18 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M3.75 3.75V14.25H14.25V9H15.75V14.25C15.75 15.075 15.075 15.75 14.25 15.75H3.75C2.9175 15.75 2.25 15.075 2.25 14.25V3.75C2.25 2.925 2.9175 2.25 3.75 2.25H9V3.75H3.75ZM10.5 3.75V2.25H15.75V7.5H14.25V4.8075L6.8775 12.18L5.82 11.1225L13.1925 3.75H10.5Z"
      fill={fill}
    />
  </svg>
);

OpenInNewIcon.defaultProps = {
  fill: '#696F88'
};

OpenInNewIcon.propTypes = {
  fill: PropTypes.string
};

export default OpenInNewIcon;
