import React from 'react';
import { Form, Input } from '@agconnections/grow-ui';
import GrowUIFormField from 'components/Forms/GrowUIFormField';

const NameField = () => {
  return (
    <Form.Field label="Name *">
      <GrowUIFormField control={Input} id="name" name="name" />
    </Form.Field>
  );
};

export default NameField;
