/* istanbul ignore file */
import { Factory } from 'miragejs';
import globalSchema from '../utils/globalSchema';
import mapImage from '../../assets/vrmap 1.png';

const Imagery = {
  url: mapImage,
  alt: 'map of a farm',
  ...globalSchema
};

export default Factory.extend(Imagery);
