// eslint-disable-next-line import/prefer-default-export
export const paths = {
  welcome: '/app/welcome',
  organizations: '/app/organizations',
  properties: '/app/property-landing',
  cropSeasons: '/app/crop-seasons',
  plans: '/app/plans',
  recommendations: '/app/recommendations',
  tasks: '/app/tasks',
  invoices: '/app/invoices',
  // former inventory
  inventory: '/app/inventory',
  products: '/app/products',
  people: '/app/people',
  companies: '/app/companies',
  equipment: '/app/equipment',
  integrations: '/app/integrations',
  reports: '/app/reports',
  yield: '/app/yieldV1',
  yieldV2: '/app/yieldV2',
  integrationCropZoneMatcher: '/app/integrations/crop-zone-matcher'
};
