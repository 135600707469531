import React from 'react';
import PropTypes from 'prop-types';
import { GeoJSONLayer } from 'react-mapbox-gl';

const SelectedLayer = ({ geoJSON, onClickShape }) => (
  <GeoJSONLayer
    fillOnClick={onClickShape}
    data={geoJSON}
    fillPaint={{
      'fill-color': 'rgba(49, 180, 242, 0.4)',
      'fill-outline-color': 'rgba(49, 180, 242, 1)',
      'fill-antialias': true
    }}
  />
);

SelectedLayer.propTypes = {
  geoJSON: PropTypes.objectOf.isRequired,
  onClickShape: PropTypes.func.isRequired
};

export default SelectedLayer;
