import React, { useEffect, useState, useCallback } from 'react';
import { Input, Row, Col } from 'syngenta-digital-cropwise-react-ui-kit';
import { Field, useFormikContext } from 'formik';
import useLandingPageData from 'hooks/useLandingPageData';
import { Button } from '@agconnections/grow-ui';
import TruckAndDriverField from '../TruckAndDriverField';

const { TextArea } = Input;
const OtherSection = () => {
  const [equipment, setEquipment] = useState();
  const [drivers, setDrivers] = useState();
  const {
    items: equipments,
    loaded: isTruckLoaded,
    setReload
  } = useLandingPageData('equipments', ['name', 'equipmentType'], 'name', 0);
  const { items: people, loaded: isDriverLoaded } = useLandingPageData(
    'worker/people',
    ['contactInfo.name.givenName', 'contactInfo.name.surname'],
    'contactInfo.name.givenName',
    0
  );

  const { values } = useFormikContext();
  const truckSortingHandler = useCallback(() => {
    const truckArray = equipments
      ?.filter(item => item?.type === 'TRUCK')
      .sort((a, b) => a.name.localeCompare(b.name));
    const notTruckArray = equipments
      ?.filter(item => item?.type !== 'TRUCK')
      .sort((a, b) => a.name.localeCompare(b.name));
    const combinedArrays = [...truckArray, ...notTruckArray];
    setEquipment(combinedArrays);
  }, [equipments]);

  const driversSortingHandler = useCallback(() => {
    const sortedPeople = [...people].sort((a, b) =>
      a.name.localeCompare(b.name)
    );
    setDrivers(sortedPeople);
  }, [people]);
  useEffect(() => {
    setReload(true);
    truckSortingHandler();
    driversSortingHandler();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [truckSortingHandler, driversSortingHandler]);
  return (
    <div>
      <div className="flex flex-col gap-4">
        <h1 className="text-xl leading-7 font-semibold tracking-tight">
          Other
        </h1>
        <Row gutter={[24, 24]} prefixCls="syt-antd-row">
          <Col xs={24} sm={24} md={12} lg={12} xl={8} prefixCls="syt-antd-col">
            <div className="flex flex-col gap-0.5">
              <label
                htmlFor="truck-select"
                className="text-neutral-60 tracking-tighter text-sm"
              >
                Truck
              </label>
              {!isTruckLoaded ? (
                <Button
                  style={{ width: '100%' }}
                  type="outline"
                  ghost
                  loading
                />
              ) : (
                <TruckAndDriverField
                  data={equipment}
                  initialValue={equipments.find(
                    item => item.id === values.truckId
                  )}
                  resetValue={values.TruckAndDriverFieldResetValue}
                  isEquipment
                />
              )}
            </div>
          </Col>
          <Col xs={24} sm={24} md={12} lg={12} xl={8} prefixCls="syt-antd-col">
            <div className="flex flex-col gap-0.5">
              <label
                htmlFor="driver-select"
                className="text-neutral-60 tracking-tighter text-sm"
              >
                Driver
              </label>
              {!isDriverLoaded ? (
                <Button
                  style={{ width: '100%' }}
                  type="outline"
                  ghost
                  loading
                />
              ) : (
                <TruckAndDriverField
                  data={drivers}
                  initialValue={people.find(
                    item => item.id === values.driverId
                  )}
                  resetValue={values.TruckAndDriverFieldResetValue}
                />
              )}
            </div>
          </Col>
        </Row>
      </div>
      <div className="mt-8">
        <div className="w-full flex flex-col gap-0.5">
          <label
            htmlFor="notes"
            className="text-neutral-60 tracking-tighter text-sm"
          >
            Notes
          </label>
          <Field
            as={TextArea}
            id="notes"
            autoSize={{ minRows: 2 }}
            name="notes"
            size="middle"
          />
        </div>
      </div>
    </div>
  );
};
export default OtherSection;
