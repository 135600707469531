import React from 'react';
import PropTypes from 'prop-types';
import { Radio } from '@agconnections/grow-ui';

const FumigationDropDownItem = ({ item }) => {
  const { value: itemValue, key, label, checked } = item;
  if (!itemValue) {
    return (
      <div
        className="text-neutral-60 pl-2"
        key={label}
        data-testid="item-without-value"
      >
        {label}
      </div>
    );
  }
  return (
    <div
      key={key}
      className="w-full flex items-center m-2 pl-4 text-xs"
      data-testid="item-with-value"
    >
      <Radio
        ariaLabel={label}
        id={`${label}-yes`}
        name={label}
        checked={checked}
        value={label}
        label={label}
      />
    </div>
  );
};

FumigationDropDownItem.propTypes = {
  item: PropTypes.shape({
    value: PropTypes.string,
    key: PropTypes.string,
    label: PropTypes.string,
    checked: PropTypes.string
  }).isRequired
};

export default FumigationDropDownItem;
