import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { isNil, noop } from 'lodash';
import { AttachFile, ClearSharp } from '@material-ui/icons';

import { Checkbox, Table } from '@agconnections/grow-ui';

import RoundedDisplay from 'components/RoundedDisplay';

import FieldIcon from '../../../../../../../components/Icons/FieldIcon';
import {
  parsePropertyInfoFromFeatureCollection,
  selectedItemAtIndex,
  calculateSelectedChanges,
  createRowGroups
} from '../../../../../helpers/fieldDataHelpers';
import { calculateFeaturesTotalAreaInAcres } from '../../../../../helpers/propertyDataHelpers';

const mapFieldsToRows = (items, _depth = 0) =>
  !items?.length
    ? []
    : items.flatMap((item, index) => {
        const newRow = {
          ...item,
          $depth: _depth,
          $acres: calculateFeaturesTotalAreaInAcres(item.features),
          parentReference: _depth === 0 ? 0 : index + 1
        };
        if (item.items?.length)
          return [newRow].concat(mapFieldsToRows(item.items, _depth + 1));
        return newRow;
      });

// eslint-disable-next-line react/prop-types
const FieldCard = ({ name, farmName, features, index }) => {
  return (
    <div className="ml-2 flex">
      <FieldIcon
        id={`import-field-icon-${index}`}
        features={features}
        color="#696F88"
      />
      <div className="flex flex-col justify-center ml-2">
        <div className="text-neutral-1000">
          {name || `Field info not available`}
        </div>
        {farmName && <div style={{ color: '#707374' }}>{farmName}</div>}
      </div>
    </div>
  );
};

// eslint-disable-next-line react/prop-types
const CropZoneCard = ({ name, features, index }) => {
  return (
    <div className="ml-2 flex">
      <FieldIcon
        id={`import-crop-zone-icon-${index}`}
        features={features}
        color="#CBCDD5"
      />
      <div className="flex flex-col justify-center ml-2 text-neutral-1000">
        {name || `Crop zone info not available`}
      </div>
    </div>
  );
};

const PropertySelector = ({ geoJson, fileName, onSelect, onCancel }) => {
  const [rowStates, setRowStates] = useState();
  const [rows, setRows] = useState([]);
  const propertyOptionsRef = useRef(
    parsePropertyInfoFromFeatureCollection(geoJson)
  );

  useEffect(() => {
    setRows(mapFieldsToRows(propertyOptionsRef.current));
  }, []);

  // select the parent rows of selected children, and the child rows of selected parents
  useEffect(() => {
    if (!rowStates) {
      return;
    }
    const parentCrossReference = rows
      .map((row, index) => [
        index,
        row.parentReference ? index - row.parentReference : null
      ])
      .filter(([, parentIndex]) => !isNil(parentIndex));
    setRowStates(previousRowStates => {
      const changes = calculateSelectedChanges(
        parentCrossReference,
        previousRowStates
      );
      if (!changes.length || !previousRowStates) {
        return previousRowStates;
      }
      return previousRowStates
        .map(({ index, checked }) => ({ index, checked }))
        .filter(
          ({ index: stateIndex }) =>
            !changes.find(selectedItemAtIndex(stateIndex))
        )
        .concat(changes);
    });
  }, [rows, rowStates]);

  useEffect(() => {
    if (!isNil(rowStates)) {
      const selections = rowStates
        .filter(({ checked }) => checked)
        .map(({ index }) => rows[index])
        .filter(({ type }) => type === 'cropZone')
        .flatMap(({ features }) => features);
      onSelect(selections);
    }
  }, [onSelect, rows, rowStates]);

  const handleSelect = (idx, evt) => {
    setRowStates((previousRowStates = []) => {
      if (previousRowStates.find(selectedItemAtIndex(idx))) {
        return previousRowStates.map(({ index, checked, event }) => ({
          index,
          event: index === idx ? evt : event,
          checked: index === idx ? !checked : checked
        }));
      }
      return previousRowStates.concat([
        { index: idx, event: evt, checked: true }
      ]);
    });
  };

  const rowGroups = createRowGroups(rows);

  return (
    <>
      <div
        className="flex items-center justify-between"
        style={{ backgroundColor: '#EAF6FF', color: '#0071CD' }}
      >
        <div>
          <AttachFile
            fontSize="small"
            className="text-neutral-600"
            style={{ height: 16 }}
          />
          {fileName}
        </div>
        <button type="button" onClick={onCancel}>
          <ClearSharp
            fontSize="small"
            className="mx-2 text-neutral-600"
            style={{ height: 12 }}
          />
        </button>
      </div>
      <div className="overflow-auto my-8" style={{ maxHeight: '36rem' }}>
        <Table selectable={false} onSelect={noop}>
          {rowGroups.map(groupRows => (
            <Table.RowGroup>
              {groupRows.map(
                ([
                  parentIndex,
                  childIndex,
                  { name, farmName, type, features, $depth, $acres }
                ]) => {
                  return (
                    <Table.Row
                      key={`${type}${name}${parentIndex}${childIndex}`}
                    >
                      <div
                        className="flex flex-1 justify-between items-center p-2 text-gray-600 hover:bg-gray-100 focus:outline-none"
                        data-testid="land-selector-row-item"
                      >
                        <div className="flex flex-1 items-center ml-2">
                          {Array.from({ length: $depth }, (el, index) => (
                            // eslint-disable-next-line react/no-array-index-key
                            <div key={`tab-${index}`} className="mx-2" />
                          ))}
                          <Checkbox
                            checked={
                              !!rowStates?.find(
                                ({ index, checked }) =>
                                  index === childIndex && checked
                              )
                            }
                            onChange={event => handleSelect(childIndex, event)}
                          />
                          {type === 'field' && (
                            <FieldCard
                              name={name}
                              farmName={farmName}
                              features={features}
                              index={childIndex}
                            />
                          )}
                          {type === 'cropZone' && (
                            <CropZoneCard
                              name={name}
                              features={features}
                              index={childIndex}
                            />
                          )}
                        </div>
                        <div className="flex flex-col justify-center">
                          <RoundedDisplay units="ac.">{$acres}</RoundedDisplay>
                        </div>
                      </div>
                    </Table.Row>
                  );
                }
              )}
            </Table.RowGroup>
          ))}
        </Table>
      </div>
    </>
  );
};

PropertySelector.defaultProps = {
  geoJson: null
};

PropertySelector.propTypes = {
  geoJson: PropTypes.shape({
    features: PropTypes.arrayOf(PropTypes.object).isRequired
  }),
  fileName: PropTypes.string.isRequired,
  onSelect: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired
};

export default PropertySelector;
