/* eslint-disable import/prefer-default-export */
const common = [
  {
    field: 'applicationRate',
    title: 'Application Rate',
    sortable: true,
    width: 'calc((100% - 85px) / 6)',
    position: 'right'
  },
  {
    field: 'costPerAcre',
    title: 'Cost per Acre',
    sortable: true,
    width: 'calc((100% - 85px) / 6)',
    position: 'right'
  },
  {
    field: 'totalCost', // check the correct property with backend
    title: 'Total Cost',
    sortable: true,
    width: 'calc((100% - 85px) / 6)',
    position: 'right'
  }
];

const field = [
  {
    field: 'productName',
    title: `Product Name`,
    sortable: true,
    width: 'calc((100% - 85px) / 6)'
  },
  {
    field: 'dateApplied',
    title: 'Date Applied',
    sortable: true,
    width: 'calc((100% - 85px) / 6)'
  },
  {
    field: 'totalAppliedArea',
    title: 'Applied Area',
    sortable: true,
    width: 'calc((100% - 85px) / 6)'
  },
  ...common,
  {
    field: 'view',
    title: '',
    sortable: false,
    width: '85px'
  }
];

const cropzone = [
  {
    field: 'productName',
    title: `Product Name`,
    sortable: true,
    width: 'calc((100% - 85px) / 6)'
  },
  {
    field: 'dateApplied',
    title: 'Date Applied',
    sortable: true,
    width: 'calc((100% - 85px) / 6)'
  },
  {
    field: 'totalAppliedArea',
    title: 'Applied Area',
    sortable: true,
    width: 'calc((100% - 85px) / 6)',
    position: 'right'
  },
  ...common,
  {
    field: 'view',
    title: '',
    sortable: false,
    width: '85px'
  }
];

const defaultSort = {
  cropzone: {
    dir: ['asc', 'asc'],
    by: ['dateApplied', 'productName']
  },
  field: {
    dir: ['asc', 'asc'],
    by: ['dateApplied', 'productName']
  }
};

export const APPLIED_PRODUCTS_TABLE = {
  columns: {
    field,
    cropzone
  },
  defaultSort
};
