import React from 'react';
import CropIconMapping from 'components/CropIconMapping';
import CloseButton from 'assets/close.svg';
import PropTypes from 'prop-types';
import { Spin } from 'syngenta-digital-cropwise-react-ui-kit';

export default function RenderTag({
  value,
  onClose,
  cropsOfSelectedSeasons,
  isGlobalCropSeasonLoading
}) {
  const option = cropsOfSelectedSeasons.find(o => o.id === value);
  return (
    <div
      style={{
        display: 'flex',
        alignItems: 'center',
        marginRight: 8
      }}
    >
      {isGlobalCropSeasonLoading ? (
        <div
          data-testid="rendertag-loading"
          className="flex items-center px-1 rounded border border-neutral-100 bg-neutral-100"
        >
          <Spin size="small" />
        </div>
      ) : (
        <div
          data-testid="render-tag"
          className="flex items-center px-1 pl-2 gap-1 rounded border border-neutral-100 bg-neutral-100"
        >
          <CropIconMapping cropObject={option} size={14} />
          <div className="text-neutral-60 font-body text-xs font-semibold">
            {option?.name}
          </div>
          <button type="button" onClick={onClose}>
            <img src={CloseButton} alt="close button" width={16} height={16} />
          </button>
        </div>
      )}
    </div>
  );
}

RenderTag.defaultProps = {
  onClose: () => {},
  cropsOfSelectedSeasons: [],
  isGlobalCropSeasonLoading: false,
  value: []
};

RenderTag.propTypes = {
  value: PropTypes.string,
  onClose: PropTypes.func,
  cropsOfSelectedSeasons: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      name: PropTypes.string
    })
  ),
  isGlobalCropSeasonLoading: PropTypes.bool
};
