import { Factory } from 'miragejs';
import globalSchema from '../utils/globalSchema';

const Tag = {
  tagType() {
    return 'farm';
  },
  tagValue(i) {
    return `Farm ${i}`;
  },
  ...globalSchema
};

export default Factory.extend(Tag);
