import React from 'react';
import PropTypes from 'prop-types';

import TileListGroupHeading from 'components/TileListGroupHeading';

const TileGroup = ({ children, title, totalItems, testId }) => {
  return (
    <div data-testid={`${testId}-group-container`} id={`${testId}-group`}>
      <TileListGroupHeading testId={`${testId}-group-title`}>
        {title} ({totalItems})
      </TileListGroupHeading>
      <div data-testid={`${testId}-group-body`} className="flex flex-wrap">
        {children}
      </div>
    </div>
  );
};

TileGroup.propTypes = {
  children: PropTypes.node,
  title: PropTypes.string,
  totalItems: PropTypes.number,
  testId: PropTypes.string
};

TileGroup.defaultProps = {
  children: null,
  title: '',
  totalItems: 0,
  testId: 'default'
};

export default TileGroup;
