import { cloneDeep, orderBy } from 'lodash';

export default (plans, field, direction) => {
  if (!direction) return plans;

  const _plans = plans?.map(plan => cloneDeep(plan));

  switch (field) {
    case 'name':
      return orderBy(_plans, [plan => plan?.name.toLowerCase()], direction);
    case 'plannedArea':
      return orderBy(_plans, [plan => plan?.estimatedAreaValue], direction);
    case 'crop':
      return orderBy(_plans, [plan => plan?.crop?.name], direction);
    case 'fixedCostPerAcre':
      return orderBy(_plans, [plan => plan?.fixedCost], direction);
    case 'varCostPerAcre':
      return orderBy(_plans, [plan => plan?.variableCost], direction);
    case 'projRevPerAcre':
      return orderBy(_plans, [plan => plan?.projectRevenue], direction);
    case 'createdAt':
      return orderBy(_plans, [plan => plan?.createdOn], direction);
    default:
      return _plans;
  }
};
