import { useCallback, useContext, useState } from 'react';
import { agrianFields, simplotCropZones } from 'utilities/api';
import catchCancel from 'helpers/catchCancel';
import { Context } from 'components/Store';
import { parseServerError } from 'helpers/errorHelpers';
import matchApiByVendor from '../helpers/matchApiByVendor';

const vendorApiPropertiesList = {
  simplot: simplotCropZones,
  agrian: agrianFields,
  agrianrecommendation: agrianFields
};

const usePropertiesMatch = () => {
  const [loading, setLoading] = useState(false);
  const [, dispatch] = useContext(Context);

  const orgId = localStorage.getItem('selectedOrganizationId');

  const getOrganizationMatch = useCallback(
    vendor => {
      const matchHandler = matchApiByVendor[vendor.toLowerCase()];
      const api = matchHandler?.api;
      const { promise } = api.fetch(
        null,
        {
          types: 'Organization'
        },
        {
          headers: {
            'cwf-context': JSON.stringify({
              organization_id: orgId
            })
          }
        }
      );

      setLoading(true);

      return promise
        .then(res => res.data)
        .catch(catchCancel)
        .catch(parseServerError(dispatch))
        .finally(() => setLoading(false));
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [orgId]
  );

  const getPropertiesMatches = useCallback(
    vendor => {
      const matchHandler = matchApiByVendor[vendor.toLowerCase()];
      const api = matchHandler?.api;
      const { promise } = api.fetch(
        null,
        {
          types: 'Organization,Farm,Field,CropZone'
        },
        {
          headers: {
            'cwf-context': JSON.stringify({
              organization_id: orgId
            })
          }
        }
      );

      setLoading(true);

      return promise
        .then(res => matchHandler.responseTranslator(res))
        .catch(catchCancel)
        .catch(parseServerError(dispatch))
        .finally(() => setLoading(false));
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [orgId]
  );

  const createMatches = useCallback(
    payload => {
      const matchApiHandler = matchApiByVendor[payload.vendor.toLowerCase()];
      const api = matchApiHandler?.api;
      const { promise } = api.create(
        matchApiHandler?.requestTranslator(payload.data),
        {
          headers: {
            'cwf-context': JSON.stringify({
              organization_id: orgId
            })
          }
        }
      );

      setLoading(true);

      return promise
        .then(response => response)
        .catch(catchCancel)
        .catch(parseServerError(dispatch))
        .finally(() => setLoading(false));
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [orgId]
  );

  const getVendorPropertyGeometry = useCallback(
    ({ vendor, vendorPropertyId }) => {
      const api = vendorApiPropertiesList[vendor.toLowerCase()];
      const { promise } = api.fetch(
        `${vendorPropertyId}/geometry`,
        {},
        {
          headers: {
            'cwf-context': JSON.stringify({
              organization_id: orgId
            })
          }
        }
      );

      setLoading(true);

      return promise
        .then(response => response)
        .catch(catchCancel)
        .catch(parseServerError(dispatch))
        .finally(() => setLoading(false));
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [orgId]
  );

  const getVendorProperties = useCallback(
    ({ vendor, cropYear }) => {
      const api = vendorApiPropertiesList[vendor.toLowerCase()];
      const { promise } = api.fetch(
        cropYear ?? '',
        {},
        {
          headers: {
            'cwf-context': JSON.stringify({
              organization_id: orgId
            })
          }
        }
      );

      setLoading(true);

      return promise
        .then(response => response)
        .catch(catchCancel)
        .catch(parseServerError(dispatch))
        .finally(() => setLoading(false));
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [orgId]
  );

  return {
    createMatches,
    getOrganizationMatch,
    getPropertiesMatches,
    getVendorProperties,
    getVendorPropertyGeometry,
    loading,
    setLoading
  };
};

export default usePropertiesMatch;
