import React, { useEffect, useState } from 'react';
import { useFormikContext } from 'formik';
import formatNumber from 'helpers/formatNumber';
import { getAbbreviatedUnit } from 'helpers/unitsAbbreviation';
import { tabsLabels } from '../../helpers/constants';
import useParameters from '../../hook/useParameters';
import PropertiesGrid from '../PropertiesGrid';

const WeightTab = () => {
  const { values } = useFormikContext();
  const { grossWeight, tareWeight, netWeight, weightUnit, sourceType } = values;
  const { removeAttributes } = useParameters(tabsLabels.weight, sourceType);

  const [properties, setProperties] = useState([]);

  useEffect(() => {
    setProperties([
      {
        name: 'grossWeight',
        value: formatNumber(grossWeight, getAbbreviatedUnit(weightUnit), 2)
      },
      {
        name: 'tareWeight',
        value: formatNumber(tareWeight, getAbbreviatedUnit(weightUnit), 2)
      },
      {
        name: 'netWeight',
        value: formatNumber(netWeight, getAbbreviatedUnit(weightUnit), 2)
      }
    ]);
  }, [grossWeight, weightUnit, tareWeight, netWeight]);
  return (
    <PropertiesGrid
      properties={properties}
      removeAttributes={removeAttributes}
      values={values}
    />
  );
};

export default WeightTab;
