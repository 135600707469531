import { set } from 'lodash';

const filterFeatureCollectionByParentIdNoZoom = (fc, parentId) =>
  fc?.features
    ? fc.features.map(feat => {
        set(
          feat,
          'properties.$layer',
          parentId === feat.properties?.id ||
            (parentId === feat.properties?.propertyId &&
              !feat.properties?.$parentDuplicated)
            ? 'selected'
            : 'default'
        );
        return feat;
      })
    : [];

export default filterFeatureCollectionByParentIdNoZoom;
