import React from 'react';
import PropTypes from 'prop-types';
import PropertyRow from 'screens/Property/PropertiesLanding/PropertyTable/PropertyRow';
import RenderValue from '../RenderValue';

export default function RenderValuePropertyRow({
  removeAttributes,
  label,
  value,
  ghostValue
}) {
  return (
    <RenderValue isRender={!removeAttributes?.includes(label)}>
      <PropertyRow label={label} value={value} ghostValue={ghostValue} />
    </RenderValue>
  );
}

RenderValuePropertyRow.propTypes = {
  removeAttributes: PropTypes.arrayOf(PropTypes.string).isRequired,
  label: PropTypes.string.isRequired,
  value: PropTypes.node,
  ghostValue: PropTypes.bool
};

RenderValuePropertyRow.defaultProps = {
  value: undefined,
  ghostValue: true
};
