import PropTypes from 'prop-types';
import { add } from 'date-fns';

export const userRoleStateProp = () => {
  PropTypes.shape({
    isAdmin: PropTypes.bool,
    isViewOnly: PropTypes.bool,
    isAuthor: PropTypes.bool,
    isFullControl: PropTypes.bool,
    financialAccess: PropTypes.string
  });
};

export const defaultUserRole = () => {
  return {
    isAdmin: false,
    isAuthor: false,
    isFullControl: false,
    isViewOnly: false,
    financialAccess: 'READ'
  };
};

export const newInvitation = organizationId => data => {
  return {
    organizationId,
    emailAddress: data.emailAddress,
    firstName: data.firstName,
    lastName: data.lastName,
    permissionLevel: data.permission,
    expiration: add(new Date(), { weeks: 1 }).toISOString()
  };
};

export const getUserRole = userRoleState => {
  let value = 'View Only';
  if (userRoleState.isFullControl) {
    value = 'Full Control';
  }
  if (userRoleState.isAdmin) {
    value = 'Admin';
  }
  if (userRoleState.isViewOnly) {
    value = 'View Only';
  }
  if (userRoleState.isAuthor) {
    value = 'Author';
  }
  return value;
};

export const getFinancial = buttonEnabledState => {
  if (buttonEnabledState.edit) {
    return 'WRITE';
  }
  if (buttonEnabledState.view) {
    return 'READ';
  }
  if (buttonEnabledState.noAccess) {
    return 'noAccess';
  }
  return 'noAccess';
};

export const getAvailableFinancialButtons = role => {
  const financialsForRole = {
    Admin: ['WRITE'],
    Author: ['WRITE', 'READ', 'none'],
    FullControl: ['WRITE'],
    // FullControl: ['WRITE', 'READ', 'none'],
    ViewOnly: ['READ']
    // ViewOnly: ['READ', 'none']
  };
  return financialsForRole[role] || [];
};

export const setUserRole = permission => {
  const userRoleState = defaultUserRole();
  switch (permission.role) {
    case 'fullControl':
      userRoleState.isFullControl = true;
      break;
    case 'admin':
      userRoleState.isAdmin = true;
      break;
    case 'viewOnly':
      userRoleState.isViewOnly = true;
      break;
    case 'author':
      userRoleState.isAuthor = true;
      break;
    default:
      userRoleState.isViewOnly = true;
  }
  return userRoleState;
};

export const getMemberRole = (role, selMember) => {
  const roleArr = role?.split(' ');
  if (role !== undefined) {
    if (roleArr?.length === 2 && role === 'Full control') {
      return (
        roleArr[0] +
        roleArr[1].charAt(0).toUpperCase() +
        roleArr[1].substring(1)
      );
    }
    if (roleArr?.length === 2 && role === 'View Only') {
      return roleArr[0] + roleArr[1];
    }
    if (role?.role === 'Admin') {
      return roleArr[0];
    }
  } else {
    const singleMemberRoleArr = selMember?.role?.split(' ');

    if (selMember?.role === 'Full control') {
      return (
        singleMemberRoleArr[0] +
        singleMemberRoleArr[1].charAt(0).toUpperCase() +
        singleMemberRoleArr[1].substring(1)
      );
    }
    if (selMember?.role === 'View Only') {
      return singleMemberRoleArr[0] + singleMemberRoleArr[1];
    }
    if (selMember?.role === 'Admin') {
      return singleMemberRoleArr[0];
    }
  }
  return '';
};

export const getFinancialAccess = (member, selMember) => {
  if (member?.memberStatus === 'Joined') {
    // Setting Initial Value for Admin
    if (member?.role === 'Admin') {
      return member?.financialAccess;
    }
    // Setting Initial Value for Full Control
    if (
      member?.role === 'Full control' &&
      member?.financialAccess === 'WRITE'
    ) {
      return member?.financialAccess;
    }
    if (member?.role === 'Full control' && member?.financialAccess === 'READ') {
      return member?.financialAccess;
    }
    if (
      member?.role === 'Full control' &&
      !Object.prototype.hasOwnProperty.call(member, 'financialAccess')
    ) {
      return 'none';
    }
    // Setting Initial Value for View Only
    if (member?.role === 'View Only' && member?.financialAccess === 'READ') {
      return member?.financialAccess;
    }
    if (
      member?.role === 'View Only' &&
      !Object.prototype.hasOwnProperty.call(member, 'financialAccess')
    ) {
      return 'none';
    }
  } else {
    if (selMember?.role === 'Full control' || selMember?.role === 'View Only') {
      return 'READ';
    }
    return 'WRITE';
  }
  return '';
};
