import faker from 'faker';
import { Factory } from 'miragejs';
import globalSchema from '../utils/globalSchema';

const Company = {
  addresses(i) {
    return [
      {
        address1: faker.address.streetAddress(),
        address2: i % 2 === 0 ? faker.address.secondaryAddress() : '',
        postalCode: faker.address.zipCode(),
        city: faker.address.city(),
        state: faker.address.stateAbbr(),
        country: faker.random.arrayElement([
          'United States',
          'Canada',
          'France'
        ]),
        addressType: faker.random.arrayElement([
          'shipping',
          'billing',
          'location'
        ])
      }
    ];
  },
  emails() {
    return [faker.internet.email(), faker.internet.email()];
  },
  phoneNumbers() {
    return [
      { phoneType: 'Home', name: 'The House', value: faker.phone.phoneNumber() }
    ];
  },
  legalName() {
    return faker.company.companyName();
  },
  commonName() {
    return faker.company.companyName();
  },
  notes() {
    return faker.lorem.paragraph();
  },
  flag() {
    return faker.random.arrayElement(['ethanol', 'diesel', 'test']);
  },
  ...globalSchema
};
export default Factory.extend(Company);
