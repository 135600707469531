export const footerTranslations = {
  pages: { english: 'Page', LATAMSpanish: 'Página' },
  copyright: {
    english: '© Copyright of Syngenta',
    LATAMSpanish: '© Derechos de autor de Syngenta'
  },
  generated: { english: 'generated', LATAMSpanish: 'generado' }
};

export default footerTranslations;
