/* eslint-disable react/forbid-prop-types */
import React from 'react';
import PropTypes from 'prop-types';
import { capitalize } from 'lodash';

import { Table } from '@agconnections/grow-ui';
import { format } from 'date-fns';

const PeopleTableRow = ({
  user: {
    id,
    name,
    email,
    permissionLevel,
    applicatorLicenseNumber,
    applicatorLicenseExpirationDateEpoch
  },
  menu,
  onAction: handleAction
}) => {
  const expDate = Date.parse(new Date(applicatorLicenseExpirationDateEpoch));
  const formattedDate = expDate ? format(expDate, 'MMM d, yyyy') : '';

  return (
    <Table.Row id={id}>
      <Table.Cell onClick={() => handleAction('view')}>
        <span className="font-bold">{name}</span>
      </Table.Cell>
      <Table.Cell>{email}</Table.Cell>
      <Table.Cell>{applicatorLicenseNumber}</Table.Cell>
      <Table.Cell>{applicatorLicenseNumber ? formattedDate : ''}</Table.Cell>
      <Table.Cell>
        {capitalize(permissionLevel)}
        <div className="absolute right-0 flex-none w-10 mr-2">{menu}</div>
      </Table.Cell>
    </Table.Row>
  );
};

PeopleTableRow.propTypes = {
  menu: PropTypes.object.isRequired,
  user: PropTypes.object.isRequired,
  onAction: PropTypes.func.isRequired
};

export default PeopleTableRow;
