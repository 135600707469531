import React from 'react';
import PropTypes from 'prop-types';
import { useHistory, useParams } from 'react-router-dom';
import { Button } from '@agconnections/grow-ui';
import { useFormikContext } from 'formik';
import { format } from 'date-fns';
import FormattedNumber from 'components/FormattedNumber';
import { paths } from 'routes/paths';

function TopHeader({ onSubmit, memberRole, memberFinancialAccess, isSaving }) {
  const { values } = useFormikContext();
  const history = useHistory();
  const { id } = useParams();
  const getDate = () => {
    if (values?.invoiceDate) {
      return format(new Date(values?.invoiceDate), 'MMM d, yyyy');
    }
    return 'Jan 02, 2020';
  };

  const formatNumber = value => {
    return (
      <FormattedNumber unit="usCurrency" roundedTo="2">
        {value || 0}
      </FormattedNumber>
    );
  };

  const getTotalProductCost = products => {
    let summation = 0;
    if (memberRole === 'Full control' && memberFinancialAccess === 'none') {
      return '';
    }
    if (
      memberRole === 'Admin' ||
      memberRole === 'View Only' ||
      (memberRole === 'Full control' && memberFinancialAccess === 'WRITE') ||
      (memberRole === 'Full control' &&
        memberFinancialAccess === 'READ' &&
        id !== 'invoice')
    ) {
      /* eslint no-unused-expressions: "off", curly: "error" */
      products?.forEach(product => {
        summation += Number(product?.totalCostValue || 0);
      });
      return formatNumber(summation);
    }
    if (
      memberRole === 'Full control' &&
      memberFinancialAccess === 'READ' &&
      id === 'invoice'
    ) {
      return '';
    }
    return '';
  };

  return (
    <div
      data-testid="invoice-top-header"
      className="flex items-center justify-between px-3"
    >
      <div className="flex justify-start">
        <div className="mr-12">
          <div className="text-sm text-gray-600">Invoice Date</div>
          <div className="text-2xl text-charcoal">{getDate()}</div>
        </div>
        <div className="mr-8">
          <div className="text-sm text-gray-600">Total Products</div>
          <div className="text-2xl text-charcoal">
            {values?.invoiceProducts?.length || 0}
          </div>
        </div>
        <div>
          <div className="text-sm text-gray-600">Total Cost</div>
          <div className="text-2xl text-charcoal">
            {getTotalProductCost(values?.invoiceProducts)}
          </div>
        </div>
      </div>
      <div className="flex justify-end flex-1">
        <div>
          <Button
            type="primary"
            ghost
            onClick={() => history.push(paths.invoices)}
            id="cancelInvoice"
          >
            Cancel
          </Button>
        </div>
        <div className="ml-4">
          <Button
            id="saveInvoice"
            type="primary"
            loading={isSaving}
            disabled={isSaving}
            onClick={onSubmit}
          >
            Save
          </Button>
        </div>
      </div>
    </div>
  );
}

TopHeader.defaultProps = {
  isSaving: false
};

TopHeader.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  memberRole: PropTypes.string.isRequired,
  memberFinancialAccess: PropTypes.string.isRequired,
  isSaving: PropTypes.bool
};

export default TopHeader;
