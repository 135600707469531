import React, { memo, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';
import { noop } from 'lodash';
import FieldIcon from 'components/Icons/FieldIcon';
import FormattedNumber from 'components/FormattedNumber';
import { mappingShape } from 'screens/Property/helpers/propertyDataHelpers';
import { paths } from 'routes/paths';
import ClickableDiv from 'components/ClickableDiv';
import { getCurArea } from '../../helpers/leftPanel';
import EditRowButton from '../EditRowButton';
import UnarchiveButton from '../UnarchiveButton';

const FieldRow = ({
  field,
  farm,
  properties,
  onSelect,
  isSelected,
  icon,
  hasEditAccess,
  showUnarchiveButton,
  onUnarchive,
  parentPage
}) => {
  const history = useHistory();
  const [fieldMapped, setFieldMapped] = useState(icon);
  const [showEditButton, setShowEditButton] = useState(false);
  const [isOnHover, setIsOnHover] = useState(false);

  useEffect(() => {
    if (icon) {
      setFieldMapped(mappingShape(icon));
    }
  }, [icon]);

  const handleClick = () => {
    onSelect(field);
    history.push(`${paths.properties}/edit/${field?.id}`, {
      parentPage
    });
  };

  const handleMouseEnter = () => {
    setShowEditButton(true);
    setIsOnHover(true);
  };

  const handleMouseLeave = () => {
    setShowEditButton(false);
    setIsOnHover(false);
  };

  const fieldIconColor = () => {
    if (field.archived) return '#DFE2E7';
    if (isSelected) return '#696F88';
    return '#868CA2';
  };

  const findField = () => {
    const farmName = farm.name;
    const fieldName = field.name;
    const _farm = properties.find(property => property.name === farmName);
    if (_farm) {
      return _farm.fields.find(f => f.name === fieldName);
    }
    return null;
  };

  const handleCropZoneCount = () => {
    const _field = findField();
    return _field ? _field.cropzones.length : 0;
  };

  const displayByCropData = () => {
    if (!properties) {
      return '';
    }
    const _field = findField();
    if (_field) {
      return `${field.cropzones.length} of ${handleCropZoneCount()} crop zone${
        handleCropZoneCount() === 1 ? '' : 's'
      }`;
    }
    return '';
  };

  const displayCropZoneData = () => {
    if (farm) {
      return '';
    }
    if (!field.cropzones.length) {
      return 'No crop zones';
    }
    return `${field.cropzones.length} crop zone${
      field.cropzones.length === 1 ? '' : 's'
    }`;
  };

  return (
    <ClickableDiv
      className="w-full py-3 pl-8 pr-1  text-left flex items-center justify-between"
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      onClick={() => onSelect(field)}
    >
      {isSelected && (
        <div className="absolute left-0 h-50px w-1 bg-blue-50 rounded-r" />
      )}
      <div data-testid={`field-row-${field.id}`} className="flex items-start">
        <FieldIcon
          id={field.id}
          features={[fieldMapped]}
          color={fieldIconColor()}
          className="flex-shrink-0"
        />
        <div className="ml-2">
          <p
            data-testid="archived-field-name"
            className={`font-medium leading-5 break-all ${
              field.archived ? 'text-gray-600' : 'text-neutral-1000'
            } ${isSelected ? 'font-semibold' : ''}`}
          >
            {field.name}
          </p>
          <div className="mt-2px text-xs leading-3">
            <span
              className={field.archived ? 'text-gray-600' : 'text-neutral-1000'}
            >
              {displayByCropData()}
              {displayCropZoneData()}
            </span>

            <span
              className={field.archived ? 'text-gray-600' : 'text-neutral-1000'}
            >
              {' '}
              •{' '}
            </span>

            {field.archived ? (
              <span className="text-gray-600">Archived</span>
            ) : (
              <span className="text-gray-700">
                <FormattedNumber roundedTo={2}>
                  {getCurArea(field)}
                </FormattedNumber>
                &nbsp;ac
              </span>
            )}
          </div>
        </div>
      </div>

      {hasEditAccess && showEditButton && (
        <EditRowButton isRowSelected={isSelected} onClick={handleClick} />
      )}
      {showUnarchiveButton && (
        <UnarchiveButton onUnarchive={onUnarchive} isOnHover={isOnHover} />
      )}
    </ClickableDiv>
  );
};

FieldRow.defaultProps = {
  field: {
    id: '',
    name: '',
    boundaryArea: 0
  },
  onSelect: noop,
  icon: undefined,
  showUnarchiveButton: false,
  onUnarchive: () => {},
  parentPage: 0,
  farm: undefined,
  properties: undefined
};

FieldRow.propTypes = {
  field: PropTypes.shape({
    name: PropTypes.string,
    boundaryArea: PropTypes.number,
    id: PropTypes.string,
    cropzones: PropTypes.arrayOf(PropTypes.object),
    archived: PropTypes.bool
  }),
  onSelect: PropTypes.func,
  isSelected: PropTypes.bool.isRequired,
  icon: PropTypes.objectOf(PropTypes.any),
  hasEditAccess: PropTypes.bool.isRequired,
  showUnarchiveButton: PropTypes.bool,
  onUnarchive: PropTypes.func,
  parentPage: PropTypes.number,
  farm: PropTypes.objectOf(PropTypes.object),
  properties: PropTypes.arrayOf(PropTypes.object)
};

export default memo(FieldRow);
