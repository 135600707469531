import React from 'react';
import PropTypes from 'prop-types';
import { StyleSheet, Text, View } from '@react-pdf/renderer';
import { getAbbreviatedUnit } from 'helpers/unitsAbbreviation';
import { tankHeaderTranslations } from 'components/PDF/TaskPdf/utils/headerTranslations';
import { displayNumber } from 'components/PDF/helpers/pdfTools';

const styles = StyleSheet.create({
  title: {
    fontFamily: 'Noto Sans',
    fontWeight: 'medium',
    fontSize: 12,
    marginBottom: 12,
    marginTop: 3
  },
  row: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginBottom: 20
  },
  fieldContainer: {
    width: '25%'
  },
  fieldText: {
    fontFamily: 'Noto Sans',
    fontSize: 9
  },
  fieldTitle: {
    color: '#363948',
    lineHeight: 2
  },
  fieldValue: {
    color: '#14151C',
    fontWeight: 'medium'
  }
});

const TankInformation = ({ exportLanguage, tankInformation }) => {
  return (
    <View>
      <Text style={styles.title}>
        {tankHeaderTranslations.tankInfo[exportLanguage]}
      </Text>
      <View style={styles.row}>
        <View style={styles.fieldContainer}>
          <Text style={[styles.fieldText, styles.fieldTitle]}>
            {tankHeaderTranslations.tankSize[exportLanguage]}
          </Text>
          <Text style={[styles.fieldText, styles.fieldValue]}>
            {`${displayNumber(
              tankInformation.tankSizeValue
            )} ${getAbbreviatedUnit(tankInformation.tankSizeUnit)}`}
          </Text>
        </View>
        <View style={styles.fieldContainer}>
          <Text style={[styles.fieldText, styles.fieldTitle]}>
            {tankHeaderTranslations.carrierArea[exportLanguage]}
          </Text>
          <Text style={[styles.fieldText, styles.fieldValue]}>
            {`${displayNumber(
              tankInformation.carrierPerAreaValue
            )} ${getAbbreviatedUnit(tankInformation.carrierPerAreaUnit)}`}
          </Text>
        </View>
        <View style={styles.fieldContainer}>
          <Text style={[styles.fieldText, styles.fieldTitle]}>
            {tankHeaderTranslations.totalCarrier[exportLanguage]}
          </Text>
          <Text style={[styles.fieldText, styles.fieldValue]}>
            {`${displayNumber(
              tankInformation.totalCarrierValue
            )} ${getAbbreviatedUnit(tankInformation.totalCarrierUnit)}`}
          </Text>
        </View>
        <View style={styles.fieldContainer}>
          <Text style={[styles.fieldText, styles.fieldTitle]}>
            {tankHeaderTranslations.tankCount[exportLanguage]}
          </Text>
          <Text style={[styles.fieldText, styles.fieldValue]}>
            {displayNumber(tankInformation.tankCount)}
          </Text>
        </View>
      </View>
    </View>
  );
};

TankInformation.propTypes = {
  tankInformation: PropTypes.shape({
    tankSizeValue: PropTypes.number,
    tankSizeUnit: PropTypes.string,
    carrierPerAreaValue: PropTypes.number,
    carrierPerAreaUnit: PropTypes.string,
    totalCarrierValue: PropTypes.number,
    totalCarrierUnit: PropTypes.string,
    tankCount: PropTypes.number
  }),
  exportLanguage: PropTypes.string.isRequired
};

TankInformation.defaultProps = {
  tankInformation: {}
};

export default TankInformation;
