import React, { useContext } from 'react';
import { INTEGRATIONS } from 'screens/Integrations/helpers/constants';

import ProgressBar from 'components/ProgressBar';
import Breadcrumb from 'components/App/AppShell/components/Breadcrumb';

import StepSelector from '../StepSelector';
import { STEPS as AGVEND_STEPS } from '..';
import { AgVendContext } from '../context/Provider';
import AgVendMatchProvider from '../AgVendMatches/context';

const progressBarSteps = AGVEND_STEPS.map(step => ({
  caption: step.header
}));

const BaseAgVendIntegration = () => {
  const {
    state: { step, canGoNext },
    gotoStep,
    goForward
  } = useContext(AgVendContext);

  return (
    <div data-testid="base-integration">
      <Breadcrumb hideCropSeasonDropdown disabled>
        <Breadcrumb.Item
          title="Integrations"
          value={INTEGRATIONS.agVend}
          isLast
        />
      </Breadcrumb>
      <div className="z-50 min-h-50 h-full left-0 right-0 bottom-0 top-18 flex flex-col items-center mb-48">
        <ProgressBar
          stepArray={progressBarSteps}
          currentStep={step}
          canGoNext={canGoNext}
          gotoStep={gotoStep}
        />
        <AgVendMatchProvider onCompleteMatch={goForward}>
          <StepSelector fixedStep={step} />
        </AgVendMatchProvider>
      </div>
    </div>
  );
};

export default BaseAgVendIntegration;
