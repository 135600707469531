import { cloneDeep, orderBy } from 'lodash';

export const sortProducts = (products, field, direction) => {
  let returnedProducts = null;

  if (field === 'name') {
    returnedProducts = orderBy(
      products,
      [product => product.productName.toString().toLowerCase()],
      [direction]
    );
  } else if (field === 'available') {
    returnedProducts = orderBy(
      products,
      [product => product.purchased - product.productQuantityValue],
      [direction]
    );
  } else {
    returnedProducts = orderBy(products, [field], [direction]);
  }

  return returnedProducts;
};

export default (products, field, direction) => {
  if (!direction) {
    return products;
  }

  let sortedProducts = products.map(product => {
    const productCopy = cloneDeep(product);

    return productCopy;
  });
  if (field === 'name') {
    sortedProducts = sortProducts(sortedProducts, 'productName', direction);
  } else if (field === 'type') {
    sortedProducts = sortProducts(sortedProducts, 'productType', direction);
  } else if (field === 'purchased') {
    sortedProducts = sortProducts(sortedProducts, 'purchased', direction);
  } else if (field === 'applied') {
    sortedProducts = sortProducts(sortedProducts, 'applied', direction);
  } else if (field === 'available') {
    sortedProducts = sortProducts(sortedProducts, 'available', direction);
  } else if (field === 'avgPricePerUnit') {
    sortedProducts = sortProducts(
      sortedProducts,
      'averagePricePerUnit',
      direction
    );
  }

  return sortedProducts;
};
