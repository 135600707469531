import React from 'react';
import PropTypes from 'prop-types';
import { Column } from '@agconnections/grow-ui';
import { generate } from 'shortid';

function convertValue(value) {
  if (value === true) {
    return 'Yes';
  }
  if (value === false) {
    return 'No';
  }
  if (
    value === '' ||
    value === null ||
    typeof value === 'undefined' ||
    value === 'null'
  ) {
    return (
      <div>
        <span className="neutral-70">None entered</span>
        <br />
      </div>
    );
  }
  if (value.split) {
    return value.split('\n').map(s => (
      <>
        <span>{s}</span>
        <br />
      </>
    ));
  }
  if (Array.isArray(value)) {
    const nonEmptyLines = value.filter(s => !!s);
    if (nonEmptyLines.length > 0) {
      return value.map(s => convertValue(s));
    }
    return convertValue('');
  }
  return value.toString();
}

function PropertyRow({
  label,
  value,
  icon,
  WidthLabel,
  widthValue,
  ghostValue,
  displayIcon
}) {
  return (
    <div
      className={`flex w-full mr-4 mt-4 pb-2 border-b-1 border-neutral-20 ${
        label ? 'justify-center' : ''
      }`}
      data-testid="property-row"
    >
      {label && (
        <Column key={generate()} width={`${WidthLabel} relative opacity-50`}>
          {label}
        </Column>
      )}
      <Column
        key={generate()}
        width={`${widthValue} relative ${ghostValue ? 'text-gray-500' : ''}`}
      >
        {icon && displayIcon && value && (
          <img
            className="mr-2 w-6 h-6 inline-block"
            alt="Field Icon"
            src={icon}
          />
        )}
        {convertValue(value)}
      </Column>
    </div>
  );
}
PropertyRow.propTypes = {
  label: PropTypes.string,
  value: PropTypes.string.isRequired,
  WidthLabel: PropTypes.string,
  widthValue: PropTypes.string,
  icon: PropTypes.string,
  ghostValue: PropTypes.bool,
  displayIcon: PropTypes.bool
};
PropertyRow.defaultProps = {
  label: '',
  icon: '',
  WidthLabel: 'w-1/2',
  widthValue: 'w-1/2',
  ghostValue: false,
  displayIcon: true
};

export default PropertyRow;
