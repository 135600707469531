import { useCallback, useContext, useState } from 'react';
import { submission as submissionApi } from 'utilities/api';
import catchCancel from 'helpers/catchCancel';
import { createServerError, parseToastServerError } from 'helpers/errorHelpers';
import { useToast } from 'components/ToastContainer';
import { Context } from 'components/Store';

const useSubmission = () => {
  const [loading, setLoading] = useState(false);
  const [, dispatch] = useContext(Context);
  const toast = useToast();
  const orgId = localStorage.getItem('selectedOrganizationId');

  const createSubmission = useCallback(
    ({
      vendor,
      seasonIds,
      cropSeasonsDateRange,
      cropzoneIds,
      submissionId
    }) => {
      const body = {
        vendor,
        seasonIds,
        cropSeasonsDateRange,
        cropzoneIds
      };

      const api = submissionApi.createChildApi({
        action: 'integrations/submissions'
      });

      const { promise } = submissionId
        ? api.update(submissionId, body, {
            headers: {
              'cwf-context': JSON.stringify({
                organization_id: orgId
              })
            }
          })
        : api.create(body, {
            headers: {
              'cwf-context': JSON.stringify({
                organization_id: orgId
              })
            }
          });

      setLoading(true);

      return promise
        .then(res => res)
        .catch(catchCancel)
        .catch(error => {
          const serverError = createServerError({}, error);
          parseToastServerError(
            {
              title: 'Unable to create submission',
              content: `An error prevented to create the submission. Please try again. \nError: ${serverError.displayMessage}`
            },
            toast,
            dispatch
          )(error);
        })
        .finally(() => setLoading(false));
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [orgId]
  );

  const getSubmission = useCallback(
    submissionId => {
      const { promise } = submissionApi
        .createChildApi({
          action: 'integrations/submissions'
        })
        .fetch(
          submissionId,
          {},
          {
            headers: {
              'cwf-context': JSON.stringify({
                organization_id: orgId
              })
            }
          }
        );

      setLoading(true);

      return promise
        .then(res => res)
        .catch(catchCancel)
        .catch(
          parseToastServerError(
            {
              title: 'Unable to get submission details',
              content:
                'An error prevented to retrieve submission details. Please try again.'
            },
            toast,
            dispatch
          )
        )
        .finally(() => setLoading(false));
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [orgId]
  );

  const deleteSubmissionCropZone = useCallback(
    ({ submissionId, cropZoneId }) => {
      setLoading(true);
      const { promise } = submissionApi
        .createChildApi({
          action: `integrations/submissions/${submissionId}/cropzones/${cropZoneId}`
        })
        .delete(null, {
          headers: {
            'cwf-context': JSON.stringify({
              organization_id: orgId
            })
          }
        });
      return promise
        .then(response => response)
        .catch(catchCancel)
        .catch(error => {
          const serverError = createServerError({}, error);
          parseToastServerError(
            {
              title: 'Unable to delete cropzone from submission',
              content: `An error prevented to delete cropzone from the submission. Please try again. \nError: ${serverError.displayMessage}`
            },
            toast,
            dispatch
          )(error);
        })
        .finally(() => setLoading(false));
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [orgId]
  );

  const deleteTaskFromSubmissionCropZone = useCallback(
    ({ submissionId, cropZoneId, taskId }) => {
      setLoading(true);
      const { promise } = submissionApi
        .createChildApi({
          action: `integrations/submissions/${submissionId}/cropzones/${cropZoneId}/tasks/${taskId}`
        })
        .delete(null, {
          headers: {
            'cwf-context': JSON.stringify({
              organization_id: orgId
            })
          }
        });
      return promise
        .then(response => response)
        .catch(catchCancel)
        .catch(error => {
          const serverError = createServerError({}, error);
          parseToastServerError(
            {
              title: 'Unable to delete task from cropzone',
              content: `An error prevented to delete task from the cropzone. Please try again. \nError: ${serverError.displayMessage}`
            },
            toast,
            dispatch
          )();
          return error;
        })
        .finally(() => setLoading(false));
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [orgId]
  );

  const getFieldsDetailsBySubmission = useCallback(
    submissionId => {
      setLoading(true);
      const { promise } = submissionApi
        .createChildApi({
          action: `integrations/submissions/${submissionId}/fields/details`
        })
        .fetch(
          null,
          {},
          {
            headers: {
              'cwf-context': JSON.stringify({
                organization_id: orgId
              })
            }
          }
        );
      return promise
        .then(response => response)
        .catch(catchCancel)
        .catch(
          parseToastServerError(
            {
              title: 'Unable to get field details',
              content:
                'An error prevented to retrieve field details. Please try again.'
            },
            toast,
            dispatch
          )
        )
        .finally(() => setLoading(false));
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [orgId]
  );

  const updateFieldsDetailsBySubmission = useCallback(
    (submissionId, fields) => {
      setLoading(true);
      const { promise } = submissionApi
        .createChildApi({
          action: `integrations/submissions/${submissionId}/fields/details`
        })
        .update(null, fields, {
          headers: {
            'cwf-context': JSON.stringify({
              organization_id: orgId
            })
          }
        });
      return promise
        .then(response => response)
        .catch(catchCancel)
        .catch(error => {
          parseToastServerError(
            {
              title: 'Unable to update field details',
              content: `An error prevented to update field details. Please try again. \nError: ${error.message}`
            },
            toast,
            dispatch
          )(error);
        })
        .finally(() => setLoading(false));
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [orgId]
  );

  const submit = useCallback(
    (submissionId, integrationType, extraData) => {
      const { promise } = submissionApi
        .createChildApi({
          action: `integrations/submissions/${submissionId}/submit/${integrationType.toLowerCase()}`
        })
        .create(extraData ?? null, {
          headers: {
            'cwf-context': JSON.stringify({
              organization_id: orgId
            })
          }
        });

      setLoading(true);

      return promise
        .then(response => response)
        .catch(catchCancel)
        .catch(error => {
          if (error.response?.status !== 422) {
            const details = error.response?.data?.response?.details;
            const errorMessage = error.response?.data?.errorMessage;
            let serverError = createServerError({}, error);
            if (details?.length > 0) {
              serverError = {
                displayMessage: `${errorMessage}:\n\n${[
                  // Should group errors by detail error message
                  ...new Set(details?.map(detail => detail.errorMessage))
                ].join('\n\n')}`
              };
            }

            toast.error('Submission unable to be sent', {
              content: serverError.displayMessage,
              supportButton: true,
              timeout: 60000
            });
            return null;
          }
          return error.response;
        })
        .finally(() => setLoading(false));
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [orgId]
  );

  const resubmit = useCallback(
    submissionId => {
      const { promise } = submissionApi
        .createChildApi({
          action: `integrations/submissions/${submissionId}/resubmission`
        })
        .create(null, {
          headers: {
            'cwf-context': JSON.stringify({
              organization_id: orgId
            })
          }
        });

      setLoading(true);

      return promise
        .then(response => response)
        .catch(catchCancel)
        .catch(error => {
          parseToastServerError(
            {
              title: 'Unable to resubmit',
              content: `An error prevented to resubmit the submission. Please try again. \nError: ${error.message}`
            },
            toast,
            dispatch
          )(error);
        })
        .finally(() => setLoading(false));
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [orgId]
  );

  const listSubmissions = useCallback(
    params => {
      const { promise } = submissionApi
        .createChildApi({
          action: 'integrations/submissions'
        })
        .fetch(null, params, {
          headers: {
            'cwf-context': JSON.stringify({
              organization_id: orgId
            })
          }
        });

      setLoading(true);

      return promise
        .then(res => res)
        .catch(catchCancel)
        .catch(
          parseToastServerError(
            {
              title: 'Unable to list submissions',
              content:
                'An error prevented to list submissions. Please try again.'
            },
            toast,
            dispatch
          )
        )
        .finally(() => setLoading(false));
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [orgId]
  );

  const getProducts = useCallback(
    submissionId => {
      const { promise } = submissionApi
        .createChildApi({
          action: `integrations/submissions/${submissionId}/products`
        })
        .fetch(
          null,
          {},
          {
            headers: {
              'cwf-context': JSON.stringify({
                organization_id: orgId
              })
            }
          }
        );

      setLoading(true);

      return promise
        .then(res => res)
        .catch(catchCancel)
        .catch(
          parseToastServerError(
            {
              title: 'Unable to retrieve products',
              content:
                'An error prevented to retrieve the list of products. Please try again.'
            },
            toast,
            dispatch
          )
        )
        .finally(() => setLoading(false));
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [orgId]
  );

  const getMaterials = useCallback(
    (vendor, text) => {
      const { promise } = submissionApi
        .createChildApi({
          action: `integrations/${vendor}/materials?name=${text}`
        })
        .fetch(
          null,
          {},
          {
            headers: {
              'cwf-context': JSON.stringify({
                organization_id: orgId
              })
            }
          }
        );

      setLoading(true);

      return promise
        .then(res => res)
        .catch(catchCancel)
        .catch(
          parseToastServerError(
            {
              title: 'Unable to retrieve materials',
              content:
                'An error prevented to retrieve the list of materials. Please try again.'
            },
            toast,
            dispatch
          )
        )
        .finally(() => setLoading(false));
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [orgId]
  );

  const updateProducts = useCallback(
    (submissionId, products) => {
      const { promise } = submissionApi
        .createChildApi({
          action: `integrations/submissions/${submissionId}/products`
        })
        .update(
          null,
          {
            products: products.map(product => ({
              id: product.productId,
              registrationNumber: product.overrideRegistrationNumber
            }))
          },
          {
            headers: {
              'cwf-context': JSON.stringify({
                organization_id: orgId
              })
            }
          }
        );

      setLoading(true);

      return promise
        .then(response => response)
        .catch(catchCancel)
        .catch(error => {
          const errorMessage = error.response?.data?.message;
          if (error.response?.status === 422 && errorMessage) {
            toast.error('Products changes unable to be sent', {
              content: errorMessage,
              supportButton: true,
              timeout: 60000
            });
          } else {
            const serverError = createServerError({}, error);
            toast.error('Products changes unable to be sent', {
              content: serverError.displayMessage,
              supportButton: true,
              timeout: 30000
            });
          }

          return error;
        })
        .finally(() => setLoading(false));
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [orgId]
  );

  return {
    createSubmission,
    getSubmission,
    getFieldsDetailsBySubmission,
    updateFieldsDetailsBySubmission,
    deleteSubmissionCropZone,
    deleteTaskFromSubmissionCropZone,
    submit,
    resubmit,
    listSubmissions,
    loading,
    getProducts,
    getMaterials,
    updateProducts,
    setLoading
  };
};

export default useSubmission;
