import shapeJs from 'shpjs';
import {
  area,
  getGeom,
  getCoords,
  convertArea,
  multiPolygon,
  featureCollection
} from '@turf/turf';

/*
Type 01
{
    "area": 1723273.87238112,
    "centroid": "{ \"coordinates\": [ -90.325097, 39.679506 ], \"crs\": { \"properties\": { \"name\": \"EPSG:4326\" }, \"type\": \"name\" }, \"type\": \"Point\" }",
    "created_at": "2018-04-17T05:00:00.000Z",
    "farm_uuid": "f1636c04-f0fd-4d24-866d-ce98cfcaef87",
    "updated_at": "2019-08-06T05:00:00.000Z",
    "uuid": "a0eba913-a60e-464e-ba76-06705499438a",
    "FarmId": "bdf2267c-7f2c-410c-a50a-de0e93c9491f",
    "FieldId": "5cbf4a41-afc1-4fb6-a540-9959cfda1bdf",
    "MapLabel": "Schmidt North",
    "name": "Schmidt North",
    "planted": ""
}
*/
const isFeatureType01 = ({ properties }) => {
  return Boolean(properties.FarmId && properties.name);
};

/*
Type 02
{
    "CLIENT_NAM": "194510  Simpson Co",
    "FARM_NAME": "26 Red Hawk Ky",
    "FIELD_NAME": "1-1  Big Bottom",
    "POLYGONTYP": 0,
    "CLIENT_ID": "52f61d2a-4c7d-4ba9-ba18-c9e540438f32",
    "FARM_ID": "2976720e-c3bb-488c-95e1-9ca420025688",
    "FIELD_ID": "ac2c7513-c645-4c69-a9e4-ab8fc74778e3",
    "ORG_ID": 23325
}
*/
const isFeatureType02 = ({ properties }) => {
  return Boolean(properties.FARM_NAME && properties.FIELD_NAME);
};

/*
Type 03
{
    "CLIENT_ID": "d1e1a81e-b852-4001-94b1-aa7fbd99992d",
    "CLIENT": "A Douglas Farms",
    "FARM_ID": "c256c3dd-32cd-4c9a-a7d6-936aeb4caa8b",
    "FARM": "A Douglas Farms",
    "FIELD_ID": "0ace3199-c908-4432-8bc2-d96e1d2bf935",
    "FIELD": "AD1",
    "AREA_UNIT": "acre",
    "REP_AREA": "0.00000",
    "FSA_FARM": "",
    "FSA_TRACT": "",
    "FSA_FIELD": "",
    "FSA_AREA": "0.00000",
    "STATE": "",
    "COUNTY": "",
    "TOWNSHIP": "",
    "TWSP_DIR": "",
    "SECTION": "",
    "RANGE": "",
    "RANGE_DIR": ""
}
*/
const isFeatureType03 = ({ properties }) => {
  return Boolean(properties.FARM && properties.FIELD);
};

/*
Type 04
{
  GrowerName: "POWER FARMS",
  FarmName: "HOME",
  FieldName: "Across Hardroad",
}
*/
const isFeatureType04 = ({ properties }) => {
  return Boolean(properties.FarmName && properties.FieldName);
};

/*
Type 05
{
  Field: "Harrison's",
  Obj__Id: 1,
  Bnd_Name: "Harrison's",
}
*/
const isFeatureType05 = ({ properties }) => {
  return Boolean(properties.Bnd_Name && properties.Field);
};

const parseFeatureType01 = ({ properties, geometry }) => {
  return {
    name: properties.name,
    farm: properties.FarmId,
    fieldId: properties.FieldId,
    geometry
  };
};

const parseFeatureType02 = ({ properties, geometry }) => {
  return {
    name: properties.FIELD_NAME,
    farm: properties.FARM_NAME,
    farmId: properties.FARM_ID,
    fieldId: properties.FIELD_ID,
    geometry
  };
};

const parseFeatureType03 = ({ properties, geometry }) => {
  return {
    name: properties.FIELD,
    farm: properties.FARM,
    farmId: properties.FARM_ID,
    fieldId: properties.FIELD_ID,
    geometry
  };
};

const parseFeatureType04 = ({ properties, geometry }) => {
  return {
    name: properties.FieldName,
    farm: properties.FarmName,
    geometry
  };
};

const parseFeatureType05 = ({ properties, geometry }) => {
  return {
    name: properties.Field,
    farm: properties.Bnd_Name,
    geometry
  };
};

const parseFeaturesToFields = features => {
  const fields = features.map(feature => {
    if (isFeatureType01(feature)) return parseFeatureType01(feature);
    if (isFeatureType02(feature)) return parseFeatureType02(feature);
    if (isFeatureType03(feature)) return parseFeatureType03(feature);
    if (isFeatureType04(feature)) return parseFeatureType04(feature);
    if (isFeatureType05(feature)) return parseFeatureType05(feature);
    throw new Error('unknown features type');
  });

  return fields;
};

const parseFeatureCollectionsToFeaturesArray = geoJSON => {
  const rawFeatures = Array.isArray(geoJSON)
    ? geoJSON.flatMap(({ features }) => features)
    : geoJSON.features;

  return rawFeatures;
};

const removeDuplicatesFields = fields => {
  const uniqueFields = new Map();

  fields.forEach(field => {
    const { name, farm } = field;
    const key = `${name}-${farm}`;

    if (!uniqueFields.get(key)) {
      uniqueFields.set(key, field);
    }
  });

  return Array.from(uniqueFields, ([, value]) => value);
};

export const processFile = async file => {
  try {
    const geoJSON = await shapeJs(await file.arrayBuffer());
    const features = parseFeatureCollectionsToFeaturesArray(geoJSON);
    const fields = parseFeaturesToFields(features);
    return removeDuplicatesFields(fields);
  } catch (error) {
    throw new Error(`Error on file '${file.name}': ${error.message}`);
  }
};

export const processFiles = async files => {
  const errors = [];

  const fields = (
    await Promise.all(
      files.map(async file => {
        try {
          return await processFile(file);
        } catch (error) {
          errors.push({ file: file.name, message: error.message });
        }
        return [];
      })
    )
  ).flat();

  return { fields: removeDuplicatesFields(fields), errors };
};

export const calculateFieldsAreaInAcres = fields => {
  const collection = featureCollection(fields);
  return convertArea(area(collection), 'meters', 'acres');
};

export const convertFeaturesToBaseGeometry = features => {
  let resultFeature = {};
  if (getCoords(features).length > 1) {
    resultFeature = multiPolygon(getCoords(features));
  } else {
    resultFeature = multiPolygon([features].map(getCoords));
  }
  return getGeom(resultFeature);
};

export const fieldToFeature = field => ({
  type: 'Feature',
  geometry: field?.geometry,
  properties: {
    // Type 3
    FIELD: field?.name,
    FARM: field?.farm
  }
});
