/* eslint-disable import/prefer-default-export */
import { capitalize } from 'lodash';

export const formatTitle = text => {
  let transformedText = text;
  transformedText = transformedText.replace(/-/g, ' ');
  transformedText = transformedText.toUpperCase();

  return transformedText;
};

export const kebabToProperCase = text => {
  let transformedText = text;
  transformedText = transformedText.replace(/-/g, ' ');
  transformedText = capitalize(transformedText);

  return transformedText;
};

export const getInitials = text => {
  let initials = text.replace(/[^a-zA-Z- ]/g, '').match(/\b\w/g);

  initials = initials.join('');

  return initials;
};

export const capitalizeName = text =>
  text.slice(0, 1).toUpperCase() + text.slice(1).toLowerCase();
