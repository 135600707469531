import React from 'react';
import PropTypes from 'prop-types';
import LoadItem from '../LoadItem';

const EmptyList = () => (
  <div className="h-full text-center p-12">
    <span>
      Select “Add to List & Create New” to add a completed load to this list and
      create a new load
    </span>
  </div>
);

const LoadTicketsList = ({ list, onDeleteYieldTicket }) => {
  if (!list.length) {
    return <EmptyList />;
  }

  return (
    <div className="h-full">
      {list.map(item => (
        <LoadItem
          key={item.name + item.loadTemporaryId}
          item={item}
          onDeleteYieldTicket={onDeleteYieldTicket}
        />
      ))}
    </div>
  );
};
LoadTicketsList.propTypes = {
  list: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      name: PropTypes.string
    })
  ).isRequired,
  onDeleteYieldTicket: PropTypes.func.isRequired
};

export default LoadTicketsList;
