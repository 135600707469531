import React from 'react';
import PropTypes from 'prop-types';
import MatchArrowIcon from 'screens/Integrations/PropertiesMatch/components/FarmList/components/icons/MatchArrowIcon';
import { Tag } from 'syngenta-digital-cropwise-react-ui-kit';
import CompanyPeopleDropdown from 'components/CompanyPeopleDropdown';

const RetailerListItem = ({ retailer, matchedRetailer, handleSelect }) => {
  return (
    <div className="justify-self-start pr-15 col-span-w w-full mb-8">
      <div className="grid items-center grid-cols-7">
        <div className="text-sm col-span-3" style={{ position: 'relative' }}>
          <div className="flex flex-row items-center">
            <div className="mr-4 mt-6 flex-none w-22">
              <Tag
                key="retailer-status"
                color={matchedRetailer ? 'green' : 'yellow'}
                data-testid="status-tag"
              >
                {matchedRetailer ? 'MATCHED' : 'UNMATCHED'}
              </Tag>
            </div>
            <div className="flex flex-col my-2 w-full">
              <span className="text-gray-600">AgVend Retailer</span>
              <div className="mt-2 p-2 rounded-md bg-gray-200 border-gray-500 border-1 text-gray-600">{`${retailer.retailerName}`}</div>
            </div>
          </div>
        </div>
        <div className="flex items-center justify-center h-full col-span-1">
          <span className="mb-1 mt-8">
            <MatchArrowIcon />
          </span>
        </div>
        <div className="flex flex-col col-span-3">
          <div
            className="text-neutral-600 text-left mb-1 text-sm"
            data-testid="cropzones-heading"
          >
            Cropwise Financials Company
          </div>
          <div className="flex items-center justify-center cropzone-dropdown">
            <CompanyPeopleDropdown
              id="cwfRetailerMatch"
              value={
                matchedRetailer ? { ...matchedRetailer, type: 'Company' } : null
              }
              onChange={value => {
                handleSelect(value);
              }}
              bottomButtonText="Create New Company"
              title="Create a Company"
              hidePeople
            />
          </div>
        </div>
      </div>
    </div>
  );
};

RetailerListItem.defaultProps = {
  matchedRetailer: undefined
};

RetailerListItem.propTypes = {
  retailer: PropTypes.shape({
    externalId: PropTypes.string,
    retailerName: PropTypes.string
  }).isRequired,
  matchedRetailer: PropTypes.shape({
    vendorEntityDescription: PropTypes.string,
    vendorEntityId: PropTypes.string,
    cwfEntityId: PropTypes.string,
    type: PropTypes.string
  }),
  handleSelect: PropTypes.func.isRequired
};

export default RetailerListItem;
