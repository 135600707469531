import React, { useState, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import { Prompt, Redirect } from 'react-router-dom';
import { useFormikContext } from 'formik';
import { values } from 'lodash';
import PortalModal from 'components/Modals/PortalModal';

const DEFAULT_TITLE = 'Leaving Form';
const BLOCKING_MESSAGE =
  'You have unsaved changes. Navigating away will discard changes. Do you want to continue?';

const FormNavGuard = ({
  title,
  onValidationError,
  customText,
  disabled,
  alwaysShow,
  isDeleting,
  hideInstructions,
  cancelLabel,
  confirmLabel,
  type
}) => {
  const { touched, isSubmitting, validateForm } = useFormikContext();
  const [isBlocking, setIsBlocking] = useState(true);
  const [showModal, setShowModal] = useState(false);
  const [confirmed, setConfirmed] = useState(false);
  const [nextLocation, setNextLocation] = useState(null);

  useEffect(() => {
    if (isDeleting) {
      setShowModal(false);
      setConfirmed(true);
    }
  }, [isDeleting]);

  useEffect(() => {
    const checkBlocking = event => {
      if (isSubmitting || disabled) {
        setIsBlocking(false);
        return;
      }
      const formTouched = values(touched).some(val => !!val);
      if (formTouched)
        if (event) {
          // event was triggered by beforeunload - set blocking at the browser level
          event.preventDefault();
          // eslint-disable-next-line no-param-reassign
          event.returnValue = BLOCKING_MESSAGE;
        } else setIsBlocking(true);
      else setIsBlocking(false);
    };
    window.addEventListener('beforeunload', checkBlocking);
    checkBlocking();
    // clean-up function
    return () => {
      window.removeEventListener('beforeunload', checkBlocking);
    };
  }, [touched, isSubmitting, disabled]);

  useEffect(() => {
    if (alwaysShow) {
      setIsBlocking(true);
    }
  }, [alwaysShow]);

  const handleBlockedNavigation = useCallback(
    next => {
      if (!confirmed) {
        setShowModal(true);
        setNextLocation(next);
        return false;
      }
      return true;
    },
    [confirmed]
  );

  const onReject = useCallback(async () => {
    const result = await validateForm();
    setShowModal(false);
    if (Object.keys(result).length) onValidationError(result);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [setShowModal, onValidationError]);

  const onConfirm = useCallback(() => {
    setShowModal(false);
    setConfirmed(true);
  }, []);

  return (
    <>
      <Prompt
        when={isBlocking && !confirmed}
        message={handleBlockedNavigation}
      />
      {confirmed && nextLocation && <Redirect to={nextLocation} />}
      <div data-testid="confirm-modal">
        <PortalModal
          open={showModal}
          close={onReject}
          onConfirm={onConfirm}
          cancelLabel={cancelLabel}
          confirmLabel={confirmLabel}
          type={type}
          title={title || DEFAULT_TITLE}
        >
          <div className="pr-6">
            {customText || BLOCKING_MESSAGE}
            {hideInstructions ? null : (
              <>
                <br />
                (Click <span className="font-semibold">Yes</span> to discard
                changes, <span className="font-semibold">No</span> to go back)
              </>
            )}
          </div>
        </PortalModal>
      </div>
    </>
  );
};

FormNavGuard.defaultProps = {
  customText: null,
  title: null,
  onValidationError: () => {},
  disabled: false,
  alwaysShow: false,
  isDeleting: false,
  hideInstructions: false,
  cancelLabel: 'No',
  confirmLabel: 'Yes',
  type: 'confirmation'
};

FormNavGuard.propTypes = {
  onValidationError: PropTypes.func,
  customText: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  title: PropTypes.string,
  disabled: PropTypes.bool,
  alwaysShow: PropTypes.bool,
  isDeleting: PropTypes.bool,
  hideInstructions: PropTypes.bool,
  cancelLabel: PropTypes.string,
  confirmLabel: PropTypes.string,
  type: PropTypes.string
};

export default FormNavGuard;
