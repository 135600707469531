import React, { createContext, useState, useMemo, useCallback } from 'react';
import PropTypes from 'prop-types';
import useSubmission from 'hooks/useSubmission';
import { SUBMISSIONS_HISTORY_TABLE } from '../../constants';

const initialState = {
  data: [],
  page: 0,
  pages: 0,
  limit: SUBMISSIONS_HISTORY_TABLE.itemsPerPage,
  elements: 0,
  integrationType: ''
};

export const SubmissionHistoryContext = createContext(initialState);

const Provider = ({ integrationType, children }) => {
  const [state, setState] = useState({
    ...initialState,
    integrationType
  });

  const { listSubmissions, loading } = useSubmission();

  const getData = useCallback(
    async (sortDir = undefined, sortBy = undefined, page = undefined) => {
      const response = await listSubmissions({
        vendor: integrationType.toLowerCase(),
        page: page ?? 0,
        limit: state.limit,
        sortDir: (sortDir ?? 'desc').toUpperCase(),
        sortBy: sortBy ?? 'created_at'
      });

      if (response?.status === 200) {
        setState(current => ({
          ...current,
          ...response.data
        }));
      }
    },
    [listSubmissions, integrationType, state.limit]
  );

  const store = useMemo(
    () => ({
      state,
      getData,
      loading
    }),
    [state, loading, getData]
  );

  return (
    <SubmissionHistoryContext.Provider value={store}>
      {children}
    </SubmissionHistoryContext.Provider>
  );
};

Provider.defaultProps = {
  integrationType: '',
  children: null
};

Provider.propTypes = {
  integrationType: PropTypes.string,
  children: PropTypes.node
};

export default Provider;
