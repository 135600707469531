import React from 'react';
import PropTypes from 'prop-types';
import shortId from 'shortid';

import LandingListItem, { ProtectedLandingListItem } from './LandingListItem';
import LandingEmpty from './LandingEmpty';

const LandingList = ({ children }) => {
  return (
    <ul data-testid="landing-list">
      {children.length ? (
        React.Children.map(children, (child, index) => {
          return (
            <li
              key={shortId.generate()}
              className="bg-white border-b border-gray-200 "
            >
              <div id={`landing-list-item-${index}`}>{child}</div>
            </li>
          );
        })
      ) : (
        <li
          key={shortId.generate()}
          className="bg-white shadow overflow-hidden sm:rounded-t-md sm:rounded-b-md"
        >
          {children}
        </li>
      )}
    </ul>
  );
};

LandingList.propTypes = {
  children: PropTypes.node.isRequired
};

LandingList.LandingListItem = LandingListItem;
LandingList.ProtectedLandingListItem = ProtectedLandingListItem;
LandingList.LandingEmpty = LandingEmpty;

export default LandingList;
