import React, { useContext, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { Spinner } from '@agconnections/grow-ui';
import { Formik } from 'formik';
import usePopulateMapData from 'hooks/usePopulateMapData';
import useYieldData from 'screens/Yield/hooks/useYieldData';
import ProgressBar from 'components/ProgressBar';
import { YieldContext } from 'screens/Yield/context/YieldProvider';
import Breadcrumb from 'components/App/AppShell/components/Breadcrumb';
import { FETCH_TYPES } from 'helpers/constants';
import yieldProgressBarSteps from 'screens/Yield/helpers/yieldProgressBarSteps';
import StepSelector from '../components/StepSelector';
import canGoNext from '../helpers/validateNextStep';
import blankValues from '../helpers/blankValues';
import getSeasons from '../helpers/getSeasons';

const SCREEN_WIDTH = window.screen.width;
const REFERENCE_WIDTH = 1792;
const REFERENCE_WIDTH_VALUE = 48;

const EditYield = () => {
  const { id } = useParams();
  const selectedCropSeasons = getSeasons();

  const { isLoadingYieldEntry, yieldEntry, loadYieldItem } = useYieldData();

  const {
    loading,
    zoom,
    setZoom,
    dataSorted: propertyLandingPageData,
    fieldsAndAreasGeoJSONCollection,
    setGeoJSONCollectionFeatures,
    fieldsLoading,
    filterFeatureCollectionByParentId,
    reloadData
  } = usePopulateMapData({ fetchType: FETCH_TYPES.cropSeasonsMap });
  // id does not do anything until muck are replaced with real end points, change logic for hook is require
  useEffect(() => {
    loadYieldItem(id);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    // when end points are done update logic to get the cropseaons with yield end points
    // this for updating breadcrump with the id incoming from yieldEntry
    localStorage.setItem('selectedCropSeasons', JSON.stringify(getSeasons()));
    // change logic when yieldEntry.seasonId  actaully comes with seaons Ids
    // reloadData({ seasonIds: yieldEntry.seasonId });
    reloadData({ seasonIds: getSeasons() });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleOrgChange = () => {
    reloadData({ seasonIds: getSeasons() });
  };
  const {
    state: { step },
    goBack: gotoStep
  } = useContext(YieldContext);

  return (
    <>
      {isLoadingYieldEntry || !yieldEntry ? (
        <Spinner />
      ) : (
        <Formik
          initialValues={{ ...blankValues, ...yieldEntry }}
          enableReinitialize
        >
          {({ values }) => (
            <div>
              <Breadcrumb
                onOrganizationSelect={handleOrgChange}
                hideCropSeasonDropdown={false}
                onCropSeasonSelect={handleOrgChange}
                disabled
              >
                <Breadcrumb.Item title="Yield" value="Edit Yield" isLast />
              </Breadcrumb>
              {loading ? (
                <Spinner />
              ) : (
                <div
                  className="z-50 h-full bottom-0 top-18"
                  data-testid="create-yield-progressbar"
                >
                  {step > 0 && step < 4 && (
                    <div className="mx-64">
                      <ProgressBar
                        stepArray={yieldProgressBarSteps}
                        currentStep={step}
                        canGoNext={canGoNext(values, step)}
                        gotoStep={gotoStep}
                        className="pb-5 pt-2"
                        style={{
                          margin: '0 auto',
                          width: `${(SCREEN_WIDTH * REFERENCE_WIDTH_VALUE) /
                            REFERENCE_WIDTH}rem`
                        }}
                      />
                    </div>
                  )}
                  <div className="bottom-0 h-full">
                    <StepSelector
                      loading={loading}
                      zoom={zoom}
                      setZoom={setZoom}
                      propertyLandingPageData={propertyLandingPageData}
                      fieldsAndAreasGeoJSONCollection={
                        fieldsAndAreasGeoJSONCollection
                      }
                      setGeoJSONCollectionFeatures={
                        setGeoJSONCollectionFeatures
                      }
                      fieldsLoading={fieldsLoading}
                      filterFeatureCollectionByParentId={
                        filterFeatureCollectionByParentId
                      }
                      selectedCropSeasons={selectedCropSeasons}
                    />
                  </div>
                </div>
              )}
            </div>
          )}
        </Formik>
      )}
    </>
  );
};

export default EditYield;
