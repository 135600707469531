import React from 'react';
import PropTypes from 'prop-types';
import StorageLocationIcon from 'assets/storage.svg';
import SaleLocationIcon from 'assets/sale.svg';
import { useFormikContext } from 'formik';
import BaseDropdown from 'components/BaseDropdown';

const DestinationField = ({
  storageLocations,
  saleLocations,
  onCreateNewLocation,
  onChange,
  value,
  id,
  isLoading
}) => {
  const { errors, touched } = useFormikContext();

  const valueRenderer = () => {
    if (value?.type === 'STORAGE') {
      return (
        <div className="w-full h-full p-2 flex items-center">
          <img className="h-4" alt="Field Icon" src={StorageLocationIcon} />
          <div className="pl-2">{value.name}</div>
        </div>
      );
    }

    if (value?.type === 'SALE') {
      return (
        <div className="w-full h-full p-2 flex items-center">
          <img className="h-4" alt="Field Icon" src={SaleLocationIcon} />
          <div className="pl-2">{value.name}</div>
        </div>
      );
    }
    return null;
  };

  return (
    <BaseDropdown
      valueRenderer={valueRenderer}
      value={value}
      bottomButtonText="Create New Location"
      onBottomButtonClick={onCreateNewLocation}
      onChange={onChange}
      id={id}
      isInvalid={
        errors?.destination !== undefined && touched?.destination !== undefined
      }
      isLoading={isLoading}
    >
      <div className="text-neutral-60 py-2">STORAGE</div>
      {storageLocations?.length > 0 ? (
        storageLocations.map(storageLocation => (
          <div
            key={storageLocation.id}
            className="w-full h-full p-2 flex items-center"
            value={{
              type: 'STORAGE',
              id: storageLocation.id,
              name: storageLocation.name
            }}
          >
            <img className="h-4" alt="Field Icon" src={StorageLocationIcon} />
            <div className="pl-2">{storageLocation.name}</div>
          </div>
        ))
      ) : (
        <div className="text-neutral-60 mb-4 mt-2">
          No storage locations found.
        </div>
      )}
      <div className="text-neutral-60 py-2">SALE</div>
      {saleLocations?.length > 0 ? (
        saleLocations.map(saleLocation => (
          <div
            key={saleLocation.id}
            className="w-full h-full p-2 flex items-center"
            value={{
              type: 'SALE',
              id: saleLocation.id,
              name: saleLocation.name
            }}
          >
            <img className="h-4" alt="Field Icon" src={SaleLocationIcon} />
            <div className="pl-2">{saleLocation.name}</div>
          </div>
        ))
      ) : (
        <div className="text-neutral-60 mb-4 mt-2">
          No sale locations found.
        </div>
      )}
    </BaseDropdown>
  );
};

DestinationField.propTypes = {
  storageLocations: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      name: PropTypes.string
    })
  ),
  saleLocations: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      name: PropTypes.string
    })
  ),
  value: PropTypes.shape({
    id: PropTypes.string,
    type: PropTypes.string,
    name: PropTypes.string
  }),
  onChange: PropTypes.func.isRequired,
  onCreateNewLocation: PropTypes.func.isRequired,
  id: PropTypes.string,
  isLoading: PropTypes.bool
};

DestinationField.defaultProps = {
  value: null,
  id: '',
  isLoading: false,
  storageLocations: [],
  saleLocations: []
};

export default DestinationField;
