import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { Table, Button } from '@agconnections/grow-ui';
import { getAbbreviatedUnit } from 'helpers/unitsAbbreviation';
import RoundedDisplay from 'components/RoundedDisplay';
import { formatProductManufacturerName } from 'utilities/helpers/labels';
import { paths } from 'routes/paths';

const ProductsTableRow = ({ product, onAction }) => {
  const handleAction = action => onAction(action, product);

  return (
    <Table.Row key={product.productId || product.id}>
      <Table.Cell width="20rem">
        <span className="flex flex-col">
          <span
            className="w-64 pb-2 text-sm font-bold text-left truncate"
            data-testid="product-name"
          >
            <Link
              to={`${paths.inventory}/${product.productId || product.id}/view`}
            >
              {product?.productName || product?.name}
            </Link>
          </span>
          <span
            className="w-64 text-sm text-left text-gray-600 truncate"
            data-testid="product-manufacturer"
          >
            {formatProductManufacturerName(product)}
          </span>
        </span>
      </Table.Cell>
      <Table.Cell>{product?.productType || product.type}</Table.Cell>
      <Table.Cell>
        <RoundedDisplay>{product.purchased || 0}</RoundedDisplay>{' '}
        {getAbbreviatedUnit(product.productQuantityUnit)}
      </Table.Cell>
      <Table.Cell>
        <RoundedDisplay>{product.productQuantityValue || 0}</RoundedDisplay>{' '}
        {getAbbreviatedUnit(product.productQuantityUnit)}
      </Table.Cell>
      <Table.Cell>
        <RoundedDisplay>
          {product.purchased - product.productQuantityValue || 0}
        </RoundedDisplay>{' '}
        {getAbbreviatedUnit(product.productQuantityUnit)}
      </Table.Cell>
      <Table.Cell>
        <div>
          $<RoundedDisplay>{product.averagePricePerUnit || 0}</RoundedDisplay>/
          {getAbbreviatedUnit(product.productQuantityUnit)}
        </div>
        <div
          data-testid="product-table-options-menu"
          className="flex-none w-10 absolute right-0 mr-5"
        >
          <Button type="link-default" onClick={() => handleAction('view')}>
            View
          </Button>
        </div>
      </Table.Cell>
    </Table.Row>
  );
};

ProductsTableRow.propTypes = {
  product: PropTypes.shape({
    productId: PropTypes.string,
    id: PropTypes.string,
    productName: PropTypes.string,
    name: PropTypes.string,
    purchased: PropTypes.number,
    purchasedUnit: PropTypes.string,
    applied: PropTypes.number,
    appliedUnit: PropTypes.string,
    available: PropTypes.number,
    custom: PropTypes.bool,
    productType: PropTypes.string,
    type: PropTypes.string,
    averagePricePerUnit: PropTypes.number,
    isCustom: PropTypes.bool,
    manufacturerName: PropTypes.string,
    manufacturer: PropTypes.string,
    stdUnit: PropTypes.number,
    productQuantityValue: PropTypes.number,
    productQuantityUnit: PropTypes.string
  }).isRequired,
  onAction: PropTypes.func.isRequired
};

export default ProductsTableRow;
