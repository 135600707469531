const RECOMMENDATION_TYPE = {
  agrian: 'AGRIAN',
  landdb: 'LANDDB'
};

export const RECOMMENDATION_TYPE_DISPLAY_NAME = {
  [RECOMMENDATION_TYPE.landdb]: 'Cropwise Financials Recommendations',
  [RECOMMENDATION_TYPE.agrian]: 'Agrian Recommendations'
};

export default RECOMMENDATION_TYPE;
