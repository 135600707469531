import { format } from 'date-fns';
import { orderBy } from 'lodash';
import { costTypes } from './constants';

const COLUMN_SORT_FUNCTIONS = {
  name: (dir, plans) => orderBy([p => p?.name], dir, plans),
  plannedArea: (dir, plans) =>
    orderBy([p => p?.estimatedAreaValue], dir, plans),
  crop: (dir, plans) => orderBy([p => p?.crop?.name], dir, plans),
  fixedCostPerAcre: (dir, plans) => orderBy([p => p?.fixedCost], dir, plans),
  varCostPerAcre: (dir, plans) => orderBy([p => p?.variableCost], dir, plans),
  projRevPerAcre: (dir, plans) => orderBy([p => p?.projectRevenue], dir, plans),
  createdAt: (dir, plans) => orderBy([p => p?.createdOn], dir, plans)
};

export const getInitPlanItem = () => ({
  costs: costTypes.map(costType => ({
    costType: costType.type,
    costValue: 0,
    costStructure: 'total'
  }))
});

export const unmaskCost = event => {
  const { value } = event.target;
  return parseFloat(value.replace(/[,$]/g, '') || 0);
};

// fixed cost

const calcFixedCostTotalType = (cost, planArea) => {
  return {
    total: Math.round(cost.costValue) || 0,
    byArea: planArea ? Math.round(cost.costValue) / planArea : 0
  };
};

const calcFixedCostByAreaType = (cost, planArea) => {
  return {
    total: Math.round(cost.costValue * planArea),
    byArea: cost.costValue || 0
  };
};

export const calcFixedCostAmounts = (cost, planArea) => {
  return cost.costStructure === 'total'
    ? calcFixedCostTotalType(cost, planArea)
    : calcFixedCostByAreaType(cost, planArea);
};

export const calcFixedCostSummary = (costs, planArea) => {
  if (!costs) {
    return {};
  }
  let totalFixedCost = 0;
  let totalFixedCostPerArea = 0;
  costs.forEach(cost => {
    const { total, byArea } = calcFixedCostAmounts(cost, planArea);
    totalFixedCost += total;
    totalFixedCostPerArea += byArea;
  });

  totalFixedCost = parseFloat(totalFixedCost.toFixed(2));
  totalFixedCostPerArea = parseFloat(totalFixedCostPerArea.toFixed(2));

  return { totalFixedCost, totalFixedCostPerArea };
};

export const calcVariableCostSummary = (products = [], planArea) => {
  let totalVariableCost = 0;
  products.forEach(product => {
    totalVariableCost +=
      product.appliedArea *
      product.applicationCount *
      product.productRate *
      product.productPrice;
  });
  const totalVariablePerArea = totalVariableCost / planArea;
  return { totalVariableCost, totalVariablePerArea };
};

export const unmaskPrice = amount => {
  return isNaN(amount)
    ? parseFloat(amount.replace(/[$,]/g, ''))
    : parseFloat(amount);
};

export const formatTargetDate = date =>
  date ? format(Date.parse(date), 'MM/dd/yyyy') : null;

export const calcTotalYield = (plan = {}) =>
  plan.revenue && plan.revenue.yieldPerArea && plan.area
    ? (plan.revenue.yieldPerArea * plan.area).toFixed(2)
    : 0;

// Searching
export const filterItems = (item, values) => {
  const checkIfValueIsPresent = value =>
    item.name.toLowerCase().includes(value.toLowerCase());
  if (values.length === 1) {
    return values.every(checkIfValueIsPresent);
  }
  return values.every(checkIfValueIsPresent);
};

export const getDateToString = dateObj => {
  const divider = '/';
  const result =
    dateObj?.monthValue +
    divider +
    dateObj?.dayOfMonth +
    divider +
    dateObj?.year;
  return result;
};

export const findPlan = (plans, value) => {
  if (value) {
    const values = value.match(/[^ ]+/g);
    const filteredItems = [];
    const foundItem = plans.filter(item => {
      return filterItems(item, values);
    });
    if (foundItem) {
      foundItem.forEach(fi => {
        filteredItems.push(fi);
      });
    }
    return filteredItems;
  }
  return plans;
};

export const sort = (plans, sortBy, sortDir) => {
  const sortFunction = COLUMN_SORT_FUNCTIONS[sortBy];

  if (!sortFunction || !sortDir) {
    return plans;
  }

  return sortFunction(sortDir, plans);
};

export const dateRangeFilter = (plans, filteredDate) => {
  if (filteredDate.length !== 2) {
    return plans;
  }

  const startTime = filteredDate[0].getTime();
  const endTime = filteredDate[1].getTime();

  return plans.filter(plan => {
    const createdOnTime = new Date(plan.createdOn).getTime();
    return createdOnTime <= endTime && createdOnTime >= startTime;
  });
};

export const getPlanProperties = (
  propertyLandingPageData,
  selectedCropzoneIds,
  fieldsAndAreasGeoJSONCollection,
  values
) => {
  const properties = propertyLandingPageData?.properties?.map(prop => {
    return { ...prop, selectedCropSeason: values?.cropSeasonIds };
  });
  const selectedCropzoneData = [];
  if (properties?.length > 0) {
    /* eslint no-unused-expressions: "off", curly: "error" */
    properties?.forEach(property => {
      if (property?.fields?.length > 0) {
        /* eslint no-unused-expressions: "off", curly: "error" */
        property?.fields.forEach(field => {
          /* eslint no-unused-expressions: "off", curly: "error" */
          field?.cropzones.forEach(cz => {
            if (selectedCropzoneIds?.includes(cz.id)) {
              selectedCropzoneData.push({
                ...cz,
                farmId: property.id,
                fieldName: field.name,
                features: fieldsAndAreasGeoJSONCollection.features?.filter(
                  feature => field.id === feature.properties.id
                )
              });
            }
          });
        });
      }
    });
  }
  return selectedCropzoneData;
};
