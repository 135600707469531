/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react/prop-types */
import React, { useEffect, useContext, useState } from 'react';
import { extraTagsCalculation } from 'components/TagsButton';
import { useParams } from 'react-router-dom';
import { Spinner, Columns, Column, TextArea } from '@agconnections/grow-ui';
import { Context } from 'components/Store';
import catchCancel from 'helpers/catchCancel';
import { parseServerError } from 'helpers/errorHelpers';
import usePlansApi from 'hooks/usePlansApi';
import useTagsData from 'hooks/useTagsData';
import Paper from 'components/Paper';
import { hasAccess } from 'utilities/access';
import Breadcrumb from 'components/App/AppShell/components/Breadcrumb';
import TagsModal from 'components/Modals/TagsModal';
import usePlanEllipsisOptions from 'screens/Plans/hooks/usePlanEllipsisOptions';
import { paths } from 'routes/paths';
import usePlanData from '../../Plan/hooks/usePlanData';
import PlanDetailsSection from './components/PlanDetailsSection';
import FixedCostSection from './components/FixedCostSection';
import ProjectedRevenueSection from './components/ProjectedRevenueSection';
import TopHeader from './components/TopHeader';
import PropertiesTable from './components/PropertiesTable';
import ProductsTable from './components/ProductsTable';
import DeletePlanModal from '../DeletePlanModal';
import EditProductModal from '../EditProductModal';
import ConvertPlanModal from '../../ConvertPlanModal';
import ConvertPlanToRecModal from '../../ConvertPlanToRecModal';
import {
  getPlanFixedCost,
  getVarCost,
  getPlanProjNetRevenue,
  getPlanProjNetRevenuePerArea
} from './helpers';

const PlanView = () => {
  const [{ loggedInUserOrgPermission }, dispatch] = useContext(Context);

  const { id } = useParams();
  const { plan, loading, setPlan } = usePlanData(id);

  const {
    handleAction,
    currentOpenModal,
    setCurrentOpenModal,
    downloadingPDF,
    setLoadingTags,
    loadingTags
  } = usePlanEllipsisOptions(plan);

  const [selProduct, setSelProduct] = useState();
  const [returnedTags, setReturnedTags] = useState([]);
  const plansAPI = usePlansApi('plan');
  const [, setError] = useState(null);
  const { tags } = useTagsData();
  const [updatedTags, setUpdatedTags] = useState(returnedTags || []);
  const [displayTagsObject, setDisplayTagsObject] = useState({
    extraTagsCount: 0,
    extraTagsNames: [],
    tagsToDisplay: []
  });

  useEffect(() => {
    if (tags?.length > 0 && plan?.flagIds?.length > 0) {
      const planTags = tags?.filter(tag =>
        plan?.flagIds?.find(flagId => tag.id === flagId)
      );
      setUpdatedTags(planTags);
    } else setUpdatedTags(returnedTags || []);
  }, [tags, plan.flagIds]);

  useEffect(() => {
    setUpdatedTags(returnedTags || []);
  }, [returnedTags]);

  useEffect(() => {
    setDisplayTagsObject(extraTagsCalculation(updatedTags, 30));
  }, [updatedTags]);

  const closeModal = () => {
    setCurrentOpenModal(null);
  };

  const handleProductModal = product => {
    setSelProduct(product);
    setCurrentOpenModal('product');
  };

  const getDisplayByFinancialAccess = () => {
    return hasAccess(loggedInUserOrgPermission, {
      minAllowedRole: 'View Only',
      minFinancialAccess: 'READ'
    });
  };

  const updatePlanTags = newTags => {
    setLoadingTags(true);
    const body = {
      ...plan,
      flagIds: newTags?.map(tag => tag.id) || []
    };
    const { promise } = plansAPI.update(plan.id, body);

    promise
      .then(({ data }) => {
        setReturnedTags(newTags || []);
        setPlan(data);
      })
      .catch(catchCancel)
      .catch(err => {
        if (err.response?.data?.validationErrors) {
          setError(`Error saving task: ${err.response.data.message}`);
        } else if (err.response?.data?.displayErrorMessage) {
          setError(err.response.data.displayErrorMessage);
        } else {
          parseServerError(dispatch)(err);
        }
      })
      .finally(() => setLoadingTags(false));
  };

  return (
    <>
      {loading ? (
        <Spinner />
      ) : (
        <div id="plan-view">
          <TagsModal
            open={currentOpenModal === 'tag'}
            close={closeModal}
            name={plan.name}
            selectedTags={updatedTags?.reduce((tagIds, tag) => {
              return `${tagIds},${tag.id}`;
            }, '')}
            returnSelectedTags={updatePlanTags}
          />
          <ConvertPlanModal
            open={currentOpenModal === 'convertToTask'}
            close={closeModal}
            currentPlan={plan}
          />
          <ConvertPlanToRecModal
            open={currentOpenModal === 'convertToRec'}
            close={closeModal}
            currentPlan={plan}
          />
          <EditProductModal
            open={currentOpenModal === 'product'}
            product={selProduct}
            handleClose={closeModal}
            displayFinancial={getDisplayByFinancialAccess()}
          />
          <DeletePlanModal
            open={currentOpenModal === 'delete'}
            close={closeModal}
            planName={plan?.name}
            planId={plan?.id}
          />
          <Breadcrumb disabled>
            <Breadcrumb.Item title="Plans" value="All Plans" to={paths.plans} />
            <Breadcrumb.Item
              title="Plan"
              value={plan.name || 'View Plan'}
              isLast
            />
          </Breadcrumb>
          <div className="flex">
            <TopHeader
              onAction={action => handleAction(action, plan.id)}
              fixedCost={getPlanFixedCost(plan)}
              variableCost={getVarCost(plan)}
              totalProjectedNetRevenue={getPlanProjNetRevenue(plan)}
              perAcreProjectedNetRevenue={getPlanProjNetRevenuePerArea(plan)}
              displayByFinancialAccess={getDisplayByFinancialAccess()}
              processing={downloadingPDF}
            />
          </div>
          <Paper>
            <div className="flex py-5">
              <div className="w-full">
                <Columns>
                  <Column width="w-full">
                    <div
                      data-testid="more-info-title"
                      className="text-2xl font-bold"
                    >
                      Plan Details
                    </div>
                  </Column>
                </Columns>
                <PlanDetailsSection
                  plan={plan}
                  displayTagsObject={displayTagsObject}
                  switchingTags={loadingTags}
                />
                <div className="w-full border-b py-5" />
              </div>
            </div>
            <div className="py-5">
              <div className="w-full">
                <Columns>
                  <Column width="w-full">
                    <div
                      data-testid="more-info-title"
                      className="text-2xl font-bold"
                    >
                      Fixed Cost
                    </div>
                  </Column>
                </Columns>
                <FixedCostSection
                  plan={plan}
                  displayFinancials={getDisplayByFinancialAccess()}
                />
                <div className="w-full border-b py-5" />
              </div>
            </div>
            <div className="py-5" id="pagebreaker-1">
              <div className="w-full">
                <Columns>
                  <Column width="w-full">
                    <div
                      data-testid="more-info-title"
                      className="text-2xl font-bold"
                    >
                      Projected Revenue
                    </div>
                  </Column>
                </Columns>
                <ProjectedRevenueSection
                  plan={plan}
                  displayFinancials={getDisplayByFinancialAccess()}
                />
                <div className="w-full border-b py-5" />
              </div>
            </div>
            <div className="py-5">
              <div className="w-full">
                <Columns>
                  <Column width="w-full">
                    <div
                      data-testid="more-info-title"
                      className="text-2xl font-bold"
                    >
                      Properties
                    </div>
                    <br />
                  </Column>
                </Columns>
                <PropertiesTable cropZones={plan.cropZones} />
                <div className="w-full border-b py-5" />
              </div>
            </div>
            <div className="py-5" id="pagebreaker-2">
              <div className="w-full">
                <Columns>
                  <Column width="w-full">
                    <div
                      data-testid="more-info-title"
                      className="text-2xl font-bold"
                    >
                      Products & Services
                    </div>
                    <div data-testid="more-info-title" className="text-sm">
                      By Rate Application
                    </div>
                    <br />
                  </Column>
                </Columns>
                <ProductsTable
                  products={plan.planProductEntries}
                  handleProductModal={handleProductModal}
                  displayFinancials={getDisplayByFinancialAccess()}
                />
                <div className="w-full border-b py-5" />
              </div>
            </div>
            <div className="py-5">
              <div className="w-full">
                <Columns>
                  <Column width="w-full">
                    <div
                      data-testid="more-info-title"
                      className="text-2xl font-bold"
                    >
                      Notes
                    </div>
                    <br />
                  </Column>
                </Columns>
                <TextArea
                  data-testid="plan-notes"
                  className="h-64 overscroll-auto"
                  resizeDisabled
                  disabled
                  value={plan.notes}
                />
                <div className="w-full border-b py-5" />
              </div>
            </div>
          </Paper>
        </div>
      )}
    </>
  );
};

export default PlanView;
