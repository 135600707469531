/* eslint-disable no-await-in-loop */
import React, {
  useState,
  useEffect,
  useCallback,
  useRef,
  useContext
} from 'react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';
import { Spinner, Button } from '@agconnections/grow-ui';
import {
  UPLOADED_FILES,
  IMPORTED_FIELDS,
  IS_FIELD_IMPORT_SUCCESSFUL
} from 'reducers/reducer';
import { Context } from 'components/Store';
import PortalModal from 'components/Modals/PortalModal';

import { paths } from 'routes/paths';
import useFarmData from '../../hooks/useFarmData';
import useFieldData from '../../hooks/useFieldData';

import {
  processFiles,
  fieldToFeature,
  convertFeaturesToBaseGeometry
} from './fieldImporterHelper';
import { getFeaturesCentroidCoordinates } from '../../helpers/mapApiHelpers';

import FieldImporterFiles from './FieldImporterFiles';
import FieldImporterTable from './FieldImporterTable';
import FieldImporterUploader from './FieldImporterUploader';

const ImportPropertyModal = ({ open, close, reload, importedFields = [] }) => {
  const needToRefresh = useRef(false);
  const history = useHistory();
  const [{ uploadedFiles }, dispatch] = useContext(Context);

  const [files, setFiles] = useState(uploadedFiles || []);
  const [fields, setFields] = useState(importedFields || []);

  const [selectedFields, setSelectedFields] = useState([]);

  const [errors, setErrors] = useState([]);
  const [isLoading, setLoading] = useState(false);

  const { createField } = useFieldData();

  const createdFarms = useRef([]);
  const { farms, createFarm, loading: loadingFarms } = useFarmData();

  useEffect(() => {
    if (files.length === 0) {
      setFields([]);
      setErrors([]);
      setSelectedFields([]);
      return;
    }

    setLoading(true);
    processFiles(files).then(({ fields: newFields, errors: newErrors }) => {
      setFields(importedFields.length > 0 ? importedFields : newFields);
      setErrors(newErrors);
      setLoading(false);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [files]);

  const handleOnFileSelect = useCallback(
    newFiles => {
      setFiles(newFiles);
      dispatch({ type: UPLOADED_FILES, payload: newFiles });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [setFiles]
  );

  const handleDeleteFile = useCallback(
    filename => {
      const index = files.findIndex(({ name }) => filename === name);
      const newFiles = [...files];
      newFiles.splice(index, 1);

      setFiles(newFiles);
      setFields([]);
      setErrors([]);
      setSelectedFields([]);
      dispatch({ type: UPLOADED_FILES, payload: newFiles });
      dispatch({ type: IMPORTED_FIELDS, payload: [] });
      dispatch({ type: IS_FIELD_IMPORT_SUCCESSFUL, payload: false });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [files]
  );

  const handleOnSelectedField = useCallback(
    newSelectedFields => {
      setSelectedFields(newSelectedFields);
    },
    [setSelectedFields]
  );

  const insertFarm = async farmName => {
    return createFarm({
      name: farmName,
      timeZone: 'US/Central',
      referencePoint: {
        type: 'Point',
        coordinates: [0, 0]
      }
    });
  };

  const getFarm = async farmName => {
    let farm = farms.find(({ name }) => name === farmName);

    if (!farm) {
      farm = createdFarms.current.find(({ name }) => name === farmName);
    }

    if (!farm) {
      farm = await insertFarm(farmName);
      createdFarms.current.push(farm);
    }

    return farm;
  };

  const insertField = async (field, farm) => {
    const feature = fieldToFeature(field);
    await createField({
      name: field.name,
      propertyId: farm.id,
      coordinates: getFeaturesCentroidCoordinates([feature]).toString(),
      geometry: convertFeaturesToBaseGeometry(feature),
      reportedArea: 0,
      reportedAreaUnit: 'acre'
    });
  };

  const updateFields = fieldsToBeUpdated => {
    const newFields = [...fields];

    for (let i = 0; i < fieldsToBeUpdated.length; i += 1) {
      const { field, error, imported } = fieldsToBeUpdated[i];
      const index = fields.findIndex(
        f => f.name === field.name && f.farm === field.farm
      );
      newFields[index] = { ...fields[index], error, imported };
    }

    dispatch({
      type: IMPORTED_FIELDS,
      payload: newFields.filter(eachField => eachField.imported !== true)
    });
    setFields(newFields.filter(eachField => eachField.imported !== true));
  };

  const handleClose = () => {
    setFiles([]);
    setFields([]);
    setErrors([]);
    setSelectedFields([]);
    setLoading(false);
    dispatch({ type: UPLOADED_FILES, payload: [] });
    dispatch({ type: IMPORTED_FIELDS, payload: [] });
    dispatch({ type: IS_FIELD_IMPORT_SUCCESSFUL, payload: false });
    close();

    if (needToRefresh.current) {
      needToRefresh.current = false;
      reload();
    }
  };

  const handleImport = async () => {
    let thereWasAnError = false;
    const fieldsToBeUpdated = [];
    setLoading(true);
    for (let i = 0; i < selectedFields.length; i += 1) {
      const field = selectedFields[i];
      try {
        let farm;
        try {
          farm = await getFarm(field.farm);
        } catch (error) {
          const message =
            error?.response?.data?.displayErrorMessage || error?.message;
          throw new Error(`[Create Farm] Error: ${message}`);
        }
        try {
          await insertField(field, farm);
        } catch (error) {
          // removing reference to error thrown by Base for now.
          // eslint-disable-next-line no-unused-vars
          const message =
            error?.response?.data?.displayErrorMessage || error?.message;
          throw new Error(`Upload Error`);
        }
        fieldsToBeUpdated.push({ field, imported: true });
      } catch (error) {
        fieldsToBeUpdated.push({ field, error: error.message });
        thereWasAnError = true;
      }

      needToRefresh.current = true;
    }

    if (needToRefresh.current && !thereWasAnError) {
      handleClose();
      return;
    }

    updateFields(fieldsToBeUpdated);
    setLoading(false);
  };

  const handleDeleteField = useCallback(
    index => {
      const filteredFields = fields.filter((field, i) => i !== index);
      setFields(filteredFields);
    },
    [fields]
  );

  const handleEditField = field => {
    history.push({
      pathname: `${paths.properties}/edit/${field?.fieldId}`,
      state: { label: 'FROM_IMPORT_FIELD', selectedField: field }
    });
  };

  const handleReloadField = async (field, index) => {
    let thereWasAnError = false;
    const currentFields = fields.filter((element, fIndex) => fIndex !== index);
    setLoading(true);
    try {
      let farm;
      try {
        farm = await getFarm(field.farm);
      } catch (error) {
        const message =
          error?.response?.data?.displayErrorMessage || error?.message;
        throw new Error(`[Create Farm] Error: ${message}`);
      }
      try {
        await insertField(field, farm);
      } catch (error) {
        // removing reference to error thrown by Base for now.
        // eslint-disable-next-line no-unused-vars
        const message =
          error?.response?.data?.displayErrorMessage || error?.message;
        throw new Error(`Upload Error`);
      }
      currentFields.splice(index, 0, { field, imported: true });
    } catch (error) {
      currentFields.splice(index, 0, { field, error: error.message });
      thereWasAnError = true;
    }

    needToRefresh.current = true;

    if (needToRefresh.current && !thereWasAnError) {
      handleClose();
      return;
    }

    updateFields(currentFields);
    setLoading(false);
  };

  return (
    <PortalModal
      title="Import Properties"
      open={open}
      type="buttonless"
      close={handleClose}
    >
      <div className="mr-12">
        {isLoading || loadingFarms ? (
          <Spinner ariaLabel="spinner" />
        ) : (
          <>
            {files.length === 0 && (
              <FieldImporterUploader onSelect={handleOnFileSelect} />
            )}
            {files.length > 0 && (
              <FieldImporterFiles
                files={files}
                errors={errors}
                onDeleteField={handleDeleteField}
                onDeleteFile={handleDeleteFile}
              />
            )}
            {fields.length > 0 && (
              <FieldImporterTable
                fields={fields}
                onDeleteField={handleDeleteField}
                onSelectedField={handleOnSelectedField}
                onEditField={handleEditField}
                onReloadField={handleReloadField}
              />
            )}
          </>
        )}
        <div className="flex justify-end">
          <div className="flex justify-between">
            <div className="px-1">
              <Button
                disabled={isLoading || loadingFarms}
                type="outline"
                onClick={() => handleClose()}
                ariaLabel="cancel"
              >
                Cancel
              </Button>
            </div>
            <div className="px-1">
              <Button
                ariaLabel="import"
                type="primary"
                disabled={!selectedFields?.length || isLoading || loadingFarms}
                onClick={handleImport}
              >
                Import
              </Button>
            </div>
          </div>
        </div>
      </div>
    </PortalModal>
  );
};

ImportPropertyModal.defaultProps = {
  importedFields: []
};

ImportPropertyModal.propTypes = {
  open: PropTypes.bool.isRequired,
  close: PropTypes.func.isRequired,
  reload: PropTypes.func.isRequired,
  importedFields: PropTypes.arrayOf(PropTypes.object)
};

export default ImportPropertyModal;
