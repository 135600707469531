import React from 'react';
import PropTypes from 'prop-types';
import { Column, Columns, Tag, TagCloud } from '@agconnections/grow-ui';
import ViewOnlyField from 'components/ViewOnlyField';
import { recommendationViewFormat } from 'helpers/dateFormat';
import useCropSeasons from 'hooks/useCropSeasons';
import useTagsData from 'hooks/useTagsData';

const DetailsSection = ({ recommendation }) => {
  const { cropSeasons, loaded: isCropSeasonsLoaded } = useCropSeasons();
  const { tags, loading: isTagsLoading } = useTagsData();

  const renderTags = () => {
    if (
      isTagsLoading ||
      !(recommendation?.flagIds || recommendation?.taskCreated)
    ) {
      return null;
    }
    return (
      <TagCloud>
        {[
          ...(recommendation?.taskCreated
            ? [
                <Tag key="task-created" color="#73DB78">
                  Task Created
                </Tag>
              ]
            : []),
          ...(recommendation?.flagIds || []).map(id => {
            const tag = tags.find(t => t.id === id);
            return (
              <Tag key={id} color={tag?.color}>
                {tag?.name}
              </Tag>
            );
          })
        ]}
      </TagCloud>
    );
  };

  return (
    <div data-testid="recommendation-details">
      <div className="flex justify-between pt-4 mb-8">
        <div className="text-2xl font-bold">Recommendation Details</div>
      </div>
      <Columns>
        <Column>
          <div className="mb-6">
            <ViewOnlyField
              header="Recommendation ID"
              data={recommendation.title}
              testId="recommendationId"
            />
          </div>
        </Column>
      </Columns>
      <Columns>
        <Column width="w-1/3">
          <div className="mb-6">
            <ViewOnlyField
              header="Proposed Date"
              data={
                <div className="flex items-center justify-between">
                  <span>
                    {recommendationViewFormat(recommendation.proposedDateEpoch)}
                  </span>
                  <svg
                    width="53"
                    height="16"
                    viewBox="0 0 53 16"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M52.7071 8.7071C53.0976 8.31658 53.0976 7.68341 52.7071 7.29289L46.3431 0.928928C45.9526 0.538404 45.3195 0.538404 44.9289 0.928928C44.5384 1.31945 44.5384 1.95262 44.9289 2.34314L50.5858 8L44.9289 13.6569C44.5384 14.0474 44.5384 14.6805 44.9289 15.0711C45.3195 15.4616 45.9526 15.4616 46.3431 15.0711L52.7071 8.7071ZM8.74228e-08 9L52 9L52 7L-8.74228e-08 7L8.74228e-08 9Z"
                      fill="#696F88"
                    />
                  </svg>
                  <span>&nbsp;</span>
                </div>
              }
              testId="proposedDate"
            />
          </div>
        </Column>
        <Column width="w-1/3">
          <div className="mb-6">
            <ViewOnlyField
              header="Expiration Date"
              data={recommendationViewFormat(
                recommendation.expirationDateEpoch
              )}
              testId="expirationDate"
            />
          </div>
        </Column>
      </Columns>
      <Columns>
        <Column width="w-1/3">
          <div className="mb-6">
            <ViewOnlyField
              header="Authorization By"
              data={recommendation.authorisedByName}
              testId="authorizedBy"
            />
          </div>
        </Column>
        <Column width="w-1/3">
          <div className="mb-6">
            <ViewOnlyField
              header="Authorization Date/Time"
              data={recommendationViewFormat(recommendation?.authorisedOnEpoch)}
              testId="authorizedOn"
            />
          </div>
        </Column>
        <Column width="w-1/3">
          <div className="mb-6">
            <ViewOnlyField
              header="Recommendation Number"
              data={recommendation.number}
              testId="number"
            />
          </div>
        </Column>
      </Columns>
      <Columns>
        <Column width="w-1/3">
          <div className="mb-6">
            <ViewOnlyField
              header="Timing Event"
              data={recommendation.timingEvent}
              testId="timingEvent"
            />
          </div>
        </Column>
        <Column width="w-1/3">
          <div className="mb-6">
            {/* TODO: Add field once backend supports it. */}
            <ViewOnlyField
              header="Created On"
              // eslint-disable-next-line react/prop-types
              data={recommendationViewFormat(recommendation.createdOn)}
              testId="createdOn"
            />
          </div>
        </Column>
      </Columns>
      <Columns>
        <Column width="w-1/3">
          <div className="mb-6">
            <ViewOnlyField
              header="Crop Season"
              data={
                isCropSeasonsLoaded && (
                  <TagCloud>
                    {recommendation.cropSeasonIds.map(id => {
                      const cropSeason = cropSeasons?.find(cs => cs.id === id);
                      return <Tag key={id}>{cropSeason?.name || ''}</Tag>;
                    })}
                  </TagCloud>
                )
              }
              testId="cropSeasons"
            />
          </div>
        </Column>
        <Column width="w-1/3">
          <div className="mb-6">
            <ViewOnlyField header="Tags" data={renderTags()} testId="flags" />
          </div>
        </Column>
        <Column width="w-1/3">
          <div className="mb-6">
            <ViewOnlyField
              header="Source"
              data={
                recommendation?.source && (
                  <TagCloud>
                    {recommendation?.source?.map(source => (
                      <Tag key={source.id}>{source.name}</Tag>
                    ))}
                  </TagCloud>
                )
              }
              testId="source"
            />
          </div>
        </Column>
      </Columns>
    </div>
  );
};

DetailsSection.propTypes = {
  recommendation: PropTypes.shape({
    title: PropTypes.string,
    proposedDate: PropTypes.string,
    proposedDateEpoch: PropTypes.number,
    expirationDate: PropTypes.string,
    expirationDateEpoch: PropTypes.number,
    authorisedByName: PropTypes.string,
    authorisedOn: PropTypes.string,
    authorisedOnEpoch: PropTypes.number,
    number: PropTypes.string,
    timingEvent: PropTypes.string,
    cropSeasonIds: PropTypes.arrayOf(PropTypes.string),
    flagIds: PropTypes.arrayOf(PropTypes.string),
    source: PropTypes.string,
    taskCreated: PropTypes.bool
  }).isRequired
};

export default DetailsSection;
