import React from 'react';
import PropTypes from 'prop-types';
import { ChevronDown, Select } from 'syngenta-digital-cropwise-react-ui-kit';

import SearchIcon from 'assets/search.svg';

import './antd-override.css';

const search = str => {
  return str
    ? str
        .normalize('NFD')
        .replace(/[\u0300-\u036f]/g, '')
        .toLowerCase()
    : str;
};

const SelectWithSearch = ({
  onChange,
  options,
  defaultValue,
  disabled,
  placeholder
}) => (
  <div className="relative inline-block w-full select-with-search-dropdown">
    <div className="prefix-icon">
      <img alt="Search icon" src={SearchIcon} />
    </div>
    <Select
      disabled={disabled}
      size="middle"
      onChange={onChange}
      filterOption={(input, option) => {
        return search(option.title)?.includes(search(input));
      }}
      showSearch
      suffixIcon={<ChevronDown className="dark-blue" />}
      placeholder={placeholder}
      optionLabelProp="label"
      data-testid="select-with-search"
      options={options}
      defaultValue={defaultValue}
    />
  </div>
);

SelectWithSearch.defaultProps = {
  disabled: false,
  placeholder: '',
  defaultValue: null,
  options: []
};

SelectWithSearch.propTypes = {
  disabled: PropTypes.bool,
  placeholder: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  defaultValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  options: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      title: PropTypes.string,
      value: PropTypes.string,
      options: PropTypes.arrayOf(
        PropTypes.shape({
          label: PropTypes.string,
          title: PropTypes.string,
          value: PropTypes.string
        })
      )
    })
  )
};

export default SelectWithSearch;
