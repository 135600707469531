/* eslint-disable no-unused-expressions */
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Formik } from 'formik';
import PortalModal from 'components/Modals/PortalModal';
import { tags as tagsApi, tag as tagApi } from 'utilities/api';
import catchCancel from 'helpers/catchCancel';
import { parseServerError } from 'helpers/errorHelpers';
import { Button } from '@agconnections/grow-ui';
import ClearIcon from '@material-ui/icons/Clear';
import TagsLanding from './TagsLanding';
import TagsCreate from './TagsCreate';
import TagsDelete from './TagsDelete';

const TagsModal = ({
  open,
  close,
  name,
  selectedTags,
  returnSelectedTags,
  setFieldTouchedParent
}) => {
  const [showLanding, setShowLanding] = useState(true);
  const [showCreate, setShowCreate] = useState(false);
  const [showDelete, setShowDelete] = useState(false);
  const [tagsState, setTagsState] = useState([]);
  const [filteredTags, setFilteredTags] = useState(tagsState);
  const [selectedTag, setSelectedTag] = useState(null);
  const [isSaving, setIsSaving] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [disableCreateBtn, setDisableCreateBtn] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [selectedTagIds, setSelectedTagIds] = useState([]);
  const [tagsModified, setTagsModified] = useState(false);

  const showTagCreate = () => {
    setFieldTouchedParent;
    setShowLanding(false);
    setShowCreate(true);
  };

  const editTag = tagToEdit => {
    setIsEdit(true);
    setSelectedTag(tagToEdit);
    showTagCreate();
  };

  const createTag = () => {
    setSelectedTag(null);
    showTagCreate();
  };

  const updatedSelectedTagIds = tagIds => {
    setTagsModified(true);
    setSelectedTagIds(tagIds);
  };

  const backToLanding = resetForm => {
    if (resetForm) {
      resetForm();
    }
    setIsEdit(false);
    setDisableCreateBtn(false);
    setShowCreate(false);
    setShowDelete(false);
    setShowLanding(true);
  };

  const backToCreate = () => {
    setShowDelete(false);
    setShowCreate(true);
  };

  const checkForDups = (targetValue, tagId) => {
    const duplicates = tagsState.filter(
      tag =>
        tag.name.toLocaleLowerCase() === targetValue.toLocaleLowerCase() &&
        tag.id !== tagId
    );
    setDisableCreateBtn(duplicates?.length > 0);
  };

  const handleDelete = () => {
    setShowCreate(false);
    setShowDelete(true);
  };

  const closeModal = () => {
    setSelectedTagIds([]);
    close();
  };

  const returnTagsAndClose = () => {
    const destructTags = tagsState.map(tag => {
      const retTag = tag;
      delete retTag.description;
      return retTag;
    });
    const returnTags = destructTags.filter(tag =>
      selectedTagIds?.includes(tag.id)
    );
    returnSelectedTags(returnTags);
    closeModal();
  };

  const handleCloseModal = resetForm => {
    if (tagsModified) {
      resetForm();
      setTagsModified(false);
      returnTagsAndClose();
    } else {
      closeModal();
    }
  };

  const setResultsMessage = () => {
    let message =
      'No tags created. Tap on "Create a new tag" button to create one.';

    if (tagsState?.length > 0) {
      message = 'No results found';
    }

    return message;
  };

  const filterTags = filterValue => {
    const filtered =
      filterValue !== ''
        ? tagsState.filter(tag => {
            return tag.name
              .toLocaleLowerCase()
              .includes(filterValue.toLocaleLowerCase());
          })
        : tagsState;
    setFilteredTags(filtered);
  };

  const getTags = async () => {
    setIsLoading(true);
    const { promise } = tagsApi.fetch();
    await promise
      .then(response => {
        const tags = response.data.results;
        const tagsarr = [];
        tags?.forEach(tag => {
          if (
            tag?.name !== '|>' &&
            tag?.name !== '!' &&
            tag?.name !== 'v' &&
            tag?.name !== '?' &&
            tag?.name !== '[]'
          )
            tagsarr.push(tag);
        });
        setTagsState(tagsarr);
        setFilteredTags(tagsarr);
        setIsLoading(false);
      })
      .catch(catchCancel)
      .catch(err => {
        parseServerError(err);
        setIsLoading(false);
      });
  };

  const handleSave = async values => {
    setIsSaving(true);
    const tag = {
      id: values?.id,
      name: values?.tagName,
      description: '',
      color: values?.color
    };

    if (showCreate) {
      if (isEdit) {
        const { promise } = tagApi.update(tag.id, tag);
        await promise
          .then(() => {
            setTagsModified(true);
            setIsSaving(false);
            setShowCreate(false);
            getTags();
            setShowLanding(true);
          })
          .catch(catchCancel)
          .catch(err => {
            setIsSaving(false);
            parseServerError(err);
          });
      } else {
        const { promise } = tagApi.create(tag);
        await promise
          .then(() => {
            setIsSaving(false);
            setShowCreate(false);
            getTags();
            setShowLanding(true);
          })
          .catch(catchCancel)
          .catch(err => {
            setIsSaving(false);
            parseServerError(err);
          });
      }
    }

    if (showDelete) {
      const { promise } = tagApi.delete(tag.id, tag);
      await promise
        .then(() => {
          setTagsModified(true);
          setIsSaving(false);
          setShowDelete(false);
          getTags();
          setShowLanding(true);
        })
        .catch(catchCancel)
        .catch(err => {
          setIsSaving(false);
          parseServerError(err);
        });
    }
  };

  useEffect(() => {
    if (open) {
      setIsEdit(false);
      setDisableCreateBtn(false);
      setShowCreate(false);
      setShowDelete(false);
      setShowLanding(true);
      setSelectedTagIds(
        selectedTags?.length > 0 ? selectedTags?.split(',') : []
      );
      getTags();
    }

    return () => setTagsState([]);
  }, [selectedTags, open]);

  return (
    <>
      <PortalModal
        open={open}
        close={closeModal}
        onCancel={() => {
          backToLanding();
        }}
        onConfirm={() => returnTagsAndClose()}
        isSaving={isSaving}
        // confirmLabel="Select"
        type="buttonless"
      >
        <div className="-ml-6 mr-6">
          <Formik
            initialValues={{ tagName: '', color: '', id: '' }}
            onSubmit={(values, { resetForm }) => {
              handleSave(values);
              resetForm();
            }}
          >
            {({ /* values, */ handleSubmit, resetForm }) => (
              <div data-testid="tags">
                <div className="flex w-full text-right">
                  <div className="w-full" />
                  <Button
                    type="overlay"
                    ghost
                    icon={<ClearIcon />}
                    onClick={() => {
                      handleCloseModal(resetForm);
                    }}
                  />
                </div>
                <div>
                  {showLanding && (
                    <TagsLanding
                      data-testid="tags-landing"
                      name={name}
                      tags={filteredTags}
                      notFoundMessage={setResultsMessage()}
                      showCreate={createTag}
                      editTag={editTag}
                      filterTags={filterTags}
                      selectedTags={selectedTagIds}
                      setSelectedTagIds={updatedSelectedTagIds}
                      isLoading={isLoading}
                      selectButtonClick={returnTagsAndClose}
                      setFieldTouchedParent={setFieldTouchedParent}
                    />
                  )}
                </div>
                <div>
                  {showCreate && (
                    <TagsCreate
                      data-testid="tags-create"
                      backToLanding={backToLanding}
                      selectedTag={selectedTag}
                      resetForm={resetForm}
                      checkForDups={checkForDups}
                      disableCreateBtn={disableCreateBtn}
                      handleSubmit={handleSubmit}
                      handleDelete={handleDelete}
                    />
                  )}
                </div>
                <div>
                  {showDelete && (
                    <TagsDelete
                      data-test="tags-delete"
                      tagId={selectedTag.id}
                      backToLanding={backToCreate}
                      resetForm={resetForm}
                      handleSubmit={handleSubmit}
                    />
                  )}
                </div>
              </div>
            )}
          </Formik>
        </div>
      </PortalModal>
    </>
  );
};

TagsModal.defaultProps = {
  selectedTags: null,
  returnSelectedTags: () => {},
  setFieldTouchedParent: () => {},
  name: ''
};

TagsModal.propTypes = {
  open: PropTypes.bool.isRequired,
  close: PropTypes.func.isRequired,
  name: PropTypes.string,
  selectedTags: PropTypes.string,
  returnSelectedTags: PropTypes.func,
  setFieldTouchedParent: PropTypes.func
};

export default TagsModal;
