export const ROLES = {
  ADMIN: 'Admin',
  FULL_CONTROL: 'Full control',
  VIEW_ONLY: 'View Only'
};

const ROLE_PRECEDENCE = {
  [ROLES.ADMIN]: 1,
  [ROLES.FULL_CONTROL]: 2,
  [ROLES.VIEW_ONLY]: 3
};

export const FINANCIAL_ACCESSES = { WRITE: 'WRITE', READ: 'READ' };

const FINANCIAL_ACCESS_PROCEDECE = {
  [FINANCIAL_ACCESSES.WRITE]: 1,
  [FINANCIAL_ACCESSES.READ]: 2
};

const hasRoleAccess = (role, minAllowedRole) => {
  if (!role || !ROLE_PRECEDENCE[role]) return false;
  if (!minAllowedRole) return true;
  return ROLE_PRECEDENCE[role] <= ROLE_PRECEDENCE[minAllowedRole];
};

const hasFinancialAccess = (financialAccess, minFinancialAccess) => {
  if (!minFinancialAccess) return true;
  if (!financialAccess) return false;
  return (
    FINANCIAL_ACCESS_PROCEDECE[financialAccess] <=
    FINANCIAL_ACCESS_PROCEDECE[minFinancialAccess]
  );
};

const hasAccess = (
  { role, financialAccess } = {},
  { minAllowedRole, minFinancialAccess } = {}
) => {
  return (
    role === ROLES.ADMIN ||
    (hasRoleAccess(role, minAllowedRole) &&
      hasFinancialAccess(financialAccess, minFinancialAccess))
  );
};

export { hasAccess, hasFinancialAccess, hasRoleAccess };
