import CardInfo from 'components/CardInfo';
import React, { useContext } from 'react';
import { StorageLocationContext } from 'screens/YieldV2/StorageLocations/context/StorageLocationProvider';
import PersonIcon from 'assets/person.svg';
import CompanyIcon from 'assets/company.svg';

const Icons = {
  Company: CompanyIcon,
  People: PersonIcon
};

const StorageAdvancedAttributes = () => {
  const {
    state: { selectedId, data = [] }
  } = useContext(StorageLocationContext);
  const selected = data.find(x => x.id === selectedId);
  return (
    <div className="flex">
      <div className="w-1/2 pr-4">
        <CardInfo
          label="Owner"
          value={
            selected?.owner?.name && (
              <div className="flex items-center">
                <img
                  className="h-4"
                  alt="Field Icon"
                  src={Icons[selected?.owner?.type]}
                />
                <div className="pl-2">{selected?.owner?.name}</div>
              </div>
            )
          }
        />
        <CardInfo label="Year Built" value={selected?.yearBuilt} />
        <CardInfo label="Property Tax ID" value={selected?.propertyTaxId} />
      </div>
      <div className="w-1/2 pl-4">
        <CardInfo label="Status" value={selected?.status} />
        <CardInfo label="Quality" value={selected?.quality} />
      </div>
    </div>
  );
};

export default StorageAdvancedAttributes;
