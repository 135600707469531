import React, { useContext } from 'react';
import useCropSeasons from 'hooks/useCropSeasons';
import { parseClientError } from 'helpers/errorHelpers';
import { Context } from 'components/Store';
import { useFormikContext } from 'formik';
import CropSeasonDropdown from 'components/CropSeasonDropdown';
import CropSeasonDateRange from 'components/CropSeasonDateRange';
import MccainFooterIntegration from '../MccainFooterIntegration';

const MccainCropSeason = () => {
  const { values, setFieldValue } = useFormikContext();

  const { cropSeasons, loaded } = useCropSeasons();
  const [, dispatch] = useContext(Context);

  const handleCropSeasonSelect = seasonSelected => {
    setFieldValue('selectedCropSeasons', seasonSelected);
  };

  const handleValidationCropSeasonsDates = () => {
    if (values.cropSeasonStartDate >= values.cropSeasonEndDate) {
      parseClientError(dispatch)('Start Date Cannot be Greater than End Date');
      return false;
    }
    return true;
  };

  return (
    <div
      className="flex flex-col bg-white top-30 left-26 h-100 w-140 rounded-md mb-20 p-6 shadow-sm"
      data-testid="crop-season-mccain"
    >
      <h1 className="mb-6 ml-8 font-body text-base font-normal leading-8 tracking-tight text-left">
        Add crop seasons for your submission.
      </h1>
      <div className="w-2/3 ml-8">
        <CropSeasonDropdown
          allCropSeasonsData={cropSeasons}
          csLoaded={loaded}
          onSelect={handleCropSeasonSelect}
          isRequired
        />
      </div>
      <CropSeasonDateRange
        className="flex flex-row my-16 pb-6 gap-20 ml-8"
        isRequired
      />
      <MccainFooterIntegration
        onNextValidation={handleValidationCropSeasonsDates}
      />
    </div>
  );
};

export default MccainCropSeason;
