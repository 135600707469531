import { useContext, useEffect, useCallback, useState } from 'react';
import axios from 'axios';

import useOrganizationApi from 'hooks/useOrganizationApi';
import catchCancel from 'helpers/catchCancel';
import { parseServerError } from 'helpers/errorHelpers';
import { Context } from 'components/Store';
import { getAccessToken } from 'utilities/base-auth';
import { CROPWISE_PROXY_URL } from 'utilities/apiConstants';

const usePeopleData = () => {
  const peopleAPI = useOrganizationApi('people');
  const [{ organization }, dispatch] = useContext(Context);

  const [people, setPeople] = useState([]);
  const [isCreatingPerson, setIsCreatingPerson] = useState(null);

  const [loading, setLoading] = useState(true);

  const loadPeople = useCallback(() => {
    const { promise } = peopleAPI.fetch();
    promise
      .then(({ data }) => {
        if (data) setPeople(data);
        return data;
      })
      .catch(catchCancel)
      .catch(err => {
        parseServerError(dispatch)(err);
        return err;
      })
      .finally(() => {
        setLoading(false);
      });
  }, [peopleAPI, dispatch]);
  useEffect(() => loadPeople(), [loadPeople]);

  const createPerson = async values => {
    setIsCreatingPerson(true);
    const args = {
      headers: {
        'Content-Type': 'application/json',
        'cwf-context': JSON.stringify({
          organization_id: organization.id
        }),
        Authorization: `Bearer ${getAccessToken()}`
      }
    };
    const promise = axios.post(
      `${CROPWISE_PROXY_URL}/v1/base/people`,
      values,
      args
    );
    return promise
      .then(({ data }) => {
        setPeople(people.concat([data]));
        return data;
      })
      .catch(catchCancel)
      .finally(() => {
        setIsCreatingPerson(false);
      });
  };

  return { people, loading, createPerson, isCreatingPerson, loadPeople };
};

export default usePeopleData;
