import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { groupBy, mapValues, omit } from 'lodash';

const FieldListContainer = ({ yieldEntry }) => {
  const [fields, setFields] = useState({});
  useEffect(() => {
    const cropZones = yieldEntry.cropZones.map(cropZone => cropZone);
    const grouped = mapValues(groupBy(cropZones, 'farmName'), cropZone =>
      cropZone.map(data => omit(data, 'farmName'))
    );
    setFields(grouped);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div
      className="overflow-scroll shadow rounded"
      style={{
        width: '212px',
        height: '200px',
        background: 'white',
        position: 'absolute',
        left: '-209px',
        top: '-50px',
        zIndex: 1,
        padding: '0.625rem'
      }}
    >
      {Object.keys(fields).map(farm => (
        <div key={farm}>
          <h2 className="text-left text-neutral-600 text-lg mb-1">{farm}</h2>
          <ul>
            {fields[farm].map(field => (
              <li
                className="pl-3 text-left text-neutral-1000 mb-4"
                key={field.fieldName}
              >
                {field.fieldName}
              </li>
            ))}
          </ul>
        </div>
      ))}
    </div>
  );
};

FieldListContainer.propTypes = {
  yieldEntry: PropTypes.shape({
    entryName: PropTypes.string,
    unit: PropTypes.string,
    id: PropTypes.string,
    totalYield: PropTypes.number,
    avgYieldPerAcre: PropTypes.number,
    salePrice: PropTypes.number,
    grossRevenue: PropTypes.number,
    totalArea: PropTypes.number,
    grossRevenuePerAcre: PropTypes.number,
    commodityId: PropTypes.string,
    cropZones: PropTypes.arrayOf(
      PropTypes.shape({
        fieldName: PropTypes.string
      })
    )
  }).isRequired
};

export default FieldListContainer;
