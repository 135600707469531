import React from 'react';
import PropTypes from 'prop-types';
import { Button } from '@agconnections/grow-ui';
import StorageLocationIcon from 'assets/storage.svg';
import { getAbbreviatedUnit } from 'helpers/unitsAbbreviation';

import Chart from './StoragePieChart';
import SummaryCard from './SummaryCard';

const StorageLocationsWidget = ({
  cropName,
  chartValue,
  totalValue,
  totalUnit,
  storageLocationsCount,
  onSeeLoadsClick,
  isLoading,
  className
}) => (
  <SummaryCard
    className={className}
    isLoading={isLoading}
    header={
      <div
        className="flex justify-between py-3 px-4
      items-center"
        style={{ whiteSpace: 'nowrap' }}
      >
        <div className="flex gap-2 items-center">
          <img className="h-6 w-6" alt="Field Icon" src={StorageLocationIcon} />
          <div className="text-base whitespace-no-wrap">Storage Locations</div>
        </div>
        <div className="flex-1" />
        <Button type="outline" size="md" onClick={onSeeLoadsClick}>
          <span className="whitespace-no-wrap">See Loads in Storage</span>
        </Button>
      </div>
    }
    content={
      <div className="flex flex-col">
        <div className="mt-4 ml-8">
          You have loads in {storageLocationsCount} Storage Location
          {storageLocationsCount !== 1 ? 's' : ''}.
        </div>
        {storageLocationsCount > 0 && (
          <div className="flex">
            <div className="ml-8 mt-8">
              <div className="leading-5 font-semibold text-sm text-neutral">
                YIELD IN STORAGE
              </div>
              <div className="flex my-2">
                <div className="w-18px h-18px bg-blue-40 rounded-sm" />
                <div className="ml-2 text-sm text-black">{cropName}</div>
              </div>
              <div className="mt-2" />
              <div className="flex my-2">
                <div className="w-18px h-18px bg-neutral-70 rounded-sm" />
                <div className="ml-2 text-sm text-black">All Other Crops</div>
              </div>
            </div>
            <div className="flex-1" />
            <div className="mr-4 mt-4">
              <Chart value={chartValue} />
            </div>
          </div>
        )}
      </div>
    }
    footer={
      <div className="flex items-center py-3 px-4 justify-end">
        <div className="text-sm mr-3">Total quantity stored</div>
        <div className="text-lg mr-1 font-semibold">{totalValue}</div>
        <div className="text-sm">{getAbbreviatedUnit(totalUnit)}</div>
      </div>
    }
  />
);

StorageLocationsWidget.propTypes = {
  cropName: PropTypes.string.isRequired,
  chartValue: PropTypes.number.isRequired,
  totalValue: PropTypes.string.isRequired,
  totalUnit: PropTypes.string.isRequired,
  storageLocationsCount: PropTypes.number.isRequired,
  onSeeLoadsClick: PropTypes.func.isRequired,
  isLoading: PropTypes.bool.isRequired,
  className: PropTypes.string.isRequired
};

export default StorageLocationsWidget;
