import React from 'react';
import { Formik } from 'formik';
import IntegrationsProvider from 'screens/Integrations/context/IntegrationsProvider';
import Provider from '../context/Provider';
import BaseAgVendIntegration from '../BaseAgVendIntegration';

const AgVendParentProvider = () => {
  return (
    <IntegrationsProvider>
      <Provider>
        <Formik initialValues={{}}>{() => <BaseAgVendIntegration />}</Formik>
      </Provider>
    </IntegrationsProvider>
  );
};

export default AgVendParentProvider;
