import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Spinner, Tabs } from '@agconnections/grow-ui';
import PropertyYieldIcon from 'assets/property_yield_icon.svg';
import EmptyTableState from 'components/EmptyTableState';
import NoDocumentIcon from 'assets/no_documents_found.svg';
import { PropertyTypes } from 'helpers/propertyHelpers';
import useYieldCropOverview from './hooks/useYieldCropOverview';
import OverviewTab from './components/OverviewTab';
import LoadsTab from './components/LoadsTab';
import PropertyCardHeader from '../PropertyCardHeader';

const PropertyYield = ({ selectedProperty }) => {
  const { getYieldCropOverview, loading } = useYieldCropOverview();
  const [data, setData] = useState();
  const { id, type, cropzones } = selectedProperty;

  useEffect(() => {
    const params = {};
    if (type.toLowerCase() === PropertyTypes.FIELD) params.fieldId = id;
    else if (type.toLowerCase() === PropertyTypes.CROP_ZONE)
      params.cropZoneId = id;
    getYieldCropOverview(params).then(_data => setData(_data));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id, type]);

  const header = (
    <PropertyCardHeader
      title="Yield"
      description="All harvested yield for the selected crop season"
      icon={PropertyYieldIcon}
    />
  );

  if (loading)
    return (
      <>
        {header}
        <Spinner />
      </>
    );

  return (
    <>
      {header}
      {data ? (
        <Tabs>
          <Tabs.Tab label="Overview">
            <OverviewTab data={data} />
          </Tabs.Tab>
          <Tabs.Tab label="Loads">
            <LoadsTab cropZones={cropzones ?? [selectedProperty]} type={type} />
          </Tabs.Tab>
        </Tabs>
      ) : (
        <EmptyTableState
          title="No Yield Loads Found"
          overlayIcon={NoDocumentIcon}
        />
      )}
    </>
  );
};

PropertyYield.propTypes = {
  selectedProperty: PropTypes.shape({
    id: PropTypes.string.isRequired,
    type: PropTypes.string.isRequired,
    cropzones: PropTypes.arrayOf()
  }).isRequired
};

export default PropertyYield;
