import React from 'react';
import PropTypes from 'prop-types';
import { Table } from '@agconnections/grow-ui';
import { CROPZONE_ACTIVE_INGREDIENTS_TABLE } from '../../constants';

const ActiveIngredientRow = ({ ingredient }) => {
  const formatedValue = Number.isFinite(ingredient?.totalQuantity)
    ? ingredient.totalQuantity.toFixed(2)
    : 0;

  return (
    <Table.Row key={ingredient.id}>
      <Table.Cell>{ingredient.name}</Table.Cell>
      <Table.Cell>
        {`${formatedValue} 
          ${ingredient?.totalQuantityUnit ||
            CROPZONE_ACTIVE_INGREDIENTS_TABLE.defaultValueUnit}`}
      </Table.Cell>
    </Table.Row>
  );
};

ActiveIngredientRow.propTypes = {
  ingredient: PropTypes.shape({
    id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    totalQuantityUnit: PropTypes.string,
    totalQuantity: PropTypes.number
  }).isRequired
};

export default ActiveIngredientRow;
