/**
 * Converting density values between lbs/gal and kg/L.
 *
 * Used to address the issue "Incorrect Total Product value for Custom Products Due to Density Unit Mismatch".
 *
 * @module densityConversion
 */

import { Gallon, Pound } from '@agconnections/agc_unitconversion';
import { Maths } from 'helpers/maths';
/**
 * Converts density from lbs/gal to kg/L.
 *
 * The conversion is necessary because the Back-End and the conversion library (@agconnections/agc_unitconversion)
 * expect the density in kg/L, while the Front-End may handle densities in lbs/gal by default for user-experience purposes.
 *
 * @function
 * @param {number|string} density - The density value in lbs/gal.
 * @returns {number|null} - The converted density value in kg/L, or null if the input is invalid.
 */
export const convertDensityFromLbsPerGalToKgPerL = density => {
  if (!density && density !== 0) return null;
  const poundToKg = new Pound().ConversionFactor;
  const gallonTo = new Gallon().ConversionFactor;
  const convertedDensity = Maths.divideSafeRound(
    Maths.multiplyRound(Maths.parseFloatRound(density), poundToKg),
    gallonTo
  );
  return convertedDensity;
};

/**
 * Converts density from kg/L to lbs/gal.
 *
 * This function ensures that densities can be converted back to lbs/gal for display purposes on the Front-End,
 * even though they are stored and processed as kg/L in the Back-End.
 *
 * @function
 * @param {number|string} density - The density value in kg/L.
 * @returns {number|null} - The converted density value in lbs/gal, or null if the input is invalid.
 */
export const convertDensityFromKgPerLToLbsPerGal = density => {
  if (!density && density !== 0) return null;
  const poundToKg = new Pound().ConversionFactor;
  const gallonTo = new Gallon().ConversionFactor;
  const convertedDensity = Maths.divideSafeRound(
    Maths.multiplyRound(Maths.parseFloatRound(density), gallonTo),
    poundToKg
  );
  return convertedDensity;
};
