import axios from 'axios';

export const headers = {
  'Content-Type': 'application/x-www-form-urlencoded',
  // This represents the client_id and the client_secret formatted as client_id:client_secret encoded in base64
  authorization: `Basic ${process.env.REACT_APP_BASE_AUTH_HEADER}`
};

const baseURL = `https://${process.env.REACT_APP_CROPWISE_BASE_API_URL}`;

export const request = axios.create({
  baseURL,
  headers: { 'Cache-Control': 'no-cache' }
});
