import React from 'react';
import PropTypes from 'prop-types';

import { INTEGRATIONS } from 'screens/Integrations/helpers/constants';

import PropertyView from '../PropertyView';
import FarmIcon from '../../../../../components/Icons/components/FarmIcon';
import CropYearSelector from '../CropYearSelector';

const FarmList = ({ farmFieldTreeData, integrationName }) => {
  const shouldRenderCropYear = integrationName === INTEGRATIONS.simplot;

  const renderPropertiesView = farm => {
    if (integrationName === INTEGRATIONS.agrian) {
      return (
        <>
          {farm.fields.map(field => (
            <PropertyView
              key={field.id}
              property={field}
              integrationName={integrationName}
            />
          ))}
        </>
      );
    }

    return (
      <>
        {farm.fields.map(field =>
          field.cropzones.map(cropZone => (
            <PropertyView
              key={cropZone.id}
              property={cropZone}
              parentName={field.name}
              integrationName={integrationName}
            />
          ))
        )}
      </>
    );
  };

  return (
    <div className="grid grid-cols-10 h-full">
      <div className="col-span-7 mb-5">
        {farmFieldTreeData?.map(farm =>
          farm.fields.length > 0 &&
          farm.fields.some(f => f.cropzones.length > 0) ? (
            <div key={farm.id} className="mt-7">
              <div className="justify-self-start">
                <div className="flex flex-row items-center text-2xl font-semibold">
                  <FarmIcon />
                  <span data-testid="farm-name" className="ml-2">
                    {farm?.name}
                  </span>
                </div>
              </div>
              <div>{renderPropertiesView(farm)}</div>
            </div>
          ) : (
            <div key={farm.id} />
          )
        )}
      </div>
      {shouldRenderCropYear && (
        <div className="col-span-3 justify-self-end object-cover h-158 w-200">
          <CropYearSelector integrationName={integrationName} />
        </div>
      )}
    </div>
  );
};

FarmList.defaultProps = {
  farmFieldTreeData: [],
  integrationName: ''
};

FarmList.propTypes = {
  farmFieldTreeData: PropTypes.arrayOf(PropTypes.object),
  integrationName: PropTypes.string
};

export default FarmList;
