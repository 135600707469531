import { useContext, useEffect, useState, useCallback } from 'react';
import usePlansApi from 'hooks/usePlansApi';
import catchCancel from 'helpers/catchCancel';
import { parseServerError } from 'helpers/errorHelpers';
import { Context } from 'components/Store';

const usePlansData = () => {
  const plansAPI = usePlansApi('plans');
  const [, dispatch] = useContext(Context);
  const [plans, setPlans] = useState({});
  const [loading, setLoading] = useState(true);
  // const orgID = Context?._currentValue[0]?.organization?.id;
  const loadPlans = useCallback(() => {
    const { promise, cancel } = plansAPI.fetch();
    promise
      .then(({ data }) => {
        setPlans(data.results);
      })
      .catch(catchCancel)
      .catch(err => {
        parseServerError(dispatch)(err);
      })
      .finally(() => setLoading(false));

    return cancel;
  }, [dispatch, plansAPI]);

  useEffect(() => {
    const cancel = loadPlans();

    return () => {
      cancel();
    };
  }, [loadPlans]);

  const deletePlan = useCallback(
    plan => plansAPI.delete(plan._id).promise.then(loadPlans),
    [plansAPI, loadPlans]
  );

  return {
    plans,
    loading,
    loadPlans,
    deletePlan
  };
};

export default usePlansData;
