import React, { memo } from 'react';
import PropTypes from 'prop-types';

const InfoIcon = ({ size = 18, color = '#319795' }) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      data-testid="info-icon"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9 1.5C4.86 1.5 1.5 4.86 1.5 9C1.5 13.14 4.86 16.5 9 16.5C13.14 16.5 16.5 13.14 16.5 9C16.5 4.86 13.14 1.5 9 1.5ZM8.25 5.25V6.75H9.75V5.25H8.25ZM8.25 8.25V12.75H9.75V8.25H8.25ZM3 9C3 12.3075 5.6925 15 9 15C12.3075 15 15 12.3075 15 9C15 5.6925 12.3075 3 9 3C5.6925 3 3 5.6925 3 9Z"
        fill={color}
      />
    </svg>
  );
};

InfoIcon.propTypes = {
  color: PropTypes.string,
  size: PropTypes.number
};

InfoIcon.defaultProps = {
  color: '#319795',
  size: 18
};

export default memo(InfoIcon);
