import React from 'react';
import { Table } from '@agconnections/grow-ui';
import PropTypes from 'prop-types';
import CropZoneCell from '../CropZoneCell';

const AddedTableView = ({ massiveChangeSummary, mappedFarmView }) => {
  return (
    <Table>
      <Table.RowGroup arrowIcon>
        <Table.Row>
          <Table.Cell>
            <p className="font-semibold font-body" data-testid="view-by-crops">
              {massiveChangeSummary.crop.name}
            </p>
          </Table.Cell>
        </Table.Row>
        {Object.entries(mappedFarmView).map(farm => (
          <Table.RowGroup arrowIcon key={farm[0]}>
            <Table.Row>
              <Table.Cell>
                <p className="font-semibold font-body">{farm[0]}</p>
              </Table.Cell>
            </Table.Row>
            {farm[1].map(property => {
              return (
                <Table.RowGroup arrowIcon key={property.id}>
                  <Table.Row>
                    <Table.Cell>
                      <p className="font-semibold font-body">{property.name}</p>
                    </Table.Cell>
                  </Table.Row>
                  <Table.Row>
                    <Table.Cell>
                      <CropZoneCell
                        massiveChangeSummary={massiveChangeSummary}
                        property={property}
                        key={property.id}
                      />
                    </Table.Cell>
                  </Table.Row>
                </Table.RowGroup>
              );
            })}
          </Table.RowGroup>
        ))}
      </Table.RowGroup>
    </Table>
  );
};

AddedTableView.defaultProps = {
  massiveChangeSummary: {},
  mappedFarmView: []
};

AddedTableView.propTypes = {
  massiveChangeSummary: PropTypes.shape(),
  mappedFarmView: PropTypes.arrayOf(PropTypes.array)
};

export default AddedTableView;
