const calculateTimeEstimate = async (taskIds, options = {}) => {
  const transactionSpeedPerTask = options.transactionSpeedPerTask || 1.0101; // Time per task in ms for internal DB
  // Retrieve network conditions from the Network Information API
  let internetSpeedFactor;
  let networkOverhead;
  if (navigator.connection) {
    const { downlink, rtt } = navigator.connection;
    // Adjust factors based on connection type and speed
    internetSpeedFactor = downlink < 1 ? 2 : 1; // Increase factor for slower connections
    networkOverhead = rtt; // Use round-trip time as network overhead
  } else {
    // Fallback values for browsers that do not support the Network Information API
    internetSpeedFactor = 1.5; // Default multiplier for slower speeds
    networkOverhead = 100; // Default network overhead in ms per task
  }
  // Measure server response time as server latency
  const getServerLatency = async () => {
    const startTime = performance.now();
    await fetch('/ping'); // A lightweight API endpoint for testing server latency
    return performance.now() - startTime;
  };
  const serverLatency = options.serverLatency || (await getServerLatency());
  // Calculate internal processing time
  const baseMilliseconds = taskIds * transactionSpeedPerTask;
  // Adjust for network and server conditions
  const networkTime = taskIds * networkOverhead * internetSpeedFactor;
  const totalMilliseconds = baseMilliseconds + networkTime + serverLatency;
  // Convert the result to appropriate units
  if (totalMilliseconds < 60000) {
    return 'one minute';
  }
  if (totalMilliseconds < 3600000) {
    const minutes = Math.ceil(totalMilliseconds / 60000);
    return `${minutes} minutes`;
  }
  return 'a few hours';
};
export default calculateTimeEstimate;
