import React, { useState } from 'react';
import { Select } from '@agconnections/grow-ui';
import PropTypes from 'prop-types';

import {
  createEventHandlerWithTransform,
  getEventSelectedKey
} from 'utilities/formHelpers';
import ChangeSumaryFFT from '../ChangeSummaryFFT';

const VIEW_BY = [
  {
    key: 'crops',
    value: 'Crops'
  },
  {
    key: 'farm',
    value: 'Farm'
  }
];

const SelectedFields = ({ massiveChangeSummary }) => {
  const [viewBy, setViewBy] = useState('farm');

  const handleFilterChange = (event, value) => {
    setViewBy(value);
  };

  const handleSelect = createEventHandlerWithTransform(
    handleFilterChange,
    getEventSelectedKey
  );

  return (
    <div
      data-testid="selected-fields-summary"
      className="flex flex-col"
      style={{ height: 'calc(100% - 1px)' }}
    >
      <div className="sticky top-9 bg-white z-1 pt-2">
        <label
          htmlFor="viewBy"
          className="text-neutral-600 text-sm inline-block"
        >
          View By
        </label>
        <Select
          name="viewBy"
          placeholder="View By"
          items={VIEW_BY}
          value={viewBy}
          onChange={handleSelect}
        />
      </div>
      <div className="mt-4 overflow-y-auto h-full flex flex-grow">
        <ChangeSumaryFFT
          massiveChangeSummary={massiveChangeSummary}
          viewBy={viewBy}
        />
      </div>
    </div>
  );
};

SelectedFields.defaultProps = {
  massiveChangeSummary: {}
};

SelectedFields.propTypes = {
  massiveChangeSummary: PropTypes.shape()
};

export default SelectedFields;
