import React from 'react';
import { Tabs, Button, Spinner } from '@agconnections/grow-ui';
import PropTypes from 'prop-types';
import useCropZoneData from 'screens/Property/hooks/useCropZoneData';
import useCompanies from 'hooks/useCompanies';
import useCropData from 'hooks/useCropData';
import useFieldData from 'screens/Property/hooks/useFieldData';
import useFarmData from 'screens/Property/hooks/useFarmData';
import NoDocumentFound from 'assets/no_documents_found.svg';
import CropzoneIcon from 'assets/property_cropzone_icon.svg';
import CropIcon from 'assets/crop.svg';
import CompanyIcon from 'assets/company.svg';

import EmptyTableState from 'components/EmptyTableState';
import PropertyRow from '../PropertyRow';
import PropertyCardHeader from '../PropertyCardHeader';
import { coordinatesFormat } from '../../../helpers/fieldDataHelpers';

const PropertyCropZoneDetail = ({ id, farmId, fieldId, setEnableEdit }) => {
  const { companies, loading: companiesLoading } = useCompanies();
  const { cropZone, loading } = useCropZoneData(id);
  const { crops, loading: cropsLoading } = useCropData();
  const { farm, loading: farmLoading } = useFarmData(farmId);
  const { field, loading: fieldLoading } = useFieldData(fieldId);

  const getCropName = () => crops.find(x => x.id === cropZone.cropId)?.name;
  const getCompanyName = () =>
    companies.find(x => x.id === cropZone.seedSource)?.name;

  const isEverythingLoaded = () => {
    return (
      !loading &&
      !fieldLoading &&
      !farmLoading &&
      !cropsLoading &&
      !companiesLoading
    );
  };

  return (
    <div>
      <PropertyCardHeader
        title="Crop Zone Information"
        description="Specific property boundary relevant content not contained within
              any crop season"
        icon={CropzoneIcon}
        setEnableEdit={setEnableEdit}
      />
      {isEverythingLoaded() ? (
        <Tabs>
          <Tabs.Tab label="Location">
            <PropertyRow
              label="Center Latitude Longitude Point"
              value={coordinatesFormat(cropZone.coordinates)}
            />
          </Tabs.Tab>
          <Tabs.Tab label="Crop">
            <PropertyRow label="Crop" value={getCropName()} icon={CropIcon} />
            <PropertyRow
              label="Seed source"
              value={getCompanyName()}
              icon={CompanyIcon}
            />
            <PropertyRow label="Insurance ID" value={cropZone.insuranceId} />
            <PropertyRow label="Seed Lot ID" value={cropZone.seedLotId} />
          </Tabs.Tab>
          <Tabs.Tab label="FSA">
            <PropertyRow label="FSA Area" value={cropZone.fsaArea} />
          </Tabs.Tab>
          <Tabs.Tab label="Regulatory Permit">
            <PropertyRow
              label="Permit ID"
              value={cropZone.permitId ?? field.permitId ?? farm.permitId}
            />
            <PropertyRow
              label="Site ID"
              value={cropZone.siteId ?? field.siteId}
            />
            <PropertyRow
              label="Crop Zone Block ID"
              value={cropZone.cropZonePermitId}
            />
          </Tabs.Tab>
          <Tabs.Tab label="Land">
            <PropertyRow label="Tillage System" value={cropZone.tillage} />
          </Tabs.Tab>
          <Tabs.Tab label="Water">
            <PropertyRow
              label="Irrigated"
              value={cropZone.irrigated ?? field.irrigated}
            />
            <PropertyRow
              label="Water Source"
              value={cropZone.waterSource ?? field.waterSource}
            />
            <PropertyRow
              label="Water Origin"
              value={cropZone.waterOrigin ?? field.waterOrigin}
            />
            <PropertyRow
              label="Irrigation System"
              value={cropZone.tileDrainage ?? field.tileDrainage}
            />
            <PropertyRow
              label="Pump Pressure"
              value={cropZone.pumpPressure ?? field.pumpPressure}
            />
            <PropertyRow
              label="Pump Lift"
              value={cropZone.pumplife ?? field.pumpLift}
            />
            <PropertyRow
              label="Pump Energy Source"
              value={cropZone.energySource ?? field.energySource}
            />
            <PropertyRow
              label="Use Polyacrylamide (PAM)"
              value={cropZone.usePolyacrylamide}
            />
          </Tabs.Tab>
          <Tabs.Tab label="Notes">
            {cropZone?.notes ? (
              <p className="text-sm font-normal">{cropZone.notes}</p>
            ) : (
              <div className="mb-10">
                <EmptyTableState
                  title="No Notes Found"
                  overlayIcon={NoDocumentFound}
                  message="Edit your crop zone information to add notes."
                  button={
                    <Button
                      type="primary"
                      onClick={() => setEnableEdit(prev => !prev)}
                    >
                      Edit Crop Zone Information
                    </Button>
                  }
                />
              </div>
            )}
          </Tabs.Tab>
        </Tabs>
      ) : (
        <Spinner />
      )}
    </div>
  );
};

PropertyCropZoneDetail.propTypes = {
  id: PropTypes.string.isRequired,
  farmId: PropTypes.string.isRequired,
  fieldId: PropTypes.string.isRequired,
  setEnableEdit: PropTypes.func.isRequired
};

export default PropertyCropZoneDetail;
