import { useState, useContext, useEffect, useMemo } from 'react';
import { fieldToFeature } from 'screens/Property/PropertiesLanding/ImportPropertyModal/fieldImporterHelper';
import { PropertiesMatchContext } from '../PropertiesMatch/context/PropertiesMatchProvider';
import usePropertiesMatch from './usePropertiesMatch';
import { INTEGRATIONS } from '../helpers/constants';

const useVendorProperty = vendorPropertyId => {
  const {
    state: { integrationType, vendorProperties = [] }
  } = useContext(PropertiesMatchContext);

  const {
    getVendorPropertyGeometry,
    loading: isLoading
  } = usePropertiesMatch();

  const [geoJsonFeature, setGeoJsonFeature] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      const response = await getVendorPropertyGeometry({
        vendor:
          integrationType === INTEGRATIONS.agrianRecommendation
            ? INTEGRATIONS.agrian
            : integrationType,
        vendorPropertyId
      });

      if (response?.status === 200) {
        setGeoJsonFeature(fieldToFeature(response.data));
      }
    };

    fetchData();
  }, [getVendorPropertyGeometry, integrationType, vendorPropertyId]);

  const vendorProperty = useMemo(
    () => vendorProperties.find(item => item.id === vendorPropertyId),
    [vendorProperties, vendorPropertyId]
  );

  return {
    property: vendorProperty,
    feature: geoJsonFeature,
    isLoading
  };
};

export default useVendorProperty;
