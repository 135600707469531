import React from 'react';
import { Col } from 'syngenta-digital-cropwise-react-ui-kit';
import PropTypes from 'prop-types';
import RemoveCircle from 'assets/remove_circle.svg';
import ProductTypeDisplay from 'screens/ProductList/helpers/ProductTypeDisplay';
import ClickableDiv from 'components/ClickableDiv';

const SelectedProductsCart = ({ product, handleOnEdit, handleOnRemove }) => {
  return (
    <Col
      span={24}
      className="flex hover:bg-info-light-blue flex-row h-60px items-center border-opacity-50"
      style={{ border: '0.5px solid #D8D8DA' }}
    >
      <Col
        span={4}
        className="flex flex-row justify-center items-center h-full"
      >
        <ClickableDiv onClick={() => handleOnRemove(product)}>
          <img className="mr-1" src={RemoveCircle} alt="remove icon" />
        </ClickableDiv>
      </Col>
      <Col
        span={10}
        className="flex flex-col justify-center h-full cursor-pointer"
        onClick={() => handleOnEdit(product)}
      >
        <p className="text-sm font-bold">{product?.name}</p>
        <p>{product?.manufacturer}</p>
      </Col>
      <Col
        span={10}
        className="flex flex-row justify-start items-center h-full cursor-pointer"
        onClick={() => handleOnEdit(product)}
      >
        <ProductTypeDisplay productType={product.producttype ?? product.type} />
      </Col>
    </Col>
  );
};

SelectedProductsCart.propTypes = {
  product: PropTypes.shape({
    name: PropTypes.string,
    manufacturer: PropTypes.string,
    producttype: PropTypes.string,
    type: PropTypes.string,
    isInUse: PropTypes.bool
  }).isRequired,
  handleOnEdit: PropTypes.func.isRequired,
  handleOnRemove: PropTypes.func.isRequired
};

export default SelectedProductsCart;
