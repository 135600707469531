import { ROLES } from 'utilities/access';

import Plans from 'screens/Plans';
import Plan from 'screens/Plans/Plan';
import PlanView from 'screens/Plans/components/PlanView';

import { PlanIcon } from 'routes/icons';
import { paths } from 'routes/paths';

const routes = [
  {
    id: 'plans',
    key: 'plans',
    label: 'Plans',
    path: paths.plans,
    exact: true,
    secure: true,
    disabled: false,
    component: Plans,
    icon: PlanIcon,
    nav: 'leftNav'
  },
  {
    id: 'planEntry',
    key: 'planEntry',
    path: `${paths.plans}/:id`,
    exact: true,
    secure: true,
    disabled: false,
    component: Plan,
    minPermissions: {
      minAllowedRole: ROLES.FULL_CONTROL
    }
  },
  {
    id: 'planView',
    key: 'planView',
    path: `${paths.plans}/:id/view`,
    exact: true,
    secure: true,
    disabled: false,
    component: PlanView
  }
];

export default routes;
