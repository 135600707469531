const canGoNext = (values, currentStep) => {
  switch (currentStep) {
    case 1:
      return values.cropZones.length > 0;
    default:
      return false;
  }
};

export default canGoNext;
