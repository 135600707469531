/* eslint-disable react/prop-types */
import React from 'react';
import { useParams } from 'react-router-dom';
import { Columns, Column, Form, Input } from '@agconnections/grow-ui';
import GrowUIFormField from 'components/Forms/GrowUIFormField';
import { useFormikContext } from 'formik';
import { getFixedCostValuesStatus } from '../../../../../helpers/permissionsHelpers';
import { unmaskPrice } from '../../../../../helpers/dataHelpers';

const FixedCostSection = ({ memberRole, memberFinancialAccess }) => {
  const { id } = useParams();
  return (
    <>
      <div className="flex items-top">
        <Columns wrap>
          <Column width="w-full">
            <div className="border-r">
              <div className="w-full flex-grow mr-4">
                <LandRent
                  memberRole={memberRole}
                  memberFinancialAccess={memberFinancialAccess}
                  planId={id}
                />
              </div>
              <div className="w-full flex-grow mr-4">
                <Repairs
                  memberRole={memberRole}
                  memberFinancialAccess={memberFinancialAccess}
                  planId={id}
                />
              </div>
              <div className="w-full flex-grow mr-4">
                <ReturnToManagement
                  memberRole={memberRole}
                  memberFinancialAccess={memberFinancialAccess}
                  planId={id}
                />
              </div>
            </div>
          </Column>
        </Columns>
        <Columns wrap>
          <Column width="w-full">
            <div className="ml-4">
              <div className="border-r">
                <div className="w-full flex-grow mr-4">
                  <Equipment
                    memberRole={memberRole}
                    memberFinancialAccess={memberFinancialAccess}
                    planId={id}
                  />
                </div>
                <div className="w-full flex-grow mr-4">
                  <Taxes
                    memberRole={memberRole}
                    memberFinancialAccess={memberFinancialAccess}
                    planId={id}
                  />
                </div>
              </div>
            </div>
          </Column>
        </Columns>
        <Columns wrap>
          <Column width="w-full">
            <div className="ml-4">
              <div className="w-full flex-grow mr-4">
                <Labor
                  memberRole={memberRole}
                  memberFinancialAccess={memberFinancialAccess}
                  planId={id}
                />
              </div>
              <div className="w-full flex-grow mr-4">
                <Insurance
                  memberRole={memberRole}
                  memberFinancialAccess={memberFinancialAccess}
                  planId={id}
                />
              </div>
            </div>
          </Column>
        </Columns>
      </div>
    </>
  );
};

const LandRent = ({ memberRole, memberFinancialAccess, planId }) => {
  const { values, setFieldValue, setFieldTouched } = useFormikContext();
  let disable = false;
  disable =
    (memberRole === 'Full control' && memberFinancialAccess === 'none') ||
    (memberRole === 'Full control' && memberFinancialAccess === 'READ');
  return (
    <Form.Field label="Land Rent">
      <Column width="w-full">
        <div className="w-full flex items-center">
          <div
            data-testid="LandRent_Total"
            className="w-full flex-grow mt-8 mr-4"
          >
            <GrowUIFormField
              control={Input}
              label="Total"
              id="fixedCost.landRent"
              name="fixedCost.landRent"
              disabled={disable}
              value={getFixedCostValuesStatus(
                memberRole,
                memberFinancialAccess,
                planId,
                values?.fixedCost?.landRent
              )}
              onBlur={e => {
                setFieldTouched('fixedCost.landRent');
                setFieldValue(
                  'fixedCost.landRent',
                  unmaskPrice(e.target.value)
                );
                if (unmaskPrice(e.target.value)) {
                  setFieldValue(
                    'fixedCost.landRent_PerAc',
                    parseFloat(
                      unmaskPrice(e.target.value) / values?.area
                    ).toFixed(2)
                  );
                } else {
                  setFieldValue(
                    'fixedCost.landRent_PerAc',
                    unmaskPrice(e.target.value)
                  );
                }
              }}
              imask={{
                mask: '\\$num',
                lazy: false,
                blocks: {
                  num: {
                    mask: Number,
                    radix: '.',
                    mapToRadix: ['.'],
                    normalizeZeros: false,
                    thousandsSeparator: ','
                  }
                }
              }}
            />
          </div>
          <div
            data-testid="LandRent_PerAc"
            className="w-full flex-grow mt-8 mr-4"
          >
            <GrowUIFormField
              control={Input}
              label="Per Ac"
              id="fixedCost.landRent_PerAc"
              name="fixedCost.landRent_PerAc"
              disabled={disable}
              value={getFixedCostValuesStatus(
                memberRole,
                memberFinancialAccess,
                planId,
                values?.fixedCost?.landRent_PerAc
              )}
              onBlur={e => {
                setFieldTouched('fixedCost.landRent_PerAc');
                setFieldValue(
                  'fixedCost.landRent_PerAc',
                  unmaskPrice(e.target.value)
                );
                if (unmaskPrice(e.target.value)) {
                  setFieldValue(
                    'fixedCost.landRent',
                    parseFloat(
                      unmaskPrice(e.target.value) * values?.area
                    ).toFixed(2)
                  );
                } else {
                  setFieldValue(
                    'fixedCost.landRent',
                    unmaskPrice(e.target.value)
                  );
                }
              }}
              imask={{
                mask: '\\$num',
                lazy: false,
                blocks: {
                  num: {
                    mask: Number,
                    radix: '.',
                    mapToRadix: ['.'],
                    normalizeZeros: false,
                    thousandsSeparator: ','
                  }
                }
              }}
            />
          </div>
        </div>
      </Column>
    </Form.Field>
  );
};

const Repairs = ({ memberRole, memberFinancialAccess, planId }) => {
  const { values, setFieldValue, setFieldTouched } = useFormikContext();
  let disable = false;
  disable =
    (memberRole === 'Full control' && memberFinancialAccess === 'none') ||
    (memberRole === 'Full control' && memberFinancialAccess === 'READ');
  return (
    <Form.Field label="Repairs">
      <Column width="w-full">
        <div className="w-full flex items-center">
          <div className="w-full flex-grow mt-8 mr-4">
            <GrowUIFormField
              control={Input}
              label="Total"
              id="fixedCost.repairs"
              name="fixedCost.repairs"
              value={getFixedCostValuesStatus(
                memberRole,
                memberFinancialAccess,
                planId,
                values?.fixedCost?.repairs
              )}
              disabled={disable}
              onBlur={e => {
                setFieldTouched('fixedCost.repairs');
                setFieldValue('fixedCost.repairs', unmaskPrice(e.target.value));
                if (unmaskPrice(e.target.value)) {
                  setFieldValue(
                    'fixedCost.repairs_PerAc',
                    parseFloat(
                      unmaskPrice(e.target.value) / values?.area
                    ).toFixed(2)
                  );
                } else {
                  setFieldValue(
                    'fixedCost.repairs_PerAc',
                    unmaskPrice(e.target.value)
                  );
                }
              }}
              imask={{
                mask: '\\$num',
                lazy: false,
                blocks: {
                  num: {
                    mask: Number,
                    radix: '.',
                    mapToRadix: ['.'],
                    normalizeZeros: false,
                    thousandsSeparator: ','
                  }
                }
              }}
            />
          </div>
          <div className="w-full flex-grow mt-8 mr-4">
            <GrowUIFormField
              control={Input}
              label="Per Ac"
              id="fixedCost.repairs_PerAc"
              name="fixedCost.repairs_PerAc"
              disabled={disable}
              value={getFixedCostValuesStatus(
                memberRole,
                memberFinancialAccess,
                planId,
                values?.fixedCost?.repairs_PerAc
              )}
              onBlur={e => {
                setFieldTouched('fixedCost.repairs_PerAc');
                setFieldValue(
                  'fixedCost.repairs_PerAc',
                  unmaskPrice(e.target.value)
                );
                if (unmaskPrice(e.target.value)) {
                  setFieldValue(
                    'fixedCost.repairs',
                    parseFloat(
                      unmaskPrice(e.target.value) * values?.area
                    ).toFixed(2)
                  );
                } else {
                  setFieldValue(
                    'fixedCost.repairs',
                    unmaskPrice(e.target.value)
                  );
                }
              }}
              imask={{
                mask: '\\$num',
                lazy: false,
                blocks: {
                  num: {
                    mask: Number,
                    radix: '.',
                    mapToRadix: ['.'],
                    normalizeZeros: false,
                    thousandsSeparator: ','
                  }
                }
              }}
            />
          </div>
        </div>
      </Column>
    </Form.Field>
  );
};

const ReturnToManagement = ({ memberRole, memberFinancialAccess, planId }) => {
  const { values, setFieldValue, setFieldTouched } = useFormikContext();
  let disable = false;
  disable =
    (memberRole === 'Full control' && memberFinancialAccess === 'none') ||
    (memberRole === 'Full control' && memberFinancialAccess === 'READ');
  return (
    <Form.Field label="Return to Management">
      <Column width="w-full">
        <div className="w-full flex items-center">
          <div className="w-full flex-grow mt-8 mr-4">
            <GrowUIFormField
              control={Input}
              label="Total"
              id="fixedCost.returnToMgt"
              name="fixedCost.returnToMgt"
              value={getFixedCostValuesStatus(
                memberRole,
                memberFinancialAccess,
                planId,
                values?.fixedCost?.returnToMgt
              )}
              disabled={disable}
              onBlur={e => {
                setFieldTouched('fixedCost.returnToMgt');
                setFieldValue(
                  'fixedCost.returnToMgt',
                  unmaskPrice(e.target.value)
                );
                if (unmaskPrice(e.target.value)) {
                  setFieldValue(
                    'fixedCost.returnToMgt_PerAc',
                    parseFloat(
                      unmaskPrice(e.target.value) / values?.area
                    ).toFixed(2)
                  );
                } else {
                  setFieldValue(
                    'fixedCost.returnToMgt_PerAc',
                    unmaskPrice(e.target.value)
                  );
                }
              }}
              imask={{
                mask: '\\$num',
                lazy: false,
                blocks: {
                  num: {
                    mask: Number,
                    radix: '.',
                    mapToRadix: ['.'],
                    normalizeZeros: false,
                    thousandsSeparator: ','
                  }
                }
              }}
            />
          </div>
          <div className="w-full flex-grow mt-8 mr-4">
            <GrowUIFormField
              control={Input}
              label="Per Ac"
              id="fixedCost.returnToMgt_PerAc"
              name="fixedCost.returnToMgt_PerAc"
              value={getFixedCostValuesStatus(
                memberRole,
                memberFinancialAccess,
                planId,
                values?.fixedCost?.returnToMgt_PerAc
              )}
              disabled={disable}
              onBlur={e => {
                setFieldTouched('fixedCost.returnToMgt_PerAc');
                setFieldValue(
                  'fixedCost.returnToMgt_PerAc',
                  unmaskPrice(e.target.value)
                );
                if (unmaskPrice(e.target.value)) {
                  setFieldValue(
                    'fixedCost.returnToMgt',
                    parseFloat(
                      unmaskPrice(e.target.value) * values?.area
                    ).toFixed(2)
                  );
                } else {
                  setFieldValue(
                    'fixedCost.returnToMgt',
                    unmaskPrice(e.target.value)
                  );
                }
              }}
              imask={{
                mask: '\\$num',
                lazy: false,
                blocks: {
                  num: {
                    mask: Number,
                    radix: '.',
                    mapToRadix: ['.'],
                    normalizeZeros: false,
                    thousandsSeparator: ','
                  }
                }
              }}
            />
          </div>
        </div>
      </Column>
    </Form.Field>
  );
};

const Equipment = ({ memberRole, memberFinancialAccess, planId }) => {
  const { values, setFieldValue, setFieldTouched } = useFormikContext();
  let disable = false;
  disable =
    (memberRole === 'Full control' && memberFinancialAccess === 'none') ||
    (memberRole === 'Full control' && memberFinancialAccess === 'READ');
  return (
    <Form.Field label="Equipment">
      <Column width="w-full">
        <div className="w-full flex items-center">
          <div className="w-full flex-grow mt-8 mr-4">
            <GrowUIFormField
              control={Input}
              label="Total"
              id="fixedCost.equipment"
              value={getFixedCostValuesStatus(
                memberRole,
                memberFinancialAccess,
                planId,
                values?.fixedCost?.equipment
              )}
              disabled={disable}
              name="fixedCost.equipment"
              onBlur={e => {
                setFieldTouched('fixedCost.equipment');
                setFieldValue(
                  'fixedCost.equipment',
                  unmaskPrice(e.target.value)
                );
                if (unmaskPrice(e.target.value)) {
                  setFieldValue(
                    'fixedCost.equipment_PerAc',
                    parseFloat(
                      unmaskPrice(e.target.value) / values?.area
                    ).toFixed(2)
                  );
                } else {
                  setFieldValue(
                    'fixedCost.equipment_PerAc',
                    unmaskPrice(e.target.value)
                  );
                }
              }}
              imask={{
                mask: '\\$num',
                lazy: false,
                blocks: {
                  num: {
                    mask: Number,
                    radix: '.',
                    mapToRadix: ['.'],
                    normalizeZeros: false,
                    thousandsSeparator: ','
                  }
                }
              }}
            />
          </div>
          <div className="w-full flex-grow mt-8 mr-4">
            <GrowUIFormField
              control={Input}
              label="Per Ac"
              id="fixedCost.equipment_PerAc"
              name="fixedCost.equipment_PerAc"
              disabled={disable}
              value={getFixedCostValuesStatus(
                memberRole,
                memberFinancialAccess,
                planId,
                values?.fixedCost?.equipment_PerAc
              )}
              onBlur={e => {
                setFieldTouched('fixedCost.equipment_PerAc');
                setFieldValue(
                  'fixedCost.equipment_PerAc',
                  unmaskPrice(e.target.value)
                );
                if (unmaskPrice(e.target.value)) {
                  setFieldValue(
                    'fixedCost.equipment',
                    parseFloat(
                      unmaskPrice(e.target.value) * values?.area
                    ).toFixed(2)
                  );
                } else {
                  setFieldValue(
                    'fixedCost.equipment',
                    unmaskPrice(e.target.value)
                  );
                }
              }}
              imask={{
                mask: '\\$num',
                lazy: false,
                blocks: {
                  num: {
                    mask: Number,
                    radix: '.',
                    mapToRadix: ['.'],
                    normalizeZeros: false,
                    thousandsSeparator: ','
                  }
                }
              }}
            />
          </div>
        </div>
      </Column>
    </Form.Field>
  );
};

const Taxes = ({ memberRole, memberFinancialAccess, planId }) => {
  const { values, setFieldValue, setFieldTouched } = useFormikContext();
  let disable = false;
  disable =
    (memberRole === 'Full control' && memberFinancialAccess === 'none') ||
    (memberRole === 'Full control' && memberFinancialAccess === 'READ');
  return (
    <Form.Field label="Taxes">
      <Column width="w-full">
        <div className="w-full flex items-center">
          <div className="w-full flex-grow mt-8 mr-4">
            <GrowUIFormField
              control={Input}
              label="Total"
              id="fixedCost.taxes"
              disabled={disable}
              name="fixedCost.taxes"
              value={getFixedCostValuesStatus(
                memberRole,
                memberFinancialAccess,
                planId,
                values?.fixedCost?.taxes
              )}
              onBlur={e => {
                setFieldTouched('fixedCost.taxes');
                setFieldValue('fixedCost.taxes', unmaskPrice(e.target.value));
                if (unmaskPrice(e.target.value)) {
                  setFieldValue(
                    'fixedCost.taxes_PerAc',
                    parseFloat(
                      unmaskPrice(e.target.value) / values?.area
                    ).toFixed(2)
                  );
                } else {
                  setFieldValue(
                    'fixedCost.taxes_PerAc',
                    unmaskPrice(e.target.value)
                  );
                }
              }}
              imask={{
                mask: '\\$num',
                lazy: false,
                blocks: {
                  num: {
                    mask: Number,
                    radix: '.',
                    mapToRadix: ['.'],
                    normalizeZeros: false,
                    thousandsSeparator: ','
                  }
                }
              }}
            />
          </div>
          <div className="w-full flex-grow mt-8 mr-4">
            <GrowUIFormField
              control={Input}
              label="Per Ac"
              id="fixedCost.taxes_PerAc"
              disabled={disable}
              name="fixedCost.taxes_PerAc"
              value={getFixedCostValuesStatus(
                memberRole,
                memberFinancialAccess,
                planId,
                values?.fixedCost?.taxes_PerAc
              )}
              onBlur={e => {
                setFieldTouched('fixedCost.taxes_PerAc');
                setFieldValue(
                  'fixedCost.taxes_PerAc',
                  unmaskPrice(e.target.value)
                );
                if (unmaskPrice(e.target.value)) {
                  setFieldValue(
                    'fixedCost.taxes',
                    parseFloat(
                      unmaskPrice(e.target.value) * values?.area
                    ).toFixed(2)
                  );
                } else {
                  setFieldValue('fixedCost.taxes', unmaskPrice(e.target.value));
                }
              }}
              imask={{
                mask: '\\$num',
                lazy: false,
                blocks: {
                  num: {
                    mask: Number,
                    radix: '.',
                    mapToRadix: ['.'],
                    normalizeZeros: false,
                    thousandsSeparator: ','
                  }
                }
              }}
            />
          </div>
        </div>
      </Column>
    </Form.Field>
  );
};

const Labor = ({ memberRole, memberFinancialAccess, planId }) => {
  const { values, setFieldValue, setFieldTouched } = useFormikContext();
  let disable = false;
  disable =
    (memberRole === 'Full control' && memberFinancialAccess === 'none') ||
    (memberRole === 'Full control' && memberFinancialAccess === 'READ');
  return (
    <Form.Field label="Labor">
      <Column width="w-full">
        <div className="w-full flex items-center">
          <div data-testid="Labor_Total" className="w-full flex-grow mt-8 mr-4">
            <GrowUIFormField
              control={Input}
              label="Total"
              disabled={disable}
              id="fixedCost.labor"
              name="fixedCost.labor"
              value={getFixedCostValuesStatus(
                memberRole,
                memberFinancialAccess,
                planId,
                values?.fixedCost?.labor
              )}
              onBlur={e => {
                setFieldTouched('fixedCost.labor');
                setFieldValue('fixedCost.labor', unmaskPrice(e.target.value));
                if (unmaskPrice(e.target.value)) {
                  setFieldValue(
                    'fixedCost.labor_PerAc',
                    parseFloat(
                      unmaskPrice(e.target.value) / values?.area
                    ).toFixed(2)
                  );
                } else {
                  setFieldValue(
                    'fixedCost.labor_PerAc',
                    unmaskPrice(e.target.value)
                  );
                }
              }}
              imask={{
                mask: '\\$num',
                lazy: false,
                blocks: {
                  num: {
                    mask: Number,
                    radix: '.',
                    mapToRadix: ['.'],
                    normalizeZeros: false,
                    thousandsSeparator: ','
                  }
                }
              }}
            />
          </div>
          <div data-testid="Labor_PerAc" className="w-full flex-grow mt-8 mr-4">
            <GrowUIFormField
              control={Input}
              label="Per Ac"
              disabled={disable}
              id="fixedCost.labor_PerAc"
              name="fixedCost.labor_PerAc"
              value={getFixedCostValuesStatus(
                memberRole,
                memberFinancialAccess,
                planId,
                values?.fixedCost?.labor_PerAc
              )}
              onBlur={e => {
                setFieldTouched('fixedCost.labor_PerAc');
                setFieldValue(
                  'fixedCost.labor_PerAc',
                  unmaskPrice(e.target.value)
                );
                if (unmaskPrice(e.target.value)) {
                  setFieldValue(
                    'fixedCost.labor',
                    parseFloat(
                      unmaskPrice(e.target.value) * values?.area
                    ).toFixed(2)
                  );
                } else {
                  setFieldValue('fixedCost.labor', unmaskPrice(e.target.value));
                }
              }}
              imask={{
                mask: '\\$num',
                lazy: false,
                blocks: {
                  num: {
                    mask: Number,
                    radix: '.',
                    mapToRadix: ['.'],
                    normalizeZeros: false,
                    thousandsSeparator: ','
                  }
                }
              }}
            />
          </div>
        </div>
      </Column>
    </Form.Field>
  );
};

const Insurance = ({ memberRole, memberFinancialAccess, planId }) => {
  const { values, setFieldValue, setFieldTouched } = useFormikContext();
  let disable = false;
  disable =
    (memberRole === 'Full control' && memberFinancialAccess === 'none') ||
    (memberRole === 'Full control' && memberFinancialAccess === 'READ');
  return (
    <Form.Field label="Insurance">
      <Column width="w-full">
        <div className="w-full flex items-center">
          <div className="w-full flex-grow mt-8 mr-4">
            <GrowUIFormField
              control={Input}
              label="Total"
              disabled={disable}
              id="fixedCost.insurance"
              name="fixedCost.insurance"
              value={getFixedCostValuesStatus(
                memberRole,
                memberFinancialAccess,
                planId,
                values?.fixedCost?.insurance
              )}
              onBlur={e => {
                setFieldTouched('fixedCost.insurance');
                setFieldValue(
                  'fixedCost.insurance',
                  unmaskPrice(e.target.value)
                );
                if (unmaskPrice(e.target.value)) {
                  setFieldValue(
                    'fixedCost.insurance_PerAc',
                    parseFloat(
                      unmaskPrice(e.target.value) / values?.area
                    ).toFixed(2)
                  );
                } else {
                  setFieldValue(
                    'fixedCost.insurance_PerAc',
                    unmaskPrice(e.target.value)
                  );
                }
              }}
              imask={{
                mask: '\\$num',
                lazy: false,
                blocks: {
                  num: {
                    mask: Number,
                    radix: '.',
                    mapToRadix: ['.'],
                    normalizeZeros: false,
                    thousandsSeparator: ','
                  }
                }
              }}
            />
          </div>
          <div className="w-full flex-grow mt-8 mr-4">
            <GrowUIFormField
              control={Input}
              label="Per Ac"
              disabled={disable}
              id="fixedCost.insurance_PerAc"
              name="fixedCost.insurance_PerAc"
              value={getFixedCostValuesStatus(
                memberRole,
                memberFinancialAccess,
                planId,
                values?.fixedCost?.insurance_PerAc
              )}
              onBlur={e => {
                setFieldTouched('fixedCost.insurance_PerAc');
                setFieldValue(
                  'fixedCost.insurance_PerAc',
                  unmaskPrice(e.target.value)
                );
                if (unmaskPrice(e.target.value)) {
                  setFieldValue(
                    'fixedCost.insurance',
                    parseFloat(
                      unmaskPrice(e.target.value) * values?.area
                    ).toFixed(2)
                  );
                } else {
                  setFieldValue(
                    'fixedCost.insurance',
                    unmaskPrice(e.target.value)
                  );
                }
              }}
              imask={{
                mask: '\\$num',
                lazy: false,
                blocks: {
                  num: {
                    mask: Number,
                    radix: '.',
                    mapToRadix: ['.'],
                    normalizeZeros: false,
                    thousandsSeparator: ','
                  }
                }
              }}
            />
          </div>
        </div>
      </Column>
    </Form.Field>
  );
};

export default FixedCostSection;
