export const SET_MATCHES = 'SET_MATCHES';
export const SET_SUBMISSION_DATA = 'SET_SUBMISSION_DATA';
export const SET_OPEN_CONFIRMATION_MODAL = 'SET_OPEN_CONFIRMATION_MODAL';
export const SET_OPEN_REMOVE_CROPZONE_MODAL = 'SET_OPEN_REMOVE_CROPZONE_MODAL';
export const SET_OPEN_REMOVE_TASK_MODAL = 'SET_OPEN_REMOVE_TASK_MODAL';
export const SET_SUBMISSION_DONE = 'SET_SUBMISSION_DONE';
export const SET_CROPSEASONS = 'SET_CROPSEASONS';
export const SET_SUBMISSION_FILTER_CROPZONE = 'SET_SUBMISSION_FILTER_CROPZONE';
export const SET_SUBMISSION_ID = 'SET_SUBMISSION_ID';
export const SET_SELECTED_PRODUCTS = 'SET_SELECTED_PRODUCTS';
export const SET_PRODUCTS = 'SET_PRODUCTS';
export const SET_FIELDS = 'SET_FIELDS';
export const SET_OPEN_DRAWER_COUNTIES = 'SET_OPEN_DRAWER_COUNTIES';
export const SET_READ_ONLY = 'SET_READ_ONLY';

const reducer = (state, action) => {
  switch (action.type) {
    case SET_MATCHES:
      return {
        ...state,
        matches: action.payload
      };
    case SET_SELECTED_PRODUCTS:
      return {
        ...state,
        selectedProducts: action.payload
      };
    case SET_PRODUCTS:
      return {
        ...state,
        products: action.payload
      };
    case SET_FIELDS:
      return {
        ...state,
        fields: action.payload
      };
    case SET_SUBMISSION_DATA:
      return {
        ...state,
        farms: action.payload.farms,
        cropSeasons: action.payload.cropSeasons,
        cropSeasonDateRange: action.payload.cropSeasonDateRange,
        tasks: action.payload.tasks,
        extraData: action.payload.extraData,
        submissionFilterCropZoneIds: action.payload.cropZoneIds,
        submissionResult: action.payload.submissionResult,
        loadedSubmission: true,
        pastSubmissions: action.payload.pastSubmissions
      };
    case SET_OPEN_CONFIRMATION_MODAL:
      return {
        ...state,
        openConfirmationModal: action.payload
      };
    case SET_OPEN_REMOVE_CROPZONE_MODAL:
      return {
        ...state,
        openRemoveCropzoneModal: action.payload.open,
        cropzoneToRemove: action.payload.cropzone
      };
    case SET_OPEN_REMOVE_TASK_MODAL:
      return {
        ...state,
        openRemoveTaskModal: action.payload.open,
        taskToRemove: action.payload.task
      };
    case SET_SUBMISSION_DONE:
      return {
        ...state,
        submissionDone: true,
        submissionResult: action.payload
      };
    case SET_CROPSEASONS:
      return {
        ...state,
        cropSeasons: action.payload
      };
    case SET_SUBMISSION_FILTER_CROPZONE:
      return {
        ...state,
        submissionFilterCropZoneIds: action.payload
      };
    case SET_SUBMISSION_ID:
      return {
        ...state,
        submissionId: action.payload
      };
    case SET_OPEN_DRAWER_COUNTIES:
      return {
        ...state,
        openDrawerCounties: action.payload
      };
    case SET_READ_ONLY:
      return {
        ...state,
        isReadOnly: action.payload
      };
    default:
      return state;
  }
};

export default reducer;
