/* eslint-disable import/prefer-default-export */
const defaultColumns = [
  {
    field: 'name',
    title: `Active Ingredient`,
    sortable: true
  },
  {
    field: 'totalQuantity',
    title: `Quantity per Acre`,
    sortable: true
  }
];

export const defaultSort = {
  dir: 'asc',
  field: 'totalQuantity'
};

export const CROPZONE_ACTIVE_INGREDIENTS_TABLE = {
  columns: defaultColumns,
  initialSort: defaultSort,
  defaultValueUnit: 'lbs / ac'
};
