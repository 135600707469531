import React from 'react';
import { Image, StyleSheet, Text, View } from '@react-pdf/renderer';
import PropTypes from 'prop-types';
import { footerTranslations } from 'components/PDF/utils/footerTranslations';

const styles = StyleSheet.create({
  footer: {
    width: '100%',
    position: 'absolute',
    bottom: 0,
    left: 30,
    paddingRight: 15,
    paddingBottom: 15,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between'
  },
  pageNumber: {
    fontSize: 10,
    marginTop: 12,
    fontFamily: 'Noto Sans',
    fontWeight: 'medium'
  },
  footerLogo: {
    width: 90
  },
  copyrightContainer: {
    paddingTop: 12,
    paddingBottom: 10,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    alignItems: 'center',
    height: '100%'
  },
  copyright: {
    fontSize: 10,
    fontFamily: 'Noto Sans'
  }
});
const Footer = ({ exportLanguage, year, fullDate }) => {
  const exportLanguages =
    exportLanguage === 'english'
      ? footerTranslations.pages.english
      : footerTranslations.pages.LATAMSpanish;
  return (
    <View fixed style={styles.footer}>
      <Text
        style={styles.pageNumber}
        render={({ pageNumber }) => `${exportLanguages} ${pageNumber}`}
      />
      <View style={styles.copyrightContainer}>
        <Text style={[styles.copyright, { fontWeight: 'medium' }]}>
          {footerTranslations.copyright[exportLanguage]} {year}
        </Text>
        <Text style={styles.copyright}>
          {footerTranslations.generated[exportLanguage]}{' '}
          {fullDate[exportLanguage]}
        </Text>
      </View>
      <Image src="/pdf-footer-logo.png" style={styles.footerLogo} />
    </View>
  );
};

Footer.propTypes = {
  year: PropTypes.string.isRequired,
  fullDate: PropTypes.string.isRequired,
  exportLanguage: PropTypes.string.isRequired
};

export default Footer;
