import React, { useContext } from 'react';
import { StorageLocationContext } from 'screens/YieldV2/StorageLocations/context/StorageLocationProvider';

const StorageNotes = () => {
  const {
    state: { selectedId, data = [] }
  } = useContext(StorageLocationContext);
  const selected = data.find(item => item.id === selectedId);
  const hasNotesAndNotEmptyString = selected?.notes?.trim()?.length;
  return (
    <p
      className={`font-normal leading-5 tracking-tight whitespace-pre-line ${
        !hasNotesAndNotEmptyString ? 'text-neutral-40 opacity-50' : ''
      }`}
    >
      {hasNotesAndNotEmptyString ? selected?.notes : 'None Entered'}
    </p>
  );
};

export default StorageNotes;
