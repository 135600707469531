import React from 'react';
import PropTypes from 'prop-types';
import LandingList from 'components/LandingList';

const EmptyLandList = ({
  listType,
  searchFilterApplied,
  routePath,
  message,
  messageHeader,
  hasRouting
}) => {
  if (hasRouting)
    return (
      <LandingList.LandingEmpty
        listType={listType}
        isSearch={searchFilterApplied}
        routePath={searchFilterApplied ? routePath : `${routePath}/create`}
        message={message}
        messageHeader={messageHeader}
      />
    );
  return (
    <LandingList.LandingEmpty
      listType={listType}
      isSearch={searchFilterApplied}
      message={message}
      messageHeader={messageHeader}
    />
  );
};
EmptyLandList.propTypes = {
  listType: PropTypes.string,
  routePath: PropTypes.string.isRequired,
  searchFilterApplied: PropTypes.bool.isRequired,
  message: PropTypes.string.isRequired,
  messageHeader: PropTypes.string.isRequired,
  hasRouting: PropTypes.bool
};
EmptyLandList.defaultProps = {
  hasRouting: true,
  listType: null
};

const getEmptyList = (
  listType,
  searchFilterApplied,
  { routePath, message, messageHeader, hasRouting }
) => (
  <EmptyLandList
    searchFilterApplied={searchFilterApplied}
    listType={listType}
    routePath={routePath}
    message={message}
    messageHeader={messageHeader}
    hasRouting={hasRouting}
  />
);

const EmptyListWrapperPropTypes = {
  children: PropTypes.func,
  isLoading: PropTypes.bool,
  loadingMessage: PropTypes.string,
  isEmpty: PropTypes.bool,
  emptyState: PropTypes.element,
  hasRouting: PropTypes.bool,
  items: PropTypes.arrayOf(PropTypes.object),
  tableColumns: PropTypes.arrayOf(PropTypes.object),
  listType: PropTypes.string,
  message: PropTypes.string,
  messageHeader: PropTypes.string,
  routePath: PropTypes.string,
  searchFilterApplied: PropTypes.bool,
  onSort: PropTypes.func,
  sortBy: PropTypes.string,
  sortDir: PropTypes.string,
  simpleSortMode: PropTypes.bool,
  wrapperClassName: PropTypes.string
};
const EmptyListWrapperDefaultProps = {
  children: null,
  hasRouting: true,
  isLoading: false,
  loadingMessage: '',
  isEmpty: false,
  emptyState: null,
  items: null,
  tableColumns: <></>,
  listType: null,
  message: '',
  messageHeader: '',
  routePath: '',
  searchFilterApplied: false,
  onSort: () => {},
  sortBy: '',
  sortDir: '',
  simpleSortMode: false,
  wrapperClassName: ''
};

const withEmptyList = WrappedComponent => {
  const EmptyListWrapper = ({
    children,
    hasRouting,
    isLoading,
    loadingMessage,
    isEmpty,
    emptyState,
    items,
    tableColumns,
    listType,
    onSort,
    sortBy,
    sortDir,
    simpleSortMode,
    message,
    messageHeader,
    routePath,
    searchFilterApplied,
    wrapperClassName
  }) => {
    if (items ? !items.length && !isLoading : isEmpty)
      return (
        emptyState ??
        getEmptyList(listType, searchFilterApplied, {
          routePath,
          message,
          messageHeader,
          hasRouting
        })
      );
    return (
      <WrappedComponent
        items={items}
        tableColumns={tableColumns}
        onSort={onSort}
        sortBy={sortBy}
        sortDir={sortDir}
        isLoading={isLoading}
        simpleSortMode={simpleSortMode}
        wrapperClassName={wrapperClassName}
        loadingMessage={loadingMessage}
      >
        {children}
      </WrappedComponent>
    );
  };
  EmptyListWrapper.propTypes = EmptyListWrapperPropTypes;
  EmptyListWrapper.defaultProps = EmptyListWrapperDefaultProps;

  return EmptyListWrapper;
};

export default withEmptyList;
