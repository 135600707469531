import React from 'react';
import PropTypes from 'prop-types';
import { Tag } from '@agconnections/grow-ui';

export const TagsPopUpMessage = ({ extraTagsNames }) => (
  <div className="list-none leading-normal">
    {extraTagsNames?.map(tag => (
      <li key={tag}>{tag}</li>
    ))}
  </div>
);

export const extraTagsCalculation = (tags, tagCharacterLimit) => {
  const tagsSpace = 2;
  const tempExtraTagsNames = [];
  const tempTagsToDisplay = [];
  let tempExtraTagsCount = 0;
  let charCount = 0;
  // eslint-disable-next-line no-unused-expressions
  tags?.forEach(tag => {
    if (charCount + tag?.name?.length + tagsSpace < tagCharacterLimit) {
      charCount += tag?.name?.length + tagsSpace;
      tempTagsToDisplay.push(tag);
    } else {
      tempExtraTagsNames.push(tag?.name);
      tempExtraTagsCount += 1;
    }
  });
  return {
    extraTagsCount: tempExtraTagsCount,
    extraTagsNames: tempExtraTagsNames,
    tagsToDisplay: tempTagsToDisplay
  };
};

export const TagsCloudDisplay = ({
  displayTagsObject,
  isLandingPage,
  viewKey
}) => {
  const view = localStorage.getItem(viewKey);
  let conditionalClasses = !isLandingPage
    ? 'w-1/12 text-center gr-text-neutral-80 px-2'
    : 'gr-text-neutral-70 px-2 bg-neutral-20 rounded-md text-xs';
  conditionalClasses =
    isLandingPage === true && view === 'list'
      ? `py-1 ${conditionalClasses}`
      : conditionalClasses;

  return (
    <div className="flex flex-nowrap space-x-2">
      <div
        className="flex space-x-2 gr-whitespace-nowrap"
        data-testid="tag-container"
      >
        {displayTagsObject?.tagsToDisplay?.map(tag => (
          <Tag key={tag.id} color={tag.color}>
            {tag.name}
          </Tag>
        ))}
      </div>
      {displayTagsObject?.extraTagsCount > 0 ? (
        <div className={conditionalClasses}>
          +{displayTagsObject?.extraTagsCount}
        </div>
      ) : (
        <div className="pb-5" />
      )}
    </div>
  );
};

const TagsButton = ({
  setOpenTags,
  tags,
  displayTagsObject,
  viewKey,
  disabled,
  prompt
}) => (
  <button
    data-testid="tags-btn"
    type="button"
    onClick={() => setOpenTags(true)}
    className="border border-neutral-30 px-2 rounded-md w-full py-1"
    disabled={disabled}
  >
    {tags?.length > 0 ? (
      <TagsCloudDisplay
        displayTagsObject={displayTagsObject}
        isLandingPage={false}
        viewKey={viewKey}
      />
    ) : (
      <div className="text-neutral-600 text-md text-left px-1">{prompt}</div>
    )}
  </button>
);

const DisplayTagsObjectProps = PropTypes.shape({
  extraTagsCount: PropTypes.number,
  extraTagsNames: PropTypes.arrayOf(PropTypes.string),
  tagsToDisplay: PropTypes.arrayOf(PropTypes.object)
});

TagsButton.propTypes = {
  setOpenTags: PropTypes.func.isRequired,
  tags: PropTypes.arrayOf(PropTypes.object).isRequired,
  displayTagsObject: DisplayTagsObjectProps.isRequired,
  viewKey: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
  prompt: PropTypes.string
};

TagsButton.defaultProps = {
  disabled: false,
  prompt: 'Add Tags'
};

TagsCloudDisplay.propTypes = {
  displayTagsObject: DisplayTagsObjectProps.isRequired,
  isLandingPage: PropTypes.bool.isRequired,
  viewKey: PropTypes.string.isRequired
};

TagsPopUpMessage.propTypes = {
  extraTagsNames: PropTypes.arrayOf(PropTypes.string).isRequired
};

export default TagsButton;
