import React from 'react';
import PropTypes from 'prop-types';
import { generatePathSegment } from 'helpers/chartCalculations';

const PieChart = ({ value }) => {
  const total = 100;
  const usedValue = Math.max(Math.min(value, 99.99999), 0.01);
  const freeValue = total - usedValue;

  // Create path elements for each segment
  const paths = [
    { percentage: usedValue, color: '#31B4F2', key: 'used' },
    { percentage: freeValue, color: '#4D5165', key: 'free' }
  ].map(({ percentage, color, key }, index) => {
    const startAngle = index === 0 ? 0 : usedValue * 3.6;
    const endAngle = startAngle + percentage * 3.6;

    const outerRadius = key === 'free' ? 80 : 90;
    const innerRadius = key === 'free' ? 70 : 60;

    const d = generatePathSegment({
      startAngle,
      endAngle,
      innerRadius,
      outerRadius
    });

    return <path key={key} d={d} fill={color} />;
  });

  // Return the JSX representing the SVG
  return (
    <div className="w-160px h-160px relative">
      <svg data-value={value} viewBox="0 0 200 200" className="w-full h-full">
        {paths}
      </svg>
      <div className="absolute left-0 top-0 right-0 bottom-0 flex items-center justify-center">
        <div className="text-2xl">{+value.toFixed()}%</div>
      </div>
    </div>
  );
};

PieChart.propTypes = {
  value: PropTypes.number.isRequired
};

export default PieChart;
