import React from 'react';
import PropTypes from 'prop-types';
import {
  mapCropView,
  mapFarmView
} from '../../../utilities/changeSummaryTools';
import AddedTableView from './AddedTableView';
import DeletedAndChangedTableView from './DeletedAndChangedTableView';

const CropView = ({ massiveChangeSummary }) => {
  const mappedFarmView = mapFarmView(
    massiveChangeSummary.fieldsToMassiveAssign
  );

  const mappedCropView = mapCropView(
    massiveChangeSummary.fieldsToMassiveAssign
  );

  return massiveChangeSummary.action === 'Added' ? (
    <AddedTableView
      mappedFarmView={mappedFarmView}
      massiveChangeSummary={massiveChangeSummary}
    />
  ) : (
    <DeletedAndChangedTableView
      mappedCropView={mappedCropView}
      massiveChangeSummary={massiveChangeSummary}
    />
  );
};

CropView.defaultProps = {
  massiveChangeSummary: {}
};

CropView.propTypes = {
  massiveChangeSummary: PropTypes.shape()
};

export default CropView;
