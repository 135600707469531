import React from 'react';
import { useFormikContext } from 'formik';
import CropInfoHeader from './Sections/CropInfoHeader';
import YieldInfoSection from './Sections/YieldInfoSection';
import SaleInfoSection from './Sections/SaleInfoSection';
import FooterButtons from '../FooterButtons';

const YieldInformationTab = () => {
  const { values } = useFormikContext();
  const shouldDisableSaveButtons =
    values.entryName !== '' &&
    values.unit !== '' &&
    values.totalYield > 0 &&
    values.avgYieldPerAcre > 0;

  return (
    <div className="px-8 bg-white" data-testid="yield-information-tab">
      <CropInfoHeader />
      <div className="w-full border-b py-2" />
      <YieldInfoSection />
      <SaleInfoSection />
      <div className="w-full border-b mt-32 py-2" />
      <FooterButtons nextButtonDisabled={!shouldDisableSaveButtons} />
    </div>
  );
};

export default YieldInformationTab;
