import React, { useEffect, useState, useMemo } from 'react';
import { Modal, Input, Button, Select, Spinner } from '@agconnections/grow-ui';
import ClearIcon from '@material-ui/icons/Clear';
import { cloneDeep } from 'lodash';
import PropTypes from 'prop-types';
import useDebounce from 'hooks/useDebounce';
import useSources from './hooks/useSources';
import DocumentCard from './components/DocumentCard';
import PlanView from './components/PlanView';
import TaskView from './components/TaskView';
import RecommendationView from './components/RecommendationView';
import InvoiceView from './components/InvoiceView';

const SourceDocumentModal = ({
  open,
  onClose,
  selected,
  setSourceDocs,
  setFieldTouched
}) => {
  const [docs, setDocs] = useState([]);
  const [selectedDocs, setSelectedDocs] = useState(selected);
  const [filteredDocs, setFilteredDocs] = useState([]);
  const [selectedFilter, setSelectedFilter] = useState(null);
  const [showPlanDetail, setShowPlanDetail] = useState(false);
  const [showTaskDetail, setShowTaskDetail] = useState(false);
  const [showRecDetail, setShowRecDetail] = useState(false);
  const [showInvoiceDetail, setShowInvoiceDetail] = useState(false);
  const [selectedDoc, setSelectedDoc] = useState(null);
  const [clickedId, setClickedId] = useState(null);
  const [searchText, setSearchText] = useState(null);
  const debouncedSearchTerm = useDebounce(searchText, 300);
  const { plans, tasks, invoices, recommendations, isLoading } = useSources();

  const filterTypes = [
    { key: 'clear', value: 'Clear filter' },
    { key: 'plan', value: 'Plans' },
    { key: 'task', value: 'Tasks' },
    { key: 'rec', value: 'Recommendations' },
    { key: 'inv', value: 'Invoices' }
    // { key: 'contract', value: 'Contracts' }
  ];

  const setDocumentCardObject = (
    type,
    id,
    name,
    numOfFields,
    docDate,
    productCount,
    amount
  ) => {
    return { type, id, name, numOfFields, docDate, productCount, amount };
  };

  const transformPlans = plansArray => {
    const morphedPlans = plansArray.map(plan => {
      return setDocumentCardObject(
        'plan',
        plan.id,
        plan.name,
        plan.cropZones?.length,
        plan.createdDateEpoch,
        plan.planProductEntries?.length,
        plan.projectRevenue
      );
    });

    return morphedPlans;
  };

  const transformTasks = tasksArray => {
    const morphedTasks = tasksArray.map(task => {
      return setDocumentCardObject(
        'task',
        task?.id,
        task?.name,
        task?.fieldCount,
        task?.startDateEpoch,
        task?.productCount,
        task?.totalTaskCost
      );
    });

    return morphedTasks;
  };

  const transformRecs = recsArray => {
    const morphedRecs = recsArray.map(rec => {
      return setDocumentCardObject(
        'rec',
        rec?.id,
        rec?.title,
        rec?.cropZones?.length,
        new Date(rec?.createdOn).toLocaleDateString(),
        rec?.recommendationProductEntries?.length,
        rec?.recommendationProductEntries?.reduce((total, prod) => {
          return total + prod.totalProductValue;
        }, 0)
      );
    });
    return morphedRecs;
  };

  const transformInvoices = invcsArray => {
    const morphedInvcs = invcsArray.map(inv => {
      return setDocumentCardObject(
        'inv',
        inv?.invoiceId,
        inv?.title,
        -1,
        inv?.invoiceDateEpoch,
        inv?.productCount,
        inv?.totalCost
      );
    });
    return morphedInvcs;
  };

  // eslint-disable-next-line no-unused-vars
  const allTransfomredDocs = useMemo(() => {
    if (!isLoading) {
      const transformedPlans = transformPlans(plans);
      const transformedTasks = transformTasks(tasks);
      const transformedRecs = transformRecs(recommendations);
      const transformedInvoices = transformInvoices(invoices);
      const allDocs = transformedPlans.concat(
        transformedTasks.concat(transformedRecs.concat(transformedInvoices))
      );
      setDocs(allDocs);
      setFilteredDocs(allDocs);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoading]);

  const addRemoveDoc = (id, name) => {
    const selectedDocsClone = cloneDeep(selectedDocs);
    const arrayIndex = selectedDocs.findIndex(doc => doc.id === id);
    if (arrayIndex >= 0) {
      selectedDocsClone.splice(arrayIndex, 1);
    } else {
      selectedDocsClone.push({ id, name });
    }
    setSelectedDocs(selectedDocsClone);
  };

  const applySearch = docArray => {
    return debouncedSearchTerm
      ? docArray.filter(
          doc =>
            doc.name.toLowerCase().includes(debouncedSearchTerm) ||
            new Date(doc.docDate)
              .toLocaleDateString()
              .includes(debouncedSearchTerm) ||
            doc.productCount.toString().includes(debouncedSearchTerm) ||
            doc.amount.toString().includes(debouncedSearchTerm)
        )
      : docArray;
  };

  const filterCards = filterValue => {
    if (filterValue === 'clear') {
      setSelectedFilter(null);
    }
    let searchTermApplied = [];
    if (filterValue === 'clear') {
      searchTermApplied = applySearch(docs);
    } else {
      const localFilteredCards = docs.filter(doc => doc.type === filterValue);
      searchTermApplied = applySearch(localFilteredCards);
    }
    setFilteredDocs(searchTermApplied);
  };

  const showDetails = (type, id) => {
    switch (type) {
      case 'plan': {
        const planToView = plans.filter(plan => plan.id === id)[0];
        setSelectedDoc(planToView);
        setShowTaskDetail(false);
        setShowRecDetail(false);
        setShowInvoiceDetail(false);
        setShowPlanDetail(true);
        break;
      }
      case 'task': {
        const taskToView = tasks.filter(task => task.id === id)[0];
        setSelectedDoc(taskToView);
        setShowPlanDetail(false);
        setShowRecDetail(false);
        setShowInvoiceDetail(false);
        setShowTaskDetail(true);
        break;
      }
      case 'rec': {
        const recToView = recommendations.filter(rec => rec.id === id)[0];
        setSelectedDoc(recToView);
        setShowPlanDetail(false);
        setShowTaskDetail(false);
        setShowInvoiceDetail(false);
        setShowRecDetail(true);
        break;
      }
      case 'inv': {
        const invToView = invoices.filter(inv => inv.invoiceId === id)[0];
        setSelectedDoc(invToView);
        setShowPlanDetail(false);
        setShowTaskDetail(false);
        setShowRecDetail(false);
        setShowInvoiceDetail(true);
        break;
      }
      default:
        break;
    }
    setClickedId(id);
  };

  const saveSourceDocs = () => {
    setSourceDocs(selectedDocs);
    onClose(false);
  };

  useEffect(() => {
    let foundDocs = [];
    if (debouncedSearchTerm) {
      foundDocs = filteredDocs.filter(
        doc =>
          doc.name?.toLowerCase().includes(debouncedSearchTerm.toLowerCase()) ||
          new Date(doc.docDate)
            .toLocaleDateString()
            .toString()
            .includes(debouncedSearchTerm.toLowerCase()) ||
          doc.productCount
            ?.toString()
            .includes(debouncedSearchTerm.toLowerCase()) ||
          doc.amount?.toString().includes(debouncedSearchTerm.toLowerCase())
      );
    } else {
      foundDocs =
        selectedFilter && selectedFilter !== 'clear'
          ? docs.filter(doc => doc.type === selectedFilter)
          : docs;
    }
    setFilteredDocs(foundDocs);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedSearchTerm]);
  return (
    <Modal size="md" open={open} onClose={() => onClose(false)} hideClose>
      <div
        className="h-half w-full py-8 overflow-y-hidden overflow-x-hidden overscroll-contain"
        style={{ height: '100%', paddingLeft: '10%', paddingRight: '17%' }}
      >
        <div data-testid="source-document-modal" className="my-6 ml-8">
          <div>Sources</div>
          <div className="flex flex-wrap">
            {selectedDocs?.map(doc => {
              return (
                <div
                  className="flex bg-gray-300 border rounded px-1 mr-2 mt-2"
                  data-testid="selected-source-document"
                >
                  <div className="mr-2 text-sm">{doc?.name}</div>
                  <button
                    type="button"
                    onClick={() => {
                      addRemoveDoc(doc.id, doc.name);
                    }}
                  >
                    <ClearIcon style={{ fontSize: 16, marginBottom: '5px' }} />
                  </button>
                </div>
              );
            })}
          </div>
        </div>
        <div className="flex w-full ml-8">
          <div className="w-1/4" style={{ width: 'auto', marginRight: '27px' }}>
            <div className="mb-4">
              <Input
                id="search"
                name="search"
                onChange={e => {
                  setSearchText(e.target.value);
                }}
                placeholder="Search..."
              />
            </div>
            <div className="mb-4">
              <Select
                id="filter"
                data-testid="filter"
                items={filterTypes}
                placeholder="Filter by type"
                value={selectedFilter}
                onChange={e => {
                  const filterValue = JSON.parse(e.target.value).key;
                  setSelectedFilter(filterValue);
                  filterCards(filterValue);
                }}
              />
            </div>
            <div className="h-80 overflow-y-scroll">
              {!isLoading ? (
                <div className="h-auto">
                  {filteredDocs.map(document => {
                    return (
                      <DocumentCard
                        document={document}
                        isAdded={
                          selectedDocs &&
                          selectedDocs?.find(doc => doc.id === document.id)
                        }
                        documentClick={() =>
                          showDetails(document.type, document.id)
                        }
                        addRemoveDoc={addRemoveDoc}
                        clickedId={clickedId}
                      />
                    );
                  })}
                </div>
              ) : (
                <div className="mt-12">
                  <Spinner />
                </div>
              )}
            </div>
          </div>
          {showPlanDetail ||
          showTaskDetail ||
          showRecDetail ||
          showInvoiceDetail ? (
            <div className="w-3/4 mr-8 overflow-y-scroll overscroll-contain">
              <div
                className="w-full pl-4 pr-8 overscroll-contain"
                style={{ height: '46rem' }}
              >
                {showPlanDetail && <PlanView id={selectedDoc.id} />}
                {showTaskDetail && (
                  <TaskView
                    location={selectedDoc.cropSeasons}
                    id={selectedDoc.id}
                  />
                )}
                {showRecDetail && <RecommendationView id={selectedDoc.id} />}
                {showInvoiceDetail && (
                  <InvoiceView id={selectedDoc.invoiceId} />
                )}
              </div>
            </div>
          ) : null}
        </div>
        <div className="w-full py-8 border-t">
          <div className="flex float-right mr-8">
            <div className="mr-4">
              <Button dataTestId="cancel" onClick={() => onClose(false)}>
                Cancel
              </Button>
            </div>
            <Button
              type="primary"
              dataTestId="add"
              onClick={() => {
                setFieldTouched('Sources');
                saveSourceDocs();
              }}
            >
              Add
            </Button>
          </div>
        </div>
      </div>
    </Modal>
  );
};

SourceDocumentModal.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  selected: PropTypes.arrayOf(PropTypes.object),
  setSourceDocs: PropTypes.func,
  setFieldTouched: PropTypes.func
};

SourceDocumentModal.defaultProps = {
  selected: [],
  setSourceDocs: () => {},
  setFieldTouched: () => {}
};

export default SourceDocumentModal;
