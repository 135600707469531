import { flowRight } from 'lodash';

export const getEventValue = event => {
  const { value } = (event && event.target) || {};
  return value;
};

/**
 * gets the key of the selected option object from the Select onChange handler's event
 * @param event {Event} - the Select onChange event object
 * @returns {*|null} - the key of the selected option object
 */
export const getEventSelectedKey = event => {
  const rawSelection = getEventValue(event) || '{}';
  const selected = JSON.parse(rawSelection);
  return selected ? selected.key : null;
};

export const getEventSelectedItems = event => {
  const rawSelection = getEventValue(event) || '[]';
  return JSON.parse(rawSelection);
};

/**
 * creates a transform function composed of multiple transform functions
 * @param {...Function} fs - the transform functions to be composed into a new function
 * @returns {Function} - the composed transform function
 */
export const composeTransforms = (...fs) => flowRight(fs);

/**
 * creates an event handler function that calls the specified handler with the specified transform applied to the event target value
 * @param {Function} handlerFunction - the handler function that will be called with the event and the transformed target value as arguments
 * @param {Function} transformFunction - transform function that accepts an event and returns a value
 * @returns {Function} - an event handler function compatible with typical DOM events
 */
export const createEventHandlerWithTransform = (
  handlerFunction,
  transformFunction
) => event => handlerFunction(event, transformFunction(event));

/**
 * creates an event handler by partially applying a handler function and completing it with a transformed event
 * @param {Function} handlerFunction - the function that will be partially applied to create the event handler
 * @param {Function} transformFunction - the function that will be applied to the event object
 * @returns {Function} - a partially applied event handler function that will apply the specified transform to the event
 */
export const createPartiallyAppliedEventHandlerWithTransform = (
  handlerFunction,
  transformFunction
) => (...args) => event => {
  handlerFunction(...args, transformFunction(event));
};
