/* eslint-disable no-unused-vars */
/* eslint-disable react/prop-types */
import React from 'react';
import { useParams } from 'react-router-dom';
import { Column, Input, Form } from '@agconnections/grow-ui';
import GrowUIFormField from 'components/Forms/GrowUIFormField';
import { useFormikContext } from 'formik';
import { getProjectedRevenueValuesStatus } from '../../../../../helpers/permissionsHelpers';
import { unmaskPrice } from '../../../../../helpers/dataHelpers';

const ProjectedRevenueSection = ({ memberRole, memberFinancialAccess }) => {
  const { id } = useParams();
  return (
    <>
      <div className="w-full flex items-center mr-8">
        <div className="border-r">
          <Yield
            memberRole={memberRole}
            memberFinancialAccess={memberFinancialAccess}
            chkEdit={id}
          />
        </div>
        <div className="border-r ml-4">
          <FSAPayment
            memberRole={memberRole}
            memberFinancialAccess={memberFinancialAccess}
            chkEdit={id}
          />
        </div>
        <div className="ml-4">
          <CropShare
            memberRole={memberRole}
            memberFinancialAccess={memberFinancialAccess}
            chkEdit={id}
          />
        </div>
      </div>
    </>
  );
};

const Yield = ({ memberRole, memberFinancialAccess, chkEdit }) => {
  const { values, setFieldValue, setFieldTouched } = useFormikContext();
  let disable = false;
  disable =
    (memberRole === 'Full control' && memberFinancialAccess === 'none') ||
    (memberRole === 'Full control' && memberFinancialAccess === 'READ');

  return (
    <Column>
      <div className="w-full mr-8">
        <Form.Field label="Yield">
          <div>
            <div className="w-full flex items-center mt-8 mr-4">
              <Column width="w-full mr-4">
                <div className="w-full flex items-center mr-4">
                  <div className="w-full flex-grow mr-4">
                    <GrowUIFormField
                      control={Input}
                      label="Total"
                      id="projectedRevenue.yieldTotal"
                      name="projectedRevenue.yieldTotal"
                      imask={{
                        mask: '\\$num',
                        lazy: false,
                        blocks: {
                          num: {
                            mask: Number,
                            radix: '.',
                            mapToRadix: ['.'],
                            thousandsSeparator: ','
                          }
                        }
                      }}
                      disabled
                    />
                  </div>
                </div>
              </Column>
              <Column width="w-full mr-4">
                <div className="w-full flex items-center mr-4">
                  <div className="w-full flex-grow mr-4">
                    <GrowUIFormField
                      control={Input}
                      label="Per Ac"
                      id="projectedRevenue.yieldPerArea"
                      name="projectedRevenue.yieldPerArea"
                      disabled={disable}
                      value={getProjectedRevenueValuesStatus(
                        memberRole,
                        memberFinancialAccess,
                        chkEdit,
                        values?.projectedRevenue?.yieldPerArea
                      )}
                      onBlur={e => {
                        setFieldTouched('projectedRevenue.yieldPerArea');
                        setFieldValue(
                          'projectedRevenue.yieldPerArea',
                          unmaskPrice(e.target.value)
                        );
                        if (isNaN(unmaskPrice(e.target.value))) {
                          setFieldValue(
                            'projectedRevenue.yieldPerArea',
                            unmaskPrice(e.target.value)
                          );
                        }
                        if (
                          values?.projectedRevenue?.pricePerUnit &&
                          unmaskPrice(e.target.value)
                        ) {
                          setFieldValue(
                            'projectedRevenue.yieldTotal',
                            parseFloat(
                              unmaskPrice(e?.target?.value) *
                                unmaskPrice(
                                  values?.projectedRevenue?.pricePerUnit
                                ) *
                                unmaskPrice(values?.area)
                            ).toFixed(2)
                          );
                        }
                      }}
                      imask={{
                        mask: 'num',
                        lazy: false,
                        blocks: {
                          num: {
                            mask: Number,
                            radix: '.',
                            mapToRadix: ['.'],
                            normalizeZeros: false,
                            thousandsSeparator: ','
                          }
                        }
                      }}
                    />
                  </div>
                </div>
              </Column>
              <Column width="w-full mr-4">
                <div className="w-full flex items-center mr-4">
                  <div className="w-full flex-grow mr-4">
                    <GrowUIFormField
                      control={Input}
                      label="Sale Price Per Unit"
                      id="projectedRevenue.pricePerUnit"
                      name="projectedRevenue.pricePerUnit"
                      disabled={disable}
                      value={getProjectedRevenueValuesStatus(
                        memberRole,
                        memberFinancialAccess,
                        chkEdit,
                        values?.projectedRevenue?.pricePerUnit
                      )}
                      onBlur={e => {
                        setFieldTouched('projectedRevenue.pricePerUnit');
                        setFieldValue(
                          'projectedRevenue.pricePerUnit',
                          unmaskPrice(e.target.value)
                        );
                        if (isNaN(unmaskPrice(e.target.value))) {
                          setFieldValue(
                            'projectedRevenue.pricePerUnit',
                            unmaskPrice(e.target.value)
                          );
                        }
                        if (
                          values?.area &&
                          unmaskPrice(e.target.value) &&
                          values?.projectedRevenue?.yieldPerArea
                        ) {
                          setFieldValue(
                            'projectedRevenue.yieldTotal',
                            parseFloat(
                              unmaskPrice(e?.target?.value) *
                                unmaskPrice(values?.area) *
                                unmaskPrice(
                                  values?.projectedRevenue?.yieldPerArea
                                )
                            ).toFixed(2)
                          );
                        }
                      }}
                      imask={{
                        mask: '\\$num',
                        lazy: false,
                        blocks: {
                          num: {
                            mask: Number,
                            radix: '.',
                            mapToRadix: ['.'],
                            normalizeZeros: false,
                            thousandsSeparator: ','
                          }
                        }
                      }}
                    />
                  </div>
                </div>
              </Column>
            </div>
          </div>
        </Form.Field>
      </div>
    </Column>
  );
};

const FSAPayment = ({ memberRole, memberFinancialAccess, chkEdit }) => {
  const { values, setFieldValue, setFieldTouched } = useFormikContext();
  let disable = false;
  disable =
    (memberRole === 'Full control' && memberFinancialAccess === 'none') ||
    (memberRole === 'Full control' && memberFinancialAccess === 'READ');

  return (
    <Column>
      <div className="w-full mr-8">
        <Form.Field label="FSA Payment">
          <div>
            <div className="w-full flex items-center mt-8 mr-4">
              <Column width="w-full mr-4">
                <div className="w-full flex items-center mr-4">
                  <div className="w-full flex-grow mr-4">
                    <GrowUIFormField
                      control={Input}
                      label="Total"
                      id="projectedRevenue.fsaPayment"
                      disabled={disable}
                      value={getProjectedRevenueValuesStatus(
                        memberRole,
                        memberFinancialAccess,
                        chkEdit,
                        values?.projectedRevenue?.fsaPayment
                      )}
                      name="projectedRevenue.fsaPayment"
                      onBlur={e => {
                        setFieldTouched('projectedRevenue.fsaPayment');
                        setFieldValue(
                          'projectedRevenue.fsaPayment',
                          unmaskPrice(e.target.value)
                        );
                        if (unmaskPrice(e.target.value)) {
                          setFieldValue(
                            'projectedRevenue.fsaPaymentPerAc',
                            parseFloat(
                              unmaskPrice(e.target.value) / values?.area
                            ).toFixed(2)
                          );
                        } else {
                          setFieldValue(
                            'projectedRevenue.fsaPaymentPerAc',
                            unmaskPrice(e.target.value)
                          );
                        }
                      }}
                      imask={{
                        mask: '\\$num',
                        lazy: false,
                        blocks: {
                          num: {
                            mask: Number,
                            radix: '.',
                            mapToRadix: ['.'],
                            normalizeZeros: false,
                            thousandsSeparator: ','
                          }
                        }
                      }}
                    />
                  </div>
                </div>
              </Column>
              <Column width="w-full mr-4">
                <div className="w-full flex items-center mr-4">
                  <div className="w-full flex-grow mr-4">
                    <GrowUIFormField
                      control={Input}
                      label="Per Ac"
                      id="projectedRevenue.fsaPaymentPerAc"
                      disabled={disable}
                      name="projectedRevenue.fsaPaymentPerAc"
                      value={getProjectedRevenueValuesStatus(
                        memberRole,
                        memberFinancialAccess,
                        chkEdit,
                        values?.projectedRevenue?.fsaPaymentPerAc
                      )}
                      onBlur={e => {
                        setFieldTouched('projectedRevenue.fsaPaymentPerAc');
                        setFieldValue(
                          'projectedRevenue.fsaPaymentPerAc',
                          unmaskPrice(e.target.value)
                        );
                        if (unmaskPrice(e.target.value)) {
                          setFieldValue(
                            'projectedRevenue.fsaPayment',
                            parseFloat(
                              unmaskPrice(e.target.value) * values?.area
                            ).toFixed(2)
                          );
                        } else {
                          setFieldValue(
                            'projectedRevenue.fsaPayment',
                            unmaskPrice(e.target.value)
                          );
                        }
                      }}
                      imask={{
                        mask: '\\$num',
                        lazy: false,
                        blocks: {
                          num: {
                            mask: Number,
                            radix: '.',
                            mapToRadix: ['.'],
                            normalizeZeros: false,
                            thousandsSeparator: ','
                          }
                        }
                      }}
                    />
                  </div>
                </div>
              </Column>
            </div>
          </div>
        </Form.Field>
      </div>
    </Column>
  );
};

const CropShare = ({ memberRole, memberFinancialAccess, chkEdit }) => {
  const { values, setFieldValue, setFieldTouched } = useFormikContext();
  let disable = false;
  disable =
    (memberRole === 'Full control' && memberFinancialAccess === 'none') ||
    (memberRole === 'Full control' && memberFinancialAccess === 'READ');

  return (
    <Column>
      <div className="w-full mr-8">
        <Form.Field label="Crop Share %">
          <div>
            <div className="w-full flex items-center mt-8 mr-4">
              <Column width="w-full mr-4">
                <div className="w-full flex items-center mr-4">
                  <div className="w-full flex-grow mr-4">
                    <GrowUIFormField
                      control={Input}
                      label="Total"
                      id="projectedRevenue.cropSharePercent"
                      disabled={disable}
                      name="projectedRevenue.cropSharePercent"
                      value={getProjectedRevenueValuesStatus(
                        memberRole,
                        memberFinancialAccess,
                        chkEdit,
                        values?.projectedRevenue.cropSharePercent
                      )}
                      onBlur={e => {
                        setFieldTouched('projectedRevenue.cropSharePercent');
                        if (e.target.value) {
                          setFieldValue(
                            'projectedRevenue.cropSharePercent',
                            parseFloat(unmaskPrice(e.target.value))
                          );
                        } else {
                          setFieldValue(
                            'projectedRevenue.cropSharePercent',
                            parseFloat(unmaskPrice(100))
                          );
                        }
                      }}
                      imask={{
                        mask: 'num',
                        lazy: false,
                        blocks: {
                          num: {
                            mask: Number,
                            radix: '.',
                            mapToRadix: ['.'],
                            normalizeZeros: false,
                            thousandsSeparator: ','
                          }
                        }
                      }}
                    />
                  </div>
                </div>
              </Column>
            </div>
          </div>
        </Form.Field>
      </div>
    </Column>
  );
};

export default ProjectedRevenueSection;
