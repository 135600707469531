export const NAVBAR_OPEN = 'NAVBAR_OPEN';
export const SET_ACTIVE_REPORT = 'SET_ACTIVE_REPORT';

export const reducer = (state, action) => {
  switch (action.type) {
    case NAVBAR_OPEN:
      return {
        ...state,
        navbarOpen: action.payload
      };
    case SET_ACTIVE_REPORT:
      return {
        ...state,
        activeReport: action.payload
      };
    default:
      return state;
  }
};
