import React, { useContext } from 'react';
import { Context } from 'components/Store';
import GrowUIFormField from 'components/Forms/GrowUIFormField';
import { Input } from '@agconnections/grow-ui';
import { useFormikContext } from 'formik';

const SlopeLength = () => {
  const [{ isEnableInfoTabEdit }] = useContext(Context);
  const { setFieldValue } = useFormikContext();

  return (
    <GrowUIFormField
      control={Input}
      type="text"
      id="slopeLength"
      name="slopeLength"
      label="Slope Length"
      disabled={!isEnableInfoTabEdit}
      placeholder="Enter slope length"
      onChange={e => setFieldValue('slopeLength', e.target.value)}
    />
  );
};

export default SlopeLength;
