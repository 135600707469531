import React, { useState, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import CheckboxTree from 'react-checkbox-tree';
import { Input, Spinner } from '@agconnections/grow-ui';
import {
  Checkbox,
  FormGroup,
  FormControlLabel,
  createTheme,
  ThemeProvider
} from '@material-ui/core';
import { obtainAllKeys } from 'screens/Reports/helpers/helperFunctions';
import 'react-checkbox-tree/lib/react-checkbox-tree.css';
import {
  CheckBox,
  CheckBoxOutlineBlank,
  IndeterminateCheckBox
} from '@material-ui/icons';
import { useFormikContext } from 'formik';

const theme = createTheme({
  palette: {
    primary: {
      main: '#0071cd'
    }
  }
});

const GroupByPlans = ({
  mappedData,
  disabled = true,
  loading,
  handleCheck,
  checked,
  setChecked
}) => {
  const [filterText, setFilterText] = useState();
  const [plansNode, setPlansNode] = useState([]);
  const [nodesFiltered, setNodesFiltered] = useState(plansNode);
  const { setFieldValue } = useFormikContext();

  useEffect(() => {
    let mappedFft = [];
    if (loading === false && mappedData) {
      mappedFft = mappedData?.map(plan => ({
        value: plan.planId,
        label: plan.name,
        type: 'plan'
      }));
    }
    if (mappedData?.length) {
      mappedFft = mappedFft.map(plan => ({
        ...plan
      }));

      setPlansNode(mappedFft);
    }
  }, [mappedData, loading]);

  useEffect(() => {
    handleCheck();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [plansNode]);

  const filterNodes = useCallback(
    (filtered, node) => {
      const children = (node.fields || []).reduce(filterNodes, []);
      if (
        node.label
          .toLocaleLowerCase()
          .indexOf(filterText?.toLocaleLowerCase()) > -1 ||
        children.length
      ) {
        filtered.push({ ...node, children });
      }

      return filtered;
    },
    [filterText]
  );

  const filterTree = () => {
    if (!filterText?.length) {
      setNodesFiltered(plansNode);
      return;
    }

    setNodesFiltered(nodes => nodes.reduce(filterNodes, []));
  };

  const onFilterChange = e => {
    if (e.target.value.length >= 3) {
      setFilterText(e.target.value);
      filterTree();
    } else {
      setFilterText('');
    }
  };

  useEffect(() => {
    if (!filterText) {
      setNodesFiltered(plansNode);
      return;
    }

    setNodesFiltered(prevState => prevState.reduce(filterNodes, []));
  }, [filterText, filterNodes, plansNode]);

  useEffect(() => {
    setChecked(obtainAllKeys(plansNode));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [plansNode]);

  const planKeys = obtainAllKeys(plansNode);

  const handleSelectAllCheckBox = () => {
    if (checked.length === planKeys.length) {
      setChecked([]);
    } else {
      setChecked(planKeys);
    }
  };

  useEffect(() => {
    setFieldValue('selectedPlans', [...new Set(checked)]);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [checked]);

  return (
    <div className="filter-container">
      {!loading ? (
        <>
          {mappedData?.length > 0 && (
            <>
              <div
                className="my-2"
                data-testid="plans-filter-container"
                style={{ display: 'none' }}
              >
                <Input
                  className="filter-text"
                  placeholder="Search..."
                  type="search"
                  onChange={onFilterChange}
                />
              </div>
              <div className="mt-2 flex">
                <FormGroup>
                  <FormControlLabel
                    control={
                      <ThemeProvider theme={theme}>
                        <Checkbox
                          style={{ borderColor: '#00000099' }}
                          size="small"
                          color="primary"
                          onChange={handleSelectAllCheckBox}
                          checked={checked?.length === planKeys?.length}
                        />
                      </ThemeProvider>
                    }
                    label="Select All"
                  />
                </FormGroup>
              </div>

              <CheckboxTree
                nodes={nodesFiltered}
                checked={checked}
                showNodeIcon={false}
                expandDisabled
                icons={{
                  halfCheck: <IndeterminateCheckBox fontSize="small" />,
                  uncheck: (
                    <CheckBoxOutlineBlank
                      fontSize="small"
                      className="text-neutral-600"
                    />
                  ),
                  expandClose: null,
                  check: <CheckBox fontSize="small" />
                }}
                css="text-lg"
                onCheck={id => handleCheck(id)}
                showNodeTitle
                disabled={disabled}
                checkModel="all"
              />
            </>
          )}
        </>
      ) : (
        <Spinner size="sm" />
      )}
    </div>
  );
};

GroupByPlans.defaultProps = {
  disabled: false,
  mappedData: [],
  loading: false
};
GroupByPlans.propTypes = {
  mappedData: PropTypes.arrayOf(PropTypes.object),
  disabled: PropTypes.bool,
  loading: PropTypes.bool,
  handleCheck: PropTypes.func.isRequired,
  checked: PropTypes.arrayOf(PropTypes.string).isRequired,
  setChecked: PropTypes.func.isRequired
};

export default GroupByPlans;
