import React, { useEffect, useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { isWithinInterval, format } from 'date-fns';
import Filtering from 'screens/Inventory/components/Filtering';
import { Spinner } from '@agconnections/grow-ui';
import { getAbbreviatedUnit } from 'helpers/unitsAbbreviation';
import { paths } from 'routes/paths';
import DetailTable from '../../../DetailTable';

const TaskTab = ({ tasks, productId, setRedirect }) => {
  const [filteredTasks, setFilteredTasks] = useState([]);
  const [currentPage, setCurrentPage] = useState(0);
  const [totalRecords, setTotalRecords] = useState(0);

  const taskRedirect = address => {
    setRedirect(address);
  };

  const setTasksRow = useCallback(
    tasksToMap => {
      const TABLE_HEADER = [
        {
          field: 'name',
          sortable: true,
          title: 'Name'
        },
        {
          field: 'completedDate',
          sortable: true,
          title: 'Completed Date'
        },
        {
          field: 'rate',
          sortable: true,
          title: 'Rate'
        },
        {
          field: 'appliedArea',
          sortable: true,
          title: 'Applied Area'
        },
        {
          field: 'totalProduct',
          sortable: true,
          title: 'Total Product'
        }
      ];

      const taskRows = tasksToMap.map(task => {
        const taskProds = task.products.filter(
          prod => prod.productId === productId
        );
        const prodRow = taskProds.map(prod => {
          const roundedRatePerArea = prod.ratePerAreaValue.toFixed(2);
          const roundedAppliedAreaValue = prod.appliedAreaValue.toFixed(2);
          const roundedTotalProductValue = prod.totalProductValue.toFixed(2);
          return [
            `${roundedRatePerArea} ${getAbbreviatedUnit(
              prod.ratePerAreaUnit
            )}/${getAbbreviatedUnit(prod.appliedAreaUnit)}`,
            `${roundedAppliedAreaValue} ${getAbbreviatedUnit(
              prod.appliedAreaUnit
            )}`,
            `${roundedTotalProductValue} ${getAbbreviatedUnit(
              prod.totalProductUnit
            )}`
          ];
        });
        const taskRow = {
          id: task.taskId,
          rowData: [
            task.name,
            new Date(
              task.duration === 'Custom'
                ? task.dueDateEpoch
                : task.startDateEpoch + task.duration * 3600
            ).toLocaleDateString(),
            prodRow[0][0],
            prodRow[0][1],
            prodRow[0][2]
          ],
          link: `${paths.tasks}/${task.taskId}/view`
        };
        return taskRow;
      });

      return { tableHeader: TABLE_HEADER, tableRows: taskRows };
    },
    [productId]
  );

  useEffect(() => {
    if (tasks) {
      setTotalRecords(tasks.length);
      setFilteredTasks(_.cloneDeep(tasks));
    }
  }, [tasks]);

  const handleFiltering = (searchTerm, cropSeasons, dateRange) => {
    let filteredData = _.cloneDeep(tasks);
    if (searchTerm) {
      filteredData = filteredData.filter(task =>
        task.name.toLowerCase().includes(searchTerm.toLowerCase())
      );
    }

    if (cropSeasons.length > 0) {
      filteredData = filteredData.filter(task =>
        task.cropSeasonIds.some(({ id }) => cropSeasons.includes(id))
      );
    }

    if (dateRange.length === 2) {
      filteredData = filteredData.filter(task => {
        const completedTaskDate = new Date(
          format(
            new Date(task.startDateEpoch + task.duration * 3600),
            'yyyy/MM/dd'
          )
        );
        const startDate = new Date(
          format(new Date(dateRange[0]), 'yyyy/MM/dd')
        );
        const endDate = new Date(format(new Date(dateRange[1]), 'yyyy/MM/dd'));

        return isWithinInterval(completedTaskDate, {
          start: startDate,
          end: endDate
        });
      });
    }

    setFilteredTasks(filteredData);
  };

  return tasks ? (
    <div data-testid="inv-task-tab">
      <Filtering
        setRowData={setTasksRow}
        rowData={filteredTasks}
        onFilter={handleFiltering}
        pageClick={setCurrentPage}
        totalRecordCount={totalRecords}
        pageNumber={currentPage}
      >
        {({ tableData }) => (
          <DetailTable data={tableData} setRedirect={taskRedirect} />
        )}
      </Filtering>
    </div>
  ) : (
    <Spinner />
  );
};

TaskTab.defaultProps = {
  tasks: null,
  productId: null,
  setRedirect: () => {}
};

TaskTab.propTypes = {
  tasks: PropTypes.arrayOf(PropTypes.object),
  productId: PropTypes.string,
  setRedirect: PropTypes.func
};

export default TaskTab;
