import React from 'react';
import PropTypes from 'prop-types';
import { RadioButtonGroup } from '@agconnections/grow-ui';
import { useFormikContext } from 'formik';

import GrowUIFormField from 'components/Forms/GrowUIFormField';

const FertilizerServiceSelector = ({ setValidationSchema }) => {
  const { setFieldValue, values } = useFormikContext();
  const { RadioButton } = RadioButtonGroup;

  const getDefaultSelected = setValue => {
    let selval = 'Fertilizer';
    if (setValue === 'Service') selval = 'Service';
    return selval;
  };

  return (
    <div className="flex">
      <div className="mt-4">
        <GrowUIFormField
          selected={getDefaultSelected(values?.type)}
          control={RadioButtonGroup}
          name="type"
          onChange={e => {
            setFieldValue('type', e);
            setValidationSchema(e);
          }}
        >
          <GrowUIFormField
            value="Fertilizer"
            id="Fertilizer"
            name="fertilizerService"
            control={RadioButton}
            onChange={e => setFieldValue(e)}
          >
            Fertilizer
          </GrowUIFormField>
          <GrowUIFormField
            value="Service"
            id="Service"
            name="fertilizerService"
            control={RadioButton}
            onChange={e => setFieldValue(e)}
          >
            Service
          </GrowUIFormField>
        </GrowUIFormField>
      </div>
    </div>
  );
};

FertilizerServiceSelector.propTypes = {
  setValidationSchema: PropTypes.func.isRequired
};

export default FertilizerServiceSelector;
