const SERIE_1100 = [
  '1101+',
  '1102',
  '1103',
  '1104',
  '1105+',
  '1106',
  '1107',
  '1108',
  '1143',
  '1144',
  '1146',
  '1147',
  '1148'
];

const SERIE_1200 = [
  '1201',
  '1202',
  '1203',
  '1204',
  '1205',
  '1206',
  '1207',
  '1208',
  '1209',
  '1210',
  '1211',
  '1242',
  '1243',
  '1245',
  '1247',
  '1248',
  '1249',
  '1259'
];

export const FUMIGATION_SERIES = [
  // Methyl Bromide Fumigation Methods (With or without chloropicrin) - 1100 series
  {
    name: '1100 series',
    codes: [...SERIE_1100, '1109', '1145', '1149', '1190']
  },

  // 1,3-Dichloropropene Fumigation Methods (with or without chloropicrin) - 1200 series
  {
    name: '1200 series',
    codes: [...SERIE_1200, '1290']
  },

  // Chloropicrin-Fumigation Methods - 1100 - 1300 series
  {
    name: '1100-1300 series',
    codes: [...SERIE_1100, '1145+', ...SERIE_1200, '1390']
  },

  // Metam-Sodium and Metam-Potassium Fumigation Methods - 1400 series
  {
    name: '1400 series',
    codes: [
      '1401',
      '1402',
      '1403',
      '1404',
      '1405',
      '1406',
      '1407',
      '1408',
      '1409',
      '1410',
      '1411',
      '1412',
      '1413',
      '1447',
      '1452',
      '1455',
      '1472',
      '1490'
    ]
  },

  // Dazomet Fumigation Methods - 1500 series
  { name: '1500 series', codes: ['1501', '1502', '1590'] },

  // Sodium Tetrathiocarbonate Fumigation Methods - 1600 series
  {
    name: '1600 series',
    codes: ['1601', '1602', '1603', '1604', '1605', '1690']
  },

  // Methyl Iodide Fumigation Methods - 1700 series
  { name: '1700 series', codes: ['1701', '1702', '1703', '1704', '1705'] }
];

export const APPLICATOR_TYPES = [
  {
    key: 'grower',
    value: 'Grower Applied'
  },
  {
    key: 'pco',
    value: 'PCO Applied'
  }
];

export const PCO_APPLICATOR_TYPES = [
  {
    key: 'PCB',
    value: 'PCB'
  },
  {
    key: 'PCM',
    value: 'PCM'
  },
  {
    key: 'APC',
    value: 'APC'
  },
  {
    key: 'JPC',
    value: 'JPC'
  },
  {
    key: 'PAC',
    value: 'PAC'
  },
  {
    key: 'UAPC',
    value: 'UAPC'
  },
  {
    key: 'UJPC',
    value: 'UJPC'
  },
  {
    key: 'VCT',
    value: 'VCT'
  }
];

export const PROPERTIES_SELECTION_STEP = 2;
