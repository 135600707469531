import React, { useState } from 'react';
import PropTypes from 'prop-types';
import ChevronRight from 'assets/chevron_right.svg';
import ChevronDown from 'assets/chevron_down.svg';

const Accordion = ({
  header,
  content,
  headerHeight,
  isProperty,
  isFullWidthButton,
  toggleCallback,
  dynamicToggle,
  canExpand
}) => {
  const [isExpanded, setIsExpanded] = useState(dynamicToggle && canExpand);

  const toggleAccordion = () => {
    if (!dynamicToggle) {
      setIsExpanded(!isExpanded);
    }
    toggleCallback();
  };

  return (
    <div
      className="bg-white rounded mb-4"
      style={{ boxShadow: '0px 2px 8px rgba(0, 0, 0, 0.15)' }}
      data-testid="accordion-container"
    >
      <button
        className={`flex flex-row items-center focus:outline-none ${
          isProperty ? 'py-6' : ''
        } 
        ${isFullWidthButton || isProperty ? 'justify-between w-full' : ''}
        `}
        type="button"
        data-testid="accordion-toggle"
        onClick={toggleAccordion}
        styles={{ height: headerHeight }}
      >
        {header}

        <img
          className="pr-16px"
          src={
            (dynamicToggle && canExpand) || isExpanded
              ? ChevronDown
              : ChevronRight
          }
          alt="expand collapse accordion"
        />
      </button>

      {((dynamicToggle && canExpand) || isExpanded) && (
        <div className="p-4" data-testid="accordion-content">
          {content}
        </div>
      )}
    </div>
  );
};

Accordion.defaultProps = {
  toggleCallback: () => {},
  dynamicToggle: false,
  canExpand: true
};

Accordion.propTypes = {
  isProperty: PropTypes.bool.isRequired,
  isFullWidthButton: PropTypes.bool.isRequired,
  header: PropTypes.node.isRequired,
  headerHeight: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
    .isRequired,
  content: PropTypes.node.isRequired,
  toggleCallback: PropTypes.func,
  dynamicToggle: PropTypes.bool,
  canExpand: PropTypes.bool
};

export default Accordion;
