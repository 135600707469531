import { useEffect, useCallback } from 'react';

import {
  area,
  center,
  convertArea,
  convertLength,
  coordAll,
  distance,
  point,
  polygon
} from '@turf/turf';

import { getEventClientCoordinates } from '../../../../../helpers/mapEventHelpers';
import { useSharedState } from '../../MapEventContext';

const useMapMouseListeners = (mode = 'simple_select') => {
  const [, setState] = useSharedState();

  const setDrawDistance = useCallback(
    drawDistance => {
      setState(prev => ({ ...prev, drawDistance }));
    },
    [setState]
  );

  const setDrawArea = useCallback(
    drawArea => {
      setState(prev => ({ ...prev, drawArea }));
    },
    [setState]
  );

  const setCoordinates = useCallback(
    coordinates => {
      setState(prev => ({ ...prev, coordinates }));
    },
    [setState]
  );

  const getPolygonPointDistance = (event, drawControlRef) => {
    const allDrawnFeatures = drawControlRef.current.draw.getAll();
    const currentDrawnFeaturePosition = allDrawnFeatures.features.length - 1;

    // Get the position of last drawn coordinate
    const previousDrawnCoordinatePosition =
      allDrawnFeatures.features[currentDrawnFeaturePosition].geometry
        .coordinates[0].length - 3; // The first and last coordinate are always the first, must go back 3 places to either first and/or the last drawn coordinate

    // Get the last drawn coordinate
    const previousDrawnCoordinate =
      allDrawnFeatures.features[currentDrawnFeaturePosition].geometry
        .coordinates[0][previousDrawnCoordinatePosition];

    if (previousDrawnCoordinate) {
      // Get the current mouse positions coordinates
      const currentMouseCoordinatesObject = event.lngLat.wrap();
      const currentMouseCoordinates = [];
      currentMouseCoordinates[0] = currentMouseCoordinatesObject.lng;
      currentMouseCoordinates[1] = currentMouseCoordinatesObject.lat;

      // Use turf to measure the distance in kilometers between the two
      const pointDistance = distance(
        point(previousDrawnCoordinate),
        point(currentMouseCoordinates),
        { units: 'kilometers' }
      );
      const pointDistanceInFeet = convertLength(
        pointDistance,
        'kilometers',
        'feet'
      );
      const pointDistanceDisplay = pointDistanceInFeet.toFixed(2);

      return pointDistanceDisplay;
    }

    return null;
  };

  const getCirclePointDistance = (event, drawControlRef) => {
    const allDrawnFeatures = drawControlRef.current.draw.getAll();
    const currentDrawnFeaturePosition = allDrawnFeatures.features.length - 1;
    if (
      !allDrawnFeatures.features[currentDrawnFeaturePosition].geometry
        .coordinates[0][0]
    ) {
      return null;
    }

    // Get the center coordinate
    const previousDrawnCoordinate = center(
      allDrawnFeatures.features[currentDrawnFeaturePosition]
    ).geometry.coordinates;

    if (previousDrawnCoordinate) {
      // Get the current mouse positions coordinates
      const currentMouseCoordinatesObject = event.lngLat.wrap();
      const currentMouseCoordinates = [];
      currentMouseCoordinates[0] = currentMouseCoordinatesObject.lng;
      currentMouseCoordinates[1] = currentMouseCoordinatesObject.lat;

      // Use turf to measure the distance in kilometers between the two
      const pointDistance = distance(
        point(previousDrawnCoordinate),
        point(currentMouseCoordinates),
        { units: 'kilometers' }
      );
      const pointDistanceInFeet = convertLength(
        pointDistance,
        'kilometers',
        'feet'
      );
      const pointDistanceDisplay = pointDistanceInFeet.toFixed(2);

      return pointDistanceDisplay;
    }

    return null;
  };

  // Calculate distance and update distance display
  const onMapMouseMoved = (event, drawControlRef) => {
    const drawMode = drawControlRef.current?.draw.getMode();
    // We should diplay draw distance if in a drawing mode
    const displayDistances =
      drawMode === 'draw_polygon' ||
      (drawMode === 'drag_circle' && event.lngLat);

    const allDrawnFeatures = drawControlRef.current.draw.getAll();
    const currentDrawnFeaturePosition = allDrawnFeatures.features.length - 1;

    if (displayDistances) {
      // If polygon drawing, use polygon distance calculation
      if (drawMode === 'draw_polygon') {
        const pointDistanceDisplay = getPolygonPointDistance(
          event,
          drawControlRef
        );
        if (pointDistanceDisplay) setDrawDistance(pointDistanceDisplay);
      }

      // If circle drawing, use circle drawing distance calculation
      if (drawMode === 'drag_circle') {
        const pointDistanceDisplay = getCirclePointDistance(
          event,
          drawControlRef
        );
        if (pointDistanceDisplay) setDrawDistance(pointDistanceDisplay);
      }
    }

    // Calc and display Area if in editing or draw modes
    const displayArea = drawMode !== 'simple_select' && event.lngLat;

    if (displayArea) {
      if (
        allDrawnFeatures.features[currentDrawnFeaturePosition].geometry
          .coordinates[0].length > 3
      ) {
        // Get current shape Geometry
        const currentDrawnCoordinates = coordAll(
          allDrawnFeatures.features[currentDrawnFeaturePosition].geometry
        );

        // Get the area of the current shape
        const currentDrawnPolygon = polygon([currentDrawnCoordinates]);
        const currentDrawnPolygonArea = area(currentDrawnPolygon);
        const currentDrawnPolygonAreaInAcres = convertArea(
          currentDrawnPolygonArea,
          'meters',
          'acres'
        );
        const currentDrawnPolygonAreaDisplay = currentDrawnPolygonAreaInAcres.toFixed(
          2
        );

        setDrawArea(currentDrawnPolygonAreaDisplay);
      }
    }
  };

  const onMapMouseDown = event => {
    // Reset draw distance and area display
    setDrawDistance(null);
    setDrawArea(null);

    const coordinates = getEventClientCoordinates(event.originalEvent);
    setCoordinates(coordinates);
  };

  useEffect(() => {
    if (mode !== 'draw_polygon' && mode !== 'drag_circle') {
      setDrawDistance(null);
      setDrawArea(null);
    }
  }, [mode, setDrawDistance, setDrawArea, setCoordinates]);

  return {
    onMapMouseMoved,
    onMapMouseDown
  };
};

export default useMapMouseListeners;
