import React from 'react';
import { Column, Select } from '@agconnections/grow-ui';

import GrowUIFormField from 'components/Forms/GrowUIFormField';

const ServiceProductForm = () => {
  return (
    <div>
      <div name="Service" data-testid="service-tab">
        <div className="mt-12">
          <Column wrap>
            <Column width="w-1/2">
              <div className="pr-2">
                <div>
                  <GrowUIFormField
                    id="standardUnit"
                    label="Standard Unit*"
                    control={Select}
                    name="standardUnit"
                    placeholder="Select"
                    data-testid="standard-unit-select"
                    items={[
                      { key: 'acre', value: 'acre' },
                      { key: 'hectare', value: 'hectare' },
                      { key: 'hour', value: 'hour' },
                      { key: 'day', value: 'day' }
                    ]}
                  />
                </div>
                <div>
                  <br />
                  <br />
                  <br />
                  <br />
                  <br />
                  <br />
                </div>
              </div>
            </Column>
          </Column>
        </div>
      </div>
    </div>
  );
};
export default ServiceProductForm;
