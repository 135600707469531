import React from 'react';
import GrowUIFormField from 'components/Forms/GrowUIFormField';
import { Input } from '@agconnections/grow-ui';

const Township = () => {
  return (
    <GrowUIFormField
      placeholder="Enter township"
      control={Input}
      type="text"
      name="township"
      id="township"
      label="Township"
    />
  );
};

export default Township;
