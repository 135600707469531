import { StyleSheet, Text, View } from '@react-pdf/renderer';
import PropTypes from 'prop-types';
import { signatureHeaderTranslations } from 'components/PDF/TaskPdf/utils/headerTranslations';
import React from 'react';

const styles = StyleSheet.create({
  signatureContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    flexDirection: 'row'
  },
  downLine: {
    borderBottom: '1px solid #00004B',
    width: '40%',
    paddingBottom: 4
  },
  text: {
    fontSize: 10,
    fontFamily: 'Noto Sans',
    fontWeight: 'medium'
  }
});

const Signature = ({ exportLanguage = 'english' }) => {
  return (
    <View style={styles.signatureContainer}>
      <View style={styles.downLine}>
        <Text style={styles.text}>
          {signatureHeaderTranslations.signature[exportLanguage]}
        </Text>
      </View>
      <View style={styles.downLine}>
        <Text style={styles.text}>
          {' '}
          {signatureHeaderTranslations.date[exportLanguage]}
        </Text>
      </View>
    </View>
  );
};
Signature.propTypes = {
  exportLanguage: PropTypes.string.isRequired
};
export default Signature;
