/* eslint-disable react/prop-types */
import React, { memo, useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Spinner } from '@agconnections/grow-ui';
import { noop } from 'lodash';

import { Context } from 'components/Store';
import FormattedNumber from 'components/FormattedNumber';

import ClickableDiv from 'components/ClickableDiv';
import { calculateFarmAcres } from '../../helpers/leftPanel';
import EditRowButton from '../EditRowButton';
import UnarchiveButton from '../UnarchiveButton';

const FarmRow = ({
  farm,
  properties,
  onSelect,
  hasEditAccess,
  isSelected,
  showUnarchiveButton,
  onUnarchive
}) => {
  const [, dispatch] = useContext(Context);

  const [totalAcres, setTotalAcres] = useState(0);
  const [showEditButton, setShowEditButton] = useState(false);
  const [isOnHover, setIsOnHover] = useState(false);

  const updateFarm = e => {
    e.preventDefault();
    e.stopPropagation();
    dispatch({
      type: 'SELECT_PROPERTY',
      payload: {
        ...farm,
        isEditing: true,
        type: 'Farm'
      }
    });
  };

  const handleMouseEnter = () => {
    setShowEditButton(true);
    setIsOnHover(true);
  };

  const handleMouseLeave = () => {
    setShowEditButton(false);
    setIsOnHover(false);
  };

  const handleFieldCount = () => {
    const farmName = farm.name;
    const _farm = properties.find(property => property.name === farmName);
    if (_farm) {
      return _farm.fields.length;
    }
    return 0;
  };

  const displayByCropData = () => {
    if (properties) {
      return `${farm.fields.length} of ${handleFieldCount()}`;
    }
    return '';
  };

  const displayFieldData = () => {
    if (properties) {
      return `field${handleFieldCount() <= 1 ? '' : 's'}`;
    }
    if (farm.archived) {
      return 'Archived';
    }
    if (!farm.fields.length) {
      return 'No fields';
    }
    return `${farm.fields.length} field${farm.fields.length === 1 ? '' : 's'}`;
  };

  useEffect(() => {
    const acres = calculateFarmAcres(farm.fields);

    setTotalAcres(acres);
  }, [farm, farm.fields]);

  return (
    <ClickableDiv
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      onClick={() => onSelect(farm)}
      className="w-full py-3 pl-8 pr-1 text-left flex justify-between items-center"
    >
      {isSelected && (
        <div className="absolute left-0 h-50px w-1 bg-blue-50 rounded-r" />
      )}
      <div data-testid={`farm-row-${farm.id}`}>
        <p
          className={`font-medium leading-5 ${
            farm.status && !farm.archived ? '' : 'text-neutral-40'
          } ${isSelected ? 'font-semibold' : ''}`}
        >
          {farm.name}
        </p>
        {farm.status ? (
          <div className="mt-2px text-xs leading-3">
            <span className={`text-neutral-${!farm.archived ? '60' : '40'}`}>
              <FormattedNumber roundedTo={2}>{totalAcres}</FormattedNumber> ac
              <span> • </span>
              {displayByCropData()} {displayFieldData()}
            </span>
          </div>
        ) : (
          <div className="flex mt-2">
            <span className="mr-2" style={{ height: '14px' }}>
              <Spinner size="xs" color="#696F88" />
            </span>
            <span className="text-gray-700">Loading...</span>
          </div>
        )}
      </div>
      {hasEditAccess && showEditButton && (
        <EditRowButton isRowSelected={isSelected} onClick={updateFarm} />
      )}
      {showUnarchiveButton && (
        <UnarchiveButton onUnarchive={onUnarchive} isOnHover={isOnHover} />
      )}
    </ClickableDiv>
  );
};

FarmRow.defaultProps = {
  farm: {
    id: '',
    name: '',
    fields: [],
    status: null
  },
  onSelect: noop,
  showUnarchiveButton: false,
  onUnarchive: () => {},
  properties: undefined
};

FarmRow.propTypes = {
  farm: PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.string,
    fields: PropTypes.arrayOf(PropTypes.object),
    status: PropTypes.number,
    archived: PropTypes.bool,
    parentPage: PropTypes.number
  }),
  showUnarchiveButton: PropTypes.bool,
  onUnarchive: PropTypes.func,
  onSelect: PropTypes.func,
  isSelected: PropTypes.bool.isRequired,
  hasEditAccess: PropTypes.bool.isRequired,
  properties: PropTypes.objectOf(PropTypes.object)
};

export default memo(FarmRow);
