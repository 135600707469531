import React from 'react';
import { Field } from 'formik';
import { Select } from 'syngenta-digital-cropwise-react-ui-kit';
import { ReactComponent as ChevronDown } from 'assets/chevron_down.svg';
import { WEIGHT_UNITS, YIELD_DEFAULT_ROUND } from 'screens/YieldV2/constants';
import getLabelValue from 'screens/YieldV2/AddYield/helpers/getLabelValue';
import FormInputWithPrecisionDisplay from '../../../FormInputWithPrecisionDisplay';

const TestWeightField = () => {
  return (
    <div className="flex flex-col gap-0.5">
      <label
        htmlFor="test-weight"
        className="text-neutral-60 tracking-tighter text-sm"
      >
        Test Weight
      </label>
      <div className="flex items-start yield-quantity-input">
        <FormInputWithPrecisionDisplay
          fieldName="testWeight"
          precision={2}
          id="test-weight"
          round={YIELD_DEFAULT_ROUND}
        />

        <Field name="testWeightUnit">
          {({ field, form }) => (
            <Select
              size="middle"
              suffixIcon={<ChevronDown />}
              onChange={value => {
                form.setFieldValue(field.name, value);
                form.validateField(field.name);
              }}
              value={field.value}
              dropdownMatchSelectWidth={false}
              optionLabelProp="label"
              className="flex-1"
              data-testid="select-weight-unit"
            >
              {WEIGHT_UNITS.map(option => (
                <Select.Option
                  key={option.value}
                  value={option.value}
                  label={getLabelValue(field.value, option)}
                >
                  {option.label}
                </Select.Option>
              ))}
            </Select>
          )}
        </Field>
      </div>
    </div>
  );
};

export default TestWeightField;
