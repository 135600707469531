import React, { useCallback } from 'react';
import { Button } from '@agconnections/grow-ui';
import mapIcon from 'assets/properties-map-icon.svg';
import { Add } from '@material-ui/icons';
import PropTypes from 'prop-types';

const EmptyProperties = ({
  openCreateModal,
  memberRole,
  memberFinancialAccess
}) => {
  const openCreateModalClick = () => {
    openCreateModal(true);
  };

  const setPermission = useCallback(() => {
    if (
      memberRole === 'View Only' &&
      (memberFinancialAccess === 'READ' || memberFinancialAccess === 'none')
    ) {
      return false;
    }
    return true;
  }, [memberRole, memberFinancialAccess]);

  return (
    <div className="flex flex-col justify-center pt-16">
      <div className="self-center">
        <img
          src={mapIcon}
          alt=""
          width="80%"
          style={{ marginLeft: 'auto', marginRight: 'auto' }}
        />
      </div>
      <div className="self-center mb-6">
        <p className="text-sm text-center">
          We were unable to find any properties
        </p>
        <p className="text-sm text-center">under this organization.</p>
      </div>
      <div className="self-center">
        {setPermission() && (
          <Button
            onClick={openCreateModalClick}
            id="overlay-create-property-btn"
            type="primary"
          >
            <Add />
            Create Fields
          </Button>
        )}
      </div>
    </div>
  );
};

EmptyProperties.propTypes = {
  openCreateModal: PropTypes.func.isRequired,
  memberRole: PropTypes.string.isRequired,
  memberFinancialAccess: PropTypes.string.isRequired
};

export default EmptyProperties;
