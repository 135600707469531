import { cloneDeep, orderBy } from 'lodash';

const parseDirection = direction => {
  if (direction === 'ascend') return 'asc';
  if (direction === 'descend') return 'desc';
  return null;
};

export const getHeaderSortClassName = (columnName, sortBy, sortDir) => {
  if (sortBy && sortBy === columnName) {
    return sortDir === 'ascend' ? 'sort-asc' : 'sort-desc';
  }
  return '';
};

export default (values, field, direction) => {
  if (!direction) return values;

  const _values = values.map(product => cloneDeep(product));
  return orderBy(
    _values,
    [
      product => {
        const val = product?.[field];
        if (typeof val === 'string') return val.trim().toLowerCase();
        return val;
      }
    ],
    parseDirection(direction)
  );
};
