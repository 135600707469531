import { useEffect, useState } from 'react';
import RECOMMENDATION_TYPE, {
  RECOMMENDATION_TYPE_DISPLAY_NAME
} from '../helpers/recommendationType';

const typeFilter = {
  key: 'recommendationType',
  value: 'Recommendation Type',
  nestedItems: [
    {
      key: RECOMMENDATION_TYPE.landdb,
      value: RECOMMENDATION_TYPE_DISPLAY_NAME[RECOMMENDATION_TYPE.landdb],
      property: 'type'
    },
    {
      key: RECOMMENDATION_TYPE.agrian,
      value: RECOMMENDATION_TYPE_DISPLAY_NAME[RECOMMENDATION_TYPE.agrian],
      property: 'type'
    }
  ]
};

const seasonFilter = {
  key: 'seasons',
  value: 'Seasons'
};

const sourceFilter = {
  key: 'source',
  value: 'Used as a source record',
  nestedItems: [
    { key: 'Not used', value: 'Not used', property: 'source' },
    { key: 'Used in task', value: 'Used in task', property: 'source' },
    {
      key: 'Used in recommendation',
      value: 'Used in recommendation',
      property: 'source'
    },
    { key: 'Unknown', value: 'Unknown', property: 'source' }
  ]
};

function useFilterItems(cropSeasons) {
  const [filterItems, setFilterItems] = useState([
    typeFilter,
    seasonFilter,
    sourceFilter
  ]);

  useEffect(() => {
    if (!cropSeasons || cropSeasons.length === 0) {
      return;
    }

    const updatedSeasonFilter = {
      ...seasonFilter,
      nestedItems: (cropSeasons ?? []).map(s => {
        return { key: s.id, value: s.name, property: 'cropSeasonIds' };
      })
    };
    setFilterItems([typeFilter, updatedSeasonFilter, sourceFilter]);
  }, [cropSeasons]);

  return filterItems;
}

export default useFilterItems;
