import React, { useState, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import CheckboxTree from 'react-checkbox-tree';
import { Input, Spinner } from '@agconnections/grow-ui';
import {
  Checkbox,
  FormGroup,
  FormControlLabel,
  createTheme,
  ThemeProvider
} from '@material-ui/core';
import { obtainAllKeys } from 'screens/Reports/helpers/helperFunctions';
import 'react-checkbox-tree/lib/react-checkbox-tree.css';
import _, { flatten } from 'lodash';
import {
  ArrowRight,
  ArrowDropDown,
  CheckBox,
  CheckBoxOutlineBlank,
  IndeterminateCheckBox
} from '@material-ui/icons';
import { useFormikContext } from 'formik';

const theme = createTheme({
  palette: {
    primary: {
      main: '#0071cd'
    }
  }
});

const productTypeDisplay = key => {
  switch (key) {
    case 'SEED':
      return 'Seed';
    case 'CROPPROTECTION':
      return 'Crop Protection';
    case 'FERTILIZER':
      return 'Fertilizer';
    case 'SERVICE':
      return 'Service';
    default:
      return 'Custom Product';
  }
};

const GroupByProducts = ({ mappedData, disabled = true, loading }) => {
  const [checked, setChecked] = useState([]);
  const [expanded, setExpanded] = useState([]);
  const [filterText, setFilterText] = useState();
  const [productsNode, setProductsNode] = useState([]);
  const [nodesFiltered, setNodesFiltered] = useState(productsNode);
  const { setFieldValue } = useFormikContext();

  useEffect(() => {
    let mappedFft = [];
    if (mappedData) {
      mappedFft = mappedData?.map(eachProduct => ({
        value: _.uniqueId('CP_'),
        label: productTypeDisplay(eachProduct.productType),
        type: 'type',
        ...(eachProduct.children.length > 0
          ? {
              children: eachProduct.children?.map(item => {
                return {
                  id: item.productId,
                  value: _.uniqueId('PN_'),
                  label: item.productName,
                  type: 'name'
                };
              })
            }
          : { disabled: true })
      }));
    }
    setProductsNode(mappedFft);
  }, [mappedData]);

  const handleCheck = checkedIds => {
    setChecked(checkedIds);
  };
  useEffect(() => {
    const products = flatten(productsNode.map(({ children }) => children));

    const productMatches = checked
      .filter(id => id.startsWith('PN_'))
      .map(_id => products.find(product => product.value === _id))
      .filter(product => !!product)
      .map(({ id }) => id);

    setFieldValue('selectedProducts', [...new Set(productMatches)]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [checked]);

  useEffect(() => {
    handleCheck();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [productsNode]);

  const filterNodes = useCallback(
    (filtered, node) => {
      const children = (node.children || []).reduce(filterNodes, []);

      if (
        node.label
          .toLocaleLowerCase()
          .indexOf(filterText?.toLocaleLowerCase()) > -1 ||
        children.length
      ) {
        filtered.push({ ...node, children });
      }

      return filtered;
    },
    [filterText]
  );

  const filterTree = () => {
    if (!filterText?.length) {
      setNodesFiltered(productsNode);
      return;
    }

    setNodesFiltered(nodes => nodes.reduce(filterNodes, []));
  };

  const onFilterChange = e => {
    if (e.target.value.length >= 3) {
      setFilterText(e.target.value);
      filterTree();
    } else {
      setFilterText('');
    }
  };

  useEffect(() => {
    if (!filterText) {
      setNodesFiltered(productsNode);
      return;
    }

    setNodesFiltered(prevState => prevState.reduce(filterNodes, []));
  }, [filterText, filterNodes, productsNode]);

  useEffect(() => {
    setChecked(obtainAllKeys(productsNode));
  }, [productsNode]);

  const productKeys = obtainAllKeys(productsNode);

  const handleSelectAllCheckBox = () => {
    if (checked.length === productKeys.length) {
      setChecked([]);
    } else {
      setChecked(productKeys);
    }
  };
  return (
    <div className="filter-container h-full">
      {loading ? (
        <Spinner size="sm" />
      ) : (
        <>
          {mappedData?.length > 0 && (
            <>
              <div
                className="my-2"
                data-testid="product-filter-container"
                style={{ display: 'none' }}
              >
                <Input
                  className="filter-text"
                  placeholder="Search..."
                  type="search"
                  onChange={onFilterChange}
                />
              </div>
              <div className="mt-2 flex">
                <FormGroup>
                  <FormControlLabel
                    control={
                      <ThemeProvider theme={theme}>
                        <Checkbox
                          style={{ borderColor: '#00000099' }}
                          size="small"
                          color="primary"
                          onChange={handleSelectAllCheckBox}
                          checked={checked?.length === productKeys?.length}
                        />
                      </ThemeProvider>
                    }
                    label="Select All"
                  />
                </FormGroup>
              </div>

              <CheckboxTree
                nodes={nodesFiltered}
                checked={checked}
                showNodeIcon={false}
                icons={{
                  halfCheck: <IndeterminateCheckBox fontSize="small" />,
                  uncheck: (
                    <CheckBoxOutlineBlank
                      fontSize="small"
                      className="text-neutral-600"
                    />
                  ),
                  check: <CheckBox fontSize="small" />,
                  expandClose: (
                    <ArrowRight fontSize="small" className="text-neutral-600" />
                  ),
                  expandOpen: (
                    <ArrowDropDown
                      fontSize="small"
                      className="text-neutral-600"
                    />
                  )
                }}
                css="text-lg"
                expanded={expanded}
                onCheck={id => handleCheck(id)}
                onExpand={ex => setExpanded(ex)}
                showNodeTitle
                disabled={disabled}
                checkModel="all"
              />
            </>
          )}
        </>
      )}
    </div>
  );
};

GroupByProducts.defaultProps = {
  disabled: false,
  mappedData: [],
  loading: false
};
GroupByProducts.propTypes = {
  mappedData: PropTypes.arrayOf(PropTypes.object),
  disabled: PropTypes.bool,
  loading: PropTypes.bool
};

export default GroupByProducts;
