import React, { useContext, useEffect, useRef, useCallback } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';

import { GLOBAL_SET_ERROR } from 'reducers/reducer';
import TryAgainMessage from './TryAgainMessage';
import AlertMessage from './AlertMessage';
import { Context } from '../Store';
import ErrorBoundary from './ErrorBoundary';

export const ErrorHandler = ({ children, history }) => {
  const [{ error }, dispatch] = useContext(Context);
  const historyUnsubscribe = useRef();

  const handleClearError = useCallback(
    () => dispatch({ type: GLOBAL_SET_ERROR, payload: null }),
    [dispatch]
  );

  useEffect(() => {
    historyUnsubscribe.current = history.listen(() => handleClearError());

    return () => {
      if (historyUnsubscribe.current) {
        historyUnsubscribe.current();
      }
    };
  }, [history, handleClearError]);

  if (error?.hideError) {
    handleClearError();
    return <></>;
  }

  return error && error.showTryAgain ? (
    <TryAgainMessage error={error} onReset={handleClearError} />
  ) : (
    <>
      {error && <AlertMessage error={error} onReset={handleClearError} />}
      <ErrorBoundary>{children}</ErrorBoundary>
    </>
  );
};

ErrorHandler.defaultProps = {
  children: undefined,
  history: undefined
};

ErrorHandler.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.object),
    PropTypes.object
  ]),
  history: PropTypes.oneOfType([PropTypes.object])
};

export default withRouter(ErrorHandler);
