import { useCallback, useContext } from 'react';

import catchCancel from 'helpers/catchCancel';
import { Context } from 'components/Store';
import { getAccessToken } from 'utilities/base-auth';
import axios from 'axios';

const apiHost =
  process.env.REACT_APP_CROPWISE_PROXY_V2_URL || 'http://localhost:4000';

const useOrganizations = () => {
  const [{ organization }] = useContext(Context);

  const sortByNameOrganizations = (a, b) => {
    const orgNameA = a.name.toUpperCase();
    const orgNameB = b.name.toUpperCase();
    if (orgNameA < orgNameB) return -1;
    if (orgNameA > orgNameB) return 1;
    return 0;
  };

  const fetchSimplotOrganizations = useCallback(() => {
    return new Promise((resolve, reject) => {
      axios
        .get(`${apiHost}/integrations/simplot/organizations`, {
          headers: {
            Authorization: `Bearer ${getAccessToken()}`,
            'cwf-context': JSON.stringify({ organization_id: organization.id })
          }
        })
        .then(response => {
          const { data } = response;
          if (data && Array.isArray(data)) {
            const sorted = data.slice(0).sort(sortByNameOrganizations);
            resolve(sorted);
          }
        })
        .catch(catchCancel)
        .catch(reject);
    });
  }, [organization]);

  const saveSimplotOrganization = useCallback(
    (selectedOrgId, simplotOrganizations) => {
      return new Promise((resolve, reject) => {
        axios
          .post(
            `${apiHost}/integrations/simplot/match`,
            [
              {
                cwfEntityId: organization.id,
                simplotEntityDescription: simplotOrganizations.find(
                  simplotOrg => simplotOrg.id === selectedOrgId
                )?.name,
                simplotEntityId: selectedOrgId,
                type: 'Organization'
              }
            ],
            {
              headers: {
                Authorization: `Bearer ${getAccessToken()}`,
                'cwf-context': JSON.stringify({
                  organization_id: organization.id
                })
              }
            }
          )
          .then(response => {
            resolve(response);
          })
          .catch(catchCancel)
          .catch(reject);
      });
    },
    [organization]
  );

  return {
    saveSimplotOrganization,
    fetchSimplotOrganizations
  };
};

export default useOrganizations;
