import React, { useContext } from 'react';
import { SimplotContext } from 'screens/Integrations/SimplotIntegration/context/Provider';
import SubmissionCropSeason from 'screens/Integrations/components/SubmissionCropSeason';
import SubmissionSummary from 'screens/Integrations/SubmissionSummary';
import { PropTypes } from 'prop-types';
import ReviewPage from 'screens/Integrations/SubmissionSummary/components/ReviewPage';
import SimplotAuthenticationPage from '../SimplotAuthenticationPage';
import SimplotOrganization from '../SimplotOrganization';
import SimplotPropertiesSelection from '../SimplotPropertiesSelection';
import { INTEGRATIONS } from '../../../helpers/constants';
import PropertiesMatch from '../../../PropertiesMatch';
import SimplotSubmissionFooter from '../SimplotSubmissionFooter';
import SimplotMatchProducts from '../SimplotMatchProducts';

export const PROPERTIES_SELECTION_STEP = 4; // Used to not allow the user move forward the step by progress bar

const STEPS = {
  1: <SimplotAuthenticationPage />,
  2: <SimplotOrganization />,
  3: (
    <SubmissionCropSeason
      context={INTEGRATIONS.simplot}
      FooterComponent={SimplotSubmissionFooter}
    />
  ),
  4: <SimplotPropertiesSelection />,
  5: <PropertiesMatch integrationName={INTEGRATIONS.simplot} />,
  6: <SimplotMatchProducts />,
  7: (
    <SubmissionSummary
      FooterComponent={SimplotSubmissionFooter}
      ReviewComponent={ReviewPage}
    />
  )
};

const StepSelector = ({ fixedStep }) => {
  const {
    state: { step }
  } = useContext(SimplotContext);

  return STEPS[fixedStep ?? step];
};

StepSelector.propTypes = {
  fixedStep: PropTypes.number
};

export default StepSelector;
