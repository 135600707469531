/* eslint-disable no-console */
/* eslint-disable react/destructuring-assignment */
import React from 'react';
import amplitude from 'amplitude-js';
import PropTypes from 'prop-types';
import properties from './properties';
import events from './events';

const AmplitudeContext = React.createContext();
// creates API instance for amplitude
// eslint-disable-next-line no-unused-vars
const instance1 = amplitude
  .getInstance()
  .init(process.env.REACT_APP_AMPLITUDE_KEY);

function AmplitudeContextProvider(props) {
  // send an event to amplitude
  function sendEventToAmplitude(event, eventAttributes = {}) {
    amplitude.getInstance().logEvent(event, eventAttributes);
  }

  function setUserId(id) {
    amplitude.setUserId(id);
  }

  // identify user, sets properties
  function sendToAmplitudeIdentify(key, value) {
    const identify = new amplitude.Identify().setOnce(key, value.email);
    amplitude.getInstance().identify(identify);
  }

  function updateIdentify(orgName, orgId) {
    const identifyUpdate = new amplitude.Identify()
      .set('organization_name', orgName)
      .set('organization_id', orgId);
    amplitude.getInstance().identify(identifyUpdate);
    sendEventToAmplitude(events.epic.Organization.switchingOrgs);
  }
  // returns the correct navItem key
  // eslint-disable-next-line consistent-return
  function filterNavItems(key) {
    switch (key) {
      case events.epic.Organization.id:
        return events.epic.Organization.accessOrg;
      case events.epic.PropertiesV2.id:
        return events.epic.PropertiesV2.accessPropertiesV2;
      case events.epic.Properties.id:
        return events.epic.Properties.accessProps;
      case events.epic.CropSeason.id:
        return events.epic.CropSeason.accessCropSeasons;
      case events.epic.Plans.id:
        return events.epic.Plans.accessPlan;
      case events.epic.Recommendations.id:
        return events.epic.Recommendations.accessRecommendations;
      case events.epic.Tasks.id:
        return events.epic.Tasks.accessTasks;
      case events.epic.Invoices.id:
        return events.epic.Invoices.accessInvoice;
      case events.epic.People.id:
        return events.epic.People.accessPeople;
      case events.epic.Companies.id:
        return events.epic.Companies.accessCompanies;
      case events.epic.Equipment.id:
        return events.epic.Equipment.accessEquipment;
      case events.epic.Reports.id:
        return events.epic.Reports.accessReports;
      case events.epic.ProductList.id:
        return events.epic.ProductList.accessPPL;
      default:
        return key;
    }
  }

  return (
    <AmplitudeContext.Provider
      value={{
        amplitude,
        events,
        properties,
        setUserId,
        sendEventToAmplitude,
        sendToAmplitudeIdentify,
        filterNavItems,
        updateIdentify
      }}
    >
      {props.children}
    </AmplitudeContext.Provider>
  );
}
AmplitudeContextProvider.propTypes = {
  children: PropTypes.node.isRequired
};

export { AmplitudeContextProvider, AmplitudeContext };
