import React, { useContext } from 'react';
import { Radio } from 'syngenta-digital-cropwise-react-ui-kit';
import { Context } from 'components/Store';
import createAction from 'helpers/createAction';
import { SET_MAP_STYLE_IMAGE } from 'reducers/reducer';
import SatelliteIcon from 'components/Icons/SatelliteIcon';
import TrafficLightIcon from 'components/Icons/TrafficLightIcon';
import { MAP_STYLE_TYPES } from 'helpers/constants';
import CollapsibleSection from '../CollapsibleSection';

const BackgroundImageSection = () => {
  const [{ mapStyleImage }, dispatch] = useContext(Context);

  const handleRadioChange = e => {
    createAction(dispatch, SET_MAP_STYLE_IMAGE, e.target.value);
  };

  return (
    <CollapsibleSection title="Background map image">
      <div className="px-4 pb-4">
        <div className="mb-2">
          <Radio.Group
            name="background-image"
            className="flex flex-col"
            value={mapStyleImage}
            onChange={handleRadioChange}
          >
            <Radio.Button
              value={MAP_STYLE_TYPES.satellite}
              className="flex items-center"
            >
              <div className="relative">
                <div className="flex items-center pt-1">
                  <SatelliteIcon />
                  <span style={{ marginLeft: '1px' }}>Satellite</span>
                </div>
              </div>
            </Radio.Button>

            <Radio.Button
              value={MAP_STYLE_TYPES.street}
              className="flex items-center"
            >
              <div className="relative">
                <div className=" flex items-center mt-3 mb-2">
                  <TrafficLightIcon />
                  <span style={{ marginLeft: '1px' }}>Road map</span>
                </div>
              </div>
            </Radio.Button>
          </Radio.Group>
        </div>
      </div>
    </CollapsibleSection>
  );
};

export default BackgroundImageSection;
