import React, { useContext, useEffect } from 'react';
import { Checkbox, Spinner } from '@agconnections/grow-ui';
import GrowUIFormField from 'components/Forms/GrowUIFormField';
import PropTypes from 'prop-types';
import useSaleLocations from '../../../../../SaleLocations/hooks/useSaleLocations';
import { Context } from '../../../../../../../components/Store';

const SaleLocationsFilter = ({ filters }) => {
  const {
    isLoadingSaleLocationList,
    getSaleLocationList,
    saleLocationList
  } = useSaleLocations();
  const [{ organization }] = useContext(Context);

  useEffect(() => {
    getSaleLocationList(organization.id);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      {isLoadingSaleLocationList ? (
        <Spinner />
      ) : (
        <>
          {saleLocationList?.length > 0 ? (
            saleLocationList?.map(saleLocation => (
              <div key={saleLocation?.id} className="flex items-center py-6px">
                <GrowUIFormField
                  control={Checkbox}
                  name="saleIds"
                  value={saleLocation.id}
                  checked={filters.saleIds?.includes(saleLocation.id)}
                />
                <p className="ml-2">{saleLocation?.name}</p>
              </div>
            ))
          ) : (
            <div className="text-neutral-60 text-sm ">
              No sale locations found.
            </div>
          )}
        </>
      )}
    </>
  );
};

SaleLocationsFilter.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  filters: PropTypes.object.isRequired
};

export default SaleLocationsFilter;
