export const filterItems = (item, values) => {
  const checkIfValueIsPresent = value =>
    item.name.toLowerCase().includes(value.toLowerCase());
  if (values.length === 1) {
    return values.every(checkIfValueIsPresent);
  }
  return values.every(checkIfValueIsPresent);
};

export const findUser = (users, value) => {
  if (value) {
    const values = value.match(/[^ ]+/g);
    const filteredItems = [];
    const foundItem = users.filter(item => {
      return filterItems(item, values);
    });
    if (foundItem) {
      foundItem.forEach(fi => {
        filteredItems.push(fi);
      });
    }
    return filteredItems;
  }
  return users;
};
