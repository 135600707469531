import Dexie from 'dexie';
import { flatten, delay } from 'lodash';

import { getCwfContext } from './interceptors';
import resourceLookup from './resourceLookup';
import axios from './getAxiosInstance';
import { dexieCacheModel } from './searchModels';

const db = new Dexie('cwf-cache');
db.version(1.1).stores({ organization: 'id,name', cropseason: 'id,name' });

const EXPIRATIONS = {
  organization: 15 * 60 * 1000, // 15 min
  cropseason: 15 * 60 * 1000 // 15 min
};

// returns an object with key of 'data' paired with the specified value
export const wrapInDataContainer = (
  value,
  restOfResponse,
  source,
  unwrapArray = false
) => {
  const data = unwrapArray ? value[0] : value;
  return {
    data,
    ...restOfResponse,
    source
  };
};

// returns the data taken out of the container with the specified name
export const unwrapContainer = (data, containerName) =>
  data && containerName ? data[containerName] : data;

export const currentFetches = {};

const rewrapPromiseResponse = (promise, containerName, source) =>
  promise.then(({ data, ...restOfResponse }) => {
    const results = unwrapContainer(data, containerName);
    return wrapInDataContainer(results, restOfResponse, source);
  });

const getFetchInProgress = (url, containerName) =>
  currentFetches[url] &&
  rewrapPromiseResponse(currentFetches[url], containerName);

export const reloadPage401Error = () => {
  localStorage.removeItem('selectedOrganizationId');
  localStorage.removeItem('selectedCropSeasons');
  window.location.replace('/');
};

export const setCurrentFetch = (url, containerName, promiseCallback) => {
  const rewrapExistingPromise = promise => {
    return promise
      ? rewrapPromiseResponse(promise, containerName, 'lastFetch')
      : null;
  };
  const setExpiringFetchCallback = () => {
    currentFetches[url] = promiseCallback();
    currentFetches[url]
      .then(() => {
        delay(() => {
          currentFetches[url] = null;
        }, 1000);
      })
      .catch(error => {
        currentFetches[url] = null;
        if (
          error.response?.status === 401 &&
          !url.includes('api/v1/base/organization')
        ) {
          reloadPage401Error();
        }
      });
    return currentFetches[url];
  };
  return (
    rewrapExistingPromise(currentFetches[url]) || setExpiringFetchCallback()
  );
};

// const getParentResourceIds = urlBuilderInstance => {
//   const urlConfig = urlBuilderInstance.getConfig();
//   return urlConfig.parentResources.reduce((result, item) => {
//     if (!item.idField) {
//       return result;
//     }
//     return {
//       ...result,
//       [item.idField]: item.idCallback()
//     };
//   }, {});
// };

const getFilterParams = (
  table,
  cwfContextRegExpExclusions = [],
  url,
  parentResourceIds,
  query
) => {
  const ORG_ID_PREFIX = '{organization_id: ';

  const contextValue = getCwfContext(
    axios.interceptors,
    cwfContextRegExpExclusions,
    url
  );
  const organizationId = contextValue
    ?.replace(ORG_ID_PREFIX, '')
    .substr(0, contextValue.length - ORG_ID_PREFIX.length - 1);
  const orgIdField = table === 'organization' ? 'id' : 'organizationId';
  const baseQuery = {
    ...(parentResourceIds || {}),
    ...(organizationId ? { [orgIdField]: organizationId } : {})
  };
  const { whereClause, filterClause } = dexieCacheModel.onRequest(
    baseQuery,
    query
  );
  return organizationId || parentResourceIds || query?.filters?.length
    ? { whereClause, filterClause }
    : {};
};

// if any items in the table are expired, clear the table for re-population
const checkCacheExpiration = async (items, table) => {
  // items might be a filtered view, so double check the full table if necessary
  if (!items.length) {
    const size = await db[table].count();
    if (size === 0) {
      return null;
    }
  }
  const now = Date.now();
  const expired = items.find(item => {
    const then = item.cacheTimestamp ? item.cacheTimestamp.getTime() : now;
    return now - then > EXPIRATIONS[table];
  });
  if (expired) {
    db[table].clear();
    return null;
  }
  return items;
};

const lookUpResource = resourceName => resourceLookup[resourceName] || {};

export const fetchFromCache = async (
  resourceName,
  { url, cwfContextRegExpExclusions, parentResourceIds, query, containerName }
) => {
  const currentFetch = getFetchInProgress(url, containerName);
  if (currentFetch) {
    return currentFetch;
  }
  const { table } = lookUpResource(resourceName);
  const isRequestForSingleItem = resourceName === table;
  // if this resource doesn't have a table in the cache db, exit out
  if (!db[table]) {
    return null;
  }
  // parent resource ids takes the form of { organizationId: '123' }
  const { whereClause, filterClause } = getFilterParams(
    table,
    cwfContextRegExpExclusions,
    url,
    parentResourceIds,
    query
  );
  const response =
    whereClause && Object.keys(whereClause).length
      ? db[table].where(whereClause)
      : db[table];
  const filteredResponse = filterClause
    ? response.filter(filterClause)
    : response;
  const results = await filteredResponse.toArray();
  const fresh = await checkCacheExpiration(results, table);
  // use the same response structure as that returned by the api
  return fresh
    ? wrapInDataContainer(fresh, null, 'cache', isRequestForSingleItem)
    : null;
};

export const addToCache = (
  resourceName,
  data,
  { url, cwfContextRegExpExclusions, parentResourceIds }
) => {
  if (!data) {
    return;
  }
  const { table } = lookUpResource(resourceName);
  // if this resource doesn't have a table in the cache db, exit out
  if (!db[table]) {
    return;
  }
  const { whereClause } = getFilterParams(
    table,
    cwfContextRegExpExclusions,
    url,
    parentResourceIds
  );

  db[table].bulkAdd(
    flatten(data).map(item => ({
      ...item,
      ...whereClause,
      cacheTimestamp: new Date()
    }))
  );
};

export const fetchFromCacheById = async (resourceName, id) => {
  const { table } = lookUpResource(resourceName);
  if (!db[table]) {
    return null;
  }
  const data = await db[table].get(id);
  return {
    data
  };
};

export const addToCacheById = (
  resourceName,
  data,
  { url, cwfContextRegExpExclusions, parentResourceIds }
) => {
  const { table } = lookUpResource(resourceName);
  // if this resource doesn't have a table in the cache db, exit out
  if (!db[table]) {
    return;
  }
  const { whereClause } = getFilterParams(
    table,
    cwfContextRegExpExclusions,
    url,
    parentResourceIds
  );

  db[table].add({ ...data, ...whereClause, cacheTimestamp: new Date() });
};

// if the resourceLookup indicates that a cache table should be cleared when the specified resource is modified, handle that here
export const checkCacheSettings = (resourceName, promise) => {
  const { clearTableOnChange } = lookUpResource(resourceName);
  if (clearTableOnChange) {
    promise.then(() => {
      db[clearTableOnChange].clear();
    });
  }
};

export const deleteCache = async () => {
  db.tables.forEach(table => {
    table.clear();
  });
};
