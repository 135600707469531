import React, { useContext, useEffect, useState } from 'react';
import Breadcrumb from 'components/App/AppShell/components/Breadcrumb';
import './index.css';
import Toast from 'components/Toast';
import { Context } from 'components/Store';
import ComingSoonIcon from 'assets/coming_soon.svg';
import { SET_SHOW_TOAST_CROP_ZONE_MATCHER } from 'reducers/reducer';
import { useFlags } from 'launchdarkly-react-client-sdk';
import EquipmentIcon from 'components/Icons/EquipmentIcon';
import SubmitIcon from 'components/Icons/SubmitIcon';
import ChecklistIcon from 'components/Icons/ChecklistIcon';
import StoreIcon from 'components/Icons/StoreIcon';
import BaseCardIntegration from './components/BaseCardIntegration';
import CardGalleryIntegration from './components/CardGalleryIntegration';
import {
  getConnectIntegrations,
  getProcessorIntegrations,
  getRegulatoryIntegrations,
  getRetailIntegrations
} from './helpers/processors';
import displayTestForToast, { toastTitle } from './helpers/displayTextForToast';
import CardGalleryHeader from './components/CardGalleryHeader';

const Integrations = () => {
  const [
    {
      cropZoneMatcherType,
      showToastCropZoneMatcher,
      additionalInformationToastCropZoneMatcher,
      organization
    },
    dispatch
  ] = useContext(Context);
  const flags = useFlags();

  const {
    operationMapped,
    cropZoneName
  } = additionalInformationToastCropZoneMatcher;

  const [openToast, setOpenToast] = useState(showToastCropZoneMatcher);
  const [connectInfo, setConnectInfo] = useState(null);
  const [processorTypes, setProcessorTypes] = useState(null);
  const [regulatoryTypes, setRegulatoryTypes] = useState(null);
  const [retailTypes, setRetailTypes] = useState(null);

  const [
    reloadUnmappedCropZonesCount,
    setReloadUnmappedCropZonesCount
  ] = useState(false);

  const handleOrgChange = () => {
    setReloadUnmappedCropZonesCount(prev => !prev);
  };

  useEffect(() => {
    setConnectInfo(getConnectIntegrations(flags, organization?.id));
    setProcessorTypes(getProcessorIntegrations(flags, organization?.id));
    setRegulatoryTypes(getRegulatoryIntegrations(flags, organization?.id));
    setRetailTypes(getRetailIntegrations(flags, organization?.id));
  }, [flags, organization]);

  return (
    <div data-testid="integrations">
      <Breadcrumb
        onOrganizationSelect={handleOrgChange}
        hideCropSeasonDropdown
        onCropSeasonSelect={handleOrgChange}
      >
        <Breadcrumb.Item title="Integrations" value="All Integrations" isLast />
      </Breadcrumb>
      <div
        data-testid="integrations-cards-container"
        className="my-4 ml-6 font-body"
      >
        {connectInfo?.length <= 0 && processorTypes?.length <= 0 ? (
          <div className="h-screen flex items-center justify-center">
            <div className="text-center grid grid-rows-3">
              <div className="row">
                <img
                  width={150}
                  src={ComingSoonIcon}
                  alt="empty-state"
                  className="mx-auto"
                />
              </div>
              <div className="row">
                <div className="font-bold">
                  <span>Integrations Coming Soon</span>
                </div>
                <div className="w-96 row">
                  <span>
                    We’re hard at work adding Integrations to Cropwise
                    Financials. These will make it easier for you bring in your
                    data and send it out to the partners you work with. Stay
                    tuned!
                  </span>
                </div>
              </div>
            </div>
          </div>
        ) : (
          <></>
        )}
        {processorTypes?.length > 0 && (
          <CardGalleryHeader
            title="Processor"
            subtitle="Selectively send your Task based application records to the various food processing companies that you contract with"
            icon={<SubmitIcon color="green-700" />}
          />
        )}
        <CardGalleryIntegration>
          {processorTypes?.map(
            ({ integrationType, title, description, name, urlPath }) => (
              <BaseCardIntegration
                key={integrationType}
                title={title}
                description={description}
                status={name}
                integrationType={integrationType}
                urlPath={urlPath}
              />
            )
          )}
        </CardGalleryIntegration>

        {regulatoryTypes?.length > 0 && (
          <CardGalleryHeader
            title="Regulatory"
            subtitle="Manage records for regulatory purposes"
            icon={<ChecklistIcon color="green-700" />}
          />
        )}
        <CardGalleryIntegration>
          {regulatoryTypes?.map(
            ({ integrationType, title, description, name, urlPath }) => (
              <BaseCardIntegration
                key={integrationType}
                title={title}
                description={description}
                status={name}
                integrationType={integrationType}
                urlPath={urlPath}
              />
            )
          )}
        </CardGalleryIntegration>

        {connectInfo?.length > 0 && (
          <CardGalleryHeader
            title="Machinery"
            subtitle="Tracking apps for tractors, planters, harvesters and agricultural aircraft"
            icon={<EquipmentIcon color="green-700" size={32} />}
          />
        )}
        <CardGalleryIntegration>
          {connectInfo?.map(({ name, description, integrationType }) => (
            <BaseCardIntegration
              key={integrationType}
              title={name}
              description={description}
              integrationType={integrationType}
              reloadUnmappedCropZonesCount={reloadUnmappedCropZonesCount}
              isExternalLink
            />
          ))}
        </CardGalleryIntegration>

        {retailTypes?.length > 0 && (
          <CardGalleryHeader
            title="Retail"
            subtitle="Import records from your retailer, removing the need to enter them yourself by hand"
            icon={
              <div className="m-1">
                <StoreIcon size={24} color="green-700" />
              </div>
            }
          />
        )}
        <CardGalleryIntegration>
          {retailTypes?.map(
            ({ integrationType, title, description, name, urlPath }) => (
              <BaseCardIntegration
                key={integrationType}
                title={title}
                description={description}
                status={name}
                integrationType={integrationType}
                urlPath={urlPath}
              />
            )
          )}
        </CardGalleryIntegration>
      </div>
      <Toast
        type={cropZoneMatcherType}
        title={`${toastTitle(cropZoneMatcherType)} ${cropZoneName}`}
        open={openToast}
        onClose={() => {
          setOpenToast(false);
          dispatch({
            type: SET_SHOW_TOAST_CROP_ZONE_MATCHER,
            payload: false
          });
        }}
        timeout={5500}
      >
        <p className="text-sm font-body">{`${operationMapped} ${displayTestForToast(
          cropZoneMatcherType
        )}.`}</p>
      </Toast>
    </div>
  );
};

export default Integrations;
