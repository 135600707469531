import React from 'react';
import ReactDOM from 'react-dom';

import { SimpleModal } from '@agconnections/grow-ui';

const modalRoot = document.getElementById('cwf-modal-root');

// use a PortalModal instead of a SimpleModal if you need the modal to be inserted into the DOM outside of the app root
const PortalModal = props => {
  if (process.env.JEST_WORKER_ID) {
    // eslint-disable-next-line react/jsx-props-no-spreading
    return <SimpleModal {...props} />;
  }
  // eslint-disable-next-line react/jsx-props-no-spreading
  return ReactDOM.createPortal(<SimpleModal {...props} />, modalRoot);
};

export default PortalModal;
