import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { Input, DatePicker, TextArea, Form } from '@agconnections/grow-ui';
import { set } from 'lodash';
import GrowUIFormField from 'components/Forms/GrowUIFormField';
import { getCropzonesCropArea } from '../../helpers/propertyHelpers';

const FormSection = ({
  properties,
  propertiesLoading,
  setFieldValue,
  values,
  setTotalArea,
  setGeoJSONCollectionFeatures,
  fieldsAndAreasGeoJSONCollection
}) => {
  let temp = 0;
  const getCropZonesData = cropZones => {
    getCropzonesCropArea(cropZones).then(results => {
      setTotalArea(
        results.reduce((acc, { reportedArea, boundaryArea }) => {
          temp = acc + (reportedArea || boundaryArea);
          return temp;
        }, 0)
      );
    });
  };

  const filterFeatureCollectionByCropZones = (fc, ids) => {
    return fc.features
      ? fc.features.map(feat => {
          set(
            feat,
            'properties.$layer',
            ids?.includes(feat.properties?.id) ||
              (ids?.includes(feat.properties?.id) &&
                !feat.properties?.$parentDuplicated)
              ? 'focused'
              : 'default'
          );
          return feat;
        })
      : [];
  };

  const updateZoom = featureLayer =>
    setGeoJSONCollectionFeatures(
      fieldsAndAreasGeoJSONCollection.features
        ? fieldsAndAreasGeoJSONCollection.features.map(feat => {
            set(feat, 'properties.$layer', featureLayer);
            return feat;
          })
        : []
    );

  const updateSelectedProperties = (cropZoneIds, farmIds) =>
    properties
      .filter(({ id }) => farmIds.includes(id))
      .map(property => {
        const propertyCropZones = property.fields.flatMap(
          ({ cropzones }) => cropzones
        );
        return {
          farmId: property.id,
          cropzoneIds: propertyCropZones
            .filter(({ id }) => cropZoneIds.includes(id))
            .map(({ id }) => id)
        };
      });

  const handlePropertySelect = (cropZoneIds, farmIds) => {
    setGeoJSONCollectionFeatures(
      filterFeatureCollectionByCropZones(
        fieldsAndAreasGeoJSONCollection,
        cropZoneIds
      )
    );
    const selectedProperties = updateSelectedProperties(cropZoneIds, farmIds);
    getCropZonesData(cropZoneIds);
    setFieldValue('properties', selectedProperties);

    // Zooming out map in case the properties checklist is completely unchecked.
    if (
      !propertiesLoading &&
      properties.length !== 0 &&
      selectedProperties.length === 0
    ) {
      updateZoom('focused'); // setting as 'focused' to zoom out first.
      updateZoom('default'); // then setting as 'default' to update CSS.
    }
  };

  useEffect(() => {
    if (values.cropZones) {
      handlePropertySelect(values.cropZones, values.farms);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [propertiesLoading]);

  return (
    <div
      data-testid="cropseason-sidebar"
      className="flex-col items-center justify-center flex-none p-6 bg-white"
      style={{ width: '500px' }}
    >
      <Form.Field label="Crop Season Name*">
        <GrowUIFormField
          control={Input}
          name="name"
          data-testid="cropSeasonName"
        />
      </Form.Field>
      <Form.Field label="Crop Season Dates*">
        <GrowUIFormField
          control={DatePicker}
          name="date"
          isRangePicker
          data-testid="cropSeasonDate"
        />
      </Form.Field>
      <Form.Field label="Notes">
        <TextArea
          name="values.notes"
          id="notes"
          onChange={e => setFieldValue('notes', e.target.value)}
          value={values.notes}
        />
      </Form.Field>
    </div>
  );
};

FormSection.propTypes = {
  properties: PropTypes.arrayOf(PropTypes.object),
  propertiesLoading: PropTypes.bool,
  setFieldValue: PropTypes.func.isRequired,
  values: PropTypes.objectOf(PropTypes.any).isRequired,
  setTotalArea: PropTypes.func.isRequired,
  setGeoJSONCollectionFeatures: PropTypes.func.isRequired,
  fieldsAndAreasGeoJSONCollection: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.object),
    PropTypes.object
  ]).isRequired
};

FormSection.defaultProps = {
  properties: [],
  propertiesLoading: true
};
export default FormSection;
