import React from 'react';
import PropTypes from 'prop-types';
import { useFormikContext } from 'formik';
import { Checkbox } from '@agconnections/grow-ui';
import PropertiesIcon from 'assets/properties.svg';
import StorageIcon from 'assets/storage.svg';
import SaleIcon from 'assets/storefront.svg';
import ArrowIcon from 'assets/arrow-forward.svg';
import GrowUIFormField from 'components/Forms/GrowUIFormField';

const LoadTypeCheckbox = ({ value, from, to }) => {
  const { values } = useFormikContext();
  return (
    <div className="flex items-center py-6px cwf-yield-load-type">
      <GrowUIFormField
        control={Checkbox}
        name="loadtype"
        value={value}
        checked={values.loadtype?.includes(value)}
      />

      <div className="flex items-center ml-2">
        {from}
        <img src={ArrowIcon} alt="load-target" className="mx-1" />
        {to}
      </div>
    </div>
  );
};

LoadTypeCheckbox.propTypes = {
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  from: PropTypes.node.isRequired,
  to: PropTypes.node.isRequired
};

const LoadTypeOption = ({ icon, name }) => {
  return (
    <div className="flex">
      <img src={icon} alt={`${name}-icon`} className="w-20px h-20px mr-1" />
      <span className="text-black font-sans text-sm font-normal leading-5 tracking-tight">
        {name}
      </span>
    </div>
  );
};

LoadTypeOption.propTypes = {
  icon: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired
};

const loadOptions = {
  Properties: <LoadTypeOption icon={PropertiesIcon} name="Properties" />,
  Sale: <LoadTypeOption icon={SaleIcon} name="Sale" />,
  Storage: <LoadTypeOption icon={StorageIcon} name="Storage" />
};

export { loadOptions };

export default LoadTypeCheckbox;
