import React, { createContext, useMemo, useReducer } from 'react';
import PropTypes from 'prop-types';

import { INTEGRATIONS } from 'screens/Integrations/helpers/constants';
import useIntegrationMatching from 'hooks/useIntegrationMatching';
import reducer, { SET_MATCHES, SET_FETCHED_MATCHES } from '../reducer';

export const initialState = {
  matches: [],
  fetchedMatches: [],
  errorMessage: '',
  matchEntityType: ''
};

export const ProductsMatchContext = createContext(initialState);

const ProductsMatchProvider = ({
  integrationType,
  children,
  onCompleteMatch
}) => {
  const [state, dispatch] = useReducer(reducer, {
    ...initialState,
    integrationType,
    children,
    matchEntityType: ['Product']
  });

  const {
    getMatches,
    createMatches,
    loading: isLoading
  } = useIntegrationMatching();

  const updateMatches = (payload, isFetched = false) => {
    dispatch({
      type: !isFetched ? SET_MATCHES : SET_FETCHED_MATCHES,
      payload
    });
  };

  // eslint-disable-next-line consistent-return
  const submitMatches = async () => {
    const newOrUpdatedMatches = state.matches.filter(
      m =>
        state.fetchedMatches.findIndex(
          fetchedMatch =>
            m.cwfEntityId === fetchedMatch.cwfEntityId &&
            m.agrianEntityId === fetchedMatch.agrianEntityId
        ) === -1
    );

    if (newOrUpdatedMatches.length === 0) {
      return onCompleteMatch();
    }

    const response = await createMatches({
      vendor: INTEGRATIONS.agrian,
      matches: newOrUpdatedMatches
    });

    if (response?.status === 201) {
      onCompleteMatch();
    }
  };

  const fetchMatches = async () => {
    const response = await getMatches({
      vendor: INTEGRATIONS.agrian,
      matchTypes: state.matchEntityType
    });

    if (response?.status === 200) {
      updateMatches(response.data, true);
    }
  };

  const memoized = useMemo(
    () => ({
      state,
      integrationContext: INTEGRATIONS.agrianRecommendation,
      dispatch,
      updateMatches,
      submitMatches,
      fetchMatches,
      isLoading
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [state, isLoading]
  );

  return (
    <ProductsMatchContext.Provider value={memoized}>
      {children}
    </ProductsMatchContext.Provider>
  );
};

ProductsMatchProvider.defaultProps = {
  children: null,
  integrationType: INTEGRATIONS.simplot,
  onCompleteMatch: null
};

ProductsMatchProvider.propTypes = {
  children: PropTypes.node,
  integrationType: PropTypes.string,
  onCompleteMatch: PropTypes.func
};

export default ProductsMatchProvider;
