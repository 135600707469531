import React from 'react';
import PropTypes from 'prop-types';
import { Row } from 'antd';

const MapControlButton = ({ onClick, className, children, ...rest }) => {
  return (
    <button
      type="button"
      className={`p-2 z-1 rounded border border-neutral-30 bg-white hover:bg-neutral-10 focus:outline-none ${className}`}
      onClick={onClick}
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...rest}
    >
      <Row className="items-center">{children}</Row>
    </button>
  );
};

MapControlButton.propTypes = {
  onClick: PropTypes.func.isRequired,
  className: PropTypes.string,
  children: PropTypes.node.isRequired,
  rest: PropTypes.objectOf(PropTypes.any)
};

MapControlButton.defaultProps = {
  className: null,
  rest: {}
};
export default MapControlButton;
