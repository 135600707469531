import React, { useMemo, useEffect } from 'react';
import PropTypes from 'prop-types';
import GrowUIFormField from 'components/Forms/GrowUIFormField';
import { useFormikContext } from 'formik';
import { DatePicker } from '@agconnections/grow-ui';
import sortDates from 'screens/Integrations/helpers/sortDates';

const CropSeasonDateRange = ({ className, isRequired }) => {
  const { values, setFieldValue } = useFormikContext();
  const { startDate: sd, endDate: ed } = useMemo(() => {
    const { startDate, endDate } = sortDates(values.selectedCropSeasons);
    return { startDate, endDate };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values.selectedCropSeasons]);

  useEffect(() => {
    setFieldValue('cropSeasonStartDate', sd);
    setFieldValue('cropSeasonEndDate', ed);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sd, ed]);

  return (
    <div className={className}>
      <GrowUIFormField
        label={isRequired ? 'Start Date *' : 'Start Date'}
        name="cropSeasonStartDate"
        dates={sd}
        control={DatePicker}
        disabled={!values?.selectedCropSeasons?.length}
      />
      <GrowUIFormField
        label={isRequired ? 'End Date *' : 'End Date'}
        name="cropSeasonEndDate"
        dates={ed}
        control={DatePicker}
        disabled={!values?.selectedCropSeasons?.length}
      />
    </div>
  );
};

CropSeasonDateRange.propTypes = {
  className: PropTypes.string,
  isRequired: PropTypes.bool.isRequired
};

CropSeasonDateRange.defaultProps = {
  className: ''
};

export default CropSeasonDateRange;
