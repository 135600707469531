import { cloneDeep, orderBy } from 'lodash';

const ingredientTableSort = (ingredients, field, direction) => {
  if (!ingredients?.length) return [];
  if (!direction) return ingredients;

  const _ingredients = ingredients.map(ingredient => cloneDeep(ingredient));
  switch (field) {
    case 'name':
      return orderBy(_ingredients, [ingredient => ingredient?.name], direction);
    case 'totalQuantity':
      return orderBy(
        _ingredients,
        [ingredient => ingredient?.totalQuantity],
        direction
      );
    default:
      return _ingredients;
  }
};

export default ingredientTableSort;
