import { useState, useContext } from 'react';

import { Context } from 'components/Store';

export const useEquipmentItemData = id => {
  const [isCreateScreen, setIsCreateScreen] = useState(id === 'item');
  const [loading, setLoading] = useState(!isCreateScreen);
  const [, dispatch] = useContext(Context);

  return {
    isCreateScreen,
    setIsCreateScreen,
    loading,
    setLoading,
    dispatch
  };
};

export default useEquipmentItemData;
