import { useCallback, useContext, useState } from 'react';
import { season as seasonApi } from 'utilities/api';

import { Context } from 'components/Store';
import catchCancel from 'helpers/catchCancel';
import { parseServerError } from 'helpers/errorHelpers';

const useCropSeason = () => {
  const [{ cropSeasons }, dispatch] = useContext(Context);
  const [dividedCropSeasons, setDividedCropSeasons] = useState({
    active: [],
    upcoming: [],
    past: []
  });

  const getCropSeason = async id => {
    const { promise } = seasonApi.fetch(id);
    return promise
      .then(({ data }) => {
        return data;
      })
      .catch(catchCancel)
      .catch(parseServerError(dispatch));
  };

  const updateCropSeason = async (id, body) => {
    const { promise } = seasonApi.update(id, body);
    return promise
      .then(({ data }) => {
        return data;
      })
      .catch(catchCancel)
      .catch(parseServerError(dispatch));
  };

  const splitCropSeasons = useCallback(() => {
    const currentTime = new Date().getTime();
    const groupedSeasons = { active: [], upcoming: [], past: [] };
    cropSeasons.forEach(item => {
      if (currentTime > item.startDateEpoch) {
        if (currentTime > item.endDateEpoch) {
          groupedSeasons.past.push(item);
        } else {
          groupedSeasons.active.push(item);
        }
      } else {
        groupedSeasons.upcoming.push(item);
      }
    });

    setDividedCropSeasons(groupedSeasons);
  }, [cropSeasons]);

  const prepareProperties = (cropZones, newFarmId, newCropzoneId) => {
    const farms = {};
    farms[newFarmId] = { farmId: newFarmId, cropzoneIds: [newCropzoneId] };

    cropZones.forEach(({ farmId, cropzoneId }) => {
      if (farms[farmId]) {
        if (!farms[farmId].cropzoneIds.find(id => id === cropzoneId)) {
          farms[farmId].cropzoneIds.push(cropzoneId);
        }
      } else {
        farms[farmId] = { farmId, cropzoneIds: [cropzoneId] };
      }
    });

    return Object.values(farms);
  };

  const removeProperties = (cropZones, oldFarmId, oldCropzoneId) => {
    const farms = {};
    farms[oldFarmId] = { farmId: oldFarmId, cropzoneIds: [] };

    cropZones.forEach(({ farmId, cropzoneId }) => {
      if (farms[farmId]) {
        if (cropzoneId !== oldCropzoneId) {
          farms[farmId].cropzoneIds.push(cropzoneId);
        }
      } else {
        farms[farmId] = { farmId, cropzoneIds: [cropzoneId] };
      }
    });

    return Object.values(farms).filter(property => property.cropzoneIds.length);
  };

  const prepareCropSeason = (
    cropSeason,
    farmId,
    cropzoneId,
    remove = false
  ) => {
    const {
      id,
      name,
      notes,
      startDateEpoch,
      endDateEpoch,
      cropZones
    } = cropSeason;

    const properties = !remove
      ? prepareProperties(cropZones, farmId, cropzoneId)
      : removeProperties(cropZones, farmId, cropzoneId);

    return {
      id,
      name,
      notes,
      properties,
      endDateEpoch,
      startDateEpoch,
      createdDate: cropSeason.createdDate,
      createdDateEpoch: cropSeason.createdDateEpoch
    };
  };

  return {
    getCropSeason,
    updateCropSeason,
    dividedCropSeasons,
    splitCropSeasons,
    prepareCropSeason
  };
};

export default useCropSeason;
