/* istanbul ignore file */
import { Factory } from 'miragejs';
import globalSchema from '../utils/globalSchema';

const onboarding = {
  dismissed: true,
  items: [
    {
      title: 'AddProperty',
      completed: true
    },
    {
      title: 'AssignCrops',
      completed: true
    },
    {
      title: 'CreatePlan',
      completed: false
    },
    {
      title: 'CreateTask',
      completed: false
    }
  ]
};

const Dashboard = {
  id() {
    return '25046b78-2904-4740-ad5d-d7986050ca34';
  },
  onboarding() {
    return onboarding;
  },
  items() {
    return [
      {
        title: 'CostPerAcre',
        options: {}
      }
    ];
  },
  ...globalSchema
};

export default Factory.extend(Dashboard);
