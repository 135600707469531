/* eslint-disable import/prefer-default-export */
export const timingOptions = [
  { key: 'Pre-Plant', value: 'Pre-Plant' },
  { key: 'Planting', value: 'Planting' },
  { key: 'Growing', value: 'Growing' },
  { key: 'Harvest', value: 'Harvest' },
  { key: 'Storage', value: 'Storage' },
  { key: 'Annual', value: 'Annual' }
];

export const costTypes = [
  { type: 'rent', title: 'Land Rent' },
  { type: 'equipment', title: 'Equipment' },
  {
    type: 'insurance',
    title: 'Insurance'
  },
  { type: 'labor', title: 'Labor' },
  { type: 'repairs', title: 'Repairs' },
  { type: 'taxes', title: 'Taxes' },
  { type: 'returnToManagement', title: 'Return To Management' }
];

export const TABLE_COLUMNS = [
  {
    field: 'name',
    title: 'Plan',
    sortable: true
  },
  {
    field: 'plannedArea',
    title: 'Planned Area',
    sortable: true
  },
  {
    field: 'crop',
    title: 'Crop',
    sortable: true
  },
  {
    field: 'fixedCostPerAcre',
    title: 'Fixed Cost/Ac',
    sortable: true
  },
  {
    field: 'varCostPerAcre',
    title: 'Var Cost/Ac',
    sortable: true
  },
  {
    field: 'projRevPerAcre',
    title: 'Proj Rev/Ac',
    sortable: true
  },
  {
    field: 'createdAt',
    title: 'Created',
    sortable: true
  }
];
