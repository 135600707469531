import React from 'react';
import TablePagination from '@material-ui/core/TablePagination';
import PAGINATION_SIZE from 'helpers/globalConstants';
import ToggleButton from 'components/ToggleButton';

export const paginationStyles = {
  paginationTableStyles: {
    border: 0,
    color: '#696F88',
    padding: '0px'
  },
  paginationButtonStyles: {
    border: '1px solid',
    borderRadius: '4px',
    padding: '1px'
  }
};
const ActionComponentDisabled = () => <span />;

const CwFPagination = (
  totalCount,
  pageNumber,
  handlePageChange,
  leftElement = null,
  rowsPerPage = PAGINATION_SIZE,
  toggleButtonEnabled = false,
  setToggleButtonEnabled = undefined
) => {
  const LeftComponent = leftElement;
  const showPaginationArrows = totalCount > rowsPerPage;
  return (
    <table
      className={`w-full ${setToggleButtonEnabled &&
        'flex flex-row justify-between items-center'}`}
    >
      {setToggleButtonEnabled && (
        <div className="float-left">
          <thead>
            <tr className="flex flex-row items-center">
              <ToggleButton
                key="enabled"
                color="#0071CD"
                name="View planting data"
                big
                defaultChecked={toggleButtonEnabled}
                onChange={e => setToggleButtonEnabled(e.currentTarget.checked)}
              />
              <span className="inline-block pl-2 -mt-2px text-sm">
                View planting data
              </span>
            </tr>
          </thead>
        </div>
      )}
      <div className="float-right">
        <thead>
          <tr>
            {LeftComponent ? (
              <th className="font-normal">
                <LeftComponent />
              </th>
            ) : null}
            <th>
              <TablePagination
                count={totalCount}
                rowsPerPageOptions={[]}
                rowsPerPage={rowsPerPage}
                page={pageNumber}
                style={paginationStyles.paginationTableStyles}
                backIconButtonProps={{
                  style: {
                    ...paginationStyles.paginationButtonStyles,
                    marginRight: '8px'
                  }
                }}
                nextIconButtonProps={{
                  style: { ...paginationStyles.paginationButtonStyles }
                }}
                onPageChange={(_, page) => {
                  handlePageChange(page);
                }}
                ActionsComponent={
                  showPaginationArrows ? undefined : ActionComponentDisabled
                }
              />
            </th>
          </tr>
        </thead>
      </div>
    </table>
  );
};

export default CwFPagination;
