const { getConvertUnitFromTo } = require('helpers/unitConversionHelpers');

const convertStdPackage = (value, fromUnit, toUnit, productObj) => {
  try {
    return getConvertUnitFromTo(value, fromUnit, toUnit, productObj);
  } catch {
    return null;
  }
};

export default convertStdPackage;
