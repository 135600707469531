import * as Yup from 'yup';

export const valuationsSchema = Yup.array().of(
  Yup.object().shape({
    amount: Yup.number(),
    date: Yup.date()
  })
);

export const statusHistorySchema = Yup.array()
  .nullable()
  .of(
    Yup.object().shape({
      status: Yup.string().required(),
      date: Yup.date(),
      current: Yup.bool(),
      active: Yup.bool()
    })
  );

export const operationCostsSchema = Yup.array()
  .nullable()
  .of(
    Yup.object().shape({
      productId: Yup.string().default(''),
      operationCost: Yup.string()
        .default(null)
        .defined()
    })
  );

export const historySchema = Yup.array()
  .nullable()
  .of(
    Yup.object().shape({
      purchasePrice: Yup.number().nullable(),
      purchaseDate: Yup.date().nullable(),
      currentValue: Yup.number().nullable(),
      currentValueDate: Yup.date().nullable()
    })
  );

export default Yup.object().shape({
  currentStatus: Yup.string()
    .default('')
    .required('Current Status is a required field'),
  currentStatusDate: Yup.date().nullable(),
  equipmentType: Yup.string()
    .default('')
    .required('Type is a required field'),
  licenseNumber: Yup.string().default(''),
  make: Yup.string().default(''),
  manufacturedYear: Yup.number()
    .default(null)
    .defined(),
  model: Yup.string().default(''),
  modelYear: Yup.number()
    .default(null)
    .defined(),
  name: Yup.string()
    .default('')
    .trim()
    .required('Name is a rquired field'),
  purchasePrice: Yup.string()
    .default(null)
    .defined(),
  purchasePriceDate: Yup.date()
    .default(null)
    .defined(),
  currentValue: Yup.string()
    .default(null)
    .defined(),
  currentValueDate: Yup.date()
    .default(null)
    .defined(),
  notes: Yup.string().default(''),
  serialNumber: Yup.string().default(''),
  unitID: Yup.string().default(''),
  statusHistory: statusHistorySchema,
  services: operationCostsSchema,
  history: historySchema
});
