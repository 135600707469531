import { TEMPLATE_TYPE } from 'screens/YieldV2/constants';

export const tabsLabels = {
  saleInfo: 'Sale Info',
  yieldDetails: 'Yield Details',
  loadAttributes: 'Load Attributes',
  weight: 'Weight',
  counts: 'Counts',
  other: 'Other',
  notes: 'Notes'
};

export const labelInputs = {
  salePrice: 'Sale Price',
  grossRevenue: 'Gross Revenue',
  infoSection: 'Info Section',
  harvestedYieldQuantity: 'Harvested Yield Quantity',
  avgHarvestedQuantity: 'Avg. Harvested Quantity',
  shrink: 'Shrink',
  shrinkPercentage: 'Shrink Percentage',
  damage: 'Damage',
  failingNumber: 'Failing Number',
  foreignMaterial: 'Foreign Material',
  lintWeight: 'Lint Weight',
  moisture: 'Moisture',
  plump: 'Plump',
  protein: 'Protein',
  quality: 'Quality',
  slm: 'SLM',
  testWeight: 'Test Weight',
  sugar: 'Sugar',
  seedWeight: 'Seed Weight',
  grossWeight: 'Gross Weight',
  tareWeight: 'Tare Weight',
  netWeight: 'Net Weight',
  boxCount: 'Box Count',
  largeSquareBaleCount: 'Large Square Bale Count',
  miniModuleCount: 'Mini Module Count',
  roundBaleCount: 'Round Bale Count',
  moduleCount: 'Module Count',
  squareBaleCount: 'Square Bale Count',
  truck: 'Truck',
  driver: 'Driver',
  billOfLading: 'Bill of lading',
  secondaryCommodity: 'Secondary Commodity',
  notes: 'Notes'
};
export const cssFull = 'w-full';
export const cssLeft = 'w-full pr-6';
export const cssRight = 'w-full pl-6';
export const cssContainerFlow = 'w-full flex flex-col';
export const cssMarginContainer = 'mx-4';
export const cssContainerRow = 'w-full flex flex-row';
export const cssStructureContainerRow = 'flex flex-row w-90/100 mx-auto mb-5';
export const cssStructureContainerCol =
  'flex flex-col w-90/100 mx-auto mb-5 justify-between';
export const cssContainerGrid = 'grid grid-cols-2 gap-x-12';

// This object controls the individual values for each tab. When more templates are added,
// you can define their signature here and import them into the useParameters hook to be used
// based on the condition you establish in relation to source and destination.
// go to useParameters hook to learn more
// For example, an empty array means all attributes for that tab are shown.
// Adding label inputs will remove those values from the tabs.

export const hiddenAttributesForTemplates = {
  [TEMPLATE_TYPE.ALL_CROPS]: {
    saleTab: [],
    yieldTab: [],
    weightTab: [],
    countsTab: [],
    loudAttributeTab: [],
    otherTab: [labelInputs.billOfLading, labelInputs.secondaryCommodity],
    notesTab: []
  },
  [TEMPLATE_TYPE.ROW_CROP]: {
    saleTab: [],
    yieldTab: [],
    weightTab: [],
    countsTab: [],
    loudAttributeTab: [
      labelInputs.failingNumber,
      labelInputs.lintWeight,
      labelInputs.plump,
      labelInputs.protein,
      labelInputs.quality,
      labelInputs.seedWeight,
      labelInputs.shrink,
      labelInputs.shrinkPercentage,
      labelInputs.slm,
      labelInputs.sugar
    ],
    otherTab: [labelInputs.billOfLading, labelInputs.secondaryCommodity],
    notesTab: []
  },
  [TEMPLATE_TYPE.SPECIALTY]: {
    saleTab: [],
    yieldTab: [],
    weightTab: [],
    countsTab: [],
    loudAttributeTab: [
      labelInputs.failingNumber,
      labelInputs.lintWeight,
      labelInputs.plump,
      labelInputs.protein,
      labelInputs.moisture,
      labelInputs.seedWeight,
      labelInputs.shrink,
      labelInputs.shrinkPercentage,
      labelInputs.slm,
      labelInputs.sugar,
      labelInputs.testWeight
    ],
    otherTab: [labelInputs.billOfLading, labelInputs.secondaryCommodity],
    notesTab: []
  }
};
