import React, { useEffect, useState, useContext } from 'react';

import PropTypes from 'prop-types';
import { Switch, Route } from 'react-router-dom';

import { useAuth } from 'utilities/base-auth';
import { hasAccess } from 'utilities/access';

import { Context } from 'components/Store';
import AppShell from 'components/App/AppShell';

import NotFound404Error from 'components/ErrorHandler/NotFound404';
import Forbidden403 from 'components/ErrorHandler/Forbidden403';
import { useFlags } from 'launchdarkly-react-client-sdk';
import featureFlagRenderItem from 'helpers/featureFlagRenderItem';

const AppContainer = ({ routes }) => {
  const {
    feIntegrationsModuleEnableJohnDeer,
    feIntegrationsModuleEnableMcCain,
    feIntegrationsModuleEnableMcCainPerOrg,
    feReportsModuleEnableModule,
    feYieldModuleEnableModule,
    beJohnDeereEnabledForAll,
    beJohnDeereEnabledPerOrg,
    beProductPriceListEnabled,
    beProductPriceListEnableByOrgId,
    feYieldV2ModuleEnableModule,
    beYieldEnableByOrgId
  } = useFlags();

  const [{ loadingOrg, loggedInUserOrgPermission, organization }] = useContext(
    Context
  );

  const { user } = useAuth();
  const [loaded, setLoaded] = useState(false);
  const [authorizedRoutes, setAutorizedRoutes] = useState(routes);

  useEffect(() => {
    const securedRoutes = new Map([
      [
        'mcCain',
        feIntegrationsModuleEnableMcCain ||
          feIntegrationsModuleEnableMcCainPerOrg?.includes(organization?.id)
      ],
      ['reports', feReportsModuleEnableModule],
      ['yield', feYieldModuleEnableModule],
      [
        'yieldV2',
        featureFlagRenderItem(
          feYieldV2ModuleEnableModule,
          beYieldEnableByOrgId,
          organization?.id
        )
      ],

      [
        'products',
        beProductPriceListEnabled ||
          beProductPriceListEnableByOrgId?.includes(organization?.id)
      ]
    ]);

    const newRoutes = routes
      .filter(({ key }) => {
        if (!securedRoutes.has(key)) {
          return true;
        }

        return securedRoutes.get(key);
      })
      .map(({ component, minPermissions, ...rest }) => {
        return {
          ...rest,
          component: hasAccess(loggedInUserOrgPermission, minPermissions)
            ? component
            : Forbidden403
        };
      });

    setAutorizedRoutes(newRoutes);
  }, [
    routes,
    loggedInUserOrgPermission,
    loadingOrg,
    feIntegrationsModuleEnableJohnDeer,
    feIntegrationsModuleEnableMcCain,
    feIntegrationsModuleEnableMcCainPerOrg,
    feReportsModuleEnableModule,
    feYieldModuleEnableModule,
    beJohnDeereEnabledForAll,
    beJohnDeereEnabledPerOrg,
    beProductPriceListEnabled,
    beProductPriceListEnableByOrgId,
    organization,
    feYieldV2ModuleEnableModule,
    beYieldEnableByOrgId
  ]);

  useEffect(() => {
    const signOutOnTokenExpiration = () => {
      // for some reason we think the token is expired
      // Disabling for now due to unexpected logouts.
      // logout();
    };

    window.addEventListener('cwf-expired', signOutOnTokenExpiration, false);

    if (user) {
      try {
        if (process.env.REACT_APP_LOG_LEVEL !== 'OFF') {
          const orgId = localStorage.getItem('selectedOrganizationId');
          window.pendo.initialize({
            visitor: {
              id: user.id,
              language: 'en_US'
            },
            account: {
              id: orgId
            }
          });

          setLoaded(true);
        }
      } catch (err) {
        // eslint-disable-next-line no-console
        if (process.env.REACT_APP_LOG_LEVEL !== 'OFF') console.log(err);
      }
    }
    return () => {
      window.removeEventListener('cwf-expired', signOutOnTokenExpiration);
    };
  }, [user]);

  return (
    <>
      {loaded ? (
        <AppShell routes={routes}>
          <Switch>
            {authorizedRoutes.map(
              ({ key, component: Screen, path, exact, redirect }) => (
                <Route key={key} path={path} exact={exact}>
                  {Screen ? <Screen redirect={redirect} /> : null}
                </Route>
              )
            )}
            <Route key="404" path="*" render={NotFound404Error} />
          </Switch>
        </AppShell>
      ) : null}
    </>
  );
};

AppContainer.propTypes = {
  routes: PropTypes.arrayOf(PropTypes.object).isRequired
};

export default AppContainer;
