import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import PersonIcon from 'assets/person.svg';
import CompanyIcon from 'assets/company.svg';
import BaseDropdown from 'components/BaseDropdown';

const OwnerField = ({
  people,
  companies,
  onCreateNewOwner,
  onChange,
  value,
  id
}) => {
  const valueRenderer = useCallback(() => {
    if (value?.type === 'People') {
      return (
        <div className="w-full h-full p-2 flex items-center">
          <img className="h-4" alt="Field Icon" src={PersonIcon} />
          <div className="pl-2">{value.name}</div>
        </div>
      );
    }

    if (value?.type === 'Company') {
      return (
        <div className="w-full h-full p-2 flex items-center">
          <img className="h-4" alt="Field Icon" src={CompanyIcon} />
          <div className="pl-2">{value.name}</div>
        </div>
      );
    }
    return null;
  }, [value]);

  return (
    <BaseDropdown
      rightOffset={0}
      heightClass="h-64"
      valueRenderer={valueRenderer}
      value={value}
      bottomButtonText="Create New Owner"
      onBottomButtonClick={onCreateNewOwner}
      onChange={onChange}
      id={id}
    >
      <div className="text-neutral-60 py-2">COMPANIES</div>
      {companies.length > 0 ? (
        companies.map(company => (
          <div
            key={company.id}
            className="w-full h-full p-2 flex items-center"
            value={{
              type: 'Company',
              id: company.id,
              name: company.name
            }}
          >
            <img className="h-4" alt="Field Icon" src={CompanyIcon} />
            <div className="pl-2">{company.name}</div>
          </div>
        ))
      ) : (
        <div className="pl-2">No companies found</div>
      )}
      <div className="text-neutral-60 py-2">PEOPLE</div>
      {people.length > 0 ? (
        people.map(person => (
          <div
            key={person.id}
            className="w-full h-full p-2 flex items-center"
            value={{
              type: 'People',
              id: person.id,
              name: person.name
            }}
          >
            <img className="h-4" alt="Field Icon" src={PersonIcon} />
            <div className="pl-2">{person.name}</div>
          </div>
        ))
      ) : (
        <div className="pl-2">No people found</div>
      )}
    </BaseDropdown>
  );
};

OwnerField.propTypes = {
  people: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      name: PropTypes.string
    })
  ).isRequired,
  companies: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      name: PropTypes.string
    })
  ).isRequired,
  value: PropTypes.shape({
    id: PropTypes.string,
    type: PropTypes.string,
    name: PropTypes.string
  }),
  onChange: PropTypes.func.isRequired,
  onCreateNewOwner: PropTypes.func.isRequired,
  id: PropTypes.string
};

OwnerField.defaultProps = {
  value: null,
  id: ''
};

export default OwnerField;
