import PropTypes from 'prop-types';
import React, { useContext } from 'react';
import CalAgSubmissionFooter from 'screens/Integrations/CalAgIntegration/components/CalAgSubmissionFooter';
import AgrianSubmissionFooter from '../../AgrianIntegration/components/AgrianSubmissionFooter';
import MccainFooterIntegration from '../../MccainIntegration/components/MccainFooterIntegration';
import SimplotSubmissionFooter from '../../SimplotIntegration/components/SimplotSubmissionFooter';
import { IntegrationsContext } from '../../context/IntegrationsProvider';
import { INTEGRATIONS } from '../../helpers/constants';
import useFormikHandler from '../../hooks/useFormikHandler';

const PropertySelectorFooter = ({
  integrationName,
  propertyLandingPageData
}) => {
  const { setSelectedProperties } = useFormikHandler();

  const {
    state: { selectedProperties },
    updateSelectedProperties
  } = useContext(IntegrationsContext);

  const mapProperties = () => {
    const mappedProperties = selectedProperties.reduce((prev, curr) => {
      const farm = propertyLandingPageData.properties.find(
        property => property.id === curr.properties.propertyId
      );

      const field = farm.fields.find(
        f => f.id === curr.properties.$parentFieldId
      );

      const cropZone = field.cropzones.find(
        cropzone => cropzone.id === curr.properties.id
      );

      const farmIndex = prev.findIndex(joinedFarm => joinedFarm.id === farm.id);

      if (farmIndex >= 0) {
        const fieldIndex = prev[farmIndex].fields.findIndex(
          joinedField => joinedField.id === field.id
        );
        if (fieldIndex >= 0) {
          prev[farmIndex].fields[fieldIndex].cropzones.push(cropZone);
        } else {
          prev[farmIndex].fields.push({
            id: field.id,
            name: field.name,
            boundaryArea: field.boundaryArea,
            geometry: field.geometry,
            reportedArea: field.reportedArea,
            cropzones: [cropZone]
          });
        }
      } else {
        prev.push({
          id: farm.id,
          name: farm.name,
          fields: [
            {
              id: field.id,
              name: field.name,
              geometry: field.geometry,
              boundaryArea: field.boundaryArea,
              reportedArea: field.reportedArea,
              cropzones: [cropZone]
            }
          ]
        });
      }

      return prev;
    }, []);

    setSelectedProperties(mappedProperties);
  };

  const resetSelectedProperties = () => {
    updateSelectedProperties([]);
    setSelectedProperties([]);
  };

  const renderFooter = () => {
    switch (integrationName) {
      case INTEGRATIONS.mccain:
        return (
          <MccainFooterIntegration
            nextButtonDisabled={selectedProperties.length === 0}
            onNext={mapProperties}
            onBack={resetSelectedProperties}
          />
        );
      case INTEGRATIONS.simplot:
        return (
          <SimplotSubmissionFooter
            isLastStep={false}
            nextDisabled={selectedProperties.length === 0}
            onNext={mapProperties}
            onBack={resetSelectedProperties}
          />
        );
      case INTEGRATIONS.agrian:
        return (
          <AgrianSubmissionFooter
            isLastStep={false}
            nextDisabled={selectedProperties.length === 0}
            onNext={mapProperties}
            onBack={resetSelectedProperties}
          />
        );
      case INTEGRATIONS.calAgPermits:
        return (
          <CalAgSubmissionFooter
            isLastStep={false}
            nextDisabled={selectedProperties.length === 0}
            onNext={mapProperties}
            onBack={resetSelectedProperties}
          />
        );
      default:
        return <></>;
    }
  };

  return renderFooter();
};

PropertySelectorFooter.propTypes = {
  integrationName: PropTypes.string.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  propertyLandingPageData: PropTypes.object.isRequired
};

export default PropertySelectorFooter;
