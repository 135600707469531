/* eslint-disable react/prop-types */
import React, { useContext, useState } from 'react';
import PropTypes from 'prop-types';
import catchCancel from 'helpers/catchCancel';
import { parseServerError } from 'helpers/errorHelpers';
import Paper from 'components/Paper';
import LandingTable from 'components/LandingTable';
import { TABLE_COLUMNS } from 'screens/Invoices/helpers/constants';
import DeleteModal from 'components/Modals/DeleteModal';
import { Context } from 'components/Store';
import TagsModal from 'components/Modals/TagsModal';
import { invoice as invoiceApi } from 'utilities/api';
import useInvoiceActions from 'screens/Invoices/hooks/useInvoiceActions';
import CwFPagination from 'components/CwFPagination';
import { INVOICES_LANDING_MENU } from 'utilities/menus';
import { paths } from 'routes/paths';
import getInvoiceCostVisibility from 'screens/Invoices/helpers/getInvoiceCostVisibility';
import InvoiceTableRow from './components/InvoiceTableRow';

const InvoiceTable = ({
  invoices,
  onDelete,
  setReRenderInvoices,
  memberRole,
  memberFinancialAccess,
  pageNo,
  onPageChange,
  totalInvoices,
  sortBy,
  sortDir,
  onSort
}) => {
  const {
    handleAction,
    deleteModalState,
    invoiceTags,
    openTags,
    selectedInvoiceTitleAndId,
    selectedInvoiceData,
    setOpenTags,
    setInvoiceTags,
    setDeleteModalState,
    downloadingPDF
  } = useInvoiceActions();

  const [, dispatch] = useContext(Context);
  const [, setError] = useState(null);
  const [rowIndex, setRowIndex] = useState(-1);

  const handleSelectedTags = async e => {
    setReRenderInvoices(false);
    setOpenTags(false);
    const body = {
      ...selectedInvoiceData,
      flagIds: e.map(flag => flag.id)
    };
    delete body.invoiceDate;
    delete body.invoiceDueDate;
    delete body.cropSeason;
    const { promise } = await invoiceApi.update(selectedInvoiceData.id, body);
    await promise
      .then(() => {
        setInvoiceTags(e);
        setReRenderInvoices(true);
      })
      .catch(catchCancel)
      .catch(err => {
        if (err.response?.data?.validationErrors) {
          setError(`Error saving task: ${err.response.data.message}`);
        } else if (err.response?.data?.displayErrorMessage) {
          setError(err.response.data.displayErrorMessage);
        } else {
          parseServerError(dispatch)(err);
        }
      });
  };
  const handleDelete = () => {
    onDelete(deleteModalState.id);
    setDeleteModalState({ open: false, id: null });
  };

  const getTotInvCostValue = invCost =>
    getInvoiceCostVisibility({
      cost: invCost,
      memberRole,
      memberFinancialAccess
    });

  return (
    <div
      className="min-w-content"
      data-testid="invoices-table"
      style={{
        minWidth: 'min-content'
      }}
    >
      <Paper>
        <DeleteModal
          open={deleteModalState.open}
          itemType="Invoice"
          onCancel={() => setDeleteModalState({ open: false, id: null })}
          onDelete={handleDelete}
        />
        <TagsModal
          open={openTags}
          close={() => setOpenTags(false)}
          name={selectedInvoiceTitleAndId.title}
          selectedTags={invoiceTags}
          returnSelectedTags={handleSelectedTags}
        />
        {CwFPagination(totalInvoices, pageNo, onPageChange)}
        <LandingTable
          listType="invoices"
          routePath={paths.invoices}
          items={invoices}
          tableColumns={TABLE_COLUMNS}
          onSort={onSort}
          sortBy={sortBy}
          sortDir={sortDir}
        >
          {({ rows }) =>
            rows.map((item, index) => {
              return (
                <InvoiceTableRow
                  key={item.invoiceId}
                  onAction={action => {
                    handleAction(action, item, true, index, setRowIndex);
                  }}
                  menuOptions={INVOICES_LANDING_MENU}
                  invoice={item}
                  getTotInvCostValue={getTotInvCostValue}
                  runningProcesses={downloadingPDF && rowIndex === index}
                />
              );
            })
          }
        </LandingTable>
        <div className="pb-12">
          {CwFPagination(totalInvoices, pageNo, onPageChange)}
        </div>
      </Paper>
    </div>
  );
};

InvoiceTable.defaultProps = {
  invoices: []
};

InvoiceTable.propTypes = {
  invoices: PropTypes.arrayOf(PropTypes.object),
  onDelete: PropTypes.func.isRequired,
  setReRenderInvoices: PropTypes.func.isRequired,
  memberRole: PropTypes.string.isRequired,
  memberFinancialAccess: PropTypes.string.isRequired,
  pageNo: PropTypes.number.isRequired,
  onPageChange: PropTypes.func.isRequired,
  totalInvoices: PropTypes.number.isRequired,
  sortBy: PropTypes.string.isRequired,
  sortDir: PropTypes.string.isRequired,
  onSort: PropTypes.func.isRequired
};

export default InvoiceTable;
