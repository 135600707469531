import { useContext, useEffect, useState, useCallback } from 'react';
import { Context } from 'components/Store';
import catchCancel from 'helpers/catchCancel';
import { parseServerError } from 'helpers/errorHelpers';
import { tags as tagsApi } from 'utilities/api';

const useTagsData = () => {
  const [, dispatch] = useContext(Context);
  const [tags, setTags] = useState([]);
  const [loading, setLoading] = useState(true);

  const getTags = useCallback(() => {
    const { promise, cancel } = tagsApi.fetch();
    promise
      .then(({ data }) => {
        setTags(data.results);
      })
      .catch(catchCancel)
      .catch(err => {
        parseServerError(dispatch)(err);
      })
      .finally(() => setLoading(false));

    return cancel;
  }, [dispatch]);

  useEffect(() => {
    const cancel = getTags();

    return () => {
      cancel();
    };
  }, [getTags]);

  return { tags, loading, getTags };
};

export default useTagsData;
