export const meridianData = [
  { value: 'None', key: 'None' },
  { value: 'Black Hills', key: 'Black Hills' },
  { value: 'Fifth Principal', key: 'Fifth Principal' },
  { value: 'Boise', key: 'Boise' },
  { value: 'Chickasaw', key: 'Chickasaw' },
  { value: 'Choctaw', key: 'Choctaw' },
  { value: 'Cimarron', key: 'Cimarron' },
  { value: 'Copper River', key: 'Copper River' },
  { value: 'Fairbanks', key: 'Fairbanks' },
  { value: 'First Principal', key: 'First Principal' },
  { value: 'Fourth Principal Extended', key: 'Fourth Principal Extended' },
  { value: 'Fourth Principal', key: 'Fourth Principal' },
  { value: 'Gila and Salt River ', key: 'Gila and Salt River' },
  { value: 'Humboldt', key: 'Humboldt' },
  { value: 'Huntsville', key: 'Huntsville' },
  { value: 'Indian', key: 'Indian' },
  { value: 'Kateel River', key: 'Kateel River' },
  { value: 'Louisiana', key: 'Louisiana' },
  { value: 'Michigan', key: 'Michigan' },
  { value: 'Montana Principal', key: 'Montana Principal' },
  { value: 'Mount Diablo', key: 'Mount Diablo' },
  { value: 'Navajo', key: 'Navajo' },
  { value: 'New Mexico Principal', key: 'New Mexico Principal' },
  { value: 'Saint Helena', key: 'Saint Helena' },
  { value: 'Saint Stephens', key: 'Saint Stephens Stephens' },
  { value: 'Salt Lake', key: 'Salt Lake' },
  { value: 'San Bernardino', key: 'San Bernardino' },
  { value: 'Second Principal', key: 'Second Principal' },
  { value: 'Seward', key: 'Seward' },
  { value: 'Sixth Principal', key: 'Sixth Principal' },
  { value: 'Tallahassee', key: 'Tallahassee' },
  { value: 'Third Principal', key: 'Third Principal' },
  { value: 'Uintah', key: 'Uintah' },
  { value: 'Umiat', key: 'Umiat' },
  { value: 'Ute', key: 'Ute' },
  { value: 'Washington', key: 'Washington' },
  { value: 'Willamette', key: 'Willamette' },
  { value: 'Wind River', key: 'Wind River' }
];

export const fsaRentTypes = [
  { key: 'None', value: 'None' },
  { key: 'Cash Rent', value: 'Cash Rent' },
  { key: 'Crop Share', value: 'Crop Share' }
];

export const soilTypes = [
  { key: 'None', value: 'None' },
  { key: 'Clay', value: 'Clay' },
  { key: 'Silt', value: 'Silt' },
  { key: 'Sand', value: 'Sand' },
  { key: 'Loam', value: 'Loam' },
  { key: 'Sandy Clay', value: 'Sandy Clay' },
  { key: 'Silty Clay', value: 'Silty Clay' },
  { key: 'Sandy Clay Loam', value: 'Sandy Clay Loam' },
  { key: 'Silty Clay Loam', value: 'Silty Clay Loam' },
  { key: 'Clay Loam', value: 'Clay Loam' },
  { key: 'Silt Loam', value: 'Silt Loam' },
  { key: 'Sandy Loam', value: 'Sandy Loam' },
  { key: 'Loamy Sand', value: 'Loamy Sand' }
];

export const hydrologyGroups = [
  { key: 'None', value: 'None' },
  { key: 'A', value: 'A' },
  { key: 'B', value: 'B' },
  { key: 'C', value: 'C' },
  { key: 'D', value: 'D' },
  { key: 'A/D', value: 'A/D' },
  { key: 'B/D', value: 'B/D' },
  { key: 'C/D', value: 'C/D' }
];

export const waterSources = [
  { key: 'None', value: 'None' },
  { key: 'Irrigation District', value: 'Irrigation District' },
  { key: 'Municipal', value: 'Municipal' },
  { key: 'On Farm Pond', value: 'On Farm Pond' },
  { key: 'Reservoir', value: 'Reservoir' },
  { key: 'River', value: 'River' },
  { key: 'Stream', value: 'Stream' },
  { key: 'Tailwater', value: 'Tailwater' },
  { key: 'Well', value: 'Well' }
];

export const waterOrigins = [
  { key: 'None', value: 'None' },
  { key: 'Aquifer', value: 'Aquifer' },
  { key: 'Watershed', value: 'Watershed' }
];

export const irrigationSystems = [
  { key: 'None', value: 'None' },
  { key: 'Basin', value: 'Basin' },
  { key: 'Border', value: 'Border' },
  { key: 'Drip', value: 'Drip' },
  { key: 'Flood', value: 'Flood' },
  { key: 'Furrow', value: 'Furrow' },
  { key: 'Linear', value: 'Linear' },
  { key: 'Micro-sprinkler', value: 'Micro-sprinkler' },
  { key: 'Pivot', value: 'Pivot' },
  { key: 'Subsurface', value: 'Subsurface' },
  { key: 'Traveling Gun', value: 'Traveling Gun' },
  { key: 'Wheel Lines', value: 'Wheel Lines' }
];

export const TileDrainageFields = [
  { key: 'No Tile Drain', value: 'No Tile Drainage' },
  { key: 'Standard density', value: 'Standard density' },
  {
    key: 'Standard density with Drainage Water Management',
    value: 'Standard density with Drainage Water Management'
  },
  { key: 'High density', value: 'High density' },
  {
    key: 'High density with Drainage Water Management',
    value: 'High density with Drainage Water Management'
  }
];

export const pumpEnergySources = [
  { key: 'None', value: 'None' },
  { key: 'Diesel', value: 'Diesel' },
  { key: 'Electric', value: 'Electric' },
  { key: 'Natural Gas', value: 'Natural Gas' },
  { key: 'Propane', value: 'Propane' }
];

export const tillageSystemFields = [
  { key: 'None', value: 'None' },
  { key: 'conventional-till', value: 'Conventional-Till' },
  { key: 'intensive-till', value: 'Intensive-Till' },
  { key: 'mulch-till', value: 'Mulch-Till' },
  { key: 'no-till', value: 'No-Till' },
  { key: 'reduced-till', value: 'Reduced-Till' },
  { key: 'strip-till', value: 'Strip-Till' }
];
