const blankValues = {
  unit: '',
  entryName: '',
  totalYield: 0,
  avgYieldPerAcre: 0,
  salePrice: 0,
  grossRevenue: 0,
  cropZones: [],
  totalAcres: 0
};

export default blankValues;
