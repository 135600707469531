import React, { useContext, useEffect, useState, useRef, useMemo } from 'react';
import { format } from 'date-fns';
import Truncate from 'react-truncate';

import { Context } from 'components/Store';
import { CropZoneIcon, FarmIcon } from 'components/Icons';
import OrganizationIcon from 'components/Icons/components/OrganizationIcon';
import { filterUniqueItems } from 'screens/Property/helpers/propertyDataHelpers';

const containerClass = 'pr-4 flex flex-col mt-1 h-72px';
const textClass = 'text-xxs font-normal';
const flexColClass = 'flex flex-col text-10pt items-start';
const flexRowClass = 'flex flex-row w-full';
const flexRowFullClass = 'flex flex-row w-full items-center';
const ml1Class = 'ml-1';
const wFullClass = 'w-full';
const commaSpace = ',\u00A0';

const PropertyMapFooter = () => {
  const [
    { propertyMapFooterConfig, fieldsToMassiveAssign, organization }
  ] = useContext(Context);

  const [generatedDate, setGeneratedDate] = useState('');
  const [isTruncatedText, setIsTruncatedText] = useState(false);
  const cropSeasonsRef = useRef(null);
  const farmNamesRef = useRef(null);

  const cropSeasons = useMemo(
    () =>
      filterUniqueItems(fieldsToMassiveAssign, field =>
        field?.cropzones.flatMap(cropZone => cropZone?.cropSeasons ?? [])
      ),
    [fieldsToMassiveAssign]
  );
  const farmNames = useMemo(
    () => filterUniqueItems(fieldsToMassiveAssign, field => [field?.farmName]),
    [fieldsToMassiveAssign]
  );

  useEffect(() => {
    const date = new Date();
    const formattedDate = format(date, 'MMM dd, yyyy hh:mm a');
    setGeneratedDate(`Generated on ${formattedDate}`);
  }, []);

  return (
    <div
      data-testid="footer-preview"
      className={containerClass}
      style={{ width: 'calc(100% - 216px)' }}
    >
      <div className={flexColClass}>
        {propertyMapFooterConfig.organizationName && (
          <div className={flexRowClass} data-testid="organization-name">
            <OrganizationIcon size={12} />
            <Truncate
              lines={1}
              ellipsis={<span>...</span>}
              className={`${ml1Class} ${isTruncatedText ? textClass : ''}`}
              onTruncate={() => setIsTruncatedText(true)}
              style={{
                whiteSpace: 'nowrap',
                overflow: 'hidden',
                textOverflow: 'ellipsis'
              }}
            >
              <span
                className={`${wFullClass} ${isTruncatedText ? textClass : ''}`}
              >
                {organization.name}
              </span>
            </Truncate>
          </div>
        )}
        <div className={`${flexRowClass} ${isTruncatedText ? textClass : ''}`}>
          {propertyMapFooterConfig.farmNames && (
            <div className={flexRowClass} data-testid="farm-names">
              <FarmIcon size={12} />
              <span ref={farmNamesRef} className={`${wFullClass} ${ml1Class}`}>
                <Truncate
                  lines={2}
                  ellipsis={<span>...</span>}
                  onTruncate={() => setIsTruncatedText(true)}
                >
                  {farmNames.map((item, index) => (
                    <span key={item}>
                      {item}
                      {index < farmNames.length - 1 && commaSpace}
                    </span>
                  ))}
                </Truncate>
              </span>
            </div>
          )}
        </div>
        <div
          className={`${flexRowFullClass} ${isTruncatedText ? textClass : ''}`}
        >
          {propertyMapFooterConfig.cropSeasonNames && (
            <div className={flexRowClass} data-testid="crop-seasons">
              <CropZoneIcon size={12} />
              <span
                ref={cropSeasonsRef}
                className={`${wFullClass} ${ml1Class}`}
              >
                <Truncate
                  lines={2}
                  ellipsis={<span>...</span>}
                  onTruncate={() => setIsTruncatedText(true)}
                >
                  {cropSeasons.map((item, index) => (
                    <span key={item.id}>
                      {item.name}
                      {index < cropSeasons.length - 1 && commaSpace}
                    </span>
                  ))}
                </Truncate>
              </span>
            </div>
          )}
        </div>
      </div>
      <div className={`${textClass} mt-auto`}>{generatedDate}</div>
    </div>
  );
};

export default PropertyMapFooter;
