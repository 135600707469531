import React from 'react';
import PropTypes from 'prop-types';
import SubmissionFooter from 'screens/Integrations/components/SubmissionFooter';
import { INTEGRATIONS } from 'screens/Integrations/helpers/constants';
import { AgrianContext } from '../../context/Provider';

const AgrianSubmissionFooter = ({
  nextDisabled,
  onNext,
  nextLabel,
  setModalSubmissionState,
  isLastStep,
  integrationType,
  isBottomFixed
}) => {
  return (
    <SubmissionFooter
      nextDisabled={nextDisabled}
      onNext={onNext}
      setModalSubmissionState={setModalSubmissionState}
      isLastStep={isLastStep}
      context={AgrianContext}
      nextLabel={nextLabel}
      integrationType={integrationType}
      isBottomFixed={isBottomFixed}
    />
  );
};

AgrianSubmissionFooter.propTypes = {
  nextDisabled: PropTypes.bool,
  onNext: PropTypes.func,
  setModalSubmissionState: PropTypes.func,
  isLastStep: PropTypes.bool,
  integrationType: PropTypes.string,
  nextLabel: PropTypes.string,
  isBottomFixed: PropTypes.bool
};

AgrianSubmissionFooter.defaultProps = {
  nextDisabled: false,
  onNext: () => {},
  setModalSubmissionState: () => {},
  isLastStep: false,
  integrationType: INTEGRATIONS.agrian,
  nextLabel: 'Next',
  isBottomFixed: false
};

export default AgrianSubmissionFooter;
