import { useContext, useEffect } from 'react';
import PropTypes from 'prop-types';
import { MapContext } from 'react-mapbox-gl';

import { KEY_CODES } from '../../../../helpers/constants';

const MapKeyPressTool = ({ onDelete, setMode }) => {
  const map = useContext(MapContext);

  useEffect(() => {
    const keyHandlerMap = {
      [KEY_CODES.BACKSPACE]: () => onDelete(),
      [KEY_CODES.DELETE]: () => onDelete(),
      [KEY_CODES.ESC]: () => setMode('simple_select')
    };

    const keyDownListener = event => {
      const handler = keyHandlerMap[event.which];
      if (handler) {
        handler();
      }
    };

    if (map && map.getCanvas()) {
      map.getCanvas().addEventListener('keydown', keyDownListener);
    }

    return () => {
      if (map && map.getCanvas()) {
        map.getCanvas().removeEventListener('keydown', keyDownListener);
      }
    };
  }, [map, onDelete, setMode]);

  return null;
};

MapKeyPressTool.propTypes = {
  onDelete: PropTypes.func.isRequired,
  setMode: PropTypes.func.isRequired
};

export default MapKeyPressTool;
