import * as Yup from 'yup';

export const formulationSchema = Yup.object().shape({
  S: Yup.number().default(0),
  B: Yup.number().default(0),
  Cl: Yup.number().default(0),
  N: Yup.number().default(0),
  P: Yup.number().default(0),
  K: Yup.number().default(0),
  Ca: Yup.number().default(0),
  Mg: Yup.number().default(0),
  Cu: Yup.number().default(0),
  Fe: Yup.number().default(0),
  Mn: Yup.number().default(0),
  Mo: Yup.number().default(0),
  Zn: Yup.number().default(0)
});

const fertilizerFields = {
  name: Yup.string()
    .trim()
    .required('Product Name is a required field'),
  manufacturer: Yup.string()
    .trim()
    .required('Manufacturer is a required field'),
  standardUnit: Yup.string()
    .trim()
    .required('Standard Unit is a required field'),
  type: Yup.string().default('Fertilizer'),
  makeUp: Yup.string()
    .trim()
    .required('Make-Up is a required field'),
  densityInLbsPerGallon: Yup.number()
    .transform((value, originalValue) =>
      originalValue === '' ? undefined : value
    )
    .nullable()
    .default(0),
  density: Yup.number().default(),
  stdFactor: Yup.number().default(0),
  stdPackageUnit: Yup.string()
    .trim()
    .default(''),
  formulation: formulationSchema
};

export const fertilizerSchema = Yup.object().shape(fertilizerFields);
export const fertilizerLiquidSchema = Yup.object().shape({
  ...fertilizerFields,
  densityInLbsPerGallon: Yup.number().nullable(),
  density: Yup.number().when('makeUp', {
    is: value => value && value.toLowerCase() === 'liquid',
    then: Yup.number()
      .required('Required')
      .positive('Density must be a positive number'),
    otherwise: Yup.number().positive('Density must be a positive number')
  })
});

export const serviceSchema = Yup.object().shape({
  name: Yup.string()
    .trim()
    .required('Product Name is a required field'),
  manufacturer: Yup.string()
    .trim()
    .required('Manufacturer is a required fields'),
  standardUnit: Yup.string()
    .trim()
    .required('Standard Unit is a required field'),
  type: Yup.string().default('Service'),
  makeUp: Yup.string()
    .trim()
    .default(''),
  density: Yup.number().default(0),
  stdFactor: Yup.number().default(0),
  stdPackageUnit: Yup.string()
    .trim()
    .default(''),
  formulation: formulationSchema
});
// export default;
