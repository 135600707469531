import React, { useContext, useState, useEffect } from 'react';
import useJohnDeereData from 'screens/Integrations/hooks/useJohnDeereData';
import PropTypes from 'prop-types';
import { urlGenerator } from 'screens/Integrations/helpers/urlGenerator';
import { Context } from 'components/Store';
import Chevron from 'assets/integrations/chevron_right_24px.svg';
import { Link, useHistory } from 'react-router-dom';
import { paths } from 'routes/paths';
import ClickableDiv from 'components/ClickableDiv';
import mapCropZone from 'assets/integrations/replace-data.svg';
import { Spinner } from '@agconnections/grow-ui';
import Ribbon from 'assets/integrations/Ribbon.svg';
import { determineSVG } from '../../helpers/determineSVG';

const cssClasses = {
  McCain: {
    imageContainer: 'm-auto py-3',
    image: 'm-auto w-1/2'
  },
  Simplot: {
    imageContainer: 'm-auto pt-10 pb-8',
    image: 'm-auto w-1/2'
  },
  Agrian: {
    imageContainer: 'm-auto pt-10 pb-10',
    image: 'm-auto w-1/2'
  },
  CalAgPermits: {
    imageContainer: 'm-auto pt-10 pb-10',
    image: 'm-auto w-1/2'
  },
  Connect: {
    imageContainer: 'm-auto pt-8',
    image: 'm-auto'
  },
  AgVend: {
    imageContainer: 'm-auto pt-10 pb-10',
    image: 'm-auto w-1/2'
  }
};

const IntegrationCard = ({
  title,
  description,
  integrationType,
  isExternalLink,
  urlPath,
  reloadUnmappedCropZonesCount
}) => {
  const [{ organization }] = useContext(Context);
  const history = useHistory();
  const integrationExternalURL = urlGenerator(organization, window.location);
  const {
    loadUnmappedCropZonesCount,
    unmappedCropZoneCount,
    isLoadingCropZoneCount
  } = useJohnDeereData();
  const [unmappedCropZones, setUnmappedCropZones] = useState(
    unmappedCropZoneCount?.unmappedCount
  );
  useEffect(() => {
    setUnmappedCropZones(undefined);
    if (integrationType === 'Connect') {
      loadUnmappedCropZonesCount();
    }

    return () => {};
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reloadUnmappedCropZonesCount]);
  useEffect(() => {
    setUnmappedCropZones(unmappedCropZoneCount?.unmappedCount);

    return () => {};
  }, [unmappedCropZoneCount]);

  const unmappedCropZoneCard = () => {
    return (
      <>
        {unmappedCropZones === 0 ? null : (
          <ClickableDiv
            className="flex w-430px rounded-b-lg bg-neutral-80 pb-4 pt-4"
            onClick={() => {
              if (isLoadingCropZoneCount) return;
              history.push(paths.integrationCropZoneMatcher);
            }}
          >
            <div className="flex">
              <div className="pt-34px">
                <img src={Ribbon} alt="" />
              </div>
              <div className="text-golden h-4 text-3xl w-51px pt-29px text-center">
                {isLoadingCropZoneCount ? (
                  <Spinner size="sm" color="white" />
                ) : (
                  unmappedCropZones
                )}
              </div>
            </div>
            <div className="pt-4">
              <div className="flex">
                <div className="h-8">
                  <img src={mapCropZone} alt="" />
                </div>
                <div className="text-base text-white">
                  CROPZONE MATCHING REQUIRED
                </div>
              </div>
              <div className="flex text-sm text-white pb-4">
                Match your machinery integration data from Cropwise Connect to
                Crop Zones
                <div className="pr-23px">
                  <img src={Chevron} alt="" />
                </div>
              </div>
            </div>
          </ClickableDiv>
        )}
      </>
    );
  };

  const integrationCard = () => {
    return (
      <div className="p-4">
        <h1
          data-testid={`integrations-card-title-${integrationType}`}
          className="text-base text-neutral-1000"
        >
          {title.toLocaleUpperCase()}
        </h1>
        <div
          data-testid={`integrations-card-image-${integrationType}`}
          className={cssClasses[integrationType].imageContainer}
        >
          <img
            className={cssClasses[integrationType].image}
            src={determineSVG(integrationType)}
            alt=""
          />
        </div>
        <span
          data-testid={`integrations-card-subtitle-${integrationType}`}
          className="text-xs text-gray-600 leading-4"
        >
          {description}
        </span>
      </div>
    );
  };

  return (
    <div
      data-testid="integrations-card"
      className="bg-white border rounded shadow-lg"
      style={{ width: '432px', height: 'auto' }}
    >
      <div>
        {isExternalLink ? (
          <>
            <a
              data-testid={`anchor-${integrationType}`}
              href={integrationExternalURL.johnDeere.url}
              rel="noreferrer"
              target="_blank"
            >
              {integrationCard()}
            </a>
            {integrationType === 'Connect' ? unmappedCropZoneCard() : null}
          </>
        ) : (
          <Link to={urlPath}>{integrationCard()}</Link>
        )}
      </div>
    </div>
  );
};

IntegrationCard.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,

  integrationType: PropTypes.string.isRequired,
  isExternalLink: PropTypes.bool,
  urlPath: PropTypes.string,
  reloadUnmappedCropZonesCount: PropTypes.bool
};

IntegrationCard.defaultProps = {
  isExternalLink: false,
  urlPath: '',
  reloadUnmappedCropZonesCount: true
};

export default IntegrationCard;
