import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';

import { paths } from 'routes/paths';

import { SubmissionHistoryContext } from 'screens/Integrations/SubmissionHistory/context/Provider';

import ViewIcon from '../../../../../components/icons/ViewIcon';

const ViewLink = ({ id }) => {
  const {
    state: { integrationType }
  } = useContext(SubmissionHistoryContext);
  const history = useHistory();

  return (
    <a
      className="flex flex-line justify-start align-center"
      href={false}
      onClick={() =>
        history.push(
          `${paths.integrations}/${integrationType}/submission/${id}`
        )
      }
    >
      <ViewIcon /> <span className="ml-1">View</span>
    </a>
  );
};

ViewLink.propTypes = {
  id: PropTypes.string.isRequired
};

export default ViewLink;
