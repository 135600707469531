import qs from 'qs';

/**
 * Custom parameter serializer for deeply nested objects
 * @param {Object} params - params object to be serialized
 */
const deepSerializer = params =>
  qs.stringify(params, {
    encodeValuesOnly: true
  });

export default deepSerializer;
