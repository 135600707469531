/* eslint-disable no-unused-vars */

export default server => {
  server.get('organizations', (schema, request) => {
    return schema.organizations.all();
  });

  server.get('organizations/:id', (schema, request) => {
    const { id } = request.params;

    return schema.organizations.find(id) || schema.organizations.first();
  });

  server.get('organizations/:id/accounts', (schema, request) => {
    return schema.members.all();
  });

  server.put('organizations/:id', (schema, request) => {
    const newAttrs = JSON.parse(request.requestBody);
    const { id } = request.params;
    const organization =
      schema.organizations.find(id) || schema.organizations.first();

    return organization.update(newAttrs);
  });

  server.delete('organizations/:id', (schema, request) => {
    const { id } = request.params;
    const organization =
      schema.organizations.find(id) || schema.organizations.first();

    return organization.destroy();
  });
};
