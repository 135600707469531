import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Button, Table, Spinner } from '@agconnections/grow-ui';
import Paper from 'components/Paper';
import ArrowForwardRoundedIcon from '@material-ui/icons/ArrowForwardRounded';
import alphanumSort from 'helpers/sorters';

import SectionHeader from 'components/SectionHeader';
import InviteModal from './InviteModal';
import InviteStatus from './InviteStatus';
import MoreButton from './MoreButton';

// eslint-disable-next-line react/prop-types
const EmptyMembersList = ({ onClick, members }) => {
  return members ? (
    <div className="flex flex-col items-center justify-center h-64 bg-white rounded shadow-md radius">
      <div className="px-4 text-xl leading-6 text-center text-gray-800">
        This organization doesn’t have members yet.
      </div>
      <div className="px-4 mt-2 mb-10 text-base leading-5 text-center text-gray-700">
        Add some to include them in tasks and reports.
      </div>
      <Button
        icon={<ArrowForwardRoundedIcon />}
        type="primary"
        iconRight
        onClick={() => onClick(true)}
      >
        Start here
      </Button>
    </div>
  ) : (
    <Spinner />
  );
};

const OrganizationMembers = ({ members, getOrganization, userDetails }) => {
  const [inviteModal, setInviteModal] = useState(false);
  const [filteredMembers, setFilteredMembers] = useState([]);
  const [selectMember, setSelectMember] = useState();
  const [disableInviteMembers, setDisableInviteMembers] = useState(false);

  useEffect(() => {
    const filter = members
      ?.filter(
        (member, i, arr) =>
          arr.findIndex(
            t => t.accountId === member.accountId && t.name === member.name
          ) === i || member.memberStatus === 'Pending'
      )
      .filter(
        eachMember =>
          (!Object.prototype.hasOwnProperty.call(eachMember, 'accountId') &&
            eachMember.memberStatus === 'Pending') ||
          eachMember.memberStatus === 'Joined' ||
          (Object.prototype.hasOwnProperty.call(eachMember, 'accountId') &&
            (eachMember.memberStatus === 'Expired' ||
              eachMember.memberStatus === 'Revoked'))
      );
    if (filter) setFilteredMembers(alphanumSort(filter));
  }, [members]);
  useEffect(() => {
    const member = members?.filter(
      eachMember => eachMember?.email === userDetails?.email
    )[0];
    setSelectMember(member);
  }, [members, userDetails?.email]);

  // Debounce for Invite Members button to be disabled when invite modal closes
  useEffect(() => {
    if (inviteModal === undefined) {
      setDisableInviteMembers(true);

      setTimeout(() => {
        setDisableInviteMembers(false);
      }, 1000);
    }
  }, [inviteModal]);

  return (
    <>
      <InviteModal
        open={inviteModal}
        onClose={setInviteModal}
        getOrganization={getOrganization}
        userDetails={userDetails}
        singleMember={selectMember}
      />

      <SectionHeader
        title="Organization Members"
        subtitle="Add members to your organization and manage their access."
        actions={
          <div>
            {selectMember?.role === 'Admin' && (
              <Button
                onClick={() => {
                  setInviteModal(true);
                }}
                disabled={disableInviteMembers}
                ghost
                type="primary"
              >
                Invite Member
              </Button>
            )}
          </div>
        }
      >
        <svg
          width="30"
          height="23"
          viewBox="0 0 30 23"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M4.5 9.85714C6.15469 9.85714 7.5 8.38371 7.5 6.57143C7.5 4.75915 6.15469 3.28571 4.5 3.28571C2.84531 3.28571 1.5 4.75915 1.5 6.57143C1.5 8.38371 2.84531 9.85714 4.5 9.85714ZM25.5 9.85714C27.1547 9.85714 28.5 8.38371 28.5 6.57143C28.5 4.75915 27.1547 3.28571 25.5 3.28571C23.8453 3.28571 22.5 4.75915 22.5 6.57143C22.5 8.38371 23.8453 9.85714 25.5 9.85714ZM27 11.5H24C23.175 11.5 22.4297 11.8645 21.8859 12.4549C23.775 13.5895 25.1156 15.6379 25.4062 18.0714H28.5C29.3297 18.0714 30 17.3373 30 16.4286V14.7857C30 12.9734 28.6547 11.5 27 11.5ZM15 11.5C17.9016 11.5 20.25 8.9279 20.25 5.75C20.25 2.5721 17.9016 0 15 0C12.0984 0 9.75 2.5721 9.75 5.75C9.75 8.9279 12.0984 11.5 15 11.5ZM18.6 13.1429H18.2109C17.2359 13.6562 16.1531 13.9643 15 13.9643C13.8469 13.9643 12.7688 13.6562 11.7891 13.1429H11.4C8.41875 13.1429 6 15.792 6 19.0571V20.5357C6 21.8962 7.00781 23 8.25 23H21.75C22.9922 23 24 21.8962 24 20.5357V19.0571C24 15.792 21.5812 13.1429 18.6 13.1429ZM8.11406 12.4549C7.57031 11.8645 6.825 11.5 6 11.5H3C1.34531 11.5 0 12.9734 0 14.7857V16.4286C0 17.3373 0.670312 18.0714 1.5 18.0714H4.58906C4.88438 15.6379 6.225 13.5895 8.11406 12.4549Z"
            fill="#73DB78"
          />
        </svg>
      </SectionHeader>
      <Paper className="w-full mt-4">
        {!filteredMembers?.length ? (
          <EmptyMembersList
            onClick={setInviteModal}
            members={filteredMembers}
          />
        ) : (
          <Table items={filteredMembers}>
            <Table.Header>
              <Table.Cell>Members</Table.Cell>
              <Table.Cell>Email</Table.Cell>
              <Table.Cell>Role</Table.Cell>
              <Table.Cell>Status</Table.Cell>
              <Table.Cell />
            </Table.Header>
            {filteredMembers.map(member => {
              return (
                <Table.Row key={member.accountId}>
                  <Table.Cell>{member.name}</Table.Cell>
                  <Table.Cell>{member.email}</Table.Cell>
                  <Table.Cell>
                    <span title="hard coded data">{member.role}</span>
                  </Table.Cell>
                  <Table.Cell>
                    {member.memberStatus && (
                      <InviteStatus
                        member={member}
                        status={member.memberStatus}
                        statusDate={member.date}
                      />
                    )}
                  </Table.Cell>
                  <Table.Cell>
                    {selectMember?.role === 'Admin' && (
                      <MoreButton
                        member={member}
                        getOrganization={getOrganization}
                        userDetails={userDetails}
                        singleMember={selectMember}
                      />
                    )}
                  </Table.Cell>
                </Table.Row>
              );
            })}
          </Table>
        )}
      </Paper>
    </>
  );
};

OrganizationMembers.defaultProps = {
  getOrganization: () => {},
  members: null,
  userDetails: {}
};

EmptyMembersList.defaultProps = {
  onClick: () => {}
};

OrganizationMembers.propTypes = {
  members: PropTypes.arrayOf(PropTypes.any),
  getOrganization: PropTypes.func,
  userDetails: PropTypes.instanceOf(Object)
};

EmptyMembersList.propTypes = {
  onClick: PropTypes.func
};

export default OrganizationMembers;
