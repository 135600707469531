import React, { useState, useEffect, useRef } from 'react';
import { useFormikContext } from 'formik';
import PropTypes from 'prop-types';
import ClickableDiv from 'components/ClickableDiv';
import ChevronDown from 'assets/chevron_down.svg';
import { Button } from '@agconnections/grow-ui';
import PersonIcon from 'assets/person.svg';
import EquipmentIcon from 'assets/equipment.svg';

const TruckAndDriverField = ({
  data,
  isEquipment,
  resetValue,
  initialValue
}) => {
  const [selectedItem, setSelectedItem] = useState(initialValue);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const dropdownRef = useRef(null);
  const { setFieldValue } = useFormikContext();

  const handleType = () => (isEquipment ? 'truckId' : 'driverId');

  const handleImg = () => {
    return isEquipment ? (
      <img className="inline-block" alt="equipment icon" src={EquipmentIcon} />
    ) : (
      <img className="inline-block" alt="person icon" src={PersonIcon} />
    );
  };

  const openDiv = () => {
    setIsDropdownOpen(!isDropdownOpen);
  };

  useEffect(() => {
    const handleClickOutside = event => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsDropdownOpen(false);
      }
    };

    document.addEventListener('click', handleClickOutside);

    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, []);

  useEffect(() => {
    if (resetValue) {
      setSelectedItem(undefined);
      setFieldValue('TruckAndDriverFieldResetValue', false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [resetValue]);

  return (
    <div data-testid="drop-down-field" className="w-full" ref={dropdownRef}>
      <Button onClick={openDiv} style={{ width: '100%' }} type="outline" ghost>
        <div className="flex flex-row justify-between w-full relative bg-color">
          <div className="flex flex-row">
            <div data-testid="drop-down-item-name" className="mr-2">
              {selectedItem ? handleImg() : null}
            </div>
            {selectedItem?.name}
          </div>
          <img src={ChevronDown} alt="Sort Icon" />
        </div>
      </Button>

      {isDropdownOpen && (
        <div
          className=" absolute bg-white mt-2 shadow-card rounded"
          style={{ height: '180px', width: 'calc(100% - 24px)', zIndex: 10000 }}
        >
          <div className="w-full h-full flex flex-col justify-center">
            <div className="mt-2 pl-1 overflow-auto flex-1  w-full">
              {data?.length > 0 ? (
                data.map(item => (
                  <ClickableDiv
                    key={item.id}
                    className="h-6 gr-whitespace-nowrap"
                    onClick={() => {
                      setIsDropdownOpen(false);
                      setSelectedItem(item);
                      setFieldValue(handleType(), item.id);
                    }}
                  >
                    <div className="w-full h-full p-2 flex items-center">
                      {handleImg()}
                      <div data-testid="item-name" className="pl-2">
                        {item.name}
                      </div>
                    </div>
                  </ClickableDiv>
                ))
              ) : (
                <div>No data found</div>
              )}
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

TruckAndDriverField.propTypes = {
  data: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      label: PropTypes.string
    })
  ).isRequired,
  resetValue: PropTypes.bool,
  initialValue: PropTypes.shape({}),
  isEquipment: PropTypes.bool
};
TruckAndDriverField.defaultProps = {
  resetValue: false,
  initialValue: null,
  isEquipment: false
};
export default TruckAndDriverField;
