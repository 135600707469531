import React, { useMemo, useRef, useEffect, useState, useContext } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import PropTypes from 'prop-types';
import _ from 'lodash';

import { Formik } from 'formik';

import editIcon from 'assets/edit_field.svg';
import cropIcon from 'assets/crop_cycle.svg';

import useCropSeason from 'hooks/useCropSeason';
import { selectCropSeason } from 'utilities/updated-permissions';

import FormNavGuard from 'components/FormNavGuard';
import { Context } from 'components/Store';
import DeleteCropzoneModal from 'screens/Property/PropertiesLanding/components/DeleteCropzoneModal';
import { paths } from 'routes/paths';

import useLandManagementData from 'screens/Property/hooks/useLandManagementData';
import { useFlags } from 'launchdarkly-react-client-sdk';
import featureFlagRenderItem from 'helpers/featureFlagRenderItem';
import useCropZoneData from '../../../hooks/useCropZoneData';
import { getFeaturesCentroidCoordinates } from '../../../helpers/mapApiHelpers';
import {
  calculateFeaturesTotalAreaInAcres,
  convertFeaturesToBaseGeometry
} from '../../../helpers/propertyDataHelpers';

import CropZoneHeader from './components/CropZoneHeader';
import CropZoneAction from './components/CropZoneAction';
import CropZoneActions from './components/CropZoneActions';
import CropZoneNameInput from './components/CropZoneNameInput';
import CropZoneAreaInput from './components/CropZoneAreaInput';
import CropZoneCropSelect from './components/CropZoneCropSelect';
import CropZoneCropSeasonSelect from './components/CropZoneCropSeasonSelect';
import schema from './schema';

const Division = () => <div className="border-b mb-2 mt-2 -ml-10 -mr-6" />;

const CropZoneCard = ({ field, features, isSaving, cropzone }) => {
  const { isOrgInFF } = useLandManagementData();

  const history = useHistory();
  const { action, cropzoneId } = useParams();
  const [newCropSeasonId, setNewCropSeasonId] = useState(null);
  const [cropZoneSaved, setCropZoneSaved] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);

  const goTo = useRef('');
  const navGuardDisabled = useRef(false);

  const [{ organization }, dispatch] = useContext(Context);

  const { cropZone, createCropZone, updateCropZone } = useCropZoneData(
    cropzoneId
  );
  const {
    getCropSeason,
    updateCropSeason,
    prepareCropSeason
  } = useCropSeason();

  const {
    releaseFeCondensedCropSeasons,
    releaseFeCondensedCropSeasonsByOrg
  } = useFlags();

  const isCondensedCropSeasonEnabled = featureFlagRenderItem(
    releaseFeCondensedCropSeasons,
    releaseFeCondensedCropSeasonsByOrg,
    organization?.id
  );

  const isEditing = action === 'edit';
  const cropSeasonSelected = cropzone?.cropSeasons
    ? cropzone?.cropSeasons[0]?.id
    : '';

  const saveCropZone = async values => {
    const { crop, name, reportedArea, cropSeasonId } = values;

    isSaving(true);

    const featuresToGetGeometry = features.map(ft => {
      if (ft.geometry.type === 'Polygon') {
        return {
          ...ft,
          geometry: {
            type: 'MultiPolygon',
            coordinates: [ft.geometry.coordinates]
          }
        };
      }

      return ft;
    });

    const geometry = convertFeaturesToBaseGeometry(featuresToGetGeometry);

    geometry.coordinates = _.flatten(geometry.coordinates);

    try {
      const cropZone2 = {
        id: cropZone?.id ? cropZone.id : '',
        name,
        cropId: crop,
        reportedArea: reportedArea ? parseFloat(reportedArea) : 0,
        fieldId: field?.id,
        propertyId: field?.propertyId,
        geometry,
        coordinates: getFeaturesCentroidCoordinates(
          featuresToGetGeometry
        ).toString()
      };

      if (isOrgInFF) {
        cropZone2.cropSeasonId = cropSeasonId;
      }

      if (!isEditing) {
        await createCropZone(cropZone2);
      } else {
        await updateCropZone(cropZone2);
      }

      navGuardDisabled.current = true;
      setCropZoneSaved(true);
      if (cropZone2.cropSeasonId) {
        await selectCropSeason(
          dispatch,
          [cropZone2.cropSeasonId],
          isCondensedCropSeasonEnabled
        );
      }
      if (isOrgInFF) {
        isSaving(false);
        if (goTo.current === 'reload') {
          window.location.reload();
        } else {
          history.push(goTo.current);
        }
      }
    } catch (err) {
      setCropZoneSaved(false);
      isSaving(false);
    }
  };

  const updateCropSeasons = async () => {
    try {
      if (cropSeasonSelected !== newCropSeasonId) {
        if (cropSeasonSelected) {
          const oldCropSeason = await getCropSeason(cropSeasonSelected);
          const updatedOldCropSeason = prepareCropSeason(
            oldCropSeason,
            field?.propertyId,
            cropZone?.id,
            true
          );

          await updateCropSeason(cropSeasonSelected, updatedOldCropSeason);
        }

        const cropSeason = await getCropSeason(newCropSeasonId);
        const updatedCropSeason = prepareCropSeason(
          cropSeason,
          field?.propertyId,
          cropZone?.id
        );

        await updateCropSeason(newCropSeasonId, updatedCropSeason);
      }

      if (goTo.current === 'reload') {
        window.location.reload();
      } else {
        history.push(goTo.current);
      }
    } catch (error) {
      setCropZoneSaved(false);
      isSaving(false);
    }
  };

  useEffect(() => {
    const saveSeasons = async () => {
      if (cropZone && cropZoneSaved) {
        await updateCropSeasons();
      }
    };

    if (!isOrgInFF) {
      saveSeasons();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cropZone, cropZoneSaved]);

  useEffect(() => {
    setNewCropSeasonId(cropSeasonSelected);
  }, [cropSeasonSelected]);

  const validateCanSave = ({ cropSeasonId, crop, name, reportedArea }) => {
    if (!crop) return false;
    if (!name || !_.trim(name)) return false;
    if (!field) return false;
    if (!features.length) return false;
    if (!cropSeasonId) return false;
    if (reportedArea && isNaN(parseFloat(reportedArea))) return false;

    return true;
  };

  const boundaryArea = useMemo(() => {
    const area = features.length
      ? calculateFeaturesTotalAreaInAcres(features)
      : 0;

    return _.round(area, 2).toFixed(2);
  }, [features]);

  const handleDelete = () => {
    setIsDeleting(true);
    navGuardDisabled.current = true;
    history.push(paths.properties);
  };

  return (
    <Formik
      initialValues={{
        crop: '',
        name: '',
        reportedArea: '',
        cropSeasonId: ''
      }}
      onSubmit={values => {
        setNewCropSeasonId(values?.cropSeasonId);
        saveCropZone(values);
      }}
      validationSchema={schema}
    >
      {({ submitForm, values, isSubmitting }) => {
        const canSave = !isSubmitting && validateCanSave(values);

        return (
          <div className="flex flex-col h-full">
            <FormNavGuard
              disabled={navGuardDisabled.current}
              isDeleting={isDeleting}
              alwaysShow
            />
            <CropZoneHeader />
            <Division />
            <div className="flex-1 mt-12 flex flex-col gap-12">
              <CropZoneCropSeasonSelect cropSeasonId={cropSeasonSelected} />
              <CropZoneCropSelect
                cropId={cropZone?.cropId || cropzone?.crop?.id}
              />
              <CropZoneNameInput name={cropzone?.name} />
              <CropZoneAreaInput
                boundaryArea={boundaryArea}
                reportedArea={cropzone?.reportedArea}
              />
            </div>
            <Division />
            <div className="flex justify-end mt-3 relative">
              <CropZoneActions
                disabled={!canSave}
                onSave={() => {
                  goTo.current = paths.properties;
                  submitForm();
                }}
                canDelete={!!cropzone}
              >
                {isEditing ? (
                  ({ deleteModalOpen, setDeleteModalOpen }) => (
                    <DeleteCropzoneModal
                      open={deleteModalOpen}
                      close={() => setDeleteModalOpen(false)}
                      cropzoneId={cropzone?.id}
                      cropzoneName={cropzone?.name}
                      onDelete={handleDelete}
                    />
                  )
                ) : (
                  <>
                    <CropZoneAction
                      disabled={!canSave}
                      onClick={() => {
                        goTo.current = 'reload';
                        submitForm();
                      }}
                    >
                      <img src={cropIcon} alt="" className="mr-2" />
                      Save and Add another Crop zone
                    </CropZoneAction>
                    <CropZoneAction
                      disabled={!canSave}
                      onClick={() => {
                        goTo.current = `${paths.properties}/create`;
                        submitForm();
                      }}
                    >
                      <img src={editIcon} alt="" className="mr-2" />
                      Save and Draw a new Field
                    </CropZoneAction>
                  </>
                )}
              </CropZoneActions>
            </div>
          </div>
        );
      }}
    </Formik>
  );
};

CropZoneCard.defaultProps = {
  field: null,
  features: [],
  isSaving: () => {},
  cropzone: null
};

CropZoneCard.propTypes = {
  isSaving: PropTypes.func,
  field: PropTypes.objectOf,
  features: PropTypes.arrayOf(PropTypes.object),
  cropzone: PropTypes.objectOf
};

export default CropZoneCard;
