import React from 'react';
import { PropTypes } from 'prop-types';
import { Button } from '@agconnections/grow-ui';
import { EditOutlined } from '@material-ui/icons';
import { kebabCase, capitalize } from 'lodash';

const PropertyCardHeader = ({ title, description, icon, setEnableEdit }) => {
  return (
    <div>
      <div className="flex flex-row items-center justify-between mb-6">
        <div className="flex">
          <div className="h-10 w-10 bg-neutral-100 rounded flex flex-row justify-center p-2">
            <img alt={`${capitalize(title)} Icon`} src={icon} />
          </div>
          <div
            data-testid={`${kebabCase(title)?.toLowerCase()}-info`}
            className="flex flex-col pl-2"
          >
            <span className="text-base font-semibold">{title}</span>
            <p className="text-xs font-normal">{description}</p>
          </div>
        </div>
        {setEnableEdit && (
          <Button
            style={{
              marginRight: '1rem'
            }}
            type="text"
            icon={<EditOutlined />}
            onClick={() => setEnableEdit(prev => !prev)}
          />
        )}
      </div>
    </div>
  );
};

PropertyCardHeader.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  icon: PropTypes.string.isRequired,
  setEnableEdit: PropTypes.func
};

PropertyCardHeader.defaultProps = {
  setEnableEdit: undefined
};

export default PropertyCardHeader;
