import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { Table } from '@agconnections/grow-ui';

import RoundedDisplay from 'components/RoundedDisplay';
import { formatEpochDate } from 'utilities/dateHelpers';
import ListWithPopup from 'components/ListWithPopup';
import './custom.css';
import EllipseMenu from 'components/EllipseMenu';
import { CROP_SEASONS_LANDING_MENU } from 'utilities/menus';
import { paths } from 'routes/paths';

const CropSeasonTableRow = ({
  cropSeason,
  onAction,
  selectedMember,
  financialAccess
}) => {
  const getCropListString = season => {
    return season.crops
      ? season.crops.map((crop, index) =>
          index !== season.crops.length - 1 ? `${crop.name}, ` : `${crop.name}`
        )
      : 'No Crop';
  };

  return (
    <Table.Row id={`${cropSeason.active ? '' : 'bg-neutral-100'}`}>
      <Table.Cell>
        <span
          className="text-sm font-bold"
          style={{ color: cropSeason.active ? '' : '#696F88' }}
        >
          <Link to={`${paths.cropSeasons}/${cropSeason.id}/view`}>
            {cropSeason.name}
          </Link>
        </span>
      </Table.Cell>
      <Table.Cell>
        <span style={{ color: cropSeason.active ? '' : '#696F88' }}>
          {cropSeason.active ? 'Active' : 'Inactive'}
        </span>
      </Table.Cell>
      <Table.Cell>
        <ListWithPopup
          position="right"
          ariaLabel="Crops List"
          delay={1000}
          top="0"
          msg={<div className="w-96">{getCropListString(cropSeason)}</div>}
          component={
            <div
              className="truncate w-60"
              style={{ color: cropSeason.active ? '' : '#696F88' }}
            >
              {getCropListString(cropSeason)}
            </div>
          }
        />
      </Table.Cell>
      <Table.Cell>
        <span style={{ color: cropSeason.active ? '' : '#696F88' }}>
          <RoundedDisplay>
            {cropSeason.totalArea?.totalArea || '0'}
          </RoundedDisplay>
        </span>
      </Table.Cell>
      <Table.Cell>
        <span style={{ color: cropSeason.active ? '' : '#696F88' }}>
          {formatEpochDate(cropSeason.startDateEpoch)}
        </span>
      </Table.Cell>
      <Table.Cell>
        <span style={{ color: cropSeason.active ? '' : '#696F88' }}>
          {formatEpochDate(cropSeason.endDateEpoch)}
        </span>
      </Table.Cell>
      <Table.Cell>
        <div
          className="mr-8"
          style={{ color: cropSeason.active ? '' : '#696F88' }}
        >
          {formatEpochDate(cropSeason.createdDateEpoch)}
        </div>

        <div
          data-testid="crop-season-table-options-menu"
          className="absolute right-0 flex-none w-10"
        >
          <EllipseMenu
            onAction={action => onAction(action, cropSeason)}
            options={CROP_SEASONS_LANDING_MENU}
            userPermissions={{
              role: selectedMember?.role,
              financialAccess
            }}
          />
        </div>
      </Table.Cell>
    </Table.Row>
  );
};

CropSeasonTableRow.defaultProps = {
  selectedMember: {}
};

CropSeasonTableRow.propTypes = {
  cropSeason: PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.string,
    crops: PropTypes.arrayOf(PropTypes.object),
    active: PropTypes.bool,
    startDate: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.instanceOf(Date)
    ]),
    endDate: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.instanceOf(Date)
    ]),
    createdAt: PropTypes.instanceOf(Date),
    totalArea: PropTypes.instanceOf(Object),
    startDateEpoch: PropTypes.number,
    endDateEpoch: PropTypes.number,
    createdDateEpoch: PropTypes.number
  }).isRequired,
  onAction: PropTypes.func.isRequired,
  selectedMember: PropTypes.instanceOf(Object),
  financialAccess: PropTypes.string.isRequired
};

export default CropSeasonTableRow;
