const tabFieldsMap = [
  ['name', 'crops', 'area', 'cropSeason'],
  ['productApplications'],
  ['totalSelectedArea', 'coverage'],
  ['costs'],
  ['revenue', 'fsaPaymentPerAcre'],
  ['notes']
];

export default tabFieldsMap;
