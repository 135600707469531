import ValidationAlert from 'assets/alert-exclamation.svg';
import PropTypes from 'prop-types';
import React from 'react';

const WeightUnitInfoAlert = ({ weightUnit = '', error }) => (
  <div className="flex flex-row">
    <span
      className="text-neutral-60 tracking-tighter text-sm"
      data-testid="weight-unit-info"
    >
      {weightUnit}
    </span>
    {error ? (
      <img
        className="ml-1"
        src={ValidationAlert}
        alt="validation alert"
        data-testid="validation-alert"
      />
    ) : null}
  </div>
);

WeightUnitInfoAlert.propTypes = {
  weightUnit: PropTypes.string.isRequired,
  error: PropTypes.string
};

WeightUnitInfoAlert.defaultProps = {
  error: ''
};

export default WeightUnitInfoAlert;
