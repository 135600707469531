import { Col, Row } from 'antd';
import { flatten } from 'lodash';
import React from 'react';

const renderLegendLabelRows = ({ fieldsToMassiveAssign, mapLegendLabels }) => {
  const cropZones = flatten(
    fieldsToMassiveAssign.map(({ cropzones }) => cropzones)
  );
  const cropZoneIndexes = Array.from(
    {
      length: new Set(cropZones).size
    },
    (_, i) => i + 1
  );

  return cropZoneIndexes.map(index => (
    <Row key={mapLegendLabels[index]}>
      <Col className="w-4 mr-6px text-right text-12pt">{index}.</Col>
      <Col className="text-12pt" span={23}>
        {mapLegendLabels[index]?.replace(/\n/g, ' / ')}
      </Col>
    </Row>
  ));
};

export default renderLegendLabelRows;
