export const getFirstLatLongCoordinates = coordinates => {
  if (!Array.isArray(coordinates[0])) {
    return [coordinates[0], coordinates[1]];
  }
  return getFirstLatLongCoordinates(coordinates[0]);
};

export const getFlatLatLongCoordinates = coordinates => {
  if (!Array.isArray(coordinates[0])) {
    return [coordinates];
  }
  return coordinates.flatMap(getFlatLatLongCoordinates);
};
