import React from 'react';
import PropTypes from 'prop-types';
import { Button, Select, Spinner } from '@agconnections/grow-ui';
import { useFormikContext } from 'formik';

import GrowUIFormField from 'components/Forms/GrowUIFormField';
import ClearIcon from '@material-ui/icons/Clear';

import ordinal from '../ordinalHelper';

const Assignees = ({ people, peopleLoading, handleRemove }) => {
  const { values } = useFormikContext();
  const mappedMembersNames = people
    ? people.map(member => {
        return { key: member.id, value: member.name };
      })
    : [];

  const assigneeRow = index => (
    <div className="flex">
      <div className="w-full">
        {peopleLoading ? (
          <Spinner />
        ) : (
          <GrowUIFormField
            label={`${index + 1}${ordinal(index + 1)} Assignee`}
            control={Select}
            name={`assigneeIds[${index}].id`}
            items={mappedMembersNames}
          />
        )}
      </div>
      <div className="ml-2">
        <Button
          type="outline"
          ghost
          onClick={() => handleRemove(index)}
          icon={<ClearIcon />}
        />
      </div>
    </div>
  );
  return (
    <>
      {values.assigneeIds && values.assigneeIds.length
        ? values.assigneeIds.map((assignee, index) => assigneeRow(index))
        : assigneeRow(0)}
      <></>
    </>
  );
};

Assignees.defaultProps = {
  people: []
};

Assignees.propTypes = {
  people: PropTypes.arrayOf(PropTypes.object),
  peopleLoading: PropTypes.bool.isRequired,
  handleRemove: PropTypes.func.isRequired
};

export default Assignees;
