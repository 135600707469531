import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useAuth } from 'utilities/base-auth';
import EllipseMenu from 'components/EllipseMenu';
import {
  addElementToMenu,
  deleteOption,
  editAccessOption,
  resendInviteOption,
  revokeAccessOption,
  revokeInviteOption
} from 'utilities/menus';
import CancelInviteModal from '../CancelInviteModal';
import RevokeAccessModal from '../RevokeAccessModal';
import InviteModal from '../InviteModal';
import DeleteRevokedModal from '../DeleteRevokedModal';

const MoreButton = ({ member, getOrganization, userDetails, singleMember }) => {
  const [inviteModalOpen, setInviteModalOpen] = useState(false);
  const [revokeAccessModalOpen, setRevokeAccessModalOpen] = useState(false);
  const [cancelInviteModalOpen, setCancelInviteModalOpen] = useState(false);
  const [deleteRevokedModalOpen, setDeleteRevokedModalOpen] = useState(false);

  const { user } = useAuth();
  const { memberStatus, email } = member;

  let menu = [];
  if (memberStatus !== 'Pending' && memberStatus !== 'Revoked') {
    menu = addElementToMenu(menu, editAccessOption());
  }
  if (memberStatus === 'Pending') {
    menu = addElementToMenu(menu, revokeInviteOption());
  }
  if (memberStatus === 'Joined' && email !== user.email) {
    menu = addElementToMenu(menu, revokeAccessOption());
  }
  if (memberStatus === 'Revoked') {
    menu = addElementToMenu(menu, resendInviteOption());
    menu = addElementToMenu(menu, deleteOption('delete'));
  }

  const handleAction = action => {
    const actions = {
      editAccess: () => setInviteModalOpen(!inviteModalOpen),
      revokeInvite: () => setCancelInviteModalOpen(!cancelInviteModalOpen),
      revokeAccess: () => setRevokeAccessModalOpen(!revokeAccessModalOpen),
      resendInvite: () => setInviteModalOpen(!inviteModalOpen),
      delete: () => setDeleteRevokedModalOpen(!deleteRevokedModalOpen)
    };
    actions[action]();
  };

  return (
    <div data-testid="more-button">
      {/* TODO Refactor so we reuse the same modal with one modal state but change out the content. */}
      <InviteModal
        open={inviteModalOpen}
        data={member}
        onClose={setInviteModalOpen}
        getOrganization={getOrganization}
        userDetails={userDetails}
        singleMember={singleMember}
      />
      <RevokeAccessModal
        open={revokeAccessModalOpen}
        accountId={member.accountId}
        onClose={setRevokeAccessModalOpen}
        getOrganization={getOrganization}
      />
      <DeleteRevokedModal
        open={deleteRevokedModalOpen}
        accountId={member.accountId}
        member={member}
        onClose={setDeleteRevokedModalOpen}
        getOrganization={getOrganization}
      />
      <CancelInviteModal
        open={cancelInviteModalOpen}
        invitationId={member.invitationId}
        onClose={setCancelInviteModalOpen}
        getOrganization={getOrganization}
      />
      <EllipseMenu onAction={handleAction} options={menu} />
    </div>
  );
};

MoreButton.defaultProps = {
  member: {},
  getOrganization: () => {},
  singleMember: {},
  userDetails: {}
};

MoreButton.propTypes = {
  member: PropTypes.oneOfType([PropTypes.object]),
  getOrganization: PropTypes.func,
  singleMember: PropTypes.instanceOf(Object),
  userDetails: PropTypes.instanceOf(Object)
};

export default MoreButton;
