import { useEffect, useState } from 'react';
import { LMOrganization } from 'utilities/api';
import catchCancel from 'helpers/catchCancel';

const useLandManagementData = () => {
  const [isOrgInFF, setIsOrgInFF] = useState();

  useEffect(() => {
    const { promise } = LMOrganization.fetch();
    promise
      .then(({ data }) => {
        setIsOrgInFF(data);
      })
      .catch(catchCancel);
  }, []);

  return {
    isOrgInFF
  };
};

export default useLandManagementData;
