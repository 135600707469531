import * as Yup from 'yup';

// eslint-disable-next-line import/prefer-default-export
export const AddMasterListProductSchema = Yup.object().shape({
  averagePricePerUnit: Yup.number().positive(
    'List Price must be a positive number'
  ),
  packageWeight: Yup.number()
    .notOneOf([0], 'Weight cannot be 0')
    .positive('Weight must be a positive number')
    .typeError('Weight must be a number')
});
