import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { Table } from '@agconnections/grow-ui';

const checkSortCol = (column, sortBy, colSortDir) =>
  column === sortBy ? colSortDir : null;

const sortDirection = currentDirection => {
  if (!currentDirection) {
    return 'asc';
  }
  if (currentDirection === 'asc') {
    return 'dsc';
  }

  return null;
};

const DetailTableHeader = ({
  sortBy,
  colSortDir,
  sortClick,
  headerColumns
}) => {
  const getSortCol = useCallback(
    column => checkSortCol(column, sortBy, colSortDir),
    [sortBy, colSortDir]
  );

  const handleSortClick = column => {
    if (sortBy) {
      const colSort = checkSortCol(column, sortBy, colSortDir);
      sortClick(column, sortDirection(colSort));
    }
  };

  return (
    <Table.Header>
      {headerColumns?.map(({ field, width, sortable, title }) => {
        return (
          <Table.Cell
            key={field}
            width={width}
            sortable={sortable}
            sort={getSortCol(field)}
            onClick={() => handleSortClick(field)}
          >
            {title}
          </Table.Cell>
        );
      })}
    </Table.Header>
  );
};

DetailTableHeader.defaultProps = {
  sortBy: null,
  colSortDir: null,
  sortClick: null
};

DetailTableHeader.propTypes = {
  sortBy: PropTypes.string,
  colSortDir: PropTypes.string,
  sortClick: PropTypes.func,
  headerColumns: PropTypes.arrayOf(PropTypes.object).isRequired
};

export default DetailTableHeader;
