import { useCallback, useRef, useState } from 'react';
import axios from 'axios';
import { CROPWISE_PROXY_URL } from 'utilities/apiConstants';
import { getAccessToken } from 'utilities/base-auth';
import catchCancel from 'helpers/catchCancel';
import {
  INVOICES_DEFAULT_PAGINATION,
  INVOICES_DEFAULT_SORT
} from './constants';

/**
 * Custom hook for managing and fetching invoice data.
 * @module useInvoices
 *
 * @returns {object} The `invoices` state object and the `fetchInvoices` function.
 *
 * @example
 * const { invoices, fetchInvoices } = useInvoices();
 * useEffect(() => {
 *   fetchInvoices({ size: 10, pageNo: 1, sortBy: 'date', sortDir: 'DESC' }).then(data => {
 *     console.log('Fetched Invoices:', data);
 *   });
 * }, [fetchInvoices]);
 * */
const useInvoices = () => {
  const requestCounter = useRef({ invoices: 0 });
  const [invoices, setInvoices] = useState({
    data: [],
    error: null,
    isLoading: false,
    isError: false,
    isSuccess: false
  });

  const fetchInvoices = useCallback(async (params = {}) => {
    const {
      size = INVOICES_DEFAULT_PAGINATION.size,
      pageNo = INVOICES_DEFAULT_PAGINATION.initialPage,
      searchBy = null,
      sortBy = INVOICES_DEFAULT_SORT.by,
      sortDir = INVOICES_DEFAULT_SORT.dir,
      cropSeasonIds = [],
      startDate,
      endDate
    } = params;
    requestCounter.current.invoices += 1;
    const counter = requestCounter.current.invoices;
    setInvoices(prev => ({ ...prev, isLoading: true }));
    return axios
      .get(`${CROPWISE_PROXY_URL}/v1/invoice/summaries`, {
        headers: {
          'Content-Type': 'application/json',
          'cwf-context': JSON.stringify({
            organization_id: localStorage.getItem('selectedOrganizationId')
          }),
          Authorization: `Bearer ${getAccessToken()}`
        },
        params: {
          size,
          pageNo,
          sortBy,
          sortDir,
          cropSeasonIds: cropSeasonIds.join(','),
          ...(searchBy ? { searchBy } : {}),
          ...(startDate && endDate ? { startDate, endDate } : {})
        }
      })
      .then(({ data }) => {
        const { results, ...rest } = data;
        if (counter === requestCounter.current.invoices) {
          setInvoices(prev => ({
            ...prev,
            ...rest,
            data: results,
            isSuccess: true,
            error: null
          }));
        }
        return data;
      })
      .catch(catchCancel)
      .catch(e => {
        if (counter === requestCounter.current.invoices) {
          setInvoices(prev => ({ ...prev, isError: true, error: e }));
        }
        return {};
      })
      .finally(() => {
        if (counter === requestCounter.current.invoices) {
          setInvoices(prev => ({ ...prev, isLoading: false }));
        }
      });
  }, []);

  return {
    invoices,
    fetchInvoices
  };
};

export default useInvoices;
