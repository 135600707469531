import React, { useContext, useState } from 'react';
import PropTypes from 'prop-types';
import { SimpleModal, Input } from '@agconnections/grow-ui';
import { withRouter } from 'react-router-dom';
import { organization as orgsApi } from 'utilities/api';
import catchCancel from 'helpers/catchCancel';
import { parseServerError } from 'helpers/errorHelpers';
import { Context } from 'components/Store';
import useOrganization from 'hooks/useOrganization';
import { paths } from 'routes/paths';

const SecondaryConfirmationContent = ({
  orgName,
  deleteConfirm,
  setDeleteConfirm,
  error
}) => {
  return (
    <div className="flex flex-col items-center justify-center pr-6 -ml-6 text-center">
      <svg
        width="162"
        height="162"
        viewBox="0 0 162 162"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className="mt-6 mb-10"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M80.919 0.166626C36.299 0.166626 0.166504 36.38 0.166504 81C0.166504 125.62 36.299 161.833 80.919 161.833C125.62 161.833 161.833 125.62 161.833 81C161.833 36.38 125.62 0.166626 80.919 0.166626ZM89.0832 89.0833V40.5833H72.9165V89.0833H89.0832ZM89.0832 121.417V105.25H72.9165V121.417H89.0832ZM16.3332 81C16.3332 116.728 45.2715 145.667 80.9998 145.667C116.728 145.667 145.666 116.728 145.666 81C145.666 45.2716 116.728 16.3333 80.9998 16.3333C45.2715 16.3333 16.3332 45.2716 16.3332 81Z"
          fill="#CF3537"
        />
      </svg>
      <div className="mb-2 text-2xl font-semibold leading-8">
        Delete the organization {orgName}?
      </div>
      <p className="mb-24 text-lg leading-7">
        You are about to permanently delete this organization. This cannot be
        undone. Are you sure you want to proceed?
      </p>

      <div className="mb-10">
        <div className="flex items-center ">
          <div className="mr-4"> Type “Delete” to confirm</div>
          <Input
            placeholder="Type Here"
            value={deleteConfirm}
            onChange={e => setDeleteConfirm(e.target.value)}
            error={error}
            errorMessage={error}
          />
        </div>
      </div>
    </div>
  );
};

const DeleteOrgConfirmation = ({ open, close, orgName }) => {
  const [{ organization, organizations }, dispatch] = useContext(Context);
  const [isSaving, setIsSaving] = useState(false);

  const [secondaryConfirmation, setSecondaryConfirmation] = useState(false);
  const [deleteConfirm, setDeleteConfirm] = useState('');
  const [error, setError] = useState(false);
  // const { fetchUserProfile } = useAuth();
  const { setOrganization } = useOrganization();
  // const [organization, setOrganization] = useState(null);
  // const [loading, setLoading] = useState(false);
  const modalContent = !secondaryConfirmation ? (
    <span>
      You are about to permanently delete {orgName}. This cannot be undone. Are
      you sure you want to proceed?
    </span>
  ) : (
    <SecondaryConfirmationContent
      orgName={orgName}
      deleteConfirm={deleteConfirm}
      setDeleteConfirm={setDeleteConfirm}
      error={error}
    />
  );

  const handleProgression = async () => {
    if (!secondaryConfirmation) {
      setSecondaryConfirmation(true);
    } else if (deleteConfirm.toLowerCase() === 'delete') {
      setIsSaving(true);
      const nextOrg = organizations?.find(
        org => !!organization?.id && org?.id !== organization?.id
      );

      const { promise } = orgsApi.delete();
      await promise
        .then(() => {
          if (!nextOrg) {
            localStorage.removeItem('selectedOrganizationId');
            window.location = `/app/welcome`;
            return;
          }

          setOrganization(nextOrg);
          window.location = `${paths.organizations}/${nextOrg.id}`;
        })
        .catch(catchCancel)
        .catch(err => {
          setIsSaving(false);
          parseServerError(dispatch)(err);
        });
    } else {
      setError('Invalid input');
    }
  };
  const handleCancel = () => {
    close();
    setSecondaryConfirmation(false);
  };
  return (
    <SimpleModal
      open={open}
      title="Delete this Organization?"
      close={close}
      cancelLabel="Cancel"
      confirmLabel="Delete"
      onCancel={() => handleCancel()}
      type="error"
      onConfirm={handleProgression}
      isSaving={isSaving}
    >
      {modalContent}
    </SimpleModal>
  );
};

SecondaryConfirmationContent.defaultProps = {
  error: null
};

SecondaryConfirmationContent.propTypes = {
  orgName: PropTypes.string.isRequired,
  deleteConfirm: PropTypes.string.isRequired,
  setDeleteConfirm: PropTypes.func.isRequired,
  error: PropTypes.oneOfType([PropTypes.string, PropTypes.bool])
};

DeleteOrgConfirmation.defaultProps = {
  orgName: ''
};

DeleteOrgConfirmation.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func,
    replace: PropTypes.func
  }).isRequired,
  open: PropTypes.bool.isRequired,
  close: PropTypes.func.isRequired,
  orgName: PropTypes.string
};

export default withRouter(DeleteOrgConfirmation);
