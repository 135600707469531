import React from 'react';
import PropTypes from 'prop-types';

import { Input } from '@agconnections/grow-ui';
import { faChevronLeft } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { roundValue } from 'helpers/unitConversionHelpers';
import GrowUIFormField from 'components/Forms/GrowUIFormField';
import Toolbar from '../Toolbar';
import { calculateFeaturesTotalAreaInAcres } from '../../../../helpers/propertyDataHelpers';

const FieldCard = ({
  features,
  children,
  saveAndCreateCZ,
  saveAndDirectToProperties,
  canSave,
  cancelBackButton,
  label,
  chkSubmitStatus,
  fieldId,
  setChkNavGuardDisabled,
  setIsBackArrowClicked
}) => {
  const fieldArea =
    features.length > 0
      ? roundValue(calculateFeaturesTotalAreaInAcres(features))
      : 0;

  const handleBackArrow = () => {
    setChkNavGuardDisabled(true);
    setIsBackArrowClicked(true);
  };

  return (
    <div
      className="h-full pb-16"
      data-testid="property-create-left-panel-field"
    >
      <div
        data-testid="property-create-field-card"
        className="h-full cursor-default"
      >
        <div
          data-testid="property-create-field-header"
          className="flex mb-2 pb-2"
        >
          <div className="mt-1 mr-2">
            <button
              type="button"
              onClick={() => {
                handleBackArrow();
              }}
            >
              <FontAwesomeIcon
                icon={faChevronLeft}
                size="lg"
                style={{ visibility: 'visible' }}
              />
            </button>
          </div>
          <div className="flex-1 flex-col mx-2">
            <div className="text-3xl font-semibold leading-none">
              {label === 'create' ? 'Create New Field' : 'Edit Field'}
            </div>
          </div>
        </div>
        <div className="border-b mb-2 mt-2 -ml-10 -mr-6" />
        <div data-testid="property-create-field-property" className="mt-12">
          {children}
        </div>
        <div data-testid="property-create-field-name" className="mt-16">
          <GrowUIFormField
            control={Input}
            placeholder="Type the name of your field here"
            name="name"
            label="Field Name*"
          />
        </div>
        <div data-testid="property-create-field-boundry" className="mt-16">
          <GrowUIFormField
            control={Input}
            placeholder="--"
            disabled
            label="Boundary Area"
            name="boundry"
            value={fieldArea}
          />
        </div>
        <div data-testid="property-create-field-reported" className="mt-16">
          <GrowUIFormField
            control={Input}
            placeholder="--"
            label="Reported Area"
            name="reportedArea"
          />
        </div>
      </div>

      <div className="border-b mb-2 mt-2 -ml-10 -mr-6" />
      <Toolbar
        saveButtonDisabled={!canSave}
        cancelClick={() => cancelBackButton()}
        saveFieldCreateZone={() => saveAndCreateCZ()}
        saveFieldDirectToProperties={() => saveAndDirectToProperties()}
        label={label}
        chkSubmitStatus={chkSubmitStatus}
        fieldId={fieldId}
        setChkNavGuardDisabled={setChkNavGuardDisabled}
      />
    </div>
  );
};

FieldCard.propTypes = {
  features: PropTypes.arrayOf(PropTypes.object),
  children: PropTypes.node.isRequired,
  canSave: PropTypes.bool,
  saveAndCreateCZ: PropTypes.func.isRequired,
  saveAndDirectToProperties: PropTypes.func.isRequired,
  cancelBackButton: PropTypes.func.isRequired,
  label: PropTypes.string.isRequired,
  chkSubmitStatus: PropTypes.bool.isRequired,
  fieldId: PropTypes.string,
  setChkNavGuardDisabled: PropTypes.func.isRequired,
  setIsBackArrowClicked: PropTypes.func.isRequired
};

FieldCard.defaultProps = {
  features: [],
  canSave: false,
  fieldId: null
};

export default FieldCard;
