import React from 'react';
import PropTypes from 'prop-types';

const SearchBar = ({ children }) => {
  return (
    <div className="flex items-center justify-between flex-1 md:ml-0">
      {children}
    </div>
  );
};

SearchBar.propTypes = {
  children: PropTypes.node.isRequired
};

export default SearchBar;
