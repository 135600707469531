import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import ItemsDropdown from 'components/ItemsDropdown';
import useProductSearch from 'components/ProductSideBar/helpers/useProductSearch';
import useInventoryProduct from 'screens/ProductList/hooks/useInventoryProduct';
import useDebounce from 'hooks/useDebounce';
import { buildCustomProductPayload } from 'screens/ProductList/helpers/formValuesToPayload';
import EditProductDrawer from 'screens/ProductList/components/EditProductDrawer';
import { useToast } from 'components/ToastContainer';
import ProductDropdownItem from './components/ProductDropdownItem';
import useProduct from '../../hooks/useProduct';

const ProductDropdown = ({ onChange, value, id }) => {
  const [searchText, setSearchText] = useState('');
  const [filteredSelectOptions, setFilteredSelectOptions] = useState([
    {
      key: 'empty',
      label: 'empty',
      type: 'PRODUCT'
    }
  ]);
  const [selectedValue, setSelectedValue] = useState(undefined);
  const timeDelay = 300;
  const debouncedSearchTerm = useDebounce(searchText, timeDelay);
  const { filteredProducts, searchProductById, loading } = useProductSearch(
    debouncedSearchTerm,
    ''
  );
  const toast = useToast();

  const { getProduct, product: loadedProduct } = useProduct();

  const [showEditDrawer, setShowEditDrawer] = useState(false);
  const [newProductAdded, setNewProductAdded] = useState(false);
  const [productToEdit, setProductToEdit] = useState(null);
  const { addProducts, updateError } = useInventoryProduct();

  useEffect(() => {
    if (loadedProduct) {
      setSelectedValue({
        name: loadedProduct.productName,
        id: loadedProduct.productId,
        stdunit: loadedProduct.stdUnit
      });
    }
  }, [loadedProduct]);

  useEffect(() => {
    async function fetchSelectedData() {
      const selected = await searchProductById(value);
      if (selected) {
        setSelectedValue(selected);
      } else {
        getProduct(value);
      }
    }
    if (value && !selectedValue) {
      fetchSelectedData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value]);

  useEffect(() => {
    if (!loading && filteredProducts?.length > 0) {
      setFilteredSelectOptions(
        filteredProducts.map(prod => ({
          key: prod.id,
          label: `${prod.name} - ${prod.manufacturer}`,
          type: 'PRODUCT',
          value: prod
        }))
      );
      if (newProductAdded) {
        setNewProductAdded(!newProductAdded);
        setSelectedValue(filteredProducts[0]);
        onChange(filteredProducts[0]);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loading, filteredProducts]);

  const handleFilter = (_, textFilter) => {
    setSearchText(textFilter);
  };

  const handleChange = selectedProduct => {
    setSelectedValue(selectedProduct);
    setSearchText(undefined);
    onChange(selectedProduct);
  };

  const valueRenderer = _value => {
    if (!_value) {
      return '';
    }
    return `${_value.name} (${_value.stdunit})`;
  };

  const handleOnAddCustomProduct = () => {
    const product = {
      isNew: true,
      custom: true,
      formulation: {},
      type: 'FERTILIZER'
    };
    setProductToEdit(product);
    setShowEditDrawer(true);
  };

  const handleEdit = async values => {
    // Hard edit logic
    if (values.custom) {
      // Hard add/edit custom product
      const payload = buildCustomProductPayload(values);
      await addProducts({ itemsToCreate: [payload] });
    }

    // Toast logic
    if (updateError) {
      toast.error('Unable to save changes', {
        supportButton: true,
        content:
          'An error prevented your product(s) from being added to your list. Please try again.'
      });
    } else {
      toast.success('Changes saved.');
      setSearchText(values.name);
      setNewProductAdded(true);
    }

    setShowEditDrawer(false);
    setProductToEdit();
  };

  return (
    <>
      <ItemsDropdown
        rightOffset={0}
        heightClass="h-64"
        mainClass="items-drop-down-field-1"
        value={selectedValue}
        key={selectedValue}
        onChange={handleChange}
        id={id}
        items={
          loading
            ? [
                {
                  key: 'loading',
                  label: 'loading',
                  type: 'PRODUCT'
                }
              ]
            : filteredSelectOptions
        }
        ItemComponent={ProductDropdownItem}
        bottomButtonText="+ Create Custom Product"
        onFilter={handleFilter}
        isServerSideFilter
        onBottomButtonClick={handleOnAddCustomProduct}
        mergeSearchInputWithValue
        searchPlaceholderText="Search by product name or manufacturer"
        valueRenderer={valueRenderer}
        isLoading={loading}
        bottomButtonTextColor="#0C612C"
        itemOptionClassName=""
      />
      <EditProductDrawer
        handleEdit={handleEdit}
        product={productToEdit}
        visible={showEditDrawer}
        onClose={() => setShowEditDrawer(false)}
        editProductHeader="Add Custom Product"
        saveText="Create Product"
      />
    </>
  );
};

ProductDropdown.propTypes = {
  value: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  id: PropTypes.string
};

ProductDropdown.defaultProps = {
  value: '',
  id: ''
};

export default ProductDropdown;
