import PropTypes from 'prop-types';
import React, { useMemo, useState } from 'react';
import { formatDateToShortMonthDayYearTime } from 'utilities/dateHelpers';
import { ICONS_BY_LOAD_DIRECTION } from 'screens/YieldV2/Loads/constants';
import { getAbbreviatedUnit } from 'helpers/unitsAbbreviation';
import { useFormikContext } from 'formik';
import DeleteIcon from 'assets/deleteIcon.svg';
import { Tooltip } from 'syngenta-digital-cropwise-react-ui-kit';
import DeleteModal from 'components/Modals/DeleteModal';

const LoadItem = ({ item, onDeleteYieldTicket, className }) => {
  const { values, setValues } = useFormikContext();
  const [showDelete, setShowDelete] = useState(false);

  const isCurrentlyEdited = useMemo(() => {
    return item?.loadTemporaryId === values?.loadTemporaryId;
  }, [item?.loadTemporaryId, values?.loadTemporaryId]);

  const { name, load, loadUnit, date, sources, sourceType, destination } =
    item || {};

  if (!sources || !destination) {
    return null;
  }

  const sourceToDestination = `${sourceType}_${destination?.type}`;

  const handleLoadItemEdit = () => {
    setValues(item);
  };

  const handleDelete = () => {
    setShowDelete(false);
    onDeleteYieldTicket(item);
  };

  const handleDeleteCancel = () => {
    setShowDelete(false);
  };

  return (
    <>
      <DeleteModal
        open={showDelete}
        itemType="Load"
        type="error"
        onCancel={handleDeleteCancel}
        onDelete={handleDelete}
        title="Delete Load?"
        message="Are you sure you want to delete this load from your submission?"
      />
      <button
        data-testid="load-item-button"
        onClick={handleLoadItemEdit}
        className={`${
          isCurrentlyEdited ? 'bg-selectedBlue' : 'hover:bg-neutral-20'
        } cursor-pointer px-4 py-10px flex flex-col gap-2 border-b border-neutral-20 w-full outline-none focus:outline-none ${className}`}
        type="button"
      >
        <div className="flex w-full">
          <div className="flex-1 flex gap-3 pb-1 overflow-hidden">
            <div className="h-6 flex-shrink-0">
              <img
                src={ICONS_BY_LOAD_DIRECTION[sourceToDestination]}
                alt={sourceToDestination}
                className="h-full mt-1"
              />
            </div>
            <p className="text-black text-base leading-7 overflow-hidden break-words text-left ">
              {name}
            </p>
          </div>
          {onDeleteYieldTicket && (
            <Tooltip title="Delete" placement="left">
              <button
                className="flex items-center align-center"
                type="button"
                onClick={e => {
                  e.preventDefault();
                  e.stopPropagation();
                  setShowDelete(true);
                }}
              >
                <img src={DeleteIcon} alt="remove icon" />
              </button>
            </Tooltip>
          )}
        </div>
        <div className="flex justify-between w-full text-left">
          <div>
            <p className="text-xs text-neutral-60">Yield Quantity</p>
            <p>
              {parseFloat(load).toFixed(2)} {getAbbreviatedUnit(loadUnit)}
            </p>
          </div>
          <div>
            <p className="text-xs text-neutral-60">Date & Time</p>
            <p>{formatDateToShortMonthDayYearTime(date)}</p>
          </div>
        </div>
      </button>
    </>
  );
};

LoadItem.propTypes = {
  item: PropTypes.shape({
    loadTemporaryId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    id: PropTypes.string,
    orgId: PropTypes.string,
    name: PropTypes.string,
    date: PropTypes.string,
    load: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    loadUnit: PropTypes.string,
    salePrice: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    sourceType: PropTypes.string,
    sources: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.string,
        name: PropTypes.string
      })
    ),
    destination: PropTypes.shape({
      id: PropTypes.string,
      name: PropTypes.string,
      type: PropTypes.string
    }),
    cropSeasonId: PropTypes.string,
    cropId: PropTypes.string,
    cropName: PropTypes.string,
    weightUnit: PropTypes.string,
    grossWeight: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    tareWeight: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    netWeight: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    truckId: PropTypes.string,
    driverId: PropTypes.string,
    notes: PropTypes.string
  }).isRequired,
  onDeleteYieldTicket: PropTypes.func,
  className: PropTypes.string
};

LoadItem.defaultProps = {
  className: '',
  onDeleteYieldTicket: null
};

export default LoadItem;
