/* eslint-disable react/prop-types */
/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';

import { Popup } from '@agconnections/grow-ui';

const PropertyPopup = ({ position, ariaLabel, msg, showPopup }) => (
  <div className="inherit bg-black">
    <div className="absolute" style={{ left: '50%', top: '50%' }}>
      <Popup
        ariaLabel={ariaLabel}
        position={position}
        align="center"
        open={showPopup}
        containerClasses="bg-black"
        // BUG: without a trigger Popup will not render
        trigger={<div />}
      >
        <div className="bg-black text-white whitespace-no-wrap -m-3 p-3 text-xl rounded">
          {msg}
        </div>
      </Popup>
    </div>
  </div>
);
export default PropertyPopup;
