import React from 'react';
import { useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';
import { paths } from 'routes/paths';
import EmptyState from 'components/EmptyState';

const InvoicesEmptyState = ({ hasPermission }) => {
  const history = useHistory();
  return (
    <EmptyState
      buttonText="Create Invoice"
      onClick={event => {
        event.stopPropagation();
        history.push(`${paths.invoices}/invoice`);
      }}
      subtitle="We were unable to find any invoices under this organization."
      setPermission={hasPermission}
    />
  );
};

InvoicesEmptyState.propTypes = {
  hasPermission: PropTypes.func.isRequired
};

export default InvoicesEmptyState;
