/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState } from 'react';
import { faUser } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Popup } from '@agconnections/grow-ui';
import { useAuth } from 'utilities/base-auth';
import useZendesk from 'hooks/useZendesk';

const ProfileButton = () => {
  const { user, logout } = useAuth();
  const { destroyChatWidget } = useZendesk();
  const [menuOpened, setMenuOpened] = useState(false);

  const handleLogout = () => {
    destroyChatWidget();
    logout();
  };

  const Trigger = (
    <button
      type="button"
      className="flex items-center justify-center w-10 h-10 bg-gray-400 rounded-full cursor-pointer hover:opacity-75"
      data-testid="profile-trigger"
      onClick={() => setMenuOpened(true)}
    >
      <FontAwesomeIcon icon={faUser} size="lg" className="text-white" />
    </button>
  );

  return (
    <Popup trigger={Trigger} position="bottom" align="right" open={menuOpened}>
      <div
        data-testid="profile-popup"
        className="-m-2 text-left "
        style={{
          minWidth: '170px'
        }}
      >
        <div className="px-3 py-3 border-b border-gray-300">
          <div className="text-lg font-light text-gray-800">
            {user && `${user.email}`}
          </div>
          <div className="text-xs leading-none text-gray-600">
            {user && user.name}
          </div>
        </div>
        <ul className="font-normal text-gray-700 ">
          <li className="cursor-pointer hover:bg-gray-200 hover:bg-text-800">
            {/* comment out section below when working with profile */}
            {/* <NavLink
              to="/app/profile"
              className="block py-2 pl-2"
              // eslint-disable-next-line react/jsx-boolean-value
              disable={true}
              onClick={() => {
                setMenuOpened(false);
              }}
            >
              Profile
            </NavLink> */}
            {/* delete section below when working with profile */}
            <h2 className="block py-2 pl-2 cursor-not-allowed">Profile</h2>
          </li>
          <li className="cursor-pointer hover:bg-gray-200 hover:bg-text-800">
            <button
              onClick={handleLogout}
              data-testid="logout-btn"
              type="button"
              className="w-full py-2 pl-2 text-left focus:outline-none"
            >
              Logout
            </button>
          </li>
        </ul>
      </div>
    </Popup>
  );
};

export default ProfileButton;
