import React from 'react';
import PropTypes from 'prop-types';

import LandingList from 'components/LandingList';
import { formatEpochDate } from 'utilities/dateHelpers';

import CwFPagination from 'components/CwFPagination';
import TileGroup from 'components/TileGroup';
import TileCard from 'components/TileCard';
import TileCardField from 'components/TileCardField';
import ListWithPopup from 'components/ListWithPopup';
import { CROP_SEASONS_LANDING_MENU } from 'utilities/menus';
import { paths } from 'routes/paths';

const CropSeasonTileBoard = ({
  cropSeasons,
  handleAction,
  seasonPageNumber,
  handlePageChange,
  cropSeasonsLength
}) => {
  const getCropListString = season => {
    return season.crops
      ? season.crops.map((crop, index) =>
          index !== season.crops.length - 1 ? `${crop.name}, ` : `${crop.name}`
        )
      : 'No Crop';
  };

  const renderedActiveSeasons = () => {
    const activeSeasonsLength = cropSeasons.filter(item => item.active).length;

    return (
      <TileGroup
        title="Active Seasons"
        totalItems={activeSeasonsLength}
        testId="crop-season-active"
      >
        {cropSeasons.map(item => {
          return (
            item.active && (
              <TileCard
                key={item.id}
                linkTo={`${paths.cropSeasons}/${item.id}/view`}
                testId="crop-season"
                title={item.name}
                subtitle={
                  <ListWithPopup
                    position="right"
                    ariaLabel="Crops List"
                    delay={1000}
                    top="0"
                    msg={<div className="w-96">{getCropListString(item)}</div>}
                    component={
                      <div className="truncate">{getCropListString(item)}</div>
                    }
                  />
                }
                onAction={action => {
                  handleAction(action, item);
                }}
                menuItems={CROP_SEASONS_LANDING_MENU}
              >
                <div
                  data-testid="crop-season-card-details"
                  className="flex flex-wrap w-full"
                >
                  <TileCardField
                    id="crop-season-total-area"
                    title="Total Area"
                    value={item.totalArea?.totalArea || '0'}
                    testId="crop-season"
                  />
                  <TileCardField
                    id="crop-season-created"
                    title="Created"
                    value={formatEpochDate(item.createdDateEpoch)}
                    testId="crop-season"
                  />
                  <TileCardField
                    id="crop-season-start"
                    title="Start Date"
                    value={formatEpochDate(item.startDateEpoch)}
                    testId="crop-season"
                  />
                  <TileCardField
                    id="crop-season-end"
                    title="End Date"
                    value={formatEpochDate(item.endDateEpoch)}
                    testId="crop-season"
                  />
                </div>
              </TileCard>
            )
          );
        })}
      </TileGroup>
    );
  };

  const renderInactiveSeasons = () => {
    const inactiveSeasonsLength = cropSeasons.filter(
      item => item.active === false
    ).length;

    return (
      <TileGroup
        title="Inactive Seasons"
        totalItems={inactiveSeasonsLength}
        testId="crop-season-inactive"
      >
        {cropSeasons.map(item => {
          return (
            item.active === false && (
              <TileCard
                key={item.id}
                linkTo={`${paths.cropSeasons}/${item.id}/view`}
                testId="crop-season"
                title={item.name}
                className="bg-neutral-100"
                titleColor="#696F88"
                subtitle={
                  <ListWithPopup
                    position="right"
                    ariaLabel="Crops List"
                    delay={1000}
                    top="0"
                    msg={<div className="w-96">{getCropListString(item)}</div>}
                    component={
                      <div className="truncate">{getCropListString(item)}</div>
                    }
                  />
                }
                onAction={action => {
                  handleAction(action, item);
                }}
                menuItems={CROP_SEASONS_LANDING_MENU}
              >
                <div
                  data-testid="crop-season-card-details"
                  className="flex flex-wrap w-full"
                >
                  <TileCardField
                    id="crop-season-total-area"
                    title="Total Area"
                    value={item.totalArea?.totalArea || '0'}
                    testId="crop-season"
                  />
                  <TileCardField
                    id="crop-season-created"
                    title="Created"
                    value={formatEpochDate(item.createdDateEpoch)}
                    testId="crop-season"
                  />
                  <TileCardField
                    id="crop-season-start"
                    title="Start Date"
                    value={formatEpochDate(item.startDateEpoch)}
                    testId="crop-season"
                  />
                  <TileCardField
                    id="crop-season-end"
                    title="End Date"
                    value={formatEpochDate(item.endDateEpoch)}
                    testId="crop-season"
                  />
                </div>
              </TileCard>
            )
          );
        })}
      </TileGroup>
    );
  };

  return (
    <>
      {cropSeasons && cropSeasonsLength ? (
        <div data-testid="crop-seasons-tile-board">
          {CwFPagination(
            cropSeasonsLength || 0,
            seasonPageNumber,
            handlePageChange
          )}

          <div className="pb-1"> </div>
          {renderedActiveSeasons()}
          {renderInactiveSeasons()}
          {CwFPagination(
            cropSeasonsLength || 0,
            seasonPageNumber,
            handlePageChange
          )}
        </div>
      ) : (
        <LandingList.LandingEmpty
          listType="any crop seasons"
          routePath={`${paths.cropSeasons}/create`}
        />
      )}
    </>
  );
};

CropSeasonTileBoard.propTypes = {
  cropSeasons: PropTypes.arrayOf(PropTypes.object),
  handleAction: PropTypes.func,
  seasonPageNumber: PropTypes.number.isRequired,
  handlePageChange: PropTypes.func.isRequired,
  cropSeasonsLength: PropTypes.number.isRequired
};

CropSeasonTileBoard.defaultProps = {
  cropSeasons: [],
  handleAction: () => {}
};

export default CropSeasonTileBoard;
