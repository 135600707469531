import React, { useEffect } from 'react';
import { Button, Spinner, Tabs } from '@agconnections/grow-ui';
import NoDocumentFound from 'assets/no_documents_found.svg';
import farmDataInputs from 'screens/Property/helpers/FarmDataInputs';
import PropTypes from 'prop-types';
import useFarmData from 'screens/Property/hooks/useFarmData';
import EmptyTableState from 'components/EmptyTableState';
import FarmIcon from 'assets/farm_icon.svg';
import { useFormikContext } from 'formik';

import PropertyRow from '../PropertyRow';
import PropertyCardHeader from '../PropertyCardHeader';

const PropertyFarmDetail = ({ id, setEnableEdit }) => {
  const { farm, loading } = useFarmData(id);
  const { setValues } = useFormikContext();

  useEffect(() => {
    if (!farm) {
      return;
    }
    setValues(farm);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [farm]);

  return (
    <>
      {loading ? (
        <Spinner />
      ) : (
        <div>
          <PropertyCardHeader
            title="Farm Information"
            description="Specific property boundary relevant content not contained
                  within any crop season"
            icon={FarmIcon}
            setEnableEdit={setEnableEdit}
          />
          <Tabs>
            <Tabs.Tab label="Location">
              <PropertyRow
                label={farmDataInputs.location.state}
                value={farm.state}
              />
              <PropertyRow
                label={farmDataInputs.location.county}
                value={farm.county}
              />
            </Tabs.Tab>
            <Tabs.Tab label="FSA">
              <PropertyRow
                data-testid="farm-FSA"
                label={farmDataInputs.FSA}
                value={farm.farmNumber}
              />
            </Tabs.Tab>
            <Tabs.Tab
              data-testid="farm-regulatory-permit"
              label="Regulatory Permit"
            >
              <PropertyRow
                label={farmDataInputs.regulatoryPermit}
                value={farm.permitId}
              />
            </Tabs.Tab>
            <Tabs.Tab label="Notes">
              {farm?.notes ? (
                <p className="text-sm font-normal">{farm.notes}</p>
              ) : (
                <div className="mt-10">
                  <EmptyTableState
                    title="No Notes Found"
                    overlayIcon={NoDocumentFound}
                    message="Edit your farm information to add notes."
                    button={
                      <Button
                        type="primary"
                        onClick={() => setEnableEdit(prev => !prev)}
                      >
                        Edit Farm Information
                      </Button>
                    }
                  />
                </div>
              )}
            </Tabs.Tab>
          </Tabs>
        </div>
      )}
    </>
  );
};

PropertyFarmDetail.defaultProps = {
  id: ''
};

PropertyFarmDetail.propTypes = {
  id: PropTypes.string,
  setEnableEdit: PropTypes.func.isRequired
};

export default PropertyFarmDetail;
