import React from 'react';
import PropTypes from 'prop-types';
import FileIcon from 'assets/file_present.svg';

const ProductDetailDocumentation = ({ documentation, openFile }) => {
  return (
    <div className="h-full grid grid-flow-row grid-cols-5 gap-6 p-2 overflow-y-auto">
      {documentation.documentdescriptions?.length > 0 ? (
        <>
          {documentation.documentdescriptions.map(doc => (
            <button
              key={doc.id}
              onClick={() => openFile(doc.id)}
              type="button"
              className="h-40 border border-neutral-30 rounded-lg hover:bg-banner-alert-blue-10"
            >
              <div key={doc.id} className="flex flex-col items-center">
                <img src={FileIcon} alt="File icon" />
                <span className="mt-4 text-base">{doc.description}</span>
                <span className="text-sm text-neutral-60 px-2 w-full truncate">
                  {doc.filename}
                </span>
              </div>
            </button>
          ))}
        </>
      ) : null}
    </div>
  );
};

ProductDetailDocumentation.propTypes = {
  documentation: PropTypes.objectOf(PropTypes.object).isRequired,
  openFile: PropTypes.func.isRequired
};

export default ProductDetailDocumentation;
