import React, { useContext } from 'react';
import { Context } from 'components/Store';
import GrowUIFormField from 'components/Forms/GrowUIFormField';
import { Select } from '@agconnections/grow-ui';

const UsePolyacrylamide = () => {
  const [{ isEnableInfoTabEdit }] = useContext(Context);
  return (
    <GrowUIFormField
      control={Select}
      id="usePolyacrylamide"
      name="usePolyacrylamide"
      items={[
        { key: null, value: 'Select polyacrylamide status' },
        { key: true, value: 'Yes' },
        { key: false, value: 'No' }
      ]}
      label="Use Polyacrylamide (PAM)"
      disabled={!isEnableInfoTabEdit}
      placeholder="Select polyacrylamide status"
    />
  );
};

export default UsePolyacrylamide;
