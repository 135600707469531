import React from 'react';
import PropTypes from 'prop-types';

import { Button } from '@agconnections/grow-ui';
import errorShape from '../models/errorShape';

const TryAgainMessage = ({ error, onReset }) => {
  return (
    <div
      data-testid="try-again-message"
      className="flex flex-col items-center justify-center h-64 bg-white rounded shadow-md radius"
      role="alert"
    >
      <div className="text-xs text-gray-500">Error code: {error.status}</div>
      <div className="px-4 text-xl leading-6 text-center text-gray-800">
        Uh oh, there was an issue.
      </div>
      <div className="px-4 mt-2 text-base leading-5 text-center text-gray-700">
        Something happened while trying to
        {error.status === 'Unknown' ? ' process' : ' load'} your data.
      </div>
      <div className="px-4 mb-10 text-base leading-5 text-center text-gray-700">
        Try again and if the problem persists, please reach out to Support.
      </div>
      <div className="flex justify-center w-full">
        <div className="mx-4">
          <a
            href="https://www.syngentadigital.com/us-en"
            type="button"
            target="_blank"
            rel="noopener noreferrer"
          >
            <Button type="primary" ghost>
              <div className="block px-4 font-normal text-center w-36">
                Contact Support
              </div>
            </Button>
          </a>
        </div>
        <div className="mx-4">
          <Button onClick={onReset} type="primary">
            <div className="block px-4 font-normal text-center w-36">
              Try again
            </div>
          </Button>
        </div>
      </div>
    </div>
  );
};

TryAgainMessage.defaultProps = {
  error: undefined
};

TryAgainMessage.propTypes = {
  error: PropTypes.shape(errorShape),
  onReset: PropTypes.func.isRequired
};

export default TryAgainMessage;
