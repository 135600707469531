import Axios from 'axios';

/**
 * helper function that captures Axios Cancel Errors
 * Rethrows any other error
 * @param {Error} e - thrown error
 */
const catchCancel = e => {
  if (Axios.isCancel(e) || e.name === 'AbortError') {
    // eslint-disable-next-line no-console
    if (process.env.REACT_APP_LOG_LEVEL !== 'OFF') console.log(e.message);
  } else throw e;
};

export default catchCancel;
