import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Col, Row } from 'antd';

import ChevronDown from 'assets/chevron_down.svg';
import ChevronUp from 'assets/chevron_up.svg';

export const CollapsibleSection = ({ title, children }) => {
  const [isOpen, setIsOpen] = useState(true);

  const toggleSection = () => {
    setIsOpen(!isOpen);
  };

  return (
    <div
      className="w-full h-fit bg-white border-b border-neutral-20"
      data-testid="collapsible-container"
    >
      <button
        className="focus:outline-none w-full"
        type="button"
        data-testid="collapsible-toggle"
        onClick={toggleSection}
      >
        <Row justify="space-between" className="pt-5 px-4 pb-6">
          <Col className="text-base font-semibold">{title}</Col>
          <img
            src={isOpen ? ChevronUp : ChevronDown}
            alt="expand collapse accordion"
          />
        </Row>
      </button>

      {isOpen && <div data-testid="collapsible-content">{children}</div>}
    </div>
  );
};

CollapsibleSection.propTypes = {
  title: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired
};
export default CollapsibleSection;
