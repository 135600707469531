import React from 'react';
import CropIcons from 'helpers/getCropIconByCropName';
import CropIconMapping from 'components/CropIconMapping';
import defaultIcon from 'assets/AllCropSVGs/fallow.svg';
import { TLoad } from 'screens/YieldV2/Loads/constants';

const LoadCrop = ({ load }) => {
  const regex = / /g;
  const scrubbedLoad = {
    ...load,
    name: load?.cropName?.replace(regex, '')
  };

  const scrubbedLoadCropName = scrubbedLoad?.name?.includes(':')
    ? scrubbedLoad?.name.split(':')[0].trim()
    : scrubbedLoad?.name;

  return (
    <div className="flex flex-row items-center">
      {scrubbedLoadCropName in CropIcons ? (
        <CropIconMapping cropObject={scrubbedLoad} />
      ) : (
        <img src={defaultIcon} alt="" />
      )}
      <div className="pl-1">{load.cropName}</div>
    </div>
  );
};

LoadCrop.propTypes = {
  load: TLoad.isRequired
};

export default LoadCrop;
