import React, { useState, useContext } from 'react';
import PropTypes from 'prop-types';
import { organization as orgApi } from 'utilities/api';
import { useHistory } from 'react-router-dom';

import { useAuth } from 'utilities/base-auth';

import { Input } from '@agconnections/grow-ui';
import { Formik } from 'formik';

import PortalModal from 'components/Modals/PortalModal';
import { Context } from 'components/Store';
import ErrorMessageConsole from 'components/ErrorHandler/ErrorMessageConsole';
import GrowUIFormField from 'components/Forms/GrowUIFormField';
import catchCancel from 'helpers/catchCancel';
import { parseServerError } from 'helpers/errorHelpers';
import { selectOrganization } from 'utilities/updated-permissions';
import { paths } from 'routes/paths';

const CreateOrganizationModal = ({ open, handleCancel }) => {
  const [apiError] = useState();
  const [isSaving, setIsSaving] = useState(false);

  const [, dispatch] = useContext(Context);

  const { fetchUserProfile } = useAuth();
  const history = useHistory();
  const { user } = useAuth();

  const handleCreateOrganization = async formData => {
    setIsSaving(true);
    orgApi.updateRequestInterceptor({}, () => ``);
    const { promise } = orgApi.create(formData);
    promise
      .then(({ data }) => {
        // This will reinitialize the application because the user profile has to be reset in order to get permissions
        fetchUserProfile();
        return data;
      })
      .then(data => {
        localStorage.setItem('selectedCropSeasons', JSON.stringify([]));
        selectOrganization(dispatch, { name: data.name, id: data.id }, user);
        history.go(`${paths.organizations}/${data.id}`);
      })
      .catch(catchCancel)
      .catch(err => {
        setIsSaving(false);
        parseServerError(dispatch)(err);
      });
  };

  return (
    <div data-testid="create-organiaztion-modal">
      <Formik
        initialValues={{
          name: '',
          languageCountry: 'en-US',
          timeZone: 'GMT',
          areaUnit: 'acres'
        }}
        validate={values => {
          const errors = {};
          const regex = /^\s+$/;
          if (!values.name.length > 0 || regex.test(values.name)) {
            errors.name = 'Required';
          }
          return errors;
        }}
        onSubmit={handleCreateOrganization}
      >
        {({ errors, handleSubmit }) => {
          return (
            <>
              <PortalModal
                cancelLabel="Cancel"
                onConfirm={handleSubmit}
                confirmLabel="Save"
                open={open}
                close={handleCancel}
                title="Create new organization"
                isSaving={isSaving}
              >
                <>
                  <div className="pr-6 mt-12">
                    <GrowUIFormField
                      autoFocus
                      control={Input}
                      label="Name"
                      name="name"
                      errors={errors.name}
                    />
                  </div>
                  <div className="flex justify-end pb-4 mr-5">
                    <ErrorMessageConsole
                      error={apiError}
                      consoleMessage={apiError}
                    />
                  </div>
                </>
              </PortalModal>
            </>
          );
        }}
      </Formik>
    </div>
  );
};

CreateOrganizationModal.defaultProps = {
  open: false
};

CreateOrganizationModal.propTypes = {
  handleCancel: PropTypes.func.isRequired,
  open: PropTypes.bool
};

export default CreateOrganizationModal;
