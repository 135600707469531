import React from 'react';
import { Formik } from 'formik';
import IntegrationsProvider from 'screens/Integrations/context/IntegrationsProvider';
import SimplotProvider from 'screens/Integrations/SimplotIntegration/context/Provider';
import BaseSimplotIntegration from '../BaseSimplotIntegration';
import { INTEGRATIONS } from '../../../helpers/constants';
import SubmissionSummaryProvider from '../../../SubmissionSummary/context/SubmissionSummaryProvider';

const SimplotParentProvider = () => {
  return (
    <IntegrationsProvider>
      <SimplotProvider>
        <Formik initialValues={{}}>
          {() => (
            <SubmissionSummaryProvider integrationType={INTEGRATIONS.simplot}>
              <BaseSimplotIntegration />
            </SubmissionSummaryProvider>
          )}
        </Formik>
      </SimplotProvider>
    </IntegrationsProvider>
  );
};

export default SimplotParentProvider;
