const formatBody = values => {
  const f = x => (x === '' || typeof x === 'undefined' ? null : x);
  const valuesToSave = {
    ...values,
    name: f(values.name),
    email: f(values.email),
    addressLineOne: f(values.addressLineOne),
    city: f(values.city),
    phone: f(values.phone),
    company: f(values.company),
    addressLineTwo: f(values.addressLineTwo),
    state: f(values.state),
    zipCode: f(values.zipCode),
    notes: f(values.notes)
  };
  return valuesToSave;
};

export default formatBody;
