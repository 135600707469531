import { definedCalAgGroups } from './products';

export const translateMethodsToCalAg = methodsInForm => {
  const method = definedCalAgGroups(methodsInForm[0]);
  switch (method) {
    case 'F':
      return 'Fumigation';
    case 'G':
      return 'Ground';
    case 'A':
      return 'Aerial';
    case 'O':
    default:
      return methodsInForm[0];
  }
};

const buildPURExtraDataForSubmit = (forms, counties) => {
  return {
    purExtraData: {
      forms: forms.map(form => {
        const method = translateMethodsToCalAg(
          form.applicationMethods.split(', ')
        );
        return {
          taskId: form.task.taskId,
          cropZoneId: form.task.cropZoneId,
          applicationMethod: method,
          applicator: form.applicatorType === 'pco' ? 2 : 1,
          ...(form.applicatorType === 'pco'
            ? {
                applicatorLicenseNumber: form.pcoNumber,
                applicatorLicenseType: form.pcoType,
                applicatorName: form.pco.name,
                applicatorType: form.pco.type,
                applicatorId: form.pco.id
              }
            : {}),
          ...(method === 'Fumigation'
            ? {
                fumigationCode: form.fumigationCode
              }
            : {})
        };
      }),
      counties
    }
  };
};

export default buildPURExtraDataForSubmit;
