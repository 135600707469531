import React, { useContext } from 'react';
import { StorageLocationContext } from 'screens/YieldV2/StorageLocations/context/StorageLocationProvider';
import StorageRound from './components/Round';
import StorageFlat from './components/Flat';
import StorageNoShape from './components/NoShape';

const formats = {
  Round: <StorageRound />,
  Flat: <StorageFlat />
};

const StorageDimensions = () => {
  const {
    state: { selectedId, data = [] }
  } = useContext(StorageLocationContext);
  const selected = data.find(item => item.id === selectedId);
  if (!selected?.shape) return <StorageNoShape />;
  return <>{formats[selected?.shape] || <StorageNoShape />}</>;
};

export default StorageDimensions;
