import React from 'react';
import PropTypes from 'prop-types';
import { Row, Col } from 'antd';
import formatNumber from 'helpers/formatNumber';
import CropIconMapping from 'components/CropIconMapping';

export const renderCropLegendRows = ({ crops, cropColors, size = 'small' }) => {
  const isSmall = size === 'small';
  const fontSize = isSmall ? '8px' : '12px';
  const lineHeight = isSmall ? '14px' : '20px';
  const rowClasses = isSmall ? 'h-14px' : 'h-6 pb-1 border-b border-neutral-20';
  return crops.map((crop, i) => (
    <Row
      key={crop.id}
      className={`${i > 0 ? 'mt-1' : ''} ${rowClasses}`}
      align="middle"
      gutter={2}
    >
      <Col>
        <div
          className={`w-${lineHeight} h-${lineHeight}`}
          style={{ background: cropColors[crop.id] }}
        />
      </Col>
      <Col>
        <CropIconMapping size={12} cropObject={crop} />
      </Col>
      <Col flex="auto" style={{ fontSize, lineHeight }}>
        {crop.name}
      </Col>
      <Col style={{ fontSize, lineHeight }}>
        {formatNumber(crop.area, 'ac')}
      </Col>
    </Row>
  ));
};

const CropLegend = ({ crops, cropColors, show, style, limit }) => {
  if (!show) return null;

  const limitedCrops = limit ? crops.slice(0, limit) : crops;

  return (
    <div
      className="absolute w-200px z-2 p-2 pb-4 bg-white border-1/2 border-neutral-60"
      style={style}
    >
      <Row
        className="mb-2"
        justify="center"
        style={{
          fontSize: 10,
          lineHeight: '16px',
          fontWeight: 500
        }}
      >
        Map Legend
      </Row>
      {renderCropLegendRows({ crops: limitedCrops, cropColors })}
    </div>
  );
};

CropLegend.propTypes = {
  crops: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
      area: PropTypes.number.isRequired
    })
  ).isRequired,
  cropColors: PropTypes.objectOf(PropTypes.string).isRequired,
  show: PropTypes.bool,

  style: PropTypes.objectOf(),
  limit: PropTypes.number
};

CropLegend.defaultProps = {
  show: true,

  style: {},
  limit: 0
};

export default CropLegend;
