import { get } from 'lodash';

import getFeatureConfig from 'utilities/getFeatureConfig';

const FEATURE_FLAG_CACHING_CATEGORY = 'caching';

const featureConfig = getFeatureConfig();

// lookup the feature flags for the specified tableName
const cachingFeatureIsDisabled = tableName => {
  // check if overall caching category is disabled in feature flags
  if (get(featureConfig, FEATURE_FLAG_CACHING_CATEGORY) === 'disabled') {
    return true;
  }
  // check if caching is disabled in feature flags for the specified tableName
  return (
    get(featureConfig, `${FEATURE_FLAG_CACHING_CATEGORY}.${tableName}`) ===
    'disabled'
  );
};

// returns the submitted tableName only if caching has not been disabled in feature flags
const getEnabledCachingTable = (
  tableName,
  tableIsDependentOnNonCachingResource
) => {
  if (tableIsDependentOnNonCachingResource) {
    return {
      clearTableOnChange: tableName
    };
  }
  return {
    table: cachingFeatureIsDisabled(tableName) ? null : tableName
  };
};

const resourceLookup = {
  organizations: getEnabledCachingTable('organization'),
  organization: getEnabledCachingTable('organization', true),
  cropseasons: getEnabledCachingTable('cropseason'),
  cropseason: getEnabledCachingTable('cropseason', true)
};

export default resourceLookup;
