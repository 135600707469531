import React from 'react';

import { Spinner } from '@agconnections/grow-ui';
import { PropTypes } from 'prop-types';
import PortalModal from '../PortalModal';

const LoadingModal = ({ isLoading, message, 'data-testid': dataTestId }) => {
  return (
    <PortalModal
      open={isLoading}
      type="buttonless"
      style={{ paddingLeft: '2rem' }}
    >
      <div
        className="flex flex-line w-full mt-2 justify-between"
        data-testid={dataTestId}
      >
        <div className="pr-12 pb-10 w-full h-40">
          <Spinner />
          <h3 className="font-bold text-lg text-gray-900 text-center">
            {message}
          </h3>
        </div>
      </div>
    </PortalModal>
  );
};

LoadingModal.defaultProps = {
  isLoading: false,
  message: 'Loading...',
  'data-testid': 'loading-modal'
};

LoadingModal.propTypes = {
  isLoading: PropTypes.bool,
  message: PropTypes.string,
  'data-testid': PropTypes.string
};

export default LoadingModal;
