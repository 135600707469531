import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { Table } from '@agconnections/grow-ui';
import { generate } from 'shortid';
import {
  getFieldSortDir,
  pickDir
} from '../../../../screens/Plans/helpers/columnHelpers';

const TableHeader = ({
  sortBy,
  sortDir,
  simpleSortMode,
  onSort,
  tableColumns
}) => {
  const getSortDir = useCallback(
    field => getFieldSortDir(field, sortBy, sortDir),
    [sortBy, sortDir]
  );

  const handleSort = field => () => {
    if (onSort) {
      if (simpleSortMode) {
        onSort(field, sortBy !== field || sortDir === 'asc' ? 'desc' : 'asc');
      } else {
        const fieldDir = getFieldSortDir(field, sortBy, sortDir);
        onSort(field, pickDir(fieldDir));
      }
    }
  };

  return (
    <Table.Header>
      {tableColumns.map(({ field, title, width, sortable, position }) => (
        <Table.Cell
          key={field || generate()}
          width={width}
          sortable={sortable}
          sort={getSortDir(field)}
          onClick={sortable && handleSort(field)}
          className={`${sortable ? '' : 'pointer-events-none'}`}
          backGround={position === 'right' ? 'number-content' : ''}
        >
          {title}
        </Table.Cell>
      ))}
    </Table.Header>
  );
};

TableHeader.propTypes = {
  sortBy: PropTypes.string,
  sortDir: PropTypes.string,
  onSort: PropTypes.func,
  simpleSortMode: PropTypes.bool,
  tableColumns: PropTypes.arrayOf(PropTypes.object).isRequired
};

TableHeader.defaultProps = {
  sortBy: null,
  sortDir: null,
  onSort: null,
  simpleSortMode: false
};

export default TableHeader;
