import React, { useState, useEffect } from 'react';
import { Input, Select } from '@agconnections/grow-ui';
import { FieldArray, useFormikContext } from 'formik';
import PropTypes from 'prop-types';
import {
  calculateProductValues,
  getCompatibleUnits,
  roundValue
} from 'helpers/unitConversionHelpers'; // include for unit conversions
import PortalModal from 'components/Modals/PortalModal';
import GrowUIFormField from 'components/Forms/GrowUIFormField';
import useProduct from 'hooks/useProduct';
import {
  calcTotalAcres,
  validateValue
} from 'components/ProductSideBar/helpers/productHelper';
import {
  APPLICATION_METHODS,
  APPLICATION_OPTIONS
} from 'components/ProductSideBar/helpers/constants';
import { getCorrectUnitName } from 'helpers/unitsAbbreviation';
import ApplicationOptionField from './components/ApplicationOptionField';
import TargetPestField from './components/TargetPestField';

const initialProductValues = values => ({
  ...values,
  totalProductValue: values?.totalProductValue || 0,
  ratePerAreaValue: values?.ratePerAreaValue || 0,
  ratePerAreaUnit: values?.ratePerAreaUnit || values?.stdpackageunit || '',
  ratePerTankValue: values?.ratePerTankValue || 0,
  ratePerTankUnit: values?.ratePerTankUnit || values?.stdpackageunit || '',
  productRateValue: values?.ratePerAreaValue || values?.ratePerTankValue || 0,
  productRateUnit: values?.ratePerAreaUnit || values?.stdunit || '',
  totalProductUnit: values?.totalProductUnit || values?.stdpackageunit || '',
  areaValue: values?.appliedAreaValue || values?.area || values?.areaValue || 0,
  areaUnit: 'acre',
  appliedAreaValue: values?.totalAreaValue || 0,
  coveragePercent: 100,
  appliedAreaUnit: 'acre',
  averagePriceUnitAtTimeOfCreation: '',
  averagePriceAtTimeOfCreation: 0,
  totalCostAtTimeOfCreation: 0,
  specificCostValue: values?.specificCostValue || 0,
  specificCostPerUnit: 0,
  specificCostUnit: values?.specificCostUnit || values?.stdpackageunit,
  applicationMethod: values?.applicationMethod || '',
  targetPest: values?.targetPest,
  targetPestId: values?.targetPestId,
  productDensity:
    values?.productDensity || values?.density || values.stdfactor || 0,
  shareOwnerInformation: {
    percentGrowerResponsibility: 100,
    totalFromGrowerInventoryQuantity: 100,
    totalFromGrowerInventoryUnit: ''
  },
  totalProductCost: values?.totalProductCost || 0,
  stdfactor: values?.stdfactor || 1,
  stdpackageunit: values?.stdpackageunit || '',
  stdunit: values?.stdunit || values?.ratePerAreaUnit || ''
});

const applicationOptions = Object.values(APPLICATION_OPTIONS);

const AddProductRecModal = ({ open, onClose, product, isEdit, prodIndex }) => {
  const { values, setFieldValue, setFieldTouched } = useFormikContext();
  const { getMasterListProductUnits, getProductUnits } = useProduct();
  const [appliedAreaChanged, setAppliedAreaChanged] = useState(false);
  const [appliedPercentageChange, setAppliedPercentageChange] = useState(false);
  const [stdPackageUnitDDL, setStdPackageUnitDDL] = useState([]);
  const [productToAdd, setProductToAdd] = useState(
    initialProductValues({
      ...{},
      applicationMethod: '',
      totalAreaValue: values?.totalAreaValue
    })
  );

  const modalType = applicationOptions.find(
    option => option.key === values.applicationStrategy
  );

  const handleClose = () => {
    setStdPackageUnitDDL([]);
    setProductToAdd(initialProductValues({}));
    onClose();
  };

  const calcPercentAcres = (totalAcres, changeAcres) => {
    let newAcres = 0;
    if (Number.parseFloat(totalAcres) > 0) {
      newAcres =
        (Number.parseFloat(changeAcres) / Number.parseFloat(totalAcres)) * 100;
    }

    return newAcres;
  };

  const calcProductValues = async (
    valueChanged,
    targetValue,
    changedUnits,
    changedAppArea
  ) => {
    const newProductToAdd = calculateProductValues(
      valueChanged,
      targetValue,
      changedUnits,
      changedAppArea,
      undefined,
      productToAdd,
      values
    );

    setProductToAdd(newProductToAdd);
  };

  const calcValuesAcresChange = (validatedValue, propertyChanged) => {
    const areaChanged = propertyChanged === 'area';

    const newAppliedPct = areaChanged
      ? roundValue(calcPercentAcres(values?.totalAreaValue, validatedValue))
      : validatedValue;

    const newAppliedArea = !areaChanged
      ? roundValue(calcTotalAcres(values?.totalAreaValue, validatedValue))
      : validatedValue;

    const acresChangedValues = {
      changedPct: newAppliedPct,
      changedArea: newAppliedArea
    };

    calcProductValues(
      modalType.value,
      productToAdd?.[modalType.value],
      {},
      acresChangedValues
    );
  };
  const productUnitChange = async (unitChanged, unit) => {
    const units = {
      rateUnit:
        modalType.key === APPLICATION_OPTIONS.byRatePerArea.key
          ? unit
          : productToAdd?.ratePerAreaUnit,
      tankUnit:
        modalType.key === APPLICATION_OPTIONS.byRatePerTank.key
          ? unit
          : productToAdd?.ratePerTankUnit,
      totalProdUnit:
        modalType.key === APPLICATION_OPTIONS.byTotalProduct.key
          ? unit
          : productToAdd?.totalProductUnit
    };
    calcProductValues(modalType.value, productToAdd?.[modalType.value], units);
  };

  const setProductValues = productValues => {
    const newProduct = initialProductValues(productValues);
    newProduct.specificCostUnit = productValues.totalProductUnit;
    newProduct.stdpackageunit = productValues.totalProductUnit;

    return newProduct;
  };

  useEffect(() => {
    if (open) {
      if (!product?.custom) {
        getMasterListProductUnits(product?.id).then(setStdPackageUnitDDL);
      } else {
        getProductUnits(product?.id).then(setStdPackageUnitDDL);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open, product]);

  useEffect(() => {
    const mappedProduct = isEdit
      ? setProductValues({
          ...product,
          totalAreaValue: values?.totalAreaValue
        })
      : initialProductValues({
          ...product,
          applicationMethod: '',
          totalAreaValue: values?.totalAreaValue
        });
    setProductToAdd(mappedProduct);
    if (isEdit) {
      setFieldValue('productUnit', product?.[modalType.unit]);
      setFieldValue('applicationMethod', product?.applicationMethod);
    } else {
      setFieldValue('productUnit', product?.stdunit);
      setFieldValue('applicationMethod', '');
      const { stdunit, stdpackageunit, density } = product;
      setStdPackageUnitDDL(
        getCompatibleUnits([stdunit, stdpackageunit], density)
      );
    }
    setAppliedAreaChanged(false);
    setAppliedPercentageChange(false);
  }, [
    open,
    isEdit,
    product,
    values?.totalAreaValue,
    modalType.unit,
    setFieldValue
  ]);

  return (
    <>
      <FieldArray name="products">
        {({ push }) => (
          <PortalModal
            open={open}
            close={onClose}
            onCancel={handleClose}
            onConfirm={() => {
              setFieldTouched('Add Product');
              if (!isEdit) {
                push({
                  ...productToAdd,
                  productName: product.name,
                  manufacturerName: product.manufacturer,
                  productId: product.id
                });
              } else {
                values.products[prodIndex] = {
                  ...productToAdd,
                  productName: product.name,
                  manufacturerName: product.manufacturer,
                  productId: product.id
                };
              }
              handleClose();
            }}
          >
            <div className="flex items-center justify-between">
              <div className="font-bold text-lg" data-testid="product-name">
                {product.name}
              </div>
            </div>
            <div className="flex items-center justify-between">
              <div
                className="text-sm text-neutral-300"
                data-testid="manufacture"
              >
                {product.manufacturer}
              </div>
            </div>
            <div className="flex mt-8">
              {/* eslint-disable no-nested-ternary */}
              {modalType.key === APPLICATION_OPTIONS.byRatePerArea.key ? (
                <ApplicationOptionField
                  applicationOption={APPLICATION_OPTIONS.byTotalProduct}
                  product={productToAdd}
                />
              ) : modalType.key === APPLICATION_OPTIONS.byTotalProduct.key ? (
                <ApplicationOptionField
                  applicationOption={APPLICATION_OPTIONS.byRatePerArea}
                  product={productToAdd}
                />
              ) : modalType.key === APPLICATION_OPTIONS.byRatePerTank.key ? (
                <>
                  <ApplicationOptionField
                    className="mr-6"
                    applicationOption={APPLICATION_OPTIONS.byRatePerTank}
                    product={productToAdd}
                  />
                  <ApplicationOptionField
                    applicationOption={APPLICATION_OPTIONS.byTotalProduct}
                    product={productToAdd}
                  />
                </>
              ) : null}
            </div>
            <div className="mt-12">
              <div className="grid grid-cols-3 gap-6 mb-12">
                <div className="my-2">
                  <GrowUIFormField
                    control={Input}
                    label={modalType.title}
                    name={modalType.value}
                    value={productToAdd?.[modalType.value]}
                    onFocus={e => e.target.select()}
                    onChange={e => {
                      calcProductValues(modalType.value, e.target.value);
                    }}
                  />
                </div>
                <div className="my-2">
                  <GrowUIFormField
                    style={{ whiteSpace: 'nowrap' }}
                    control={Select}
                    label="Unit"
                    name="productUnit"
                    value={getCorrectUnitName(productToAdd?.[modalType.unit])}
                    items={stdPackageUnitDDL}
                    onChange={e => {
                      productUnitChange(
                        productToAdd?.[modalType.unit],
                        JSON.parse(e.target.value).key
                      );
                    }}
                  />
                </div>
                <div className="my-2">
                  <GrowUIFormField
                    control={Input}
                    label="Applied Area"
                    name="productAppliedArea"
                    value={productToAdd?.appliedAreaValue}
                    onChange={e => {
                      if (!appliedPercentageChange) {
                        const validatedValue = validateValue(
                          e.target.value,
                          values?.totalAreaValue
                        );
                        calcValuesAcresChange(validatedValue, 'area');
                      }
                    }}
                    onFocus={e => {
                      e.target.select();
                      setAppliedAreaChanged(true);
                    }}
                    onBlur={() => setAppliedAreaChanged(false)}
                    imask={{
                      mask: 'num \\ac',
                      lazy: false,
                      blocks: {
                        num: {
                          mask: Number,
                          radix: '.',
                          mapToRadix: [','],
                          thousandsSeparator: ','
                        }
                      }
                    }}
                  />
                </div>
                <div className="my-2">
                  <GrowUIFormField
                    control={Input}
                    label="Applied Area %"
                    name="productAppliedAreaPercent"
                    value={productToAdd?.coveragePercent}
                    onChange={e => {
                      if (e.target.value) {
                        if (!appliedAreaChanged) {
                          const validatedValue = validateValue(
                            e.target.value,
                            100
                          );
                          calcValuesAcresChange(validatedValue, 'pct');
                        }
                      }
                    }}
                    onFocus={e => {
                      e.target.select();
                      setAppliedPercentageChange(true);
                    }}
                    onBlur={() => setAppliedPercentageChange(false)}
                    imask={{
                      mask: 'num%',
                      lazy: false,
                      blocks: {
                        num: {
                          mask: Number,
                          radix: '.',
                          mapToRadix: [','],
                          thousandsSeparator: ','
                        }
                      }
                    }}
                  />
                </div>
                <div className="my-2">
                  <GrowUIFormField
                    control={Select}
                    label="Application Method"
                    name="applicationMethod"
                    items={APPLICATION_METHODS}
                    onChange={e => {
                      setProductToAdd({
                        ...productToAdd,
                        applicationMethod: JSON.parse(e.target.value).key
                      });
                    }}
                  />
                </div>
                <div className="-mt-3">
                  <TargetPestField
                    pest={product.targetPest}
                    onSelectPest={pest => {
                      setProductToAdd({
                        ...productToAdd,
                        targetPestId: pest.pestid,
                        targetPest: {
                          latinnames: pest.latinnames,
                          name: pest.name,
                          nameid: pest.nameid,
                          pestid: pest.pestid
                        }
                      });
                    }}
                  />
                </div>
              </div>
            </div>
          </PortalModal>
        )}
      </FieldArray>
    </>
  );
};

AddProductRecModal.defaultProps = {
  prodIndex: 0
};

AddProductRecModal.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  product: PropTypes.object.isRequired,
  isEdit: PropTypes.bool.isRequired,
  prodIndex: PropTypes.number
};

export default AddProductRecModal;
