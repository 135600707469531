/* eslint-disable no-param-reassign */
import _ from 'lodash';
import { LOAD_TYPES } from 'screens/YieldV2/Loads/constants';

const validateSource = (_tabs, sourceType) => {
  const cloneTabs = _.cloneDeep(_tabs);
  if (sourceType === LOAD_TYPES.CROP_ZONE) {
    return cloneTabs;
  }
  if (sourceType === LOAD_TYPES.STORAGE) {
    return cloneTabs;
  }
  if (sourceType === LOAD_TYPES.SALE) {
    return cloneTabs;
  }
  return _tabs;
};

export default validateSource;
