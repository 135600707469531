import { DEFAULT_TASK_VALUES } from 'helpers/constants';
import { defaultProducts } from 'helpers/transformHelpers';
import { getPlanProperties } from '../../helpers/dataHelpers';

export const mapProperties = (
  cropZones,
  propertyLandingPageData,
  fieldsAndAreasGeoJSONCollection,
  values
) => {
  const mappedProperties = cropZones.map(cropZone => {
    const planProps = getPlanProperties(
      propertyLandingPageData,
      [cropZone.cropZoneIdValue],
      fieldsAndAreasGeoJSONCollection,
      values
    );
    return {
      areaType: cropZone?.boundaryAreaUnit || '',
      areaUnit: 'acres',
      areaValue: cropZone?.areaValue || '',
      boundaryArea: cropZone?.boundaryArea || '',
      coveragePct: 100,
      crop: {
        id: cropZone?.cropId || '',
        name: ''
      },
      cropZoneId: cropZone?.cropZoneIdValue || '',
      farmId: planProps[0]?.farmId || '',
      name: cropZone?.name || '',
      reportedArea: cropZone?.reportedArea || ''
    };
  });

  return mappedProperties;
};

export const mapPlanToTask = (
  plan,
  productsToInclude,
  propertyLandingPageData,
  fieldsAndAreasGeoJSONCollection
) => {
  const newTask = { ...DEFAULT_TASK_VALUES };
  const products = productsToInclude.map(product => {
    return defaultProducts(product);
  });

  newTask.products = products;
  newTask.properties =
    mapProperties(
      plan?.cropZones,
      propertyLandingPageData,
      fieldsAndAreasGeoJSONCollection,
      plan
    ) || [];
  newTask.totalApplied = plan?.cropZones.reduce((ta, { areaValue }) => {
    return ta + areaValue;
  }, 0);
  newTask.totalAreaValue = newTask.totalApplied;
  newTask.cropSeasonIds = plan.cropSeasonIds.reduce((ids, currId) => {
    return `${ids}, ${currId}`;
  });
  newTask.source = plan.id;

  return newTask;
};
