/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useMemo, useEffect } from 'react';
import {
  Input,
  Table,
  RadioButtonGroup,
  Select,
  SimpleModal,
  Spinner,
  Toast,
  Form
} from '@agconnections/grow-ui';
import { useFormikContext, FieldArray } from 'formik';
import PropTypes from 'prop-types';
import { generate } from 'shortid';
import GrowUIFormField from 'components/Forms/GrowUIFormField';
import FieldIcon from 'components/Icons/FieldIcon';
import MapIllustration from 'assets/properties-map-icon.svg';
import { mappingShape } from 'screens/Property/helpers/propertyDataHelpers';
import usePropertyData from 'hooks/usePropertyData';
import { adjustZoneAcreValues } from 'utilities/helpers';
import usePlanProducts from 'screens/Plans/hooks/usePlanProducts';

import EllipseMenu from 'components/EllipseMenu';
import { addSelectedCropSeasonsToFarms } from 'helpers/propertyHelpers';
import { filterArchived, getArchivedCropZones } from 'helpers/archivedHelpers';
import { PLANS_PROPERTIES_TAB_OPTIONS } from 'utilities/menus';
import {
  roundPercentToArea,
  roundAreaToPercent
} from '../../../../Tasks/Task/Tabs/Properties/helpers';
import ChecklistProperty from '../../../../CropSeasons/CropSeason/components/FormSection/GroupByFilterProperty';
import ChecklistCrop from '../../../../CropSeasons/CropSeason/components/FormSection/GroupByFilterCrop';

const Properties = ({
  propertyLandingPageData,
  loading,
  fieldsAndAreasGeoJSONCollection,
  selectedCropSeasonIds
}) => {
  const {
    values,
    setFieldValue,
    setFieldTouched,
    touched
  } = useFormikContext();
  const { recalculateProducts, recalculateCosts } = usePlanProducts({
    values,
    setFieldValue
  });
  const [selectedCropzoneIds, setSelectedCropzoneIds] = useState(
    values?.cropZones?.map(cz => cz?.cropZoneIdValue)
  );
  const [tabPropertyGroupByValue, setTabPropertyGroupByValue] = useState(
    values?.propertiesGroupBy ? values?.propertiesGroupBy : 'property'
  );
  const [toastRenderContents, setToastRenderContents] = useState(null);
  const [toastHasNotBeenClosed, setToastHasNotBeenClosed] = useState(true);
  const [editingProperty, setEditingProperty] = useState(null);
  const { RadioButton } = RadioButtonGroup;
  const [noCropSeason, setNoCropSeason] = useState(false);
  const [initialCropzoneIds] = useState(
    values.cropZones?.map(cz => cz.cropZoneIdValue)
  );
  const [archivedCropZones] = useState(
    getArchivedCropZones(propertyLandingPageData)
  );
  const [filteredByArchivedPropertyLandingPageData] = useState(
    filterArchived(propertyLandingPageData, initialCropzoneIds)
  );
  const [originalCropzonesValues] = useState(values.cropZones);

  const { originalTotalArea, setProperties } = usePropertyData();

  const cropsLoading = false;
  const properties = addSelectedCropSeasonsToFarms(
    filteredByArchivedPropertyLandingPageData,
    selectedCropSeasonIds
  );
  const checkForFFTChanges = () => {
    originalCropzonesValues.length !== values.cropZones.length &&
      setFieldTouched('FFT Changed');
  };
  const selectedCropzones = useMemo(() => {
    const selectedCropzoneData = [];
    if (properties?.length > 0) {
      /* eslint no-unused-expressions: "off", curly: "error" */
      properties?.forEach(property => {
        if (property?.fields?.length > 0) {
          /* eslint no-unused-expressions: "off", curly: "error" */
          property?.fields.forEach(field => {
            /* eslint no-unused-expressions: "off", curly: "error" */
            field?.cropzones.forEach(cz => {
              if (selectedCropzoneIds?.includes(cz.id)) {
                selectedCropzoneData.push({
                  ...cz,
                  farmId: property.id,
                  fieldName: field.name,
                  features: fieldsAndAreasGeoJSONCollection.features?.filter(
                    feature => field.id === feature.properties.id
                  )
                });
              }
            });
          });
        }
      });
    }
    return selectedCropzoneData;
  }, [propertyLandingPageData, selectedCropzoneIds]);

  useEffect(() => {
    if (
      selectedCropSeasonIds === undefined ||
      selectedCropSeasonIds.length === 0
    ) {
      setNoCropSeason(true);
      setToastRenderContents(
        'Please select a cropseason on the Details Tab before selecting a property.'
      );
      setToastHasNotBeenClosed(true);
    }
  }, []);

  useEffect(() => {
    // this entire function needs to be simplified and testable
    const sanitizedArray = [];
    const czIds = selectedCropzones.map(cz => cz.id);
    const existingProperties = values.cropZones.filter(property =>
      czIds.includes(property.cropZoneIdValue)
    );
    // eslint-disable-next-line no-unused-vars
    const existingPropertyIds = existingProperties.map(
      property => property.cropZoneIdValue
    );

    selectedCropzones.forEach(cropzone => {
      const savedAreaValue = existingProperties.find(
        prop => prop.cropZoneIdValue === cropzone.id
      )?.areaValue;
      const reportedOrBoundaryArea =
        cropzone.reportedArea > 0
          ? cropzone.reportedArea
          : cropzone.boundaryArea;
      const areaValueToUse = savedAreaValue || reportedOrBoundaryArea;
      const obj = {
        farmId: cropzone.farmId,
        cropZoneIdValue: cropzone.id,
        areaUnit: 'acres',
        originalAcres:
          cropzone.areaValue ||
          Math.round((reportedOrBoundaryArea + Number.EPSILON) * 100) / 100,
        areaValue:
          cropzone.areaValue ||
          Math.round((areaValueToUse + Number.EPSILON) * 100) / 100,
        coveragePct:
          cropzone.coveragePct ||
          Math.round((areaValueToUse / reportedOrBoundaryArea) * 10000) / 100,
        boundaryArea:
          Math.round((cropzone.boundaryArea + Number.EPSILON) * 100) / 100,
        reportedArea:
          Math.round((cropzone.reportedArea + Number.EPSILON) * 100) / 100,
        areaType: cropzone.areaType || 'reported',
        name: cropzone.name,
        crop: cropzone.crop,
        features: cropzone.features,
        fieldName: cropzone.fieldName
      };
      sanitizedArray.push(obj);
    });
    setFieldValue('cropZones', [...sanitizedArray]);
    setProperties([...sanitizedArray]);
  }, [selectedCropzones, setFieldValue]);

  const getTotalArea = () =>
    values.cropZones.reduce((acc, curr) => {
      return acc + curr.areaValue;
    }, 0);

  useEffect(() => {
    checkForFFTChanges();
    const totalArea = getTotalArea();
    setFieldValue(
      'totalApplied',
      Math.round((totalArea + Number.EPSILON) * 100) / 100
    );
    setFieldValue(
      'totalAreaValue',
      Math.round((totalArea + Number.EPSILON) * 100) / 100
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values.cropZones]);

  useEffect(() => {
    setFieldValue('propertiesGroupBy', 'property');
  }, []);

  const handlePropertySelect = cropzones => {
    setSelectedCropzoneIds(cropzones);
    if (cropzones.length === 0) {
      setFieldValue('totalApplied', 0);
      setFieldValue('totalAreaValue', 0);
      setFieldValue('coveragePercent', 100);
    }
  };

  const handleChange = e => {
    const dropDownValue = JSON.parse(e.target.value);
    setTabPropertyGroupByValue(dropDownValue.key);
    setFieldValue('propertiesGroupBy', dropDownValue.key);
  };

  const recalculate = newArea => {
    setFieldValue('area', newArea);
    setFieldValue('estimatedAreaValue', newArea);
    recalculateProducts(values.planProductEntries, newArea).then(
      productsUpdated => {
        setFieldValue('planProductEntries', productsUpdated);
      }
    );

    recalculateCosts(newArea);
  };

  useEffect(() => {
    if (!originalTotalArea) {
      return;
    }
    const newPercent = roundAreaToPercent(
      values.totalApplied,
      originalTotalArea
    );

    if (
      newPercent !== Number(values.coveragePercent) &&
      !Number.isNaN(newPercent) &&
      Number.isFinite(newPercent)
    ) {
      setFieldValue('coveragePercent', newPercent);
    }

    if (!touched.estimatedAreaValue && values.totalApplied > 1) {
      recalculate(values.totalApplied);
    } else if (!touched.estimatedAreaValue) {
      recalculate(1);
    }
  }, [values.totalApplied]);

  const handleAction = (action, property) => {
    const actions = {
      edit: () => setEditingProperty(property),
      remove: () =>
        handlePropertySelect(map =>
          map.filter(item => item !== property?.cropZoneIdValue)
        )
    };
    actions[action]();
  };

  return (
    <div className="-mx-2 -mr-6" data-testid="properties-tab">
      <div className="flex justify-end mb-6">
        <div className="mr-6">
          {toastHasNotBeenClosed ? (
            <Toast
              icon="error"
              onClose={() => {
                setToastHasNotBeenClosed(false);
              }}
            >
              {toastRenderContents}
            </Toast>
          ) : null}
          <GrowUIFormField
            label="Total Applied"
            control={Input}
            name="totalApplied"
            disabled={
              values.cropZones.length === 0 || values.coveragePercent === 0
            }
            onBlur={e => {
              setFieldTouched('totalApplied');
              const totalApplied = Number(e.target.value);
              setFieldValue('totalApplied', totalApplied);
              if (totalApplied !== Number(e.target.defaultValue)) {
                const newPercent = roundAreaToPercent(
                  totalApplied,
                  originalTotalArea
                );

                setFieldValue(
                  'cropZones',
                  adjustZoneAcreValues(
                    newPercent,
                    roundAreaToPercent(
                      Number(e.target.defaultValue),
                      originalTotalArea
                    ),
                    values.cropZones
                  )
                );
                setFieldValue('coveragePercent', newPercent);
              }
            }}
            imask={{
              mask: 'num',
              lazy: false,
              blocks: {
                num: {
                  mask: Number,
                  scale: 2,
                  signed: false,
                  thousandsSeparator: '',
                  padFractionalZeros: false,
                  normalizeZeros: true,
                  radix: '.',
                  mapToRadix: [','],
                  min: 0
                }
              }
            }}
          />
        </div>
        <GrowUIFormField
          label="Coverage %"
          control={Input}
          name="coveragePercent"
          disabled={values.cropZones.length === 0}
          onBlur={e => {
            setFieldTouched('coveragePercent');
            setFieldValue('coveragePercent', Number(e.target.value));
            if (Number(e.target.value) !== Number(e.target.defaultValue)) {
              setFieldValue(
                'cropZones',
                adjustZoneAcreValues(
                  Number(e.target.value),
                  Number(e.target.defaultValue),
                  values.cropZones
                )
              );
            }
          }}
          imask={{
            mask: 'num',
            lazy: false,
            blocks: {
              num: {
                mask: Number,
                scale: 2,
                signed: false,
                thousandsSeparator: '',
                padFractionalZeros: false,
                normalizeZeros: false,
                radix: '.',
                mapToRadix: [','],
                min: 0
              }
            }
          }}
        />
      </div>
      <div className="flex">
        <div className="w-1/4 pr-8">
          <div className="mb-3">
            <div className="mb-4 text-sm font-semibold uppercase">
              Properties
            </div>
            <Form.Field label="Properties" />
            {!loading ? (
              <>
                <GrowUIFormField
                  control={Select}
                  name="propertiesGroupBy"
                  value={tabPropertyGroupByValue}
                  items={[
                    { key: 'property', value: 'Group By Property' },
                    { key: 'crop', value: 'Group By Crop' }
                  ]}
                  onChange={e => {
                    handleChange(e);
                  }}
                />

                {tabPropertyGroupByValue === 'property' ? (
                  <ChecklistProperty
                    data={properties}
                    onChange={handlePropertySelect}
                    value={values.cropZones}
                    cropZonesChecked={selectedCropzoneIds}
                    setCropZonesChecked={setSelectedCropzoneIds}
                    disabled={cropsLoading || noCropSeason}
                  />
                ) : (
                  <ChecklistCrop
                    data={properties}
                    onChange={handlePropertySelect}
                    value={values.cropZones}
                    cropZonesChecked={selectedCropzoneIds}
                    setCropZonesChecked={setSelectedCropzoneIds}
                    disabled={cropsLoading || noCropSeason}
                  />
                )}
              </>
            ) : (
              <Spinner />
            )}
          </div>
        </div>
        <div className="flex flex-col w-3/4">
          <div className="flex flex-col h-full">
            <Table>
              <Table.Header>
                <Table.Cell width="30%">
                  Fields ({values?.cropZones?.length})
                </Table.Cell>
                <Table.Cell>Crops</Table.Cell>
                <Table.Cell>Area</Table.Cell>
                <Table.Cell>Coverage %</Table.Cell>
                <Table.Cell />
              </Table.Header>
              {values.cropZones?.map(property => {
                const ft = property?.features?.[0]
                  ? [mappingShape(property.features[0])]
                  : undefined;

                const isPropertyArchived =
                  archivedCropZones.indexOf(property.cropZoneIdValue) !== -1;

                return (
                  <Table.Row
                    key={`property-row-${property?.farmId || generate()}`}
                  >
                    <Table.Cell>
                      <div className="flex items-center">
                        <FieldIcon id="field-card-icon" features={ft} />
                        <div className="flex flex-col justify-start leading-5 text-left">
                          <span className="font-bold text-neutral-1000">
                            {property?.fieldName}
                            {isPropertyArchived ? ' (archived) ' : ''}
                          </span>
                          <span style={{ color: '#707374' }}>
                            {property?.name}
                          </span>
                        </div>
                      </div>
                    </Table.Cell>
                    <Table.Cell>{property?.crop?.name}</Table.Cell>
                    <Table.Cell>
                      {property?.areaValue} {property?.areaUnit}
                    </Table.Cell>
                    <Table.Cell>{property?.coveragePct}</Table.Cell>
                    <Table.Cell>
                      <EllipseMenu
                        onAction={action => handleAction(action, property)}
                        options={PLANS_PROPERTIES_TAB_OPTIONS}
                      />
                    </Table.Cell>
                  </Table.Row>
                );
              })}
            </Table>
            {!values?.cropZones?.length && (
              <div className="flex flex-col items-center self-stretch justify-center h-full py-32 border border-t-0">
                <img src={MapIllustration} alt="No properties illustration" />
                <p>
                  Select one or more fields from the list to add it to this Plan
                </p>
              </div>
            )}
          </div>
        </div>
      </div>
      <>
        {editingProperty && (
          <FieldArray name="cropZones">
            {({ replace }) => (
              <SimpleModal
                confirmLabel="Save"
                open={editingProperty}
                onConfirm={() => {
                  replace(
                    values.cropZones
                      .map(property => property.cropZoneIdValue)
                      .indexOf(editingProperty?.cropZoneIdValue),
                    editingProperty
                  );
                  setEditingProperty(null);
                }}
                close={() => setEditingProperty(null)}
              >
                <div className="ml-6">
                  <div className="mb-6">
                    <div className="text-xl font-bold">
                      {editingProperty?.fieldName}
                    </div>
                    <span className="text-sm text-neutral-300">
                      {editingProperty?.name}
                    </span>
                  </div>
                  <div className="flex mb-6">
                    <div className="mr-6">
                      <span className="text-sm text-neutral-300">Area</span>
                      <div className="text-xl">
                        {editingProperty?.areaValue} ac
                      </div>
                    </div>
                    <div>
                      <span className="text-sm text-neutral-300">Crop</span>
                      <div className="text-xl ">
                        {editingProperty?.crop?.name}
                      </div>
                    </div>
                  </div>
                  <div className="mb-12">
                    <GrowUIFormField
                      control={RadioButtonGroup}
                      name="areaReported"
                      selected={editingProperty?.areaType}
                      onChange={e => {
                        const _area =
                          e === 'boundary'
                            ? editingProperty?.boundaryArea
                            : editingProperty?.reportedArea;
                        setEditingProperty({
                          ...editingProperty,
                          areaType: e,
                          areaValue: _area,
                          coveragePct: roundAreaToPercent(
                            _area,
                            editingProperty?.originalAcres
                          )
                        });
                      }}
                    >
                      <GrowUIFormField
                        value="reported"
                        id="reported"
                        name="areaReported"
                        control={RadioButton}
                      >
                        <span className="truncate w-48">
                          Reported Area ({editingProperty?.reportedArea} ac)
                        </span>
                      </GrowUIFormField>
                      <GrowUIFormField
                        value="boundary"
                        id="boundary"
                        name="areaReported"
                        control={RadioButton}
                      >
                        <span className="truncate w-48">
                          Boundary Area ({editingProperty?.boundaryArea} ac)
                        </span>
                      </GrowUIFormField>
                    </GrowUIFormField>
                  </div>
                  <div className="flex">
                    <div className="mr-4">
                      <GrowUIFormField
                        label="Area Applied"
                        control={Input}
                        value={editingProperty?.areaValue}
                        onBlur={e => {
                          const area = Number(e.target.value);
                          setEditingProperty({
                            ...editingProperty,
                            areaValue: area,
                            coveragePct: roundAreaToPercent(
                              area,
                              editingProperty?.originalAcres
                            )
                          });
                        }}
                        name="zoneAcres"
                        imask={{
                          mask: 'num',
                          lazy: false,
                          blocks: {
                            num: {
                              mask: Number,
                              signed: false,
                              thousandsSeparator: '',
                              padFractionalZeros: false,
                              normalizeZeros: true,
                              radix: '.',
                              mapToRadix: [','],
                              min: 0
                            }
                          }
                        }}
                      />
                    </div>
                    <GrowUIFormField
                      label="Coverage %"
                      control={Input}
                      name="zoneCoveragePercent"
                      value={editingProperty?.coveragePct}
                      onBlur={e => {
                        const coverage = Number(e.target.value);
                        setEditingProperty({
                          ...editingProperty,
                          coveragePct: coverage,
                          areaValue: roundPercentToArea(
                            coverage,
                            editingProperty?.originalAcres
                          )
                        });
                      }}
                      imask={{
                        mask: 'num',
                        lazy: false,
                        blocks: {
                          num: {
                            mask: Number,
                            signed: false,
                            thousandsSeparator: '',
                            padFractionalZeros: false,
                            normalizeZeros: false,
                            radix: '.',
                            mapToRadix: [','],
                            min: 0
                          }
                        }
                      }}
                    />
                  </div>
                </div>
              </SimpleModal>
            )}
          </FieldArray>
        )}
      </>
    </div>
  );
};

Properties.propTypes = {
  propertyLandingPageData: PropTypes.oneOfType([
    PropTypes.shape({
      orgId: PropTypes.string,
      properties: PropTypes.arrayOf(
        PropTypes.shape({
          map: PropTypes.func
        })
      )
    }).isRequired,
    PropTypes.arrayOf(PropTypes.array)
  ]).isRequired,
  loading: PropTypes.bool.isRequired,
  fieldsAndAreasGeoJSONCollection: PropTypes.shape({
    type: PropTypes.string.isRequired,
    features: PropTypes.arrayOf(
      PropTypes.shape({
        filter: PropTypes.func
      })
    )
  }).isRequired,
  selectedCropSeasonIds: PropTypes.arrayOf(PropTypes.string).isRequired
};

export default Properties;
