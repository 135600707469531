import { useState, useEffect, useCallback } from 'react';
import {
  documentDetails as documentDetailsApi,
  documentData as documentDataApi
} from 'utilities/api';
import catchCancel from 'helpers/catchCancel';
import atob from 'atob';

const useInventoryDocumentationData = prodId => {
  const [dataDocumentation, setDataDocumentation] = useState(null);
  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(true);

  const getDocumentationData = useCallback(productId => {
    const { promise } = documentDetailsApi.fetch(`${productId}`);
    promise
      .then(data => {
        setDataDocumentation(data.data);
        setIsLoading(false);
      })
      .catch(catchCancel)
      .catch(() => setIsLoading(false));
  }, []);

  const b64toBlob = dataURI => {
    const byteString = atob(dataURI);
    const ab = new ArrayBuffer(byteString.length);
    const ia = new Uint8Array(ab);

    // eslint-disable-next-line no-plusplus
    for (let i = 0; i < byteString.length; i++) {
      ia[i] = byteString.charCodeAt(i);
    }
    return new Blob([ab], { type: 'application/pdf' });
  };

  const openFile = async fileId => {
    const { promise } = documentDataApi.fetch(fileId);
    await promise
      .then(data => {
        const base64File = data.data.data;
        if (base64File) {
          const blob = b64toBlob(base64File);
          const blobUrl = URL.createObjectURL(blob);

          window.open(blobUrl);
        } else {
          setError(
            'We were unable to find any documentation for this product.'
          );
        }
      })
      .catch(catchCancel);
  };

  useEffect(() => {
    getDocumentationData(prodId);
  }, [prodId, getDocumentationData]);

  return { dataDocumentation, isLoading, openFile, error, setError };
};

export default useInventoryDocumentationData;
