import React, { memo } from 'react';
import PropTypes from 'prop-types';

const TrashIcon = ({ size, color }) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      data-testid="trash-icon"
      className={`fill-current text-${color}`}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.875 2.25H7.125L6.375 3H3.75V4.5H14.25V3H11.625L10.875 2.25ZM12 6.75V14.25H6V6.75H12ZM4.5 5.25H13.5V14.25C13.5 15.075 12.825 15.75 12 15.75H6C5.175 15.75 4.5 15.075 4.5 14.25V5.25Z"
      />
    </svg>
  );
};

TrashIcon.propTypes = {
  color: PropTypes.string,
  size: PropTypes.number
};

TrashIcon.defaultProps = {
  color: 'red-700',
  size: 20
};

export default memo(TrashIcon);
