import faker from 'faker';
import { Factory } from 'miragejs';
import globalSchema from '../utils/globalSchema';

const zeroOrMax = max =>
  faker.random.boolean() ? 0 : faker.random.number(max);

const Equipment = {
  name() {
    const truckMaker = faker.random.arrayElement([
      'Ford',
      'Chevy',
      'Toyota',
      'Dodge'
    ]);
    const tractorMaker = faker.random.arrayElement([
      'Case IH',
      'John Deere',
      'Fendt',
      'New Holland'
    ]);
    return faker.random.arrayElement([
      `${truckMaker} Truck`,
      `${tractorMaker} Tractor`
    ]);
  },
  valuations() {
    return Array.from({ length: faker.random.number(5) }, () => ({
      amount: faker.random.number({ min: 200, max: 5000 }),
      date: faker.date.past(10)
    }));
  },
  notes() {
    return faker.random.boolean() ? faker.lorem.paragraph() : '';
  },
  manufacturedYear() {
    return faker.date.past(5).getFullYear();
  },
  make() {
    return this.name.split(' ')[0];
  },
  model: '',
  modelYear() {
    return this.manufacturedYear;
  },
  serialNumber() {
    return faker.random.alphaNumeric(15);
  },
  licenseNumber() {
    return faker.random.alphaNumeric(15);
  },
  mileage() {
    return zeroOrMax(100000);
  },
  hours() {
    return zeroOrMax(100000);
  },
  unitID() {
    return zeroOrMax(100000).toString();
  },
  purchasePrice() {
    return zeroOrMax(100000);
  },
  purchaseDate() {
    return faker.date.past(5);
  },
  sellPrice() {
    return zeroOrMax(100000);
  },
  equipmentType() {
    const words = this.name.split(' ');
    return words[words.length - 1];
  },
  currentStatus() {
    return faker.random.arrayElement(['owned', 'leased']);
  },
  currentStatusDate() {
    return faker.date.recent();
  },
  operationCost() {
    return Number(faker.finance.amount(0, 20000, 2));
  },
  ...globalSchema
};

export default Factory.extend(Equipment);
