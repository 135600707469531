import React, { useState, useContext } from 'react';
import { Button } from '@agconnections/grow-ui';
import { Redirect } from 'react-router-dom';
import backgroundImage from 'assets/login-background.svg';
import cropwiseBrandLogo from 'assets/cropwise-financials-logo.svg';
import { useFlags } from 'launchdarkly-react-client-sdk';
import MaintenanceLandingComponent from './MaintenanceLandingComponent';
import { AmplitudeContext } from '../../utilities/amplitude/useAmplitude';

const Landing = () => {
  // const history = useHistory();
  const [redirect, setRedirect] = useState();
  const { events, sendEventToAmplitude } = useContext(AmplitudeContext);
  const {
    feLandManagementUndergoingMaintenanceMessaging: maintenanceLandingFF
  } = useFlags();

  return (
    <div>
      {redirect && <Redirect push to={`${redirect}`} />}
      <div>
        <img
          src={backgroundImage}
          alt=""
          className="fixed right-0 object-cover w-1/2 h-screen inset"
        />
        {/* <div className="fixed bottom-0 right-0">
          <div
            className="font-medium uppercase text-white mix-blend-normal"
            style={{
              font: 'rubik',
              fontSize: '210px',
              lineHeight: '137px',
              letterSpacing: '-13.83px',
              opacity: '0.2'
            }}
          >
            Wel
          </div>
          <div
            className="font-medium uppercase text-white mix-blend-normal"
            style={{
              font: 'rubik',
              fontSize: '210px',
              lineHeight: '137px',
              letterSpacing: '-13.83px',
              opacity: '0.2',
              paddingLeft: '9%',
              marginTop: '3%'
            }}
          >
            come
          </div>
        </div> */}
      </div>
      <div className="w-1/2 h-screen">
        <div className="flex justify-center items-center h-screen">
          <div className="relative flex flex-col items-center justify-center pl-6 md:items-start">
            <img
              src={cropwiseBrandLogo}
              alt="CropWise Logo"
              className="block mb-12 md:inline"
            />
            <div className="flex w-full justify-center">
              <div className="flex flex-wrap">
                <Button
                  type="primary"
                  disabled={maintenanceLandingFF !== ''}
                  onClick={() => {
                    // amplitude trigger
                    sendEventToAmplitude(events.epic.Authentication.signIn);
                    setRedirect('/login');
                  }}
                >
                  Sign In
                </Button>
                <div className="ml-4">
                  <Button
                    type="primary"
                    disabled={maintenanceLandingFF !== ''}
                    onClick={() =>
                      (window.location.href =
                        process.env.REACT_APP_CAMPAIGN_LINK)
                    }
                  >
                    Sign Up
                  </Button>
                </div>
              </div>
            </div>
            {maintenanceLandingFF && maintenanceLandingFF !== '' && (
              <MaintenanceLandingComponent
                featureFlagText={maintenanceLandingFF}
              />
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Landing;

// <div className="fixed top-0 right-0 z-50 w-full h-screen">
//   {redirect && <Redirect push to={`${redirect}`} />}
//   <a href="https://staging.accounts.cropwise.com/signup?source_campaign=M2FlN2VjM2ItNjVjMy00YzA5LTk4MWItMzEzOTc5ODZjYzIzOjoyNmRlYWQ0ZS1mNjVmLTQwYmUtOGEzMC02NDZiYWNjYjhlY2M6OkNyb3BXaXNl">
//     Sign Up
//   </a>
//   <Button
//     onClick={() => {
//       setRedirect('/login');
//     }}
//   >
//     Sign In
//   </Button>
// </div>
