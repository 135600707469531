import React, { memo } from 'react';
import PropTypes from 'prop-types';

const ExclamationTriangle = ({ size = 20, color = '#C17E19' }) => (
  <svg
    width={size * 1.1}
    height={size}
    viewBox="0 0 22 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    data-testid="exclamation-triangle"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M22 19.5L11 0.5L0 19.5H22ZM10 16.5V14.5H12V16.5H10ZM10 12.5H12V8.5H10V12.5Z"
      fill={color}
    />
  </svg>
);

ExclamationTriangle.propTypes = {
  color: PropTypes.string,
  size: PropTypes.number
};

ExclamationTriangle.defaultProps = {
  color: '#C17E19',
  size: 20
};

export default memo(ExclamationTriangle);
