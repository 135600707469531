import { useEffect, useState } from 'react';
import { FIELDS_TO_MASSIVE_ASSIGN } from 'reducers/reducer';

const useMassAssignerTools = (
  processedProperties,
  fieldsToMassiveAssign,
  dispatch,
  sortByCrop
) => {
  const [selectedAllProperties, setSelectedAllProperties] = useState(false);
  // Helper Functions
  const addCropZonesToExistingField = (existingField, cropZonesToAdd) => {
    const existingCZIds = existingField.cropzones.map(cz => cz.id);
    cropZonesToAdd.forEach(cz => {
      if (!existingCZIds.includes(cz.id)) {
        existingField.cropzones.push(cz);
      }
    });
  };
  const createFieldEntry = (field, farm, cropZones) => ({
    ...field,
    farmName: farm.name,
    farmId: farm.id,
    cropZones
  });
  const getAllFieldsGroupedByFarm = properties => {
    const allFields = [];
    properties.forEach(farm => {
      farm.fields.forEach(field => {
        allFields.push(createFieldEntry(field, farm, [...field.cropzones]));
      });
    });
    return allFields;
  };

  const addFieldCropZonesByCrop = (allFields, field, farm, crop) => {
    const cropZonesToAdd = field.cropzones.filter(cz => cz.crop.id === crop.id);
    if (cropZonesToAdd.length === 0) return;
    const existingFieldIndex = allFields.findIndex(f => f.id === field.id);
    if (existingFieldIndex >= 0) {
      addCropZonesToExistingField(
        allFields[existingFieldIndex],
        cropZonesToAdd
      );
    } else {
      allFields.push(createFieldEntry(field, farm, cropZonesToAdd));
    }
  };

  const processField = (allFields, field, farm, crop) => {
    addFieldCropZonesByCrop(allFields, field, farm, crop);
  };

  const processFarm = (allFields, farm, crop) => {
    farm.fields.forEach(field => {
      processField(allFields, field, farm, crop);
    });
  };

  const processCrop = (allFields, crop) => {
    crop.farms.forEach(farm => {
      processFarm(allFields, farm, crop);
    });
  };

  const getAllFieldsGroupedByCrop = properties => {
    const allFields = [];
    properties.forEach(crop => {
      processCrop(allFields, crop);
    });
    return allFields;
  };

  const handleSelectAllProperties = () => {
    const newSelectedValue = !selectedAllProperties;
    setSelectedAllProperties(newSelectedValue);
    if (newSelectedValue) {
      // Select all
      const allFields = sortByCrop
        ? getAllFieldsGroupedByCrop(processedProperties)
        : getAllFieldsGroupedByFarm(processedProperties);
      dispatch({
        type: FIELDS_TO_MASSIVE_ASSIGN,
        payload: allFields
      });
    } else {
      // Deselect all
      dispatch({
        type: FIELDS_TO_MASSIVE_ASSIGN,
        payload: []
      });
    }
  };
  useEffect(() => {
    if (!fieldsToMassiveAssign.length) {
      setSelectedAllProperties(false);
    }
  }, [fieldsToMassiveAssign]);
  // Selection Check Functions
  const isFieldFullySelected = field => {
    const fieldInAssign = fieldsToMassiveAssign.find(f => f.id === field.id);
    if (!fieldInAssign) return false;
    return field.cropzones.every(cz =>
      fieldInAssign.cropzones.some(fcz => fcz.id === cz.id)
    );
  };
  const isFieldSelectedInCropGroup = (crop, field) => {
    const cropZonesToCheck = field.cropzones.filter(
      cz => cz.crop.id === crop.id
    );
    if (cropZonesToCheck.length === 0) return false;
    const fieldInAssign = fieldsToMassiveAssign.find(f => f.id === field.id);
    if (!fieldInAssign) return false;
    return cropZonesToCheck.every(cz =>
      fieldInAssign.cropzones.some(fcz => fcz.id === cz.id)
    );
  };
  const isFarmSelectedInCropGroup = (crop, farm) =>
    farm.fields.every(field => isFieldSelectedInCropGroup(crop, field));
  const isCropGroupFullySelected = crop =>
    crop.farms.every(farm => isFarmSelectedInCropGroup(crop, farm));
  const isFarmFullySelected = farm =>
    farm.fields.every(field => isFieldFullySelected(field));
  // useEffect Hook for Updating Selected All Properties State
  useEffect(() => {
    let allSelected = false;
    if (processedProperties && processedProperties.length > 0) {
      if (sortByCrop) {
        // Grouped by crops
        allSelected = processedProperties.every(crop =>
          isCropGroupFullySelected(crop)
        );
      } else {
        // Grouped by farms
        allSelected = processedProperties.every(farm =>
          isFarmFullySelected(farm)
        );
      }
    }
    setSelectedAllProperties(allSelected);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fieldsToMassiveAssign, processedProperties, sortByCrop]);
  // Helper functions for updating fields and cropzones
  const removeCropZonesFromField = (existingField, cropId) => {
    // eslint-disable-next-line no-param-reassign
    existingField.cropzones = existingField.cropzones.filter(
      cz => cz.crop.id !== cropId
    );
  };
  const addCropZonesToField = (existingField, cropzonesToAdd) => {
    const existingCZIds = existingField.cropzones.map(cz => cz.id);
    cropzonesToAdd.forEach(cz => {
      if (!existingCZIds.includes(cz.id)) {
        existingField.cropzones.push(cz);
      }
    });
  };
  const addNewFieldWithCropZones = (
    updatedFields,
    field,
    cropZonesToAdd,
    farm
  ) => {
    updatedFields.push({
      ...field,
      farmName: farm ? farm.name : field.farmName,
      farmId: farm ? farm.id : field.farmId,
      cropzones: cropZonesToAdd
    });
  };
  // Functions for grouping by farms
  const handleSelectedFarm = farm => {
    if (sortByCrop) return;
    let updatedFields = [...fieldsToMassiveAssign];
    const allFieldsSelected = farm.fields.every(field =>
      isFieldFullySelected(field)
    );
    if (allFieldsSelected) {
      // All fields are selected, so deselect them
      updatedFields = updatedFields.filter(
        f => !farm.fields.some(field => field.id === f.id)
      );
    } else {
      // Not all fields are selected, so select all fields and their crop zones
      farm.fields.forEach(field => {
        const fieldIndex = updatedFields.findIndex(f => f.id === field.id);
        if (fieldIndex >= 0) {
          // Field is already in selection, ensure all crop zones are selected
          updatedFields[fieldIndex].cropzones = [...field.cropzones];
        } else {
          // Add field with all crop zones
          updatedFields.push({
            ...field,
            farmName: farm.name,
            farmId: farm.id,
            cropzones: [...field.cropzones]
          });
        }
      });
    }
    dispatch({
      type: FIELDS_TO_MASSIVE_ASSIGN,
      payload: updatedFields
    });
  };
  const isFieldSelected = field => {
    if (sortByCrop) return false;
    return isFieldFullySelected(field);
  };
  const isFarmSelected = farm => {
    if (sortByCrop) return false;
    return farm.fields.every(field => isFieldSelected(field));
  };
  const handleSelectedField = (farm, field) => {
    if (sortByCrop) return;
    const updatedFields = [...fieldsToMassiveAssign];
    const fieldIndex = updatedFields.findIndex(f => f.id === field.id);
    const fieldInAssign = updatedFields[fieldIndex];
    const allCropZonesSelected = fieldInAssign
      ? field.cropzones.every(cz =>
          fieldInAssign.cropzones.some(fcz => fcz.id === cz.id)
        )
      : false;
    if (fieldInAssign) {
      if (allCropZonesSelected) {
        // All crop zones are selected, so deselect the field
        updatedFields.splice(fieldIndex, 1);
      } else {
        // Not all crop zones are selected, so select all crop zones
        updatedFields[fieldIndex].cropzones = [...field.cropzones];
      }
    } else {
      // Field not in selection, add it with all crop zones
      updatedFields.push({
        ...field,
        farmName: farm.name,
        farmId: farm.id,
        cropzones: [...field.cropzones]
      });
    }
    dispatch({
      type: FIELDS_TO_MASSIVE_ASSIGN,
      payload: updatedFields
    });
  };
  // Functions for grouping by crops
  const isCropGroupSelected = crop => {
    if (!sortByCrop) return false;
    return isCropGroupFullySelected(crop);
  };

  const updateFieldInCropGroup = (
    updatedFields,
    field,
    farm,
    crop,
    allSelected
  ) => {
    const cropzonesToHandle = field.cropzones.filter(
      cz => cz.crop.id === crop.id
    );
    const fieldIndex = updatedFields.findIndex(f => f.id === field.id);
    if (fieldIndex >= 0) {
      const existingField = updatedFields[fieldIndex];
      if (allSelected) {
        // Deselect cropzones
        removeCropZonesFromField(existingField, crop.id);
        if (existingField.cropzones.length === 0) {
          updatedFields.splice(fieldIndex, 1);
        }
      } else {
        // Select cropzones
        addCropZonesToField(existingField, cropzonesToHandle);
      }
    } else if (!allSelected) {
      // Add new field with cropzones
      addNewFieldWithCropZones(updatedFields, field, cropzonesToHandle, farm);
    }
  };
  const isCropZoneSelected = cropzone =>
    fieldsToMassiveAssign.some(field =>
      field.cropzones.some(cz => cz.id === cropzone.id)
    );
  const handleSelectedCropGroup = crop => {
    if (!sortByCrop) return;
    const allSelected = isCropGroupSelected(crop);
    const updatedFields = [...fieldsToMassiveAssign];
    crop.farms.forEach(farm => {
      farm.fields.forEach(field => {
        updateFieldInCropGroup(updatedFields, field, farm, crop, allSelected);
      });
    });
    dispatch({ type: FIELDS_TO_MASSIVE_ASSIGN, payload: updatedFields });
  };
  const handleSelectedFarmInCropGroup = (crop, farm) => {
    if (!sortByCrop) return;
    const allSelected = isFarmSelectedInCropGroup(crop, farm);
    const updatedFields = [...fieldsToMassiveAssign];
    farm.fields.forEach(field => {
      updateFieldInCropGroup(updatedFields, field, farm, crop, allSelected);
    });
    dispatch({ type: FIELDS_TO_MASSIVE_ASSIGN, payload: updatedFields });
  };
  const handleSelectedFieldInCropGroup = (crop, field) => {
    if (!sortByCrop) return;
    const allSelected = isFieldSelectedInCropGroup(crop, field);
    const updatedFields = [...fieldsToMassiveAssign];
    updateFieldInCropGroup(updatedFields, field, null, crop, allSelected);
    dispatch({ type: FIELDS_TO_MASSIVE_ASSIGN, payload: updatedFields });
  };

  const handleSelectedCropZone = (farm, field, cropZone, crop = null) => {
    const updatedFields = [...fieldsToMassiveAssign];
    const fieldIndex = updatedFields.findIndex(fd => fd.id === field.id);
    if (fieldIndex >= 0) {
      const existingField = updatedFields[fieldIndex];
      const cropZoneIndex = existingField.cropzones.findIndex(
        cz => cz.id === cropZone.id
      );
      if (cropZoneIndex >= 0) {
        // Deselect cropzone
        existingField.cropzones.splice(cropZoneIndex, 1);
        if (existingField.cropzones.length === 0) {
          updatedFields.splice(fieldIndex, 1);
        }
      } else if (!crop || cropZone.crop.id === crop.id) {
        // Select cropzone
        existingField.cropzones.push(cropZone);
      }
    } else if (!crop || cropZone.crop.id === crop.id) {
      // Add new field with the cropzone
      updatedFields.push({
        ...field,
        farmName: farm.name,
        farmId: farm.id,
        cropzones: [cropZone]
      });
    }
    dispatch({
      type: FIELDS_TO_MASSIVE_ASSIGN,
      payload: updatedFields
    });
  };
  return {
    handleSelectedFarm,
    handleSelectedField,
    isFarmSelected,
    isFieldSelected,
    handleSelectedCropGroup,
    handleSelectedFarmInCropGroup,
    handleSelectedFieldInCropGroup,
    isCropGroupSelected,
    isFarmSelectedInCropGroup,
    isFieldSelectedInCropGroup,
    isCropZoneSelected,
    handleSelectedCropZone,
    handleSelectAllProperties,
    selectedAllProperties
  };
};
export default useMassAssignerTools;
