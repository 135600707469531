import React, { useContext } from 'react';
import { PropTypes } from 'prop-types';

import useCropSeasons from 'hooks/useCropSeasons';

import CropSeasonDropdown from 'components/CropSeasonDropdown';

import { SubmissionSummaryContext } from 'screens/Integrations/SubmissionSummary/context/SubmissionSummaryProvider';

import { determineIntegrationContext } from 'screens/Integrations/helpers/determineIntegrationContext';
import useFormikHandler from 'screens/Integrations/hooks/useFormikHandler';
import useSubmissionAnalytics from 'screens/Integrations/hooks/useSubmissionAnalytics';
import CropSeasonSingleDateRange from 'components/CropSeasonSingleDateRange';
import { useFormikContext } from 'formik';
import { formatDateToShortMonthDayYear } from 'utilities/dateHelpers';
import { INTEGRATIONS } from 'screens/Integrations/helpers/constants';
import { Spinner } from '@agconnections/grow-ui';

const SubmissionCropSeason = ({ context, FooterComponent }) => {
  const { events, triggerAnalyticsEvent } = useSubmissionAnalytics();
  const { setSelectedCropSeasons, selectedCropSeasons } = useFormikHandler();
  const { values } = useFormikContext();

  const { updateCropSeasons, updateInitialDateRange } = useContext(
    SubmissionSummaryContext
  );
  const { goForward } = useContext(determineIntegrationContext(context));

  const { cropSeasons, loaded } = useCropSeasons();

  const handleCropSeasonSelect = seasonsSelected => {
    setSelectedCropSeasons(seasonsSelected);
  };

  const onNextHandler = () => {
    updateCropSeasons(selectedCropSeasons);
    updateInitialDateRange(values.seasonSingleDateRange);
    triggerAnalyticsEvent(events.epic.Integrations.submissionSeasonSelected, {
      vendor: context
    });
    goForward();
  };

  if (!loaded) {
    return (
      <div className="m-auto">
        <Spinner />
      </div>
    );
  }

  return (
    <div
      className="flex flex-col bg-white top-30 left-26 w-140 rounded-md mb-20 p-6 shadow-sm"
      data-testid="submission-crop-season-selection"
    >
      <h1 className="mb-6 ml-8 font-body text-base font-normal leading-8 tracking-tight text-left">
        Add crop seasons for your submission.
      </h1>
      <div className="w-2/3 ml-8 flex-1 h-full">
        <CropSeasonDropdown
          selectedActive={false}
          allCropSeasonsData={cropSeasons}
          csLoaded={loaded}
          onSelect={handleCropSeasonSelect}
          isRequired
        />
        {context === INTEGRATIONS.calAgPermits &&
          selectedCropSeasons?.length > 0 && (
            <div className="text-gray-600">
              Season range:
              {` ${formatDateToShortMonthDayYear(
                values.cropSeasonsMinDate
              )} - ${formatDateToShortMonthDayYear(values.cropSeasonsMaxDate)}`}
            </div>
          )}
        <div className="flex flex-row mt-8 mb-16 pb-6 max-w-80">
          <CropSeasonSingleDateRange
            isDisabled={context !== INTEGRATIONS.calAgPermits}
          />
        </div>
      </div>
      {FooterComponent && (
        <FooterComponent
          onNext={onNextHandler}
          nextDisabled={
            !selectedCropSeasons || selectedCropSeasons.length === 0
          }
        />
      )}
    </div>
  );
};

SubmissionCropSeason.propTypes = {
  context: PropTypes.string.isRequired,
  FooterComponent: PropTypes.oneOfType([
    PropTypes.elementType, // For React components
    PropTypes.func // For functions that return JSX
  ])
};

SubmissionCropSeason.defaultProps = {
  FooterComponent: null
};

export default SubmissionCropSeason;
