import React from 'react';
import PropTypes from 'prop-types';
import SeedsIcon from 'assets/seeds.svg';
import ServiceIcon from 'assets/service.svg';
import SprayIcon from 'assets/spray.svg';
import { productTypes } from 'components/ProductSideBar/helpers/constants';

const ProductTypeDisplay = ({ productType, iconSize }) => {
  const productTypeString = productTypes.find(
    ({ key }) =>
      productType.localeCompare(key, undefined, { sensitivity: 'accent' }) === 0
  )?.value;

  let productTypeIcon;
  switch (productType) {
    case 'Seed':
      productTypeIcon = SeedsIcon;
      break;
    case 'Service':
      productTypeIcon = ServiceIcon;
      break;
    default:
      productTypeIcon = SprayIcon;
  }

  return (
    <>
      <img
        width={iconSize}
        className="mr-1"
        src={productTypeIcon}
        alt="product type icon"
      />
      {productTypeString}
    </>
  );
};

ProductTypeDisplay.propTypes = {
  productType: PropTypes.string.isRequired,
  iconSize: PropTypes.number
};

ProductTypeDisplay.defaultProps = {
  iconSize: 18
};

export default ProductTypeDisplay;
