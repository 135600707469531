import React, { useEffect, useState } from 'react';
import { Select } from '@agconnections/grow-ui';
import PropTypes from 'prop-types';
import { difference } from 'lodash';
import useProducts from 'hooks/useProducts';
import { SOURCE_FILTER_OPTIONS } from 'screens/ProductList/helpers/tableConstants';

const ProductListSourceFilter = ({ sourceFilters, handleFilterChange }) => {
  const { products } = useProducts();

  const [showFilter, setShowFilter] = useState(false);

  useEffect(() => {
    if (!products) {
      setShowFilter(false);
      return;
    }

    const diffArray = difference(
      [true, false],
      products.map(p => p.custom)
    );
    setShowFilter(diffArray.length === 0);
  }, [products]);

  return showFilter ? (
    <div className="w-220px ml-4 font-medium max-h-8 whitespace-nowrap">
      <Select
        multiple
        value={sourceFilters.join(',')}
        placeholder="Filter by source"
        items={SOURCE_FILTER_OPTIONS}
        onChange={handleFilterChange}
      />
    </div>
  ) : null;
};

ProductListSourceFilter.propTypes = {
  sourceFilters: PropTypes.arrayOf(PropTypes.object).isRequired,
  handleFilterChange: PropTypes.func.isRequired
};
export default ProductListSourceFilter;
