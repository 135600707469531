/* eslint-disable react/jsx-curly-brace-presence */
import React, { useRef, useState } from 'react';
import PropTypes from 'prop-types';

import ClickableDiv from 'components/ClickableDiv';
import FarmIcon from 'screens/Integrations/components/icons/FarmIcon';

import FarmSelectorOptionList from '../FarmSelectorOptionList';

const Farm = ({ farms, farm, selectFarm, selectFarmTextSize }) => {
  const [show, setShow] = useState(false);
  const selectRef = useRef();

  const handleBlur = e => {
    if (selectRef.current.contains(e.target)) {
      return;
    }
    setShow(false);
  };

  return (
    <div className="flex items-center justify-center farm-select">
      <div
        className={`flex flex-row items-center text-2xl font-semibold mr-10`}
      >
        <FarmIcon color="#363948" />
        <span
          style={{ color: '#363948' }}
          className="ml-2 "
          data-testid="farm-name"
        >
          {farm?.name}
        </span>
      </div>
      {farms?.length > 1 && (
        <div className="relative flex-1">
          <ClickableDiv
            className="bg-transparent text-info-dark-blue"
            data-testid="clickable-div"
            ref={selectRef}
            onClick={() => setShow(prev => !prev)}
          >
            <div className={`flex flex-row items-center ${selectFarmTextSize}`}>
              <span className="ml-2">Jump to other farms</span>
              <span className="yield-select-arrow w-8 h-7 selected-icon" />
            </div>
            {show && (
              <FarmSelectorOptionList
                data-testid="farm-option-list"
                farms={farms}
                selectFarm={selectFarm}
                onBlur={handleBlur}
              />
            )}
          </ClickableDiv>
        </div>
      )}
    </div>
  );
};

Farm.propTypes = {
  farm: PropTypes.shape({
    id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired
  }).isRequired,
  selectFarm: PropTypes.func.isRequired,
  farms: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired
    })
  ).isRequired,
  selectFarmTextSize: PropTypes.string
};

Farm.defaultProps = {
  selectFarmTextSize: 'text-xl'
};

export default Farm;
