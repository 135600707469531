import React from 'react';
import PropTypes from 'prop-types';
import { Select } from '@agconnections/grow-ui';

import {
  createEventHandlerWithTransform,
  getEventSelectedKey
} from 'utilities/formHelpers';
import statesItems from './data';

function StateSelect({ value, onChange }) {
  const handleChange = createEventHandlerWithTransform(
    onChange,
    getEventSelectedKey
  );
  return (
    <Select
      id="state-select"
      items={statesItems}
      value={value}
      onChange={handleChange}
    />
  );
}

StateSelect.defaultProps = {
  value: null
};

StateSelect.propTypes = {
  value: PropTypes.string,
  onChange: PropTypes.func.isRequired
};

export default StateSelect;
