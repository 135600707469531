/* eslint-disable react/prop-types */
import React, { useState, useContext, useEffect } from 'react';
import PropTypes from 'prop-types';
import catchCancel from 'helpers/catchCancel';
import { parseServerError } from 'helpers/errorHelpers';
import { TagCloud, Tag } from '@agconnections/grow-ui';
import Paper from 'components/Paper';
import TileCard from 'components/TileCard';
import TileCardField from 'components/TileCardField';
import TileGroup from 'components/TileGroup';
import DeleteModal from 'components/Modals/DeleteModal';
import { Context } from 'components/Store';
import TagsModal from 'components/Modals/TagsModal';
import { formatEpochDate } from 'utilities/dateHelpers';
import { invoice as invoiceApi } from 'utilities/api';
import useInvoiceActions from 'screens/Invoices/hooks/useInvoiceActions';
import CwFPagination from 'components/CwFPagination';
import { INVOICES_LANDING_MENU } from 'utilities/menus';
import { paths } from 'routes/paths';
import formatInvoiceCropName from 'screens/Invoices/helpers/formatInvoiceCropName';
import getInvoiceCostVisibility from 'screens/Invoices/helpers/getInvoiceCostVisibility';

const InvoicesBoard = ({
  invoices,
  setReRenderInvoices,
  memberRole,
  memberFinancialAccess,
  pageNo,
  onDelete,
  onPageChange,
  totalInvoices
}) => {
  const [, dispatch] = useContext(Context);
  const [, setError] = useState(null);

  const {
    handleAction,
    deleteModalState,
    invoiceTags,
    openTags,
    selectedInvoiceTitleAndId,
    selectedInvoiceData,
    setOpenTags,
    setInvoiceTags,
    setDeleteModalState,
    downloadingPDF
  } = useInvoiceActions();

  const [tileIndex, setTileIndex] = useState(-1);

  useEffect(() => {
    if (!downloadingPDF) setTileIndex(-1);
  }, [downloadingPDF]);

  const getTotInvCostValue = invCost =>
    getInvoiceCostVisibility({
      cost: invCost,
      memberRole,
      memberFinancialAccess
    });

  const handleSelectedTags = async e => {
    setReRenderInvoices(false);
    setOpenTags(false);
    const body = {
      ...selectedInvoiceData,
      flagIds: e.map(flag => flag.id)
    };
    delete body.invoiceDate;
    delete body.invoiceDueDate;
    delete body.cropSeason;
    const { promise } = await invoiceApi.update(selectedInvoiceData.id, body);
    await promise
      .then(() => {
        setInvoiceTags(e);
        setReRenderInvoices(true);
      })
      .catch(catchCancel)
      .catch(err => {
        if (err.response?.data?.validationErrors) {
          setError(`Error saving task: ${err.response.data.message}`);
        } else if (err.response?.data?.displayErrorMessage) {
          setError(err.response.data.displayErrorMessage);
        } else {
          parseServerError(dispatch)(err);
        }
      });
  };

  const handleDelete = async () => {
    onDelete(deleteModalState.id);
    setDeleteModalState({ open: false, id: null });
  };

  return (
    <>
      <div data-testid="invoice-tileboard">
        <DeleteModal
          open={deleteModalState.open}
          itemType="Invoice"
          onCancel={() => setDeleteModalState({ open: false, id: null })}
          onDelete={handleDelete}
        />
        <TagsModal
          open={openTags}
          close={() => setOpenTags(false)}
          name={selectedInvoiceTitleAndId.title}
          selectedTags={invoiceTags}
          returnSelectedTags={handleSelectedTags}
        />
        <Paper>
          {CwFPagination(totalInvoices, pageNo, onPageChange)}
          <TileGroup
            key={null}
            title="Cropwise Financials Invoices"
            totalItems={invoices.length}
            testId="invoices-board-title"
          >
            <div className="flex flex-wrap w-full">
              {invoices?.length
                ? invoices.map((invoice, index) => {
                    return (
                      <TileCard
                        key={invoice.invoiceId}
                        title={invoice.title}
                        linkTo={`${paths.invoices}/${invoice.invoiceId}/view`}
                        subtitle={
                          invoice.invoiceDate
                            ? formatEpochDate(invoice.invoiceDateEpoch)
                            : 'N/A'
                        }
                        onAction={action => {
                          handleAction(
                            action,
                            invoice,
                            true,
                            index,
                            setTileIndex
                          );
                        }}
                        menuItems={INVOICES_LANDING_MENU}
                        testId="invoice"
                        runningProcesses={downloadingPDF && tileIndex === index}
                      >
                        <div className="flex flex-wrap w-full mt-2 mb-2">
                          {invoice?.flags && (
                            <TagCloud>
                              {invoice?.flags?.map(tag => {
                                return (
                                  <Tag key={tag.id} color={tag.color}>
                                    {tag.name}
                                  </Tag>
                                );
                              })}
                            </TagCloud>
                          )}
                        </div>
                        <div className="flex flex-wrap w-full">
                          <TileCardField
                            title="Due Date"
                            value={
                              invoice.invoiceDueDate
                                ? formatEpochDate(invoice.invoiceDueDateEpoch)
                                : 'N/A'
                            }
                          />
                          <TileCardField
                            title="Total Invoice Cost"
                            value={getTotInvCostValue(invoice?.totalCost)}
                          />
                          <TileCardField
                            title="Product Count"
                            value={invoice?.productCount || 0}
                          />
                          <TileCardField
                            title="Season(s)"
                            value={formatInvoiceCropName(invoice?.cropSeasons)}
                          />
                        </div>
                      </TileCard>
                    );
                  })
                : ''}
            </div>
          </TileGroup>
          <div style={{ minHeight: '35px' }}>
            {CwFPagination(totalInvoices, pageNo, onPageChange)}
          </div>
        </Paper>
      </div>
    </>
  );
};
InvoicesBoard.defaultProps = {
  invoices: []
};
InvoicesBoard.propTypes = {
  invoices: PropTypes.arrayOf(PropTypes.object),
  onDelete: PropTypes.func.isRequired,
  setReRenderInvoices: PropTypes.func.isRequired,
  memberRole: PropTypes.string.isRequired,
  memberFinancialAccess: PropTypes.string.isRequired,
  pageNo: PropTypes.number.isRequired,
  onPageChange: PropTypes.func.isRequired,
  totalInvoices: PropTypes.number.isRequired
};
export default InvoicesBoard;
