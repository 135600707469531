import React, { useContext, useState } from 'react';
import PropTypes from 'prop-types';
import { organization as organizationApi } from 'utilities/api';
import catchCancel from 'helpers/catchCancel';
import { parseServerError } from 'helpers/errorHelpers';
import { Context } from 'components/Store';
import { useParams, useHistory } from 'react-router-dom';

import { SimpleModal } from '@agconnections/grow-ui';
import { paths } from 'routes/paths';

const DeleteRevokedModal = ({ open, onClose, accountId, getOrganization }) => {
  const [, dispatch] = useContext(Context);
  const { id } = useParams();
  const history = useHistory();
  const [isSaving, setIsSaving] = useState(false);

  const deleteApi = organizationApi.createChildApi({
    action: `organization/account/${accountId}`
  });

  const deleteRevoked = async () => {
    setIsSaving(true);
    const { promise } = deleteApi.delete(null);
    promise
      .then(() => {
        history.replace(`${paths.organizations}/${id}`);
        getOrganization();
      })
      .catch(catchCancel)
      .catch(err => {
        parseServerError(dispatch)(err);
      })
      .finally(() => {
        setIsSaving(false);
        onClose();
      });
  };
  return (
    <div data-testid="DeleteRevokedModal">
      <SimpleModal
        onConfirm={deleteRevoked}
        open={open}
        close={onClose}
        title="Delete Revoked"
        type="confirmation"
        isSaving={isSaving}
      >
        <div className="pr-6">
          Are you sure you want to delete this revoked member from your list?
        </div>
      </SimpleModal>
    </div>
  );
};

DeleteRevokedModal.defaultProps = {
  open: false,
  onClose: () => {},
  accountId: '',
  getOrganization: () => {}
};

DeleteRevokedModal.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
  accountId: PropTypes.string,
  getOrganization: PropTypes.func
};

export default DeleteRevokedModal;
