/**
 * filters global schema from data. moddifies fields when neccessary
 * @param {Object} data - object to be filtered
 * @param {Boolean} isPut - boolean to deterimine if version is removed
 * @returns {Object} - filtered data
 */
const filterObjectGlobalSchema = (data, isPut = false) => {
  const obj = { ...data };
  // global schema
  const globalSchema = [
    '_id',
    'createdAt',
    'createdBy',
    'updatedBy',
    'updatedAt',
    'active'
  ];

  if (!isPut) globalSchema.push('version');

  globalSchema.forEach(prop => {
    delete obj[prop];
  });
  return obj;
};

/**
 * filters global schema from data. moddifies fields when neccessary
 * @param {Object|Object[]} data - object or array of object to be filtered
 * @param {Boolean} isPut - boolean to deterimine if version is removed
 * @returns {Object|Object[]} - filtered data
 */
const filterGlobalSchema = (data, isPut = false) => {
  if (Array.isArray(data)) {
    return data.map(item => filterObjectGlobalSchema(item, isPut));
  }

  if (typeof data === 'object') {
    return filterObjectGlobalSchema(data, isPut);
  }

  return data;
};

export default filterGlobalSchema;
