import React, { createContext, useState, useMemo } from 'react';
import PropTypes from 'prop-types';

export const AgVendContext = createContext();

const Provider = ({ children }) => {
  const [step, setStep] = useState(1);
  const [canGoNext, setCanGoNext] = useState(false);
  const blockStepForward = () => setCanGoNext(false);
  const releaseStepForward = () => setCanGoNext(true);

  const goBack = () => {
    setStep(s => Math.max(0, s - 1));
  };

  const goForward = () => {
    setStep(s => s + 1);
  };

  const store = useMemo(
    () => ({
      state: { step, canGoNext },
      gotoStep: setStep,
      goBack,
      blockStepForward,
      releaseStepForward,
      goForward
    }),
    [step, canGoNext]
  );

  return (
    <AgVendContext.Provider value={store}>{children}</AgVendContext.Provider>
  );
};

Provider.defaultProps = {
  children: null
};

Provider.propTypes = {
  children: PropTypes.node
};

export default Provider;
