/* eslint-disable no-shadow */
import _ from 'lodash';

const isArrayTouched = (key, values, submittedValues) =>
  !!_.differenceWith(values[key], submittedValues[key], _.isEqual).length ||
  !!_.differenceWith(submittedValues[key], values[key], _.isEqual).length;

const isDateEqual = (key, values, submittedValues) =>
  values[key]?.getTime() === submittedValues[key]?.getTime();

export const isFormIncomplete = (formConfig, values) => {
  if (!formConfig) return true;

  return (
    (formConfig.showCropSeasons && !values.selectedCropSeasons?.length) ||
    (formConfig.showDateRange &&
      (!values.cropSeasonStartDate || !values.cropSeasonEndDate)) ||
    (formConfig.showPlans && !values.selectedPlans?.length) ||
    (formConfig.showProperties && !values.selectedCropzones?.length) ||
    (formConfig.showProducts && !values.selectedProducts?.length)
  );
};
export default (formConfig, values, submittedValues) => {
  if (!formConfig) return false;

  const conds = [];
  if (formConfig.showCropSeasons) {
    conds.push(isArrayTouched('selectedCropSeasons', values, submittedValues));
  }
  if (formConfig.showDateRange) {
    conds.push(
      !isDateEqual('cropSeasonStartDate', values, submittedValues),
      !isDateEqual('cropSeasonEndDate', values, submittedValues)
    );
  }
  if (formConfig.showPlans) {
    conds.push(isArrayTouched('selectedPlans', values, submittedValues));
  }
  if (formConfig.showProperties) {
    conds.push(isArrayTouched('selectedCropzones', values, submittedValues));
  }
  if (formConfig.showProducts) {
    conds.push(isArrayTouched('selectedProducts', values, submittedValues));
  }

  return !!conds.find(b => b);
};
