/**
 * An object returned from all api utility functions that contains the resulting promise
 * and the cancellation function associated with the promise.
 * @typedef APIResult
 * @property {Promise} promise - Axios promise returned from request
 * @property {Function} cancel - cancellation function for request
 */

/**
 * add a cancel function to Promises returned from axios request
 * @param {Promise} promise - promise to be modified
 * @returns {APIResult} returns an APIResult object containing promise and cancellation function
 */
function withCancel(promise, cancel, message = 'Request Cancelled') {
  // Set initial state
  let isPending = true;
  // Set pending to false when fullfilled
  const result = promise.finally(() => (isPending = false));

  return {
    promise: result,
    cancel: () => isPending && cancel(message)
  };
}

export default withCancel;
