import React, { useContext, useMemo } from 'react';
import PropTypes from 'prop-types';

import { Tag } from '@agconnections/grow-ui';

import {
  INTEGRATIONS,
  SubmissionStatus
} from 'screens/Integrations/helpers/constants';

import { SubmissionSummaryContext } from '../../context/SubmissionSummaryProvider';

import FieldProductsTable from './components/FieldProductsTable';
import ArrowRight from './icons/arrow_right';
import RemoveCircle from './icons/remove_circle';

const FieldsView = ({ fields }) => {
  const {
    state: { farms, matches, isReadOnly, integrationType, submissionResult },
    setOpenRemoveCropzoneModal
  } = useContext(SubmissionSummaryContext);

  const totalCropzones = useMemo(
    () =>
      farms?.reduce(
        (accByProperty, property) =>
          accByProperty +
          property.fields.reduce(
            (accByField, field) => accByField + field.cropzones.length,
            0
          ),
        0
      ),
    [farms]
  );

  const errors = useMemo(
    () =>
      submissionResult?.response?.details
        .filter(detail => detail.status === SubmissionStatus.ERROR)
        .map(error => {
          return {
            errorMessage: error.errorMessage,
            taskIds: error.responseData?.taskIds,
            cropZoneId: error.vendorPayload?.cropZoneId,
            internalRecordId: error.responseData?.internalRecordId
          };
        }),
    [submissionResult]
  );

  const getCropSeasonsIds = cropSeasons =>
    cropSeasons?.map(season => season.id);

  const getProductsFromCropzone = cz =>
    cz.tasks?.flatMap(task =>
      task.products.map(prod => ({
        ...prod,
        taskId: task.taskId,
        cropSeasonsIds: getCropSeasonsIds(cz.cropSeasons)
      }))
    );

  return (
    <>
      {fields?.map(field => (
        <div
          key={field.id}
          data-testid="field-summary-table"
          className="w-full mb-10"
          id={field.id}
        >
          <div className="w-full">
            {field?.cropzones?.map(cropzone => {
              const area = cropzone?.area || cropzone.boundaryArea;
              const products = getProductsFromCropzone(cropzone);
              const idToMatch =
                integrationType === INTEGRATIONS.agrian
                  ? field.id
                  : cropzone.id;
              return (
                <div key={cropzone.id} className="mb-10">
                  <div className="flex flex-row mb-3 leading-loose items-center">
                    <div className="flex-column">
                      <p className="font-thin text-xs text-gray-600">
                        Cropwise
                      </p>
                      <div
                        className="font-bold mr-4"
                        data-testid="cropzone-name"
                      >
                        {field.name} / {cropzone.name} - {area?.toFixed(2)} ac
                      </div>
                    </div>
                    <div className="mt-6">
                      <ArrowRight />
                    </div>
                    <div className="flex-column">
                      <p className="font-thin text-xs text-gray-600 ml-4 mr-4">
                        {integrationType}
                      </p>
                      <div className="font-bold ml-4 mr-4">
                        {matches?.[idToMatch] ??
                          `No Matching Found in ${integrationType}`}
                      </div>
                    </div>
                    <div
                      className="w-auto mt-6"
                      data-testid="cropzone-cropseasons-name"
                    >
                      {cropzone.cropSeasons?.map(cropseason => (
                        <Tag color="#696F88" key={cropseason.id}>
                          {cropseason.name}
                        </Tag>
                      ))}
                    </div>
                    <div className="ml-auto flex flex-row items-center">
                      {!isReadOnly && totalCropzones > 1 && (
                        <button
                          className="flex items-center py-1 px-2 text-neutral-600 hover:bg-neutral-100"
                          type="button"
                          onClick={() => {
                            setOpenRemoveCropzoneModal(true, cropzone);
                          }}
                          data-testid="cropzone-button-remove"
                        >
                          <RemoveCircle />
                          <span className="ml-1">Remove</span>
                        </button>
                      )}
                    </div>
                  </div>
                  <FieldProductsTable
                    products={products}
                    errors={errors?.filter(
                      error => error.internalRecordId === cropzone.id
                    )}
                  />
                </div>
              );
            })}
          </div>
        </div>
      ))}
    </>
  );
};

FieldsView.defaultProps = {
  fields: []
};

FieldsView.propTypes = {
  fields: PropTypes.arrayOf(PropTypes.object)
};

export default FieldsView;
