import React from 'react';
import PropTypes from 'prop-types';
import TryAgainMessage from '../TryAgainMessage';

const TryAgainPageWrapper = ({ error, onReset, children }) => {
  return (
    <>
      {error ? <TryAgainMessage error={error} onReset={onReset} /> : children}
    </>
  );
};

TryAgainPageWrapper.propTypes = {
  error: PropTypes.objectOf(PropTypes.shape()),
  onReset: PropTypes.func,
  children: PropTypes.node.isRequired
};

TryAgainPageWrapper.defaultProps = {
  error: null,
  onReset: () => {}
};

export default TryAgainPageWrapper;
