import React from 'react';
import PropTypes from 'prop-types';

import { Form } from '@agconnections/grow-ui';

import EditScreenField from './EditScreenField';

const EditScreenForm = ({ children }) => {
  return (
    <Form>
      <div data-testid="edit-screen" className="p-5">
        {React.Children.map(children, (child, index) => {
          return (
            <div>
              {child}
              {index === children.length - 1 ? null : (
                <div className="border-t border-gray-200" />
              )}
            </div>
          );
        })}
      </div>
    </Form>
  );
};

EditScreenForm.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.object),
    PropTypes.object
  ]).isRequired
};

EditScreenForm.EditScreenField = EditScreenField;

export default EditScreenForm;
