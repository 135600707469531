import React from 'react';
import PropTypes from 'prop-types';

const SprayIcon = ({ width, height, color, size }) => (
  <svg
    width={`${width}`}
    height={`${height}`}
    viewBox={`0 0 ${size} ${size}`}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M7.87499 9H10.125L11.1578 11.735C11.3431 12.2256 10.9806 12.75 10.4562 12.75H7.54458C7.02027 12.75 6.65777 12.2258 6.84287 11.7352L7.87499 9ZM8.62499 10.5L8.24999 11.25H9.74999L9.37499 10.5H8.62499Z"
      fill={color}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8.24999 17.25L8.24999 14.25H9.74999V17.25H8.24999Z"
      fill={color}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M11.874 15.7881L10.626 13.916L11.874 13.084L13.1221 14.9561L11.874 15.7881Z"
      fill={color}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M4.87789 14.9561L6.12597 13.084L7.37404 13.916L6.12597 15.7881L4.87789 14.9561Z"
      fill={color}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M3.89199 2.68659C3.53666 2.19027 3.89141 1.5 4.50181 1.5H13.498C14.1084 1.5 14.4631 2.19025 14.1078 2.68658L10.125 8.25H7.87499L3.89199 2.68659ZM5.95726 3L8.63583 6.75H9.3639L12.0425 3H5.95726Z"
      fill={color}
    />
  </svg>
);

SprayIcon.defaultProps = {
  color: '#696F88',
  height: 18,
  width: 18,
  size: 18
};

SprayIcon.propTypes = {
  color: PropTypes.string,
  height: PropTypes.number,
  width: PropTypes.number,
  size: PropTypes.number
};

export default SprayIcon;
