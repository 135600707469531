import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import EmptySummary from 'screens/Property/PropertiesLanding/PropertyLandingMap/PropertyMapWrapper/PropertyMap/ChangeSummary/components/EmptySummary';
import PropertyMapWrapper from 'screens/Property/PropertiesLanding/PropertyLandingMap/PropertyMapWrapper';
import { getTotalAreaFromFeatures } from 'helpers/getTotalAreaAcres';
import { YieldContext } from 'screens/Yield/context/YieldProvider';
import YieldSelectedPropertyInfo from '../YieldSelectedPropertyInfo';

const YieldMapView = ({ geoJSONCollection, zoom, setZoom }) => {
  const {
    state: { cropZones }
  } = useContext(YieldContext);

  const selectedPropertyInfoClasses =
    cropZones.length === 0
      ? 'ml-8 border-2'
      : 'ml-8 divide-y-2 overflow-auto h-512px';

  const baseText =
    'Select the properties from the left that you would like to submit';
  return (
    <>
      <div className="w-2/4 flex h-560px" data-testid="yield-map-view">
        <PropertyMapWrapper
          geoJSONCollection={geoJSONCollection}
          zoom={zoom}
          setZoom={setZoom}
          customStyles={cropZones.length > 0}
          stretch
        />
      </div>
      <div className="h-full w-1/4">
        <div className="flex flex-row justify-between pl-8 pb-3 font-semibold">
          <span>SELECTED PROPERTIES</span>
          <span>{getTotalAreaFromFeatures(cropZones)} ac</span>
        </div>
        <div className={selectedPropertyInfoClasses}>
          {cropZones.length === 0 ? (
            <EmptySummary baseText={baseText} />
          ) : (
            cropZones.map(property => (
              <YieldSelectedPropertyInfo
                property={property.properties}
                key={property.properties.id}
              />
            ))
          )}
        </div>
      </div>
    </>
  );
};

YieldMapView.propTypes = {
  geoJSONCollection: PropTypes.shape({
    type: PropTypes.string,
    features: PropTypes.arrayOf(PropTypes.object)
  }),
  zoom: PropTypes.arrayOf(PropTypes.number).isRequired,
  setZoom: PropTypes.func.isRequired
};

YieldMapView.defaultProps = {
  geoJSONCollection: {
    type: 'FeatureCollection',
    features: []
  }
};

export default YieldMapView;
