import { cloneDeep, orderBy } from 'lodash';

export default (tasks, field, direction) => {
  if (!direction) return tasks;

  const _tasks = tasks.map(task => cloneDeep(task));
  switch (field) {
    case 'task':
      return orderBy(_tasks, [task => task?.name], direction);
    case 'fields':
      return orderBy(_tasks, [task => task?.fieldCount], direction);
    case 'totalAcres':
      return orderBy(_tasks, [task => task?.totalAreaValue], direction);
    case 'products':
      return orderBy(_tasks, [task => task?.productCount], direction);
    case 'totalCost':
      return orderBy(_tasks, [task => task?.totalTaskCost], direction);
    case 'cropSeason':
      return orderBy(
        _tasks,
        [task => task?.cropSeasons?.cropSeasons[0]],
        direction
      );
    case 'startDateTime':
      return orderBy(_tasks, [task => task?.startDateEpoch], direction);
    case 'status':
      return orderBy(_tasks, [task => task?.status], direction);
    default:
      return _tasks;
  }
};
