import React from 'react';
import PropTypes from 'prop-types';

const CropSeasonSingleSelectionDropdownItem = ({ item }) => {
  const { value: itemValue, key, label, type } = item;
  if (!itemValue) {
    if (label === 'empty') {
      return (
        <div
          className="px-2 ml-2 text-neutral-60"
          key={key}
          data-testid="item-empty-value"
        >{`No ${type} Seasons found`}</div>
      );
    }
    return (
      <div
        className="text-neutral-60 p-2 ml-2"
        key={key}
        data-testid="item-without-value"
      >
        {label}
      </div>
    );
  }
  return (
    <div
      key={key}
      className="w-full h-full px-2 py-1 ml-2 flex items-center"
      data-testid="item-with-value"
    >
      <div>{itemValue.name}</div>
    </div>
  );
};

CropSeasonSingleSelectionDropdownItem.propTypes = {
  item: PropTypes.shape({
    value: PropTypes.shape({
      name: PropTypes.string,
      type: PropTypes.string
    }),
    key: PropTypes.string,
    label: PropTypes.string,
    type: PropTypes.string
  }).isRequired
};

export default CropSeasonSingleSelectionDropdownItem;
