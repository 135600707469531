import {
  genGetNestedResourceRouteHandler,
  genGetNestedResourceByIdRouteHandler,
  genCreateNestedResourceRouteHandler,
  genUpdateNestedResourceRouteHandler,
  genSoftDeleteNestedResourceRouteHandler
} from './resourceNestedRouteHandlers';

import {
  genGetResourceRouteHandler,
  genGetResourceByIdRouteHandler,
  genCreateResourceRouteHandler,
  genUpdateResourceRouteHandler,
  genSoftDeleteResourceRouteHandler
} from './resourceRouteHandlers';

/**
 * creates standard route handlers for a resource
 * @param {Server} server - instance of Mirage Server
 * @param {String} name - the name of the model (singular form of resource usually)
 * @param {String} parentName - the name of the parent model (singular form of parent resource usually)
 * @param {Object} options - options
 * @param {Object} options.namePlural - override pluralized form of resource name. (pluralize library normally handles this)
 * @param {Object} options.orgRoutes - if true organization routes are added (defaults to true)
 * @param {Object} options.customHandlers - functions to prepare the data for specific use cases
 */
export const createNestedRouteHandlers = (
  server,
  name,
  parentName,
  options = {}
) => {
  const { namePlural, parentNamePlural, customHandlers = {} } = options;
  const resourceName = namePlural || server.inflector.pluralize(name);
  const parentResourceName =
    parentNamePlural || server.inflector.pluralize(parentName);

  // GET
  const nestedResourceUrl = `/${parentResourceName}/:parentId/${resourceName}`;
  // fetch nested resource
  server.get(
    nestedResourceUrl,
    genGetNestedResourceRouteHandler(server, name, parentName)
  );
  // fetch nested resource by id
  server.get(
    `${nestedResourceUrl}/:id`,
    genGetNestedResourceByIdRouteHandler(server, name, parentName)
  );

  // POST
  // create nested resource
  server.post(
    nestedResourceUrl,
    genCreateNestedResourceRouteHandler(server, name, parentName)
  );

  // PUT
  // update nested resource
  server.put(
    `${nestedResourceUrl}/:id`,
    genUpdateNestedResourceRouteHandler(
      server,
      name,
      parentName,
      customHandlers.beforeUpdate
    )
  );

  // DELETE
  // delete nested resource
  server.delete(
    `${nestedResourceUrl}/:id`,
    genSoftDeleteNestedResourceRouteHandler(server, name, parentName),
    200
  ); // there is a hidden status code parameter not defined in docs
};

/**
 * creates standard route handlers for a resource
 * @param {Server} server - instance of Mirage Server
 * @param {String} name - the name of the model
 * @param {Object} options - options
 * @param {Object} options.namePlural - override pluralized form of resource name. (pluralize library normally handles this)
 * @param {Object} options.orgRoutes - if true organization routes are added (defaults to true)
 * @params {Object} options.customHandlers - functions to prepare the data for specific use cases
 */
export const createRouteHandlers = (server, name, options = {}) => {
  const {
    namePlural,
    orgRoutes = true,
    requiredFields,
    customHandlers = {}
  } = options;
  const resourceName = namePlural || server.inflector.pluralize(name);
  // GET
  // fetch resource
  server.get(`/${resourceName}`, genGetResourceRouteHandler(server, name));
  // fetch resource by id
  server.get(
    `/${resourceName}/:id`,
    genGetResourceByIdRouteHandler(server, name)
  );
  // update resource
  server.put(
    `/${resourceName}/:id`,
    customHandlers.update ||
      genUpdateResourceRouteHandler(
        server,
        name,
        requiredFields,
        customHandlers.beforeUpdate
      )
  );
  // create resource
  server.post(
    `/${resourceName}`,
    genCreateResourceRouteHandler(server, name, requiredFields)
  );

  // delete resource
  server.delete(
    `${resourceName}/:id`,
    genSoftDeleteResourceRouteHandler(server, name)
  );

  // ORGANIZATION ROUTES
  if (orgRoutes) {
    createNestedRouteHandlers(server, name, 'organization', { customHandlers });
  }
};
