import React from 'react';
import PropTypes from 'prop-types';

const MccainCropSeasonIntegration = ({ children }) => {
  return (
    <div
      className="w-full h-full align-center"
      data-testid="mccain-cropseason-integration"
    >
      <div className="flex w-full justify-center items-center">{children}</div>
    </div>
  );
};

MccainCropSeasonIntegration.propTypes = {
  children: PropTypes.node.isRequired
};

export default MccainCropSeasonIntegration;
