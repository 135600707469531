import React, { memo } from 'react';
import PropTypes from 'prop-types';
import Accordion from 'components/Accordion';
import StatusMessage from 'screens/Integrations/SubmissionSummary/components/TopHeader/components/StatusMessage';
import { SubmissionStatus } from 'screens/Integrations/helpers/constants';
import CalAgAccordionHeader from '../../../CalAgAccordionHeader';
import CalAgPurForm from '../CalAgPurForm';

const CalAgFormAccordion = ({
  form,
  toggleCallback,
  selectedId,
  isReadOnly,
  pastSubmissions
}) => {
  const { formId, task, propertyDetails, status, errorMessage } = form;
  const { name, products } = task;
  const { cropZoneName } = propertyDetails;

  const returnProductsLabel = prods =>
    `[${prods.map(p => p.productName).join('], [')}]`;

  const pastSubmission = pastSubmissions.find(
    s => s.cropZoneId === task.cropZoneId && s.taskId === task.taskId
  );

  return (
    <Accordion
      header={
        <CalAgAccordionHeader
          firstLabel={`[${cropZoneName}] [${name}]`}
          secondLabel={returnProductsLabel(products)}
          status={status}
          isTagVisible={
            !isReadOnly ||
            status === SubmissionStatus.ERROR ||
            status === SubmissionStatus.DONE
          }
          pastSubmission={pastSubmission}
        />
      }
      content={
        propertyDetails && task ? (
          <>
            {status === SubmissionStatus.ERROR && (
              <StatusMessage
                submissionResult={{
                  status: SubmissionStatus.ERROR,
                  errorMessage
                }}
              />
            )}
            <CalAgPurForm
              propertyDetails={propertyDetails}
              task={task}
              isReadOnly={isReadOnly}
            />
          </>
        ) : (
          undefined
        )
      }
      isFullWidthButton
      toggleCallback={toggleCallback}
      dynamicToggle
      canExpand={formId === selectedId}
      isProperty={false}
      headerHeight="40px"
    />
  );
};

CalAgFormAccordion.defaultProps = {
  toggleCallback: () => {},
  selectedId: '',
  isReadOnly: false,
  pastSubmissions: []
};

CalAgFormAccordion.propTypes = {
  form: PropTypes.shape({
    formId: PropTypes.string.isRequired,
    task: PropTypes.shape({
      name: PropTypes.string.isRequired,
      taskId: PropTypes.string.isRequired,
      cropZoneId: PropTypes.string.isRequired,
      products: PropTypes.arrayOf(
        PropTypes.shape({
          productName: PropTypes.string.isRequired,
          productId: PropTypes.string.isRequired
        }).isRequired
      )
    }),
    propertyDetails: PropTypes.shape({
      fieldId: PropTypes.string.isRequired,
      fieldName: PropTypes.string.isRequired,
      cropZoneId: PropTypes.string.isRequired,
      cropZoneName: PropTypes.string.isRequired,
      state: PropTypes.string,
      county: PropTypes.string,
      townshipNumber: PropTypes.string,
      townshipDirection: PropTypes.string,
      rangeNumber: PropTypes.string,
      rangeDirection: PropTypes.string,
      geometry: PropTypes.shape({
        type: PropTypes.string.isRequired,
        coordinates: PropTypes.arrayOf(
          PropTypes.arrayOf(PropTypes.arrayOf(PropTypes.number.isRequired))
        ).isRequired
      }).isRequired,
      permitId: PropTypes.string,
      siteId: PropTypes.string,
      status: PropTypes.string.isRequired
    }).isRequired,
    status: PropTypes.string.isRequired,
    errorMessage: PropTypes.string
  }).isRequired,
  toggleCallback: PropTypes.func,
  selectedId: PropTypes.string,
  isReadOnly: PropTypes.bool,
  pastSubmissions: PropTypes.arrayOf(
    PropTypes.shape({
      submissionId: PropTypes.string,
      submittedAt: PropTypes.string,
      internalRecordId: PropTypes.string
    })
  )
};

export default memo(CalAgFormAccordion);
