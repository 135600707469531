import { useContext, useCallback, useState } from 'react';
import axios from 'axios';

import catchCancel from 'helpers/catchCancel';
import { parseServerError } from 'helpers/errorHelpers';
import { Context } from 'components/Store';
import { getAccessToken } from 'utilities/base-auth';
import { CROPWISE_PROXY_V2_URL } from 'utilities/apiConstants';

const StorageLocationsSummary = () => {
  const [{ organization }, dispatch] = useContext(Context);

  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(false);

  // Load tasks
  const fetchStorageLocationsSummary = useCallback(
    ({ cropId, seasonId }) => {
      setLoading(true);
      const args = {
        headers: {
          'Content-Type': 'application/json',
          'cwf-context': JSON.stringify({
            organization_id: organization.id
          }),
          Authorization: `Bearer ${getAccessToken()}`
        },
        params: {
          cropSeasonId: seasonId,
          cropId
        }
      };
      const promise = axios.get(
        `${CROPWISE_PROXY_V2_URL}/yield/summary/storage`,
        args
      );
      promise
        .then(response => {
          setData(response.data);
          setLoading(false);
        })
        .catch(catchCancel)
        .catch(err => {
          parseServerError(dispatch)(err);
          setLoading(false);
        });
    },
    [dispatch, organization.id]
  );

  return { loading, fetchStorageLocationsSummary, storageLocationsInfo: data };
};

export default StorageLocationsSummary;
