import Profile from 'screens/Profile';

const routes = [
  {
    id: 'profile',
    key: 'profile',
    path: '/app/profile',
    exact: true,
    secure: true,
    component: Profile
  }
];

export default routes;
