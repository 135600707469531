import React from 'react';
import PropTypes from 'prop-types';
import { Table } from '@agconnections/grow-ui';

import defaultIcon from 'assets/AllCropSVGs/fallow.svg';
import CropIcons from 'helpers/getCropIconByCropName';
import CropIconMapping from 'components/CropIconMapping';
import toCamelCase from '../../helpers/toCamelCase';

const PropertyCropHistorySummaryRow = ({ data }) => {
  const regex = / /g;
  const scrubbedProduct = {
    ...data,
    name: data?.cropname?.replace(regex, '')
  };

  const cropName = scrubbedProduct?.name?.includes(':')
    ? scrubbedProduct?.name
        .split(':')
        .map(part => toCamelCase(part.trim()))
        .join('')
    : scrubbedProduct?.name;
  return (
    <Table.Row>
      <Table.Cell>
        <div
          style={{
            wordBreak: 'break-word',
            whiteSpace: 'normal',
            overflowWrap: 'break-word'
          }}
        >
          <div>{data?.cropseason}</div>
        </div>
      </Table.Cell>
      <Table.Cell>
        <div className="flex gap-1 h-4">
          {cropName in CropIcons ? (
            <CropIconMapping cropObject={scrubbedProduct} />
          ) : (
            <img src={defaultIcon} alt="" />
          )}
          {data?.cropname}
        </div>
      </Table.Cell>
    </Table.Row>
  );
};

PropertyCropHistorySummaryRow.propTypes = {
  data: PropTypes.shape({
    cropseason: PropTypes.string.isRequired,
    cropZones: PropTypes.arrayOf(PropTypes.object).isRequired,
    cropname: PropTypes.string.isRequired,
    variety: PropTypes.string.isRequired,
    plantingDate: PropTypes.string.isRequired,
    area: PropTypes.string.isRequired
  }).isRequired
};

export default PropertyCropHistorySummaryRow;
