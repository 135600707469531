/* eslint-disable no-param-reassign */
import React, { useContext, useMemo } from 'react';

import events from 'utilities/amplitude/events';
import { getCropZonesFromProperties } from 'helpers/propertyHelpers';
import { Button } from '@agconnections/grow-ui';
import { useHistory } from 'react-router-dom';

import { paths } from 'routes/paths';
import useSubmissionUrlHandler from 'screens/Integrations/hooks/useSubmissionUrlHandler';
import useSubmissionAnalytics from 'screens/Integrations/hooks/useSubmissionAnalytics';
import { SubmissionSummaryContext } from '../../context/SubmissionSummaryProvider';
import IconByStatus from './components/IconByStatus';
import TextByStatus from './components/TextByStatus';

const SuccessPage = () => {
  const {
    state: { integrationType, farms, submissionId, submissionResult }
  } = useContext(SubmissionSummaryContext);

  const { comingFromView } = useSubmissionUrlHandler();

  const history = useHistory();

  const tasksLength = useMemo(() => {
    const cropzones = getCropZonesFromProperties(farms);
    return [
      ...new Map(
        cropzones?.flatMap(cropzone =>
          cropzone.tasks?.map(task => [task.taskId, task])
        )
      ).values()
    ].length;
  }, [farms]);

  useSubmissionAnalytics({
    onMountTriggerEvent: {
      event: events.epic.Integrations.submissionCompleted,
      data: { vendor: integrationType, tasksLength, submissionId }
    }
  });

  const handleRedirectToHistoryPage = () => {
    return history.push(
      `${paths.integrations}/${integrationType}/landing-page`
    );
  };

  return (
    <div
      className="w-full h-full bg-white flex flex-col px-6 py-4 rounded-xl font-body"
      data-testid="success-page"
    >
      <div className="flex justify-center  flex-col h-full">
        <div className="flex text-center items-center flex-col justify-center ">
          <IconByStatus submissionResult={submissionResult} />
          <TextByStatus
            submissionResult={submissionResult}
            integrationType={integrationType}
            comingFromView={comingFromView}
          />
          <div className="flex justify-center">
            {(submissionResult?.httpStatusCode === 207 ||
              submissionResult?.httpStatusCode === 422) && (
              <div className="mr-2">
                <a
                  href={`${paths.integrations}/${integrationType}/submission/${submissionResult.id}`}
                  rel="noreferrer"
                >
                  <Button id="return-viewpage-button" type="primary">
                    View Submission Details
                  </Button>
                </a>
              </div>
            )}
            <Button
              id="return-mainpage-button"
              type={
                submissionResult?.httpStatusCode === 207 ||
                submissionResult?.httpStatusCode === 422
                  ? 'outline'
                  : 'primary'
              }
              onClick={handleRedirectToHistoryPage}
            >
              Go to Submissions History
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SuccessPage;
