import React, { useContext } from 'react';
import { Context } from 'components/Store';
import GrowUIFormField from 'components/Forms/GrowUIFormField';
import { Select } from '@agconnections/grow-ui';
import { pumpEnergySources } from '../DataSets';

const PumpEnergySource = () => {
  const [{ isEnableInfoTabEdit }] = useContext(Context);
  return (
    <GrowUIFormField
      control={Select}
      id="energySource"
      name="energySource"
      items={pumpEnergySources}
      label="Pump Energy Source"
      disabled={!isEnableInfoTabEdit}
      placeholder="Select pump energy source"
    />
  );
};

export default PumpEnergySource;
