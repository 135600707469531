/* eslint-disable react/forbid-prop-types */
import React, { useContext, useState } from 'react';
import PropTypes from 'prop-types';
import { useFormikContext } from 'formik';
import { Input } from '@agconnections/grow-ui';
import { useLocation } from 'react-router-dom';
import useDebounce from 'hooks/useDebounce';
import CustomProductModal from 'components/Modals/CustomProductModal';
import WarningMessage from 'components/WarningMessage';
import { AmplitudeContext } from 'utilities/amplitude/useAmplitude';
import useProductSearch from './helpers/useProductSearch';
import FilterByType from './components/FilterByType';
import ProductsAndServices from './components/ProductsAndServices';
import ProductsSearchModal from './components/ProductsSearchModal';
import ServiceProductForm from './components/ServiceProductForm';
import FertilizerProductForm from './components/FertilizerProductForm';
import AddProductTaskModal from './components/AddProductTaskModal';
import AddProductRecModal from './components/AddProductRecModal';
import AddProductPlanModal from './components/AddProductPlanModal';
import AddProductInvoiceModal from './components/AddProductInvoiceModal';
import { productTypes } from './helpers/constants';
import { mapProductToAdd } from './helpers/productHelper';

const initialProductValuesPlan = (product, values) => ({
  productId: product.id || '',
  density: product.density || 0,
  trackingId: product.trackingId || '',
  productRateValue: product.productRateValue || 0,
  productRateUnit: product.productRateUnit || product.stdunit || '',
  areaValue: product.areaValue || values?.area || 0,
  areaUnit: product.areaUnit || 'acre',
  totalProductValue: product.totalProductValue || 0,
  totalProductUnit: product.totalProductUnit || product.stdpackageunit || '',
  pricingStrategy: product.pricingStrategy || 'SystemDefined',
  specificCostValue: product.specificCostValue || 0,
  specificCostUnit: product.specificCostUnit || product.stdpackageunit,
  totalProductCost: product.totalProductCost || 0,
  coveragePercent: product.coveragePercent || 100,
  applicationCount: product.applicationCount || 1,
  targetDateEpoch: product.targetDateEpoch || '',
  timingEvent: product.timingEvent || '',
  timingEventTag: product.timingEventTag || '',
  manufacturerName: product.manufacturer || '',
  productDensity: product.density || 0,
  productName: product.name || '',
  shareOwnerInformation: {
    percentGrowerResponsibility: 100,
    totalFromGrowerInventoryQuantity: 100,
    totalFromGrowerInventoryUnit: ''
  },
  stdfactor: product.stdfactor || 1,
  stdpackageunit: product.stdpackageunit || '',
  stdunit: product.stdunit || '',
  currency: 'USD',
  custom: product.custom
});

const initialProductValuesInvoice = product => ({
  productId: product.id || '',
  density: product.density,
  trackingId: product.trackingId || '',
  totalQuantityValue: product.totalQuantityValue || 0,
  totalQuantityUnit: product.totalQuantityUnit || product.stdunit || '',
  totalCostValue: product.totalCostValue || 0,
  totalCostCurrency: product.totalCostCurrency || 'USD',
  timingEvent: product.timingEvent || '',
  timingEventTag: product.timingEventTag || '',
  productDensity: product.density || 0,
  productName: product.name || '',
  custom: product.custom,
  manufacturerName: product.manufacturer || ''
});

const ProductsSideBar = ({
  memberRole,
  memberFinancialAccess,
  selectedApplicationOption,
  setCustomProductCreated,
  productFieldName
}) => {
  // disabling the eslint check for this line for LDBAPP-3360 Compiler Messages, can be removed when custom products are re-introduced
  // eslint-disable-next-line no-unused-vars
  const [customProductModalOpened, setCustomProductModalOpened] = useState(
    false
  );
  const amplitude = useContext(AmplitudeContext);
  const { pathname } = useLocation();
  const { values, setFieldValue } = useFormikContext();
  const [searchText, setSearchText] = useState('');
  const [filterValue, setFilterValue] = useState(null);
  const debouncedSearchTerm = useDebounce(searchText, 300);
  const debouncedTypeFilter = useDebounce(filterValue, 300);

  const {
    products,
    filteredProducts,
    loading,
    setLoading,
    setPageNo,
    hasNextPage
  } = useProductSearch(debouncedSearchTerm, debouncedTypeFilter);

  const [productModalOpen, setProductModalOpen] = useState(false);
  const [selectedProduct, setSelectedProduct] = useState(null);
  const [searchWarning, setSearchWarning] = useState(false);

  const closeProductModal = () => {
    setProductModalOpen(false);
    setSelectedProduct(null);
  };

  const toggleCreateCustomProductModal = () => {
    setCustomProductModalOpened(previousState => !previousState);
  };

  const showProductsSearchModal = () => {
    return filteredProducts?.length === 0 || searchText?.length < 3;
  };

  // disabling the eslint check for this line for LDBAPP-3360 Compiler Messages, can be removed when custom products are re-introduced
  // eslint-disable-next-line no-unused-vars
  const selModalTypeForm = selectedValue => {
    if (selectedValue === 'Service') {
      return <ServiceProductForm />;
    }
    return <FertilizerProductForm />;
  };

  const onChangeSearchProducts = e => {
    const inputValue = e.target.value;

    if (inputValue.length >= 3) {
      setLoading(false);
      setSearchText(inputValue);
      setSearchWarning(false);
    } else {
      setSearchWarning(true);
      setSearchText('');
    }
  };

  const handleProdSave = newProduct => {
    const prodToAdd = mapProductToAdd(newProduct, values, pathname);

    setFieldValue(productFieldName, [...values[productFieldName], prodToAdd]);
    setCustomProductModalOpened(false);
    setCustomProductCreated(true);
  };

  const onChangeFilterByType = event => {
    const filter =
      JSON.parse(event.target.value).key !== 'clear'
        ? JSON.parse(event.target.value).key
        : null;
    setFilterValue(filter);
  };

  const displayAddProductModal = () => {
    if (selectedProduct) {
      if (pathname.includes('tasks')) {
        return (
          <AddProductTaskModal
            values={values}
            open={productModalOpen}
            product={selectedProduct}
            onClose={closeProductModal}
            isEdit={false}
            memberRole={memberRole}
            memberFinancialAccess={memberFinancialAccess}
            selectedApplicationOption={selectedApplicationOption}
          />
        );
      }

      if (pathname.includes('recommendations')) {
        return (
          <AddProductRecModal
            values={values}
            open={productModalOpen}
            product={selectedProduct}
            onClose={closeProductModal}
            isEdit={false}
          />
        );
      }

      if (pathname.includes('plans')) {
        return (
          <AddProductPlanModal
            values={values}
            open={productModalOpen}
            product={selectedProduct}
            onClose={closeProductModal}
            isEdit={false}
            memberRole={memberRole}
            memberFinancialAccess={memberFinancialAccess}
          />
        );
      }

      if (pathname.includes('invoices')) {
        return (
          <AddProductInvoiceModal
            values={values}
            open={productModalOpen}
            product={selectedProduct}
            onClose={closeProductModal}
            isEdit={false}
            memberRole={memberRole}
            memberFinancialAccess={memberFinancialAccess}
          />
        );
      }
    }

    return null;
  };

  return (
    <>
      <CustomProductModal
        open={customProductModalOpened}
        onClose={() => setCustomProductModalOpened(false)}
        handleSave={handleProdSave}
        setModalTab="Fertilizer"
      />
      {displayAddProductModal()}
      <div className="w-full">
        <Input
          id="products-search"
          placeholder="Search Products/Services"
          onChange={onChangeSearchProducts}
          onClick={() => setSearchWarning(true)}
        />
        <WarningMessage
          canRender={searchWarning}
          text="Type at least 3 characters to search"
        />
      </div>
      <div className="w-full mt-6">
        <FilterByType
          items={productTypes}
          value={filterValue}
          onChange={onChangeFilterByType}
        />
      </div>
      <div
        className="overflow-scroll"
        style={{ height: 'calc(100% - 64px - 1.5rem)' }}
      >
        {/* <RecentProducts
          addProduct={product => {
            setSelectedProduct(product);
            setProductModalOpen(true);
          }}
          recentProducts={[]}
        /> */}
        <ProductsAndServices
          addProduct={product => {
            if (pathname.includes('plans')) {
              setSelectedProduct(initialProductValuesPlan(product, values));
            } else if (pathname.includes('invoice')) {
              setSelectedProduct(initialProductValuesInvoice(product));
            } else {
              amplitude.sendEventToAmplitude(
                amplitude.events.epic.Tasks.addProduct
              );
              setSelectedProduct(product);
            }
            setProductModalOpen(true);
          }}
          toggleCreateCustomProductModal={toggleCreateCustomProductModal}
          products={filteredProducts}
          isLoading={loading}
          setPageNo={setPageNo}
          hasNextPage={hasNextPage}
        />
        {showProductsSearchModal() && (
          <ProductsSearchModal
            toggleCreateCustomProductModal={toggleCreateCustomProductModal}
            productsLength={products?.length}
            searchTextLength={searchText.length}
            isLoading={loading}
          />
        )}
      </div>
    </>
  );
};

ProductsSideBar.defaultProps = {
  productFieldName: 'products',
  selectedApplicationOption: undefined
};

ProductsSideBar.propTypes = {
  memberRole: PropTypes.string.isRequired,
  memberFinancialAccess: PropTypes.string.isRequired,
  selectedApplicationOption: PropTypes.string,
  setCustomProductCreated: PropTypes.func.isRequired,
  productFieldName: PropTypes.string
};

export default ProductsSideBar;
