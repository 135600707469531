import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { Context } from 'components/Store';
import GrowUIFormField from 'components/Forms/GrowUIFormField';
import { Search } from '@agconnections/grow-ui';

const LandOwnerName = ({ landOwnerList }) => {
  const [{ isEnableInfoTabEdit }] = useContext(Context);
  return (
    <GrowUIFormField
      control={Search}
      id="landOwnerName"
      name="landOwnerName"
      label="Land Owner"
      items={landOwnerList}
      placeholder="Select Land Owner"
      type="text"
      disabled={!isEnableInfoTabEdit}
    />
  );
};
LandOwnerName.propTypes = {
  landOwnerList: PropTypes.arrayOf(PropTypes.object).isRequired
};
export default LandOwnerName;
