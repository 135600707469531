import React from 'react';
import PropTypes from 'prop-types';
import { AddRounded, RemoveRounded, SyncAltRounded } from '@material-ui/icons';

const ChangeSummaryCropAction = ({ action, crop, count }) => {
  const icons = {
    Added: {
      Icon: <AddRounded fontSize="small" />,
      color: 'text-cucumber-green-700'
    },
    TagsAdded: {
      Icon: <AddRounded fontSize="small" />,
      color: 'text-cucumber-green-700'
    },
    TagsDeleted: {
      Icon: <RemoveRounded fontSize="small" />,
      color: 'text-remove'
    },
    Deleted: {
      Icon: <RemoveRounded fontSize="small" />,
      color: 'text-remove'
    },
    Changed: {
      Icon: <SyncAltRounded fontSize="small" />,
      color: 'text-info-dark-blue'
    }
  };

  const actionHandler = act => {
    if (act === 'TagsAdded') {
      return (
        <span className="pl-1 inline-block">{`${crop?.name} (tags added)`}</span>
      );
    }
    if (act === 'TagsDeleted') {
      return (
        <span className="pl-1 inline-block">{`${crop?.name} (${count} tags deleted)`}</span>
      );
    }
    return (
      <span className="pl-1 inline-block">{`${crop?.name} ${action}`}</span>
    );
  };

  return (
    <p
      className={`text-left font-semibold font-body flex items-center leading-4 ${icons[action].color}`}
    >
      {icons[action].Icon}
      {actionHandler(action)}
    </p>
  );
};

ChangeSummaryCropAction.propTypes = {
  action: PropTypes.oneOf([
    'Added',
    'TagsAdded',
    'Deleted',
    'Changed',
    'TagsDeleted'
  ]).isRequired,
  crop: PropTypes.shape().isRequired,
  count: PropTypes.number.isRequired
};

export default ChangeSummaryCropAction;
