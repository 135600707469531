import React, { useEffect, useState } from 'react';
import { useFormikContext } from 'formik';
import useLandingPageData from 'hooks/useLandingPageData';
import { tabsLabels } from '../../helpers/constants';
import useParameters from '../../hook/useParameters';
import PropertiesGrid from '../PropertiesGrid';

const OtherTab = () => {
  const { values } = useFormikContext();
  const { driverId, truckId, billOfLading, templateType } = values;
  const { removeAttributes } = useParameters(
    tabsLabels.other,
    {
      source: true
    },
    templateType
  );
  const [properties, setProperties] = useState([]);

  // this logic will be removed in the future
  // truck and driver name will became from backend
  const { items: equipments, loaded: isTruckLoaded } = useLandingPageData(
    'equipments',
    ['name', 'equipmentType'],
    'name',
    0
  );

  const { items: people, loaded: isDriverLoaded } = useLandingPageData(
    'worker/people',
    ['contactInfo.name.givenName', 'contactInfo.name.surname'],
    'contactInfo.name.givenName',
    0
  );

  const truck = isTruckLoaded
    ? equipments.find(item => item.id === truckId)?.name
    : 'Loading...';

  const driver = isDriverLoaded
    ? people.find(item => item.id === driverId)?.name
    : 'Loading...';

  useEffect(() => {
    setProperties([
      {
        name: 'truck',
        value: truck,
        ghostValue: !isTruckLoaded || !truck
      },
      {
        name: 'driver',
        value: driver,
        ghostValue: !isDriverLoaded || !driver
      },
      {
        name: 'billOfLading',
        values: billOfLading
      },
      'secondaryCommodity'
    ]);
  }, [truck, isTruckLoaded, isDriverLoaded, driver, billOfLading]);

  return (
    <PropertiesGrid
      removeAttributes={removeAttributes}
      properties={properties}
      values={values}
    />
  );
};

export default OtherTab;
