import React from 'react';

import GrowUIFormField from 'components/Forms/GrowUIFormField';
import { Form, Select } from '@agconnections/grow-ui';
import { types } from '../../helpers/constants';

const EquipmentTypeField = () => {
  return (
    <Form.Field label="Type *">
      <GrowUIFormField
        id="equipmentType"
        name="equipmentType"
        control={Select}
        items={types}
      />
    </Form.Field>
  );
};

export default EquipmentTypeField;
