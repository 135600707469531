import React, { useContext, useState, useEffect } from 'react';
import { Input, Row, Col } from 'syngenta-digital-cropwise-react-ui-kit';
import { Field, ErrorMessage, useField, useFormikContext } from 'formik';
import { DatePicker } from '@agconnections/grow-ui';
import GrowUIFormField from 'components/Forms/GrowUIFormField';
import { Context } from 'components/Store';
import { AmplitudeContext } from 'utilities/amplitude/useAmplitude';
import SourceField from '../SourceField';
import DestinationField from '../DestinationField';
import './antd-override.css';

import useStorageLocations from '../../../StorageLocations/hooks/useStorageLocations';
import useSaleLocations from '../../../SaleLocations/hooks/useSaleLocations';
import AddNewLocationDrawer from '../AddNewLocationDrawer';
import SelectPropertiesDrawer from '../../../Loads/components/LoadsFilterDrawer/components/SelectPropertiesFilter/components/SelectPropertiesDrawer';

const BasicInfo = () => {
  const amplitude = useContext(AmplitudeContext);
  const [{ organization }] = useContext(Context);

  const [openDrawer, setOpenDrawer] = useState(false);

  const [addNewSourceDrawerOpen, setAddNewSourceDrawerOpen] = useState(false);
  const [
    addNewDestinationDrawerOpen,
    setAddNewDestinationDrawerOpen
  ] = useState(false);

  const storageLocations = useStorageLocations();
  const saleLocations = useSaleLocations();
  const [
    startedSaleLocationsFetching,
    setStartedSaleLocationsFetching
  ] = useState(false);
  const [storageLocationsList, setStorageLocationsList] = useState([]);

  useEffect(() => {
    storageLocations
      .fetchStorageLocations()
      .then(locations => setStorageLocationsList(locations.data));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    saleLocations.getSaleLocationList(organization.id);
    setStartedSaleLocationsFetching(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const isEverythingLoaded =
    storageLocationsList &&
    startedSaleLocationsFetching &&
    !saleLocations.isLoadingSaleLocationList;

  const [, meta] = useField('name');
  const { values, setFieldValue } = useFormikContext();

  const onDestinationCreated = (locationType, id, name) => {
    if (locationType === 'SALE') {
      saleLocations.getSaleLocationList(organization.id);
      setFieldValue('destination', { id, name, type: locationType });
    } else if (locationType === 'STORAGE') {
      storageLocations.fetchStorageLocations().then(locations => {
        setStorageLocationsList(locations.data);
        setFieldValue('destination', { id, name, type: locationType });
      });
    }
  };

  const onSourceCreated = (_, id, name) => {
    storageLocations.fetchStorageLocations().then(({ data: storagesData }) => {
      setStorageLocationsList(storagesData || []);
      const newStorageData =
        storagesData?.find(storage => storage.id === id) || {};
      setFieldValue('sourceType', 'STORAGE');
      setFieldValue('sources', [
        {
          id,
          name,
          totalStoredLoad: newStorageData.totalStoredLoad || 0,
          capacity: newStorageData.capacity || null
        }
      ]);
    });
  };

  useEffect(() => {
    if (isEverythingLoaded) {
      if (values.sourceType === 'STORAGE') {
        const storageId = values.sources[0]?.id;
        const storage = storageLocationsList?.find(x => x.id === storageId);
        if (storage) setFieldValue('sources', [storage]);
      }

      if (values.sourceType === 'CROP_ZONE' && values.sources.length) {
        setFieldValue('sources', values.sources);
        const totalArea = values.sources.reduce(
          (total, { area = 0 }) => total + Number(area),
          0
        );
        setFieldValue('totalArea', totalArea);
        setFieldValue(
          'cropZones',
          values.sources.map(({ id }) => id)
        );
      }

      if (values.destinationType === 'STORAGE' && values.destination?.id) {
        const storageId = values.destination?.id;
        const name = storageLocationsList?.find(x => x.id === storageId)?.name;
        setFieldValue('destination', {
          id: storageId,
          name,
          type: 'STORAGE'
        });
      }
      if (values.destinationType === 'SALE' && values.destination?.id) {
        const saleId = values.destination?.id;
        const name = saleLocations.saleLocationList.find(x => x.id === saleId)
          ?.name;
        setFieldValue('destination', {
          id: saleId,
          name,
          type: 'SALE'
        });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isEverythingLoaded]);

  const destinationStorageLocations = storageLocationsList?.filter(
    x => !values?.sources?.some(source => source?.id === x.id)
  );

  const sourceStorageLocations = storageLocationsList?.filter(
    x => x.id !== values?.destination?.id
  );

  const onCreateNewSourceStorageButtonClick = () => {
    setAddNewSourceDrawerOpen(true);
    amplitude.sendEventToAmplitude(
      amplitude.events.epic.YieldV2.addYield.createNewSourceStorage,
      {
        page: 'add yield load page'
      }
    );
  };

  return (
    <div>
      {openDrawer && (
        <SelectPropertiesDrawer
          open
          onClose={() => setOpenDrawer(false)}
          type="Load"
        />
      )}
      <h1 className="text-xl leading-7 font-semibold tracking-tight block mb-4">
        Basic Info
      </h1>
      <Row gutter={[24, 24]} prefixCls="syt-antd-row">
        <Col xs={24} sm={24} md={12} lg={12} xl={8} prefixCls="syt-antd-col">
          <div className="flex flex-col gap-1">
            <label
              htmlFor="load-name"
              className="text-neutral-60 tracking-tighter text-sm"
            >
              Load Name<span className="text-remove">*</span>
            </label>
            <Field
              as={Input}
              name="name"
              id="load-name"
              size="middle"
              type="default"
              required
              className={
                meta.error && meta.touched ? 'border border-remove' : ''
              }
            />
            <ErrorMessage name="name" component="div" className="text-remove" />
          </div>
        </Col>
        <Col xs={24} sm={24} md={12} lg={12} xl={8} prefixCls="syt-antd-col">
          <div className="flex flex-col gap-0.5">
            <label
              htmlFor="source-select"
              className="text-neutral-60 tracking-tighter text-sm"
            >
              Source<span className="text-remove">*</span>
            </label>
            <Field name="sources">
              {({ field, form }) => (
                <SourceField
                  id="source-select"
                  value={field.value}
                  storageLocations={sourceStorageLocations}
                  onChange={({ id, name, type, totalStoredLoad, capacity }) => {
                    form.setFieldValue('sourceType', type);
                    form.setFieldValue(field.name, [
                      { id, name, totalStoredLoad, capacity }
                    ]);
                  }}
                  onSelectProperties={() => setOpenDrawer(true)}
                  isLoading={storageLocations?.loading}
                  onCreateNewStorage={onCreateNewSourceStorageButtonClick}
                />
              )}
            </Field>
            <ErrorMessage
              name="sources[0].id"
              component="div"
              className="text-remove"
            />
          </div>
        </Col>
        <Col xs={24} sm={24} md={12} lg={12} xl={8} prefixCls="syt-antd-col">
          <div className="flex flex-col gap-0.5">
            <label
              htmlFor="destination-select"
              className="text-neutral-60 tracking-tighter text-sm"
            >
              Destination<span className="text-remove">*</span>
            </label>
            <Field name="destination">
              {({ field, form }) => (
                <DestinationField
                  id="destination-select"
                  value={field.value}
                  storageLocations={destinationStorageLocations}
                  saleLocations={saleLocations.saleLocationList}
                  onChange={value => form.setFieldValue(field.name, value)}
                  onCreateNewLocation={() =>
                    setAddNewDestinationDrawerOpen(true)
                  }
                  isLoading={
                    storageLocations?.loading ||
                    saleLocations?.isLoadingSaleLocationList
                  }
                />
              )}
            </Field>
            <ErrorMessage
              name="destination.id"
              component="div"
              className="text-remove"
            />
          </div>
        </Col>
        <Col xs={24} sm={24} md={12} lg={12} xl={8} prefixCls="syt-antd-col">
          <div className="flex flex-col gap-0.5">
            <label
              htmlFor="date"
              className="text-neutral-60 tracking-tighter text-sm"
            >
              Date & Time<span className="text-remove">*</span>
            </label>
            <GrowUIFormField
              name="date"
              control={DatePicker}
              enableTime
              dateFormat="M j, Y h:i K"
            />
          </div>
        </Col>
      </Row>
      <AddNewLocationDrawer
        open={addNewDestinationDrawerOpen}
        onClose={() => setAddNewDestinationDrawerOpen(false)}
        onLocationAdded={onDestinationCreated}
      />
      <AddNewLocationDrawer
        open={addNewSourceDrawerOpen}
        onClose={() => setAddNewSourceDrawerOpen(false)}
        onLocationAdded={onSourceCreated}
        isAddStorageOnly
      />
    </div>
  );
};

export default BasicInfo;
