import React from 'react';
import PropTypes from 'prop-types';
import { Table } from '@agconnections/grow-ui';
import DetailTableBody from './DetailTableBody';
import DetailTableHeader from './DetailTableHeader';

const DetailTable = ({ data, setRedirect }) => {
  const tableRedirect = address => {
    setRedirect(address);
  };

  return (
    <Table data-testid="inv-data-table">
      <DetailTableHeader
        sortBy={null}
        colSortDir="asc"
        sortClick={() => {}}
        headerColumns={data?.tableHeader}
      />
      <DetailTableBody
        tableRows={data?.tableRows}
        setRedirect={tableRedirect}
      />
    </Table>
  );
};

DetailTable.propTypes = {
  data: PropTypes.arrayOf(PropTypes.object),
  setRedirect: PropTypes.func
};

DetailTable.defaultProps = {
  data: null,
  setRedirect: null
};

export default DetailTable;
