import React from 'react';
import { Select } from 'syngenta-digital-cropwise-react-ui-kit';
import { ReactComponent as ChevronDown } from 'assets/chevron_down.svg';
import PropTypes from 'prop-types';
import { SHRINK_TYPE } from 'screens/YieldV2/constants';
import { capitalizeName } from 'helpers/textHelpers';

const ShrinkTypeDropDown = ({
  value,
  onChange,
  className,
  isRequired,
  minWidth
}) => {
  const options = Object.values(SHRINK_TYPE).map(option => ({
    value: option,
    label: capitalizeName(option)
  }));
  return (
    <div
      data-testid="shrink-type-dropdown"
      className="relative flex flex-col"
      style={{ minWidth }}
    >
      <Select
        size="middle"
        suffixIcon={<ChevronDown />}
        onChange={onChange}
        value={value}
        dropdownMatchSelectWidth={false}
        className={className}
        optionLabelProp="label"
        required={isRequired}
      >
        {options.map(option => (
          <Select.Option
            key={option.value}
            value={option.value}
            label={option.label}
          >
            {option.label}
          </Select.Option>
        ))}
      </Select>
    </div>
  );
};

ShrinkTypeDropDown.propTypes = {
  value: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  className: PropTypes.string,
  isRequired: PropTypes.bool,
  minWidth: PropTypes.string
};

ShrinkTypeDropDown.defaultProps = {
  value: '',
  className: '',
  isRequired: false,
  minWidth: '165px'
};

export default ShrinkTypeDropDown;
