const cropZoneSplitter = input => {
  const allCropZones = Array.isArray(input) ? input : [input];

  const chunkArray = (array, chunkSize) => {
    const chunks = [];
    for (let i = 0; i < array.length; i += chunkSize) {
      chunks.push(array.slice(i, i + chunkSize));
    }
    return chunks;
  };

  const splitCropZones = allCropZones.flatMap(cropZone => {
    if (cropZone.cropZones.length > 100) {
      const chunks = chunkArray(cropZone.cropZones, 100);
      return chunks.map(chunk => ({
        ...cropZone,
        cropZones: chunk
      }));
    }
    return cropZone;
  });

  return splitCropZones;
};

export default cropZoneSplitter;
