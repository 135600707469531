export const meridianEnum = [
  '',
  'Black Hills',
  'Fifth Principal',
  'Boise',
  'Chickasaw',
  'Choctaw',
  'Cimarron',
  'Copper River',
  'Fairbanks',
  'First Principal',
  'Fourth Principal',
  'Extended',
  'Fourth Principal',
  'Gila and Salt River',
  'Humboldt',
  'Huntsville',
  'Indian',
  'Kateel River',
  'Louisiana',
  'Michigan',
  'Montana Principal',
  'Mount Diablo',
  'Navajo',
  'New Mexico Principal',
  'Saint Helena',
  'Saint Stephens',
  'Salt Lake',
  'San Bernardino',
  'Second Principal',
  'Seward',
  'Sixth Principal',
  'Tallahassee',
  'Third Principal',
  'Uintah',
  'Uniat',
  'Ute',
  'Washington',
  'Willamette',
  'Wind River'
];
export const soilTextures = [
  'clay',
  'silt',
  'sand',
  'loam',
  'siltyClay',
  'sandyClayLoam',
  'siltyClayLoam',
  'clayLoam',
  'siltyLoam',
  'sandyLoam',
  'loamySand'
];
export const hydrologyGroups = ['A', 'B', 'C', 'D', 'A/D', 'B/D', 'C/D'];
export const waterSources = [
  'irrigationDistrict',
  'municipal',
  'onFarmPond',
  'reservoir',
  'river',
  'stream',
  'tailwater',
  'well'
];
export const pumpEnergySources = [
  'diesel',
  'electric',
  'naturalGas',
  'propane'
];
export const irrigationSystems = [
  'basin',
  'border',
  'drip',
  'flood',
  'furrow',
  'linear',
  'microSprinkler',
  'pivot',
  'subsurface',
  'travelingGun',
  'wheelLines'
];
export const tileDrainages = [
  'noTileDrain',
  'standardDensity',
  'standardDensityWithDrainageWaterManagement',
  'highDensity',
  'highDensityWithDrainageWaterManagement'
];

export const states = ['TN', 'KY'];
export const counties = [
  ['Knox', 'Davidson', 'Sevier'],
  ['Benton', 'Caldwell', 'Henry']
];
