/* eslint-disable react/prop-types */
import React, { useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import { useFormikContext } from 'formik';
import _ from 'lodash';
import State from 'screens/Property/PropertiesLanding/PropertyTable/UniqueFields/State';
import County from 'screens/Property/PropertiesLanding/PropertyTable/UniqueFields/County';
import CLUId from 'screens/Property/PropertiesLanding/PropertyTable/UniqueFields/CLUId';
import FarmNumber from 'screens/Property/PropertiesLanding/PropertyTable/UniqueFields/FarmNumber';
import FieldNumber from 'screens/Property/PropertiesLanding/PropertyTable/UniqueFields/FieldNumber';
import TractNumber from 'screens/Property/PropertiesLanding/PropertyTable/UniqueFields/TractNumber';
import Section from 'screens/Property/PropertiesLanding/PropertyTable/UniqueFields/Section';
import FSAArea from 'screens/Property/PropertiesLanding/PropertyTable/UniqueFields/FSAArea';
import PermitId from 'screens/Property/PropertiesLanding/PropertyTable/UniqueFields/PermitId';
import SiteId from 'screens/Property/PropertiesLanding/PropertyTable/UniqueFields/SiteId';
import Notes from 'screens/Property/PropertiesLanding/PropertyTable/UniqueFields/Notes';
import LandOwnerName from 'screens/Property/PropertiesLanding/PropertyTable/UniqueFields/LandOwnerName';
import LandOwnerPercentage from 'screens/Property/PropertiesLanding/PropertyTable/UniqueFields/LandOwnerPercentage';
import TenantPercentage from 'screens/Property/PropertiesLanding/PropertyTable/UniqueFields/TenantPercentage';
import RentType from 'screens/Property/PropertiesLanding/PropertyTable/UniqueFields/RentType';
import Township from 'screens/Property/PropertiesLanding/PropertyTable/UniqueFields/Township';
import TownshipDirection from 'screens/Property/PropertiesLanding/PropertyTable/UniqueFields/TownshipDirection';
import Range from 'screens/Property/PropertiesLanding/PropertyTable/UniqueFields/Range';
import RangeDirection from 'screens/Property/PropertiesLanding/PropertyTable/UniqueFields/RangeDirection';
import Meridian from 'screens/Property/PropertiesLanding/PropertyTable/UniqueFields/Meridian';
import TaxId from 'screens/Property/PropertiesLanding/PropertyTable/UniqueFields/TaxId';
import ParcelNumber from 'screens/Property/PropertiesLanding/PropertyTable/UniqueFields/ParcelNumber';
import ShortDescription from 'screens/Property/PropertiesLanding/PropertyTable/UniqueFields/ShortDescription';
import LegalDescription from 'screens/Property/PropertiesLanding/PropertyTable/UniqueFields/LegalDescription';
import Slope from 'screens/Property/PropertiesLanding/PropertyTable/UniqueFields/Slope';
import SlopeLength from 'screens/Property/PropertiesLanding/PropertyTable/UniqueFields/SlopeLength';
import Soil from 'screens/Property/PropertiesLanding/PropertyTable/UniqueFields/Soil';
import HydrologyGroup from 'screens/Property/PropertiesLanding/PropertyTable/UniqueFields/HydrologyGroup';
import Irrigated from 'screens/Property/PropertiesLanding/PropertyTable/UniqueFields/Irrigated';
import WaterSource from 'screens/Property/PropertiesLanding/PropertyTable/UniqueFields/WaterSource';
import WaterOrigin from 'screens/Property/PropertiesLanding/PropertyTable/UniqueFields/WaterOrigin';
import PumpPressure from 'screens/Property/PropertiesLanding/PropertyTable/UniqueFields/PumpPressure';
import PumpLift from 'screens/Property/PropertiesLanding/PropertyTable/UniqueFields/PumpLift';
import PumpEnergySource from 'screens/Property/PropertiesLanding/PropertyTable/UniqueFields/PumpEnergySource';
import IrrigationSystem from 'screens/Property/PropertiesLanding/PropertyTable/UniqueFields/IrrigationSystem';
import UsePolyacrylamide from 'screens/Property/PropertiesLanding/PropertyTable/UniqueFields/UsePolyacrylamide';
import usePeopleData from 'hooks/usePeopleData';
import useCompanies from 'hooks/useCompanies';
import Accordion from '../components/Accordion';
import Editor from '../components/Editor';

const EditField = ({ field, farm, setEdited }) => {
  const { values, setValues } = useFormikContext();

  const { people, loading: peopleLoading } = usePeopleData();
  const { companies, loading: companiesLoading } = useCompanies();

  const initialValues = useMemo(
    () => ({
      ...field,
      permitId: field?.permitId || farm?.permitId,
      farmNumber: field?.farmNumber || farm?.farmNumber
    }),
    [field, farm]
  );

  useEffect(() => {
    if (field && !peopleLoading && !companiesLoading) {
      setValues(initialValues);
    }
  }, [field, initialValues, peopleLoading, companiesLoading, setValues]);

  useEffect(() => {
    if (_.isEqual(initialValues, values)) {
      setEdited(false);
    } else {
      setEdited(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initialValues, values]);

  if (!field || peopleLoading || companiesLoading) {
    return <></>;
  }

  const peopleList = people?.map(person => {
    return { value: person.name, key: person.id };
  });

  const companiesList = companies?.map(company => {
    return { value: company.name, key: company.id };
  });

  return (
    <>
      <Accordion header="Location">
        <Editor label="State">
          <State />
        </Editor>
        <Editor label="County">
          <County />
        </Editor>
        <Editor label="Land Owner">
          <LandOwnerName
            landOwnerList={[
              { key: 'none', value: 'None' },
              ...peopleList,
              ...companiesList
            ]}
          />
        </Editor>
      </Accordion>
      <Accordion header="FSA">
        <Editor>
          <FarmNumber />
        </Editor>
        <Editor>
          <FieldNumber />
        </Editor>
        <Editor>
          <TractNumber />
        </Editor>
        <Editor suffix="ac">
          <FSAArea />
        </Editor>
        <Editor>
          <CLUId />
        </Editor>
        <Editor>
          <RentType />
        </Editor>
        <Editor suffix="%">
          <LandOwnerPercentage />
        </Editor>
        <Editor suffix="%">
          <TenantPercentage />
        </Editor>
      </Accordion>
      <Accordion header="Regulatory Permit">
        <Editor>
          <PermitId />
        </Editor>
        <Editor>
          <SiteId />
        </Editor>
      </Accordion>
      <Accordion header="Legal">
        <Editor halfWidth>
          <Township />
        </Editor>
        <Editor halfWidth>
          <TownshipDirection />
        </Editor>
        <Editor halfWidth>
          <Range />
        </Editor>
        <Editor halfWidth>
          <RangeDirection />
        </Editor>
        <Editor>
          <Section />
        </Editor>
        <Editor>
          <Meridian />
        </Editor>
        <Editor>
          <TaxId />
        </Editor>
        <Editor>
          <ParcelNumber />
        </Editor>
        <Editor>
          <ShortDescription />
        </Editor>
        <Editor>
          <LegalDescription />
        </Editor>
      </Accordion>
      <Accordion header="Land">
        <Editor suffix="%">
          <Slope />
        </Editor>
        <Editor suffix="ft">
          <SlopeLength />
        </Editor>
        <Editor>
          <Soil />
        </Editor>
        <Editor>
          <HydrologyGroup />
        </Editor>
      </Accordion>
      <Accordion header="Water">
        <Editor>
          <Irrigated />
        </Editor>
        <Editor>
          <WaterSource />
        </Editor>
        <Editor>
          <WaterOrigin />
        </Editor>
        <Editor>
          <IrrigationSystem />
        </Editor>
        <Editor suffix="PSI">
          <PumpPressure />
        </Editor>
        <Editor suffix="ft">
          <PumpLift />
        </Editor>
        <Editor>
          <PumpEnergySource />
        </Editor>
        <Editor>
          <UsePolyacrylamide />
        </Editor>
      </Accordion>
      <Accordion header="Notes">
        <Editor>
          <Notes />
        </Editor>
      </Accordion>
    </>
  );
};

EditField.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  field: PropTypes.object.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  farm: PropTypes.object.isRequired,
  setEdited: PropTypes.func.isRequired
};

export default EditField;
