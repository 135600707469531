import { useCallback, useContext, useState } from 'react';
import { Context } from 'components/Store';
import catchCancel from 'helpers/catchCancel';
import { parseServerError } from 'helpers/errorHelpers';
import { mcCain as mcCainApi } from 'utilities/api';

const useMcCainData = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [, dispatch] = useContext(Context);

  const createMcCain = useCallback(
    payload => {
      setIsLoading(true);

      const { promise } = mcCainApi.create(payload);

      return promise
        .then(data => {
          return data;
        })
        .catch(catchCancel)
        .catch(parseServerError(dispatch))
        .finally(() => setIsLoading(false));
    },

    [dispatch]
  );

  return {
    createMcCain,
    isLoading
  };
};

export default useMcCainData;
