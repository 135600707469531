import React from 'react';
import PropTypes from 'prop-types';

import EmptyStateProduct from 'screens/Inventory/components/EmptyStateProduct';
import DocumentationCard from 'components/DocumentationCard';

const Documentation = ({ dataDocumentation, openFile }) => {
  return dataDocumentation &&
    dataDocumentation?.documentdescriptions?.length > 0 ? (
    <div className="grid grid-cols-auto-fill gap-6">
      {dataDocumentation?.documentdescriptions?.map(
        ({ filename, description, id }) => (
          <DocumentationCard
            key={id}
            description={description}
            fileName={filename}
            openFile={() => openFile(id)}
          />
        )
      )}
    </div>
  ) : (
    <EmptyStateProduct section="documentation" />
  );
};

Documentation.propTypes = {
  dataDocumentation: PropTypes.shape().isRequired,
  openFile: PropTypes.func.isRequired
};

export default Documentation;
