import { formatPercentage } from 'helpers/formatNumber';
import { Maths } from 'helpers/maths';

export default function formatShrinkPercentage(load, shrinkQuantity) {
  let percentage = 0;
  if (load && shrinkQuantity) {
    percentage = Maths.divideSafeRound(shrinkQuantity, load, 4);
  }
  return formatPercentage(percentage);
}
