const buildYieldPayload = values => {
  const { cropZones, ...object } = values;
  const _cropZones = cropZones
    .map(farm => {
      return farm?.fields?.map(field =>
        field.cropzones.map(cropzone =>
          cropzone.cropSeasons.map(cropSeason => ({
            fieldName: field.name,
            farmName: farm.name,
            cropZoneName: cropzone.name,
            seasonId: cropSeason.id,
            cropZoneId: cropzone.id,
            fieldId: field.id,
            farmId: farm.id,
            acres: cropzone.reportedArea || cropzone.boundaryArea
          }))
        )
      );
    })
    .flat(3);

  return {
    ...object,
    cropZones: _cropZones,
    grossRevenue: +object.grossRevenue,
    grossRevenuePerAcre: +(object.grossRevenue / object.totalArea).toFixed(2),
    salePrice: object.salePrice ? object.salePrice : 0
  };
};
export default buildYieldPayload;
