import { useCallback, useContext, useState } from 'react';
import axios from 'axios';
import catchCancel from 'helpers/catchCancel';
import { Context } from 'components/Store';
import { getAccessToken } from 'utilities/base-auth';
import { CROPWISE_PROXY_V2_URL } from 'utilities/apiConstants';

const defaultParams = { sortBy: 'name', sortDir: 'asc' };
const useStorageLocations = () => {
  const [loading, setLoading] = useState(false);
  const [loadingDetails, setLoadingDetails] = useState(false);
  const [saving, setSaving] = useState(false);
  const [deleting, setDeleting] = useState({
    isLoading: false,
    isSuccess: false,
    isError: false
  });
  const [{ organization }] = useContext(Context);

  const fetchStorageLocations = useCallback(
    ({ sortBy, sortDir } = defaultParams) => {
      setLoading(true);
      const promise = axios.get(
        `${CROPWISE_PROXY_V2_URL}/yield/storage-location/list?page=0&sortBy=${sortBy}&sortDir=${sortDir}&limit=0`,
        {
          headers: {
            'Content-Type': 'application/json',
            'cwf-context': JSON.stringify({
              organization_id: organization.id
            }),
            Authorization: `Bearer ${getAccessToken()}`
          }
        }
      );

      return promise
        .then(({ data }) => data)
        .catch(catchCancel)
        .catch(e => e.response)
        .finally(() => setLoading(false));
    },
    [organization]
  );

  const fetchStorageDetails = useCallback(
    id => {
      setLoadingDetails(true);
      const promise = axios.get(
        `${CROPWISE_PROXY_V2_URL}/yield/storage-location/${id}`,
        {
          headers: {
            'Content-Type': 'application/json',
            'cwf-context': JSON.stringify({
              organization_id: organization.id
            }),
            Authorization: `Bearer ${getAccessToken()}`
          }
        }
      );

      return promise
        .then(({ data }) => data)
        .catch(catchCancel)
        .catch(e => e.response)
        .finally(() => setLoadingDetails(false));
    },
    [organization]
  );

  const createStorageLocation = useCallback(
    values => {
      setSaving(true);

      const promise = axios.post(
        `${CROPWISE_PROXY_V2_URL}/yield/storage-location`,
        values,
        {
          headers: {
            'Content-Type': 'application/json',
            'cwf-context': JSON.stringify({
              organization_id: organization.id
            }),
            Authorization: `Bearer ${getAccessToken()}`
          }
        }
      );

      return promise
        .then(({ data }) => data?.id)
        .catch(catchCancel)
        .catch(() => null)
        .finally(() => setSaving(false));
    },
    [organization]
  );

  const updateStorageLocation = useCallback(
    (id, values) => {
      setSaving(true);

      const promise = axios.put(
        `${CROPWISE_PROXY_V2_URL}/yield/storage-location/${id}`,
        values,
        {
          headers: {
            'Content-Type': 'application/json',
            'cwf-context': JSON.stringify({
              organization_id: organization.id
            }),
            Authorization: `Bearer ${getAccessToken()}`
          }
        }
      );

      return promise
        .then(({ data }) => data?.id)
        .catch(catchCancel)
        .catch(() => null)
        .finally(() => setSaving(false));
    },
    [organization]
  );

  const deleteStorageLocation = useCallback(
    id => {
      setDeleting(prev => ({ ...prev, isLoading: true }));
      const promise = axios.delete(
        `${CROPWISE_PROXY_V2_URL}/yield/storage-location/${id}`,
        {
          headers: {
            'Content-Type': 'application/json',
            'cwf-context': JSON.stringify({
              organization_id: organization.id
            }),
            Authorization: `Bearer ${getAccessToken()}`
          }
        }
      );

      return promise
        .then(() => {
          setDeleting(prev => ({ ...prev, isSuccess: true }));
        })
        .catch(catchCancel)
        .catch(e => {
          setDeleting(prev => ({ ...prev, isError: true }));
          return e.response;
        })
        .finally(() => {
          setDeleting(prev => ({ ...prev, isLoading: false }));
        });
    },
    [organization]
  );

  return {
    fetchStorageLocations,
    fetchStorageDetails,
    createStorageLocation,
    updateStorageLocation,
    deleteStorageLocation,
    loading,
    loadingDetails,
    saving,
    deleting,
    setLoading
  };
};

export default useStorageLocations;
