import { useState } from 'react';
import catchCancel from 'helpers/catchCancel';
import axios from 'axios';
import { CROPWISE_PROXY_V2_URL } from 'utilities/apiConstants';
import { getAccessToken } from 'utilities/base-auth';
import { parseServerError } from 'helpers/errorHelpers';

const useLoads = () => {
  const [loading, setLoading] = useState(false);

  const getYieldCropOverview = async ({ fieldId, cropZoneId }) => {
    setLoading(true);
    const orgId = localStorage.getItem('selectedOrganizationId');
    const cropSeasonIds = fieldId
      ? JSON.parse(localStorage.getItem('selectedCropSeasons'))
      : undefined;
    const urlSegment = cropZoneId
      ? `cropzone/${cropZoneId}`
      : `field/${fieldId}`;
    return axios
      .get(
        `${CROPWISE_PROXY_V2_URL}/yield/summary/${urlSegment}/crop-overview`,
        {
          headers: {
            'Content-Type': 'application/json',
            'cwf-context': JSON.stringify({
              organization_id: orgId
            }),
            Authorization: `Bearer ${getAccessToken()}`
          },
          params: {
            cropSeasonIds
          }
        }
      )
      .then(({ data }) => {
        return data;
      })
      .catch(catchCancel)
      .catch(err => {
        parseServerError(err);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return {
    getYieldCropOverview,
    loading
  };
};

export default useLoads;
