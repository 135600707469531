const getFieldsFromFarms = farms => {
  return farms?.flatMap(farm => farm.fields) ?? [];
};

const getCropZonesFromFarms = farms => {
  return (
    farms?.flatMap(farm => farm.fields?.flatMap(field => field.cropzones)) ?? []
  );
};

const getTasksFromFarms = farms => {
  return getCropZonesFromFarms(farms)?.flatMap(cropzone =>
    cropzone.tasks?.map(task => ({ ...task, cropZoneId: cropzone.id }))
  );
};

export { getFieldsFromFarms, getCropZonesFromFarms, getTasksFromFarms };
