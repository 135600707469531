import React from 'react';
import PropTypes from 'prop-types';

const ViewOnlyField = ({ className, testId, header, data, text }) => {
  let textSize = '';
  if (text === undefined) textSize = 'text-2xl';
  return (
    <div data-testid={testId} className={className}>
      <div className="text-gray-600">{header}</div>
      <div className={textSize}>{data}</div>
    </div>
  );
};
ViewOnlyField.defaultProps = {
  className: '',
  header: '',
  data: '',
  testId: '',
  text: ''
};
ViewOnlyField.propTypes = {
  className: PropTypes.string,
  header: PropTypes.string,
  data: PropTypes.string,
  testId: PropTypes.string,
  text: PropTypes.string
};

export default ViewOnlyField;
