import { Factory } from 'miragejs';
import faker from 'faker';

import globalSchema from '../utils/globalSchema';

const skyConditions = [
  'clear',
  'becomingClear',
  'mostlyClear',
  'becomingMostlyClear',
  'partlyCloudy',
  'becomingPartlyCloudy',
  'partlyClear',
  'becomingPartlyClear',
  'mostlyCloudy',
  'becomingMostlyCloudy',
  'cloudy',
  'becomingCloudy',
  'fair',
  'becomingFair',
  'fewClouds',
  'fewCloudsDeveloping',
  'scatteredClouds',
  'scatteredCloudsDeveloping',
  'brokenOvercast',
  'becomingBrokenOvercast',
  'overcast',
  'becomingOvercast',
  'clouds',
  'decreasingClouds',
  'increasingClouds',
  'variableClouds',
  'numerousClouds',
  'numerousCloudsDeveloping',
  'sunny',
  'becomingSunny',
  'mostlySunny',
  'becomingMostlySunny',
  'partlySunny',
  'becomingPartlySunny',
  'considerableCloudiness',
  'becomingConsiderableCloudiness',
  'considerableCloudinessDeveloping'
];

const windDirections = [
  'variableWinds',
  'N',
  'NE',
  'NNE',
  'NW',
  'NNW',
  'S',
  'SE',
  'SSE',
  'SW',
  'SSW',
  'E',
  'ENE',
  'ESE',
  'W',
  'WSW',
  'WNW'
];

const Weather = {
  eventDateTime() {
    return faker.date.future();
  },
  startDateTime() {
    return faker.date.future();
  },
  endDateTime() {
    return faker.date.future();
  },
  temperatureUnit() {
    return faker.random.arrayElement(['°F', '°C', '°']);
  },
  temperature() {
    if (this.temperatureUnit() === '°F')
      return faker.random.number({ min: -50, max: 100 });
    return faker.random.number({ min: -10, max: 40 });
  },
  relativeHumidity() {
    return faker.random.number(100);
  },
  windSpeedUnit() {
    return faker.random.arrayElement(['mph', 'km/h']);
  },
  windSpeed() {
    if (this.windSpeedUnit() === 'mph') return faker.random.number(200);
    return faker.random.number(300);
  },
  windDirection() {
    return faker.random.arrayElement(windDirections);
  },
  skyCondition() {
    return faker.random.arrayElement(skyConditions);
  },
  ...globalSchema
};

export default Factory.extend(Weather);
