import React from 'react';

import AgrianLogo from 'assets/integrations/AgrianLogo.svg';
import SubmissionHistory from 'screens/Integrations/SubmissionHistory';
import { INTEGRATIONS } from 'screens/Integrations/helpers/constants';
import Provider from 'screens/Integrations/SubmissionHistory/context/Provider';
import LandingPage from 'screens/Integrations/components/LandingPage';
import Instructions from 'screens/Integrations/components/Instructions';

const AgrianLandingPage = () => {
  return (
    <>
      <LandingPage
        headerText={`${INTEGRATIONS.agrian} Processor Integration`}
        breadcrumbText={INTEGRATIONS.agrian}
        ownerText={INTEGRATIONS.agrian}
        targetPath={INTEGRATIONS.agrian}
        LogoImg={AgrianLogo}
      />
      <div className="w-full border-t-1 mb-5" />
      <Provider integrationType={INTEGRATIONS.agrian}>
        <SubmissionHistory />
      </Provider>
      <Instructions />
    </>
  );
};

export default AgrianLandingPage;
