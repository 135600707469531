import React, { useCallback, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import SaleIcon from 'assets/sale.svg';
import { Select } from 'syngenta-digital-cropwise-react-ui-kit';
import { Link, useHistory } from 'react-router-dom';
import { Button } from '@agconnections/grow-ui';
import { ReactComponent as ChevronDown } from 'assets/chevron_down.svg';
import {
  MEASUREMENT_UNITS,
  convertUnit,
  getAbbreviatedUnit
} from 'helpers/unitsAbbreviation';
import { getBushelWeight } from 'helpers/getBushelWeight';
import { paths } from 'routes/paths';
import useSalesSummary from '../../hooks/useSalesSummary';
import SummaryCard from '../SummaryCard';

const sortingOptions = [
  {
    label: 'Highest quantity',
    value: 'highest_quantity'
  },
  {
    label: 'Lowest quantity',
    value: 'lowest_quantity'
  },
  {
    label: 'Highest revenue',
    value: 'highest_revenue'
  },
  {
    label: 'Lowest revenue',
    value: 'lowest_revenue'
  }
];

const currencyTemplate = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD'
});

function SaleLocations({
  cropSeasonId,
  cropId,
  className,
  selectedUOM,
  selectedCropName,
  selectedCropId
}) {
  const history = useHistory();

  const [sortValue, setSortValue] = useState('highest_quantity');
  const { loading, onSortChange, salesSummary } = useSalesSummary({
    cropSeasonId,
    cropId
  });

  const convertQuantity = useCallback(
    quantity => {
      let convertedQuantity;
      if (selectedUOM === MEASUREMENT_UNITS.BUSHEL) {
        const poundsPerBushel = getBushelWeight(selectedCropName);
        convertedQuantity = quantity / poundsPerBushel;
      } else {
        convertedQuantity = convertUnit(
          quantity,
          MEASUREMENT_UNITS.POUND,
          selectedUOM
        );
      }
      return convertedQuantity.toLocaleString('en-US', {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2
      });
    },
    [selectedUOM, selectedCropName]
  );

  const salesValues = useMemo(() => {
    if (salesSummary) {
      return {
        saleCount: salesSummary.saleCount,
        totalSoldQuantity: convertQuantity(salesSummary.totalSoldQuantity),
        sales: salesSummary.sales?.map(sale => ({
          id: sale.id,
          name: sale.name,
          soldQuantity: convertQuantity(sale.soldQuantity),
          grossRevenue: currencyTemplate.format(sale.grossRevenue)
        }))
      };
    }
    return {
      saleCount: 0,
      totalSoldQuantity: 0,
      sales: []
    };
  }, [salesSummary, convertQuantity]);

  const seeLoadsInSale = () => {
    const filters = {
      loadtype: [
        {
          sourceType: 'CROP_ZONE',
          destinationType: 'SALE'
        },
        {
          sourceType: 'STORAGE',
          destinationType: 'SALE'
        }
      ]
    };
    history.push(
      `${
        paths.yieldV2
      }/loads?cropsFilters=${selectedCropId}&filters=${JSON.stringify(filters)}`
    );
  };

  return (
    <SummaryCard
      className={className}
      isLoading={loading}
      header={
        <div className="flex justify-between py-3 px-4">
          <div className="flex gap-2 items-center">
            <img src={SaleIcon} alt="Sale icon" />
            <div className="text-base whitespace-no-wrap">Sale Locations</div>
          </div>
          <div className="flex items-center">
            <div className="text-sm text-neutral mr-2 whitespace-no-wrap">
              Sort by
            </div>
            <div className="mr-6">
              <Select
                size="middle"
                suffixIcon={<ChevronDown />}
                onChange={newSortValue => {
                  setSortValue(newSortValue);
                  onSortChange(newSortValue);
                }}
                value={sortValue}
                className="w-160px"
              >
                {sortingOptions.map(option => (
                  <Select.Option
                    key={option.value}
                    value={option.value}
                    label={option.label}
                    className="whitespace-no-wrap"
                  >
                    {option.label}
                  </Select.Option>
                ))}
              </Select>
            </div>
            <Button onClick={seeLoadsInSale} type="outline" size="md">
              <span className="whitespace-no-wrap">See Loads in Sale</span>
            </Button>
          </div>
        </div>
      }
      content={
        <div className="flex flex-col px-8 py-4">
          <div className="mb-4">
            You have loads in {salesValues.saleCount} Sale Locations.
          </div>
          <div className="flex gap-6">
            {salesValues.sales.map(
              ({ id, name, grossRevenue, soldQuantity }) => (
                <Link
                  key={id}
                  to={`${paths.yieldV2}/sale-locations?saleId=${id}`}
                  className="hover:text-black"
                >
                  <div
                    key={id}
                    className="flex flex-col gap-2 border-1 rounded-lg border-neutral-20 p-4 min-w-165px cursor-pointer hover:bg-selectedBlue"
                  >
                    <div className="flex gap-2 items-center">
                      <img src={SaleIcon} alt="Sale icon" />
                      <div className="text-base">{name}</div>
                    </div>
                    <div>
                      <div className="text-xs text-neutral">Gross Revenue</div>
                      <div className="text-sm">{grossRevenue}</div>
                    </div>
                    <div>
                      <div className="text-xs text-neutral">Sold Quantity</div>
                      <div className="text-sm ">
                        {soldQuantity} {getAbbreviatedUnit(selectedUOM)}
                      </div>
                    </div>
                  </div>
                </Link>
              )
            )}
          </div>
        </div>
      }
      footer={
        <div className="flex justify-end items-center py-3 px-4">
          <div className="text-sm mr-3">Total quantity sold</div>
          <div className="text-lg mr-1 font-semibold">
            {salesValues.totalSoldQuantity}
          </div>
          <div className="text-sm">{getAbbreviatedUnit(selectedUOM)}</div>
        </div>
      }
    />
  );
}

SaleLocations.propTypes = {
  cropSeasonId: PropTypes.string.isRequired,
  cropId: PropTypes.string.isRequired,
  className: PropTypes.string,
  selectedUOM: PropTypes.string.isRequired,
  selectedCropName: PropTypes.string.isRequired,
  selectedCropId: PropTypes.string.isRequired
};

SaleLocations.defaultProps = {
  className: ''
};

export default SaleLocations;
