import { useEffect, useContext, useState } from 'react';
import catchCancel from 'helpers/catchCancel';
import { parseServerError } from 'helpers/errorHelpers';
import { newProxyReiphisData as reiphisAPI } from 'utilities/api';
import { Context } from 'components/Store';

const useREIPHIData = id => {
  const [, dispatch] = useContext(Context);
  const [reiphiData, setReiphiData] = useState();
  const [reiphiLoading, setReiphiLoading] = useState(true);
  const [saving, setSaving] = useState(false);
  const [deleting, setDeleting] = useState(false);
  const getREIPHIData = () => {
    const { promise } = reiphisAPI.fetch(id);
    promise
      .then(result => {
        setReiphiData(result.data);
        setReiphiLoading(false);
      })
      .catch(catchCancel)
      .catch(err => {
        parseServerError(dispatch)(err);
        setReiphiLoading(false);
      });
  };

  const saveREIPHI = (prodId, values, isEdit, callBack) => {
    setSaving(true);
    const cropId = values?.cropid || values?.cropId;
    const payLoad = {
      rei: values?.rei,
      phi: values?.phi,
      reiu: values?.reiu,
      phiu: values?.phiu
    };
    const url = `reiphi/${prodId}/${cropId}`;
    const updateCrop = isEdit && values?.source === 'CWF';
    const saveApi = reiphisAPI.createChildApi({
      action: url
    });
    const { promise } = updateCrop
      ? saveApi.update('', payLoad)
      : saveApi.create(payLoad);
    return promise
      .then(result => {
        callBack({ ...result.data, i: values?.i, cropName: values?.cropName });
      })
      .catch(catchCancel)
      .catch(err => {
        parseServerError(dispatch)(err);
      })
      .finally(() => setSaving(false));
  };

  const deleteREIPHI = (reiphi, callBack) => {
    setDeleting(true);
    const cropId = reiphi?.cropid || reiphi.cropId;
    const deleteId = `${reiphi.productId}/${cropId}`;
    const toDelete = { ...reiphi };
    delete toDelete.i;
    const { promise } = reiphisAPI.delete(deleteId, toDelete);
    return promise
      .then(() => {
        callBack(true);
      })
      .catch(catchCancel)
      .catch(err => {
        parseServerError(dispatch)(err);
      })
      .finally(() => setDeleting(false));
  };

  useEffect(() => {
    getREIPHIData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return {
    reiphiData,
    setReiphiData,
    reiphiLoading,
    saveREIPHI,
    deleteREIPHI,
    saving,
    deleting
  };
};

export default useREIPHIData;
