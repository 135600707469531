/* eslint-disable no-nested-ternary */
import React, { useContext, useState } from 'react';
import PropTypes from 'prop-types';
import { Spinner } from '@agconnections/grow-ui';
import {
  EmptyProperties,
  PanelHeader,
  PropertyTreeView
} from '../components/PanelComponents';
import PropertyFilters from '../components/PropertyTreeView/components/PropertyFilters';
import PanelFooter from '../components/PanelFooter';
import PropertyLandingContext from '../context';

const PropertiesTree = ({
  properties,
  rawProperties,
  openCreateModal,
  memberRole
}) => {
  const {
    isLoadingProperties,
    viewMode,
    financialAccess,
    fetchProperties,
    onViewModeChange
  } = useContext(PropertyLandingContext);
  const [filtersOpen, setFiltersOpen] = useState(false);

  const onOpenFilters = () => setFiltersOpen(true);
  const onCloseFilters = () => setFiltersOpen(false);

  return (
    <div
      data-testid="property-landing-property-tree"
      className="h-full bg-white cursor-default flex flex-col outline-r outline-neutral-20"
    >
      {filtersOpen ? (
        <PropertyFilters properties={rawProperties} onClose={onCloseFilters} />
      ) : (
        <>
          <PanelHeader
            showTools={!!properties?.length}
            title="Properties"
            reloadFarmData={fetchProperties}
            openCreateModal={openCreateModal}
            openFilters={onOpenFilters}
            loading={isLoadingProperties}
            onChangeView={onViewModeChange}
            view={viewMode}
          />

          <div className="w-full flex flex-grow overflow-y-auto">
            {isLoadingProperties && <Spinner />}
            {!isLoadingProperties && properties?.length > 0 && (
              <PropertyTreeView properties={properties} />
            )}
            {!isLoadingProperties && !properties?.length && (
              <EmptyProperties
                openCreateModal={openCreateModal}
                memberRole={memberRole}
                memberFinancialAccess={financialAccess}
              />
            )}
          </div>

          <PanelFooter
            showTools={!!properties?.length}
            reloadFarmData={fetchProperties}
            openCreateModal={openCreateModal}
            setView={onViewModeChange}
          />
        </>
      )}
    </div>
  );
};

PropertiesTree.defaultProps = {
  properties: [],
  rawProperties: []
};

PropertiesTree.propTypes = {
  properties: PropTypes.arrayOf(PropTypes.object),
  rawProperties: PropTypes.arrayOf(PropTypes.object),
  openCreateModal: PropTypes.func.isRequired,
  memberRole: PropTypes.string.isRequired
};

export default PropertiesTree;
