/* eslint-disable react/jsx-curly-brace-presence */
import React from 'react';
import { useFormikContext } from 'formik';
import MccainFarm from '../MccainFarm';

const MccainSummaryFarms = () => {
  const { values, setFieldValue } = useFormikContext();
  const { selectedProperties, selectedFarm } = values;

  const handleSelectedFarm = i => {
    setFieldValue('selectedFarm', i);
  };
  const scroll = selectedProperties?.length > 10 ? 'overflow-y-scroll' : '';

  return (
    <div
      className={`bg-info-light-blue w-48 overflow-auto ${scroll} max-h-200 h-auto rounded rounded-xl py-4 font-body`}
      data-testid="mccain-summary-farms"
    >
      {selectedProperties?.map((farm, i) => {
        return (
          <MccainFarm
            key={farm.id}
            farmName={farm.name}
            farmFields={farm.fields}
            isFarmSelected={selectedFarm === i}
            handleSelectedFarm={() => handleSelectedFarm(i)}
          />
        );
      })}
    </div>
  );
};

export default MccainSummaryFarms;
