import React from 'react';
import PropTypes from 'prop-types';

const SaleLocationsListItem = ({ element, isSelected }) => {
  const { name, company, city, state } = element || {};
  const { name: companyName } = company || {};
  const divider = () => {
    return <div className="h-20px w-1px bg-neutral-20 mx-2" />;
  };
  return (
    <div className="w-full h-56px pl-2">
      <div className="flex flex-row h-full items-center">
        {isSelected && (
          <div
            data-testid="selected-indicator"
            className="w-1 h-90/100 flex-shrink-0 rounded-r-md bg-blue-50 absolute left-0"
          />
        )}
        <div className="flex flex-col justify-start">
          <p
            className={`text-left text-base ${
              isSelected ? 'font-semibold' : 'font-normal'
            }`}
          >
            {name}
          </p>
          <div>
            <p
              className="text-sm text-left font-normal flex flex-row items-center text-neutral-60 pt-2"
              data-testid="sub-element"
            >
              {[
                companyName,
                city && companyName && (
                  <>
                    {divider()}
                    {city}
                    {state && ', '}
                  </>
                ),
                city && !companyName && (
                  <>
                    {city}
                    {state && ', '}
                  </>
                ),
                !city && ' ',
                state && (
                  <>
                    {companyName && !city && divider()}
                    {state}
                  </>
                )
              ]}
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

SaleLocationsListItem.propTypes = {
  element: PropTypes.shape({
    name: PropTypes.string.isRequired,
    company: PropTypes.shape({
      name: PropTypes.string
    }),
    city: PropTypes.string,
    state: PropTypes.string
  }).isRequired,
  isSelected: PropTypes.bool.isRequired
};

SaleLocationsListItem.defaultProps = {};

export default SaleLocationsListItem;
