import React, { useContext, useEffect } from 'react';
import PropTypes from 'prop-types';

import { parseClientError } from 'helpers/errorHelpers';
import { Context } from '../../Store';

/**
 * a component of consistent size used to display error messages, for example in the form footer
 * @param error - truthy indicator that there is an error to be displayed
 * @param consoleMessage - if provided, the error message to be displayed in the console
 *                          otherwise, the value of error will be displayed
 * @param toastMessage - if provided, the message that will be displayed in an alert toast
 * @returns {*}
 * @constructor
 */
const ErrorMessageConsole = ({ error, consoleMessage, toastMessage }) => {
  const [, dispatch] = useContext(Context);

  useEffect(() => {
    if (error && toastMessage) {
      parseClientError(dispatch)(toastMessage);
    }
  }, [dispatch, error, toastMessage]);

  return (
    <div
      className="flex flex-col align-center justify-center mx-4"
      role="alert"
    >
      <div
        title={consoleMessage || error || ''}
        className={`gr-text-red-600 text-sm whitespace-no-wrap truncate ${
          error ? 'visible' : 'invisible'
        }`}
      >
        {consoleMessage || error || '&nbsp;'}
      </div>
    </div>
  );
};

ErrorMessageConsole.defaultProps = {
  error: false,
  consoleMessage: null,
  toastMessage: null
};

ErrorMessageConsole.propTypes = {
  error: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
  consoleMessage: PropTypes.string,
  toastMessage: PropTypes.string
};

export default ErrorMessageConsole;
