import React from 'react';
import PropTypes from 'prop-types';

const SectionHeader = ({ title, subtitle, children, actions }) => {
  return (
    <div
      data-testid="equipment-item-section-header"
      className="flex items-center w-full"
    >
      {children && (
        <div data-class="py-2" className="w-10 h-10 text-center rounded">
          <div className="flex items-center h-full align-middle">
            {children}
          </div>
        </div>
      )}
      <div className="flex-1">
        <div className="font-bold text-syngenta-navy">{title}</div>
        <div className="text-xs text-gray-600">{subtitle}</div>
      </div>

      {actions}
    </div>
  );
};

SectionHeader.defaultProps = {
  title: '',
  subtitle: '',
  children: null,
  actions: null
};

SectionHeader.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.object),
    PropTypes.object
  ]),
  actions: PropTypes.node
};

export default SectionHeader;
