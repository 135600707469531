import React, { createContext, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { fromPairs } from 'lodash';
import { Spinner, Table } from '@agconnections/grow-ui';
import TableHeader from './components/TableHeader';

import withEmptyList from './_HOC_/withEmptyTable';

const LandingTableContext = createContext({ items: [], tableColumns: [] });

const createParentRow = (item, depth) => {
  return {
    ...item,
    $isParent: true,
    $numOfChildren: item.$nestedItems.length,
    $depth: depth
  };
};

const mapParentRow = (parent, parents, maxDepth, mapItemsToRows, depth) => {
  const { id, $nestedItems } = parent;
  const meta = { totalVisibleDescendants: 0 };
  const newParentRow = createParentRow(parent, depth);
  let childrenRows = [];
  if (parents[id] && parents[id].$isOpen) {
    const [_childrenRows, { totalVisibleDescendants }] = mapItemsToRows(
      $nestedItems,
      parents,
      maxDepth,
      { $parentId: id },
      depth + 1
    );
    childrenRows = _childrenRows;

    // UPDATE meta data
    meta.totalVisibleDescendants = totalVisibleDescendants;
  }

  if (parents[id]) newParentRow.$isOpen = parents[id].$isOpen;

  // ADD meta data to ParentRow
  newParentRow.$totalVisibleDescendants = meta.totalVisibleDescendants;
  return [[newParentRow, ...childrenRows], meta];
};

/**
 * Used to create the final rows that are added to the LandTable.Context
 * @param {Array} items - original list of items
 * @param {Object} parentRows - and object which stores which parent rows (i.e. rows with nested children) are open or closed and other status information
 * @param {Number} maxDepth - sets max depth
 * @param {Number} _depth - used internally to control depth
 */
const mapItemsToRows = (
  items,
  parentRows,
  maxDepth,
  { $parentId } = {},
  _depth = 0
) => {
  let rows = [];
  const meta = { totalVisibleDescendants: 0 };
  if (_depth < maxDepth && items && items.length) {
    items.forEach(item => {
      const { id, $nestedItems, ...rest } = item;
      if ($nestedItems) {
        const [newRows, childMeta] = mapParentRow(
          item,
          parentRows,
          maxDepth,
          mapItemsToRows,
          _depth
        );
        rows = [...rows, ...newRows];
        meta.totalVisibleDescendants += childMeta.totalVisibleDescendants;
      } else {
        const newRow = { ...rest, id, $depth: _depth };
        if ($parentId) newRow.$parentId = $parentId;
        rows.push(newRow);
      }
      meta.totalVisibleDescendants += 1;
    });
  }

  return _depth === 0 ? rows : [rows, meta];
};

/**
 * Creates a Key Value Store of Parent Rows assigned by Id
 * Parent rows are rows with children (i.e. nested items)
 * @param {Array} items - the original list of items passed to the table
 */
const createParentRowsFromItems = (items, maxDepth, _depth = 0) => {
  let parents = new Map();
  if (items && items.length && _depth < maxDepth) {
    items.forEach(({ $nestedItems, id }) => {
      if ($nestedItems) {
        const kvp = [id, { id, $nestedItems, $isOpen: false }];
        const nestedMap = createParentRowsFromItems(
          $nestedItems,
          maxDepth,
          _depth + 1
        );
        parents = new Map([...parents, kvp, ...nestedMap]);
      }
    });
  }
  /**
   * Lot going on in this next line so I'll explain fromPairs is a lodash function to turn key value pairs(KVP) into Objects.
   * https://lodash.com/docs/4.17.15#fromPairs
   * Im using fromPairs instead of Object.fromEntries because fromEntries is not supported in IE
   * https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Object/fromEntries#Browser_compatibility
   * I used a map because it only allows unique KVPs (no dups)
   *
   * Finally I return parents id the depth is "deeper" than 0 which is the first level. Because this is a recursive function
   * that means that we reached the end and have popped off all the stacks. We just return <Map>'s at the lower levels, will
   * we get back home.
   */
  return _depth === 0 ? fromPairs(Array.from(parents)) : parents;
};

export const RawLandingTable = ({
  items,
  children,
  isLoading,
  loadingMessage,
  maxDepth,
  tableColumns,
  onSort,
  sortBy,
  sortDir,
  simpleSortMode,
  wrapperClassName
}) => {
  const [parentRows, setParentRows] = useState({});
  const [rows, setRows] = useState([]);

  useEffect(() => {
    setParentRows(createParentRowsFromItems(items, maxDepth));
  }, [items, maxDepth]);

  useEffect(() => {
    setRows(mapItemsToRows(items, parentRows, maxDepth));
  }, [items, parentRows, maxDepth]);

  const toggleRow = id => {
    const parentRow = parentRows[id];
    if (parentRow)
      setParentRows({
        ...parentRows,
        [id]: { ...parentRow, $isOpen: !parentRow.$isOpen }
      });
  };

  return (
    <div className={`h-full ${wrapperClassName}`} data-testid="landing-table">
      <LandingTableContext.Provider value={{ rows, toggleRow }}>
        <Table>
          <TableHeader
            tableColumns={tableColumns}
            onSort={onSort}
            sortBy={sortBy}
            sortDir={sortDir}
            simpleSortMode={simpleSortMode}
          />
          <LandingTableContext.Consumer>
            {children}
          </LandingTableContext.Consumer>
        </Table>
        {isLoading && (
          <div className="w-full h-full flex flex-col items-center justify-center left-0 top-0 absolute">
            <Spinner size="lg" />
            <span className="font-semibold text-base">{loadingMessage}</span>
          </div>
        )}
      </LandingTableContext.Provider>
    </div>
  );
};

// for nested proptypes
function lazyFunction(f) {
  function lazyWrapper(...args) {
    return f().apply(this, args);
  }
  return lazyWrapper;
}
const ItemShape = PropTypes.shape({
  id: PropTypes.string.isRequired,
  $nestedItems: PropTypes.arrayOf(lazyFunction(() => ItemShape))
});

RawLandingTable.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  children: PropTypes.func,
  items: PropTypes.arrayOf(ItemShape),
  isLoading: PropTypes.bool,
  loadingMessage: PropTypes.string,
  maxDepth: PropTypes.number,
  tableColumns: PropTypes.arrayOf(PropTypes.object),
  onSort: PropTypes.func,
  sortBy: PropTypes.string,
  sortDir: PropTypes.string,
  simpleSortMode: PropTypes.bool,
  wrapperClassName: PropTypes.string
};
RawLandingTable.defaultProps = {
  children: null,
  items: [],
  isLoading: false,
  loadingMessage: '',
  maxDepth: 3,
  tableColumns: [],
  onSort: () => {},
  sortBy: '',
  sortDir: '',
  simpleSortMode: false,
  wrapperClassName: ''
};

export default withEmptyList(RawLandingTable);
export { LandingTableContext };
