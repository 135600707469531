import React from 'react';
import PropTypes from 'prop-types';
import { StyleSheet, Text, View } from '@react-pdf/renderer';

const styles = StyleSheet.create({
  container: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    backgroundColor: '#F3F4F6',
    width: '100%',
    padding: '6px 9px'
  },
  text: {
    fontFamily: 'Noto Sans',
    fontSize: 9,
    fontWeight: 'medium'
  }
});

const TableHeader = ({ items }) => {
  return (
    <View style={styles.container}>
      {items.map(item => (
        <Text
          style={[
            styles.text,
            {
              width: `${item.width}%`,
              textAlign: item.alignment
            }
          ]}
          key={item.label}
        >
          {item.label}
        </Text>
      ))}
    </View>
  );
};

TableHeader.propTypes = {
  items: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      width: PropTypes.number.isRequired
    })
  )
};

TableHeader.defaultProps = {
  items: []
};

export default TableHeader;
