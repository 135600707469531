import { useContext, useEffect, useState, useCallback } from 'react';
import { taskProducts as taskProductsApi } from 'utilities/api';
import catchCancel from 'helpers/catchCancel';
import { parseServerError } from 'helpers/errorHelpers';
import { Context } from 'components/Store';

const useTaskProducts = defaultParams => {
  const [, dispatch] = useContext(Context);
  const [taskProducts, setTaskProducts] = useState([]);
  const [loading, setLoading] = useState(true);
  const [request] = useState({
    seasonIdsCommaSeparated: JSON.parse(
      localStorage.getItem('selectedCropSeasons')
    ).join(','),
    ...defaultParams
  });

  const loadTaskProducts = useCallback(
    params => {
      const { promise, cancel } = taskProductsApi.fetch(
        null,
        {
          ...request,
          ...params
        },
        {},
        null,
        false
      );
      promise
        .then(({ data }) => {
          setTaskProducts(data);
        })
        .catch(catchCancel)
        .catch(err => {
          parseServerError(dispatch)(err);
        })
        .finally(() => setLoading(false));

      return cancel;
    },
    [request, dispatch]
  );

  useEffect(() => {
    const cancel = loadTaskProducts();

    return () => {
      cancel();
    };
  }, [loadTaskProducts]);

  return {
    taskProducts,
    loading,
    loadTaskProducts
  };
};

export default useTaskProducts;
