import React, { memo } from 'react';
import PropTypes from 'prop-types';

const SearchIcon = ({ size = 18, color = '#696F88' }) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      data-testid="search-icon"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.2239 10.6904H11.8164L15.5589 14.4404L14.4414 15.5579L10.6914 11.8154V11.2229L10.4889 11.0129C9.63391 11.7479 8.52391 12.1904 7.31641 12.1904C4.62391 12.1904 2.44141 10.0079 2.44141 7.31543C2.44141 4.62293 4.62391 2.44043 7.31641 2.44043C10.0089 2.44043 12.1914 4.62293 12.1914 7.31543C12.1914 8.52293 11.7489 9.63293 11.0139 10.4879L11.2239 10.6904ZM3.94141 7.31543C3.94141 9.18293 5.44891 10.6904 7.31641 10.6904C9.18391 10.6904 10.6914 9.18293 10.6914 7.31543C10.6914 5.44793 9.18391 3.94043 7.31641 3.94043C5.44891 3.94043 3.94141 5.44793 3.94141 7.31543Z"
        fill={color}
      />
    </svg>
  );
};

SearchIcon.propTypes = {
  color: PropTypes.string,
  size: PropTypes.number
};

SearchIcon.defaultProps = {
  color: '#696F88',
  size: 18
};

export default memo(SearchIcon);
