import React, { useState, useEffect, useContext, useRef } from 'react';
import _ from 'lodash';
import PropTypes from 'prop-types';
import {
  Input,
  Select,
  Checkbox,
  Button,
  Spinner
} from '@agconnections/grow-ui';
import { ArrowForward as SVGArrowFoward } from '@material-ui/icons';
import ClickableDiv from 'components/ClickableDiv';
import useJohnDeereData from 'screens/Integrations/hooks/useJohnDeereData';
import SourceOperationListItem from '../SourceOperationListItem';
import { CropZoneMatcherContext } from '../../context/CropZoneMatcherProvider';
import getAllCheckBoxes from '../../helpers/getAllCheckBoxes';
import sortOperationsByDate from '../../helpers/sortOperationsByDate';

const SourceOperationList = ({
  selectedSourceField,
  isLoadingOperations,
  selectedSeasons
}) => {
  const [selectedCropZone, setSelectedCropZone] = useState(undefined);
  const selectedSourceFieldRef = useRef(selectedSourceField);
  const {
    loadUnmappedFarms,
    unmappedFarms,
    isLoadingFields
  } = useJohnDeereData();
  const [localCropZones, setLocalCropZones] = useState([]);
  const [showCropzoneDropDown, setShowCropZoneDropDown] = useState(false);
  const [isCheckedAllInstances, setIsCheckedAllInstances] = useState(false);
  const [checkIsAllSelected, setCheckIsAllSelected] = useState([]);
  const sortedOperations = !isLoadingOperations
    ? sortOperationsByDate(selectedSourceField?.operations)
    : [];
  const {
    toMatchCropZone,
    getCropZoneId,
    getCropZoneName,
    operationReset,
    operationIds,
    fieldId,
    cropSeasonId,
    isLoadingMatcher,
    buttonIsLoading,
    reloadData,
    getCs,
    extractFieldsGeometriesFromFarms
  } = useContext(CropZoneMatcherContext);

  const onClickAllInstances = () => {
    setIsCheckedAllInstances(prev => !prev);
  };
  const handleCropZoneSelect = () => {
    setShowCropZoneDropDown(!showCropzoneDropDown);
  };

  const handleCropZoneClick = cropZone => {
    setShowCropZoneDropDown(false);
    setSelectedCropZone(cropZone);
  };

  const displayOperations = () => {
    if (operationIds.length) {
      return operationIds.length > 1
        ? `${operationIds.length} operations will be updated.`
        : '1 operation will be updated.';
    }
    return '';
  };

  const handleSubmit = () => {
    buttonIsLoading();
    toMatchCropZone();
  };

  useEffect(() => {
    getCropZoneName(selectedCropZone?.name);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedCropZone]);

  useEffect(() => {
    getCropZoneId(selectedCropZone?.id);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedCropZone, isLoadingFields]);

  useEffect(() => {
    operationReset();
    setIsCheckedAllInstances(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [localCropZones]);

  useEffect(() => {
    getCs(selectedCropZone?.cropSeasons[0]?.id);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedCropZone]);

  useEffect(() => {
    setSelectedCropZone(undefined);
    getCs(undefined);
    operationReset();
    setIsCheckedAllInstances(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedSourceField]);

  useEffect(() => {
    const check = getAllCheckBoxes().length;
    if (check === 0 && operationIds.length === 0) {
      setCheckIsAllSelected(false);
      return;
    }
    if (getAllCheckBoxes().length === operationIds.length) {
      setCheckIsAllSelected(true);
      setIsCheckedAllInstances(true);
    } else {
      setCheckIsAllSelected(false);
    }
  }, [operationIds]);

  useEffect(() => {
    if (!selectedSeasons.length) {
      return;
    }

    if (
      selectedSourceFieldRef.current?.fieldId === selectedSourceField?.fieldId
    ) {
      loadUnmappedFarms(selectedSeasons);
    } else {
      selectedSourceFieldRef.current = selectedSourceField;
    }

    if (!unmappedFarms?.length || !selectedSourceField) {
      return;
    }
    const unmmappedCropZones = _.flattenDeep(
      unmappedFarms.map(farm =>
        farm.fields
          .filter(field => field.id === selectedSourceField.fieldId)
          .map(field =>
            field.cropzones.map(cropzone => ({
              ...cropzone,
              fieldName: field.name,
              farmName: farm.name
            }))
          )
      )
    );
    setLocalCropZones(unmmappedCropZones);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [unmappedFarms, reloadData, selectedSeasons, selectedSourceField]);

  useEffect(() => {
    if (!unmappedFarms?.length) {
      return;
    }
    extractFieldsGeometriesFromFarms(unmappedFarms);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [unmappedFarms]);

  const unmappedCropZones = () => {
    if (isLoadingFields) {
      return (
        <div className="h-40px w-332px bg-white">
          <Spinner size="sm" />
        </div>
      );
    }

    if (localCropZones.length) {
      return localCropZones.map(cropZone => (
        <ClickableDiv
          key={cropZone.id}
          onClick={() => {
            handleCropZoneClick(cropZone);
          }}
          className="bg-white rounded w-332px max-h-80 overflow-auto py-6px"
        >
          <div className="flex">
            <div className="w-30px mr-4" />
            <div>
              <div className="font-normal text-sm text-neutral-1000">
                {cropZone.fieldName} / {cropZone.name}
              </div>
              <div className="text-xs	font-normal text-neutral-600">
                {cropZone.farmName}
              </div>
            </div>
          </div>
        </ClickableDiv>
      ));
    }

    return (
      <div className="bg-white rounded w-332px max-h-80 overflow-auto px-3 py-8px">
        No crop zones for this field for selected crop season. Try changing your
        crop season selection
      </div>
    );
  };

  return (
    <div className="flex flex-col h-full items-center mt-8">
      <div className="h-40px w-720px flex flex-row justify-between items-center mb-4">
        <div style={{ width: '46%' }} className="pointer-events-none">
          <Input value={selectedSourceField?.fieldName} />
        </div>

        <SVGArrowFoward fill="696F88" />
        <div style={{ width: '46%' }}>
          <Select
            onClick={handleCropZoneSelect}
            inputValue={selectedCropZone?.name}
            items={[]}
            placeholder="Select Crop Zone"
          />
          {showCropzoneDropDown ? (
            <div className="absolute z-50 mt-1">
              {localCropZones.length ? (
                <div className="bg-white rounded w-332px pt-6px pb-6px font-semibold	text-xs text-neutral-600 pl-3">
                  Crop Zones in Source Field
                </div>
              ) : (
                <div />
              )}
              <div>{unmappedCropZones()}</div>
            </div>
          ) : (
            <div />
          )}
        </div>
      </div>
      <div
        className="h-480px w-720px bg-white rounded overflow-y-scroll"
        style={{
          border: '1px solid #C2C7D0'
        }}
      >
        <div
          className="h-48px flex w-full bg-white shadow items-center mb-4 sticky"
          style={{
            width: '718px',
            borderRadius: '6px 6px 0px 0px',
            position: 'absolute',
            zIndex: '1'
          }}
        >
          <Checkbox
            label="Select All instances"
            style={{ marginLeft: '24px' }}
            checked={checkIsAllSelected}
            onClick={onClickAllInstances}
          />
        </div>
        <div className="mt-60px">
          <div
            className="flex flex-col items-center h-full px-16px"
            id="listOfInstances"
          >
            {!isLoadingOperations ? (
              sortedOperations?.map((operation, index) => (
                <SourceOperationListItem
                  key={operation.id}
                  index={index}
                  operation={operation}
                  selectedField={selectedSourceField}
                  isCheckedAllInstances={isCheckedAllInstances}
                />
              ))
            ) : (
              <Spinner />
            )}
          </div>
        </div>
      </div>

      <div className="h-76px w-720px flex flex-row items-center justify-end">
        <div className="font-normal text-sm text-neutral-600 mr-26px">
          {displayOperations()}
        </div>
        <Button
          type="primary"
          onClick={handleSubmit}
          disabled={
            !operationIds?.length ||
            !cropSeasonId ||
            !fieldId ||
            isLoadingMatcher
          }
          loading={isLoadingMatcher}
        >
          Match to Crop Zone
        </Button>
      </div>
    </div>
  );
};

SourceOperationList.propTypes = {
  selectedSourceField: PropTypes.shape().isRequired,
  isLoadingOperations: PropTypes.bool.isRequired,
  selectedSeasons: PropTypes.arrayOf(PropTypes.string).isRequired
};

export default SourceOperationList;
