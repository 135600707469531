import React, { useState, useContext, useEffect } from 'react';
import { useFormikContext } from 'formik';
import PropTypes from 'prop-types';
import ProductsSideBar from 'components/ProductSideBar';
import { Context } from 'components/Store';
import ProductTable from './components/ProductsTable';
import TopOptions from './components/TopOptions';

function Products({ selectedCropseasons }) {
  const { setFieldValue, values, setFieldTouched } = useFormikContext();
  const [{ loggedInUserOrgPermission }] = useContext(Context);
  const { role, financialAccess } = loggedInUserOrgPermission;

  const [customProductCreated, setCustomProductCreated] = useState(false);

  const removeProduct = product => {
    const stubbedAddedProductsCopy = values?.products;
    stubbedAddedProductsCopy.splice(
      stubbedAddedProductsCopy.findIndex(
        productCopy => productCopy.productId === product.productId
      ),
      1
    );
    setFieldValue(values?.products, stubbedAddedProductsCopy);
  };

  const editProduct = () => {
    setFieldValue(values?.products, values?.products);
  };

  useEffect(() => {
    setFieldValue('cropSeasonIds', selectedCropseasons);
  }, [selectedCropseasons, setFieldValue]);

  return (
    <div className="h-full flex justify-between">
      <div className="w-3/12 mt-16">
        <ProductsSideBar
          memberRole={role}
          memberFinancialAccess={financialAccess}
          setCustomProductCreated={setCustomProductCreated}
        />
      </div>

      <div className="ml-4 flex-grow">
        <TopOptions
          values={values}
          setFieldValue={setFieldValue}
          setFieldTouched={setFieldTouched}
        />
        <ProductTable
          editProduct={editProduct}
          removeProduct={removeProduct}
          setCustomProductCreated={setCustomProductCreated}
          customProductCreated={customProductCreated}
        />
      </div>
    </div>
  );
}

Products.propTypes = {
  selectedCropseasons: PropTypes.arrayOf(PropTypes.object).isRequired
};

export default Products;
