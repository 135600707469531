import React, { useState } from 'react';
import PropTypes from 'prop-types';
import CwFPagination from 'components/CwFPagination';
import Paper from 'components/Paper';
import LandingTable from 'components/LandingTable';
import { paths } from 'routes/paths';
import CropSeasonTableRow from './components/CropSeasonTableRow';
import cropSeasonTableSort from './helpers/cropSeasonTableSort';

const TABLE_COLUMNS = [
  {
    field: 'name',
    title: 'Season',
    sortable: true
  },
  {
    field: 'active',
    title: 'Season status',
    sortable: true
  },
  {
    field: 'crop',
    title: 'Crop',
    sortable: true
  },
  {
    field: 'totalArea',
    title: 'Total Area',
    sortable: true
  },
  {
    field: 'startDate',
    title: 'Start Date',
    sortable: true
  },
  {
    field: 'endDate',
    title: 'End Date',
    sortable: true
  },
  {
    field: 'createdAt',
    title: 'Created',
    sortable: true
  }
];

const CropSeasonTable = ({
  cropSeasons,
  allCropSeasons,
  tableSort,
  handleAction,
  selectedMember,
  financialAccess,
  seasonPageNumber,
  handlePageChange,
  cropSeasonsLength
}) => {
  const [sortField, setSortField] = useState();
  const [sortDir, setSortDir] = useState();

  const handleSort = (field, dir) => {
    setSortField(field);
    setSortDir(dir);
    tableSort(cropSeasonTableSort(allCropSeasons, field, dir));
  };

  return (
    <div
      className="mx-2 my-4 min-w-content"
      data-testid="cropSeasons-table"
      style={{ minWidth: 'min-content' }}
    >
      <Paper>
        {CwFPagination(
          cropSeasonsLength || 0,
          seasonPageNumber,
          handlePageChange
        )}
        <LandingTable
          listType="Crop Seasons"
          routePath={paths.cropSeasons}
          items={cropSeasons}
          tableColumns={TABLE_COLUMNS}
          onSort={handleSort}
          sortBy={sortField}
          sortDir={sortDir}
        >
          {({ rows }) =>
            rows.map(season => (
              <CropSeasonTableRow
                key={season.id}
                cropSeason={season}
                onAction={handleAction}
                selectedMember={selectedMember}
                financialAccess={financialAccess}
              />
            ))
          }
        </LandingTable>
        <div className="pb-12">
          {CwFPagination(
            cropSeasonsLength || 0,
            seasonPageNumber,
            handlePageChange
          )}
        </div>
      </Paper>
    </div>
  );
};

CropSeasonTable.propTypes = {
  cropSeasons: PropTypes.arrayOf(PropTypes.object),
  allCropSeasons: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  handleAction: PropTypes.func,
  selectedMember: PropTypes.instanceOf(Object),
  financialAccess: PropTypes.string.isRequired,
  seasonPageNumber: PropTypes.number.isRequired,
  handlePageChange: PropTypes.func.isRequired,
  cropSeasonsLength: PropTypes.number.isRequired,
  tableSort: PropTypes.func.isRequired
};

CropSeasonTable.defaultProps = {
  cropSeasons: [],
  handleAction: () => {},
  selectedMember: {}
};

export default CropSeasonTable;
