import { PropTypes } from 'prop-types';
import React, { memo } from 'react';

const PersonIcon = ({ size, color }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      viewBox="0 0 20 20"
      fill="none"
      data-testid="person-icon"
      className={`fill-current text-${color}`}
    >
      <g id="person">
        <path
          id="icon/social/person_24px"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M10.0002 3.33203C8.1585 3.33203 6.66683 4.8237 6.66683 6.66536C6.66683 8.50703 8.1585 9.9987 10.0002 9.9987C11.8418 9.9987 13.3335 8.50703 13.3335 6.66536C13.3335 4.8237 11.8418 3.33203 10.0002 3.33203ZM11.6668 6.66536C11.6668 5.7487 10.9168 4.9987 10.0002 4.9987C9.0835 4.9987 8.3335 5.7487 8.3335 6.66536C8.3335 7.58203 9.0835 8.33203 10.0002 8.33203C10.9168 8.33203 11.6668 7.58203 11.6668 6.66536ZM15.0002 14.9987C14.8335 14.407 12.2502 13.332 10.0002 13.332C7.7585 13.332 5.19183 14.3987 5.00016 14.9987H15.0002ZM3.3335 14.9987C3.3335 12.782 7.77516 11.6654 10.0002 11.6654C12.2252 11.6654 16.6668 12.782 16.6668 14.9987V16.6654H3.3335V14.9987Z"
        />
      </g>
    </svg>
  );
};

PersonIcon.propTypes = {
  color: PropTypes.string,
  size: PropTypes.number
};

PersonIcon.defaultProps = {
  color: 'neutral-600',
  size: 20
};

export default memo(PersonIcon);
