import React from 'react';
import { Table } from '@agconnections/grow-ui';
import PropTypes from 'prop-types';
import CropZoneCell from '../CropZoneCell';

const DeletedAndChangedTableView = ({
  massiveChangeSummary,
  mappedCropView
}) => {
  return (
    <Table>
      {Object.entries(mappedCropView).map(crop => (
        <Table.RowGroup arrowIcon key={crop[0]}>
          <Table.Row>
            <Table.Cell>
              <p
                className="font-semibold font-body"
                data-testid="view-by-crops"
              >
                {crop[0]}
              </p>
            </Table.Cell>
          </Table.Row>
          {Object.entries(crop[1]).map(farm => (
            <Table.RowGroup arrowIcon key={farm[0]}>
              <Table.Row>
                <Table.Cell>
                  <p className="font-semibold font-body">{farm[0]}</p>
                </Table.Cell>
              </Table.Row>
              {farm[1].map(property => {
                return (
                  <Table.RowGroup arrowIcon key={property.id}>
                    <Table.Row>
                      <Table.Cell>
                        <p className="font-semibold font-body">
                          {property.name}
                        </p>
                      </Table.Cell>
                    </Table.Row>
                    <Table.Row>
                      <Table.Cell>
                        <CropZoneCell
                          massiveChangeSummary={massiveChangeSummary}
                          property={property}
                          cropName={crop[0]}
                          key={property.id}
                        />
                      </Table.Cell>
                    </Table.Row>
                  </Table.RowGroup>
                );
              })}
            </Table.RowGroup>
          ))}
        </Table.RowGroup>
      ))}
    </Table>
  );
};

DeletedAndChangedTableView.defaultProps = {
  massiveChangeSummary: {},
  mappedCropView: []
};

DeletedAndChangedTableView.propTypes = {
  massiveChangeSummary: PropTypes.shape(),
  mappedCropView: PropTypes.arrayOf(PropTypes.array)
};

export default DeletedAndChangedTableView;
