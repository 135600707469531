import { useCallback, useMemo } from 'react';
import { useHistory, useParams } from 'react-router-dom';

import { getLocalStorageApi, STORAGE_KEYS } from 'utilities/localStorage';
import useQuery from 'helpers/useQuery';
import { paths } from 'routes/paths';

const useSubmissionUrlHandler = () => {
  const query = useQuery();
  const history = useHistory();
  const comingFrom = query.get('from');
  const { id: idOnParams } = useParams();

  const comingFromTaskEdition = comingFrom === 'task';
  const comingFromView = comingFrom === 'view';

  const determineSubmissionId = useCallback(() => {
    if (idOnParams) {
      return idOnParams;
    }
    if (comingFromTaskEdition) {
      const submissionId = getLocalStorageApi(
        STORAGE_KEYS.SUBMISSION_TASK_REDIRECT,
        null
      ).get();

      getLocalStorageApi(STORAGE_KEYS.SUBMISSION_TASK_REDIRECT).remove();
      return comingFromTaskEdition ? submissionId : null;
    }

    const submissionId = getLocalStorageApi(
      STORAGE_KEYS.SUBMISSION_EDIT_REDIRECT,
      null
    ).get();

    getLocalStorageApi(STORAGE_KEYS.SUBMISSION_EDIT_REDIRECT).remove();
    return comingFromView ? submissionId : null;
  }, [comingFromTaskEdition, comingFromView, idOnParams]);

  const redirectToTask = useCallback(
    (product, submissionId, integrationType) => {
      getLocalStorageApi('selectedCropSeasons').save(
        JSON.stringify(product.cropSeasonsIds)
      );
      getLocalStorageApi(STORAGE_KEYS.SUBMISSION_TASK_REDIRECT).save(
        submissionId
      );
      history.push(`${paths.integrations}/${integrationType}?from=task`); // To enable user to hit browser back button in task view
      history.push(
        `${paths.tasks}/${product.taskId}?submission=${submissionId}&vendor=${integrationType}`
      );
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );
  const redirectToEdit = useCallback(
    (submissionId, integrationType) => {
      getLocalStorageApi(STORAGE_KEYS.SUBMISSION_EDIT_REDIRECT).save(
        submissionId
      );
      history.push(`${paths.integrations}/${integrationType}?from=view`);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  return useMemo(
    () => ({
      determineSubmissionId,
      comingFromTaskEdition,
      comingFromView,
      redirectToTask,
      redirectToEdit,
      isReadOnly: !!idOnParams
    }),
    [
      idOnParams,
      comingFromTaskEdition,
      comingFromView,
      determineSubmissionId,
      redirectToTask,
      redirectToEdit
    ]
  );
};

export default useSubmissionUrlHandler;
