import React, { useContext } from 'react';
import errorInfo from 'assets/error_24px.svg';
import PropTypes from 'prop-types';
import ClickableDiv from 'components/ClickableDiv';
import { ProductDetailsContext } from 'screens/ProductList/ProductDetails/context/ProductDetailsProvider';
import convertDateString from '../../../../helpers/dateFormat';
import ContainerSubmissionBanner from '../ContainerSubmissionBanner';
import FailedSubmissionDrawer from './components/FailedSubmissionDrawer';

const FailedSubmissionBanner = ({
  submissionsStatus,
  dismissAction,
  listPriceUnit,
  loadingRetrySubmission,
  retryAction
}) => {
  const { openDrawer, setOpenDrawer } = useContext(ProductDetailsContext);

  const onClickSelectAllRecords = () => {
    setOpenDrawer(true);
  };

  const onCloseDrawer = () => {
    setOpenDrawer(false);
  };

  return (
    <ContainerSubmissionBanner
      data-testid="failed-submission-banner"
      backgroundColor="#FFF1F1"
      icon={errorInfo}
      dismissAction={dismissAction}
    >
      <p data-testid="text-banner">
        Some records from the request on{' '}
        {convertDateString(submissionsStatus.earliestRequestDateTime)} were not
        successfully updated.
      </p>

      <ClickableDiv
        data-testid="select-all-records"
        onClick={onClickSelectAllRecords}
      >
        <span className="text-blue-60 font-semibold cursor-pointer ml-1">
          See more details
        </span>
      </ClickableDiv>
      <FailedSubmissionDrawer
        submissionsStatus={submissionsStatus}
        openDrawer={openDrawer}
        onCloseDrawer={onCloseDrawer}
        listPriceUnit={listPriceUnit}
        retryAction={retryAction}
        loadingRetrySubmission={loadingRetrySubmission}
      />
    </ContainerSubmissionBanner>
  );
};
FailedSubmissionBanner.propTypes = {
  submissionsStatus: PropTypes.shape({
    dismiss: PropTypes.bool,
    earliestRequestDateTime: PropTypes.string.isRequired,
    failedSubmissions: PropTypes.arrayOf(PropTypes.object).isRequired
  }).isRequired,
  dismissAction: PropTypes.func.isRequired,
  listPriceUnit: PropTypes.string.isRequired,
  loadingRetrySubmission: PropTypes.bool.isRequired,
  retryAction: PropTypes.func.isRequired
};

export default FailedSubmissionBanner;
