import React from 'react';
import PropTypes from 'prop-types';
import checkInfo from 'assets/check_circle_24px.svg';
import convertDateString from '../../../../helpers/dateFormat';
import ContainerSubmissionBanner from '../ContainerSubmissionBanner';

const SuccessfulSubmissionBanner = ({ submissionsStatus, dismissAction }) => {
  return (
    <ContainerSubmissionBanner
      data-testid="successful-submission-banner"
      backgroundColor="#DFFBE8"
      icon={checkInfo}
      dismissAction={dismissAction}
    >
      <p data-testid="text-banner">
        The record update request made on{' '}
        {convertDateString(submissionsStatus.earliestRequestDateTime)} was
        successful.
      </p>
    </ContainerSubmissionBanner>
  );
};

SuccessfulSubmissionBanner.propTypes = {
  submissionsStatus: PropTypes.shape({
    dismiss: PropTypes.bool,
    earliestRequestDateTime: PropTypes.string.isRequired
  }).isRequired,
  dismissAction: PropTypes.func.isRequired
};

export default SuccessfulSubmissionBanner;
