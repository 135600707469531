import React, { createRef, useEffect, useState } from 'react';
import { useFormikContext } from 'formik';
import { Spinner, Table, Tag, TagCloud } from '@agconnections/grow-ui';
import { formatDateToShortMonthDayYear } from 'utilities/dateHelpers';
import { INTEGRATIONS } from 'screens/Integrations/helpers/constants';
import useFormikHandler from 'screens/Integrations/hooks/useFormikHandler';
import useAgrianApi from 'hooks/useAgrianApi';
import useSubmissionAnalytics from 'screens/Integrations/hooks/useSubmissionAnalytics';
import { useHistory } from 'react-router-dom';
import AgrianSubmissionFooter from '../../../components/AgrianSubmissionFooter';
import useRecommendationsFarmFieldTree from '../../hooks/useRecommendationsFarmFieldTree';
import MatchArrowIcon from '../../../../PropertiesMatch/components/FarmList/components/icons/MatchArrowIcon';
import Farm from '../../../../SubmissionSummary/components/SummaryFarms/components/Farm';
import CropIconMapping from '../../../../../../components/CropIconMapping';
import ExpandableLongText from '../../../../../../components/ExpandableLongText';
import SpinnerModal from '../../../../../../components/SpinnerModal';
import { paths } from '../../../../../../routes/paths';

const AgrianRecommendationReview = () => {
  const history = useHistory();
  const { values } = useFormikContext();
  const { selectedCropSeasons, selectedRecommendations } = useFormikHandler();
  const [farmRefs, setFarmRefs] = useState([]);
  const { events, triggerAnalyticsEvent } = useSubmissionAnalytics();
  const { loading: isLoadingApi, importRecommendations } = useAgrianApi();
  const {
    recommendationsFarmFieldTree,
    loading: isLoadingRecords
  } = useRecommendationsFarmFieldTree();

  const scrollToFarm = index => {
    if (farmRefs[index]) {
      farmRefs[index].current.scrollIntoView({
        behavior: 'smooth',
        block: 'center'
      });
    }
  };

  const onImportHandler = () => {
    triggerAnalyticsEvent(
      events.epic.Integrations.importRecommendationTriggered,
      {
        vendor: INTEGRATIONS.agrianRecommendation
      }
    );
    importRecommendations({
      recommendationsIds: selectedRecommendations.map(r => r.id),
      seasonId: selectedCropSeasons[0]?.id
    }).then(() =>
      history.replace(`${paths.integrations}/AgrianRecommendation/landing-page`)
    );
  };

  const formatRecommendationProducts = recommendation => {
    return [...new Set(recommendation.products.map(p => p.productName))].join(
      ', '
    );
  };

  useEffect(() => {
    setFarmRefs(currentFarmRefs =>
      recommendationsFarmFieldTree?.map(
        (_, i) => currentFarmRefs[i] || createRef()
      )
    );
  }, [recommendationsFarmFieldTree]);

  return (
    <>
      <SpinnerModal open={isLoadingApi} message="Importing Recommendations" />
      <div className="w-full mx-6">
        <div
          className="flex flex-col bg-white top-30 left-26 rounded-md pt-6 pl-6 pr-6 shadow-sm"
          data-testid="agrian-recommendation-review"
        >
          <div>
            <div className="flex flex-col">
              <div className="text-lg leading-7 font-semibold text-neutral-1000 mb-2">
                Review and Import
              </div>
              <p className="text-sm leading-6 text-neutral-70">
                Review matched data before importing
              </p>
            </div>
            <div className="h-px w-full bg-gray-500 mt-6 mb-6" />
            <div className="w-full flex h-15 items-center font-semibold">
              <p className="text-2xl">
                Agrian Import {new Date().toLocaleDateString('en-Us')}
              </p>
            </div>
            <div className="h-full mt-6 flex justify-start items-center">
              <div>
                <p className="font-thin text-xs text-gray-600">
                  Agrian Crop Year
                </p>
                <div className="items-center" data-testid="agrian-crop-year">
                  <p className="text-2xl pr-2 w-auto overflow-x-auto">
                    {values?.agrianCropYear}
                  </p>
                </div>
              </div>
              <MatchArrowIcon width="80" height="25" />
              <div>
                <p className="font-thin text-xs text-gray-600">
                  Cropwise Crop Season
                </p>
                <div className="items-center" data-testid="cwf-crop-season">
                  <p className="text-2xl pr-2 w-auto overflow-x-auto">
                    {selectedCropSeasons[0]?.name}
                  </p>
                </div>
              </div>
            </div>
          </div>
          {isLoadingRecords ? (
            <div
              data-testid="loading-agrian-records"
              className="w-full h-full py-24 flex flex-col justify-center items-center"
            >
              <Spinner />
              <p className="text-sm font-semibold mt-4">Loading records...</p>
            </div>
          ) : (
            <div
              className="flex flex-col bg-white top-30 left-26 rounded-md mb-24 shadow-sm"
              data-testid="recommendation-review-table"
            >
              {recommendationsFarmFieldTree?.map((farm, i) => (
                <div key={farm.id}>
                  <div className="w-full flex justify-between align-middle mt-10">
                    <div className="flex justify-start">
                      <div
                        data-testid="agrian-farm"
                        className="w-full"
                        ref={el => {
                          if (farmRefs[i]) {
                            farmRefs[i].current = el;
                            return farmRefs[i].current;
                          }
                          return el;
                        }}
                      >
                        <Farm
                          farm={farm}
                          selectFarm={scrollToFarm}
                          farms={recommendationsFarmFieldTree}
                          selectFarmTextSize="text-sm"
                        />
                      </div>
                    </div>
                    <div className="flex justify-end mt-1">
                      <TagCloud>
                        <Tag key="cropzones-length" color="#D60469">
                          {farm.fields.length} Fields
                        </Tag>
                        <Tag key="applications-length" color="#2B9C92">
                          {
                            [
                              ...new Set(
                                farm.fields
                                  .flatMap(f => f.recommendations)
                                  .map(r => r.id)
                              )
                            ].length
                          }{' '}
                          Recommendations
                        </Tag>
                      </TagCloud>
                    </div>
                  </div>
                  {farm.fields.map(field => {
                    return (
                      <div key={field.id}>
                        <div
                          className="h-full mt-6 flex justify-start items-center"
                          data-testid="agrian-field-collapse"
                        >
                          <div
                            className="text-base font-semibold text-neutral-1000"
                            data-testid="agrian-field-name"
                          >
                            {field.name}
                          </div>
                          <MatchArrowIcon width="80" height="25" />
                          <div
                            className="text-base font-semibold text-neutral-1000"
                            data-testid="cwf-cropzone-name"
                          >
                            {field.cwfCropZone?.name ?? 'N/A'}
                          </div>
                          <div className="ml-3">
                            <CropIconMapping
                              cropObject={field.cwfCropZone?.crop}
                              size={28}
                            />
                          </div>
                          <div
                            className="text-sm font-normal text-neutral-1000 ml-1"
                            data-testid="cwf-crop-name"
                          >
                            {field.cwfCropZone?.crop?.name}
                          </div>
                        </div>
                        <Table>
                          <Table.Header>
                            <Table.Cell>Recommendation Name</Table.Cell>
                            <Table.Cell>Proposed Date</Table.Cell>
                            <Table.Cell>Products</Table.Cell>
                          </Table.Header>
                          {field.recommendations.map(recommendation => (
                            <Table.Row key={`${recommendation.id}_${field.id}`}>
                              <Table.Cell>
                                {recommendation.description}
                              </Table.Cell>
                              <Table.Cell>
                                {formatDateToShortMonthDayYear(
                                  recommendation.startDate
                                )}
                              </Table.Cell>
                              <Table.Cell>
                                <ExpandableLongText
                                  text={formatRecommendationProducts(
                                    recommendation
                                  )}
                                  maxLength={120}
                                />
                              </Table.Cell>
                            </Table.Row>
                          ))}
                        </Table>
                      </div>
                    );
                  })}
                </div>
              ))}
            </div>
          )}
        </div>
      </div>
      <AgrianSubmissionFooter
        onNext={onImportHandler}
        loading={isLoadingRecords || isLoadingApi}
        nextDisabled={isLoadingRecords || isLoadingApi}
        nextLabel="Import Records"
        integrationType={INTEGRATIONS.agrianRecommendation}
        isBottomFixed
      />
    </>
  );
};

export default AgrianRecommendationReview;
