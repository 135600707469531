import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { useHistory, useParams } from 'react-router-dom';
import { useField } from 'formik';
import { differenceInCalendarDays } from 'date-fns';
import { Button } from '@agconnections/grow-ui';
import FormNavGuard from 'components/FormNavGuard';
import { paths } from 'routes/paths';
import { AmplitudeContext } from 'utilities/amplitude/useAmplitude';

const TopHeader = ({ handleSubmit, loading, saving, totalArea }) => {
  // const [redirect, setRedirect] = useState();
  const [{ value: date }] = useField('date');
  const { id } = useParams();
  const history = useHistory();
  const amplitude = useContext(AmplitudeContext);
  const duration =
    date?.length === 2 ? differenceInCalendarDays(date[1], date[0]) + 1 : 0;
  return (
    <>
      {/* {redirect && <Redirect push to={redirect} />} */}
      <FormNavGuard />
      <div
        data-testid="cropseason-topheader"
        className="flex items-center justify-between h-20 px-4 border bg-app-background"
        style={{ borderColor: '#E8EAED' }}
      >
        <div className="flex justify-start">
          <div className="mr-12">
            <div className="text-sm text-gray-600">Duration</div>
            <div className="text-2xl text-charcoal">{duration} Days</div>
          </div>
          <div>
            <div className="text-sm text-gray-600">Total Area</div>
            <div className="text-2xl text-charcoal">{totalArea} Acres</div>
          </div>
        </div>
        <div className="flex space-x-4">
          <Button
            type="outline"
            onClick={() => {
              amplitude.sendEventToAmplitude(
                amplitude.events.epic.CropSeason.cancelCropSeason
              );
              history.push(paths.cropSeasons);
              amplitude.sendEventToAmplitude(
                amplitude.events.epic.CropSeason.cancelCropSeasonSuccess
              );
              amplitude.sendEventToAmplitude(
                amplitude.events.epic.CropSeason.accessCropSeasons
              );
            }}
          >
            <span className="w-16">Cancel</span>
          </Button>
          <Button
            type="primary"
            onClick={() => {
              if (id === 'create') {
                amplitude.sendEventToAmplitude(
                  amplitude.events.epic.CropSeason.saveCropSeason
                );
              } else {
                amplitude.sendEventToAmplitude(
                  amplitude.events.epic.CropSeason.updateCropSeason
                );
              }

              handleSubmit();
            }}
            disabled={saving || loading}
          >
            <span className="w-16">{saving ? 'Saving...' : 'Save'}</span>
          </Button>
        </div>
      </div>
    </>
  );
};

TopHeader.defaultProps = {
  saving: false,
  loading: false,
  totalArea: '0'
};
TopHeader.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  loading: PropTypes.bool,
  saving: PropTypes.bool,
  totalArea: PropTypes.string
  // id: PropTypes.string.isRequired
};

export default TopHeader;
