import React from 'react';
import PropTypes from 'prop-types';

import { RadioButtonGroup } from '@agconnections/grow-ui';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faThList,
  faList,
  faSortAlphaUp,
  faSortAlphaDownAlt
} from '@fortawesome/free-solid-svg-icons';

const ToolbarComponents = ({ view, changeView, sort, changeSort }) => {
  return (
    <div data-testid="toolbar-container">
      <div className="flex">
        <RadioButtonGroup
          selected={view}
          onChange={event => {
            changeView(event);
          }}
        >
          <RadioButtonGroup.RadioButton
            name="comfortableView"
            value="comfortable"
          >
            <FontAwesomeIcon fixedWidth icon={faThList} />
          </RadioButtonGroup.RadioButton>
          <RadioButtonGroup.RadioButton name="compactView" value="compact">
            <FontAwesomeIcon fixedWidth icon={faList} />
          </RadioButtonGroup.RadioButton>
        </RadioButtonGroup>
        <div className="flex ml-4">
          <RadioButtonGroup
            selected={sort}
            onChange={event => {
              changeSort(event);
            }}
          >
            <RadioButtonGroup.RadioButton name="ascSort" value="asc">
              <FontAwesomeIcon fixedWidth icon={faSortAlphaUp} size="lg" />
            </RadioButtonGroup.RadioButton>
            <RadioButtonGroup.RadioButton name="descSort" value="desc">
              <FontAwesomeIcon fixedWidth icon={faSortAlphaDownAlt} size="lg" />
            </RadioButtonGroup.RadioButton>
          </RadioButtonGroup>
        </div>
      </div>
    </div>
  );
};

ToolbarComponents.defaultProps = {
  changeView: () => {},
  changeSort: () => {}
};

ToolbarComponents.propTypes = {
  view: PropTypes.string.isRequired,
  changeView: PropTypes.func,
  sort: PropTypes.string.isRequired,
  changeSort: PropTypes.func
};

export default ToolbarComponents;
