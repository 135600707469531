import { useState } from 'react';
import { reports as reportsApi } from 'utilities/api';
import catchCancel from 'helpers/catchCancel';

const useAppliedProducts = () => {
  const [loading, setLoading] = useState(false);
  const appliedProductsApi = reportsApi.createChildApi({
    action: 'reports/appliedproducts'
  });

  const getAppliedProducts = async (
    seasonsCropZones = [],
    costType = 'AVERAGE',
    reportType = 'ByFieldId'
  ) => {
    const body = {
      costType,
      reportType,
      seasonsCropZones
    };

    const { promise } = appliedProductsApi.create(body);

    return promise
      .then(({ data }) => {
        setLoading(true);
        return data;
      })
      .catch(catchCancel)
      .catch(() => {
        setLoading(true);

        return [];
      });
  };

  return {
    getAppliedProducts,
    loading,
    setLoading
  };
};

export default useAppliedProducts;
