import PropTypes from 'prop-types';

const RenderValue = ({ children, isRender }) => {
  if (isRender) {
    return children;
  }
  return null;
};

RenderValue.propTypes = {
  children: PropTypes.element,
  isRender: PropTypes.bool
};

RenderValue.defaultProps = {
  children: null,
  isRender: true
};
export default RenderValue;
