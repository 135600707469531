import React, { useContext, useEffect } from 'react';
import { Spinner } from '@agconnections/grow-ui';

import PortalModal from 'components/Modals/PortalModal';
import { Context } from 'components/Store';
import { PropertiesMatchContext } from '../../context/PropertiesMatchProvider';

const ConfirmationModal = () => {
  const { state, setOpenConfirmationModal } = useContext(
    PropertiesMatchContext
  );
  const [{ error }] = useContext(Context);

  useEffect(() => {
    if (error) {
      setOpenConfirmationModal(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [error]);

  return (
    <PortalModal
      open={state.openConfirmationModal}
      type="buttonless"
      style={{ paddingLeft: '2rem' }}
    >
      <div
        className="flex flex-line w-full mt-2 justify-between"
        data-testid="confirmation-modal"
      >
        <div className="pr-12 pb-10 w-full h-40">
          <Spinner />
          <h3 className="font-bold text-lg text-gray-900 text-center">
            Generating Properties Matches
          </h3>
        </div>
      </div>
    </PortalModal>
  );
};

export default ConfirmationModal;
