import React from 'react';
import PropTypes from 'prop-types';
import { TEMPLATE_ATTRIBUTES, TEMPLATE_TYPE } from 'screens/YieldV2/constants';
import BasicInfoSection from '../BasicInfoSection';
import WeightSection from '../WeightSection';
import OtherSection from '../OtherSection';
import SaleSection from '../SaleSection';
import LoadAttributesSection from '../LoadAttributesSection';
import YieldSectionContainer from '../YieldSectionContainer';

const DefaultRowCropTemplate = ({ selectedCropName }) => {
  return (
    <div className="flex flex-col gap-8">
      <BasicInfoSection />
      <YieldSectionContainer
        shrinkEnabled
        selectedCropName={selectedCropName}
      />
      <SaleSection />
      <LoadAttributesSection
        attributes={TEMPLATE_ATTRIBUTES[TEMPLATE_TYPE.ROW_CROP]}
      />
      <WeightSection />
      <OtherSection />
    </div>
  );
};

DefaultRowCropTemplate.propTypes = {
  selectedCropName: PropTypes.string.isRequired
};

export default DefaultRowCropTemplate;
