import React, { useContext } from 'react';
import { Context } from 'components/Store';
import GrowUIFormField from 'components/Forms/GrowUIFormField';
import { Input } from '@agconnections/grow-ui';
import { useFormikContext } from 'formik';

const PermitId = () => {
  const [{ isEnableInfoTabEdit }] = useContext(Context);
  const { values } = useFormikContext();

  return (
    <GrowUIFormField
      value={values.permitId}
      placeholder="Enter permit ID"
      control={Input}
      id="permitId"
      name="permitId"
      label="Permit ID"
      data-testid="ui-field-permit-id"
      disabled={!isEnableInfoTabEdit}
    />
  );
};

export default PermitId;
