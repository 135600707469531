import React from 'react';
import PropTypes from 'prop-types';
import ClickableDiv from 'components/ClickableDiv';
import FarmIcon from 'screens/Integrations/components/icons/FarmIcon';

const FarmOption = ({ farm, selectFarm, index }) => {
  const handleClick = () => {
    selectFarm(index);
  };

  return (
    <ClickableDiv
      className="flex flex-row w-auto p-2 text-xl text-gray-900 hover:bg-info-light-blue hover:text-info-dark-blue"
      onClick={handleClick}
    >
      <FarmIcon color="#696F88" />
      <span className="ml-3">{farm.name}</span>
    </ClickableDiv>
  );
};

FarmOption.propTypes = {
  farm: PropTypes.shape().isRequired,
  selectFarm: PropTypes.func.isRequired,
  index: PropTypes.number.isRequired
};

export default FarmOption;
