import { ROLES } from 'utilities/access';

import Equipment from 'screens/Equipment';
import EquipmentItem from 'screens/Equipment/EquipmentItem';
import EquipmentView from 'screens/Equipment/components/EquipmentView';

import { EquipmentIcon } from 'routes/icons';
import { paths } from 'routes/paths';

const routes = [
  {
    id: 'equipment',
    key: 'equipment',
    path: paths.equipment,
    exact: true,
    secure: true,
    disabled: false,
    icon: EquipmentIcon,
    nav: 'leftNav',
    component: Equipment
  },
  {
    id: 'equipmentEntry',
    key: 'equipmentEntry',
    path: `${paths.equipment}/:id`,
    exact: true,
    secure: true,
    component: EquipmentItem,
    minPermissions: {
      minAllowedRole: ROLES.FULL_CONTROL
    }
  },
  {
    id: 'equipmentView',
    key: 'equipmentView',
    path: `${paths.equipment}/:id/view`,
    exact: true,
    secure: true,
    component: EquipmentView,
    minPermissions: {
      minAllowedRole: ROLES.VIEW_ONLY
    }
  }
];

export default routes;
