/* eslint-disable no-unused-expressions */
import React, { useCallback, useEffect, useState, useContext } from 'react';
import { Redirect } from 'react-router-dom';
import { Toast, Spinner } from '@agconnections/grow-ui';
import { useAuth } from 'utilities/base-auth';
import Breadcrumb from 'components/App/AppShell/components/Breadcrumb';
import { organization as organizationApi } from 'utilities/api';
import catchCancel from 'helpers/catchCancel';
import { parseServerError } from 'helpers/errorHelpers';
import { Context } from 'components/Store';
import { paths } from 'routes/paths';
import OrganizationDetails from './components/OrganizationDetails';
import DeleteOrganization from './components/DeleteOrganization';
import OrganizationMembers from './components/OrganizationMembers';

const Organization = () => {
  const { user } = useAuth();
  const [organization, setOrganization] = useState(null);
  const [loading, setLoading] = useState(false);
  const [{ organization: org }, dispatch] = useContext(Context);
  const [userDetails, setUserDetails] = useState();
  const [successMessage, setSuccessMessage] = useState();
  const [redirect, setRedirect] = useState(false);
  const getOrganization = useCallback(async () => {
    setLoading(true);
    const { promise } = organizationApi.fetch();
    await promise
      .then(({ data }) => {
        setOrganization(data);
        const filterMember = data?.members?.filter(
          eachMember => eachMember.email === user.email
        )[0];
        setUserDetails(filterMember);
        setLoading(false);
      })
      .catch(catchCancel)
      .catch(err => {
        setLoading(false);
        parseServerError(dispatch)(err);
      });
  }, [dispatch, user.email]);

  const handleSaveOrganization = async formData => {
    const { promise } = organizationApi.update(null, formData, false);
    await promise
      .then(() => {
        setSuccessMessage('Your changes have been saved!');
        location.reload();
      })
      .catch(catchCancel)
      .catch(err => {
        parseServerError(dispatch)(err);
      });
  };

  useEffect(() => {
    if (!organization) {
      getOrganization();
    }
  }, [organization, getOrganization]);

  const handleOrgChange = () => {
    setRedirect(true);
    getOrganization();
  };

  return (
    <>
      {redirect && (
        <Redirect
          push
          to={{
            pathname: `${paths.organizations}/${org.id}`
          }}
        />
      )}
      <Breadcrumb onOrganizationSelect={handleOrgChange} />
      {successMessage && (
        <Toast isError={false} onClose={() => setSuccessMessage()}>
          {successMessage}
        </Toast>
      )}
      <>
        {loading ? (
          <Spinner />
        ) : (
          <>
            <OrganizationDetails
              organization={organization}
              onSave={handleSaveOrganization}
              userDetails={user}
              members={organization?.members}
            />
            <OrganizationMembers
              members={organization?.members}
              getOrganization={getOrganization}
              userDetails={user}
            />
            {userDetails?.role !== 'Full control' && (
              <DeleteOrganization organization={organization} />
            )}
          </>
        )}
      </>
    </>
  );
};

Organization.defaultProps = {};

Organization.propTypes = {};

export default Organization;
