import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import ClickableDiv from 'components/ClickableDiv';
import ChevronDown from 'assets/chevron_down.svg';
import { Button } from '@agconnections/grow-ui';

const Loading = () => (
  <div className="flex justify-center items-center">
    <div className="animate-spin rounded-full h-4 w-4 border-b-2 border-gray-500" />
  </div>
);

const BaseDropdown = ({
  valueRenderer,
  children,
  onChange,
  value,
  bottomButtonText,
  onBottomButtonClick,
  id,
  rightOffset,
  isInvalid,
  isLoading
}) => {
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const dropdownRef = useRef(null);

  const openDiv = () => {
    setIsDropdownOpen(!isDropdownOpen);
  };

  useEffect(() => {
    const handleClickOutside = event => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsDropdownOpen(false);
      }
    };

    document.addEventListener('click', handleClickOutside);

    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, []);

  const getSelectionClass = itemValue =>
    JSON.stringify(itemValue) === JSON.stringify(value)
      ? 'bg-selectedBlue'
      : 'hover:bg-neutral-100';

  const selectedValueComponent = valueRenderer(value);
  const renderItem = child => {
    if (child.props?.onItemClick) {
      return (
        <ClickableDiv
          className="h-8 gr-whitespace-nowrap"
          onClick={() => {
            setIsDropdownOpen(false);
            child.props.onItemClick();
          }}
          key={JSON.stringify(child.props.value)}
        >
          {child}
        </ClickableDiv>
      );
    }
    if (child.props?.value) {
      return (
        <ClickableDiv
          key={JSON.stringify(child.props.value)}
          className={`${getSelectionClass(
            child.props.value
          )} h-6 gr-whitespace-nowrap pr-4`}
          onClick={() => {
            setIsDropdownOpen(false);
            onChange(child.props.value);
          }}
        >
          {child}
        </ClickableDiv>
      );
    }
    return child;
  };
  return (
    <div className="w-full mt-1" ref={dropdownRef} id={id}>
      <Button
        onClick={openDiv}
        style={{ width: '100%' }}
        type="outline"
        ghost={!isInvalid}
        danger={isInvalid}
        disabled={isLoading}
        dataTestId={id}
      >
        <div className="flex flex-row w-full relative">
          <div
            style={{ whiteSpace: 'nowrap' }}
            className="flex-1 overflow-x-hidden whitespace-nowrap"
          >
            {selectedValueComponent}
          </div>
          {isLoading ? (
            <Loading />
          ) : (
            <img className="mr-1" src={ChevronDown} alt="Sort Icon" />
          )}
        </div>
      </Button>

      {isDropdownOpen && (
        <div
          className={`absolute bg-white z-50 mt-2 shadow-card rounded `}
          style={{ minWidth: `calc(100% - ${rightOffset}px)` }}
        >
          <div className="w-full flex flex-col justify-center">
            <div className="mt-2 pl-4 flex-1 overflow-x-hidden overflow-y-auto w-full">
              {children.flat().map(renderItem)}
            </div>
            {bottomButtonText && onBottomButtonClick && (
              <div className="flex justify-center border-t border-solid border-neutral-100">
                <div className="mx-4 my-2 w-full h-full">
                  <Button
                    onClick={() => {
                      setIsDropdownOpen(false);
                      onBottomButtonClick();
                    }}
                    style={{ width: '100%' }}
                    type="outline"
                    ghost={!isInvalid}
                    danger={isInvalid}
                  >
                    {bottomButtonText}
                  </Button>
                </div>
              </div>
            )}
          </div>
        </div>
      )}
    </div>
  );
};

BaseDropdown.defaultProps = {
  value: null,
  id: '',
  isInvalid: false,
  rightOffset: 24,
  isLoading: false,
  bottomButtonText: null,
  onBottomButtonClick: null
};

BaseDropdown.propTypes = {
  children: PropTypes.node.isRequired,
  valueRenderer: PropTypes.func.isRequired,
  value: PropTypes.oneOfType([
    PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.string,
        name: PropTypes.string
      })
    ),
    PropTypes.shape({
      id: PropTypes.string,
      name: PropTypes.string
    })
  ]),
  rightOffset: PropTypes.number,
  onChange: PropTypes.func.isRequired,
  onBottomButtonClick: PropTypes.func,
  bottomButtonText: PropTypes.string,
  id: PropTypes.string,
  isInvalid: PropTypes.bool,
  isLoading: PropTypes.bool
};

export default BaseDropdown;

BaseDropdown.ClickableElement = ({ children }) => children;
