import { useFlags, withLDConsumer } from 'launchdarkly-react-client-sdk';
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Spinner, Table, Tag } from '@agconnections/grow-ui';
import { Link } from 'react-router-dom';
import FormattedNumber from 'components/FormattedNumber';
import { taskFormat } from 'helpers/dateFormat';
import { hasAccess } from 'utilities/access';
import {
  TagsCloudDisplay,
  extraTagsCalculation,
  TagsPopUpMessage
} from 'components/TagsButton';
import ListWithPopup from 'components/ListWithPopup';
import EllipseMenu from 'components/EllipseMenu';
import {
  TASKS_COMPLETE_LANDING_MENU,
  TASKS_READY_LANDING_MENU
} from 'utilities/menus';
import { paths } from 'routes/paths';

const TasksTableRow = ({
  task,
  onAction,
  flags,
  memberRole,
  memberFinancialAccess,
  runningProcesses
}) => {
  const { feTasksSpanishReadyTaskTranslation } = useFlags();

  const [displayTagsObject, setDisplayTagsObject] = useState({
    extraTagsCount: 0,
    extraTagsNames: [],
    tagsToDisplay: []
  });

  useEffect(() => {
    if (flags.length > 0) setDisplayTagsObject(extraTagsCalculation(flags, 20));
  }, [flags]);

  const renderStatus = status => {
    let currentStatus = {
      name: 'Ready',
      color: '#E99921'
    };
    if (status === 'inprogress') {
      currentStatus = {
        name: 'In Progress',
        color: '#5FD2C8'
      };
    } else if (status === 'complete') {
      currentStatus = {
        name: 'Complete',
        color: '#0071CD'
      };
    }
    return (
      <div>
        <Tag color={currentStatus.color}>
          <span className="w-32 text-lg">{currentStatus.name}</span>
        </Tag>
      </div>
    );
  };
  const getTotCostValue = cost => {
    if (memberRole === 'Full control' && memberFinancialAccess === 'none') {
      return '';
    }
    if (memberRole === 'View Only' && memberFinancialAccess === undefined) {
      return '';
    }
    if (
      hasAccess(
        {
          role: memberRole,
          financialAccess: memberFinancialAccess
        },
        { minAllowedRole: 'View Only', minFinancialAccess: 'READ' }
      )
    ) {
      return cost;
    }
    return '';
  };

  return (
    <Table.Row key={task.id}>
      <Table.Cell width="20rem">
        <span className="flex flex-col">
          <span
            className="w-64 pb-2 text-sm font-bold text-left truncate"
            data-testid="task-name"
          >
            <Link to={`${paths.tasks}/${task.id}/view`}>{task.name}</Link>
          </span>
          {displayTagsObject.extraTagsNames.length > 0 ? (
            <ListWithPopup
              position="bottom"
              ariaLabel="Task Tags"
              top="1.5rem"
              msg={
                <TagsPopUpMessage
                  extraTagsNames={displayTagsObject.extraTagsNames}
                />
              }
              component={
                <TagsCloudDisplay
                  displayTagsObject={displayTagsObject}
                  isLandingPage
                  viewKey="tag_1"
                />
              }
            />
          ) : (
            <TagsCloudDisplay
              displayTagsObject={displayTagsObject}
              isLandingPage
              viewKey="tag_2"
            />
          )}
        </span>
      </Table.Cell>
      <Table.Cell width="8rem">{task.fieldCount}</Table.Cell>
      <Table.Cell width="12rem">
        <FormattedNumber>{task.totalAreaValue || 0}</FormattedNumber>
      </Table.Cell>
      <Table.Cell width="8rem">{task.productCount}</Table.Cell>
      {memberRole === 'View Only' && memberFinancialAccess === undefined ? (
        <Table.Cell />
      ) : (
        <Table.Cell>
          <FormattedNumber unit="usCurrency" roundedTo={2}>
            {getTotCostValue(task.totalTaskCost)}
          </FormattedNumber>
        </Table.Cell>
      )}
      {/* display as currency */}
      <Table.Cell width="20rem">
        <span className="w-64 h-full py-1 text-left truncate">
          {task?.cropSeasons?.cropSeasons?.map(
            (cz, i) =>
              `${cz.name}${
                i === task?.cropSeasons?.cropSeasons.length - 1 ? '' : ', '
              }`
          )}
        </span>
      </Table.Cell>
      <Table.Cell>{taskFormat(task.startDate)}</Table.Cell>
      <Table.Cell>
        <div className="flex justify-between w-full">
          <div className="mr-6">{renderStatus(task.status)}</div>
          <div data-testid="task-table-options-menu" className="w-10 mr-2">
            {runningProcesses ? (
              <Spinner size="sm" />
            ) : (
              <EllipseMenu
                onAction={onAction}
                options={
                  task.status === 'ready' && feTasksSpanishReadyTaskTranslation
                    ? TASKS_READY_LANDING_MENU
                    : TASKS_COMPLETE_LANDING_MENU
                }
              />
            )}
          </div>
        </div>
      </Table.Cell>
    </Table.Row>
  );
};

TasksTableRow.propTypes = {
  task: PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.string,
    content: PropTypes.string,
    cropSeasons: PropTypes.shape({
      message: PropTypes.string,
      status: PropTypes.number,
      cropSeasons: PropTypes.arrayOf(
        PropTypes.shape({
          id: PropTypes.string,
          message: PropTypes.string,
          status: PropTypes.number,
          name: PropTypes.string
        })
      )
    }),
    dueDate: PropTypes.instanceOf(Date),
    startDate: PropTypes.string,
    productCount: PropTypes.number,
    totalAreaValue: PropTypes.number,
    totalTaskCost: PropTypes.number,
    fieldCount: PropTypes.number,
    status: PropTypes.string
  }).isRequired,
  onAction: PropTypes.func.isRequired,
  flags: PropTypes.arrayOf(PropTypes.object).isRequired,
  memberRole: PropTypes.string.isRequired,
  memberFinancialAccess: PropTypes.string.isRequired,
  runningProcesses: PropTypes.bool
};

TasksTableRow.defaultProps = {
  runningProcesses: false
};

export default withLDConsumer()(TasksTableRow);
