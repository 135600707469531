import React, { createContext, useReducer } from 'react';
import PropTypes from 'prop-types';

import reducer, {
  SET_STEP,
  SET_SELECTED_COMMODITY,
  SET_SELECTED_PROPERTIES,
  SET_SHOW_TOAST,
  SET_TOAST_MESSAGE,
  SET_LOADING_FETCH
} from '../reducer';

export const initialState = {
  step: 1,
  selectedCommodity: { id: '', name: '' },
  cropZones: [],
  showToast: false,
  typeToast: 'success',
  messageToast: 'Yield data saved.',
  isLoading: false
};

export const YieldContext = createContext(initialState);

const YieldProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialState);

  const goBack = (specificStep = null) => {
    dispatch({
      type: SET_STEP,
      payload: specificStep || state.step - 1
    });
  };

  const goForward = (specificStep = null) => {
    dispatch({
      type: SET_STEP,
      payload: specificStep || state.step + 1
    });
  };

  const updateSelectedCommodity = selectedCommodity => {
    dispatch({
      type: SET_SELECTED_COMMODITY,
      payload: selectedCommodity
    });
  };

  const updateCropZones = cropZones => {
    dispatch({
      type: SET_SELECTED_PROPERTIES,
      payload: cropZones
    });
  };

  const setIsLoading = isLoading => {
    dispatch({
      type: SET_LOADING_FETCH,
      payload: isLoading
    });
  };

  const setResponseToastMessage = ({ type, message, isToastActive }) => {
    dispatch({
      type: SET_TOAST_MESSAGE,
      payload: {
        isToastActive,
        typeToast: type,
        messageToast: message
      }
    });
  };

  const setShowToast = showToast => {
    dispatch({
      type: SET_SHOW_TOAST,
      payload: showToast
    });
  };

  return (
    <YieldContext.Provider
      value={{
        state,
        dispatch,
        goBack,
        goForward,
        updateSelectedCommodity,
        updateCropZones,
        setShowToast,
        setResponseToastMessage,
        setIsLoading
      }}
    >
      {children}
    </YieldContext.Provider>
  );
};

YieldProvider.defaultProps = {
  children: null
};

YieldProvider.propTypes = {
  children: PropTypes.node
};

export default YieldProvider;
