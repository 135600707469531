import React, { useEffect, useState, useContext } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { Formik } from 'formik';
import { Button, Spinner, Column, Toast } from '@agconnections/grow-ui';
import useOrganizationApi from 'hooks/useOrganizationApi';
import Breadcrumb from 'components/App/AppShell/components/Breadcrumb';
import { Context } from 'components/Store';
import Paper from 'components/Paper';
import EditScreenForm from 'components/EditScreenForm';
import catchCancel from 'helpers/catchCancel';
import { parseServerError } from 'helpers/errorHelpers';
import { AmplitudeContext } from 'utilities/amplitude/useAmplitude';
import { paths } from 'routes/paths';
import NameField from './components/NameField';
import PhoneNumberField from './components/PhoneNumberField';
import EmailField from './components/EmailField';
import CityField from './components/CityField';
import StateField from './components/StateField';
import ZipCodeField from './components/ZipCodeField';
import AddressLineOneField from './components/AddressLineOneField';
import AddressLineTwoField from './components/AddressLineTwoField';
import LegalNameField from './components/LegalNameField';
import NotesField from './components/NotesField';

const Company = () => {
  const [error, setError] = useState('');
  const { id } = useParams();
  const history = useHistory();
  const isCreateScreen = id === 'company';
  const [company, setCompany] = useState({});
  const [loading, setLoading] = useState(true);
  const [, dispatch] = useContext(Context);
  const workerAPI = useOrganizationApi('worker');
  const companyAPI = useOrganizationApi('company');
  const amplitude = useContext(AmplitudeContext);

  useEffect(() => {
    setLoading(true);
    const { promise } = workerAPI.fetch(id);
    promise
      .then(({ data }) => {
        if (data) setCompany(data);
        setLoading(false);
      })
      .catch(catchCancel)
      .catch(() => {
        setLoading(false);
      });
  }, [workerAPI, id]);

  const initValues = {
    id: company?.id || '',
    name: company?.name || '',
    legalName: company.legalName || '',
    notes: company?.notes || '',
    address: {
      line1: company?.address?.line1 || '',
      line2: company?.address?.line2 || '',
      city: company?.address?.city || '',
      state: company?.address?.state || '',
      zipCode: company?.address?.zipCode || ''
    },
    phone: company?.phone || '',
    email: company?.email || ''
  };

  const onValidate = values => {
    const errors = {};
    const regex = /^\s+$/;
    if (!values.name || regex.test(values.name)) {
      errors.name = 'Required';
    }

    return errors;
  };

  const editUpdateSave = companyCopy => {
    const { promise } = companyAPI.update(companyCopy.id, companyCopy);
    promise
      .then(() => {
        history.push(paths.companies);
      })
      .catch(catchCancel)
      .catch(parseServerError(dispatch));
    return () => {
      dispatch({ type: 'SET_TOOLBAR', payload: null });
    };
  };

  const newCreateSave = companyCopy => {
    const { promise } = companyAPI.create(companyCopy);
    promise
      .then(() => {
        history.push(paths.companies);
      })
      .catch(catchCancel)
      .catch(err => {
        if (err.response?.data?.displayErrorMessage) {
          setError(err.response.data.displayErrorMessage);
        } else {
          parseServerError(dispatch)(err);
        }
      });
    return () => {
      dispatch({ type: 'SET_TOOLBAR', payload: null });
    };
  };

  const onSubmit = async values => {
    // BASE is only allowing us to save 1 address, phone, email currently, this will need revisited once they permit more.
    if (!isCreateScreen) {
      editUpdateSave(values);
    } else {
      newCreateSave(values);
    }
  };

  // useEffect(() => {
  //   let cancel;
  //   if (!isCreateScreen) {
  //     let promise;
  //     ({ promise, cancel } = companyAPI.fetch(id));
  //     promise
  //       .then(response => {
  //         setCompany(response.data.data);
  //       })
  //       .catch(catchCancel)
  //       .catch(parseServerError(dispatch))
  //       .finally(() => setLoading(false));
  //     return () => {
  //       dispatch({ type: 'SET_TOOLBAR', payload: null });
  //       cancel();
  //     };
  //   }
  //   return () => cancel && cancel();
  // }, [companyAPI, dispatch, id, isCreateScreen]);

  const getNameCrumbs = () => {
    if (!isCreateScreen) return 'Edit Company';
    return 'Create Company';
  };

  const getFormik = () => {
    return (
      <>
        <Formik
          initialValues={initValues}
          validate={onValidate}
          onSubmit={values => {
            // amplitude trigger
            amplitude.sendEventToAmplitude(
              amplitude.events.epic.Companies.createCompany
            );
            onSubmit(values);
          }}
        >
          {({ handleSubmit }) => {
            return (
              <div className="mb-10">
                <div className="flex items-center">
                  <div className="w-full flex mr-4">
                    <div className="text-3xl my-6">
                      {!isCreateScreen ? 'Edit Company' : 'Create Company'}
                    </div>
                  </div>
                  <div className="flex">
                    <div className="w-1/2 flex mr-4">
                      <div className="flex mx-2">
                        <Button
                          type="outline"
                          onClick={() => history.push(paths.companies)}
                        >
                          Cancel
                        </Button>
                        <div className="ml-2">
                          <Button
                            id="save-and-quit-button"
                            type="primary"
                            onClick={handleSubmit}
                          >
                            Save
                          </Button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <Paper>
                  <EditScreenForm onSubmit={handleSubmit}>
                    <Column width="w-full">
                      <div
                        data-testid="more-info-title"
                        className="text-2xl font-bold mt-10"
                      >
                        Basic Info
                      </div>
                      <div className="flex items-center">
                        <div className="w-1/2 flex-grow mr-4">
                          <NameField />
                        </div>
                        <div className="w-1/2 flex-grow mr-4">
                          <LegalNameField />
                        </div>
                      </div>

                      <div className="flex items-center">
                        <div className="w-1/2 flex-grow mr-4">
                          <EmailField />
                        </div>
                        <div className="w-1/2 flex-grow mr-4">
                          <PhoneNumberField />
                        </div>
                      </div>

                      <div
                        data-testid="more-info-title"
                        className="text-2xl font-bold mt-10"
                      >
                        Address Info
                      </div>
                      <div className="flex items-center">
                        <div className="w-1/2 flex-grow mr-4">
                          <AddressLineOneField />
                        </div>
                        <div className="w-1/2 flex-grow mr-4">
                          <AddressLineTwoField />
                        </div>
                      </div>

                      <div className="flex items-center">
                        <div className="w-1/2 flex-grow mr-4">
                          <CityField />
                        </div>
                        <div className="w-1/4 flex-grow mr-2">
                          <StateField />
                        </div>
                        <div className="w-1/4 flex-grow mr-4">
                          <ZipCodeField />
                        </div>
                      </div>

                      <div
                        data-testid="more-info-title"
                        className="text-2xl font-bold mt-10"
                      >
                        Notes
                      </div>
                      <div className="flex items-center">
                        <div className="w-full">
                          <NotesField />
                        </div>
                      </div>
                    </Column>
                  </EditScreenForm>
                </Paper>
              </div>
            );
          }}
        </Formik>
      </>
    );
  };

  const getFormikOrSpinner = () => {
    if (loading) {
      return <Spinner />;
    }
    return getFormik();
  };

  return (
    <div className="w-full h-full">
      <Breadcrumb disabled>
        <Breadcrumb.Item
          title="Company"
          value="All Companies"
          to={paths.companies}
        />
        <Breadcrumb.Item title="Company" value={getNameCrumbs()} isLast />
      </Breadcrumb>
      <>
        {error && (
          <Toast icon="error" onClose={() => setError('')}>
            {error}
          </Toast>
        )}
        {getFormikOrSpinner()}
      </>
    </div>
  );
};

export default Company;
