import React from 'react';
import PropTypes from 'prop-types';
import { Modal } from 'syngenta-digital-cropwise-react-ui-kit';
import Spinner from 'assets/spinner_modal.svg';

const SpinnerModal = ({ open, message, opaque }) => (
  <Modal
    centered
    className={opaque ? 'opacity-75	' : ''}
    closable={false}
    data-testid="spinner-modal"
    footer={null}
    maskClosable={false}
    open={open}
  >
    <div className="py-8 flex flex-col justify-center items-center">
      <img
        src={Spinner}
        alt="spinner"
        className="animate-spin m-auto mb-40px"
      />
      <div className="font-semibold">{message}</div>
    </div>
  </Modal>
);

SpinnerModal.defaultProps = {
  open: false,
  message: 'Loading',
  opaque: false
};

SpinnerModal.propTypes = {
  open: PropTypes.bool,
  message: PropTypes.string,
  opaque: PropTypes.bool
};

export default SpinnerModal;
