import * as Yup from 'yup';

export const tankInfo = Yup.object().shape({
  tankSizeValue: Yup.number().default(0),
  tankSizeUnit: Yup.string().default('bag'),
  tankCount: Yup.number().default(0),
  carrierPerAreaValue: Yup.number().default(0),
  carrierPerAreaUnit: Yup.string().default('bag'),
  totalCarrierValue: Yup.number().default(0),
  totalCarrierUnit: Yup.string().default('bag'),
  tankValueTypeEnteredByUser: Yup.string().default('CarrierPerArea')
});

export const weather = Yup.object().shape({
  date: Yup.date().nullable(),
  temperature: Yup.string().default(''),
  temperatureUnit: Yup.string().default('F'),
  relativeHumidity: Yup.string().default(''),
  skyCondition: Yup.string().default(''),
  windSpeed: Yup.string().default(''),
  windSpeedUnit: Yup.string().default('MPH'),
  windDirection: Yup.string().default(''),
  soilMoisture: Yup.string().default(''),
  type: Yup.string().default('')
});

export const weatherDefault = Yup.array()
  .nullable()
  .of(weather);

export const assigneesDefault = Yup.array()
  .nullable()
  .of(Yup.object());

export const applicatorsDefault = Yup.array()
  .nullable()
  .of(Yup.object());

export const productsDefault = Yup.array()
  .nullable()
  .of(Yup.object());

export const propertiesDefault = Yup.array()
  .nullable()
  .of(Yup.object());

export default Yup.object().shape({
  name: Yup.string()
    .trim()
    .required('Name is required'),
  status: Yup.string().required('Status is a required field'),
  startDate: Yup.date().required('Start Date & Time is a required field'),
  duration: Yup.string()
    .default(2)
    .required('Duration is a required field'),
  type: Yup.string().default('General'),
  timingEvent: Yup.string().default(''),
  // cropSeasonIds: Yup.string()
  //   .default('')
  //   .required('The Crop Season is a required field'),
  applicationStrategy: Yup.string().default('ratearea'),
  flags: Yup.string().default(''),
  source: Yup.string().default(''),
  authorizedBy: Yup.string().default(''),
  authorizedDate: Yup.date().nullable(),
  bufferZone: Yup.string().default(''),
  bufferReason: Yup.string().default(''),
  totalAreaValue: Yup.number().default(0),
  tankInformation: tankInfo,
  weather: weatherDefault,
  applicators: applicatorsDefault,
  assigneeIds: assigneesDefault,
  notes: Yup.string().default(''),
  totalApplied: Yup.number().default(0),
  coveragePercent: Yup.number().default(100),
  properties: propertiesDefault,
  products: productsDefault
});
