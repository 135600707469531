import useAnalytics from 'hooks/useAnalytics';
import { useCallback, useContext, useEffect } from 'react';
import { v4 as uuidv4 } from 'uuid';
import { IntegrationsContext } from '../context/IntegrationsProvider';

const useSubmissionAnalytics = ({
  onMountTriggerEvent = undefined,
  onUnMountTriggerEvent = undefined
} = {}) => {
  const orgId = localStorage.getItem('selectedOrganizationId');
  const {
    state: { submissionFlowId },
    updateSubmissionFlowId
  } = useContext(IntegrationsContext);

  const { events, triggerAnalyticsEvent } = useAnalytics();

  const interceptEventAddFlowIdToData = useCallback(
    eventTrigger => {
      if (eventTrigger) {
        const { event, data } = eventTrigger;
        let newFlowId;
        if (event === events.epic.Integrations.submissionStarted) {
          newFlowId = uuidv4();
          updateSubmissionFlowId(newFlowId);
        }

        return { orgId, flowId: newFlowId ?? submissionFlowId, ...data };
      }
      return {};
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  const triggerEvent = useCallback(
    (event, data) => {
      triggerAnalyticsEvent(
        event,
        interceptEventAddFlowIdToData({ event, data })
      );
    },
    [triggerAnalyticsEvent, interceptEventAddFlowIdToData]
  );

  useEffect(() => {
    if (onMountTriggerEvent) {
      triggerEvent(onMountTriggerEvent.event, onMountTriggerEvent.data);
    }

    return () => {
      if (onUnMountTriggerEvent) {
        triggerEvent(onUnMountTriggerEvent.event, onUnMountTriggerEvent.data);
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return {
    events,
    triggerAnalyticsEvent: triggerEvent
  };
};

export default useSubmissionAnalytics;
