import { Maths } from 'helpers/maths';
import { YIELD_DEFAULT_ROUND } from 'screens/YieldV2/constants';
import sanitizeAndConvertToNumber from './sanitizeAndConvertToNumber';

/**
 * Calculates the net weight based on gross weight and tare weight,
 * using sanitized values and performing rounded subtraction.
 *
 * @param {Object} params - The parameters for calculating the net weight.
 * @param {number} params.grossWeight - The gross weight value.
 * @param {number} params.tareWeight - The tare weight value.
 * @returns {number} The calculated net weight, or null if calculation is not possible.
 */
const calculateNetWeight = ({ grossWeight, tareWeight }) => {
  if (!grossWeight || !tareWeight) return null;
  const sanitizedGrossWeight = sanitizeAndConvertToNumber(grossWeight);
  const sanitizedTareWeight = sanitizeAndConvertToNumber(tareWeight);
  if (sanitizedGrossWeight === null || sanitizedTareWeight === null) {
    return null;
  }
  return Maths.minusRound(
    sanitizedGrossWeight,
    sanitizedTareWeight,
    YIELD_DEFAULT_ROUND
  );
};

export default calculateNetWeight;
