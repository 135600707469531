export const SET_SELECTED = 'SET_SELECTED';
export const ATTACH_DETAILS_TO_ITEM = 'ATTACH_DETAILS_TO_ITEM';
export const SET_STORAGE_LOCATIONS = 'SET_STORAGE_LOCATIONS';
export const SET_LOADING_STATUS = 'SET_LOADING_STATUS';
export const SET_FETCHING_DETAILS_STATUS = 'SET_FETCHING_DETAILS_STATUS';
export const SET_SORTED = 'SET_SORTED';

const reducer = (state, action) => {
  switch (action.type) {
    case SET_STORAGE_LOCATIONS:
      return {
        ...state,
        ...action.payload,
        recentFetchIds: []
      };
    case SET_SELECTED:
      return {
        ...state,
        selectedId: action.payload
      };
    case ATTACH_DETAILS_TO_ITEM:
      return {
        ...state,
        data: state.data.map(item =>
          item.id === action.payload.id ? action.payload : item
        ),
        recentFetchIds: [...state.recentFetchIds, action.payload.id]
      };
    case SET_LOADING_STATUS:
      return {
        ...state,
        isLoading: action.payload
      };
    case SET_FETCHING_DETAILS_STATUS:
      return {
        ...state,
        isFetchingDetails: action.payload
      };
    case SET_SORTED:
      return {
        ...state,
        sortBy: action.payload.sortBy,
        sortDir: action.payload.sortDir
      };
    default:
      return state;
  }
};

export default reducer;
