import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';

import { Icon } from '@agconnections/grow-ui';
import ClickableDiv from 'components/ClickableDiv';
import { verticalCollapse } from 'utilities/animations';

const selectArrowIcon = open => (
  <Icon icon={open ? 'chevron-up' : 'chevron-down'} color="#fff" />
);

const setBackground = (currentIdx, targetIndex) =>
  currentIdx === targetIndex
    ? 'bg-collapsible-selected-option font-semibold'
    : 'hover:bg-collapsible-hovered-option';

const setSelectedLine = (currentIdx, targetIndex) =>
  currentIdx === targetIndex
    ? 'border-l-2 border-r-4 rounded-2xl border-white'
    : 'ml-2';

const CollapsibleOption = ({
  title,
  options,
  showOptions,
  selectedIdx,
  handleSelectedItem
}) => {
  const [toggleOptions, setToggleOptions] = useState(showOptions);
  const [arrowIcon, setArrowIcon] = useState(selectArrowIcon(showOptions));

  const optionsRef = useRef();

  useEffect(() => {
    setArrowIcon(selectArrowIcon(toggleOptions));
    verticalCollapse(optionsRef.current, toggleOptions);
  }, [toggleOptions]);

  return (
    <div className="mb-3">
      <ClickableDiv
        className="flex justify-between items-center mb-3 px-5"
        onClick={() => setToggleOptions(!toggleOptions)}
      >
        <span className="text-white">{title}</span>
        {arrowIcon}
      </ClickableDiv>
      <div
        className="transition-all duration-200 overflow-y-hidden"
        ref={optionsRef}
        data-testid="collapsible-options"
      >
        <ul className="text-white">
          {options?.map(option => (
            <ClickableDiv
              key={option.key}
              onClick={() => handleSelectedItem(option.key)}
            >
              <li
                data-testid={option.key}
                className={`py-2 flex items-center pl-3 cursor-pointer h-12 transition-all ease-in-out duration-200 ${setBackground(
                  option.key,
                  selectedIdx
                )}`}
              >
                <span
                  className={`${setSelectedLine(
                    option.key,
                    selectedIdx
                  )} inline-block h-8`}
                />
                <span className="inline-block px-5">{option.label}</span>
              </li>
            </ClickableDiv>
          ))}
        </ul>
      </div>
    </div>
  );
};

CollapsibleOption.propTypes = {
  title: PropTypes.string.isRequired,
  options: PropTypes.arrayOf(PropTypes.string).isRequired,
  showOptions: PropTypes.bool,
  selectedIdx: PropTypes.number,
  handleSelectedItem: PropTypes.func
};

CollapsibleOption.defaultProps = {
  showOptions: false,
  selectedIdx: -1,
  handleSelectedItem: () => {}
};

export default CollapsibleOption;
