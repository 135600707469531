import React from 'react';
import PropTypes from 'prop-types';

import { Tag } from '@agconnections/grow-ui';

const Status = ({ type }) => {
  const attributes = {
    bgColor: '#FFE4AE',
    fontColor: '#744A0B',
    text: type
  };

  switch (type) {
    case 'done':
      attributes.bgColor = '#C3EAD1';
      attributes.fontColor = '#0C612C';
      attributes.text = 'Submitted';
      break;
    case 'in_progress':
      attributes.bgColor = '#C6E6FF';
      attributes.fontColor = '#005693';
      attributes.text = 'Submission in Progress';
      break;
    case 'pending':
      attributes.bgColor = '#A9EFE8';
      attributes.fontColor = '#165C56';
      attributes.text = 'Submission Pending';
      break;
    case 'error':
      attributes.bgColor = '#FFD9D9';
      attributes.fontColor = '#9C2628';
      attributes.text = 'Errors in Submission';
      break;
    default:
      break;
  }

  return (
    <Tag color={attributes.bgColor}>
      <span className="p-2" style={{ color: attributes.fontColor }}>
        {attributes.text}
      </span>
    </Tag>
  );
};

Status.defaultProps = {
  type: ''
};

Status.propTypes = {
  type: PropTypes.oneOf(['error', 'pending', 'in_progress', 'done'])
};

export default Status;
