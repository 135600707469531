import React, { useContext } from 'react';
import PropTypes from 'prop-types';

import { Button } from '@agconnections/grow-ui';

import { SubmissionSummaryContext } from '../../../../context/SubmissionSummaryProvider';

const ModalButtons = ({ handleClose }) => {
  const {
    state: { resubmitEnabled },
    submitIntegration
  } = useContext(SubmissionSummaryContext);

  return (
    <>
      <div className="mr-5">
        <Button type="outline" id="cancel-button" onClick={handleClose}>
          Cancel
        </Button>
      </div>
      <div>
        <Button type="primary" id="submit-button" onClick={submitIntegration}>
          {resubmitEnabled ? 'Resubmit' : 'Submit'}
        </Button>
      </div>
    </>
  );
};

ModalButtons.propTypes = {
  handleClose: PropTypes.func.isRequired
};

export default ModalButtons;
