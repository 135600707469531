import React from 'react';
import PropTypes from 'prop-types';
import { Table } from '@agconnections/grow-ui';
import { findGeoJSON } from 'screens/Property/helpers/propertyDataHelpers';
import FarmRow from 'screens/Property/PropertiesLanding/components/FarmRow';
import CropZoneRow from 'screens/Property/PropertiesLanding/components/CropZoneRow';
import FieldRow from 'screens/Property/PropertiesLanding/components/FieldRow';

const renderCropZoneRow = (
  property,
  field,
  cropZone,
  fieldsAndAreasGeoJSONCollection,
  handlers
) => {
  const cropZoneGeoJSON = findGeoJSON(
    fieldsAndAreasGeoJSONCollection,
    cropZone
  );
  return (
    <Table.Row
      key={cropZone.id}
      onClick={() => handlers.handleSelectedCropZone(property, field, cropZone)}
      selectable={!!property.fields.length}
      selected={handlers.isCropZoneSelected(cropZone)}
    >
      <Table.Cell>
        <CropZoneRow
          cropZone={cropZone}
          icon={cropZoneGeoJSON}
          fieldId={field.id}
          isEditable={false}
        />
      </Table.Cell>
    </Table.Row>
  );
};

const renderCropZones = (
  field,
  property,
  fieldsAndAreasGeoJSONCollection,
  handlers
) => {
  return field.cropzones.map(cropZone =>
    renderCropZoneRow(
      property,
      field,
      cropZone,
      fieldsAndAreasGeoJSONCollection,
      handlers
    )
  );
};

const renderFieldRowGroup = (
  field,
  property,
  fieldsAndAreasGeoJSONCollection,
  handlers
) => {
  const fieldGeoJSON = findGeoJSON(fieldsAndAreasGeoJSONCollection, field);
  return (
    <Table.RowGroup
      key={field.id}
      arrowIcon
      onClick={() => handlers.handleSelectedField(property, field)}
      selectable={!!property.fields.length}
      selected={handlers.isFieldSelected(field)}
    >
      <Table.Row>
        <Table.Cell>
          <FieldRow field={field} icon={fieldGeoJSON} />
        </Table.Cell>
      </Table.Row>
      {renderCropZones(
        field,
        property,
        fieldsAndAreasGeoJSONCollection,
        handlers
      )}
    </Table.RowGroup>
  );
};

const renderFields = (property, fieldsAndAreasGeoJSONCollection, handlers) => {
  return property.fields.map(field =>
    renderFieldRowGroup(
      field,
      property,
      fieldsAndAreasGeoJSONCollection,
      handlers
    )
  );
};

const GroupByFarm = ({
  property,
  fieldsAndAreasGeoJSONCollection,
  handleSelectedFarm,
  isFarmSelected,
  handleSelectedField,
  isFieldSelected,
  handleSelectedCropZone,
  isCropZoneSelected
}) => {
  const handlers = {
    handleSelectedField,
    isFieldSelected,
    handleSelectedCropZone,
    isCropZoneSelected
  };
  return (
    <Table.RowGroup
      key={property.id}
      arrowIcon
      onClick={() => handleSelectedFarm(property)}
      selectable={!!property.fields.length}
      selected={isFarmSelected(property)}
    >
      <Table.Row>
        <Table.Cell>
          <FarmRow farm={property} />
        </Table.Cell>
      </Table.Row>
      {renderFields(property, fieldsAndAreasGeoJSONCollection, handlers)}
    </Table.RowGroup>
  );
};
GroupByFarm.propTypes = {
  property: PropTypes.shape({
    id: PropTypes.string.isRequired,
    fields: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.string.isRequired,
        cropzones: PropTypes.arrayOf(
          PropTypes.shape({
            id: PropTypes.string.isRequired
          })
        ).isRequired
      })
    ).isRequired
  }).isRequired,
  fieldsAndAreasGeoJSONCollection: PropTypes.arrayOf(PropTypes.object)
    .isRequired,
  handleSelectedFarm: PropTypes.func.isRequired,
  isFarmSelected: PropTypes.func.isRequired,
  handleSelectedField: PropTypes.func.isRequired,
  isFieldSelected: PropTypes.func.isRequired,
  handleSelectedCropZone: PropTypes.func.isRequired,
  isCropZoneSelected: PropTypes.func.isRequired
};
export default GroupByFarm;
