import {
  getCurrentOrganizationAndPermissions,
  selectOrganization
} from 'utilities/updated-permissions';

const setOrgAndPermission = (user, defaultOrg, dispatch, setStateLoaded) => {
  const { currentOrganization } = getCurrentOrganizationAndPermissions(
    user,
    defaultOrg
  );
  selectOrganization(dispatch, currentOrganization, user);
  setStateLoaded(true);
};

export default setOrgAndPermission;
