import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import MonetizationOnOutlined from '@material-ui/icons/MonetizationOnOutlined';
import HelpOutlineOutlined from '@material-ui/icons/HelpOutlineOutlined';
import { Tooltip } from 'syngenta-digital-cropwise-react-ui-kit';
import {
  MEASUREMENT_UNITS,
  convertUnit,
  getAbbreviatedUnit
} from 'helpers/unitsAbbreviation';
import { getBushelWeight } from 'helpers/getBushelWeight';
import SummaryCard from '../SummaryCard';
import useGrossRevenue from '../../hooks/useGrossRevenue';

const currencyTemplate = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD'
});

const GrossRevenueHeader = () => {
  return (
    <div className="h-full flex items-center justify-between px-4">
      <div className="flex items-center gap-2">
        <MonetizationOnOutlined className="text-neutral-600" />
        <span className="text-base leading-6 whitespace-no-wrap">
          Gross Revenue
        </span>
      </div>
      <div className="flex items-center gap-0.5 space-x-0 text-figma-blue-60 hover:text-figma-blue-70">
        <HelpOutlineOutlined className="cursor-pointer" />
        <Tooltip
          title="Gross Revenue is the combined revenue for all loads sent to sale locations"
          trigger="click"
        >
          <span className="whitespace-no-wrap text-xs cursor-pointer">
            What is this?
          </span>
        </Tooltip>
      </div>
    </div>
  );
};

const GrossRevenueContent = ({ grossRevenue }) => {
  return (
    <div className="p-4 text-center">
      <span className="font-semibold text-5xl leading-54px">
        {currencyTemplate.format(grossRevenue)}
      </span>
    </div>
  );
};

const GrossRevenueFooter = ({ avgPrice, selectedUOM }) => {
  return (
    <div className="h-full flex pr-4 items-center justify-end">
      <span className="text-neutral-1000 mr-3">Avg. price</span>
      <span className="text-xl font-semibold mr-2px">
        {avgPrice === null ? '---' : currencyTemplate.format(avgPrice)}
      </span>
      <span className="text-neutral-1000">/ {selectedUOM}</span>
    </div>
  );
};

const GrossRevenue = ({
  selectedUOM,
  className,
  selectedCropName,
  cropSeasonId,
  cropId
}) => {
  const { revenueData, isLoading } = useGrossRevenue({
    cropSeasonId,
    cropId
  });
  const { grossRevenue, avgPrice } = revenueData || {
    grossRevenue: 0,
    avgPrice: null
  };

  const convertedAvgPrice = useMemo(() => {
    // Special handling for bushels
    if (!avgPrice) {
      return null;
    }
    if (selectedUOM === 'bushel') {
      // Convert bushels to pounds using the crop-specific weight
      let poundsPerBushel = getBushelWeight(selectedCropName);
      if (!poundsPerBushel) {
        // This should not happen normally, because the bushel option won't be available,
        // but in case it is, we are safe, so returned value is a number and not a NaN
        // eslint-disable-next-line no-console
        console.error(
          'No bushel/lbs conversion found for crop:',
          selectedCropName
        );
        poundsPerBushel = 60;
      }
      // Adjust the average price per bushel to per pound
      return avgPrice * poundsPerBushel;
    }
    // For other units, use the convertUnit function to adjust the average price per pound
    const conversionFactor = convertUnit(
      1,
      MEASUREMENT_UNITS.POUND,
      selectedUOM
    );
    return avgPrice / conversionFactor;
  }, [selectedUOM, avgPrice, selectedCropName]);

  if (!selectedCropName || !selectedUOM) {
    return null;
  }

  return (
    <SummaryCard
      header={<GrossRevenueHeader />}
      footer={
        <GrossRevenueFooter
          avgPrice={convertedAvgPrice}
          selectedUOM={getAbbreviatedUnit(selectedUOM)}
        />
      }
      content={<GrossRevenueContent grossRevenue={grossRevenue} />}
      className={className}
      isLoading={isLoading}
    />
  );
};

GrossRevenue.propTypes = {
  selectedUOM: PropTypes.string.isRequired,
  cropSeasonId: PropTypes.string,
  cropId: PropTypes.string,
  className: PropTypes.string,
  selectedCropName: PropTypes.string.isRequired
};

GrossRevenue.defaultProps = {
  cropSeasonId: null,
  cropId: null,
  className: ''
};

GrossRevenueFooter.propTypes = {
  avgPrice: PropTypes.number,
  selectedUOM: PropTypes.string.isRequired
};

GrossRevenueFooter.defaultProps = {
  avgPrice: null
};

GrossRevenueContent.propTypes = {
  grossRevenue: PropTypes.number.isRequired
};

export default GrossRevenue;
