/* istanbul ignore file */
import { Factory } from 'miragejs';
import faker from 'faker';
import globalSchema from '../utils/globalSchema';

const DashboardWeather = {
  current: {
    updatedAt: faker.date.recent(),
    forecast: faker.random.arrayElement(['cloudy', 'sunny', 'rainy']),
    temperature: {
      value: 56,
      unit: 'f'
    }
  },
  location: {
    city: 'denver',
    stateAbbr: 'co'
  },
  today: {
    high: {
      value: faker.random.number({ min: 50, max: 100 }),
      unit: 'f'
    },
    low: {
      value: faker.random.number({ min: 20, max: 49 }),
      unit: 'f'
    },
    humidity: `${faker.random.number({ min: 0, max: 100 })}%`,
    wind: {
      direction: 'ssw',
      speed: `${faker.random.number({ min: 0, max: 20 })} m/h'`
    },
    precipitation: '.1"',
    dewPoint: 22
  },
  upcoming: ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday'],
  ...globalSchema
};

export default Factory.extend(DashboardWeather);
