import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import { Button, ButtonType } from 'syngenta-digital-cropwise-react-ui-kit';
import './index.css';

const ContainerSubmissionBanner = ({
  backgroundColor,
  icon,
  dismissAction,
  children,
  'data-testid': dataTestId
}) => {
  const containerRef = useRef();
  const handleDismissAction = () => {
    containerRef.current.classList.add(
      'product-detail-status-submission-banner'
    );
    containerRef.current.addEventListener('animationend', dismissAction, {
      once: true
    });
  };
  return (
    <div
      ref={containerRef}
      data-testid={dataTestId}
      className="text-sm remove-parent-padding flex flex-row items-center w-full"
      style={{ backgroundColor }}
      colSpan={7}
    >
      <div className="flex flex-grow inline-flex">
        <div className="flex flex-grow">
          <div className="flex px-4 py-3">
            <img
              alt="info"
              src={icon}
              style={{ width: '24px', height: '24px' }}
            />
          </div>
          <div className="flex items-center">{children}</div>
        </div>
        <div className="inline-flex justify-end">
          <Button
            data-testid="dismiss-button"
            type={ButtonType.outline}
            className="outlineButton mx-3 my-3 p-2 h-6"
            onClick={handleDismissAction}
          >
            Dismiss
          </Button>
        </div>
      </div>
    </div>
  );
};
ContainerSubmissionBanner.propTypes = {
  backgroundColor: PropTypes.string.isRequired,
  icon: PropTypes.string.isRequired,
  dismissAction: PropTypes.func.isRequired,
  children: PropTypes.node.isRequired,
  'data-testid': PropTypes.string
};
ContainerSubmissionBanner.defaultProps = {
  'data-testid': ''
};
export default ContainerSubmissionBanner;
