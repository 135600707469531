import React from 'react';
import PropTypes from 'prop-types';
import { Spinner, Tag } from '@agconnections/grow-ui';
import CheckIcon from '@material-ui/icons/Check';

const StepHeader = ({
  title,
  description,
  unmatchCount,
  isMatchCountVisible,
  isLoading
}) => {
  return (
    <div data-testid="top-header">
      <div className="w-full flex h-10 mb-2 items-center font-semibold">
        <p className="text-xl">{title}</p>
      </div>
      <div className="w-full">
        <div className="grid grid-cols-10">
          <div className="col-span-8 justify-self-start">
            <p className="font-normal text-sm ">{description}</p>
          </div>
          {isLoading && isMatchCountVisible ? (
            <div className="col-span-2 justify-self-end">
              <Spinner size="sm" />
            </div>
          ) : (
            <div className="col-span-2 justify-self-end">
              {unmatchCount === 0 ? (
                <div data-testid="all-matched">
                  <Tag key="unmatched-properties-length" color="#DFFBE8">
                    <CheckIcon
                      style={{
                        marginLeft: '7px',
                        fontSize: 16,
                        color: '#DFFBE8',
                        backgroundColor: '#0C612C',
                        borderRadius: '50%'
                      }}
                    />
                    <span className="text-base mr-2 ml-2 font-thin text-green-900">
                      All Matched
                    </span>
                  </Tag>
                </div>
              ) : (
                <div data-testid="unmatched-properties">
                  <Tag key="unmatched-properties-length" color="#FFE4AE">
                    <div className="text-base mr-2 ml-2 text-yellow-700">
                      <span
                        className="font-bold"
                        data-testid="unmatched-properties-count"
                      >
                        {Math.max(unmatchCount, 0)}
                      </span>
                      <span className="font-thin"> Unmatched</span>
                    </div>
                  </Tag>
                </div>
              )}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

StepHeader.defaultProps = {
  isLoading: false,
  unmatchCount: 0,
  isMatchCountVisible: false
};

StepHeader.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  unmatchCount: PropTypes.number,
  isMatchCountVisible: PropTypes.bool,
  isLoading: PropTypes.bool
};

export default StepHeader;
