import React, { useEffect } from 'react';
import { Input, Form } from '@agconnections/grow-ui';
import PropTypes from 'prop-types';

const ProfileFields = ({ profilePicture }) => {
  useEffect(() => {
    // if the profile picture is updated.
  }, [profilePicture]);
  return (
    <>
      <div className="flex" data-testid="profile-fields">
        <div className="w-1/2 pr-5">
          <Form.Field label="First Name">
            <Input name="firstName" value="" />
          </Form.Field>
        </div>
        <div className="w-1/2 pl-5">
          <Form.Field label="Last Name">
            <Input name="lastName" value="" />
          </Form.Field>
        </div>
      </div>
      <div className="flex items-center">
        <div className="w-1/2 pr-5">
          <Form.Field label="Display Name">
            <Input name="displayName" value="" />
          </Form.Field>
        </div>
        <div className="w-1/2 pl-5 ">
          <label
            htmlFor="profilePicture"
            className="hover:bg-gray-100 hover:cursor-pointer p-2 h-full w-full h-16 block transition duration-200 rounded"
          >
            <div className="w-16 h-16 rounded-full bg-gray-400 mr-5 inline-block align-middle">
              {profilePicture && (
                <img
                  src={profilePicture.src}
                  alt="upload preview"
                  className="h-full object-cover"
                />
              )}
            </div>
            <input
              type="file"
              className="absolute invisible"
              name="profilePicture"
              id="profilePicture"
            />
            <div className="inline-block align-middle pointer pointer-events-none">
              <div className="text-gray-600 font-bold">
                {profilePicture ? profilePicture.name : 'Profile Picture'}
              </div>
            </div>
          </label>
        </div>
      </div>
    </>
  );
};

ProfileFields.defaultProps = {
  profilePicture: null
};

ProfileFields.propTypes = {
  profilePicture: PropTypes.shape({
    name: PropTypes.string,
    src: PropTypes.string
  })
};

export default ProfileFields;
