import PropTypes from 'prop-types';
import React, { useContext } from 'react';
import { mapFieldsGeoJSONCollectionOnCropZones } from 'helpers/propertyHelpers';
import ChecklistProperty from '../../../CropSeasons/CropSeason/components/FormSection/GroupByFilterProperty';
import ChecklistCrop from '../../../CropSeasons/CropSeason/components/FormSection/GroupByFilterCrop';
import { IntegrationsContext } from '../../context/IntegrationsProvider';

// eslint-disable-next-line react/prop-types
const PropertySelector = ({
  properties,
  cropZonesChecked,
  setCropZonesChecked,
  fieldsAndAreasGeoJSONCollection,
  setGeoJSONCollectionFeatures,
  filterFeatureCollectionByParentId
}) => {
  const {
    state: { propertiesGroupedBy, selectedProperties },
    updateSelectedProperties
  } = useContext(IntegrationsContext);

  const handlePropertySelect = propertiesSelected => {
    if (
      fieldsAndAreasGeoJSONCollection.features &&
      propertiesSelected.length !== selectedProperties.length
    ) {
      setGeoJSONCollectionFeatures(
        filterFeatureCollectionByParentId(
          fieldsAndAreasGeoJSONCollection,
          undefined,
          propertiesSelected.map(id => ({ id }))
        )
      );

      const mappedProperties = mapFieldsGeoJSONCollectionOnCropZones(
        fieldsAndAreasGeoJSONCollection,
        propertiesSelected
      );

      updateSelectedProperties(mappedProperties);
    }
  };

  return propertiesGroupedBy === 'property' ? (
    <div className="pt-2 overflow-auto h-480px">
      <ChecklistProperty
        data={properties}
        cropZonesChecked={cropZonesChecked}
        setCropZonesChecked={setCropZonesChecked}
        onChange={handlePropertySelect}
      />
    </div>
  ) : (
    <div className="pt-4 overflow-auto h-480px">
      <ChecklistCrop
        data={properties}
        cropZonesChecked={cropZonesChecked}
        setCropZonesChecked={setCropZonesChecked}
        onChange={handlePropertySelect}
      />
    </div>
  );
};

PropertySelector.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  properties: PropTypes.oneOfType([PropTypes.object, PropTypes.array])
    .isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  cropZonesChecked: PropTypes.oneOfType([PropTypes.object, PropTypes.array])
    .isRequired,
  setCropZonesChecked: PropTypes.func.isRequired,
  fieldsAndAreasGeoJSONCollection: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.func
  ]).isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  setGeoJSONCollectionFeatures: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.func
  ]).isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  filterFeatureCollectionByParentId: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.func
  ]).isRequired
};

export default PropertySelector;
