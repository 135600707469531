import React, { useContext } from 'react';
import { Context } from 'components/Store';
import GrowUIFormField from 'components/Forms/GrowUIFormField';
import { Select } from '@agconnections/grow-ui';
import { fsaRentTypes } from '../DataSets';

const RentType = () => {
  const [{ isEnableInfoTabEdit }] = useContext(Context);
  return (
    <GrowUIFormField
      placeholder="Select rent type"
      control={Select}
      type="text"
      id="rentType"
      name="rentType"
      items={fsaRentTypes}
      label="Rent Type"
      disabled={!isEnableInfoTabEdit}
    />
  );
};

export default RentType;
