import { useCallback } from 'react';
import useIntegrationMatching from 'hooks/useIntegrationMatching';
import matchApiByVendor from '../helpers/matchApiByVendor';

const useProductsMatch = () => {
  const {
    createMatchingProductsV2,
    getMatches,
    loading: isLoadingProductMatching
  } = useIntegrationMatching();

  const getProductMatches = useCallback(
    async ({ vendor, matchTypes = [] }) => {
      const matchHandler = matchApiByVendor[vendor.toLowerCase()];
      return matchHandler.responseTranslator(
        await getMatches({ vendor, matchTypes }),
        'Product'
      );
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  const convertMatchToMaterialMatch = match => {
    switch (match.source) {
      case 'MATERIAL':
        return {
          cwfEntityId: match.cwfId,
          simplotEntityId: match.vendorId,
          simplotEntityDescription: match.vendorPropertyName,
          type: match.type
        };
      case 'COMMON':
        return {
          cwfEntityId: match.cwfId,
          simplotEntityId: match.vendorId,
          simplotEntityDescription: match.vendorPropertyName,
          type: match.type,
          from: match.simplotProduct.from,
          stdUnit: match.cwfProduct.stdUnit
        };
      default:
        return {
          ...match.cwfProduct,
          id: match.cwfProduct.productId,
          name: match.cwfProduct.productName,
          type: match.cwfProduct.productType,
          density: match.cwfProduct.productDensity
        };
    }
  };

  const generatePayloadFromMatches = matches => {
    return {
      materials: matches
        .filter(m => m.source === 'MATERIAL')
        .map(convertMatchToMaterialMatch),
      commonMaterials: matches
        .filter(m => m.source === 'COMMON')
        .map(convertMatchToMaterialMatch),
      createCustomMaterials: matches
        .filter(m => m.source === 'CUSTOM')
        .map(convertMatchToMaterialMatch)
    };
  };

  const createProductMatches = async ({ vendor, matches }) => {
    return createMatchingProductsV2({
      vendor,
      products: generatePayloadFromMatches(matches)
    });
  };

  return {
    getProductMatches,
    createProductMatches,
    loading: isLoadingProductMatching
  };
};

export default useProductsMatch;
