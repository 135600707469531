import { getToken } from 'utilities/helpers';
import { INVITE_API_MIRAGE_ROOT_URL } from 'utilities/apiConstants';
import {
  notFoundResponse,
  notAuthorizedResponse
} from '../utils/errorResponses';

// This is just for demo purposes, we need a way to pass in the orgId to the application
const organizationId = process.env.REACT_APP_DEVTEST_ORGANIZATIONID;

export default server => {
  server.get(
    `${INVITE_API_MIRAGE_ROOT_URL}/invites`,
    async (schema, request) => {
      const { status } = request.queryParams;
      const orgId = request.queryParams.organizationId;
      let data = schema.invitations
        .all()
        .filter(invitation => invitation.attrs.organizationId === orgId);

      if (status) {
        data = data.filter(invitation => invitation.attrs.status === status);
      }
      if (data) return data;
      return notFoundResponse(orgId, 'Invitations');
    }
  );

  server.get(
    `${INVITE_API_MIRAGE_ROOT_URL}/invites/:id`,
    async (schema, request) => {
      const { id } = request.params;
      let data = schema.invitations.all();
      const orgId = request.queryParams.organizationId;

      const token = getToken(request.requestHeaders);
      if (token) {
        data = data.filter(
          invitation => invitation.attrs.emailAddress === token.sub
        );
      }

      if (id) {
        data = data.filter(invitation => invitation.attrs.id === id);
      }
      if (orgId) {
        data = data.filter(
          invitation => invitation.attrs.organizationId === orgId
        );
      }
      if (data) return data;
      return notFoundResponse(orgId, 'Invitations');
    }
  );

  server.delete(
    `${INVITE_API_MIRAGE_ROOT_URL}/invitations/:id`,
    (schema, request) => {
      const { id } = request.params;
      const invitation = schema.invitations.find(id);
      if (getToken(request.requestHeaders) !== null) {
        if (invitation) return invitation.destroy();
        return notFoundResponse(id, 'Invitation');
      }
      return notAuthorizedResponse();
    }
  );

  server.put(
    `${INVITE_API_MIRAGE_ROOT_URL}/invitations/:id`,
    (schema, request) => {
      const { id } = request.params;
      const invitation = schema.invitations.find(id);
      if (getToken(request.requestHeaders) !== null) {
        if (invitation)
          return invitation.update(JSON.parse(request.requestBody));
        return notFoundResponse(id, 'Invitation');
      }
      return notAuthorizedResponse();
    }
  );

  server.post(
    `${INVITE_API_MIRAGE_ROOT_URL}/invites`,
    async (schema, request) => {
      const members = await schema.members.where({
        organizationId
      });
      if (members) {
        const parsedBody = JSON.parse(request.requestBody);
        const foundMember = members.models.find(item => {
          return item.attrs.contactInfo.emails.includes(
            parsedBody.emailAddress
          );
        });
        const token = getToken(request.requestHeaders);
        if (!foundMember) {
          await schema.members.create({
            active: true,
            oktaId: token.uid,
            organizationId,
            contactInfo: {
              name: {
                givenName: parsedBody.firstName,
                surname: parsedBody.lastName
              },
              emails: [parsedBody.emailAddress]
            }
          });
        }
        if (token !== null) {
          return schema.invitations.create({
            ...parsedBody,
            email: parsedBody.emailAddress,
            name: `${parsedBody.firstName} ${parsedBody.lastName}`,
            financial: parsedBody.financial,
            role: parsedBody.permissionLevel,
            expiry: parsedBody.expirationDate,
            createdBy: token.uid,
            createdDate: new Date().toISOString(),
            status: 'active'
          });
        }
        return notAuthorizedResponse();
      }
      return notFoundResponse();
    }
  );

  server.post(
    `${INVITE_API_MIRAGE_ROOT_URL}/acceptInvitation`,
    (schema, request) => {
      const token = getToken(request.requestHeaders);
      if (token !== null) {
        const parsedBody = JSON.parse(request.requestBody);
        const invitation = schema.invitations.find(parsedBody.id);
        if (invitation)
          return invitation.update({
            status: 'accepted',
            acceptedDate: new Date().toISOString()
          });
      }
      return notAuthorizedResponse();
    }
  );

  server.post(`${INVITE_API_MIRAGE_ROOT_URL}/resendInvitation`, () => {
    // const token = getToken(request.requestHeaders);
    // if (token !== null) {
  });

  server.post(
    `${INVITE_API_MIRAGE_ROOT_URL}/cancelInvitation`,
    (schema, request) => {
      // const token = getToken(request.requestHeaders);
      const parsedBody = JSON.parse(request.requestBody);
      // if (token !== null) {
      const invitation = schema.invitations
        .all()
        .filter(i => i.emailAddress === parsedBody.emailAddress);
      if (invitation) {
        return invitation.update({
          status: 'cancelled',
          cancelledDate: new Date().toISOString()
        });
      }
      // }
      return notAuthorizedResponse();
    }
  );

  server.post(
    `${INVITE_API_MIRAGE_ROOT_URL}/declineInvitation`,
    (schema, request) => {
      const parsedBody = JSON.parse(request.requestBody);
      const token = getToken(request.requestHeaders);
      if (token !== null) {
        const invitation = schema.invitations.find(parsedBody.id);
        if (invitation)
          return invitation.update({
            status: 'declined',
            declinedDate: new Date().toISOString()
          });
      }
      return notAuthorizedResponse();
    }
  );
};
