import React from 'react';
import PropTypes from 'prop-types';
import {
  Button,
  ButtonType,
  Col,
  Row
} from 'syngenta-digital-cropwise-react-ui-kit';
import { Input } from '@agconnections/grow-ui';
import GrowUIFormField from 'components/Forms/GrowUIFormField';
import { Formik } from 'formik';
import ChevronUp from 'assets/chevron_up.svg';
import ChevronDown from 'assets/chevron_down.svg';
import CropIconMapping from 'components/CropIconMapping';
import { getAbbreviatedUnit } from 'helpers/unitsAbbreviation';
import { handleValidationAlertIcon } from 'screens/ProductList/components/CustomProductListForm';
import { TProductMissingWeight, validationSchema } from '../../constants';

const formRowClass = 'mb-6';
const formLabelClass = 'text-neutral-60';
const formValueClass = 'h-6 text-base leading-6 text-neutral-1000';

const ProductAccordion = ({
  product,
  isExpanded,
  isLoading,
  resolvedProducts,
  onExpand,
  onSubmit
}) => {
  const {
    cropName,
    productId,
    productName,
    stdPackageUnit,
    manufacturerName,
    operationsCount,
    averagePricePerUnit
  } = product;

  const isResolved = resolvedProducts.find(
    item => item.productId === productId
  );
  const lastSavedValue = isResolved?.packageWeight;

  return (
    <div
      className="bg-white rounded mb-4"
      style={{ boxShadow: '0px 2px 8px rgba(0, 0, 0, 0.15)' }}
    >
      <button
        className="flex flex-row items-center focus:outline-none justify-between w-full p-4"
        type="button"
        onClick={onExpand}
        data-testid="product-accordion-expand-button"
      >
        <header className="flex justify-between w-full pr-1">
          <div className="flex items-center">
            <CropIconMapping cropObject={{ name: cropName }} />
            <h1
              className={`ml-2 text-base leading-6 tracking-tighter ${
                isExpanded ? 'font-semibold' : 'font-normal'
              }`}
            >
              {productName}
            </h1>
          </div>

          <div className="flex items-center">
            <span className="flex bg-blue-30 rounded-md px-1 font-semibold text-xs text-blue-70 mr-2">
              {`${operationsCount} TASK${operationsCount > 1 ? 'S' : ''}`}
            </span>

            <span
              className={`rounded-md px-1 font-semibold text-xs mx-2 ${
                isResolved
                  ? 'text-green-700 bg-green-200'
                  : 'text-yellow-700 bg-yellow-200'
              }`}
            >
              {isResolved ? 'WEIGHT ENTERED' : 'NO WEIGHT'}
            </span>
          </div>
        </header>

        <img
          className="pr-16px"
          src={isExpanded ? ChevronUp : ChevronDown}
          alt="expand collapse accordion"
        />
      </button>

      {isExpanded && (
        <Formik
          validateOnBlur
          validationSchema={validationSchema}
          onSubmit={onSubmit}
          initialValues={{
            productId,
            packageWeight: lastSavedValue,
            averagePricePerUnit
          }}
        >
          {({ submitForm, dirty, isValid, errors }) => (
            <div className="p-4" aria-expanded="true">
              <p className="text-base font-normal leading-6 tracking-tighter mb-1">
                Enter weight value
              </p>
              <Row className={`${formRowClass} package-weight-row`} gutter={32}>
                <Col span={12}>
                  <div className={formLabelClass}>Package Weight Density</div>
                  <GrowUIFormField
                    control={Input}
                    name="packageWeight"
                    attachRight={
                      <div
                        style={{
                          backgroundColor: 'white',
                          borderLeft: 'none'
                        }}
                        className="flex w-fit mr-10px"
                      >
                        <span className="mr-1">seeds per lbs</span>
                        {handleValidationAlertIcon(errors, 'packageWeight')}
                      </div>
                    }
                  />
                </Col>
              </Row>
              <Row className={formRowClass} gutter={32}>
                <Col span={12}>
                  <div className={formLabelClass}>Standard Package Unit</div>
                  <div className={formValueClass}>
                    {getAbbreviatedUnit(stdPackageUnit)}
                  </div>
                </Col>
                <Col span={12}>
                  <div className={formLabelClass}>Manufacturer</div>
                  <div className={formValueClass}>{manufacturerName}</div>
                </Col>
              </Row>
              <Row justify="end">
                <Button
                  type={ButtonType.primary}
                  loading={isLoading}
                  disabled={!isValid || !dirty}
                  onClick={submitForm}
                >
                  Save
                </Button>
              </Row>
            </div>
          )}
        </Formik>
      )}
    </div>
  );
};

ProductAccordion.defaultProps = {
  isLoading: false
};

ProductAccordion.propTypes = {
  product: TProductMissingWeight.isRequired,
  isExpanded: PropTypes.bool.isRequired,
  isLoading: PropTypes.bool,
  resolvedProducts: PropTypes.arrayOf(
    PropTypes.shape({
      productId: PropTypes.string,
      packageWeight: PropTypes.number
    })
  ).isRequired,
  onSubmit: PropTypes.func.isRequired,
  onExpand: PropTypes.func.isRequired
};

export default ProductAccordion;
