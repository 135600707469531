import { Response } from 'miragejs';

export const notAuthorizedResponse = () =>
  new Response(404, {}, { message: `Unable to Authorize User` });

export const notFoundResponse = (id, resource) =>
  new Response(
    404,
    {},
    { message: `Unable to retrieve ${resource} with id: ${id}` }
  );
export default notFoundResponse;
