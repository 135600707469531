import React, { useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Row, Col, Layout } from 'syngenta-digital-cropwise-react-ui-kit';
import SearchStateIcon from 'assets/search_state.svg';
import noSearchResultsFound from 'assets/no_search_results_found.svg';
import EmptyTableState from 'components/EmptyTableState';
import { ProductListContext } from 'screens/ProductList/context/ProductListProvider';
import { Spinner } from '@agconnections/grow-ui';
import filteredProducts from 'screens/ProductList/helpers/filteredProducts';
import calcProductsInUse from 'screens/ProductList/helpers/calcProductsInUse';
import DrawerBox from '../DrawerBox';
import SelectedProductsCart from '../SelectedProductsCart';

const AddProductSearchList = ({
  setSelectedProductsList,
  selectedProductsList,
  handleOnEdit,
  typeFilters
}) => {
  const emptyObjectToMatch = [];
  const [allSearchProducts, setAllSearchProducts] = useState([]);
  const [isLoadingAllData, setIsLoadingAllData] = useState(false);
  const {
    productsInUse,
    searchedProducts,
    loadingProductSearch,
    isLoadingProductInUse,
    searchText
  } = useContext(ProductListContext);

  const displayedSelectedProductsList =
    searchText.length >= 3
      ? filteredProducts(selectedProductsList, searchText, [])
      : selectedProductsList;

  useEffect(() => {
    if (searchText.length >= 3) setIsLoadingAllData(true);
    if (
      searchText.length < 3 ||
      isLoadingProductInUse ||
      loadingProductSearch
    ) {
      setAllSearchProducts([]);
      return;
    }
    let searchResults = filteredProducts(
      calcProductsInUse(productsInUse, searchedProducts, emptyObjectToMatch),
      searchText
    );
    if (typeFilters?.includes('CustomProduct')) {
      searchResults = searchResults.filter(product => {
        return product.custom === true;
      });
    } else if (typeFilters?.length) {
      searchResults = searchResults.filter(product =>
        typeFilters
          .map(producttype => producttype?.toUpperCase())
          .includes(product.producttype?.toUpperCase())
      );
    }
    if (selectedProductsList?.length) {
      searchResults = searchResults.filter(
        prod => !selectedProductsList.find(selected => selected.id === prod.id)
      );
    }
    setAllSearchProducts(searchResults);
    setIsLoadingAllData(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    searchedProducts,
    isLoadingAllData,
    productsInUse,
    isLoadingProductInUse,
    searchText,
    selectedProductsList,
    loadingProductSearch,
    typeFilters
  ]);

  const handleOnRemove = prod => {
    const _selectedProducts = selectedProductsList?.filter(
      selected => selected.id !== prod.id
    );
    setSelectedProductsList(_selectedProducts);
  };

  return (
    <Layout>
      <Layout.Header>
        <Row className="h-44px bg-neutral-20 items-center">
          <Col span={24}>
            <Row>
              <Col span={11} className="text-center font-semibold">
                Product Name
              </Col>
              <Col span={9} className="text-center font-semibold">
                Product Type
              </Col>
            </Row>
          </Col>
        </Row>
      </Layout.Header>
      {displayedSelectedProductsList.length ? (
        <div>
          <Layout.Header>
            <Row className="h-28px px-4 bg-blue-70 text-base text-white font-semibold items-center">
              Selected Products
            </Row>
          </Layout.Header>
          <Layout.Content>
            <div className="justify-content">
              {displayedSelectedProductsList?.map(product => (
                <SelectedProductsCart
                  key={product.id ?? product.name}
                  product={product}
                  handleOnEdit={handleOnEdit}
                  handleOnRemove={handleOnRemove}
                />
              ))}
            </div>
          </Layout.Content>
        </div>
      ) : null}
      <Layout.Header>
        <Row className="h-28px px-4 bg-blue-70 text-base text-white font-semibold items-center">
          {displayedSelectedProductsList.length === 0 &&
          allSearchProducts.length === 0 &&
          searchText.length < 3
            ? 'Selected Products'
            : 'All Products'}
        </Row>
      </Layout.Header>
      <Layout.Content>
        <Row className="items-center">
          {!isLoadingAllData
            ? allSearchProducts?.map(product => (
                <DrawerBox
                  key={product.id}
                  product={product}
                  handleOnEdit={handleOnEdit}
                />
              ))
            : searchText.length >= 3 && (
                <Col span={24}>
                  <div className="mt-10 ">
                    <Spinner size="lg" />
                  </div>
                </Col>
              )}

          {searchText.length >= 3 &&
            !allSearchProducts.length &&
            !displayedSelectedProductsList.length &&
            !loadingProductSearch &&
            !isLoadingAllData && (
              <EmptyTableState
                overlayIcon={noSearchResultsFound}
                title="No Products Found"
                message="Please try a different search or filter."
              />
            )}
        </Row>
        {searchText.length < 3 &&
          !allSearchProducts.length &&
          !loadingProductSearch && (
            <EmptyTableState
              overlayIcon={SearchStateIcon}
              title="Search for Products"
              message="Enter at least three characters to see
          a list of products from the master list"
            />
          )}
      </Layout.Content>
    </Layout>
  );
};

AddProductSearchList.propTypes = {
  setSelectedProductsList: PropTypes.func.isRequired,
  typeFilters: PropTypes.arrayOf(PropTypes.object).isRequired,
  selectedProductsList: PropTypes.arrayOf({
    name: PropTypes.string,
    manufacturer: PropTypes.string,
    producttype: PropTypes.string,
    isInUse: PropTypes.bool
  }).isRequired,
  handleOnEdit: PropTypes.func.isRequired
};

export default AddProductSearchList;
