import React, { useCallback, useContext, useEffect, useState } from 'react';
import { v4 as uuidv4 } from 'uuid';
import { Spinner } from '@agconnections/grow-ui';
import PropTypes from 'prop-types';
import axios from 'axios';
import LandingTable from 'components/LandingTable';
import CwFPagination from 'components/CwFPagination';
import PAGINATION_SIZE from 'helpers/globalConstants';
import infoIcon from 'assets/info.svg';
import useClientSidePagination from 'screens/Property/hooks/useClientSidePagination';
import multiLevelSort from 'helpers/multiLevelSort';
import productIcon from 'assets/product-icon.svg';
import { CROPWISE_PROXY_URL } from '../../../../../utilities/apiConstants';
import { getAccessToken } from '../../../../../utilities/base-auth';
import catchCancel from '../../../../../helpers/catchCancel';
import { parseServerError } from '../../../../../helpers/errorHelpers';
import { APPLIED_PRODUCTS_TABLE } from './constants';
import { Context } from '../../../../../components/Store';
import NoProductsFound from '../../components/NoProductsFound';
import getSeasons from '../../../../Yield/components/YieldActions/helpers/getSeasons';
import CropZoneAppliedProductRow from './components/CropZoneAppliedProductRow';
import FieldAppliedProductRow from './components/FieldAppliedProductRow';
import appliedProductsTableSort from './helpers/appliedProductsTableSort';
import PropertyCardHeader from '../PropertyCardHeader';

const { columns, defaultSort } = APPLIED_PRODUCTS_TABLE;

const PropertyAppliedProducts = ({ id, level = 'field' }) => {
  const isField = level === 'field';
  const [loading, setLoading] = useState(true);

  const [sortDir, setSortDir] = useState(defaultSort[level].dir);
  const [sortBy, setSortBy] = useState(defaultSort[level].by);

  const [{ organization, isSidebarOpen }, dispatch] = useContext(Context);
  const TABLE_COLUMNS = columns[level];

  const {
    currentPageData,
    totalItems,
    handlePageChange,
    updateAllData,
    pageNo
  } = useClientSidePagination(PAGINATION_SIZE);

  const getAppliedProducts = async () => {
    setLoading(true);
    const seasonIds = getSeasons();
    const promise = axios.post(
      `${CROPWISE_PROXY_URL}/v2/base/${level}/${id}/appliedproducts`,
      { seasonIds },
      {
        headers: {
          'Content-Type': 'application/json',
          'cwf-context': JSON.stringify({
            organization_id: organization.id
          }),
          Authorization: `Bearer ${getAccessToken()}`
        }
      }
    );

    await promise
      .then(({ data }) => {
        updateAllData(
          multiLevelSort({
            items: data,
            sortBy: defaultSort[level].by,
            sortDir: defaultSort[level].dir,
            sorter: appliedProductsTableSort
          })
        );
      })
      .catch(catchCancel)
      .catch(err => {
        parseServerError(dispatch)(err);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const isEverythingLoaded = () => {
    return !loading;
  };

  const handleSort = useCallback(
    (tableField, dir = 'asc') => {
      const newSortBy = [tableField];
      const newSortDir = [dir];
      setSortBy(newSortBy);
      setSortDir(newSortDir);
      updateAllData(data =>
        multiLevelSort({
          items: data,
          sortBy: newSortBy,
          sortDir: newSortDir,
          sorter: appliedProductsTableSort
        })
      );
    },
    [updateAllData]
  );

  useEffect(() => {
    getAppliedProducts();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  const fieldHeaderProduct = (
    <PropertyCardHeader
      title="Applied Products"
      description="All products used during the selected crop season"
      icon={productIcon}
    />
  );

  if (isEverythingLoaded() && !totalItems)
    return (
      <>
        {isField && fieldHeaderProduct}
        <NoProductsFound />
      </>
    );

  return (
    <div>
      {isField && fieldHeaderProduct}
      {isEverythingLoaded() ? (
        <>
          {totalItems > 0 ? (
            <div
              className="cwf-property-sticky-table"
              data-testid="applied-products-table"
            >
              <LandingTable
                listType="Applied Products"
                items={currentPageData}
                tableColumns={TABLE_COLUMNS}
                onSort={handleSort}
                sortBy={sortBy[0]}
                sortDir={sortDir[0]}
              >
                {({ rows }) =>
                  rows.map(entry => (
                    <>
                      {isField ? (
                        <FieldAppliedProductRow
                          key={uuidv4()}
                          product={entry}
                        />
                      ) : (
                        <CropZoneAppliedProductRow
                          key={uuidv4()}
                          product={entry}
                        />
                      )}
                    </>
                  ))
                }
              </LandingTable>
              <div className="flex justify-between pt-12px">
                {isField && (
                  <div
                    className={`flex items-center ${
                      !isSidebarOpen ? 'flex-grow' : ''
                    }`}
                  >
                    <img src={infoIcon} alt="info-icon" />
                    <span className="pl-8px font-sm text-neutral-1000">
                      To view more product details, select a crop zone from the
                      farm-field-tree on the left.
                    </span>
                  </div>
                )}
                <div className={`${isSidebarOpen ? 'w-full' : 'w-auto'}`}>
                  {CwFPagination(totalItems, pageNo, handlePageChange)}
                </div>
              </div>
            </div>
          ) : (
            <NoProductsFound />
          )}
        </>
      ) : (
        <Spinner />
      )}
    </div>
  );
};

PropertyAppliedProducts.propTypes = {
  id: PropTypes.string.isRequired,
  level: PropTypes.string.isRequired
};

export default PropertyAppliedProducts;
