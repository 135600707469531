import React from 'react';
import PropTypes from 'prop-types';
import SearchStateIcon from 'assets/search_state.svg';
import EmptyTableState from 'components/EmptyTableState';
import LandingTable from 'components/LandingTable';
import { TABLE_COLUMNS } from 'screens/Yield/helpers/constants';
import { paths } from 'routes/paths';
import YieldTableRow, { yieldEntryPropTypes } from './YieldTableRow';
import './styles.css';
import AddYieldButton from '../AddYieldButton';

const YieldTable = ({
  items,
  sortField,
  sortDir,
  handleSort,
  handleDeleteClick,
  handleCloseDeleteModal,
  setDeleteYieldEntryId
}) => {
  return (
    <LandingTable
      listType="Yield Entries"
      routePath={paths.yield}
      items={items}
      tableColumns={TABLE_COLUMNS}
      onSort={handleSort}
      sortBy={sortField}
      sortDir={sortDir}
      isEmpty={items && !items.length}
      emptyState={
        <div className="pb-8">
          <EmptyTableState
            overlayIcon={SearchStateIcon}
            message="We are unable to find any yield data for this commodity."
            button={<AddYieldButton />}
          />
        </div>
      }
    >
      {({ rows }) =>
        rows.map(item => (
          <YieldTableRow
            key={item?.id}
            yieldEntry={item}
            handleDeleteClick={handleDeleteClick}
            handleCloseDeleteModal={handleCloseDeleteModal}
            setDeleteYieldEntryId={setDeleteYieldEntryId}
          />
        ))
      }
    </LandingTable>
  );
};

YieldTable.propTypes = {
  items: PropTypes.arrayOf(yieldEntryPropTypes).isRequired,
  sortField: PropTypes.string.isRequired,
  sortDir: PropTypes.string.isRequired,
  handleSort: PropTypes.func.isRequired,
  handleDeleteClick: PropTypes.func.isRequired,
  handleCloseDeleteModal: PropTypes.func.isRequired,
  setDeleteYieldEntryId: PropTypes.func.isRequired
};

export default YieldTable;
