import { useState } from 'react';
import { genProxyResource } from 'utilities/api';

function useRecommendationsApi(resourceName) {
  const [api] = useState(genProxyResource(resourceName, { urlVersion: '/v1' }));

  return api;
}

export default useRecommendationsApi;
