import React, { forwardRef } from 'react';
import { useParams } from 'react-router-dom';
import PropTypes from 'prop-types';
import { useFormikContext } from 'formik';
import GrowUIFormField from 'components/Forms/GrowUIFormField';
import { Form, Input, DatePicker } from '@agconnections/grow-ui';

const PriceAndStatusSection = forwardRef(
  ({ memberRole, memberFinancialAccess }, ref) => {
    const { values } = useFormikContext();
    const { id } = useParams();

    const getServiceList = () => {
      if (
        id !== 'item' &&
        memberRole === 'Full control' &&
        memberFinancialAccess === 'none'
      ) {
        return '';
      }
      return values?.purchasePrice;
    };

    const getCurrentValue = () => {
      if (
        id !== 'item' &&
        memberRole === 'Full control' &&
        memberFinancialAccess === 'none'
      ) {
        return '';
      }
      return values?.currentValue;
    };

    const getDisabled = () => {
      if (
        id !== 'item' &&
        memberRole === 'Full control' &&
        (memberFinancialAccess === 'none' || memberFinancialAccess === 'READ')
      )
        return true;

      return false;
    };

    return (
      <div
        ref={ref}
        className="mt-4"
        data-testid="equipment-item-price-and-status-section"
      >
        <div className="text-2xl font-bold mb-4">History Info</div>
        <div className="flex">
          <div className="w-1/2 mr-16">
            <div className="text-xl font-semibold">Purchase History</div>
            <div className="flex">
              <div className="w-1/2 mr-8">
                <Form.Field label="Purchase Price">
                  <GrowUIFormField
                    control={Input}
                    id="purchasePrice"
                    name="purchasePrice"
                    value={getServiceList()}
                    imask={{
                      mask: '\\$num',
                      lazy: false,
                      blocks: {
                        num: {
                          mask: Number,
                          radix: '.',
                          mapToRadix: ['.'],
                          thousandsSeparator: ','
                        }
                      }
                    }}
                    disabled={getDisabled()}
                  />
                </Form.Field>
              </div>
              <div className="w-1/2">
                <Form.Field label="Purchase Date">
                  <GrowUIFormField
                    control={DatePicker}
                    id="purchasePriceDate"
                    name="purchasePriceDate"
                    dateFormat="M d, Y"
                  />
                </Form.Field>
              </div>
            </div>
          </div>
          <div className="w-1/2">
            <div className="text-xl font-semibold">Current Value</div>
            <div className="flex">
              <div className="w-1/2 mr-8">
                <Form.Field label="Current Value">
                  <GrowUIFormField
                    control={Input}
                    id="currentValue"
                    name="currentValue"
                    value={getCurrentValue()}
                    imask={{
                      mask: '\\$num',
                      lazy: false,
                      blocks: {
                        num: {
                          mask: Number,
                          radix: '.',
                          mapToRadix: ['.'],
                          thousandsSeparator: ','
                        }
                      }
                    }}
                    disabled={getDisabled()}
                  />
                </Form.Field>
              </div>
              <div className="w-1/2">
                <Form.Field label="Current Value Date">
                  <GrowUIFormField
                    control={DatePicker}
                    id="currentValueDate"
                    name="currentValueDate"
                    dateFormat="M d, Y"
                  />
                </Form.Field>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
);

PriceAndStatusSection.propTypes = {
  memberRole: PropTypes.string.isRequired,
  memberFinancialAccess: PropTypes.string.isRequired
};

export default PriceAndStatusSection;
