import React from 'react';
import PropTypes from 'prop-types';
import { StyleSheet, Text, View } from '@react-pdf/renderer';
import { displayText } from 'components/PDF/helpers/pdfTools';
import { workerProtectionHeaderTranslations } from '../../utils/headerTranslations';

const styles = StyleSheet.create({
  title: {
    fontFamily: 'Noto Sans',
    fontSize: 12,
    fontWeight: 600,
    marginBottom: 12
  },
  productName: {
    fontFamily: 'Noto Sans',
    fontWeight: 400,
    fontSize: 12
  },
  row: {
    display: 'flex',
    flexWrap: 'wrap',
    flexDirection: 'row',
    width: 532,
    marginBottom: 12
  },
  fieldText: {
    fontFamily: 'Noto Sans',
    fontSize: 9
  },
  fieldTextWarning: {
    fontFamily: 'Noto Sans',
    fontWeight: 500,
    fontSize: 9
  },
  fieldTitle: {
    color: '#363948',
    width: 64,
    alignItems: 'flex-start',
    justifySelf: 'flex-start',
    flexDirection: 'row',
    flexGrow: 1
  },
  fieldValue: {
    color: '#14151C',
    width: 468,
    flexDirection: 'row',
    flexGrow: 2
  }
});

const WorkerProtection = ({ exportLanguage, workerProtectionData }) => {
  return (
    <View>
      <View style={styles.tableHeaderContainer}>
        <Text style={styles.title}>
          {workerProtectionHeaderTranslations.workerProtection[exportLanguage]}
        </Text>
      </View>
      {workerProtectionData.map(data => {
        return data ? (
          <View key={data.key}>
            <View style={styles.row}>
              <Text style={[styles.productName]}>
                {displayText(data.productName)}{' '}
              </Text>
            </View>
            <View style={styles.row} wrap={false}>
              <Text style={[styles.fieldText, styles.fieldTitle]}>
                Signal Word
              </Text>
              <Text style={[styles.fieldTextWarning, styles.fieldValue]}>
                {displayText(data.signalword?.toUpperCase())}
              </Text>
            </View>
            <View style={styles.row} wrap={false}>
              <Text style={[styles.fieldText, styles.fieldTitle]}>RUP</Text>
              <Text style={[styles.fieldTextWarning, styles.fieldValue]}>
                {displayText(data.rup?.toUpperCase())}
              </Text>
            </View>
            <View style={styles.row} wrap={false}>
              <Text style={[styles.fieldText, styles.fieldTitle]}>PPE</Text>
              <Text style={[styles.fieldText, styles.fieldValue]}>
                {displayText(data.ppe)}
              </Text>
            </View>
            <View style={styles.row} wrap={false}>
              <Text style={[styles.fieldText, styles.fieldTitle]}>ReEntry</Text>
              <Text style={[styles.fieldText, styles.fieldValue]}>
                {displayText(data.ppereentry)}
              </Text>
            </View>
          </View>
        ) : null;
      })}
    </View>
  );
};

WorkerProtection.propTypes = {
  workerProtectionData: PropTypes.arrayOf(
    PropTypes.shape({
      productName: PropTypes.string.isRequired,
      signalword: PropTypes.string.isRequired,
      rup: PropTypes.string.isRequired,
      ppe: PropTypes.string.isRequired,
      ppereentry: PropTypes.string.isRequired,
      key: PropTypes.string.isRequired
    })
  ).isRequired,
  exportLanguage: PropTypes.string.isRequired
};

export default WorkerProtection;
