import React from 'react';
import PortalModal from 'components/Modals/PortalModal';
import ErrorOutline from 'components/Icons/ErrorOutline';
import PropTypes from 'prop-types';
import ModalButtons from './components/ModalButtons';

const ConfirmationModal = ({ open, setOpenModal }) => {
  const handleClose = () => {
    setOpenModal(false);
  };
  return (
    <PortalModal open={open} type="buttonless">
      <div
        className="-ml-6 mr-6 flex flex-row w-full"
        data-testid="confirmation-modal"
      >
        <div className="flex mr-4">
          <ErrorOutline />
        </div>
        <div className="flex flex-col w-ful">
          <div className="flex flex-row justify-items-center pb-4">
            <h3 className="font-medium text-lg leading-6 text-gray-900 w-full">
              Do you want to submit this data?
            </h3>
          </div>
          <div className="flex flex-col mb-4">
            <p>
              Are you sure you want to submit this data to McCain Foods Inc.?
            </p>
            <p>(You can complete a new submission at any time) </p>
          </div>
          <div className="mt-5 flex flex-row items-end justify-between w-full">
            <ModalButtons handleClose={handleClose} />
          </div>
        </div>
      </div>
    </PortalModal>
  );
};

ConfirmationModal.defaultProps = {
  open: true,
  setOpenModal: () => {}
};

ConfirmationModal.propTypes = {
  open: PropTypes.bool,
  setOpenModal: PropTypes.func
};

export default ConfirmationModal;
