import { useField } from 'formik';
import { omit } from 'lodash';

const defaultMeta = {
  readOnly: false
};

export const addPlanMeta = (plan, initMetaValue = defaultMeta) => ({
  ...plan,
  _planMeta: initMetaValue
});

export const removePlanMeta = plan => omit(plan, ['_planMeta']);

// planMeta is a field stored in the formik bag that provides plan metadata (such as readonly state)
const usePlanMeta = () => {
  const [planMetaField] = useField('_planMeta');
  return {
    ...defaultMeta,
    ...planMetaField.value
  };
};

export default usePlanMeta;
