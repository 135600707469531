import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { useFormikContext } from 'formik';
import ItemsDropdown from 'components/ItemsDropdown';
import { FUMIGATION_SERIES } from 'screens/Integrations/CalAgIntegration/helpers/constants';
import FumigationDropDownItem from './components/FumigationDropDownItem';

const FumigationField = ({ onChange, value, id }) => {
  const { errors, touched } = useFormikContext();

  const handlefilter = (item, filterValue) => {
    if (!item.value) {
      return true;
    }
    return item.value.includes(filterValue);
  };

  const valueRenderer = () => {
    if (!value) return null;
    return (
      <div className="w-full h-full flex items-center ml-2">
        <div>{value}</div>
      </div>
    );
  };

  const fumigationItems = useMemo(() => {
    return FUMIGATION_SERIES.reduce((acc, curr) => {
      return acc.concat([
        {
          key: curr.name,
          label: curr.name,
          items: curr.codes.map(code => ({
            key: curr.name + code,
            value: code,
            label: code
          }))
        }
      ]);
    }, []);
  }, []);

  return (
    <ItemsDropdown
      valueRenderer={valueRenderer}
      value={value}
      onChange={onChange}
      id={id}
      heightClass="h-330px"
      isInvalid={
        errors?.fumigationCode !== undefined &&
        touched?.fumigationCode !== undefined
      }
      items={fumigationItems}
      ItemComponent={FumigationDropDownItem}
      onFilter={handlefilter}
    />
  );
};

FumigationField.propTypes = {
  value: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  id: PropTypes.string
};

FumigationField.defaultProps = {
  value: undefined,
  id: ''
};

export default FumigationField;
