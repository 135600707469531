import React from 'react';
import PropTypes from 'prop-types';
import { StyleSheet, Text, View } from '@react-pdf/renderer';
import { convertNumberToDesiredFormat } from 'helpers/transformHelpers';

const styles = StyleSheet.create({
  title: {
    fontFamily: 'Noto Sans',
    fontWeight: 'medium',
    fontSize: 12,
    marginBottom: 12
  },
  container: {
    border: '1px solid #DFE2E7',
    padding: 12,
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap'
  },
  column: {
    width: '50%'
  },
  row: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    paddingHorizontal: 6,
    marginBottom: 12
  },
  key: {
    fontFamily: 'Noto Sans',
    fontSize: 9,
    width: '50%'
  },
  value: {
    fontWeight: 'medium'
  },
  breakContainer: {
    width: '100%',
    marginTop: 9,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    paddingHorizontal: 6
  },
  breakTitle: {
    width: '75%',
    fontSize: 9,
    color: '#696F88',
    fontFamily: 'Noto Sans'
  },
  breakValue: {
    width: '25%',
    fontSize: 9,
    fontWeight: 'medium',
    fontFamily: 'Noto Sans',
    paddingLeft: 3
  }
});

const Profitability = ({
  estimatedAreaValue,
  projectedRevenue,
  cost,
  projNetRevPerArea,
  projNetRev,
  totalFixedCost
}) => {
  const costs = cost + totalFixedCost;

  return (
    <>
      <Text style={styles.title}>Profitability</Text>
      <View style={styles.container}>
        <View style={styles.column}>
          <View style={styles.row}>
            <Text style={styles.key}>Est. Area</Text>
            <Text
              style={{
                ...styles.key,
                ...styles.value
              }}
            >
              {convertNumberToDesiredFormat(estimatedAreaValue, 'fixed')}
            </Text>
          </View>
          <View style={styles.row}>
            <Text style={styles.key}>Est. Yield / Area</Text>
            <Text
              style={{
                ...styles.key,
                ...styles.value
              }}
            >
              {convertNumberToDesiredFormat(
                projectedRevenue.yieldPerArea,
                'fixed'
              )}
            </Text>
          </View>
          <View style={styles.row}>
            <Text style={styles.key}>Est. Price / Unit</Text>
            <Text
              style={{
                ...styles.key,
                ...styles.value
              }}
            >
              {convertNumberToDesiredFormat(
                projectedRevenue.pricePerUnit,
                'currency'
              )}
            </Text>
          </View>
          <View style={styles.row}>
            <Text style={styles.key}>FSA Income</Text>
            <Text
              style={{
                ...styles.key,
                ...styles.value
              }}
            >
              {convertNumberToDesiredFormat(
                projectedRevenue.fsaPayment,
                'currency'
              )}
            </Text>
          </View>
          <View style={styles.row}>
            <Text style={styles.key}>Total Revenue</Text>
            <Text
              style={{
                ...styles.key,
                ...styles.value
              }}
            >
              {convertNumberToDesiredFormat(
                estimatedAreaValue *
                  projectedRevenue.yieldPerArea *
                  projectedRevenue.pricePerUnit,
                'currency'
              )}
            </Text>
          </View>
        </View>
        <View style={styles.column}>
          <View style={styles.row}>
            <Text style={styles.key}>Crop Share %</Text>
            <Text
              style={{
                ...styles.key,
                ...styles.value
              }}
            >
              {convertNumberToDesiredFormat(
                projectedRevenue.cropSharePercent,
                'percent'
              )}
            </Text>
          </View>
          <View style={styles.row}>
            <Text style={styles.key}>Costs</Text>
            <Text
              style={{
                ...styles.key,
                ...styles.value
              }}
            >
              {convertNumberToDesiredFormat(costs, 'currency')}
            </Text>
          </View>
          <View style={styles.row}>
            <Text style={styles.key}>Cost/Area</Text>
            <Text
              style={{
                ...styles.key,
                ...styles.value
              }}
            >
              {convertNumberToDesiredFormat(
                costs / estimatedAreaValue,
                'currency'
              )}
            </Text>
          </View>
          <View style={styles.row}>
            <Text style={styles.key}>Net Revenue / Area</Text>
            <Text
              style={{
                ...styles.key,
                ...styles.value
              }}
            >
              {convertNumberToDesiredFormat(projNetRevPerArea, 'currency')}
            </Text>
          </View>
          <View style={styles.row}>
            <Text style={styles.key}>Total Net Revenue</Text>
            <Text
              style={{
                ...styles.key,
                ...styles.value
              }}
            >
              {convertNumberToDesiredFormat(projNetRev, 'currency')}
            </Text>
          </View>
        </View>
        <View style={[styles.breakContainer, { marginTop: 18 }]}>
          <Text style={styles.breakTitle}>
            Break Even Yield On Variable and Fixed @{' '}
            {convertNumberToDesiredFormat(
              projectedRevenue.pricePerUnit,
              'currency'
            )}
          </Text>
          <Text style={styles.breakValue}>
            {convertNumberToDesiredFormat(
              costs / estimatedAreaValue / projectedRevenue.pricePerUnit,
              'fixed'
            )}
          </Text>
        </View>
        <View style={styles.breakContainer}>
          <Text style={styles.breakTitle}>
            Break Even Unit Price On Variable and Fixed @{' '}
            {convertNumberToDesiredFormat(
              projectedRevenue.yieldPerArea,
              'fixed'
            )}
          </Text>
          <Text style={styles.breakValue}>
            {convertNumberToDesiredFormat(
              costs / estimatedAreaValue / projectedRevenue.yieldPerArea,
              'currency'
            )}
          </Text>
        </View>
      </View>
    </>
  );
};

Profitability.propTypes = {
  estimatedAreaValue: PropTypes.number,
  projectedRevenue: PropTypes.shape(),
  cost: PropTypes.number,
  projNetRevPerArea: PropTypes.number,
  projNetRev: PropTypes.number,
  totalFixedCost: PropTypes.number
};

Profitability.defaultProps = {
  estimatedAreaValue: 0,
  projectedRevenue: {
    yieldPerArea: 0,
    pricePerUnit: 0,
    cropSharePercent: 0,
    fsaPayment: 0
  },
  cost: 0,
  projNetRevPerArea: 0,
  projNetRev: 0,
  totalFixedCost: 0
};

export default Profitability;
