import React from 'react';

import RoundedDisplay from 'components/RoundedDisplay';
import { useSharedState } from '../MapEventContext';
import { calculateFeaturesTotalAreaInAcres } from '../../../../helpers/propertyDataHelpers';

const MapEventDisplay = () => {
  const [{ drawDistance, drawArea, coordinates, feature }] = useSharedState();
  const [xCoordinate, yCoordinate] = coordinates || [];

  const displayOffset = 20;

  const customDistanceDisplayStyles = !drawDistance
    ? {}
    : {
        left: `${xCoordinate + displayOffset}px`,
        top: `${yCoordinate - displayOffset}px`,
        textShadow:
          '-1px -1px 0 #000,  1px -1px 0 #000, -1px 1px 0 #000, 1px 1px 0 #000'
      };

  const customAreaDisplayStyles = !drawArea
    ? {}
    : {
        left: `${xCoordinate - displayOffset}px`,
        top: `${yCoordinate + displayOffset}px`,
        textShadow:
          '-1px -1px 0 #0060A4,  1px -1px 0 #0060A4, -1px 1px 0 #0060A4, 1px 1px 0 #0060A4'
      };

  const customInfoDisplayStyles = !feature
    ? {}
    : {
        left: `${xCoordinate}px`,
        top: `${yCoordinate}px`,
        textShadow:
          '-1px -1px 0 #0060A4,  1px -1px 0 #0060A4, -1px 1px 0 #0060A4, 1px 1px 0 #0060A4'
      };

  return (
    <>
      {drawArea && (
        <div
          data-testid="map-event-display-area"
          style={customAreaDisplayStyles}
          className="text-white absolute pointer-events-none z-10"
        >
          {drawArea} acres
        </div>
      )}

      {drawDistance && (
        <div
          data-testid="map-event-display-distance"
          style={customDistanceDisplayStyles}
          className="text-white absolute pointer-events-none z-10"
        >
          {drawDistance} ft
        </div>
      )}

      {feature && (
        <div
          data-testid="map-event-display-feature-info"
          style={customInfoDisplayStyles}
          className="text-white absolute pointer-events-none z-10"
        >
          <RoundedDisplay units="acres">
            {calculateFeaturesTotalAreaInAcres([feature])}
          </RoundedDisplay>
          {feature.properties.crops && <div>({feature.properties.crops})</div>}
        </div>
      )}
    </>
  );
};
export default MapEventDisplay;
