import React, { memo } from 'react';
import PropTypes from 'prop-types';

const MapIcon = ({ size, color }) => {
  return (
    <svg
      className="mt-2px"
      width={size}
      height={size}
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      data-testid="map-icon"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15.255 2.2725L15.375 2.25C15.585 2.25 15.75 2.415 15.75 2.625V13.965C15.75 14.1375 15.6375 14.2725 15.48 14.325L11.25 15.75L6.75 14.175L2.745 15.7275L2.625 15.75C2.415 15.75 2.25 15.585 2.25 15.375V4.035C2.25 3.8625 2.3625 3.7275 2.52 3.675L6.75 2.25L11.25 3.825L15.255 2.2725ZM10.5 5.15156L7.5 4.10156V12.8466L10.5 13.8966V5.15156ZM3.75 4.845L6 4.0875V12.8625L3.75 13.7325V4.845ZM12 13.9125L14.25 13.155V4.275L12 5.145V13.9125Z"
        fill={color}
      />
    </svg>
  );
};

MapIcon.propTypes = {
  color: PropTypes.string,
  size: PropTypes.number
};

MapIcon.defaultProps = {
  color: '#696F88',
  size: 18
};

export default memo(MapIcon);
