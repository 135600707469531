import React, { memo } from 'react';
import PropTypes from 'prop-types';

const ErrorIcon = ({ size = 20, color = '#CF3537' }) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      data-testid="error-icon"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9 1.5C4.86 1.5 1.5 4.86 1.5 9C1.5 13.14 4.86 16.5 9 16.5C13.14 16.5 16.5 13.14 16.5 9C16.5 4.86 13.14 1.5 9 1.5ZM8.25 12.75V11.25H9.75V12.75H8.25ZM8.25 5.25V9.75H9.75V5.25H8.25Z"
        fill={color}
      />
    </svg>
  );
};

ErrorIcon.propTypes = {
  color: PropTypes.string,
  size: PropTypes.number
};

ErrorIcon.defaultProps = {
  color: '#CF3537',
  size: 20
};

export default memo(ErrorIcon);
