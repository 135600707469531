import React from 'react';
import PropTypes from 'prop-types';

const FieldArea = ({ area, unit }) => {
  if (!area) return null;

  return (
    <div>
      <span className="text-xs">Size: </span>
      <span className="text-xs bold">
        {area?.toFixed(2)} {unit}
      </span>
    </div>
  );
};

FieldArea.defaultProps = {
  unit: 'ac'
};

FieldArea.propTypes = {
  area: PropTypes.number.isRequired,
  unit: PropTypes.string
};

export default FieldArea;
