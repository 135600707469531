import { PropTypes } from 'prop-types';
import React, { memo } from 'react';

const ChecklistIcon = ({ size, color }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      viewBox="0 0 52 52"
      data-testid="checklist-icon"
      className={`fill-current text-${color}`}
    >
      {/* <path d="M32.0533 24.6669L27.3333 19.9469L29.2133 18.0669L32.04 20.8936L37.6933 15.2402L39.5733 17.1202L32.0533 24.6669ZM24.6666 19.3336H12.6666V22.0002H24.6666V19.3336ZM38 27.8802L36.12 26.0002L32.6666 29.4536L29.2133 26.0002L27.3333 27.8802L30.7866 31.3336L27.3333 34.7869L29.2133 36.6669L32.6666 33.2136L36.12 36.6669L38 34.7869L34.5466 31.3336L38 27.8802ZM24.6666 30.0002H12.6666V32.6669H24.6666V30.0002Z" /> */}
      <path d="M32.0533 24.6669L27.3333 19.9469L29.2133 18.0669L32.04 20.8936L37.6933 15.2402L39.5733 17.1202L32.0533 24.6669ZM24.6666 19.3336H12.6666V22.0002H24.6666V19.3336ZM38 27.8802L36.12 26.0002L32.6666 29.4536L29.2133 26.0002L27.3333 27.8802L30.7866 31.3336L27.3333 34.7869L29.2133 36.6669L32.6666 33.2136L36.12 36.6669L38 34.7869L34.5466 31.3336L38 27.8802ZM24.6666 30.0002H12.6666V32.6669H24.6666V30.0002Z" />
    </svg>
  );
};

ChecklistIcon.propTypes = {
  color: PropTypes.string,
  size: PropTypes.number
};

ChecklistIcon.defaultProps = {
  color: 'neutral-600',
  size: 32
};

export default memo(ChecklistIcon);
