/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import PropTypes from 'prop-types';
import { toLower } from 'lodash';

import { Context } from '../../Store';

// the Placeholder can be used to make sure the component takes up the same amount of space even when it is not visible
// specify a placeholderWidth and/or placeholderHeight to trigger the usage of the Placeholder
const Placeholder = ({ placeholderWidth, placeholderHeight }) => {
  return placeholderHeight || placeholderWidth ? (
    <div
      data-testid="permissions-placeholder"
      style={{
        width: placeholderWidth,
        height: placeholderHeight
      }}
    >
      &nbsp;
    </div>
  ) : null;
};

Placeholder.propTypes = {
  placeholderWidth: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  placeholderHeight: PropTypes.oneOfType([PropTypes.number, PropTypes.string])
};

Placeholder.defaultProps = {
  placeholderWidth: 0,
  placeholderHeight: 0
};

/**
 * an HOC for creating a version of a Component that is disabled or invisible for users lacking appropriate permissions
 * @param Component - the component that needs special treatment based on the user's permissions
 * @param visibleRoles - optional array that specifies the user roles permitted to see this Component
 * @param enabledRoles - optional array that specifies the user roles permitted to use this Component
 * @param placeholderWidth - optional width number/string that specifies the width of the Placeholder that replaces the  invisible Component
 * @param placeholderHeight - optional height number/string that specifies the height of the Placeholder that replaces the invisible Component
 * @returns {function(*): *} - the permissions-controlled version of the Component
 */
const withPermissions = (
  Component,
  { visibleRoles, enabledRoles, placeholderWidth, placeholderHeight }
) => props => (
  <Context.Consumer>
    {([{ permission }]) => {
      if (!permission) {
        return (
          <Placeholder
            placeholderWidth={placeholderWidth}
            placeholderHeight={placeholderHeight}
          />
        );
      }
      const isVisible =
        !visibleRoles ||
        visibleRoles.map(toLower).includes(toLower(permission.role));
      const isEnabled =
        !enabledRoles ||
        enabledRoles.map(toLower).includes(toLower(permission.role));
      if (!isVisible) {
        return (
          <Placeholder
            placeholderWidth={placeholderWidth}
            placeholderHeight={placeholderHeight}
          />
        );
      }
      return <Component {...props} disabled={!isEnabled} />;
    }}
  </Context.Consumer>
);

export default withPermissions;
