import React, { useEffect, useMemo, useState, useContext } from 'react';
import Breadcrumb from 'components/App/AppShell/components/Breadcrumb';
import { Formik, Form } from 'formik';
import ADD_YIELD_SCHEMAS from 'screens/YieldV2/AddYield/helpers/addYieldValidationSchema';
import LoadsManager from 'screens/YieldV2/AddYield/components/LoadsManager';
import { useParams, useHistory } from 'react-router-dom';
import useLoads from 'hooks/useLoads';
import CropIconMapping from 'components/CropIconMapping';
import convertLoadToFormValues from 'screens/YieldV2/AddYield/helpers/convertLoadToFormValues';
import InfoToast from 'screens/YieldV2/StorageLocations/components/InfoToast';
import { paths } from 'routes/paths';
import { Context } from 'components/Store';
import { SET_SHOW_TOAST_CHANGES_SAVED } from 'reducers/reducer';
import { replaceUndefinedAndEmptyWithNull } from 'utilities/helpers';
import FullHeightSpinner from 'screens/YieldV2/AddYield/components/FullHeightSpinner';
import processYieldTickets from '../AddYield/helpers/processYieldTickets';
import { YIELD_TEMPLATES } from '../AddYield';
import { transformWeightUnit } from '../AddYield/helpers/transformWeightUnit';

const EditYield = () => {
  const { id } = useParams();
  const history = useHistory();
  const [, dispatch] = useContext(Context);
  const [isToastVisible, setIsToastVisible] = useState(false);
  const {
    getLoadById,
    updateLoadById,
    yieldLoad,
    loading: isUpdating,
    submitError
  } = useLoads();

  useEffect(() => {
    getLoadById(id);
  }, [getLoadById, id]);

  const initialFormValues = useMemo(() => {
    if (!yieldLoad) {
      return null;
    }
    // select the season from the data
    localStorage.setItem(
      'selectedCropSeasons',
      JSON.stringify([yieldLoad.cropSeasonId])
    );
    return convertLoadToFormValues(yieldLoad);
  }, [yieldLoad]);

  const onSubmit = async values => {
    try {
      const normalizedValues = replaceUndefinedAndEmptyWithNull(values);
      if (normalizedValues.weightUnit) {
        normalizedValues.weightUnit = transformWeightUnit(
          normalizedValues.weightUnit
        );
      }
      const [updatedTicket] = processYieldTickets([
        { ...normalizedValues, templateType: yieldLoad.templateType }
      ]);
      await updateLoadById(id, updatedTicket);
      dispatch({ type: SET_SHOW_TOAST_CHANGES_SAVED, payload: true });
      history.push(`${paths.yieldV2}/loads/${id}/view`);
    } catch (error) {
      setIsToastVisible(true);
    }
  };

  if (!initialFormValues) {
    return null;
  }

  const YieldTemplate = YIELD_TEMPLATES[yieldLoad.templateType].component;
  return (
    <Formik
      initialValues={initialFormValues}
      validationSchema={ADD_YIELD_SCHEMAS[yieldLoad.templateType]}
      onSubmit={onSubmit}
    >
      <Form className="h-full" noValidate>
        <Breadcrumb hideCropSeasonDropdown={false} disabledCropSeasons disabled>
          <Breadcrumb.Item title="Yield" value="Edit Yield Load" isLast />
        </Breadcrumb>
        <div className="flex h-full">
          <div className="px-4 flex-1">
            <div className="pl-8 pb-4 pt-6 flex gap-6">
              <h1 className="text-xl leading-7 tracking-tight flex items-center">
                Crop:
                <CropIconMapping cropObject={{ name: yieldLoad?.cropName }} />
                {yieldLoad?.cropName}
              </h1>
            </div>
            <div
              style={{ maxHeight: 'calc(100% - 100px)' }}
              className="shadow-elevation-2 bg-white rounded-lg relative flex-1 flex flex-col overflow-auto h-full"
            >
              {isUpdating ? (
                <FullHeightSpinner text="Saving Changes..." />
              ) : (
                <div className="overflow-y-auto p-8 ">
                  <YieldTemplate selectedCropName={yieldLoad?.cropName} />
                </div>
              )}
            </div>
          </div>
          <div className="max-w-80 w-full h-full">
            {/* we should move LoadsManager to EditYield screen */}
            <LoadsManager isEditYieldPage />
            <InfoToast
              title="Unable to save changes."
              state="error"
              showToast={submitError !== null && isToastVisible}
              setShowToast={setIsToastVisible}
              errorMessage="An error prevented your load from being updated. Please try
                  again."
              isBold
            />
          </div>
        </div>
      </Form>
    </Formik>
  );
};

export default EditYield;
