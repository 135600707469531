import React, {
  useEffect,
  useContext,
  useState,
  useRef,
  useCallback
} from 'react';
import { useParams, Redirect } from 'react-router-dom';
import { Formik } from 'formik';
import { Spinner, Button, TextArea } from '@agconnections/grow-ui';
// import axios from 'axios';
// import { MASTERLIST_PROXY_URL } from 'utilities/apiConstants';
// import { getAccessToken, useAuth } from 'utilities/base-auth';
import { useAuth } from 'utilities/base-auth';
// import alphanumSort from 'helpers/sorters';

import Breadcrumb from 'components/App/AppShell/components/Breadcrumb';
import useOrganizationApi from 'hooks/useOrganizationApi';
import catchCancel from 'helpers/catchCancel';
import { parseServerError } from 'helpers/errorHelpers';
import EditScreenForm from 'components/EditScreenForm';
import withAJAXErrorHandler, {
  AjaxErrorContext
} from 'components/ErrorHandler/_HOC_/withAJAXErrorHandler';
import { organization as organizationApi } from 'utilities/api';
import DeleteModal from 'components/Modals/DeleteModal';
import DeleteModalTrigger from 'components/Modals/DeleteModal/components/DeleteModalTrigger';
import { Paper } from '@material-ui/core';
import GrowUIFormField from 'components/Forms/GrowUIFormField';
import { AmplitudeContext } from 'utilities/amplitude/useAmplitude';
import { paths } from 'routes/paths';
import { mapFormToApi, FormModel, mapApiToForm } from './helpers/dataHelpers';
import { create, update } from '../../helpers/dataHelpers';
import { useEquipmentItemData } from './hooks/useEquipmentItemData';
import EquipmentDetailsSection from './components/EquipmentDetailsSection';
// import ServiceCostsSection from './components/ServiceCostsSection';
import PriceAndStatusSection from './components/PriceAndStatusSection';

import schema from './schema';

const EquipmentItem = () => {
  const { id } = useParams();
  const { user } = useAuth();
  const [redirect, setRedirect] = useState(null);
  const [equipmentItem, setEquipmentItem] = useState(FormModel());
  const [open, setOpen] = useState(false);
  // const [servicesList, setServicesList] = useState([]);
  const [submitting, setSubmitting] = useState(false);
  const priceAndStatusRef = useRef(null);
  const equipmentAPI = useOrganizationApi('equipment');
  const openModal = () => setOpen(true);
  const closeModal = () => setOpen(false);
  const setCancelBtn = true;
  const amplitude = useContext(AmplitudeContext);
  const { setAjaxError } = useContext(AjaxErrorContext);
  const [selectedMember, setSelectedMember] = useState(null);
  const [financialAccess, setFinancialAccess] = useState();

  const {
    loading,
    setLoading,
    isCreateScreen,
    dispatch
  } = useEquipmentItemData(id);

  const triggerRedirect = () => {
    setEquipmentItem(null);
    setRedirect(paths.equipment);
  };

  const getSingleMember = useCallback(async () => {
    const { promise } = organizationApi.fetch();
    await promise
      .then(({ data }) => {
        const filterMember = data?.members?.filter(
          eachMember => eachMember.email === user.email
        )[0];
        setSelectedMember(filterMember);
      })
      .catch(catchCancel)
      .catch(err => {
        parseServerError(dispatch)(err);
      });
  }, [dispatch, user.email]);

  useEffect(() => {
    if (!selectedMember) {
      getSingleMember();
    }
    if (
      selectedMember?.role === 'Full control' &&
      !Object.prototype.hasOwnProperty.call(selectedMember, 'financialAccess')
    ) {
      setFinancialAccess('none');
    } else {
      setFinancialAccess(selectedMember?.financialAccess);
    }
  }, [selectedMember, getSingleMember]);

  useEffect(() => {
    let cancel;
    if (!isCreateScreen) {
      const { promise, cancel: _cancel } = equipmentAPI.fetch(id);
      cancel = _cancel;
      promise
        .then(({ data }) => {
          // getServicesList();
          const mappedToForm = mapApiToForm(data);
          setEquipmentItem(mappedToForm);
        })
        .catch(catchCancel)
        .catch(err => {
          parseServerError(dispatch)(err);
          setAjaxError(err);
        })
        .finally(() => setLoading(false));
      return () => {
        dispatch({ type: 'SET_TOOLBAR', payload: null });
        cancel();
      };
    }

    // getServicesList();

    return () => cancel && cancel();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const createOrUpdateItem = async ({ ...values }) => {
    try {
      const mappedValues = mapFormToApi(values);
      const { promise } = isCreateScreen
        ? create(
            mappedValues,
            equipmentAPI,
            setSubmitting,
            triggerRedirect,
            dispatch
          )
        : update(
            mappedValues,
            equipmentAPI,
            setSubmitting,
            triggerRedirect,
            dispatch
          );
      await promise;
    } catch (error) {
      parseServerError(dispatch)(error);
    }
  };

  const deleteItem = async () => {
    try {
      await equipmentAPI.delete(equipmentItem.id).promise;
      setRedirect(paths.equipment);
    } catch (error) {
      parseServerError(dispatch)(error);
    }
  };

  const getDisplayName = () => {
    return isCreateScreen ? 'Create Equipment' : 'Edit Equipment';
  };

  return (
    <div className="w-full h-full">
      <Breadcrumb disabled>
        <Breadcrumb.Item
          title="Equipment"
          value="All Equipment"
          to={paths.equipment}
        />
        <Breadcrumb.Item
          title="Equipment"
          value={isCreateScreen ? 'New Equipment' : 'Edit Equipment'}
          isLast
        />
      </Breadcrumb>
      {redirect && <Redirect push to={`${redirect}`} />}
      {loading ? (
        <Spinner />
      ) : (
        <>
          <div data-testid="equipment-item">
            <DeleteModal
              open={open}
              itemType="Equipment"
              onCancel={closeModal}
              onDelete={deleteItem}
            />
            <Formik
              initialValues={equipmentItem}
              validationSchema={schema}
              onSubmit={createOrUpdateItem}
            >
              {/* {({ values, submitForm, errors }) => { */}
              {({ submitForm, errors }) => {
                return (
                  <div className="mb-10">
                    <div className="flex text-gray-800 pt-4 pb-4">
                      <div className="flex w-full">
                        <div className="text-2xl w-full font-bold">
                          {getDisplayName()}
                        </div>
                        <div className="flex">
                          <DeleteModalTrigger
                            isCreateScreen={setCancelBtn}
                            redirectPath={paths.equipment}
                            openModal={openModal}
                            setRedirect={setRedirect}
                          />
                          <div className="ml-4">
                            <Button
                              type="primary"
                              onClick={() => {
                                // setSubmitting(true);
                                // amplitude trigger
                                amplitude.sendEventToAmplitude(
                                  amplitude.events.epic.Equipment
                                    .createEquipment
                                );
                                submitForm();
                              }}
                              disabled={Object.keys(errors).length > 0}
                              loading={submitting}
                            >
                              Save
                            </Button>
                          </div>
                        </div>
                      </div>
                    </div>
                    <Paper>
                      <EditScreenForm>
                        <EquipmentDetailsSection />
                        <PriceAndStatusSection
                          ref={priceAndStatusRef}
                          memberRole={selectedMember?.role}
                          memberFinancialAccess={financialAccess}
                        />
                        {/* <ServiceCostsSection
                          data={values}
                          serviceList={servicesList}
                          memberRole={selectedMember?.role}
                          memberFinancialAccess={financialAccess}
                        /> */}
                        <div className="mt-4 mb-4 text-2xl font-bold">
                          Notes
                        </div>
                        <GrowUIFormField
                          control={TextArea}
                          id="notes"
                          name="notes"
                          rows="5"
                        />
                      </EditScreenForm>
                    </Paper>
                  </div>
                );
              }}
            </Formik>
          </div>
        </>
      )}
    </div>
  );
};

export default withAJAXErrorHandler(EquipmentItem, {
  resourceName: 'Equipment Item',
  btnLink: paths.equipment,
  btnText: 'View Equipment'
});
