import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { sortBy } from 'lodash';
import { Columns, Column, Button, Spinner } from '@agconnections/grow-ui';

import PlusIcon from 'assets/plus_green.svg';
import CropAddEditModal from 'components/Modals/CropAddEditModal';
import CropCard from 'components/CropCard';
import PortalModal from 'components/Modals/PortalModal';
import { FETCH_TYPES } from 'helpers/constants';
import usePopulateMapData from 'hooks/usePopulateMapData';
import useCropSeasons from 'hooks/useCropSeasons';
import { OpenInNewIcon } from 'components/Icons';

import EmptyStateProduct from 'screens/Inventory/components/EmptyStateProduct';

import useREIPHIData from '../../hooks/useREIPHIData';
import useInventoryDocumentationData from '../../hooks/useInventoryDocumentationData';
import getCompleteREIPHIData from '../../helpers/getCompleteREIPHIData';

const ProductDetailREIPHI = () => {
  const { id: productId } = useParams();

  const [reiPhiFiltered, setReiPhiFiltered] = useState([]);
  const [selectedCrop, setSelectedCrop] = useState(null);
  const [openEdit, setOpenEdit] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [openDelete, setOpenDelete] = useState(false);
  const [prodToDelete, setProdToDelete] = useState(null);

  const { reiphiData, reiphiLoading, saveREIPHI, deleteREIPHI } = useREIPHIData(
    productId
  );

  const {
    dataDocumentation,
    isLoading: isDocsLoading
  } = useInventoryDocumentationData(productId);

  const { cropSeasons } = useCropSeasons();

  const {
    dataSorted: propertyData,
    reloadData,
    loading: isPropertyLoading
  } = usePopulateMapData({
    fetchType: FETCH_TYPES.cropSeasonsMap,
    isCacheOn: true
  });

  useEffect(() => {
    if (cropSeasons) {
      const cropSeasonIds = cropSeasons.map(cropSeason => cropSeason.id);

      reloadData({ seasonIds: cropSeasonIds });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cropSeasons]);

  useEffect(() => {
    if (
      !reiphiData ||
      !dataDocumentation?.reiphiitems ||
      !propertyData?.properties.length > 0
    ) {
      return;
    }

    const completeREIPHIData = getCompleteREIPHIData(
      dataDocumentation.reiphiitems,
      propertyData.properties,
      reiphiData
    );

    setReiPhiFiltered(completeREIPHIData);
  }, [reiphiData, dataDocumentation, propertyData]);

  const openDeleteModal = deleteProd => {
    setProdToDelete(deleteProd);
    setOpenDelete(true);
  };

  const closeDeleteModal = () => {
    setOpenDelete(false);
  };

  const handleDelete = () => {
    deleteREIPHI(prodToDelete, closeDeleteModal);
  };

  const openAddEdit = (edit, cropToEdit) => {
    setSelectedCrop(cropToEdit);
    setIsEdit(edit);
    setOpenEdit(true);
  };

  const closeAddEditModal = () => {
    setOpenEdit(false);
  };

  const footerInfor = () => {
    return (
      <>
        <ul className="list-disc text-m text-neutral-80 leading-7 py-2 mt-2 ml-6">
          <li>
            REI (Restricted Entry Interval): Time limit for access on the field
            after application of the product. Measured in hours.
          </li>
          <li>
            PHI (Pre-Harvested Interval): Time limit for harvesting after
            application. Measured in days.
          </li>
          <li>
            REI/PHI: Is indicated on the label of the preparation and/or on the
            manufacturer&apos;s website.
          </li>
        </ul>
        <span className="text-m text-neutral-1000 leading-10 py-2 flex items-center">
          <p className="text-m text-neutral-1000">
            Always check the product label to confirm accuracy. Cropwise is not
            responsible for inaccurate information.
          </p>
          <a
            href="https://login.landdb.com/eula"
            type="button"
            target="_blank"
            rel="noopener noreferrer"
          >
            <Button type="link-default" icon={<OpenInNewIcon fill="#0071CD" />}>
              VIEW LICENSE AGREEMENT
            </Button>
          </a>
        </span>
        {openEdit && (
          <CropAddEditModal
            openEdit={openEdit}
            onClose={closeAddEditModal}
            crop={selectedCrop}
            isEdit={isEdit}
            productId={productId}
            saveREIPHI={saveREIPHI}
          />
        )}
      </>
    );
  };

  if (reiphiLoading || isDocsLoading || isPropertyLoading) {
    return (
      <div
        data-testid="rei-phi-loading"
        className="flex justify-center items-center w-full h-full"
      >
        <Spinner size="lg" />
      </div>
    );
  }

  return reiPhiFiltered.length > 0 ? (
    <>
      <div className="grid justify-end py-2 mb-2">
        <Button
          type="primary"
          ghost
          onClick={() => openAddEdit(false)}
          icon={<img alt="plus" src={PlusIcon} className="mr-1" />}
        >
          Add Crop
        </Button>
      </div>
      <div className="max-h-20 overflow-y-auto">
        <Columns wrap>
          {sortBy(reiPhiFiltered, 'name').map(product => (
            <Column width="gr-w-1/2 p-4" key={product.cropId}>
              <CropCard
                product={product}
                editClick={() => openAddEdit(true, product)}
                deleteClick={() => {
                  openDeleteModal(product);
                }}
              />
            </Column>
          ))}
        </Columns>
      </div>
      <div className="ml-4">{footerInfor()}</div>
      <PortalModal
        open={openDelete}
        title="Delete REI/PHI Information?"
        close={closeDeleteModal}
        cancelLabel="Cancel"
        confirmLabel="Delete"
        onCancel={closeDeleteModal}
        type="error"
        onConfirm={handleDelete}
      >
        <div className="pr-6">
          Are you sure you want to delete this information? This action cannot
          be undone.
        </div>
      </PortalModal>
    </>
  ) : (
    <EmptyStateProduct
      setPermission={() => true}
      openAddEditModal={openAddEdit}
    >
      {footerInfor()}
    </EmptyStateProduct>
  );
};

export default ProductDetailREIPHI;
