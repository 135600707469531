import React, { useContext, useEffect, useState } from 'react';
import CwFPagination from 'components/CwFPagination';
import PropTypes from 'prop-types';
import { TABLE_COLUMNS } from 'screens/Companies/helpers/constants';
import { Redirect, useHistory } from 'react-router-dom';
import companiesTableSort from 'screens/Companies/helpers/companyTableSort';
import CompanyTableRow from 'screens/Companies/components/CompanyList/CompanyTableRow';
import LandingTable from 'components/LandingTable';
import Paper from 'components/Paper';
import { AmplitudeContext } from 'utilities/amplitude/useAmplitude';
import { paths } from 'routes/paths';
import NoCompaniesOverlay from '../NoCompaniesOverlay';
import DeleteCompanyModal from '../DeleteCompanyModal';

const CompanyList = ({
  companies,
  memberRole,
  totalCompanies,
  companyPageNumber,
  handlePageChange
}) => {
  const [sortField, setSortField] = useState();
  const [sortDir, setSortDir] = useState();
  const [filteredCompanies, setFilteredCompanies] = useState(companies);
  const redirect = null;
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const openDeleteModal = () => setDeleteModalOpen(true);
  const closeDeleteModal = () => setDeleteModalOpen(false);
  const [companyToDelete, setCompanyToDelete] = useState(null);
  const history = useHistory();
  const handleSort = (field, dir) => {
    setSortField(field);
    setSortDir(dir);
    setFilteredCompanies(companiesTableSort(filteredCompanies, field, dir));
  };
  const amplitude = useContext(AmplitudeContext);

  const handleViewClicked = id => {
    history.push({
      pathname: `${paths.companies}/${id}/view`
    });
  };

  const handleEditClicked = id => {
    history.push({
      pathname: `${paths.companies}/${id}`
    });
  };

  const handleAction = async (action, { id, name }) => {
    const actions = {
      view: () => {
        amplitude.sendEventToAmplitude(
          amplitude.events.epic.Companies.editCompany
        );
        handleViewClicked(id);
      },
      edit: () => {
        handleEditClicked(id);
      },
      delete: () => {
        amplitude.sendEventToAmplitude(
          amplitude.events.epic.Companies.deleteCompany
        );
        openDeleteModal();
        setCompanyToDelete({ id, name });
      }
    };
    actions[action]();
  };

  useEffect(() => {
    setFilteredCompanies(companies);
  }, [companies]);

  return (
    <>
      {redirect && <Redirect push to={`${redirect}`} />}
      {companies.length ? (
        <Paper>
          {CwFPagination(totalCompanies, companyPageNumber, handlePageChange)}
          <DeleteCompanyModal
            open={deleteModalOpen}
            close={closeDeleteModal}
            companyName={companyToDelete?.name}
            companyId={companyToDelete?.id}
          />
          <LandingTable
            listType="Companies"
            routePath={paths.companies}
            items={filteredCompanies}
            tableColumns={TABLE_COLUMNS}
            onSort={handleSort}
            sortBy={sortField}
            sortDir={sortDir}
          >
            {({ rows }) =>
              rows.map(companyObject => (
                <CompanyTableRow
                  key={companyObject.id}
                  onAction={handleAction}
                  company={companyObject}
                />
              ))
            }
          </LandingTable>
          {CwFPagination(totalCompanies, companyPageNumber, handlePageChange)}
          <div className="mb-12"> </div>
        </Paper>
      ) : (
        <NoCompaniesOverlay
          open={companies?.length === 0}
          onClick={() => history.push(`${paths.companies}/company`)}
          memberRole={memberRole}
        />
      )}
    </>
  );
};

CompanyList.propTypes = {
  companies: PropTypes.arrayOf(
    PropTypes.shape({
      forEach: PropTypes.func,
      length: PropTypes.number
    })
  ).isRequired,
  memberRole: PropTypes.string.isRequired,
  totalCompanies: PropTypes.number.isRequired,
  companyPageNumber: PropTypes.number.isRequired,
  handlePageChange: PropTypes.func.isRequired
};

export default CompanyList;
