const normalize = value => {
  if (typeof value === 'string') {
    return value.trim().toLowerCase();
  }
  return value;
};

const combineCropData = (cropHistory, variety) => {
  const varietyMap = new Map(
    variety.map(item => [`${normalize(item.id)}`, item])
  );

  return cropHistory.map(history => {
    const key = `${normalize(history.cropZoneId)}`;
    const match = varietyMap.get(key);

    return {
      cropseason: history.cropseason,
      cropname: history.cropname,
      cropId: history.cropId,
      cropZoneId: history.cropZoneId,
      ...(match || {})
    };
  });
};

export default combineCropData;
