/* eslint-disable react/prop-types */
import React, { useContext, useEffect, useState } from 'react';
import Breadcrumb from 'components/App/AppShell/components/Breadcrumb';
import { paths } from 'routes/paths';
import useLandingPageData from 'hooks/useLandingPageData';
import { useHistory, useParams } from 'react-router-dom';
import { Spinner } from '@agconnections/grow-ui';
import { Context } from 'components/Store';
import { SET_SHOW_TOAST_SALE_LOCATION } from 'reducers/reducer';
import createAction from 'helpers/createAction';
import useSaleLocations from '../../hooks/useSaleLocations';
import SaleLocationToast from '../SaleLocationToast';
import { initialValuesSaleLocation } from '../../constants';
import validationSchemaSaleLocation from '../../helpers/validationSchemaSaleLocation';
import FormStructureSaleLocation from '../FormStructureSaleLocation';
import findById from '../../helpers/findById';
import formatBody from '../../helpers/formatBody';

const EditSaleLocation = () => {
  const history = useHistory();
  const [
    { showToastSaleLocation, toastType, organization },
    dispatch
  ] = useContext(Context);
  const [proxyLoading, setProxyLoading] = useState(false);
  const [saleListToEdit, setSaleListToEdit] = useState({});
  const { id } = useParams();
  const [showToast, setShowToast] = useState();
  const {
    editSaleLocation,
    isLoadingCreateSaleLocation,
    isLoadingEditSaleLocation,
    getSaleLocationList,
    saleLocationList
  } = useSaleLocations();
  const { loaded, items: companies, setReload } = useLandingPageData(
    'worker/companies',
    ['company.name'],
    0
  );
  useEffect(() => {
    setProxyLoading(true);
    const foundSaleLocation =
      saleLocationList?.length && !isLoadingCreateSaleLocation
        ? findById(saleLocationList, id)
        : undefined;
    if (!foundSaleLocation) return () => {};
    setSaleListToEdit(prevValue => ({
      ...prevValue,
      ...foundSaleLocation,
      companyId: foundSaleLocation.company?.id
    }));
    setProxyLoading(false);

    return () => {};
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [saleLocationList, id]);

  useEffect(() => {
    getSaleLocationList(organization.id);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setShowToast(showToastSaleLocation);
  }, [showToastSaleLocation]);

  const closeToast = value => {
    createAction(dispatch, SET_SHOW_TOAST_SALE_LOCATION, value);
  };

  const cancel = () => {
    history.push(`${paths.yieldV2}/sale-locations`);
  };
  const handleOrgChange = () => {
    setReload(true);
  };

  const submit = values => {
    editSaleLocation(id, formatBody(values));
  };
  return (
    <>
      <Breadcrumb
        onOrganizationSelect={handleOrgChange}
        hideCropSeasonDropdown
        disabled
      >
        <Breadcrumb.Item title="Yield" value="Edit Sale Location" isLast />
      </Breadcrumb>

      {!proxyLoading ? (
        <FormStructureSaleLocation
          formInitialValues={{
            ...initialValuesSaleLocation,
            ...saleListToEdit
          }}
          schemaValidation={validationSchemaSaleLocation}
          _handleSubmit={submit}
          handleCancel={cancel}
          isLoadingForm={isLoadingEditSaleLocation}
          labelSavingForm="Saving Changes"
          isLoadingCompanies={loaded}
          companies={companies}
        />
      ) : (
        <Spinner size="lg" />
      )}

      {showToastSaleLocation && (
        <SaleLocationToast
          showToast={showToast}
          setShowToast={closeToast}
          state={toastType}
        />
      )}
    </>
  );
};

export default EditSaleLocation;
