import React from 'react';
import PropTypes from 'prop-types';
import { Select } from '@agconnections/grow-ui';

import {
  createEventHandlerWithTransform,
  getEventSelectedKey
} from 'utilities/formHelpers';

function CountryRegionSelect({ country, onChange }) {
  const countriesItems = [
    { key: 'United States', value: 'United States' },
    { key: 'Canada', value: 'Canada' },
    { key: 'France', value: 'France' }
  ];

  const handleChange = createEventHandlerWithTransform(
    onChange,
    getEventSelectedKey
  );

  return (
    <Select
      id="country-select"
      items={countriesItems}
      value={country}
      onChange={handleChange}
    />
  );
}

CountryRegionSelect.defaultProps = {
  country: null
};

CountryRegionSelect.propTypes = {
  country: PropTypes.string,
  onChange: PropTypes.func.isRequired
};

export default CountryRegionSelect;
