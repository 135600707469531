import React from 'react';
import PropTypes from 'prop-types';

const ModalTileButton = ({
  title,
  subtitle,
  imageSource,
  buttonClickEvent,
  onKeyDown
}) => {
  return (
    <>
      <div
        className="w-full h-full border rounded-xl text-center shadow-lg"
        onClick={buttonClickEvent}
        onKeyDown={onKeyDown}
        role="button"
        tabIndex={0}
      >
        <div className="mt-8 mb-4 ml-6 mr-6">
          <div className="mb-8">
            <img className="block m-auto" src={imageSource} alt="" />
          </div>
          <div className="text-xl font-semibold leading-7">{title}</div>
          <div className="text-lg leading-7">{subtitle}</div>
        </div>
      </div>
    </>
  );
};

ModalTileButton.defaultProps = {
  imageSource: ''
};

ModalTileButton.propTypes = {
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.string.isRequired,
  imageSource: PropTypes.string,
  buttonClickEvent: PropTypes.func.isRequired,
  onKeyDown: PropTypes.func.isRequired
};

export default ModalTileButton;
