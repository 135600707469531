import React, { useContext } from 'react';
import { Button } from '@agconnections/grow-ui';
import { AddRounded } from '@material-ui/icons';
import FilterOutlinedIcon from 'assets/filter_outlined.svg';
import SearchIcon from 'assets/search.svg';
import PropTypes from 'prop-types';
import { Badge, Input } from 'syngenta-digital-cropwise-react-ui-kit';
import { AmplitudeContext } from 'utilities/amplitude/useAmplitude';
import FilterByCropsSelectInput from '../FilterByCropsSelectInput';
import './index.css';

export default function ActionsTopbar({
  searchValue,
  onSearchChange,
  cropsFilterValue,
  cropsFilterValueChange,
  onFilterButtonClick,
  areFiltersApplied,
  addYieldLoads
}) {
  const amplitude = useContext(AmplitudeContext);
  return (
    <div
      data-testid="actions-topbar"
      className="flex items-center justify-between h-16"
    >
      <div className="flex items-center gap-4 flex-1 max-w-3xl">
        <div data-testid="search-loads-input" className="flex-1">
          <Input
            className="search-loads-input"
            type="default"
            style={{ margin: 0 }}
            prefix={<img alt="Search icon" src={SearchIcon} />}
            placeholder="Search loads"
            allowClear
            size="middle"
            value={searchValue}
            onChange={e => onSearchChange(e.target.value)}
          />
        </div>
        <div data-testid="crops-input" className="flex-1">
          <FilterByCropsSelectInput
            cropsFilterValue={cropsFilterValue}
            cropsFilterValueChange={cropsFilterValueChange}
          />
        </div>
        <div className="h-max filter-button" data-testid="filter-button">
          <Badge
            dot
            color="#0092E4"
            size="default"
            count={areFiltersApplied ? 1 : 0}
            showZero={false}
          >
            <Button
              type="outline"
              style={{ height: '100%' }}
              onClick={onFilterButtonClick}
              icon={<img src={FilterOutlinedIcon} alt="Filter button" />}
            >
              Filter
            </Button>
          </Badge>
        </div>
      </div>
      <div data-testid="add-yield-load">
        <Button
          icon={<AddRounded />}
          type="primary"
          onClick={() => {
            amplitude.sendEventToAmplitude(
              amplitude.events.epic.YieldV2.addYield.createYield
            );
            addYieldLoads();
          }}
        >
          Yield Loads
        </Button>
      </div>
    </div>
  );
}

ActionsTopbar.defaultProps = {
  cropsFilterValue: [],
  cropsFilterValueChange: () => {},
  onSearchChange: () => {},
  onFilterButtonClick: () => {},
  addYieldLoads: () => {},
  areFiltersApplied: false,
  searchValue: ''
};

ActionsTopbar.propTypes = {
  cropsFilterValue: PropTypes.arrayOf(PropTypes.string),
  cropsFilterValueChange: PropTypes.func,
  onSearchChange: PropTypes.func,
  onFilterButtonClick: PropTypes.func,
  addYieldLoads: PropTypes.func,
  areFiltersApplied: PropTypes.bool,
  searchValue: PropTypes.string
};
