import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';

import { Icon } from '@agconnections/grow-ui';
import ClickableDiv from 'components/ClickableDiv';
import { verticalCollapse } from 'utilities/animations';
import { generate } from 'shortid';
import ToggleButton from '../ToggleButton';

const selectArrowIcon = open => (
  <Icon icon={open ? 'chevron-up' : 'chevron-down'} color="#515253" />
);

const CollapsibleToggle = ({ title, options, showOptions }) => {
  const [toggleOptions, setToggleOptions] = useState(showOptions);
  const [arrowIcon, setArrowIcon] = useState(selectArrowIcon(showOptions));

  const optionsRef = useRef();

  useEffect(() => {
    setArrowIcon(selectArrowIcon(toggleOptions));
    verticalCollapse(optionsRef.current, toggleOptions);
  }, [toggleOptions]);

  return (
    <div className="my-3">
      <ClickableDiv
        className="flex justify-between items-center mb-4 px-5"
        onClick={() => setToggleOptions(!toggleOptions)}
      >
        <span className="text-black font-semibold">{title}</span>
        {arrowIcon}
      </ClickableDiv>
      <div
        className="transition-all duration-200 overflow-y-hidden border-b border-neutral-100"
        ref={optionsRef}
        data-testid="collapsible-options"
      >
        <ul className="text-black px-5 pt-2 pb-4 gap-y-4">
          {options?.map(option => (
            <div
              key={generate()}
              className="flex flex-row items-center justify-between pb-2"
            >
              <span className="text-neutral-1000 font-light">
                {option.name}
              </span>
              <ToggleButton
                id={`collapsible-toggle-option-${option.name}`}
                name={`collapsible-toggle-option-${option.name}`}
                defaultChecked={option.value}
                onChange={event => option.onChange(event.target.checked)}
                color="#0071CD"
                big
              />
            </div>
          ))}
        </ul>
      </div>
    </div>
  );
};

CollapsibleToggle.propTypes = {
  title: PropTypes.string.isRequired,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      onChange: PropTypes.func,
      value: PropTypes.bool
    })
  ).isRequired,
  showOptions: PropTypes.bool
};

CollapsibleToggle.defaultProps = {
  showOptions: false
};

export default CollapsibleToggle;
