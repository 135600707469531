import React, { useState, useEffect, useMemo } from 'react';
import { Input, Select, Toast } from '@agconnections/grow-ui';
import { useParams } from 'react-router-dom';
import PropTypes from 'prop-types';
import { FieldArray, useFormikContext } from 'formik';
import PortalModal from 'components/Modals/PortalModal';
import GrowUIFormField from 'components/Forms/GrowUIFormField';
import useProduct from 'hooks/useProduct';
import { Maths } from 'helpers/maths';
import convertFifteenPlaces, {
  convertTwoPlaces
} from 'components/ProductSideBar/helpers/conversion';
import { getCorrectUnitName } from 'helpers/unitsAbbreviation';

// eslint-disable-next-line react/prop-types
const AddProductInvoiceModal = ({
  open,
  onClose,
  product,
  isEdit,
  rowIndex,
  memberRole,
  memberFinancialAccess
}) => {
  const initialValueForUnitPrice = useMemo(() => {
    if (isEdit) {
      const value = convertFifteenPlaces(
        Maths.divide(product?.totalCostValue, product?.totalQuantityValue)
      );
      return isNaN(value) ? (0).toFixed(2) : value;
    }
    return 0;
  }, [product?.totalCostValue, product?.totalQuantityValue, isEdit]);
  const { getMasterListProductUnits, getProductUnits } = useProduct();
  const [stdPackageUnit, setStdPackageUnit] = useState([]);
  const { values, setFieldError, errors } = useFormikContext();
  const [productToAdd, setProductToAdd] = useState(product);
  const [openConfirmation, setOpenConfirmation] = useState(false);
  const [unitPrice, setUnitPrice] = useState(initialValueForUnitPrice);
  const [isEditingTotalProduct, setIsEditingTotalProduct] = useState(false);
  const [isEditingUnitPrice, setIsEditingUnitPrice] = useState(false);
  const [isEditingTotalCost, setIsEditingTotalCost] = useState(false);
  const [error, setError] = useState(null);
  const { id } = useParams();

  useEffect(() => {
    setProductToAdd(product);
  }, [product]);

  useEffect(() => {
    if (open) {
      if (!product?.custom) {
        getMasterListProductUnits(product?.productId).then(setStdPackageUnit);
      } else {
        getProductUnits(product?.productId).then(setStdPackageUnit);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open, product]);

  const handleClose = () => {
    setFieldError('totalQuantityUnit');
    setFieldError('unitPrice');
    onClose();
  };

  const calcTotalCost = (totalProduct, _unitPrice, currentTotalCost) => {
    if (currentTotalCost && Number.parseFloat(currentTotalCost) !== 0) {
      return Number.parseFloat(currentTotalCost);
    }
    return convertFifteenPlaces(
      Maths.multiply(
        Number.parseFloat(totalProduct),
        Number.parseFloat(_unitPrice)
      )
    );
  };

  const calcUnitPrice = (totalProduct, totalCostValue) => {
    const result = Maths.divide(
      Number.parseFloat(totalCostValue),
      Number.parseFloat(totalProduct)
    );
    return isFinite(result) ? convertFifteenPlaces(result) : result.toNumber();
  };

  const calcTotalProduct = (
    _unitPrice,
    totalCostValue,
    currentTotalProduct
  ) => {
    if (currentTotalProduct && Number.parseFloat(currentTotalProduct) !== 0) {
      return Number.parseFloat(currentTotalProduct);
    }
    return convertFifteenPlaces(
      Maths.divide(
        Number.parseFloat(totalCostValue),
        Number.parseFloat(_unitPrice)
      )
    );
  };

  const getDisabled = () => {
    if (
      memberRole === 'Full control' &&
      (memberFinancialAccess === 'none' || memberFinancialAccess === 'READ')
    )
      return true;
    return false;
  };

  const displayTotalCostValue = () => {
    if (memberRole === 'Full control' && memberFinancialAccess === 'none') {
      return true;
    }
    if (
      memberRole === 'Full control' &&
      memberFinancialAccess === 'READ' &&
      id === 'invoice'
    ) {
      return true;
    }
    return false;
  };

  const onConfirmHandler = push => {
    const newProduct = {
      ...productToAdd,
      totalQuantityUnit: productToAdd?.totalQuantityUnit,
      timingEvent: values?.timingEvent,
      timingEventTag: values?.timingEventTag,
      totalCostValue: productToAdd?.totalCostValue || 0,
      totalQuantityValue: productToAdd?.totalQuantityValue || 0
    };
    delete newProduct.density;
    if (isEdit) {
      values.invoiceProducts[rowIndex] = newProduct;
    } else {
      push(newProduct);
    }
    handleClose();
  };

  const validateInputs = push => {
    if (productToAdd?.totalCostValue === 0) {
      setOpenConfirmation(true);
    } else {
      onConfirmHandler(push);
    }
  };

  const totalProductValueHandler = e => {
    let newTotal = e.target.value;
    if (e.type === 'blur') {
      setIsEditingTotalProduct(false);
      if (!e.target.value) {
        newTotal = 0;
      }
    }

    // eslint-disable-next-line eqeqeq
    if (newTotal == 0) {
      setFieldError(
        'totalQuantityValue',
        'Cannot save with Total Product of 0'
      );
    } else {
      setFieldError('totalQuantityValue');
    }
    setProductToAdd({
      ...productToAdd,
      totalQuantityValue: !newTotal ? newTotal : convertFifteenPlaces(newTotal),
      totalCostValue:
        calcTotalCost(newTotal, unitPrice, productToAdd?.totalCostValue) || 0
    });
    const newUnitPrice = calcUnitPrice(newTotal, productToAdd?.totalCostValue);
    if (newUnitPrice === Infinity || newUnitPrice < 0 || isNaN(newUnitPrice)) {
      setFieldError('unitPrice', 'Unit price must be positive');
      setUnitPrice(null);
    } else {
      setFieldError('unitPrice');
      setUnitPrice(newUnitPrice || 0);
    }
  };

  const unitHandler = e => {
    setProductToAdd({
      ...productToAdd,
      totalQuantityUnit: JSON.parse(e.target.value).key
    });
  };

  const unitPriceHandler = e => {
    if (e.type === 'blur') {
      setIsEditingUnitPrice(false);
    }
    const newUnitPrice = Number.parseFloat(e.target.value);
    if (!unitPrice || newUnitPrice < 0) {
      setFieldError('unitPrice', 'Unit price must be positive');
      setUnitPrice(null);
      return;
    }

    if (newUnitPrice) {
      setFieldError('unitPrice');
      const newUnitPriceParsed = convertFifteenPlaces(newUnitPrice);
      const newTotalProduct = calcTotalProduct(
        newUnitPriceParsed,
        productToAdd?.totalCostValue,
        productToAdd?.totalQuantityValue
      );
      if (newTotalProduct) {
        setFieldError('totalQuantityValue');
      }
      const newTotalCost = calcTotalCost(
        newTotalProduct,
        newUnitPriceParsed,
        !newTotalProduct ? product?.totalCostValue : undefined
      );

      setProductToAdd({
        ...productToAdd,
        totalCostValue: newTotalCost,
        totalQuantityValue: newTotalProduct
      });
      setUnitPrice(newUnitPriceParsed);
    } else {
      const unitValue = calcUnitPrice(
        productToAdd?.totalQuantityValue,
        productToAdd?.totalCostValue
      );
      setUnitPrice(isNaN(unitValue) ? 0 : unitValue);
    }
  };

  const totalCostHandler = e => {
    let newTotalCost = e.target.value;
    if (e.type === 'blur') {
      setIsEditingTotalCost(false);
      if (!e.target.value) {
        newTotalCost = 0;
      }
    }

    const newUnitPrice = calcUnitPrice(
      productToAdd?.totalQuantityValue,
      newTotalCost
    );
    setProductToAdd({
      ...productToAdd,
      totalCostValue: !newTotalCost
        ? newTotalCost
        : convertFifteenPlaces(newTotalCost)
    });

    if (
      !newUnitPrice ||
      newUnitPrice === Infinity ||
      newUnitPrice < 0 ||
      isNaN(newUnitPrice)
    ) {
      setFieldError('unitPrice', 'Unit price must be positive');
      setUnitPrice(null);
    } else {
      setFieldError('unitPrice');
      setUnitPrice(isNaN(newUnitPrice) ? 0 : newUnitPrice);
    }
  };

  const totalProductValue = useMemo(() => {
    if (productToAdd?.totalQuantityValue === 0) {
      return (0).toFixed(2);
    }
    if (!productToAdd?.totalQuantityValue) {
      return productToAdd?.totalQuantityValue;
    }

    const result = convertFifteenPlaces(productToAdd.totalQuantityValue);

    return isEditingTotalProduct ? result : convertTwoPlaces(result).toFixed(2);
  }, [productToAdd?.totalQuantityValue, isEditingTotalProduct]);

  const totalCostValue = useMemo(() => {
    if (displayTotalCostValue()) {
      return '';
    }

    if (productToAdd?.totalCostValue === 0) {
      return (0).toFixed(2);
    }

    if (!productToAdd?.totalCostValue) {
      return productToAdd?.totalCostValue;
    }
    const totalCostParsed = convertFifteenPlaces(productToAdd.totalCostValue);
    const displayValue = isEditingTotalCost
      ? totalCostParsed
      : convertTwoPlaces(totalCostParsed).toFixed(2);
    return displayValue;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [productToAdd?.totalCostValue, isEditingTotalCost]);

  const unitPriceValue = useMemo(() => {
    if (getDisabled()) {
      return '';
    }
    if (unitPrice === 0) {
      return (0).toFixed(2);
    }
    if (!unitPrice) {
      return unitPrice || '';
    }
    return isEditingUnitPrice
      ? convertFifteenPlaces(unitPrice)
      : convertTwoPlaces(unitPrice).toFixed(2);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [unitPrice, isEditingUnitPrice]);

  return (
    <>
      <FieldArray name="invoiceProducts">
        {({ push }) => (
          <>
            <PortalModal
              open={open}
              close={handleClose}
              onCancel={handleClose}
              confirmLabel="Save"
              onConfirm={() => validateInputs(push)}
              hasError={Object.values(errors)?.length}
            >
              <div className="mb-6">
                <div className="flex w-full justify-between">
                  <div
                    data-testid="invoice-product-name"
                    className="font-bold text-lg"
                  >
                    {productToAdd?.productName}
                  </div>
                </div>
                <div className="flex items-center justify-between">
                  <div className="text-sm text-neutral-300">
                    {productToAdd?.manufacturerName}
                  </div>
                </div>
              </div>
              <div className="mt-12">
                <div className="grid grid-cols-9 gap-3 mb-12">
                  <div className="my-2 mb-6 col-span-2">
                    <GrowUIFormField
                      control={Input}
                      error={!!errors?.totalQuantityValue}
                      errorMessage={errors?.totalQuantityValue}
                      label="Total Product"
                      name="totalQuantityValue"
                      value={totalProductValue}
                      onBlur={e => totalProductValueHandler(e)}
                      onChange={e => totalProductValueHandler(e)}
                      onFocus={() => setIsEditingTotalProduct(true)}
                      type="number"
                      step="any"
                    />
                  </div>
                  <div className="my-2 col-span-2">
                    <GrowUIFormField
                      style={{ whiteSpace: 'nowrap' }}
                      control={Select}
                      label="Unit"
                      name="totalQuantityUnit"
                      value={getCorrectUnitName(
                        productToAdd?.totalQuantityUnit
                      )}
                      items={stdPackageUnit}
                      onChange={e => unitHandler(e)}
                    />
                  </div>
                  <div className="my-2 col-span-2">
                    <GrowUIFormField
                      attachLeft="$"
                      control={Input}
                      disabled={getDisabled()}
                      error={!!errors?.unitPrice}
                      errorMessage={errors?.unitPrice}
                      label="Unit Price"
                      name="unitPrice"
                      onBlur={e => unitPriceHandler(e)}
                      onChange={e => {
                        setUnitPrice(e.target.value);
                      }}
                      onFocus={() => {
                        setIsEditingUnitPrice(true);
                      }}
                      type="number"
                      value={unitPriceValue}
                      step="any"
                    />
                  </div>
                  <div className="my-2 col-span-3">
                    <GrowUIFormField
                      attachLeft="$"
                      control={Input}
                      disabled={getDisabled()}
                      label="Total Cost"
                      name="totalCost"
                      onBlur={e => totalCostHandler(e)}
                      onChange={e => totalCostHandler(e)}
                      onFocus={() => setIsEditingTotalCost(true)}
                      type="number"
                      value={totalCostValue}
                      step="any"
                    />
                  </div>
                </div>
              </div>
              {error && (
                <Toast icon="error" onClose={() => setError(null)}>
                  {error}
                </Toast>
              )}
            </PortalModal>
            <PortalModal
              open={openConfirmation}
              close={() => setOpenConfirmation(false)}
              onCancel={() => setOpenConfirmation(false)}
              confirmLabel="Yes"
              onConfirm={() => {
                onConfirmHandler(push);
                setOpenConfirmation(false);
                onClose();
              }}
              type="confirmation"
              title="You are about to save a Product with a price of $0"
            >
              <p>Are you sure you want to save?</p>
            </PortalModal>
          </>
        )}
      </FieldArray>
    </>
  );
};

AddProductInvoiceModal.defaultProps = {
  open: false,
  isEdit: false,
  rowIndex: null
};

AddProductInvoiceModal.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func.isRequired,
  product: PropTypes.shape({
    productId: PropTypes.string,
    productName: PropTypes.string,
    manufacturerName: PropTypes.string,
    totalCostValue: PropTypes.number,
    totalQuantityValue: PropTypes.number,
    totalQuantityUnit: PropTypes.string,
    custom: PropTypes.bool,
    density: PropTypes.number
  }).isRequired,
  isEdit: PropTypes.bool,
  rowIndex: PropTypes.number,
  memberRole: PropTypes.string.isRequired,
  memberFinancialAccess: PropTypes.string.isRequired
};

export default AddProductInvoiceModal;
