import { cloneDeep, orderBy, isObject, get } from 'lodash';
import { parse } from 'qs';

const applySort = (models, sortType, sortAttributes) => {
  let modelsCopy = models;
  const sortAttributesArray = cloneDeep(sortAttributes);
  const sortTypesArray = [sortType];

  for (let i = 0; i < sortAttributesArray.length; i += 1) {
    sortAttributesArray[i] = `attrs.${sortAttributesArray[i]}`;
    sortTypesArray.push(sortType);
  }

  modelsCopy = orderBy(modelsCopy, sortAttributesArray, sortTypesArray);

  return modelsCopy;
};

const filterResource = (models, filterKey, filterType, filterAttribute) => {
  let modelsCopy = models;

  if (filterType === 'like') {
    modelsCopy = modelsCopy.filter(model => {
      const filterRegexString = filterAttribute.replace(/%/g, '.*');
      // get parses the fully-qualified key name and gets the respective value
      const field = get(model.attrs, filterKey);
      return field.match(new RegExp(filterRegexString, 'i'));
    });
  }

  return modelsCopy;
};

const getSortArray = queryParams => {
  let sortArray = [];
  if (queryParams['sort[0]'] && queryParams['sort[1]']) {
    sortArray = [queryParams['sort[0]'], queryParams['sort[1]']];
  }
  return sortArray;
};

const parsePair = pair => {
  const key = Object.keys(pair)[0];
  return { key, value: pair[key] };
};

const parseParamAtKey = (queryParams, key) => {
  return parse(`${key}=${queryParams[key]}`);
};

// if the query param is structured, drill down into the param and into the respective model before filtering
// parentKeyPath defaults to '', but during drilldown contains the traversed path in dot notation
const paramDrillDown = (models, param, parentKeyPath = '') => {
  const { key, value } = parsePair(param);
  const fullyQualifiedKey = `${parentKeyPath}${key}`;
  if (isObject(value)) {
    return paramDrillDown(models, value, `${fullyQualifiedKey}.`);
  }
  const filterType = value.substring(0, value.indexOf('('));
  const filterAttribute = value.substring(
    value.indexOf('(') + 1,
    value.indexOf(')')
  );
  return filterResource(models, fullyQualifiedKey, filterType, filterAttribute);
};

const applyFetchFilters = (resources, queryParams) => {
  let resourcesCopy = cloneDeep(resources);
  const sort = getSortArray(queryParams);
  if (sort.length) {
    const sortArray = sort;
    const sortType = sortArray[0].substring(0, sortArray[0].indexOf('('));
    const sortAttributes = sortArray.map(sortString => {
      return sortString.substring(
        sortString.indexOf('(') + 1,
        sortString.indexOf(')')
      );
    });

    resourcesCopy.models = applySort(
      resourcesCopy.models,
      sortType,
      sortAttributes
    );
  }

  if (queryParams.limit) {
    const start =
      queryParams.offset === undefined ? 0 : parseInt(queryParams.offset, 10);

    resourcesCopy = resourcesCopy.slice(
      start,
      start + parseInt(queryParams.limit, 10)
    );
  }

  for (let i = 0; i < Object.keys(queryParams).length; i += 1) {
    const currentKey = Object.keys(queryParams)[i];

    const param = parseParamAtKey(queryParams, currentKey);
    const { key: resourceName } = parsePair(param);
    // Checks for resource filter defined
    if (
      resourceName in resourcesCopy.models[0].attrs &&
      resourceName !== 'active'
    ) {
      resourcesCopy.models = paramDrillDown(resourcesCopy.models, param);
    }
  }

  return resourcesCopy;
};

export default applyFetchFilters;
