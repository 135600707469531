import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { ReactComponent as YieldByIcon } from 'assets/yield_by_icon.svg';
import { Button } from '@agconnections/grow-ui';
import { useHistory } from 'react-router-dom';
import { paths } from 'routes/paths';
import {
  MEASUREMENT_UNITS,
  convertUnit,
  getAbbreviatedUnit
} from 'helpers/unitsAbbreviation';
import { getBushelWeight } from 'helpers/getBushelWeight';
import SummaryCard from '../SummaryCard';
import useTotalYield from '../../hooks/useTotalYield';

const TotalYieldHeader = ({ cropId }) => {
  const history = useHistory();
  const seeLoads = () => {
    history.push(`${paths.yieldV2}/loads?cropsFilters=${cropId}`);
  };

  return (
    <div className="flex items-center justify-between px-4 h-full">
      <div className="flex items-center gap-2">
        <YieldByIcon className="text-neutral-600" />
        <span className="text-base leading-6 whitespace-no-wrap">
          Total Yield
        </span>
      </div>
      <div className="flex items-center">
        <Button
          onClick={seeLoads}
          type="outline"
          size="md"
          dataTestId="see-loads-btn"
        >
          See Loads
        </Button>
      </div>
    </div>
  );
};

const TotalYieldContent = ({
  selectedUOM,
  totalQuantity,
  area,
  selectedCropName
}) => {
  const convertedTotalQuantity = useMemo(() => {
    if (selectedUOM === MEASUREMENT_UNITS.BUSHEL) {
      const poundsPerBushel = getBushelWeight(selectedCropName);
      return totalQuantity / poundsPerBushel;
    }
    return convertUnit(totalQuantity, MEASUREMENT_UNITS.POUND, selectedUOM);
  }, [totalQuantity, selectedUOM, selectedCropName]);

  const convertedAvgQuantity = useMemo(() => {
    // Calculate average quantity based on the area
    if (!area || !totalQuantity) {
      return 0;
    }
    const avgQuantity = totalQuantity / area;
    if (selectedUOM === MEASUREMENT_UNITS.BUSHEL) {
      const poundsPerBushel = getBushelWeight(selectedCropName);
      return avgQuantity / poundsPerBushel; // Convert avgQuantity to bushels per acre
    }
    return convertUnit(avgQuantity, MEASUREMENT_UNITS.POUND, selectedUOM); // Convert avgQuantity to the selected unit per acre
  }, [totalQuantity, area, selectedUOM, selectedCropName]);

  const formattedTotalQuantity = convertedTotalQuantity.toLocaleString(
    'en-US',
    {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2
    }
  );

  const formattedAvgQuantity = convertedAvgQuantity.toLocaleString('en-US', {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2
  });

  if (!selectedCropName || !selectedUOM) {
    return null;
  }

  return (
    <div className="py-4 px-8 flex items-stretch gap-6">
      <div>
        <p className="text-neutral-600 whitespace-no-wrap">Total quantity</p>
        <p className="text-3xl leading-7 font-semibold whitespace-no-wrap">
          {formattedTotalQuantity}
          <span className="ml-2px text-sm font-normal">
            {getAbbreviatedUnit(selectedUOM)}
          </span>
        </p>
      </div>
      <div className="w-1px bg-neutral-20 self-stretch" />
      <div>
        <p className="text-neutral-600 whitespace-no-wrap">Avg. quantity</p>
        <p className="text-3xl leading-7 font-semibold whitespace-no-wrap">
          {formattedAvgQuantity}
          <span className="ml-2px text-sm font-normal">
            {getAbbreviatedUnit(selectedUOM)} / ac
          </span>
        </p>
      </div>
      <div className="w-1px bg-neutral-20 self-stretch" />
      <div>
        <p className="text-neutral-600">Area</p>
        <p className="text-3xl leading-7 font-semibold whitespace-no-wrap">
          {area ? area.toFixed(2) : '0.00'}
          <span className="ml-2px text-sm font-normal">ac</span>
        </p>
      </div>
    </div>
  );
};

const TotalYieldFooter = ({ cropZonesHarvested }) => (
  <div className="flex pr-4 items-center justify-end h-full whitespace-no-wrap">
    <span className="text-neutral-1000 mr-3">Total boundaries harvested</span>
    <span className="text-xl font-semibold mr-1">{cropZonesHarvested}</span>
    <span className="text-neutral-1000">Crop Zones</span>
  </div>
);

const TotalYield = ({
  selectedUOM,
  className,
  cropSeasonId,
  cropId,
  selectedCropName
}) => {
  const { totalYieldData, isLoading } = useTotalYield({ cropSeasonId, cropId });
  const { totalQuantity, avgQuantity, area, cropZonesHarvested } =
    totalYieldData || {};

  if (!selectedUOM) {
    return null;
  }

  return (
    <SummaryCard
      header={<TotalYieldHeader cropId={cropId} />}
      content={
        <TotalYieldContent
          selectedUOM={selectedUOM}
          totalQuantity={totalQuantity}
          avgQuantity={avgQuantity}
          area={area}
          selectedCropName={selectedCropName}
        />
      }
      footer={<TotalYieldFooter cropZonesHarvested={cropZonesHarvested} />}
      className={className}
      isLoading={isLoading}
    />
  );
};

TotalYield.propTypes = {
  selectedUOM: PropTypes.string.isRequired,
  className: PropTypes.string,
  selectedCropName: PropTypes.string.isRequired,
  cropSeasonId: PropTypes.string,
  cropId: PropTypes.string
};

TotalYield.defaultProps = {
  className: '',
  cropSeasonId: null,
  cropId: null
};

TotalYieldHeader.propTypes = {
  cropId: PropTypes.string.isRequired
};

TotalYieldFooter.propTypes = {
  cropZonesHarvested: PropTypes.number
};

TotalYieldFooter.defaultProps = {
  cropZonesHarvested: null
};

TotalYieldContent.propTypes = {
  selectedUOM: PropTypes.string.isRequired,
  totalQuantity: PropTypes.number,
  area: PropTypes.number,
  selectedCropName: PropTypes.string
};

TotalYieldContent.defaultProps = {
  totalQuantity: null,
  area: null,
  selectedCropName: null
};

export default TotalYield;
