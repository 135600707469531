import React from 'react';

const PencilEditIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="19"
      height="19"
      viewBox="0 0 19 19"
      fill="none"
      data-testid="pencil-edit-icon"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14.659 0C14.409 0 14.149 0.1 13.959 0.29L12.129 2.12L15.879 5.87L17.709 4.04C18.099 3.65 18.099 3.02 17.709 2.63L15.369 0.29C15.169 0.09 14.919 0 14.659 0ZM11.059 6.02L11.979 6.94L2.91902 16H1.99902V15.08L11.059 6.02ZM-0.000976562 14.2514L11.059 3.19141L14.809 6.94141L3.74902 18.0014H-0.000976562V14.2514Z"
        fill="#696F88"
      />
    </svg>
  );
};

export default PencilEditIcon;
