import React, { useContext, useEffect, useState } from 'react';
import { useFormikContext } from 'formik';
import { Select } from '@agconnections/grow-ui';
import { Switch, Input } from 'syngenta-digital-cropwise-react-ui-kit';
import useDebounce from 'hooks/useDebounce';
import useSubmissionAnalytics from 'screens/Integrations/hooks/useSubmissionAnalytics';
import {
  AVAILABLE_SEASONS,
  INTEGRATIONS
} from 'screens/Integrations/helpers/constants';
import { getFieldSortDir, pickDir } from 'screens/Plans/helpers/columnHelpers';
import GrowUIFormField from 'components/Forms/GrowUIFormField';
import Editor from 'screens/Property/PropertiesLanding/components/Editor';
import CropSeasonSingleSelectionDropdown from 'components/CropSeasonSingleSelectionDropdown';
import SearchIcon from 'assets/search.svg';
import CwFPagination from 'components/CwFPagination';
import useFormikHandler from 'screens/Integrations/hooks/useFormikHandler';
import useAgrianApi from 'hooks/useAgrianApi';
import AgrianSubmissionFooter from '../../../components/AgrianSubmissionFooter';
import { AgrianContext } from '../../../context/Provider';
import AgrianRecommendationTable from './components/AgrianRecommendationTable';

import './index.css';

const AgrianRecommendationList = () => {
  const [searchText, setSearchText] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const [totalElements, setTotalElements] = useState(0);
  const [pageSize] = useState(25);
  const [sortBy, setSortBy] = useState();
  const [sortDir, setSortDir] = useState();
  const [includePreviousSubmitted, setIncludePreviousSubmitted] = useState(
    true
  );
  const [filteredRecommendations, setFilteredRecommendations] = useState([]);
  const [
    isCropYearAndCropSeasonSelected,
    setIsCropYearAndCropSeasonSelected
  ] = useState(false);

  const { events, triggerAnalyticsEvent } = useSubmissionAnalytics();
  const { goForward, releaseStepForward } = useContext(AgrianContext);

  const {
    loading: isLoading,
    getAgrianRecommendationsBySeasonWithoutCache
  } = useAgrianApi();

  const { values, setFieldValue } = useFormikContext();
  const {
    selectedCropSeasons,
    setSelectedCropSeasons,
    selectedRecommendations,
    setSelectedRecommendations
  } = useFormikHandler();

  const timeDelay = 300;
  const debouncedSearchTerm = useDebounce(searchText, timeDelay);

  useEffect(() => {
    async function listRecommendations() {
      if (values.agrianCropYear && selectedCropSeasons?.length > 0) {
        triggerAnalyticsEvent(
          events.epic.Integrations.importRecommendationSearched,
          {
            vendor: INTEGRATIONS.agrianRecommendation
          }
        );
        const response = await getAgrianRecommendationsBySeasonWithoutCache({
          cwfSeasonId: selectedCropSeasons[0]?.id,
          displayPrevious: includePreviousSubmitted,
          query: debouncedSearchTerm,
          sortDir: sortDir?.toUpperCase(),
          sortBy,
          season: values.agrianCropYear,
          page: currentPage,
          limit: pageSize
        });
        if (response) {
          releaseStepForward();
          setSelectedRecommendations([]);
          setFieldValue('recommendations', response.data);
        }
      }
    }
    listRecommendations();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    values.agrianCropYear,
    selectedCropSeasons,
    includePreviousSubmitted,
    sortBy,
    sortDir,
    debouncedSearchTerm,
    currentPage
  ]);

  useEffect(() => {
    setIsCropYearAndCropSeasonSelected(
      values.agrianCropYear && selectedCropSeasons?.length > 0
    );
  }, [values.agrianCropYear, selectedCropSeasons]);

  useEffect(() => {
    if (values.recommendations) {
      const { data: responseData, elements } = values.recommendations;

      setTotalElements(elements);
      setFilteredRecommendations(responseData);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values.recommendations]);

  const handleSelectRecommendation = recommendation => {
    if (!selectedRecommendations?.length > 0) {
      return setSelectedRecommendations([recommendation]);
    }

    const notMatchRecommendations = selectedRecommendations?.filter(
      ({ id }) => id !== recommendation.id
    );

    if (notMatchRecommendations.length !== selectedRecommendations?.length) {
      return setSelectedRecommendations(notMatchRecommendations);
    }

    return setSelectedRecommendations(
      [recommendation].concat(selectedRecommendations)
    );
  };

  const handleCropSeasonSelect = seasonSelected => {
    setSelectedCropSeasons([seasonSelected]);
  };

  const onNextHandler = async () => {
    triggerAnalyticsEvent(
      events.epic.Integrations.importRecommendationSelected,
      {
        vendor: INTEGRATIONS.agrianRecommendation
      }
    );
    goForward();
  };

  const handleFilter = searchValue => {
    setSearchText(searchValue);
  };

  const handleSort = field => () => {
    setSortBy(field);
    setSortDir(pickDir(getFieldSortDir(field, sortBy, sortDir)));
  };

  return (
    <>
      <div className="w-full mx-6">
        <div
          className="flex flex-col bg-white top-30 left-26 rounded-md p-6 shadow-sm"
          data-testid="submission-recommendation-selection"
        >
          <div className="mb-4">
            <div className="flex flex-col">
              <div className="text-lg leading-7 font-semibold text-neutral-1000 mb-2">
                Select Records
              </div>
              <p className="text-sm leading-6 text-neutral-70">
                Select your Agrian crop year and the corresponding crop season
                to see Agrian recommendations for import.
              </p>
            </div>
            <div className="grid grid-cols-2 gap-x-2 text-sm">
              <Editor label="Agrian Crop Year*" halfWidth>
                <div className="mt-1">
                  <GrowUIFormField
                    control={Select}
                    id="agrianCropYear"
                    name="agrianCropYear"
                    items={AVAILABLE_SEASONS}
                  />
                </div>
              </Editor>
              <Editor label="Cropwise Financials Crop Season*" halfWidth>
                <div className="text-sm">
                  <CropSeasonSingleSelectionDropdown
                    id="cwfCropSeason"
                    value={selectedCropSeasons ? selectedCropSeasons[0] : null}
                    onChange={value => {
                      handleCropSeasonSelect(value);
                    }}
                  />
                </div>
              </Editor>
            </div>
          </div>
        </div>
        <div className="flex flex-row mt-4 mb-1 items-center">
          <div className="flex flex-row">
            <div className="mr-4 relative inline-block min-w-360px base-drop-down-search-input">
              <div className="prefix-icon">
                <img alt="Search icon" src={SearchIcon} />
              </div>
              <Input
                id="label-search-input"
                placeholder="Search names of records, fields or products"
                allowClear
                disabled={!isCropYearAndCropSeasonSelected}
                type="default"
                autoComplete="off"
                onChange={e => handleFilter(e.target.value)}
              />
            </div>

            <div className="my-auto">
              <Switch
                size="medium"
                checked={
                  isCropYearAndCropSeasonSelected && includePreviousSubmitted
                }
                onChange={newValue => {
                  setIncludePreviousSubmitted(newValue);
                }}
              />
              <span
                className={`ml-2 ${(!includePreviousSubmitted ||
                  !isCropYearAndCropSeasonSelected) &&
                  'text-gray-500'} `}
              >
                Display previously imported recommendations
              </span>
            </div>
          </div>
          <div className="w-40 ml-auto text-right">
            <span
              className={`${
                selectedRecommendations?.length > 0
                  ? 'text-blue-50'
                  : 'text-gray-500'
              } font-bold`}
            >
              {`${selectedRecommendations?.length ?? 0} ${
                selectedRecommendations?.length > 1 ? 'records' : 'record'
              } selected`}
            </span>
          </div>
        </div>
        <div
          className="flex flex-col bg-white top-30 left-26 rounded-md mb-24 shadow-sm"
          data-testid="submission-recommendation-table"
        >
          <AgrianRecommendationTable
            handleSort={handleSort}
            sortBy={sortBy}
            sortDir={sortDir}
            filteredRecommendations={filteredRecommendations}
            handleSelection={handleSelectRecommendation}
            selectedRecommendations={selectedRecommendations}
            isLoading={isLoading}
            isCropYearAndCropSeasonSelected={isCropYearAndCropSeasonSelected}
            PaginationComponent={() =>
              CwFPagination(
                totalElements,
                currentPage - 1,
                page => setCurrentPage(page + 1),
                null,
                pageSize
              )
            }
          />
        </div>
      </div>
      <AgrianSubmissionFooter
        onNext={onNextHandler}
        loading={isLoading}
        nextLabel="Next: Match Properties"
        nextDisabled={
          !selectedRecommendations?.length > 0 ||
          !selectedCropSeasons?.length > 0 ||
          !values?.agrianCropYear
        }
        integrationType={INTEGRATIONS.agrianRecommendation}
        isBottomFixed
      />
    </>
  );
};

export default AgrianRecommendationList;
