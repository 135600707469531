import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Button, Input, Form } from '@agconnections/grow-ui';
import { useFormikContext } from 'formik';
import GrowUIFormField from 'components/Forms/GrowUIFormField';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck, faChevronLeft } from '@fortawesome/free-solid-svg-icons';
import { Colors } from '../helpers/constants';

const TagsCreate = ({
  backToLanding,
  selectedTag,
  resetForm,
  checkForDups,
  disableCreateBtn,
  handleSubmit,
  handleDelete
}) => {
  const [selectedColor, setSelectedColor] = useState(null);
  const { setFieldValue } = useFormikContext();
  const [formLabel, setFormLabel] = useState('Create Tag');
  const [colorNotSelected, setColorNotSelected] = useState(true);
  const [doesNotHaveName, setDoesNotHaveName] = useState(true);

  const setNameValue = name => {
    setDoesNotHaveName(name === '');
    setFieldValue('tagName', name);
    checkForDups(name, selectedTag?.id);
  };

  const setColorValue = color => {
    setFieldValue('color', color);
    setSelectedColor(color);
    setColorNotSelected(false);
  };

  const isDiabled = () => {
    return disableCreateBtn || colorNotSelected || doesNotHaveName;
  };

  useEffect(() => {
    if (selectedTag) {
      setSelectedColor(selectedTag.color);
      setColorNotSelected(false);
      setFieldValue('tagName', selectedTag.name);
      setFieldValue('id', selectedTag.id);
      setFieldValue('color', selectedTag.color);
      setFormLabel('Edit Tag');
      setDoesNotHaveName(selectedTag.name === '');
    }
  }, [selectedTag, setFieldValue]);

  return (
    <div data-testid="create-tag-modal">
      <div className="flex w-full mb-12">
        <Button
          data-testid="back-button"
          id="back-to-landing"
          type="cancel"
          style={{ width: '100%' }}
          onClick={() => backToLanding(resetForm)}
        >
          <FontAwesomeIcon
            icon={faChevronLeft}
            size="2x"
            style={{
              visibility: 'visible'
            }}
          />
          <div
            data-testid="back-button-label"
            className="text-xl align-middle font-bold ml-4 w-full text-left"
          >
            {formLabel}
          </div>
        </Button>
      </div>
      <Form.Field>
        <GrowUIFormField
          name="tagName"
          label="Tag Name"
          control={Input}
          onChange={e => setNameValue(e.target.value)}
        />
      </Form.Field>
      <div className="-mt-3 mb-5">Tag name should be unique</div>
      <div className="mb-2">Select Color</div>
      <div className="flex justify-between text-white mb-5">
        {Colors.map(color => {
          const isSelected = color.hex === selectedColor;
          return (
            <button
              data-testid="color-button"
              className="w-8 h-8 border rounded-full"
              type="button"
              style={{
                backgroundColor: color.hex
              }}
              onClick={() => setColorValue(color.hex)}
            >
              <FontAwesomeIcon
                icon={faCheck}
                size="1x"
                style={{
                  visibility: isSelected ? 'visible' : 'hidden',
                  color: '#FFFFFF'
                }}
              />
            </button>
          );
        })}
      </div>
      <div className="border mt-2 mb-5" />
      {!selectedTag ? (
        <Button
          id="create-tag-button"
          type="primary"
          style={{ width: '100%' }}
          onClick={handleSubmit}
          disabled={isDiabled()}
        >
          Create
        </Button>
      ) : (
        <div className="flex">
          <button
            data-testid="delete-button"
            className="border border-solid rounded m-2 p-2 w-full mr-8"
            style={{
              backgroundColor: '#CF3537',
              color: '#FFFFFF'
            }}
            id="delete-tag-button"
            type="button"
            onClick={handleDelete}
          >
            Delete
          </button>
          <button
            data-testid="save-button"
            className={`border border-solid rounded m-2 p-2 w-full ml-8 ${
              isDiabled() ? 'cursor-not-allowed' : ''
            }`}
            style={{
              backgroundColor: isDiabled() ? '#f3f4f6' : '#14803C',
              color: '#FFFFFF'
            }}
            onClick={handleSubmit}
            id="save-tag-button"
            type="button"
            disabled={isDiabled()}
          >
            Save
          </button>
        </div>
      )}
    </div>
  );
};

TagsCreate.defaultProps = {
  selectedTag: null
};

TagsCreate.propTypes = {
  backToLanding: PropTypes.func.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  selectedTag: PropTypes.object,
  resetForm: PropTypes.func.isRequired,
  checkForDups: PropTypes.func.isRequired,
  disableCreateBtn: PropTypes.bool.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  handleDelete: PropTypes.func.isRequired
};

export default TagsCreate;
