import React, { useContext, useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { Context } from 'components/Store';
import { CheckOutlined } from '@material-ui/icons';
import { Select, Spin } from 'syngenta-digital-cropwise-react-ui-kit';
import CropIconMapping from 'components/CropIconMapping';
import ChevronRight from 'assets/chevron_right.svg';
import getFilteredCropsWithinCropSeasons from 'screens/Property/PropertiesLanding/helpers/getFilteredCropsWithinCropSeasons';
import getFilteredCropsWithinField from 'screens/Property/PropertiesLanding/helpers/getFilteredCropsWithinField';
import './index.css';

export default function FilterByCropsSelect({ selectedCrop, setSelectedCrop }) {
  const [
    { organization, cropSeasons, isGlobalCropSeasonLoading, selectedProperty }
  ] = useContext(Context);

  const [cropsSelectOpen, setCropsSelectOpen] = useState(false);

  const showSingleCrop = () => {
    const isCropZone = selectedProperty.type === 'Crop Zone';
    if (!selectedProperty.cropzones) return isCropZone;

    const allCropIds = selectedProperty.cropzones.map(({ crop: { id } }) => id);
    const uniqueCropIds = [...new Set(allCropIds)];
    return isCropZone || uniqueCropIds.length === 1;
  };

  useEffect(() => {
    setSelectedCrop(null);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [organization?.id, selectedProperty?.id]);

  const crops = useMemo(() => {
    if (showSingleCrop()) return [];
    if (isGlobalCropSeasonLoading) return [];
    const selectedCropSeasons =
      JSON.parse(localStorage.getItem('selectedCropSeasons')) || [];
    return getFilteredCropsWithinField(
      getFilteredCropsWithinCropSeasons(cropSeasons, selectedCropSeasons),
      selectedProperty
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cropSeasons, selectedProperty, organization?.id]);

  const renderSelectedIcon = option => {
    if (!cropsSelectOpen) return null;
    if (selectedCrop === option || (!selectedCrop && !option)) {
      return (
        <div className="selected-icon w-30px">
          <CheckOutlined />
        </div>
      );
    }
    return <span className="selected-icon inline-block w-30px" />;
  };

  if (showSingleCrop()) {
    const crop = selectedProperty.crop ?? selectedProperty.cropzones[0].crop;
    return (
      <div className="flex align-items-center">
        <CropIconMapping cropObject={crop} size={18} />
        <div className="ml-1">{crop.name}</div>
      </div>
    );
  }

  const getDropdownRender = menu => <div>{menu}</div>;

  return (
    <div className="crop-select-container whitespace-nowrap inline-block">
      <Select
        defaultActiveFirstOption
        data-testid="filter-by-crops-select"
        id="type-filter-select"
        placeholder="Filter by crop"
        value={selectedCrop}
        onChange={setSelectedCrop}
        defaultValue="all"
        notFoundContent={
          isGlobalCropSeasonLoading ? (
            <Spin size="small" data-testid="loading-spinner" />
          ) : (
            'No data'
          )
        }
        listHeight={340}
        suffixIcon={
          <img
            src={ChevronRight}
            style={{
              transform: cropsSelectOpen ? 'rotate(270deg)' : null
            }}
            height={18}
            width={18}
            alt="Select dropdown icon"
          />
        }
        popupClassName="property-crop-selection-dropdown"
        open={cropsSelectOpen}
        onDropdownVisibleChange={setCropsSelectOpen}
        dropdownRender={getDropdownRender}
        placement="bottomRight"
      >
        {crops.length > 0 && (
          <Select.Option>
            <div className="flex align-items-center">
              {renderSelectedIcon()}
              <span>All Crops</span>
            </div>
          </Select.Option>
        )}
        {crops.map(item => (
          <Select.Option key={item.id} value={item.id}>
            <div className="flex align-items-center">
              {renderSelectedIcon(item.id)}
              <CropIconMapping cropObject={item} size={16} />
              <div className="ml-2">{item.name}</div>
            </div>
          </Select.Option>
        ))}
      </Select>
    </div>
  );
}

FilterByCropsSelect.defaultProps = {
  selectedCrop: null,
  setSelectedCrop: () => {}
};

FilterByCropsSelect.propTypes = {
  selectedCrop: PropTypes.string,
  setSelectedCrop: PropTypes.func
};
