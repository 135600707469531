import PropTypes from 'prop-types';

export const TGeometry = PropTypes.shape({
  type: PropTypes.string.isRequired,
  coordinates: PropTypes.arrayOf(
    PropTypes.arrayOf(PropTypes.arrayOf(PropTypes.number.isRequired))
  ).isRequired
});

export const TCrop = PropTypes.shape({
  id: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired
});

export const TCropSeason = PropTypes.shape({
  status: PropTypes.number.isRequired,
  message: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  crops: PropTypes.arrayOf(TCrop).isRequired,
  currency: PropTypes.string.isRequired,
  active: PropTypes.bool.isRequired
});

export const TCropzone = PropTypes.shape({
  id: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  reportedArea: PropTypes.number.isRequired,
  boundaryArea: PropTypes.number.isRequired,
  geometry: TGeometry.isRequired,
  cropSeasons: PropTypes.arrayOf(TCropSeason).isRequired,
  crop: TCrop.isRequired,
  archived: PropTypes.bool
});

export const TField = PropTypes.shape({
  id: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  geometry: TGeometry.isRequired,
  cropzones: PropTypes.arrayOf(TCropzone).isRequired,
  boundaryArea: PropTypes.number.isRequired,
  reportedArea: PropTypes.number.isRequired,
  archived: PropTypes.bool
});

export const TProperty = PropTypes.shape({
  status: PropTypes.number.isRequired,
  message: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  fields: PropTypes.arrayOf(TField).isRequired,
  archived: PropTypes.bool,
  selectedCropSeason: PropTypes.arrayOf(PropTypes.string.isRequired)
});

export const TGeoJSONFeature = PropTypes.shape({
  type: PropTypes.string,
  geometry: TGeometry,
  // eslint-disable-next-line react/forbid-prop-types
  properties: PropTypes.object
});

export const TGeoJSONFeatureCollection = PropTypes.shape({
  type: PropTypes.string,
  features: PropTypes.arrayOf(TGeoJSONFeature).isRequired
});
