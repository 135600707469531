import React from 'react';
import PropTypes from 'prop-types';
import { Spinner } from '@agconnections/grow-ui';

const FullHeightSpinner = ({ text }) => (
  <div
    className="flex items-center justify-center flex-col gap-8"
    style={{ height: 'calc(100vh - 100px)' }}
  >
    <div>
      <Spinner />
    </div>
    <p className="text-base font-semibold tracking-tight">{text}</p>
  </div>
);

FullHeightSpinner.propTypes = {
  text: PropTypes.string
};

FullHeightSpinner.defaultProps = {
  text: ''
};

export default FullHeightSpinner;
