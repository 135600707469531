import { Maths } from 'helpers/maths';
import { YIELD_DEFAULT_ROUND } from 'screens/YieldV2/constants';
import sanitizeAndConvertToNumber from './sanitizeAndConvertToNumber';

/**
 * Calculates the gross revenue based on the given load and sale price.
 *
 * @param {Object} params - The parameters for calculating gross revenue.
 * @param {number} params.load - The load value.
 * @param {number} params.salePrice - The sale price value.
 * @returns {number|null} The calculated gross revenue or null if either load or salePrice is not provided.
 */
const calculateGrossRevenue = ({ load, salePrice }) => {
  if (load === 0 || salePrice === 0) return 0;
  if (!load || !salePrice) return null;
  const sanitizedSalePrice = sanitizeAndConvertToNumber(salePrice);
  const sanitizedLoad = sanitizeAndConvertToNumber(load);
  if (sanitizedSalePrice === null || sanitizedLoad === null) return null;
  return Maths.multiplyRound(
    sanitizedSalePrice,
    sanitizedLoad,
    YIELD_DEFAULT_ROUND
  );
};

export default calculateGrossRevenue;
