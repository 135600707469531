import React from 'react';
import PropTypes from 'prop-types';

import { useFormikContext } from 'formik';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faTools,
  faBatteryFull,
  faEye
} from '@fortawesome/free-solid-svg-icons';

import UserRoleBaseButton from './components/UserRoleBaseButton';

const descriptions = {
  Author:
    'Can modify data directly associated with the user either by authorship or assignment.',
  FullControl:
    'Grants full control over all organization record management and reporting. Cannot manipulate organization profile details. This user type can view, but not manage other member permissions or invite other users.',
  Admin:
    'Grants administrative control over all organizational data. This user type can manage other member permissions, including inviting other users.',
  ViewOnly:
    'Grants read-only access to all organization records and reporting. This user cannot view or manage other member permissions.'
};

const UserRole = ({ field: { value, name, onChange }, singleMember }) => {
  const description = descriptions[value] || '';
  const { setFieldValue } = useFormikContext();

  const selectRole = role => {
    // set defaults for financialAccess information
    if (role === 'Admin') setFieldValue('financialAccess', 'WRITE');
    else if (role === 'ViewOnly') setFieldValue('financialAccess', null);
    else if (role === 'FullControl') {
      setFieldValue('financialAccess', null);
    }
    // fake event matches expected Formik format
    const event = { target: { value: role, name } };
    onChange(event);
  };

  return (
    <div data-testid="userRole">
      <p className="text-lg font-medium text-syngenta-navy">User Role</p>
      <p className="mb-6 font-medium text-gray-600">
        Control what the members of your organization have access to.
      </p>
      <div style={{ justifyContent: 'space-between' }} className="flex">
        <UserRoleBaseButton
          id="admin"
          display="Admin"
          selected={value === 'Admin'}
          onClick={() => selectRole('Admin')}
          icon={<FontAwesomeIcon size="2x" icon={faTools} />}
          disabled={singleMember?.role === 'Full control'}
        />
        <UserRoleBaseButton
          id="fullControl"
          display="Full Control"
          selected={value === 'FullControl'}
          onClick={() => selectRole('FullControl')}
          icon={<FontAwesomeIcon size="2x" icon={faBatteryFull} />}
        />
        <UserRoleBaseButton
          id="viewOnly"
          display="View Only"
          selected={value === 'ViewOnly'}
          onClick={() => selectRole('ViewOnly')}
          icon={<FontAwesomeIcon size="2x" icon={faEye} />}
          disabled={
            singleMember?.role === 'Full control' &&
            singleMember?.financialAccess === 'READ'
          }
        />
      </div>
      <div
        data-testid="userRoleText"
        className="h-10 mt-6 mb-10 text-sm text-center text-gray-600"
      >
        {description}
      </div>
    </div>
  );
};

UserRole.propTypes = {
  field: PropTypes.shape({
    value: PropTypes.string,
    name: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired
  }).isRequired,
  singleMember: PropTypes.instanceOf(Object).isRequired
};

export default UserRole;
