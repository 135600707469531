import React, { useContext, useEffect, useState } from 'react';

import { useToast } from 'components/ToastContainer';
import useFormikHandler from 'screens/Integrations/hooks/useFormikHandler';
import AgrianSubmissionFooter from 'screens/Integrations/AgrianIntegration/components/AgrianSubmissionFooter';
import { INTEGRATIONS } from 'screens/Integrations/helpers/constants';
import useSubmissionAnalytics from 'screens/Integrations/hooks/useSubmissionAnalytics';
import StepHeader from 'screens/Integrations/components/StepHeader';
import { AgrianContext } from '../../../context/Provider';
import ProductList from './components/ProductList';
import { ProductsMatchContext } from './context/ProductsMatchProvider';

const AgrianMatchProducts = () => {
  const { events, triggerAnalyticsEvent } = useSubmissionAnalytics();
  const { goForward } = useContext(AgrianContext);
  const [unmatchedProducts, setUnmatchedProducts] = useState([]);
  const [productsToMatch, setProductsToMatch] = useState([]);

  const {
    state: { matches },
    isLoading,
    fetchMatches,
    submitMatches,
    updateMatches
  } = useContext(ProductsMatchContext);

  const { selectedRecommendations } = useFormikHandler();

  const toast = useToast();

  useEffect(() => {
    if (!isLoading) {
      fetchMatches();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const products = selectedRecommendations.flatMap(r => r.products);
    setProductsToMatch(
      products.reduce((acc, cur) => {
        if (acc.findIndex(p => p.productId === cur.productId) === -1) {
          acc.push(cur);
        }
        return acc;
      }, [])
    );
  }, [selectedRecommendations]);

  useEffect(() => {
    setUnmatchedProducts(
      productsToMatch.filter(
        p => matches.findIndex(m => m.agrianEntityId === p.productId) === -1
      )
    );
  }, [matches, productsToMatch]);

  const onNextHandler = async () => {
    let canSubmit = true;
    const cwfIds = matches
      .filter(
        m =>
          productsToMatch.findIndex(p => p.productId === m.agrianEntityId) >= 0
      )
      .map(match => match.cwfEntityId);
    const uniqueCwfIds = [...new Set(cwfIds)];
    const checkDuplicates = uniqueCwfIds.map(value => [
      value,
      cwfIds.filter(id => id === value).length
    ]);
    checkDuplicates.forEach(check => {
      if (check[1] > 1) {
        canSubmit = false;
      }
    });

    if (canSubmit) {
      triggerAnalyticsEvent(
        events.epic.Integrations.importRecommendationProductsMatch,
        {
          vendor: INTEGRATIONS.agrianRecommendation
        }
      );
      await submitMatches();
      goForward();
    } else {
      toast.error('Unable to create product matching', {
        supportButton: true,
        content:
          'The same Cropwise Product is selected for multiple products. Please choose different Cropwise Product for each product.'
      });
    }
  };

  const handleSelectMatch = (agrianProduct, selectedProduct) => {
    if (!selectedProduct) {
      return updateMatches([
        ...matches.filter(m => m.agrianEntityId !== agrianProduct.productId)
      ]);
    }

    const match = {
      agrianEntityDescription: agrianProduct.productName,
      agrianEntityId: agrianProduct.productId,
      cwfEntityId: selectedProduct.id,
      type: 'Product'
    };
    const nonAffectedProducts = matches.filter(
      m => m.agrianEntityId !== agrianProduct.productId
    );
    if (nonAffectedProducts.length !== matches.length) {
      return updateMatches([...nonAffectedProducts, match]);
    }
    return updateMatches([...matches, match]);
  };

  return (
    <>
      <div
        className="w-84/100 bg-white flex flex-col px-6 py-4 rounded-xl font-body mb-mtcm"
        data-testid="properties-match-view"
      >
        <div
          className="flex flex-col w-full mb-3"
          id="properties-match-top-header"
        >
          <StepHeader
            title="Match Products"
            description="Match products from your Agrian recommendations to products on the Cropwise Financials product list."
            isLoading={isLoading}
            isMatchCountVisible
            unmatchCount={unmatchedProducts.length}
          />
        </div>
        <div className="w-full mb-5 overflow-y-auto">
          {!isLoading && (
            <ProductList
              products={productsToMatch}
              matches={matches}
              onSelectMatch={handleSelectMatch}
              unmatchedProducts={unmatchedProducts}
            />
          )}
        </div>
      </div>
      <AgrianSubmissionFooter
        onNext={onNextHandler}
        loading={isLoading}
        nextLabel="Next: Match Products"
        nextDisabled={isLoading || unmatchedProducts.length > 0}
        integrationType={INTEGRATIONS.agrianRecommendation}
        isBottomFixed
      />
    </>
  );
};

export default AgrianMatchProducts;
