/* eslint-disable no-extra-boolean-cast */
import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { DatePicker, Input, Select } from '@agconnections/grow-ui';
import CwFPagination from 'components/CwFPagination';
import useCropSeasons from 'hooks/useCropSeasons';
import useDebounce from 'hooks/useDebounce';
import PAGINATION_SIZE from 'helpers/globalConstants';

const Filtering = ({
  children,
  setRowData,
  rowData,
  onFilter,
  pageClick,
  totalRecordCount,
  pageNumber
}) => {
  const { cropSeasons: cropSeasonsArray } = useCropSeasons();
  const [searchText, setSearchText] = useState(null);
  const debouncedSearchTerm = useDebounce(searchText, 300);

  const [dateRange, setDateRange] = useState([]);
  const [cropSeasons, setCropSeasons] = useState(null);
  const [tableData, setTableData] = useState(null);
  const [cropSeasonItems, setCropSeasonItems] = useState([]);

  const pageNumberRef = useRef(null);

  const handleFilterSelect = (_, __, e) => {
    const selectedCropSeasons = e.map(({ key }) => key).join(',');

    setCropSeasons(selectedCropSeasons);
  };

  const handleFilterDate = e => {
    if (e.target.value?.length === 2) {
      setDateRange(e.target.value);
    } else {
      setDateRange([]);
    }
  };

  useEffect(() => {
    if (cropSeasonsArray) {
      const cropSeasonList = cropSeasonsArray.map(crop => ({
        key: crop.id,
        value: crop.name
      }));
      setCropSeasonItems(cropSeasonList);
    }
  }, [cropSeasonsArray]);

  useEffect(() => {
    const mappedRowData = setRowData(rowData);
    if (
      !mappedRowData?.tableRows?.length ||
      pageNumberRef.current === pageNumber
    ) {
      setTableData(mappedRowData);
      pageNumberRef.current = -1;
      return;
    }

    pageNumberRef.current = pageNumber;

    const paginatedTableRows = mappedRowData.tableRows.slice(
      pageNumber * PAGINATION_SIZE,
      (pageNumber + 1) * PAGINATION_SIZE
    );
    setTableData({
      ...mappedRowData,
      tableRows: paginatedTableRows
    });
  }, [rowData, setRowData, pageNumber]);

  useEffect(() => {
    const splitUpCropSeasons = cropSeasons?.split(',') || [];

    onFilter(debouncedSearchTerm, splitUpCropSeasons, dateRange);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedSearchTerm, cropSeasons, dateRange]);

  return (
    <div className="-ml-6 -mr-6" data-testid="inventory-filtering">
      <div className="flex justify-between items-center pb-6">
        <div className="flex">
          <Input
            className=" border-none"
            type="text"
            name="search"
            placeholder="Search"
            onChange={e => setSearchText(e.target.value)}
          />
        </div>
        <div className="flex items-center">
          <div className="mr-4">
            <Select
              multiple
              value={cropSeasons}
              items={cropSeasonItems}
              placeholder="Filter by season"
              onChange={handleFilterSelect}
            />
          </div>
          <div className="relative w-64 text-gray-400 focus-within:text-gray-600 mr-4">
            <DatePicker onChange={handleFilterDate} isRangePicker />
          </div>
          {CwFPagination(totalRecordCount, pageNumber, pageClick)}
        </div>
      </div>
      {children({ tableData })}
      {CwFPagination(totalRecordCount, pageNumber, pageClick)}
    </div>
  );
};

Filtering.propTypes = {
  children: PropTypes.func.isRequired,
  setRowData: PropTypes.func.isRequired,
  rowData: PropTypes.arrayOf(PropTypes.object).isRequired,
  onFilter: PropTypes.func,
  pageClick: PropTypes.func.isRequired,
  totalRecordCount: PropTypes.number,
  pageNumber: PropTypes.number
};

Filtering.defaultProps = {
  onFilter: () => {},
  totalRecordCount: 0,
  pageNumber: 0
};

export default Filtering;
