import React from 'react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';
import { startCase, sortBy } from 'lodash';
import RoundedDisplay from 'components/RoundedDisplay';
import { getAbbreviatedUnit } from 'helpers/unitsAbbreviation';
import TileGroup from 'components/TileGroup';
import TileCard from 'components/TileCard';
import TileCardField from 'components/TileCardField';
import { paths } from 'routes/paths';
import { Spinner } from '@agconnections/grow-ui';

const ProductTileBoard = ({ products, isLoading }) => {
  const history = useHistory();
  const handleAction = (action, product) => {
    const actions = {
      view: () => {
        /* view action */
        history.push(
          `${paths.inventory}/${product?.productId || product?.id}/view`
        );
      },
      edit: () => {
        /* edit action */
      },
      delete: () => {
        /* delete action */
      },
      flag: () => {
        /* flag action */
      },
      duplicate: () => {
        /* duplicate action */
      }
    };
    actions[action]();
  };

  const genSubtitle = product => {
    const manufacturerSubtitle =
      product.manufacturerName || product.manufacturer
        ? [product.manufacturerName || product.manufacturer]
        : [];
    const customSubtitle = product.custom !== false ? ['Custom'] : [];
    return [...customSubtitle, ...manufacturerSubtitle];
  };

  const getProductsGroups = () => {
    const productGroups = [];

    products.forEach(product => {
      const index = productGroups.findIndex(
        group => group.isCustom === product.isCustom
      );
      if (index === -1) {
        productGroups.push({ isCustom: product.isCustom, items: [product] });
      } else {
        productGroups[index].items.push(product);
      }
    });

    return sortBy(productGroups, [
      s => {
        return s.isCustom;
      }
    ]).reverse();
  };

  return (
    <div data-testid="products-tile-board" className="mx-4">
      {getProductsGroups().map(group => {
        return (
          <TileGroup
            key={group.isCustom}
            title={group.isCustom ? 'Custom Products' : 'Products and Services'}
            totalItems={group.items.length}
            testId="product"
          >
            {!isLoading ? (
              group.items.map(product => {
                return (
                  <TileCard
                    key={product.productId || product.id}
                    testId="product"
                    linkTo={`${paths.inventory}/${product.productId ||
                      product.id}/view`}
                    title={product.productName || product?.name}
                    subtitle={genSubtitle(product).join(' • ')}
                    onAction={() => {
                      handleAction('view', product);
                    }}
                    contextMenuHide
                  >
                    <div
                      data-testid="product-card-details"
                      className="flex flex-wrap w-full"
                    >
                      <TileCardField
                        id="product-type"
                        title="Type"
                        value={startCase(product.productType || product.type)}
                        testId="product"
                      />
                      <TileCardField
                        id="product-avg-price-per-unit"
                        title="Avg. Price/Unit"
                        value={
                          <>
                            $
                            <RoundedDisplay>
                              {product.averagePricePerUnit || 0}
                            </RoundedDisplay>
                            /{getAbbreviatedUnit(product.productQuantityUnit)}
                          </>
                        }
                        testId="product"
                      />
                      <TileCardField
                        id="product-purchased-amount"
                        title="Invoiced (Qty)"
                        value={
                          <>
                            <RoundedDisplay>
                              {product.purchased || 0}
                            </RoundedDisplay>{' '}
                            {getAbbreviatedUnit(product.purchasedUnit) ||
                              getAbbreviatedUnit(product.appliedUnit)}
                          </>
                        }
                        testId="product"
                      />
                      <TileCardField
                        id="product-applied-amount"
                        title="Applied (Qty)"
                        value={
                          <>
                            <RoundedDisplay>
                              {product.productQuantityValue || 0}
                            </RoundedDisplay>{' '}
                            {getAbbreviatedUnit(product.productQuantityUnit) ||
                              getAbbreviatedUnit(product.purchasedUnit)}
                          </>
                        }
                        testId="product"
                      />
                      <TileCardField
                        id="product-available-amount"
                        title="Available (Qty)"
                        value={
                          <>
                            <RoundedDisplay>
                              {product.purchased -
                                product.productQuantityValue || 0}
                            </RoundedDisplay>{' '}
                            {getAbbreviatedUnit(product.productQuantityUnit) ||
                              getAbbreviatedUnit(product.purchasedUnit)}
                          </>
                        }
                        testId="product"
                      />
                    </div>
                  </TileCard>
                );
              })
            ) : (
              <div className="flex items-center w-full h-40">
                <Spinner size="lg" />
              </div>
            )}
          </TileGroup>
        );
      })}
    </div>
  );
};

ProductTileBoard.propTypes = {
  products: PropTypes.arrayOf(PropTypes.object),
  isLoading: PropTypes.bool.isRequired
};

ProductTileBoard.defaultProps = {
  products: []
};

export default ProductTileBoard;
