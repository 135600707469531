import React, { useEffect, useState } from 'react';
import { useFormikContext } from 'formik';
import { tabsLabels } from '../../helpers/constants';
import PropertiesGrid from '../PropertiesGrid';
import useParameters from '../../hook/useParameters';

const propertiesNames = [
  'boxCount',
  'miniModuleCount',
  'roundBaleCount',
  'largeSquareBaleCount',
  'moduleCount',
  'squareBaleCount'
];

const CountTab = () => {
  const { values } = useFormikContext();
  const { templateType } = values;
  const { removeAttributes } = useParameters(
    tabsLabels.loadAttributes,
    { source: true },
    templateType
  );
  const [properties, setProperties] = useState([]);

  useEffect(() => {
    setProperties(propertiesNames);
  }, []);

  return (
    <PropertiesGrid
      removeAttributes={removeAttributes}
      values={values}
      properties={properties}
    />
  );
};

export default CountTab;
