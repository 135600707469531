import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import PortalModal from 'components/Modals/PortalModal';
import { useToast } from 'components/ToastContainer';
import useFarmData from 'screens/Property/hooks/useFarmData';

const ArchiveFarmModal = ({ open, farm, onConfirm, onCancel }) => {
  const toast = useToast();
  const { archive, archiveFarm } = useFarmData(farm.id);

  useEffect(() => {
    if (archive.isLoading) return;
    if (archive.isSuccess) {
      toast.success(`${farm.name} archived.`, {
        content:
          'You have archived your farm along with all associated fields and crop zones.'
      });
      onConfirm();
    }
    if (archive.isError) {
      toast.error(`${farm.name} not archived.`, {
        content:
          'An error prevented your farm from being archived.  Please try again.',
        supportButton: true
      });
      onCancel();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [archive]);

  const handleConfirm = () => {
    archiveFarm(farm.id);
  };

  return (
    <PortalModal
      open={open}
      title={`Archive ${farm?.name}?`}
      close={onCancel}
      cancelLabel="Cancel"
      confirmLabel="Archive farm"
      // onCancel={onCancel}
      type="warningConfirmation"
      onConfirm={handleConfirm}
      isSaving={archive.isLoading}
    >
      <div className="pr-6">
        This farm will be archived along with all associated fields and crop
        zones. You can unarchive this farm later. Do you want to continue?
      </div>
    </PortalModal>
  );
};

ArchiveFarmModal.propTypes = {
  open: PropTypes.bool.isRequired,
  farm: PropTypes.shape({
    id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired
  }).isRequired,
  onConfirm: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired
};

export default ArchiveFarmModal;
