import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Input, Search, Form } from '@agconnections/grow-ui';
import { Formik } from 'formik';
import GrowUIFormField from 'components/Forms/GrowUIFormField';
import PortalModal from 'components/Modals/PortalModal';
import useCropData from 'hooks/useCropData';

import schema from './schema';

const defaultValues = {
  prodid: null,
  cropid: null,
  parentid: null,
  rei: 0,
  phi: 0,
  reiu: 'hour',
  phiu: 'day'
};

const CropAddEditModal = ({
  openEdit,
  onClose,
  isEdit,
  crop,
  productId,
  saveREIPHI
}) => {
  const [cropItems, setCropItems] = useState([]);
  const { crops } = useCropData();
  const [modalTitle] = useState(
    isEdit ? 'Edit REI/PHI Information' : 'Add REI/PHI Information'
  );
  const [initialValues] = useState(
    crop || { ...defaultValues, prodid: productId }
  );
  const [selectedCrop, setSelectedCrop] = useState({
    cropid: crop?.cropId,
    cropName: crop?.cropName
  });

  const reiUnits = crop?.reiu || 'hour';
  const phiUnits = crop?.phiu || 'day';

  const saveForm = values => {
    saveREIPHI(
      productId,
      {
        ...values,
        i: crop?.i || -1,
        cropid: selectedCrop?.cropid,
        cropName: selectedCrop?.cropName
      },
      isEdit,
      onClose
    );
  };

  useEffect(() => {
    if (!isEdit && crops) {
      const crpItems =
        crops?.map(crp => ({
          key: crp.id,
          value: crp.name
        })) || [];
      setCropItems(crpItems);
    }
  }, [isEdit, crops, crop]);

  return (
    <>
      <Formik initialValues={initialValues} validationSchema={schema}>
        {({ values }) => {
          return (
            <>
              <PortalModal
                open={openEdit}
                close={onClose}
                onCancel={() => onClose()}
                onConfirm={() => saveForm(values)}
                confirmLabel={isEdit ? 'Update' : 'Add'}
              >
                <div className="pt-4 pb-4">
                  <div
                    className="text-2xl font-semibold mb-10"
                    data-testid="modal-title"
                  >
                    {modalTitle}
                  </div>
                  {isEdit ? (
                    <div
                      className="text-lg mb-12"
                      data-testid="edit-crop-title"
                    >
                      {crop?.cropName}
                    </div>
                  ) : (
                    <div
                      className="h-full w-full mb-12"
                      data-testid="add-select-search"
                    >
                      <Form.Field label="Select Crop">
                        <div className="mt-1">
                          <GrowUIFormField
                            control={Search}
                            name="cropid"
                            items={cropItems}
                            placeholder="Select"
                            label="Select Crop"
                            onSelect={e => {
                              setSelectedCrop({
                                cropid: e.key,
                                cropName: e.value
                              });
                            }}
                          />
                        </div>
                      </Form.Field>
                    </div>
                  )}
                  <div className="flex w-full h-full justify-between -mt-8">
                    <Form.Field label="REI">
                      <div className="flex w-full mt-1" data-testid="rei-input">
                        <GrowUIFormField control={Input} name="rei" />
                        <div className="pl-2 pt-1">{reiUnits}</div>
                      </div>
                    </Form.Field>
                    <Form.Field label="PHI">
                      <div className="flex w-full mt-1" data-testid="phi-input">
                        <GrowUIFormField control={Input} name="phi" />
                        <div className="pl-2 pt-1">{phiUnits}</div>
                      </div>
                    </Form.Field>
                  </div>
                </div>
              </PortalModal>
            </>
          );
        }}
      </Formik>
    </>
  );
};

CropAddEditModal.propTypes = {
  openEdit: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  isEdit: PropTypes.bool.isRequired,
  crop: PropTypes.shape(PropTypes.object),
  productId: PropTypes.string,
  saveREIPHI: PropTypes.func.isRequired
};

CropAddEditModal.defaultProps = {
  crop: null,
  productId: null
};

export default CropAddEditModal;
