import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { Context } from 'components/Store';
import CropIconMapping from 'components/CropIconMapping';
import { Select } from 'syngenta-digital-cropwise-react-ui-kit';
import { ReactComponent as ChevronDown } from 'assets/chevron-down-bold.svg';
import './borderless.css';

const BorderlessCropSelect = ({ crops, value, onChange, isLoading }) => {
  const [, dispatch] = useContext(Context);

  const handleSelect = selectedCrop => {
    dispatch({
      type: 'SELECTED_YIELD_COMMODITY',
      payload: selectedCrop
    });
    onChange(selectedCrop);
  };

  if (!crops?.length) {
    return null;
  }

  return (
    <div className="commodity-dropdown">
      <Select
        size="middle"
        required
        suffixIcon={<ChevronDown className="text-info-dark-blue" />}
        onSelect={handleSelect}
        value={value}
        dropdownMatchSelectWidth={false}
        bordered={false}
        data-testid="commodity-dropdown"
        popupClassName="commodity-drowpdown-options"
        loading={isLoading}
      >
        {crops.map(crop => (
          <Select.Option
            key={crop?.id}
            value={crop?.id}
            data-testid={`crop-option-${crop?.id}`}
          >
            <div className="flex items-center gap-2">
              <div className="flex-shrink-0">
                <CropIconMapping cropObject={crop} size={32} />
              </div>
              <div className="crop-name truncate">{crop?.name}</div>
            </div>
          </Select.Option>
        ))}
      </Select>
    </div>
  );
};

BorderlessCropSelect.propTypes = {
  crops: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      name: PropTypes.string
    })
  ).isRequired,
  value: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  isLoading: PropTypes.bool
};

BorderlessCropSelect.defaultProps = {
  value: undefined,
  isLoading: false
};

export default BorderlessCropSelect;
