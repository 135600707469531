import React from 'react';
import PropTypes from 'prop-types';
import { Tag } from 'syngenta-digital-cropwise-react-ui-kit';
import { SubmissionStatus } from 'screens/Integrations/helpers/constants';
import { Popup } from '@agconnections/grow-ui';
import { taskFormat } from '../../../../../helpers/dateFormat';

const CalAgAccordionHeader = ({
  firstLabel,
  secondLabel,
  status,
  isTagVisible,
  pastSubmission
}) => {
  const returnStatusLabel = fieldStatus => {
    switch (fieldStatus) {
      case SubmissionStatus.DONE:
        return 'SUBMITTED';
      case 'complete':
        return 'COMPLETE';
      case 'need_info':
        return 'NEEDS INFO';
      case SubmissionStatus.ERROR:
      default:
        return 'ERROR';
    }
  };

  const returnStatusColor = fieldStatus => {
    switch (fieldStatus) {
      case SubmissionStatus.DONE:
        return 'green';
      case 'complete':
        return 'green';
      case 'need_info':
        return 'yellow';
      case SubmissionStatus.ERROR:
      default:
        return 'red';
    }
  };

  const TagPreviouslySubmitted = (
    <div className="ml-6">
      <Tag
        key="field-past-submission"
        color="neutral"
        data-testid="field-past-submission"
      >
        Previously Submitted
      </Tag>
    </div>
  );
  return (
    <div className="m-4 flex flex-row w-full justify-start">
      {isTagVisible && (
        <span className="text-yellow-700">
          <Tag
            key="field-status"
            color={returnStatusColor(status)}
            data-testid="status-tag"
          >
            {returnStatusLabel(status)}
          </Tag>
        </span>
      )}
      <span className="ml-2 " data-testid="farm-name">
        {firstLabel}
      </span>
      <span className="ml-2 font-bold" data-testid="farm-name">
        / {secondLabel}
      </span>
      {pastSubmission && (
        <Popup
          enableHover
          position="top"
          align="center"
          hasArrow
          bgColor="#232630"
          trigger={TagPreviouslySubmitted}
        >
          <div
            className="gr-w-48 text-xs text-white text-left"
            data-testid="previously-submitted-hover-content"
          >
            This record was last submitted on{' '}
            {taskFormat(pastSubmission.submittedAt)} with Document Number{' '}
            {pastSubmission.internalRecordId}
          </div>
        </Popup>
      )}
    </div>
  );
};

CalAgAccordionHeader.defaultProps = {
  firstLabel: 'NOT_DEFINED_FIRST_LABEL',
  secondLabel: 'NOT_DEFINED_SECOND_LABEL',
  status: 'NOT_DEFINED_STATUS',
  isTagVisible: true,
  pastSubmission: null
};

CalAgAccordionHeader.propTypes = {
  firstLabel: PropTypes.string,
  secondLabel: PropTypes.string,
  status: PropTypes.string,
  isTagVisible: PropTypes.bool,
  pastSubmission: PropTypes.shape({
    submissionId: PropTypes.string,
    submittedAt: PropTypes.string,
    internalRecordId: PropTypes.string
  })
};

export default CalAgAccordionHeader;
