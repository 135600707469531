import React from 'react';
import PropTypes from 'prop-types';
import ViewOnlyField from 'components/ViewOnlyField';
import editOutlined from 'assets/edit_outlined.svg';
import deleteOutlined from 'assets/delete_outlined.svg';
import CropIcons from 'helpers/getCropIconByCropName';
import CropIconMapping from 'components/CropIconMapping';
import defaultIcon from '../../assets/AllCropSVGs/fallow.svg';

const CropCard = ({ product, editClick, deleteClick }) => {
  const regex = / /g;
  const scrubbedProduct = {
    ...product,
    name: product?.cropName?.replace(regex, '')
  };

  const cropName = scrubbedProduct?.name?.includes(':')
    ? scrubbedProduct?.name.split(':')[0].trim()
    : scrubbedProduct?.name;

  return (
    <div className="h-full w-full border rounded p-4">
      <div className="w-full pb-4 mb-3 border-b" data-testid="product-title">
        <div className="flex justify-between text-xl">
          <div
            className={`flex ${product.source === 'CWF' ? 'w-5/6' : 'w-11/12'}`}
          >
            <div className="mr-2">
              {cropName in CropIcons ? (
                <CropIconMapping cropObject={scrubbedProduct} />
              ) : (
                <img src={defaultIcon} alt="" />
              )}
            </div>
            <div className="truncate">{product?.cropName}</div>
          </div>
          <div className="flex">
            <button
              data-testid="edit-button"
              type="button"
              onClick={() => editClick(product.id)}
            >
              <img src={editOutlined} alt="" />
            </button>
            {product.source === 'CWF' && (
              <button
                data-testid="delete-button"
                className="ml-1"
                type="button"
                onClick={() => deleteClick(product.id)}
              >
                <img src={deleteOutlined} alt="" />
              </button>
            )}
          </div>
        </div>
      </div>
      <div
        className="flex w-full justify-between text-md"
        data-testid="product-details"
      >
        <ViewOnlyField
          header="REI"
          data={`${product?.rei} ${product?.reiu}`}
          testId="rei-field"
        />
        <ViewOnlyField
          header="PHI"
          data={`${product?.phi} ${product?.phiu}`}
          testId="phi-field"
        />
        {product?.dateEdited && (
          <ViewOnlyField
            className="mr-6"
            header="Edited On"
            data={`${product?.dateEdited}`}
            testId="edited-date-field"
          />
        )}
        {!product?.dateEdited && <div />}
      </div>
    </div>
  );
};

CropCard.propTypes = {
  product: PropTypes.shape({
    cropName: PropTypes.string,
    id: PropTypes.string,
    rei: PropTypes.number,
    phi: PropTypes.number,
    reiu: PropTypes.string,
    phiu: PropTypes.string,
    dateEdited: PropTypes.string,
    source: PropTypes.string
  }).isRequired,
  editClick: PropTypes.func,
  deleteClick: PropTypes.func
};

CropCard.defaultProps = {
  editClick: () => {},
  deleteClick: () => {}
};

export default CropCard;
