import EmptyState from 'components/EmptyState';
import React from 'react';
import PropTypes from 'prop-types';

const EmptyStateProduct = ({
  setPermission,
  section,
  children,
  // remove when data is available, for verification pusposes only
  openAddEditModal
}) => {
  const toDisplay = () => {
    switch (section) {
      case 'inventory':
        return '';
      case 'documentation':
        return 'No documentation found for this product';
      default:
        return 'No Crop found to display REI/PHI information';
    }
  };
  const toExecute = () => {
    let result = () => openAddEditModal(false);
    if (section === 'documentation') {
      result = () => {};
    }
    if (section === 'inventory') {
      result = '';
    }
    return result;
  };
  const imageToDisplay = () => {
    switch (section) {
      case 'inventory':
        return 'inventory';
      case 'documentation':
        return 'documentation';
      default:
        return 'REIPHI';
    }
  };
  const buttonText = () => {
    switch (section) {
      case 'inventory':
        return '';
      case 'documentation':
        return '';
      default:
        return 'Add Crop';
    }
  };
  return (
    <>
      <EmptyState
        className="w-1"
        buttonText={buttonText()}
        onClick={toExecute()}
        subtitle={toDisplay()}
        imageToUse={imageToDisplay()}
        // permission use to display button
        setPermission={setPermission}
      >
        {children}
      </EmptyState>
    </>
  );
};
EmptyStateProduct.defaultProps = {
  setPermission: () => false,
  section: '',
  children: <></>,
  // remove when data is available for crop cards
  openAddEditModal: () => {}
};

EmptyStateProduct.propTypes = {
  setPermission: PropTypes.func,
  section: PropTypes.string,
  children: PropTypes.node,
  // remove when data is available for crop cards
  openAddEditModal: PropTypes.func
};

export default EmptyStateProduct;
