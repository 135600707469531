import React, { memo } from 'react';
import PropTypes from 'prop-types';

const ListIcon = ({ size, color }) => {
  return (
    <svg
      className="mt-2px"
      width={size}
      height={size}
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      data-testid="list-icon"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2.25 6.75H3.75V5.25H2.25V6.75ZM3.75 9.75H2.25V8.25H3.75V9.75ZM3.75 12.75H2.25V11.25H3.75V12.75ZM15.75 9.75H5.25V8.25H15.75V9.75ZM5.25 12.75H15.75V11.25H5.25V12.75ZM5.25 6.75V5.25H15.75V6.75H5.25Z"
        fill={color}
      />
    </svg>
  );
};

ListIcon.propTypes = {
  color: PropTypes.string,
  size: PropTypes.number
};

ListIcon.defaultProps = {
  color: '#696F88',
  size: 18
};

export default memo(ListIcon);
