import React from 'react';
import PropTypes from 'prop-types';
import { StyleSheet, View } from '@react-pdf/renderer';

import { convertNumberToDesiredFormat } from 'helpers/transformHelpers';

import Layout from '../Layout';
import { Environmental, TaskDetails } from './components';
import { Notes, Signature, Table, TankInformation } from '../components';
import {
  FIELDS_HEADER_ITEMS,
  getProductHeaderItems
} from './helpers/tableMaker';
import WorkerProtection from './components/WorkerProtection';
import {
  fieldHeaderTranslations,
  productHeaderTranslations,
  stateTaskTranslations,
  environmentalHeaderTranslations
} from './utils/headerTranslations';

const styles = StyleSheet.create({
  viewContainer: {
    marginBottom: 27
  }
});

const TaskPdf = ({ task }) => {
  if (!task) return null;
  return (
    <Layout
      headerTitle={task.name}
      type={stateTaskTranslations.task[task._exportLanguage].toString()}
      orgName={task.organizationName}
      cropSeasons={task.cropSeasons}
      generatedYear={task.year}
      generatedFullDate={task.fullDate}
      status={task.status}
      exportLanguage={task._exportLanguage.toString()}
    >
      <View style={styles.viewContainer}>
        <TaskDetails task={task} exportLanguage={task._exportLanguage} />
        <Environmental
          title={
            environmentalHeaderTranslations.environmentalStartingConditions[
              task._exportLanguage
            ]
          }
          weather={task.weather?.[0]}
          exportLanguage={task._exportLanguage}
        />
        {task.status === 'complete' && (
          <Environmental
            title="Environmental Ending Conditions"
            weather={task.weather?.[1]}
          />
        )}
        <TankInformation
          tankInformation={task.tankInformation}
          exportLanguage={task._exportLanguage}
        />
      </View>
      <View style={styles.viewContainer}>
        <Table
          title={productHeaderTranslations.products[task._exportLanguage]}
          headerItems={getProductHeaderItems({
            language: task._exportLanguage,
            applicationStrategy: task.applicationStrategy
          })}
          bodyItems={task.products}
        />
      </View>
      <View style={styles.viewContainer}>
        <Table
          title={fieldHeaderTranslations.fields[task._exportLanguage]}
          headerItems={FIELDS_HEADER_ITEMS[task._exportLanguage]}
          bodyItems={task.properties}
          titleRight={`${
            fieldHeaderTranslations.area[task._exportLanguage]
          } ${convertNumberToDesiredFormat(task.totalFieldsArea, 'fixed')} ac.`}
        />
      </View>
      <View style={styles.viewContainer}>
        <Notes notes={task.notes} exportLanguage={task._exportLanguage} />
      </View>
      <View style={styles.viewContainer}>
        {task.status === 'ready' && (
          <WorkerProtection
            workerProtectionData={task.workerProtection}
            exportLanguage={task._exportLanguage}
          />
        )}
      </View>
      <View style={styles.viewContainer} wrap={false}>
        <Signature exportLanguage={task._exportLanguage} />
      </View>
    </Layout>
  );
};

TaskPdf.propTypes = {
  task: PropTypes.shape()
};

TaskPdf.defaultProps = {
  task: null
};

export default TaskPdf;
