import { cloneDeep, orderBy } from 'lodash';

export default (companies, field, direction) => {
  if (!direction) return companies;

  const _companies = companies.map(company => cloneDeep(company));

  switch (field) {
    case 'name':
      return orderBy(_companies, [company => company.name], direction);
    case 'city':
      return orderBy(
        _companies,
        [company => company?.address?.city],
        direction
      );
    case 'state':
      return orderBy(
        _companies,
        [company => company?.address?.state],
        direction
      );
    default:
      return _companies;
  }
};
