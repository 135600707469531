import { useState, useEffect } from 'react';
import { getOriginalTotalArea } from 'utilities/helpers';

const usePropertyData = () => {
  const [fields, setFields] = useState(null);
  const [fieldsLoading] = useState(false);
  const [loading, setLoading] = useState(false);
  const [originalTotalArea, setOriginalTotalArea] = useState(0);
  const [properties, setProperties] = useState([]);

  useEffect(() => {
    setLoading(fieldsLoading);
  }, [setLoading, fieldsLoading]);

  useEffect(() => {
    setOriginalTotalArea(getOriginalTotalArea(properties));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [properties.length]);

  return {
    fields: fields || [],
    setFields,
    loading,
    originalTotalArea,
    setProperties
  };
};

export default usePropertyData;
