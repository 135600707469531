import React, { useContext, useState, useEffect } from 'react';
import { useParams, Redirect, useHistory, useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';
import { Spinner } from '@agconnections/grow-ui';
import Breadcrumb from 'components/App/AppShell/components/Breadcrumb';
import { AmplitudeContext } from 'utilities/amplitude/useAmplitude';
import useCropsSeasons from 'hooks/useCropSeasons';
import { cloneDeep } from 'lodash';
import { paths } from 'routes/paths';
import usePopulateMapData from 'hooks/usePopulateMapData';
import { FETCH_TYPES } from 'helpers/constants';
import PlanForm from './components/PlanForm';
import { costTypes } from '../helpers/constants';
import { addPlanMeta } from '../hooks/usePlanMeta';
import { calcTotalYield, unmaskPrice } from '../helpers/dataHelpers';
import usePlanData from './hooks/usePlanData';
import PlanView from '../components/PlanView';

const Plan = () => {
  const location = useLocation();
  const fromView = location?.state?.selectedView;

  const redirect = false;
  // const [redirect, setRedirect] = useState(false);
  const [toastRenderContents, setToastRenderContents] = useState(null);
  const [isCreateScreen, setIsCreateScreen] = useState(false);
  const { id, view } = useParams();

  const { plan, loading, saving, savePlan } = usePlanData(id);
  const { cropSeasons } = useCropsSeasons();
  const history = useHistory();
  let planName = plan?.name;
  const duplicating = fromView;
  const amplitude = useContext(AmplitudeContext);
  if (duplicating === 'edit-dup') {
    planName = '';
  }
  const [initialValues, setInitialValues] = useState(null);
  const [loadInitialValues, setLoadInitialValues] = useState(true);
  const [selectedCropSeasonIds, setSelectedCropSeasonIds] = useState(
    JSON.parse(localStorage.getItem('selectedCropSeasons')) || null
  );

  const {
    dataSorted: propertyLandingPageData,
    loading: propertiesLoading,
    fieldsAndAreasGeoJSONCollection,
    reloadData
  } = usePopulateMapData({
    fetchType: FETCH_TYPES.propertiesMap
  });

  const updateSelectedCropSeasons = () => {
    reloadData();
    setSelectedCropSeasonIds(
      JSON.parse(localStorage.getItem('selectedCropSeasons')) || null
    );
  };
  const setProductCoveragePerentage = products => {
    return products?.map(product => {
      return {
        ...product,
        coveragePercent: Number.parseFloat(product.coveragePercent) * 100
      };
    });
  };

  useEffect(() => {
    if (!loading && plan && selectedCropSeasonIds && loadInitialValues) {
      setInitialValues(
        addPlanMeta({
          ...(isCreateScreen && id !== 'plan' && { id }),
          crops: plan?.name?.crops || 'none',
          productApplications: plan?.name?.productApplications || [],
          // area: plan?.estimatedAreaValue || 1,
          revenue: plan?.name?.revenue || {
            cropShare: 100,
            salePricePerUnit: 0,
            yieldPerArea: 0,
            fsaPayment: 0
          },
          costs:
            plan?.name?.costs ||
            costTypes.map(costType => ({
              costType: costType?.type,
              costValue: 0,
              costStructure: 'total'
            })),
          totalSelectedArea: 0,
          coverage: 0,
          yield: calcTotalYield(plan),
          name: planName,
          estimatedAreaUnit: plan?.estimatedAreaUnit || 'acre',
          estimatedAreaValue: plan?.estimatedAreaValue || 1,
          fixedCost: {
            landRent: plan?.fixedCost?.landRent * plan?.estimatedAreaValue,
            landRent_PerAc: plan?.fixedCost?.landRent,
            equipment: plan?.fixedCost?.equipment * plan?.estimatedAreaValue,
            equipment_PerAc: plan?.fixedCost?.equipment,
            insurance: plan?.fixedCost?.insurance * plan?.estimatedAreaValue,
            insurance_PerAc: plan?.fixedCost?.insurance,
            labor: plan?.fixedCost?.labor * plan?.estimatedAreaValue,
            labor_PerAc: plan?.fixedCost?.labor,
            repairs: plan?.fixedCost?.repairs * plan?.estimatedAreaValue,
            repairs_PerAc: plan?.fixedCost?.repairs,
            taxes: plan?.fixedCost?.taxes * plan?.estimatedAreaValue,
            taxes_PerAc: plan?.fixedCost?.taxes,
            returnToMgt:
              plan?.fixedCost?.returnToMgt * plan?.estimatedAreaValue,
            returnToMgt_PerAc: plan?.fixedCost?.returnToMgt
          },
          projectedRevenue: {
            yieldPerArea: plan?.projectedRevenue?.yieldPerArea,
            fsaPayment: plan?.projectedRevenue?.fsaPayment,
            yieldTotal:
              plan?.projectedRevenue?.yieldPerArea *
              plan?.estimatedAreaValue *
              plan?.projectedRevenue?.pricePerUnit,
            fsaPaymentPerAc:
              plan?.projectedRevenue?.fsaPayment / plan?.estimatedAreaValue,
            cropSharePercent:
              plan?.projectedRevenue?.cropSharePercent * 100 || 100,
            pricePerUnit: plan?.projectedRevenue?.pricePerUnit
          },
          notes: plan?.notes,
          cropSeasonIds: plan?.cropSeasonIds || selectedCropSeasonIds,
          flagIds: plan?.flagIds,
          planProductEntries: setProductCoveragePerentage(
            plan?.planProductEntries || []
          ),
          cropZones: plan?.cropZones || [],
          cropId: plan?.cropId,
          currency: plan?.currency || 'USD',
          totalApplied: plan?.totalApplied || 0,
          totalAreaValue: plan?.totalAreaValue || 0,
          coveragePercent: plan?.coveragePercent || 100,
          propertiesGroupBy: 'property'
        })
      );
      setLoadInitialValues(false);
    }
  }, [
    loading,
    plan,
    isCreateScreen,
    id,
    planName,
    selectedCropSeasonIds,
    loadInitialValues
  ]);

  const findCropSeasonIdForCropZone = zone => {
    const seasonsFiltered = cropSeasons.filter(
      season =>
        season.cropZoneIds.includes(zone) &&
        selectedCropSeasonIds.includes(season.id)
    );
    return seasonsFiltered;
  };

  const newCropzonesMapping = values => {
    const newZonesArr = [];
    /* eslint no-unused-expressions: "off", curly: "error" */
    values?.cropZones?.forEach(zone => {
      newZonesArr.push({
        id: zone?.id || '',
        cropSeasonId:
          findCropSeasonIdForCropZone(zone.cropZoneIdValue)[0].id || '',
        cropZoneIdValue: zone?.cropZoneIdValue || '',
        areaValue: zone?.areaValue || 0,
        areaUnit: zone?.areaUnit || 'acre'
      });
    });
    return newZonesArr;
  };

  const cleanSaveMetaData = values => {
    let cId = '';
    const cloneValues = cloneDeep(values);
    const newProductMapping = cloneValues?.planProductEntries?.map(product => {
      const newProduct = product;
      delete newProduct?.stdpackageunit;
      delete newProduct?.stdunit;
      delete newProduct?.stdfactor;
      delete newProduct?.density;
      delete newProduct.productName;
      delete newProduct.shareOwnerInformation;

      newProduct.coveragePercent =
        Number.parseFloat(product.coveragePercent) / 100;
      return newProduct;
    });
    if (values?.cropId?.$id === undefined) {
      cId = values.cropId;
    } else {
      cId = values?.cropId?.$id;
    }
    return {
      ...(isCreateScreen && id !== 'plan' && { id }),
      name: values?.name || '',
      estimatedAreaUnit: values?.estimatedAreaUnit || 'acre',
      estimatedAreaValue: values?.estimatedAreaValue || 1,
      fixedCost: {
        /* eslint-disable camelcase */
        landRent: unmaskPrice(values?.fixedCost?.landRent_PerAc || 0),
        equipment: unmaskPrice(values?.fixedCost?.equipment_PerAc || 0),
        insurance: unmaskPrice(values?.fixedCost?.insurance_PerAc || 0),
        labor: unmaskPrice(values?.fixedCost?.labor_PerAc || 0),
        repairs: unmaskPrice(values?.fixedCost?.repairs_PerAc || 0),
        taxes: unmaskPrice(values?.fixedCost?.taxes_PerAc || 0),
        returnToMgt: unmaskPrice(values?.fixedCost?.returnToMgt_PerAc || 0)
      },
      projectedRevenue: {
        yieldPerArea: unmaskPrice(values?.projectedRevenue?.yieldPerArea || 0),
        fsaPayment: unmaskPrice(values?.projectedRevenue?.fsaPayment || 0),
        cropSharePercent: values?.projectedRevenue?.cropSharePercent / 100 || 1,
        pricePerUnit: unmaskPrice(values?.projectedRevenue?.pricePerUnit || '0')
      },
      notes: values?.notes || '',
      cropSeasonIds: selectedCropSeasonIds || [],
      flagIds: values?.flagIds || [],
      planProductEntries: newProductMapping || [],
      cropZones: newCropzonesMapping(values) || [],
      organizationId: plan.organizationId,
      cropId: cId,
      currency: values?.currency || 'USD',
      // sending the value back as was entered even though it is a percentage as the frontend calculations already works with the modified value.
      coveragePercent: values?.coveragePercent
    };
  };

  const handleSave = async cleanValues => {
    if (cleanValues?.id === 'plan') {
      setIsCreateScreen(true);
    } else {
      setIsCreateScreen(false);
    }

    const success = await savePlan(cleanValues, duplicating);

    if (success) {
      setToastRenderContents('Save Succeeded');
      if (fromView === 'edit-fromView') {
        history.push(`${paths.plans}/${id}/view`);
      } else {
        history.push(paths.plans);
      }
      location.reload();
    }
  };

  const onSubmit = async values => {
    // amplitude trigger
    amplitude.sendEventToAmplitude(amplitude.events.epic.Plans.createPlan);
    let cleanValues = cleanSaveMetaData(values);
    const { notes, ...restCleanValues } = cleanValues;
    cleanValues = notes ? cleanValues : restCleanValues;

    await handleSave(cleanValues);
  };

  const getViewEditDesign = () => {
    if (view === 'view') {
      return <PlanView plan={initialValues} />;
    }
    return (
      <PlanForm
        onSubmit={onSubmit}
        plan={initialValues}
        isSaving={saving}
        selectedview={fromView}
        selectedCropSeasonIds={selectedCropSeasonIds}
        propertiesSortedData={propertyLandingPageData}
        propertiesLoading={propertiesLoading}
        fieldsAndAreasGeoJSONCollection={fieldsAndAreasGeoJSONCollection}
        reloadData={reloadData}
      />
    );
  };

  const getBreadCr = () => {
    if (view === 'view') {
      return (
        <Breadcrumb.Item
          title="Plans"
          value={initialValues.name}
          to={paths.plans}
          isLast
        />
      );
    }
    return (
      <>
        <Breadcrumb.Item title="Plans" value="All Plans" to={paths.plans} />
        <Breadcrumb.Item
          title="Plan"
          value={id === 'plan' ? 'Create Plan' : 'Edit Plan'}
          isLast
        />
      </>
    );
  };

  return (
    <div className="w-full h-full" data-testid="plan-form-container">
      <Breadcrumb
        disabled={id === 'plan' || id.length > 0}
        hideCropSeasonDropdown={false}
        onCropSeasonSelect={updateSelectedCropSeasons}
      >
        {getBreadCr()}
      </Breadcrumb>
      {redirect && (
        <Redirect
          push
          to={{
            pathname: redirect,
            state: { toastRenderContents }
          }}
        />
      )}
      {loading ? <Spinner /> : getViewEditDesign()}
    </div>
  );
};

Plan.defaultProps = {
  location: {
    reload: () => {},
    selectedView: ''
  }
};

Plan.propTypes = {
  location: PropTypes.shape({
    selectedView: PropTypes.string,
    reload: PropTypes.func
  })
};
export default Plan;
