import React, { useEffect } from 'react';
import PropTypes from 'prop-types';

import FarmOption from '../FarmOption';

const FarmSelectorOptionList = ({ farms, selectFarm, onBlur }) => {
  useEffect(() => {
    document.addEventListener('mousedown', onBlur);
    return () => document.removeEventListener('mousedown', onBlur);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div
      data-testid="farm-option-list"
      className="absolute mt-1 ml-2 w-56 py-2 rounded bg-white shadow z-50"
    >
      {farms.map((farm, index) => (
        <FarmOption
          key={farm.id}
          selectFarm={selectFarm}
          farm={farm}
          index={index}
        />
      ))}
    </div>
  );
};

FarmSelectorOptionList.propTypes = {
  farms: PropTypes.arrayOf(PropTypes.object).isRequired,
  selectFarm: PropTypes.func.isRequired,
  onBlur: PropTypes.func.isRequired
};

export default FarmSelectorOptionList;
