import React from 'react';
import PropTypes from 'prop-types';
import { take } from 'lodash';

import useWindowDimensions from 'hooks/useWindowDimensions';
import { Popup, Tag } from '@agconnections/grow-ui';

const numberOfDisplayedCropSeasons = width => {
  if (width <= 1410) return 1;
  if (width >= 1411 && width <= 1599) return 2;
  return 3;
};

const CropSeasonsTags = ({ cropSeasons }) => {
  const { width } = useWindowDimensions();

  const cropSeasonsToDisplay = take(
    cropSeasons,
    numberOfDisplayedCropSeasons(width)
  );

  const cropSeasonsRemaining = cropSeasons.filter(
    season =>
      !cropSeasonsToDisplay.some(
        seasonDisplay => seasonDisplay.id === season.id
      )
  );

  return (
    <div className="flex gap-2 justify-start">
      {cropSeasonsToDisplay.map(cropSeason => (
        <div key={cropSeason.id} className="w-auto">
          <Tag color="#696F88">
            <span className="p-2">{cropSeason.name}</span>
          </Tag>
        </div>
      ))}
      {cropSeasonsRemaining.length > 0 && (
        <Popup
          enableHover
          position="top"
          align="center"
          hasArrow
          bgColor="#232630"
          trigger={
            <span className="font-bold" data-testid="size-remaining">
              +{cropSeasonsRemaining.length}
            </span>
          }
        >
          <div className="gr-w-40 text-xs text-white">
            {cropSeasonsRemaining.map(season => season.name).join(', ')}
          </div>
        </Popup>
      )}
    </div>
  );
};

CropSeasonsTags.defaultProps = {
  cropSeasons: []
};

CropSeasonsTags.propTypes = {
  cropSeasons: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired
    })
  )
};

export default CropSeasonsTags;
