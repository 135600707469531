import { uniqBy } from 'lodash';

export const getEventClientCoordinates = event => {
  const rect = event.target.getBoundingClientRect();
  const xCoordinate = event.clientX - rect.left;
  const yCoordinate = event.clientY - rect.top / 2;
  return [xCoordinate, yCoordinate];
};

// find the map feature at the specified long lat
export const getTargetMapFeature = (map, lngLat) => {
  const point = map.project(lngLat);
  const foundFeatures = map.queryRenderedFeatures(point);
  const [targetFeature] = uniqBy(foundFeatures, 'properties.grisId');
  return targetFeature;
};
