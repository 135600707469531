export default function matchFeaturesAndSelectedCropzones(
  featureCollection,
  cropZones = []
) {
  return cropZones
    .map(curr =>
      featureCollection.features.find(item => item.properties.id === curr)
    )
    .filter(Boolean);
}
