import React from 'react';
import PropTypes from 'prop-types';

import { Form, Input, Spinner } from '@agconnections/grow-ui';

const SubmissionCounties = ({ counties, setCounties, isLoading }) => {
  const setCountyData = (prop, value, index) => {
    setCounties(old => {
      const newValue = old;
      newValue[index][prop] = value;
      return [...newValue];
    });
  };

  const inputStyleOverrides = {
    paddingLeft: '10px',
    maxWidth: '300px',
    borderColor: '#e2e8f0'
  };

  return (
    <div className="mt-8 flex flex-col w-full">
      <span className="mb-4">
        Please provide username and password for each county included in your
        submission.
      </span>
      {isLoading ? (
        <div className="mt-20">
          <Spinner className="mb-4" />
          <div className="mt-4 text-center">
            <span className="font-bold">Submitting your data</span>
          </div>
        </div>
      ) : (
        counties.map((county, index) => {
          return (
            <div key={county.name} className="mt-2">
              <span className="font-bold">{county.name} login</span>
              <Form.Field label="Username *">
                <Input
                  name="countyUsername"
                  type="text"
                  required
                  value={county.username}
                  style={inputStyleOverrides}
                  onChange={e => {
                    setCountyData('username', e.target.value, index);
                  }}
                />
              </Form.Field>
              <Form.Field label="Password *">
                <Input
                  name="countyPassword"
                  type="password"
                  required
                  value={county.password}
                  style={inputStyleOverrides}
                  onChange={e => {
                    setCountyData('password', e.target.value, index);
                  }}
                />
              </Form.Field>
            </div>
          );
        })
      )}
    </div>
  );
};

SubmissionCounties.propTypes = {
  counties: PropTypes.arrayOf(
    PropTypes.shape({
      username: PropTypes.string.isRequired,
      password: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired
    })
  ).isRequired,
  setCounties: PropTypes.func.isRequired,
  isLoading: PropTypes.bool.isRequired
};

export default SubmissionCounties;
