import { useCallback, useContext, useEffect, useState } from 'react';

import { Context } from 'components/Store';
import { orderBy } from 'lodash';
import { selectCropSeason } from 'utilities/updated-permissions';
import { useFlags } from 'launchdarkly-react-client-sdk';
import featureFlagRenderItem from 'helpers/featureFlagRenderItem';

const useCropSeasons = (
  { searchTerm, useFullResponse } = {
    searchTerm: null,
    useFullResponse: false
  }
) => {
  const [
    {
      organization,
      cropSeasons: contextCropSeasons,
      isGlobalCropSeasonLoading
    },
    dispatch
  ] = useContext(Context);

  const {
    releaseFeCondensedCropSeasons,
    releaseFeCondensedCropSeasonsByOrg
  } = useFlags();

  const isCondensedCropSeasonEnabled = featureFlagRenderItem(
    releaseFeCondensedCropSeasons,
    releaseFeCondensedCropSeasonsByOrg,
    organization?.id
  );

  const [cropSeasons, setCropSeasons] = useState();

  const getAllSeasons = async () => {
    const useCondensed = useFullResponse ? false : isCondensedCropSeasonEnabled;
    await selectCropSeason(dispatch, null, useCondensed);
  };

  const reloadCropSeasons = () => {
    getAllSeasons();
  };

  const findCropSeason = useCallback(
    value => {
      const result = contextCropSeasons
        ? contextCropSeasons.filter(
            season =>
              !value || season.name.toLowerCase().includes(value.toLowerCase())
          )
        : [];

      if (result) {
        setCropSeasons(
          orderBy(result, [({ name }) => name?.toLowerCase()], 'asc')
        );
      }
    },
    [contextCropSeasons]
  );

  useEffect(() => {
    if (isGlobalCropSeasonLoading) return;
    getAllSeasons();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (searchTerm) {
      findCropSeason(searchTerm);
    } else {
      findCropSeason();
    }
  }, [searchTerm, findCropSeason]);
  return {
    cropSeasons,
    findCropSeason,
    loaded: !isGlobalCropSeasonLoading,
    reloadCropSeasons
  };
};
export default useCropSeasons;
