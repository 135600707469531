import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { AddRounded } from '@material-ui/icons';
import { Button } from '@agconnections/grow-ui';
import { Context } from 'components/Store';
import SortButton from 'components/SortButton';
import { SET_SORT_RULES } from 'reducers/reducer';
import createAction from 'helpers/createAction';
import sortItems from '../../helpers/sortValuesSaleLocation';

const SaleLocationsHeaderActions = ({ onCreate, onSort }) => {
  const [{ organization, sortRules }, dispatch] = useContext(Context);

  const getSortRules = value => {
    createAction(dispatch, SET_SORT_RULES, value);
  };

  return (
    <div className="w-full flex flex-row justify-around pt-3">
      <Button
        style={{ width: '45%' }}
        icon={<AddRounded style={{ width: '70%' }} />}
        type="primary"
        onClick={onCreate}
      >
        <span style={{ marginLeft: '-4px' }}>Create New</span>
      </Button>

      <SortButton
        sortItems={sortItems}
        onSort={onSort}
        orgId={organization.id}
        sortRules={sortRules}
        setSortRules={getSortRules}
      />
    </div>
  );
};

SaleLocationsHeaderActions.propTypes = {
  onCreate: PropTypes.func.isRequired,
  onSort: PropTypes.func.isRequired
};

export default SaleLocationsHeaderActions;
