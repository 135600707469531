import PropTypes from 'prop-types';
import { SERVER_ERROR, CLIENT_ERROR } from 'helpers/errorHelpers';

const errorShape = {
  id: PropTypes.string,
  type: PropTypes.oneOf([SERVER_ERROR, CLIENT_ERROR]),
  showTryAgain: PropTypes.bool,
  message: PropTypes.string,
  displayMessage: PropTypes.string,
  status: PropTypes.number
};

export default errorShape;
