import React from 'react';
import PropTypes from 'prop-types';

import { DatePicker, Form } from '@agconnections/grow-ui';

function ExpirationField({ expiration, onChange, title }) {
  return (
    <Form.Field>
      <div data-testid="expirationField" className="flex">
        <div className="w-full ml-6">
          <div className="block text-sm font-medium leading-5 gr-text-gray-600 sm:mt-px sm:pt-2">
            {title}
          </div>
          <div className="block text-sm font-medium leading-5 text-gray-700 sm:mt-px sm:pt-2 w-full">
            <DatePicker
              id="expiry-date-picker"
              onChange={onChange}
              defaultValue={expiration}
              dates={expiration}
              name="expiry"
              dateFormat="M d, Y"
            />
          </div>
        </div>
      </div>
    </Form.Field>
  );
}

ExpirationField.defaultProps = {
  expiration: null,
  title: ''
};

ExpirationField.propTypes = {
  expiration: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.instanceOf(Date)
  ]),
  onChange: PropTypes.func.isRequired,
  title: PropTypes.string
};
export default ExpirationField;
