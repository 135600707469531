// import faker from 'faker';
import { Factory } from 'miragejs';

import { shouldUseMirage } from 'utilities/apiConstants';

// generate these resources for each mirage organization
export const createOrganizationResources = (server, organizationId) => {
  if (shouldUseMirage) {
    server.createList('user', 4, { organizationId });
    server.createList('member', 9, { organizationId });
    server.createList('equipment', 10, {
      organizationId
    });
  }
  server.createList('company', 5, { organizationId });
};

const Organization = {
  name() {
    return 'Mirage Organization';
  },
  primaryContact() {
    return 'Guy Fieri';
  },
  primaryContactEmail() {
    return 'guy.fieri@flavortown.com';
  },
  primaryContactPhoneNumber() {
    return '5555555555';
  },
  billingAddress() {
    return {
      address1: '',
      address2: '',
      postCode: '',
      state: '',
      country: ''
    };
  },
  afterCreate(organization, server) {
    // create a user and a member in the default org from your stored okta token

    createOrganizationResources(server, organization.attrs.id);
    return true;
  }
};

export default Factory.extend(Organization);
