/**
 * Sanitizes and converts a value to a number.
 *
 * @param {string} value - The value to be sanitized and converted.
 * @returns {number|null} - The sanitized and converted number, or null if the conversion fails.
 */
const sanitizeAndConvertToNumber = value => {
  const numberValue = parseFloat(value);
  return Number.isNaN(numberValue) ? null : numberValue;
};

export default sanitizeAndConvertToNumber;
