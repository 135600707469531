/* eslint-disable eqeqeq */
import React, { useEffect, useState, useContext } from 'react';
import { useParams } from 'react-router-dom';
import { Spinner } from '@agconnections/grow-ui';
import { Context } from 'components/Store';
import useLoads from 'hooks/useLoads';
import Breadcrumb from 'components/App/AppShell/components/Breadcrumb';
import InfoToast from 'screens/YieldV2/StorageLocations/components/InfoToast';
import LoadDetailsInfo from './components/LoadDetailsInfo';

const LoadDetails = () => {
  const { id } = useParams();
  const [load, setLoad] = useState();
  const [cropzoneTotalArea, setCropzoneTotalArea] = useState(0);
  const { getLoadDetails } = useLoads();
  const [isFetching, setIsFetching] = useState(true);
  const [
    { showToastChangesSaved, isGlobalCropSeasonLoading },
    dispatch
  ] = useContext(Context);

  useEffect(() => {
    if (!id) return;
    getLoadDetails(id).then(loadData => {
      setLoad(loadData);
      setIsFetching(false);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  useEffect(() => {
    setCropzoneTotalArea(
      load?.sources
        ?.map(({ area }) => area)
        .reduce((partialSum, a) => partialSum + a, 0)
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [load]);

  const closeToast = close => {
    dispatch({ type: 'SET_SHOW_TOAST_CHANGES_SAVED', payload: close });
  };

  return (
    <div
      className="h-full w-full flex flex-col"
      data-testid="load-details-view"
    >
      <Breadcrumb disabled hideCropSeasonDropdown>
        <Breadcrumb.Item title="Yield" value={`${load?.name}`} isLast />
      </Breadcrumb>
      {isFetching || isGlobalCropSeasonLoading ? (
        <Spinner />
      ) : (
        <LoadDetailsInfo cropzoneTotalArea={cropzoneTotalArea} load={load} />
      )}
      {showToastChangesSaved && (
        <InfoToast
          state="success"
          isBold
          showToast
          title="Changes saved."
          setShowToast={closeToast}
        />
      )}
    </div>
  );
};
export default LoadDetails;
