import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { Button } from '@agconnections/grow-ui';

const NotFound404Error = ({ resourceName, btnText, btnLink }) => {
  return (
    <div className="flex flex-col items-center justify-center h-64 bg-white rounded shadow-md radius">
      <div className="text-xs text-gray-500">Error code: 404</div>
      <div className="px-4 text-xl leading-6 text-center text-gray-800">
        {resourceName ? `${resourceName} not found` : 'Page not found'}
      </div>
      <div className="px-4 mt-2 mb-10 text-base leading-5 text-center text-gray-700">
        We couldn&apos;t find what you&apos;re looking for.
      </div>
      <div className="flex justify-center w-full">
        <div className="mx-4">
          <Link to={btnLink || '/app'}>
            <Button>{btnText || 'View Dashboard'}</Button>
          </Link>
        </div>
      </div>
    </div>
  );
};
NotFound404Error.defaultProps = {
  btnLink: null,
  btnText: null,
  resourceName: null
};
NotFound404Error.propTypes = {
  btnLink: PropTypes.string,
  btnText: PropTypes.string,
  resourceName: PropTypes.string
};

export default NotFound404Error;
