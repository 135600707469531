export const getTotalAreaAcresCropZones = fields => {
  let totalAcres = 0;
  fields.forEach(field => {
    if (field.cropzones.length > 0) {
      totalAcres = field.cropzones.reduce((acc, currCropZone) => {
        const toSum = currCropZone.reportedArea || currCropZone.boundaryArea;
        return acc + toSum;
      }, 0);
    }
    return totalAcres;
  });
  return `${totalAcres.toFixed(2)} ac`;
};

/**
 * Calculates the total area number by summing up the reportedArea or boundaryArea properties of the given features.
 *
 * @param {Array} features - An array of features.
 * @returns {number} - The total area in acres.
 */
export const getTotalAreaNumber = features => {
  const totalAcres = features.reduce((acc, curr) => {
    const area = curr.properties.reportedArea || curr.properties.boundaryArea;

    return acc + area;
  }, 0);

  return totalAcres;
};

/**
 * Calculates the total area in acres from an array of features.
 *
 * @param {Array} features - An array of features.
 * @returns {string} - The total area in acres, rounded to 2 decimal places.
 */
export const getTotalAreaFromFeatures = features => {
  return getTotalAreaNumber(features).toFixed(2);
};
