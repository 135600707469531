import { ROLES } from 'utilities/access';

import Organization from 'screens/Organizations/Organization';

import { OrganizationsIcon } from 'routes/icons';
import { paths } from 'routes/paths';

const routes = [
  {
    id: 'organization',
    key: 'organizations',
    path: `${paths.organizations}/:id`,
    secure: true,
    disabled: false,
    component: Organization,
    icon: OrganizationsIcon,
    nav: 'leftNav',
    minPermissions: {
      minAllowedRole: ROLES.FULL_CONTROL
    },
    redirect: paths.properties
  },
  {
    id: 'organizationEntry',
    key: 'organizationEntry',
    path: `${paths.organizations}/:id`,
    exact: true,
    secure: true,
    component: Organization,
    minPermissions: {
      minAllowedRole: ROLES.FULL_CONTROL
    }
  }
];

export default routes;
