import React, { useEffect, useState } from 'react';
import { Checkbox, Spinner } from '@agconnections/grow-ui';
import GrowUIFormField from 'components/Forms/GrowUIFormField';
import PropTypes from 'prop-types';
import useStorageLocations from '../../../../../StorageLocations/hooks/useStorageLocations';

const StorageLocationsFilter = ({ filters }) => {
  const { loading, fetchStorageLocations } = useStorageLocations();
  const [storageLocations, setStorageLocations] = useState([]);

  useEffect(() => {
    const loadLocation = async () => {
      const { data } = await fetchStorageLocations();
      setStorageLocations(data);
    };
    loadLocation();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      {loading ? (
        <Spinner />
      ) : (
        <>
          {storageLocations?.length > 0 ? (
            storageLocations?.map(storageLocation => (
              <div
                key={storageLocation?.id}
                className="flex items-center pl-2 py-6px"
              >
                <GrowUIFormField
                  control={Checkbox}
                  name="storageIds"
                  value={storageLocation.id}
                  checked={filters.storageIds?.includes(storageLocation.id)}
                />
                <p className="ml-2">{storageLocation?.name}</p>
              </div>
            ))
          ) : (
            <div className="text-neutral-60 text-sm ">
              No storage locations found.
            </div>
          )}
        </>
      )}
    </>
  );
};

StorageLocationsFilter.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  filters: PropTypes.object.isRequired
};

export default StorageLocationsFilter;
