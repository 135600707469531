/* eslint-disable no-param-reassign */
import _ from 'lodash';

export const mapFarmView = fieldsToMassiveAssign =>
  _.reduce(
    fieldsToMassiveAssign,
    (prev, current) => {
      if (prev && prev[current.farmName]) {
        prev[current.farmName].push(current);
      } else if (prev && !prev[current.farmName]) {
        return {
          ...prev,
          [current.farmName]: [current]
        };
      } else {
        return { [current.farmName]: [current] };
      }

      return prev;
    },
    {}
  );

export const mapCropView = fieldsToMassiveAssign =>
  _.reduce(
    fieldsToMassiveAssign,
    (prev, current) => {
      return _.reduce(
        current.cropzones,
        (prevCrop, currentCrop) => {
          if (
            prevCrop &&
            prevCrop[currentCrop.crop.name] &&
            prevCrop[currentCrop.crop.name][current.farmName]
          ) {
            prevCrop[currentCrop.crop.name][current.farmName].push(current);
          } else if (
            prevCrop &&
            prevCrop[currentCrop.crop.name] &&
            !prevCrop[currentCrop.crop.name][current.farmName]
          ) {
            prevCrop[currentCrop.crop.name] = {
              ...prevCrop[currentCrop.crop.name],
              [current.farmName]: [current]
            };
          } else if (prevCrop && !prevCrop[currentCrop.crop.name]) {
            return {
              ...prevCrop,
              [currentCrop.crop.name]: {
                [current.farmName]: [current]
              }
            };
          } else {
            return {
              [currentCrop.crop.name]: { [current.farmName]: [current] }
            };
          }

          return prevCrop;
        },
        prev
      );
    },
    {}
  );
