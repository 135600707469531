export const stateTaskTranslations = {
  ready: { english: 'Ready', LATAMSpanish: 'Listo' },
  task: { english: 'Task', LATAMSpanish: 'Tarea' }
};

export const taskDetailHeaderTranslations = {
  taskDetails: {
    english: 'Task Details',
    LATAMSpanish: 'Detalles de la tarea'
  },
  startTime: { english: 'Start Time:', LATAMSpanish: 'Hora de inicio:' },
  finishTime: {
    english: 'Finish Time:',
    LATAMSpanish: 'Hora de finalización:'
  },
  authorizedBy: { english: 'Authorized By', LATAMSpanish: 'Autorizado por' },
  authorizedOn: { english: 'Authorized On', LATAMSpanish: 'Autorizado el' },
  timingEvent: {
    english: 'Timing Event',
    LATAMSpanish: 'Sincronización del evento'
  }
};

export const applicatorsHeaderTranslations = {
  applicators: { english: 'Applicators', LATAMSpanish: 'Aplicadores' },
  nameCompany: { english: 'Name/Company', LATAMSpanish: 'Nombre/compañía' },
  licenseNo: { english: 'License No', LATAMSpanish: 'N.° de licencia' }
};

export const environmentalHeaderTranslations = {
  environmentalStartingConditions: {
    english: 'Environmental Starting Conditions',
    LATAMSpanish: 'Condiciones ambientales de inicio'
  },
  skyCondition: {
    english: 'Sky Condition:',
    LATAMSpanish: 'Condición del cielo:'
  },
  windSpeed: { english: 'Wind Speed:', LATAMSpanish: 'Velocidad del viento:' },
  windDirection: {
    english: 'Wind Direction:',
    LATAMSpanish: 'Dirección del viento:'
  },
  soilCondition: {
    english: 'Soil Condition:',
    LATAMSpanish: 'Condición del suelo:'
  },
  temperature: { english: 'Temperature:', LATAMSpanish: 'Temperatura:' },
  humidity: { english: 'Humidity:', LATAMSpanish: 'Humedad:' }
};

export const tankHeaderTranslations = {
  tankInfo: {
    english: 'Tank Information',
    LATAMSpanish: 'Información del tanque'
  },
  tankSize: { english: 'Tank Size', LATAMSpanish: 'Tamaño del tanque' },
  carrierArea: { english: 'Carrier/Area', LATAMSpanish: 'Transporte/área' },
  totalCarrier: {
    english: 'Total Carrier',
    LATAMSpanish: 'Total de transporte'
  },
  tankCount: { english: 'Tank Count', LATAMSpanish: 'Cantidad de tanques' }
};

export const productHeaderTranslations = {
  products: { english: 'Products', LATAMSpanish: 'Productos' },
  nameActive: { english: 'Name/Active', LATAMSpanish: 'Nombre/activo' },
  reg: { english: 'Reg #', LATAMSpanish: 'N.° de reg.' },
  pest: { english: 'Pest', LATAMSpanish: 'Peste' },
  rei: { english: 'REI', LATAMSpanish: 'IER' },
  phi: { english: 'PHI', LATAMSpanish: 'PHI' },
  appMethod: { english: 'App Method', LATAMSpanish: 'Método de aplicación' },
  appArea: { english: 'App Area', LATAMSpanish: 'Área de aplicación' },
  rateAC: { english: 'Rate a/c.', LATAMSpanish: 'Tasa aplic./cult.' },
  total: { english: 'Total', LATAMSpanish: 'Total' }
};

export const fieldHeaderTranslations = {
  fields: { english: 'Fields', LATAMSpanish: 'Campos' },
  area: { english: 'Area', LATAMSpanish: 'Área' },
  farm: { english: 'Farms', LATAMSpanish: 'Granjos' },
  field: { english: 'Field', LATAMSpanish: 'Campo' },
  cropZone: { english: 'Crop Zone', LATAMSpanish: 'Zone de Cultivo' },
  crop: { english: 'Crop', LATAMSpanish: 'Cultivo' },
  centerLatLong: {
    english: 'Center Lat/Long',
    LATAMSpanish: 'Lat./long. del centro'
  },
  appArea: { english: 'App Area', LATAMSpanish: 'Área de aplicación' }
};

export const notesHeaderTranslations = {
  notes: { english: 'Notes', LATAMSpanish: 'Notas' }
};

export const workerProtectionHeaderTranslations = {
  workerProtection: {
    english: 'Worker Protection',
    LATAMSpanish: 'Protección del trabajador'
  }
};

export const signatureHeaderTranslations = {
  signature: { english: 'Signature', LATAMSpanish: 'Firma' },
  date: { english: 'Date', LATAMSpanish: 'Fecha' }
};
