import { formatCurrency } from 'helpers/formatNumber';

const getInvoiceCostVisibility = ({
  cost,
  memberRole,
  memberFinancialAccess
}) => {
  if (
    (memberRole === 'Full control' || memberRole === 'View Only') &&
    memberFinancialAccess === 'none'
  ) {
    return '';
  }

  if (
    memberRole === 'Admin' ||
    memberRole === 'View Only' ||
    (memberRole === 'Full control' && memberFinancialAccess === 'WRITE') ||
    (memberRole === 'Full control' && memberFinancialAccess === 'READ')
  ) {
    return formatCurrency(cost);
  }

  // this condition seems neve be reached, since is covere by conditional above
  // I commented out for now but this code needs clarification or correction.
  // if (memberRole === 'Full control' && memberFinancialAccess === 'READ') {
  //   return '';
  // }
  return '';
};

export default getInvoiceCostVisibility;
