import React from 'react';
import PropTypes from 'prop-types';
import { useFormikContext } from 'formik';
import { Input } from 'syngenta-digital-cropwise-react-ui-kit';

const FormulationInput = ({ fieldValue }) => {
  const { values, setFieldValue } = useFormikContext();

  const formatPercent = e => {
    const formattedPercent = parseFloat(
      e.target.value.replace(/%/g, '')
    ).toFixed(2);
    setFieldValue(
      `formulation.${fieldValue}`,
      isNaN(formattedPercent) ? '' : `${formattedPercent}%`
    );
  };

  return (
    <>
      <div className="text-neutral-60">{fieldValue}</div>
      <Input
        placeholder="0%"
        name={`formulation.${fieldValue}`}
        value={values.formulation?.[fieldValue]}
        onChange={e => {
          setFieldValue(`formulation.${fieldValue}`, e.target.value);
        }}
        onBlur={formatPercent}
      />
    </>
  );
};

FormulationInput.propTypes = {
  fieldValue: PropTypes.string.isRequired
};

export default FormulationInput;
