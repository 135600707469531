import React, { useState, useContext, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Button, Input, Form, Spinner } from '@agconnections/grow-ui';
import { useHistory } from 'react-router-dom';
import AgrianLogo from 'assets/integrations/AgrianLogo.svg';
import useAgrianApi from 'hooks/useAgrianApi';
import { INTEGRATIONS } from 'screens/Integrations/helpers/constants';
import events from 'utilities/amplitude/events';
import useSubmissionAnalytics from 'screens/Integrations/hooks/useSubmissionAnalytics';
import { AgrianContext } from '../../context/Provider';

const AgrianAuthenticationPage = ({ buttonText, integrationType }) => {
  const { triggerAnalyticsEvent } = useSubmissionAnalytics({
    onMountTriggerEvent: {
      event: events.epic.Integrations.submissionStarted,
      data: { vendor: integrationType }
    }
  });
  const history = useHistory();
  const { goForward } = useContext(AgrianContext);
  const { loading, growers, authenticate } = useAgrianApi();
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');

  useEffect(() => {
    async function checkAuthenticated() {
      const hideRequestErrors = true;
      const response = await growers(hideRequestErrors);
      if (response) {
        triggerAnalyticsEvent(
          events.epic.Integrations.submissionCredentialsFilled,
          { vendor: integrationType }
        );
        goForward();
      }
    }

    checkAuthenticated();
  }, [growers, goForward, triggerAnalyticsEvent, integrationType]);

  const handleSubmit = async e => {
    e.preventDefault();

    const response = await authenticate({ email, password });

    if (response) {
      triggerAnalyticsEvent(
        events.epic.Integrations.submissionCredentialsFilled,
        { vendor: integrationType }
      );
      goForward();
    }
    return false;
  };

  return (
    <div className="flex self-center bg-white h-100 w-130 rounded-md mt-10 p-6 shadow-sm">
      <Form onSubmit={handleSubmit}>
        <div className="w-full mb-5 flex gap-5 items-center">
          <div className="rounded-xl border p-3">
            <img src={AgrianLogo} alt="" />
          </div>
          <div className="flex flex-col justify-center">
            <h3 className="font-bold text-2xl">Agrian Integration</h3>
            <h2>Sign in with your Agrian credentials</h2>
          </div>
        </div>
        <hr />
        {loading ? (
          <div className="flex w-full flex-col items-center justify-center h-full pb-20">
            <Spinner />
          </div>
        ) : (
          <>
            <div className="flex flex-col w-full items-center justify-center">
              <div className=" w-full">
                <Form.Field label="E-mail *" labelFor="agrianEmail">
                  <Input
                    name="agrianEmail"
                    type="email"
                    id="agrianEmail"
                    required
                    value={email}
                    style={{ paddingLeft: '10px', borderColor: '#e2e8f0' }}
                    placeholder="Enter your e-mail address from Agrian vendor"
                    onChange={e => {
                      setEmail(e.target.value);
                    }}
                  />
                </Form.Field>
              </div>
              <div className="w-full">
                <Form.Field label="Password *" labelFor="agrianPassword">
                  <Input
                    name="agrianPassword"
                    type="password"
                    id="agrianPassword"
                    required
                    value={password}
                    placeholder="Enter your password from Agrian vendor"
                    style={{ paddingLeft: '10px', borderColor: '#e2e8f0' }}
                    onChange={e => {
                      setPassword(e.target.value);
                    }}
                  />
                </Form.Field>
              </div>
            </div>
            <div className="flex flex-row w-full mt-10 items-start justify-between">
              <Button
                type="outline"
                className="absolute"
                onClick={history.goBack}
              >
                {'< Back'}
              </Button>
              <Button type="submit">{buttonText}</Button>
            </div>
          </>
        )}
      </Form>
    </div>
  );
};

AgrianAuthenticationPage.propTypes = {
  buttonText: PropTypes.string,
  integrationType: PropTypes.string
};

AgrianAuthenticationPage.defaultProps = {
  buttonText: 'Start New Submission',
  integrationType: INTEGRATIONS.agrian
};

export default AgrianAuthenticationPage;
