import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { ErrorMessage, Field } from 'formik';
import UOMDropdown from 'components/UOMDropdown';
import { YIELD_DEFAULT_ROUND } from 'screens/YieldV2/constants';
import { AmplitudeContext } from 'utilities/amplitude/useAmplitude';
import FormInputWithPrecisionDisplay from '../../../FormInputWithPrecisionDisplay';

const YieldQuantityField = ({ label, fieldName, selectedCropName }) => {
  const amplitude = useContext(AmplitudeContext);

  return (
    <div className="flex flex-col gap-0.5">
      <label
        htmlFor={fieldName}
        className="text-neutral-60 tracking-tighter text-sm"
      >
        {label}
        <span className="text-remove">*</span>
      </label>
      <div className="flex items-start yield-quantity-input">
        <FormInputWithPrecisionDisplay
          fieldName={fieldName}
          isRequired
          precision={2}
          id={fieldName}
          round={YIELD_DEFAULT_ROUND}
        />
        <Field name="loadUnit">
          {({ field, form }) => (
            <UOMDropdown
              value={field.value}
              onChange={value => {
                amplitude.sendEventToAmplitude(
                  amplitude.events.epic.YieldV2.addYield.selectUOM,
                  {
                    type: value
                  }
                );
                form.setFieldValue(field.name, value);
              }}
              cropName={selectedCropName}
              className="flex-1"
              isRequired
              minWidth="50%"
            />
          )}
        </Field>
      </div>
      <div className="absolute" style={{ width: '45%', top: '58px' }}>
        <ErrorMessage
          name="load"
          component="div"
          className="text-remove text-wrap"
        />
      </div>
    </div>
  );
};

YieldQuantityField.propTypes = {
  label: PropTypes.string.isRequired,
  fieldName: PropTypes.string.isRequired,
  selectedCropName: PropTypes.string.isRequired
};

export default YieldQuantityField;
