export const SET_SELECTED_CROPYEAR = 'SET_SELECTED_CROPYEAR';
export const SET_UNMATCHED_ENTITIES = 'SET_UNMATCHED_ENTITIES';
export const SET_VENDOR_PROPERTIES = 'SET_VENDOR_PROPERTIES';
export const SET_MATCHES_SUBMISSION = 'SET_MATCHES_SUBMISSION';
export const SET_OPEN_CONFIRMATION_MODAL = 'SET_OPEN_CONFIRMATION_MODAL';
export const SET_FARMS_TO_MATCH = 'SET_FARMS_TO_MATCH';

const reducer = (state, action) => {
  switch (action.type) {
    case SET_VENDOR_PROPERTIES:
      return {
        ...state,
        vendorProperties: action.payload
      };
    case SET_SELECTED_CROPYEAR:
      return {
        ...state,
        selectedCropYear: action.payload
      };
    case SET_UNMATCHED_ENTITIES:
      return {
        ...state,
        unmatchedEntities: action.payload
      };
    case SET_MATCHES_SUBMISSION:
      return {
        ...state,
        matches: action.payload
      };
    case SET_OPEN_CONFIRMATION_MODAL:
      return {
        ...state,
        openConfirmationModal: action.payload
      };
    case SET_FARMS_TO_MATCH:
      return {
        ...state,
        farmsMatch: action.payload
      };
    default:
      return state;
  }
};

export default reducer;
