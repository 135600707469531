/* eslint-disable react/prop-types */
import React, { useContext, useState, useEffect, useCallback } from 'react';
import ViewOnlyField from 'components/ViewOnlyField';
import { Spinner } from '@agconnections/grow-ui';
import { startCase, camelCase } from 'lodash';
import PropTypes from 'prop-types';
import { Context } from 'components/Store';
import { useParams, useHistory } from 'react-router-dom';
import Paper from 'components/Paper';
import { parseServerError } from 'helpers/errorHelpers';
import catchCancel from 'helpers/catchCancel';
import useOrganizationApi from 'hooks/useOrganizationApi';
import { formatEpochDate } from 'utilities/dateHelpers';
import FormattedNumber from 'components/FormattedNumber';
import EllipseMenu from 'components/EllipseMenu';
import Breadcrumb from 'components/App/AppShell/components/Breadcrumb';
import { deleteMenuOption, EQUIPMENT_VIEW_MENU } from 'utilities/menus';
import { ROLES } from 'utilities/access';
import { paths } from 'routes/paths';
import DeleteEquipmentModal from '../DeleteEquipmentModal';

const EquipmentCosts = ({ equipment }) => {
  return (
    <>
      <div className="flex py-5">
        <ViewOnlyField
          data={equipment?.productName || '--'}
          header="Service"
          className="w-1/3"
          text="text-lg"
        />
        <div className="w-1/3">
          <ViewOnlyField
            header="Operation Cost"
            className="w-1/3"
            text="text-lg"
          />
          <FormattedNumber unit="usCurrency" roundedTo={2}>
            {equipment?.operationCost || 0}
          </FormattedNumber>
        </div>
      </div>
    </>
  );
};

const PurchaseHistory = ({ equipment, nonFinancialAccess }) => {
  const { valuations } = equipment;
  const purchaseValues = valuations
    ? valuations.find(item => {
        return item.type === 'PURCHASE';
      })
    : {};

  return (
    <>
      <div className="flex py-5">
        <div className="w-1/3">
          <ViewOnlyField
            // data={purchaseValues?.amount || '--'}
            header="Purchase Price"
            className="w-1/3"
            text="text-lg"
          />
          {nonFinancialAccess() ? (
            <FormattedNumber unit="usCurrency" roundedTo={2}>
              {purchaseValues?.amount || ''}
            </FormattedNumber>
          ) : null}
        </div>
        <ViewOnlyField
          data={
            purchaseValues &&
            purchaseValues.dateEpoch &&
            purchaseValues.dateEpoch !== 0
              ? formatEpochDate(purchaseValues.dateEpoch)
              : '--'
          }
          header="Purchase Date"
          className="w-1/3"
          text="text-lg"
        />
      </div>
    </>
  );
};

const CurrentValue = ({ equipment, nonFinancialAccess }) => {
  const { valuations } = equipment;
  const currentValues = valuations
    ? valuations.find(item => {
        return item.type === 'CURRENT';
      })
    : {};

  return (
    <>
      <div className="flex py-5">
        <div className="w-1/3">
          <ViewOnlyField
            // data={currentValues?.amount || '--'}
            header="Current Value"
            id="currentValue"
            className="w-1/3"
            text="text-lg"
          />
          {nonFinancialAccess() ? (
            <FormattedNumber unit="usCurrency" roundedTo={2}>
              {currentValues?.amount || 0}
            </FormattedNumber>
          ) : null}
        </div>
        <ViewOnlyField
          data={
            currentValues &&
            currentValues.dateEpoch &&
            currentValues.dateEpoch !== 0
              ? formatEpochDate(currentValues.dateEpoch)
              : '--'
          }
          header="Current Value Date"
          id="currentValueDate"
          className="w-1/3"
          text="text-lg"
        />
      </div>
    </>
  );
};

const BasicInfoView = ({ equipment }) => {
  return (
    <>
      <div className="flex py-5">
        <ViewOnlyField
          data={equipment?.name || '--'}
          header="Name"
          className="w-1/3"
          text="text-lg"
        />
        <ViewOnlyField
          data={startCase(camelCase(equipment?.type?.split('_'))) || '--'}
          header="Type"
          className="w-1/3"
          text="text-lg"
        />
      </div>
      <div className="flex py-5">
        <ViewOnlyField
          data={equipment?.year?.toString() || '--'}
          header="Year"
          className="w-1/3"
          text="text-lg"
        />
        <ViewOnlyField
          data={equipment?.make || '--'}
          header="Make"
          className="w-1/3"
          text="text-lg"
        />
        <ViewOnlyField
          data={equipment?.model || '--'}
          header="Model"
          className="w-1/3"
          text="text-lg"
        />
      </div>
      <div className="flex py-5">
        <ViewOnlyField
          data={
            startCase(camelCase(equipment?.statusHistory[0]?.status)) || '--'
          }
          header="Current Status"
          className="w-1/3"
          text="text-lg"
        />
        <ViewOnlyField
          data={formatEpochDate(equipment?.statusHistory[0]?.dateEpoch) || '--'}
          header="Current Status Date"
          className="w-1/3"
          text="text-lg"
        />
        <ViewOnlyField
          data={equipment?.unitId || '--'}
          header="Unit ID"
          className="w-1/3"
          text="text-lg"
        />
      </div>
      <div className="flex py-5">
        <ViewOnlyField
          data={equipment?.serialNumber || '--'}
          header="Serial Number"
          className="w-1/3"
          text="text-lg"
        />
        <ViewOnlyField
          data={equipment?.licenseNumber || '--'}
          header="License Number"
          className="w-1/3"
          text="text-lg"
        />
      </div>
    </>
  );
};

const EquipmentView = () => {
  const [equipmentItem, setEquipmentItem] = useState({});
  const [loading, setLoading] = useState(true);
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const openDeleteModal = () => setDeleteModalOpen(true);
  const closeDeleteModal = () => {
    setDeleteModalOpen(false);
  };
  const [, dispatch] = useContext(Context);
  const history = useHistory();
  const { id } = useParams();
  const equipmentAPI = useOrganizationApi('equipment');
  const [financialAccess, setFinancialAccess] = useState();

  const [{ loggedInUserOrgPermission }] = useContext(Context);

  const menu =
    loggedInUserOrgPermission?.role !== ROLES.VIEW_ONLY
      ? deleteMenuOption(EQUIPMENT_VIEW_MENU, 'shareOrPrint')
      : EQUIPMENT_VIEW_MENU;

  useEffect(() => {
    if (
      (loggedInUserOrgPermission?.role === 'View Only' ||
        loggedInUserOrgPermission?.role === 'Full control') &&
      !Object.prototype.hasOwnProperty.call(
        loggedInUserOrgPermission,
        'financialAccess'
      )
    ) {
      setFinancialAccess('none');
    } else {
      setFinancialAccess(loggedInUserOrgPermission?.financialAccess);
    }
  }, [loggedInUserOrgPermission]);
  const nonFinancialAccess = useCallback(() => {
    if (
      loggedInUserOrgPermission?.role === 'View Only' &&
      financialAccess === 'none'
    ) {
      return false;
    }
    return true;
  }, [loggedInUserOrgPermission.role, financialAccess]);

  useEffect(() => {
    setLoading(true);
    const { promise } = equipmentAPI.fetch(id);
    promise
      .then(({ data }) => {
        setEquipmentItem(data);
        setLoading(false);
      })
      .catch(catchCancel)
      .catch(err => {
        parseServerError(dispatch)(err);
        setLoading(false);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleAction = action => {
    switch (action) {
      case 'edit':
        history.push(`${paths.equipment}/${id}`);
        break;
      case 'delete':
        openDeleteModal();
        break;
      case 'share':
        break;
      default:
        break;
    }
  };

  const getDeleteModal = () => {
    return (
      <DeleteEquipmentModal
        open={deleteModalOpen}
        close={closeDeleteModal}
        equipmentName={equipmentItem?.name}
        equipmentId={equipmentItem?.id}
        label="view"
      />
    );
  };
  return (
    <>
      <div className="w-full h-full" data-testid="equipment-view">
        <Breadcrumb disabled>
          <Breadcrumb.Item
            title="Equipment"
            value="All Equipment"
            to={paths.equipment}
          />
          <Breadcrumb.Item
            title="Equipment"
            value={equipmentItem?.name}
            isLast
          />
        </Breadcrumb>
        {loading ? (
          <Spinner />
        ) : (
          <div>
            {getDeleteModal()}
            <div className="mb-10" data-testid="equipment-view-loaded">
              <div className="flex items-center">
                <div className="w-full flex mr-4">
                  <div className="text-3xl font-med my-6">View Equipment</div>
                </div>
                <div className="flex">
                  <div className="w-1/2 flex mr-4">
                    <div className="flex mx-2">
                      <EllipseMenu onAction={handleAction} options={menu} />
                    </div>
                  </div>
                </div>
              </div>
              <Paper>
                <div className="bg-white w-full p-6">
                  <div className="w-full">
                    <div
                      data-testid="more-info-title"
                      className="text-2xl font-bold "
                    >
                      Basic Info
                    </div>
                    <BasicInfoView equipment={equipmentItem} />
                  </div>
                  <div className="w-full border-b py-5" />
                </div>
                <div className="bg-white w-full p-6">
                  <div className="w-full">
                    <div
                      data-testid="more-info-title"
                      className="text-2xl font-bold "
                    >
                      History Info
                    </div>
                    <div>
                      <span>
                        <br />
                      </span>
                    </div>
                    <div
                      data-testid="more-info-title"
                      className="text-lg font-bold"
                    >
                      Purchase History
                    </div>
                    <PurchaseHistory
                      equipment={equipmentItem}
                      nonFinancialAccess={nonFinancialAccess}
                    />
                    <div
                      data-testid="more-info-title"
                      className="text-lg font-bold"
                    >
                      Current Value
                    </div>
                    <CurrentValue
                      equipment={equipmentItem}
                      nonFinancialAccess={nonFinancialAccess}
                    />
                  </div>
                  <div className="w-full border-b py-5" />
                </div>
                {/* hide Equipment view for know to aligh with mobile */}
                {/* {nonFinancialAccess() ? (
                  <div className="bg-white w-full p-6">
                    <div className="w-full">
                      <div
                        data-testid="more-info-title"
                        className="text-2xl font-bold "
                      >
                        Equipment Costs
                      </div>
                      {equipmentItem.services ? (
                        equipmentItem.services.map(item => {
                          return (
                            <EquipmentCosts
                              equipment={item}
                              key={item.productId}
                            />
                          );
                        })
                      ) : (
                        <EquipmentCosts equipment={null} />
                      )}
                    </div>
                    <div className="w-full border-b py-5" />
                  </div>
                ) : null} */}
                <div className="bg-white w-full p-6">
                  <div className="w-full">
                    <div
                      data-testid="more-info-title"
                      className="text-2xl font-bold "
                    >
                      Notes
                    </div>
                    <ViewOnlyField
                      data={equipmentItem?.notes || '--'}
                      text="text-lg"
                    />
                  </div>
                </div>
              </Paper>
            </div>
          </div>
        )}
      </div>
    </>
  );
};

EquipmentCosts.propTypes = {
  equipment: PropTypes.shape({
    productName: PropTypes.string,
    operationCost: PropTypes.number
  })
};

PurchaseHistory.propTypes = {
  equipment: PropTypes.shape({
    name: PropTypes.string.isRequired,
    type: PropTypes.string.isRequired,
    valuations: PropTypes.arrayOf(
      PropTypes.shape({
        find: PropTypes.func
      }).isRequired
    )
  })
};

PurchaseHistory.defaultProps = {
  equipment: {
    valuations: {
      find: () => {}
    }
  }
};

EquipmentCosts.defaultProps = {
  equipment: {
    productName: '',
    operationCost: null
  }
};

PurchaseHistory.defaultProps = {
  equipment: {
    valuations: {
      find: () => {}
    }
  }
};

export default EquipmentView;
