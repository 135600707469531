import { useCallback, useContext, useState } from 'react';
import { integrations as integrationsApi } from 'utilities/api';
import catchCancel from 'helpers/catchCancel';
import { useToast } from 'components/ToastContainer';
import { Context } from 'components/Store';
import { parseToastServerError } from 'helpers/errorHelpers';

const useIntegrationMatching = () => {
  const [loading, setLoading] = useState(false);
  const [, dispatch] = useContext(Context);
  const toast = useToast();
  const orgId = localStorage.getItem('selectedOrganizationId');

  const getMatches = useCallback(
    ({ vendor, matchTypes = [] }) => {
      const { promise } = integrationsApi
        .createChildApi({
          action: `integrations/${vendor}/match`
        })
        .fetch(
          null,
          {
            types: matchTypes.join(',')
          },
          {
            headers: {
              'cwf-context': JSON.stringify({
                organization_id: orgId
              })
            }
          }
        );

      setLoading(true);

      return promise
        .then(res => res)
        .catch(catchCancel)
        .catch(
          parseToastServerError(
            {
              title: 'Unable to retrieve matches',
              content:
                'An error prevented to retrieve matches. Please try again.'
            },
            toast,
            dispatch
          )
        )
        .finally(() => setLoading(false));
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [orgId]
  );

  const createMatchingProducts = useCallback(
    ({ vendor, products }) => {
      const { promise } = integrationsApi
        .createChildApi({
          action: `integrations/${vendor}/match/products`
        })
        .create(products, {
          headers: {
            'cwf-context': JSON.stringify({
              organization_id: orgId
            })
          }
        });

      setLoading(true);

      return promise
        .then(res => res)
        .catch(catchCancel)
        .catch(error => {
          parseToastServerError(
            {
              title: 'Unable to create product match',
              content: `An error prevented to create product match. Please try again. \nError: ${error.message}`
            },
            toast,
            dispatch
          )(error);
        })
        .finally(() => setLoading(false));
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [orgId]
  );

  const createMatchingProductsV2 = useCallback(
    ({ vendor, products }) => {
      const { promise } = integrationsApi
        .createChildApi({
          action: `integrations/${vendor}/match/products/v2`
        })
        .create(products, {
          headers: {
            'cwf-context': JSON.stringify({
              organization_id: orgId
            })
          }
        });

      setLoading(true);

      return promise
        .then(res => res)
        .catch(catchCancel)
        .catch(error => error)
        .finally(() => setLoading(false));
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [orgId]
  );

  const createMatches = useCallback(
    ({ vendor, matches }) => {
      const { promise } = integrationsApi
        .createChildApi({
          action: `integrations/${vendor}/match/`
        })
        .create(matches, {
          headers: {
            'cwf-context': JSON.stringify({
              organization_id: orgId
            })
          }
        });

      setLoading(true);

      return promise
        .then(res => res)
        .catch(catchCancel)
        .catch(error => {
          toast.error('Unable to create match', {
            content: `An error prevented to create match. Please try again. \nError: ${error.message}`,
            supportButton: true,
            timeout: 30000
          });
          return error;
        })
        .finally(() => setLoading(false));
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [orgId]
  );

  return {
    getMatches,
    createMatches,
    createMatchingProducts,
    createMatchingProductsV2,
    loading
  };
};

export default useIntegrationMatching;
