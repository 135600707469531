import React from 'react';
import PropTypes from 'prop-types';

const CardGalleryIntegration = ({ children }) => {
  return (
    <div className="flex flex-row gap-4" data-testid="integration-gallery-card">
      {children}
    </div>
  );
};

CardGalleryIntegration.propTypes = {
  children: PropTypes.node.isRequired
};
export default CardGalleryIntegration;
