import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { Alert } from 'syngenta-digital-cropwise-react-ui-kit';
import { SubmissionSummaryContext } from 'screens/Integrations/SubmissionSummary/context/SubmissionSummaryProvider';
import PropertyRow from 'screens/Property/PropertiesLanding/PropertyTable/PropertyRow';
import '../../index.css';

const SubmissionDetails = ({ forms, counties }) => {
  const {
    state: { fields }
  } = useContext(SubmissionSummaryContext);

  const productsInSubmission = [
    ...new Set(
      forms.flatMap(form =>
        form.task.products.flatMap(product => product.productName)
      )
    )
  ];

  return (
    <div>
      <Alert
        className="calag-submission-details-load-alerts"
        message="You will be unable to change this information once submitted, verify it is correct before you submit."
        showIcon
        type="warning"
      />
      <div className="mt-10 flex flex-col w-full">
        <span className="mb-4 font-bold">Submission Details</span>
        <PropertyRow
          label="Total Pesticide Usage Reports"
          value={`${forms.length}`}
        />
        <PropertyRow
          label="Total Fields in Submission"
          value={`${fields.length}`}
        />
        <PropertyRow
          label="Counties in Submission"
          value={counties.map(county => county.name)}
        />
        <PropertyRow
          label="Products in Submission"
          value={productsInSubmission}
        />
      </div>
    </div>
  );
};

SubmissionDetails.propTypes = {
  forms: PropTypes.arrayOf(
    PropTypes.shape({
      task: PropTypes.shape({
        products: PropTypes.arrayOf(
          PropTypes.shape({
            productName: PropTypes.string.isRequired
          })
        ).isRequired
      }).isRequired
    })
  ).isRequired,
  counties: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired
    })
  ).isRequired
};

export default SubmissionDetails;
