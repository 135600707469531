import { NATIONAL_ZOOM } from './constants';

import {
  reducerUpdatePropertiesDataState,
  reducerUpdateFieldsLoadingState,
  reducerSetGeoJSONCollectionFeatures,
  reducerCalculateGeoJSONCentroidState,
  reducerResetMapViewState,
  reducerSetState,
  reducerUpdateSingleFarmDataState,
  setPropertyCosts,
  reducerUpdatePaginatedPropertiesDataState
} from './propertyReducerHelpers';

// used to initialize the reloading of the fields data
export const PROPERTY_DATA_INIT_RELOAD = 'PROPERTY_DATA_INIT_RELOAD';
export const PROPERTY_PAGINATED_DATA_INIT_RELOAD =
  'PROPERTY_PAGINATED_DATA_INIT_RELOAD';
// use the data returned in the response from the properties request as the action payload
export const PROPERTY_DATA_LOAD_PROPERTIES = 'PROPERTY_DATA_LOAD_PROPERTIES';
export const PROPERTY_DATA_PAGINATED_LOAD_PROPERTIES =
  'PROPERTY_DATA_PAGINATED_LOAD_PROPERTIES';
export const RESET_PRELOADED_PAGES = 'RESET_PRELOADED_PAGES';
// set the fields loading flag to false
export const PROPERTY_DATA_SET_FIELDS_LOADED =
  'PROPERTY_DATA_SET_FIELDS_LOADED';
export const PROPERTY_DATA_SET_GEOJSON_COLLECTION_FEATURES =
  'PROPERTY_DATA_SET_GEOJSON_COLLECTION_FEATURES';
// calculate the value for fieldsAndAreasCentroidCoordinates based on state from fieldsAndAreasGeoJSONCollection
export const PROPERTY_DATA_CALCULATE_CENTROID =
  'PROPERTY_DATA_CALCULATE_CENTROID';
// reset the zoom and map position when there are no features to center on
export const PROPERTY_DATA_RESET_MAP_VIEW = 'PROPERTY_DATA_RESET_MAP_VIEW';
// set fields state with a setState-like argument for the action payload
export const PROPERTY_DATA_SET_FIELDS = 'PROPERTY_DATA_SET_FIELDS';
// set fieldsAndAreasCentroidCoordinates state with a setState-like argument for the action payload
export const PROPERTY_DATA_SET_MAP_CENTROID = 'PROPERTY_DATA_SET_MAP_CENTROID';
// set zoom state with a setState-like argument for the action payload
export const PROPERTY_DATA_SET_ZOOM = 'PROPERTY_DATA_SET_ZOOM';

export const SET_PROPERTY_FARM_DATA = 'SET_PROPERTY_FARM_DATA';

export const SET_PROPERTY_COSTS = 'SET_PROPERTY_COSTS';

const initState = {
  data: {
    beforeSearch: null,
    pagination: {
      preLoadedPages: []
    },
    properties: []
  },
  loadedBoundedAreaCount: 0,
  zoom: NATIONAL_ZOOM,
  fieldsAndAreasCentroidCoordinates: null,
  fieldsAndAreasGeoJSONCollection: {
    type: 'FeatureCollection',
    features: []
  },
  loading: true,
  fieldsLoading: false,
  shapesLoading: true,
  costs: {}
};

export const resetPropertyData = () => initState;

const reducerActionHandlers = {
  [PROPERTY_DATA_INIT_RELOAD]: () => ({
    ...initState,
    fieldsLoading: true
  }),
  [PROPERTY_PAGINATED_DATA_INIT_RELOAD]: state => ({
    ...state,
    isLoadingPaginatedProperties: true
  }),
  [RESET_PRELOADED_PAGES]: resetPropertyData,
  [PROPERTY_DATA_LOAD_PROPERTIES]: reducerUpdatePropertiesDataState,
  [PROPERTY_DATA_PAGINATED_LOAD_PROPERTIES]: reducerUpdatePaginatedPropertiesDataState,
  [PROPERTY_DATA_SET_FIELDS_LOADED]: reducerUpdateFieldsLoadingState(false),
  [PROPERTY_DATA_SET_GEOJSON_COLLECTION_FEATURES]: reducerSetGeoJSONCollectionFeatures,
  [PROPERTY_DATA_CALCULATE_CENTROID]: reducerCalculateGeoJSONCentroidState,
  [PROPERTY_DATA_RESET_MAP_VIEW]: reducerResetMapViewState,
  [PROPERTY_DATA_SET_FIELDS]: reducerSetState('fields'),
  [PROPERTY_DATA_SET_MAP_CENTROID]: reducerSetState(
    'fieldsAndAreasCentroidCoordinates'
  ),
  [PROPERTY_DATA_SET_ZOOM]: reducerSetState('zoom'),
  [SET_PROPERTY_FARM_DATA]: reducerUpdateSingleFarmDataState,
  [SET_PROPERTY_COSTS]: setPropertyCosts
};

export const propertyDataReducer = (state, action) => {
  const actionHandler = reducerActionHandlers[action.type];
  if (actionHandler) {
    return actionHandler(state, action);
  }
  return state;
};
