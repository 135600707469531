import PDF from 'components/PDF';
import Landing from 'screens/Landing';
import LoginCallback from 'utilities/base-auth/LoginCallback';

import AppRoutes from './App';
import withFeatureAware from './withFeatureAware';

const routes = [
  ...AppRoutes,
  {
    id: 'login',
    key: 'login',
    path: ['/login', '/authenticate'],
    exact: true,
    secure: false,
    component: LoginCallback
  },
  {
    id: 'landing',
    key: 'landing',
    path: '/welcome',
    exact: true,
    secure: false,
    component: Landing
  },
  {
    id: 'PDF',
    key: 'PDF',
    path: '/PDF',
    exact: true,
    secure: false,
    component: PDF
  }
];

export default withFeatureAware(routes);
