import { useState, useEffect, useCallback } from 'react';
import { tasks as tasksApi } from 'utilities/api';
import catchCancel from 'helpers/catchCancel';

const useInventoryTasksData = prodId => {
  const [tasks, setTasks] = useState([]);
  const [tasksLoading, setTasksLoading] = useState(true);
  const [tasksError, setTasksError] = useState(null);

  const getAllTasks = useCallback(productId => {
    const { promise } = tasksApi.fetch(`?size=${100}`);
    promise
      .then(data => {
        const apiTasks = productId
          ? data.data.results
              .filter(task => {
                const hasProduct =
                  task.products.filter(prod => prod.productId === productId)
                    .length > 0;
                return hasProduct ? task : null;
              })
              .filter(task => task.status === 'complete')
          : data.data.results;
        setTasks(apiTasks);
        setTasksLoading(false);
      })
      .catch(err => {
        setTasksError(err);
        setTasksLoading(false);
      })
      .catch(catchCancel);
  }, []);

  useEffect(() => {
    getAllTasks(prodId);
  }, [prodId, getAllTasks]);

  return { tasks, tasksLoading, tasksError };
};

export default useInventoryTasksData;
