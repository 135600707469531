import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { TEMPLATE_TYPE } from 'screens/YieldV2/constants';
import {
  hiddenAttributesForTemplates,
  labelInputs,
  tabsLabels
} from '../../helpers/constants';

const useParameters = (tab, { source, destination }, templateType) => {
  const [removeAttributes, setRemoveAttributes] = useState([]);
  if (!templateType) {
    // eslint-disable-next-line no-param-reassign
    templateType = TEMPLATE_TYPE.ALL_CROPS;
  }

  useEffect(() => {
    // This is bare bones right now. In the future, source and destination types will determine different signature values for each tab.
    // For example, depending on the source, some values in each tab might be removed; the same logic applies to destination tabs.
    // For now, source and destination do not factor in since we are only using the default definition in the constant hiddenAttributesForDefaultTemplate.
    if (source || destination) {
      const tabMapping = {
        [tabsLabels.saleInfo]:
          hiddenAttributesForTemplates[templateType].saleTab,
        [tabsLabels.yieldDetails]:
          hiddenAttributesForTemplates[templateType].yieldTab,
        [tabsLabels.weight]:
          hiddenAttributesForTemplates[templateType].weightTab,
        [tabsLabels.loadAttributes]:
          hiddenAttributesForTemplates[templateType].loudAttributeTab,
        [tabsLabels.counts]:
          hiddenAttributesForTemplates[templateType].countsTab,
        [tabsLabels.other]: hiddenAttributesForTemplates[templateType].otherTab,
        [tabsLabels.notes]: hiddenAttributesForTemplates[templateType].notesTab
      };
      const renderedValue = _.cloneDeep(tabMapping[tab]);
      if (tab === tabsLabels.yieldDetails && source !== 'CROP_ZONE') {
        renderedValue.push(labelInputs.avgHarvestedQuantity);
      }

      if (renderedValue) {
        setRemoveAttributes(renderedValue);
      }
    }
  }, [tab, source, destination, templateType]);

  return {
    removeAttributes
  };
};
useParameters.propTypes = {
  tab: PropTypes.string.isRequired,
  sourceAndDestination: PropTypes.shape({
    source: PropTypes.string,
    destination: PropTypes.string
  }),
  templateType: PropTypes.string
};
useParameters.defaultProps = {
  sourceAndDestination: {
    source: '',
    destination: ''
  }
};

export default useParameters;
