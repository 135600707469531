/* eslint-disable react/prop-types */
import React from 'react';
import PropTypes from 'prop-types';
import { Table, Spinner } from '@agconnections/grow-ui';
import { Link } from 'react-router-dom';
import { formatEpochDate } from 'utilities/dateHelpers';
import EllipseMenu from 'components/EllipseMenu';
import { paths } from 'routes/paths';
import formatInvoiceCropName from 'screens/Invoices/helpers/formatInvoiceCropName';

const InvoiceTableRow = ({
  invoice,
  onAction,
  getTotInvCostValue,
  menuOptions,
  runningProcesses
}) => {
  return (
    <Table.Row key={invoice?.invoiceId}>
      <Table.Cell>
        <div className="flex flex-col items-start text-sm font-bold">
          <Link to={`${paths.invoices}/${invoice.invoiceId}/view`}>
            {invoice?.title}
          </Link>
          <div className="flex flex-wrap w-full mt-2 mb-2">
            {invoice.flags &&
              invoice.flags.map(tag => {
                return (
                  <div
                    className="border rounded pl-1 pr-1 mr-2"
                    style={{
                      backgroundColor: tag.color,
                      color: '#FFFFFF'
                    }}
                  >
                    {tag.name}
                  </div>
                );
              })}
          </div>
        </div>
      </Table.Cell>
      <Table.Cell>
        {invoice.invoiceDate
          ? formatEpochDate(invoice.invoiceDateEpoch)
          : 'N/A'}
      </Table.Cell>
      <Table.Cell>
        {invoice.invoiceDueDate
          ? formatEpochDate(invoice.invoiceDueDateEpoch)
          : 'N/A'}
      </Table.Cell>
      <Table.Cell>{getTotInvCostValue(invoice?.totalCost)}</Table.Cell>
      <Table.Cell>{invoice?.productCount || '0'}</Table.Cell>
      <Table.Cell>
        {formatInvoiceCropName(invoice?.cropSeasons)}
        <div
          data-testid="invoice-table-options-menu"
          className="absolute right-0 flex-none w-10 mr-2"
        >
          {runningProcesses ? (
            <Spinner size="sm" />
          ) : (
            <EllipseMenu onAction={onAction} options={menuOptions} />
          )}
        </div>
      </Table.Cell>
    </Table.Row>
  );
};

InvoiceTableRow.propTypes = {
  invoice: PropTypes.shape({
    onAction: PropTypes.func,
    invoiceId: PropTypes.string,
    invoiceDate: PropTypes.instanceOf(Date),
    invoiceDueDate: PropTypes.instanceOf(Date),
    totalCost: PropTypes.number,
    productCount: PropTypes.number,
    cropSeasons: PropTypes.string,
    title: PropTypes.string,
    flags: PropTypes.arrayOf(PropTypes.object),
    cropNames: PropTypes.string
  }).isRequired,
  menuOptions: PropTypes.arrayOf(PropTypes.object).isRequired,
  onAction: PropTypes.func.isRequired,
  getTotInvCostValue: PropTypes.func.isRequired
};

export default InvoiceTableRow;
