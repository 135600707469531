import { extractAlphanumericPartsToSort } from 'helpers/multiLevelSort';

const cropHistoryTableSort = (field, item) => {
  const isAlphanumericField =
    field === 'crop' || field === 'variety' || field === 'cropSeasonName'; // A-Z # || Z-A #
  return isAlphanumericField
    ? extractAlphanumericPartsToSort(item[field])
    : item[field];
};
export default cropHistoryTableSort;
