import { useContext, useEffect, useState } from 'react';
import usePlansApi from 'hooks/usePlansApi';
import catchCancel from 'helpers/catchCancel';
import { parseServerError } from 'helpers/errorHelpers';
import { Context } from 'components/Store';

const usePlanData = id => {
  const plansAPI = usePlansApi('plan');
  const [, dispatch] = useContext(Context);

  const [plan, setPlan] = useState({});
  const [loading, setLoading] = useState(true);
  const [saving, setSaving] = useState(false);

  useEffect(() => {
    const isCreateScreen = id === 'plan';
    let cancel;
    if (!isCreateScreen) {
      const { promise, cancel: _cancel } = plansAPI.fetch(id);
      cancel = _cancel;
      promise
        .then(({ data }) => {
          // setPlan(transformPlanForGet(data.data));
          setPlan(data);
        })
        .catch(catchCancel)
        .catch(parseServerError(dispatch))
        .finally(() => setLoading(false));
    } else {
      setLoading(false);
    }
    return () => cancel && cancel();
  }, [dispatch, id, plansAPI]);

  const savePlan = (values, duplicating) => {
    if (id === 'plan' || duplicating === 'edit-dup') {
      const saveValues = { ...values };
      delete saveValues.organizationId;
      saveValues.cropZones = saveValues.cropZones?.map(
        // eslint-disable-next-line no-unused-vars
        ({ id: _, ...cropZone }) => cropZone
      );
      saveValues.planProductEntries = saveValues?.planProductEntries?.map(
        product => ({
          ...product,
          trackingId: '',
          productRateValue: product.productRateValue.toString()
        })
      );

      setSaving(true);
      const { promise } = plansAPI.create(saveValues);
      return promise
        .catch(catchCancel)
        .catch(parseServerError(dispatch))
        .finally(() => {
          setSaving(false);
        });
    }
    setSaving(true);
    const { promise } = plansAPI.update(id, values);
    return promise
      .catch(catchCancel)
      .catch(parseServerError(dispatch))
      .finally(() => {
        setSaving(false);
      });
  };

  return {
    plan,
    loading,
    saving,
    savePlan,
    setPlan
  };
};

export default usePlanData;
