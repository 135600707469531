import React from 'react';

import SuccessfulSubmissionBanner from './components/SuccessfulSubmissionBanner';
import FailedSubmissionBanner from './components/FailedSubmissionBanner';

const submissionBannerStatus = (
  submissionsStatus,
  dismissAction,
  listPriceUnit,
  loadingRetrySubmission,
  retryAction,
  isUpdateListPrice
) => {
  if (isUpdateListPrice) return null;
  if (
    !!submissionsStatus?.dismiss === false &&
    submissionsStatus?.failedSubmissions?.length === 0
  ) {
    return (
      <SuccessfulSubmissionBanner
        submissionsStatus={submissionsStatus}
        dismissAction={dismissAction}
      />
    );
  }

  if (
    !!submissionsStatus?.dismiss === false &&
    submissionsStatus?.failedSubmissions?.length
  ) {
    return (
      <FailedSubmissionBanner
        submissionsStatus={submissionsStatus}
        dismissAction={dismissAction}
        listPriceUnit={listPriceUnit}
        loadingRetrySubmission={loadingRetrySubmission}
        retryAction={retryAction}
      />
    );
  }
  return null;
};

export default submissionBannerStatus;
