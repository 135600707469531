import React from 'react';

const ReloadIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="22"
      viewBox="0 0 16 22"
      fill="none"
      data-testid="reload-icon"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8 0V3C12.42 3 16 6.58 16 11C16 12.57 15.54 14.03 14.76 15.26L13.3 13.8C13.75 12.97 14 12.01 14 11C14 7.69 11.31 5 8 5V8L4 4L8 0ZM2 10.9983C2 14.3083 4.69 16.9983 8 16.9983V13.9983L12 17.9983L8 21.9983V18.9983C3.58 18.9983 0 15.4183 0 10.9983C0 9.42828 0.46 7.96828 1.24 6.73828L2.7 8.19828C2.25 9.02828 2 9.98828 2 10.9983Z"
        fill="#696F88"
      />
    </svg>
  );
};

export default ReloadIcon;
