import React, { useContext } from 'react';
import { Context } from 'components/Store';
import GrowUIFormField from 'components/Forms/GrowUIFormField';
import { Select } from '@agconnections/grow-ui';
import { meridianData } from '../DataSets';

const Meridian = () => {
  const [{ isEnableInfoTabEdit }] = useContext(Context);
  return (
    <GrowUIFormField
      control={Select}
      type="text"
      id="meridian"
      name="meridian"
      items={meridianData}
      label="Meridian"
      placeholder="Select meridian"
      disabled={!isEnableInfoTabEdit}
    />
  );
};

export default Meridian;
