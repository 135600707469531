/* istanbul ignore file */
import { Factory } from 'miragejs';
import faker from 'faker';
import globalSchema from '../utils/globalSchema';

const costTypes = [
  'rent',
  'equipment',
  'insurance',
  'labor',
  'repairs',
  'taxes',
  'returnToManagement'
];

const Plan = {
  name() {
    return `Plant ${faker.random.word()}`;
  },
  crops() {
    const crops = [
      {
        $id: faker.random.uuid(),
        $ref: 'Crop'
      }
    ];

    return crops;
  },
  area() {
    return faker.random.number();
  },
  notes() {
    return faker.lorem.sentences();
  },
  costs() {
    const costs = [];
    for (let i = 0; i < 5; i += 1) {
      costs.push({
        costType: faker.random.arrayElement(costTypes),
        costValue: faker.random.number(),
        costStructure: faker.random.arrayElement(['byArea', 'total'])
      });
    }

    return costs;
  },
  revenue() {
    return {
      yieldPerArea: faker.random.number(),
      salePricePerUnit: parseFloat(faker.finance.amount()),
      fsaPayment: faker.random.number(),
      cropShare: faker.random.number({ min: 0, max: 100 })
    };
  },
  flag() {
    return faker.random.word();
  },
  cropSeason(i) {
    return {
      cropSeasonLabel: i < 3 ? '2020' : '2019',
      startDate: faker.date.recent(),
      endDate: faker.date.future()
    };
  },
  cropYear() {
    return {
      cropYearLabel: faker.random.word(),
      startDate: faker.date.recent(),
      endDate: faker.date.future()
    };
  },
  fields() {
    return null;
  },
  productApplication() {
    return null;
  },
  ...globalSchema,

  afterCreate(plan, server) {
    const timingEvent = [
      'prePlant',
      'planting',
      'growing',
      'harvest',
      'storage',
      'annual'
    ];

    server.create('crop', { id: plan.crops[0].$id });

    plan.update({
      productApplication: {
        products: server.createList('product', 5),
        timingEvent: faker.random.arrayElement(timingEvent),
        timingTag: faker.random.word(),
        targetDate: faker.date.future(),
        applicationCount: faker.random.number()
      }
    });
  }
};

export default Factory.extend(Plan);
