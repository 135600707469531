import React from 'react';
import PropTypes from 'prop-types';
import { Select } from '@agconnections/grow-ui';

const FilterByType = ({ items, value, onChange }) => {
  return (
    <Select
      // eslint-disable-next-line prettier/prettier
      style={{ whiteSpace: 'nowrap' }}
      id="type-filter-select"
      placeholder="Filter by type"
      items={items}
      value={value}
      onChange={onChange}
    />
  );
};

FilterByType.defaultProps = {
  value: undefined
};

FilterByType.propTypes = {
  items: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  value: PropTypes.string,
  onChange: PropTypes.func.isRequired
};

export default FilterByType;
