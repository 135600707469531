/* eslint-disable react/jsx-pascal-case */
import React from 'react';
import Toast from 'components/Toast';
import PropTypes from 'prop-types';
import { Button } from '@agconnections/grow-ui';

const messages = {
  error: 'Unable to save Package Weight Values',
  success: 'Package Weight Entered.',
  default: 'Package Weight Values Required'
};

const ToastPackageWeight = ({
  showToast,
  setShowToast,
  state,
  setIsDrawerOpen
}) => {
  const handleClick = () => {
    setShowToast(false);
    setIsDrawerOpen(true);
  };

  const title = messages?.[state] || messages.default;

  return (
    <Toast
      type={state}
      title={title}
      open={showToast}
      onClose={() => {
        setShowToast(false);
      }}
      customStyle={{ top: '16px' }}
      timeout={5000}
      isBold
    >
      {state === 'info' && (
        <div className="w-full">
          <p className="text-sm font-normal mb-2">
            We’ve imported your planting task records, please enter a package
            weight for each seed product.
          </p>
          <Button
            size="lg"
            type="primary"
            onClick={handleClick}
            style={{ marginLeft: 'auto' }}
          >
            Add Package Weight
          </Button>
        </div>
      )}
      {state === 'success' && (
        <div>
          <p className="text-sm font-normal mb-2">
            You can edit Package Weight in the Products page.
          </p>
        </div>
      )}
      {state === 'error' && (
        <div>
          <p className="text-sm font-normal mb-4">
            An error prevented your package weight value from being saved.
            Please try again.
          </p>
          <Button
            size="lg"
            type="primary"
            onClick={() =>
              window.open(
                'https://agconnections.zendesk.com/hc/en-us/categories/7419295252627-Cropwise-Financials',
                '_blank',
                'noopener'
              )
            }
            style={{ marginLeft: 'auto' }}
          >
            Contact Support
          </Button>
        </div>
      )}
    </Toast>
  );
};

ToastPackageWeight.defaultProps = {
  setIsDrawerOpen: null
};

ToastPackageWeight.propTypes = {
  showToast: PropTypes.bool.isRequired,
  setShowToast: PropTypes.func.isRequired,
  state: PropTypes.string.isRequired,
  setIsDrawerOpen: PropTypes.func
};

export default ToastPackageWeight;
