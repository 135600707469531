import React from 'react';
import PropTypes from 'prop-types';
import { useLocation } from 'react-router-dom';

import { paths } from 'routes/paths';
import DrawModeButtons from './components/DrawModeButtons';
import EditButtons from './components/EditButtons';
import ControlButtons from './components/ControlButtons';
import TopLeftControlButtons from './components/TopLeftControlButtons';

const DrawTools = ({
  mode,
  modeSelected,
  onDelete,
  onSubtract,
  onSplit,
  setZoom,
  overlay,
  setOverlay,
  features,
  setSelectedShapeIds,
  showSplitToolPopup
}) => {
  const location = useLocation();

  const pathMatch = () =>
    location.pathname.match(`${paths.properties}/create`) ||
    location.pathname.match(`${paths.properties}/edit`);

  return (
    <div data-testid="draw-tools">
      {pathMatch() && (
        <div data-testid="map-tools">
          <div className="absolute z-1 top-0 left-0 m-5">
            <TopLeftControlButtons
              overlay={overlay}
              setOverlay={setOverlay}
              modeSelected={modeSelected}
            />
          </div>

          <div className="absolute z-1 bottom-0 top-0 right-0 m-5">
            <div className="right-0">
              <DrawModeButtons
                mode={mode}
                modeSelected={modeSelected}
                overlay={overlay}
              />
              <EditButtons
                onDelete={onDelete}
                onSubtract={onSubtract}
                onSplit={onSplit}
                features={features}
                setSelectedShapeIds={setSelectedShapeIds}
                showSplitToolPopup={showSplitToolPopup}
              />
            </div>
          </div>
        </div>
      )}

      <ControlButtons setZoom={setZoom} />
    </div>
  );
};

DrawTools.defaultProps = {
  setZoom: () => {},
  overlay: null,
  features: []
};

DrawTools.propTypes = {
  mode: PropTypes.string.isRequired,
  modeSelected: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
  onSubtract: PropTypes.func.isRequired,
  setZoom: PropTypes.func,
  onSplit: PropTypes.func.isRequired,
  overlay: PropTypes.string,
  setOverlay: PropTypes.func.isRequired,
  features: PropTypes.arrayOf(PropTypes.object),
  setSelectedShapeIds: PropTypes.func.isRequired,
  showSplitToolPopup: PropTypes.func.isRequired
};

export default DrawTools;
