import React from 'react';
import PropTypes from 'prop-types';
import { StyleSheet, View } from '@react-pdf/renderer';

import Layout from '../Layout';
import { Notes, Signature, Table, TankInformation } from '../components';
import { RecDetails } from './components';
import {
  PRODUCT_HEADER_ITEMS,
  FIELDS_HEADER_ITEMS
} from './helpers/tableMaker';
import { displayNumber, displayText } from '../helpers/pdfTools';

const styles = StyleSheet.create({
  viewContainer: {
    marginBottom: 27
  }
});

const RecPdf = ({ rec }) => {
  return rec ? (
    <Layout
      headerTitle={rec.title}
      type="Recommendation"
      orgName={rec.organizationName}
      cropSeasons={rec.cropSeasons}
      generatedYear={rec.year}
      generatedFullDate={rec.fullDate}
    >
      <View style={styles.viewContainer}>
        <RecDetails rec={rec} />
        <TankInformation tankInformation={rec.tankInformation} />
      </View>
      <View style={styles.viewContainer} wrap={false}>
        <Table
          title="Products"
          headerItems={PRODUCT_HEADER_ITEMS}
          bodyItems={rec.recommendationProductEntries}
        />
      </View>
      <View style={styles.viewContainer} wrap={false}>
        <Table
          title="Fields"
          headerItems={FIELDS_HEADER_ITEMS}
          bodyItems={rec.cropZones}
          titleRight={`Area: ${displayNumber(rec.totalFieldsArea)} ac.`}
        />
      </View>
      <View style={styles.viewContainer} wrap={false}>
        <Notes notes={displayText(rec.notes)} />
      </View>
      <View style={styles.viewContainer} wrap={false}>
        <Signature />
      </View>
    </Layout>
  ) : null;
};

RecPdf.propTypes = {
  rec: PropTypes.shape()
};

RecPdf.defaultProps = {
  rec: null
};

export default RecPdf;
