import React from 'react';
import PropTypes from 'prop-types';

import { Input, Form } from '@agconnections/grow-ui';

function ZipField({ postalCode, errors, onChange }) {
  return (
    <Form.Field>
      <div className="block ml-6">
        <div className="w-full">
          <div className="block text-sm font-medium leading-5 gr-text-gray-600 sm:mt-px sm:pt-2">
            Zip Code
          </div>
        </div>
        <div className="w-full">
          <Input
            id="postal-code-input"
            value={postalCode}
            name="postalCode"
            onChange={onChange}
            error={errors}
            errorMessage={errors}
          />
        </div>
      </div>
    </Form.Field>
  );
}

ZipField.defaultProps = {
  postalCode: '',
  errors: null
};

ZipField.propTypes = {
  postalCode: PropTypes.string,
  errors: PropTypes.string,
  onChange: PropTypes.func.isRequired
};

export default ZipField;
