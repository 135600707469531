import React, { useContext } from 'react';
import ViewOnlyField from 'components/ViewOnlyField';
import { Context } from 'components/Store';
import { getTotalAreaFromFeatures } from 'helpers/getTotalAreaAcres';
import { YieldContext } from 'screens/Yield/context/YieldProvider';

const CropInfoHeader = () => {
  const {
    state: { cropZones }
  } = useContext(YieldContext);
  const [{ selectedYieldCommodity }] = useContext(Context);

  return (
    <div className="flex py-4 px-8">
      <div className="w-1/5" data-testid="yield-crop-name">
        <ViewOnlyField header="Crop" className="w-1/3" text="text-lg" />
        <span className="text-2xl w-1/3">
          {selectedYieldCommodity?.name || '--'}
        </span>
      </div>
      <div className="w-1/5" data-testid="yield-total-acres">
        <ViewOnlyField header="Acres" className="w-1/3" />
        <span className="text-2xl w-1/3">
          {getTotalAreaFromFeatures(cropZones)}
        </span>
      </div>
    </div>
  );
};

export default CropInfoHeader;
