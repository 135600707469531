import { ROLES } from 'utilities/access';

import Companies from 'screens/Companies';
import Company from 'screens/Companies/Company';
import CompanyView from 'screens/Companies/Company/components/CompanyView';

import { CompaniesIcon } from 'routes/icons';
import { paths } from 'routes/paths';

const routes = [
  {
    id: 'companies',
    key: 'companies',
    path: paths.companies,
    exact: true,
    secure: true,
    disabled: false,
    icon: CompaniesIcon,
    nav: 'leftNav',
    component: Companies
  },
  {
    id: 'companyEntry',
    key: 'companyEntry',
    path: `${paths.companies}/:id`,
    exact: true,
    secure: true,
    component: Company,
    minPermissions: {
      minAllowedRole: ROLES.FULL_CONTROL
    }
  },
  {
    id: 'companyView',
    key: 'companyView',
    path: `${paths.companies}/:id/view`,
    exact: true,
    secure: true,
    component: CompanyView,
    minPermissions: {
      minAllowedRole: ROLES.VIEW_ONLY
    }
  }
];

export default routes;
