import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Input } from '@agconnections/grow-ui';

import PortalModal from 'components/Modals/PortalModal';
import { handleNameChange } from '../../helpers.js/propertyCreateHelpers';

const CreatePropertyModal = ({
  open,
  onCancel,
  onCreate,
  setIsSaving,
  isSaving
}) => {
  const [name, setName] = useState('');
  const [error, setError] = useState('');

  useEffect(() => {
    if (!open) {
      setName('');
      setError('');
    }
  }, [open]);

  const handleAdd = () => {
    if (name) {
      onCreate(name);
    }
    setIsSaving(true);
  };

  const handleCancel = () => {
    onCancel();
  };

  return (
    <PortalModal
      close={onCancel}
      open={open}
      title="Create New Farm"
      cancelLabel="Cancel"
      onCancel={handleCancel}
      onConfirm={handleAdd}
      confirmLabel="Save"
      isSaving={isSaving}
      hasError={error === 'Required' || name === ''}
    >
      <div data-testid="create-property-modal-body" className="my-8">
        <Input
          id="create-property-modal-name"
          label="Farm Name*"
          value={name || ''}
          onChange={e => handleNameChange(e, setName, setError)}
          error={error}
          errorMessage={error}
          autoFocus
        />
      </div>
    </PortalModal>
  );
};

CreatePropertyModal.propTypes = {
  open: PropTypes.bool.isRequired,
  onCancel: PropTypes.func.isRequired,
  onCreate: PropTypes.func.isRequired,
  setIsSaving: PropTypes.func.isRequired,
  isSaving: PropTypes.bool.isRequired
};

export default CreatePropertyModal;
