import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Table } from '@agconnections/grow-ui';
import { cloneDeep, orderBy } from 'lodash';
import AgVendInvoicesReviewTableRow from '../AgVendInvoicesReviewTableRow';

const AgVendInvoicesReviewTable = ({ invoices, handleRemove }) => {
  const [sortField, setSortField] = useState('invoiceNumber');
  const [sortDir, setSortDir] = useState('asc');

  const handleSort = field => {
    if (sortField === field) {
      setSortDir(sortDir === 'asc' ? 'desc' : 'asc');
    } else {
      setSortDir('asc');
      setSortField(field);
    }
  };

  const getOrderedInvoices = (_invoices, field, direction) => {
    if (!direction || !_invoices) return _invoices;

    const clonedInvoices = _invoices?.map(invoice => cloneDeep(invoice));
    switch (field) {
      case 'invoiceNumber':
        return orderBy(
          clonedInvoices,
          [invoice => invoice?.invoiceNumber],
          direction
        );

      case 'invoiceDate':
        return orderBy(
          clonedInvoices,
          [invoice => invoice?.invoiceDate],
          direction
        );
      default:
        return clonedInvoices;
    }
  };

  const getSortIcon = column => {
    if (sortField !== column) {
      return {};
    }

    return sortDir;
  };
  return (
    <div data-testid="invoices-table" className="w-full">
      <Table>
        <Table.Header>
          <Table.Cell
            width="10rem"
            sortable
            sort={getSortIcon('invoiceNumber')}
            onClick={() => handleSort('invoiceNumber')}
          >
            Invoice #
          </Table.Cell>
          <Table.Cell
            width="10rem"
            sortable
            sort={getSortIcon('invoiceDate')}
            onClick={() => handleSort('invoiceDate')}
          >
            Date
          </Table.Cell>
          <Table.Cell>Products</Table.Cell>
          <Table.Cell width="8rem" />
        </Table.Header>

        {getOrderedInvoices(invoices, sortField, sortDir).map(inv => (
          <AgVendInvoicesReviewTableRow
            invoice={inv}
            key={inv.id}
            handleRemove={handleRemove}
          />
        ))}
      </Table>
    </div>
  );
};

AgVendInvoicesReviewTable.propTypes = {
  invoices: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      description: PropTypes.string,
      startDate: PropTypes.string,
      fields: PropTypes.arrayOf(
        PropTypes.shape({
          name: PropTypes.string
        })
      ),
      products: PropTypes.arrayOf(
        PropTypes.shape({
          productName: PropTypes.string
        })
      )
    })
  ).isRequired,
  handleRemove: PropTypes.func.isRequired
};

export default AgVendInvoicesReviewTable;
