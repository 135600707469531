import axios from 'axios';
import { Context } from 'components/Store';
import catchCancel from 'helpers/catchCancel';
import { parseServerError } from 'helpers/errorHelpers';
import { useContext, useState } from 'react';
import { CROPWISE_PROXY_V2_URL } from 'utilities/apiConstants';
import { getAccessToken } from 'utilities/base-auth';

const useLocations = () => {
  const [{ organization }, dispatch] = useContext(Context);

  const [
    isCreateSaleLocationLoading,
    setIsCreateSaleLocationLoading
  ] = useState(false);

  const [
    isCreateStorageLocationLoading,
    setIsCreateStorageLocationLoading
  ] = useState(false);

  const headers = {
    'Content-Type': 'application/json',
    'cwf-context': JSON.stringify({
      organization_id: organization.id
    }),
    Authorization: `Bearer ${getAccessToken()}`
  };

  const createSaleLocation = body => {
    setIsCreateSaleLocationLoading(true);
    const promise = axios.post(
      `${CROPWISE_PROXY_V2_URL}/yield/sale-location`,
      body,
      {
        headers
      }
    );
    return promise
      .then(data => ({ id: data.data?.id, name: data.data?.name }))
      .catch(catchCancel)
      .catch(err => {
        parseServerError(dispatch)(err);
        return false;
      })
      .finally(() => setIsCreateSaleLocationLoading(false));
  };

  const createStorageLocation = body => {
    setIsCreateStorageLocationLoading(true);
    const promise = axios.post(
      `${CROPWISE_PROXY_V2_URL}/yield/storage-location`,
      body,
      {
        headers
      }
    );
    return promise
      .then(data => ({ id: data.data?.id, name: data.data?.name }))
      .catch(catchCancel)
      .catch(err => {
        parseServerError(dispatch)(err);
        return false;
      })
      .finally(() => setIsCreateStorageLocationLoading(false));
  };

  return {
    createSaleLocation,
    isCreateSaleLocationLoading,
    createStorageLocation,
    isCreateStorageLocationLoading
  };
};

export default useLocations;
