import React, { useEffect, useState } from 'react';
import { Select } from 'syngenta-digital-cropwise-react-ui-kit';
import { ReactComponent as ChevronDown } from 'assets/chevron_down.svg';
import PropTypes from 'prop-types';
import CropIconMapping from 'components/CropIconMapping';

const CropSelect = ({ crops, selectedCropId, onCropChange, disabled }) => {
  const [selectedValue, setSelectedValue] = useState(selectedCropId);

  useEffect(() => {
    setSelectedValue(selectedCropId);
  }, [selectedCropId]);

  const handleCropChange = value => {
    onCropChange(value);
  };

  if (!crops || !selectedCropId) return null;

  return (
    <>
      <label
        htmlFor="crop-select"
        className="text-neutral-60 tracking-tighter text-sm"
      >
        Crop
      </label>
      <Select
        id="crop-select"
        value={selectedValue}
        onSelect={handleCropChange}
        size="middle"
        suffixIcon={<ChevronDown />}
        className="w-full"
        disabled={disabled}
      >
        {crops.map(crop => (
          <Select.Option
            key={crop.id}
            value={crop.id}
            data-testid={`crop-option-${crop.id}`}
          >
            <div className="flex items-center">
              <CropIconMapping cropObject={crop} />
              <div className="ml-2">{crop.name}</div>
            </div>
          </Select.Option>
        ))}
      </Select>
    </>
  );
};

CropSelect.defaultProps = {
  disabled: false
};

CropSelect.propTypes = {
  crops: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      name: PropTypes.string
    })
  ).isRequired,
  selectedCropId: PropTypes.string.isRequired,
  onCropChange: PropTypes.func.isRequired,
  disabled: PropTypes.bool
};

export default CropSelect;
