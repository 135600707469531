import React from 'react';
import { Formik } from 'formik';
import IntegrationsProvider from 'screens/Integrations/context/IntegrationsProvider';
import { useParams } from 'react-router-dom';
import Provider from '../../context/Provider';
import { INTEGRATIONS } from '../../../helpers/constants';
import SubmissionSummaryProvider from '../../../SubmissionSummary/context/SubmissionSummaryProvider';
import BaseCalAgIntegration from '../BaseCalAgIntegration';

const CalAgParentProvider = () => {
  const { id } = useParams();

  return (
    <IntegrationsProvider>
      <Provider>
        <Formik initialValues={{}}>
          {() => (
            <SubmissionSummaryProvider
              integrationType={INTEGRATIONS.calAgPermits}
              submissionId={id}
            >
              <BaseCalAgIntegration />
            </SubmissionSummaryProvider>
          )}
        </Formik>
      </Provider>
    </IntegrationsProvider>
  );
};

export default CalAgParentProvider;
