/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Input } from 'syngenta-digital-cropwise-react-ui-kit';
import SearchIcon from 'assets/search.svg';
import useDebounce from 'hooks/useDebounce';
import useWindowDimensions from 'hooks/useWindowDimensions';

const SearchInput = ({ onChange, placeholder, delay }) => {
  const { width } = useWindowDimensions();

  const [searchText, setSearchText] = useState(null);
  const debouncedSearchTerm = useDebounce(searchText, delay);

  useEffect(() => {
    onChange(debouncedSearchTerm);
  }, [onChange, debouncedSearchTerm]);

  return (
    <div>
      <label>
        <span className="sr-only">Search</span>
        <Input
          allowClear
          type="text"
          autoComplete="off"
          prefix={<img alt="Search icon" src={SearchIcon} />}
          placeholder={width <= 386 ? 'Search...' : placeholder}
          onChange={event => setSearchText(event.target.value)}
        />
      </label>
    </div>
  );
};

SearchInput.defaultProps = {
  delay: 300,
  placeholder: ''
};

SearchInput.propTypes = {
  delay: PropTypes.number,
  placeholder: PropTypes.string,
  onChange: PropTypes.func.isRequired
};

export default SearchInput;
