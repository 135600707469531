import { useState, useContext } from 'react';
import axios from 'axios';
import { Context } from 'components/Store';
import { getAccessToken } from 'utilities/base-auth';
import { useToast } from 'components/ToastContainer';
import { CROPWISE_PROXY_V2_URL } from 'utilities/apiConstants';
import { ProductDetailsContext } from '../context/ProductDetailsProvider';
import calculateTimeEstimate from '../helpers/calculateTimeEstimate';

export const bodyConstructor = (allTasks, unitPrice, taskIds) => {
  return {
    allTasks,
    unitPrice,
    taskIds
  };
};

const useTaskPriceList = productId => {
  const toast = useToast();
  const [{ organization }] = useContext(Context);
  const [dataSubmit, setDataSubmit] = useState(null);
  const [loadingSubmit, setLoadingSubmit] = useState(false);
  const [statusSubmit, setStatusSubmit] = useState();
  const [loadingRetrySubmission, setLoadingRetrySubmission] = useState(false);
  const [submissionsStatus, setSubmissionsStatus] = useState({});
  const [statusToSubmissionsStatus, setStatusToSubmissionsStatus] = useState();
  const { setOpenDrawer } = useContext(ProductDetailsContext);

  const submit = (body, reset = () => {}) => {
    let taskIds = [];
    setLoadingSubmit(true);
    if (!body.allTasks) {
      taskIds = [...new Set(body.taskIds.map(key => key.split('_')[0]))];
    }
    axios
      .post(
        `${CROPWISE_PROXY_V2_URL}/tasks/products/${productId}/submissions`,
        { ...body, taskIds },
        {
          headers: {
            'Content-Type': 'application/json',
            'cwf-context': JSON.stringify({
              organization_id: organization.id
            }),
            Authorization: `Bearer ${getAccessToken()}`
          }
        }
      )
      .then(async response => {
        const timeEstimate = await calculateTimeEstimate(body.taskIds.length);
        setStatusSubmit('success');
        setDataSubmit(response.data);
        toast.success('Processing record update.', {
          timeout: 10000,
          content: `The selected records will be updated within ${timeEstimate}. You can return to this page for confirmation once the update is complete.`
        });
        reset();
      })
      .catch(() => {
        setStatusSubmit('error');
        toast.error('Unable to update records.', {
          timeout: 10000,
          content:
            'An error prevented your records from being updated. Please try again.',
          supportButton: true
        });
      })
      .finally(() => {
        setLoadingSubmit(false);
      });
  };
  const dismissSubmissionsStatus = () => {
    axios
      .patch(
        `${CROPWISE_PROXY_V2_URL}/tasks/products/${productId}/submissions/dismiss`,
        {},
        {
          headers: {
            'Content-Type': 'application/json',
            'cwf-context': JSON.stringify({
              organization_id: organization.id
            }),
            Authorization: `Bearer ${getAccessToken()}`
          }
        }
      )
      .then(() => {
        setSubmissionsStatus({});
      });
  };

  const retrySubmission = (submissionIds, taskIds) => {
    setLoadingRetrySubmission(true);
    axios
      .patch(
        `${CROPWISE_PROXY_V2_URL}/tasks/products/${productId}/submissions/status/pending`,
        { submissionIds },
        {
          headers: {
            'Content-Type': 'application/json',
            'cwf-context': JSON.stringify({
              organization_id: organization.id
            }),
            Authorization: `Bearer ${getAccessToken()}`
          }
        }
      )
      .then(async () => {
        const timeEstimate = await calculateTimeEstimate(taskIds.length);
        setOpenDrawer(false);
        setSubmissionsStatus({});
        setStatusToSubmissionsStatus('success');
        toast.success('Re-processing record update.', {
          timeout: 10000,
          content: `The records will be updated within ${timeEstimate}. You can return to this page for confirmation once the update is complete.`
        });
      })
      .catch(() => {
        setStatusToSubmissionsStatus('error');
        toast.error('Unable to update records.', {
          timeout: 10000,
          content:
            'An error prevented your records from being updated. Please try again.',
          supportButton: true
        });
      })
      .finally(() => {
        setLoadingRetrySubmission(false);
      });
  };

  const getSubmissionsStatus = () => {
    axios
      .get(`${CROPWISE_PROXY_V2_URL}/tasks/products/${productId}/submissions`, {
        headers: {
          'Content-Type': 'application/json',
          'cwf-context': JSON.stringify({
            organization_id: organization.id
          }),
          Authorization: `Bearer ${getAccessToken()}`
        }
      })
      .then(response => {
        setSubmissionsStatus(response.data);
        setStatusToSubmissionsStatus('success');
      })
      .catch(() => {
        setStatusToSubmissionsStatus('error');
      });
  };

  return {
    submit,
    dataSubmit,
    loadingSubmit,
    statusSubmit,
    submissionsStatus,
    getSubmissionsStatus,
    statusToSubmissionsStatus,
    dismissSubmissionsStatus,
    loadingRetrySubmission,
    retrySubmission
  };
};

export default useTaskPriceList;
