import React, { useEffect } from 'react';
import GrowUIFormField from 'components/Forms/GrowUIFormField';
import { useFormikContext } from 'formik';
import { Input, Select } from '@agconnections/grow-ui';
import { unmaskPriceYield } from 'screens/Yield/helpers/calculateYield';
import {
  currentFormattedDate,
  unitsList,
  ImaskField,
  getUnitValue
} from 'screens/Yield/helpers/infoSectionData';

const YieldInfoSection = () => {
  const { values, setFieldValue, setFieldTouched } = useFormikContext();

  useEffect(() => {
    if (values.entryName?.length) {
      return;
    }

    setFieldValue('entryName', currentFormattedDate());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="py-4 px-8 mb-8">
      <div
        data-testid="yield-info-title"
        className="text-2xl font-semibold py-2 mb-10"
      >
        Yield Info
      </div>
      <div className="flex items-center">
        <div className="w-1/4 mr-8">
          <GrowUIFormField
            control={Input}
            name="entryName"
            id="entryName"
            label="Entry Name*"
            data-testid="yield-entry-name"
            value={values?.entryName}
            onChange={e => {
              setFieldValue('entryName', e.target.value);
            }}
          />
        </div>
        <div className="w-1/4 mr-8">
          <GrowUIFormField
            control={Select}
            name="unit"
            label="Units*"
            data-testid="yield-units"
            items={unitsList}
            placeHolder=""
          />
        </div>
        <div className="w-1/4 mr-8">
          <GrowUIFormField
            control={Input}
            id="totalYield"
            name="totalYield"
            label="Total Yield*"
            imask={ImaskField}
            data-testid="total-yield"
            onBlur={e => {
              setFieldTouched('totalYield');
              setFieldValue('totalYield', unmaskPriceYield(e.target.value));
              if (unmaskPriceYield(e.target.value)) {
                setFieldValue(
                  'avgYieldPerAcre',
                  parseFloat(
                    unmaskPriceYield(e.target.value) /
                      values?.totalAcres.split(' ')[0]
                  ).toFixed(2)
                );
              } else {
                setFieldValue(
                  'avgYieldPerAcre',
                  unmaskPriceYield(e.target.value)
                );
              }
            }}
          />
          <span className="float-right px-2 -my-7 p-1px relative text-neutral-600 text-sm">
            {getUnitValue(values.unit)}
          </span>
        </div>
        <div className="w-1/4 mr-8">
          <GrowUIFormField
            control={Input}
            id="avgYieldPerAcre"
            name="avgYieldPerAcre"
            label="Avg Yield / ac*"
            data-testid="avg-yield-per-acre"
            imask={ImaskField}
            onBlur={e => {
              setFieldTouched('avgYieldPerAcre');
              setFieldValue(
                'avgYieldPerAcre',
                unmaskPriceYield(e.target.value)
              );
              if (unmaskPriceYield(e.target.value)) {
                setFieldValue(
                  'totalYield',
                  parseFloat(
                    unmaskPriceYield(e.target.value) *
                      values?.totalAcres.split(' ')[0]
                  ).toFixed(2)
                );
              } else {
                setFieldValue('totalYield', unmaskPriceYield(e.target.value));
              }
            }}
          />
          <span className="float-right px-2 -my-7 p-1px relative text-neutral-600 text-sm">
            {getUnitValue(values.unit)}/ac
          </span>
        </div>
      </div>
    </div>
  );
};

export default YieldInfoSection;
