import { convertDensityFromKgPerLToLbsPerGal } from './convertDensity';

export const handleMasterProduct = setSchema => {
  setSchema('MASTER');
};

export const handleServiceProduct = (product, setCustomProduct, setSchema) => {
  setCustomProduct(product);
  setSchema('SERVICE');
};

export const handleFertilizerProduct = (
  product,
  setCustomProduct,
  setSchema,
  fetchCustomProduct,
  visible
) => {
  setCustomProduct(product);
  setSchema('FERTILIZER');

  if (visible && !product.isNew) {
    fetchCustomProduct(product.productId).then(res => {
      setCustomProduct({
        ...res.data,
        averagePricePerUnit: product.averagePricePerUnit,
        custom: true,
        densityInLbsPerGallon: convertDensityFromKgPerLToLbsPerGal(
          product?.density
        )
      });
    });
  }
};
