import React from 'react';
import { Field, ErrorMessage, useFormikContext } from 'formik';
import { SHRINK_TYPE, YIELD_DEFAULT_ROUND } from 'screens/YieldV2/constants';
import ValidationAlert from 'assets/alert-exclamation.svg';
import { getAbbreviatedUnit } from 'helpers/unitsAbbreviation';
import ShrinkTypeDropDown from '../ShrinkType';
import FormInputWithPrecisionDisplay from '../../FormInputWithPrecisionDisplay';

const ShrinkField = () => {
  const { errors, values } = useFormikContext();
  const unit =
    values.shrinkType === SHRINK_TYPE.BY_PERCENT
      ? '%'
      : getAbbreviatedUnit(values.loadUnit);

  return (
    <div className="flex flex-col gap-0.5">
      <label
        htmlFor="shrink"
        className="text-neutral-60 tracking-tighter text-sm"
      >
        Shrink
      </label>
      <div className="flex items-center yield-quantity-input">
        <FormInputWithPrecisionDisplay
          fieldName="shrink"
          inputSuffix={
            <>
              {unit}
              {errors.shrink ? (
                <img
                  className="ml-1"
                  src={ValidationAlert}
                  alt="validation alert"
                  data-testid="validation-alert"
                />
              ) : null}
            </>
          }
          precision={2}
          id="shrink"
          round={YIELD_DEFAULT_ROUND}
        />
        <Field name="shrinkType">
          {({ field, form }) => (
            <ShrinkTypeDropDown
              value={field.value}
              onChange={value => {
                form.setFieldValue(field.name, value);
              }}
              className="flex-1"
              isRequired
              minWidth="50%"
            />
          )}
        </Field>
      </div>
      {!errors?.shrink?.includes('less than') && (
        <div className="absolute" style={{ width: '45%', top: '58px' }}>
          <ErrorMessage
            name="shrink"
            component="div"
            className="text-remove text-wrap"
          />
        </div>
      )}
    </div>
  );
};

export default ShrinkField;
