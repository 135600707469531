import React from 'react';
import PropTypes from 'prop-types';
import { Spinner, Table } from '@agconnections/grow-ui';

const LeftSideLayout = ({
  elementList,
  type,
  typeIcon,
  headerActions,
  setValueSelected,
  selectedValueId,
  isLoadingLeftSide,
  emptyState,
  getSaleLocationById,
  renderItem: TableCellRender
}) => {
  const handleSelection = element => {
    if (selectedValueId === element.id) return;
    getSaleLocationById(element.id);
    setValueSelected(element);
  };

  return (
    <div className="w-332px h-full bg-white" data-testid="left-side-layout">
      <div className="w-full h-120px p-4 border-b">
        <div className="flex flex-row items-center">
          <img className="mr-1 ml-1" src={typeIcon} alt="Icon" />
          <span className="font-semibold text-2xl">{type}</span>
        </div>
        {headerActions}
      </div>
      <div className="overflow-y-auto flex flex-col pb-4 default-fft h-84/100">
        {isLoadingLeftSide ? (
          <Spinner size="lg" />
        ) : (
          <>
            {elementList.length ? (
              <Table>
                {elementList?.map(element => (
                  <Table.Row
                    key={element.id}
                    onClick={() => handleSelection(element)}
                    className={
                      element.id === selectedValueId ? 'bg-selectedBlue' : ''
                    }
                  >
                    <Table.Cell>
                      <TableCellRender
                        element={element}
                        isSelected={element.id === selectedValueId}
                      />
                    </Table.Cell>
                  </Table.Row>
                ))}
              </Table>
            ) : (
              emptyState
            )}
          </>
        )}
      </div>
    </div>
  );
};

LeftSideLayout.propTypes = {
  elementList: PropTypes.arrayOf(PropTypes.object).isRequired,
  type: PropTypes.string,
  setValueSelected: PropTypes.func,
  typeIcon: PropTypes.element.isRequired,
  headerActions: PropTypes.element.isRequired,
  isLoadingLeftSide: PropTypes.bool.isRequired,
  emptyState: PropTypes.element,
  selectedValueId: PropTypes.string,
  renderItem: PropTypes.func,
  getSaleLocationById: PropTypes.func
};

LeftSideLayout.defaultProps = {
  type: '',
  setValueSelected: () => {},
  emptyState: null,
  selectedValueId: '',
  renderItem: () => {},
  getSaleLocationById: () => {}
};

export default LeftSideLayout;
