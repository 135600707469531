import { CropZoneIcon } from 'components/Icons';
import YieldAcIcon from 'components/Icons/components/YieldAcIcon';
import YieldFieldIcon from 'components/Icons/components/YieldFieldIcon';
import PropTypes from 'prop-types';
import React from 'react';
import { numberFormatter } from 'screens/Yield/helpers/formatHelpers';
import { getUnitSymbol } from 'screens/Yield/helpers/infoSectionData';

const YieldInformation = ({ data }) => {
  return (
    <div className="flex flex-col flex-1">
      <div className="flex flex-row items-center mb-6">
        <div className="flex flex-row items-center mr-6">
          <CropZoneIcon />
          <span className="text-xl font-semibold ml-3 mr-1">Yield</span>
        </div>
        <div className="flex flex-row items-center mr-3">
          <YieldFieldIcon />
          <span className="text-xs ml-1 mr-1">{data.totalFields} fields</span>
        </div>
        <div className="flex flex-row items-center">
          <YieldAcIcon />
          <span className="text-xs ml-1 mr-1">{data.totalAcreage} ac</span>
        </div>
      </div>
      <div>
        <p className="ml-2">Total Yield</p>
        <div className="mt-2 w-92">
          {data.units.map(total => (
            <div
              key={total.unit}
              className="flex flex-row justify-between px-3 py-2 my-2 w-auto"
              style={{ backgroundColor: '#F9FAFB' }}
            >
              <span>
                <span className="font-semibold mr-1">
                  {numberFormatter.format(total.totalYield)}
                </span>
                {getUnitSymbol(total.unit)}
              </span>
              <span
                style={{
                  width: '2px',
                  height: 'auto',
                  backgroundColor: '#696F88'
                }}
              />
              <span>
                <span className="font-semibold mr-1">
                  {numberFormatter.format(total.yieldsPerAcre)}
                </span>
                {getUnitSymbol(total.unit)}/ac
              </span>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

YieldInformation.propTypes = {
  data: PropTypes.shape({
    totalAcreage: PropTypes.number.isRequired,
    totalFields: PropTypes.number.isRequired,
    totalGrossRevenue: PropTypes.number.isRequired,
    units: PropTypes.arrayOf(
      PropTypes.shape({
        unit: PropTypes.string,
        totalYield: PropTypes.number,
        avgPricePerUnit: PropTypes.number
      })
    )
  }).isRequired
};

export default YieldInformation;
