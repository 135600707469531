/* eslint-disable react/prop-types */
import React, { useContext, useEffect, useState } from 'react';
import Breadcrumb from 'components/App/AppShell/components/Breadcrumb';
import { paths } from 'routes/paths';
import { Context } from 'components/Store';
import useLandingPageData from 'hooks/useLandingPageData';
import { useHistory } from 'react-router-dom';
import { SET_SHOW_TOAST_SALE_LOCATION } from 'reducers/reducer';
import createAction from 'helpers/createAction';
import useSaleLocations from '../../hooks/useSaleLocations';
import SaleLocationToast from '../SaleLocationToast';
import { initialValuesSaleLocation } from '../../constants';
import validationSchemaSaleLocation from '../../helpers/validationSchemaSaleLocation';
import FormStructureSaleLocation from '../FormStructureSaleLocation';
import formatBody from '../../helpers/formatBody';

const CreateSaleLocation = () => {
  const history = useHistory();
  const [{ showToastSaleLocation, toastType }, dispatch] = useContext(Context);
  const {
    createSaleLocation,
    isLoadingCreateSaleLocation
  } = useSaleLocations();
  const { loaded, items: companies, setReload } = useLandingPageData(
    'worker/companies',
    ['company.name'],
    0
  );
  const [showToast, setShowToast] = useState();

  useEffect(() => {
    setShowToast(showToastSaleLocation);
  }, [showToastSaleLocation]);

  const cancel = () => {
    history.push(`${paths.yieldV2}/sale-locations`);
  };
  const handleOrgChange = () => {
    setReload(true);
  };

  const closeToast = value => {
    createAction(dispatch, SET_SHOW_TOAST_SALE_LOCATION, value);
  };

  const submit = values => {
    createSaleLocation(formatBody(values));
  };
  return (
    <>
      <Breadcrumb onOrganizationSelect={handleOrgChange} hideCropSeasonDropdown>
        <Breadcrumb.Item title="Yield" value="Create Sale Location" isLast />
      </Breadcrumb>

      <FormStructureSaleLocation
        formInitialValues={initialValuesSaleLocation}
        schemaValidation={validationSchemaSaleLocation}
        _handleSubmit={submit}
        labelSavingForm="Saving Location ..."
        handleCancel={cancel}
        isLoadingForm={isLoadingCreateSaleLocation}
        isLoadingCompanies={loaded}
        companies={companies}
      />
      {showToastSaleLocation && (
        <SaleLocationToast
          showToast={showToast}
          setShowToast={closeToast}
          state={toastType}
        />
      )}
    </>
  );
};

export default CreateSaleLocation;
