/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import React, { useState, useEffect, useContext, useCallback } from 'react';
import { Redirect } from 'react-router-dom';
import PropTypes from 'prop-types';

import { noop, orderBy } from 'lodash';

import { Button, Toast, Spinner } from '@agconnections/grow-ui';
import AddRoundedIcon from '@material-ui/icons/AddRounded';
import { hasRoleAccess } from 'utilities/access';

import usePlans from 'hooks/usePlans';
import useDebounce from 'hooks/useDebounce';

import { paths } from 'routes/paths';
import { Context } from '../../components/Store';
import { getLocalStorageApi, STORAGE_KEYS } from '../../utilities/localStorage';
import Breadcrumb from '../../components/App/AppShell/components/Breadcrumb';
import EmptyState from '../../components/EmptyState';
import LandingSearch from '../../components/LandingSearch';
import LandingListViewToggle from '../../components/LandingListViewToggle';
import PlanTileBoard from './components/PlanTileBoard';
import PlanTable from './components/PlanTable';

const plansViewStorageApi = getLocalStorageApi(STORAGE_KEYS.PLANS_VIEW, 'tile');

const Plans = () => {
  const [
    { loadingOrg, loggedInUserOrgPermission, isGlobalCropSeasonLoading }
  ] = useContext(Context);

  const defaultPlansView = plansViewStorageApi.get();
  const [view, setView] = useState(defaultPlansView ?? 'tile');
  const {
    plans,
    pageNo,
    plansCount,
    goToPage,
    isLoading,
    reset,
    filterByNameQuery,
    filterByDateRange,
    reload
  } = usePlans();
  const [searchText, setSearchText] = useState(null);
  const debouncedSearchTerm = useDebounce(searchText, 300);

  const [redirect, setRedirect] = useState(false);
  const [toastHasNotBeenClosed, setToastHasNotBeenClosed] = useState(true);
  const [tagsLoading, setTagsLoading] = useState(false);

  const canCreatePlan = hasRoleAccess(
    loggedInUserOrgPermission?.role,
    'Full control'
  );

  const filterItems = [
    {
      key: 'seasons',
      value: 'Seasons'
      // nestedItems: seasonItems
    },
    {
      key: 'farms',
      value: 'Farms'
      // nestedItems: farmItems
    },
    {
      key: 'crops',
      value: 'Crops'
      // nestedItems: cropItems
    },
    {
      key: 'flags',
      value: 'Flags'
      // nestedItems: flagItems
    }
  ];

  useEffect(() => {
    filterByNameQuery(debouncedSearchTerm);
  }, [debouncedSearchTerm, filterByNameQuery]);

  const handleOrgChange = () => {
    reset();
  };

  const handleDateRangeFilter = e => {
    filterByDateRange([...e.target.value]);
  };

  const handleChangeView = newView => {
    setView(newView);
    plansViewStorageApi.save(newView);
  };

  const loadSpinner = () => {
    return tagsLoading || isLoading || loadingOrg || isGlobalCropSeasonLoading;
  };

  const getView = () => {
    if (loadSpinner()) {
      return <Spinner />;
    }

    if (plans && plans.length === 0) {
      return (
        <EmptyState
          buttonText="Create Plan"
          onClick={event => {
            event.stopPropagation();
            setRedirect(true);
          }}
          subtitle="We were unable to find any plans under this organization."
          setPermission={() => canCreatePlan}
        />
      );
    }

    if (view === 'tile')
      return (
        <PlanTileBoard
          plans={plans}
          planPageNumber={pageNo}
          totalPlans={plansCount}
          handlePageChange={goToPage}
          loadPlanSummaries={reload}
          setTagsLoading={setTagsLoading}
        />
      );
    return (
      <PlanTable
        plans={orderBy(plans, [({ name }) => name.toLowerCase()], 'asc')}
        planPageNumber={pageNo}
        totalPlans={plansCount}
        handlePageChange={goToPage}
        loadPlanSummaries={reload}
        setTagsLoading={setTagsLoading}
      />
    );
  };

  return (
    <div className="w-full h-full" data-testid="plans">
      <Breadcrumb
        hideCropSeasonDropdown={false}
        onCropSeasonSelect={handleOrgChange}
        onOrganizationSelect={handleOrgChange}
      >
        <Breadcrumb.Item title="Plans" value="All Plans" isLast disabled />
      </Breadcrumb>
      {location &&
      location.state &&
      location.state.toastRenderContents &&
      toastHasNotBeenClosed ? (
        <Toast
          isError={false}
          onClose={() => {
            setToastHasNotBeenClosed(false);
          }}
        >
          {location.state.toastRenderContents}
        </Toast>
      ) : null}
      {redirect && (
        <Redirect
          push
          to={{
            pathname: `${paths.plans}/plan`
          }}
        />
      )}
      <>
        <div className="flex items-center justify-between">
          <LandingSearch
            id="plan-search-input"
            filterItems={filterItems}
            selectedItems={[]}
            name="search_plans"
            placeholder="Search Plans"
            onChange={event => {
              setSearchText(event.target.value);
            }}
            onFilterChange={noop}
            onChangeLabel={noop}
            onChangeProductType={noop}
            onChangeTaskType={noop}
            onDateRangeChange={handleDateRangeFilter}
            showCropSeason={false}
          />
          <div className="flex items-center">
            <LandingListViewToggle
              testId="plans-view-toggle"
              view={view}
              changeView={handleChangeView}
            />
            <div className="pl-2 whitespace-no-wrap">
              {canCreatePlan && (
                <Button
                  id="add-plan-button"
                  type="primary"
                  onClick={event => {
                    event.stopPropagation();
                    setRedirect(true);
                  }}
                  icon={<AddRoundedIcon />}
                >
                  Create Plan
                </Button>
              )}
            </div>
          </div>
        </div>
        {getView()}
      </>
    </div>
  );
};

export default Plans;
