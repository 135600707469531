const formatStorageValue = (value, unit = '') => {
  if (typeof value !== 'number') return '';
  const { format } = Intl.NumberFormat('en-US', {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2
  });
  return `${format(value)} ${unit}`;
};

export default formatStorageValue;
