import { set, isNil, isDate } from 'lodash';

// this search model functionality is an attempt to create a uniform way of specifying sort and filter options for the
// api.find function, so that the sorts and filters can be translated for use by the proxy api, some other api, or by
// our frontend caching.

// ideally for every situation where we encounter an api that requires sort or filter options specified in different ways
// in the request, we will have a search model here that can be used for that. the way i'm imagining it, a search model
// will be an object that contains one of 2 functions:
// - onRequest - a function to be called before the request is made - it consumes an axios reqest config and a query object,
//        and returns the updated axios request config object
// - onResponse - a function to be called after the response is returned, to perform the filtering and sorting then - it
//        consumes the response results and a query object, and returns the filtered and sorted response results
// it is conceivable that we could have a  search model with both an onRequest function and an onResponse function - for
// example, if the query filters can be processed and submitted in the api request, but the query sorts must be handled
// on the client side after the response is returned

// the shape of the query object passed to any api.find function should now be as follows, using the prop types format:
// query: PropTypes.shape({
//   sorts: PropTypes.arrayOf(
//     PropTypes.shape({
//       field: PropTypes.string,
//       dir: PropTypes.string
//     })
//   }),
//   filters: PropTypes.arrayOf(
//        PropTypes.shape({
//          field: PropTypes.string,
//          value: PropTypes.string,
//          type: PropTypes.string  (type is one of str,num,date,matches for now)
//     })
//   })
// })

// the filter type options we currently have include:
// - str - compares the whole string to the specified value (for partial strings use 'matches' instead of 'str')
// - num - compares numeric values
// - date - compares date values
// - matches - the value field should contain a predicate function where the field value is the function argument

const clientFilters = {
  str: (value, term) => value.toLowerCase() === term.toLowerCase(),
  num: (value, term) => value === term || Math.floor(value) === term,
  date: (value, term) => {
    const valueString = isDate(value) ? value.toDateString() : value;
    const termString = isDate(term) ? term.toDateString() : term;
    return valueString === termString;
  },
  matches: (value, term) => term(value)
};

const clientComparator = (field, dir) => (a, b) => {
  if (a[field] === b[field]) {
    return 0;
  }
  const director = dir === 'asc' ? x => x : x => !x;
  const aLess = a[field] < b[field];
  return director(aLess) ? -1 : 1;
};

const clientSorter = (sorts, data) =>
  sorts
    .reverse()
    .reduce(
      (items, { field, dir }) => items.sort(clientComparator(field, dir)),
      data
    );

export const clientSideSearchModel = {
  onResponse: (data, sorts = [], filters = []) => {
    const results = data.filter(item => {
      return filters.reduce((isValid, { field, value, type }) => {
        if (isNil(value)) {
          return isValid;
        }
        return isValid && clientFilters[type](item[field], value);
      }, true);
    });
    return clientSorter(sorts, results);
  }
};

export const vnextClassicSearchModel = {
  onRequest: (axiosConfig, { sorts = [], filters = [] }) => {
    return {
      ...axiosConfig,
      params: {
        ...axiosConfig.params,
        ...filters.reduce(
          (accum, { field, value }) => {
            if (!isNil(value)) {
              set(accum, field, `like(%${value}%)`);
            }
            return accum;
          },
          {
            sort: sorts.map(({ field, dir }) => `${dir}(${field})`)
          }
        )
      }
    };
  }
};

// TODO: create a real model (or even multiple models!) for the proxy search/sort once we have a use case
const proxySearchModel = {
  onRequest: axiosConfig => axiosConfig
};

export const dexieCacheModel = {
  onRequest: (dexieConfig, query) => {
    if (!query) {
      return dexieConfig;
    }
    return query.filters.reduce(
      (accum, { field, value, type }) => {
        if (isNil(value)) {
          return accum;
        }
        if (type === 'matches') {
          return {
            ...accum,
            filterClause: item => {
              const isMatch = item && item[field] && value(item[field]);
              return accum.filterClause
                ? itm => isMatch && accum.filterClause(itm)
                : isMatch;
            }
          };
        }
        return {
          ...accum,
          whereClause: {
            ...accum.whereClause,
            [field]: value
          }
        };
      },
      {
        whereClause: dexieConfig
      }
    );
  }
};

export const CLIENT_SIDE_MODEL = 'CLIENT_SIDE_MODEL';
export const VNEXT_CLASSIC_MODEL = 'VNEXT_CLASSIC_MODEL';
export const PROXY_SEARCH_MODEL = 'PROXY_SEARCH_MODEL';

/**
 * @type {{ [*]: { onResponse: (data: Array, sorts: Array, filters: Array) => Array, onRequest: (sorts: Array, filters: Array) => * }}}
 */
export const searchModels = {
  [CLIENT_SIDE_MODEL]: clientSideSearchModel,
  [VNEXT_CLASSIC_MODEL]: vnextClassicSearchModel,
  [PROXY_SEARCH_MODEL]: proxySearchModel
};
