import { useEffect, useRef } from 'react';

// returns a DOM element that is a child of the element with the specified id, for use with ReactDOM.createPortal
const usePortal = id => {
  const portal = useRef();

  useEffect(() => {
    if (process.env.JEST_WORKER_ID) {
      return () => {};
    }
    const mount = document.getElementById(id);
    const portalEl = portal.current;
    mount.appendChild(portalEl);

    return () => {
      portalEl.remove();
    };
  }, [id]);

  const getPortal = () => {
    if (!portal.current) {
      portal.current = document.createElement('div');
    }
    return portal.current;
  };

  return getPortal();
};

export default usePortal;
