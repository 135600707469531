import React from 'react';
import PropTypes from 'prop-types';

const RoundedDisplay = ({ children, roundedTo, units }) => {
  return (
    <>
      {Number(children).toFixed(roundedTo)}
      {units && ` ${units}`}
    </>
  );
};

RoundedDisplay.defaultProps = {
  roundedTo: 2,
  units: null
};

RoundedDisplay.propTypes = {
  children: PropTypes.number.isRequired,
  roundedTo: PropTypes.number,
  units: PropTypes.string
};

export default RoundedDisplay;
