import React, { useContext } from 'react';
import CardInfo from 'components/CardInfo';
import { StorageLocationContext } from 'screens/YieldV2/StorageLocations/context/StorageLocationProvider';
import formatStorageValue from 'screens/YieldV2/StorageLocations/helpers/formatStorageValue';

const StorageNoShape = () => {
  const {
    state: { data = [], selectedId }
  } = useContext(StorageLocationContext);
  const selected = data.find(storage => storage.id === selectedId);
  return (
    <div className="flex">
      <div className="w-1/2 pr-4">
        <CardInfo label="Shape" />
      </div>
      {(!selected?.capacity || selected?.totalStoredLoadUnit === 'lb') && (
        <div className="w-1/2 pl-4">
          <CardInfo
            label="Capacity"
            value={
              selected?.capacity
                ? formatStorageValue(selected?.capacity, 'bu')
                : null
            }
          />
        </div>
      )}
    </div>
  );
};

export default StorageNoShape;
