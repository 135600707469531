/* eslint-disable import/prefer-default-export */
export const ELEMENTS_TO_SUBTRACT = {
  propertylanding: {
    elementsToSum: ['mass-assigner-header'],
    defaultSub: 12
  },
  integrations: {
    elementsToSum: [
      'mass-assigner-header',
      'integration-proggress-bar',
      'mccain-footer',
      'integration-property-toolbar'
    ],
    defaultSum: 76
  }
};

export const calculateCorrectMapHeight = isMassAssignerActive => {
  const currentPath = window.location.pathname.split('/').pop();
  const sumHeight = ELEMENTS_TO_SUBTRACT[currentPath]?.elementsToSum?.reduce(
    (prev, curr) => prev + (document.getElementById(curr)?.clientHeight || 0),
    ELEMENTS_TO_SUBTRACT[currentPath]?.defaultSum || 0
  );

  const defaultSub = ELEMENTS_TO_SUBTRACT[currentPath]?.defaultSub || 0;
  const totalHeight = sumHeight || defaultSub;

  const baseHeight = isMassAssignerActive ? 154 : 64;

  return `calc(100vh - ${baseHeight + totalHeight - defaultSub}px)`;
};
