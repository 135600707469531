import React, { useContext, createContext, useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import smartlook from 'smartlook-client';
import catchCancel from 'helpers/catchCancel';
import axios from 'axios';
import qs from 'querystring';
import * as Sentry from '@sentry/react';
import { AmplitudeContext } from '../amplitude/useAmplitude';
import { request, headers } from './axios';
import {
  cleanNewUserSession,
  getAccessToken,
  getTokens,
  clearAccessToken,
  isAccessTokenAvailable,
  accountsOAuthUrl,
  setAccessToken
} from './common-methods';

const authContext = createContext();
const cropwiseBaseAPIUrl = process.env.REACT_APP_CROPWISE_BASE_API_URL;

export const performLogin = async authTokenRequestDTO => {
  const config = {
    headers
  };

  if (!authTokenRequestDTO.code) {
    return;
  }

  const response = await request
    .post(`oauth/token`, qs.stringify(authTokenRequestDTO), config)
    .catch(catchCancel);
  const tokens = response.data;
  setAccessToken(JSON.stringify(tokens));
};

function logout() {
  const logoutUrl = `https://${cropwiseBaseAPIUrl}/exit?continue=${window.location.origin}`;
  clearAccessToken();
  window.location.href = logoutUrl;
}

export async function coreServiceAutoOrgPermissionChecker(
  orgId,
  workSpaceId,
  accountId
) {
  const config = {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${getAccessToken()}`
    }
  };
  const commandvalues = {
    role_context: process.env.REACT_APP_ROLE_CONTEXT_PERMISSIONS_UPDATE,
    workspace_id: workSpaceId,
    org_id: orgId
  };
  const payload = {
    updates: [
      {
        operation: 'add',
        roles: [
          {
            role_id: 'CWFAdminView',
            ...commandvalues
          },
          {
            role_id: 'CWFAdminEdit',
            ...commandvalues
          },
          {
            role_id: 'CWFFinancialEdit',
            ...commandvalues
          },
          {
            role_id: 'CWFFinancialView',
            ...commandvalues
          }
        ]
      }
    ]
  };

  return axios.post(
    `https://${process.env.REACT_APP_CROPWISE_BASE_API_CROPS}/v2/accounts/${accountId}/roles/updates`,
    payload,
    config
  );
}

function useProvideAuth() {
  const amplitude = useContext(AmplitudeContext);
  const [user, setUser] = useState(null);
  const fetchUserProfile = useCallback(() => {
    if (!isAccessTokenAvailable()) return;
    request
      .get(`/v2/accounts/me`, {
        headers: {
          common: {
            Authorization: `Bearer ${getAccessToken()}`
          }
        },
        useCache: false
      })
      .then(res => {
        if (res.error) {
          throw res.error;
        }
        setUser(res.data);
        const { id, email, name } = res.data;
        amplitude.setUserId(res.data.id);
        smartlook.identify(res.data.id, { id, email, name });
        Sentry.setUser({ email });
      })
      .catch(error => {
        if (error.response?.data?.error === 'invalid_token') {
          Sentry.setUser(null);
        }
        logout();
      });
    // eslint-disable-next-line no-unused-expressions
  }, [amplitude]);
  return {
    user,
    logout,
    performLogin,
    fetchUserProfile
  };
}

const ProvideAuth = ({ children }) => {
  const { properties, sendToAmplitudeIdentify } = useContext(AmplitudeContext);
  const auth = useProvideAuth();
  // eslint-disable-next-line no-unused-expressions
  auth.user && sendToAmplitudeIdentify(properties.keys.user_id, auth.user);
  return <authContext.Provider value={auth}>{children}</authContext.Provider>;
};

function useAuth() {
  return useContext(authContext);
}
ProvideAuth.propTypes = {
  children: PropTypes.element
};
ProvideAuth.defaultProps = {
  children: <></>
};

export {
  useAuth,
  ProvideAuth,
  cleanNewUserSession,
  getAccessToken,
  getTokens,
  isAccessTokenAvailable,
  accountsOAuthUrl
};
