import React, { useContext } from 'react';
import { Context } from 'components/Store';
import GrowUIFormField from 'components/Forms/GrowUIFormField';
import { Input } from '@agconnections/grow-ui';

const SiteId = () => {
  const [{ isEnableInfoTabEdit }] = useContext(Context);
  return (
    <GrowUIFormField
      placeholder="Enter site ID"
      control={Input}
      id="siteId"
      name="siteId"
      label="Site ID"
      data-testid="siteId"
      disabled={!isEnableInfoTabEdit}
    />
  );
};

export default SiteId;
