import { agrianMatch, simplotMatch } from 'utilities/api';

const matchApiByVendor = {
  simplot: {
    api: simplotMatch,
    responseTranslator: (response, type = 'CropZone') => ({
      status: response.status,
      data: response.data
        .filter(match => match.type === type)
        .map(match => ({
          cwfId: match.cwfEntityId,
          vendorId: match.simplotEntityId,
          vendorPropertyName: match.simplotEntityDescription
        }))
    }),
    requestTranslator: payload =>
      payload.map(match => ({
        cwfEntityId: match.cwfId,
        simplotEntityId: match.vendorId,
        simplotEntityDescription: match.vendorPropertyName,
        type: match.type || 'CropZone'
      }))
  },
  agrian: {
    api: agrianMatch,
    responseTranslator: response => ({
      status: response.status,
      data: response.data
        .filter(match => match.type === 'Field')
        .map(match => ({
          cwfId: match.cwfEntityId,
          vendorId: match.agrianEntityId,
          type: match.type
        }))
    }),
    requestTranslator: payload =>
      payload.map(match => ({
        cwfEntityId: match.cwfId,
        agrianEntityId: match.vendorId,
        agrianEntityDescription: match.vendorPropertyName,
        type: 'Field'
      }))
  },
  agrianrecommendation: {
    api: agrianMatch,
    responseTranslator: response => ({
      status: response.status,
      data: response.data
        .filter(match => match.type === 'CropZone')
        .map(match => ({
          cwfId: match.cwfEntityId,
          vendorId: match.agrianEntityId
        }))
    }),
    requestTranslator: payload =>
      payload.map(match => ({
        cwfEntityId: match.cwfId,
        agrianEntityId: match.vendorId,
        agrianEntityDescription: match.vendorPropertyName,
        type: 'CropZone'
      }))
  }
};

export default matchApiByVendor;
