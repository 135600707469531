import React, { useContext, useEffect, useState } from 'react';

import { useToast } from 'components/ToastContainer';
import SimplotSubmissionFooter from 'screens/Integrations/SimplotIntegration/components/SimplotSubmissionFooter';
import { INTEGRATIONS } from 'screens/Integrations/helpers/constants';
import useSubmissionAnalytics from 'screens/Integrations/hooks/useSubmissionAnalytics';
import { SubmissionSummaryContext } from 'screens/Integrations/SubmissionSummary/context/SubmissionSummaryProvider';
import StepHeader from 'screens/Integrations/components/StepHeader';
import { SimplotContext } from '../../context/Provider';
import ProductList from './components/ProductList';
import { ProductsMatchContext } from './context/ProductsMatchProvider';

const SimplotMatchProducts = () => {
  const { events, triggerAnalyticsEvent } = useSubmissionAnalytics();
  const { goForward } = useContext(SimplotContext);
  const [unmatchedProducts, setUnmatchedProducts] = useState([]);
  const [productsToMatch, setProductsToMatch] = useState([]);
  const [matchesWithErrors, setMatchesWithErrors] = useState([]);
  const {
    state: { matches, fetchedMatches },
    isLoading,
    fetchMatches,
    submitMatches,
    updateMatches
  } = useContext(ProductsMatchContext);

  const {
    state: { tasks },
    loadSubmission,
    isLoading: isSummaryLoading
  } = useContext(SubmissionSummaryContext);

  const toast = useToast();

  useEffect(() => {
    if (!isLoading) {
      fetchMatches();
    }
    if (!isSummaryLoading) {
      loadSubmission();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!isSummaryLoading) {
      const products = tasks.flatMap(r => r.products);
      setProductsToMatch(
        products.reduce((acc, cur) => {
          if (acc.findIndex(p => p.productId === cur.productId) === -1) {
            acc.push(cur);
          }
          return acc;
        }, [])
      );
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tasks]);

  useEffect(() => {
    setUnmatchedProducts(
      productsToMatch.filter(
        p => matches.findIndex(m => m.cwfId === p.productId) === -1
      )
    );
  }, [matches, fetchedMatches, productsToMatch]);

  // eslint-disable-next-line consistent-return
  const onNextHandler = async () => {
    triggerAnalyticsEvent(events.epic.Integrations.submissionProductsMatched, {
      vendor: INTEGRATIONS.simplot
    });
    const response = await submitMatches();

    if (response === undefined) {
      // no request were sent
      return goForward();
    }

    if (response?.status === 201) {
      goForward();
    } else {
      toast.error('Unable to match products.', {
        supportButton: true,
        content:
          'We were unable to match at least one of your products. Review the errors and select a new Simplot Common Material'
      });
      const apiResponse =
        response?.status === 207 ? response : response?.response;
      if (apiResponse?.status === 207) {
        setMatchesWithErrors(apiResponse?.data?.filter(m => m.error));
      }

      if (apiResponse?.status === 422) {
        setMatchesWithErrors(apiResponse?.data?.filter(m => m.error));
      }
    }
  };

  const handleSelectMatch = (simplotMaterial, selectedProduct) => {
    if (matchesWithErrors) {
      setMatchesWithErrors(
        matchesWithErrors.filter(m => m.cwfId === selectedProduct.productId)
      );
    }

    if (!selectedProduct) {
      return updateMatches([
        ...matches.filter(m => m.vendorId !== simplotMaterial.id)
      ]);
    }

    const match = {
      vendorPropertyName: simplotMaterial.name,
      vendorId: simplotMaterial.id,
      cwfId: selectedProduct.productId,
      type: 'Product',
      source: simplotMaterial.source,
      cwfProduct: selectedProduct,
      simplotProduct: simplotMaterial
    };
    const nonAffectedProducts = matches.filter(
      m => m.cwfId !== selectedProduct.productId
    );
    if (nonAffectedProducts.length !== matches.length) {
      return updateMatches([...nonAffectedProducts, match]);
    }
    return updateMatches([...matches, match]);
  };

  return (
    <div
      className="w-84/100 bg-white flex flex-col px-6 py-4 rounded-xl font-body mb-mtcm"
      data-testid="properties-match-view"
    >
      <div
        className="flex flex-col w-full mb-3"
        id="properties-match-top-header"
      >
        <StepHeader
          title="Match Products"
          description="Match products from Cropwise Financials product list to products, chemicals or materials on the Simplot integration."
          isLoading={isLoading}
          isMatchCountVisible
          unmatchCount={unmatchedProducts.length}
        />
      </div>
      <div className="w-full mb-5 overflow-y-auto">
        {!isLoading && (
          <ProductList
            products={productsToMatch}
            matches={matches}
            onSelectMatch={handleSelectMatch}
            unmatchedProducts={unmatchedProducts}
            matchesWithErrors={matchesWithErrors}
          />
        )}
      </div>
      <SimplotSubmissionFooter
        onNext={onNextHandler}
        loading={isLoading}
        nextLabel="Next: Review and Submit"
        nextDisabled={isLoading || unmatchedProducts.length > 0}
        integrationType={INTEGRATIONS.simplot}
        isBottomFixed
      />
    </div>
  );
};

export default SimplotMatchProducts;
