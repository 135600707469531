import React, { useContext, useEffect, useState } from 'react';

import usePopulateMapData from 'hooks/usePopulateMapData';
import { addSelectedCropSeasonsToFarms } from 'helpers/propertyHelpers';
import { FETCH_TYPES } from 'helpers/constants';
import { useToast } from 'components/ToastContainer';
import { PropertiesMatchContext } from 'screens/Integrations/PropertiesMatch/context/PropertiesMatchProvider';
import useFormikHandler from 'screens/Integrations/hooks/useFormikHandler';
import AgrianSubmissionFooter from 'screens/Integrations/AgrianIntegration/components/AgrianSubmissionFooter';
import { INTEGRATIONS } from 'screens/Integrations/helpers/constants';
import { AgrianContext } from '../../../context/Provider';
import TopHeader from './components/TopHeader';
import FarmList from './components/FarmList';
import {
  buildFieldOptionsForSelect,
  checkIfMatchExistsInCropZones
} from './helpers';

const AgrianMatchProperties = () => {
  const { goForward } = useContext(AgrianContext);
  const [farmFieldTreeData, setFarmFieldTreeData] = useState([]);
  const [fftSelectGroups, setFftSelectGroups] = useState([]);
  const [cropZones, setCropZones] = useState([]);
  const [vendorFarms, setVendorFarms] = useState([]);
  const {
    fetchVendorProperties,
    fetchMatches,
    submitMatches,
    updateMatches,
    isLoading,
    updateUnmatchedEntities,
    state: { vendorProperties, unmatchedEntities, matches }
  } = useContext(PropertiesMatchContext);
  const { selectedCropSeasons, selectedRecommendations } = useFormikHandler();
  const {
    loading,
    dataSorted: propertyLandingPageData,
    reloadData
  } = usePopulateMapData({
    fetchType: FETCH_TYPES.cropSeasonsMap
  });

  const toast = useToast();

  useEffect(() => {
    reloadData({
      seasonIds: selectedCropSeasons?.map(season => season.id)
    });
    fetchVendorProperties();
    fetchMatches();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!vendorProperties.length) {
      return;
    }

    const farms = new Map(
      selectedRecommendations.flatMap(r =>
        r.farms.map(farm => [farm.id, { ...farm, fields: [] }])
      )
    );

    const fields = new Map(
      selectedRecommendations.flatMap(r =>
        r.fields.map(field => [field.id, field])
      )
    );

    fields.forEach(fieldInRec => {
      const field = vendorProperties.find(
        vendorProperty =>
          vendorProperty.id === fieldInRec.id &&
          farms.get(vendorProperty.farmId)
      );

      if (field) {
        farms.get(field.farmId).fields.push({
          ...fieldInRec,
          ...field
        });
      }
    });
    setVendorFarms([...farms].map(([, value]) => value));
  }, [vendorProperties, selectedRecommendations]);

  useEffect(() => {
    if (propertyLandingPageData.properties) {
      const propertiesWithCropSeasons = addSelectedCropSeasonsToFarms(
        propertyLandingPageData.properties,
        selectedCropSeasons?.map(season => season.id)
      );

      setFarmFieldTreeData(propertiesWithCropSeasons);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [propertyLandingPageData.properties]);

  useEffect(() => {
    if (farmFieldTreeData?.length && !fftSelectGroups.length) {
      const fields = farmFieldTreeData?.flatMap(farm =>
        farm.fields
          .filter(field => field.cropzones?.length)
          .map(field => ({
            farm,
            field
          }))
      );

      const selectGroups = fields.map(({ farm, field }) =>
        buildFieldOptionsForSelect(farm, field)
      );

      setFftSelectGroups(selectGroups);
      setCropZones(fields.flatMap(({ field }) => field.cropzones));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [farmFieldTreeData]);

  const onNextHandler = async () => {
    let canSubmit = true;
    const cwfIds = matches.map(match => match.cwfId);
    const uniqueCwfIds = [...new Set(cwfIds)];
    const checkDuplicates = uniqueCwfIds.map(value => [
      value,
      cwfIds.filter(id => id === value).length
    ]);
    checkDuplicates.forEach(check => {
      if (check[1] > 1) {
        canSubmit = false;
      }
    });

    if (canSubmit) {
      await submitMatches();
      goForward();
    } else {
      toast.error('Unable to create property matching', {
        supportButton: true,
        content:
          'The same Cropwise Crop Zone is selected for multiple fields. Please choose different Crop Zone for each field.'
      });
    }
  };

  useEffect(() => {
    if (vendorFarms?.length) {
      /**
       * Cleanup from payload matches that not exists in vendor farms
       */
      const fieldsIds = vendorFarms?.flatMap(farm =>
        farm.fields.map(field => field.id)
      );
      updateMatches(
        matches.filter(match => fieldsIds.includes(match.vendorId))
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [vendorFarms]);

  const refreshTotalUnmatches = () => {
    const fields = vendorFarms
      .flatMap(farm => farm.fields)
      ?.filter(field => !field.hide);

    const totalUnmatches = fields?.filter(
      field => !checkIfMatchExistsInCropZones(cropZones, field, matches)
    ).length;

    updateUnmatchedEntities(totalUnmatches);
  };

  useEffect(() => {
    if (matches?.length && vendorFarms?.length && cropZones?.length) {
      refreshTotalUnmatches();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [matches, vendorFarms, cropZones]);

  return (
    <>
      <div
        className="w-84/100 bg-white flex flex-col px-6 py-4 rounded-xl font-body mb-mtcm"
        data-testid="properties-match-view"
      >
        <div
          className="flex flex-col w-full mb-3"
          id="properties-match-top-header"
        >
          <TopHeader
            isLoading={loading || isLoading}
            title="Match Properties"
            description="Match applicable fields from Agrian to crop zones within Cropwise
            Financials."
            unmatchedEntities={unmatchedEntities}
          />
        </div>
        <div className="w-full mb-5 overflow-y-auto">
          {!loading && !isLoading && (
            <FarmList
              vendorFarms={vendorFarms}
              cropZones={cropZones}
              fftSelectGroups={fftSelectGroups}
            />
          )}
        </div>
      </div>
      <AgrianSubmissionFooter
        onNext={onNextHandler}
        loading={isLoading || loading}
        nextLabel="Next: Match Products"
        nextDisabled={isLoading || loading || unmatchedEntities > 0}
        integrationType={INTEGRATIONS.agrianRecommendation}
        isBottomFixed
      />
    </>
  );
};

export default AgrianMatchProperties;
