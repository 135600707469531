import React from 'react';
import PropTypes from 'prop-types';

const YieldQuantityDisplay = ({ label, quantity, unit }) => {
  const formatValueToDisplay = value => value?.toFixed(2) || '--';

  return (
    <div className="flex flex-col gap-0.5">
      <p className="text-neutral-60 tracking-tighter text-sm">{label}</p>
      <p className="text-base leading-normal">
        {formatValueToDisplay(quantity)}&nbsp;
        {unit}
      </p>
    </div>
  );
};

YieldQuantityDisplay.propTypes = {
  label: PropTypes.string.isRequired,
  quantity: PropTypes.string.isRequired,
  unit: PropTypes.string.isRequired
};

export default YieldQuantityDisplay;
