import React from 'react';
import { Table, Button } from '@agconnections/grow-ui';
import PropTypes from 'prop-types';

const DetailTableBody = ({ tableRows, setRedirect }) => {
  const viewLink = (address, addLink) => {
    return addLink ? (
      <div className="flex-none w-10 absolute right-0 mr-5">
        <Button
          type="link-default"
          className="right-0"
          onClick={() => setRedirect(address)}
        >
          View
        </Button>
      </div>
    ) : (
      ''
    );
  };

  return (
    <>
      {tableRows?.map(row => {
        return (
          <Table.Row key={row?.id}>
            {row.rowData?.map((cellValue, i) => {
              return (
                <Table.Cell key={cellValue}>
                  <div className="flex items-center">
                    <div>{cellValue}</div>
                    {viewLink(row.link, i === row.rowData.length - 1)}
                  </div>
                </Table.Cell>
              );
            })}
          </Table.Row>
        );
      })}
    </>
  );
};

DetailTableBody.defaultProps = {
  tableRows: [],
  setRedirect: () => {}
};

DetailTableBody.propTypes = {
  tableRows: PropTypes.arrayOf(PropTypes.object),
  setRedirect: PropTypes.func
};

export default DetailTableBody;
