import React from 'react';
import deleteIcon from 'assets/delete_outlined.svg';
import editIcon from 'assets/edit_outlined.svg';
import openInNew from 'assets/open_in_new.svg';
import PropTypes from 'prop-types';
import ActionButton from '../ActionButton';

export default function ActionTableCell({
  onRecordView,
  onRecordDelete,
  onRecordEdit,
  compact
}) {
  return (
    <div data-testid="action-table-cell" className="flex justify-end gap-1">
      <ActionButton
        icon={
          <img
            src={openInNew}
            className="min-w-1rem"
            alt="View yield load icon button"
          />
        }
        onClick={onRecordView}
        text="View"
        compact={compact}
      />

      {!compact && (
        <>
          <div className="text-neutral-60">|</div>
          <ActionButton
            icon={
              <img
                src={editIcon}
                className="min-w-1rem"
                alt="Edit yield load icon button"
              />
            }
            onClick={onRecordEdit}
            text="Edit"
          />
        </>
      )}

      {!compact && (
        <>
          <div className="text-neutral-60">|</div>
          <ActionButton
            icon={
              <img
                src={deleteIcon}
                className="min-w-1rem"
                alt="Delete yield load icon button"
              />
            }
            onClick={onRecordDelete}
            text="Delete"
          />
        </>
      )}
    </div>
  );
}

ActionTableCell.defaultProps = {
  onRecordView: () => {},
  onRecordEdit: () => {},
  onRecordDelete: () => {},
  compact: false
};

ActionTableCell.propTypes = {
  onRecordView: PropTypes.func,
  onRecordEdit: PropTypes.func,
  onRecordDelete: PropTypes.func,
  compact: PropTypes.bool
};
