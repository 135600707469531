import React from 'react';
import PropTypes from 'prop-types';

import PlanContext from '../../context';
import usePropertyMapping from '../../hooks/usePropertyMapping';
import usePropertySelection from '../../hooks/usePropertySelection';
import useAreaMapping from '../../hooks/useAreaMapping';

const PlanContextWrapper = ({ children }) => {
  const landItems = usePropertyMapping();
  const selectionBag = usePropertySelection(landItems);
  const areaBag = useAreaMapping(landItems, selectionBag.selectionMap);
  const contextBag = { landItems, ...selectionBag, ...areaBag };

  return (
    <PlanContext.Provider value={contextBag}>{children}</PlanContext.Provider>
  );
};

PlanContextWrapper.propTypes = {
  children: PropTypes.node
};
PlanContextWrapper.defaultProps = {
  children: null
};

export default PlanContextWrapper;
