import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { Context } from 'components/Store';
import _ from 'lodash';
import CropIconMapping from 'components/CropIconMapping';
import { Row } from 'syngenta-digital-cropwise-react-ui-kit';
import formatNumber from 'helpers/formatNumber';
import convertCropFromPoundToBushel from 'helpers/convertCropFromPoundToBushel';
import { getAbbreviatedUnit } from 'helpers/unitsAbbreviation';
import PropertyRow from '../../../PropertyRow';

const OverviewTab = ({ data }) => {
  const [{ cropSeasons }] = useContext(Context);

  const formatAvgYieldPerAcre = (value, cropName) => {
    const convertedValue = convertCropFromPoundToBushel(value, cropName);
    if (!convertedValue) {
      return undefined;
    }
    return `${formatNumber(
      convertedValue.value,
      getAbbreviatedUnit(convertedValue.unit)
    )} / ac`;
  };

  const formatTotalHarvestedYield = (value, cropName) => {
    const convertedValue = convertCropFromPoundToBushel(value, cropName);
    if (!convertedValue) {
      return undefined;
    }
    return formatNumber(
      convertedValue.value,
      getAbbreviatedUnit(convertedValue.unit)
    );
  };

  const crops = _.compact(_.flatten(cropSeasons.map(cs => cs.crops)));
  const cropBreakdowns = _.flatten([data])
    .map(d => ({
      ...d,
      crop: crops.find(_crop => _crop.id === d.cropId)
    }))
    .sort((a, b) => a.crop.name.localeCompare(b.crop.name))
    .map((cropBreakdown, i) => (
      <div key={cropBreakdown.crop.id} className={i > 0 ? 'mt-8' : null}>
        <Row prefixCls="syt-antd-row">
          <CropIconMapping cropObject={cropBreakdown.crop} size={18} />
          <span className="ml-1">{cropBreakdown.crop.name.toUpperCase()}</span>
        </Row>
        <PropertyRow
          label="Avg. Yield per Acre"
          value={formatAvgYieldPerAcre(
            cropBreakdown.avgYieldPerAcre,
            cropBreakdown.crop.name
          )}
        />
        <PropertyRow
          label="Total Harvested Yield"
          value={formatTotalHarvestedYield(
            cropBreakdown.totalHarvestedYield,
            cropBreakdown.crop.name
          )}
          unit="bu"
        />
        <PropertyRow
          label="Total Number of Harvested Loads"
          value={cropBreakdown.numYieldLoads}
        />
      </div>
    ));
  return <div className="pb-4">{cropBreakdowns}</div>;
};

OverviewTab.propTypes = {
  data: PropTypes.arrayOf().isRequired
};

export default OverviewTab;
