/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react/prop-types */
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useParams } from 'react-router-dom';
import { Table } from '@agconnections/grow-ui';
import { useFormikContext } from 'formik';
import FormattedNumber from 'components/FormattedNumber';
import { getAbbreviatedUnit } from 'helpers/unitsAbbreviation';
import AddProductPlanModal from 'components/ProductSideBar/components/AddProductPlanModal';
import EllipseMenu from 'components/EllipseMenu';
import { TAB_OPTIONS } from 'utilities/menus';
import EmptyProductsDisplay from './EmptyProductsDisplay';

// eslint-disable-next-line react/prop-types
const PlanProductTable = ({
  removeProduct,
  noBlank,
  memberRole,
  memberFinancialAccess,
  setCustomProductCreated,
  customProductCreated
}) => {
  const [customProductModalOpened, setCustomProductModalOpened] = useState(
    false
  );
  const [rowIndex, setRowIndex] = useState(null);
  // eslint-disable-next-line no-unused-vars
  const [modalType, setModalType] = useState({
    key: 'ratearea',
    value: 'ratePerAreaValue',
    unit: 'ratePerAreaUnit',
    title: 'Rate'
  });
  const [sort] = useState();
  const [planProductItem, setPlanProductItem] = useState(null);

  // force rebuild
  // const roundValue = valueToRound => {
  //   return Math.round((valueToRound + Number.EPSILON) * 100) / 100;
  // };

  const { values } = useFormikContext();

  const { id: checkEdit } = useParams();
  const applicationOptions = [
    {
      key: 'totalproduct',
      value: 'totalProductValue',
      unit: 'totalProductUnit',
      title: 'Total Product'
    },
    {
      key: 'productcost',
      value: 'productCostValue',
      unit: 'productCostUnit',
      title: 'Product Cost'
    },
    {
      key: 'ratearea',
      value: 'ratePerAreaValue',
      unit: 'ratePerAreaUnit',
      title: 'Rate'
    }
  ];

  // const specialCaseUnit = ['kernal', 'kernel', 'seed', 'tablet'];
  // useEffect(() => {
  //   if (values?.planProductEntries) {
  //     const updatedPlanProducts = products.map(product => {
  //       const productArea =
  //         product.coveragePercent < 100
  //           ? (values.area * product.coveragePercent) / 100
  //           : values.area;
  //       //      const totalProduct = product.productRateValue * values.area;
  //       let totalProduct = product.productRateValue * product.areaValue;
  //       let totalCost =
  //         product.productRateValue * product.specificCostValue * values.area;
  //       if (
  //         product?.productRateUnit !== product?.totalProductUnit &&
  //         specialCaseUnit.indexOf(product.productRateUnit) === -1
  //       ) {
  //         const conversion = getConvertUnitFromTo(
  //           Number.parseFloat(product.productRateValue * product.areaValue),
  //           product.productRateUnit,
  //           product?.totalProductUnit
  //         );
  //         totalCost =
  //           Number.parseFloat(conversion.Value) * product.specificCostValue;
  //       }
  //       return {
  //         ...product,
  //         areaValue: productArea,
  //         totalProductValue: totalProduct,
  //         totalProductCost: totalCost
  //       };
  //     });
  //     setFieldValue('planProductEntries', updatedPlanProducts);
  //   }
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [values?.area]);

  useEffect(() => {
    setModalType(
      applicationOptions.find(
        option => option.key === values.applicationStrategy
      )
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values.applicationStrategy]);

  useEffect(() => {
    if (values.planProductEntries?.length > 0) {
      const lastIndex = values.planProductEntries.length - 1;
      const lastProductAdded = values.planProductEntries[lastIndex];

      if (lastProductAdded.custom && customProductCreated) {
        setPlanProductItem(lastProductAdded);
        setRowIndex(`${lastIndex}`);
        setCustomProductModalOpened(true);
        setCustomProductCreated(false);
      }
    }
  }, [values.planProductEntries, customProductCreated]);

  const handleSort = () => {};
  const handleAction = (action, product, index) => {
    switch (action) {
      case 'edit':
        setRowIndex(index);
        setPlanProductItem(product);
        setCustomProductModalOpened(true);
        break;
      case 'remove':
        removeProduct(index);
        break;
      default:
        setCustomProductModalOpened(false);
        break;
    }
  };
  // const toggleCreateCustomProductModal = () => {
  //   setCustomProductModalOpened(previousState => !previousState);
  //   setPlanProductItem(null);
  // };

  const closeProductModal = () => {
    setCustomProductModalOpened(false);
    setPlanProductItem(null);
  };

  // const handleSave = () => {
  //   setCustomProductModalOpened(false);
  // };
  const getAdditionalHeaders = () => {
    return (
      <>
        <Table.Cell sort={sort} onClick={handleSort}>
          Rate/Area
        </Table.Cell>
        <Table.Cell sort={sort} onClick={handleSort}>
          Price
        </Table.Cell>
        <Table.Cell sort={sort} onClick={handleSort}>
          Applied Area
        </Table.Cell>
        <Table.Cell sort={sort} onClick={handleSort}>
          Applied Area %
        </Table.Cell>
        <Table.Cell sort={sort} onClick={handleSort}>
          Total Product
        </Table.Cell>
        <Table.Cell sort={sort} onClick={handleSort}>
          Total Cost
        </Table.Cell>
      </>
    );
  };

  const getMemberAccess = () => {
    if (memberRole === 'Full control' && memberFinancialAccess === 'none') {
      return true;
    }
    if (
      memberRole === 'Full control' &&
      memberFinancialAccess === 'READ' &&
      checkEdit !== 'plan'
    ) {
      return true;
    }
    return false;
  };

  const getLiveDataTable = () => {
    return (
      <Table>
        <Table.Header>
          <Table.Cell sort={sort} onClick={handleSort}>
            {`Products (${values.planProductEntries.length})`}
          </Table.Cell>
          {getAdditionalHeaders()}
        </Table.Header>
        {values.planProductEntries.map((product, index) => {
          return (
            <Table.Row key={product.trackingId}>
              <Table.Cell>
                <div className="text-left">
                  <div className="font-bold">{product.productName}</div>
                  <div className="mt-2 text-neutral-300">
                    {product.manufacturerName}
                  </div>
                </div>
              </Table.Cell>
              <Table.Cell>
                {product.productRateValue || '--'}{' '}
                {getAbbreviatedUnit(product.productRateUnit)}
              </Table.Cell>
              {getMemberAccess() ? (
                <Table.Cell />
              ) : (
                <Table.Cell>
                  {product.specificCostValue ? (
                    <FormattedNumber roundedTo={2} unit="usCurrency">
                      {product.specificCostValue}
                    </FormattedNumber>
                  ) : (
                    <div>--</div>
                  )}
                </Table.Cell>
              )}
              <Table.Cell>{product.areaValue || '-- '} ac</Table.Cell>
              <Table.Cell>
                {product.coveragePercent.toFixed(2) || '-- '}%
              </Table.Cell>
              <Table.Cell>
                {product.totalProductValue.toFixed(2) || '--'}{' '}
                {getAbbreviatedUnit(product?.stdpackageunit)}
              </Table.Cell>
              <Table.Cell>
                <div className="flex w-28 items-center justify-around">
                  {getMemberAccess() ? (
                    <div className="ml-2" />
                  ) : (
                    <div>
                      {product.totalProductCost ? (
                        <FormattedNumber roundedTo={2} unit="usCurrency">
                          {product.totalProductCost}
                        </FormattedNumber>
                      ) : (
                        <div>--</div>
                      )}
                    </div>
                  )}
                  <div data-testid="plan-table-options-menu">
                    <EllipseMenu
                      onAction={action => handleAction(action, product, index)}
                      options={TAB_OPTIONS}
                    />
                  </div>
                </div>
              </Table.Cell>
            </Table.Row>
          );
        })}
      </Table>
    );
  };

  return (
    <div className="mt-8">
      {planProductItem && (
        <AddProductPlanModal
          open={customProductModalOpened}
          product={planProductItem}
          onClose={closeProductModal}
          isEdit
          rowIndex={rowIndex}
          memberRole={memberRole}
          memberFinancialAccess={memberFinancialAccess}
        />
      )}
      {!values.planProductEntries?.length ? (
        <>
          <Table>
            <Table.Header>
              <Table.Cell sort={sort} onClick={handleSort}>
                Products(0)
              </Table.Cell>
              {getAdditionalHeaders()}
            </Table.Header>
          </Table>
          {!noBlank ? <EmptyProductsDisplay /> : <></>}
        </>
      ) : (
        getLiveDataTable()
      )}
    </div>
  );
};

PlanProductTable.defaultProps = {
  product: null
};

PlanProductTable.propTypes = {
  removeProduct: PropTypes.func.isRequired,
  product: PropTypes.shape({
    totalProductUnit: PropTypes.string.isRequired,
    productRateValue: PropTypes.number.isRequired,
    totalCostAtTimeOfCreation: PropTypes.string.isRequired,
    productRateUnit: PropTypes.string.isRequired,
    coveragePercent: PropTypes.number.isRequired,
    applicationCount: PropTypes.number.isRequired
  }),
  setCustomProductCreated: PropTypes.func.isRequired,
  customProductCreated: PropTypes.bool.isRequired
};

export default PlanProductTable;
