import { getCropZonesFromProperties } from 'helpers/propertyHelpers';

const getCompleteREIPHIData = (
  reiphiCropDetails,
  properties,
  reiphiitems = []
) => {
  const cropzones = getCropZonesFromProperties(properties);
  const customCrop = reiphiitems.filter(detail => detail.source === 'CWF');
  return reiphiitems.reduce((prev, curr) => {
    const isItUsedOnAnyCropzone = cropzones.some(cropzone => {
      const cropIdWithoutDashes = cropzone.crop.id.replace(/-/gi, '');
      const curCropIdWithoutDashes = curr.cropId.replace(/-/gi, '');

      return cropIdWithoutDashes === curCropIdWithoutDashes;
    });
    if (
      isItUsedOnAnyCropzone ||
      customCrop.find(crop => crop.cropId === curr.cropId)
    ) {
      // leaving in the crop details to fetch the crop name as a fall back, but the crop chosen may not be in the documentation for the product.
      const cropInfo = reiphiCropDetails?.find(eachDetails => {
        return eachDetails.crop.id === curr.cropId.replace(/-/gi, '');
      });
      const cropName =
        curr.cropName && curr?.cropName !== ''
          ? curr?.cropName
          : cropInfo?.crop?.name;
      const dateEdited =
        curr.editedOnDateEpoch > 0
          ? new Date(curr.editedOnDateEpoch).toLocaleDateString('en-US')
          : '';
      prev.push({ ...curr, dateEdited, cropName });
    }

    return prev;
  }, []);
};

export default getCompleteREIPHIData;
