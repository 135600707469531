import React from 'react';
import PropTypes from 'prop-types';
import { Button } from '@agconnections/grow-ui';
import { Link } from 'react-router-dom';

const AddYieldButton = ({ disabled }) => {
  return (
    <Link to="yieldV1/create" className={disabled ? 'pointer-events-none' : ''}>
      <Button
        type="primary"
        size="md"
        style={{ paddingRight: '40px', paddingLeft: '40px' }}
        disabled={disabled}
      >
        <span className="text-2xl" style={{ marginRight: '7.75px' }}>
          +
        </span>
        Add Yield
      </Button>
    </Link>
  );
};

AddYieldButton.propTypes = {
  disabled: PropTypes.bool
};

AddYieldButton.defaultProps = {
  disabled: false
};

export default AddYieldButton;
