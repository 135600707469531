import React from 'react';
import MonetizationOnOutlined from '@material-ui/icons/MonetizationOnOutlined';
import PropTypes from 'prop-types';
import { currencyFormatter } from 'screens/Yield/helpers/formatHelpers';
import { getUnitSymbol } from 'screens/Yield/helpers/infoSectionData';

const RevenueInformation = ({ data }) => {
  return (
    <div className="flex flex-col flex-1">
      <div className="flex flex-row items-center mb-6">
        <MonetizationOnOutlined className="text-neutral-600" />
        <span className="ml-2 font-semibold text-xl">Revenue</span>
      </div>
      <div className="flex flex-row">
        <div className="flex-1">
          <p className="text-4xl font-semibold mb-0">
            {currencyFormatter.format(data.totalGrossRevenue)}
          </p>
          <p className="text-sm">Total gross revenue</p>
        </div>
        <div className="flex-1">
          <p className="ml-2">Avg price/unit</p>
          <div className="w-50">
            {data.units.map(price => (
              <p
                key={getUnitSymbol(price.unit)}
                className="px-3 py-2 my-2"
                style={{ backgroundColor: '#F9FAFB' }}
              >
                <span className="font-semibold mr-1">
                  {currencyFormatter.format(price.avgPricePerUnit)}
                </span>
                / {getUnitSymbol(price.unit)}
              </p>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

RevenueInformation.propTypes = {
  data: PropTypes.shape({
    totalGrossRevenue: PropTypes.number.isRequired,
    units: PropTypes.arrayOf(
      PropTypes.shape({
        unit: PropTypes.string,
        avgPricePerUnit: PropTypes.number
      })
    )
  }).isRequired
};

export default RevenueInformation;
