import React from 'react';
import PropTypes from 'prop-types';
import { Spinner } from '@agconnections/grow-ui';

const SummaryCard = ({ header, footer, content, className, isLoading }) => (
  <div className={`flex flex-col bg-white shadow-card rounded-lg ${className}`}>
    <div className="border-b border-solid border-neutral-20 h-14 flex flex-col justify-center">
      {header}
    </div>
    {isLoading ? (
      <div className="flex items-center justify-center h-full w-full flex-1">
        <Spinner size="lg" />
      </div>
    ) : (
      <>
        <div className="flex-1">{content}</div>
        <div className="border-t border-solid border-neutral-20 h-52px flex-col justify-center">
          {footer}
        </div>
      </>
    )}
  </div>
);

SummaryCard.propTypes = {
  header: PropTypes.node,
  footer: PropTypes.node,
  content: PropTypes.node,
  className: PropTypes.string,
  isLoading: PropTypes.bool
};

SummaryCard.defaultProps = {
  header: null,
  footer: null,
  content: null,
  className: '',
  isLoading: false
};

export default SummaryCard;
