function convertDateString(field) {
  if (!field) {
    return undefined;
  }
  const date = new Date(field);
  if (isNaN(date.getTime())) {
    return undefined;
  }
  return date
    .toLocaleString('en-US', {
      year: 'numeric',
      month: 'short',
      day: '2-digit',
      hour: 'numeric',
      minute: '2-digit',
      hour12: true
    })
    .replace(/,\s([^,]*)$/, ' $1')
    .replace(/\s(.{2})$/, '$1');
}

export default convertDateString;
