import * as Yup from 'yup';

export default Yup.object().shape({
  name: Yup.string()
    .label('Plan Name')
    .trim()
    .max(128, 'Name must be 128 characters or less.')
    .required('Required')
    .typeError('Required'),
  crops: Yup.array()
    .label('Crop')
    .nullable(),
  area: Yup.number()
    .required('Required')
    .typeError('Required'),
  revenue: Yup.object()
    .shape({
      yieldPerArea: Yup.number(),
      salePricePerUnit: Yup.number(),
      fsaPayment: Yup.number(),
      cropShare: Yup.number()
    })
    .nullable()
});
