/**
 * Checks if a load is overdrawing from a specified storage source.
 * This function determines if the amount to be drawn (`load`) from a storage source exceeds the total stored load available in the source.
 * @param {Object} params - The parameters for checking overdraw.
 * @param {string} params.sourceType - The type of the source. This function only checks storage type sources.
 * @param {Object|null} params.source - The storage source object, which must contain `capacity` and `totalStoredLoad` properties.
 * @param {number|string} params.load - The amount of the load that is attempted to be drawn from the source.
 * @returns {boolean} - Returns true if the load exceeds the source's total stored load, otherwise false. If the source type is not 'STORAGE', or if any of the parameters are missing or invalid, the function returns false.
 *
 */
const isOverdrawingSource = ({ sourceType, source, load }) => {
  if (sourceType !== 'STORAGE' || !source || !load || !source?.capacity) {
    return false;
  }
  return Number(load) > Number(source?.totalStoredLoad);

  // UOM conversion will be implemented in a new ticket
  /** const totalStoredLoadInBushels = Number(source?.totalStoredLoad);

  if (loadUnit === MEASUREMENT_UNITS.BUSHEL) {
    return Number(load) > totalStoredLoadInBushels;
  }

  const poundsPerBushel = getBushelWeight(cropName);
  if (!poundsPerBushel) return false;

  const loadInPounds =
    loadUnit === MEASUREMENT_UNITS.POUND
      ? Number(load)
      : convertUnit(Number(load), loadUnit, MEASUREMENT_UNITS.POUND);

  const loadInBushels = loadInPounds / poundsPerBushel;
  return loadInBushels > totalStoredLoadInBushels; */
};

export default isOverdrawingSource;
