import { add, format } from 'date-fns';
import { getInitials } from 'helpers/textHelpers';
import { compact } from 'lodash';
import { APPLICATION_OPTIONS } from './applicationOptions';

export function getDefaultFormValues(user) {
  const nameInitials = getInitials(user.name);
  const yymmddDateString = format(new Date(), 'yyMMdd');
  const currentMilitaryTime = format(new Date(), 'kkmm');
  return {
    applicationStrategy: APPLICATION_OPTIONS.byRatePerArea.key,
    applicators: [],
    authorizationDate: new Date(),
    authorizedBy: undefined,
    authorizedByName: undefined,
    coveragePercent: 100,
    cropSeasonIds:
      JSON.parse(localStorage.getItem('selectedCropSeasons')) || null,
    expirationDate: add(new Date(), { days: 31 }),
    name: `${nameInitials} ${yymmddDateString} ${currentMilitaryTime}`,
    notes: '',
    number: '',
    properties: [],
    proposedDate: new Date(),
    products: [],
    propertiesGroupBy: 'property',
    source: undefined,
    totalAreaValue: 0,
    tankInformation: {
      tankSizeValue: 0,
      tankSizeUnit: 'gallon',
      tankCount: 0,
      carrierPerAreaValue: 0,
      carrierPerAreaUnit: 'gallon',
      totalCarrierValue: 0,
      totalCarrierUnit: 'gallon',
      tankValueTypeEnteredByUser: 'CarrierPerArea'
    },
    timingEvent: undefined
  };
}

function apiToFormValues(apiRecommendation = {}, userName = '') {
  const defaultValues = getDefaultFormValues({ name: userName });
  const totalArea = (apiRecommendation.cropZones ?? []).reduce(
    (total, cropZone) => total + cropZone.areaValue,
    0
  );

  return {
    applicationStrategy:
      apiRecommendation.applicationStrategy ??
      defaultValues.applicationStrategy,
    applicators: apiRecommendation.applicators ?? defaultValues.applicators,
    authorizationDate: apiRecommendation.authorisedOnEpoch
      ? new Date(apiRecommendation.authorisedOnEpoch)
      : defaultValues.authorizationDate,
    authorizedBy:
      apiRecommendation.authorisedById ?? defaultValues.authorizedBy,
    authorizedByName:
      apiRecommendation.authorisedByName ?? defaultValues.authorizedByName,
    coveragePercent:
      apiRecommendation.coveragePercent ?? defaultValues.coveragePercent,
    cropSeasonIds:
      apiRecommendation.cropSeasonIds ?? defaultValues.cropSeasonIds, // .join(', '),
    expirationDate: apiRecommendation.expirationDateEpoch
      ? new Date(apiRecommendation.expirationDateEpoch)
      : defaultValues.expirationDate,
    flagIds: apiRecommendation.flagIds,
    name: apiRecommendation.title ?? defaultValues.name,
    notes: apiRecommendation.notes ?? defaultValues.notes,
    number: apiRecommendation.number ?? defaultValues.number,
    properties: (apiRecommendation.cropZones ?? []).map(cropZone => {
      return {
        areaValue: cropZone.areaValue,
        areaUnit: cropZone.areaUnit,
        crop: { id: cropZone.cropId },
        cropZoneId: cropZone.cropZoneIdValue
      };
    }),
    proposedDate: apiRecommendation.proposedDateEpoch
      ? new Date(apiRecommendation.proposedDateEpoch)
      : defaultValues.proposedDate,
    products: (apiRecommendation.recommendationProductEntries ?? []).map(
      product => {
        return {
          productId: product.productId,
          productName: product.productName,
          manufacturerName: product.manufacturerName,
          ratePerAreaValue: product.productRatePerAreaValue,
          ratePerAreaUnit: product.productRatePerAreaUnit,
          areaUnit: product.areaUnit,
          ratePerTankValue: product.productRatePerTankValue,
          ratePerTankUnit: product.productRatePerTankUnit,
          totalProductValue: product.totalProductValue,
          totalProductUnit: product.totalProductUnit,
          coveragePercent: product.coveragePercent * 100,
          appliedAreaValue: product.productAppliedAreaValue,
          appliedAreaUnit: product.productAppliedAreaUnit,
          productDensity: product.productDensity,
          applicationMethod: product.applicationMethod,
          targetPestId: product.targetPestId,
          custom: product.custom
        };
      }
    ),
    // TODO: Figure this value out. Looks like the API does not return it so we'll just set the default value for now
    propertiesGroupBy:
      apiRecommendation.propertiesGroupBy ?? defaultValues.propertiesGroupBy,
    source: apiRecommendation.source ?? defaultValues.source,
    totalApplied: Math.round((totalArea + Number.EPSILON) * 100) / 100,
    totalAreaValue: Math.round((totalArea + Number.EPSILON) * 100) / 100,
    tankInformation:
      apiRecommendation.tankInformation ?? defaultValues.tankInformation,
    timingEvent: apiRecommendation.timingEvent ?? defaultValues.timingEvent
  };
}

function formValuesToApi(values) {
  const recommendation = {
    applicationStrategy: values.applicationStrategy,
    applicators: values.applicators?.map(applicator => {
      return {
        personId: applicator.personId,
        personName: '',
        companyName: '',
        companyId: applicator.companyId
      };
    }),
    authorisedById: values.authorizedBy,
    authorisedByName: values.authorizedByName,
    authorisedOnEpoch: new Date(values.authorizationDate ?? '').valueOf(),
    coveragePercent: values?.coveragePercent,
    cropSeasonIds: compact(values.cropSeasonIds),
    cropZones: values.properties.map(property => ({
      areaValue: property.areaValue,
      areaUnit: 'acre',
      cropId: property.crop.id,
      cropZoneIdValue: property.cropZoneId
    })),
    expirationDateEpoch: new Date(values.expirationDate ?? '').valueOf(),
    flagIds: values.flagIds,
    number: values.number ?? '',
    proposedDateEpoch: new Date(values.proposedDate ?? '').valueOf(),
    recommendationProductEntries: values?.products.map(product => {
      return {
        productId: product.productId,
        productName: product.productName,
        manufacturerName: product.manufacturerName,
        // trackingId: product.productId,
        productRatePerAreaValue: product.ratePerAreaValue,
        productRatePerAreaUnit: product.ratePerAreaUnit,
        areaUnit: product.areaUnit,
        productRatePerTankValue:
          (product.appliedAreaValue * product.ratePerAreaValue) /
          values.tankInformation.tankCount,
        productRatePerTankUnit: product.ratePerTankUnit,
        totalProductValue: product.totalProductValue,
        totalProductUnit: product.totalProductUnit,
        // ratePerAreaUnit: 'gallon',
        // ratePerTankUnit: 'gallon',
        coveragePercent: product.coveragePercent / 100,
        productAppliedAreaValue: product.appliedAreaValue,
        productAppliedAreaUnit: product.appliedAreaUnit,
        productDensity: product.productDensity,
        applicationMethod: product.applicationMethod,
        targetPestId: product.targetPestId,
        custom: product.custom || false,
        stdunit: product.stdunit
      };
    }),
    source: values.source,
    tankInformation: {
      tankCount: values.tankInformation.tankCount,
      tankValueTypeEntered: 'CarrierPerArea',
      tankSizeUnit: 'gallon',
      tankSizeValue: values.tankInformation.tankSizeValue,
      carrierPerAreaUnit: 'gallon',
      carrierPerAreaValue: values.tankInformation.carrierPerAreaValue,
      totalCarrierUnit: 'gallon',
      totalCarrierValue: values.tankInformation.totalCarrierValue
    },
    timingEvent: values.timingEvent,
    title: values.name,
    type: 'LANDDB'
  };

  if (values.notes) {
    recommendation.notes = values.notes;
  }

  return recommendation;
}

export default {
  apiToFormValues,
  formValuesToApi
};
