/* eslint-disable no-param-reassign */
import React, { useContext, useState, useEffect, useRef } from 'react';
import { Select, Spinner } from '@agconnections/grow-ui';
import PropertyToolbar from 'screens/Property/PropertiesLanding/PropertyLandingMap/PropertyToolbar';
import { IntegrationsContext } from 'screens/Integrations/context/IntegrationsProvider';
import { SET_PROPERTIES_GROUPED_BY } from 'screens/Integrations/context/reducer';
import { addSelectedCropSeasonsToFarms } from 'helpers/propertyHelpers';
import usePopulateMapData from 'hooks/usePopulateMapData';
import { FETCH_TYPES } from 'helpers/constants';
import PropTypes from 'prop-types';
import PropertyView from '../PropertyView/PropertyView';
import PropertySelectorFooter from '../PropertySelectorFooter/PropertySelectorFooter';
import PropertySelector from '../PropertySelector/PropertySelector';
import useFormikHandler from '../../hooks/useFormikHandler';

// eslint-disable-next-line react/prop-types
const PropertyIntegrationBase = ({ integrationName }) => {
  const [view, setView] = useState('map');
  const [properties, setProperties] = useState([]);
  const [cropZonesChecked, setCropZonesChecked] = useState([]);

  const { selectedCropSeasons } = useFormikHandler();

  const {
    state: { propertiesGroupedBy, selectedProperties },
    dispatch
  } = useContext(IntegrationsContext);

  const {
    loading,
    zoom,
    setZoom,
    dataSorted: propertyLandingPageData,
    fieldsAndAreasGeoJSONCollection,
    setGeoJSONCollectionFeatures,
    fieldsLoading,
    filterFeatureCollectionByParentId,
    reloadData
  } = usePopulateMapData({
    fetchType: FETCH_TYPES.cropSeasonsMap
  });

  const seasonIds = useRef(selectedCropSeasons.map(season => season.id));

  useEffect(() => {
    reloadData({
      seasonIds: seasonIds.current
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (propertyLandingPageData.properties) {
      const propertiesWithCropSeasons = addSelectedCropSeasonsToFarms(
        propertyLandingPageData.properties,
        seasonIds.current
      );

      setProperties(propertiesWithCropSeasons);
    }
  }, [propertyLandingPageData.properties]);

  const handleChange = e => {
    const dropDownValue = JSON.parse(e.target.value);
    dispatch({
      type: SET_PROPERTIES_GROUPED_BY,
      payload: dropDownValue.key
    });
  };

  useEffect(() => {
    setGeoJSONCollectionFeatures(
      filterFeatureCollectionByParentId(
        fieldsAndAreasGeoJSONCollection,
        undefined,
        selectedProperties.map(property => property.properties)
      )
    );

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedProperties]);

  useEffect(() => {
    const checkedCropZones = selectedProperties.map(
      property => property.properties.id
    );

    setCropZonesChecked(checkedCropZones);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedProperties]);

  return loading || fieldsLoading ? (
    <Spinner />
  ) : (
    <div
      className="w-full bg-white px-6 rounded-xl"
      data-testid="property-integration"
    >
      <div
        className="flex flex-row w-full justify-end py-4 mb-2"
        id="integration-property-toolbar"
      >
        <div
          className="flex flex-row w-full items-center py-4 h-10/100 justify-between mb-2"
          id="integration-property-toolbar"
        >
          <div>
            <div className="text-xl leading-7 font-semibold text-neutral-1000 mb-2">
              Select Properties
            </div>
            <p className="text-base leading-6 text-neutral-70">
              Select properties based on your selected crop seasons and time
              period
            </p>
          </div>
          <PropertyToolbar
            loading={false}
            onChangeView={setView}
            view={view}
            buttonText="List"
          />
        </div>
      </div>
      <div className="flex flex-row w-full h-auto pb-6">
        <div className="h-full w-1/4 pr-5">
          <Select
            items={[
              { key: 'property', value: 'Group By Property' },
              { key: 'crop', value: 'Group By Crop' }
            ]}
            onChange={e => {
              handleChange(e);
            }}
            value={propertiesGroupedBy}
          />
          <div className=" mt-6">
            <div>
              <h1 className="font-semibold">PROPERTIES</h1>
            </div>
            <div>
              <PropertySelector
                properties={properties}
                cropZonesChecked={cropZonesChecked}
                setCropZonesChecked={setCropZonesChecked}
                setGeoJSONCollectionFeatures={setGeoJSONCollectionFeatures}
                fieldsAndAreasGeoJSONCollection={
                  fieldsAndAreasGeoJSONCollection
                }
                fieldsLoading={fieldsLoading}
                filterFeatureCollectionByParentId={
                  filterFeatureCollectionByParentId
                }
              />
            </div>
          </div>
        </div>

        <PropertyView
          view={view}
          propertyLandingPageData={propertyLandingPageData}
          fieldsAndAreasGeoJSONCollection={fieldsAndAreasGeoJSONCollection}
          fieldsLoading={fieldsLoading}
          zoom={zoom}
          setZoom={setZoom}
        />
      </div>
      <PropertySelectorFooter
        integrationName={integrationName}
        propertyLandingPageData={propertyLandingPageData}
      />
    </div>
  );
};

PropertyIntegrationBase.propTypes = {
  integrationName: PropTypes.string.isRequired
};

export default PropertyIntegrationBase;
