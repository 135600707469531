import { StyleSheet, Text } from '@react-pdf/renderer';
import { notesHeaderTranslations } from 'components/PDF/TaskPdf/utils/headerTranslations';
import PropTypes from 'prop-types';
import React from 'react';

const styles = StyleSheet.create({
  title: {
    fontFamily: 'Noto Sans',
    fontWeight: 'medium',
    fontSize: 12,
    marginBottom: 12
  },
  notes: {
    fontFamily: 'Noto Sans',
    fontSize: 9
  }
});

const Notes = ({ notes, exportLanguage = 'english' }) => {
  return (
    <>
      <Text style={styles.title}>
        {notesHeaderTranslations.notes[exportLanguage]}
      </Text>
      <Text style={styles.notes}>{notes}</Text>
    </>
  );
};

Notes.propTypes = {
  notes: PropTypes.string,
  exportLanguage: PropTypes.string.isRequired
};

Notes.defaultProps = {
  notes: ''
};

export default Notes;
