import React, { createContext, useMemo, useReducer } from 'react';
import PropTypes from 'prop-types';

import reducer, {
  PRODUCTS_IN_USE,
  SEARCH_TEXT,
  SEARCHED_PRODUCTS,
  LOADING_PRODUCT_SEARCH,
  IS_LOADING_PRODUCT_IN_USE,
  TABLE_SEARCH_TEXT
} from '../reducer';

export const initialState = {
  productsInUse: [{}],
  searchText: '',
  tableSearchText: '',
  filterValue: null,
  searchedProducts: [{}],
  loadingProductSearch: false,
  isLoadingProductInUse: undefined
};

export const ProductListContext = createContext(initialState);

const ProductListProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialState);
  const executeAction = (type, action) => {
    dispatch({
      type,
      payload: action
    });
  };

  const setProductsInUse = payload => {
    executeAction(PRODUCTS_IN_USE, payload);
  };

  const { productsInUse } = state;

  const setSearchText = payload => {
    executeAction(SEARCH_TEXT, payload);
  };

  const { searchText } = state;

  const setSearchedProducts = payload => {
    executeAction(SEARCHED_PRODUCTS, payload);
  };

  const { searchedProducts } = state;

  const setLoadingProductSearch = payload => {
    executeAction(LOADING_PRODUCT_SEARCH, payload);
  };

  const { loadingProductSearch } = state;

  const setIsLoadingProductInUse = payload => {
    executeAction(IS_LOADING_PRODUCT_IN_USE, payload);
  };

  const { isLoadingProductInUse } = state;

  const setTableSearchText = payload => {
    executeAction(TABLE_SEARCH_TEXT, payload);
  };

  const { tableSearchText } = state;

  const clearAddProduct = () => {
    setSearchText('');
    setSearchedProducts([]);
    setProductsInUse([]);
  };

  const pro = useMemo(
    () => ({
      setProductsInUse,
      productsInUse,
      setSearchText,
      searchText,
      setSearchedProducts,
      searchedProducts,
      setLoadingProductSearch,
      loadingProductSearch,
      setIsLoadingProductInUse,
      isLoadingProductInUse,
      clearAddProduct,
      setTableSearchText,
      tableSearchText
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      productsInUse,
      searchText,
      searchedProducts,
      loadingProductSearch,
      isLoadingProductInUse,
      tableSearchText
    ]
  );

  return (
    <ProductListContext.Provider value={pro}>
      {children}
    </ProductListContext.Provider>
  );
};

ProductListProvider.defaultProps = {
  children: null
};

ProductListProvider.propTypes = {
  children: PropTypes.node
};

export default ProductListProvider;
