import React from 'react';
import PropTypes from 'prop-types';
import { Table } from '@agconnections/grow-ui';
import PropertyTableRow from '../PropertyTableRow';

const PropertiesTable = ({ cropZones, coveragePercent }) => {
  const handleSort = () => {};
  const sort = {};
  return (
    <>
      {!cropZones ? (
        <>
          <Table>
            <Table.Header>
              <Table.Cell sort={sort} onClick={handleSort}>
                Fields (0)
              </Table.Cell>
              <Table.Cell sort={sort} onClick={handleSort}>
                Crops
              </Table.Cell>
              <Table.Cell sort={sort} onClick={handleSort}>
                Area
              </Table.Cell>
              <Table.Cell sort={sort} onClick={handleSort}>
                Coverage %
              </Table.Cell>
            </Table.Header>
            <Table.Row>
              <Table.Cell width="20rem">
                <div className="flex flex-col justify-start leading-5 text-left">
                  <span className="font-bold text-neutral-1000">
                    No Properties Selected
                  </span>
                </div>
              </Table.Cell>
              <Table.Cell />
              <Table.Cell />
              <Table.Cell />
            </Table.Row>
          </Table>
        </>
      ) : (
        <div className="flex-grow">
          <div className="flex flex-col h-full justify-end">
            <Table>
              <Table.Header>
                <Table.Cell />
                <Table.Cell sort={sort} onClick={handleSort}>
                  {`Fields (${cropZones?.length})`}
                </Table.Cell>
                <Table.Cell sort={sort} onClick={handleSort}>
                  Crops
                </Table.Cell>
                <Table.Cell sort={sort} onClick={handleSort}>
                  Area
                </Table.Cell>
                <Table.Cell sort={sort} onClick={handleSort}>
                  Coverage %
                </Table.Cell>
              </Table.Header>
              {cropZones.map(property => (
                <PropertyTableRow
                  property={property}
                  coveragePercent={coveragePercent}
                />
              ))}
            </Table>
          </div>
        </div>
      )}
    </>
  );
};

PropertiesTable.defaultProps = {
  cropZones: [],
  coveragePercent: ''
};

PropertiesTable.propTypes = {
  cropZones: PropTypes.arrayOf({
    areaUnit: PropTypes.string,
    areaValue: PropTypes.number,
    cropSeasonId: PropTypes.string,
    cropZoneIdValue: PropTypes.string,
    id: PropTypes.string
  }),
  coveragePercent: PropTypes.number
};

export default PropertiesTable;
