import React, { useContext } from 'react';
import { PropTypes } from 'prop-types';

import PropertiesMatch from 'screens/Integrations/PropertiesMatch';
import { INTEGRATIONS } from 'screens/Integrations/helpers/constants';
import SubmissionCropSeason from 'screens/Integrations/components/SubmissionCropSeason';
import SubmissionSummary from 'screens/Integrations/SubmissionSummary';
import ReviewPage from 'screens/Integrations/SubmissionSummary/components/ReviewPage';

import { AgrianContext } from '../../../context/Provider';
import AgrianAuthenticationPage from '../../../components/AgrianAuthenticationPage';
import AgrianOrganization from '../../../components/AgrianOrganization';
import AgrianPropertiesSelection from '../AgrianPropertiesSelection';
import AgrianSubmissionFooter from '../../../components/AgrianSubmissionFooter';

export const PROPERTIES_SELECTION_STEP = 4; // Used to not allow the user move forward the step by progress bar

const STEPS = {
  1: <AgrianAuthenticationPage />,
  2: <AgrianOrganization />,
  3: (
    <SubmissionCropSeason
      context={INTEGRATIONS.agrian}
      FooterComponent={AgrianSubmissionFooter}
    />
  ),
  4: <AgrianPropertiesSelection />,
  5: <PropertiesMatch integrationName={INTEGRATIONS.agrian} />,
  6: (
    <SubmissionSummary
      FooterComponent={AgrianSubmissionFooter}
      ReviewComponent={ReviewPage}
    />
  )
};

const StepSelector = ({ fixedStep }) => {
  const {
    state: { step }
  } = useContext(AgrianContext);

  return STEPS[fixedStep ?? step];
};

StepSelector.propTypes = {
  fixedStep: PropTypes.number
};

export default StepSelector;
