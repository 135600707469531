import React from 'react';
import { Field } from 'formik';
import { Input } from 'syngenta-digital-cropwise-react-ui-kit';

const QualityField = () => {
  return (
    <div className="flex flex-col gap-0.5 weight-input">
      <label
        htmlFor="quality"
        className="text-neutral-60 tracking-tighter text-sm"
      >
        Quality
      </label>
      <Field
        as={Input}
        name="quality"
        id="quality"
        size="middle"
        type="default"
      />
    </div>
  );
};

export default QualityField;
