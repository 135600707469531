import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Popup } from '@agconnections/grow-ui';
import ClickableDiv from 'components/ClickableDiv';

const ListWithPopup = ({ position, ariaLabel, top, delay, msg, component }) => {
  const [hoverTimeout, setHoverTimeout] = useState(null);
  const [showPopup, setShowPopup] = useState(false);

  const onMouseEnter = () =>
    setHoverTimeout(setTimeout(() => setShowPopup(true), delay));

  const onMouseLeave = () => clearTimeout(hoverTimeout) || setShowPopup(false);

  return (
    <ClickableDiv
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
      className="gr-text-left"
    >
      <div className="relative" aria-label={ariaLabel}>
        <div className="absolute" style={{ top }}>
          <Popup
            position={position}
            open={showPopup}
            hasArrow
            trigger={<div />}
          >
            <div className="whitespace-no-wrap">{msg}</div>
          </Popup>
        </div>
      </div>
      <div>{component}</div>
    </ClickableDiv>
  );
};

ListWithPopup.propTypes = {
  position: PropTypes.string.isRequired,
  ariaLabel: PropTypes.string.isRequired,
  top: PropTypes.string.isRequired,
  delay: PropTypes.number.isRequired,
  msg: PropTypes.string.isRequired,
  component: PropTypes.node.isRequired
};

export default ListWithPopup;
