const searchByTextWithinFFT = (fft = [], textValue = '') => {
  if (textValue === '' || textValue === undefined) {
    return fft;
  }

  const criteria = textValue.toLowerCase();
  return fft.filter(
    prop =>
      prop.name.toLowerCase().includes(criteria) ||
      prop.fields.some(
        field =>
          field.name.toLowerCase().includes(criteria) ||
          field?.cropzones?.some(cropZone =>
            cropZone.name.toLowerCase().includes(criteria)
          )
      )
  );
};

export default searchByTextWithinFFT;
