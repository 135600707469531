import React from 'react';
import PropTypes from 'prop-types';

const BreadcrumbDropDownItem = ({ name, onSelect, isActive }) => {
  return (
    <li
      data-testid="breadcrumb-dropdown-item"
      className={`hover:bg-gray-200 cursor-pointer hover:bg-text-800 pb-1 pl-2 pt-1 ${
        isActive ? 'bg-info-light-blue' : ''
      }`}
    >
      <div
        className="flex justify-start p-1"
        role="button"
        tabIndex={0}
        onClick={onSelect}
        onKeyDown={event => event.nativeEvent.key === 'Enter' && onSelect()}
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="25"
          height="24"
          viewBox="0 0 25 24"
          fill="none"
          className="flex-shrink-0"
        >
          <path
            d="M8.8273 15.8769L4.6573 11.7069L3.2373 13.1169L8.8273 18.7069L20.8273 6.70687L19.4173 5.29688L8.8273 15.8769Z"
            fill={isActive ? '#0071CD' : 'bg-text-800'}
          />
        </svg>

        <div className="leading-5 ml-2 text-sm font-normal truncate text-neutral-1000">
          {name}
        </div>
      </div>
    </li>
  );
};

BreadcrumbDropDownItem.propTypes = {
  name: PropTypes.string.isRequired,
  onSelect: PropTypes.func.isRequired,
  isActive: PropTypes.bool
};

BreadcrumbDropDownItem.defaultProps = {
  isActive: false
};

export default BreadcrumbDropDownItem;
