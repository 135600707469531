import React from 'react';
import PropTypes from 'prop-types';

const CropZoneIcon = ({ size = 24 }) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M14.0794 5.69593C14.0794 7.73714 11.9674 9.39187 11.9674 9.39187C11.9674 9.39187 9.85547 7.73714 9.85547 5.69593C9.85547 3.65473 11.9674 2 11.9674 2C11.9674 2 14.0794 3.65473 14.0794 5.69593Z"
      stroke="#696F88"
      strokeWidth="2"
      strokeLinejoin="round"
    />
    <path
      d="M16.6614 11.4107C15.2181 12.8541 12.5546 12.5308 12.5546 12.5308C12.5546 12.5308 12.2313 9.86732 13.6747 8.42397C15.118 6.98061 17.7815 7.30393 17.7815 7.30393C17.7815 7.30393 18.1048 9.96738 16.6614 11.4107Z"
      stroke="#696F88"
      strokeWidth="2"
      strokeLinejoin="round"
    />
    <path
      d="M16.6614 16.6744C15.2181 18.1178 12.5546 17.7944 12.5546 17.7944C12.5546 17.7944 12.2313 15.131 13.6747 13.6876C15.118 12.2443 17.7815 12.5676 17.7815 12.5676C17.7815 12.5676 18.1048 15.2311 16.6614 16.6744Z"
      stroke="#696F88"
      strokeWidth="2"
      strokeLinejoin="round"
    />
    <path
      d="M7.33857 11.4107C8.78192 12.8541 11.4454 12.5308 11.4454 12.5308C11.4454 12.5308 11.7687 9.86732 10.3253 8.42397C8.88198 6.98062 6.21853 7.30393 6.21853 7.30393C6.21853 7.30393 5.89522 9.96738 7.33857 11.4107Z"
      stroke="#696F88"
      strokeWidth="2"
      strokeLinejoin="round"
    />
    <path
      d="M7.33857 16.6744C8.78192 18.1178 11.4454 17.7944 11.4454 17.7944C11.4454 17.7944 11.7687 15.131 10.3253 13.6876C8.88198 12.2443 6.21853 12.5676 6.21853 12.5676C6.21853 12.5676 5.89522 15.2311 7.33857 16.6744Z"
      stroke="#696F88"
      strokeWidth="2"
      strokeLinejoin="round"
    />
    <path
      d="M11.9678 10.4434L11.9678 22.0592"
      stroke="#696F88"
      strokeWidth="2"
      strokeLinecap="round"
    />
  </svg>
);

CropZoneIcon.propTypes = {
  size: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
};

CropZoneIcon.defaultProps = {
  size: 24
};

export default CropZoneIcon;
