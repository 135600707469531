import React from 'react';
import IntegrationsProvider from 'screens/Integrations/context/IntegrationsProvider';
import BaseMccainIntegration from '../BaseMccainIntegration';

const MccainParentProvider = () => {
  return (
    <IntegrationsProvider>
      <BaseMccainIntegration />
    </IntegrationsProvider>
  );
};

export default MccainParentProvider;
