import React, { useContext, useEffect, useState } from 'react';
import {
  Button,
  ButtonType,
  Tooltip
} from 'syngenta-digital-cropwise-react-ui-kit';
import SpinnerModal from 'components/SpinnerModal';
import PortalModal from 'components/Modals/PortalModal';
import { AmplitudeContext } from 'utilities/amplitude/useAmplitude';
import { useToast } from 'components/ToastContainer';
import { ReactComponent as DeleteIcon } from 'assets/delete_outlined.svg';
import useStorageLocations from '../../hooks/useStorageLocations';
import { StorageLocationContext } from '../../context/StorageLocationProvider';

const DeleteStorage = () => {
  const amplitude = useContext(AmplitudeContext);
  const toast = useToast();

  const {
    state: { selectedId, data = [] },
    refetch,
    resetLocation
  } = useContext(StorageLocationContext);

  const {
    deleteStorageLocation,
    deleting: { isLoading, isError, isSuccess }
  } = useStorageLocations();

  const [askConfirmation, setAskConfirmation] = useState(false);

  const selectedIndex = data.findIndex(item => item.id === selectedId);
  const selected = data[selectedIndex];
  const isDisabled = selected?.hasAssociatedYields;

  const handleConfirmation = () => setAskConfirmation(prev => !prev);

  const onDelete = async () => {
    amplitude.sendEventToAmplitude(
      amplitude.events.epic.YieldV2.storageLocations.delete
    );
    await deleteStorageLocation(selected.id);
    handleConfirmation();
  };

  useEffect(() => {
    if (isLoading) return;
    if (isError) {
      toast.error('Unable to delete location.', {
        content:
          'An error prevented your location from being deleted. Please try again.',
        supportButton: true
      });
      return;
    }
    if (isSuccess) {
      toast.success('Location deleted.');
      resetLocation();
      const nextStorage = data?.[selectedIndex + 1] || data?.[0];
      refetch({ skipCache: true, initSelected: nextStorage?.id });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoading, isSuccess, isError]);

  if (isLoading) return <SpinnerModal open message="Deleting Location..." />;
  return (
    <>
      <Tooltip
        overlayClassName="max-w-full"
        title={
          isDisabled
            ? 'Locations that are associated with loads cannot be deleted'
            : null
        }
        placement="topRight"
      >
        <div>
          <Button
            icon={<DeleteIcon width={18} />}
            disabled={isDisabled}
            type={ButtonType.outline}
            onClick={handleConfirmation}
          >
            Delete
          </Button>
        </div>
      </Tooltip>

      <PortalModal
        title={<span className="font-semibold">Delete Location?</span>}
        type="error"
        open={askConfirmation}
        close={handleConfirmation}
        confirmLabel="Delete"
        cancelLabel="Cancel"
        onConfirm={onDelete}
      >
        <div className="pr-6">
          Are you sure you want to delete this location?
        </div>
      </PortalModal>
    </>
  );
};

export default DeleteStorage;
