import React from 'react';
import { useFormikContext } from 'formik';
import { cssMarginContainer } from '../../helpers/constants';

const NotesTab = () => {
  const { values } = useFormikContext();
  const { notes } = values;
  return (
    <div className={cssMarginContainer}>
      <p className={!notes ? 'text-gray-500' : null}>
        {!notes ? 'None entered' : notes}
      </p>
    </div>
  );
};

export default NotesTab;
