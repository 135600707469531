import React from 'react';
import PropTypes from 'prop-types';
import FormattedNumber from 'components/FormattedNumber';

const SelectedPropertyInfo = ({ property }) => {
  return (
    <div
      className="p-3 border-t-2 first:border-t-0"
      data-testid="selected-properties-info"
    >
      <div className="flex justify-between">
        <span className="font-bold text-sm">{property.fieldName}</span>
        <span className="font-bold text-sm">
          <FormattedNumber roundedTo={2}>
            {property.reportedArea || property.boundaryArea}
          </FormattedNumber>{' '}
          ac
        </span>
      </div>
      <div>
        <span className="text-sm">{property.name}</span>
      </div>
      <div>
        <span className="text-sm text-gray-600">{property.crop.name}</span>
      </div>
    </div>
  );
};

SelectedPropertyInfo.propTypes = {
  property: PropTypes.shape().isRequired
};

export default SelectedPropertyInfo;
