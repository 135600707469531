export const MAP_HISTORY_SET_NEW_FEATURE = 'MAP_HISTORY_SET_NEW_FEATURE';
export const MAP_HISTORY_SET_FEATURE_PROCESSED =
  'MAP_HISTORY_SET_FEATURE_PROCESSED';

export const resetMapHistory = () => ({
  newFeatures: [],
  archivedFeatures: [],
  mapChangeEvent: new Event('init history')
});

/**
 * creates a mapChangeAction from a reducer action
 * @param action
 *    - type
 *    - payload
 * @returns mapChangeAction
 *    - type
 *    - payload
 *    - mapChangeEvent
 */
const createMapChangeAction = action => {
  const mapChangeEvent =
    action.payload && action.payload.event
      ? action.payload.event
      : new Event(action.type);
  return {
    ...action,
    mapChangeEvent
  };
};

/**
 * create a new mapHistoryFeature with featureType, feature, and mapChangeEvent
 * @param payload - either a mapChangeAction or an array of mapChangeAction items
 * @param mapChangeEvent
 * @returns mapHistoryFeature
 *    - mapChangeEvent
 */
export const createNewMapHistoryFeatures = ({ payload, mapChangeEvent }) => {
  if (Array.isArray(payload)) {
    return payload.map(({ feature, featureType, featureAttributes }) => ({
      feature,
      featureType,
      featureAttributes,
      mapChangeEvent
    }));
  }
  return {
    ...payload,
    mapChangeEvent
  };
};

const processedFeaturePredicate = mapChangeAction => newFeature =>
  newFeature.mapChangeEvent.timeStamp ===
  mapChangeAction.mapChangeEvent.timeStamp;

// mapHistoryReducer provides the state for newFeatures and archivedFeatures to support the undo/redo functionality
// - newFeatures - an array of features to be processed by LandMap and then archived into archivedFeatures
// - archivedFeatures - an array of features that have been added to the map
// - mapChangeEvent - an event that is triggered by additions to newFeatures that need to be processed
export const mapHistoryReducer = (state, action) => {
  const mapChangeAction = createMapChangeAction(action);
  switch (action.type) {
    case MAP_HISTORY_SET_NEW_FEATURE:
      return {
        ...state,
        newFeatures: state.newFeatures.concat(
          createNewMapHistoryFeatures(mapChangeAction)
        ),
        mapChangeEvent: mapChangeAction.mapChangeEvent
      };
    case MAP_HISTORY_SET_FEATURE_PROCESSED:
      return {
        ...state,
        archivedFeatures: [
          ...state.archivedFeatures,
          state.newFeatures.find(processedFeaturePredicate(mapChangeAction))
        ],
        newFeatures: state.newFeatures.filter(
          feature => !processedFeaturePredicate(mapChangeAction)(feature)
        )
      };
    default:
      return state;
  }
};
