import { ROLES } from 'utilities/access';

import CropSeasons from 'screens/CropSeasons';
import CropSeason from 'screens/CropSeasons/CropSeason';
import CropSeasonView from 'screens/CropSeasons/CropSeason/CropSeasonView';

import { CropSeasonIcon } from 'routes/icons';
import { paths } from 'routes/paths';

const routes = [
  {
    id: 'crop seasons',
    key: 'crop seasons',
    path: paths.cropSeasons,
    exact: true,
    secure: true,
    disabled: false,
    component: CropSeasons,
    icon: CropSeasonIcon,
    nav: 'leftNav'
  },
  // NOTE: can this route resolve views for edit, view and create?
  // looks dont follow entry postfix in order to apply disable or any mod
  // check withFeatureAware HOC
  {
    id: 'crop season',
    key: 'crop season',
    path: `${paths.cropSeasons}/:id`,
    exact: true,
    secure: true,
    disabled: false,
    component: CropSeason,
    minPermissions: {
      minAllowedRole: ROLES.FULL_CONTROL
    }
  },
  {
    id: 'crop season view',
    key: 'crop season view',
    path: `${paths.cropSeasons}/:id/view`,
    exact: true,
    secure: true,
    disabled: false,
    component: CropSeasonView
  }
];

export default routes;
