import { format, parseISO } from 'date-fns';

/* eslint-disable import/prefer-default-export */

export const standardFormat = date => {
  return date && format(Date.parse(date), 'MM/dd/yyyy');
};

export const customDateFormat = (date, pattern) => {
  if (!pattern) return standardFormat(date);
  return date && format(parseISO(date), pattern);
};

export const taskFormat = date => {
  if (date) {
    return format(Date.parse(date), 'MMM d, h:mm aaaa');
  }
  return '';
};

export const taskListViewFormat = date => {
  return format(Date.parse(date), 'MMM d. y h:mm aaaa');
};

export const taskDetailViewFormat = date => {
  return format(Date.parse(date), 'MMMM d, y, h:mm aaaa');
};

export const recommendationViewFormat = date => {
  return (
    date && format(typeof date === 'number' ? date : Date.parse(date), 'PPP')
  );
};

export const onlyTimeFormat = date => {
  return date && format(Date.parse(date), 'h:mm aaaa');
};

const twoDigits = num => `${num < 10 ? '0' : ''}${num}`;
export const yearMonthDayFormat = date =>
  `${date.getFullYear()}-${twoDigits(date.getMonth() + 1)}-${twoDigits(
    date.getDate()
  )}`;
