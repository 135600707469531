/* eslint-disable no-else-return */
import React from 'react';
import PropTypes from 'prop-types';
import { Input, SimpleModal, RadioButtonGroup } from '@agconnections/grow-ui';

const PropertiesModal = ({ open, onClose, property }) => {
  const displayEmptyCell = value => {
    if (!value || value === 0) {
      return ' ';
    } else {
      return `${value} ac`;
    }
  };
  return (
    <SimpleModal open={open} close={onClose} onConfirm={onClose} size="sm">
      <div>
        <div className="flex items-center justify-between">
          <div className="font-bold text-lg">{property.fieldName}</div>
        </div>

        <div className="text-neutral-300 text-sm -mt-1">
          {property.cropZoneName}
        </div>

        <div className="w-48 mt-4 flex justify-between">
          <div>
            <div className="text-neutral-300 text-sm">Area</div>
            <div className="font-semibold text-lg">{property.areaValue} ac</div>
          </div>

          <div>
            <div className="text-neutral-300 text-sm">Crop</div>
            <div className="font-semibold text-lg">{property.cropName}</div>
          </div>
        </div>

        <div className="mt-8">
          <RadioButtonGroup>
            <RadioButtonGroup.RadioButton disabled selected>
              Reported Area ({displayEmptyCell(property.reportedArea)})
            </RadioButtonGroup.RadioButton>
            <RadioButtonGroup.RadioButton disabled>
              Boundary Area ({property.boundaryArea} ac)
            </RadioButtonGroup.RadioButton>
          </RadioButtonGroup>
        </div>

        <div className="flex mt-12 mb-24">
          <Input
            disabled
            label="Applied Area"
            value={`${property.areaValue} ac`}
          />
          <div className="ml-4">
            <Input
              disabled
              label="Coverage %"
              value={Math.round(property?.coveragePercent + Number.EPSILON)}
            />
          </div>
        </div>
      </div>
    </SimpleModal>
  );
};

PropertiesModal.defaultProps = {
  open: false,
  onClose: () => {}
};
PropertiesModal.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
  property: PropTypes.shape({
    cropZoneName: PropTypes.string,
    cropName: PropTypes.string,
    fieldName: PropTypes.string,
    areaValue: PropTypes.number,
    areaUnit: PropTypes.string,
    boundaryArea: PropTypes.number,
    reportedArea: PropTypes.number,
    originalAcres: PropTypes.number,
    coveragePercent: PropTypes.number
  }).isRequired
};

export default PropertiesModal;
