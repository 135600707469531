import shortId from 'shortid';

const createColumns = (tasks, sorts) => {
  const readyTasks = tasks.filter(task => task.status === 'ready');
  const inProgressTasks = tasks.filter(task => task.status === 'inprogress');
  const completeTasks = tasks.filter(task => task.status === 'complete');

  const sortedReadyTasks = readyTasks.slice().sort((previousTask, nextTask) => {
    if (sorts.ready.sort === 'desc') {
      return new Date(nextTask.startDate) - new Date(previousTask.startDate);
    }
    if (sorts.ready.sort === 'asc') {
      return new Date(previousTask.startDate) - new Date(nextTask.startDate);
    }
    return new Date(nextTask.startDate) - new Date(previousTask.startDate);
  });

  const sortedInProgressTasks = inProgressTasks
    .slice()
    .sort((previousTask, nextTask) => {
      if (sorts.inprogress.sort === 'desc') {
        return new Date(nextTask.startDate) - new Date(previousTask.startDate);
      }
      if (sorts.inprogress.sort === 'asc') {
        return new Date(previousTask.startDate) - new Date(nextTask.startDate);
      }
      return new Date(nextTask.startDate) - new Date(previousTask.startDate);
    });

  const sortedCompleteTasks = completeTasks
    .slice()
    .sort((previousTask, nextTask) => {
      if (sorts.complete.sort === 'desc') {
        return new Date(nextTask.startDate) - new Date(previousTask.startDate);
      }
      if (sorts.complete.sort === 'asc') {
        return new Date(previousTask.startDate) - new Date(nextTask.startDate);
      }
      return new Date(nextTask.startDate) - new Date(previousTask.startDate);
    });

  const readyColumn = {
    id: shortId.generate(),
    title: 'ready',
    tasks: sortedReadyTasks
      .filter(task => {
        return task.status === 'ready';
      })
      .map(task => task.id)
  };
  const inProgressColumn = {
    id: shortId.generate(),
    title: 'inprogress',
    tasks: sortedInProgressTasks
      .filter(task => {
        return task.status === 'inprogress';
      })
      .map(task => task.id)
  };
  const completeColumn = {
    id: shortId.generate(),
    title: 'complete',
    tasks: sortedCompleteTasks
      .filter(task => {
        return task.status === 'complete';
      })
      .map(task => task.id)
  };

  return [readyColumn, inProgressColumn, completeColumn];
};

export default createColumns;
