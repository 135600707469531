import React, { useState } from 'react';
import { Button, TagCloud, Tag } from '@agconnections/grow-ui';
import PencilEditIcon from 'components/Icons/components/PencilEditIcon';
import { useFormikContext } from 'formik';
import MccainVendorIdModal from './MccainVendorIdModal';

const TopHeader = () => {
  const { values } = useFormikContext();
  const [vendorIdModal, setVendorIdModal] = useState(false);

  const showModal = () => {
    setVendorIdModal(true);
  };

  const closeModal = () => {
    setVendorIdModal(false);
  };

  return (
    <div data-testid="top-header">
      <div className="w-full flex h-10 items-center">
        <p className="font-normal text-sm ">
          Review the selected data before submitting to McCain.
        </p>
      </div>
      <div className="w-full flex h-20 items-center font-semibold">
        <p className="text-2xl">
          McCain Foods Inc. Submission {values.submitDate.toLocaleDateString()}
        </p>
      </div>
      <div className="w-full h-32">
        <div className="flex w-full h-full">
          <div className="flex h-full">
            <div className="h-full">
              <MccainVendorIdModal open={vendorIdModal} onClose={closeModal} />
              <p className="font-thin text-xs text-gray-600">
                McCain Vendor ID
              </p>
              <div className="flex w-full items-center">
                <p className="text-2xl pr-2 flex w-auto overflow-x-auto">
                  {values.mccainVendorId}
                </p>
                <div className="">
                  <Button
                    type="text"
                    icon={<PencilEditIcon />}
                    onClick={showModal}
                  />
                </div>
              </div>
            </div>
            <div className="ml-14 h-full">
              <p className="font-thin text-xs text-gray-600">Crop Season(s) </p>
              <div>
                <TagCloud data-testid="crops">
                  {/* delete dummy data replace with values?.selectedCropSeasons */}
                  {values.selectedCropSeasons.map(cropSeason => (
                    <Tag key={cropSeason.id} color="#696F88">
                      {cropSeason.name}
                    </Tag>
                  ))}
                </TagCloud>
              </div>
            </div>
          </div>
          <div className="flex">
            <div className="flex flex-col ml-14">
              <p className="font-thin text-xs text-gray-600">Start Date</p>
              <p className="text-2xl font-normal pr-2">
                {values?.cropSeasonStartDate.toLocaleDateString()}
              </p>
            </div>
            <div className="flex flex-col ml-12">
              <p className="font-thin text-xs text-gray-600">End Date</p>
              <p className="text-2xl font-normal pr-2">
                {values?.cropSeasonEndDate.toLocaleDateString()}
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TopHeader;
