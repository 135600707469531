import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import usePeople from 'hooks/usePeopleData';
import useCompanies from 'hooks/useCompanies';
import { FieldArray, useFormikContext } from 'formik';
import {
  Button,
  DatePicker,
  Input,
  Label,
  Select,
  TextArea
} from '@agconnections/grow-ui';
import SourceDocumentModal from 'components/Modals/SourceDocumentModal';
import TagsModal from 'components/Modals/TagsModal';
import TagsButton, {
  extraTagsCalculation,
  TagsPopUpMessage
} from 'components/TagsButton';
import { STORAGE_KEYS } from 'utilities/localStorage';
import ListWithPopup from 'components/ListWithPopup';
import GrowUIFormField from 'components/Forms/GrowUIFormField';
import alphanumSort from 'helpers/sorters';
import Applicators from '../../../../Tasks/Task/Tabs/Details/Sections/Applicators';

const buttonDisplayObject = {
  extraTagsCount: 0,
  extraTagsNames: [],
  tagsToDisplay: []
};

function DetailsTab({ tags, selectedCropSeasons }) {
  const { values, setFieldValue, setFieldTouched } = useFormikContext();
  const [openTags, setOpenTags] = useState(false);
  const [recommendationTags, setRecommendationTags] = useState(
    tags?.filter(tag => values?.flagIds?.find(flag => flag === tag.id)) || []
  );
  const [recommendationSources, setRecommendationSources] = useState(
    values?.source
  );
  const [displaySourcesObject, setDisplaySourcesObject] = useState(
    buttonDisplayObject
  );
  const [displayTagsObject, setDisplayTagsObject] = useState(
    buttonDisplayObject
  );
  // const { cropSeasons } = useCropSeasons();
  const { companies, loading } = useCompanies();
  const { people, loading: peopleLoading } = usePeople();
  const [openSources, setOpenSources] = useState(false);
  const mappedPeopleNames = alphanumSort(
    people
      ? people.map(person => {
          return { key: person.id, value: person.name };
        })
      : [],
    'value'
  );

  const handleAuthorizedByChanged = e => {
    setFieldTouched('Authorized By');
    const { key, value } = JSON.parse(e.target.value);
    setFieldValue('authorizedBy', key);
    setFieldValue('authorizedByName', value);
  };

  const handleAddRow = (section, { ...helpers }, fields) => {
    helpers.push({
      ...fields
      // id: values[section].length
    });
  };

  const handleRemoveRow = (section, { ...helpers }, index) => {
    helpers.remove(index);
    if (values[section].length === 0) {
      handleAddRow(
        section,
        helpers,
        section === 'applicators'
          ? {
              id: '',
              company: ''
            }
          : { id: '' }
      );
    }
  };

  const handleSelectedSources = selectedSources => {
    setRecommendationSources(selectedSources);
    setFieldValue('source', selectedSources);
  };

  const handleSelectedTags = async e => {
    setFieldValue(
      'flagIds',
      e.map(flag => flag.id)
    );
    setRecommendationTags(e);
  };

  useEffect(() => {
    setDisplaySourcesObject(extraTagsCalculation(recommendationSources, 42));
  }, [recommendationSources]);

  useEffect(() => {
    setDisplayTagsObject(extraTagsCalculation(recommendationTags, 38));
  }, [recommendationTags]);

  useEffect(() => {
    setFieldValue('cropSeasonIds', selectedCropSeasons);
  }, [selectedCropSeasons, setFieldValue]);

  return (
    <div className="-mx-6">
      {openSources && (
        <SourceDocumentModal
          open={openSources}
          onClose={setOpenSources}
          setSourceDocs={handleSelectedSources}
          selected={recommendationSources}
          setFieldTouched={setFieldTouched}
        />
      )}
      <TagsModal
        open={openTags}
        close={() => setOpenTags(false)}
        name={values?.name}
        selectedTags={recommendationTags?.reduce((tagIds, tag) => {
          return `${tagIds},${tag.id}`;
        }, '')}
        returnSelectedTags={handleSelectedTags}
        setFieldTouchedParent={setFieldTouched}
      />
      <div className="text-2xl font-semibold mb-12" id="recommendation-info">
        Recommendation Info
      </div>
      <div
        className="grid items-end grid-cols-3 gap-24 mb-12 gap-y-12"
        data-testid="recommendation-info-content-1"
      >
        <GrowUIFormField
          label="Recommendation ID *"
          control={Input}
          name="name"
        />
        <GrowUIFormField
          label="Recommendation Number"
          control={Input}
          name="number"
        />
        <GrowUIFormField
          label="Timing Event"
          control={Select}
          name="timingEvent"
          items={[
            { key: 'Pre-Plant', value: 'Pre-Plant' },
            { key: 'Planting', value: 'Planting' },
            { key: 'Growing', value: 'Growing' },
            { key: 'Harvest', value: 'Harvest' },
            { key: 'Storage', value: 'Storage' },
            { key: 'Annual', value: 'Annual' }
          ]}
        />
        <GrowUIFormField
          label="Proposed Date *"
          control={DatePicker}
          name="proposedDate"
          dateFormat="F j,  Y"
          onChange={date => {
            if (
              new Date(date.target.value[0]).valueOf() <=
              new Date(values.expirationDate).valueOf()
            ) {
              setFieldTouched('proposedDate');
              setFieldValue('proposedDate', date.target.value[0]);
            }
          }}
        />
        <GrowUIFormField
          label="Expiration Date *"
          control={DatePicker}
          name="expirationDate"
          dateFormat="F j,  Y"
          onChange={date => {
            setFieldTouched('expirationDate');
            setFieldValue('expirationDate', date.target.value[0]);
          }}
        />
        <div>
          <Label absolute>Sources</Label>
          {displaySourcesObject.extraTagsNames.length > 0 ? (
            <ListWithPopup
              position="botton"
              ariaLabl="Recommendation Sources"
              top="1.5rem"
              msg={
                <TagsPopUpMessage
                  extraTagsNames={displaySourcesObject.extraTagsNames}
                />
              }
              component={
                <TagsButton
                  setOpenTags={setOpenSources}
                  tags={recommendationSources}
                  displayTagsObject={displaySourcesObject}
                  viewKey={STORAGE_KEYS.RECOMMENDATIONS_VIEW}
                  disabled={false}
                  prompt="Add Sources"
                />
              }
            />
          ) : (
            <TagsButton
              setOpenTags={setOpenSources}
              tags={recommendationSources}
              displayTagsObject={displaySourcesObject}
              viewKey={STORAGE_KEYS.RECOMMENDATIONS_VIEW}
              disabled={false}
              prompt="Add Sources"
            />
          )}
        </div>
        <div>
          <Label absolute>Tags</Label>
          {displayTagsObject.extraTagsNames.length > 0 ? (
            <ListWithPopup
              position="bottom"
              ariaLabel="Task Tags"
              top="1.5rem"
              msg={
                <TagsPopUpMessage
                  extraTagsNames={displayTagsObject.extraTagsNames}
                />
              }
              component={
                <TagsButton
                  setOpenTags={setOpenTags}
                  tags={recommendationTags}
                  displayTagsObject={displayTagsObject}
                  viewKey={STORAGE_KEYS.RECOMMENDATIONS_VIEW}
                  disabled={false}
                />
              }
            />
          ) : (
            <TagsButton
              setOpenTags={setOpenTags}
              tags={recommendationTags}
              displayTagsObject={displayTagsObject}
              viewKey={STORAGE_KEYS.RECOMMENDATIONS_VIEW}
              disabled={false}
            />
          )}
        </div>
        <GrowUIFormField
          label="Authorized By"
          control={Select}
          name="authorizedBy"
          items={mappedPeopleNames}
          onChange={handleAuthorizedByChanged}
        />
        <GrowUIFormField
          label="Authorization Date *"
          control={DatePicker}
          name="authorizationDate"
          dateFormat="F j,  Y"
          onChange={date => {
            setFieldTouched('authorizationDate');
            setFieldValue('authorizationDate', date.target.value[0]);
          }}
        />
      </div>
      <FieldArray
        name="applicators"
        render={arrayHelpers => (
          <>
            <div className="flex justify-between gap-24 mb-12 gap-y-12">
              <div className="text-2xl font-semibold" id="applicators">
                Applicator(s)
              </div>
              <div>
                <Button
                  type="primary"
                  ghost
                  onClick={() => {
                    setFieldTouched('Applicators');
                    handleAddRow('applicators', arrayHelpers, {
                      id: '',
                      company: ''
                    });
                  }}
                >
                  Add Applicator
                </Button>
              </div>
            </div>
            <div
              className="grid items-end grid-cols-2 gap-24 mb-12 gap-y-12"
              data-testid="applicators-content"
            >
              <Applicators
                people={people}
                peopleLoading={peopleLoading}
                companies={companies}
                companiesLoaded={!loading}
                handleRemove={index => {
                  setFieldTouched('Applicators');
                  handleRemoveRow('applicators', arrayHelpers, index);
                }}
                showBufferInfo={false}
              />
            </div>
          </>
        )}
      />
      <div className="text-2xl font-semibold mb-4" id="notes">
        Notes
      </div>
      <GrowUIFormField control={TextArea} name="notes" />
    </div>
  );
}

DetailsTab.propTypes = {
  tags: PropTypes.arrayOf(PropTypes.object).isRequired,
  selectedCropSeasons: PropTypes.arrayOf(PropTypes.object).isRequired
};

export default DetailsTab;
