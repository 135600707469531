import React from 'react';
import { Form, Input } from '@agconnections/grow-ui';
import GrowUIFormField from 'components/Forms/GrowUIFormField';

const EmailField = () => {
  return (
    <Form.Field label="Email Address">
      <GrowUIFormField control={Input} id="email" name="email" />
    </Form.Field>
  );
};

export default EmailField;
