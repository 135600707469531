import React from 'react';
import { Formik } from 'formik';
import IntegrationsProvider from 'screens/Integrations/context/IntegrationsProvider';

import Provider from '../../../context/Provider';
import BaseAgrianIntegration from '../BaseAgrianIntegration';
import { INTEGRATIONS } from '../../../../helpers/constants';
import SubmissionSummaryProvider from '../../../../SubmissionSummary/context/SubmissionSummaryProvider';

const AgrianParentProvider = () => {
  return (
    <IntegrationsProvider>
      <Provider>
        <Formik initialValues={{}}>
          {() => (
            <SubmissionSummaryProvider integrationType={INTEGRATIONS.agrian}>
              <BaseAgrianIntegration />
            </SubmissionSummaryProvider>
          )}
        </Formik>
      </Provider>
    </IntegrationsProvider>
  );
};

export default AgrianParentProvider;
