import React from 'react';
import PropTypes from 'prop-types';
import SpinnerModal from 'components/SpinnerModal';
import { SimpleModal } from '@agconnections/grow-ui';

const DeleteConfirmationSaleLocation = ({
  open,
  loading,
  onCancel,
  onSubmit
}) => {
  const deleteMessage = 'Are you sure you want to delete this location?';
  if (!open) return null;
  if (loading) return <SpinnerModal open message="Deleting Sale Location..." />;
  return (
    <SimpleModal
      title={<span className="font-semibold">Delete Location?</span>}
      type="error"
      open={open}
      close={onCancel}
      confirmLabel="Delete"
      cancelLabel="Cancel"
      onConfirm={onSubmit}
    >
      <div className="pr-6">{deleteMessage}</div>
    </SimpleModal>
  );
};

DeleteConfirmationSaleLocation.propTypes = {
  loading: PropTypes.bool.isRequired,
  open: PropTypes.bool.isRequired,
  onCancel: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired
};

export default DeleteConfirmationSaleLocation;
