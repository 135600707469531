import React, { useContext, useState } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { Button, ButtonType } from 'syngenta-digital-cropwise-react-ui-kit';
import { AmplitudeContext } from 'utilities/amplitude/useAmplitude';
import { Context } from 'components/Store';
import CollapsibleToggle from 'components/CollapsibleToggle';
import usePropertyFilters from 'hooks/usePropertyFilters';
import FarmsFilter from './components/FarmsFilter';

const PropertyFilters = ({ properties, onClose }) => {
  const [{ organization, propertyFilters, isExportPDFMapsActive }] = useContext(
    Context
  );
  const flags = useFlags();
  const feArchiveField =
    flags.feArchiveField ||
    (flags.feArchiveFieldOrgIds &&
      flags.feArchiveFieldOrgIds.indexOf(organization?.id) !== -1);

  const feFarmsFilter = true;
  const amplitude = useContext(AmplitudeContext);
  const {
    showArchivedProperties,
    hasFilterChanges,
    applyFilters
  } = usePropertyFilters();

  const [
    showLocalArchivedProperties,
    setShowLocalArchivedProperties
  ] = useState(showArchivedProperties);
  const [selectedFarmIds, setSelectedFarmIds] = useState(
    propertyFilters?.showFarmWithIds || []
  );

  const hasSelectedFilters = () =>
    showLocalArchivedProperties || !!selectedFarmIds.length;

  const isFilterTouched = () =>
    hasFilterChanges({ selectedFarmIds, showLocalArchivedProperties });

  const onFarmSelect = id => () => {
    if (selectedFarmIds.includes(id)) {
      setSelectedFarmIds(selectedFarmIds.filter(farmId => farmId !== id));
    } else {
      setSelectedFarmIds([...selectedFarmIds, id]);
    }
  };

  const toggleArchiveField = value => {
    if (!value) {
      // If archived farms are toggled off, de-select all archived farms in farm filter
      const archivedFarmIds = properties
        .filter(farm => farm.archived)
        .map(farm => farm.id);
      setSelectedFarmIds(_.difference(selectedFarmIds, archivedFarmIds));
    }
    setShowLocalArchivedProperties(value);
  };

  const onApply = () => {
    applyFilters({ showLocalArchivedProperties, selectedFarmIds });
    amplitude.sendEventToAmplitude(
      amplitude.events.epic.Properties.filters.toggleArchivedProperties,
      {
        localArchivedProperties: showLocalArchivedProperties
      }
    );
    onClose();
  };

  const onCancel = () => {
    onClose();
  };

  const onClearAll = () => {
    setSelectedFarmIds([]);
    setShowLocalArchivedProperties(false);
  };

  return (
    <div
      data-tree
      className="relative flex-grow flex flex-col h-full bg-white"
      data-testid="mass-assigner-tree"
    >
      <div className="flex-1 flex flex-col h-full">
        <div className="w-full px-5 flex flex-row justify-between items-center sticky top-0 bg-white pt-6 pb-10">
          <h1 className="text-3xl font-semibold">Filter</h1>
          <Button
            data-testid="clear-all-filters-button"
            type={ButtonType.outline}
            size="small"
            onClick={onClearAll}
            disabled={!hasSelectedFilters()}
          >
            Clear All
          </Button>
        </div>
        <div className="mb-16 overflow-y-auto">
          {feFarmsFilter && (
            <FarmsFilter
              farms={properties}
              selectedFarmIds={selectedFarmIds}
              onFarmSelect={onFarmSelect}
              showArchived={showLocalArchivedProperties}
            />
          )}
          {feArchiveField && !isExportPDFMapsActive && (
            <CollapsibleToggle
              options={[
                {
                  name: 'Show archived properties in field list',
                  onChange: toggleArchiveField,
                  value: showLocalArchivedProperties
                }
              ]}
              title="Archived"
            />
          )}
        </div>
        <div className="flex flex-row w-full gap-x-2 py-6 px-6 justify-between absolute left-0 bottom-0 bg-white border-t-1 border-neutral-20">
          <Button onClick={onCancel} type={ButtonType.outline}>
            Cancel
          </Button>
          <Button
            data-testid="apply-fft-filters-button"
            onClick={onApply}
            type={ButtonType.primary}
            disabled={!isFilterTouched()}
          >
            Apply
          </Button>
        </div>
      </div>
    </div>
  );
};

PropertyFilters.defaultProps = {
  properties: []
};

PropertyFilters.propTypes = {
  onClose: PropTypes.func.isRequired,
  properties: PropTypes.arrayOf(PropTypes.shape())
};

export default PropertyFilters;
