import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Button, Icon } from '@agconnections/grow-ui';
import { CloseRounded } from '@material-ui/icons';
import SuccessIcon from 'components/Icons/components/SuccessIcon';
import WarningIcon from 'components/Icons/components/WarningIcon';

import './index.css';

const Toast = ({
  type,
  title,
  onClose,
  open,
  timeout,
  children,
  isBold,
  customStyle
}) => {
  const [showToast, setShowToast] = useState(open);

  useEffect(() => {
    setShowToast(open);
  }, [open]);

  useEffect(() => {
    let timer;
    if (showToast && timeout >= 0) {
      timer = setTimeout(() => {
        setShowToast(false);
        onClose();
      }, timeout);
    }

    return () => {
      if (timer) {
        clearTimeout(timer);
      }
    };
  }, [showToast, timeout, onClose]);

  const ToastIcon = {
    success: <SuccessIcon width={24} height={24} />,
    warning: <WarningIcon />,
    info: <Icon icon="error" color="#0092E4" size={24} />,
    error: <Icon icon="error" color="#CF3537" size={24} />
  };

  const borderColor = {
    success: '#19A04B',
    warning: '#ECC94B',
    info: '#0092E4',
    error: '#CF3537'
  };

  return (
    <div
      className={` toast fixed transition-all duration-500 ease-in top-21 ${showToast &&
        'show-toast'} py-4 px-6 bg-white rounded border-l-4 flex ${
        children ? 'w-2/6' : 'w-1/4'
      }`}
      style={{
        zIndex: 2000,
        borderColor: borderColor[type],
        boxShadow: '0 2px 3px 3px rgba(0, 0, 0, 0.2)',
        ...customStyle
      }}
      data-testid="toast-alert"
    >
      <div className="w-full flex">
        <div className="mr-3">{ToastIcon[type]}</div>
        <div className="w-full">
          <div className="flex justify-between font-body items-center">
            <h4
              className={`text-base capitalize-first ${
                isBold ? 'font-semibold' : ''
              }`}
            >
              {title}
            </h4>
            <Button
              ariaLabel="alert-close"
              type="text"
              onClick={onClose}
              icon={<CloseRounded />}
              size="small"
            />
          </div>
          {children && <div className="mt-2">{children}</div>}
        </div>
      </div>
    </div>
  );
};

Toast.defaultProps = {
  type: 'success',
  open: false,
  timeout: 5000,
  onClose: () => {},
  title: '',
  isBold: false,
  children: null,
  customStyle: {}
};

Toast.propTypes = {
  type: PropTypes.oneOf(['success', 'warning', 'error', 'info']),
  onClose: PropTypes.func,
  title: PropTypes.string,
  children: PropTypes.node,
  open: PropTypes.bool,
  timeout: PropTypes.number,
  isBold: PropTypes.bool,
  // eslint-disable-next-line react/forbid-prop-types
  customStyle: PropTypes.object
};

export default Toast;
