import React from 'react';
import { Button } from '@agconnections/grow-ui';
import PropTypes from 'prop-types';

const EditFooterButtons = ({ onSave, onSaveAndCreateNew }) => {
  return (
    <div className="flex gap-4 py-3 px-6 justify-end w-full border-neutral-20 border-t-1">
      <Button type="outline" onClick={onSave}>
        Save Changes
      </Button>
      <Button ghost type="primary" onClick={onSaveAndCreateNew}>
        Save Changes & Create New
      </Button>
    </div>
  );
};

EditFooterButtons.propTypes = {
  onSave: PropTypes.func.isRequired,
  onSaveAndCreateNew: PropTypes.func.isRequired
};

export default EditFooterButtons;
