import GrowUIFormField from 'components/Forms/GrowUIFormField';
import React, { useCallback, useContext, useEffect, useState } from 'react';
import { useFormikContext } from 'formik';
import { Input, Button } from '@agconnections/grow-ui';
import { useHistory } from 'react-router-dom';

import events from 'utilities/amplitude/events';

import { INTEGRATIONS } from 'screens/Integrations/helpers/constants';
import { IntegrationsContext } from 'screens/Integrations/context/IntegrationsProvider';
import useSubmissionAnalytics from 'screens/Integrations/hooks/useSubmissionAnalytics';

const MccainAuthentication = () => {
  const { triggerAnalyticsEvent } = useSubmissionAnalytics({
    onMountTriggerEvent: {
      event: events.epic.Integrations.submissionStarted,
      data: { vendor: INTEGRATIONS.mccain }
    }
  });

  const { setFieldValue } = useFormikContext();

  const {
    state: { submissionFlowId },
    goForward
  } = useContext(IntegrationsContext);

  const [vendorId, setVendorId] = useState('');

  const [disabledButton, setDisabledButton] = useState(true);

  const history = useHistory();

  const startNewSubmissionHandler = useCallback(() => {
    triggerAnalyticsEvent(
      events.epic.Integrations.submissionCredentialsFilled,
      { vendor: INTEGRATIONS.mccain, flowId: submissionFlowId }
    );
    goForward();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [submissionFlowId, goForward]);

  useEffect(() => {
    if (vendorId.length === 7) {
      setDisabledButton(prevState => !prevState);
    } else {
      setDisabledButton(true);
    }
  }, [vendorId]);

  return (
    <div
      className="h-full p-4 bg-white rounded-xl"
      data-testid="mccain-authentication"
    >
      <div className="w-full flex pl-4 mt-2">
        <Button
          type="outline"
          data-testid="mccain-authentication-back-button"
          className="absolute"
          onClick={() => {
            triggerAnalyticsEvent(events.epic.Integrations.submissionCanceled, {
              vendor: INTEGRATIONS.mccain,
              flowId: submissionFlowId
            });
            history.goBack();
          }}
        >
          {'< Back'}
        </Button>
      </div>
      <div className="flex">
        <div className="w-1/2">
          <div className="w-full filler-image-integration h-full" />
        </div>
        <div className="w-1/2 py-7 rounded-xl">
          <div className="flex flex-row gap-x-3">
            <GrowUIFormField
              control={Input}
              name="mccainVendorId"
              id="mccainVendorId"
              label="McCain Vendor ID *"
              placeholder="Enter Vendor ID Number"
              onChange={e => {
                setFieldValue('mccainVendorId', e.target.value);
                setVendorId(e.target.value);
              }}
              imask={{
                mask: 'num',
                blocks: {
                  num: {
                    mask: Number,
                    scale: 0
                  }
                }
              }}
            />
            <Button
              onClick={startNewSubmissionHandler}
              disabled={disabledButton}
              type="primary"
            >
              Start New Submission
            </Button>
          </div>
          <p className="mt-6 pr-6 font-normal font-body text-sm leading-6 color-600">
            {`Please be aware, that you are submitting data to a “third-party”,
            McCain Foods. Syngenta Digital IS NOT responsible for the accuracy
            of the data submitted and encourages users to verify the accuracy of
            the data using the recipient’s interface. The user is responsible
            for the contents of data transmitted. Grower acknowledges that any
            data released to a third party at Grower's request is subject to the
            terms of the recipient’s privacy policy. Grower assumes all risk for
            third party use of data.
            `}
            <br />
            <br />
            The user shall:
            <br />
            <br />
            (a) obtain and pay for all equipment and third-party services (e.g.,
            Internet access, phone service, SMS, user credentials and
            subscriptions to webservices) required for you to access and use the
            webservices;
            <br />
            <br />
            (b) maintain the security of your user credentials and other
            confidential information relating to your account; and
            <br />
            <br />
            (c) be responsible for all submissions resulting from the use of
            your webservices account, including potential unauthorized use prior
            to your notifying Syngenta Digital of such use and Syngenta Digital
            taking steps to prevent its further occurrence.
          </p>
        </div>
      </div>
    </div>
  );
};

export default MccainAuthentication;
