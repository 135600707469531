import { useState } from 'react';
import { connectAPI } from 'utilities/api';

const useConnect = () => {
  const [fetchSaved, setFetchSaved] = useState({
    cancel: () => {},
    promise: () => {}
  });

  const getTasksByFetchToConnect = async () => {
    let objectToastToSend = {};

    const connectRequest = connectAPI.fetch();
    setFetchSaved(connectRequest);
    return connectRequest.promise
      .then(connectResolved => {
        const successTasksAdded = connectResolved.filter(
          item => item.status === '200'
        );
        if (!successTasksAdded.length) {
          objectToastToSend = {
            ...objectToastToSend,
            title: 'No tasks found',
            message:
              'We were unable to find any tasks within your Cropwise Connect Integration',
            type: 'error'
          };
        } else {
          objectToastToSend = {
            ...objectToastToSend,
            title: 'Tasks imported successfully',
            message: `${successTasksAdded.length} task${
              successTasksAdded.length === 1 ? '' : 's'
            } were imported from Cropwise Connect`,
            type: 'success'
          };
        }
        return objectToastToSend;
      })
      .catch(error => {
        if (error.message === 'Cancelled johnDeere fetch') {
          return {};
        }
        objectToastToSend = {
          ...objectToastToSend,
          title: 'No integration found',
          message:
            'Unable to find a configured integration via Cropwise Connect. Check your integrations to ensure it’s been fully configured',
          type: 'error',
          buttonFooterText: 'Go To Integration Page',
          buttonFooterType: 'INTEGRATION'
        };
        return objectToastToSend;
      });
  };
  const cancelFetchActive = () => {
    fetchSaved.cancel();
  };
  return {
    cancelFetchActive,
    getTasksByFetchToConnect
  };
};

export default useConnect;
