import React from 'react';
import PropTypes from 'prop-types';

import ClearSharp from '@material-ui/icons/ClearSharp';

const FieldImporterFiles = ({ files, errors, onDeleteFile }) => {
  return (
    <div className="flex flex-col p-3 mb-3 border rounded-lg border-dashed border-neutral-300">
      <h1 className="text-black mb-1">Uploaded files:</h1>
      <div className="text-green-600">
        {files.map(({ name }) => (
          <div key={name} className="flex justify-between">
            <span
              className={
                errors.find(error => error.file === name) ? 'text-red-600' : ''
              }
            >
              {name}
            </span>
            <button
              type="button"
              aria-label={name}
              onClick={() => {
                onDeleteFile(name);
              }}
            >
              <ClearSharp
                fontSize="small"
                className="text-red-600"
                style={{ height: 12 }}
              />
            </button>
          </div>
        ))}
        {errors.length > 0 && (
          <div className="text-red-500 text-xs italic">
            <hr className="my-2" />
            {errors.map(({ file, message }) => (
              <div key={file}>{`- ${message}`}</div>
            ))}
          </div>
        )}
      </div>
    </div>
  );
};

FieldImporterFiles.propTypes = {
  files: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired
    })
  ).isRequired,
  errors: PropTypes.arrayOf(
    PropTypes.shape({
      file: PropTypes.string.isRequired,
      message: PropTypes.string.isRequired
    })
  ).isRequired,
  onDeleteFile: PropTypes.func.isRequired
};

export default FieldImporterFiles;
