import React from 'react';
import PropTypes from 'prop-types';
import { Row, Col } from 'syngenta-digital-cropwise-react-ui-kit';
import DamageField from './components/DamageField';
import ForeignMaterialField from './components/ForeignMaterialField';
import MoistureField from './components/MoistureField';
import TestWeightField from './components/TestWeightField';
import QualityField from './components/QualityField';

const attributeFields = {
  damage: <DamageField />,
  foreignMaterial: <ForeignMaterialField />,
  moisture: <MoistureField />,
  testWeight: <TestWeightField />,
  quality: <QualityField />
};

const LoadAttributesSection = ({ attributes }) => {
  return (
    <div>
      <div className="flex items-center gap-4 mb-4">
        <h1
          data-testid="outside-element"
          className="text-xl leading-7 font-semibold tracking-tight"
        >
          Load Attributes
        </h1>
      </div>
      <Row gutter={[24, 24]} prefixCls="syt-antd-row">
        {attributes.map(field => (
          <Col
            key={`load-attribute-${field}`}
            xs={24}
            sm={24}
            md={12}
            lg={12}
            xl={8}
            prefixCls="syt-antd-col"
          >
            {attributeFields[field]}
          </Col>
        ))}
      </Row>
    </div>
  );
};

LoadAttributesSection.propTypes = {
  attributes: PropTypes.arrayOf(PropTypes.string).isRequired
};

export default LoadAttributesSection;
