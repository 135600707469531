import { extractAlphanumericPartsToSort } from 'helpers/multiLevelSort';

const appliedProductsTableSort = (field, item) => {
  const isAlphanumericField = field === 'product' || field === 'productName';
  return isAlphanumericField
    ? extractAlphanumericPartsToSort(item[field])
    : item[field];
};

export default appliedProductsTableSort;
