import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { Button } from '@agconnections/grow-ui';
import { useFormikContext } from 'formik';
import usePDFTools from 'hooks/usePDFTools';
import SavePDFIcon from 'components/Icons/components/SavePDFIcon';
import { IntegrationsContext } from 'screens/Integrations/context/IntegrationsProvider';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronLeft } from '@fortawesome/free-solid-svg-icons';
import { useHistory } from 'react-router-dom';
import { INTEGRATIONS } from 'screens/Integrations/helpers/constants';
import useSubmissionAnalytics from 'screens/Integrations/hooks/useSubmissionAnalytics';

const options = {
  filename: `McCain_Export_${new Date()
    .toJSON()
    .slice(0, 10)
    .replace(/-/g, '_')}`,
  jsPDF: { unit: 'in', format: 'a4', orientation: 'landscape' }
};

const MccainFooterIntegration = ({
  MCCAIN_PDF_WRAPPER,
  nextButtonDisabled,
  onNextValidation,
  onNext,
  onBack
}) => {
  const { events, triggerAnalyticsEvent } = useSubmissionAnalytics();

  const { values } = useFormikContext();
  const history = useHistory();

  const {
    goForward,
    goBack,
    setModalSubmissionState,
    state: { step }
  } = useContext(IntegrationsContext);

  const { mccainDownloadPDF } = usePDFTools();

  const handleNext = () => {
    if (onNextValidation()) {
      onNext();
      goForward();
    }
  };

  const handleBack = () => {
    onBack();
    goBack();
  };

  const handleCancel = () => {
    triggerAnalyticsEvent(events.epic.Integrations.submissionCanceled, {
      vendor: INTEGRATIONS.mccain
    });
    history.goBack();
  };

  const renderFooter = () => {
    switch (step) {
      case 1:
        return (
          <div
            className="flex flex-row border-t-2 justify-between pt-4 "
            data-testid="mccain-crop-season-footer"
            id="mccain-footer"
          >
            <Button type="outline" onClick={handleCancel}>
              Cancel
            </Button>
            <Button
              type="primary"
              onClick={() => {
                triggerAnalyticsEvent(
                  events.epic.Integrations.submissionSeasonSelected,
                  { vendor: INTEGRATIONS.mccain }
                );
                handleNext();
              }}
              ghost
              disabled={
                !values?.selectedCropSeasons?.length > 0 ||
                !values.cropSeasonStartDate ||
                !values.cropSeasonEndDate
              }
            >
              Next: Select Properties
            </Button>
          </div>
        );
      case 2:
        return (
          <div
            className="flex flex-row border-t-2 bg-white w-auto justify-between px-4 py-6 h-auto"
            data-testid="mccain-properties-footer"
            id="mccain-footer"
          >
            <Button type="outline" onClick={handleCancel}>
              Cancel
            </Button>
            <div className="flex flex-row gap-6">
              <Button
                type="outline"
                onClick={handleBack}
                icon={
                  <FontAwesomeIcon fixedWidth size="1x" icon={faChevronLeft} />
                }
              >
                Back
              </Button>
              <Button
                type="primary"
                ghost
                onClick={() => {
                  triggerAnalyticsEvent(
                    events.epic.Integrations.submissionPropertiesSelected,
                    { vendor: INTEGRATIONS.mccain }
                  );
                  handleNext();
                }}
                disabled={nextButtonDisabled}
              >
                Next: Review & Submit
              </Button>
            </div>
          </div>
        );
      case 3:
        return (
          <div
            className="w-full h-24 bg-black"
            data-testid="mccain-review-footer"
            id="mccain-footer"
            data-html2canvas-ignore
          >
            <div className="flex flex-row border-t-2 bg-white w-auto justify-between px-4 py-6 h-auto">
              <Button type="outline" onClick={handleCancel}>
                Cancel
              </Button>
              <div className="flex flex-row gap-6">
                <Button
                  type="outline"
                  onClick={handleBack}
                  icon={
                    <FontAwesomeIcon
                      fixedWidth
                      size="1x"
                      icon={faChevronLeft}
                    />
                  }
                >
                  Back
                </Button>
                <Button
                  id="save-button"
                  type="primary"
                  ghost
                  icon={<SavePDFIcon />}
                  onClick={() => {
                    mccainDownloadPDF(MCCAIN_PDF_WRAPPER, options);
                  }}
                >
                  Save as PDF
                </Button>
                <Button
                  type="primary"
                  onClick={() => {
                    setModalSubmissionState(true);
                  }}
                >
                  Submit to McCain
                </Button>
              </div>
            </div>
          </div>
        );
      default:
        return null;
    }
  };

  return renderFooter();
};

MccainFooterIntegration.propTypes = {
  nextButtonDisabled: PropTypes.bool,
  onNext: PropTypes.func,
  onBack: PropTypes.func,
  MCCAIN_PDF_WRAPPER: PropTypes.string,
  onNextValidation: PropTypes.func
};

MccainFooterIntegration.defaultProps = {
  nextButtonDisabled: true,
  onNext: () => {},
  onBack: () => {},
  MCCAIN_PDF_WRAPPER: '',
  onNextValidation: () => true
};

export default MccainFooterIntegration;
