import React, { useEffect, useRef, useState, useContext } from 'react';
import PropTypes from 'prop-types';
import { Context } from 'components/Store';
import CropIconMapping from 'components/CropIconMapping';
import ClickableDiv from 'components/ClickableDiv';
import './index.css';
import { useFormikContext } from 'formik';

const CommodityOption = ({ cropzone, onSelect }) => {
  const icon = CropIconMapping({
    cropObject: {
      name: cropzone.name
    }
  });

  const handleClick = () => {
    onSelect(cropzone.id, cropzone.name);
  };

  return (
    <ClickableDiv
      className="flex flex-row w-auto text-neutral-1000 yield-option text-sm px-4 py-1 hover:bg-info-light-blue hover:text-info-dark-blue"
      onClick={handleClick}
    >
      {icon}
      <span className="ml-3">{cropzone.name}</span>
    </ClickableDiv>
  );
};

CommodityOption.propTypes = {
  cropzone: PropTypes.shape().isRequired,
  onSelect: PropTypes.func.isRequired
};

const CommoditySelectorOptionList = ({ cropzones, onSelect, onBlur }) => {
  useEffect(() => {
    document.addEventListener('mousedown', onBlur);
    return () => document.removeEventListener('mousedown', onBlur);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="absolute mt-1 ml-2 w-56 py-2 rounded bg-white shadow z-50">
      {cropzones.map(cropzone => (
        <CommodityOption
          key={cropzone.id}
          cropzone={cropzone}
          onSelect={onSelect}
        />
      ))}
    </div>
  );
};

CommoditySelectorOptionList.propTypes = {
  cropzones: PropTypes.arrayOf(PropTypes.object).isRequired,
  onSelect: PropTypes.func.isRequired,
  onBlur: PropTypes.func.isRequired
};

const CommoditySelector = ({ cropzones, value, onChange }) => {
  const [, dispatch] = useContext(Context);
  const [show, setShow] = useState(false);

  const selectRef = useRef();

  const { setFieldValue } = useFormikContext();

  useEffect(() => {
    onChange(cropzones[0]);
  }, [cropzones, onChange]);

  useEffect(() => {
    if (!value) onChange(cropzones[0]);
  }, [value, onChange, cropzones]);

  const handleSelect = (selectedId, selectedName) => {
    const commodity = { id: selectedId, name: selectedName };
    dispatch({
      type: 'SELECTED_YIELD_COMMODITY',
      payload: commodity
    });
    onChange(commodity);
    setFieldValue('commodityId', selectedId);
  };

  const handleBlur = e => {
    if (selectRef.current.contains(e.target)) {
      return;
    }

    setShow(false);
  };

  return (
    <div className="relative flex-1">
      <ClickableDiv
        className="bg-transparent text-info-dark-blue"
        name="commodity"
        id="commodity"
        ref={selectRef}
        onClick={() => setShow(prev => !prev)}
      >
        <div className="flex flex-row items-center text-2xl font-semibold">
          <span className="selected-icon">
            {CropIconMapping({
              cropObject: value,
              size: 28
            })}
          </span>
          <span className="ml-2">{value?.name ?? '--'}</span>
          <span className="yield-select-arrow w-8 h-7 selected-icon" />
        </div>
        {show && (
          <CommoditySelectorOptionList
            cropzones={cropzones}
            onSelect={handleSelect}
            onBlur={handleBlur}
          />
        )}
      </ClickableDiv>
    </div>
  );
};

CommoditySelector.propTypes = {
  cropzones: PropTypes.arrayOf(PropTypes.object).isRequired,
  value: PropTypes.shape(),
  onChange: PropTypes.func.isRequired
};

CommoditySelector.defaultProps = {
  value: undefined
};

export default CommoditySelector;
