import React, { useEffect, useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { isWithinInterval, format } from 'date-fns';
import { getAbbreviatedUnit } from 'helpers/unitsAbbreviation';
import Filtering from 'screens/Inventory/components/Filtering';
import { Spinner } from '@agconnections/grow-ui';
import { paths } from 'routes/paths';
import DetailTable from '../../../DetailTable';

const InvoiceTab = ({ invoices, productId, setRedirect }) => {
  const [filteredInvoices, setFilteredInvoices] = useState([]);
  const [currentPage, setCurrentPage] = useState(0);
  const [totalRecords, setTotalRecords] = useState(0);

  const setInvoiceRedirect = address => {
    setRedirect(address);
  };

  const setRowData = useCallback(
    invs => {
      const TABLE_HEADER = [
        {
          field: 'name',
          sortable: true,
          title: 'Name'
        },
        {
          field: 'invoiceDate',
          sortable: true,
          title: 'Invoice Date'
        },
        {
          field: 'totalUnits',
          sortable: true,
          title: 'Total Units'
        },
        {
          field: 'totalPrice',
          sortable: true,
          title: 'Total Price'
        },
        {
          field: 'pricePerUnit',
          sortable: true,
          title: 'Price Per Unit'
        }
      ];

      const formatter = new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD'
      });

      const rows = invs.map(inv => {
        const invProds = inv.invoiceProducts.filter(
          prod => prod.productId === productId
        );
        const prodRow = invProds.map(prod => {
          const pricePerUnit =
            prod.totalCostValue / prod.totalQuantityValue || 0;
          return [
            `${prod.totalQuantityValue} ${getAbbreviatedUnit(
              prod.totalQuantityUnit
            )}`,
            formatter.format(prod.totalCostValue),
            `${formatter.format(pricePerUnit)}/${getAbbreviatedUnit(
              prod.totalQuantityUnit
            )}`
          ];
        });
        const tableRow = {
          id: inv.id,
          rowData: [
            inv.invoiceNumber,
            new Date(inv.invoiceDateEpoch).toLocaleDateString(),
            prodRow[0][0],
            prodRow[0][1],
            prodRow[0][2]
          ],
          link: `${paths.invoices}/${inv.id}/view`
        };
        return tableRow;
      });

      return { tableHeader: TABLE_HEADER, tableRows: rows };
    },
    [productId]
  );

  useEffect(() => {
    if (invoices) {
      setTotalRecords(invoices.length);
      setFilteredInvoices(_.cloneDeep(invoices));
    }
  }, [invoices]);

  const handleFiltering = (searchTerm, cropSeasons, dateRange) => {
    let filteredData = _.cloneDeep(invoices);
    if (searchTerm) {
      filteredData = filteredData.filter(invoice =>
        invoice.invoiceNumber.toLowerCase().includes(searchTerm.toLowerCase())
      );
    }

    if (cropSeasons.length > 0) {
      filteredData = filteredData.filter(invoice =>
        invoice.cropSeasons.some(({ seasonId }) =>
          cropSeasons.includes(seasonId)
        )
      );
    }

    if (dateRange.length === 2) {
      filteredData = filteredData.filter(invoice => {
        const invoiceDate = new Date(
          format(new Date(invoice.invoiceDate), 'yyyy/MM/dd')
        );
        const startDate = new Date(
          format(new Date(dateRange[0]), 'yyyy/MM/dd')
        );
        const endDate = new Date(format(new Date(dateRange[1]), 'yyyy/MM/dd'));

        return isWithinInterval(invoiceDate, {
          start: startDate,
          end: endDate
        });
      });
    }

    setFilteredInvoices(filteredData);
  };

  return invoices ? (
    <div data-testid="inv-invoice-tab">
      <Filtering
        setRowData={setRowData}
        rowData={filteredInvoices}
        onFilter={handleFiltering}
        pageClick={setCurrentPage}
        totalRecordCount={totalRecords}
        pageNumber={currentPage}
      >
        {({ tableData }) => (
          <DetailTable data={tableData} setRedirect={setInvoiceRedirect} />
        )}
      </Filtering>
    </div>
  ) : (
    <Spinner />
  );
};

InvoiceTab.defaultProps = {
  invoices: [],
  productId: null,
  setRedirect: () => {}
};

InvoiceTab.propTypes = {
  invoices: PropTypes.arrayOf(PropTypes.object),
  productId: PropTypes.string,
  setRedirect: PropTypes.func
};

export default InvoiceTab;
