import React, { useState, useEffect, useContext } from 'react';
import { Button, Card, Form, Spinner } from '@agconnections/grow-ui';

import Breadcrumb from 'components/App/AppShell/components/Breadcrumb';
import useOrganizationApi from 'hooks/useOrganizationApi';
import { Formik } from 'formik';
import { Redirect, useParams } from 'react-router-dom';
import catchCancel from 'helpers/catchCancel';
import { isEmpty } from 'lodash';
import { Context } from 'components/Store';
import DeleteModal from 'components/Modals/DeleteModal';
import DeleteModalTrigger from 'components/Modals/DeleteModal/components/DeleteModalTrigger';
import ErrorMessageConsole from 'components/ErrorHandler/ErrorMessageConsole';
import {
  ENTRY_FORM_ABOVE_ERROR_CONSOLE_MESSAGE,
  ENTRY_FORM_ERROR_TOAST_MESSAGE
} from 'components/ErrorHandler/constants';
import { parseServerError } from 'helpers/errorHelpers';
import { AmplitudeContext } from 'utilities/amplitude/useAmplitude';
import { paths } from 'routes/paths';
import FullNameField from './components/FullNameField';
import EmailAddressField from './components/EmailAddressField';
import PhoneNumberField from './components/PhoneNumberField';
import StreetAddressField from './components/StreetAddressField';
import CityField from './components/CityField';
import StateField from './components/StateField';
import ZipField from './components/ZipField';
import NotesField from './components/NotesField';
import { mapUserToPerson, cloneUser, getApplicatorLicense } from './helpers';
import blankUser from './blankUser';
import ApplicatorLicense from './components/ApplicatorLicense';
import { update, create, cleanupData } from '../../helpers/dataHelpers';
import PersonView from './PesonView';

const Person = () => {
  const [updatedLicense, setLicense] = useState(undefined);
  const [user, setUser] = useState(blankUser);
  const [open, setOpen] = useState(false);
  const [redirect, setRedirect] = useState(null);
  const [
    {
      loggedInUserOrgPermission: { role }
    },
    dispatch
  ] = useContext(Context);
  const workerAPI = useOrganizationApi('worker');
  const peopleAPI = useOrganizationApi('people');
  const amplitude = useContext(AmplitudeContext);
  const [submitting, setSubmitting] = useState(false);
  const openModal = () => setOpen(true);
  const closeModal = () => setOpen(false);

  const { id, view } = useParams();
  const isCreateScreen = id === 'person';
  const loaded = (id && user.id) || isCreateScreen;
  const setCancelBtn = true;
  const isView = view === 'view';
  const isEdit = id === user.id;

  const triggerRedirect = () => {
    setRedirect(paths.people);
  };

  const deleteUser = userObject => {
    const deleteData = async () => {
      setOpen(false);
      await peopleAPI
        .delete(userObject.id)
        .promise.then(() => {
          setRedirect(paths.people);
        })
        .catch(catchCancel);
    };

    return deleteData().catch(parseServerError(dispatch));
  };

  const handleDelete = () => {
    amplitude.sendEventToAmplitude(amplitude.events.epic.People.deletePerson);
    deleteUser(user);
  };

  const saveUser = userCopy => {
    if (isCreateScreen) {
      create(userCopy, peopleAPI, setSubmitting, triggerRedirect, dispatch);
    } else {
      // eslint-disable-next-line no-unused-vars
      const { type, ...restUserCopy } = userCopy;
      update(restUserCopy, peopleAPI, setSubmitting, triggerRedirect, dispatch);
    }
  };

  const setSpinnerOrView = () => {
    if (isView && user.name !== '') {
      return <PersonView user={user} handleDelete={handleDelete} role={role} />;
    }
    return <Spinner />;
  };

  useEffect(() => {
    let cancel;
    if (!isCreateScreen) {
      let promise;
      ({ promise, cancel } = workerAPI.fetch(id));
      promise
        .then(
          ({ data }) =>
            setUser(cleanupData(data)) || setLicense(getApplicatorLicense(data))
        )
        .catch(catchCancel);
      return () => {
        dispatch({ type: 'SET_TOOLBAR', payload: null });
        cancel();
      };
    }
    return () => cancel && cancel();
  }, [dispatch, id, workerAPI, isCreateScreen]);

  const setBreadCrumbValue = () => {
    if (isCreateScreen) return 'Create Person';
    if (isView) return user.name;
    return 'Edit Person';
  };

  return (
    <div className="w-full h-full">
      <Breadcrumb disabled={isCreateScreen || isView || isEdit}>
        <Breadcrumb.Item title="People" value="All People" to={paths.people} />
        <Breadcrumb.Item title="Person" value={setBreadCrumbValue()} isLast />
      </Breadcrumb>
      {redirect && <Redirect push to={`${redirect}`} />}
      {loaded && !isView ? (
        <div style={{ minHeight: '35rem' }}>
          <DeleteModal
            open={open}
            itemType="Person"
            onCancel={closeModal}
            onDelete={handleDelete}
          />
          <Formik
            initialValues={mapUserToPerson(user)}
            validate={values => {
              const errors = {};
              const regex = /^\s+$/;
              if (!values.fullName || regex.test(values.fullName)) {
                errors.fullName = 'Required';
              }
              return errors;
            }}
            onSubmit={values => {
              // amplitude trigger
              amplitude.sendEventToAmplitude(
                amplitude.events.epic.People.createPerson
              );
              setSubmitting(true);
              saveUser(cloneUser(user, values, updatedLicense));
            }}
          >
            {({
              errors,
              touched,
              values,
              handleChange,
              handleBlur,
              setFieldValue,
              handleSubmit
            }) => {
              const hasErrors = Object.keys(errors).length > 0;
              return (
                <>
                  <div className="flex text-gray-800">
                    <div className="flex w-full">
                      <div className="text-lg w-full text-3xl">
                        {isCreateScreen ? 'Create Person' : 'Edit Person Info'}
                      </div>
                      <div className="flex">
                        <DeleteModalTrigger
                          isCreateScreen={setCancelBtn}
                          redirectPath={paths.people}
                          openModal={openModal}
                          setRedirect={setRedirect}
                        />
                        <div className="ml-4">
                          <Button
                            type="primary"
                            onClick={handleSubmit}
                            disabled={hasErrors}
                          >
                            Save
                          </Button>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="py-5">
                    <Card>
                      <Card.Content>
                        <Form>
                          <div className="p-5">
                            <div className="text-2xl font-bold mt-10">
                              Basic Info
                            </div>
                            <div className="flex">
                              <FullNameField
                                fullName={values.fullName}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                errors={touched.fullName && errors.fullName}
                              />
                            </div>
                            <div className="flex">
                              <EmailAddressField
                                emailAddress={values.emailAddress}
                                onChange={handleChange}
                                errors={errors.emailAddress}
                              />
                              <PhoneNumberField
                                phoneNumber={values.phoneNumber}
                                onChange={handleChange}
                                errors={errors.phoneNumber}
                              />
                            </div>
                            <div className="text-2xl font-bold mt-10">
                              Address Info
                            </div>
                            <div className="flex">
                              <StreetAddressField
                                streetAddress={values.streetAddress1}
                                title="Address Line 1"
                                name="streetAddress1"
                                onChange={handleChange}
                                errors={errors}
                                blockMargin="mr-6"
                                id="street-address-1-input"
                              />
                              <StreetAddressField
                                streetAddress={values.streetAddress2}
                                title="Address Line 2"
                                name="streetAddress2"
                                onChange={handleChange}
                                errors={errors}
                                blockMargin="ml-6"
                                id="street-address-2-input"
                              />
                            </div>
                            <div className="flex">
                              <div className="w-1/2">
                                <CityField
                                  cityName={values.cityName}
                                  onChange={handleChange}
                                  errors={errors.cityName}
                                />
                              </div>
                              <div className="w-1/2">
                                <div className="flex">
                                  <StateField
                                    state={values.state}
                                    setFieldValue={setFieldValue}
                                    errors={errors.state}
                                  />
                                  <ZipField
                                    postalCode={values.postalCode}
                                    onChange={handleChange}
                                    errors={errors.postalCode}
                                  />
                                </div>
                              </div>
                            </div>
                            <ApplicatorLicense
                              onChange={changedLicense => {
                                setLicense(changedLicense);
                              }}
                              isCreateScreen={
                                user.applicatorLicenseNumber &&
                                user.applicatorLicenseNumber.length === 0
                              }
                              license={values.applicatorLicense}
                              handleClick={handleSubmit}
                            />
                            <div className="text-2xl font-bold mt-10">
                              Notes
                            </div>
                            <NotesField
                              notes={values.notes}
                              errors={errors.notes}
                              onChange={handleChange}
                            />
                          </div>
                        </Form>
                      </Card.Content>
                      {hasErrors && (
                        <div className="p-5 -m-5">
                          <div className="flex justify-end">
                            <ErrorMessageConsole
                              error={hasErrors}
                              consoleMessage={
                                !isEmpty(touched) &&
                                ENTRY_FORM_ABOVE_ERROR_CONSOLE_MESSAGE
                              }
                              toastMessage={
                                !isEmpty(touched) &&
                                submitting &&
                                ENTRY_FORM_ERROR_TOAST_MESSAGE
                              }
                            />
                          </div>
                        </div>
                      )}
                    </Card>
                  </div>
                </>
              );
            }}
          </Formik>
        </div>
      ) : (
        setSpinnerOrView()
      )}
    </div>
  );
};

export default Person;
