import CardInfo from 'components/CardInfo';
import React, { useContext } from 'react';
import { StorageLocationContext } from 'screens/YieldV2/StorageLocations/context/StorageLocationProvider';

const StorageSystem = () => {
  const {
    state: { selectedId, data = [] }
  } = useContext(StorageLocationContext);
  const selected = data.find(item => item.id === selectedId);
  return (
    <div className="flex">
      <div className="w-1/2 pr-4">
        <CardInfo label="Cooling System" value={selected?.coolingSystem} />
        <CardInfo label="Unloading System" value={selected?.unloadingSystem} />
      </div>
      <div className="w-1/2 pl-4">
        <CardInfo label="Filling System" value={selected?.fillingSystem} />
      </div>
    </div>
  );
};

export default StorageSystem;
