import React, { useState, useEffect, useContext } from 'react';
import { useHistory } from 'react-router-dom';
import { Button } from '@agconnections/grow-ui';
import PropTypes from 'prop-types';
import { useFormikContext } from 'formik';
import FormNavGuard from 'components/FormNavGuard';
import CheckCircleIcon from 'assets/check-circle-white.svg';
import { AmplitudeContext } from 'utilities/amplitude/useAmplitude';
import LoadItem from '../LoadItem';
import LoadTicketsList from '../LoadTicketsList';
import LoadsManagerModal from '../LoadsManagerModal';
import isOverdrawingSource from '../../helpers/isOverdrawingSource';

const LoadsManager = ({
  isEditYieldPage,
  list,
  onDeleteYieldTicket,
  popupTitle,
  isEditingLoad,
  isDisabled,
  onSaveAllLoads,
  resetSubimissionChange,
  setResetSubimissionChange
}) => {
  const {
    values,
    setFieldTouched,
    setFieldValue,
    submitForm,
    dirty,
    isValid
  } = useFormikContext();
  const amplitude = useContext(AmplitudeContext);
  const history = useHistory();
  const [showToast, setShowToast] = useState(false);
  const [initialLoad, setInitialLoad] = useState(true);
  const [confirmationModal, setConfirmationModal] = useState({
    visible: false,
    type: null
  });
  const listCount = list.length;
  const unSavedTicketsCount = listCount ? `(${listCount})` : '';

  useEffect(() => {
    if (list.length > 0) {
      setFieldTouched('preventNavigation', true);
      setFieldValue('preventNavigation', true);
    }
    if (!initialLoad) {
      if (resetSubimissionChange) {
        setResetSubimissionChange(false);
        return;
      }
      setShowToast(true);
      setTimeout(() => setShowToast(false), 2000);
    }
    setInitialLoad(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [list]);

  const addYieldSubmit = () => {
    if (dirty && isValid) {
      setConfirmationModal({ visible: true, type: 'saveUnsaved' });
      return;
    }
    setFieldValue('preventNavigation', false);
    onSaveAllLoads();
  };

  const editYieldSubmit = () => {
    const displayOverdrawnSourceModal = isOverdrawingSource({
      sourceType: values?.sourceType,
      source: values?.sources[0],
      load: values?.load
    });

    if (displayOverdrawnSourceModal) {
      setConfirmationModal({ visible: true, type: 'overdrawnSource' });
      return;
    }
    amplitude.sendEventToAmplitude(
      amplitude.events.epic.YieldV2.loads.editYield
    );
    submitForm();
  };

  const handleSubmit = e => {
    e.preventDefault();
    return isEditYieldPage ? editYieldSubmit() : addYieldSubmit();
  };

  return (
    <div className="flex flex-col h-full bg-white shadow-elevation-2">
      <FormNavGuard
        hideInstructions
        disabled={!values?.preventNavigation}
        title="Cancel this submission?"
        customText={
          <>
            <p>
              You will lose <strong>all data</strong> from this submission,
              including any loads on the loads list.
            </p>
            <p className="mt-3">Are you sure you want to continue?</p>
          </>
        }
        type="error"
        confirmLabel="Leave Page"
        cancelLabel="Cancel"
      />
      <div className="pl-6 pt-6 pb-4 border-b border-neutral-20">
        <h1 className="text-xl leading-7 tracking-tight">
          {isEditYieldPage ? 'Edit Yield Load' : 'Loads List'}
          {!isEditYieldPage && listCount > 0 && (
            <span className="ml-2">({listCount})</span>
          )}
        </h1>
      </div>
      <div
        className={`duration-400 ease-in-out ${
          showToast ? 'opacity-100 max-h-28px py-1' : 'opacity-0 max-h-0'
        } overflow-hidden gap-1 flex justify-center items-center bg-cucumber-green-700 text-white text-sm`}
        style={{ transitionProperty: 'opacity, max-height' }}
      >
        <img src={CheckCircleIcon} alt="Check Circle" className="h-20px" />
        <span>{popupTitle}</span>
      </div>
      <div className="flex-1">
        {isEditYieldPage ? (
          <LoadItem item={values} className="bg-selectedBlue" />
        ) : (
          <LoadTicketsList
            list={list}
            onDeleteYieldTicket={onDeleteYieldTicket}
          />
        )}
      </div>
      <div
        data-testid="loads-manager-footer"
        className="flex  gap-4 px-4 py-10px border-t-1 border-neutral-20"
      >
        <Button
          type="outline"
          onClick={() => {
            setFieldValue('preventNavigation', true);
            history.goBack();
          }}
          size="lg"
          disabled={isDisabled}
        >
          Cancel
        </Button>
        <Button
          size="lg"
          type="submit"
          style={{ flex: '1' }}
          onClick={handleSubmit}
          disabled={
            isDisabled || isEditingLoad || (!isEditYieldPage && listCount === 0)
          }
        >
          {isEditYieldPage
            ? 'Save Load'
            : `Save All Loads ${unSavedTicketsCount}`}
        </Button>
      </div>
      <LoadsManagerModal
        type={confirmationModal.type}
        onSaveAllLoads={onSaveAllLoads}
        setModalVisible={visible => setConfirmationModal(visible)}
        modalVisible={confirmationModal.visible}
      />
    </div>
  );
};

LoadsManager.propTypes = {
  isDisabled: PropTypes.bool,
  isEditYieldPage: PropTypes.bool,
  list: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      name: PropTypes.string
    })
  ),
  onDeleteYieldTicket: PropTypes.func,
  popupTitle: PropTypes.string.isRequired,
  isEditingLoad: PropTypes.bool,
  onSaveAllLoads: PropTypes.func.isRequired,
  resetSubimissionChange: PropTypes.bool,
  setResetSubimissionChange: PropTypes.func
};

LoadsManager.defaultProps = {
  isDisabled: false,
  isEditYieldPage: false,
  list: [],
  onDeleteYieldTicket: () => {},
  isEditingLoad: false,
  resetSubimissionChange: false,
  setResetSubimissionChange: () => {}
};

export default LoadsManager;
