import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { IntegrationsContext } from 'screens/Integrations/context/IntegrationsProvider';

import PropertyIntegrationBase from 'screens/Integrations/components/PropertyIntegration';

import MccainAuthentication from 'screens/Integrations/MccainIntegration/components/MccainAuthentication';
import MccainCropSeason from 'screens/Integrations/MccainIntegration/components/MccainCropSeason';
import MccainCropSeasonIntegration from 'screens/Integrations/MccainIntegration/components/MccainCropSeasonIntegration';
import MccainSummaryView from 'screens/Integrations/MccainIntegration/components/MccainSummaryView';
import McCainSuccessPage from 'screens/Integrations/MccainIntegration/components/McCainSuccessPage';
import { INTEGRATIONS } from '../../helpers/constants';

const STEPS = {
  mccain: {
    0: <MccainAuthentication />,
    1: (
      <MccainCropSeasonIntegration>
        <MccainCropSeason />
      </MccainCropSeasonIntegration>
    ),
    2: <PropertyIntegrationBase integrationName={INTEGRATIONS.mccain} />,
    3: <MccainSummaryView />,
    4: <McCainSuccessPage />
  }
};

const StepSelector = ({ integration }) => {
  const {
    state: { step }
  } = useContext(IntegrationsContext);

  return STEPS[integration.toLowerCase()][step];
};

StepSelector.propTypes = {
  integration: PropTypes.string.isRequired
};

export default StepSelector;
