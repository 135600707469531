import convertStdPackage from './convertStdPackage';
import { emptyValue } from './tableConstants';

const getProductQuantity = product => {
  const productObj = {
    density: product.density,
    productDensity: product.productDensity,
    stdfactor: product.stdFactor,
    stdunit: product.stdUnit,
    stdpackageunit: product.stdPackageUnit
  };
  const taskValue = convertStdPackage(
    product.productQuantityValue,
    product.productQuantityUnit,
    product.stdPackageUnit,
    productObj
  );
  const invoiceValue = convertStdPackage(
    product.purchased,
    product.purchasedUnit,
    product.stdPackageUnit,
    productObj
  );

  let availableQuantity = emptyValue.dashLine;
  if (taskValue?.Value || invoiceValue?.Value) {
    availableQuantity = (invoiceValue?.Value - taskValue?.Value).toFixed(2);
  }

  return product.productQuantityValue || product.purchased
    ? availableQuantity
    : (0).toFixed(0);
};

export default getProductQuantity;
