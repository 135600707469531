import React from 'react';
import PropTypes from 'prop-types';
import { cloneDeep } from 'lodash';
import { Input, Select } from '@agconnections/grow-ui';
import GrowUIFormField from 'components/Forms/GrowUIFormField';
import { getConvertUnitFromTo } from 'helpers/unitConversionHelpers';
import { APPLICATION_OPTIONS } from '../../../../helpers/applicationOptions';

const applicationOptions = Object.values(APPLICATION_OPTIONS).map(option => ({
  key: option.key,
  value: option.title
}));

const masks = {
  gallon: 'num g\\al'
};

const specialCaseUnit = [
  'bag',
  'box',
  'bulk',
  'bushel',
  'can',
  'crate',
  'dispenser',
  'kernal',
  'kernel',
  'load',
  'package',
  'plant',
  'roll',
  'seed',
  'tablet',
  'pound'
];

const calcRatePerTank = (tankCount, productToAdd) => {
  const specialCase = specialCaseUnit.includes(productToAdd?.totalProductUnit);
  let ratePerTank = 0;
  if (tankCount > 0) {
    const totalUnitIsSame =
      productToAdd?.ratePerTankUnit === productToAdd?.totalProductUnit;
    const conversionFactor = !totalUnitIsSame
      ? productToAdd?.productDensity
      : 1;

    if (specialCase) {
      ratePerTank = !totalUnitIsSame
        ? productToAdd?.totalProductValue / conversionFactor / tankCount
        : productToAdd?.totalProductValue / tankCount;
    }
    if (!totalUnitIsSame) {
      const newRatePerTank = productToAdd?.totalProductValue / tankCount;
      ratePerTank = getConvertUnitFromTo(
        Number.parseFloat(newRatePerTank),
        productToAdd?.totalProductUnit,
        productToAdd?.ratePerTankUnit,
        productToAdd
      ).Value;
    }
  }

  return ratePerTank;
};

const TopOptions = ({ values, setFieldValue, setFieldTouched }) => {
  const roundValue = valueToRound => {
    return Math.round((valueToRound + Number.EPSILON) * 100) / 100;
  };

  const recalcTotalProductValues = countChange => {
    const newProducts = [];
    /* eslint no-unused-expressions: "off", curly: "error" */
    values?.products?.forEach(product => {
      const newProduct = cloneDeep(product);
      newProduct.ratePerTankValue = roundValue(
        calcRatePerTank(countChange, product)
      );
      newProducts.push(newProduct);
    });

    setFieldValue('products', newProducts);
  };

  const tankSizeChange = sizeChange => {
    let totalCarrierValue = 0;
    if (Number.parseFloat(sizeChange)) {
      totalCarrierValue =
        Number(sizeChange) * Number(values?.tankInformation?.tankCount);
      setFieldValue(
        'tankInformation.totalCarrierValue',
        roundValue(totalCarrierValue)
      );
    }
    if (Number.parseFloat(values?.totalAreaValue) > 0) {
      const carrierPerArea = totalCarrierValue / values?.totalAreaValue;
      setFieldValue(
        'tankInformation.carrierPerAreaValue',
        roundValue(carrierPerArea)
      );
    }
    setFieldValue('tankInformation.tankSizeValue', sizeChange);
  };

  const tankCountChange = countChange => {
    let totalCarrierValue = 0;
    if (countChange !== '' && countChange !== '0') {
      if (Number.parseFloat(values?.tankInformation?.tankSizeValue)) {
        totalCarrierValue =
          Number(values?.tankInformation?.tankSizeValue) * Number(countChange);
        setFieldValue(
          'tankInformation.totalCarrierValue',
          roundValue(totalCarrierValue)
        );
      }
      if (Number.parseFloat(values?.totalAreaValue) > 0) {
        const carrierPerArea = totalCarrierValue / values?.totalAreaValue;
        setFieldValue(
          'tankInformation.carrierPerAreaValue',
          roundValue(carrierPerArea)
        );
      }
    }
    setFieldValue('tankInformation.tankCount', countChange);
    recalcTotalProductValues(countChange);
  };

  const carrierPerAreaChange = carrierChange => {
    let totalCarrierValue = 0;
    if (Number.parseFloat(carrierChange)) {
      totalCarrierValue =
        Number(carrierChange) * Number(values?.totalAreaValue);
      setFieldValue(
        'tankInformation.totalCarrierValue',
        roundValue(totalCarrierValue)
      );
    }
    if (Number.parseFloat(values?.tankInformation?.tankSizeValue) > 0) {
      const tankCount =
        totalCarrierValue / Number(values?.tankInformation?.tankSizeValue);
      setFieldValue('tankInformation.tankCount', roundValue(tankCount));
      recalcTotalProductValues(tankCount);
    }
    setFieldValue('tankInformation.carrierPerAreaValue', carrierChange);
  };

  const totalCarrierChange = totalChange => {
    if (Number.parseFloat(values?.tankInformation?.tankSizeValue) > 0) {
      const tankCount =
        Number(totalChange) / Number(values?.tankInformation?.tankSizeValue);
      setFieldValue('tankInformation.tankCount', roundValue(tankCount));
      recalcTotalProductValues(tankCount);
    }
    if (Number.parseFloat(values?.totalAreaValue)) {
      const carrierPerArea =
        Number(totalChange) / Number(values?.totalAreaValue);
      setFieldValue(
        'tankInformation.carrierPerAreaValue',
        roundValue(carrierPerArea)
      );
    }
    setFieldValue('tankInformation.totalCarrierValue', totalChange);
  };

  return (
    <div className="flex">
      <div className="w-1/4">
        <span
          className="flex items-center text-neutral-600"
          style={{ fontSize: '0.88vw' }}
          data-testid="application-options-select"
        >
          Application Options
        </span>
        <div className="w-full">
          <GrowUIFormField
            control={Select}
            name="applicationStrategy"
            // label="Application Options"
            items={applicationOptions}
          />
        </div>
      </div>
      <div className="w-3/4 flex justify-between">
        <div className="" style={{ paddingLeft: '2.5%' }}>
          <span
            className="flex items-center text-neutral-600"
            style={{ fontSize: '0.88vw' }}
            data-testid="tank-size-input"
          >
            Tank Size
          </span>
          <GrowUIFormField
            control={Input}
            name="tankInformation.tankSizeValue"
            value={values?.tankInformation?.tankSizeValue}
            onChange={e => tankSizeChange(e.target.value || 0)}
            onBlur={() => {
              setFieldTouched('Tank Size');
            }}
            disabled={values?.totalAreaValue === 0}
            imask={{
              mask: masks[values?.tankInformation?.tankSizeUnit] || 'num',
              lazy: false,
              blocks: {
                num: {
                  mask: Number,
                  radix: '.',
                  mapToRadix: ['.'],
                  normalizeZeros: false,
                  thousandsSeparator: ','
                }
              }
            }}
          />
        </div>
        <div className="" style={{ paddingLeft: '2.5%' }}>
          <span
            className="flex items-center text-neutral-600"
            style={{ fontSize: '0.88vw' }}
            data-testid="tank-count-input"
          >
            Tank Count
          </span>
          <GrowUIFormField
            control={Input}
            name="tankInformation.tankCount"
            value={values?.tankInformation?.tankCount}
            onChange={e => tankCountChange(e.target.value || 0)}
            onBlur={() => {
              setFieldTouched('Tank Count Input');
            }}
            disabled={values?.totalAreaValue === 0}
            imask={{
              mask: 'num t\\anks',
              lazy: false,
              blocks: {
                num: {
                  mask: Number,
                  radix: '.',
                  mapToRadix: ['.'],
                  normalizeZeros: false,
                  thousandsSeparator: ','
                }
              }
            }}
          />
        </div>
        <div className="" style={{ paddingLeft: '2.5%' }}>
          <span
            className="flex items-center text-neutral-600"
            style={{ fontSize: '0.88vw' }}
            data-testid="total-carrier-input"
          >
            Total Carrier
          </span>
          <GrowUIFormField
            control={Input}
            // label="Total Carrier"
            name="tankInformation.totalCarrierValue"
            value={values?.tankInformation?.totalCarrierValue}
            onChange={e => totalCarrierChange(e.target.value || 0)}
            onBlur={() => {
              setFieldTouched('Total Carrier Input');
            }}
            disabled={values?.totalAreaValue === 0}
            imask={{
              mask: masks[values?.tankInformation?.totalCarrierUnit] || 'num',
              lazy: false,
              blocks: {
                num: {
                  mask: Number,
                  radix: '.',
                  mapToRadix: ['.'],
                  normalizeZeros: false,
                  thousandsSeparator: ','
                }
              }
            }}
          />
        </div>
        <div className="" style={{ paddingLeft: '2.5%' }}>
          <span
            className="flex items-center text-neutral-600"
            style={{ fontSize: '0.88vw' }}
            data-testid="carrier-by-area-input"
          >
            Carrier per Area
          </span>
          <GrowUIFormField
            control={Input}
            // label="Carrier per Area"
            name="tankInformation.carrierPerAreaValue"
            value={values?.tankInformation?.carrierPerAreaValue}
            onChange={e => carrierPerAreaChange(e.target.value || 0)}
            onBlur={() => {
              setFieldTouched('Carrier by Area Input');
            }}
            disabled={values?.totalAreaValue === 0}
            imask={{
              mask: masks[values?.tankInformation?.carrierPerAreaUnit] || 'num',
              lazy: false,
              blocks: {
                num: {
                  mask: Number,
                  radix: '.',
                  mapToRadix: ['.'],
                  normalizeZeros: false,
                  thousandsSeparator: ','
                }
              }
            }}
          />
        </div>
      </div>
    </div>
  );
};

TopOptions.defaultProps = {
  setFieldTouched: () => {}
};

TopOptions.propTypes = {
  setFieldTouched: PropTypes.func,
  values: PropTypes.shape({
    products: PropTypes.arrayOf(
      PropTypes.shape({ totalProductValue: PropTypes.number })
    ),
    tankInformation: PropTypes.shape({
      tankCount: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
      tankSizeValue: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
      tankSizeUnit: PropTypes.string,
      carrierPerAreaValue: PropTypes.oneOfType([
        PropTypes.number,
        PropTypes.string
      ]),
      carrierPerAreaUnit: PropTypes.string,
      totalCarrierValue: PropTypes.oneOfType([
        PropTypes.number,
        PropTypes.string
      ]),
      totalCarrierUnit: PropTypes.string
    }),
    totalAreaValue: PropTypes.number
  }).isRequired,
  setFieldValue: PropTypes.func.isRequired
};

export default TopOptions;
