import React from 'react';
import PropTypes from 'prop-types';
import PropertyListView from '../PropertyListView';
import PropertyMapView from '../PropertyMapView';

const PropertyView = ({
  view,
  propertyLandingPageData,
  fieldsAndAreasGeoJSONCollection,
  fieldsLoading,
  zoom,
  setZoom
}) => {
  return view === 'info' ? (
    <PropertyListView />
  ) : (
    <PropertyMapView
      properties={propertyLandingPageData.properties}
      geoJSONCollection={fieldsAndAreasGeoJSONCollection}
      fieldsLoading={fieldsLoading}
      zoom={zoom}
      setZoom={setZoom}
    />
  );
};

PropertyView.propTypes = {
  view: PropTypes.string.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  propertyLandingPageData: PropTypes.object.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  fieldsAndAreasGeoJSONCollection: PropTypes.object.isRequired,
  fieldsLoading: PropTypes.bool.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  zoom: PropTypes.object.isRequired,
  setZoom: PropTypes.func.isRequired
};

export default PropertyView;
