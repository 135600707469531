import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Spinner, Tabs } from '@agconnections/grow-ui';
import { getAbbreviatedUnit } from 'helpers/unitsAbbreviation';
import useCropSeasons from 'hooks/useCropSeasons';
import InvoiceTab from './components/InvoiceTab';
import TaskTab from './components/TaskTab';

const Inventory = ({
  id,
  selectedProduct,
  invoices,
  tasks,
  reloadData,
  setRedirect
}) => {
  const isSelectedProductDefine = selectedProduct.productName;
  const [tabSelected, setTabSelected] = useState(0);
  const { cropSeasons } = useCropSeasons();
  useEffect(() => {
    if (cropSeasons) {
      const cropSeasonIds = cropSeasons.map(cropSeason => cropSeason.id);

      reloadData({ seasonIds: cropSeasonIds });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cropSeasons]);
  const formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD'
  });

  const tabs = () => {
    return (
      <Tabs activeTab={tabSelected}>
        <Tabs.Tab
          label="Invoices"
          onClick={() => setTabSelected(0)}
          active={tabSelected === 0}
        >
          <InvoiceTab
            invoices={invoices}
            productId={id}
            cropSeasonsArray={cropSeasons}
            setRedirect={setRedirect}
          />
        </Tabs.Tab>
        <Tabs.Tab
          label="Tasks"
          onClick={() => setTabSelected(1)}
          active={tabSelected === 1}
        >
          <TaskTab
            tasks={tasks}
            productId={id}
            cropSeasonsArray={cropSeasons}
            setRedirect={setRedirect}
          />
        </Tabs.Tab>
      </Tabs>
    );
  };

  return (
    <>
      {isSelectedProductDefine ? (
        <>
          <div className="mb-6 border-b-2">
            <div className="flex mb-6">
              <div className="mr-6">
                <div className="text-sm">Average Price/Unit</div>
                <div className="text-xl">
                  {formatter.format(
                    isNaN(selectedProduct?.averagePricePerUnit)
                      ? 0
                      : selectedProduct?.averagePricePerUnit
                  )}
                  {'/'}
                  {getAbbreviatedUnit(selectedProduct?.averagePriceUnit)}
                </div>
              </div>
              <div className="mr-6">
                <div className="text-sm">Available Quantity</div>
                <div className="text-xl">
                  <p>
                    {selectedProduct?.available?.toFixed(2)}{' '}
                    {getAbbreviatedUnit(selectedProduct?.stdPackageUnit)}
                  </p>
                </div>
              </div>
              <div className="mr-6">
                <div className="text-sm">Total Invoiced Quantity</div>
                <div className="text-xl">
                  {selectedProduct?.purchased.toFixed(2)}{' '}
                  {selectedProduct?.purchasedUnit
                    ? getAbbreviatedUnit(selectedProduct?.purchasedUnit)
                    : ''}
                </div>
              </div>
              <div className="mr-6">
                <div className="text-sm">Total Applied Quantity</div>
                <div className="text-xl">
                  {selectedProduct?.productQuantityValue?.toFixed(2)}{' '}
                  {getAbbreviatedUnit(selectedProduct?.productQuantityUnit)}
                </div>
              </div>
            </div>
          </div>
          <div>{tabs()}</div>
        </>
      ) : (
        <Spinner />
      )}
    </>
  );
};

Inventory.propTypes = {
  id: PropTypes.string.isRequired,
  selectedProduct: PropTypes.shape(PropTypes.object).isRequired,
  invoices: PropTypes.arrayOf(PropTypes.object).isRequired,
  tasks: PropTypes.arrayOf(PropTypes.object).isRequired,
  setRedirect: PropTypes.func.isRequired,
  reloadData: PropTypes.func.isRequired
};

export default Inventory;
