import React, { useState, useEffect, useContext } from 'react';
import { useFormikContext } from 'formik';
import { Spinner } from '@agconnections/grow-ui';
import useAppliedProducts from 'hooks/useAppliedProducts';
import ConfirmationModal from 'screens/Integrations/components/ConfirmationModal';
import { IntegrationsContext } from 'screens/Integrations/context/IntegrationsProvider';
import { getCropZonesFromProperties } from 'helpers/propertyHelpers';
import TopHeader from './components/TopHeader';
import MccainSummaryFarms from './components/MccainSummaryFarms';
import MccainFooterIntegration from '../MccainFooterIntegration';
import FieldsView from './components/FieldsView';

export const MCCAIN_PDF_WRAPPER = 'mccain-pdf-wrapper';

const MccainSummaryView = () => {
  const [isLoading, setIsLoading] = useState(true);
  const { values, setFieldValue } = useFormikContext();
  const { selectedProperties, selectedCropSeasons, selectedFarm } = values;
  const { getAppliedProducts } = useAppliedProducts();

  const {
    setModalSubmissionState,
    state: { openSubmissionModal }
  } = useContext(IntegrationsContext);

  const getProductsByCropZoneId = (products, id) =>
    products?.filter?.(product => id === product.cropZoneId) ?? [];

  const adaptFieldsToPayload = (fields, products) =>
    fields
      .map(field => ({
        ...field,
        cropzones: field.cropzones
          .map(cz => ({
            ...cz,
            products: getProductsByCropZoneId(products, cz.id)
          }))
          .filter(cz => cz.products.length)
      }))
      .filter(field => field.cropzones.length);

  useEffect(() => {
    const selectedCropSeasonsIds = selectedCropSeasons.map(
      cropseason => cropseason.id
    );
    const cropzones = getCropZonesFromProperties(selectedProperties).flatMap(
      cz => ({
        cropZoneId: cz.id,
        seasonsIds: cz.cropSeasons?.map(season => season.id)
      })
    );

    const seasonsCropZonesPayload = selectedCropSeasonsIds
      ?.reduce((acc, seasonId) => {
        acc.push({
          seasonId,
          cropZoneIds: cropzones
            ?.filter(cz => cz.seasonsIds.includes(seasonId))
            ?.map(({ cropZoneId }) => cropZoneId)
        });
        return acc;
      }, [])
      ?.filter(payload => payload.cropZoneIds?.length);

    getAppliedProducts(seasonsCropZonesPayload).then(data => {
      const updatedProperties = [...selectedProperties];

      const toPayload = updatedProperties.map(property => {
        return {
          ...property,
          fields: adaptFieldsToPayload(property.fields, data.products)
        };
      });
      setFieldValue('selectedProperties', toPayload);
      setIsLoading(false);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedCropSeasons]);
  return (
    <>
      <div
        className="w-full h-full bg-white flex flex-col px-6 py-4 rounded-xl font-body"
        data-testid="summary-view"
        id={MCCAIN_PDF_WRAPPER}
      >
        <div
          className="flex flex-col w-full h-48 border-b-2 mb-12"
          id="mccain-top-header"
        >
          <TopHeader />
        </div>
        {isLoading ? (
          <div className="m-auto">
            <Spinner />
          </div>
        ) : (
          <>
            <div
              className="flex flex-row w-full h-full"
              id="mccain-summaryview"
            >
              <div className="w-auto mr-12">
                <MccainSummaryFarms />
              </div>
              <div className="w-full">
                <FieldsView fields={selectedProperties[selectedFarm]?.fields} />
              </div>
            </div>
            <MccainFooterIntegration MCCAIN_PDF_WRAPPER={MCCAIN_PDF_WRAPPER} />
          </>
        )}
      </div>
      <ConfirmationModal
        open={openSubmissionModal}
        setOpenModal={setModalSubmissionState}
      />
    </>
  );
};

export default MccainSummaryView;
