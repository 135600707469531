const calcProductsInUse = (productList, searchProducts, otherObjectToMap) => {
  const productsInUse = new Set(
    productList?.map(pro => pro?.productId),
    otherObjectToMap?.map(pro => pro?.productId)
  );
  return searchProducts?.map(pr => ({
    ...pr,
    isInUse: productsInUse.has(pr.id)
  }));
};
export default calcProductsInUse;
