import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Drawer } from 'syngenta-digital-cropwise-react-ui-kit';
import useInventoryProduct from 'screens/ProductList/hooks/useInventoryProduct';
import { buildMasterlistProductPayload } from 'screens/ProductList/helpers/formValuesToPayload';
import useSessionStorage from 'hooks/useSessionStorage';
import CloseIcon from 'assets/close.svg';
import ProductAccordion from '../ProductAccordion';
import {
  TProductMissingWeight,
  RESOLVED_PACKAGE_WEIGHT_KEY
} from '../../constants';
import ToastPackageWeight from '../ToastPackageWeight';

const ResolvePackageWeightDrawer = ({ products, onClose, onFinish, open }) => {
  const {
    addUpdateListPrice,
    isUpdateLoading,
    updateError,
    setUpdateError
  } = useInventoryProduct();

  const { storedValue, setValue } = useSessionStorage(
    RESOLVED_PACKAGE_WEIGHT_KEY,
    []
  );

  const [resolvedProducts, setResolvedProducts] = useState(storedValue);
  const [selectedProduct, setSelectedProduct] = useState();

  const updateLocally = values => {
    setResolvedProducts(prev => {
      const index = prev.findIndex(item => item.productId === values.productId);
      const isAnUpdate = index !== -1;
      if (isAnUpdate) {
        const updated = [...prev];
        updated[index] = values;
        return updated;
      }
      return [...prev, values];
    });
    setSelectedProduct(prev => prev + 1);
  };

  const onSubmit = useCallback(values => {
    addUpdateListPrice(buildMasterlistProductPayload(values), () =>
      updateLocally(values)
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onExpand = useCallback(index => {
    setSelectedProduct(prev => (prev === index ? null : index));
  }, []);

  useEffect(() => {
    setValue(resolvedProducts);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [resolvedProducts]);

  useEffect(() => {
    if (
      products.length > 0 &&
      resolvedProducts?.length > 0 &&
      resolvedProducts?.length >= products.length
    ) {
      setValue([]);
      setResolvedProducts([]);
      onFinish();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [resolvedProducts, products]);

  return (
    <>
      <Drawer
        open={open}
        title={
          <header>
            <button
              type="button"
              onClick={onClose}
              className="absolute top-3 right-4"
              style={{}}
            >
              <img alt="close" src={CloseIcon} />
            </button>

            <h1 className="font-semibold text-xl mb-2">Add Package Weight</h1>
            <p>
              Before you can match crop zones, we need to <br />
              know the package weight for the following seed <br />
              products.
            </p>
          </header>
        }
        placement="right"
        onClose={onClose}
        width="700px"
        closable={false}
      >
        {products.map((product, index) => (
          <ProductAccordion
            key={product.productId}
            product={product}
            resolvedProducts={resolvedProducts}
            isExpanded={index === selectedProduct}
            isLoading={isUpdateLoading}
            onExpand={() => onExpand(index)}
            onSubmit={onSubmit}
          />
        ))}
      </Drawer>

      <ToastPackageWeight
        showToast={updateError}
        setShowToast={setUpdateError}
        state="error"
      />
    </>
  );
};

ResolvePackageWeightDrawer.defaultProps = {
  products: []
};

ResolvePackageWeightDrawer.propTypes = {
  products: PropTypes.arrayOf(TProductMissingWeight),
  onClose: PropTypes.func.isRequired,
  onFinish: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired
};

export default ResolvePackageWeightDrawer;
