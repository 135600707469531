import React, { useState } from 'react';
import { Button, Popup } from '@agconnections/grow-ui';
import PropTypes from 'prop-types';
import { ArrowDropDown } from '@material-ui/icons';
import editIcon from 'assets/edit_field.svg';
import cropIcon from 'assets/crop_cycle.svg';

const buttonStyle = {
  borderTopRightRadius: '0',
  borderBottomRightRadius: '0',
  height: '50px',
  borderRight: 'none'
};

const SaveButton = ({
  saveButtonDisabled,
  saveDrawAgain,
  saveCreateCZ,
  saveDirectToProp,
  label,
  chkSubmitStatus
}) => {
  const [menuOpened, setMenuOpened] = useState(false);
  const saveDrawClick = () => {
    saveDrawAgain();
    setMenuOpened(false);
  };

  const saveCreateClick = () => {
    saveCreateCZ();
    setMenuOpened(false);
  };

  return (
    <div className="top-0 white-bg">
      <div className="flex">
        <Button
          disabled={saveButtonDisabled || chkSubmitStatus}
          loading={chkSubmitStatus}
          type="primary"
          onClick={() => saveDirectToProp()}
          className="w-full"
          style={
            label === 'create'
              ? buttonStyle
              : {
                  height: '50px',
                  borderRight: 'none'
                }
          }
        >
          Save Field
        </Button>
        {label === 'create' ? (
          <Popup
            position="top"
            align="right"
            trigger={
              <Button
                className=""
                type="primary"
                onClick={() => setMenuOpened(true)}
                disabled={saveButtonDisabled}
                style={{
                  height: '50px',
                  borderTopLeftRadius: '0',
                  borderBottomLeftRadius: '0',
                  borderLeft: 'none'
                }}
              >
                <ArrowDropDown />
              </Button>
            }
            open={menuOpened}
          >
            <div
              style={{ minWidth: '250px' }}
              className="mb-3 max-w-full text-left flex flex-wrap justify-between"
            >
              <ul className="font-normal text-gray-700 text-left">
                <li className="cursor-pointer hover:bg-gray-200 hover:bg-text-800">
                  <button
                    onClick={saveDrawClick}
                    data-testid="field-btn"
                    type="button"
                    className="w-full py-2 pl-2 text-left focus:outline-none"
                  >
                    <div className="flex">
                      <img src={editIcon} alt="" className="mr-2" />
                      Save and draw a new field
                    </div>
                  </button>
                </li>
                <li className="cursor-pointer hover:bg-gray-200 hover:bg-text-800">
                  <button
                    onClick={saveCreateClick}
                    data-testid="crop-zone-btn"
                    type="button"
                    className="w-full py-2 pl-2 text-left focus:outline-none"
                  >
                    <div className="flex">
                      <img src={cropIcon} alt="" className="mr-2" />
                      Save and add a crop zone
                    </div>
                  </button>
                </li>
              </ul>
            </div>
          </Popup>
        ) : (
          <></>
        )}
      </div>
    </div>
  );
};

SaveButton.defaultProps = {
  saveButtonDisabled: false,
  chkSubmitStatus: false
};

SaveButton.propTypes = {
  saveButtonDisabled: PropTypes.bool,
  saveCreateCZ: PropTypes.func.isRequired,
  saveDrawAgain: PropTypes.func.isRequired,
  saveDirectToProp: PropTypes.func.isRequired,
  label: PropTypes.string.isRequired,
  chkSubmitStatus: PropTypes.bool
};

export default SaveButton;
