import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Table, Button, Spinner } from '@agconnections/grow-ui';
import FormattedNumber from 'components/FormattedNumber';
import { getCropZonesFromProperties } from 'helpers/propertyHelpers';
import usePopulateMapData from 'hooks/usePopulateMapData';
import FieldIcon from 'components/Icons/FieldIcon';
import { mappingShape } from 'screens/Property/helpers/propertyDataHelpers';
import { FETCH_TYPES } from 'helpers/constants';
import ViewPropertyModal from '../../../ViewPropertyModal';
import useCropZoneData from '../../../../../Property/hooks/useCropZoneData';
import useFieldData from '../../../../../Property/hooks/useFieldData';
import CropField from '../CropField';

const PropertyTableRow = ({ property }) => {
  const {
    dataSorted,
    fieldsAndAreasGeoJSONCollection,
    fieldsLoading,
    reloadData
  } = usePopulateMapData({
    fetchType: FETCH_TYPES.propertiesMap
  });

  const [propertiesModalOpen, setPropertiesModalOpen] = useState(false);
  const [zoneID, setZoneID] = useState();
  const [fieldID, setField] = useState();
  const [allCropZones, setAllCropZones] = useState([]);
  const [crop, setCrop] = useState();
  const { cropZone } = useCropZoneData(zoneID);
  const { field } = useFieldData(fieldID);

  useEffect(() => {
    if (!fieldsLoading) {
      const cropzones = getCropZonesFromProperties(dataSorted?.properties);
      setAllCropZones(cropzones);
    }
  }, [fieldsLoading, dataSorted.properties]);

  const getFieldIconFeatures = () => {
    if (fieldsAndAreasGeoJSONCollection.features.length > 0) {
      const fieldIconFeatures = [];
      fieldsAndAreasGeoJSONCollection.features.forEach(feature => {
        if (property.cropZoneIdValue === feature.properties.id) {
          fieldIconFeatures.push(mappingShape(feature));
        }
      });
      return fieldIconFeatures;
    }
    return '';
  };

  const calculateCoveragePercent = (prop, allCZ) => {
    if (prop && allCZ.length > 0) {
      const getIndividualCropZone = allCZ?.find(
        item => item?.id === prop?.cropZoneIdValue
      );
      let covPercent = 0;
      if (getIndividualCropZone?.reportedArea === 0) {
        covPercent = Math.round(
          (prop?.areaValue / getIndividualCropZone?.boundaryArea +
            Number.EPSILON) *
            100
        );
      } else {
        covPercent = Math.round(
          (prop?.areaValue / getIndividualCropZone?.reportedArea +
            Number.EPSILON) *
            100
        );
      }
      return covPercent;
    }
    return false;
  };

  useEffect(() => {
    setZoneID(property?.cropZoneIdValue);
  }, [property]);

  useEffect(() => {
    if (!field) setField(cropZone?.fieldId);
  }, [field, cropZone]);

  useEffect(() => {
    reloadData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return field && cropZone && !fieldsLoading ? (
    <>
      <ViewPropertyModal
        open={propertiesModalOpen}
        onClose={() => {
          setPropertiesModalOpen(false);
        }}
        property={{
          cropZoneName: cropZone?.name,
          cropName: crop?.name,
          fieldName: field?.name,
          areaValue: property?.areaValue,
          areaUnit: property?.areaUnit,
          boundaryArea:
            Math.round((cropZone?.boundaryArea + Number.EPSILON) * 100) / 100,
          reportedArea:
            Math.round((cropZone?.reportedArea + Number.EPSILON) * 100) / 100,
          coveragePercent:
            calculateCoveragePercent(property, allCropZones) || ''
        }}
      />
      <Table.Row>
        <Table.Cell width="5rem">
          <FieldIcon
            id="view-plan-card-icon"
            features={getFieldIconFeatures()}
          />
        </Table.Cell>
        <Table.Cell>
          <div className="flex flex-col justify-start leading-5 text-left">
            <span className="font-bold text-neutral-1000">{field?.name}</span>
            <span style={{ color: '#707374' }}>{cropZone?.name}</span>
          </div>
        </Table.Cell>
        <Table.Cell>
          <CropField cropId={cropZone.cropId} setCrop={setCrop} />
        </Table.Cell>
        <Table.Cell>
          <FormattedNumber roundedTo={2}>{property?.areaValue}</FormattedNumber>
          {' ac'}
        </Table.Cell>
        <Table.Cell>
          <div className="mr-6">
            {calculateCoveragePercent(property, allCropZones)} %
          </div>
          <div
            data-testid="product-table-view-button"
            className="mr-8 absolute right-0 flex-none w-10"
            data-html2canvas-ignore
          >
            <Button
              type="link-default"
              onClick={() => setPropertiesModalOpen(true)}
            >
              View
            </Button>
          </div>
        </Table.Cell>
      </Table.Row>
    </>
  ) : (
    <Spinner />
  );
};

PropertyTableRow.propTypes = {
  property: PropTypes.shape({
    cropZoneIdValue: PropTypes.string,
    cropZoneName: PropTypes.string,
    cropName: PropTypes.string,
    areaValue: PropTypes.number,
    areaUnit: PropTypes.string,
    fieldName: PropTypes.string,
    boundaryArea: PropTypes.number,
    coveragePercent: PropTypes.number,
    reportedArea: PropTypes.number
  }).isRequired
};

export default PropertyTableRow;
