const sortItems = [
  {
    sortLabel: `Location Name (A \u2192 Z)`,
    sortKey: 'name',
    sortDirection: 'asc'
  },
  {
    sortLabel: `Capacity (High \u2192 Low)`,
    sortKey: 'capacity',
    sortDirection: 'desc'
  },
  {
    sortLabel: `Available Space (High \u2192 Low)`,
    sortKey: 'availableSpace',
    sortDirection: 'desc'
  }
];

export default sortItems;
