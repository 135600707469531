import useMasterProducts from 'hooks/useMasterProducts';
import { unmaskPrice } from 'screens/Recommendations/helpers/dataHelpers';

const usePlanProducts = (formikContext = {}) => {
  const { appliedAreaCalculation } = useMasterProducts();
  const { setFieldValue, values } = formikContext;

  const recalculateProducts = async (planProductEntries = [], areaValue) => {
    const promiseProductsUpdated = planProductEntries.map(async product => {
      const mappedProduct = await appliedAreaCalculation(product, areaValue);

      return mappedProduct;
    });

    const productsUpdated = await Promise.all(promiseProductsUpdated);

    return productsUpdated;
  };

  const recalculateCosts = estimatedAreaValue => {
    if (setFieldValue && values) {
      if (values.fixedCost.landRent_PerAc) {
        setFieldValue(
          'fixedCost.landRent',
          parseFloat(
            Number(unmaskPrice(values.fixedCost.landRent_PerAc)) *
              Number(estimatedAreaValue)
          )
        );
      }

      if (values.fixedCost.repairs_PerAc) {
        setFieldValue(
          'fixedCost.repairs',
          parseFloat(
            Number(unmaskPrice(values.fixedCost.repairs_PerAc)) *
              Number(estimatedAreaValue)
          )
        );
      }

      if (values.fixedCost.returnToMgt_PerAc) {
        setFieldValue(
          'fixedCost.returnToMgt',
          parseFloat(
            Number(unmaskPrice(values.fixedCost.returnToMgt_PerAc)) *
              Number(estimatedAreaValue)
          )
        );
      }

      if (values.fixedCost.equipment_PerAc) {
        setFieldValue(
          'fixedCost.equipment',
          parseFloat(
            Number(unmaskPrice(values.fixedCost.equipment_PerAc)) *
              Number(estimatedAreaValue)
          )
        );
      }

      if (values.fixedCost.taxes_PerAc) {
        setFieldValue(
          'fixedCost.taxes',
          parseFloat(
            Number(unmaskPrice(values.fixedCost.taxes_PerAc)) *
              Number(estimatedAreaValue)
          )
        );
      }

      if (values.fixedCost.labor_PerAc) {
        setFieldValue(
          'fixedCost.labor',
          parseFloat(
            Number(unmaskPrice(values.fixedCost.labor_PerAc)) *
              Number(estimatedAreaValue)
          )
        );
      }

      if (values.fixedCost.insurance_PerAc) {
        setFieldValue(
          'fixedCost.insurance',
          parseFloat(
            Number(unmaskPrice(values.fixedCost.insurance_PerAc)) *
              Number(estimatedAreaValue)
          )
        );
      }

      if (values.projectedRevenue.fsaPaymentPerAc) {
        setFieldValue(
          'projectedRevenue.fsaPayment',
          parseFloat(
            Number(unmaskPrice(values.projectedRevenue.fsaPaymentPerAc)) *
              Number(estimatedAreaValue)
          )
        );
      }

      if (values.projectedRevenue.yieldTotal) {
        setFieldValue(
          'projectedRevenue.yieldTotal',
          parseFloat(
            Number(unmaskPrice(values.projectedRevenue.pricePerUnit)) *
              Number(values.projectedRevenue.yieldPerArea) *
              Number(estimatedAreaValue)
          )
        );
      }
    }
  };

  return { recalculateProducts, recalculateCosts };
};

export default usePlanProducts;
