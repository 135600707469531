import React from 'react';
import PropTypes from 'prop-types';

const CropZoneAction = ({ children, onClick, disabled }) => {
  return (
    <button
      type="button"
      onClick={onClick}
      disabled={disabled}
      className="py-2 pl-2 focus:outline-none whitespace-no-wrap"
    >
      <div className="flex justify-start">{children}</div>
    </button>
  );
};

CropZoneAction.defaultProps = {
  disabled: false,
  onClick: () => {}
};

CropZoneAction.propTypes = {
  onClick: PropTypes.func,
  disabled: PropTypes.bool,
  children: PropTypes.node.isRequired
};

export default CropZoneAction;
