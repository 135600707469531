import React from 'react';
import { Button, ButtonType } from 'syngenta-digital-cropwise-react-ui-kit';
import PropTypes from 'prop-types';

const ToastActionButton = ({ label, action }) => {
  return (
    <div data-testid="toast-action-button" className="flex flex-col mt-2">
      <Button
        style={{ alignSelf: 'end' }}
        type={ButtonType.primary}
        onClick={action}
      >
        {label}
      </Button>
    </div>
  );
};

ToastActionButton.propTypes = {
  label: PropTypes.string.isRequired,
  action: PropTypes.func.isRequired
};

export default ToastActionButton;
