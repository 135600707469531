import React, { useContext, useState } from 'react';
import PropTypes from 'prop-types';
import { organization as organizationApi } from 'utilities/api';
import catchCancel from 'helpers/catchCancel';
import { parseServerError } from 'helpers/errorHelpers';
import { Context } from 'components/Store';

import { SimpleModal } from '@agconnections/grow-ui';

const CancelInviteModal = ({
  open,
  onClose,
  invitationId,
  getOrganization
}) => {
  const [, dispatch] = useContext(Context);
  const [isSaving, setIsSaving] = useState(false);

  const inviteApi = organizationApi.createChildApi({
    action: 'organization/invitation'
  });

  const cancelInvite = async () => {
    setIsSaving(true);
    const { promise } = inviteApi.delete(invitationId);
    promise
      .then(() => {
        getOrganization();
        setIsSaving(false);
        onClose();
      })
      .catch(catchCancel)
      .catch(err => {
        parseServerError(dispatch)(err);
        setIsSaving(false);
        onClose();
      });
  };
  return (
    <div data-testid="cancel-invite">
      <SimpleModal
        onConfirm={cancelInvite}
        open={open}
        close={onClose}
        title="Cancel Invite"
        type="confirmation"
        isSaving={isSaving}
      >
        <div className="pr-6">Are you sure you want to cancel this Invite?</div>
      </SimpleModal>
    </div>
  );
};

CancelInviteModal.defaultProps = {
  open: false,
  onClose: () => {},
  invitationId: '',
  getOrganization: () => {}
};

CancelInviteModal.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
  invitationId: PropTypes.string,
  getOrganization: PropTypes.func
};

export default CancelInviteModal;
