import { createRouteHandlers } from './helpers/apiResourceBuilder';

const tags = server => {
  createRouteHandlers(server, 'tags', {
    orgRoutes: false,
    customHandlers: {
      // custom update handler specifies this function for use as the PUT function
      update: (schema, request) => {
        const { id } = request.params;
        const parsedBody = JSON.parse(request.requestBody);
        const fields = schema.fields.where(field => field.farm.id === id);
        for (let i = 0; i < fields.models.length; i += 1) {
          const element = fields.models[i];
          element.farm.name = parsedBody.name;
        }
        const tag = schema.tags.where({ id });
        tag.update({ name: parsedBody.name });
        return tag;
      }
    }
  });
};

export default tags;
