import { useContext, useEffect, useState } from 'react';
import useRecommendationsApi from 'hooks/useRecommendationsApi';
import catchCancel from 'helpers/catchCancel';
import { parseServerError } from 'helpers/errorHelpers';
import { Context } from 'components/Store';

function useRecommendationData(id, action) {
  const recommendationsAPI = useRecommendationsApi('recommendation');
  const [, dispatch] = useContext(Context);
  const [recommendation, setRecommendation] = useState(undefined);
  const [isLoading, setIsLoading] = useState(true);
  const [isSaving, setIsSaving] = useState(false);

  useEffect(() => {
    const isCreateScreen = id === 'create' || id === 'convert';
    let cancel;
    if (!isCreateScreen) {
      const { promise, cancel: _cancel } = recommendationsAPI.fetch(id);
      cancel = _cancel;
      promise
        .then(({ data }) => {
          setRecommendation(data);
        })
        .catch(catchCancel)
        .catch(parseServerError(dispatch))
        .finally(() => setIsLoading(false));
    } else {
      setIsLoading(false);
    }
    return () => cancel?.();
  }, [dispatch, id, recommendationsAPI]);

  return {
    isSaving,
    saveRecommendation: values => {
      setIsSaving(true);

      const isCreateScreen =
        id === 'create' || id === 'convert' || action === 'duplicate';
      const { promise } = isCreateScreen
        ? recommendationsAPI.create(values)
        : recommendationsAPI.update(id, values);

      return promise
        .catch(catchCancel)
        .catch(parseServerError(dispatch))
        .finally(() => {
          setIsSaving(false);
        });
    },
    recommendation,
    isLoading
  };
}

export default useRecommendationData;
