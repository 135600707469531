import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import EmptyStateImage from 'assets/no_products_found.svg';
import { Spinner } from '@agconnections/grow-ui';

const ProductDropdownItem = ({ item }) => {
  const titleRef = useRef(null);
  const { value: itemValue, key, label } = item;
  if (!itemValue) {
    if (label === 'empty') {
      return (
        <div
          className="flex flex-col justify-center items-center h-full"
          key={key}
          data-testid="item-empty-value"
        >
          <img
            className="w-1/2 mx-auto"
            src={EmptyStateImage}
            alt="empty-state"
            data-testid="empty-image"
          />
          <p data-testid="empty-table-state-message" className="text-sm mb-4">
            Enter at least three characters to search the product list
          </p>
        </div>
      );
    }

    if (label === 'loading') {
      return (
        <div
          className="flex flex-col justify-center items-center h-full"
          key={key}
          data-testid="item-loading-value"
        >
          <div className="w-1/2 mx-auto" data-testid="loading-spinner">
            <Spinner />
          </div>
        </div>
      );
    }
  }
  return (
    <div
      key={key}
      className="w-full pb-2 pt-1 px-2 flex flex-col"
      data-testid="item-with-value"
    >
      <div className="flex flex-row justify-between w-full align-middle text-sm">
        <div ref={titleRef} className="break-normal w-3/4">
          {itemValue.name}
          <span className="text-gray-600 text-xs">{` (${itemValue.stdunit})`}</span>
        </div>
        <div>
          <span className="text-xs bg-gray-200 text-gray-600 p-1 mt-1 rounded-md">
            {itemValue.producttype}
          </span>
        </div>
      </div>
      <div>
        <p
          className="text-gray-600 text-xs mt-1"
          style={{ width: `calc(100% -${titleRef?.current?.clientWidth}px)` }}
        >
          {itemValue.manufacturer}
        </p>
      </div>
    </div>
  );
};

ProductDropdownItem.propTypes = {
  item: PropTypes.shape({
    value: PropTypes.shape({
      name: PropTypes.string,
      type: PropTypes.string,
      stdunit: PropTypes.string,
      producttype: PropTypes.string,
      manufacturer: PropTypes.string
    }),
    key: PropTypes.string,
    label: PropTypes.string,
    type: PropTypes.string
  }).isRequired
};

export default ProductDropdownItem;
