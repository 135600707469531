import React, { useContext, useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';
import { cloneDeep } from 'lodash';
import { Modal, Select, Form, Button } from '@agconnections/grow-ui';
import GrowUIFormField from 'components/Forms/GrowUIFormField';
import { Formik } from 'formik';
import { timingKeyValuePair } from 'screens/helpers/constants';
import { Context } from 'components/Store';
import useProductsData from 'hooks/useProductData';
import usePopulateMapData from 'hooks/usePopulateMapData';
import { FETCH_TYPES } from 'helpers/constants';
import { paths } from 'routes/paths';
import ConvertPlanTable from './ConvertPlanTable';
import { mapPlanToTask } from './helpers/dataHelper';

const ConvertPlanModal = ({ open, close, currentPlan }) => {
  const [mappedProducts, setMappedProducts] = useState([]);
  const [allAreMarked, setAllAreMarked] = useState(false);
  const { loading } = useProductsData();
  const handleCancel = () => {
    close();
  };
  const history = useHistory();
  const [timings, setTimings] = useState(timingKeyValuePair);
  const [timingTags, setTimingTags] = useState([]);
  const [timingDropValue, setTimingDropValue] = useState(null);
  const [timingTagDropValue, setTimingTagDropValue] = useState(null);

  const [, dispatch] = useContext(Context);
  const {
    dataSorted: propertyLandingPageData,
    fieldsAndAreasGeoJSONCollection,
    reloadData
  } = usePopulateMapData({
    fetchType: FETCH_TYPES.propertiesMap
  });

  const initialValues = {
    timing: '',
    timingTag: ''
  };

  const baseDropDownValues = [
    { key: 'clear', value: '- Clear Filter' },
    { key: 'none', value: 'None' }
  ];

  const resetMappedProducts = () => {
    const resetMapped = mappedProducts.map(prod => {
      const newProd = prod;
      newProd.hide = false;
      newProd.marked = false;
      return newProd;
    });
    setMappedProducts(resetMapped);
  };

  const closeModal = () => {
    setTimingDropValue(null);
    setTimingTagDropValue(null);
    resetMappedProducts();
    setAllAreMarked(false);
    close();
  };

  const convertToTask = () => {
    const selectProducts = mappedProducts.filter(p => p.marked);
    const convertPlan = mapPlanToTask(
      currentPlan,
      selectProducts,
      propertyLandingPageData,
      fieldsAndAreasGeoJSONCollection
    );
    dispatch({
      type: 'SET_CONVERT_PLAN',
      payload: convertPlan
    });
    history.push(`${paths.tasks}/convert`);
  };

  const checkIfAllMarked = () => {
    const numberShown = mappedProducts?.filter(p => !p.hide).length;
    const numberMarked = mappedProducts?.filter(p => !p.hide && p.marked)
      .length;
    setAllAreMarked(numberShown === numberMarked && numberShown > 0);
  };

  const markUnmarkAll = isMarked => {
    const updatedProducts = mappedProducts.map(prod => {
      const newProd = prod;
      newProd.marked = prod.hide ? prod.marked : isMarked;
      return newProd;
    });

    setMappedProducts(updatedProducts);
    checkIfAllMarked();
  };

  const markUnmarkProduct = (isMarked, prodIdx) => {
    const updatedProducts = mappedProducts.map(prod => {
      return {
        ...prod,
        marked: prodIdx === prod.trackingId ? isMarked : prod.marked
      };
    });

    setMappedProducts(updatedProducts);
    checkIfAllMarked();
  };

  const filterTimingOnSelect = ({ key }) => {
    const keyValue = key === 'none' ? '' : key;
    const updatedProducts = mappedProducts.map(prod => {
      const doesContain =
        keyValue === 'clear' ? prod.marked : keyValue === prod.timingEvent;
      return {
        ...prod,
        hide: key === 'clear' ? false : !doesContain
      };
    });

    setMappedProducts(updatedProducts);
    checkIfAllMarked();
  };

  const filterTimingTagsOnSelect = ({ key }) => {
    const keyValue = key === 'none' ? '' : key;
    const updatedProducts = mappedProducts.map(prod => {
      const doesContain =
        !prod.timingEventTag || keyValue === prod.timingEventTag;
      return {
        ...prod,
        hide: key === 'clear' ? false : !doesContain
      };
    });

    setMappedProducts(updatedProducts);
    checkIfAllMarked();
  };

  useEffect(() => {
    const planProducts = currentPlan?.planProductEntries?.map(planProd => {
      return {
        marked: false,
        hide: false,
        ...planProd
      };
    });
    setMappedProducts(planProducts);

    const newTimings = cloneDeep(baseDropDownValues);
    const combined = newTimings.concat(cloneDeep(timingKeyValuePair));
    setTimings(combined);

    const tempTimingTags = cloneDeep(baseDropDownValues);
    // eslint-disable-next-line no-unused-expressions
    currentPlan?.planProductEntries?.forEach(prod => {
      if (
        prod.timingEventTag &&
        tempTimingTags.findIndex(tag => tag.key === prod.timingEventTag) === -1
      ) {
        tempTimingTags.push({
          key: prod.timingEventTag,
          value: prod.timingEventTag
        });
      }
    });
    setTimingTags(tempTimingTags);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPlan?.planProductEntries]);

  useEffect(() => {
    checkIfAllMarked();
  });

  useEffect(() => {
    reloadData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Modal
      open={open}
      onClose={close}
      onCancel={() => handleCancel()}
      onConfirm={() => handleCancel()}
      size="lg"
      hideClose
    >
      <div className="p-5">
        <div className="text-2xl font-bold">Convert to Task</div>
        <Formik initialValues={initialValues} onSubmit={() => convertToTask()}>
          {({ values, handleSubmit }) => {
            return (
              <>
                <div className="flex w-full w-1/3">
                  <div className="w-full mr-4">
                    <Form.Field label="Timing">
                      <GrowUIFormField
                        control={Select}
                        value={timingDropValue}
                        items={timings}
                        id="timing"
                        name="timing"
                        onChange={e => {
                          let dValue = null;
                          if (JSON.parse(e.target.value).key !== 'clear') {
                            dValue = JSON.parse(e.target.value).key;
                          }
                          filterTimingOnSelect(JSON.parse(e.target.value));
                          setTimingDropValue(dValue);
                        }}
                      />
                    </Form.Field>
                  </div>
                  <Form.Field label="Timing Tag">
                    <GrowUIFormField
                      // onSelect={filterTimingTagsOnSelect}
                      control={Select}
                      selected={values.timingTag}
                      value={timingTagDropValue}
                      items={timingTags}
                      id="timingTag"
                      name="timingTag"
                      onChange={e => {
                        let dValue = null;
                        if (JSON.parse(e.target.value).key !== 'clear') {
                          dValue = JSON.parse(e.target.value).key;
                        }
                        filterTimingTagsOnSelect(JSON.parse(e.target.value));
                        setTimingTagDropValue(dValue);
                      }}
                    />
                  </Form.Field>
                </div>
                <div className="text-2xl font-bold">Products & Services</div>
                <div className="text-sm pb-4">
                  Select the product(s) you want to include in your task
                </div>
                <div className="h-80 overflow-y-auto mb-8 border border-solid">
                  <ConvertPlanTable
                    planProducts={mappedProducts}
                    markAll={markUnmarkAll}
                    markUnmarkProduct={markUnmarkProduct}
                    loading={loading}
                    allAreMarked={allAreMarked}
                    id="productsTable"
                    name="productsTable"
                  />
                </div>
                <div className="flex justify-end">
                  <div className="mr-4">
                    <Button
                      className="pr-2"
                      onClick={() => {
                        closeModal();
                      }}
                    >
                      Cancel
                    </Button>
                  </div>
                  <Button type="primary" onClick={handleSubmit}>
                    Next
                  </Button>
                </div>
              </>
            );
          }}
        </Formik>
      </div>
    </Modal>
  );
};

ConvertPlanModal.defaultProps = {
  currentPlan: null
};

ConvertPlanModal.propTypes = {
  open: PropTypes.bool.isRequired,
  close: PropTypes.func.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  currentPlan: PropTypes.object
};

export default ConvertPlanModal;
