import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Table, Checkbox } from '@agconnections/grow-ui';
import { formatDateToShortMonthDayYear } from 'utilities/dateHelpers';

const AgrianRecommendationRow = ({
  recommendation,
  handleSelection,
  selectedRecommendations
}) => {
  const [showMoreTextFields, setShowMoreTextFields] = useState(false);
  const [showMoreTextProducts, setShowMoreTextProducts] = useState(false);

  const renderLongTextWithShowMore = (text, flag, toggleFlag) => {
    if (text.length < 80) {
      return text;
    }

    return (
      <>
        {flag ? text : `${text.substring(0, 80)}...`}
        <button
          type="button"
          className="text-blue-50 ml-2 cursor-pointer"
          onClick={toggleFlag}
          onKeyPress={toggleFlag}
        >
          {flag ? 'show less' : 'show more'}
        </button>
      </>
    );
  };

  const isRecommendationSelected = recommendationId => {
    if (!selectedRecommendations || selectedRecommendations.length === 0) {
      return false;
    }

    return (
      selectedRecommendations.findIndex(({ id }) => id === recommendationId) !==
      -1
    );
  };

  return (
    <Table.Row>
      <Table.Cell>
        <Checkbox
          checked={isRecommendationSelected(recommendation.id)}
          onChange={() => {
            handleSelection(recommendation);
          }}
        />
      </Table.Cell>
      <Table.Cell>
        <div className="text-left">{recommendation.description}</div>
      </Table.Cell>
      <Table.Cell>
        <div className="text-left">
          {formatDateToShortMonthDayYear(recommendation.startDate)}
        </div>
      </Table.Cell>
      <Table.Cell>
        <div className="text-left">
          {renderLongTextWithShowMore(
            recommendation.fields?.map(f => f.name).join(`, `),
            showMoreTextFields,
            () => setShowMoreTextFields(!showMoreTextFields)
          )}
        </div>
      </Table.Cell>
      <Table.Cell>
        <div className="text-left">
          {renderLongTextWithShowMore(
            recommendation.products?.map(p => p.productName).join(`, `),
            showMoreTextProducts,
            () => setShowMoreTextProducts(!showMoreTextProducts)
          )}
        </div>
      </Table.Cell>
      <Table.Cell>
        {recommendation.lastSubmission && (
          <div className="bg-gray-200 text-neutral-50 font-semibold px-2 rounded-sm">
            {formatDateToShortMonthDayYear(recommendation.lastSubmission)}
          </div>
        )}
      </Table.Cell>
    </Table.Row>
  );
};

AgrianRecommendationRow.propTypes = {
  recommendation: PropTypes.shape({
    id: PropTypes.string,
    description: PropTypes.string,
    startDate: PropTypes.string,
    fields: PropTypes.arrayOf(
      PropTypes.shape({
        name: PropTypes.string
      })
    ),
    products: PropTypes.arrayOf(
      PropTypes.shape({
        productName: PropTypes.string
      })
    ),
    lastSubmission: PropTypes.string
  }).isRequired,
  handleSelection: PropTypes.func.isRequired,
  selectedRecommendations: PropTypes.arrayOf(
    PropTypes.shape({ id: PropTypes.string })
  ).isRequired
};

export default AgrianRecommendationRow;
