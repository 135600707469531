import React from 'react';
import AttachedFileIcon from 'components/Icons/components/AttachedFileIcon';
import PropTypes from 'prop-types';

const DocumentationCard = ({ description, fileName, openFile }) => {
  const truncateFileName = name => {
    if (name.length > 20) {
      const extension = name.slice(-6, name.length);
      const nameOfFile = name.slice(0, 10);
      return `${nameOfFile}...${extension}`;
    }

    return fileName;
  };

  return (
    <div
      data-testid="document-single-card"
      className="border flex flex-wrap flex-col rounded-xl w-full h-48 shadow-documentation-card text-center cursor-pointer"
      onClick={openFile}
      onKeyDown={openFile}
      role="button"
      tabIndex={0}
    >
      <div className="flex flex-col items-center justify-center h-screen">
        <div className="place-self-center">
          <AttachedFileIcon />
        </div>
        <div className="text-sm text-neutral-80 pt-4 pb-1">{description}</div>
        <div className="text-xs text-neutral-600 w-full">
          {truncateFileName(fileName)}
        </div>
      </div>
    </div>
  );
};

DocumentationCard.propTypes = {
  description: PropTypes.string.isRequired,
  fileName: PropTypes.string.isRequired,
  openFile: PropTypes.func.isRequired
};

export default DocumentationCard;
