import Decimal from 'decimal.js';
import { getBushelWeight } from './getBushelWeight';
import { Maths } from './maths';
import { MEASUREMENT_UNITS } from './unitsAbbreviation';

export default function convertCropFromPoundToBushel(valuePounds, cropName) {
  try {
    const density = getBushelWeight(cropName);
    if (density) {
      return {
        value: Maths.divideSafeRound(valuePounds, density),
        unit: MEASUREMENT_UNITS.BUSHEL
      };
    }
    return {
      value: new Decimal(valuePounds).toNumber(),
      unit: MEASUREMENT_UNITS.POUND
    };
  } catch (error) {
    return undefined;
  }
}
