import React from 'react';
import PropTypes from 'prop-types';

import { Form, TextArea } from '@agconnections/grow-ui';

function NotesField({ notes, onChange, errors }) {
  return (
    <Form.Field>
      <div className="block">
        <div className="w-full">
          <TextArea
            id="notes-textarea"
            name="notes"
            error={errors}
            value={notes}
            onChange={onChange}
            rows="8"
          />
        </div>
      </div>
    </Form.Field>
  );
}

NotesField.defaultProps = {
  notes: '',
  errors: null
};

NotesField.propTypes = {
  notes: PropTypes.string,
  errors: PropTypes.string,
  onChange: PropTypes.func.isRequired
};

export default NotesField;
