import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import MatchArrowIcon from 'screens/Integrations/PropertiesMatch/components/FarmList/components/icons/MatchArrowIcon';
import { Switch, Tag } from 'syngenta-digital-cropwise-react-ui-kit';
import SimplotMaterialsDropdown from '../SimplotMaterialsDropdown';

const ProductListItem = ({ product, matchedProduct, handleSelect }) => {
  const [isCreateCustomProduct, setIsCreateCustomProduct] = useState(false);
  const [selectedValue, setSelectedValue] = useState();

  useEffect(() => {
    if (matchedProduct) {
      setSelectedValue({
        id: matchedProduct?.vendorId,
        name: matchedProduct?.vendorPropertyName
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [matchedProduct]);

  const cleanUpDataByMaterialType = type => {
    /**
     * Filter
     * CropProtection - only displays type === Chemicals
     * Fertilizer - only displays type === Fertilizer
     * Services - displays others then type === Fertilizer || Chemicals
     *
     */
    if (type.toLowerCase() === 'cropprotection') {
      return material => material.type === 'Chemical';
    }

    if (type.toLowerCase() === 'fertilizer') {
      return material => material.type === 'Fertilizer';
    }

    return material =>
      material.type !== 'Fertilizer' && material.type !== 'Chemical';
  };

  const getTagAppearance = () => {
    if (matchedProduct) {
      if (matchedProduct.error) {
        return {
          label: 'ERROR',
          color: 'red'
        };
      }
      return {
        label: 'MATCHED',
        color: 'green'
      };
    }

    return {
      label: 'UNMATCHED',
      color: 'yellow'
    };
  };

  const adaptProductToMatch = prod => {
    return {
      ...prod,
      source: 'CUSTOM',
      id: prod.productId,
      name: prod.productName
    };
  };

  const handleSwitchCreateCustomProduct = () => {
    setIsCreateCustomProduct(!isCreateCustomProduct);
    if (!isCreateCustomProduct) {
      handleSelect(adaptProductToMatch(product), product);
    } else {
      handleSelect(adaptProductToMatch(product));
    }
  };

  return (
    <div className="justify-self-start pr-15 col-span-w w-full mb-8">
      <div className="grid items-center grid-cols-7">
        <div className="text-sm col-span-3" style={{ position: 'relative' }}>
          <div className="flex flex-row items-center">
            <div className="mr-4 mt-6 flex-none w-22">
              <Tag
                key="product-status"
                color={getTagAppearance().color}
                data-testid="status-tag"
              >
                {getTagAppearance().label}
              </Tag>
            </div>
            <div className="flex flex-col my-2 w-full">
              <span className="text-gray-600">Cropwise Product</span>
              <div className="mt-1 p-2 rounded-md bg-gray-200 border-gray-500 border-1 text-gray-600">{`${product.productName} (${product.quantityUnit})`}</div>
            </div>
          </div>
        </div>
        <div className="flex items-center justify-center h-full col-span-1">
          <span className="mb-1 mt-8">
            <MatchArrowIcon />
          </span>
        </div>
        <div className="flex flex-col col-span-3">
          {!isCreateCustomProduct ? (
            <>
              <div
                className="text-neutral-600 text-left text-sm mt-8"
                data-testid="cropzones-heading"
              >
                Simplot Materials
              </div>
              <div className="flex items-center justify-center cropzone-dropdown">
                <SimplotMaterialsDropdown
                  id="cwfProductMatch"
                  value={selectedValue}
                  onChange={value => {
                    handleSelect(value, product);
                  }}
                  cleanUpCallback={cleanUpDataByMaterialType(
                    product.productType
                  )}
                />
              </div>
            </>
          ) : (
            <div className="flex flex-col my-2 w-full">
              <span className="text-gray-600 mt-8">Simplot Product</span>
              <div className="mt-1 p-2 rounded-md bg-gray-200 border-gray-500 border-1 text-gray-600">
                New Custom Simplot Product
              </div>
            </div>
          )}

          <div className="my-auto pt-2">
            <Switch
              size="medium"
              checked={isCreateCustomProduct}
              onChange={handleSwitchCreateCustomProduct}
            />
            <span className="ml-2">
              Use Cropwise data to create custom Simplot product
            </span>
          </div>
          {matchedProduct?.error && (
            <div className="w-full bg-red-200 text-red-800 p-2 mt-2 rounded-md">
              {matchedProduct?.error}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

ProductListItem.defaultProps = {
  matchedProduct: undefined
};

ProductListItem.propTypes = {
  product: PropTypes.shape({
    productId: PropTypes.string,
    productName: PropTypes.string,
    productType: PropTypes.string,
    quantityUnit: PropTypes.string,
    unit: PropTypes.string
  }).isRequired,
  matchedProduct: PropTypes.shape({
    vendorPropertyName: PropTypes.string,
    vendorId: PropTypes.string,
    cwfId: PropTypes.string,
    type: PropTypes.string,
    error: PropTypes.string
  }),
  handleSelect: PropTypes.func.isRequired
};

export default ProductListItem;
