const getIsNavItemActive = ({ item, pathname }) => {
  return (
    pathname.includes(item?.path) ||
    item?.subRoutes?.some(subRoute => pathname.includes(subRoute?.path))
  );
};

export default getIsNavItemActive;

export const populateDropDown = (
  menuOpened,
  loadedOrgs,
  organizations,
  searchTerm,
  setLoaded,
  setItems
) => {
  setLoaded(false);
  if (!menuOpened || !loadedOrgs || !organizations) {
    return;
  }

  const filteredOrganizations = organizations.filter(org =>
    searchTerm
      ? org.name.toLowerCase().includes(searchTerm.toLowerCase())
      : true
  );

  setItems(filteredOrganizations);
  setLoaded(true);
};
