import React from 'react';
import PropTypes from 'prop-types';
import { Input, RadioButtonGroup } from '@agconnections/grow-ui';
import GrowUIFormField from 'components/Forms/GrowUIFormField';

const PropertiesModalBody = ({
  property,
  readonly,
  onAreaReportedChange,
  onAreaAppliedChange,
  onZoneCoveragePercentChange
}) => {
  const displayEmptyCell = value => {
    if (!value || value === 0) {
      return ' ';
      // eslint-disable-next-line no-else-return
    } else {
      return `${value} ac`;
    }
  };
  return (
    <div className="ml-6 mb-24">
      <div className="mb-6">
        <div className="text-xl font-bold">{property?.fieldName}</div>
        <span className="text-sm text-neutral-300">{property?.name}</span>
      </div>
      <div className="flex mb-6">
        <div className="mr-6">
          <span className="text-sm text-neutral-300">Area</span>
          <div className="text-xl">{property?.originalAcres} ac</div>
        </div>
        <div>
          <span className="text-sm text-neutral-300">Crop</span>
          <div className="text-xl ">{property?.crop?.name}</div>
        </div>
      </div>
      <div className="mb-12">
        <GrowUIFormField
          control={RadioButtonGroup}
          name="areaReported"
          selected={property?.areaType}
          onChange={onAreaReportedChange}
          disabled={readonly}
        >
          <GrowUIFormField
            value="reported"
            id="reported"
            name="areaReported"
            control={RadioButtonGroup.RadioButton}
            disabled={readonly}
          >
            <span className="truncate w-48">
              Reported Area ({displayEmptyCell(property?.reportedArea)})
            </span>
          </GrowUIFormField>
          <GrowUIFormField
            value="boundary"
            id="boundary"
            name="areaReported"
            control={RadioButtonGroup.RadioButton}
            disabled={readonly}
          >
            <span className="truncate w-48">
              Boundary Area ({property?.boundaryArea} ac)
            </span>
          </GrowUIFormField>
        </GrowUIFormField>
      </div>
      <div className="flex">
        <div className="mr-4">
          <GrowUIFormField
            label="Area Applied"
            control={Input}
            value={property?.areaValue}
            onBlur={onAreaAppliedChange}
            name="zoneAcres"
            imask={{
              mask: 'num',
              lazy: false,
              blocks: {
                num: {
                  mask: Number,
                  scale: 2,
                  signed: false,
                  thousandsSeparator: '',
                  padFractionalZeros: false,
                  normalizeZeros: true,
                  radix: '.',
                  mapToRadix: [','],
                  min: 0
                }
              }
            }}
            disabled={readonly}
          />
        </div>
        <GrowUIFormField
          label="Coverage %"
          control={Input}
          name="zoneCoveragePercent"
          value={property?.coveragePct}
          onBlur={onZoneCoveragePercentChange}
          imask={{
            mask: 'num',
            lazy: false,
            blocks: {
              num: {
                mask: Number,
                signed: false,
                thousandsSeparator: '',
                padFractionalZeros: false,
                normalizeZeros: false,
                radix: '.',
                mapToRadix: [','],
                min: 0
              }
            }
          }}
          disabled={readonly}
        />
      </div>
    </div>
  );
};

PropertiesModalBody.defaultProps = {
  readonly: false,
  onAreaReportedChange: () => {},
  onAreaAppliedChange: () => {},
  onZoneCoveragePercentChange: () => {}
};

PropertiesModalBody.propTypes = {
  property: PropTypes.shape({
    areaType: PropTypes.oneOf(['reported', 'boundary']),
    areaValue: PropTypes.number,
    boundaryArea: PropTypes.number,
    coveragePct: PropTypes.number,
    crop: PropTypes.shape({ name: PropTypes.string }),
    fieldName: PropTypes.string,
    name: PropTypes.string,
    originalAcres: PropTypes.number,
    reportedArea: PropTypes.number
  }).isRequired,
  readonly: PropTypes.bool,
  onAreaReportedChange: PropTypes.func,
  onAreaAppliedChange: PropTypes.func,
  onZoneCoveragePercentChange: PropTypes.func
};

export default PropertiesModalBody;
