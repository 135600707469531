import React, { useContext, useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { ReportsContext } from 'screens/Reports/context/ReportsProvider';
import CollapsibleOption from 'components/CollapsibleOption';
import { Button, Search } from '@agconnections/grow-ui';
import { ArrowLeft, ArrowRight } from 'routes/icons';
import useReportMetadata, {
  ReportCategories,
  ReportSubTypes,
  getReport,
  getReportByName,
  getReportsByCategory
} from 'screens/Reports/hooks/useReportMetadata';

const buttonStyle = {
  position: 'fixed',
  border: '1px solid #0092E4',
  borderRadius: '50%',
  height: '24px',
  width: '24px'
};

const SideMenu = ({ setActiveReport: setActiveReportState, onChange }) => {
  const {
    toggleNavbar,
    setActiveReport,
    getActiveReport,
    state: { navbarOpen }
  } = useContext(ReportsContext);
  const activeReport = getActiveReport();
  const reports = useReportMetadata();

  const defaultReport = getReport(
    reports,
    ReportCategories.Properties,
    ReportSubTypes.CropLayout
  );

  const [openSideMenuClasses, setOpenSideMenu] = useState('w-0');
  const [opacity, setOpacity] = useState('opacity-0');
  const [selectedItem, setSelectedItem] = useState(defaultReport.name);

  useEffect(() => {
    setOpenSideMenu(navbarOpen ? 'w-60' : 'w-5');
    setOpacity(navbarOpen ? 'opacity-100' : 'opacity-0');
  }, [navbarOpen]);

  useEffect(() => {
    setActiveReport(defaultReport);
    setActiveReportState(defaultReport);
    setSelectedItem(defaultReport.name);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const resetSelectedItems = () => {
    setSelectedItem(defaultReport);
  };

  const handleSelectedItem = reportName => {
    const selectedReport = getReportByName(reports, reportName);
    if (
      activeReport.category === selectedReport.category &&
      activeReport.subType === selectedReport.subType
    ) {
      return;
    }
    onChange();
    resetSelectedItems();
    setSelectedItem(reportName);
    setActiveReport(selectedReport);
    setActiveReportState(selectedReport);
  };

  const reportsByCategories = reports.reduce((accum, report) => {
    if (!accum[report.category]) {
      return {
        ...accum,
        [report.category]: getReportsByCategory(
          reports,
          report.category
        ).filter(_report => _report.featureFlag)
      };
    }
    return accum;
  }, {});

  const reportMenuOptions = Object.keys(reportsByCategories)
    .filter(category => reportsByCategories[category].length)
    .sort((a, b) => a.localeCompare(b))
    .map(category => (
      <CollapsibleOption
        key={category}
        title={category}
        options={reportsByCategories[category].map(report => ({
          key: report.name,
          label: report.subType.toUpperCase()
        }))}
        selectedIdx={selectedItem}
        handleSelectedItem={id => handleSelectedItem(id)}
        showOptions
      />
    ));

  const arrowIcon = useMemo(() => (navbarOpen ? ArrowLeft : ArrowRight), [
    navbarOpen
  ]);

  return (
    <div
      className={`${openSideMenuClasses} sticky top-0 transition-width duration-200 overflow-x-hidden overflow-y-auto`}
      style={{
        backgroundColor: '#363948'
      }}
      data-testid="sidemenu-container"
    >
      <div className="w-full h-12 relative">
        <div className="absolute top-3 right-3">
          <Button icon={arrowIcon} style={buttonStyle} onClick={toggleNavbar} />
        </div>
      </div>
      <div
        className={`transition-opacity duration-200 w-60 ${opacity}`}
        data-testid="sidemenu-content"
      >
        <h3 className="text-xl text-center mt-3 text-white">
          Reports Navigation
        </h3>
        <div className="mr-5 ml-5 mt-5" style={{ display: 'none' }}>
          <Search placeholder="Search" />
        </div>
        <div className="mt-8">{reportMenuOptions}</div>
      </div>
    </div>
  );
};

SideMenu.propTypes = {
  setActiveReport: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired
};

export default SideMenu;
