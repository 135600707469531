import React, { useCallback, useEffect, useState } from 'react';
import useJohnDeereData from 'screens/Integrations/hooks/useJohnDeereData';
import { Button } from 'syngenta-digital-cropwise-react-ui-kit';
import ResolvePackageWeightDrawer from './components/ResolvePackageWeightDrawer';
import ToastPackageWeight from './components/ToastPackageWeight';
import { TOAST_STATES } from './constants';

const CheckPackageWeight = () => {
  const [toast, setToast] = useState({
    isVisible: false,
    state: null
  });

  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [hasProductsWithoutWeight, setHasProductsWithoutWeight] = useState(
    false
  );

  const {
    isLoadingWeight,
    loadProductsMissingWeight,
    productsWithoutWeight
  } = useJohnDeereData();

  const onOpen = () => setIsDrawerOpen(true);
  const onClose = () => setIsDrawerOpen(false);

  const onFinish = useCallback(() => {
    setToast({
      state: TOAST_STATES.success,
      isVisible: true
    });
    onClose();
    setHasProductsWithoutWeight(false);
    loadProductsMissingWeight();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    loadProductsMissingWeight();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!isLoadingWeight && productsWithoutWeight.length > 0) {
      setHasProductsWithoutWeight(true);
      setIsDrawerOpen(true);
    }
  }, [isLoadingWeight, productsWithoutWeight]);

  return (
    <>
      {hasProductsWithoutWeight && (
        <>
          <div
            data-testid="package-weight-button"
            className="pt-6 px-6 flex flex-col"
          >
            <div className="pb-4" style={{ borderBottom: '1px solid #DFE2E7' }}>
              <span className="text-base">Package Weight</span>
              <p className="text-normal mb-20px">
                Define package weight for certain seed products
              </p>
              <Button
                className="bg-white"
                onClick={onOpen}
                data-testid="add-product-package-weight"
              >
                Add Package Weight
              </Button>
            </div>
          </div>

          <ResolvePackageWeightDrawer
            showDrawer={onOpen}
            open={isDrawerOpen}
            products={productsWithoutWeight}
            onClose={onClose}
            onFinish={onFinish}
          />
        </>
      )}

      <ToastPackageWeight
        showToast={toast.isVisible}
        setShowToast={isVisible => setToast(prev => ({ ...prev, isVisible }))}
        state={toast.state}
        setIsDrawerOpen={setIsDrawerOpen}
      />
    </>
  );
};

export default CheckPackageWeight;
