import React from 'react';
import PropTypes from 'prop-types';
import { Button } from '@agconnections/grow-ui';
import { condensor } from 'helpers/condensor';
import AddIcon from '@material-ui/icons/Add';

const mapProduct = product => ({
  ...product,
  manufacturer: product.manufacturer,
  producttype: product.producttype || product.type,
  stdunit: product.stdunit || product.standardUnit,
  registrationnumber: product.registrationnumber || ''
});

const ProductCard = React.forwardRef(
  ({ addProduct, product, isFirst }, ref) => {
    const mappedProduct = mapProduct(product);

    const productCardBody = (
      <div className={`border ${isFirst ? 'mt-3' : 'border-t-0'} p-5`}>
        <div className="flex justify-between">
          <div className="truncate text-lg font-bold">{mappedProduct.name}</div>
          <Button
            id="add-product-button"
            circular
            type="primary"
            size="sm"
            icon={<AddIcon style={{ fontSize: 16 }} />}
            onClick={() => addProduct(mappedProduct)}
          />
        </div>

        <div className="truncate text-sm text-neutral-300">
          {mappedProduct.manufacturer}
        </div>
        <div className="truncate text-sm text-neutral-300">
          {mappedProduct.producttype}
        </div>
        <div className="truncate text-sm text-neutral-300">
          {mappedProduct.stdunit}
        </div>
        <div className="truncate text-sm text-neutral-300">
          {condensor(mappedProduct.registrationnumber)}
        </div>
      </div>
    );

    const productCardContent = ref ? (
      <div ref={ref}>{productCardBody}</div>
    ) : (
      <div>{productCardBody}</div>
    );

    return productCardContent;
  }
);

ProductCard.propTypes = {
  addProduct: PropTypes.func.isRequired,
  product: PropTypes.shape({
    name: PropTypes.string.isRequired,
    manufacturer: PropTypes.string.isRequired,
    producttype: PropTypes.string.isRequired,
    stdunit: PropTypes.string.isRequired,
    registrationnumber: PropTypes.string.isRequired
  }).isRequired,
  isFirst: PropTypes.bool
};

ProductCard.defaultProps = {
  isFirst: false
};

export default ProductCard;
