import React, { useContext, useState } from 'react';
import PropTypes from 'prop-types';
import PortalModal from 'components/Modals/PortalModal';
import { withRouter } from 'react-router-dom';
import catchCancel from 'helpers/catchCancel';
import { AmplitudeContext } from 'utilities/amplitude/useAmplitude';
import useFieldData from '../../hooks/useFieldData';

const ArchiveFieldModal = ({ open, close, fieldId, onSuccess, onError }) => {
  const { archiveField, field } = useFieldData(fieldId);
  const [isSaving, setIsSaving] = useState(false);
  const amplitude = useContext(AmplitudeContext);
  const handleArchive = async () => {
    amplitude.sendEventToAmplitude(
      amplitude.events.epic.Properties.archiveField
    );
    setIsSaving(true);
    const promise = archiveField(fieldId);
    await promise
      .then(() => {
        setIsSaving(false);
        close();
        onSuccess(field?.name);
      })
      .catch(catchCancel)
      .catch(() => {
        setIsSaving(false);
        close();
        onError(field?.name);
      });
  };

  return (
    <PortalModal
      data-testid="archive-field-modal"
      open={open}
      title={`Archive ${field?.name}?`}
      close={close}
      cancelLabel="Cancel"
      confirmLabel="Archive field"
      onCancel={() => close()}
      type="warningConfirmation"
      onConfirm={() => handleArchive()}
      isSaving={isSaving}
    >
      <div className="pr-6">
        This field will be archived along with all associated crop zones. You
        can unarchive this field later. Do you want to continue?
      </div>
    </PortalModal>
  );
};

ArchiveFieldModal.propTypes = {
  open: PropTypes.bool.isRequired,
  close: PropTypes.func.isRequired,
  fieldId: PropTypes.string.isRequired,
  onSuccess: PropTypes.func.isRequired,
  onError: PropTypes.func.isRequired
};

export default withRouter(ArchiveFieldModal);
