import React, { useState, useEffect, useContext } from 'react';
import PropTypes from 'prop-types';

import { Popup, Spinner } from '@agconnections/grow-ui';
import { generate } from 'shortid';
import { Input } from 'syngenta-digital-cropwise-react-ui-kit';
import { populateDropDown } from 'components/Navigation/helpers/helpers';
import SearchIcon from 'assets/search.svg';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { AmplitudeContext } from 'utilities/amplitude/useAmplitude';
import { Context } from 'components/Store';
import BreadCrumbItem from '../../../App/AppShell/components/Breadcrumb/BreadCrumbItem';
import BreadcrumbDropDownItem from './components/BreadcrumbDropDownItem';

const BreadcrumbDropDown = ({
  dropDownFooter,
  dropDownItemProps,
  getData,
  organizations,
  isLast,
  onSelect,
  searchPlaceholder,
  title,
  value,
  loadedOrgs
}) => {
  const { sendEventToAmplitude, events } = useContext(AmplitudeContext);
  const [{ isSidebarOpen }] = useContext(Context);
  const [menuOpened, setMenuOpen] = useState(false);
  const [items, setItems] = useState([]);
  const [loaded, setLoaded] = useState(false);
  const [searchText, setSearchText] = useState(null);
  /// /////////////////////////////////////////////////
  /// /////TO BE ADDED LATER WHEN ORGS GET FIX/////////
  /// /////////////////////////////////////////////////
  const { releaseFe50Orgs } = useFlags();
  const pageNo = 0;
  const limit = 1000;

  /// /////////////////////////////////////////////////
  /// /////TO BE ADDED LATER WHEN ORGS GET FIX/////////
  /// /////////////////////////////////////////////////

  useEffect(() => {
    if (!menuOpened) return;
    /**
     * everytime the user opens the dropdown menu it runs a new request
     * for the refactor to node API, maybe we could do also a refactor on frontend to have some cache on this
     * to avoid recalling the same data all the time
     */
    if (!releaseFe50Orgs) {
      getData(0, 50);
      return;
    }
    getData(pageNo, limit);
    /// /////////////////////////////////////////////////
    /// /////TO BE ADDED LATER WHEN ORGS GET FIX/////////
    /// /////////////////////////////////////////////////
    // remove after release
    // if (!releaseFe50Orgs) {
    //   getData(pageNo, 50);
    //   return;
    // }
    // getData(pageNo, limit);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [menuOpened]);

  useEffect(() => {
    populateDropDown(
      menuOpened,
      loadedOrgs,
      organizations,
      searchText,
      setLoaded,
      setItems
    );
  }, [menuOpened, loadedOrgs, organizations, searchText]);

  useEffect(() => {
    if (!menuOpened) return;
    if (organizations?.length)
      sendEventToAmplitude(events.epic.Organization.orgLoadingCount, {
        orgsLoading: organizations.length
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [organizations, menuOpened]);

  const closeMenu = () => {
    setMenuOpen(false);
    setSearchText(null);
  };

  const setItemState = item => {
    onSelect(item);
    closeMenu();
  };
  const Trigger = (
    <div
      data-testid="nav-menu-head"
      className="flex items-center flex-shrink-0 focus:border-none text-white"
      role="button"
      tabIndex={0}
      onClick={() => {
        setMenuOpen(!menuOpened);
        setSearchText(null);
      }}
      onKeyDown={event =>
        event.nativeEvent.key === 'Enter' &&
        setMenuOpen(!menuOpened) &&
        setSearchText(null)
      }
    >
      <div>
        <BreadCrumbItem title={title} value={value} isLast={isLast} />
      </div>
    </div>
  );
  return (
    <Popup
      trigger={Trigger}
      position="bottom"
      align="left"
      hasOffset
      open={menuOpened}
      onClose={closeMenu}
      footer={
        dropDownFooter && [
          <div
            key={generate()}
            className="w-full text-left bg-white rounded-b px-1 pt-2 pb-1 border-t border-neutral-200"
          >
            {dropDownFooter({
              onClick: () => {
                closeMenu();
              }
            })}
          </div>
        ]
      }
    >
      <div
        data-testid="breadcrumb-popup"
        className="h-490px w-max text-left mt-2"
      >
        <span className="text-sm font-semibold">Organizations</span>
        <div className="relative w-full text-gray-400 focus-within:text-gray-600 mr-4 my-2">
          <Input
            data-testid="search-input"
            style={{ marginTop: '8px' }}
            suffix={<img alt="Search icon" src={SearchIcon} />}
            allowClear
            type="default"
            autoComplete="off"
            placeholder={searchPlaceholder}
            onChange={event => {
              setSearchText(event.target.value);
            }}
            value={searchText}
          />
        </div>
        <ul
          className="font-normal text-gray-700 h-420px overflow-y-scroll -mx-2 border-t min-w-430px w-max"
          style={{
            maxWidth: `calc(100vw - ${isSidebarOpen ? '400px' : '200px'})`
          }}
        >
          {loaded ? (
            items.map(item => (
              <BreadcrumbDropDownItem
                key={generate()}
                onSelect={() => setItemState(item)}
                name={dropDownItemProps.name(item)}
                isActive={dropDownItemProps.isActive(item)}
              />
            ))
          ) : (
            <li className="w-full min-w-430px h-full flex flex-col items-center justify-center">
              <div style={{ marginTop: '-60px' }}>
                <Spinner size="lg" />
                <span className="font-semibold text-xs">
                  Loading your list of organizations
                </span>
              </div>
            </li>
          )}
        </ul>
      </div>
    </Popup>
  );
};

BreadcrumbDropDown.defaultProps = {
  dropDownFooter: undefined,
  searchPlaceholder: 'Search',
  isLast: false
};

BreadcrumbDropDown.propTypes = {
  dropDownFooter: PropTypes.func,
  dropDownItemProps: PropTypes.shape({
    name: PropTypes.func.isRequired,
    isActive: PropTypes.func.isRequired
  }).isRequired,
  getData: PropTypes.func.isRequired,
  isLast: PropTypes.bool,
  onSelect: PropTypes.func.isRequired,
  organizations: PropTypes.shape().isRequired,
  loadedOrgs: PropTypes.bool.isRequired,
  searchPlaceholder: PropTypes.string,
  title: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired
};

export default BreadcrumbDropDown;
