import React from 'react';
import PropTypes from 'prop-types';
import { customDateFormat } from 'helpers/dateFormat';
import { Table } from '@agconnections/grow-ui';
import Status from './components/Status';
import Properties from './components/Properties';
import CropSeasonsTags from './components/CropSeasonsTags';
import ViewLink from './components/ViewLink';

const TableRow = ({ submission }) => {
  return (
    <Table.Row>
      <Table.Cell>
        <div className="flex w-full justify-start">
          {customDateFormat(submission.date, 'MMM dd, yyyy h:mm aaaa')}
        </div>
      </Table.Cell>
      <Table.Cell>
        <div className="flex w-auto justify-start">
          <Status type={submission.status} />
        </div>
      </Table.Cell>
      <Table.Cell>
        <div className="text-left">{submission.taskCount}</div>
      </Table.Cell>
      <Table.Cell>
        <CropSeasonsTags cropSeasons={submission.cropSeasons} />
      </Table.Cell>
      <Table.Cell>
        <Properties
          propertiesArea={submission.propertiesArea}
          propertiesCount={submission.propertiesCount}
          propertiesNames={submission.propertiesNames}
        />
      </Table.Cell>
      <Table.Cell>
        <ViewLink id={submission.id} />
      </Table.Cell>
    </Table.Row>
  );
};

TableRow.propTypes = {
  submission: PropTypes.shape({
    id: PropTypes.string,
    propertiesNames: PropTypes.arrayOf(PropTypes.string),
    propertiesArea: PropTypes.number,
    propertiesCount: PropTypes.number,
    taskCount: PropTypes.number,
    cropSeasons: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.string.isRequired,
        name: PropTypes.string.isRequired
      })
    ),
    status: PropTypes.oneOf(['error', 'pending', 'in_progress', 'done']),
    date: PropTypes.instanceOf(Date)
  }).isRequired
};

export default TableRow;
