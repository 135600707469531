import { runInContext, isString } from 'lodash';

// when a resource id callback is provided upfront, get the id and add it to the url
const parseResourceCallback = (accum, resource, idCallback) =>
  `${accum}/${resource}/${idCallback()}`;

// create a composer chaining context with the 3 url appender functions mixed in so they can be chained too
const urlComposerChain = runInContext();
urlComposerChain.mixin({
  // append the resource name to the url when not suppressed
  appendUrlResourceName: (url, resourceName, suppressResourceName) =>
    suppressResourceName ? url : `${url}/${resourceName}`,
  // append the action to the the url when specified
  appendUrlAction: (url, action) => (action ? `${url}/${action}` : url),
  // append the id to the url when specified
  appendUrlId: (url, id) => (id ? `${url}/${id}` : url)
});

// compose the url build chain function
const composeUrlBuildFunction = (
  urlPrefix,
  resourceName,
  suppressResourceName,
  action
) => idArg => {
  // the url composer function should be called with the id of the resource, or null if there is no id
  return urlComposerChain
    .chain(urlPrefix)
    .appendUrlResourceName(resourceName, suppressResourceName)
    .appendUrlAction(action)
    .appendUrlId(idArg)
    .value();
};

// iterate over the parent resources array and add the url segments for each
const parseAllUrlResources = (resources, apiRootUrl) =>
  resources.reduce(
    (accumUrl, { resource, idCallback }) =>
      parseResourceCallback(accumUrl, resource, idCallback),
    apiRootUrl
  );

// combine the parent biulder options with the new child options and return the result
const coalesceOptions = (parentOptions, childOptions) => {
  const parentResources = [
    ...(parentOptions.parentResources || []),
    ...(childOptions.parentResources || [])
  ].map(resource => (isString(resource) ? { resource } : resource));
  const action = childOptions.action || parentOptions.action;
  const suppressResourceName =
    !!action ||
    childOptions.suppressResourceName ||
    parentOptions.suppressResourceName;
  return {
    parentResources,
    action,
    suppressResourceName
  };
};

const createBuilder = (resourceName, apiRootUrl, parentOptions = {}) => (
  options = {}
) => {
  const { parentResources, action, suppressResourceName } = coalesceOptions(
    parentOptions,
    options
  );

  const urlWithParentResources = parseAllUrlResources(
    parentResources,
    apiRootUrl
  );
  const builder = composeUrlBuildFunction(
    urlWithParentResources,
    resourceName,
    suppressResourceName,
    action
  );
  builder.createUrlBuilder = createBuilder(resourceName, apiRootUrl, {
    parentResources,
    action,
    suppressResourceName
  });
  return builder;
};

/**
 * given the resource, the api root url, and optionally some parent resources and/or an api action name,
 * this function can build the url needed for all the requests made by the api functions
 * @param {String} resourceName
 * @param {String} apiRootUrl
 * @param {Array} parentResources
 * @param {String} action
 * @param {Boolean} suppressResourceName
 * @returns {Function}
 */
const urlBuilder = (
  resourceName,
  apiRootUrl,
  { parentResources, action, suppressResourceName } = {}
) =>
  createBuilder(resourceName, apiRootUrl, {
    parentResources,
    action,
    suppressResourceName
  })();

export default urlBuilder;
