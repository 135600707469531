import React, { useContext, useState } from 'react';
import { Formik, Field } from 'formik';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';
import catchCancel from 'helpers/catchCancel';
import { parseServerError } from 'helpers/errorHelpers';
import { SimpleModal, Input, Toast } from '@agconnections/grow-ui';
// import { InputField } from 'components/EditModal';
import { organization as organizationApi } from 'utilities/api';
import GrowUIFormField from 'components/Forms/GrowUIFormField';
import { Context } from 'components/Store';
import { ROLES } from 'utilities/access';
import { paths } from 'routes/paths';
import { getMemberRole, getFinancialAccess } from './helpers';

import UserRole from './components/UserRole';
import FinancialInformation from './components/FinancialInformation';
import schema from './schema';

const inviteApi = organizationApi.createChildApi({
  action: 'organization/member/invite'
});

const memberApi = organizationApi.createChildApi({
  action: 'organization/member'
});

const InviteModal = ({
  data,
  open,
  onClose,
  getOrganization,
  singleMember
}) => {
  const [, dispatch] = useContext(Context);
  const [isSaving, setIsSaving] = useState(false);
  const [error, setError] = useState('');
  const history = useHistory();

  let redirectURI = '';

  switch (process.env.REACT_APP_ENVIRONMENT) {
    case 'prod':
      redirectURI = process.env.REACT_APP_ORG_INVITE_REDIRECT_PROD_URL;
      break;
    case 'staging':
      redirectURI = process.env.REACT_APP_ORG_INVITE_REDIRECT_STAGING_URL;
      break;
    case 'dev':
      redirectURI = process.env.REACT_APP_ORG_INVITE_REDIRECT_DEV_URL;
      break;
    case 'local':
      redirectURI = process.env.REACT_APP_ORG_INVITE_REDIRECT_DEV_URL;
      break;
    case 'qa':
      redirectURI = process.env.REACT_APP_ORG_INVITE_REDIRECT_QA_URL;
      break;
    default:
      redirectURI = process.env.REACT_APP_ORG_INVITE_REDIRECT_PROD_URL;
  }

  const handleInviteSend = async formData => {
    setIsSaving(true);
    const { promise } = await inviteApi.create(formData);
    promise
      .then(() => {
        getOrganization();
        setIsSaving(false);
        onClose();
      })
      .catch(catchCancel)
      .catch(err => {
        if (
          err.response.data.message.includes(
            'Member already present in given org'
          ) &&
          err.response.status === 500
        ) {
          setError(
            'The email address you are trying to use is already associated with a member of your organization.'
          );
          setIsSaving(false);
        } else {
          parseServerError(dispatch)(err);
          setIsSaving(false);
        }
      });
  };

  const handleMemberEditSuccess = (role, financialAccess) => {
    getOrganization();
    setIsSaving(false);
    onClose();
    if (role === 'ViewOnly') {
      history.push(paths.properties);
      dispatch({
        type: 'SELECT_MEMBER',
        payload: {
          role: ROLES.VIEW_ONLY,
          financialAccess
        }
      });
    }
  };

  const getMemberEditBody = formData => {
    return {
      accountId: formData.accountId,
      name: `${formData.firstName} ${formData.lastName}`,
      financialAccess: formData.financialAccess,
      role: formData.role
    };
  };

  const handleMemberEdit = async formData => {
    const body = getMemberEditBody(formData);
    setIsSaving(true);
    const { promise } = memberApi.update(null, body);
    promise
      .then(() => {
        handleMemberEditSuccess(formData.role, formData.financialAccess);
      })
      .catch(catchCancel)
      .catch(err => {
        parseServerError(dispatch)(err);
        setIsSaving(false);
      });
  };
  const onSubmit = formData => {
    if (formData.accountId) {
      const obj = formData;
      delete obj.email;
      handleMemberEdit(obj);
    } else {
      const obj = formData;
      delete obj.accountId;
      if (!formData.financialAccess) {
        setError('Please select a level of Financial Information Access.');
      } else {
        handleInviteSend({
          name: `${formData.firstName} ${formData.lastName}`,
          email: formData.email,
          role: formData.role,
          clientId: process.env.REACT_APP_CLIENT_ID,
          redirectUri: redirectURI,
          financialAccess: formData.financialAccess
        });
      }
    }
  };

  const getFirstName = name => {
    const nameArr = name?.split(' ');
    if (nameArr?.length > 0) {
      if (nameArr[0]?.length > 0) return nameArr[0];
    }
    return '';
  };

  const getLastName = name => {
    const nameArr = name?.split(' ');
    if (nameArr?.length > 0) {
      if (nameArr[1]?.length > 0) return nameArr[1];
    }
    return '';
  };
  // removed comment
  const getHeader = () => {
    if (data?.accountId?.length > 0 && data?.memberStatus === 'Revoked')
      return 'Grant Access';
    if (data?.accountId === undefined && data?.memberStatus === 'Revoked')
      return 'Resend Invite';
    return 'Invite Member';
  };

  return (
    <Formik
      initialValues={{
        firstName: data?.firstName || getFirstName(data?.name) || '',
        lastName: data?.lastName || getLastName(data?.name),
        email: data?.email || '',
        financialAccess: getFinancialAccess(data, singleMember) || '',
        role: getMemberRole(data?.role, singleMember) || 'Admin',
        accountId: data?.accountId || ''
      }}
      enableReinitialize
      validationSchema={schema}
      onSubmit={onSubmit}
    >
      {({ handleSubmit, resetForm }) => {
        return (
          <>
            <SimpleModal
              cancelLabel="Cancel"
              onConfirm={handleSubmit}
              confirmLabel="Save"
              open={open}
              close={() => {
                onClose();
                resetForm();
                setError('');
              }}
              title={getHeader()}
              isSaving={isSaving}
            >
              {error && (
                <Toast
                  icon="error"
                  duration={7000}
                  onClose={() => setError('')}
                >
                  {error}
                </Toast>
              )}
              <div data-testid="inviteModal" className="pr-6 mt-8">
                <>
                  <div className="grid grid-cols-2 gap-6 mb-12">
                    <GrowUIFormField
                      control={Input}
                      label="First Name"
                      name="firstName"
                    />
                    <GrowUIFormField
                      control={Input}
                      label="Last Name"
                      name="lastName"
                    />
                  </div>
                  <div className="mb-8">
                    <GrowUIFormField
                      control={Input}
                      label="Email"
                      name="email"
                      disabled={data?.email}
                      imask={{
                        mask: /^[ A-Za-z0-9_@./#-]*$/
                      }}
                    />
                    <div
                      className={`text-neutral-600 text-base mt-2 ${
                        data?.email ? '' : 'hidden'
                      }`}
                    >
                      In order to change email address, you must request a
                      <span
                        // style={{
                        //   textDecoration: 'underline'
                        // }}
                        className="text-neutral-1000"
                      >
                        {' '}
                        new invitation.
                      </span>
                    </div>
                  </div>
                  <Field
                    name="role"
                    component={UserRole}
                    singleMember={singleMember}
                  />
                  <Field
                    name="financialAccess"
                    component={FinancialInformation}
                    singleMember={singleMember}
                  />
                </>
              </div>
            </SimpleModal>
          </>
        );
      }}
    </Formik>
  );
};

InviteModal.defaultProps = {
  data: null,
  open: false,
  onClose: () => {},
  getOrganization: () => {},
  singleMember: {}
};

InviteModal.propTypes = {
  data: PropTypes.objectOf(PropTypes.any),
  open: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
  onClose: PropTypes.func,
  getOrganization: PropTypes.func,
  singleMember: PropTypes.instanceOf(Object)
};

export default InviteModal;
