import React, { Fragment } from 'react';
import PropTypes from 'prop-types';

import StepCard from './StepCard';

const ProgressBar = ({
  stepArray,
  currentStep,
  canGoNext,
  gotoStep,
  style,
  className
}) => {
  return (
    <div
      className={`flex w-full px-4 mb-5 mt-5 ${className}`}
      id="integration-proggress-bar"
      style={style}
    >
      {stepArray.length > 0 &&
        stepArray.map((step, i) => {
          return (
            <Fragment key={step.caption}>
              <div className="w-auto">
                <StepCard
                  stepNumber={i + 1}
                  caption={step.caption}
                  currentStep={currentStep}
                  canGoNext={canGoNext}
                  gotoStep={gotoStep}
                />
              </div>
              {stepArray.length !== i + 1 ? (
                <div className="h-px w-full my-auto mx-2 bg-gray-500 " />
              ) : null}
            </Fragment>
          );
        })}
    </div>
  );
};

ProgressBar.propTypes = {
  stepArray: PropTypes.arrayOf(PropTypes.object),
  currentStep: PropTypes.number,
  canGoNext: PropTypes.bool,
  gotoStep: PropTypes.func.isRequired,
  style: PropTypes.shape({}),
  className: PropTypes.string
};

ProgressBar.defaultProps = {
  stepArray: [],
  currentStep: 1,
  canGoNext: () => false,
  style: {},
  className: ''
};

export default ProgressBar;
