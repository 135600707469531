import { isNil, isString } from 'lodash';

// change key's version suffix when any policy for that storage key changes
export const STORAGE_KEYS = {
  PLANS_VIEW: 'PLANS_VIEW_v1',
  CROPSEASON_VIEW: 'CROPSEASON_VIEW_v1',
  ORGANIZATION_VIEW: 'ORGANIZATION_VIEW_v1',
  DEFAULT_ORG: 'DEFAULT_ORG_v1',
  PRODUCT_VIEW: 'PRODUCT_VIEW_v1',
  INVENTORY_VIEW: 'INVENTORY_VIEW_v1',
  TASKS_VIEW: 'TASKS_VIEW_v1',
  RECOMMENDATIONS_VIEW: 'RECOMMENDATIONS_VIEW_v1',
  INVOICES_VIEW: 'INVOICES_VIEW_v1',
  PROPERTY_VIEW: 'PROPERTY_VIEW_v1',
  PROPERTY_VIEW_V2: 'PROPERTY_VIEW_v2',
  SUBMISSION_TASK_REDIRECT: 'SUBMISSION_TASK_REDIRECT',
  SUBMISSION_EDIT_REDIRECT: 'SUBMISSION_EDIT_REDIRECT'
};

// return JSON parse or raw string value
const parseStringValue = value =>
  value && value.substr(0, 1) === '{' ? JSON.parse(value) : value;

// get the value as null or a string
const getStorageValue = value =>
  value && value !== 'null' ? parseStringValue(value) : null;

// stringify the value if not already a string
const setStringValue = value =>
  isString(value) ? value : JSON.stringify(value);

// set the value to null or to a string
const setStorageValue = value => (isNil(value) ? null : setStringValue(value));

// save the value to storage
const save = key => value => {
  localStorage.setItem(key, setStorageValue(value));
  return value;
};

// if the storage key exists return the value, otherwise return the default
const get = (key, defaultValue = undefined) => () =>
  (localStorage.hasOwnProperty(key) &&
    getStorageValue(localStorage.getItem(key))) ||
  defaultValue;

// if the storage key exists remove the item
const remove = key => () =>
  localStorage.hasOwnProperty(key) && localStorage.removeItem(key);

// returns an api to use save/get/remove with the specified storage key
export const getLocalStorageApi = (key, defaultValue) => ({
  save: save(key),
  get: get(key, defaultValue),
  remove: remove(key)
});
