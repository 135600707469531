import React, { useContext, useEffect, useState } from 'react';
import { INTEGRATIONS } from 'screens/Integrations/helpers/constants';
import extractCropZoneIds from 'screens/Integrations/helpers/extractCropZoneIds';
import useSubmissionAnalytics from 'screens/Integrations/hooks/useSubmissionAnalytics';
import { SimplotContext } from '../../context/Provider';
import PropertyIntegrationBase from '../../../components/PropertyIntegration';
import { SubmissionSummaryContext } from '../../../SubmissionSummary/context/SubmissionSummaryProvider';
import useFormikHandler from '../../../hooks/useFormikHandler';

const SimplotPropertiesSelection = () => {
  const { events, triggerAnalyticsEvent } = useSubmissionAnalytics();
  const { goForward } = useContext(SimplotContext);
  const { updateSubmissionFilterCropZoneIds } = useContext(
    SubmissionSummaryContext
  );
  const [keepCurrentStep, setKeepCurrentStep] = useState(true);
  const { selectedProperties } = useFormikHandler();

  useEffect(() => {
    setKeepCurrentStep(!keepCurrentStep);
    if (selectedProperties && !keepCurrentStep) {
      const cropZoneIds = extractCropZoneIds(selectedProperties);
      updateSubmissionFilterCropZoneIds(cropZoneIds);
      triggerAnalyticsEvent(
        events.epic.Integrations.submissionPropertiesSelected,
        {
          vendor: INTEGRATIONS.simplot,
          cropZonesLength: cropZoneIds.length
        }
      );
      goForward();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedProperties]);

  return <PropertyIntegrationBase integrationName={INTEGRATIONS.simplot} />;
};

export default SimplotPropertiesSelection;
