import React from 'react';
import PropTypes from 'prop-types';
import PortalModal from 'components/Modals/PortalModal';

const CropChangeConfirmation = ({ open, onCancel, onConfirm }) => {
  const handleConfirm = () => {
    onConfirm();
    onCancel();
  };

  return (
    <PortalModal
      open={open}
      title="Change Crop Selection?"
      close={onCancel}
      cancelLabel="Cancel"
      confirmLabel="Continue"
      onCancel={onCancel}
      type="error"
      onConfirm={handleConfirm}
    >
      <div className="pr-6">
        <div className="pb-4">
          Only one crop type can be used per submission. Changing the crop
          selection will delete <b>all data</b> from this submission, including
          any loads on the loads list.
        </div>
        <div>Are you sure you want to continue?</div>
      </div>
    </PortalModal>
  );
};

CropChangeConfirmation.defaultProps = {
  open: false
};

CropChangeConfirmation.propTypes = {
  open: PropTypes.bool,
  onCancel: PropTypes.func.isRequired,
  onConfirm: PropTypes.func.isRequired
};

export default CropChangeConfirmation;
