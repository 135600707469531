import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { GeoJSONLayer } from 'react-mapbox-gl';

import { Context } from 'components/Store';
import { getCentroidGeoJSON } from 'screens/Property/helpers/mapApiHelpers';
import { PROPERTY_MAP_FIELDS_OPTIONS } from 'screens/Property/helpers/constants';
import {
  conditionLayerSelected,
  conditionLayerCustom,
  customSymbolPaint,
  symbolLayoutTemplate
} from '../../../PropertyMap/utilities/mapboxLayerHelpers';

const DynamicLayer = ({
  geoJSON,
  onClickShape,
  labelKey,
  isGeoJSONLabelEnabled
}) => {
  const [
    { isExportPDFMapsActive, exportPDFMapsStep, propertyMapFieldsConfig }
  ] = useContext(Context);
  const isExportPreview = isExportPDFMapsActive && exportPDFMapsStep === 1;
  if (
    isExportPreview &&
    propertyMapFieldsConfig === PROPERTY_MAP_FIELDS_OPTIONS.HIDE
  ) {
    return null;
  }

  const centroidGeoJSON = getCentroidGeoJSON(geoJSON);

  let defaultBackgroundColor = 'rgba(255, 255, 255, 0.3)';
  let defaultBorderColor = '#FFFFFF';
  let symbolLayout = {};
  let symbolPaint = {};
  const showSymbolLayer =
    isGeoJSONLabelEnabled &&
    !(
      isExportPreview &&
      propertyMapFieldsConfig === PROPERTY_MAP_FIELDS_OPTIONS.SHOW_BOUNDARIES
    );
  if (showSymbolLayer) {
    symbolLayout = {
      'text-field': ['case', conditionLayerCustom, '', ['get', labelKey]],
      'text-anchor': 'center',
      'text-justify': 'center',
      'text-variable-anchor': ['center'],
      'text-radial-offset': 0,
      'text-size': 20,
      'text-font': ['Open Sans Semibold', 'Arial Unicode MS Bold'],
      'text-allow-overlap': true,
      'symbol-sort-key': ['get', 'sortKey']
    };
    symbolPaint = {
      'text-color': 'white'
    };
  }

  if (isExportPDFMapsActive) {
    Object.assign(symbolLayout, symbolLayoutTemplate);
    if (exportPDFMapsStep === 0) {
      symbolLayout['text-size'] = 14;
    } else {
      defaultBackgroundColor = 'rgba(134, 140, 162, 0.5)';
      defaultBorderColor = '#363948';
    }
    Object.assign(symbolPaint, customSymbolPaint);
  }

  return (
    <div
      data-testid={
        isGeoJSONLabelEnabled ? 'geojson-layer-with-labels' : 'geojson-layer'
      }
    >
      <GeoJSONLayer
        fillOnClick={onClickShape}
        data={geoJSON}
        fillPaint={{
          'fill-color': [
            'case',
            conditionLayerCustom,
            'transparent',
            conditionLayerSelected,
            'rgba(49, 180, 242, 0.05)',
            defaultBackgroundColor
          ],
          'fill-antialias': true
        }}
        fillSortKey={['get', 'sortKey']}
        linePaint={{
          'line-color': [
            'case',
            conditionLayerCustom,
            'transparent',
            defaultBorderColor
          ],
          'line-width': 2.5
        }}
        beforeId="symbol-layer"
      />

      {showSymbolLayer && (
        <GeoJSONLayer
          symbolLayout={symbolLayout}
          symbolPaint={symbolPaint}
          data={centroidGeoJSON}
        />
      )}
    </div>
  );
};

DynamicLayer.propTypes = {
  geoJSON: PropTypes.objectOf().isRequired,
  onClickShape: PropTypes.func.isRequired,
  labelKey: PropTypes.string.isRequired,
  isGeoJSONLabelEnabled: PropTypes.bool.isRequired
};

export default DynamicLayer;
