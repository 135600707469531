import React from 'react';
import { Button } from '@agconnections/grow-ui';
import { useHistory } from 'react-router-dom';
import { useFormikContext } from 'formik';
import { paths } from 'routes/paths';
import events from 'utilities/amplitude/events';
import { INTEGRATIONS } from 'screens/Integrations/helpers/constants';
import useSubmissionAnalytics from 'screens/Integrations/hooks/useSubmissionAnalytics';
import mcCainConfirmation from '../../../../../assets/mccain-confirmation.svg';

const McCainSuccessPage = () => {
  useSubmissionAnalytics({
    onMountTriggerEvent: {
      event: events.epic.Integrations.submissionCompleted,
      data: { vendor: INTEGRATIONS.mccain }
    }
  });

  const history = useHistory();
  const { values } = useFormikContext();

  return (
    <div className="flex justify-center flex-col h-full">
      <div className="flex text-center flex-col justify-center ">
        <div className="flex justify-center" style={{ marginBottom: '30px' }}>
          <img
            src={mcCainConfirmation}
            alt="McCain Confirmation"
            width="60px"
            height="60px"
          />
        </div>
        <h1
          className="text-2xl leading-8 font-semibold"
          style={{ marginBottom: '8px' }}
        >
          Your data has been submitted!
        </h1>
        <div style={{ marginBottom: '32px' }}>
          <p className="font-normal text-sm">
            Your submission to McCain vendor ID {values.mccainVendorId || ''}{' '}
            has been sent to McCain Foods Inc.
            <br />
            You can view the submitted properties via the McCain Foods Inc.
            portal.
          </p>
        </div>
        <div className="flex justify-center">
          <Button
            id="return-mainpage-button"
            type="primary"
            ghost
            onClick={() =>
              history.push({
                pathname: paths.integrations
              })
            }
          >
            Return to Integrations
          </Button>
        </div>
      </div>
    </div>
  );
};

export default McCainSuccessPage;
