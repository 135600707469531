import React from 'react';

const SaleLocationsEmptyState = () => {
  return (
    <div className="h-full w-full flex flex-row justify-center mt-8">
      <span>No sale locations found</span>
    </div>
  );
};

export default SaleLocationsEmptyState;
