import * as Yup from 'yup';

export default Yup.object().shape({
  firstName: Yup.string()
    .trim()
    .required('First name is required'),
  lastName: Yup.string()
    .trim()
    .required('Last name is required'),
  email: Yup.string()
    .email()
    .required('Email is not valid')
});
