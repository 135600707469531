import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import BuildIcon from 'assets/build-icon.svg';
import { Form, Input, Select } from '@agconnections/grow-ui';
import GrowUIFormField from 'components/Forms/GrowUIFormField';
import statesItems from 'components/AddressFields/StateSelect/data';
import formatPhoneNumber from 'screens/YieldV2/SaleLocations/helpers/formatPhoneNumber';
import { useFormikContext } from 'formik';

const ColumnWithFieldsRight = ({
  labelTitles,
  LabelInputs,
  divider,
  companies,
  isLoadingCompanies
}) => {
  const { values, setFieldValue } = useFormikContext();
  const [companyList, setCompanyList] = useState();
  const [phoneFormat, setPhoneFormat] = useState(
    formatPhoneNumber(values.phone ?? '')
  );

  useEffect(() => {
    const arrayOfCompanies = [{ value: '\u00A0', key: null }];
    // eslint-disable-next-line no-unused-expressions
    companies?.forEach(company =>
      arrayOfCompanies.push({
        value: (
          <div className="flex flex-row items-center">
            <img
              src={BuildIcon}
              alt={company.name}
              style={{ width: '24px', marginRight: '8px' }}
            />
            {company?.name}
          </div>
        ),
        key: company?.id
      })
    );
    setCompanyList(arrayOfCompanies);
  }, [companies]);
  return (
    <div className="flex flex-col w-126">
      <div className="flex flex-row">
        <Form.Field label={labelTitles.phone} labelFor={LabelInputs.phone}>
          <GrowUIFormField
            control={Input}
            id={LabelInputs.phone}
            name={LabelInputs.phone}
            value={phoneFormat}
            onChange={e => {
              setPhoneFormat(e.target.value);
            }}
            onBlur={e => {
              setPhoneFormat(formatPhoneNumber(e.target.value ?? ''));
              setFieldValue('phone', e.target.value);
            }}
          />
        </Form.Field>
        {divider('w-6')}
        <Form.Field label={labelTitles.company} labelFor={LabelInputs.company}>
          <GrowUIFormField
            control={Select}
            id={LabelInputs.company}
            name={LabelInputs.companyId}
            items={companyList}
            placeholder={isLoadingCompanies ? '' : 'Loading...'}
            type="search"
            disabled={!isLoadingCompanies}
          />
        </Form.Field>
      </div>
      <Form.Field
        label={labelTitles.addressLineTwo}
        labelFor={LabelInputs.addressLineTwo}
      >
        <GrowUIFormField
          control={Input}
          id={LabelInputs.addressLineTwo}
          name={LabelInputs.addressLineTwo}
        />
      </Form.Field>
      <div className="flex flex-row">
        <Form.Field label={labelTitles.State} labelFor={LabelInputs.State}>
          <GrowUIFormField
            control={Select}
            items={statesItems}
            id={LabelInputs.State}
            name={LabelInputs.State}
            placeholder="Select State"
            type="text"
          />
        </Form.Field>
        {divider('w-6')}
        <Form.Field label={labelTitles.zipCode} labelFor={LabelInputs.zipCode}>
          <GrowUIFormField
            control={Input}
            id={LabelInputs.zipCode}
            name={LabelInputs.zipCode}
          />
        </Form.Field>
      </div>
    </div>
  );
};

ColumnWithFieldsRight.propTypes = {
  labelTitles: PropTypes.shape({
    phone: PropTypes.string.isRequired,
    company: PropTypes.string.isRequired,
    addressLineTwo: PropTypes.string.isRequired,
    zipCode: PropTypes.string.isRequired,
    State: PropTypes.string.isRequired
  }).isRequired,
  LabelInputs: PropTypes.shape({
    phone: PropTypes.string.isRequired,
    company: PropTypes.string.isRequired,
    companyId: PropTypes.string.isRequired,
    addressLineTwo: PropTypes.string.isRequired,
    zipCode: PropTypes.string.isRequired,
    State: PropTypes.string.isRequired
  }).isRequired,
  divider: PropTypes.func.isRequired,
  companies: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      name: PropTypes.string.isRequired,
      svgUrl: PropTypes.string
    })
  ).isRequired,
  isLoadingCompanies: PropTypes.bool.isRequired
};

export default ColumnWithFieldsRight;
