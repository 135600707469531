import { upperFirst, differenceBy } from 'lodash';
import { singularize } from 'inflected';

import getFeatureConfig from 'utilities/getFeatureConfig';
import NotFound404Error from 'components/ErrorHandler/NotFound404';

const getRouteFromKey = (key, parentKey = '') => {
  if (parentKey)
    return `${singularize(parentKey).toLowerCase()}${upperFirst(
      key.toLowerCase()
    )}`;
  return key.toLowerCase();
};

const isEntryPageDisabled = obj => obj.entry === 'disabled';
const isFeatureDisabled = val => val === 'disabled';
const getDisableFeature = key => getRouteFromKey(key);
const getDisableEntries = parentKey => getRouteFromKey('entry', parentKey);

const getDisabledRouteIds = config => {
  const routeSet = Object.keys(config).reduce((acc, key) => {
    if (typeof config[key] === 'object') {
      // entry page (includes create & edit)
      if (isEntryPageDisabled(config[key])) {
        acc.add(getDisableEntries(key));
      }
    } else if (isFeatureDisabled(config[key])) {
      acc.add(getDisableFeature(key));
      acc.add(getDisableEntries(key));
    }

    return acc;
  }, new Set());

  return Array.from(routeSet);
};

// TODO: this logic is attached to appcontainer maybe need to be more generic
const withFeatureAware = routes => {
  const disabledRouteIds = getDisabledRouteIds(getFeatureConfig());

  const appRoutesIdx = routes.findIndex(({ id }) => id === 'appcontainer');
  const appContainer = routes[appRoutesIdx];
  const appRoutes = appContainer.routes;

  const disabledRoutes = appRoutes
    .filter(({ id }) => disabledRouteIds.includes(id))
    .map(obj => ({ ...obj, component: NotFound404Error, disabled: true }));
  const enabledRoutes = differenceBy(appRoutes, disabledRoutes, 'id');

  routes.splice(appRoutesIdx, 1);
  const newRoutes = [
    { ...appContainer, routes: [...enabledRoutes, ...disabledRoutes] },
    ...routes
  ];

  return newRoutes;
};

export default withFeatureAware;
