/* eslint-disable react/forbid-prop-types */
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Tag } from '@agconnections/grow-ui';

const StatusTag = ({ status }) => {
  const [statusTag, setStatusTag] = useState({
    name: 'Ready',
    color: '#E99921'
  });

  useEffect(() => {
    if (status === 'inprogress') {
      setStatusTag({
        name: 'In Progress',
        color: '#5FD2C8'
      });
    } else if (status === 'complete') {
      setStatusTag({
        name: 'Complete',
        color: '#0071CD'
      });
    }
  }, [status]);

  return (
    <Tag color={statusTag.color}>
      <span className="w-32 text-lg">{statusTag.name}</span>
    </Tag>
  );
};
StatusTag.propTypes = { status: PropTypes.string.isRequired };

export default StatusTag;
