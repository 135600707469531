export const filterPropertiesByCropZones = (properties, cropZoneIds) =>
  properties
    .map(farm => {
      const newFields = farm.fields.map(field => ({
        ...field,
        cropzones: field.cropzones.filter(cz => cropZoneIds.includes(cz.id))
      }));
      return {
        ...farm,
        fields: newFields.filter(field => field.cropzones.length > 0)
      };
    })
    .filter(property => property.fields.length > 0);

export const propertiesResponseToTreeData = properties =>
  properties.map(farm => ({
    title: farm.name,
    key: farm.id,
    children: farm.fields?.map(field => ({
      title: field.name,
      key: field.id,
      children: field.cropzones?.map(cropzone => ({
        title: cropzone.name,
        key: cropzone.id
      }))
    }))
  }));
