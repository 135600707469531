import React from 'react';
import PropTypes from 'prop-types';
import { Input, SimpleModal } from '@agconnections/grow-ui';

const ProductsModal = ({ open, onClose, product, pests }) => {
  const pest = product.targetPestId
    ? pests.find(p => p.pestid === product.targetPestId)
    : undefined;

  return (
    <SimpleModal open={open} close={onClose} onConfirm={onClose} size="sm">
      <div className="flex items-center justify-between">
        <div className="font-bold text-lg">{product?.productName}</div>
      </div>
      <div className="flex items-center justify-between">
        <div className="text-sm text-neutral-300">
          {product?.manufacturerName}
        </div>
      </div>
      <div className="mt-12">
        <div className="grid grid-cols-3 gap-6 mb-12">
          <div className="my-2">
            <Input
              label="Rate"
              value={product?.productRatePerAreaValue}
              disabled
            />
          </div>
          <div className="my-2">
            <Input
              label="Unit"
              value={product?.productRatePerAreaUnit}
              disabled
            />
          </div>
          <div className="my-2">
            <Input
              label="Applied Area"
              value={product?.productAppliedAreaValue}
              disabled
            />
          </div>
          <div className="my-2">
            <Input
              label="Total Product"
              value={product?.totalProductValue}
              disabled
            />
          </div>
          <div className="my-2">
            <Input
              label="Application Method"
              value={product?.applicationMethod}
              disabled
            />
          </div>
          <div className="my-2">
            <Input label="Target Pest" value={pest?.name ?? ''} disabled />
          </div>
          <div className="my-2">
            <Input
              label="Applied Area %"
              value={product?.coveragePercent * 100}
              disabled
            />
          </div>
        </div>
      </div>
    </SimpleModal>
  );
};

ProductsModal.defaultProps = {
  open: false,
  onClose: () => {}
};

ProductsModal.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
  product: PropTypes.shape({
    applicationMethod: PropTypes.string,
    coveragePercent: PropTypes.number,
    manufacturerName: PropTypes.string,
    productName: PropTypes.string,
    productAppliedAreaValue: PropTypes.number,
    productRatePerAreaUnit: PropTypes.string,
    productRatePerAreaValue: PropTypes.number,
    targetPestId: PropTypes.number,
    totalProductValue: PropTypes.number
  }).isRequired,
  pests: PropTypes.arrayOf(
    PropTypes.shape({
      latinnames: PropTypes.string,
      name: PropTypes.string,
      nameid: PropTypes.number,
      pestid: PropTypes.number
    })
  ).isRequired
};

export default ProductsModal;
