import React, { useState, useContext, useEffect } from 'react';
import PropTypes from 'prop-types';
import { MapContext, GeoJSONLayer } from 'react-mapbox-gl';
import { circle, featureCollection, feature } from '@turf/turf';
import { negate, noop, isNil } from 'lodash';
import axios from 'axios';

import {
  MIN_ZOOM,
  FETCH_RADIUS,
  DISPLAY_PROPERTIES
} from './helpers/constants';

import { useSharedState } from '../MapEventContext';
import LandContext from '../../PropertyMapContext';
import { MAP_HISTORY_FEATURE_TYPES } from '../../../../helpers/constants';
import {
  getEventClientCoordinates,
  getTargetMapFeature
} from '../../../../helpers/mapEventHelpers';
// import ConfirmModal from './ConfirmModal';

export const GRIS_TYPES = {
  CLU: {
    providerName: 'Common Land Unit (CLU)',
    cropSeason: 'Summer',
    cropYear: 2008
  },
  SATELLITE: {
    providerName: 'Cropio'
  }
};

const matchingFeaturePredicate = matchItem => item =>
  matchItem.properties.grisId === item.properties.grisId;

// create a circle at the current map center
const getSearchPolygon = map => {
  const { lng, lat } = map.getCenter();
  return [`${lng}-${lat}`, circle([lng, lat], FETCH_RADIUS)];
};

const activeFetches = {};

const fetchBoundaries = ([centerCoordinates, polygon], grisTypeOptions) => {
  if (activeFetches[centerCoordinates]) {
    return Promise.resolve({});
  }
  const inputGeometry = {
    type: 'MultiPolygon',
    coordinates: [polygon.geometry.coordinates]
  };
  const promise = axios.post(
    'https://gris.syngentadigitalapps.com/fbm/v1/boundaries?authkey=6c66fce4-f0b0-4f63-9eb5-e71eceaa93dd',
    {
      ...grisTypeOptions,
      inputGeometry
    }
  );
  activeFetches[centerCoordinates] = promise;
  promise.then(() => {
    activeFetches[centerCoordinates] = null;
  });
  return promise;
};

const getItemId = item =>
  isNil(item.id)
    ? `${item.xMin}-${item.yMin}-${item.xMax}-${item.yMax}`
    : item.id;

// concat the newly fetched features to the current collection if they aren't already there,
// excluding the specified features that have already been added to the property
const parseFetchedFeatures = (
  currentCollection,
  excludedFeatures,
  newFeatures
) => {
  const existingIds = currentCollection.features.map(
    item => item.properties.grisId
  );
  const excludedIds = excludedFeatures.map(item => item.id).concat(existingIds);

  return currentCollection.features.concat(
    newFeatures
      .filter(item => !excludedIds.includes(getItemId(item)))
      .map(item =>
        feature(item.boundaryData, {
          grisId: getItemId(item),
          crops: ''
        })
      )
  );
};

const GrisLayer = ({
  setZoom,
  geoJSONCollection,
  type,
  onChange,
  onDelete
}) => {
  const [excludedFeatures] = useState(geoJSONCollection?.features ?? []);
  //  eslint no-unneeded-ternary: "error"
  const [availableFeatureCollection, setAvailableFeatureCollection] = useState(
    featureCollection([])
  );
  const [selectedFeatureCollection, setSelectedFeatureCollection] = useState(
    featureCollection([])
  );

  const { addNewMapFeatures } = useContext(LandContext);
  const map = useContext(MapContext);
  const [, setState] = useSharedState();
  const [, setMapFeatures] = useState([]);

  useEffect(() => {
    if (map) {
      const zoom = map.getZoom();
      if (zoom < MIN_ZOOM) {
        setZoom([MIN_ZOOM]);
      }
    }
  }, [map, setZoom]);

  useEffect(() => {
    let mounted = true;
    const initFetch = () => {
      if (mounted && map) {
        const searchPolygon = getSearchPolygon(map);

        fetchBoundaries(searchPolygon, type)
          .then(results => {
            if (results.data?.data) {
              setAvailableFeatureCollection(previousCollection => {
                const availableFeatures = parseFetchedFeatures(
                  previousCollection,
                  excludedFeatures,
                  results.data.data
                );
                return featureCollection(availableFeatures);
              });
            }
          })
          .catch(() => {}); // console.log('GRIS ERROR!', err));
      }
    };
    if (map) {
      map.on('moveend', initFetch);
      initFetch();
    }

    return () => {
      mounted = false;
      if (map) {
        map.off('moveend', initFetch);
      }
    };
  }, [map, excludedFeatures, type]);

  const handleLeave = () => {
    setState(previousState => ({
      ...previousState,
      coordinates: null,
      feature: null
    }));
  };

  const handleCreateConfirm = eachCollection => {
    handleLeave();
    // setModalOpen(false);
    const mapHistoryFeatures = eachCollection.features.map(feat => {
      const result = feature(feat.geometry, {
        internalId: feat.properties.grisId
      });
      result.id = feat.properties.grisId;
      return { feature: result };
    });
    setMapFeatures(mapHistoryFeatures);
    addNewMapFeatures(MAP_HISTORY_FEATURE_TYPES.ADD, mapHistoryFeatures);
    onChange(mapHistoryFeatures);
    // onClose(mapHistoryFeatures);
  };

  const handleSelect = event => {
    const selectedFeature = getTargetMapFeature(map, event.lngLat);
    const { features } = selectedFeatureCollection;

    const displayFeatures = [selectedFeature].map(feat => {
      const displayFeat = {};
      DISPLAY_PROPERTIES.forEach(prop => {
        displayFeat[prop] = feat[prop];
      });
      return displayFeat;
    });

    const filteredFeatureBasedOnGrisId = availableFeatureCollection?.features
      .filter(
        eachCollectionFeature =>
          eachCollectionFeature?.properties?.grisId ===
          displayFeatures[0]?.properties?.grisId
      )
      .pop();

    displayFeatures[0].geometry = filteredFeatureBasedOnGrisId?.geometry;

    const newCollection = featureCollection([]);

    if (features?.find(matchingFeaturePredicate(selectedFeature))) {
      onDelete();
      newCollection.features = features.filter(
        negate(matchingFeaturePredicate(selectedFeature))
      );
    } else {
      newCollection.features = features?.concat(displayFeatures);
    }

    if (newCollection?.features.length > 0) {
      setSelectedFeatureCollection(newCollection);
    } else {
      setSelectedFeatureCollection(featureCollection([]));
    }

    handleCreateConfirm(newCollection);
  };

  const handleMove = event => {
    const hoverFeature = getTargetMapFeature(map, event?.lngLat);
    setState(previousState => {
      if (
        previousState?.feature?.properties?.grisId ===
        hoverFeature?.properties?.grisId
      ) {
        return previousState;
      }
      const coordinates = getEventClientCoordinates(event.originalEvent);
      return {
        ...previousState,
        coordinates,
        feature: hoverFeature
      };
    });
  };

  // const handleLeave = () => {
  //   setState(previousState => ({
  //     ...previousState,
  //     coordinates: null,
  //     feature: null
  //   }));
  // };

  // const handleCreateConfirm = eachCollection => {
  //   handleLeave();
  //   // setModalOpen(false);
  //   const mapHistoryFeatures = eachCollection.features.map(feat => {
  //     const result = feature(feat.geometry, {
  //       internalId: feat.properties.grisId
  //     });
  //     result.id = feat.properties.grisId;
  //     return { feature: result };
  //   });
  //   addNewMapFeatures(MAP_HISTORY_FEATURE_TYPES.ADD, mapHistoryFeatures);
  //   // onClose(mapHistoryFeatures);
  // };

  // const handleConfirm = () => {
  //   onClose(mapFeatures);
  // };

  // const handleCreateCancel = () => {
  //   setModalOpen(false);
  // };

  return (
    <>
      {/* <ConfirmModal
        onConfirm={handleConfirm}
        onCancel={handleCreateCancel}
        open={modalOpen}
        type={type}
      /> */}
      <GeoJSONLayer
        data={availableFeatureCollection}
        fillPaint={{
          'fill-color': 'rgba(0, 113, 205, 0.7)',
          'fill-outline-color': 'rgba(130, 207, 255, 1)',
          'fill-antialias': true
        }}
        fillOnClick={handleSelect}
        fillOnMouseMove={handleMove}
        fillOnMouseLeave={handleLeave}
      />
      {selectedFeatureCollection && (
        <GeoJSONLayer
          data={selectedFeatureCollection}
          fillPaint={{
            'fill-color': 'rgba(25, 160, 75, 0.7)',
            'fill-outline-color': 'rgba(195, 234, 209, 1)',
            'fill-antialias': true
          }}
        />
      )}
    </>
  );
};

GrisLayer.propTypes = {
  setZoom: PropTypes.func,
  geoJSONCollection: PropTypes.shape({
    features: PropTypes.arrayOf(PropTypes.object)
  }),
  type: PropTypes.shape({
    providerId: PropTypes.string
  }),
  onChange: PropTypes.func,
  onDelete: PropTypes.func
};

GrisLayer.defaultProps = {
  setZoom: noop,
  geoJSONCollection: null,
  type: {},
  onChange: () => {},
  onDelete: () => {}
};

export default GrisLayer;
