import React from 'react';
import PropTypes from 'prop-types';

const FormattedNumber = ({ children, unit, roundedTo }) => {
  const { format } = Intl.NumberFormat('en-US', {
    minimumFractionDigits: roundedTo,
    maximumFractionDigits: roundedTo,
    ...(unit === 'usCurrency'
      ? {
          style: 'currency',
          currency: 'USD'
        }
      : {})
  });
  return (
    <>
      {format(children)}
      {unit === 'bu' && ' bu'}
    </>
  );
};

FormattedNumber.propTypes = {
  children: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  unit: PropTypes.oneOf(['bu', 'usCurrency']),
  roundedTo: PropTypes.number
};

FormattedNumber.defaultProps = {
  unit: null,
  children: undefined,
  roundedTo: undefined
};

export default FormattedNumber;
