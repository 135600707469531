// eslint-disable-next-line import/prefer-default-export
export const calculateTotalAcres = () => {
  return 199.55;
};

export const unmaskPriceYield = amount => {
  const amountValidated = typeof amount === 'string' ? amount : '';
  return isNaN(amountValidated)
    ? parseFloat(amountValidated.replace(/[$,]/g, ''))
    : parseFloat(amountValidated);
};
