import React from 'react';
import { Tag } from '@agconnections/grow-ui';
import PropTypes from 'prop-types';
import CropIconMapping from 'components/CropIconMapping';
import FieldProductsTable from './FieldProductsTable';

const FieldsView = ({ fields }) => {
  return (
    <>
      {fields.map((field, fieldIndex) => {
        return (
          field?.cropzones?.reduce(
            (sum, cropzone) => sum + cropzone?.products?.length,
            0
          ) > 0 && (
            <div
              key={field.id}
              data-testid="field-summary-table"
              className={`w-full ${
                fieldIndex === fields.length - 1 ? 'mb-10' : 'mb-20'
              }`}
              id={field.fieldId}
            >
              <div className="w-full text-2xl font-bold">{field?.name}</div>
              <div className="w-full">
                {field?.cropzones?.map(cropzone => {
                  return (
                    cropzone?.products?.length > 0 && (
                      <div className="mt-6" key={cropzone.id}>
                        <div className="flex flex-row mb-3 leading-loose items-center">
                          <div className="font-bold mr-4">{cropzone?.name}</div>
                          <div className="flex flex-row mr-4 items-center">
                            {CropIconMapping({
                              cropObject: {
                                name: cropzone?.cropName ?? cropzone?.name
                              }
                            })}{' '}
                            {cropzone?.cropName ?? cropzone?.name}
                          </div>
                          <div className="w-auto">
                            {cropzone?.cropSeasons?.map(cropseason => (
                              <Tag color="#696F88" key={cropseason.id}>
                                {cropseason?.cropseasonName ?? cropseason.name}
                              </Tag>
                            ))}
                          </div>
                        </div>
                        <FieldProductsTable products={cropzone?.products} />
                      </div>
                    )
                  );
                })}
              </div>
            </div>
          )
        );
      })}
    </>
  );
};

FieldsView.propTypes = {
  fields: PropTypes.arrayOf(PropTypes.object)
};

FieldsView.defaultProps = {
  fields: []
};

export default FieldsView;
