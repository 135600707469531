import { format } from 'date-fns';

function getInitialLoadName(userName = '') {
  let userInitials;
  const nameParts = userName.trim().split(' ');

  if (nameParts.length >= 2) {
    userInitials = nameParts[0].charAt(0) + nameParts[1].charAt(0);
  } else {
    userInitials = userName.substring(0, 2);
  }

  userInitials = userInitials.toUpperCase();

  const formattedDate = format(new Date(), 'yyMMdd-HHmm');

  return `${userInitials} ${formattedDate}`;
}

export default getInitialLoadName;
