import React, { useContext } from 'react';
import { Row, Col, Switch } from 'antd';
import { Radio } from 'syngenta-digital-cropwise-react-ui-kit';

import { Context } from 'components/Store';
import createAction from 'helpers/createAction';
import { SET_MAP_LABEL_CONFIG } from 'reducers/reducer';
import CollapsibleSection from '../CollapsibleSection';

export const switchConfigs = [
  { key: 'farmNames', label: 'Farm names' },
  { key: 'fieldNames', label: 'Field names' },
  { key: 'cropZoneNames', label: 'Crop zone names' },
  { key: 'assignedCropType', label: 'Assigned crop type' },
  { key: 'area', label: 'Area' }
];

const MapLabelsSection = () => {
  const [{ mapLabelConfig }, dispatch] = useContext(Context);

  const handleSwitchChange = key => {
    createAction(dispatch, SET_MAP_LABEL_CONFIG, {
      [key]: !mapLabelConfig[key]
    });
  };

  const handleRadioChange = e => {
    createAction(dispatch, SET_MAP_LABEL_CONFIG, {
      labelLocation: e.target.value
    });
  };

  return (
    <CollapsibleSection title="Map labels">
      <div className="px-4 pb-6">
        <div className="mb-6">
          <label htmlFor="labelLocation" className="text-neutral-60">
            Show labels:
          </label>
          <Radio.Group
            name="labelLocation"
            className="mt-1"
            value={mapLabelConfig.labelLocation}
            onChange={handleRadioChange}
          >
            <Radio.Button value="map">Directly on the map</Radio.Button>
            <Radio.Button value="legend">Using the map legend</Radio.Button>
          </Radio.Group>
        </div>
        <div>
          <span className="text-neutral-60">Labels contain:</span>
          {switchConfigs.map(({ label, key }) => (
            <Row key={key} className="mt-2" gutter={8}>
              <Col>
                <Switch
                  name={key}
                  checked={mapLabelConfig[key]}
                  onChange={() => handleSwitchChange(key)}
                  size="small"
                />
              </Col>
              <Col>{label}</Col>
            </Row>
          ))}
        </div>
      </div>
    </CollapsibleSection>
  );
};

export default MapLabelsSection;
