import faker from 'faker';
import { Factory } from 'miragejs';

const Permission = {
  oktaId: '',
  organizationId: '',
  userId() {
    return faker.random.uuid();
  },
  role: '',
  financial: '',
  active() {
    return true;
  },
  version() {
    return faker.random.number();
  }
};
export default Factory.extend(Permission);
