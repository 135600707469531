import { Server, Model, RestSerializer, hasMany } from 'miragejs';
import { inflections } from 'inflected';

import UUIDIdentityManager from './identityManagers/UUIDIdentityManager';
import {
  Area,
  Crop,
  CostPerAcre,
  Dashboard,
  Equipment,
  Field,
  Imagery,
  Permission,
  Product,
  Tag,
  Task,
  User,
  Member,
  Organization,
  DashboardWeather,
  Company,
  Invitation,
  Plan,
  Weather,
  Person
} from './factories';
import collectionRefSerializer from './utils/collectionRefSerializer';
import {
  areas,
  crops,
  companies,
  dashboards,
  equipment,
  fields,
  tags,
  permissions,
  plans,
  products,
  tasks,
  users,
  members,
  organizations,
  invitations,
  weather,
  persons
} from './routes';

import multiplePassthroughRequestFix from './utils/multiplePassthroughRequestFix';

const ApplicationSerializer = RestSerializer.extend({
  keyForModel() {
    return 'content';
  },
  keyForCollection() {
    return 'content';
  }
});

const OutdatedApplicationSerializer = RestSerializer.extend({
  keyForModel() {
    return 'data';
  },
  keyForCollection() {
    return 'data';
  }
});

const UnderscoreIdSerializer = OutdatedApplicationSerializer.extend({
  keyForAttribute(attr) {
    if (attr === 'id') return '_id';
    return attr;
  }
});

// for services like invites, which do not wrap results in a data object
const ServiceSerializer = RestSerializer.extend({ root: false, embed: true });

// override inflection for weather. plural for weather is weird, sometimes is weathers, sometimes it's not.
inflections('en', inflect => {
  inflect.irregular('weather', 'weather');
  inflect.irregular('person', 'people');
});

/* eslint-disable import/prefer-default-export */
export function makeServer({ environment = 'development' }) {
  const exportedServer = new Server({
    environment,

    identityManagers: {
      area: UUIDIdentityManager,
      crop: UUIDIdentityManager,
      company: UUIDIdentityManager,
      equipment: UUIDIdentityManager,
      field: UUIDIdentityManager,
      tag: UUIDIdentityManager,
      permission: UUIDIdentityManager,
      product: UUIDIdentityManager,
      plan: UUIDIdentityManager,
      user: UUIDIdentityManager,
      member: UUIDIdentityManager,
      organization: UUIDIdentityManager,
      invitation: UUIDIdentityManager,
      weather: UUIDIdentityManager,
      task: UUIDIdentityManager,
      person: UUIDIdentityManager
    },

    serializers: {
      application: ApplicationSerializer,
      area: UnderscoreIdSerializer.extend({
        serialize(...args) {
          return collectionRefSerializer('areas', this, args);
        }
      }),
      crop: ServiceSerializer,
      company: UnderscoreIdSerializer,
      equipment: UnderscoreIdSerializer,
      field: UnderscoreIdSerializer.extend({
        include: ['areas'],
        embed: true
      }),
      tag: UnderscoreIdSerializer,
      permission: ServiceSerializer,
      person: ApplicationSerializer,
      product: UnderscoreIdSerializer,
      plan: UnderscoreIdSerializer,
      user: ApplicationSerializer,
      member: ApplicationSerializer,
      organization: ApplicationSerializer,
      invitation: ServiceSerializer,
      weather: ApplicationSerializer,
      task: ApplicationSerializer.extend({
        include: ['applicators', 'assignees'],
        embed: true
      })
    },

    models: {
      area: Model.extend({
        areas: hasMany()
      }),
      crop: Model,
      costPerAcre: Model,
      company: Model,
      dashboard: Model,
      equipment: Model,
      field: Model.extend({
        areas: hasMany()
      }),
      imagery: Model,
      tag: Model,
      permission: Model,
      person: Model,
      plan: Model.extend({
        fields: hasMany()
      }),
      product: Model,
      task: Model.extend({
        applicators: hasMany('person'),
        assignees: hasMany('member')
      }),
      user: Model,
      member: Model,
      organization: Model.extend({
        members: hasMany()
      }),
      dashboardWeather: Model,
      invitation: Model,
      weather: Model
    },

    factories: {
      area: Area,
      crop: Crop,
      costPerAcre: CostPerAcre,
      company: Company,
      dashboard: Dashboard,
      dashboardWeather: DashboardWeather,
      equipment: Equipment,
      field: Field,
      imagery: Imagery,
      tag: Tag,
      permission: Permission,
      person: Person,
      plan: Plan,
      product: Product,
      task: Task,
      user: User,
      member: Member,
      organization: Organization,
      invitation: Invitation,
      weather: Weather
    },

    seeds(server) {
      server.create('organization');
      server.createList('member', 2);
      server.createList('field', 2);
      server.createList('area', 1);
      server.createList('permission', 5);
      server.createList('crop', 10);
      server.create('dashboard');
      server.createList('cost-per-acre', 6);
      server.create('dashboardWeather');
      server.create('imagery');
      server.createList('tag', 5);
      server.createList('plan', 5);
      server.createList('product', 12);
      server.createList('weather', 10);
      server.createList('company', 10);
      server.createList('task', 2, { status: 'ready' });
      server.createList('task', 2, { status: 'in-progress' });
      server.createList('task', 2, { status: 'complete' });
    },

    routes() {
      this.namespace = '';
      if (environment === 'test') {
        this.timing = 1;
      } else {
        this.timing = 500;
      }

      // routes
      areas(this);
      crops(this);
      companies(this);
      dashboards(this);
      equipment(this);
      fields(this);
      permissions(this);
      persons(this);
      plans(this);
      products(this);
      tags(this);
      tasks(this);
      users(this);
      members(this);
      organizations(this);
      invitations(this);
      weather(this);

      this.get('/cost-per-acres', schema => {
        return schema.costPerAcres.all();
      });

      this.get('/imagery', schema => {
        return schema.imageries.first();
      });

      this.get('/dashboardWeather', schema => {
        return schema.dashboardWeathers.all();
      });

      this.passthrough(`${process.env.REACT_APP_API_URL}/**`);
      this.passthrough(`${process.env.REACT_APP_PERMISSIONSERVICE_API_URL}/**`);
      this.passthrough(`${process.env.REACT_APP_INVITE_API_URL}/**`);
      this.passthrough(`/invite-proxy/**`);
      this.passthrough('https://api.mapbox.com/**');
      this.passthrough('https://events.mapbox.com/**');
      this.passthrough('https://auth-dev.landdb.com/**');
      this.passthrough('https://auth-qa.landdb.com/**');
      this.passthrough('https://auth-staging.landdb.com/**');
      this.passthrough('https://auth-prod.landdb.com/**');
      this.passthrough('https://vnext-api-geojson-qa.S3.amazonaws.com/**');
      this.passthrough('http://localhost:4000/**');
      this.passthrough('https://masterlist.agconnections.com/**');
      this.passthrough(
        `https://${process.env.REACT_APP_CROPWISE_BASE_API_URL}/v2/accounts/**`
      );
      this.passthrough(
        `https://${process.env.REACT_APP_CROPWISE_BASE_API_URL}/oauth/token`
      );
      this.passthrough('https://vnext-api-qa.landdb.com/**');
    }
  });

  // Mapbox Fix - https://github.com/kturney/ember-mapbox-gl/issues/53
  multiplePassthroughRequestFix();

  // Uncomment to disable mirage server call logging
  // exportedServer.logging = false;

  return exportedServer;
}
