import React from 'react';
import { PropTypes } from 'prop-types';
import { Table, Spinner } from '@agconnections/grow-ui';
import ConvertPlanTableHeader from './components/ConvertPlanTableHeader';
import ConvertPlanTableRow from './components/ConvertPlanTableRow';

const ConvertPlanTable = ({
  planProducts,
  markAll,
  markUnmarkProduct,
  loading,
  allAreMarked
}) => {
  return !loading ? (
    <Table>
      <ConvertPlanTableHeader
        sort=""
        handleSort={() => {}}
        numProducts={planProducts.filter(p => p.marked).length}
        markAll={markAll}
        allAreMarked={allAreMarked}
      />
      <ConvertPlanTableRow
        products={planProducts}
        onAction={markUnmarkProduct}
      />
    </Table>
  ) : (
    <Spinner />
  );
};

ConvertPlanTable.propTypes = {
  planProducts: PropTypes.arrayOf(PropTypes.object).isRequired,
  markAll: PropTypes.func.isRequired,
  markUnmarkProduct: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
  allAreMarked: PropTypes.bool.isRequired
};

export default ConvertPlanTable;
