import React, { useContext, useEffect, useState } from 'react';
import { useFormikContext } from 'formik';
import { Context } from 'components/Store';
import { Icon, Spinner } from '@agconnections/grow-ui';
import useSubmissionAnalytics from 'screens/Integrations/hooks/useSubmissionAnalytics';
import { INTEGRATIONS } from 'screens/Integrations/helpers/constants';
import { SubmissionSummaryContext } from '../../../SubmissionSummary/context/SubmissionSummaryProvider';
import CalAgSubmissionFooter from '../CalAgSubmissionFooter';
import CalAgAccordion from './components/CalAgAccordion';
import { CalAgContext } from '../../context/Provider';

const CalAgFieldDetails = () => {
  const { events, triggerAnalyticsEvent } = useSubmissionAnalytics();
  const { goForward, blockStepForward } = useContext(CalAgContext);
  const [{ isEnableInfoTabEdit }, dispatch] = useContext(Context);

  const {
    state: { fields, submissionId },
    isLoading,
    getSubmissionFields,
    setSubmissionFields
  } = useContext(SubmissionSummaryContext);

  const { values, setValues } = useFormikContext();
  const [selectedId, setSelectedId] = useState('');
  const [updatedFields, setUpdatedFields] = useState(fields);
  const [needInfoFields, setNeedInfoFields] = useState(
    fields.filter(f => f.status === 'need_info').length
  );

  useEffect(() => {
    async function fetchFields() {
      const response = await getSubmissionFields();
      if (!response) {
        blockStepForward();
      }
    }

    if (submissionId) {
      fetchFields();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [submissionId, blockStepForward]);

  useEffect(() => {
    setUpdatedFields(fields);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fields, setUpdatedFields]);

  useEffect(() => {
    if (!isEnableInfoTabEdit) {
      dispatch({
        type: 'IS_ENABLE_INFO_TAB_EDIT',
        payload: true
      });
    }

    return () => {
      dispatch({
        type: 'IS_ENABLE_INFO_TAB_EDIT',
        payload: false
      });
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setNeedInfoFields(
      updatedFields.filter(f => f.status === 'need_info').length
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [updatedFields]);

  const validateMandatoryAttributesCropZoneField = field => {
    const requiredFields = [
      'state',
      'county',
      'section',
      'townshipNumber',
      'townshipDirection',
      'rangeNumber',
      'rangeDirection',
      'permitId',
      'siteId'
    ];

    const allFieldsPresent = requiredFields.every(key => field[key]);
    if (!allFieldsPresent) {
      return false;
    }

    const isDirectionValid =
      ['north', 'south'].includes(field.townshipDirection) &&
      ['west', 'east'].includes(field.rangeDirection);

    return isDirectionValid;
  };

  const adaptToFormikContext = field => {
    return {
      ...field,
      range: field.rangeNumber,
      township: field.townshipNumber
    };
  };

  const adaptFromFormikContext = field => {
    return {
      ...field,
      rangeNumber: field.range,
      townshipNumber: field.township
    };
  };

  const updateFieldInformation = (selectedField, updatedValues) => {
    let status = 'need_info';
    if (
      validateMandatoryAttributesCropZoneField(
        adaptFromFormikContext(updatedValues)
      )
    ) {
      status = 'complete';
    }
    const newUpdatedFields = updatedFields.map(f => {
      if (`${f.fieldId}#${f.cropZoneId}` === selectedField) {
        return { ...f, ...adaptFromFormikContext(updatedValues), status };
      }
      return f;
    });
    setUpdatedFields(newUpdatedFields);
    return newUpdatedFields;
  };

  useEffect(() => {
    if (selectedId !== '') {
      updateFieldInformation(selectedId, values);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values]);

  const expandField = field => {
    if (selectedId === `${field.fieldId}#${field.cropZoneId}`) {
      setSelectedId('');
      setValues({
        ...values,
        state: '',
        county: '',
        section: '',
        township: '',
        townshipNumber: '',
        townshipDirection: '',
        rangeNumber: '',
        range: '',
        rangeDirection: '',
        permitId: '',
        siteId: ''
      });
    } else {
      const state = field.state || 'CA';
      setSelectedId(`${field.fieldId}#${field.cropZoneId}`);
      setValues({ ...values, ...adaptToFormikContext(field), state });
    }
  };

  const goToNextStep = () => {
    goForward();
    triggerAnalyticsEvent(
      events.epic.Integrations.submissionFieldsFilledRegulatoryData,
      {
        vendor: INTEGRATIONS.calAgPermits
      }
    );
  };

  const onNextHandler = async () => {
    const fieldsChangedToUpdate = updatedFields.filter(
      updatedField =>
        fields.findIndex(f => {
          const fieldToMatch = { ...updatedField };
          delete fieldToMatch.range;
          delete fieldToMatch.township;
          return JSON.stringify(f) === JSON.stringify(fieldToMatch);
        }) === -1
    );

    if (!fieldsChangedToUpdate?.length) {
      goToNextStep();
      return;
    }

    const response = await setSubmissionFields(fieldsChangedToUpdate);

    if (response) {
      goToNextStep();
    }
  };

  return (
    <div
      className="flex flex-col bg-white top-30 left-26 w-140 rounded-md mb-20 p-6 shadow-sm"
      data-testid="submission-crop-season-selection"
    >
      <div className="mb-4 flex flex-row">
        <div className="flex flex-col">
          <div className="text-lg leading-7 font-semibold text-neutral-1000 mb-2">
            Enter Field Details
          </div>
          <p className="text-sm leading-6 text-neutral-70">
            Enter property details for the fields you are submitting. Any
            details entered from the property page will be shown here.
          </p>
        </div>

        {!isLoading && (
          <div className="flex h-full items-center">
            <span
              className={`ml-4 p-3 text-base ${
                needInfoFields !== 0
                  ? ' text-yellow-700 bg-yellow-200'
                  : ' text-green-700 bg-green-200'
              } rounded-lg whitespace-no-wrap h-fit flex fle-row`}
            >
              {needInfoFields !== 0 ? (
                <>
                  <strong data-testid="need-info-fields" className="mr-1">
                    {needInfoFields}
                  </strong>{' '}
                  Fields Need Info
                </>
              ) : (
                <>
                  <div className="mr-3 w-fit h-fit p-1 rounded-full bg-green-600">
                    <Icon size={12} icon="checkmark" color="#FFFFFF" />
                  </div>
                  <div>Fields Details Entered</div>
                </>
              )}
            </span>
          </div>
        )}
      </div>
      {isLoading ? (
        <Spinner />
      ) : (
        <>
          {updatedFields.map(field => (
            <CalAgAccordion
              field={field}
              toggleCallback={() => expandField(field)}
              selectedId={selectedId}
              key={`field-${field.fieldId}-${field.cropZoneId}`}
            />
          ))}
        </>
      )}
      <CalAgSubmissionFooter
        onNext={onNextHandler}
        loading={isLoading}
        nextLabel="Next: Review and Submit"
        nextDisabled={needInfoFields !== 0}
      />
    </div>
  );
};

export default CalAgFieldDetails;
