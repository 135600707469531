import React from 'react';
import PropTypes from 'prop-types';

const SuccessIcon = ({ size, height = 30, width = 30 }) => (
  <svg
    viewBox={`0 0 ${size} ${size}`}
    width={`${width}`}
    height={`${height}`}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    data-testid="success-icon"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M10 0C4.48 0 0 4.48 0 10s4.48 10 10 10 10-4.48 10-10S15.52 0 10 0Zm0 18c-4.41 0-8-3.59-8-8s3.59-8 8-8 8 3.59 8 8-3.59 8-8 8Zm-2-5.83 6.59-6.59L16 7l-8 8-4-4 1.41-1.41L8 12.17Z"
      fill="#19A04B"
      transform="translate(2, 2)"
    />
  </svg>
);

SuccessIcon.defaultProps = {
  size: 24,
  height: 30,
  width: 30
};

SuccessIcon.propTypes = {
  size: PropTypes.number,
  height: PropTypes.number,
  width: PropTypes.number
};

export default SuccessIcon;
