import React from 'react';
import PropTypes from 'prop-types';
import { Tabs } from '@agconnections/grow-ui';
import _ from 'lodash';
import convertKeyAsLabel from 'screens/YieldV2/StorageLocations/helpers/convertKeyAsLabel';
import { Formik } from 'formik';
import SaleInfoTab from './components/SaleInfoTab';
import { tabsLabels } from './helpers/constants';
import YieldDetailsTab from './components/YieldDetailsTab';
import LoadAttributesTab from './components/LoadAttributesTab';
import WeightTab from './components/WeightTab';
import CountTab from './components/CountsTab';
import OtherTab from './components/OtherTab';
import NotesTab from './components/NotesTab';
import validateDestination from './helpers/validateDestination';
import validateSource from './helpers/validateSource';
import validateTemplate from './helpers/validateTemplate';

const LoadDetailsInfoTabs = ({ load, cropzoneTotalArea }) => {
  const { sourceType, destinationType, templateType } = load;

  const tabs = {
    [tabsLabels.saleInfo]: <SaleInfoTab />,
    [tabsLabels.yieldDetails]: (
      <YieldDetailsTab cropzoneTotalArea={cropzoneTotalArea} />
    ),
    [tabsLabels.loadAttributes]: <LoadAttributesTab />,
    [tabsLabels.weight]: <WeightTab />,
    [tabsLabels.counts]: <CountTab />,
    [tabsLabels.other]: <OtherTab />,
    [tabsLabels.notes]: <NotesTab />
  };

  const filterData = () => {
    let filteredTabs;
    const _tabs = _.cloneDeep(tabs);
    filteredTabs = validateTemplate(_tabs, templateType, load);
    filteredTabs = validateDestination(filteredTabs, destinationType);
    filteredTabs = validateSource(filteredTabs, sourceType);
    return filteredTabs;
  };

  return (
    <div className="mt-4">
      <Formik
        initialValues={{
          ...load
        }}
      >
        <Tabs pane>
          {Object.entries(filterData()).map(([key, content]) => {
            return (
              <Tabs.Tab key={key} label={convertKeyAsLabel(key)}>
                <div className="pt-4 pr-4 pl-4 pb-8">{content}</div>
              </Tabs.Tab>
            );
          })}
        </Tabs>
      </Formik>
    </div>
  );
};

LoadDetailsInfoTabs.propTypes = {
  load: PropTypes.shape().isRequired,
  cropzoneTotalArea: PropTypes.number.isRequired
};
export default LoadDetailsInfoTabs;
