import React, { useContext } from 'react';
import { Context } from 'components/Store';
import { TLoad } from 'screens/YieldV2/Loads/constants';
import CardInfo from 'components/CardInfo';
import { customDateFormat } from 'helpers/dateFormat';
import { destinationLoadTypeIcon } from '../../../helpers/constants';
import getCropSeasonName from '../../../helpers/getCropSeasonName';
import LoadCrop from '../../LoadCrop';

const StorageAsSourceDetails = ({ load }) => {
  const [{ cropSeasons }] = useContext(Context);
  return (
    <section className="w-full flex flex-row px-8 py-4 bg-white rounded-lg shadow-lg">
      <div className="w-1/2 mr-6">
        <CardInfo
          labelColWidth="w-1/5"
          wrapperClassName="justify-start"
          label="Source"
          value={
            !load?.sources?.[0]?.name ? null : (
              <div className="ml-1 flex flex-row">
                <img
                  className="mr-1"
                  width={20}
                  src={destinationLoadTypeIcon[load.sourceType]}
                  alt={`${load.sourceType} icon`}
                />
                <div>{load.sources[0].name}</div>
              </div>
            )
          }
        />

        <CardInfo
          labelColWidth="w-1/5"
          wrapperClassName="justify-start"
          label="Date & Time"
          value={
            load?.date
              ? customDateFormat(load.date, 'MMM d, yyyy hh:mm a')
              : null
          }
        />

        <CardInfo
          labelColWidth="w-1/5"
          wrapperClassName="justify-start"
          label="Crop"
          value={<LoadCrop load={load} />}
        />
      </div>
      <div className="w-1/2">
        <CardInfo
          labelColWidth="w-1/5"
          wrapperClassName="justify-start"
          label="Destination"
          value={
            !load?.destination?.name ? null : (
              <div className="flex items-center">
                <img
                  className="mr-1"
                  width={20}
                  src={destinationLoadTypeIcon[load.destinationType]}
                  alt={`${load.destinationType} icon`}
                />
                {load.destination.name}
              </div>
            )
          }
        />
        <CardInfo
          labelColWidth="w-1/5"
          wrapperClassName="justify-start"
          label="Crop Season"
          value={getCropSeasonName(cropSeasons, load.cropSeasonId)}
        />
      </div>
    </section>
  );
};

StorageAsSourceDetails.propTypes = {
  load: TLoad.isRequired
};

export default StorageAsSourceDetails;
