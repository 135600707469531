import React from 'react';
import { Column, TextArea, Form } from '@agconnections/grow-ui';
import GrowUIFormField from 'components/Forms/GrowUIFormField';

const PlanNotesSection = () => {
  return (
    <>
      <Column width="w-full">
        <div className="flex items-center-1000">
          <Form.Field>
            <GrowUIFormField
              control={TextArea}
              name="notes"
              id="notes"
              maxLength="1000"
              className="h-64 overscroll-auto"
              resizeDisabled
            />
          </Form.Field>
        </div>
      </Column>
    </>
  );
};

export default PlanNotesSection;
