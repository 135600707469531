import React from 'react';
import PropTypes from 'prop-types';
import { SimpleModal } from '@agconnections/grow-ui';
import ReportProblemOutlinedIcon from '@material-ui/icons/ReportProblemOutlined';

const CropzoneModalConfirmation = ({
  open,
  onClose,
  onConfirm,
  relatedSeasons,
  action
}) => {
  const splitUpSeasons = () =>
    relatedSeasons.reduce((prev, curr, i) => {
      if (i === 0) {
        return curr.name;
      }

      return `${prev}, ${curr.name}`;
    }, '');

  const actionType = action === 'Deleted' ? 'Delete' : 'Update';

  const confirmationText =
    actionType === 'Delete'
      ? 'Some of which will be deleted. Are you sure you want to continue?'
      : 'Are you sure you want to continue?';

  return (
    <SimpleModal
      open={open}
      title={`${actionType} Crop Zone(s)?`}
      close={onClose}
      cancelLabel="Cancel"
      confirmLabel={`${actionType} Crop Zone(s)?`}
      onCancel={onClose}
      type="basic"
      onConfirm={onConfirm}
      icon={<ReportProblemOutlinedIcon />}
    >
      <div className="pr-6">
        <p>
          You are about to {actionType.toLowerCase()} crop zone(s){' '}
          {splitUpSeasons() && 'for'}{' '}
          <span className="font-bold">{splitUpSeasons()}</span>.{' '}
          {confirmationText}
        </p>
      </div>
    </SimpleModal>
  );
};

CropzoneModalConfirmation.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onConfirm: PropTypes.func.isRequired,
  relatedSeasons: PropTypes.arrayOf(PropTypes.object).isRequired,
  action: PropTypes.string.isRequired
};

export default CropzoneModalConfirmation;
