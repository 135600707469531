import React from 'react';
import PropTypes from 'prop-types';
import { Popup } from '@agconnections/grow-ui';
import { getAbbreviatedUnit } from 'helpers/unitsAbbreviation';
import { emptyValue } from 'screens/ProductList/helpers/tableConstants';
import { ReactComponent as InfoIcon } from 'assets/info.svg';
import getProductQuantity from 'screens/ProductList/helpers/getProductQuantity';

const ProductListCellQuantity = ({ product }) => {
  const availableQuantity = getProductQuantity(product);
  const unit = getAbbreviatedUnit(product.stdPackageUnit);
  return (
    <div className="flex flex-row justify-center">
      <p>
        {product.productQuantityValue || product.purchased ? (
          <>
            <span>{availableQuantity}</span>{' '}
            <span>
              {availableQuantity !== emptyValue.dashLine ? unit : null}
            </span>
          </>
        ) : (
          emptyValue.none
        )}
      </p>
      {availableQuantity < 0 ? (
        <Popup
          bgColor="#232630"
          enableHover
          position="top"
          align="left"
          className="w-30"
          hasArrow
          trigger={
            <div className="pl-3">
              <InfoIcon />
            </div>
          }
        >
          <div className="w-356px text-xs text-white">
            Add product quantity via invoices to resolve negative value
          </div>
        </Popup>
      ) : null}
    </div>
  );
};

ProductListCellQuantity.propTypes = {
  product: PropTypes.arrayOf(PropTypes.object).isRequired,
  purchased: PropTypes.string.isRequired,
  productQuantityValue: PropTypes.number.isRequired
};

export default ProductListCellQuantity;
