/* istanbul ignore file */
import { Factory } from 'miragejs';
import faker from 'faker';
import globalSchema from '../utils/globalSchema';

const Product = {
  name(i) {
    const productsList = [
      '18-46-0',
      '0-0-60',
      '32-0-0',
      'Acuron',
      'Quilt Xcel',
      'Endigo',
      'Spraying',
      'Spreading',
      'Planting',
      'GH Corn seed',
      'NK Soybean seed',
      'AgriPro Wheat seed'
    ];

    return i < productsList.length ? productsList[i] : 'Product';
  },
  manufacturer() {
    return faker.company.companyName();
  },
  type() {
    return 'CropProtection';
  },
  status() {
    return faker.random.arrayElement(['Active', 'Inactive']);
  },
  makeup() {
    return faker.random.arrayElement(['Liquid', 'Dry']);
  },
  density() {
    return faker.random.number({ max: 10 });
  },
  signal_word() {
    return 'WARNING';
  },
  rei() {
    return null;
  },
  phi() {
    return null;
  },
  carbamate() {
    return null;
  },
  organophosphate() {
    return null;
  },
  geneticallyEnhanced() {
    return null;
  },
  restrictedUse() {
    return false;
  },
  oralNotification() {
    return null;
  },
  activeIngredients() {
    return [
      {
        id: faker.random.uuid(),
        name: 'Azoxystrobin',
        percentage: 13.5
      },
      {
        id: faker.random.uuid(),
        name: 'Propiconazole',
        percentage: 11.3
      }
    ];
  },
  area() {
    return faker.random.number({ max: 60 });
  },
  rate() {
    return faker.random.number({ max: 1500 });
  },
  rateUnit() {
    return faker.random.arrayElement(['Gal', 'Ker']);
  },
  pricePerUnit() {
    return faker.finance.amount();
  },
  coverage() {
    return faker.random.number({ max: 100 });
  },
  totalProduct() {
    return faker.random.number();
  },
  totalCost() {
    return faker.finance.amount();
  },
  growerCost() {
    return faker.finance.amount();
  },
  ...globalSchema
};

export default Factory.extend(Product);
