import React, { useContext } from 'react';
import { useHistory } from 'react-router-dom';
import { Button, Spinner, Tabs } from '@agconnections/grow-ui';
import { paths } from 'routes/paths';
import CardWrapper from 'components/CardWrapper';
import EmptyTableState from 'components/EmptyTableState';
import notesIcon from 'assets/noNotesFound.png';
import { AmplitudeContext } from 'utilities/amplitude/useAmplitude';
import clipboardIcon from 'assets/clipboardIcon.png';
import { StorageLocationContext } from '../../context/StorageLocationProvider';
import convertKeyAsLabel from '../../helpers/convertKeyAsLabel';
import StorageAdvancedAttributes from './components/StorageAdvancedAttributes';
import StorageContactDetails from './components/StorageContactDetails';
import StorageDimensions from './components/StorageDimensions';
import StorageNotes from './components/StorageNotes';
import StorageSystem from './components/StorageSystem';
import StoredQuantity from './components/StoredQuantity';
import StorageLocationDetailHeader from './components/StorageLocationDetailHeader';

const tabs = {
  dimensions: <StorageDimensions />,
  systems: <StorageSystem />,
  advancedAttributes: <StorageAdvancedAttributes />,
  notes: <StorageNotes />
};

const StorageLocationDetails = () => {
  const history = useHistory();
  const amplitude = useContext(AmplitudeContext);
  const {
    state: { data, selectedId, isLoading, isFetchingDetails, sortBy, sortDir }
  } = useContext(StorageLocationContext);
  const selected =
    data.length > 0 ? data.find(item => item.id === selectedId) : false;
  if (isLoading || isFetchingDetails) return <Spinner />;
  return (
    <div className="h-full w-full relative flex flex-col">
      {selected && <StorageLocationDetailHeader />}
      {!data.length ? (
        <div className="min-w-content gap-y-4 p-4">
          <CardWrapper className="pt-48px pb-128px">
            <EmptyTableState
              title="No Storage Locations Found"
              overlayIcon={notesIcon}
              button={
                <Button
                  type="primary"
                  onClick={() => {
                    amplitude.sendEventToAmplitude(
                      amplitude.events.epic.YieldV2.storageLocations
                        .createStorageLocation
                    );
                    history.push(`${paths.yieldV2}/storage-locations/create`, {
                      selectedId,
                      sortBy,
                      sortDir
                    });
                  }}
                >
                  + Create Location
                </Button>
              }
            />
          </CardWrapper>
        </div>
      ) : (
        <div className="min-w-content h-full flex flex-col flex-1 gap-y-4 p-4">
          {!selected ? (
            <CardWrapper className="pt-48px pb-128px">
              <EmptyTableState
                title="No Storage Location Selected"
                overlayIcon={clipboardIcon}
              />
            </CardWrapper>
          ) : (
            <>
              <div className="lg:flex lg:space-x-4 space-y-4 lg:space-y-0">
                <div className="lg:w-1/2 h-auto">
                  <StoredQuantity />
                </div>
                <div className="lg:w-1/2 h-auto">
                  <StorageContactDetails />
                </div>
              </div>

              <Tabs pane>
                {Object.entries(tabs).map(([key, content]) => (
                  <Tabs.Tab key={key} label={convertKeyAsLabel(key)}>
                    <div className="pt-4 pr-4 pl-4 pb-8">{content}</div>
                  </Tabs.Tab>
                ))}
              </Tabs>
            </>
          )}
        </div>
      )}
    </div>
  );
};

export default StorageLocationDetails;
