import { ROLES } from 'utilities/access';

import Recommendations from 'screens/Recommendations';
import Recommendation from 'screens/Recommendations/Recommendation';
import RecommendationView from 'screens/Recommendations/Recommendation/RecommendationView';

import { RecommendationsIcon } from 'routes/icons';
import { paths } from 'routes/paths';

const routes = [
  {
    id: 'recommendations',
    key: 'recommendations',
    label: 'Recommendations',
    path: paths.recommendations,
    exact: true,
    secure: true,
    disabled: false,
    component: Recommendations,
    icon: RecommendationsIcon,
    nav: 'leftNav'
  },
  {
    id: 'recommendation',
    key: 'recommendation',
    path: `${paths.recommendations}/:id`,
    exact: true,
    secure: true,
    disabled: false,
    component: Recommendation,
    minPermissions: {
      minAllowedRole: ROLES.FULL_CONTROL
    }
  },
  {
    id: 'recommendationView',
    key: 'recommendationView',
    path: `${paths.recommendations}/:id/view`,
    exact: true,
    secure: true,
    disabled: false,
    component: RecommendationView
  }
];

export default routes;
