/* eslint-disable no-unused-vars */
import { cloneDeep } from 'lodash';
import blankPhoneNumber from './blankPhoneNumber';

const getPhoneNumber = user => {
  let phoneNumber;

  if (user.contactInfo.phoneNumbers.length > 0) {
    phoneNumber = user.contactInfo.phoneNumbers.find(
      number => number.phoneType === 'home'
    );
  }
  if (phoneNumber === undefined) return '';
  return phoneNumber;
};

const getAddress = user => {
  let addressToRet;

  if (user.addresses) {
    addressToRet = user.address;
  }
  if (addressToRet === undefined)
    return {
      country: '',
      line1: '',
      line2: '',
      city: '',
      state: '',
      zipCode: ''
    };
  return addressToRet;
};

export const getApplicatorLicense = user => {
  const applicatorLicense = {
    value: user.applicatorLicenseNumber,
    expiry: user.applicatorLicenseExpirationDateEpoch
  };
  return applicatorLicense;
};

export const setApplicatorLicense = (user, applicatorLicense) => {
  const applicatorLicenseIndex = user.licenses.findIndex(
    license => license.licenseType === 'Applicator'
  );
  if (applicatorLicenseIndex >= 0) {
    user.licenses.splice(applicatorLicenseIndex, 1, applicatorLicense);
  } else if (applicatorLicense !== undefined) {
    user.licenses.push(applicatorLicense);
  }
};

export const setPhoneNumber = (user, phoneNumber) => {
  const phoneNumberIndex = user.contactInfo.phoneNumbers.findIndex(
    number => number.phoneType === 'home'
  );
  if (phoneNumberIndex >= 0) {
    const newPhoneNumber = user.contactInfo.phoneNumbers[phoneNumberIndex];
    newPhoneNumber.value = phoneNumber;
    if (phoneNumber !== '') {
      user.contactInfo.phoneNumbers.splice(phoneNumberIndex, 1, newPhoneNumber);
    } else {
      user.contactInfo.phoneNumbers.splice(phoneNumberIndex, 1);
    }
  } else if (phoneNumber) {
    const newPhoneNumber = blankPhoneNumber;
    newPhoneNumber.value = phoneNumber;
    user.contactInfo.phoneNumbers.push(newPhoneNumber);
  }
};

export const mapAddress = address => {
  return {
    line1: address.streetAddress1,
    line2: address.streetAddress2,
    city: address.cityName,
    state: address['object Object'],
    zipCode: address.postalCode,
    country: address.country,
    addressId: ''
  };
};

export const setAddress = (user, values) => {
  const address = mapAddress(values);
  const addressIndex = user.contactInfo.addresses.findIndex(
    addressItem => addressItem.addressType === 'location'
  );
  let hasStreetAndPostalCode = false;
  hasStreetAndPostalCode = !!(values.streetAddress1 && values.postalCode);
  if (addressIndex >= 0 && hasStreetAndPostalCode) {
    user.contactInfo.addresses.splice(addressIndex, 1, address);
  } else if (addressIndex >= 0) {
    user.contactInfo.addresses.splice(addressIndex, 1);
  }

  if (addressIndex === -1 && hasStreetAndPostalCode) {
    user.contactInfo.addresses.push(address);
  }
};

export const cloneUser = (user, values, updatedLicense) => {
  const userCopy = cloneDeep(user);
  userCopy.name = values.fullName;
  userCopy.email = values.emailAddress;
  userCopy.notes = values.notes;
  userCopy.address = mapAddress(values);
  userCopy.applicatorLicenseNumber = updatedLicense ? updatedLicense.value : '';
  userCopy.applicatorLicenseExpirationDateEpoch = updatedLicense
    ? updatedLicense.expiry
    : '';
  userCopy.phone = values.phoneNumber;
  userCopy.notes = values.notes;
  return userCopy;
};

export const mapUserToPerson = user => {
  return {
    fullName: user.name,
    emailAddress: user.email,
    country: user.address?.country,
    streetAddress1: user.address?.line1,
    streetAddress2: user.address?.line2,
    cityName: user.address?.city,
    state: user.address?.state,
    postalCode: user.address?.zipCode,
    notes: user.notes,
    phoneNumber: user.phone,
    applicatorLicense: {
      value: user.applicatorLicenseNumber,
      expiry:
        user.applicatorLicenseExpirationDateEpoch > 0
          ? user.applicatorLicenseExpirationDateEpoch
          : ''
    }
  };
};
