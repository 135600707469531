export const emptyValue = { none: 'None', dashLine: '---' };

export const TYPE_FILTER_LIST_OPTIONS = [
  {
    key: 'CropProtection',
    value: 'Crop Protection'
  },
  {
    key: 'Fertilizer',
    value: 'Fertilizer'
  },
  {
    key: 'Seed',
    value: 'Seed'
  },
  {
    key: 'Service',
    value: 'Service'
  }
];

export const SOURCE_FILTER_OPTIONS = [
  {
    key: 'CustomProduct',
    value: 'Custom Product'
  },
  {
    key: 'CropwiseList',
    value: 'Cropwise List'
  }
];

export const TYPE_FILTER_ADD_OPTIONS = [
  {
    key: 'CropProtection',
    value: 'Crop Protection'
  },
  {
    key: 'Fertilizer',
    value: 'Fertilizer'
  },
  {
    key: 'Seed',
    value: 'Seed'
  },
  {
    key: 'Service',
    value: 'Service'
  }
];
