import React from 'react';
import PropTypes from 'prop-types';
import { Button } from '@agconnections/grow-ui';
import { useFormikContext } from 'formik';

const UpdateReportButton = ({ disabled }) => {
  const { values, handleSubmit } = useFormikContext();
  return (
    <div
      className="w-full flex justify-end pt-3 pr-3 pb-3 absolute left-0 bottom-0 bg-white"
      style={{ borderTop: '1px solid #A3A9B9', bottom: '-42px' }}
    >
      <Button
        type="primary"
        disabled={disabled}
        onClick={() => {
          handleSubmit(values);
        }}
      >
        Update Report
      </Button>
    </div>
  );
};

UpdateReportButton.propTypes = {
  disabled: PropTypes.bool.isRequired
};

export default UpdateReportButton;
