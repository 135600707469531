import React from 'react';
import PropTypes from 'prop-types';
import { Button, Select, Spinner } from '@agconnections/grow-ui';
import GrowUIFormField from 'components/Forms/GrowUIFormField';
import ClearIcon from '@material-ui/icons/Clear';

const ApplicatorRowElement = ({
  index,
  peopleLoading,
  ordinal,
  mappedPeopleNames,
  getDisabled,
  companiesLoaded,
  mappedPeopleCompanies,
  onButtonClick
}) => {
  const InlineHorizontalRule = () => (
    <span
      className="absolute top-0 right-0 block w-12 mt-3 bg-neutral-200"
      style={{ height: '2px', marginRight: '-4.5rem' }}
    />
  );
  return (
    <>
      <div className="relative ">
        <InlineHorizontalRule />
        {peopleLoading ? (
          <Spinner />
        ) : (
          <GrowUIFormField
            label={`${index + 1}${ordinal(index + 1)} Applicator`}
            control={Select}
            name={`applicators[${index}].personId`}
            items={mappedPeopleNames}
            disabled={getDisabled()}
          />
        )}
      </div>
      <div className="relative flex">
        <div className="w-full">
          {!companiesLoaded ? (
            <Spinner />
          ) : (
            <GrowUIFormField
              label="Company"
              control={Select}
              name={`applicators[${index}].companyId`}
              items={mappedPeopleCompanies}
              disabled={getDisabled()}
            />
          )}
        </div>
        <div className="ml-2">
          <Button
            type="outline"
            ghost
            onClick={onButtonClick}
            icon={<ClearIcon />}
            disabled={getDisabled()}
          />
        </div>
      </div>
    </>
  );
};

ApplicatorRowElement.propTypes = {
  index: PropTypes.number.isRequired,
  peopleLoading: PropTypes.bool.isRequired,
  ordinal: PropTypes.func.isRequired,
  mappedPeopleNames: PropTypes.arrayOf(
    PropTypes.shape({
      key: PropTypes.string,
      value: PropTypes.string
    })
  ).isRequired,
  getDisabled: PropTypes.func,
  companiesLoaded: PropTypes.bool.isRequired,
  mappedPeopleCompanies: PropTypes.arrayOf(
    PropTypes.shape({
      key: PropTypes.string,
      value: PropTypes.string
    })
  ).isRequired,
  onButtonClick: PropTypes.func
};

ApplicatorRowElement.defaultProps = {
  getDisabled: () => {},
  onButtonClick: () => {}
};

export default ApplicatorRowElement;
