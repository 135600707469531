import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import CloseIcon from 'assets/close.svg';

const CropSeasonsTags = ({ seasons, ids, onRemove }) => {
  const data = useMemo(() => {
    return ids.map(seasonId => {
      const season = seasons.find(s => s.id === seasonId);

      return {
        id: seasonId,
        name: season.name
      };
    });
  }, [seasons, ids]);

  const itemCount = data.length;

  return (
    <div className="w-full flex gap-2 flex-no-wrap">
      {data.slice(0, 3).map(item => {
        return (
          <div
            key={`label_${item.id}`}
            className={`flex justify-between items-center gap-1 bg-neutral-100 rounded px-2 py-3px ${
              itemCount < 4 ? 'max-w-85px' : 'max-w-70px'
            }`}
          >
            <div className="flex-1 font-semibold text-neutral-60 text-xs text-center truncate">
              <span title={item.name}>{item.name}</span>
            </div>
            <button
              className="w-4 h-4"
              type="button"
              onClick={e => {
                e.stopPropagation();
                onRemove(item.id);
              }}
            >
              <img
                className="h-full w-full"
                alt="remove crop season"
                src={CloseIcon}
              />
            </button>
          </div>
        );
      })}
      {itemCount > 3 ? (
        <div className="bg-neutral-100 rounded px-2 py-x">
          <span className="font-semibold text-neutral-60 text-xs">
            + {itemCount - 3}
          </span>
        </div>
      ) : null}
    </div>
  );
};

CropSeasonsTags.propTypes = {
  ids: PropTypes.arrayOf(PropTypes.string).isRequired,
  seasons: PropTypes.arrayOf(PropTypes.object).isRequired,
  onRemove: PropTypes.func.isRequired
};

export default CropSeasonsTags;
