import React from 'react';
import PropTypes from 'prop-types';
import { NavLink } from 'react-router-dom';
import { lowerCase } from 'lodash';
import ChevronDown from 'assets/chevron_down.svg';

const BreadCrumbItem = ({
  title,
  value,
  isLast,
  to,
  onClick,
  onKeyDown,
  disabled
}) => {
  const itemTextContent = (
    <>
      <div
        className={`${disabled ? 'text-neutral-50' : 'text-gray-600'} text-sm`}
      >
        {title}
      </div>
      <div
        className={`${
          disabled ? 'text-neutral-50' : 'text-gray-900'
        } font-medium text-lg max-w-xs truncate`}
      >
        {value}
      </div>
    </>
  );

  const renderItemContent = () => {
    if (to) {
      return <NavLink to={to}>{itemTextContent}</NavLink>;
    }

    if (onClick && !disabled) {
      return (
        <div
          data-testid="breadcrumb-item"
          role="button"
          tabIndex={0}
          onClick={onClick}
          onKeyDown={onKeyDown}
        >
          <div className="text-gray-600 text-sm">{title}</div>
          <div className="text-gray-900 font-medium text-lg max-w-xs truncate">
            {value}
          </div>
        </div>
      );
    }

    return itemTextContent;
  };

  const lowerCaseValue = lowerCase(value);
  const dasherizedValue = lowerCaseValue
    ? lowerCaseValue.replace(/\s+/g, '-')
    : '';
  return (
    <div
      className={`px-3 flex items-center rounded py-1 ${
        to || !disabled ? 'hover:bg-gray-300' : 'bg-neutral-100'
      }`}
    >
      <div
        id={dasherizedValue}
        data-testid={dasherizedValue}
        className={`flex flex-col ${!isLast ? 'mr-6' : ''}`}
      >
        {renderItemContent()}
      </div>
      {!isLast ? (
        <div className="text-gray-600">
          <img src={ChevronDown} alt="expand collapse accordion" />
        </div>
      ) : null}
    </div>
  );
};

BreadCrumbItem.defaultProps = {
  title: null,
  value: null,
  isLast: false,
  to: null,
  onClick: () => {},
  onKeyDown: () => {},
  disabled: false
};

BreadCrumbItem.propTypes = {
  title: PropTypes.string,
  value: PropTypes.string,
  isLast: PropTypes.bool,
  to: PropTypes.string,
  onClick: PropTypes.func,
  onKeyDown: PropTypes.func,
  disabled: PropTypes.bool
};

export default BreadCrumbItem;
