const combineDuplicatedProducts = (inventoryData, masterlistData) => {
  const masterlistFiltered = masterlistData.reduce((prev, curr) => {
    const isItInThere = inventoryData.some(
      inventoryProduct => inventoryProduct.productId === curr.id
    );

    if (!isItInThere) {
      prev.push({
        ...curr,
        productName: curr.name,
        productType: curr.type.replace(/ /gi, ''),
        manufacturerName: curr.manufacturer
      });
    }

    return prev;
  }, []);

  return inventoryData
    .map(product => ({
      ...product,
      type: product.productType.toUpperCase()
    }))
    .concat(masterlistFiltered);
};

export default combineDuplicatedProducts;
