import { useState } from 'react';
import { createContainer } from 'react-tracked';

const initialState = {
  drawDistance: null,
  drawArea: null,
  coordinates: null,
  feature: null
};

const useMyState = () => useState(initialState);

export const {
  Provider: MapEventContext,
  useTracked: useSharedState
} = createContainer(useMyState);
