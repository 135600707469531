/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import { useFormikContext } from 'formik';

import { Search } from '@agconnections/grow-ui';

import useCropData from 'hooks/useCropData';
import GrowUIFormField from 'components/Forms/GrowUIFormField';

const CropZoneCropSelect = ({ cropId }) => {
  const { crops } = useCropData();

  const { setFieldValue } = useFormikContext();

  const cropItems = useMemo(
    () =>
      crops?.map(crop => ({
        key: crop.id,
        value: crop.name
      })) || [],
    [crops]
  );

  useEffect(() => {
    setFieldValue('crop', cropId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cropId]);

  return (
    <div className="-mt-8">
      <label
        htmlFor="crop"
        className="text-neutral-600 text-sm inline-block mb-1 "
      >
        Crop*
      </label>
      <GrowUIFormField
        name="crop"
        items={cropItems}
        control={Search}
        placeholder="Select Crop"
        onSelect={({ key, value }) => {
          setFieldValue('crop', key);
          setFieldValue('name', value);
        }}
      />
    </div>
  );
};

CropZoneCropSelect.propTypes = {
  cropId: PropTypes.string
};

CropZoneCropSelect.defaultProps = {
  cropId: ''
};

export default CropZoneCropSelect;
