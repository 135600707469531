import { fieldBodyGenerator } from 'components/PDF/helpers/tableGenerator';
import { convertNumberToDesiredFormat } from 'helpers/transformHelpers';
import { getAbbreviatedUnit } from 'helpers/unitsAbbreviation';

const DESCRIPTION_WIDTH = 40;
const REG_NO_WIDTH = 13;
const MANUFACTURER_WIDTH = 22;
const QUANTITY_WIDTH = 12;
const TOTAL_PRICE_WIDTH = 13;

export const INVOICE_PRODUCT_HEADER_ITEMS = [
  { label: 'Description', width: DESCRIPTION_WIDTH },
  { label: 'Reg. No.', width: REG_NO_WIDTH },
  { label: 'Manufacturer', width: MANUFACTURER_WIDTH },
  { label: 'Quantity', width: QUANTITY_WIDTH },
  { label: 'Total Price', width: TOTAL_PRICE_WIDTH, alignment: 'right' }
];

export const mapInvoiceProductsToTable = invoiceProductEntries => {
  const mappedInvoiceProducts = invoiceProductEntries.map(product => {
    const description = fieldBodyGenerator(product.productName, {
      width: `${DESCRIPTION_WIDTH}%`
    });
    const registrationNumber = fieldBodyGenerator(product.registrationNumber, {
      width: `${REG_NO_WIDTH}%`
    });
    const manufacturer = fieldBodyGenerator(product.manufacturer, {
      width: `${MANUFACTURER_WIDTH}%`
    });
    const quantity = fieldBodyGenerator(
      `${convertNumberToDesiredFormat(
        product.totalQuantityValue,
        'fixed'
      )} ${getAbbreviatedUnit(product.totalQuantityUnit)}`,
      {
        width: `${QUANTITY_WIDTH}%`
      }
    );
    const totalPricePerProduct = fieldBodyGenerator(
      convertNumberToDesiredFormat(product.totalCostValue, 'currency'),
      {
        width: `${TOTAL_PRICE_WIDTH}%`,
        textAlign: 'right'
      }
    );

    return [
      description,
      registrationNumber,
      manufacturer,
      quantity,
      totalPricePerProduct
    ];
  });

  const totalInvoiceCost = invoiceProductEntries?.reduce(
    (totalCost, { totalCostValue }) => {
      return totalCost + totalCostValue;
    },
    0
  );

  mappedInvoiceProducts.push([
    fieldBodyGenerator(
      `Total Price: ${convertNumberToDesiredFormat(
        totalInvoiceCost,
        'currency'
      )}`,
      {
        width: '100%',
        fontSize: 11,
        fontWeight: 'medium',
        paddingVertical: 6,
        textAlign: 'right'
      }
    )
  ]);

  return mappedInvoiceProducts;
};
