import React, { useContext, useEffect, useMemo, useRef, useState } from 'react';
import { Formik } from 'formik';
import { Spinner } from '@agconnections/grow-ui';
import Breadcrumb from 'components/App/AppShell/components/Breadcrumb';
import { DUMMY_SEASON } from 'helpers/constants';
import { Context } from 'components/Store';
import PAGINATION_SIZE from 'helpers/globalConstants';
import useCropSeasons from 'hooks/useCropSeasons';
import CwFPagination from 'components/CwFPagination';
import YieldSummary from './components/YieldSummary';
import YieldSummaryEmptyState from './components/YieldSummaryEmptyState';
import YieldSummaryHeader from './components/YieldSummaryHeader';
import YieldTable from './components/YieldTable';
import useYieldData from './hooks/useYieldData';
import initialValuesYieldDashboard from './helpers/initialValuesYieldDashboard';
import DeleteEntryModal from './components/YieldTable/components/DeleteEntryModal';
import getSeasons from './components/YieldActions/helpers/getSeasons';

const Yield = () => {
  const [isEmptyStateDisabled, setIsEmptyStateDisabled] = useState(false);
  const [pageNumber, setPageNumber] = useState(0);
  const [sortField, setSortField] = useState('entryName');
  const [sortDir, setSortDir] = useState('asc');
  const [selectedCropSeasons, setSelectedCropSeasons] = useState(new Set(''));
  const [selectedCommodity, onSelectCommodity] = useState(null);

  const [deleteYieldEntryId, setDeleteYieldEntryId] = useState();
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);

  const [{ loadingOrg, isGlobalCropSeasonLoading }] = useContext(Context);
  const selectedCommodityRef = useRef(null);

  const {
    yieldEntries,
    yieldEntriesCount,
    isLoadingYieldEntry,
    loadYieldEntries,
    isDeleteLoading,
    deleteYield
  } = useYieldData();

  const [displayedYieldEntries, setDisplayedYieldEntries] = useState(
    yieldEntries
  );

  const [displayedYieldEntriesCount, setDisplayedYieldEntriesCount] = useState(
    yieldEntriesCount
  );

  const { cropSeasons } = useCropSeasons();

  const handleSort = (field, dir) => {
    setSortField(field);
    setSortDir(dir);
    setPageNumber(0);
  };

  const handleCloseDeleteModal = () => {
    setDeleteModalOpen(false);
  };

  const handleDeleteClick = id => {
    setDeleteYieldEntryId(id);
    setDeleteModalOpen(true);
  };

  const handleDeleteConfirmation = async id => {
    await deleteYield(id);
    setDeleteModalOpen(false);
    if ((displayedYieldEntriesCount - 1) % PAGINATION_SIZE === 0) {
      setPageNumber(pageNumber - 1);
      return;
    }
    setDisplayedYieldEntries(prevValue =>
      prevValue.filter(entry => entry.id !== id)
    );
    setDisplayedYieldEntriesCount(displayedYieldEntriesCount - 1);
  };

  const getLocalStorageSeasonsSet = () => {
    return new Set(JSON.parse(localStorage.getItem('selectedCropSeasons')));
  };

  useEffect(() => {
    setSelectedCropSeasons(getLocalStorageSeasonsSet());
  }, []);

  useEffect(() => {
    setDisplayedYieldEntries(yieldEntries);
    setDisplayedYieldEntriesCount(yieldEntriesCount);
  }, [yieldEntries, yieldEntriesCount]);

  useEffect(() => {
    if (!selectedCommodity) {
      selectedCommodityRef.current = selectedCommodity;
      return;
    }
    const seasonIds = getSeasons();
    if (seasonIds.length) {
      loadYieldEntries({
        seasonIds,
        commodityId: selectedCommodity.id,
        fieldToOrder: sortField,
        orderingType: sortDir,
        page: pageNumber
      });
    }
    selectedCommodityRef.current = selectedCommodity;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedCommodity, cropSeasons, sortField, sortDir, pageNumber]);

  const handleOrgChange = () => {
    setSelectedCropSeasons(getLocalStorageSeasonsSet());
  };

  const cropItems = useMemo(() => {
    const cropPairs = [
      ...new Set(
        cropSeasons
          ?.filter(season => selectedCropSeasons.has(season?.id))
          .map(season => season.crops)
          .flat()
          .map(crop => `${crop?.id}:${crop?.name}`) ?? []
      )
    ];

    const crops = cropPairs
      .map(crop => crop.split(':'))
      .map(crop => ({ id: crop[0], name: crop[1] }))
      .filter(({ id }) => id !== 'undefined');
    return crops;
  }, [cropSeasons, selectedCropSeasons]);

  const tablePagination = displayedYieldEntries.length ? (
    <div className="mr-20px">
      {CwFPagination(displayedYieldEntriesCount, pageNumber, setPageNumber)}
    </div>
  ) : null;

  useEffect(() => {
    if (cropItems.length && !selectedCropSeasons.has(DUMMY_SEASON)) {
      setIsEmptyStateDisabled(false);
    } else {
      setIsEmptyStateDisabled(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cropItems]);

  return (
    <>
      <Breadcrumb
        onOrganizationSelect={handleOrgChange}
        hideCropSeasonDropdown={false}
        onCropSeasonSelect={handleOrgChange}
      >
        <Breadcrumb.Item title="Yield" value="Dashboard" isLast />
      </Breadcrumb>
      {loadingOrg || isGlobalCropSeasonLoading ? (
        <Spinner />
      ) : (
        <>
          <Formik initialValues={initialValuesYieldDashboard}>
            <div
              className="flex flex-col font-body"
              data-testid="yield-dashboard"
            >
              <YieldSummaryHeader
                disabled={isEmptyStateDisabled}
                cropItems={cropItems}
                selectedCommodity={selectedCommodity}
                onSelectCommodity={onSelectCommodity}
              />
              {isEmptyStateDisabled ? (
                <YieldSummaryEmptyState />
              ) : (
                <YieldSummary
                  selectedCommodity={selectedCommodityRef}
                  displayedYieldEntries={displayedYieldEntries}
                />
              )}
            </div>
          </Formik>
          {!isEmptyStateDisabled && cropItems.length ? (
            <div
              className="bg-white shadow-documentation-card rounded-lg mt-40px py-9px px-10px"
              data-testid="yield-table"
            >
              {isLoadingYieldEntry ? (
                <div className="my-40px">
                  <Spinner />
                </div>
              ) : (
                <>
                  {tablePagination}
                  <YieldTable
                    items={displayedYieldEntries}
                    sortField={sortField}
                    sortDir={sortDir}
                    handleSort={handleSort}
                    handleDeleteClick={handleDeleteClick}
                    setDeleteYieldEntryId={setDeleteYieldEntryId}
                  />
                  {tablePagination}
                </>
              )}
            </div>
          ) : (
            <div />
          )}
        </>
      )}
      <DeleteEntryModal
        open={deleteModalOpen}
        yieldEntryId={deleteYieldEntryId}
        close={handleCloseDeleteModal}
        handleDeleteConfirmation={handleDeleteConfirmation}
        title="Delete Yield Data?"
        confirmLabel="Delete"
        isSaving={isDeleteLoading}
      />
    </>
  );
};

export default Yield;
