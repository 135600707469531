import { useState } from 'react';
import { farm } from 'utilities/api';
import catchCancel from 'helpers/catchCancel';

const useFarmCost = () => {
  const [loading, setLoading] = useState(false);

  const getFarmCost = async ({ id, seasonIds }) => {
    setLoading(true);

    const farmApi = farm.createChildApi({
      action: `farm/${id}/cost`
    });

    const body = {
      seasonIds
    };

    const { promise } = farmApi.post(body);

    return promise
      .then(({ data }) => {
        setLoading(false);
        return data;
      })
      .catch(catchCancel)
      .catch(() => {
        setLoading(false);

        return [];
      });
  };

  return {
    getFarmCost,
    loading,
    setLoading
  };
};

export default useFarmCost;
