/* eslint-disable react/jsx-props-no-spreading */
import React, { useState } from 'react';
import PropTypes from 'prop-types';

import NotFound404 from 'components/ErrorHandler/NotFound404';

const statusSwitch = (status, { resourceName, btnText, btnLink }) => {
  if (status === 404)
    return (
      <NotFound404
        resourceName={resourceName}
        btnText={btnText}
        btnLink={btnLink}
      />
    );
  return null;
};

/**
 * wraps component with a AJAX Error Handler
 * @param {*} WrappedComponent - component to be wrapped
 * @param {*} options - overrride default errorHandler props (right nowmits just 404)
 */
const withAJAXErrorHandler = (WrappedComponent, options) => {
  const AJAXErrorWrapperComponent = props => {
    const [ajaxError, setAjaxError] = useState(null);
    if (ajaxError) return statusSwitch(ajaxError?.response?.status, options);
    return (
      <AjaxErrorContext.Provider value={{ ajaxError, setAjaxError }}>
        <WrappedComponent {...props} />
      </AjaxErrorContext.Provider>
    );
  };
  AJAXErrorWrapperComponent.defaultProps = {
    error: null
  };
  AJAXErrorWrapperComponent.propTypes = {
    error: PropTypes.shape({
      response: PropTypes.objectOf(PropTypes.any).isRequired
    })
  };
  return AJAXErrorWrapperComponent;
};

export default withAJAXErrorHandler;

export const AjaxErrorContext = React.createContext();
