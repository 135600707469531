import React from 'react';
import PropTypes from 'prop-types';
import { Spinner } from '@agconnections/grow-ui';
import { TagsCloudDisplay, TagsPopUpMessage } from 'components/TagsButton';
import { STORAGE_KEYS } from 'utilities/localStorage';
import ViewOnlyField from 'components/ViewOnlyField';
import ListWithPopup from 'components/ListWithPopup';
import CropSeasonField from '../CropSeasonField';
import CropField from '../CropField';

const PlanDetailsSection = ({ plan, displayTagsObject, switchingTags }) => {
  const {
    name,
    estimatedAreaValue,
    estimatedAreaUnit,
    cropId,
    cropSeasonIds,
    flagIds
  } = plan;

  const EmptyField = (
    <span className="text-2xl w-1/3" data-testid="without-tags">
      --
    </span>
  );

  const showTags = () =>
    flagIds?.length > 0 ? (
      <div className="text-2xl">
        <div className="mt-1">
          {displayTagsObject.extraTagsNames.length > 0 ? (
            <ListWithPopup
              position="bottom"
              ariaLabel="Task Tags"
              top="1.5rem"
              msg={
                <TagsPopUpMessage
                  extraTagsNames={displayTagsObject.extraTagsNames}
                />
              }
              component={
                <TagsCloudDisplay
                  displayTagsObject={displayTagsObject}
                  isLandingPage="view"
                  viewKey={STORAGE_KEYS.PLANS_VIEW}
                />
              }
            />
          ) : (
            <TagsCloudDisplay
              displayTagsObject={displayTagsObject}
              isLandingPage="view"
              viewKey={STORAGE_KEYS.PLANS_VIEW}
            />
          )}
        </div>
      </div>
    ) : (
      EmptyField
    );

  return (
    <>
      <div className="flex py-5">
        <div className="w-1/3">
          <ViewOnlyField header="Plan Name" className="w-1/3" text="text-lg" />
          <span className="text-2xl w-1/3">{name || '--'}</span>
        </div>
        <div className="w-1/3">
          <ViewOnlyField
            header="Planned Area"
            className="w-1/3"
            text="text-lg"
          />
          {estimatedAreaValue && estimatedAreaUnit ? (
            <span className="text-2xl w-1/3">
              {estimatedAreaValue} {estimatedAreaUnit}
            </span>
          ) : (
            EmptyField
          )}
        </div>
        <div className="w-1/3">
          <ViewOnlyField header="Crop" className="w-1/3" text="text-lg" />
          {cropId ? (
            <CropField cropId={cropId} cropStyle="text-2xl w-1/3" />
          ) : (
            EmptyField
          )}
        </div>
      </div>
      <div className="flex py-5">
        <CropSeasonField cropseasonId={cropSeasonIds[0]} />
        <div className="mb-6">
          <div data-testid="flags">
            <div className="text-gray-600">Tags</div>
            {switchingTags ? <Spinner size="sm" /> : showTags()}
          </div>
        </div>
      </div>
      <br />
    </>
  );
};

PlanDetailsSection.propTypes = {
  plan: PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.string,
    estimatedAreaValue: PropTypes.number,
    estimatedAreaUnit: PropTypes.string,
    cropId: PropTypes.string,
    cropSeasonIds: PropTypes.arrayOf(PropTypes.string),
    flagIds: PropTypes.arrayOf(PropTypes.string)
  }).isRequired,
  displayTagsObject: PropTypes.shape({
    extraTagsCount: PropTypes.number,
    extraTagsNames: PropTypes.arrayOf(PropTypes.string),
    tagsToDisplay: PropTypes.arrayOf(PropTypes.object)
  }).isRequired,
  switchingTags: PropTypes.bool
};

PlanDetailsSection.defaultProps = {
  switchingTags: false
};

export default PlanDetailsSection;
