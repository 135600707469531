import catchCancel from 'helpers/catchCancel';
import { parseServerError } from 'helpers/errorHelpers';

export const cleanupData = data => {
  const cleanedData = data;
  delete cleanedData.accessGranted;
  delete cleanedData.permissionLevel;
  delete cleanedData.memberSinceDateEpoch;
  delete cleanedData.memberSinceDate;
  return cleanedData;
};

export const update = (obj, api, setSubmitting, triggerRedirect, dispatch) => {
  const { promise, cancel } = api.update(obj.id, obj);
  promise
    .then(() => {
      setSubmitting(false);
      triggerRedirect();
    })
    .catch(catchCancel)
    .catch(parseServerError(dispatch))
    .finally(() => setSubmitting(false));

  return () => {
    dispatch({ type: 'SET_TOOLBAR', payload: null });
    cancel();
  };
};

export const create = (obj, api, setSubmitting, triggerRedirect, dispatch) => {
  const { promise, cancel } = api.create(obj);
  promise
    .then(() => {
      setSubmitting(false);
      triggerRedirect();
    })
    .catch(catchCancel)
    .catch(parseServerError(dispatch))
    .finally(() => setSubmitting(false));

  return () => {
    dispatch({ type: 'SET_TOOLBAR', payload: null });
    cancel();
  };
};

export const del = (obj, api, setSubmitting, triggerRedirect, dispatch) => {
  const { promise, cancel } = api.delete(obj);
  promise
    .then(() => {
      setSubmitting(false);
      triggerRedirect();
    })
    .catch(catchCancel)
    .catch(parseServerError(dispatch))
    .finally(() => setSubmitting(false));

  return () => {
    dispatch({ type: 'SET_TOOLBAR', payload: null });
    cancel();
  };
};
