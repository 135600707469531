import React from 'react';
import PropTypes from 'prop-types';
import { Text, View, StyleSheet, Image } from '@react-pdf/renderer';
import { stateTaskTranslations } from 'components/PDF/TaskPdf/utils/headerTranslations';
import { displayText } from 'components/PDF/helpers/pdfTools';

// Create styles
const styles = StyleSheet.create({
  headerContainer: {
    marginTop: 18
  },
  header: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    fontSize: 12,
    paddingHorizontal: 10
  },
  titleContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    marginBottom: 9
  },
  title: {
    marginRight: 10,
    fontSize: 12,
    fontWeight: 'semibold',
    fontFamily: 'Noto Sans'
  },
  text: {
    marginBottom: 6,
    fontSize: 10,
    fontFamily: 'Noto Sans'
  },
  status: {
    borderRadius: 4,
    color: 'white',
    paddingVertical: 2,
    paddingHorizontal: 13,
    textAlign: 'center',
    margin: 0,
    fontSize: 8,
    fontFamily: 'Noto Sans'
  },
  logo: {
    width: 'auto',
    height: 45,
    position: 'relative',
    right: -18
  },
  divider: {
    borderTop: '1 solid #A3A9B9',
    marginTop: 6,
    marginBottom: 21
  }
});

const statusList = {
  ready: {
    color: '#E99921',
    label: 'Ready'
  },
  inprogress: {
    color: '#5FD2C8',
    label: 'In Progress'
  },
  complete: {
    color: '#0071CD',
    label: 'Complete'
  }
};

// Create Document Component
const Header = ({
  title,
  status,
  organization,
  type,
  cropSeasons,
  cropName,
  exportLanguage = 'english'
}) => {
  const displayCropData = () => {
    const result = [];
    if (cropSeasons?.length) {
      result.push(cropSeasons.join(', '));
    }
    if (cropName) {
      result.push(cropName);
    }
    return result.join(' | ');
  };
  return (
    <View fixed style={styles.headerContainer}>
      <View style={styles.header}>
        <View>
          <View style={styles.titleContainer}>
            <Text style={styles.title}>{displayText(title)}</Text>
            {status && (
              <Text
                style={{
                  ...styles.status,
                  backgroundColor: statusList[status].color
                }}
              >
                {statusList[status].label === 'Ready'
                  ? stateTaskTranslations.ready[exportLanguage]
                  : statusList[status].label}
              </Text>
            )}
          </View>
          <Text style={styles.text}>
            {organization} | {type}
          </Text>
          <Text style={styles.text}>{displayCropData()}</Text>
        </View>
        <Image style={styles.logo} src="/Cropwise Product Logo.png" />
      </View>
      <View style={styles.divider} />
    </View>
  );
};

Header.propTypes = {
  title: PropTypes.string.isRequired,
  status: PropTypes.string,
  organization: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  cropSeasons: PropTypes.arrayOf(PropTypes.string),
  cropName: PropTypes.string,
  exportLanguage: PropTypes.string.isRequired
};

Header.defaultProps = {
  status: null,
  cropSeasons: [],
  cropName: null
};

export default Header;
