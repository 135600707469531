import React from 'react';
import PropTypes from 'prop-types';
import { Button } from '@agconnections/grow-ui';
import clipboardIcon from 'assets/clipboardIcon.png';
import AddRoundedIcon from '@material-ui/icons/AddRounded';

const NoCompaniesOverlay = ({ open, onClick, memberRole }) => {
  return (
    <>
      {open && (
        <div
          data-testid="property-overlay-modal"
          className="p-10 bg-white border rounded-none sm:rounded border-neutral"
        >
          <div className="flex flex-col justify-center">
            <div className="self-center">
              <img width="356" height="318" src={clipboardIcon} alt="" />
            </div>
            <div className="self-center mb-3">
              <p>
                We were unable to find any companies under this organization.
              </p>
            </div>
            <div className="self-center">
              {memberRole !== 'View Only' ? (
                <Button
                  icon={<AddRoundedIcon />}
                  iconLeft
                  onClick={onClick}
                  id="overlay-create-property-btn"
                  type="primary"
                >
                  Create Company
                </Button>
              ) : (
                <></>
              )}
            </div>
          </div>
        </div>
      )}
    </>
  );
};

NoCompaniesOverlay.defaultProps = {
  open: false,
  onClick: () => {}
};

NoCompaniesOverlay.propTypes = {
  open: PropTypes.bool,
  onClick: PropTypes.func,
  memberRole: PropTypes.string.isRequired
};

export default NoCompaniesOverlay;
