import React from 'react';
import PropTypes from 'prop-types';
import PropertyRow from 'screens/Property/PropertiesLanding/PropertyTable/PropertyRow';
import { Spinner } from '@agconnections/grow-ui';
import BuildIcon from 'assets/build-icon.svg';
import SaleLocationDetailsHeader from '../SaleLocationDetailsHeader';
import DetailsContainer from '../DetailsContainer';
import formatPhoneNumber from '../../helpers/formatPhoneNumber';

const SalesLocationDetails = ({
  selectedLocation,
  saleLocationList,
  setSaleLocationList,
  isFetchingSaleLocation
}) => {
  const _widthLabel = 'w-1/4';
  const _widthValue = 'w-3/4';
  const additionalClasses = 'mt-2 mb-4';
  const NoneEntered = 'None Entered';
  const {
    id,
    name,
    email,
    phone,
    addressLineOne,
    addressLineTwo,
    city,
    state,
    zipCode,
    notes,
    hasAssociatedYields
  } = selectedLocation || {};
  const { name: companyName } = selectedLocation.company || {};
  let fullAddress;

  if (addressLineOne || addressLineTwo || city || state || zipCode) {
    const addressLines = [
      addressLineOne && `${addressLineOne}\n`,
      addressLineTwo && `${addressLineTwo}\n`,
      city && `${city},`,
      state && `${state}`,
      zipCode && ` ${zipCode}`
    ]
      .filter(Boolean)
      .join(' ');

    fullAddress = addressLines.trim();
  }

  return (
    <div data-testid="sale-location-details" className="w-full h-full">
      {!isFetchingSaleLocation ? (
        <>
          <SaleLocationDetailsHeader
            header={name}
            saleLocationId={id}
            saleLocationList={saleLocationList}
            setSaleLocationList={setSaleLocationList}
            hasAssociatedYields={hasAssociatedYields}
          />
          <div className="flex flex-col items-center mt-2">
            <DetailsContainer
              key="generalInfo"
              optionalClasses={additionalClasses}
            >
              <PropertyRow
                label="Email Address"
                value={email}
                WidthLabel={_widthLabel}
                widthValue={_widthValue}
                ghostValue={!email}
              />
              <PropertyRow
                label="Phone"
                value={formatPhoneNumber(phone ?? '')}
                WidthLabel={_widthLabel}
                widthValue={_widthValue}
                ghostValue={!phone}
              />
              <PropertyRow
                label="Company"
                value={companyName}
                icon={BuildIcon}
                displayIcon={companyName}
                WidthLabel={_widthLabel}
                widthValue={_widthValue}
                ghostValue={!companyName}
              />
              <PropertyRow
                label="Address"
                value={fullAddress}
                WidthLabel={_widthLabel}
                widthValue={_widthValue}
                ghostValue={!fullAddress}
              />
            </DetailsContainer>
            <DetailsContainer key="noteInfo" className={additionalClasses}>
              <span className="font-semibold text-base">Notes</span>
              <p
                className={`text-sm font-normal mt-2 ${!notes &&
                  'text-gray-500'}`}
              >
                {!notes ? NoneEntered : notes}
              </p>
            </DetailsContainer>
          </div>
        </>
      ) : (
        <Spinner size="lg" />
      )}
    </div>
  );
};

SalesLocationDetails.propTypes = {
  selectedLocation: PropTypes.shape({
    id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    email: PropTypes.string,
    phone: PropTypes.string,
    company: PropTypes.shape(),
    addressLineOne: PropTypes.string,
    addressLineTwo: PropTypes.string,
    city: PropTypes.string,
    state: PropTypes.string,
    zipCode: PropTypes.string,
    notes: PropTypes.string
  }),
  saleLocationList: PropTypes.shape().isRequired,
  setSaleLocationList: PropTypes.func.isRequired,
  isFetchingSaleLocation: PropTypes.bool.isRequired
};

SalesLocationDetails.defaultProps = {
  selectedLocation: {
    email: '',
    phone: '',
    company: {},
    addressLineOne: '',
    addressLineTwo: '',
    city: '',
    state: '',
    zipCode: '',
    notes: ''
  }
};

export default SalesLocationDetails;
