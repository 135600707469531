import React, { useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Row, Col } from 'antd';

import CropIconMapping from 'components/CropIconMapping';
import ColorPicker from 'components/ColorPicker';
import { Context } from 'components/Store';
import createAction from 'helpers/createAction';
import {
  assignCropColors,
  getFieldCrops
} from 'screens/Property/helpers/propertyDataHelpers';
import { SET_CROP_COLORS } from 'reducers/reducer';
import { CROP_COLORS } from 'screens/Property/helpers/constants';
import { flatten, shuffle } from 'lodash';
import CollapsibleSection from '../CollapsibleSection';

const allCropColors = shuffle(flatten(CROP_COLORS).map(color => color.primary));

const CropColorsSection = ({ selectedFields }) => {
  const [crops, setCrops] = useState([]);
  const [{ cropColors }, dispatch] = useContext(Context);

  useEffect(() => {
    const fieldCrops = getFieldCrops(selectedFields);
    setCrops(fieldCrops);
    const updatedCropColors = assignCropColors(
      fieldCrops,
      cropColors,
      allCropColors
    );
    createAction(dispatch, SET_CROP_COLORS, updatedCropColors);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedFields, dispatch]);

  // If no selected crop zones, hide crop colors section
  if (!selectedFields.find(({ cropzones }) => cropzones.length)) {
    return null;
  }

  const onColorChange = (cropId, color) => {
    createAction(dispatch, SET_CROP_COLORS, { [cropId]: color });
  };

  return (
    <CollapsibleSection title="Crop colors">
      <div className="px-4 pb-6">
        {crops.map((crop, i) => (
          <Row
            key={crop.id}
            className={`h-8 ${i > 0 ? 'mt-4' : ''}`}
            align="middle"
          >
            <Col className="mr-2px">
              <CropIconMapping cropObject={crop} />
            </Col>
            <Col>{crop.name}</Col>
            <Col flex="auto" />
            {/* Color input */}
            <Col>
              <ColorPicker
                cropId={crop.id}
                value={cropColors[crop.id]}
                onChange={onColorChange}
              />
            </Col>
          </Row>
        ))}
      </div>
    </CollapsibleSection>
  );
};

CropColorsSection.propTypes = {
  selectedFields: PropTypes.arrayOf.isRequired
};

export default CropColorsSection;
