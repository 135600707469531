import React from 'react';
import PropTypes from 'prop-types';
import EmptyStateImage from 'assets/no_products_found.svg';

const EmptyTableState = ({
  title,
  message,
  overlayIcon,
  button,
  dataTestId = 'empty-table-state'
}) => (
  <div
    data-testid={dataTestId}
    className="w-full h-full flex flex-col justify-center items-center"
  >
    <img width={234} src={overlayIcon ?? EmptyStateImage} alt="empty-state" />
    <p
      className={`text-sm font-semibold mb-2 ${
        overlayIcon === '/static/media/noDocumentFound.e8c780e2.svg'
          ? 'mt-10'
          : null
      }`}
    >
      {title}
    </p>
    {message && (
      <p data-testid="empty-table-state-message" className="text-sm mb-4">
        {message}
      </p>
    )}
    {button}
  </div>
);

EmptyTableState.propTypes = {
  title: PropTypes.string,
  message: PropTypes.string,
  overlayIcon: PropTypes.element,
  button: PropTypes.element,
  dataTestId: PropTypes.string
};

EmptyTableState.defaultProps = {
  title: 'No Results Found',
  message: '',
  overlayIcon: null,
  button: null,
  dataTestId: 'empty-table-state'
};

export default EmptyTableState;
