/* eslint-disable no-unused-expressions */
import React, {
  useEffect,
  useContext,
  useState,
  useCallback,
  useRef
} from 'react';
import { useHistory } from 'react-router-dom';
import { noop, isEmpty } from 'lodash';
import { Spinner, Toast } from '@agconnections/grow-ui';
import { Context } from 'components/Store';
import LandingSearch from 'components/LandingSearch';
import Breadcrumb from 'components/App/AppShell/components/Breadcrumb';
import { FINANCIAL_ACCESSES } from 'utilities/access';
import LandingListViewToggle from 'components/LandingListViewToggle';
import { getLocalStorageApi, STORAGE_KEYS } from 'utilities/localStorage';
import { invoice as invoiceApi } from 'utilities/api';
import catchCancel from 'helpers/catchCancel';
import { parseServerError } from 'helpers/errorHelpers';
import useDebounce from 'hooks/useDebounce';
import useInvoices from './helpers/useInvoices';
import CreateInvoiceButton from './components/CreateInvoiceButton';
import InvoiceBoard from './components/InvoicesBoard';
import InvoiceTable from './components/InvoiceTable';
import InvoicesEmptyState from './components/InvoicesEmptyState';
import {
  INVOICES_DEFAULT_SORT,
  INVOICES_DEFAULT_VIEW_MODE
} from './helpers/constants';

const invoicesViewStorageApi = getLocalStorageApi(
  STORAGE_KEYS.INVOICES_VIEW,
  INVOICES_DEFAULT_VIEW_MODE
);

const Invoices = () => {
  const defaultTasksView = invoicesViewStorageApi.get();
  const history = useHistory();

  const [
    { loggedInUserOrgPermission, loadingOrg, isGlobalCropSeasonLoading },
    dispatch
  ] = useContext(Context);

  const [orgLoading, setOrgLoading] = useState(
    loggedInUserOrgPermission !== undefined
  );
  const [view, setView] = useState(
    defaultTasksView ?? INVOICES_DEFAULT_VIEW_MODE
  );
  const [reRenderInvoices, setReRenderInvoices] = useState(false);
  const [financialAccess, setFinancialAccess] = useState();
  const [toastHasNotBeenClosed, setToastHasNotBeenClosed] = useState(true);

  const [pageNo, setPageNo] = useState(0);
  const [filters, setFilters] = useState({});
  const [sortDir, setSortDir] = useState(INVOICES_DEFAULT_SORT.dir);
  const [sortBy, setSortBy] = useState(INVOICES_DEFAULT_SORT.by);
  const [searchText, setSearchText] = useState();
  const debouncedSearchTerm = useDebounce(searchText, 500);
  const { invoices, fetchInvoices } = useInvoices();

  const prevSearchTerm = useRef();
  const prevFilters = useRef();

  useEffect(() => {
    const currentFilters = JSON.stringify(filters);
    const hasFilterChange =
      debouncedSearchTerm !== prevSearchTerm.current ||
      currentFilters !== prevFilters.current;
    const shouldResetPage = hasFilterChange && pageNo !== 0;

    prevSearchTerm.current = debouncedSearchTerm;
    prevFilters.current = currentFilters;

    fetchInvoices({
      pageNo: shouldResetPage ? 0 : pageNo,
      searchBy: debouncedSearchTerm,
      cropSeasonIds: JSON.parse(localStorage.getItem('selectedCropSeasons')),
      startDate: filters?.daterange?.[0],
      endDate: filters?.daterange?.[1],
      sortDir,
      sortBy
    });

    if (shouldResetPage) setPageNo(0);
  }, [fetchInvoices, pageNo, debouncedSearchTerm, filters, sortDir, sortBy]);

  useEffect(() => {
    // maybe create a hook to handle these url filters
    const searchParams = new URLSearchParams(location.search);
    const searchParam = searchParams.get('search');
    const filtersParam = searchParams.get('filters');
    if (filtersParam) setFilters(JSON.parse(filtersParam));
    if (searchParam) setSearchText(searchParam);
  }, []);

  useEffect(() => {
    const updateUrlSearchParams = () => {
      const searchParams = new URLSearchParams();
      if (debouncedSearchTerm) {
        searchParams.set('search', debouncedSearchTerm);
      }
      if (Object.keys(filters).length) {
        searchParams.set('filters', JSON.stringify(filters));
      }
      history.replace({ search: searchParams.toString() });
    };
    updateUrlSearchParams();
  }, [debouncedSearchTerm, history, filters]);

  useEffect(() => {
    if (isEmpty(loggedInUserOrgPermission)) {
      setOrgLoading(false);
    } else {
      setOrgLoading(true);
    }

    if (
      (loggedInUserOrgPermission?.role === 'Full control' ||
        loggedInUserOrgPermission?.role === 'View Only') &&
      !Object.prototype.hasOwnProperty.call(
        loggedInUserOrgPermission,
        'financialAccess'
      )
    ) {
      setFinancialAccess('none');
    } else {
      setFinancialAccess(loggedInUserOrgPermission?.financialAccess);
    }
  }, [loggedInUserOrgPermission]);

  useEffect(() => {
    if (reRenderInvoices) {
      fetchInvoices({
        cropSeasonIds: JSON.parse(localStorage.getItem('selectedCropSeasons'))
      });
      setReRenderInvoices(false);
    }
  }, [reRenderInvoices, fetchInvoices]);

  const handleChangeView = newView => {
    setView(newView);
    invoicesViewStorageApi.save(newView);
  };

  const handleSort = useCallback((field, dir) => {
    setSortBy(field);
    setSortDir(dir);
  }, []);

  const handleDateFilterChange = useCallback(e => {
    const daterange = e.target.value;
    setFilters(prev => {
      if (daterange.length === 2) return { ...prev, daterange };
      if (daterange.length === 0) return { ...prev, daterange: [] };
      return prev;
    });
  }, []);

  const onDelete = invoiceId => {
    const { promise } = invoiceApi.delete(invoiceId);
    promise
      .then(() => {
        setReRenderInvoices(true);
      })
      .catch(catchCancel)
      .catch(err => {
        parseServerError(dispatch)(err);
      });
  };

  const hasPermission = () => {
    const role = loggedInUserOrgPermission?.role?.toLowerCase();
    const finAccess = FINANCIAL_ACCESSES.WRITE;
    return (
      (role === 'full control' || role === 'admin') && finAccess === 'WRITE'
    );
  };

  const showSpinner = () => {
    return loadingOrg || !orgLoading || isGlobalCropSeasonLoading;
  };

  const invoicesPageContent = () => {
    if (invoices.isLoading) {
      return <Spinner />;
    }

    if (invoices?.numberOfElements === 0) {
      return <InvoicesEmptyState hasPermission={hasPermission} />;
    }

    if (view === 'tile') {
      return (
        <InvoiceBoard
          invoices={invoices.data}
          setReRenderInvoices={setReRenderInvoices}
          memberRole={loggedInUserOrgPermission?.role}
          memberFinancialAccess={financialAccess}
          pageNo={pageNo}
          totalInvoices={invoices?.totalElements}
          onDelete={onDelete}
          onPageChange={setPageNo}
        />
      );
    }
    return (
      <InvoiceTable
        invoices={invoices.data}
        setReRenderInvoices={setReRenderInvoices}
        memberRole={loggedInUserOrgPermission?.role}
        memberFinancialAccess={financialAccess}
        pageNo={pageNo}
        totalInvoices={invoices?.totalElements}
        sortDir={sortDir}
        sortBy={sortBy}
        onDelete={onDelete}
        onPageChange={setPageNo}
        onSort={handleSort}
      />
    );
  };

  return (
    <div className="w-full h-full" data-testid="invoices">
      <Breadcrumb
        onOrganizationSelect={() => setReRenderInvoices(true)}
        hideCropSeasonDropdown={false}
        onCropSeasonSelect={() => setReRenderInvoices(true)}
      >
        <Breadcrumb.Item title="Invoices" value="All Invoices" isLast />
      </Breadcrumb>

      {location &&
      location.state &&
      location.state.toastRenderContents &&
      toastHasNotBeenClosed ? (
        <Toast
          isError={false}
          onClose={() => {
            setToastHasNotBeenClosed(false);
          }}
        >
          {location.state.toastRenderContents}
        </Toast>
      ) : null}

      {showSpinner() ? (
        <Spinner />
      ) : (
        <>
          <div className="flex items-center justify-between">
            <LandingSearch
              id="invoice-search-input"
              name="search_invoices"
              placeholder="Search name"
              showCropSeason={false}
              onChange={event => {
                setSearchText(event.target.value);
              }}
              onChangeLabel={noop}
              onChangeProductType={noop}
              onChangeTaskType={noop}
              onDateRangeChange={handleDateFilterChange}
            />

            <div className="flex items-center gap-4">
              <LandingListViewToggle
                testId="invoices-view-toggle"
                view={view}
                changeView={handleChangeView}
              />
              <CreateInvoiceButton hasPermission={hasPermission} />
            </div>
          </div>

          <div>{invoicesPageContent()}</div>
        </>
      )}
    </div>
  );
};

export default Invoices;
