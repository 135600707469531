export const currentFormattedDate = () =>
  new Date()
    .toLocaleDateString(undefined, {
      year: '2-digit',
      month: '2-digit',
      day: '2-digit',
      hour: 'numeric',
      minute: 'numeric',
      hour12: true
    })
    .replace(/\//g, '-')
    .replace(/,/g, '')
    .toUpperCase();

export const unitsList = [
  { key: 'bushels', value: 'bushels', symbol: 'bu' },
  { key: 'dryOunces', value: 'dry ounces', symbol: 'oz' },
  { key: 'grams', value: 'grams', symbol: 'g' },
  { key: 'kilograms', value: 'kilograms', symbol: 'kg' },
  { key: 'metricTons', value: 'metric tons', symbol: 'mt' },
  { key: 'pounds', value: 'pounds', symbol: 'lb' },
  { key: 'tons', value: 'tons', symbol: 'T' }
];

export const getUnitValue = unit =>
  unitsList.find(({ key }) => key === unit)?.value;

export const getUnitSymbol = unit =>
  unitsList.find(({ key }) => key === unit)?.symbol;

export const Imask = {
  mask: '\\$num',
  lazy: false,
  blocks: {
    num: {
      mask: Number,
      radix: '.',
      mapToRadix: ['.'],
      normalizeZeros: false,
      thousandsSeparator: ','
    }
  }
};

export const ImaskField = {
  mask: Number,
  lazy: false,
  radix: '.',
  mapToRadix: ['.'],
  normalizeZeros: false,
  thousandsSeparator: ','
};
