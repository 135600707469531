export const SET_STEP = 'SET_STEP';
export const SET_SELECTED_PROPERTIES = 'SET_SELECTED_PROPERTIES';
export const SET_SELECTED_COMMODITY = 'SET_SELECTED_COMMODITY';
export const SET_SHOW_TOAST = 'SET_SHOW_TOAST';
export const SET_TOAST_MESSAGE = 'SET_TOAST_MESSAGE';
export const SET_LOADING_FETCH = 'SET_LOADING_FETCH';

const reducer = (state, action) => {
  switch (action.type) {
    case SET_STEP:
      return {
        ...state,
        step: action.payload
      };
    case SET_SELECTED_PROPERTIES:
      return {
        ...state,
        cropZones: action.payload
      };
    case SET_SELECTED_COMMODITY:
      return {
        ...state,
        selectedCommodity: action.payload
      };
    case SET_SHOW_TOAST:
      return {
        ...state,
        showToast: action.payload
      };
    case SET_TOAST_MESSAGE:
      return {
        ...state,
        showToast: action.payload.isToastActive,
        typeToast: action.payload.typeToast,
        messageToast: action.payload.messageToast
      };
    case SET_LOADING_FETCH:
      return {
        ...state,
        isLoading: action.payload
      };
    default:
      return state;
  }
};

export default reducer;
