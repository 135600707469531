const getSubmissionButtonLabel = ({
  isLastStep,
  comingFromView,
  integrationType,
  nextLabel
}) => {
  if (isLastStep) {
    return `${comingFromView ? 'Resubmit' : 'Submit'} to ${integrationType}`;
  }
  return nextLabel;
};

export default getSubmissionButtonLabel;
