import React from 'react';

const FarmIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="18"
    height="18"
    viewBox="0 0 18 18"
    fill="none"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M7.5 3.68408L13.5 6.25551V9.75006H12V7.24461L7.5 5.31603L3 7.24461V9.75006H1.5V6.25551L7.5 3.68408Z"
      fill="#696F88"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M4.875 7.5H10.125V9.75H8.625V9H6.375V9.75H4.875V7.5Z"
      fill="#696F88"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M13.5 3.13169V9.75H12V3H12.75C12 3 12 2.99926 12 2.99852L12 2.99702L12 2.99396L12.0001 2.98755L12.0004 2.97367C12.0007 2.96394 12.0013 2.95326 12.002 2.94169C12.0036 2.91857 12.0062 2.89173 12.0105 2.86171C12.019 2.80179 12.0344 2.72814 12.0619 2.64564C12.1178 2.47805 12.2218 2.28004 12.4072 2.09467C12.7849 1.71691 13.3844 1.5 14.25 1.5C15.1156 1.5 15.7151 1.71691 16.0928 2.09467C16.2782 2.28004 16.3822 2.47805 16.4381 2.64564C16.4656 2.72814 16.481 2.80179 16.4895 2.86171C16.4938 2.89173 16.4964 2.91857 16.498 2.94169C16.4987 2.95326 16.4993 2.96394 16.4996 2.97367L16.4999 2.98755L16.5 2.99396L16.5 2.99702L16.5 2.99852C16.5 2.99926 16.5 3 15.75 3H16.5V9.75H15V3.13169C14.9766 3.11739 14.9386 3.09821 14.88 3.0787C14.7627 3.0396 14.5647 3 14.25 3C13.9353 3 13.7373 3.0396 13.62 3.0787C13.5614 3.09821 13.5234 3.11739 13.5 3.13169Z"
      fill="#696F88"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M16.5 12H1.5V10.5H16.5V12Z"
      fill="#696F88"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M16.5 14.25H1.5V12.75H16.5V14.25Z"
      fill="#696F88"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M16.5 16.5H1.5V15H16.5V16.5Z"
      fill="#696F88"
    />
  </svg>
);

export default FarmIcon;
