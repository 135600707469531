/* eslint-disable import/prefer-default-export */
import * as Yup from 'yup';
import { positiveNumberNullable } from 'screens/YieldV2/AddYield/helpers/validationUtils';

export const locationFormValidationSchema = Yup.object({
  name: Yup.string()
    .required('Location Name is required')
    .max(45, 'Must be 45 characters or less'),
  capacity: positiveNumberNullable('Capacity'),
  length: positiveNumberNullable('Length'),
  width: positiveNumberNullable('Width'),
  height: positiveNumberNullable('Height'),
  volume: positiveNumberNullable('Volume'),
  diameter: positiveNumberNullable('Diameter'),
  coneHeight: positiveNumberNullable('Cone Height')
});
