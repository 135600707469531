import { useState, useRef, useEffect, useCallback, useContext } from 'react';

import { Context } from 'components/Store';
import catchCancel from 'helpers/catchCancel';
import { parseServerError } from 'helpers/errorHelpers';
import {
  cropZone as cropZoneApi,
  cropZoneV2 as cropZoneApiV2,
  bulkCropZoneCreation as CropZoneBulkApi,
  createChildApi
} from 'utilities/api';
import sanitizeCropZone from '../helpers/sanitizeCropZone';

import {
  DEFAULT_ZOOM,
  NATIONAL_ZOOM,
  USA_CENTER_COORDINATES
} from '../helpers/constants';

const useCropZoneData = (id, useV2 = false) => {
  const [cropZone, setCropZone] = useState();
  const [state, setState] = useState();
  const [loading, setLoading] = useState(!!id);
  const [saving, setSaving] = useState(false);
  const [
    cropZoneCentroidCoordinates,
    setCropZoneCentroidCoordinates
  ] = useState();
  const [zoom, setZoom] = useState(DEFAULT_ZOOM);
  const [cropHistory, setCropHistory] = useState([]);
  const [productsServices, setProductsServices] = useState([]);
  const [variety, setVariety] = useState([]);
  const savingRef = useRef(false);

  const [{ cropSeasons }, dispatch] = useContext(Context);

  const fetchCropZone = useCallback(
    (cropZoneId = id, redirectOnError) => {
      setLoading(true);
      const { promise, cancel } = cropZoneApi.fetch(cropZoneId);
      promise
        .then(({ data }) => {
          const selectedCropSeasons =
            JSON.parse(localStorage.getItem('selectedCropSeasons')) || [];
          if (selectedCropSeasons.length > 0) {
            sanitizeCropZone(selectedCropSeasons, cropSeasons, data);
          }
          setCropZone(data);
        })
        .catch(catchCancel)
        .catch(parseServerError(dispatch, { showTryAgain: !!redirectOnError }))
        .finally(() => {
          setLoading(false);
        });
      return { promise, cancel };
    },
    [dispatch, cropSeasons, id]
  );

  const createBulkCropZone = useCallback(
    payload => {
      const { promise } = CropZoneBulkApi.create(payload);
      promise.catch(catchCancel).catch(err => {
        parseServerError(dispatch)(err);
      });

      return promise;
    },
    [dispatch]
  );

  const updateBulkCropZone = useCallback(
    payload => {
      const { promise } = CropZoneBulkApi.update(undefined, payload);
      promise.catch(catchCancel).catch(err => {
        parseServerError(dispatch)(err);
      });

      return promise;
    },
    [dispatch]
  );

  const deleteCropZone = useCallback(
    (cropZoneId = id) => {
      const { promise } = cropZoneApi.delete(cropZoneId);
      promise
        .then(({ data }) => {
          return data;
        })
        .catch(catchCancel);
      return promise;
    },
    [id]
  );

  useEffect(() => {
    let cleanupFunc;

    if (id) {
      const { cancel } = fetchCropZone();
      cleanupFunc = cancel;

      return () => {
        if (cleanupFunc) {
          cleanupFunc();
        }
      };
    }

    return cleanupFunc;
  }, [fetchCropZone, id]);

  // Get or compute the centroid value
  useEffect(() => {
    if (cropZone && !cropZone.boundedArea) {
      setZoom(NATIONAL_ZOOM);
      setCropZoneCentroidCoordinates(USA_CENTER_COORDINATES);
    }
  }, [cropZone]);

  const createCropZone = useCallback(
    cropZoneData => {
      const { promise } = cropZoneApi.create(cropZoneData);
      promise
        .then(({ data }) => {
          setCropZone(data);
        })
        .catch(catchCancel)
        .catch(parseServerError(dispatch));

      return promise;
    },
    [dispatch]
  );

  const updateCropZone = useCallback(
    (cropZoneData, cropZoneId = id) => {
      const { promise } = cropZoneApi.update(cropZoneId, cropZoneData);
      promise
        .then(({ data }) => {
          setCropZone({
            ...data
          });
        })
        .catch(catchCancel)
        .catch(parseServerError(dispatch));

      return promise;
    },
    [dispatch, id]
  );

  const editCropZone = useCallback(values => {
    if (savingRef.current) {
      return Promise.reject(new Error('Save is already in progress'));
    }
    setState('');
    setSaving(true);
    savingRef.current = true;
    const { promise } = cropZoneApi.update(values.id, values);

    promise
      .then(({ data }) => {
        setState('success');
        setCropZone(data);
        return data;
      })
      .catch(() => {
        setState('error');
        return null;
      })
      .finally(() => {
        savingRef.current = false;
        setSaving(false);
      });
    return promise;
  }, []);

  const makeRequest = (
    setStateFn,
    endpoint,
    v2PropertiesPostBody,
    defaultValue = []
  ) => {
    const createApiV1 = () => {
      const childApi = createChildApi(cropZoneApi, endpoint);

      return childApi.fetch();
    };

    const createApiV2 = () => {
      const childApi = createChildApi(cropZoneApiV2, endpoint);

      return childApi.create(v2PropertiesPostBody);
    };

    const { promise } = useV2 ? createApiV2() : createApiV1();

    promise
      .then(({ data }) => {
        setStateFn(data.results);
      })
      .catch(err => {
        if (err.response?.status !== 404) {
          parseServerError(dispatch)(err);
          catchCancel(err);
        } else {
          setStateFn(defaultValue);
        }
      });
  };

  const getCropZoneData = () => {
    return {
      getAppliedProducts(endpoint, v2PropertiesPostBody = {}) {
        makeRequest(setProductsServices, endpoint, v2PropertiesPostBody);
      },
      getVariety(endpoint, v2PropertiesPostBody = {}) {
        makeRequest(setVariety, endpoint, v2PropertiesPostBody);
      },
      getCrophistory(endpoint, v2PropertiesPostBody = {}) {
        makeRequest(setCropHistory, endpoint, v2PropertiesPostBody);
      }
    };
  };

  return {
    cropZone,
    cropZoneCentroidCoordinates,
    setCropZoneCentroidCoordinates,
    fetchCropZone,
    loading,
    saving,
    state,
    createCropZone,
    editCropZone,
    updateCropZone,
    zoom,
    setZoom,
    getCropZoneData,
    productsServices,
    cropHistory,
    variety,
    deleteCropZone,
    createBulkCropZone,
    updateBulkCropZone
  };
};

export default useCropZoneData;
