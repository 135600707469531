import React, { createContext, useReducer } from 'react';
import PropTypes from 'prop-types';
import { INTEGRATIONS } from 'screens/Integrations/helpers/constants';
import useMcCainData from 'hooks/useMcCainData';
import { useAuth } from 'utilities/base-auth';
import reducer, {
  SET_STEP,
  SET_SELECTED_PROPERTIES,
  SET_OPEN_SUBMISSION_MODAL,
  SET_OPEN_RESUBMISSION_MODAL,
  SET_SUBMISSION_FLOW_ID
} from '../reducer';
import {
  getFirstLatLongCoordinates,
  getFlatLatLongCoordinates
} from '../../helpers/extractCoordinates';

export const initialState = {
  integrationStatus: INTEGRATIONS.all,
  step: 0,
  propertiesGroupedBy: 'property',
  selectedProperties: [],
  openSubmissionModal: false,
  openReSubmissionModal: false,
  submissionFlowId: null
};

export const IntegrationsContext = createContext(initialState);

const IntegrationsProvider = ({ children }) => {
  const { user } = useAuth();

  const [state, dispatch] = useReducer(reducer, initialState);

  const { createMcCain, isLoading } = useMcCainData();

  const goBack = (specificStep = null) => {
    dispatch({
      type: SET_STEP,
      payload: specificStep || state.step - 1
    });
  };

  const goForward = (specificStep = null) => {
    dispatch({
      type: SET_STEP,
      payload: specificStep || state.step + 1
    });
  };

  const updateSelectedProperties = selectedProperties => {
    dispatch({
      type: SET_SELECTED_PROPERTIES,
      payload: selectedProperties
    });
  };

  const updateSubmissionFlowId = submissionFlowId => {
    dispatch({
      type: SET_SUBMISSION_FLOW_ID,
      payload: submissionFlowId
    });
  };

  const createCropZoneBoundaries = coordinates => {
    return getFlatLatLongCoordinates(coordinates).map((flatCoordinates, i) => ({
      longitude: flatCoordinates[0],
      latitude: flatCoordinates[1],
      sequenceNumber: i + 1
    }));
  };

  const selectCorrectCropSeason = (selectedCropSeasons, cropZoneId) => {
    let startDateEpoch = 0;
    selectedCropSeasons.forEach(cropSeason => {
      if (!cropSeason.cropZoneIds.includes(cropZoneId)) {
        startDateEpoch = cropSeason.startDateEpoch;
      }
    });

    if (startDateEpoch === 0) {
      startDateEpoch = selectedCropSeasons[0].startDateEpoch;
    }

    const date = new Date(startDateEpoch);
    return date.getFullYear();
  };

  const createMcCainPayload = mccainValues => {
    return {
      id: user?.id,
      fmsVendor: 'Agconnections',
      vendorNumber: mccainValues.mccainVendorId,
      name: user?.name,
      country: 'United States',
      growerContinent: 'North America',
      email: user?.email,
      farm: mccainValues.selectedProperties.map(farm => {
        return {
          id: farm.id,
          name: farm.name,
          field: farm.fields.map(field => {
            const coordinates =
              field.geometry?.coordinates ??
              field.cropzones[0]?.geometry?.coordinates;
            const [longitude, latitude] = getFirstLatLongCoordinates(
              coordinates
            );

            return {
              id: field.id,
              name: field.name,
              officialArea: field.reportedArea || field.boundaryArea,
              unitOfMeasure: 'acre',
              soilType: '',
              longitude,
              latitude,
              cropZone: field.cropzones.map(cropzone => {
                return {
                  id: cropzone.id,
                  name: `${field.name} - ${cropzone.name}`,
                  boundaries: createCropZoneBoundaries(
                    cropzone.geometry.coordinates
                  ),
                  longitude,
                  latitude,
                  acreage: cropzone.reportedArea || cropzone.boundaryArea,
                  unitOfMeasure: 'acre',
                  products: cropzone.products,
                  season: selectCorrectCropSeason(
                    mccainValues.selectedCropSeasons,
                    cropzone.id
                  )
                };
              })
            };
          })
        };
      })
    };
  };

  const submitToMcCain = async mccainValues => {
    const body = createMcCainPayload(mccainValues);
    const response = await createMcCain(body);

    if (response?.status === 201) {
      dispatch({
        type: SET_STEP,
        payload: 4
      });
    }
  };

  const setModalSubmissionState = bool => {
    dispatch({ type: SET_OPEN_SUBMISSION_MODAL, payload: bool });
  };

  const setModalResubmissionState = bool => {
    dispatch({ type: SET_OPEN_RESUBMISSION_MODAL, payload: bool });
  };

  return (
    <IntegrationsContext.Provider
      value={{
        state,
        dispatch,
        goBack,
        goForward,
        updateSelectedProperties,
        updateSubmissionFlowId,
        submitToMcCain,
        isLoading,
        setModalSubmissionState,
        setModalResubmissionState
      }}
    >
      {children}
    </IntegrationsContext.Provider>
  );
};

IntegrationsProvider.defaultProps = {
  children: null
};

IntegrationsProvider.propTypes = {
  children: PropTypes.node
};

export default IntegrationsProvider;
