import { Maths } from 'helpers/maths';
import { YIELD_DEFAULT_ROUND } from 'screens/YieldV2/constants';
import sanitizeAndConvertToNumber from './sanitizeAndConvertToNumber';

/**
 * Calculates the tare weight based on gross weight and net weight,
 * using sanitized values and performing rounded subtraction.
 *
 * @param {Object} params - The parameters for calculating the tare weight.
 * @param {number} params.grossWeight - The gross weight value.
 * @param {number} params.netWeight - The net weight value.
 * @returns {number} The calculated tare weight, or null if calculation is not possible.
 */
const calculateTareWeight = ({ grossWeight, netWeight }) => {
  if (!grossWeight || !netWeight) return null;
  const sanitizedGrossWeight = sanitizeAndConvertToNumber(grossWeight);
  const sanitizedNetWeight = sanitizeAndConvertToNumber(netWeight);

  if (sanitizedGrossWeight === null || sanitizedNetWeight === null) {
    return null;
  }

  return Maths.minusRound(
    sanitizedGrossWeight,
    sanitizedNetWeight,
    YIELD_DEFAULT_ROUND
  );
};

export default calculateTareWeight;
