// this is for transforming the weight unit as BE expect in payload.
// because FE send Units as (lbs,oz,t) and BE expects (lb,ounce,ton)
export const transformWeightUnit = weightUnit => {
  switch (weightUnit) {
    case 'lbs':
      return 'lb';
    case 'oz':
      return 'ounce';
    case 't':
      return 'ton';
    default:
      return weightUnit;
  }
};

// this is for transforming the weight unit as FE expect.
// because BE send Units as (lb,ounce,ton) and FE expects (lbs,oz,t)
export const transformWeightUnitToOriginal = weightUnit => {
  switch (weightUnit) {
    case 'pounds':
      return 'lbs';
    case 'lb':
      return 'lbs';
    case 'ounce':
      return 'oz';
    case 'ton':
      return 't';
    default:
      return weightUnit;
  }
};
