import { replaceUndefinedAndEmptyWithNull } from 'utilities/helpers';

const prepareLocationValuesToPayload = values => {
  const f = x => (typeof x === 'string' && x.trim().length > 0 ? +x : x);
  const dimensions = {
    volume: null,
    height: null,
    length: null,
    width: null,
    diameter: null,
    coneHeight: null
  };

  const shape = values.shape?.toLowerCase();

  if (shape && shape !== 'none') {
    dimensions.volume = f(values.volume);
    dimensions.height = f(values.height);
  }

  if (shape === 'flat') {
    dimensions.length = f(values.length);
    dimensions.width = f(values.width);
  }

  if (shape === 'round') {
    dimensions.diameter = f(values.diameter);
    dimensions.coneHeight = f(values.coneHeight);
  }

  const valuesToSave = {
    ...values,
    capacity: f(values.capacity),
    ...dimensions
  };

  return replaceUndefinedAndEmptyWithNull(valuesToSave);
};

export default prepareLocationValuesToPayload;
