import React from 'react';
import PropTypes from 'prop-types';
import { SimpleModal, Table } from '@agconnections/grow-ui';
import './index.css';

import FormattedNumber from 'components/FormattedNumber';

const DeleteCropZoneModalSummaryConflicts = ({
  open,
  onClose,
  cropzonesToDelete
}) => {
  return (
    <>
      <SimpleModal
        open={open}
        onCancel={onClose}
        onClose={onClose}
        onConfirm={onClose}
        close={onClose}
        type="basic"
        title="Delete CropZones"
      >
        <h1 className="mt-3 font-body">
          The following selected crop zones cannot be deleted due to associated
          records:
        </h1>
        <div className="table-layout mt-4">
          <Table>
            {cropzonesToDelete.map(cropZone => (
              <Table.Row>
                <Table.Cell>
                  <p className="font-body">{cropZone.farmName}</p>
                </Table.Cell>
                <Table.Cell>
                  <p className="font-body">{cropZone.fieldName}</p>
                </Table.Cell>
                <Table.Cell>
                  <div className="w-full font-body">
                    <p className="text-left">{cropZone.name}</p>
                    <p className="text-neutral-600 text-sm text-left mt-2">
                      <FormattedNumber roundedTo={2}>
                        {cropZone.boundaryArea}
                      </FormattedNumber>
                      {` • ${cropZone.crop.name}`}
                    </p>
                  </div>
                </Table.Cell>
              </Table.Row>
            ))}
          </Table>
        </div>
      </SimpleModal>
    </>
  );
};

DeleteCropZoneModalSummaryConflicts.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  cropzonesToDelete: PropTypes.arrayOf(PropTypes.object).isRequired
};

export default DeleteCropZoneModalSummaryConflicts;
