import { useState, useEffect } from 'react';
import { sum, mean, identity, mapValues } from 'lodash';
import { useField } from 'formik';

import { CHECKSTATE, NEXT_CHECKSTATE } from 'helpers/checkboxHelpers';
import {
  createAppliedAreaMap,
  createCoverageMap,
  createAcresMap
} from '../helpers/areaMapping';
import { createParentChildMap } from '../helpers/selectionMapping';

const useAreaMapping = (landItems, selectionMap) => {
  const [taField, , taHelpers] = useField('totalSelectedArea');
  const { value: totalSelectedArea } = taField;
  const { setValue: setTotalSelectedArea } = taHelpers;

  const [{ value: headerCoverage }, , covHelpers] = useField('coverage');
  const { setValue: setHeaderCoverage } = covHelpers;

  const [rowTouched, setRowTouched] = useState(false);
  const [isBaseLocked, setIsBaseLocked] = useState(true);
  const [baseAppliedAreaMap, setBaseAppliedAreaMap] = useState({});
  const [parentChildMap, setParentChildMap] = useState({});
  const [appliedAreaMap, setAppliedAreaMap] = useState({});
  const [coverageMap, setCoverageMap] = useState({});
  const [areaMap, setAreaMap] = useState({});

  const isUnchecked = id => selectionMap[id] === CHECKSTATE.unchecked;
  const hasArea = id => !!areaMap[id];
  const isSelectedAndHasArea = id => !isUnchecked(id) && hasArea(id);

  const orgId = landItems[0] ? landItems[0].id : null;

  // when base changes reset header values
  useEffect(() => {
    if (!orgId) return;

    setTotalSelectedArea(baseAppliedAreaMap[orgId]);
    setHeaderCoverage(100);
    // Formik useFields is not using a React.useCallback, Please don't fix this until this issue is resolved. It will cause an infinite loop (see: https://github.com/formium/formik/issues/2268)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [baseAppliedAreaMap, orgId]);

  useEffect(() => {
    setAppliedAreaMap(createAppliedAreaMap(landItems));
    setCoverageMap(createCoverageMap(landItems));
    setParentChildMap(createParentChildMap(landItems));
    setAreaMap(createAcresMap(landItems));
  }, [setAppliedAreaMap, setCoverageMap, setParentChildMap, landItems]);

  useEffect(() => {
    if (!isBaseLocked) setBaseAppliedAreaMap({ ...appliedAreaMap });
  }, [appliedAreaMap, isBaseLocked]);

  const areaAncestorReaction = (
    id,
    area,
    _areaMap,
    formula,
    ignoreCheckState = false
  ) => {
    let newAreaMap = {};
    const { parentId } = parentChildMap[id];
    if (parentId) {
      const { childrenIds: siblingIds } = parentChildMap[parentId];
      const selectedSiblingIds = siblingIds.filter(
        _id =>
          ((ignoreCheckState && _id === id) || !isUnchecked(_id)) &&
          hasArea(_id)
      );
      const childrenAreas = selectedSiblingIds.map(_id =>
        _id === id ? area : _areaMap[_id]
      );
      const newParentArea = formula(childrenAreas, parentId);
      newAreaMap = {
        [parentId]: newParentArea,
        ...areaAncestorReaction(
          parentId,
          newParentArea,
          _areaMap,
          formula,
          ignoreCheckState
        )
      };
    }
    return newAreaMap;
  };

  const areaDescendantReaction = (
    id,
    area,
    formula,
    ignoreCheckState = false
  ) => {
    const { childrenIds } = parentChildMap[id];
    const selChildrenIds = childrenIds.filter(
      _id => (ignoreCheckState || !isUnchecked(_id)) && hasArea(id)
    );
    return selChildrenIds.reduce((descendantMap, childId) => {
      const value = formula(area, selChildrenIds);
      return {
        ...descendantMap,
        [childId]: value,
        ...areaDescendantReaction(childId, value, formula, ignoreCheckState)
      };
    }, {});
  };

  const setRowAppliedArea = (id, area, lockBase = false) => {
    setRowTouched(true);
    setIsBaseLocked(lockBase);
    const divideEvenly = (val, children) => val / children.length;

    const ancestorMap = areaAncestorReaction(id, area, appliedAreaMap, sum);
    const descendantMap = areaDescendantReaction(id, area, divideEvenly);
    setAppliedAreaMap({
      ...appliedAreaMap,
      ...ancestorMap,
      ...descendantMap,
      ...(isSelectedAndHasArea(id) ? { [id]: area } : null)
    });
  };

  const setRowCoverage = (id, coverage, lockBase = false) => {
    setRowTouched(true);
    setIsBaseLocked(lockBase);

    const ancestorMap = areaAncestorReaction(id, coverage, coverageMap, mean);
    const descendantMap = areaDescendantReaction(id, coverage, identity);
    setCoverageMap({
      ...coverageMap,
      ...ancestorMap,
      ...descendantMap,
      ...(isSelectedAndHasArea(id) ? { [id]: coverage } : null)
    });
  };

  const setItemAndDescendants = (id, valueOrFn) => {
    const value = typeof valueOrFn === 'function' ? valueOrFn(id) : valueOrFn;
    const { childrenIds } = parentChildMap[id];
    return childrenIds.reduce((descendantMap, childId) => {
      return {
        ...descendantMap,
        [childId]: value,
        ...setItemAndDescendants(childId, valueOrFn)
      };
    }, {});
  };

  const onSelectDescendantReaction = (id, nextState, defaultValOrFn) => {
    if (nextState === CHECKSTATE.checked) {
      return setItemAndDescendants(id, defaultValOrFn);
    }
    return setItemAndDescendants(id, 0);
  };

  // sets row area and descendents to 0 or default value, based on selection state
  const defaultRowAreaFields = id => {
    setRowTouched(true);
    // if area value is invalid don't default appliedArea or coverage row, ancestors or descendants.
    if (areaMap[id] === 0) return;
    setIsBaseLocked(false);

    const nextState = NEXT_CHECKSTATE[selectionMap[id]];

    const acres = areaMap[id];
    const appliedAcresVal = nextState !== CHECKSTATE.unchecked ? acres : 0;
    const newAppliedAreaMap = {
      ...appliedAreaMap,
      [id]: nextState !== CHECKSTATE.unchecked ? acres : 0,
      ...areaAncestorReaction(id, appliedAcresVal, appliedAreaMap, sum, true),
      ...onSelectDescendantReaction(id, nextState, _id => areaMap[_id])
    };
    setAppliedAreaMap(newAppliedAreaMap);

    const covFormula = (arr, parentId) =>
      (newAppliedAreaMap[parentId] / areaMap[parentId]) * 100;

    const coverageVal = nextState !== CHECKSTATE.unchecked ? 100 : 0;
    setCoverageMap({
      ...coverageMap,
      [id]: coverageVal,
      ...areaAncestorReaction(id, coverageVal, coverageMap, covFormula, true),
      ...onSelectDescendantReaction(id, nextState, 100)
    });
  };

  const applyHeaderCoverage = coverageFactor => {
    setIsBaseLocked(true);

    const newCoverageMap = mapValues(
      coverageMap,
      coverage => coverage * coverageFactor
    );

    const newAppliedAreaMap = mapValues(
      baseAppliedAreaMap,
      area => area * coverageFactor
    );

    setCoverageMap(newCoverageMap);
    setAppliedAreaMap(newAppliedAreaMap);
  };

  return {
    appliedAreaMap,
    applyHeaderCoverage,
    baseAppliedAreaMap,
    coverageMap,
    defaultRowAreaFields,
    headerCoverage,
    rowTouched,
    setHeaderCoverage,
    setRowAppliedArea,
    setRowCoverage,
    setTotalSelectedArea,
    totalSelectedArea
  };
};

export default useAreaMapping;
