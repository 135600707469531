/* eslint-disable no-param-reassign */
import React, { useState, useContext, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useField } from 'formik';

import { Select } from '@agconnections/grow-ui';
import GrowUIFormField from 'components/Forms/GrowUIFormField';
import { AmplitudeContext } from 'utilities/amplitude/useAmplitude';
import CreatePropertyModal from '../CreatePropertyModal';

const farmOptionMapper = ({ id, name }) => ({ key: id, value: name });

const ADD_FARM_KEY = '_ADD_';

const getFarmOptionsWithAddOption = farms =>
  [{ key: ADD_FARM_KEY, value: '+ Create New Farm' }].concat(
    farms.map(farmOptionMapper)
  );

const PropertySelector = ({
  items,
  onCreate,
  selectedValue,
  isDisabled = false,
  fieldsAndAreasGeoJSONCollection
}) => {
  const amplitude = useContext(AmplitudeContext);
  const [addModalIsOpen, setAddModalIsOpen] = useState(false);
  const [isSaving, setIsSaving] = useState(false);
  const [, , { setValue: setFarm }] = useField('propertyId');

  useEffect(() => {
    setFarm(selectedValue || '');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedValue]);

  const handleCloseAddModal = () => setAddModalIsOpen(false);

  const handleFarmSelect = ({ key }) => {
    if (key === ADD_FARM_KEY) {
      setAddModalIsOpen(true);
      setFarm('');
    }
  };

  const makeAllFieldsFocused = geoJSONCollection => {
    return geoJSONCollection?.features?.map(feature => {
      if (Object.prototype.hasOwnProperty.call(feature.properties, '$layer')) {
        delete feature.properties.$layer;
        return (feature.properties.$layer = 'focused');
      }
      return (feature.properties.$layer = 'focused');
    });
  };

  const handleCreateFarm = async name => {
    // amplitude trigger
    amplitude.sendEventToAmplitude(
      amplitude.events.epic.Properties.createProperty
    );
    const flag = await onCreate(name).then(newFarm => {
      setFarm(newFarm.id);
      makeAllFieldsFocused(fieldsAndAreasGeoJSONCollection);
    });
    if (!flag) {
      setAddModalIsOpen(false);
      setIsSaving(false);
    }
  };

  return (
    <div data-testid="property-create-selector">
      <CreatePropertyModal
        open={addModalIsOpen}
        onCancel={handleCloseAddModal}
        onCreate={handleCreateFarm}
        setIsSaving={setIsSaving}
        isSaving={isSaving}
      />
      <GrowUIFormField
        style={{ overflow: 'hidden' }}
        control={Select}
        name="propertyId"
        label="Farm*"
        placeholder="Select a Farm"
        items={getFarmOptionsWithAddOption(items)}
        onSelect={handleFarmSelect}
        disabled={isDisabled}
      />
    </div>
  );
};

PropertySelector.propTypes = {
  items: PropTypes.arrayOf(PropTypes.object).isRequired,
  onCreate: PropTypes.func.isRequired,
  selectedValue: PropTypes.string,
  isDisabled: PropTypes.bool,
  fieldsAndAreasGeoJSONCollection: PropTypes.objectOf(PropTypes.object)
    .isRequired
};

PropertySelector.defaultProps = {
  selectedValue: null,
  isDisabled: false
};

export default PropertySelector;
