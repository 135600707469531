export const CHECKSTATE = {
  checked: 'checked',
  indeterminate: 'indeterminate',
  unchecked: 'unchecked'
};

export const NEXT_CHECKSTATE = {
  [CHECKSTATE.unchecked]: CHECKSTATE.checked,
  [CHECKSTATE.indeterminate]: CHECKSTATE.checked,
  [CHECKSTATE.checked]: CHECKSTATE.unchecked
};

export const isChecked = val => val === CHECKSTATE.checked;
export const isUnChecked = val => val === CHECKSTATE.unchecked;
export const isIndeterm = val => val === CHECKSTATE.indeterminate;
