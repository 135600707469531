import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import ClickableDiv from 'components/ClickableDiv';
import SortIcon from 'assets/sort.svg';
import { Button } from '@agconnections/grow-ui';

const SortButton = ({ sortItems, onSort, selectedItem }) => {
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const dropdownRef = useRef(null);

  const openDiv = () => {
    setIsDropdownOpen(!isDropdownOpen);
  };

  const handleSelection = (index, type, direction) => {
    onSort({ sortBy: type, sortDir: direction });
    setIsDropdownOpen(false);
  };

  useEffect(() => {
    const handleClickOutside = event => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsDropdownOpen(false);
      }
    };

    document.addEventListener('click', handleClickOutside);

    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, []);

  const effectiveSelectedItemIndex = selectedItem
    ? sortItems.indexOf(
        sortItems.find(
          x =>
            x.sortKey === selectedItem.sortBy &&
            x.sortDirection === selectedItem.sortDir
        )
      )
    : -1;

  return (
    <div style={{ width: '100%' }} ref={dropdownRef}>
      <Button onClick={openDiv} style={{ width: '100%' }} type="outline" ghost>
        <div className="flex flex-row relative bg-color">
          <img className="mr-1" src={SortIcon} alt="Sort Icon" />
        </div>
        Sort
      </Button>

      {isDropdownOpen && (
        <div className="h-35 absolute bg-white z-50 mt-2 shadow-card rounded ">
          <div className="w-full h-full flex flex-col justify-center">
            {sortItems?.map((element, index) => (
              <ClickableDiv
                key={element.sortKey}
                className={`${
                  index === effectiveSelectedItemIndex ? 'bg-selectedBlue' : ''
                } "h-8 gr-whitespace-nowrap `}
                onClick={() =>
                  handleSelection(index, element.sortKey, element.sortDirection)
                }
              >
                <div
                  className={`w-full h-full p-2 ${index ===
                    effectiveSelectedItemIndex && 'font-semibold'}`}
                >
                  {element.sortLabel}
                </div>
              </ClickableDiv>
            ))}
          </div>
        </div>
      )}
    </div>
  );
};

SortButton.defaultProps = {
  selectedItem: ''
};

SortButton.propTypes = {
  sortItems: PropTypes.arrayOf(
    PropTypes.shape({
      sortLabel: PropTypes.string.isRequired,
      sortKey: PropTypes.string.isRequired,
      sortDirection: PropTypes.string.isRequired
    })
  ).isRequired,
  selectedItem: PropTypes.shape({
    sortBy: PropTypes.string,
    sortDir: PropTypes.string
  }),
  onSort: PropTypes.func.isRequired
};

export default SortButton;
