import { Button } from '@agconnections/grow-ui';
import PropTypes from 'prop-types';
import React, { useContext } from 'react';
import { AddRounded } from '@material-ui/icons';
import StorageLocationIcon from 'assets/storage.svg';
import SortButton from '../../../SortButton';
import sortItems from '../../../../helpers/sortValuesStorageLocation';
import { StorageLocationContext } from '../../../../context/StorageLocationProvider';

const StorageLocationListHeader = ({ onCreateNew }) => {
  const { applySort, state } = useContext(StorageLocationContext);
  return (
    <div className="pb-4 sticky top-0 z-4 pt-5 white-bg px-5 border-b border-neutral-20">
      <div className="flex items-center justify-start mb-4">
        <img alt="Field Icon" src={StorageLocationIcon} />
        <h4 className="text-2xl font-semibold leading-none ml-2">Storage</h4>
      </div>
      <div className="flex items-center justify-center mb-1 space-x-4">
        <div className="flex-1">
          <Button
            type="primary"
            onClick={onCreateNew}
            style={{ width: '100%' }}
            icon={<AddRounded style={{ width: '70%' }} />}
          >
            <span style={{ marginLeft: '-4px' }}>Create New</span>
          </Button>
        </div>
        <div className="flex-1">
          <SortButton
            className="w-full"
            selectedItem={{ sortBy: state?.sortBy, sortDir: state?.sortDir }}
            sortItems={sortItems}
            onSort={({ sortBy, sortDir }) => {
              applySort({ sortBy, sortDir });
            }}
          />
        </div>
      </div>
    </div>
  );
};
StorageLocationListHeader.propTypes = {
  onCreateNew: PropTypes.func.isRequired
};

export default StorageLocationListHeader;
