import React from 'react';
import PropTypes from 'prop-types';
import useWindowDimensions from 'hooks/useWindowDimensions';
import SearchIcon from 'assets/search.svg';
import { Input } from 'syngenta-digital-cropwise-react-ui-kit';
import LandingFilterSelect from './components/LandingFilterSelect';
import TaskTypeSelect from './components/TaskTypeSelect';
import DateRangePicker from './components/DateRangePicker';

const LandingSearch = ({
  searchValue,
  onChange,
  onFilterChange,
  onChangeTaskType,
  onChangeProductType,
  placeholder,
  id,
  filterPlaceholder,
  filterItems,
  selectedItems,
  selectedProductTypes,
  name,
  showSearch,
  showDateRange,
  showCropSeason,
  showTaskType,
  showProductType,
  onDateRangeChange
}) => {
  const { width } = useWindowDimensions();
  const taskTypes = [
    { key: 'none', value: 'none' },
    { key: 'planting', value: 'Planting' },
    { key: 'workOrder', value: 'Work Order' }
  ];
  const productTypes = [
    // { key: 'custom', value: 'Custom Product' },
    { key: 'cropProtection', value: 'Crop Protection' },
    { key: 'fertilizer', value: 'Fertilizer' },
    { key: 'seed', value: 'Seed' },
    { key: 'service', value: 'Service' }
    // { key: 'surfactant', value: 'Surfactant' },
    // { key: 'water', value: 'Water' }
  ];
  const productTypesFilter = [
    {
      key: 'productTypes',
      value: 'Product Types',
      nestedItems: productTypes
    }
  ];

  return (
    <div className="flex items-center justify-between flex-1 md:ml-0">
      {/* eslint-disable jsx-a11y/label-has-associated-control */}
      {showSearch ? (
        <div>
          <label htmlFor={id} className="sr-only">
            Search
          </label>
          <Input
            value={searchValue ?? undefined}
            data-testid="search-input"
            style={{ margin: 0 }}
            prefix={<img alt="Search icon" src={SearchIcon} />}
            allowClear
            type="default"
            id={id}
            name={name}
            placeholder={width <= 386 ? 'Search...' : placeholder}
            onChange={onChange}
          />
        </div>
      ) : (
        ''
      )}
      <div className="flex items-center">
        {showDateRange ? (
          <DateRangePicker onDateRangeChange={onDateRangeChange} />
        ) : null}
        {showCropSeason ? (
          <LandingFilterSelect
            onFilterChange={onFilterChange}
            filterItems={filterItems}
            filterPlaceholder={filterPlaceholder}
            selectedItems={selectedItems}
          />
        ) : null}
        {showTaskType ? (
          <TaskTypeSelect
            taskTypes={taskTypes}
            onChangeTaskType={onChangeTaskType}
          />
        ) : null}
        {showProductType ? (
          <LandingFilterSelect
            filterItems={productTypesFilter}
            onFilterChange={onChangeProductType}
            selectedItems={selectedProductTypes}
          />
        ) : null}
      </div>
    </div>
  );
};

LandingSearch.defaultProps = {
  searchValue: null,
  placeholder: 'Search...',
  id: 'search',
  filterPlaceholder: 'Filters',
  filterItems: null,
  selectedItems: [],
  selectedProductTypes: [],
  name: 'search',
  showDateRange: true,
  showCropSeason: true,
  showSearch: true,
  showTaskType: false,
  showProductType: false,
  onFilterChange: () => {},
  onChangeTaskType: () => {},
  onChangeProductType: () => {},
  onDateRangeChange: () => {}
};

LandingSearch.propTypes = {
  searchValue: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  onFilterChange: PropTypes.func,
  onChangeTaskType: PropTypes.func,
  onChangeProductType: PropTypes.func,
  showDateRange: PropTypes.bool,
  showCropSeason: PropTypes.bool,
  showProductType: PropTypes.bool,
  showTaskType: PropTypes.bool,
  showSearch: PropTypes.bool,
  placeholder: PropTypes.string,
  id: PropTypes.string,
  selectedItems: PropTypes.arrayOf(PropTypes.shape()),
  selectedProductTypes: PropTypes.arrayOf(PropTypes.string),
  filterPlaceholder: PropTypes.string,
  filterItems: PropTypes.arrayOf(
    PropTypes.oneOfType([
      PropTypes.shape({ key: PropTypes.string, value: PropTypes.string }),
      PropTypes.shape({
        key: PropTypes.string,
        value: PropTypes.string,
        nestedItems: PropTypes.arrayOf(
          PropTypes.shape({ key: PropTypes.string, value: PropTypes.string })
        )
      })
    ])
  ),
  name: PropTypes.string,
  onDateRangeChange: PropTypes.func
};

export default LandingSearch;
