import React from 'react';
import PropTypes from 'prop-types';
import { format, isValid } from 'date-fns';

function TopHeaderDetails({ proposedDate, numProducts, totalApplied }) {
  return (
    <div className="flex justify-start">
      <div className="mr-12" data-testid="editViewProposedDate">
        <div className="text-sm text-gray-600">Proposed Date</div>
        <div className="text-2xl text-charcoal">
          {isValid(proposedDate) ? format(proposedDate, 'MMM d, yyyy') : null}
        </div>
      </div>
      <div className="mr-8" data-testid="editViewProducts">
        <div className="text-sm text-gray-600">Products</div>
        <div className="text-2xl text-charcoal">{numProducts}</div>
      </div>
      <div data-testid="editViewAcres">
        <div className="text-sm text-gray-600">Acres</div>
        <div className="text-2xl text-charcoal">{totalApplied}</div>
      </div>
    </div>
  );
}

TopHeaderDetails.propTypes = {
  proposedDate: PropTypes.instanceOf(Date),
  numProducts: PropTypes.number,
  totalApplied: PropTypes.number
};

TopHeaderDetails.defaultProps = {
  proposedDate: undefined,
  numProducts: 0,
  totalApplied: 0
};

export default TopHeaderDetails;
