import ProductDetails from 'screens/ProductList/ProductDetails/index';
import { ProductsIcon } from 'routes/icons';
import { paths } from 'routes/paths';
import RouteProductList from 'screens/ProductList/components/ParentProductListProvider';

const routes = [
  {
    id: 'products',
    key: 'products',
    path: paths.products,
    exact: true,
    secure: true,
    disabled: false,
    component: RouteProductList,
    icon: ProductsIcon,
    nav: 'leftNav'
  },
  {
    id: 'productdetails',
    key: 'productdetails',
    path: `${paths.products}/:id/view`,
    exact: true,
    secure: true,
    disabled: false,
    component: ProductDetails
  }
];

export default routes;
