import React, { useContext } from 'react';
import { Context } from 'components/Store';
import GrowUIFormField from 'components/Forms/GrowUIFormField';
import { Select } from '@agconnections/grow-ui';
import { waterSources } from '../DataSets';

const WaterSource = () => {
  const [{ isEnableInfoTabEdit }] = useContext(Context);
  return (
    <GrowUIFormField
      control={Select}
      id="waterSource"
      name="waterSource"
      items={waterSources}
      label="Water Source"
      disabled={!isEnableInfoTabEdit}
      placeholder="Select water source"
    />
  );
};

export default WaterSource;
