import React, { useState, useEffect, useContext } from 'react';
import PropTypes from 'prop-types';
import {
  Button,
  ButtonType,
  Row
} from 'syngenta-digital-cropwise-react-ui-kit';
import { Context } from 'components/Store';
import PlusIcon from 'assets/plus.svg';
import CwFPagination from 'components/CwFPagination';
import LandingTable from 'components/LandingTable';
import { paths } from 'routes/paths';
import useInventoryProduct from 'screens/ProductList/hooks/useInventoryProduct';
import ProductListTableRow from '../ProductListTableRow';
import RemoveProductModal from '../RemoveProductModal';
import EditProductDrawer from '../EditProductDrawer';

export const AddProductButton = ({ onClick }) => (
  <Button
    className="mt-1 px-3"
    id="add-product-button"
    type={ButtonType.primary}
    onClick={onClick}
  >
    <Row>
      <img className="mr-1" alt="plus" src={PlusIcon} />
      <span>Add Product</span>
    </Row>
  </Button>
);

const ProductListTable = ({
  products,
  productsCount,
  isLoading,
  sortBy,
  sortDir,
  loadingMessage,
  pageNo,
  setPageNo,
  onSort,
  emptyState,
  productToEdit,
  handleOnEdit,
  showEditDrawer,
  setShowEditDrawer,
  editProductHeader,
  editProductSaveText,
  handleEdit,
  setToastType,
  setToastMessage,
  setShowToast,
  refreshProducts
}) => {
  const [
    { toastType, toastMessage, showToastRemoveProduct, removeProductFailure }
  ] = useContext(Context);

  const { isRemoveLoading, removeProduct, removeError } = useInventoryProduct();

  const [filteredProducts, setFilteredProducts] = useState(products);
  const [showRemoveModal, setShowRemoveModal] = useState(false);
  const [productToRemove, setProductToRemove] = useState(null);
  const [toastHasNotBeenClosed, setToastHasNotBeenClosed] = useState(true);

  const TABLE_COLUMNS = [
    {
      field: 'productName',
      title: `Products (${productsCount})`,
      sortable: true
    },
    {
      field: 'productType',
      title: 'Type',
      sortable: true
    },
    {
      field: 'available',
      title: 'Available Quantity',
      sortable: true
    },
    {
      field: 'averagePricePerUnit',
      title: 'List Price',
      sortable: true
    },
    {
      field: 'productId',
      title: '',
      sortable: false
    }
  ];

  useEffect(() => {
    setFilteredProducts(products);
  }, [products]);

  const handlePageChange = page => {
    setPageNo(page);
  };

  const openRemoveModal = () => {
    setShowRemoveModal(true);
  };

  const handleOnRemove = product => {
    setProductToRemove(product);
    openRemoveModal();
  };

  const closeRemoveModal = () => {
    setShowRemoveModal(false);
  };

  useEffect(() => {
    if (showToastRemoveProduct && !removeProductFailure) {
      setToastType(toastType);
      setToastMessage(toastMessage);
      setShowToast(true);
    } else if (removeProductFailure) {
      setToastType('error');
      setToastMessage('Unable to remove product.');
      setShowToast(true);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showToastRemoveProduct, toastMessage, toastType]);

  const handleRemove = async () => {
    try {
      await removeProduct(productToRemove.productId);
    } finally {
      if (!removeError) {
        setShowRemoveModal(false);
        setToastType('success');
        setToastMessage('Product removed.');
      } else {
        setShowRemoveModal(false);
        setToastType('error');
        setToastMessage('Unable to remove product.');
      }
    }
    setShowToast(true);
    refreshProducts();
  };

  const isTableEmpty = !isLoading && products?.length === 0;

  return (
    <div
      data-testid="product-list-table"
      className="h-full w-full flex flex-col"
    >
      <div className="flex-auto overflow-auto">
        <LandingTable
          listType="Products"
          routePath={paths.products}
          items={filteredProducts}
          isLoading={isLoading}
          loadingMessage={loadingMessage}
          isEmpty={isTableEmpty}
          emptyState={emptyState}
          tableColumns={TABLE_COLUMNS}
          onSort={onSort}
          sortBy={sortBy}
          sortDir={sortDir}
          simpleSortMode
        >
          {({ rows }) =>
            !isLoading
              ? rows.map(product => (
                  <ProductListTableRow
                    key={product.productId}
                    product={product}
                    onEdit={handleOnEdit}
                    onRemove={handleOnRemove}
                  />
                ))
              : null
          }
        </LandingTable>
      </div>
      {!isTableEmpty && (
        <div
          className={`pr-6 border-t-1 border-neutral-20 ${
            isLoading ? 'pointer-events-none cursor-not-allowed' : ''
          }`}
        >
          {CwFPagination(productsCount, pageNo, handlePageChange)}
        </div>
      )}
      <EditProductDrawer
        handleEdit={handleEdit}
        product={productToEdit}
        visible={showEditDrawer}
        onClose={() => setShowEditDrawer(false)}
        saveText={editProductSaveText}
        editProductHeader={editProductHeader}
      />
      <RemoveProductModal
        open={showRemoveModal}
        itemType="Product"
        close={closeRemoveModal}
        handleRemove={handleRemove}
        isRemoveLoading={isRemoveLoading}
        setToastHasNotBeenClosed={setToastHasNotBeenClosed}
        toastHasNotBeenClosed={toastHasNotBeenClosed}
        toastRenderContents={null}
      />
    </div>
  );
};

AddProductButton.propTypes = {
  onClick: PropTypes.func.isRequired
};

ProductListTable.defaultProps = {
  sortBy: '',
  sortDir: '',
  onSort: () => false,
  loadingMessage: ''
};

ProductListTable.propTypes = {
  products: PropTypes.arrayOf(PropTypes.object).isRequired,
  productsCount: PropTypes.number.isRequired,
  isLoading: PropTypes.number.isRequired,
  loadingMessage: PropTypes.string,
  pageNo: PropTypes.bool.isRequired,
  setPageNo: PropTypes.func.isRequired,
  emptyState: PropTypes.element.isRequired,
  productToEdit: PropTypes.arrayOf(PropTypes.object).isRequired,
  handleOnEdit: PropTypes.func.isRequired,
  showEditDrawer: PropTypes.bool.isRequired,
  setShowEditDrawer: PropTypes.func.isRequired,
  editProductHeader: PropTypes.string.isRequired,
  editProductSaveText: PropTypes.string.isRequired,
  handleEdit: PropTypes.func.isRequired,
  setToastType: PropTypes.func.isRequired,
  setToastMessage: PropTypes.func.isRequired,
  setShowToast: PropTypes.func.isRequired,
  refreshProducts: PropTypes.func.isRequired,
  sortBy: PropTypes.string,
  sortDir: PropTypes.string,
  onSort: PropTypes.func
};

export default ProductListTable;
