import React, { useContext, useEffect, useState } from 'react';
import { startCase } from 'lodash';
import PropTypes from 'prop-types';
import expandLessIcon from 'assets/expand_less.svg';
import expandMoreIcon from 'assets/expand_more.svg';
import { NavItem } from '@agconnections/grow-ui';
import { useLocation, NavLink } from 'react-router-dom';
import { AmplitudeContext } from '../../../../utilities/amplitude/useAmplitude';
import getIsNavItemActive from '../../helpers/helpers';

const CollapsibleNavItem = ({ item, isOpened }) => {
  const [isCollapsed, setIsCollapsed] = useState(true);
  const [showSidenav, setShowSidenav] = useState(false);
  const amplitude = useContext(AmplitudeContext);
  const location = useLocation();

  useEffect(() => {
    if (isOpened) {
      setShowSidenav(false);
    } else {
      setIsCollapsed(true);
    }
  }, [isOpened]);

  const isActive = getIsNavItemActive({ item, pathname: location.pathname });

  const renderNavItem = ({ route, pathname }) => (
    <NavItem
      key={route.key}
      disabled={route.disabled}
      icon={route.icon}
      active={getIsNavItemActive({
        item: route,
        pathname
      })}
      open
      as={
        <NavLink
          key={route.key}
          onClick={() =>
            amplitude.sendEventToAmplitude(amplitude.filterNavItems(route.key))
          }
          to={route.path}
          sensitive
          secure
          exact
        />
      }
    >
      <>{startCase(route.id)}</>
    </NavItem>
  );

  return (
    <>
      <button
        type="button"
        className={`focus:outline-none relative gr-flex gr-flex-row gr-items-center  gr-w-64 gr-leading-5 gr-py-1 gr-relative gr-transition gr-items-center gr-duration-200 gr-group gr-mb-1 gr-text-neutral-500 hover:gr-bg-neutral-1000 hover:gr-text-white cursor-pointer ${
          isActive ? 'text-white' : ''
        }`}
        onClick={() =>
          isOpened ? setIsCollapsed(!isCollapsed) : setShowSidenav(!showSidenav)
        }
        onBlur={e => {
          if (!e.currentTarget.contains(e.relatedTarget)) {
            setShowSidenav(false);
          }
        }}
      >
        {showSidenav && (
          <div
            className="absolute w-full z-50"
            style={{ left: '68px', backgroundColor: '#232630' }}
          >
            {item?.subRoutes?.map(route =>
              renderNavItem({ route, pathname: location.pathname })
            )}
          </div>
        )}
        <div className="gr-h-8 gr-items-center gr-justify-center gr-flex gr-w-16">
          <span className="gr-bg-inherit gr-h-8 gr-absolute gr-rounded-r gr-w-1 gr-flex-1 gr-left-0" />
          {item.icon}
        </div>
        <div className="flex-1 text-left">
          <span
            className={`gr-opacity-100 gr-transition gr-ease-in gr-duration-200 gr-text-sm gr-min-w-full ${
              isActive ? 'text-white' : ''
            }`}
          >
            {startCase(item.id)}
          </span>
        </div>
        <div className="gr-h-8 gr-items-center gr-justify-center gr-flex gr-w-16">
          {item?.subRoutes?.length > 0 &&
            (isCollapsed ? (
              <img
                src={expandMoreIcon}
                className="gr-text-neutral-500 hover:gr-text-white"
                alt="expand-more-icon"
              />
            ) : (
              <img
                src={expandLessIcon}
                className="gr-text-neutral-500 hover:gr-text-white"
                alt="expand-less-icon"
              />
            ))}
        </div>
      </button>
      <div>
        {!isCollapsed &&
          item?.subRoutes?.map(route =>
            renderNavItem({ route, pathname: location.pathname })
          )}
      </div>
    </>
  );
};

CollapsibleNavItem.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  item: PropTypes.shape({
    id: PropTypes.string,
    key: PropTypes.string,
    path: PropTypes.string,
    icon: PropTypes.node,
    subRoutes: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.string,
        key: PropTypes.string,
        path: PropTypes.string
      })
    )
  }).isRequired,
  isOpened: PropTypes.bool.isRequired
};

export default CollapsibleNavItem;
