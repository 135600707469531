import React, { useEffect, useState } from 'react';
import { Spinner } from '@agconnections/grow-ui';
import PropTypes from 'prop-types';
import useCropZoneData from 'screens/Property/hooks/useCropZoneData';
import { Context } from 'components/Store';
import { cropZoneV2 as cropZoneApiV2 } from 'utilities/api';
import getSeasons from 'screens/Yield/components/YieldActions/helpers/getSeasons';
import PropertyRow from '../PropertyRow';

const FertilizerSummation = ({ id }) => {
  const { cropZone, loading } = useCropZoneData(id);
  const [data, setData] = useState([]);
  const [error, setError] = useState(false);

  useEffect(() => {
    setError(false);
    const orgId = Context?._currentValue[0]?.organization?.id;
    const seasonIds = getSeasons();
    const { promise } = cropZoneApiV2
      .createChildApi({
        action: `cropzone/${id}/fertilizers`
      })
      .post(
        { seasonIds },
        {
          headers: {
            'cwf-context': JSON.stringify({
              organization_id: orgId
            })
          }
        }
      );
    promise
      .then(response => {
        setData([
          {
            name: 'Nitrogen (N)',
            value: response.data.n || 0
          },
          {
            name: 'Phosphorus (P)',
            value: response.data.p || 0
          },
          {
            name: 'Potassium (K)',
            value: response.data.k || 0
          }
        ]);
      })
      .catch(() => setError(true));
  }, [id, cropZone]);

  const isEverythingLoaded = () => {
    return !loading;
  };

  if (error) {
    return 'Failed to load fertilizer summation data';
  }

  return (
    <div>
      {isEverythingLoaded() ? (
        <div className="mb-6">
          <div className="mt-6">MAJOR</div>
          {data.map(entry => (
            <PropertyRow
              key={entry.name}
              label={entry.name}
              value={`${entry.value.toFixed(2)} lbs`}
            />
          ))}
        </div>
      ) : (
        <Spinner />
      )}
    </div>
  );
};

FertilizerSummation.propTypes = {
  id: PropTypes.string.isRequired
};

export default FertilizerSummation;
