import React, { useEffect, useContext, useState } from 'react';

import CwFPagination from 'components/CwFPagination';
import LandingTable from 'components/LandingTable';

import { SubmissionHistoryContext } from './context/Provider';
import { SUBMISSIONS_HISTORY_TABLE } from './constants';
import TableRow from './components/TableRow';

import './index.css';

const SubmissionHistory = () => {
  const {
    getData,
    loading,
    state: { data: submissions, elements, page, limit }
  } = useContext(SubmissionHistoryContext);

  const [sortFields, setSortFields] = useState({
    sortBy: 'date',
    sortDir: 'desc'
  });

  const handleSort = async (sortBy, sortDir) => {
    if (loading) {
      return;
    }

    await getData(sortDir, sortBy === 'date' ? 'created_at' : sortBy);
    setSortFields({
      sortBy,
      sortDir
    });
  };

  const handlePageChange = async nextPage => {
    if (loading) {
      return;
    }
    await getData(
      sortFields.sortDir,
      sortFields.sortBy === 'date' ? 'created_at' : sortFields.sortBy,
      nextPage
    );
  };

  useEffect(() => {
    async function callSubmissionList() {
      await getData();
    }

    callSubmissionList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <>
      {submissions?.length > 0 && (
        <div className="mb-6">
          <div className="w-full mb-2 font-semibold">
            <p className="text-xl">Previous Submissions</p>
          </div>
          <div
            className={`flex flex-col bg-white rounded-xl justify-between w-full syt-antd-table-wrapper ${
              loading ? 'previous-submission-table-loading' : ''
            }`}
          >
            <LandingTable
              isLoading={loading}
              listType="Pevious Submissions"
              items={submissions}
              tableColumns={SUBMISSIONS_HISTORY_TABLE.columns}
              onSort={handleSort}
              sortBy={sortFields.sortBy}
              sortDir={sortFields.sortDir}
              simpleSortMode
              data-testid="submission-history-table"
              hasRouting={false}
              message="Click in the button on top of screen and create a new submission"
            >
              {({ rows }) =>
                !loading
                  ? rows.map(submission => (
                      <TableRow key={submission.id} submission={submission} />
                    ))
                  : null
              }
            </LandingTable>
            <div
              className={`w-full pr-2 ${
                loading ? 'pointer-events-none cursor-not-allowed' : ''
              }`}
            >
              {CwFPagination(elements, page, handlePageChange, null, limit)}
            </div>
          </div>
        </div>
      )}
    </>
  );
};

SubmissionHistory.defaultProps = {};

SubmissionHistory.propTypes = {};

export default SubmissionHistory;
