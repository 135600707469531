import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import ItemsDropdown from 'components/ItemsDropdown';
import useCompanies from 'hooks/useCompanies';
import usePeopleData from 'hooks/usePeopleData';
import PersonIcon from 'components/Icons/PersonIcon';
import CompanyIcon from 'components/Icons/CompanyIcon';

import AddNewCompanyPeopleDrawer from './components/AddNewCompanyPeopleDrawer';
import CompanyPeopleDropDownItem from './components/CompanyPeopleDropDownItem';

const CompanyPeopleDropdown = ({
  bottomButtonText,
  onChange,
  value,
  id,
  title,
  hidePeople
}) => {
  const [mixedItems, setMixedItems] = useState([]);
  const [
    addNewCompanyPeopleDrawerOpen,
    setAddNewCompanyPeopleDrawerOpen
  ] = useState(false);

  const {
    companies,
    loading: loadingCompanies,
    loadCompanies
  } = useCompanies();

  const { people, loading: loadingPeople, loadPeople } = usePeopleData();

  const onCompanyPeopleCreated = companyPeopleValue => {
    onChange(companyPeopleValue);
    loadCompanies();
    if (!hidePeople) {
      loadPeople();
    }
  };

  const valueRenderer = useCallback(() => {
    if (!value?.type) {
      return null;
    }
    return (
      <div
        className="w-full h-full p-2 flex items-center"
        data-testid="selected-company-people"
      >
        <span>
          {value?.type === 'People' ? (
            <PersonIcon size={16} />
          ) : (
            <CompanyIcon size={16} />
          )}
        </span>
        <div className="pl-2">{value.name}</div>
      </div>
    );
  }, [value]);

  useEffect(() => {
    if (!loadingCompanies && !loadingPeople) {
      setMixedItems([
        {
          key: 'company-label',
          label: 'COMPANIES',
          type: 'Company',
          items:
            companies.length > 0
              ? companies.map(company => {
                  return {
                    key: company.id,
                    label: company.name,
                    value: { ...company, type: 'Company' }
                  };
                })
              : [
                  {
                    key: 'empty-companies',
                    label: 'empty',
                    type: 'Company'
                  }
                ]
        },
        !hidePeople
          ? {
              key: 'people-label',
              label: 'PEOPLE',
              type: 'People',
              items:
                people.length > 0
                  ? people.map(person => {
                      return {
                        key: person.id,
                        label: person.name,
                        value: { ...person, type: 'People' }
                      };
                    })
                  : [
                      {
                        key: 'empty-people',
                        label: 'empty',
                        type: 'People'
                      }
                    ]
            }
          : {}
      ]);
    }
    return () => {
      setMixedItems([]);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [people, companies, loadingCompanies, loadingPeople]);

  return (
    <>
      <ItemsDropdown
        rightOffset={0}
        heightClass="h-64"
        valueRenderer={valueRenderer}
        value={value}
        bottomButtonText={bottomButtonText}
        onBottomButtonClick={() => {
          setAddNewCompanyPeopleDrawerOpen(true);
        }}
        onChange={onChange}
        id={id}
        items={mixedItems}
        ItemComponent={CompanyPeopleDropDownItem}
      />
      <AddNewCompanyPeopleDrawer
        onClose={() => setAddNewCompanyPeopleDrawerOpen(false)}
        onCompanyPeopleAdded={onCompanyPeopleCreated}
        open={addNewCompanyPeopleDrawerOpen}
        title={title}
      />
    </>
  );
};

CompanyPeopleDropdown.propTypes = {
  value: PropTypes.shape({
    id: PropTypes.string,
    type: PropTypes.string,
    name: PropTypes.string
  }),
  onChange: PropTypes.func.isRequired,
  id: PropTypes.string,
  bottomButtonText: PropTypes.string,
  title: PropTypes.string,
  hidePeople: PropTypes.bool
};

CompanyPeopleDropdown.defaultProps = {
  value: null,
  id: '',
  bottomButtonText: '',
  title: 'Create New Owner',
  hidePeople: false
};

export default CompanyPeopleDropdown;
