import React, { useState, useEffect, useContext } from 'react';
import {
  TextArea,
  Columns,
  Column,
  Spinner,
  Table
} from '@agconnections/grow-ui';
import differenceInCalendarDays from 'date-fns/differenceInCalendarDays';
import { noop } from 'lodash';

import { season as seasonApi } from 'utilities/api';
import { useParams, useHistory } from 'react-router-dom';
import catchCancel from 'helpers/catchCancel';
import { Context } from 'components/Store';
import { parseServerError } from 'helpers/errorHelpers';
import Paper from 'components/Paper';
import ViewOnlyField from 'components/ViewOnlyField';
import TableHeader from 'components/LandingTable/components/TableHeader';
import { formatEpochDate } from 'utilities/dateHelpers';
import Breadcrumb from 'components/App/AppShell/components/Breadcrumb';
import EllipseMenu from 'components/EllipseMenu';
import { CROP_SEASON_VIEW_MENU } from 'utilities/menus';
import { FETCH_TYPES } from 'helpers/constants';
import { paths } from 'routes/paths';
import usePopulateMapData from 'hooks/usePopulateMapData';
import { AmplitudeContext } from 'utilities/amplitude/useAmplitude';
import PropertyTableRow from './components/PropertyTableRow';
import DeleteSeasonModal from '../../components/DeleteSeasonModal';

const CropSeasonView = () => {
  const { id } = useParams();
  const [season, setSeason] = useState(null);
  const [cropzone, setCropzone] = useState(null);
  const [loaded, setLoaded] = useState(false);
  const [, dispatch] = useContext(Context);
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const openDeleteModal = () => setDeleteModalOpen(true);
  const closeDeleteModal = () => setDeleteModalOpen(false);
  const [seasonToDelete, setSeasonToDelete] = useState(null);
  const history = useHistory();

  const amplitude = useContext(AmplitudeContext);

  const {
    fieldsAndAreasGeoJSONCollection,
    fieldsLoading,
    reloadData
  } = usePopulateMapData({
    fetchType: FETCH_TYPES.propertiesMap,
    isCacheOn: true
  });

  const getDays = () => {
    return season
      ? differenceInCalendarDays(
          new Date(season.endDate),
          new Date(season.startDate)
        ) + 1
      : 0;
  };

  const handleAction = action => {
    const actions = {
      edit: () => {
        amplitude.sendEventToAmplitude(
          amplitude.events.epic.CropSeason.editCropSeason
        );
        history.push(`${paths.cropSeasons}/${id}`);
      },
      delete: () => {
        amplitude.sendEventToAmplitude(
          amplitude.events.epic.CropSeason.deleteCropSeason
        );
        openDeleteModal();
        setSeasonToDelete({ id, name: season.name });
      }
    };
    actions[action]();
  };

  const propertyColumns = [
    {},
    {
      field: 'cropzones',
      title: 'Cropzones',
      sortable: false
    },
    {
      field: 'crops',
      title: 'Crops',
      sortable: false
    },
    {
      field: 'area',
      title: 'Area',
      sortable: false
    }
  ];

  const getCropSeasonDetails = async () => {
    const { promise } = seasonApi.fetch(id);
    setLoaded(false);
    await promise
      .then(({ data }) => {
        const filteredCropZones = data?.cropZones?.filter(
          property => property?.status === 200
        );
        setCropzone(filteredCropZones);
        setSeason(data);
        setLoaded(true);
      })
      .catch(catchCancel)
      .catch(err => {
        parseServerError(dispatch)(err);
      });
  };
  useEffect(() => {
    reloadData();
    amplitude.sendEventToAmplitude(
      amplitude.events.epic.CropSeason.viewCropSeasonSuccess
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  useEffect(() => {
    if (!season) getCropSeasonDetails();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [season]);

  return (
    <>
      {!loaded ? (
        <Spinner />
      ) : (
        <>
          <Breadcrumb disabled>
            <Breadcrumb.Item
              title="Crop Seasons"
              value="All Crop Seasons"
              to={paths.cropSeasons}
            />
            <Breadcrumb.Item title="Crop Season" value={season.name} isLast />
          </Breadcrumb>
          <DeleteSeasonModal
            open={deleteModalOpen}
            close={closeDeleteModal}
            seasonName={seasonToDelete?.name}
            seasonId={seasonToDelete?.id}
          />
          <div className="flex justify-between">
            <div className="flex justify-start w-1/2">
              <ViewOnlyField
                className="ml-4 mr-8"
                header="Duration"
                data={`${getDays()} Days`}
                testId="duration"
              />
              <ViewOnlyField
                className="ml-8 mr-4"
                header="Total Area"
                data={`${
                  season.totalArea.status === 200
                    ? season.totalArea.totalArea
                    : 0
                } ac`}
                testId="totalArea"
              />
            </div>
            <div className="flex self-center mr-6">
              <EllipseMenu
                onAction={handleAction}
                options={CROP_SEASON_VIEW_MENU}
              />
            </div>
          </div>
          <div
            className="mx-2 my-4 min-w-content"
            data-testid="tasks-table"
            style={{ minWidth: 'min-content' }}
          >
            <Paper>
              <div className="m-4">
                <div data-testid="crop-season-details">
                  <div className="flex justify-between pt-4 mb-8">
                    <div className="text-2xl font-bold">
                      Crop Season Details
                    </div>
                  </div>
                </div>
                <div>
                  <Columns>
                    <Column>
                      <div className="mb-6">
                        <ViewOnlyField
                          header="Crop Season Name"
                          data={season.name}
                          testId="crop-season-name"
                        />
                      </div>
                    </Column>
                  </Columns>
                  <Columns>
                    <Column width="w-120">
                      <div className="mb-6">
                        <ViewOnlyField
                          header="Start Date"
                          data={formatEpochDate(season.startDateEpoch)}
                          testId="startDateTime"
                        />
                      </div>
                    </Column>
                    <Column width="w-56">
                      <div className="flex items-center w-full h-full">
                        <svg
                          width="53"
                          height="16"
                          viewBox="0 0 53 16"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M52.7071 8.7071C53.0976 8.31658 53.0976 7.68341 52.7071 7.29289L46.3431 0.928928C45.9526 0.538404 45.3195 0.538404 44.9289 0.928928C44.5384 1.31945 44.5384 1.95262 44.9289 2.34314L50.5858 8L44.9289 13.6569C44.5384 14.0474 44.5384 14.6805 44.9289 15.0711C45.3195 15.4616 45.9526 15.4616 46.3431 15.0711L52.7071 8.7071ZM8.74228e-08 9L52 9L52 7L-8.74228e-08 7L8.74228e-08 9Z"
                            fill="#696F88"
                          />
                        </svg>
                      </div>
                    </Column>
                    <Column width="w-120">
                      <div className="mb-6">
                        <ViewOnlyField
                          header="End Date"
                          data={formatEpochDate(season.endDateEpoch)}
                          testId="endDateTime"
                        />
                      </div>
                    </Column>
                  </Columns>
                  <Columns>
                    <Column>
                      <div className="mb-6">
                        <ViewOnlyField
                          header="Crops"
                          data={
                            <div>
                              {season.crops?.map((crop, index) =>
                                index !== season.crops.length - 1
                                  ? `${crop.name}, `
                                  : `${crop.name}`
                              )}
                            </div>
                          }
                          testId="crop-season-crops"
                        />
                      </div>
                    </Column>
                  </Columns>
                </div>
                <hr className="my-6" />
                <div data-testid="properties-section" className="mb-6">
                  <div className="flex justify-between pt-4 mb-8">
                    <div className="text-2xl font-bold">Properties</div>
                  </div>
                  <Table>
                    <TableHeader
                      tableColumns={propertyColumns}
                      onSort={noop}
                      sortBy=""
                      sortDir=""
                    />
                    {cropzone.map(property => (
                      <PropertyTableRow
                        key={property.cropzoneId}
                        property={property}
                        fieldsAndAreasGeoJSONCollection={
                          fieldsAndAreasGeoJSONCollection
                        }
                        fieldsLoading={fieldsLoading}
                      />
                    ))}
                  </Table>
                </div>
                <div data-testid="notes-section">
                  <div className="flex justify-between pt-4 mb-8">
                    <div className="text-2xl font-bold">Notes</div>
                  </div>
                  <TextArea
                    data-testid="crop-season-notes"
                    className="h-64 overscroll-auto"
                    resizeDisabled
                    disabled
                    value={season.notes ?? ''}
                  />
                </div>
              </div>
            </Paper>
          </div>
        </>
      )}
    </>
  );
};
export default CropSeasonView;
