import React from 'react';
import { Table } from '@agconnections/grow-ui';
import PropTypes from 'prop-types';
import { TStorageLocationItemProps } from 'screens/YieldV2/StorageLocations/constants';
import formatStorageValue from 'screens/YieldV2/StorageLocations/helpers/formatStorageValue';
import PieChart from '../../../PieChart';
import './index.css';

const StorageLocationItemRow = ({ item, isSelected, onClick }) => {
  let totalStoredLoad = formatStorageValue(
    item?.totalStoredLoad,
    item?.totalStoredLoadUnit === 'lb' ? 'lbs' : 'bu'
  );
  const capacity = formatStorageValue(item?.capacity, 'bu');
  if (capacity && item.totalStoredLoadUnit !== 'lb') {
    totalStoredLoad += ` / ${capacity}`;
  }

  let pieValue = item?.totalStoredLoad
    ? (item?.totalStoredLoad / item?.capacity) * 100
    : 0;

  if (!item.capacity) {
    pieValue = 0;
  }

  return (
    <Table.Row
      className={`cursor-pointer relative group cwfweb-storage-location-row
      ${
        isSelected
          ? 'bg-blue-10 hover:bg-blue-30'
          : 'bg-white !hover:bg-neutral-10 !active:bg-neutral-10'
      }`}
      onClick={() => onClick(item.id)}
    >
      <Table.Cell className="min-h-88px">
        {isSelected && (
          <div
            data-testid="selected-indicator"
            className="w-1 h-90/100 flex-shrink-0 rounded-r-md bg-blue-50 absolute left-0"
          />
        )}
        <div className="w-full text-left flex justify-between items-center">
          <div className="flex-1 mr-4">
            <h4
              className={`text-base storage-name mb-1 ${
                isSelected ? 'font-semibold' : 'font-normal'
              }`}
            >
              {item.name}
            </h4>
            <div>
              <p className="text-neutral-60 text-xs font-normal leading-5 storage-usage-title">
                Total Stored
              </p>
              <p className="storage-usage-value">{`${totalStoredLoad}`}</p>
            </div>
          </div>
          {item.totalStoredLoadUnit !== 'lb' &&
            item?.capacity !== null &&
            item?.capacity > 0 && (
              <div className="h-16 w-16">
                <PieChart value={pieValue} />
              </div>
            )}
        </div>
      </Table.Cell>
    </Table.Row>
  );
};

StorageLocationItemRow.defaultProps = {
  isSelected: false
};

StorageLocationItemRow.propTypes = {
  item: TStorageLocationItemProps.isRequired,
  isSelected: PropTypes.bool,
  onClick: PropTypes.func.isRequired
};

export default StorageLocationItemRow;
