import React, { useContext, useEffect, useState } from 'react';
import { TagCloud, Tag } from '@agconnections/grow-ui';
import useCropSeasons from 'hooks/useCropSeasons';
import {
  getCropSeasonMaxEndDateEpoch,
  getCropSeasonMinStartDateEpoch
} from 'screens/Integrations/helpers/cropSeasonsHelpers';
import { SubmissionSummaryContext } from '../../context/SubmissionSummaryProvider';
import SubmitHeader from './components/SubmitHeader';

const TopHeader = () => {
  const { cropSeasons: cropSeasonsFromCwfApi } = useCropSeasons();
  const {
    state: { orgId, integrationType, submissionResult, cropSeasons, matches }
  } = useContext(SubmissionSummaryContext);

  const [cropSeasonStartDate, setCropSeasonStartDate] = useState();
  const [cropSeasonEndDate, setCropSeasonEndDate] = useState();

  useEffect(() => {
    const cropSeasonsWithDate = cropSeasons?.map(season => {
      const filledCropSeason = cropSeasonsFromCwfApi?.find(
        crop => crop.id === season.id
      );
      return { ...filledCropSeason, ...season };
    });
    if (cropSeasonsWithDate.length) {
      setCropSeasonStartDate(
        new Date(getCropSeasonMinStartDateEpoch(cropSeasonsWithDate))
      );
      setCropSeasonEndDate(
        new Date(getCropSeasonMaxEndDateEpoch(cropSeasonsWithDate))
      );
    }
  }, [cropSeasons, cropSeasonsFromCwfApi]);

  const vendorOrgName = matches?.[orgId];

  const dateFormat = {
    year: 'numeric',
    month: 'short',
    day: '2-digit'
  };

  return (
    <div data-testid="top-header">
      <SubmitHeader
        integrationType={integrationType}
        submissionResult={submissionResult}
      />
      <div className="w-full flex h-15 items-center font-semibold">
        <p className="text-2xl">
          {integrationType} Submission{' '}
          {new Date().toLocaleDateString('en-Us', dateFormat)}
        </p>
      </div>
      <div className="w-full">
        <div
          className="flex w-full h-full"
          style={{ justifyContent: 'space-between' }}
        >
          <div className="flex h-full">
            <div className="h-full">
              <p className="font-thin text-xs text-gray-600">
                {integrationType} Organization
              </p>
              <div className="h-full">
                <div className="w-full items-center">
                  <p className="text-2xl pr-2 w-auto overflow-x-auto">
                    {vendorOrgName}
                  </p>
                </div>
              </div>
            </div>

            <div className="h-full ml-10">
              <p className="font-thin text-xs text-gray-600">Crop Season(s) </p>
              <div>
                <TagCloud data-testid="crops">
                  {cropSeasons?.map(cropSeason => (
                    <Tag key={cropSeason.id} color="#696F88">
                      {cropSeason.name}
                    </Tag>
                  ))}
                </TagCloud>
              </div>
            </div>
          </div>
          <div className="flex">
            <div className="flex flex-col mr-10 ">
              <p className="font-thin text-xs text-gray-600">Start Date</p>
              <p className="text-2xl font-normal pr-2">
                {cropSeasonStartDate?.toLocaleDateString('en-Us', dateFormat)}
              </p>
            </div>
            <div className="flex flex-col mr-40">
              <p className="font-thin text-xs text-gray-600">End Date</p>
              <p className="text-2xl font-normal pr-2">
                {cropSeasonEndDate?.toLocaleDateString('en-Us', dateFormat)}
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TopHeader;
