import React, { createContext, useMemo, useState } from 'react';
import PropTypes from 'prop-types';

const ProductDetailsContext = createContext();

const ProductDetailsProvider = ({ children }) => {
  const [isUpdateListPrice, setIsUpdateListPrice] = useState(false);
  const [selectedTaskIds, setSelectedTaskIds] = useState([]);
  const [showInfoRow, setShowInfoRow] = useState(false);
  const [isInfoRow, setIsInfoRow] = useState({});
  const [openDrawer, setOpenDrawer] = useState(false);

  const resetValues = () => {
    setIsUpdateListPrice(false);
    setSelectedTaskIds([]);
  };

  const selectAllRows = useMemo(() => {
    return (fullDataSet, rowKey) => {
      const allRowKeys = fullDataSet.map(row => row[rowKey]);
      setSelectedTaskIds(allRowKeys);
    };
  }, []);

  const contextValue = useMemo(
    () => ({
      isUpdateListPrice,
      setIsUpdateListPrice,
      selectedTaskIds,
      setSelectedTaskIds,
      resetValues,
      selectAllRows,
      isInfoRow,
      setIsInfoRow,
      showInfoRow,
      setShowInfoRow,
      openDrawer,
      setOpenDrawer
    }),
    [
      isUpdateListPrice,
      selectAllRows,
      selectedTaskIds,
      isInfoRow,
      showInfoRow,
      openDrawer
    ]
  );

  return (
    <ProductDetailsContext.Provider value={contextValue}>
      {children}
    </ProductDetailsContext.Provider>
  );
};

ProductDetailsProvider.defaultProps = {
  children: null
};

ProductDetailsProvider.propTypes = {
  children: PropTypes.node
};

export { ProductDetailsContext, ProductDetailsProvider };
