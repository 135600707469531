/* eslint-disable import/prefer-default-export */
import { MEASUREMENT_UNITS } from 'helpers/unitsAbbreviation';

// if you need to add something to this object,
// please pay attention to the crop name.
// Add it either exactly as it is in the db, like 'Corn : Sweet, Processed'
// or replace the variant with ALL, like 'Clover : ALL'
// then it will apply to all variants of that crop,
// e.g. 'Clover', 'Clover : Arrowleaf Clover', 'Clover : Crimson Clover', etc.
// the source of tge data is
// https://syngenta-my.sharepoint.com/:x:/r/personal/ashley_manning_syngenta_com/Documents/Standard%20Bushel%20Weight%20per%20Crop.xlsx?d=w29994e9e90e94c08ba418615e50a8dbc&csf=1&web=1&e=D3BJGj

export const WEIGHT_UNITS = [
  {
    label: 'pounds (lbs)',
    value: 'lbs'
  },
  {
    label: 'dry ounces (oz)',
    value: 'oz'
  },
  {
    label: 'grams (g)',
    value: 'g'
  },
  {
    label: 'tons (T)',
    value: 't'
  }
];

// https://syt-dpe.slack.com/archives/C04JWG0SV1R/p1712338172623349
// https://syt-dpe.slack.com/archives/C06CQA756UF/p1714487657159129
export const YIELD_DEFAULT_ROUND = 15;

export const TEMPLATE_TYPE = {
  ALL_CROPS: 'ALL_CROPS',
  ROW_CROP: 'ROW_CROP',
  SPECIALTY: 'SPECIALTY'
};

export const SHRINK_TYPE = {
  BY_QUANTITY: 'QUANTITY',
  BY_PERCENT: 'PERCENT'
};

export const TEMPLATE_ATTRIBUTES = {
  [TEMPLATE_TYPE.ALL_CROPS]: [],
  [TEMPLATE_TYPE.ROW_CROP]: [
    'damage',
    'foreignMaterial',
    'moisture',
    'testWeight'
  ],
  [TEMPLATE_TYPE.SPECIALTY]: ['damage', 'foreignMaterial', 'quality']
};

export const TEMPLATE_DEFAULT_VALUES = {
  [TEMPLATE_TYPE.ALL_CROPS]: {},
  [TEMPLATE_TYPE.ROW_CROP]: {
    shrinkType: SHRINK_TYPE.BY_QUANTITY,
    testWeightUnit: MEASUREMENT_UNITS.POUND
  },
  [TEMPLATE_TYPE.SPECIALTY]: {}
};
