import React, { useEffect, useMemo, useRef, useState } from 'react';
import { Search, Label } from '@agconnections/grow-ui';
import { useFormikContext } from 'formik';
import PropTypes from 'prop-types';
import GrowUIFormField from 'components/Forms/GrowUIFormField';
import useDebounce from 'hooks/useDebounce';
import usePestData from 'hooks/usePestData';

const fieldName = 'targetPest';

const TargetPestField = ({ pest, onSelectPest }) => {
  const { setFieldTouched } = useFormikContext();
  const [selectedValue, setSelectedValue] = useState(undefined);
  const [searchTextPest, setSearchTextPest] = useState(pest ? pest.name : null);
  const debouncedSearchTermPest = useDebounce(searchTextPest, 300);
  const { pests, loading } = usePestData(debouncedSearchTermPest);

  const firstRender = useRef(false);

  const pestList = useMemo(() => {
    if (!pests?.length) return [];
    return pests.map(p => {
      return { ...p, key: p.nameid, value: p.name };
    });
  }, [pests]);

  useEffect(() => {
    // Do not set the selected value if we have not loaded any pests yet.
    // This is needed when a pest is provided and we need to set it as the
    // value of the Search box.
    if (loading || pestList.length === 0) {
      return;
    }

    if (!firstRender.current) {
      firstRender.current = true;
      setSelectedValue(
        pest ? { ...pest, key: pest.id, value: pest.name } : undefined
      );
    }
  }, [pest, loading, pestList.length]);

  return (
    <>
      <div className="-mt-1 mb-1">
        <Label>Target Pest</Label>
      </div>
      <GrowUIFormField
        control={Search}
        id={fieldName}
        name={fieldName}
        placeholder="Search pests"
        items={pestList}
        loading={loading}
        selectedValue={selectedValue?.key}
        onChange={e => {
          setSearchTextPest(e.target.value);
          if (!e.target.value) {
            setSelectedValue(undefined);
            onSelectPest(null);
            setFieldTouched('targetPest', true);
          }
        }}
        onSelect={item => {
          setFieldTouched(fieldName, true);
          setSelectedValue(item);
          onSelectPest(item);
        }}
      />
    </>
  );
};

TargetPestField.propTypes = {
  pest: PropTypes.shape({
    id: PropTypes.number.isRequired,
    name: PropTypes.string.isRequired
  }),
  onSelectPest: PropTypes.func.isRequired
};

TargetPestField.defaultProps = {
  pest: undefined
};

export default TargetPestField;
