import { useContext, useState } from 'react';

import {
  hourlyHistoricalPath,
  weather as weatherApi,
  task as taskApi
} from 'utilities/api';
import catchCancel from 'helpers/catchCancel';
import usePopulateMapData from 'hooks/usePopulateMapData';
import { parseServerError } from 'helpers/errorHelpers';
import { Context } from 'components/Store';
import { FETCH_TYPES } from 'helpers/constants';
import { windDirections } from '../Task/Tabs/Details/Sections/weatherData';
import { getFeaturesCentroidCoordinates } from '../../Property/helpers/mapApiHelpers';

const { REACT_APP_AG_CLEAR_APP_ID, REACT_APP_AG_CLEAR_APP_KEY } = process.env;

const useWeatherData = () => {
  const [context, dispatch] = useContext(Context);
  const { weatherAutomaticToggle } = context;
  const { fieldsAndAreasCentroidCoordinates } = usePopulateMapData({
    fetchType: FETCH_TYPES.propertiesMap
  });
  const [updating, setUpdating] = useState(false);

  const TaskWeatherObj = {
    date: '',
    dateEpoch: '',
    temperature: '',
    temperatureUnit: '',
    humidity: '',
    skyCondition: '',
    windSpeed: '',
    windSpeedUnit: '',
    windDirection: '',
    soilMoisture: '',
    type: ''
  };

  const DefaultWeatherData = [
    {
      date: '',
      temperature: '',
      temperatureUnit: 'F',
      humidity: '',
      skyCondition: '',
      windSpeed: '',
      windSpeedUnit: 'MPH',
      windDirection: '',
      soilMoisture: 'unknown',
      type: 'start'
    },
    {
      date: '',
      temperature: '',
      temperatureUnit: 'F',
      humidity: '',
      skyCondition: '',
      windSpeed: '',
      windSpeedUnit: 'MPH',
      windDirection: '',
      soilMoisture: 'unknown',
      type: 'end'
    }
  ];

  const getNumericValue = field => {
    const fieldData = field || '';
    const matches = fieldData.match(/(\d+)/);
    return matches && matches?.length > 0 ? field.match(/(\d+)/)[0] : '';
  };

  const checkForValidWindDirection = firstHourlyResult => {
    // depending on when the weather data is pulled the results set can return a different wind direction object
    const windDirection =
      firstHourlyResult?.descriptors?.wind_direction_descriptor ||
      firstHourlyResult?.descriptors?.wind_direction;
    const resultWD = windDirections.filter(
      wd => wd.code === windDirection?.code
    );
    return resultWD.length === 0
      ? windDirections.filter(dir => dir.key === 'unknown')[0].value
      : resultWD[0]?.value;
  };

  const setWeatherObj = (index, firstHourlyResult, startDateTimeStamp) => ({
    date: new Date(startDateTimeStamp * 1000),
    dateEpoch: startDateTimeStamp * 1000,
    temperature: firstHourlyResult?.air_temp?.value || '',
    temperatureUnit: firstHourlyResult?.air_temp?.unit || '',
    humidity: firstHourlyResult?.relative_humidity?.value || '',
    skyCondition:
      firstHourlyResult?.descriptors?.weather_descriptor?.text || '',
    windSpeed: firstHourlyResult?.wind_speed?.value || '',
    windSpeedUnit: firstHourlyResult?.wind_speed?.unit?.toUpperCase() || '',
    windDirection: checkForValidWindDirection(firstHourlyResult) || '',
    soilMoisture: 'unknown',
    type: index === 0 ? 'start' : 'end'
  });

  const getCoordinates = (id, values, fieldsAndAreasGeoJSONCollection) => {
    let coordinates;
    if (
      (id === 'create' || id === 'convert' || id === 'duplicate') &&
      values?.properties?.features?.length > 0
    ) {
      const allFeatures = values?.properties?.map(
        property => property.features[0]
      );
      const featureCentroidCoordinates = getFeaturesCentroidCoordinates(
        allFeatures
      );
      coordinates = [...featureCentroidCoordinates];
      // setCoordinates([...featureCentroidCoordinates]);
    } else {
      const tempData = values?.properties?.map(property =>
        fieldsAndAreasGeoJSONCollection?.features?.find(
          feature =>
            property.farmId === feature.properties.propertyId &&
            feature.properties.$landType === 'field'
        )
      );
      if (
        tempData.length > 0 &&
        fieldsAndAreasGeoJSONCollection?.features?.length > 0
      ) {
        const featureCentroidCoordinates = getFeaturesCentroidCoordinates(
          tempData
        );
        coordinates = [...featureCentroidCoordinates];
        // setCoordinates([...featureCentroidCoordinates]);
      }
    }

    return coordinates;
  };

  const isStartWeather = index => {
    return index === 0;
  };

  const isEndWeather = index => {
    return index === 1;
  };

  const fetchURLDetails = (
    startDate,
    startDateEpoch,
    duration,
    index,
    id,
    dueDate
  ) => {
    let startDateTimeStamp;
    // only historical data is to used.  no data will be gathered for future dates.
    if (isStartWeather(index)) {
      if (id === 'create' || id === 'convert' || id === 'duplicate')
        startDateTimeStamp = startDate?.getTime() / 1000;
      else startDateTimeStamp = startDateEpoch / 1000;
    } else if (isEndWeather(index)) {
      if (duration !== 'Custom') {
        const taskDuration = Number(duration) * 3600;
        startDateTimeStamp = startDate?.getTime() / 1000 + taskDuration; // Hour 0 represents the current hour.
      } else {
        startDateTimeStamp = dueDate?.getTime() / 1000;
      }
    } else {
      throw new Error('Unexpected weather index :', index);
    }
    const endDateTimeStamp = startDateTimeStamp + 30;
    return {
      hourlyHistoricalPath,
      startDateTimeStamp,
      endDateTimeStamp,
      index
    };
  };

  const fetchClearAppWeatherData = async (
    startDate,
    coordinates,
    urlDetails,
    updateWeather
  ) => {
    const [longitude, latitude] = coordinates;
    const { path, startDateTimeStamp, endDateTimeStamp } = urlDetails;
    // Only displays ClearApp data when date is selected by user.
    if (startDate?.getTime() < Date.now()) {
      await fetch(
        `${path}?app_id=${REACT_APP_AG_CLEAR_APP_ID}&app_key=${REACT_APP_AG_CLEAR_APP_KEY}&end=${endDateTimeStamp}&location=[(${latitude},${longitude})]&start=${startDateTimeStamp}`
      )
        .then(async response => {
          if (response.status >= 200 && response.status <= 299) {
            return response.json();
          }
          const text = await response.text();
          throw new Error(text);
        })
        .then(result => {
          const locationResult = result[`${latitude},${longitude}`];
          const firstHourlyResult =
            locationResult[Object.keys(locationResult)[0]];
          updateWeather(firstHourlyResult, startDateTimeStamp);
        })
        .catch(catchCancel)
        .catch(error => {
          parseServerError(dispatch)(error);
        });
    }
  };

  const fetchClearAppWeatherDataReturn = async (
    startDate,
    coordinates,
    urlDetails
  ) => {
    let data = {};
    const [longitude, latitude] = coordinates;
    const { startDateTimeStamp, endDateTimeStamp } = urlDetails;
    // Only displays ClearApp data when date is selected by user.
    if (startDate?.getTime() < Date.now()) {
      const weatherLandingApi = weatherApi.createChildApi({
        action: `?app_id=${REACT_APP_AG_CLEAR_APP_ID}&app_key=${REACT_APP_AG_CLEAR_APP_KEY}&end=${endDateTimeStamp}&location=[(${latitude},${longitude})]&start=${startDateTimeStamp}`
      });
      const { promise } = weatherLandingApi.fetch();
      data = await promise
        .then(result => {
          const locationResult = result.data[`${latitude},${longitude}`];
          const firstHourlyResult =
            locationResult[Object.keys(locationResult)[0]];
          return firstHourlyResult;
        })
        .catch(catchCancel)
        .catch(error => {
          parseServerError(dispatch)(error);
        });
    }
    return data;
  };

  const updateTaskWeather = async (task, status) => {
    if (status === 'complete' && weatherAutomaticToggle) {
      setUpdating(true);
      // let updatedTask;
      const taskWeatherArray = [];
      // get the weather object from the task or if none get a couple of emtpy objects
      const weatherArray = [TaskWeatherObj, TaskWeatherObj];

      for (let i = 0; i < weatherArray.length; i += 1) {
        // get url data
        const urlData = fetchURLDetails(
          new Date(task?.startDate),
          task?.startDateEpoch,
          task?.duration,
          i,
          task?.id,
          new Date(task?.dueDate)
        );

        // get weather data from api
        // eslint-disable-next-line no-await-in-loop
        const taskWeatherObj = await fetchClearAppWeatherDataReturn(
          new Date(urlData.startDateTimeStamp),
          fieldsAndAreasCentroidCoordinates,
          urlData
        );

        // format weather data for the task save
        const formattedWeatherObj = setWeatherObj(
          i,
          taskWeatherObj,
          urlData.startDateTimeStamp
        );

        // add to the array
        taskWeatherArray.push(formattedWeatherObj);
      }

      // update task with new weather array
      const updatedTask = {
        ...task,
        applicationStrategy: 'ratearea',
        weather: taskWeatherArray
      };

      const { promise } = await taskApi.update(task.taskId, updatedTask);
      await promise.catch(catchCancel).catch(err => {
        parseServerError(dispatch)(err);
      });

      setUpdating(false);
    }
  };

  return {
    setWeatherObj,
    updateTaskWeather,
    fetchClearAppWeatherData,
    fetchClearAppWeatherDataReturn,
    fetchURLDetails,
    getCoordinates,
    getNumericValue,
    checkForValidWindDirection,
    TaskWeatherObj,
    DefaultWeatherData,
    updating
  };
};

export default useWeatherData;
