import React from 'react';
import PropTypes from 'prop-types';
import FormattedNumber from 'components/FormattedNumber';

const YieldSelectedPropertyInfo = ({ property }) => {
  return (
    <div
      className="p-3 first:border-t-2 last:border-b-2 border-l-2 border-r-2"
      data-testid="yield-selected-properties-info"
    >
      <div className="flex justify-between">
        <span className="font-bold text-sm">{property.fieldName}</span>
        <span className="font-bold text-sm">
          <FormattedNumber roundedTo={2}>
            {property.reportedArea || property.boundaryArea}
          </FormattedNumber>{' '}
          ac
        </span>
      </div>
      <div>
        <span className="text-sm">{property.name}</span>
      </div>
      <div>
        <span className="text-sm text-gray-600">{property.crop.name}</span>
      </div>
    </div>
  );
};

YieldSelectedPropertyInfo.propTypes = {
  property: PropTypes.shape().isRequired
};

export default YieldSelectedPropertyInfo;
