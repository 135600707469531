import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import EmptyStateImage from 'assets/no_products_found.svg';
import { Spinner } from '@agconnections/grow-ui';

const SimplotMaterialsDropdownItem = ({ item }) => {
  const titleRef = useRef(null);
  const { value: itemValue, key, label, type } = item;
  if (!itemValue) {
    if (label === 'empty') {
      return (
        <div
          className="flex flex-col justify-center items-center h-full"
          key={key}
          data-testid="item-empty-value"
        >
          <img
            className="w-1/2 mx-auto"
            src={EmptyStateImage}
            alt="empty-state"
            data-testid="empty-image"
          />
          <p data-testid="empty-table-state-message" className="text-sm mb-4">
            Enter at least three characters to search the materials list
          </p>
        </div>
      );
    }

    if (label === 'loading') {
      return (
        <div
          className="flex flex-col justify-center items-center h-full"
          key={key}
          data-testid="item-loading-value"
        >
          <div className="w-1/2 mx-auto" data-testid="loading-spinner">
            <Spinner />
          </div>
        </div>
      );
    }

    if (label.startsWith('empty-')) {
      return (
        <div
          className="px-2 ml-2 text-neutral-60"
          key={key}
          data-testid="item-empty-value"
        >{`No ${
          type === 'COMMON' ? 'common materials' : 'materials'
        } found`}</div>
      );
    }

    return (
      <div
        className="text-neutral-60 pl-2"
        key={label}
        data-testid="item-without-value"
      >
        {label}
      </div>
    );
  }

  /**
   * Fertilizer displays, name, type and form
   * Chemical displays name, type and form
   * Others displays name, type
   */
  return (
    <div
      key={key}
      className="w-full pb-2 pt-1 px-2 flex flex-col"
      data-testid="item-with-value"
    >
      <div className="flex flex-row justify-between w-full align-middle text-sm pl-2">
        <div ref={titleRef} className="break-normal w-3/4">
          {`${itemValue?.name} - ${itemValue?.type} ${
            itemValue?.form &&
            (itemValue?.type === 'Fertilizer' || itemValue?.type === 'Chemical')
              ? `(${itemValue?.form})`
              : ''
          }`}
        </div>
      </div>
    </div>
  );
};

SimplotMaterialsDropdownItem.propTypes = {
  item: PropTypes.shape({
    value: PropTypes.shape({
      name: PropTypes.string,
      type: PropTypes.string,
      form: PropTypes.string,
      manufacturer: PropTypes.string
    }),
    key: PropTypes.string,
    label: PropTypes.string,
    type: PropTypes.string
  }).isRequired
};

export default SimplotMaterialsDropdownItem;
