import React from 'react';
import PropTypes from 'prop-types';

const TileListGroupHeading = ({ children, testId }) => (
  <div
    data-testid={testId}
    className="text-lg font-semibold text-charcoal text-opacity-90 mt-6"
  >
    {children}
    <hr className="border-charcoal border-opacity-50" />
  </div>
);

TileListGroupHeading.propTypes = {
  children: PropTypes.node,
  testId: PropTypes.string
};

TileListGroupHeading.defaultProps = {
  children: null,
  testId: 'tile-list-group-heading'
};

export default TileListGroupHeading;
