import React, { useState } from 'react';
import PropTypes from 'prop-types';
import useConnect from 'hooks/useConnect';
import { SimpleModal, Spinner } from '@agconnections/grow-ui';
import CropwiseConnectLogo from 'assets/cropwise_connect_logo.svg';

const ImportConnectModal = ({ isOpen, onCancel, onImportFinish }) => {
  const [isImporting, setIsImporting] = useState(false);
  const { cancelFetchActive, getTasksByFetchToConnect } = useConnect();

  const onImport = async () => {
    try {
      setIsImporting(true);
      const toastObject = await getTasksByFetchToConnect();
      if (Object.keys(toastObject).length) {
        onImportFinish(toastObject);
      }
    } finally {
      setIsImporting(false);
    }
  };

  const onCancelClick = () => {
    setIsImporting(false);
    cancelFetchActive();
    onCancel();
  };
  return (
    <SimpleModal
      icon={<img src={CropwiseConnectLogo} alt="Cropwise Connect" />}
      open={isOpen}
      onCancel={onCancelClick}
      onConfirm={onImport}
      close={onCancel}
      confirmLabel="Import"
      title="Import Tasks From Connect"
      isSaving={isImporting}
    >
      <div className="mt-6">
        {isImporting ? (
          <>
            <Spinner data-testid="import-modal-spinner" />
            <p className="mt-2 mb-8 text-center font-body text-sm">
              Importing tasks, this may take a minute if you have a lot of data
            </p>
          </>
        ) : (
          <p
            className="mt-2 mb-8 pl-2 pr-2 font-body text-sm"
            data-testid="import-modal-paragraph"
          >
            If you have set up a machinery integration in Cropwise Connect click
            here to import tasks and automatically import them into Cropwise
            Financials
          </p>
        )}
      </div>
    </SimpleModal>
  );
};

ImportConnectModal.defaultProps = {
  onCancel: () => {},
  onImportFinish: () => {}
};
ImportConnectModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onCancel: PropTypes.func,
  onImportFinish: PropTypes.func
};

export default ImportConnectModal;
