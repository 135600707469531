import React from 'react';
import PropTypes from 'prop-types';

const Paper = ({ className, children }) => {
  const bgColor = className === 'bg-neutral-100' ? className : 'bg-white';
  return (
    <div className={`${className} w-full p-6 mt-4 ${bgColor} border rounded`}>
      {children}
    </div>
  );
};

Paper.defaultProps = {
  className: ''
};

Paper.propTypes = {
  className: PropTypes.string,
  // children: PropTypes.element.isRequired
  children: PropTypes.oneOfType([
    PropTypes.element.isRequired,
    PropTypes.objectOf(PropTypes.object).isRequired,
    PropTypes.node
  ]).isRequired
};

export default Paper;
