import React, { useEffect, useState, useRef } from 'react';
import PropTypes from 'prop-types';

import { cloneDeep } from 'lodash';

import { Tag } from '@agconnections/grow-ui';

import ColorSelector from './ColorSelector';

const AddIcon = () => {
  return (
    <svg
      width="13"
      height="14"
      viewBox="0 0 13 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M6.0529 13.0292C2.70916 13.0292 0 10.1134 0 6.51459C0 2.9158 2.70916 0 6.0529 0C9.39664 0 12.1058 2.9158 12.1058 6.51459C12.1058 10.1134 9.39664 13.0292 6.0529 13.0292ZM9.56749 5.77907C9.56749 5.6057 9.43569 5.46385 9.27461 5.46385H7.02918V3.04715C7.02918 2.87377 6.89738 2.73192 6.73629 2.73192H5.36951C5.20842 2.73192 5.07663 2.87377 5.07663 3.04715V5.46385H2.8312C2.67011 5.46385 2.53831 5.6057 2.53831 5.77907V7.25011C2.53831 7.42348 2.67011 7.56533 2.8312 7.56533H5.07663V9.98203C5.07663 10.1554 5.20842 10.2973 5.36951 10.2973H6.73629C6.89738 10.2973 7.02918 10.1554 7.02918 9.98203V7.56533H9.27461C9.43569 7.56533 9.56749 7.42348 9.56749 7.25011V5.77907Z"
        fill="#718096"
      />
    </svg>
  );
};

const TagsDisplay = () => {
  const [selectedTags, setSelectedTags] = useState([]);
  const selectTag = tagName => {
    setSelectedTags(previousTags => {
      const previousTagsCopy = cloneDeep(previousTags);

      if (previousTagsCopy.includes(tagName)) {
        const tagNameIndex = selectedTags.indexOf(tagName);
        previousTagsCopy.splice(tagNameIndex, 1);
      } else {
        previousTagsCopy.push(tagName);
      }

      return previousTagsCopy;
    });
  };

  return (
    <>
      <div className="mb-1">
        <Tag
          onClick={() => selectTag('critical')}
          selected={selectedTags.includes('critical')}
          color="#C23234"
        >
          Critical
        </Tag>
      </div>
      <div className="mb-1">
        <Tag
          onClick={() => selectTag('high-priority')}
          selected={selectedTags.includes('high-priority')}
          color="#D95032"
        >{`|> High Priority |>`}</Tag>
      </div>
      <div className="mb-1">
        <Tag
          onClick={() => selectTag('normal-priority')}
          selected={selectedTags.includes('normal-priority')}
          color="#E99921"
        >{String.raw`/\ Normal Priority`}</Tag>
      </div>
      <div className="mb-1">
        <Tag
          onClick={() => selectTag('low-priority')}
          selected={selectedTags.includes('low-priority')}
          color="#9664F0"
        >{String.raw`\/ Low Priority`}</Tag>
      </div>
      <div className="mb-1">
        <Tag
          onClick={() => selectTag('rectangle-7')}
          selected={selectedTags.includes('rectangle-7')}
          color="#46A79F"
        >{String.raw`* Rectangle 7`}</Tag>
      </div>
      <div className="mb-1">
        <Tag
          onClick={() => selectTag('to-do')}
          selected={selectedTags.includes('to-do')}
          color="#0078CD"
        >{String.raw`[ ] To Do`}</Tag>
      </div>
    </>
  );
};

const TagsSelector = ({ handleColorSelectorToggle }) => {
  return (
    <>
      <div id="tags-display" className="p-2 mb-4">
        <TagsDisplay />
      </div>

      <button
        id="add-flag-button"
        type="button"
        className="border-t border-gray-400 py-2 flex justify-center items-center"
        onClick={handleColorSelectorToggle}
      >
        <AddIcon />
        <div className="ml-1 text-gray-600 text-sm">Add New Label</div>
      </button>
    </>
  );
};

TagsSelector.propTypes = {
  handleColorSelectorToggle: PropTypes.func.isRequired
};

const FlagSelector = ({ className, handleFlagSelectorClose }) => {
  const [colorSelectorOpened, setColorSelectorOpened] = useState(false);
  const flagSelectorNode = useRef();

  const handleColorSelectorToggle = () => {
    setColorSelectorOpened(previousColorSelectorOpened => {
      return !previousColorSelectorOpened;
    });
  };

  function handleClick(event) {
    if (
      flagSelectorNode.current &&
      flagSelectorNode.current.contains(event.target)
    ) {
      // Inside click, do nothing
      return;
    }

    // Clicked outside the component
    handleFlagSelectorClose();
  }

  useEffect(() => {
    // Add when mounted
    document.addEventListener('mousedown', handleClick);

    // Return function to be called when unmounted
    return () => {
      document.removeEventListener('mousedown', handleClick);
    };
  });

  return (
    <div
      id="flag-selector"
      ref={flagSelectorNode}
      className={`absolute z-10 flex flex-col w-64 m-4 bg-white shadow border rounded border-gray-400 ${className} text-left`}
    >
      {colorSelectorOpened ? (
        <ColorSelector handleColorSelectorToggle={handleColorSelectorToggle} />
      ) : (
        <TagsSelector handleColorSelectorToggle={handleColorSelectorToggle} />
      )}
    </div>
  );
};

FlagSelector.defaultProps = {
  className: ''
};

FlagSelector.propTypes = {
  className: PropTypes.string,
  handleFlagSelectorClose: PropTypes.func.isRequired
};

export default FlagSelector;
