import React from 'react';
import PropTypes from 'prop-types';

const EditIcon = ({ disabled }) => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M16.1701 6.0687L13.8868 3.78537C13.2699 3.20591 12.3166 3.18083 11.6701 3.72703L4.17009 11.227C3.90073 11.4987 3.73301 11.8547 3.69509 12.2354L3.33676 15.7104C3.31403 15.9575 3.40262 16.2019 3.57843 16.377C3.73569 16.533 3.94859 16.62 4.17009 16.6187H4.24509L7.72009 16.302C8.10076 16.2641 8.45679 16.0964 8.72842 15.827L16.2284 8.32703C16.8345 7.68675 16.8084 6.67684 16.1701 6.0687ZM7.57009 14.6354L5.07009 14.8687L5.29509 12.3687L10.0034 7.7187L12.2534 9.9687L7.57009 14.6354ZM11.1034 6.6187L13.3368 8.85203L15.0034 7.22703L12.7284 4.95203L11.1034 6.6187Z"
      fill="#8E9293"
      opacity={disabled ? '0' : '100'}
    />
  </svg>
);

EditIcon.defaultProps = {
  disabled: false
};

EditIcon.propTypes = {
  disabled: PropTypes.bool
};

export default EditIcon;
