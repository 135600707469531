import { useContext, useEffect, useState } from 'react';
import { flatten } from 'lodash';
import catchCancel from 'helpers/catchCancel';
import { parseServerError } from 'helpers/errorHelpers';
import { Context } from 'components/Store';
import { embeddedReport as embeddedReportApi } from 'utilities/api';
import axios from 'axios';
import { AmplitudeContext } from 'utilities/amplitude/useAmplitude';
import useReportMetadata, {
  ReportCategories,
  ReportSubTypes,
  getReport
} from './useReportMetadata';

const embedTokenBody = {
  accessLevel: 'View'
};

const genEmbedTokenV2Body = config => {
  const _datasetIds = config.datasetId
    ? flatten([config.datasetId])
    : undefined;
  const _reportIds = flatten([config.reportId]);

  return {
    datasets: _datasetIds?.map(id => ({
      id,
      xmlaPermissions: 'ReadOnly'
    })),
    reports: _reportIds.map(id => ({ id }))
  };
};

const genEmbedTokenUrl = ({ groupId, reportId }) =>
  `https://api.powerbi.com/v1.0/myorg/groups/${groupId}/reports/${reportId}/GenerateToken`;
const embedTokenV2Url = 'https://api.powerbi.com/v1.0/myorg/GenerateToken';

const getTokenUrl = reportState =>
  reportState.reportConfig.isPaginated ||
  reportState.reportConfig.embedTokenBody
    ? embedTokenV2Url
    : genEmbedTokenUrl(reportState.reportConfig);

const getTokenBody = reportState => {
  if (reportState.reportConfig.embedTokenBody) {
    return reportState.reportConfig.embedTokenBody;
  }
  return reportState.reportConfig.isPaginated
    ? genEmbedTokenV2Body(reportState.reportConfig)
    : embedTokenBody;
};

const useReport = () => {
  const [, dispatch] = useContext(Context);
  const reports = useReportMetadata();

  const [embeddedReport, setEmbeddedReport] = useState({});
  const [embedToken, setEmbedToken] = useState('');
  const [isLoadingReport, setIsLoadingReport] = useState(true);
  const [reportState, setReportState] = useState(
    getReport(reports, ReportCategories.Fields, ReportSubTypes.CropLayout)
  );

  const amplitude = useContext(AmplitudeContext);

  const loadEmbeddedReport = () => {
    if (!reportState) return undefined;

    const reportId =
      typeof reportState.reportConfig.reportId === 'object'
        ? reportState.reportConfig.reportId[0]
        : reportState.reportConfig.reportId;
    const { promise, cancel } = embeddedReportApi.post({
      ...reportState.reportConfig,
      reportId
    });
    promise
      .then(({ data }) => {
        setEmbeddedReport(data);
        axios
          .post(getTokenUrl(reportState), getTokenBody(reportState), {
            headers: {
              Authorization: `Bearer ${data.accessToken}`
            }
          })
          .then(embedTokenRes => {
            amplitude.sendEventToAmplitude(
              amplitude.events.epic.Reports.reportSuccess,
              { reportName: data.name }
            );
            setEmbedToken(embedTokenRes.data.token);
          })

          .catch(err => {
            amplitude.sendEventToAmplitude(
              amplitude.events.epic.Reports.reportFailed,
              { reportName: embeddedReport.name }
            );
            parseServerError(dispatch)(err);
          });
      })
      .catch(catchCancel)
      .catch(err => {
        amplitude.sendEventToAmplitude(
          amplitude.events.epic.Reports.reportFailed,
          { reportName: embeddedReport.name }
        );
        parseServerError(dispatch)(err);
      })
      .finally(() => setIsLoadingReport(false));

    return cancel;
  };

  useEffect(() => {
    loadEmbeddedReport();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reportState]);

  return {
    embeddedReport,
    isLoadingReport,
    loadEmbeddedReport,
    embedToken,
    setReportState,
    setEmbedToken
  };
};

export default useReport;
