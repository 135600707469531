import React from 'react';
import PropTypes from 'prop-types';

import { Input, Form } from '@agconnections/grow-ui';

function PhoneNumberField({ phoneNumber, onChange, errors }) {
  return (
    <Form.Field>
      <div className="block ml-6">
        <div className="w-full">
          <div className="block text-sm font-medium leading-5 gr-text-gray-600 sm:mt-px sm:pt-2">
            Phone Number
          </div>
        </div>
        <div className="w-full">
          <Input
            id="phone-number-input"
            onChange={onChange}
            defaultValue={phoneNumber}
            name="phoneNumber"
            error={errors}
            errorMessage={errors}
          />
        </div>
      </div>
    </Form.Field>
  );
}

PhoneNumberField.defaultProps = {
  phoneNumber: '',
  errors: null
};

PhoneNumberField.propTypes = {
  phoneNumber: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  errors: PropTypes.string
};

export default PhoneNumberField;
