import React, { useCallback, useContext, useEffect, useState } from 'react';

import { Checkbox, Spinner } from '@agconnections/grow-ui';
import { Context } from 'components/Store';
import { OpenInNewIcon } from 'components/Icons';
import useCalAgApi from 'hooks/useCalAgApi';

import { SubmissionSummaryContext } from 'screens/Integrations/SubmissionSummary/context/SubmissionSummaryProvider';

import { INTEGRATIONS } from 'screens/Integrations/helpers/constants';
import useSubmissionAnalytics from 'screens/Integrations/hooks/useSubmissionAnalytics';
import CalAgSubmissionFooter from '../CalAgSubmissionFooter';
import Product from './components/Product';
import { CalAgContext } from '../../context/Provider';
import { PROPERTIES_SELECTION_STEP } from '../../helpers/constants';
import { getCleanOverrideRegistrationNumberForUX } from '../../helpers/products';

const CalAgProductsSelection = () => {
  const { events, triggerAnalyticsEvent } = useSubmissionAnalytics();
  const {
    state: { comingFromStep },
    goForward,
    goBack,
    releaseStepForward
  } = useContext(CalAgContext);
  const [{ error }] = useContext(Context);
  const [callLoadMatches, setCallLoadMatches] = useState(false);
  const [productsMatches, setProductsMatches] = useState(new Map());
  const [loadingNext, setLoadingNext] = useState(false);
  const [loadingSubmission, setLoadingSubmission] = useState(true);
  const {
    loading: loadingMatches,
    getProductsMatches,
    updateProductsMatches
  } = useCalAgApi();
  const {
    state: { selectedProducts, products, submissionId },
    isLoading,
    setSelectedProducts,
    startSubmission,
    getSubmissionProducts,
    setSubmissionProducts
  } = useContext(SubmissionSummaryContext);

  const [selectAll, setSelectAll] = useState(false);

  useEffect(() => {
    if (comingFromStep === PROPERTIES_SELECTION_STEP) {
      setSelectedProducts([]);
      startSubmission().then(() => {
        setLoadingSubmission(false);
      });
    } else {
      setLoadingSubmission(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    async function loadProducts() {
      if (submissionId && !loadingSubmission) {
        await getSubmissionProducts();
        setCallLoadMatches(true);
      }
    }

    loadProducts();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [submissionId, loadingSubmission]);

  useEffect(() => {
    async function loadProductsMatches() {
      const response = await getProductsMatches({ submissionId });
      if (response?.data?.length > 0) {
        setProductsMatches(
          response?.data?.reduce((acc, curr) => {
            acc.set(curr.cwfProductId, curr.calAgRegistrationNumber);
            return acc;
          }, new Map())
        );
      }
    }

    if (submissionId && products?.length > 0 && callLoadMatches) {
      loadProductsMatches();
    }
  }, [
    submissionId,
    products,
    callLoadMatches,
    setProductsMatches,
    getProductsMatches
  ]);

  const handleSelectAll = useCallback(() => {
    const checked = !selectAll;
    setSelectAll(checked);
    if (checked) {
      setSelectedProducts(
        products.map(product => ({
          ...product,
          overrideRegistrationNumber: getCleanOverrideRegistrationNumberForUX(
            product.overrideRegistrationNumber ||
              productsMatches.get(product.productId)
          )
        }))
      );
    } else {
      setSelectedProducts([]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectAll, productsMatches]);

  useEffect(() => {
    setSelectAll(
      products.length && selectedProducts.length === products.length
    );
  }, [selectedProducts, setSelectedProducts, products]);

  const onNextHandler = useCallback(async () => {
    setLoadingNext(true);
    const response = await setSubmissionProducts();
    if (!(response instanceof Error)) {
      await updateProductsMatches(
        selectedProducts?.map(product => ({
          cwfProductId: product.productId,
          calAgRegistrationNumber: product.overrideRegistrationNumber
        }))
      );
      triggerAnalyticsEvent(
        events.epic.Integrations.submissionProductsSelected,
        {
          vendor: INTEGRATIONS.calAgPermits
        }
      );
      goForward();
    }
    setLoadingNext(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    setSubmissionProducts,
    updateProductsMatches,
    goForward,
    triggerAnalyticsEvent,
    events
  ]);

  useEffect(() => {
    if (!loadingSubmission && error) {
      goBack();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [error, loadingSubmission]);

  useEffect(() => {
    if (selectedProducts.length > 0) {
      releaseStepForward();
    }
  }, [selectedProducts, releaseStepForward]);

  return (
    <div
      className="flex flex-col bg-white top-30 left-26 w-140 rounded-md shadow-lg mb-20"
      data-testid="submission-calag-products-selection"
    >
      <div className="p-10">
        <h1 className="text-xl leading-7 font-normal font-semibold text-neutral-1000 mb-2">
          Select Products
        </h1>
        <h2 className="mb-6 font-body text-base font-normal text-neutral-70 text-left">
          Based on the crop seasons and properties you selected we’ve found a
          list of relevant products to submit. Choose which products you’d like
          to submit.
        </h2>
        <div className="h-full w-full mt-4">
          <div className="flex">
            <div className="ml-4">
              <Checkbox
                label="Select All"
                onChange={handleSelectAll}
                checked={selectAll}
                color="#0071cd"
              />
            </div>
            <div className="ml-auto">
              <a
                href="https://apps.cdpr.ca.gov/docs/label/prodnam.cfm"
                type="button"
                target="_blank"
                rel="noopener noreferrer"
              >
                <div className="flex flex-row">
                  <div className="block px-2 font-normal text-center w-36 text-green-700">
                    Search for CDPR Registration Number
                  </div>
                  <OpenInNewIcon fill="#0C612C" />
                </div>
              </a>
            </div>
          </div>
          <div className="border-t-2 pt-4 mt-4 gap-1 flex flex-col">
            {!loadingNext && (isLoading || loadingMatches) ? (
              <Spinner />
            ) : (
              <>
                {products.map(product => (
                  <Product
                    key={product.productId}
                    matchedRegistrationNumber={productsMatches.get(
                      product.productId
                    )}
                    product={product}
                    checked={selectedProducts.some(
                      selectedProduct =>
                        selectedProduct.productId === product.productId
                    )}
                  />
                ))}
              </>
            )}
          </div>
        </div>
      </div>
      <CalAgSubmissionFooter
        onNext={onNextHandler}
        nextLabel="Next: Enter Field Details"
        loading={loadingNext || isLoading}
        nextDisabled={
          !selectedProducts.length ||
          selectedProducts.some(
            selectedProduct =>
              selectedProduct.error ||
              !selectedProduct.overrideRegistrationNumber
          )
        }
      />
    </div>
  );
};

export default CalAgProductsSelection;
