import { ROLES } from 'utilities/access';

import Invoices from 'screens/Invoices';
import Invoice from 'screens/Invoices/Invoice';
import InvoiceView from 'screens/Invoices/Invoice/InvoiceView';

import { InvoiceIcon } from 'routes/icons';
import { paths } from 'routes/paths';

const routes = [
  {
    id: 'invoices',
    key: 'invoices',
    path: paths.invoices,
    exact: true,
    secure: true,
    disabled: false,
    component: Invoices,
    icon: InvoiceIcon,
    nav: 'leftNav'
  },
  {
    id: 'invoice',
    key: 'invoice',
    path: `${paths.invoices}/:id`,
    exact: true,
    secure: true,
    disabled: false,
    component: Invoice,
    minPermissions: {
      minAllowedRole: ROLES.FULL_CONTROL
    }
  },
  {
    id: 'invoiceView',
    key: 'invoiceView',
    path: `${paths.invoices}/:id/:view`,
    exact: true,
    secure: true,
    component: InvoiceView
  }
];

export default routes;
