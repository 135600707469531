import React from 'react';
import PropTypes from 'prop-types';

const PlanTabContents = ({ children }) => {
  return (
    <div data-testid="tabs-content" className="h-full">
      {children}
    </div>
  );
};

PlanTabContents.propTypes = {
  children: PropTypes.node.isRequired
};

export default PlanTabContents;
