import faker from 'faker';

const globalSchema = {
  active() {
    return true;
  },
  createdAt() {
    return faker.date.past();
  },
  updatedAt() {
    return faker.date.recent();
  },
  version() {
    return faker.random.number();
  }
};

export default globalSchema;
