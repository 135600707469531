/* eslint-disable import/prefer-default-export */
const columns = [
  {
    field: 'date',
    title: 'Submission Date',
    sortable: true,
    width: 'calc((100% - 85px) / 6)',
    position: 'left'
  },
  {
    field: 'status',
    title: 'Submission Status',
    sortable: true,
    width: 'calc((100% - 85px) / 6)',
    position: 'left'
  },
  {
    field: 'taskCount',
    title: 'Task Count',
    width: 'calc((100% - 85px) / 6)',
    position: 'left'
  },
  {
    field: 'cropSeasons',
    title: 'Crop Seasons',
    width: 'calc((100% - 85px) / 6)',
    position: 'left'
  },
  {
    field: 'propertiesCount',
    title: 'Properties',
    width: 'calc((100% - 85px) / 6)',
    position: 'left'
  },
  {
    width: '85px',
    position: 'left'
  }
];

export const SUBMISSIONS_HISTORY_TABLE = {
  itemsPerPage: 5,
  columns
};
