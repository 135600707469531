import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useFormikContext } from 'formik';
import formatNumber, { formatPercentage } from 'helpers/formatNumber';
import { getAbbreviatedUnit } from 'helpers/unitsAbbreviation';
import { SHRINK_TYPE } from 'screens/YieldV2/constants';
import { tabsLabels } from '../../helpers/constants';
import useParameters from '../../hook/useParameters';
import PropertiesGrid from '../PropertiesGrid';
import formatShrinkPercentage from '../../helpers/formatShrinkPercentage';
import formatAvgHarvestedQuantity from '../../helpers/formatAvgHarvestedQuantity';
import formatShrink from '../../helpers/formatShrink';

const YieldDetailsTab = ({ cropzoneTotalArea }) => {
  const { values } = useFormikContext();
  const {
    loadUnit,
    harvestedQuantity,
    sourceType,
    shrink,
    shrinkType,
    templateType
  } = values;
  const [properties, setProperties] = useState([]);

  useEffect(() => {
    setProperties([
      {
        name: 'harvestedYieldQuantity',
        value: formatNumber(harvestedQuantity, getAbbreviatedUnit(loadUnit), 2),
        ghostValue: !harvestedQuantity
      },
      {
        name: 'avgHarvestedQuantity',
        value: formatAvgHarvestedQuantity(
          harvestedQuantity,
          loadUnit,
          cropzoneTotalArea
        ),
        ghostValue: !(harvestedQuantity && loadUnit && cropzoneTotalArea)
      },
      {
        name: 'shrink',
        value:
          shrinkType === SHRINK_TYPE.BY_QUANTITY
            ? formatNumber(shrink, getAbbreviatedUnit(loadUnit), 2)
            : formatShrink(harvestedQuantity, loadUnit, shrink)
      },
      {
        name: 'shrinkPercentage',
        value:
          shrinkType === SHRINK_TYPE.BY_QUANTITY
            ? formatShrinkPercentage(harvestedQuantity, shrink)
            : formatPercentage(shrink),
        ghostValue: !(harvestedQuantity && shrink)
      }
    ]);
  }, [cropzoneTotalArea, harvestedQuantity, loadUnit, shrink, shrinkType]);

  const { removeAttributes } = useParameters(
    tabsLabels.yieldDetails,
    { source: sourceType },
    templateType
  );

  return (
    <PropertiesGrid
      removeAttributes={removeAttributes}
      properties={properties}
      values={values}
    />
  );
};

YieldDetailsTab.propTypes = {
  cropzoneTotalArea: PropTypes.number.isRequired
};

export default YieldDetailsTab;
