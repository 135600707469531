import React from 'react';
import PropTypes from 'prop-types';
import { Button, SimpleModal } from '@agconnections/grow-ui';
import ExclamationTriangleOutline from 'components/Icons/components/ExclamationTriangleOutline';

const MaintenanceModal = ({ open, handleClose, message }) => {
  const buttonStyle = {
    marginLeft: 'auto',
    backgroundColor: '#14803C',
    color: 'white',
    padding: '20px 15px'
  };

  return (
    <SimpleModal
      onCancel={handleClose}
      close={handleClose}
      open={open}
      type="buttonless"
    >
      <div className="pr-12 grid grid-cols-12">
        <div className="col-span-1">
          <div className="mt-2 mr-4">
            <ExclamationTriangleOutline />
          </div>
        </div>
        <div className="col-span-11">
          <h2 className="text-lg mb-4 mt-2">
            <b>Upcoming Maintenance</b>
          </h2>
          <span className="text-base">
            Cropwise Financials will be offline for planned maintenance from{' '}
            <b>{message}</b> The tool will be inaccessible during this time
            frame and we apologize for any inconvenience this may cause.
          </span>
          <Button style={buttonStyle} onClick={handleClose}>
            OK
          </Button>
        </div>
      </div>
    </SimpleModal>
  );
};

MaintenanceModal.defaultProps = {
  open: false,
  handleClose: () => {}
};

MaintenanceModal.propTypes = {
  open: PropTypes.bool,
  handleClose: PropTypes.func,
  message: PropTypes.string.isRequired
};

export default MaintenanceModal;
