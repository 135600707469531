import { useContext, useEffect, useState, useCallback } from 'react';
import { Context } from 'components/Store';
import catchCancel from 'helpers/catchCancel';
import { parseServerError } from 'helpers/errorHelpers';
import { sortBy } from 'lodash';
import useInventoryApi from 'hooks/useInventoryApi';
import PAGINATION_SIZE from 'helpers/globalConstants';
import { productsMasterData as productsMasterDataAPI } from 'utilities/api';
import combineDuplicatedProducts from './helperFunction';

const useInventoryData = (runSideEffect = true) => {
  const inventoryAPI = useInventoryApi('inventory/products');
  const [, dispatch] = useContext(Context);
  const [products, setProducts] = useState([]);
  const [loading, setLoading] = useState(true);
  const [totalInventoryProducts, setTotalInventoryProducts] = useState(0);

  const getInventoryMasterDetails = async filteredData => {
    const orgId = localStorage.getItem('selectedOrganizationId');
    const { promise } = await productsMasterDataAPI.fetch(orgId);
    promise
      .then(data => {
        const combinedProducts = combineDuplicatedProducts(
          filteredData,
          data.data
        );
        setProducts(sortBy(combinedProducts, 'productName'));
        setTotalInventoryProducts(combinedProducts.length);
      })
      .catch(catchCancel)
      .catch(parseServerError(dispatch))
      .finally(() => setLoading(false));
  };

  const getInventoryDetails = useCallback(
    (pageNo = 0, size = PAGINATION_SIZE) => {
      setLoading(true);
      const { promise } = inventoryAPI.fetch(undefined, {
        pageNo,
        size
      });
      promise
        .then(({ data }) => {
          getInventoryMasterDetails(data.results);
        })
        .catch(catchCancel)
        .catch(parseServerError(dispatch))
        .finally(() => setLoading(false));
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [inventoryAPI]
  );

  useEffect(() => {
    if (!runSideEffect) return;
    getInventoryDetails();
  }, [getInventoryDetails, runSideEffect]);

  return {
    products,
    setProducts,
    setLoading,
    loading,
    totalInventoryProducts,
    setTotalInventoryProducts,
    getInventoryDetails
  };
};

export default useInventoryData;
