import React, { useState } from 'react';
import { Button } from '@agconnections/grow-ui';
import PropTypes from 'prop-types';
import { useFormikContext } from 'formik';
import LoadsManagerModal from '../LoadsManagerModal';
import isOverdrawingSource from '../../helpers/isOverdrawingSource';

const FooterButtons = ({ isDisabled, showCreateNewLoadWidget }) => {
  const [
    overdrawnSourceModalVisible,
    setOverdrawnSourceModalVisible
  ] = useState(false);

  const [addAndCreateNew, setAddAndCreateNew] = useState(false);

  const {
    handleSubmit,
    validateForm,
    setFieldValue,
    setFieldTouched,
    values,
    isValid,
    dirty
  } = useFormikContext();

  const overdrawnSource = isOverdrawingSource({
    sourceType: values?.sourceType,
    source: values?.sources[0],
    load: values?.load
  });

  const onAdd = async () => {
    if (overdrawnSource) {
      setOverdrawnSourceModalVisible(true);
      return;
    }
    handleSubmit();
    const validationErrors = await validateForm();
    if (Object.keys(validationErrors).length === 0) {
      showCreateNewLoadWidget();
      setFieldTouched('preventNavigation', true);
    }
  };

  const onAddAndCreateNew = async () => {
    if (overdrawnSource) {
      setOverdrawnSourceModalVisible(true);
      return;
    }
    handleSubmit();
    await validateForm();
    setFieldTouched('preventNavigation', true);
  };

  const buttonDisabled = isDisabled || !isValid || !dirty;

  return (
    <div
      data-testid="add-yield-footer"
      className="flex gap-4 py-3 px-6 justify-end w-full border-neutral-20 border-t-1"
    >
      <Button type="outline" onClick={onAdd} disabled={buttonDisabled}>
        Add to List
      </Button>
      <Button
        ghost
        type="primary"
        onClick={() => {
          setFieldValue('preventNavigation', false);
          onAddAndCreateNew();
          setAddAndCreateNew(true);
        }}
        disabled={buttonDisabled}
      >
        Add to List & Create New
      </Button>
      <LoadsManagerModal
        showCreateNewLoadWidget={showCreateNewLoadWidget}
        addAndCreateNew={addAndCreateNew}
        type="overdrawnSource"
        setModalVisible={setOverdrawnSourceModalVisible}
        modalVisible={overdrawnSourceModalVisible}
      />
    </div>
  );
};

FooterButtons.propTypes = {
  isDisabled: PropTypes.bool,
  showCreateNewLoadWidget: PropTypes.func
};

FooterButtons.defaultProps = {
  isDisabled: false,
  showCreateNewLoadWidget: () => {}
};

export default FooterButtons;
