import React, { useState, useEffect, useContext, useRef } from 'react';
import PropTypes from 'prop-types';
import {
  Spinner,
  Columns,
  Column,
  Table,
  TagCloud,
  Tag
} from '@agconnections/grow-ui';
import ViewOnlyField from 'components/ViewOnlyField';
import FormattedNumber from 'components/FormattedNumber';
import { tags as tagsApi, invoice as invoiceApi } from 'utilities/api';
import { Context } from 'components/Store';
import catchCancel from 'helpers/catchCancel';
import { parseServerError } from 'helpers/errorHelpers';
import useCompanies from 'hooks/useCompanies';
import useCropSeason from '../../../../../hooks/useCropSeason';

const InvoiceView = ({ id }) => {
  const isMounted = useRef(true);
  const [
    {
      loggedInUserOrgPermission: {
        role: memberRole,
        financialAccess: memberFinancialAccess = 'none'
      }
    },
    dispatch
  ] = useContext(Context);
  const [cropSeasonNames, setCropSeasonNames] = useState('');
  const [, setTags] = useState([]);
  // eslint-disable-next-line no-unused-vars
  const [sort, setSort] = useState();
  const [invoice, setInvoice] = useState(null);
  const [loading, setLoading] = useState(true);
  const [updatedTags, setUpdatedTags] = useState([]);
  const [companyName, setCompanyName] = useState('--');
  const { cropSeasons } = useCropSeason();
  const { companies } = useCompanies();

  useEffect(() => {
    return () => {
      isMounted.current = false;
    };
  }, []);

  const formatNumber = numValue => {
    return (
      <FormattedNumber unit="usCurrency" roundedTo={2}>
        {numValue}
      </FormattedNumber>
    );
  };

  const getTotInvCostValue = invCost => {
    if (
      (memberRole === 'Full control' && memberFinancialAccess === 'none') ||
      (memberRole === 'View Only' && memberFinancialAccess === 'none')
    ) {
      return '';
    }
    if (
      memberRole === 'Admin' ||
      memberRole === 'View Only' ||
      (memberRole === 'Full control' && memberFinancialAccess === 'WRITE') ||
      (memberRole === 'Full control' && memberFinancialAccess === 'READ')
    ) {
      return formatNumber(invCost);
    }
    if (memberRole === 'Full control' && memberFinancialAccess === 'READ') {
      return '';
    }

    return '';
  };

  const dateOptions = {
    year: 'numeric',
    month: 'long',
    day: 'numeric',
    hour: '2-digit',
    minute: '2-digit'
  };

  const getCropSeasons = currInv => {
    const cropNames = [];
    /* eslint no-unused-expressions: "off", curly: "error" */
    currInv?.cropSeasons?.forEach(season => cropNames.push(season.name));
    return cropNames;
  };

  const getTags = async currInv => {
    const { promise } = tagsApi.fetch();
    await promise
      .then(response => {
        const _tags = response.data.results;
        const filteredTags = _tags.filter(tag =>
          currInv?.flagIds?.includes(tag.id)
        );
        setTags(filteredTags);
        setUpdatedTags(filteredTags || []);
      })
      .catch(catchCancel)
      .catch(err => {
        parseServerError(err);
      });
  };

  const productTable = () => {
    const handleSort = () => {};

    return (
      <Table>
        <Table.Header>
          <Table.Cell sortable sort={sort} onClick={handleSort}>
            Product Name ({invoice?.invoiceProducts?.length})
          </Table.Cell>
          <Table.Cell sortable sort={sort} onClick={handleSort}>
            Total Product
          </Table.Cell>
          <Table.Cell sortable sort={sort} onClick={handleSort}>
            Unit Price
          </Table.Cell>
          <Table.Cell sortable sort={sort} onClick={handleSort}>
            Total Price per Product
          </Table.Cell>
        </Table.Header>
        {invoice?.invoiceProducts?.map(prod => {
          return (
            <Table.Row>
              <Table.Cell>{prod.productName}</Table.Cell>
              <Table.Cell>{`${prod.productDensity} ${prod.totalQuantityUnit}`}</Table.Cell>
              <Table.Cell>
                {getTotInvCostValue(prod.totalQuantityValue)}
              </Table.Cell>
              <Table.Cell>{getTotInvCostValue(prod.totalCostValue)}</Table.Cell>
            </Table.Row>
          );
        })}
      </Table>
    );
  };

  useEffect(() => {
    const getInvoiceApi = invoiceApi.createChildApi({
      action: `invoice/${id}`
    });
    const { promise } = getInvoiceApi.fetch();
    promise
      .then(({ data }) => {
        if (isMounted.current) {
          setInvoice(data);
          getTags(data);
          setCropSeasonNames(getCropSeasons(data));
          if (companies?.length > 0 && data.vendor !== '') {
            setCompanyName(
              companies.filter(c => c.id === data.vendor)[0]?.name
            );
          }
          setLoading(false);
        }
      })
      .catch(catchCancel)
      .catch(err => {
        parseServerError(dispatch)(err);
        setLoading(false);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cropSeasons, companies, dispatch, id]);

  return (
    <>
      {loading ? (
        <Spinner />
      ) : (
        <>
          <div className="border rounded">
            <div className="flex py-5 ml-4">
              <div className="w-full">
                <Columns>
                  <Column width="w-full">
                    <div className="text-2xl font-bold ml-4">
                      Invoice Details
                    </div>
                    <ViewOnlyField
                      className="mx-4 mt-4"
                      header="Invoice Name/Number"
                      data={invoice?.invoiceNumber}
                      testId="invNumber"
                    />
                  </Column>
                </Columns>
                <Columns>
                  <Column width="w-120">
                    <ViewOnlyField
                      className="mx-4 mt-4"
                      header="Invoice Date"
                      data={
                        invoice?.invoiceDateEpoch > 0
                          ? new Date(invoice?.invoiceDateEpoch).toLocaleString(
                              'en-us',
                              dateOptions
                            )
                          : '--'
                      }
                      testId="invDate"
                    />
                  </Column>
                  <Column width="w-56">
                    <div className="flex items-center w-full h-full mt-4">
                      <svg
                        width="53"
                        height="16"
                        viewBox="0 0 53 16"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M52.7071 8.7071C53.0976 8.31658 53.0976 7.68341 52.7071 7.29289L46.3431 0.928928C45.9526 0.538404 45.3195 0.538404 44.9289 0.928928C44.5384 1.31945 44.5384 1.95262 44.9289 2.34314L50.5858 8L44.9289 13.6569C44.5384 14.0474 44.5384 14.6805 44.9289 15.0711C45.3195 15.4616 45.9526 15.4616 46.3431 15.0711L52.7071 8.7071ZM8.74228e-08 9L52 9L52 7L-8.74228e-08 7L8.74228e-08 9Z"
                          fill="#696F88"
                        />
                      </svg>
                    </div>
                  </Column>
                  <Column width="w-120">
                    <ViewOnlyField
                      className="mx-4 mt-4"
                      header="Due Date"
                      data={
                        invoice?.invoiceDueDateEpoch > 0
                          ? new Date(
                              invoice?.invoiceDueDateEpoch
                            ).toLocaleString('en-us', dateOptions)
                          : '--'
                      }
                      testId="invDate"
                    />
                  </Column>
                </Columns>
                <Columns>
                  <Column width="w-full">
                    <ViewOnlyField
                      className="mx-4 mt-4"
                      header="Company"
                      data={companyName}
                      testId="vendorName"
                    />
                  </Column>
                </Columns>
                <Columns>
                  <Column width="w-120">
                    <ViewOnlyField className="mx-4 mt-4" header="Crop Season" />
                    <div className="ml-3 mt-2">
                      {cropSeasonNames && (
                        <TagCloud>
                          {cropSeasonNames?.map(season => {
                            return <Tag>{season}</Tag>;
                          })}
                        </TagCloud>
                      )}
                    </div>
                  </Column>
                  <Column width="w-full">
                    <ViewOnlyField className="mx-4 mt-4" header="Tags" />
                    <div className="ml-3 mt-2">
                      {updatedTags?.length > 0 && (
                        <TagCloud>
                          {updatedTags?.map(tag => {
                            return (
                              <Tag key={tag.id} color={tag.color}>
                                {tag.name}
                              </Tag>
                            );
                          })}
                        </TagCloud>
                      )}
                    </div>
                  </Column>
                </Columns>
              </div>
            </div>
            <div className="mt-12 py-6 border-t ml-6 mr-6">
              <div className="mt-12">
                <div className="text-2xl font-bold">Products & Services</div>
                <div className="mt-12">
                  {invoice?.invoiceProducts && productTable()}
                </div>
              </div>
            </div>
            <div className="mt-6 py-4 ml-6 mr-4">
              <div className="text-2xl font-bold">Notes</div>
              <div className="mt-3 border p-4 h-64 mb-8 overflow-y-scroll">
                {invoice?.notes}
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
};

InvoiceView.propTypes = {
  id: PropTypes.string.isRequired
};

export default InvoiceView;
