import * as Yup from 'yup';
import PropTypes from 'prop-types';

export const validationSchema = Yup.object().shape({
  packageWeight: Yup.number()
    .notOneOf([0], 'Weight cannot be 0')
    .positive('Weight must be a positive number')
    .typeError('Weight must be a number')
});

export const TProductMissingWeight = PropTypes.shape({
  productName: PropTypes.string.isRequired,
  productId: PropTypes.string.isRequired,
  operationsCount: PropTypes.number.isRequired,
  cropName: PropTypes.string,
  stdPackageUnit: PropTypes.string.isRequired,
  manufacturerName: PropTypes.string.isRequired,
  registrationNumber: PropTypes.string.isRequired
});

export const RESOLVED_PACKAGE_WEIGHT_KEY = 'product-entered-package-weight';

export const TOAST_STATES = {
  info: 'info',
  success: 'success',
  error: 'error'
};
