/* eslint-disable react/forbid-prop-types */
import React, { createContext } from 'react';
import PropTypes from 'prop-types';

export const TaskContext = createContext();

export const TaskProvider = ({ children, updatingTask, updatingWeather }) => {
  return (
    <TaskContext.Provider
      value={{
        updatingTask,
        updatingWeather
      }}
    >
      {children}
    </TaskContext.Provider>
  );
};

TaskProvider.propTypes = {
  children: PropTypes.element,
  updatingTask: PropTypes.object,
  updatingWeather: PropTypes.bool
};

TaskProvider.defaultProps = {
  children: <></>,
  updatingTask: {},
  updatingWeather: false
};
