import React from 'react';
import { YIELD_DEFAULT_ROUND } from 'screens/YieldV2/constants';
import FormInputWithPrecisionDisplay from '../../../FormInputWithPrecisionDisplay';

const MoistureField = () => {
  return (
    <div className="flex flex-col gap-0.5 weight-input">
      <label
        htmlFor="moisture"
        className="text-neutral-60 tracking-tighter text-sm"
      >
        Moisture
      </label>
      <FormInputWithPrecisionDisplay
        fieldName="moisture"
        inputSuffix="%"
        id="moisture"
        round={YIELD_DEFAULT_ROUND}
      />
    </div>
  );
};

export default MoistureField;
