/* eslint-disable react/prop-types */
import React, { useContext, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Button } from '@agconnections/grow-ui';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronLeft } from '@fortawesome/free-solid-svg-icons';
import PropTypes from 'prop-types';
import { Context } from 'components/Store';
import FormattedNumber from 'components/FormattedNumber';
import { paths } from 'routes/paths';
import YieldIcon from 'assets/yield_icon.svg';
import DeleteIcon from 'assets/deleteIcon.svg';
import { getAbbreviatedUnit } from 'helpers/unitsAbbreviation';
import EditIcon from 'assets/edit_outlined.svg';
import LoadDetailsDrawer from '../LoadDetailsDrawer';
import PropertyAsSourceDetails from '../SourceDetails/PropertyAsSourceDetails';
import LoadDetailsInfoTabs from './components/LoadDetailsInfoTabs';
import StorageAsSourceDetails from '../SourceDetails/StorageAsSourceDetails';
import {
  ICONS_BY_LOAD_DIRECTION,
  LOAD_TYPES,
  TLoad,
  treeDataPropType
} from '../../../Loads/constants';
import DeleteConfirmation from '../../../Loads/components/DeleteConfirmation';

const LoadDetailsInfo = ({ load, treeData, cropzoneTotalArea }) => {
  const history = useHistory();
  const sourcesQnt = load?.sources?.length || 0;
  const [{ cropSeasons }] = useContext(Context);
  const [visible, setVisible] = useState(false);
  const sourceToDestination = `${load?.sourceType}_${load?.destinationType}`;
  const [showDeleteModal, setShowDeleteModal] = useState(false);

  const showDrawer = () => {
    setVisible(true);
  };
  const onCloseHandler = () => {
    setVisible(false);
  };

  const cropSeasonHandler = () => {
    const newCropSeason = cropSeasons?.find(
      cropSeason => cropSeason?.id === load?.cropSeasonId
    );
    return newCropSeason?.name;
  };

  const rightDetailsPanelHandler = () => {
    if (load?.sourceType === LOAD_TYPES.CROP_ZONE)
      return (
        <PropertyAsSourceDetails
          cropzoneTotalArea={cropzoneTotalArea}
          visible={visible}
          showDrawer={showDrawer}
          load={load}
        />
      );
    if (load?.sourceType === LOAD_TYPES.STORAGE)
      return <StorageAsSourceDetails load={load} />;
    return null;
  };

  const handleEdit = () => {
    history.push(`${paths.yieldV2}/loads/${load.id}/edit`);
  };

  if (!load) return null;

  return (
    <>
      <DeleteConfirmation
        id={load.id}
        open={showDeleteModal}
        onCancel={setShowDeleteModal}
        onResult={result => {
          history.push({
            pathname: `${paths.yieldV2}/loads`,
            toast: result
          });
        }}
      />
      <div className="w-full flex items-center justify-between p-4 bg-white border-b">
        <div className="flex items-center gap-6">
          <Button
            onClick={history.goBack}
            icon={
              <FontAwesomeIcon
                data-testid="chevron-left-icon"
                icon={faChevronLeft}
                size="xs"
                style={{ marginRight: '4px' }}
              />
            }
            type="outline"
          >
            Back
          </Button>
          <h1 className="text-2xl font-semibold flex flex-row">
            <img
              className="mr-3"
              src={ICONS_BY_LOAD_DIRECTION[sourceToDestination]}
              alt={sourceToDestination}
            />

            {load?.name}
          </h1>
        </div>

        <div className="flex items-center gap-2">
          {' '}
          <Button
            icon={<img width={18} src={EditIcon} alt="edit icon" />}
            type="outline"
            onClick={handleEdit}
          >
            Edit
          </Button>
          <Button
            icon={<img width={18} src={DeleteIcon} alt="delete icon" />}
            type="outline"
            onClick={() => setShowDeleteModal(true)}
          >
            Delete
          </Button>
        </div>
      </div>
      <div className="w-full p-4">
        <div className="flex gap-4">
          <section className="items-center bg-white rounded-lg shadow-sm-card">
            <div className="w-full border-b py-3 flex flex-row">
              <img className="ml-4" src={YieldIcon} alt="yield icon" />
              <div className="ml-2 font-normal">Yield</div>
            </div>
            <div className="m-4 mb-0">
              <div className="text-neutral-60">Yield quantity</div>
              <div className="flex flex-row">
                <div className="font-semibold text-3xl flex flex-row">
                  <FormattedNumber roundedTo={2}>{load?.load}</FormattedNumber>
                </div>
                <div className="text-sm font-normal	pt-4 ml-1">
                  {getAbbreviatedUnit(load?.loadUnit)}
                </div>
              </div>
            </div>
            {load?.sourceType !== LOAD_TYPES.STORAGE ? (
              <div className="m-4 mb-0">
                <div className="text-neutral-60">Avg. quantity</div>
                <div className="flex flex-row">
                  <div className="font-semibold text-3xl flex flex-row">
                    <FormattedNumber roundedTo={2}>
                      {parseFloat(load?.load) /
                        load?.sources.reduce(
                          (accumulator, source) => accumulator + source.area,
                          0
                        )}
                    </FormattedNumber>
                  </div>
                  <div className="pt-4 ml-1">{`${getAbbreviatedUnit(
                    load.loadUnit
                  )} / ac`}</div>
                </div>
              </div>
            ) : null}
            {load?.loadUnit === 'bu' ? (
              <div className="mt-4 ml-4 mb-2 text-neutral-60">
                1.00 bushel = 56.00 lbs
              </div>
            ) : null}
          </section>

          {rightDetailsPanelHandler()}
        </div>
        <LoadDetailsDrawer
          cropzoneTotalArea={cropzoneTotalArea}
          sourcesQnt={sourcesQnt}
          cropSeasonHandler={cropSeasonHandler}
          visible={visible}
          onClose={onCloseHandler}
          load={load}
          treeData={treeData}
        />
        <LoadDetailsInfoTabs
          load={load}
          cropzoneTotalArea={cropzoneTotalArea}
        />
      </div>
    </>
  );
};

LoadDetailsInfo.defaultProps = {
  cropzoneTotalArea: 0,
  treeData: null
};

LoadDetailsInfo.propTypes = {
  load: TLoad.isRequired,
  cropzoneTotalArea: PropTypes.number,
  treeData: treeDataPropType
};

export default LoadDetailsInfo;
