import React from 'react';
import AgVendLogo from 'assets/integrations/AgVend.png';
import Provider from 'screens/Integrations/SubmissionHistory/context/Provider';
import Instructions from 'screens/Integrations/components/Instructions';
import LandingPage from 'screens/Integrations/components/LandingPage';
import { INTEGRATIONS } from 'screens/Integrations/helpers/constants';

export const STEPS = [
  {
    number: 1,
    header: 'Select Invoices',
    description:
      'Start a new import and select from your list of invoices sent from AgVend'
  },
  {
    number: 2,
    header: 'Match with AgVend',
    description:
      'Match up products and companies from invoices to records in Cropwise Financials'
  },
  {
    number: 3,
    header: 'Review and Import',
    description: 'Review the list of invoices you’ve selected to import'
  }
];

const AgVendLandingPage = () => {
  return (
    <>
      <LandingPage
        headerText={`${INTEGRATIONS.agVend} Retailer Integration`}
        breadcrumbText={INTEGRATIONS.agVend}
        ownerText={INTEGRATIONS.agVend}
        targetPath={INTEGRATIONS.agVend}
        LogoImg={AgVendLogo}
        buttonText="Start a New AgVend Import"
        imgStyle={{ width: '168px' }}
      />
      <div className="w-full border-t-1 mb-5" />
      <Provider integrationType={INTEGRATIONS.agVend} />
      <Instructions
        steps={STEPS}
        footerInstructions={null}
        headerInstructions="How to import your invoices"
      />
      <div className="w-1/2 ml-2">
        <span className="text-black font-semibold">About</span>
        <p>
          AgVend is the leading provider of digital enablement solutions that
          transform the way agribusinesses operate and engage with their
          customers. AgVend’s white-labeled system of action streamlines
          workflows from field to office, enabling frontline teams to offer more
          efficient and differentiated customer interactions. Since launching
          their first platform in 2020, AgVend’s technology is used by more than
          25% of the North American ag retail market. Through their growing
          network of retailers and integrations with third-party partners,
          AgVend is laying the foundation for a digitally connected agricultural
          supply chain. Headquartered in Austin, TX, AgVend operates across all
          major North American agricultural regions.
        </p>
      </div>
    </>
  );
};

export default AgVendLandingPage;
