import React from 'react';
import PropTypes from 'prop-types';
import { Tabs } from '@agconnections/grow-ui';
import productIcon from 'assets/product-icon.svg';
import ActiveIngredientSummary from './ActiveIngredientSummary';
import PropertyAppliedProducts from '../PropertyAppliedProducts';
import FertilizerSummation from '../FertilizerSummation';
import PropertyCardHeader from '../PropertyCardHeader';

const PropertyCropZoneProducts = ({ id }) => {
  return (
    <div>
      <PropertyCardHeader
        title="Products"
        description="All active and used products during the selected crop season"
        icon={productIcon}
      />
      <Tabs>
        <Tabs.Tab label="All Applied Products">
          <PropertyAppliedProducts level="cropzone" id={id} />
        </Tabs.Tab>
        <Tabs.Tab label="Fertilizer Summation">
          <FertilizerSummation id={id} />
        </Tabs.Tab>
        <Tabs.Tab label="Active Ingredient Summary">
          <ActiveIngredientSummary id={id} />
        </Tabs.Tab>
      </Tabs>
    </div>
  );
};

PropertyCropZoneProducts.propTypes = {
  id: PropTypes.string.isRequired
};

export default PropertyCropZoneProducts;
