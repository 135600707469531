import React from 'react';
import PropTypes from 'prop-types';
import FormattedNumber from 'components/FormattedNumber';
import { useParams } from 'react-router-dom';

const PriceDisplay = ({
  variableCost,
  fixedCost,
  totalProjectedNetRevenue,
  perAcreProjectedNetRevenue,
  memberRole,
  memberFinancialAccess
}) => {
  const { id } = useParams();
  const getTotalCost = price => {
    if (memberRole === 'Full control' && memberFinancialAccess === 'none') {
      return '';
    }
    if (
      memberRole === 'Admin' ||
      memberRole === 'View Only' ||
      (memberRole === 'Full control' && memberFinancialAccess === 'WRITE') ||
      (memberRole === 'Full control' &&
        memberFinancialAccess === 'READ' &&
        id !== 'plan')
    ) {
      return price;
    }
    if (
      memberRole === 'Full control' &&
      memberFinancialAccess === 'READ' &&
      id === 'plan'
    ) {
      return '';
    }
    return '';
  };

  return (
    <>
      <div className="flex flex-col mr-4 w-50">
        <div className="text-neutral-300 opacity-60">Variable Cost</div>
        <div data-testid="variable-cost" className="text-neutral-300">
          <FormattedNumber unit="usCurrency" roundedTo={2}>
            {getTotalCost(variableCost)}
          </FormattedNumber>
        </div>
      </div>
      <div className="flex flex-col mr-4 w-50">
        <div className="text-neutral-300 opacity-60">Fixed Cost</div>
        <div data-testid="variable-cost" className="text-neutral-300">
          <FormattedNumber unit="usCurrency" roundedTo={2}>
            {getTotalCost(fixedCost || 0)}
          </FormattedNumber>
        </div>
      </div>
      <div className="flex flex-col mr-4 w-50">
        <div className="text-neutral-300 opacity-60">Proj. Net Revenue</div>
        <div data-testid="net-revenue" className="text-neutral-300">
          <FormattedNumber unit="usCurrency" roundedTo={2}>
            {getTotalCost(totalProjectedNetRevenue || 0)}
          </FormattedNumber>
        </div>
      </div>
      <div className="flex flex-col mr-4 w-50">
        <div className="text-neutral-300 opacity-60">
          Proj. Net Revenue Per Area
        </div>
        <div data-testid="net-revenue-per-acre" className="text-neutral-300">
          <div>
            {isFinite(perAcreProjectedNetRevenue) && (
              <FormattedNumber unit="usCurrency" roundedTo={2}>
                {getTotalCost(perAcreProjectedNetRevenue || 0)}
              </FormattedNumber>
            )}
            {'/ac'}
          </div>
        </div>
      </div>
    </>
  );
};

PriceDisplay.propTypes = {
  variableCost: PropTypes.number.isRequired,
  fixedCost: PropTypes.number.isRequired,
  totalProjectedNetRevenue: PropTypes.number.isRequired,
  perAcreProjectedNetRevenue: PropTypes.number.isRequired,
  memberRole: PropTypes.string.isRequired,
  memberFinancialAccess: PropTypes.string.isRequired
};

export default PriceDisplay;
