import { format } from 'date-fns';

/* eslint-disable import/prefer-default-export */
export const formatEpochDate = date => {
  return date ? format(date, 'MMM d, yyyy') : null;
};

/**
 * Formats a date to a specific format: "Sep 15 2023 08:00AM".
 *
 * @param {string} date - The date to format.
 * @returns {string} The formatted date string. If the date is null or undefined, returns undefined.
 *
 * @example
 * "Sep 15, 2023 08:00AM"
 */
export const formatDateToShortMonthDayYearTime = date => {
  const dateObj = new Date(date);
  return dateObj
    ?.toLocaleString('en-US', {
      month: 'short',
      day: '2-digit',
      year: 'numeric',
      hour: '2-digit',
      minute: '2-digit',
      hour12: true
    })
    .replace(/(\d{2}:\d{2})\s?(AM|PM)/, '$1$2') // remove space between time and AM/PM
    .replace(/(\d{4}),/, '$1'); // remove comma after year
};

/**
 * Formats a date to a specific format: "Sep 15 2023".
 *
 * @param {string} date - The date to format.
 * @returns {string} The formatted date string. If the date is null or undefined, returns undefined.
 *
 * @example
 * "Sep 15, 2023"
 */
export const formatDateToShortMonthDayYear = date => {
  const dateObj = new Date(date);
  return dateObj
    ?.toLocaleString('en-US', {
      month: 'short',
      day: '2-digit',
      year: 'numeric'
    })
    .replace(/(\d{4}),/, '$1'); // remove comma after year
};

/**
 * Return array of next year, current year and past x years".
 *
 * @param {number} yearCount - The amount of years needed.
 * @returns {Array} Array of next year, current year and nth past years according with amount of yearCount in param.
 *
 * @example
 * ["2025", "2024", "2023", "2022", "2021"]
 */
export const getNextYearAndPastNthYears = yearCount => {
  const currentYear = new Date().getFullYear();

  const years = [];
  // eslint-disable-next-line no-plusplus
  for (let i = 0; i < yearCount; i++) {
    years.push(String(currentYear + 1 - i));
  }

  return years;
};

/**
 * Return Javascript Date from YYYY-mm-dd fixing time to local timezone.
 *
 * @param {string} date - The date to format.
 * @returns {Date} Javascript Date Object . If the date is null or undefined, returns undefined.
 *
 * @example
 * "Sep 15, 2023"
 */
export const getUTCDateByDateString = date => {
  const dateObj = new Date(date);
  return dateObj.setMinutes(dateObj.getMinutes() + dateObj.getTimezoneOffset());
};
