import React, { useState, useEffect, useContext } from 'react';
import { Formik } from 'formik';
import { GLOBAL_SET_ERROR } from 'reducers/reducer';
import { CLIENT_ERROR } from 'helpers/errorHelpers';
import { useHistory, useParams } from 'react-router-dom';
import { Context } from 'components/Store';
import { Spinner, Tabs } from '@agconnections/grow-ui';
import { useAuth } from 'utilities/base-auth';
import Breadcrumb from 'components/App/AppShell/components/Breadcrumb';
import usePopulateMapData from 'hooks/usePopulateMapData';
import { AmplitudeContext } from 'utilities/amplitude/useAmplitude';
import useTagsData from 'hooks/useTagsData';
import { paths } from 'routes/paths';
import { FETCH_TYPES } from 'helpers/constants';
import DetailsTab from './components/DetailsTab';
import ProductsTab from './components/ProductsTab';
import PropertiesTab from './components/PropertiesTab';
import TopHeader from './components/TopHeader';
import useRecommendationData from './hooks/useRecommendationData';
import dataMappers from './helpers/dataMappers';

async function handleSave({
  saveRecommendation,
  setRedirect,
  setToastRenderContents,
  values
}) {
  const success = await saveRecommendation(values);
  if (success) {
    setToastRenderContents('Save Succeeded');
    setRedirect(paths.recommendations);
  }
}

async function onSubmit({
  saveRecommendation,
  setRedirect,
  setToastRenderContents,
  values
}) {
  await handleSave({
    saveRecommendation,
    setRedirect,
    setToastRenderContents,
    values: dataMappers.formValuesToApi(values)
  });
}

function Recommendation() {
  const [initialValues, setInitialValues] = useState();
  const [selectedTabIndex] = useState(0);
  const [, setToastRenderContents] = useState(null);
  const [context, dispatch] = useContext(Context);
  const { tags } = useTagsData();

  const { user } = useAuth();
  const { id } = useParams();
  const history = useHistory();
  const [recId] = useState(id === 'duplicate' ? context.planToConvert.id : id);
  const [selectedCropSeasonIds, setSelectedCropSeasonIds] = useState(
    JSON.parse(localStorage.getItem('selectedCropSeasons')) || null
  );
  const amplitude = useContext(AmplitudeContext);
  const {
    isSaving,
    saveRecommendation,
    isLoading,
    recommendation
  } = useRecommendationData(recId, id);

  const {
    dataSorted: propertyLandingPageData,
    loading: propertiesLoading,
    fieldsAndAreasGeoJSONCollection,
    reloadData
  } = usePopulateMapData({
    fetchType: FETCH_TYPES.propertiesMap,
    isCacheOn: true
  });

  useEffect(() => {
    reloadData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const updateSelectedCropSeasons = () => {
    reloadData();
    setSelectedCropSeasonIds(
      JSON.parse(localStorage.getItem('selectedCropSeasons')) || null
    );
  };

  const parseSource = (origSource, sourceId, sourceName) => {
    const newSource = { id: sourceId, name: sourceName };
    if (origSource && typeof origSource[Symbol.iterator] === 'function') {
      return [...origSource, newSource];
    }
    return [newSource];
  };

  useEffect(() => {
    if (!isLoading) {
      if (id === 'convert') {
        setInitialValues({ ...context.planToConvert });
      } else if (id === 'duplicate') {
        setInitialValues({
          ...dataMappers.apiToFormValues(recommendation, user.name),
          id: '',
          name: '',
          source: parseSource(
            recommendation?.source,
            recommendation?.id,
            recommendation?.title
          )
        });
      } else {
        const values = dataMappers.apiToFormValues(recommendation, user.name);
        setInitialValues(values);
      }
    }
  }, [id, context, isLoading, recommendation, user.name]);

  const setBreadcrumbTitle = () => {
    switch (id) {
      case 'create':
      case 'duplicate':
      case 'convert':
        return 'New';
      default:
        return 'Edit';
    }
  };

  return (
    <>
      <Breadcrumb
        disabled={id === 'create' || id.length > 0}
        hideCropSeasonDropdown={false}
        onCropSeasonSelect={updateSelectedCropSeasons}
      >
        <Breadcrumb.Item
          title="Recommendations"
          value="All Recommendations"
          to={paths.recommendations}
        />
        <Breadcrumb.Item
          title="Recommendations"
          value={`${setBreadcrumbTitle()} Recommendation`}
          isLast
        />
      </Breadcrumb>
      {initialValues && (
        <Formik
          initialValues={initialValues}
          validate={values => {
            const errors = {};

            if (!values?.name) {
              errors.name = 'ID is required';
            }

            return errors;
          }}
          onSubmit={values => {
            if (!values?.name) {
              dispatch({
                type: GLOBAL_SET_ERROR,
                payload: {
                  type: CLIENT_ERROR,
                  message: 'Recommendation ID can not be empty'
                }
              });
              return;
            }

            if (!values?.cropSeasonIds || values.cropSeasonIds.length === 0) {
              dispatch({
                type: GLOBAL_SET_ERROR,
                payload: {
                  type: CLIENT_ERROR,
                  message: 'It is necessary to select a Crop Season'
                }
              });
              return;
            }

            if (!values?.products || values.products.length === 0) {
              dispatch({
                type: GLOBAL_SET_ERROR,
                payload: {
                  type: CLIENT_ERROR,
                  message: 'Cannot create a recommendation without products'
                }
              });
              return;
            }
            // amplitude trigger
            if (id === 'create')
              amplitude.sendEventToAmplitude(
                amplitude.events.epic.Recommendations.createRecommendations
              );
            onSubmit({
              saveRecommendation,
              setRedirect: history.push,
              setToastRenderContents,
              values
            });
          }}
        >
          {({
            errors,
            values,
            handleChange,
            handleBlur,
            submitForm,
            setFieldValue
          }) => {
            return (
              <>
                <TopHeader isSaving={isSaving} onSubmit={submitForm} />
                {isLoading || !initialValues ? (
                  <Spinner />
                ) : (
                  <div
                    data-testid="tabs-content"
                    className="px-6 pt-4 -mx-4 bg-white"
                    style={{ height: 'calc(100% - 96px)' }}
                  >
                    <div className="h-full p-10 mt-3">
                      <Tabs activeTab={selectedTabIndex}>
                        <Tabs.Tab label="Details">
                          <DetailsTab
                            tags={tags}
                            selectedCropSeasons={selectedCropSeasonIds}
                          />
                        </Tabs.Tab>
                        <Tabs.Tab label="Properties">
                          <PropertiesTab
                            propertyLandingPageData={propertyLandingPageData}
                            loading={propertiesLoading}
                            fieldsAndAreasGeoJSONCollection={
                              fieldsAndAreasGeoJSONCollection
                            }
                            selectedCropseasons={selectedCropSeasonIds}
                          />
                        </Tabs.Tab>
                        <Tabs.Tab label="Products">
                          <ProductsTab
                            errors={errors}
                            handleChange={handleChange}
                            handleBlur={handleBlur}
                            setFieldValue={setFieldValue}
                            values={values}
                            selectedCropseasons={selectedCropSeasonIds}
                          />
                        </Tabs.Tab>
                      </Tabs>
                    </div>
                  </div>
                )}
              </>
            );
          }}
        </Formik>
      )}
    </>
  );
}

export default Recommendation;
