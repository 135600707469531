import PropertyLanding from 'screens/Property/PropertiesLanding';
import CropZoneCreate from 'screens/Property/PropertiesLanding/CropZoneCreate';
import PropertyLandingCreate from 'screens/Property/PropertiesLanding/PropertyCreate';
import { PropertiesIcon } from 'routes/icons';
import { ROLES } from 'utilities/access';
import { paths } from 'routes/paths';

const routes = [
  {
    id: 'properties',
    key: 'properties',
    path: paths.properties,
    exact: true,
    secure: true,
    disabled: false,
    component: PropertyLanding,
    icon: PropertiesIcon,
    nav: 'leftNav'
  },
  {
    id: 'propertiesCreate',
    key: 'propertiesCreate',
    path: `${paths.properties}/:action(create|edit)/:fieldId?`,
    exact: true,
    secure: true,
    disabled: false,
    component: PropertyLandingCreate,
    minPermissions: {
      minAllowedRole: ROLES.FULL_CONTROL
    }
  },
  {
    id: 'propertiesCreateCropZone',
    key: 'propertiesCreateCropZone',
    path: `${paths.properties}/:action(create|edit)/:fieldId/cropzone/:cropzoneId?`,
    exact: true,
    secure: true,
    disabled: false,
    component: CropZoneCreate,
    minPermissions: {
      minAllowedRole: ROLES.FULL_CONTROL
    }
  }
];

export default routes;
