import { cloneDeep, orderBy } from 'lodash';

export default (people, field, direction) => {
  if (!direction) return people;

  const _people = people.map(person => cloneDeep(person));
  switch (field) {
    case 'name':
      return orderBy(
        _people,
        [person => person?.name.toLowerCase()],
        direction
      );
    case 'email':
      return orderBy(_people, [person => person?.email], direction);
    case 'license':
      return orderBy(
        _people,
        [person => person?.applicatorLicenseNumber],
        direction
      );
    case 'expirationDate':
      return orderBy(
        _people,
        [person => person?.applicatorLicenseExpirationDateEpoch],
        direction
      );
    case 'permission':
      return orderBy(_people, [person => person?.permissionLevel], direction);
    default:
      return _people;
  }
};
