import React from 'react';
import PropTypes from 'prop-types';
import { Marker } from 'react-mapbox-gl';
import shortId from 'shortid';
import { getAllLabelsFromArray } from '../utilities/getCentroidFromLatLon';

const getArrayOfLabels = geoCollection => {
  const fieldPoints = [];
  const cropZonePoints = [];
  const archivedPoints = {};
  const features = geoCollection?.features;
  features.forEach(item => {
    const isArchived = Boolean(item?.properties?.archived);
    if (item?.properties?.crop === undefined) {
      const fp = getAllLabelsFromArray(
        item?.geometry?.coordinates,
        item?.properties?.name ?? 'Untitled-Field'
      );
      fieldPoints.push({
        coordinates: fp[0],
        name: fp[1],
        isArchived
      });
    } else {
      const cp = getAllLabelsFromArray(
        item?.geometry?.coordinates,
        item?.properties?.name ?? 'Untitled-Cropzone'
      );
      cropZonePoints.push({
        coordinates: cp[0],
        name: cp[1],
        parentFieldId: item?.properties?.$parentFieldId
      });
    }
  });
  const results = {
    fieldPoints,
    cropZonePoints,
    archivedPoints
  };
  return results;
};
const ObjectMarkerLabel = ({ geoCollection, activeObjectType }) => {
  const labelsArr = getArrayOfLabels(geoCollection);
  let markerPoints = [];
  if (activeObjectType === 'field' || activeObjectType === 'cropzone') {
    markerPoints = labelsArr.cropZonePoints;
  } else {
    markerPoints = labelsArr.fieldPoints;
  }

  if (geoCollection?.features?.length > 0) {
    let markerLabels = [];
    if (activeObjectType === 'farm' || activeObjectType === '') {
      markerLabels = markerPoints;
    } else {
      const mapFieldToCropZoneMarker = new Map();
      markerPoints.forEach(item => {
        const keyMap = `${item.parentFieldId}-${item.coordinates[0]}-${item.coordinates[1]}`;
        const mapItem = mapFieldToCropZoneMarker.get(keyMap);
        if (mapItem) {
          mapFieldToCropZoneMarker.set(keyMap, {
            ...mapItem,
            cropZoneNames: [...mapItem.cropZoneNames, item.name]
          });
        } else {
          mapFieldToCropZoneMarker.set(keyMap, {
            cropZoneNames: [item.name],
            ...item
          });
        }
      });
      markerLabels = [...mapFieldToCropZoneMarker.values()];
    }
    return markerLabels.map(marker => {
      return (
        <Marker
          key={shortId.generate()}
          coordinates={marker.coordinates}
          anchor="center"
          style={{
            pointerEvents: 'none'
          }}
        >
          <div
            data-tag="map-marker-children"
            className="flex flex-col items-center justify-center"
          >
            <div
              key={shortId.generate()}
              className="text-center self-center text-white font-bold text-lg"
            >
              {marker?.cropZoneNames?.map(name => (
                <div
                  key={shortId.generate()}
                  data-tag="map-marker-cropzone"
                  className="my-1"
                >
                  {name}
                </div>
              )) ?? <div data-tag="map-marker-field">{marker.name}</div>}
            </div>
            {marker.isArchived && (
              <div
                data-tag="map-marker-field-archived"
                className="text-center self-center w-fit mt-1 text-white rounded-md bg-neutral-80 px-1 py-0.5"
              >
                ARCHIVED
              </div>
            )}
          </div>
        </Marker>
      );
    });
  }
  return null;
};

ObjectMarkerLabel.propTypes = {
  geoCollection: PropTypes.shape({
    features: PropTypes.arrayOf(PropTypes.object)
  }),
  activeObjectType: PropTypes.string
};
ObjectMarkerLabel.defaultProps = {
  geoCollection: [],
  activeObjectType: ''
};

export default ObjectMarkerLabel;
