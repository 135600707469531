import React, {
  useRef,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState
} from 'react';
import { Spinner } from '@agconnections/grow-ui';
import { useHistory } from 'react-router-dom';
import Breadcrumb from 'components/App/AppShell/components/Breadcrumb';
import { paths } from 'routes/paths';
import useDebounce from 'hooks/useDebounce';
import useLoads from 'hooks/useLoads';
import { Context } from 'components/Store';
import useTablePaginationAndSort from 'hooks/useTablePaginationAndSort';
import ActionsTopbar from './components/ActionsTopbar';
import LoadsEmptyState from './components/LoadsEmptyState';
import LoadsTable from './components/LoadsTable';
import LoadsFilterDrawer from './components/LoadsFilterDrawer';
import {
  SET_REMOVE_PRODUCT_FAILURE,
  SET_SHOW_TOAST_REMOVE_PRODUCT,
  SET_TOAST_MESSAGE,
  SET_TOAST_TYPE
} from '../../../reducers/reducer';
import DeleteConfirmation from './components/DeleteConfirmation';
import ToastHandler from './components/ToastHandler';

const Loads = () => {
  const history = useHistory();
  const [{ cropSeasons, isGlobalCropSeasonLoading }] = useContext(Context);

  const requestCounter = useRef(0);
  const [searchValue, setSearchValue] = useState('');
  const debouncedSearchTerm = useDebounce(searchValue, 300);

  const [cropsFilter, setCropsFilter] = useState([]);
  const [openFilters, setOpenFilters] = useState(false);
  const [filters, setFilters] = useState({});

  const {
    reactUiKitTableOnChange,
    reactUiKitTablePaginationProp,
    page,
    pageSize,
    sortBy,
    sortDir,
    setTotalItemsCount
  } = useTablePaginationAndSort({});
  const { getLoads, loading } = useLoads();
  const [tableData, setTableData] = useState([]);

  const [selectedRecordId, setSelectedRecordId] = useState('');
  const [showDeleteModal, setShowDeleteModal] = useState(false);

  const areFiltersApplied = useMemo(() => {
    return (
      !!filters &&
      Object.values(filters).some(item =>
        Array.isArray(item) ? item.length > 0 : !!item
      )
    );
  }, [filters]);

  const pickedSeasons = useMemo(() => {
    if (cropSeasons?.length) {
      return JSON.parse(localStorage.getItem('selectedCropSeasons'));
    }
    return [];
  }, [cropSeasons]);

  const getYieldLoads = () => {
    requestCounter.current += 1;
    const counter = requestCounter.current;
    const sortDirMap = {
      ascend: 'asc',
      descend: 'desc'
    };
    getLoads({
      limit: pageSize,
      page: page - 1, // Note: Ant Design's Table component defaults to 1-based indexing for pagination.
      sortBy,
      sortDir: sortDirMap[sortDir],
      seasonIds: pickedSeasons,
      cropIds: cropsFilter,
      searchQuery: debouncedSearchTerm,
      startDate: filters?.daterange?.[0],
      endDate: filters?.daterange?.[1],
      loadTypes: filters?.loadtype,
      cropZoneIds: filters?.cropZones,
      storageIds: filters?.storageIds,
      saleIds: filters?.saleIds
    }).then(data => {
      if (counter === requestCounter.current) {
        setTotalItemsCount(data?.elements);
        setTableData(data?.data);
      }
    });
  };

  useEffect(() => {
    if (!pickedSeasons?.length) return;

    getYieldLoads();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    pickedSeasons,
    cropsFilter,
    debouncedSearchTerm,
    filters,
    page,
    sortBy,
    sortDir
  ]);

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const searchParam = searchParams.get('search');
    const cropsFiltersParam = searchParams.get('cropsFilters');
    const filtersParam = searchParams.get('filters');

    if (searchParam) {
      setSearchValue(searchParam);
    }

    if (cropsFiltersParam) {
      setCropsFilter(cropsFiltersParam.split(','));
    }

    if (filtersParam) {
      setFilters(JSON.parse(filtersParam));
    }
  }, []);

  useEffect(() => {
    const updateUrlSearchParams = () => {
      const searchParams = new URLSearchParams();

      if (debouncedSearchTerm) {
        searchParams.set('search', debouncedSearchTerm);
      }

      if (cropsFilter.length) {
        searchParams.set('cropsFilters', cropsFilter.join(','));
      }

      if (Object.keys(filters).length) {
        searchParams.set('filters', JSON.stringify(filters));
      }

      history.replace({ search: searchParams.toString() });
    };

    updateUrlSearchParams();
  }, [debouncedSearchTerm, cropsFilter, history, filters]);

  const onSearchChange = searchInputValue => {
    setSearchValue(searchInputValue);
  };

  const cropsFilterValueChange = filterValue => {
    setCropsFilter(filterValue);
  };

  const toggleFilterDrawer = () => {
    setOpenFilters(prev => !prev);
  };

  const onApplyFilters = newFilters => {
    setFilters(newFilters);
  };

  const onClearFilters = () => {
    setFilters({});
  };

  const addYieldLoads = () => {
    // if multiple crop seasons are selected,
    // select just one that have crops and redirect to create yield loads
    const selectedCropSeasons = JSON.parse(
      localStorage.getItem('selectedCropSeasons')
    );
    if (selectedCropSeasons.length > 1) {
      const selectedSeason = cropSeasons?.find(
        season =>
          selectedCropSeasons.includes(season?.id) && season?.crops?.length > 0
      );
      if (selectedSeason) {
        localStorage.setItem(
          'selectedCropSeasons',
          JSON.stringify([selectedSeason?.id])
        );
      }
    }

    history.push(`${paths.yieldV2}/create`);
  };

  const onRecordView = useCallback(
    id => {
      history.push(`${paths.yieldV2}/loads/${id}/view`);
    },
    [history]
  );

  const onRecordEdit = id => {
    history.push(`${paths.yieldV2}/loads/${id}/edit`);
  };
  const onRecordDelete = id => {
    setSelectedRecordId(id);
    setShowDeleteModal(true);
  };

  const [showToast, setShowToast] = useState(
    history.location?.toast?.showToast
  );
  const [toast, setToast] = useState(history.location?.toast);

  const [, dispatch] = useContext(Context);

  const onCloseHandler = () => {
    dispatch({
      type: SET_TOAST_MESSAGE,
      payload: 'null'
    });
    dispatch({
      type: SET_TOAST_TYPE,
      payload: 'null'
    });
    dispatch({
      type: SET_SHOW_TOAST_REMOVE_PRODUCT,
      payload: false
    });
    dispatch({
      type: SET_REMOVE_PRODUCT_FAILURE,
      payload: false
    });
    setShowToast(false);
  };

  return (
    <>
      <DeleteConfirmation
        id={selectedRecordId}
        open={showDeleteModal}
        onCancel={setShowDeleteModal}
        onResult={newToast => {
          setToast(newToast);
          setShowToast(true);
          if (newToast?.type === 'success') {
            getYieldLoads();
          }
        }}
      />
      <Breadcrumb hideCropSeasonDropdown={false}>
        <Breadcrumb.Item title="Yield" value="Loads" isLast />
      </Breadcrumb>
      <div data-testid="loads-container " className="h-full max-h-full px-4">
        <ActionsTopbar
          searchValue={searchValue}
          onSearchChange={onSearchChange}
          cropsFilterValue={cropsFilter}
          cropsFilterValueChange={cropsFilterValueChange}
          onFilterButtonClick={toggleFilterDrawer}
          areFiltersApplied={areFiltersApplied}
          addYieldLoads={addYieldLoads}
        />

        {loading || isGlobalCropSeasonLoading ? (
          <div
            data-testid="loading-container"
            className="bg-white rounded-lg shadow-sm-card border-1 border-neutral-30 h-90/100 flex flex-col items-center justify-center"
          >
            <div>
              <Spinner />
            </div>
            {Boolean(debouncedSearchTerm) && (
              <p className="font-semibold text-base leading-6 tracking-tight text-center mt-4">
                Searching Loads...
              </p>
            )}
            {areFiltersApplied && (
              <p className="font-semibold text-base leading-6 tracking-tight text-center mt-4">
                Filtering Loads...
              </p>
            )}
          </div>
        ) : (
          <>
            {tableData?.length === 0 ? (
              <div
                data-testid="empty-state-container"
                className="py-20 bg-white rounded-lg shadow-sm-card border-1 border-neutral-30"
              >
                <LoadsEmptyState
                  addYieldLoads={addYieldLoads}
                  areFiltersApplied={areFiltersApplied}
                  onAdjustFilters={toggleFilterDrawer}
                  onClearFilters={onClearFilters}
                  isSearchApplied={Boolean(debouncedSearchTerm)}
                />
              </div>
            ) : (
              <LoadsTable
                tableData={tableData}
                onRecordView={onRecordView}
                onRecordDelete={onRecordDelete}
                onRecordEdit={onRecordEdit}
                pagination={reactUiKitTablePaginationProp}
                onChange={reactUiKitTableOnChange}
                sortBy={sortBy}
                sortDir={sortDir}
              />
            )}
          </>
        )}
      </div>

      <LoadsFilterDrawer
        appliedFilters={filters}
        visible={openFilters}
        onClose={toggleFilterDrawer}
        onApplyFilters={onApplyFilters}
        onClearFilters={onClearFilters}
      />

      <ToastHandler
        onCloseHandler={onCloseHandler}
        showToast={showToast}
        toastType={toast?.type}
        title={toast?.title}
        description={toast?.message}
      />
    </>
  );
};

export default Loads;
