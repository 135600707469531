import React, { useEffect, useState, useContext, useCallback } from 'react';
import { Redirect } from 'react-router-dom';
import { Spinner, Button } from '@agconnections/grow-ui';
import { Context } from 'components/Store';
import { isEmpty } from 'lodash';
import LandingSearch from 'components/LandingSearch';
import AddRoundedIcon from '@material-ui/icons/AddRounded';
import useLandingPageData from 'hooks/useLandingPageData';
import useDebounce from 'hooks/useDebounce';
import Breadcrumb from 'components/App/AppShell/components/Breadcrumb';
import { paths } from 'routes/paths';
import CompanyList from './components/CompanyList';
import CompanyToolbar from './components/CompanyToolbar';
import findCompany from './helpers/dataHelpers';

const Companies = () => {
  const [companyPageNumber, setCompanyPageNumber] = useState(0);
  const {
    view,
    setView,
    sort,
    setSort,
    isSearch,
    loaded,
    setReload,
    items: companies,
    totalItems: totalCompanies
  } = useLandingPageData(
    'worker/companies',
    ['company.name', 'company.legalName'],
    'company.name',
    companyPageNumber
  );
  const [{ loggedInUserOrgPermission, loadingOrg }, dispatch] = useContext(
    Context
  );
  const [orgReloading, setOrgReloading] = useState(false);
  const [redirect, setRedirect] = useState(false);
  const [filteredCompany, setFilteredCompany] = useState([]);
  const [searchText, setSearchText] = useState(null);
  const debouncedSearchTerm = useDebounce(searchText, 300);

  useEffect(() => {
    if (debouncedSearchTerm) {
      setFilteredCompany(findCompany(companies, debouncedSearchTerm));
    } else {
      setFilteredCompany(findCompany(companies, null));
    }
    dispatch({
      type: 'SET_TOOLBAR',
      payload: (
        <CompanyToolbar
          view={view}
          changeView={event => setView(event)}
          sort={sort}
          changeSort={event => setSort(event)}
        />
      )
    });
    return () => {
      dispatch({ type: 'SET_TOOLBAR', payload: null });
    };
  }, [view, setView, sort, setSort, dispatch, debouncedSearchTerm, companies]);

  useEffect(() => {
    if (isEmpty(loggedInUserOrgPermission)) {
      setOrgReloading(false);
    } else {
      setOrgReloading(true);
    }
  }, [loggedInUserOrgPermission]);

  const getAddButtonLayout = () => {
    if (companies?.length < 1) return 'Create Company';
    return 'Add Company';
  };

  const handleOrgChange = () => {
    setCompanyPageNumber(0);
    setReload(true);
    // setOrgReloading(true);
  };

  const showSpinner = () => {
    return !loadingOrg && loaded && orgReloading;
  };

  const setPermission = useCallback(() => {
    if (loggedInUserOrgPermission?.role !== 'View Only') {
      return true;
    }
    return false;
  }, [loggedInUserOrgPermission?.role]);

  const handlePageChange = page => {
    setCompanyPageNumber(page);
    setReload(true);
  };

  return (
    <>
      <div className="w-full h-full" data-testid="companies">
        <Breadcrumb
          onOrganizationSelect={handleOrgChange}
          hideCropSeasonDropdown
        >
          <Breadcrumb.Item title="Company" value="All Companies" isLast />
        </Breadcrumb>
        {redirect && (
          <Redirect
            push
            to={{
              pathname: `${paths.companies}/company`
            }}
          />
        )}
        {showSpinner() ? (
          <>
            <div className="flex justify-between flex-1 mb-3">
              <LandingSearch
                name="search_companies"
                id="companies-search-input"
                selectedItems={[{}]}
                showLabels={false}
                showDateRange={false}
                showCropSeason={false}
                onChange={event => {
                  setSearchText(event.target.value);
                }}
                placeholder="Search Companies"
              />
              <div className="h-8">
                {setPermission() ? (
                  <Button
                    id="add-company-button"
                    data-testid="add_company"
                    type="primary"
                    icon={<AddRoundedIcon />}
                    onClick={event => {
                      event.stopPropagation();
                      setRedirect(true);
                    }}
                  >
                    {getAddButtonLayout()}
                  </Button>
                ) : (
                  <></>
                )}
              </div>
            </div>

            <CompanyList
              view={view}
              companies={filteredCompany}
              isSearch={isSearch}
              memberRole={loggedInUserOrgPermission?.role}
              totalCompanies={totalCompanies}
              companyPageNumber={companyPageNumber}
              handlePageChange={handlePageChange}
            />
          </>
        ) : (
          <Spinner />
        )}
      </div>
    </>
  );
};
export default Companies;
