import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import ReactMapboxGl, { GeoJSONLayer } from 'react-mapbox-gl';
import { bbox } from '@turf/turf';
import { Spinner } from '@agconnections/grow-ui';
import { calculateCentroid } from 'screens/Property/helpers/mapApiHelpers';
import cropzoneToGeoJSONFeature from '../../helpers/cropzoneToGeoJSONFeature';

const AccessToken = process.env.REACT_APP_MAPBOX_ACCESS_TOKEN;

const MapBox = ReactMapboxGl({
  accessToken: AccessToken
});

export const dataTestId = {
  MAP: 'cropzones-map-preview',
  LOADING: 'cropzones-map-preview-loading'
};

const CropZonesMapView = ({ cropzones, className, fieldColor, isLoading }) => {
  const { centroId, boundingBox, featureCollection } = useMemo(() => {
    if (isLoading) {
      return { centroId: [0, 0], boundingBox: null };
    }
    const geoJsonFeatures = cropzones.map(cropzoneToGeoJSONFeature);
    const isValid = geoJsonFeatures.some(item => !!item?.geometry?.coordinates);
    const calculated = calculateCentroid({ features: geoJsonFeatures });

    const _featureCollection = {
      type: 'FeatureCollection',
      features: isValid ? geoJsonFeatures : []
    };

    if (!isValid) {
      return {
        centroId: [0, 0],
        boundingBox: null,
        featureCollection: _featureCollection
      };
    }

    const calculatedBoundingBox = bbox(_featureCollection);
    return {
      centroId: calculated?.geometry?.coordinates || [0, 0],
      boundingBox: calculatedBoundingBox,
      featureCollection: _featureCollection
    };
  }, [cropzones, isLoading]);

  if (isLoading) {
    return (
      <div
        data-testid={dataTestId.LOADING}
        className={`${className} flex justify-center items-center h-full bg-gray-200`}
      >
        <Spinner />
      </div>
    );
  }

  return (
    <MapBox
      // eslint-disable-next-line react/style-prop-object
      style="mapbox://styles/mapbox/satellite-streets-v11"
      center={centroId}
      fitBounds={boundingBox}
      fitBoundsOptions={{ padding: 4 }}
      className={`${className} w-full h-full`}
      logoPosition="none"
      data-testid={dataTestId.MAP}
    >
      <GeoJSONLayer
        data={featureCollection}
        symbolLayout={{
          'text-field': ['get', 'name'],
          'text-size': 10,
          'text-letter-spacing': 0.05,
          'text-allow-overlap': true,
          'text-offset': [0, 0.5],
          'symbol-placement': 'point',
          'text-anchor': 'top'
        }}
        symbolPaint={{
          'text-color': '#fff',
          'text-halo-color': '#000',
          'text-halo-width': 1
        }}
        fillLayout={{
          visibility: 'visible'
        }}
        fillPaint={{
          'fill-color': fieldColor,
          'fill-opacity': 0.4
        }}
        linePaint={{
          'line-color': fieldColor,
          'line-width': 2
        }}
      />
    </MapBox>
  );
};

const cropzonePropTypes = PropTypes.shape({
  id: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  area: PropTypes.number.isRequired,
  geometry: PropTypes.shape({
    type: PropTypes.string.isRequired,
    coordinates: PropTypes.arrayOf(
      PropTypes.arrayOf(PropTypes.arrayOf(PropTypes.number))
    ).isRequired
  }).isRequired
});

CropZonesMapView.defaultProps = {
  className: '',
  fieldColor: '#31B4F2',
  isLoading: false,
  cropzones: []
};

CropZonesMapView.propTypes = {
  cropzones: PropTypes.arrayOf(cropzonePropTypes),
  className: PropTypes.string,
  fieldColor: PropTypes.string,
  isLoading: PropTypes.bool
};

export default CropZonesMapView;
