import { cropZone as cropZoneApi } from 'utilities/api';
import catchCancel from 'helpers/catchCancel';
import { filterCropSeason } from 'screens/CropSeasons/helpers/dataHelpers';

export const getCropzonesCropArea = cropzones => {
  const allPromises = cropzones.map(zone => {
    const { promise } = cropZoneApi.fetch(zone);
    return promise;
  });
  return Promise.all(allPromises)
    .catch(catchCancel)
    .then(results =>
      results.flatMap(({ data }) => {
        return {
          cropId: data.cropId,
          reportedArea: data.reportedArea,
          boundaryArea: data.boundaryArea
        };
      })
    );
};

export const traverseFields = (selectedCropSeason, cropZones = []) => {
  if (selectedCropSeason) {
    let fieldCount = 0;
    cropZones.forEach(zone => {
      fieldCount += filterCropSeason(selectedCropSeason, zone.cropSeasons);
    });
    return fieldCount;
  }
  if (
    (selectedCropSeason === null || selectedCropSeason === undefined) &&
    cropZones.length > 0
  ) {
    return 1;
  }
  return 0;
};

export const traverseFarms = (selectedCropSeason, fields = []) => {
  if (selectedCropSeason) {
    let farmCount = 0;
    fields.forEach(field => {
      farmCount += traverseFields(selectedCropSeason, field.cropzones);
    });
    return farmCount;
  }
  if (selectedCropSeason === null || selectedCropSeason === undefined) {
    return 1;
  }
  return 0;
};

export const selectedCropzones = (mappedData, selectedCropzoneData) => {
  const newSelected = [...selectedCropzoneData];
  if (mappedData?.length > 0) {
    mappedData.forEach(property => {
      if (property?.children?.length > 0 && property?.disabled === false) {
        property.children.forEach(field => {
          if (field?.children?.length > 0 && field?.disabled === false) {
            field.children.forEach(cz => {
              if (cz?.disabled === false) {
                newSelected.push(cz.value);
              }
            });
          }
        });
      }
    });
  }
  return newSelected;
};

export const getFarmIds = (fft, checkedIds = []) => {
  const flattenTree = array => {
    const flatArray = array.flatMap(item => {
      if (item.children?.length > 0) {
        return flattenTree(item.children);
      }
      return item;
    });
    return flatArray;
  };

  const allFarmIds = flattenTree(fft)
    .filter(cz => checkedIds.includes(cz.value))
    .map(({ farmId }) => farmId);
  return [...new Set(allFarmIds)];
};
