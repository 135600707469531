import _ from 'lodash';

const replaceEmptyStringsWithFill = inputObject => {
  // Clone the input object to avoid mutating the original
  const resultObject = _.cloneDeep(inputObject);

  // eslint-disable-next-line guard-for-in, no-restricted-syntax
  for (const key in resultObject) {
    const value = resultObject[key];
    const isValidString = typeof value === 'string' && value.trim() !== '';
    const isValidNumber =
      typeof value === 'number' && String(value).trim() !== '';

    if (isValidString || isValidNumber) {
      resultObject[key] = 'fill';
    } else {
      delete resultObject[key];
    }
  }

  return resultObject;
};

export default replaceEmptyStringsWithFill;
