import React, { useContext, useEffect } from 'react';
import PropTypes from 'prop-types';
import { YieldContext } from 'screens/Yield/context/YieldProvider';
import { useFormikContext } from 'formik';
import YieldPropertiesTab from './YieldPropertiesTab';
import YieldInformationTab from './YieldInformationTab';

const StepSelector = ({
  loading,
  zoom,
  setZoom,
  propertyLandingPageData,
  fieldsAndAreasGeoJSONCollection,
  setGeoJSONCollectionFeatures,
  fieldsLoading,
  filterFeatureCollectionByParentId,
  selectedCropSeasons
}) => {
  const {
    state: { step, cropZones }
  } = useContext(YieldContext);

  const { setFieldValue } = useFormikContext();

  const mapProperties = () => {
    const mappedProperties = cropZones.reduce((prev, curr) => {
      const farm = propertyLandingPageData.properties.find(
        property => property.id === curr.properties.propertyId
      );

      const field = farm.fields.find(
        f => f.id === curr.properties.$parentFieldId
      );

      const cropZone = field.cropzones.find(
        cropzone => cropzone.id === curr.properties.id
      );

      const farmIndex = prev.findIndex(joinedFarm => joinedFarm.id === farm.id);

      if (farmIndex >= 0) {
        const fieldIndex = prev[farmIndex].fields.findIndex(
          joinedField => joinedField.id === field.id
        );
        if (fieldIndex >= 0) {
          prev[farmIndex].fields[fieldIndex].cropzones.push(cropZone);
        } else {
          prev[farmIndex].fields.push({
            id: field.id,
            name: field.name,
            cropzones: [cropZone]
          });
        }
      } else {
        prev.push({
          id: farm.id,
          name: farm.name,
          fields: [
            {
              id: field.id,
              name: field.name,
              cropzones: [cropZone]
            }
          ]
        });
      }

      return prev;
    }, []);

    setFieldValue('cropZones', mappedProperties);
  };

  useEffect(() => {
    if (step === 2) {
      mapProperties();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [step]);
  return (
    <>
      {step === 1 ? (
        <YieldPropertiesTab
          loading={loading}
          zoom={zoom}
          setZoom={setZoom}
          propertyLandingPageData={propertyLandingPageData}
          fieldsAndAreasGeoJSONCollection={fieldsAndAreasGeoJSONCollection}
          setGeoJSONCollectionFeatures={setGeoJSONCollectionFeatures}
          fieldsLoading={fieldsLoading}
          filterFeatureCollectionByParentId={filterFeatureCollectionByParentId}
          selectedCropSeasons={selectedCropSeasons}
        />
      ) : (
        <YieldInformationTab />
      )}
    </>
  );
};

StepSelector.propTypes = {
  loading: PropTypes.bool.isRequired,
  zoom: PropTypes.arrayOf(PropTypes.number).isRequired,
  setZoom: PropTypes.func.isRequired,
  propertyLandingPageData: PropTypes.shape().isRequired,
  fieldsAndAreasGeoJSONCollection: PropTypes.shape().isRequired,
  setGeoJSONCollectionFeatures: PropTypes.func.isRequired,
  fieldsLoading: PropTypes.bool.isRequired,
  filterFeatureCollectionByParentId: PropTypes.func.isRequired,
  selectedCropSeasons: PropTypes.arrayOf(PropTypes.string).isRequired
};

export default StepSelector;
