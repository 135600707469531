import React from 'react';
import { Form, Input } from '@agconnections/grow-ui';
import GrowUIFormField from 'components/Forms/GrowUIFormField';

const AddressLineOneField = () => {
  return (
    <Form.Field label="Address Line 1">
      <GrowUIFormField control={Input} id="line1" name="address.line1" />
    </Form.Field>
  );
};

export default AddressLineOneField;
