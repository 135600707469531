export const PRODUCTS_IN_USE = 'PRODUCTS_IN_USE';
export const SEARCH_TEXT = 'SEARCH_TEXT';
export const SEARCHED_PRODUCTS = 'SEARCHED_PRODUCTS';
export const LOADING_PRODUCT_SEARCH = 'LOADING_PRODUCT_SEARCH';
export const IS_LOADING_PRODUCT_IN_USE = 'IS_LOADING_PRODUCT_IN_USE';
export const TABLE_SEARCH_TEXT = 'TABLE_SEARCH_TEXT';

const reducer = (state, action) => {
  switch (action.type) {
    case PRODUCTS_IN_USE:
      return {
        ...state,
        productsInUse: action.payload
      };
    case SEARCH_TEXT:
      return {
        ...state,
        searchText: action.payload
      };
    case SEARCHED_PRODUCTS:
      return {
        ...state,
        searchedProducts: action.payload
      };
    case LOADING_PRODUCT_SEARCH:
      return {
        ...state,
        loadingProductSearch: action.payload
      };
    case IS_LOADING_PRODUCT_IN_USE:
      return {
        ...state,
        isLoadingProductInUse: action.payload
      };
    case TABLE_SEARCH_TEXT:
      return {
        ...state,
        tableSearchText: action.payload
      };
    default:
      return state;
  }
};

export default reducer;
