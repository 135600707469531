import React from 'react';
import PropTypes from 'prop-types';
import { Button } from '@agconnections/grow-ui';
import clipboardIcon from 'assets/clipboardIcon.png';
import documentIcon from 'assets/documentIcon.png';
import AddRoundedIcon from '@material-ui/icons/AddRounded';
import { ReactComponent as CropwiseConnectLogo } from 'assets/cropwise_connect_logo.svg';
import { useFlags } from 'launchdarkly-react-client-sdk';

function EmptyState({
  buttonText,
  onClick,
  subtitle,
  setPermission,
  imageToUse,
  children,
  text,
  importButtonName,
  onImportClick
}) {
  const { feTasksModuleConnectsImport } = useFlags();

  const srcImage = () => {
    if (imageToUse === 'documentation') {
      return documentIcon;
    }

    return clipboardIcon;
  };

  return (
    <div
      data-testid="property-overlay-modal"
      className="mt-4 p-10 bg-white border rounded-none sm:rounded border-neutral"
    >
      <div className="flex flex-col justify-center">
        <div className="self-center" data-testid="empty-state-image">
          <img width="356" height="318" src={srcImage()} alt={imageToUse} />
        </div>
        <div className="self-center mb-3" data-testid="empty-state-subtitle">
          <p>{subtitle}</p>
          <p>{text}</p>
        </div>
        <div className="flex self-center" data-testid="empty-state-button">
          {importButtonName && feTasksModuleConnectsImport && (
            <div className="mr-2">
              <Button
                icon={<CropwiseConnectLogo className="mr-1" />}
                iconLeft
                onClick={onImportClick}
                id="overlay-create-property-btn"
                type="outline"
                style={{
                  height: '2.5rem',
                  paddingRight: '1rem',
                  paddingLeft: '1.13rem'
                }}
              >
                {importButtonName}
              </Button>
            </div>
          )}
          {setPermission() ? (
            <Button
              icon={<AddRoundedIcon style={{ height: '0.8em' }} />}
              iconLeft
              onClick={onClick}
              id="overlay-create-property-btn"
              type="primary"
              style={{ height: '2.5rem', paddingRight: '1rem' }}
            >
              {buttonText}
            </Button>
          ) : (
            <></>
          )}
        </div>
        {children}
      </div>
    </div>
  );
}

EmptyState.defaultProps = {
  onClick: () => {},
  setPermission: () => true,
  children: <></>,
  imageToUse: '',
  text: '',
  importButtonName: null,
  onImportClick: () => {}
};

EmptyState.propTypes = {
  buttonText: PropTypes.string.isRequired,
  onClick: PropTypes.func,
  subtitle: PropTypes.string.isRequired,
  setPermission: PropTypes.func,
  imageToUse: PropTypes.string,
  children: PropTypes.node,
  text: PropTypes.string,
  importButtonName: PropTypes.string,
  onImportClick: PropTypes.func
};

export default EmptyState;
