export const formatDateToIsoString = date => {
  if (typeof date === 'number') {
    return new Date(date)?.toISOString().split('T')?.[0];
  }
  return date?.toISOString().split('T')?.[0];
};

const formatIsoDateString = isoDateString =>
  new Date(`${isoDateString?.split('T')?.[0]}:00:00`);

const sortDates = selectedCS => {
  if (!selectedCS?.length) {
    return {};
  }
  const listOfDates = selectedCS?.map(cropseason => ({
    startDate: formatIsoDateString(cropseason?.startDate),
    endDate: formatIsoDateString(cropseason?.endDate)
  }));

  const listOfStartDates = listOfDates.sort(
    (date1, date2) => date1.startDate.getTime() - date2.startDate.getTime()
  );
  const listOfEndDates = [...listOfDates].sort(
    (date1, date2) => date2.endDate.getTime() - date1.endDate.getTime()
  );

  const [{ startDate }] = listOfStartDates;
  const [{ endDate }] = listOfEndDates;

  return {
    startDate,
    endDate
  };
};

export default sortDates;
