import getUUIDFromString from 'helpers/getUUIDFromString';

import {
  createRouteHandlers,
  createNestedRouteHandlers
} from './helpers/apiResourceBuilder';

export default server => {
  createRouteHandlers(server, 'area', { orgRoutes: false });
  createNestedRouteHandlers(server, 'area', 'field');

  server.get('areas/:id', (schema, request) => {
    const { id } = request.params;

    let areaById = schema.areas.find(id);
    if (!areaById) {
      areaById = schema.areas.first();
    }
    return areaById;
  });

  server.put(
    'organizations/:organizationId/fields/:fieldId/areas/:areaId',
    (schema, request) => {
      const attrs = JSON.parse(request.requestBody);
      const { areaId } = request.params;

      // Mirage expects relational data to be in format *Ids (e.g. areaIds)
      if (attrs.areas) {
        attrs.areaIds = [];

        for (let i = 0; i < attrs.areas.length; i += 1) {
          attrs.areaIds.push(attrs.areas[i].$id);
        }

        delete attrs.areas;
      }

      let updatedArea = schema.areas.find(areaId);
      if (!updatedArea) {
        updatedArea = schema.areas.first();
      }

      if (attrs.boundedArea && attrs.boundedArea.charAt(0) === '{') {
        const updatedShapeGeoJSON = JSON.parse(attrs.boundedArea);
        const shapeId = getUUIDFromString(updatedArea.boundedArea);

        if (shapeId) {
          const updatedShape = schema.shapes.find(shapeId);
          updatedShape.attrs.body = updatedShapeGeoJSON;
          updatedShape.save();
          attrs.boundedArea = `/shapes/${shapeId}`;
        }
      }

      return updatedArea.update(attrs);
    }
  );
};
