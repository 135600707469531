import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { Context } from 'components/Store';
import GrowUIFormField from 'components/Forms/GrowUIFormField';
import { Select } from '@agconnections/grow-ui';

const SeedSource = ({ companiesList }) => {
  const [{ isEnableInfoTabEdit }] = useContext(Context);
  return (
    <GrowUIFormField
      control={Select}
      id="seedSource"
      placeholder="Select seed source"
      name="seedSource"
      label="Seed Source"
      items={companiesList}
      disabled={!isEnableInfoTabEdit}
    />
  );
};

SeedSource.propTypes = {
  companiesList: PropTypes.arrayOf(PropTypes.object).isRequired
};

export default SeedSource;
