import React from 'react';
import PropTypes from 'prop-types';
import { Table } from '@agconnections/grow-ui';
import { formatDateToShortMonthDayYear } from 'utilities/dateHelpers';
import ExpandableLongText from 'components/ExpandableLongText';
import RemoveCircleOutlinedIcon from 'components/Icons/RemoveCircleOutlinedIcon';

const AgVendInvoicesReviewTableRow = ({ invoice, handleRemove }) => {
  return (
    <Table.Row>
      <Table.Cell>
        <div className="text-left" data-testid="invoice-number-cell">
          {invoice.invoiceNumber}
        </div>
      </Table.Cell>
      <Table.Cell>
        <div className="text-left" data-testid="invoice-date-cell">
          {formatDateToShortMonthDayYear(invoice.invoiceDate)}
        </div>
      </Table.Cell>
      <Table.Cell>
        <div className="text-left">
          <ExpandableLongText
            text={invoice.products?.map(p => p.productName).join(`, `)}
            maxLength={120}
          />
        </div>
      </Table.Cell>
      <Table.Cell>
        <button
          className="flex items-center py-1 px-2 text-neutral-600 hover:bg-neutral-100"
          type="button"
          onClick={() => {
            handleRemove(invoice.invoiceNumber);
          }}
          data-testid="invoice-button-remove"
        >
          <RemoveCircleOutlinedIcon />
          <span className="ml-1">Remove</span>
        </button>
      </Table.Cell>
    </Table.Row>
  );
};

AgVendInvoicesReviewTableRow.propTypes = {
  invoice: PropTypes.shape({
    id: PropTypes.string,
    invoiceNumber: PropTypes.string,
    invoiceDate: PropTypes.string,
    retailerName: PropTypes.string,
    products: PropTypes.arrayOf(
      PropTypes.shape({
        productName: PropTypes.string
      })
    ),
    importedAt: PropTypes.string
  }).isRequired,
  handleRemove: PropTypes.func.isRequired
};

export default AgVendInvoicesReviewTableRow;
