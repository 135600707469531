import React, { useRef, useEffect } from 'react';
import PropTypes from 'prop-types';

import { renderFeaturesToSvg } from '../../../helpers/svgHelpers';

const ICON_COLOR = '#C2C7D0';

const DefaultIconPaths = () => (
  <>
    <path
      d="M6.47059 0C2.89698 0 0 2.89698 0 6.47059V18.92L7.93419 0H6.47059Z"
      fill={ICON_COLOR}
    />
    <path
      d="M9.12645 0L0 25.4206V37.5294C0 40.6324 2.1842 43.2252 5.099 43.8543L14.7045 0H9.12645Z"
      fill={ICON_COLOR}
    />
    <path
      d="M15.8963 0L8.58659 44H20.3672L21.4034 0H15.8963Z"
      fill={ICON_COLOR}
    />
    <path
      d="M22.5957 0L23.646 44H35.4551L28.0176 0H22.5957Z"
      fill={ICON_COLOR}
    />
    <path
      d="M29.196 0L38.6971 43.8949C41.6984 43.3479 43.9773 40.7304 43.9998 37.5765V25.8606L34.6747 0H29.196Z"
      fill={ICON_COLOR}
    />
    <path
      d="M35.8672 0L44 19.4733V6.47059C44 2.89698 41.103 0 37.5294 0H35.8672Z"
      fill={ICON_COLOR}
    />
  </>
);

const FieldIcon = ({ id, features, color }) => {
  const svgRef = useRef();

  useEffect(() => {
    if (!svgRef.current || features.length === 0) {
      return;
    }

    renderFeaturesToSvg(features, svgRef.current, {
      color,
      extent: [
        [4, 4],
        [40, 40]
      ]
    });
  }, [features, color]);

  return (
    <svg
      data-testid="field-shapes-icon"
      id={id}
      ref={el => (svgRef.current = el)}
      width="44"
      height="44"
      viewBox="0 0 44 44"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      {/* this group contains the paths drawn by d3 to match the features */}
      <g className={features.length ? '' : 'hidden'} />
      {/* this renders the default paths only when there are no features */}
      {features.length === 0 && <DefaultIconPaths />}
    </svg>
  );
};

FieldIcon.propTypes = {
  id: PropTypes.string.isRequired,
  features: PropTypes.arrayOf(PropTypes.object),
  color: PropTypes.string
};

FieldIcon.defaultProps = {
  features: [],
  color: ICON_COLOR
};

export default FieldIcon;
