import { UPDATE_TYPES } from '../../../../../helpers/constants';

const useDrawHandlers = (
  handleDrawCreate,
  handleDrawUpdate,
  processNewFeature,
  setDeleteModalOpen
) => {
  const onDrawCreate = changedFeatures => {
    processNewFeature(changedFeatures[0], newFeature => {
      handleDrawCreate([newFeature]);
    });
  };

  const onDrawUpdate = changedFeatures => {
    const shapeId = changedFeatures[0].id;
    handleDrawUpdate(shapeId, changedFeatures);
  };

  const onDrawChanged = (changedFeatures, updateMethod) => {
    if (updateMethod === UPDATE_TYPES.DELETE) {
      setDeleteModalOpen(true);
    } else if (updateMethod === UPDATE_TYPES.CREATE) {
      onDrawCreate(changedFeatures);
    } else if (updateMethod === UPDATE_TYPES.UPDATE) {
      onDrawUpdate(changedFeatures);
    }
  };

  return {
    onDrawChanged
  };
};

export default useDrawHandlers;
