/* eslint-disable spaced-comment */
import React, { useContext, useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { useAuth } from 'utilities/base-auth';
import useOrganization from 'hooks/useOrganization';
import { useHistory, useLocation } from 'react-router-dom';

import { Spinner } from '@agconnections/grow-ui';

import Navigation from 'components/Navigation';
import ErrorHandlerWithRouter from 'components/ErrorHandler';
import setOrgAndPermission from 'helpers/setOrgAndPermission';
import { Context } from 'components/Store';
import { IS_FULL_SCREEN_NEEDED } from 'reducers/reducer';
import { useFlags } from 'launchdarkly-react-client-sdk';
import getPreviousValue from 'helpers/findPreviousValue';
import { paths } from 'routes/paths';
import { useToast } from 'components/ToastContainer';
import useOrganizations from 'hooks/useOrganizations';
import OrgGlobalLoader from 'components/OrgGlobalLoader';
import TopNavigation from './components/TopNavigation';

const returnData = (value1, value2) => {
  if (value1) return value1;
  if (value2) return [value2];
  return [];
};

const FULL_SCREEN_PAGES = [
  'property-landing',
  'reports',
  'crop-zone-matcher',
  'products',
  'storage-locations',
  'sale-locations',
  'loads',
  'yieldV2/create',
  'yieldV2/commodity-summary'
];

const EMBEDDED_ROUTES = [
  `${paths.yieldV2}/storage-locations`,
  `${paths.yieldV2}/sale-locations`,
  `${paths.yieldV2}/loads`,
  `${paths.yieldV2}/commodity-summary`
];

const ROUTES_TO_EXCLUDE = ['/app/welcome'];
const AppShell = ({ routes, children }) => {
  const [
    { organization, isFullScreenNeeded, loadingOrg: LoadingOrgAndPermission },
    dispatch
  ] = useContext(Context);
  const { user } = useAuth();
  const toast = useToast();
  const { getDefaultOrg, loadedDefaultOrg, defaultOrg } = useOrganizations();
  const {
    setOrganization,
    organizationPermissionError,
    getOrgByIdV1,
    loadedOrgByIdV1,
    orgByIdV1
  } = useOrganization();
  const [stateLoaded, setStateLoaded] = useState(false);
  const history = useHistory();
  const location = useLocation();
  const { releaseFe50Orgs } = useFlags();
  const redirectToWelcome = !user;
  const APILoaded = loadedDefaultOrg || loadedOrgByIdV1;
  const orgIds = useRef(new Set());
  const validateLoop = useRef('');

  const pushToWelcome = () => {
    history.replace(paths.welcome);
    setStateLoaded(true);
    orgIds.current = new Set();
    validateLoop.current = '';
  };

  useEffect(() => {
    if (!releaseFe50Orgs) {
      getDefaultOrg(0, 50);
      return;
    }
    const selectedOrganizationId = localStorage.getItem(
      'selectedOrganizationId'
    );

    if (selectedOrganizationId) {
      getOrgByIdV1(selectedOrganizationId);
      return;
    }
    getDefaultOrg(0, 1);
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const isOrgFailingAPI = organizationPermissionError;
    if (!releaseFe50Orgs && isOrgFailingAPI) {
      toast.error('Unable to load organization data', {
        supportButton: true,
        content:
          "An error prevented us from loading your list of organization's. Please try again."
      });
      pushToWelcome();
    }
    if (!releaseFe50Orgs || !isOrgFailingAPI) return;
    if (orgIds.current.size <= 1) {
      toast.error('Unable to load organization data', {
        supportButton: true,
        content:
          "An error prevented us from loading your list of organization's. Please try again."
      });
      pushToWelcome();
      return;
    }
    const previousSelectedOrg = getPreviousValue(orgIds.current);
    if (validateLoop.current === previousSelectedOrg) {
      toast.error('Unable to load organization data', {
        supportButton: true,
        content:
          "An error prevented us from loading any of your organization's. Please try logging in again."
      });
      pushToWelcome();
      return;
    }
    toast.error('Unable to load organization data', {
      supportButton: true,
      content: `An error prevented us from loading your organization ${organization.name}. Please try again.`
    });
    validateLoop.current = previousSelectedOrg;
    getOrgByIdV1(previousSelectedOrg);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [organizationPermissionError]);

  useEffect(() => {
    if (!APILoaded) {
      return;
    }
    if (redirectToWelcome && location.pathname !== paths.welcome) {
      pushToWelcome();
      return;
    }
    if (!orgByIdV1 && !defaultOrg) {
      pushToWelcome();
      return;
    }

    setOrgAndPermission(
      user,
      // getOrganizationData(organizationV2, organizationsV2),
      returnData(defaultOrg, orgByIdV1),
      dispatch,
      setStateLoaded
    );

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [APILoaded]);

  useEffect(() => {
    const path = location.pathname.split('/');
    const payload = FULL_SCREEN_PAGES.some(page => {
      const isCompositePath = page.includes('/');
      if (isCompositePath) return location.pathname.includes(page);
      return path.includes(page);
    });
    dispatch({
      type: IS_FULL_SCREEN_NEEDED,
      payload
    });
  }, [location.pathname, dispatch]);

  useEffect(() => {
    if (organization.id !== process.env.REACT_APP_DEVTEST_ORGANIZATIONID) {
      orgIds.current.add(organization.id);
      setOrganization(organization);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [organization]);

  return (
    <div className="flex h-screen bg-[#F8F9FA]">
      <Navigation routes={routes} />
      <div className="flex flex-col flex-1 w-0">
        {stateLoaded ? (
          <>
            <TopNavigation />
            <main
              className="relative overflow-auto flex flex-1 focus:outline-none bg-app-background"
              style={{ height: 'calc(100% - 64px)' }}
            >
              <div
                className={`w-full flex flex-col flex-1 ${!isFullScreenNeeded &&
                  'p-4'} mx-auto`}
              >
                <ErrorHandlerWithRouter>{children}</ErrorHandlerWithRouter>
                {releaseFe50Orgs && (
                  <OrgGlobalLoader
                    route={location.pathname}
                    shouldRenderInRoutes={[
                      ...Object.values(paths),
                      ...EMBEDDED_ROUTES
                    ].filter(route => !ROUTES_TO_EXCLUDE.includes(route))}
                    isLoading={LoadingOrgAndPermission}
                  />
                )}
              </div>
            </main>
          </>
        ) : (
          <Spinner />
        )}
      </div>
    </div>
  );
};

AppShell.defaultProps = {
  children: null
};

AppShell.propTypes = {
  routes: PropTypes.arrayOf(PropTypes.object).isRequired,
  children: PropTypes.element
};

export default AppShell;
