import React, { useState, useContext } from 'react';
import { Spinner } from '@agconnections/grow-ui';
import PropTypes from 'prop-types';
import useTagsData from 'hooks/useTagsData';
import { Context } from 'components/Store';

import DetailsSection from 'screens/Recommendations/Recommendation/RecommendationView/components/DetailsSection';
import useRecommendationData from 'screens/Recommendations/Recommendation/RecommendationView/hooks/useRecommendationData';
import ApplicatorSection from 'screens/Recommendations/Recommendation/RecommendationView/components/ApplicatorSection';
import NotesSection from 'screens/Recommendations/Recommendation/RecommendationView/components/NotesSection';
import PropertiesSection from './components/PropertiesSection';
import ProductsSection from './components/ProductsSection';

const RecommendationView = ({ id }) => {
  // eslint-disable-next-line no-unused-vars
  const [{ loggedInUserOrgPermission }, dispatch] = useContext(Context);
  const { loading, recommendation } = useRecommendationData(id);
  // eslint-disable-next-line no-unused-vars
  const { tags, loading: isTagsLoading, getTags } = useTagsData();

  // eslint-disable-next-line no-unused-vars
  const [tagsLoading, setTagsLoading] = useState(false);

  const appIsLoading = loading || isTagsLoading || tagsLoading;

  return (
    <>
      {appIsLoading ? (
        <Spinner />
      ) : (
        <>
          <div style={{ minWidth: 'min-content' }}>
            <div className="border rounded">
              <div className="m-4" data-testid="source-document-recommendation">
                <DetailsSection recommendation={recommendation} />
                <hr className="my-6" />
                <ApplicatorSection
                  applicators={recommendation?.applicators ?? []}
                />
                <hr className="my-6" />
                <PropertiesSection recommendation={recommendation} />
                <ProductsSection recommendation={recommendation} />
                <NotesSection notes={recommendation?.notes} />
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
};

RecommendationView.propTypes = {
  id: PropTypes.string.isRequired
};

export default RecommendationView;
