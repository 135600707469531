import { StyleSheet, Text, View } from '@react-pdf/renderer';
import PropTypes from 'prop-types';
import React from 'react';

const styles = StyleSheet.create({
  title: {
    fontFamily: 'Noto Sans',
    fontWeight: 'normal',
    fontSize: 9,
    marginBottom: 6,
    color: '#363948'
  },
  details: {
    fontFamily: 'Noto Sans',
    fontWeight: 'medium',
    fontSize: 9,
    color: '#14151C'
  }
});

const VendorDetails = ({ vendorName, invoiceDate, invoiceDueDate }) => {
  return (
    <>
      <View>
        <Text style={styles.title}>Vendor</Text>
        <Text style={styles.details}>{vendorName}</Text>
      </View>
      <View>
        <Text style={styles.title}>Date</Text>
        <Text style={styles.details}>{invoiceDate}</Text>
      </View>
      <View>
        <Text style={styles.title}>Due Date</Text>
        <Text style={styles.details}>{invoiceDueDate}</Text>
      </View>
    </>
  );
};

VendorDetails.propTypes = {
  vendorName: PropTypes.string,
  invoiceDate: PropTypes.string,
  invoiceDueDate: PropTypes.string
};

VendorDetails.defaultProps = {
  vendorName: '',
  invoiceDate: '',
  invoiceDueDate: ''
};

export default VendorDetails;
