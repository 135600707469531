import React from 'react';
import PropTypes from 'prop-types';
import FileInput from 'components/FileInput';

const FieldImporterUploader = ({ onSelect }) => {
  const handleOnSelect = (files, event) => {
    event.preventDefault();
    onSelect(files);
  };

  return (
    <div className="h-64 mb-24">
      <FileInput
        title="Click or drag file to this area to upload"
        subtitle="Upload a .zip that contains .shp, .shx, and .dbf"
        multiple
        fileTypes={['.zip']}
        onSelect={handleOnSelect}
      />
    </div>
  );
};

FieldImporterUploader.propTypes = {
  onSelect: PropTypes.func.isRequired
};

export default FieldImporterUploader;
