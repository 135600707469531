import React, { useEffect, useState } from 'react';
import { Spinner, Button, Tabs } from '@agconnections/grow-ui';
import PropTypes from 'prop-types';
import useFieldData from 'screens/Property/hooks/useFieldData';
import usePeopleData from 'hooks/usePeopleData';
import catchCancel from 'helpers/catchCancel';
import NoDocumentFound from 'assets/no_documents_found.svg';
import FieldIcon from 'assets/field_icon.svg';

import { farm as farmApi } from 'utilities/api';
import EmptyTableState from 'components/EmptyTableState';
import statesItems from 'components/AddressFields/StateSelect/data';
import PersonIcon from 'assets/person.svg';
import PropertyRow from '../PropertyRow';
import PropertyCardHeader from '../PropertyCardHeader';

const PropertyFieldDetail = ({ id, setEnableEdit }) => {
  const { field, loading } = useFieldData(id);
  const { people, loading: peopleLoading } = usePeopleData();
  const [farm, setFarm] = useState(null);
  const [farmLoading, setFarmLoading] = useState(true);

  useEffect(() => {
    if (field) {
      const { promise } = farmApi.fetch(field.propertyId);
      promise
        .then(({ data }) => setFarm(data))
        .catch(catchCancel)
        .finally(() => setFarmLoading(false));
    }
  }, [field, setFarm, id]);

  const isEverythingLoaded = () => {
    return !loading && !peopleLoading && !farmLoading;
  };

  const getOwner = ownerId => people.find(x => x.id === ownerId)?.name;
  const getState = state => statesItems.find(x => x.key === state)?.value;

  if (!field) return null;
  return (
    <div>
      <PropertyCardHeader
        title="Field Information"
        description="Specific property boundary relevant content not contained within
              any crop season"
        icon={FieldIcon}
        setEnableEdit={setEnableEdit}
      />
      {isEverythingLoaded() ? (
        <Tabs>
          <Tabs.Tab label="Location">
            <PropertyRow
              label="Center Latitude Longitude Point"
              value={field.coordinates}
            />
            <PropertyRow label="State" value={getState(field.state)} />
            <PropertyRow label="County" value={field.county} />
            <PropertyRow
              label="Land Owner"
              value={getOwner(field.landOwnerName)}
              icon={PersonIcon}
            />
          </Tabs.Tab>
          <Tabs.Tab label="FSA">
            <PropertyRow
              label="Farm Number"
              value={field.farmNumber || farm.farmNumber}
            />
            <PropertyRow label="Field Number" value={field.fieldNumber} />
            <PropertyRow label="Tract Number" value={field.tractNumber} />
            <PropertyRow label="FSA Area" value={field.fsaArea} />
            <PropertyRow label="CLU ID" value={field.cluID} />
            <PropertyRow label="Rent Type" value={field.rentType} />
            <PropertyRow
              label="Land Owner Percentage"
              value={field.ownerPercentage}
            />
            <PropertyRow
              label="Tenant Percentage"
              value={field.tenantPercentage}
            />
          </Tabs.Tab>
          <Tabs.Tab label="Regulatory Permit">
            <PropertyRow
              label="Permit ID"
              value={field.permitId || farm.permitId}
            />
            <PropertyRow label="Site ID" value={field.siteId} />
          </Tabs.Tab>
          <Tabs.Tab label="Legal">
            <PropertyRow
              label="Township"
              value={[field.township, field.townshipDirection]}
            />
            <PropertyRow
              label="Range"
              value={[field.range, field.rangeDirection]}
            />
            <PropertyRow label="Section" value={field.section} />
            <PropertyRow label="Meridian" value={field.meridian} />
            <PropertyRow label="Tax ID" value={field.propertyTaxId} />
            <PropertyRow label="Parcel Number" value={field.parcelNumber} />
            <PropertyRow
              label="Short Description"
              value={field.shortDescription}
            />
            <PropertyRow label="Legal Description" value={field.legalName} />
          </Tabs.Tab>
          <Tabs.Tab label="Land">
            <PropertyRow label="Slope Percentage" value={field.slope} />
            <PropertyRow label="Slope Length" value={field.slopeLength} />
            <PropertyRow label="Soil Type" value={field.soil} />
            <PropertyRow label="Hydrology Group" value={field.hydrologyGroup} />
          </Tabs.Tab>
          <Tabs.Tab label="Water">
            <PropertyRow label="Irrigated" value={field.irrigated} />
            <PropertyRow label="Water Source" value={field.waterSource} />
            <PropertyRow label="Water Origin" value={field.waterOrigin} />
            <PropertyRow
              label="Irrigation System"
              value={field.irrigationSystem}
            />
            <PropertyRow label="Pump Pressure" value={field.pumpPressure} />
            <PropertyRow label="Pump Lift" value={field.pumpLift} />
            <PropertyRow
              label="Pump Energy Source"
              value={field.energySource}
            />
            <PropertyRow
              label="Use Polyacrylamide (PAM)"
              value={field.usePolyacrylamide}
            />
          </Tabs.Tab>
          <Tabs.Tab label="Notes">
            {field?.notes ? (
              <p className="text-sm font-normal">{field.notes}</p>
            ) : (
              <div className="mb-10">
                <EmptyTableState
                  title="No Notes Found"
                  overlayIcon={NoDocumentFound}
                  message="Edit your field information to add notes."
                  button={
                    <Button
                      type="primary"
                      size="lg"
                      onClick={() => setEnableEdit(prev => !prev)}
                    >
                      Edit Field Information
                    </Button>
                  }
                />
              </div>
            )}
          </Tabs.Tab>
        </Tabs>
      ) : (
        <Spinner />
      )}
    </div>
  );
};

PropertyFieldDetail.propTypes = {
  id: PropTypes.string.isRequired,
  setEnableEdit: PropTypes.func.isRequired
};

export default PropertyFieldDetail;
