/* istanbul ignore file */
import { Factory } from 'miragejs';
import faker from 'faker';

const Person = {
  applicatorLicenseNumber() {
    return '8299103475';
  },
  applicatorLicenseExpirationDate() {
    return faker.date.future();
  },
  name() {
    return `${faker.name.firstName()} ${faker.name.lastName()}`;
  },
  company() {
    return `${faker.name.firstName()} company`;
  }
};

export default Factory.extend(Person);
