import React from 'react';

import CreateIcon from '@material-ui/icons/Create';

import { Button } from '@agconnections/grow-ui';

const EditButton = props => {
  return (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <Button {...props} type="primary">
      <div className="flex items-center">
        <CreateIcon
          style={{
            fontSize: 16
          }}
        />
        <span className="ml-2">Edit</span>
      </div>
    </Button>
  );
};

export default EditButton;
