import React from 'react';
import { useParams } from 'react-router-dom';
import YieldProvider from 'screens/Yield/context/YieldProvider';
import CreateYield from '../YieldActions/CreateYield';
import EditYield from '../YieldActions/EditYield';

const YieldParentProvider = () => {
  const { id } = useParams();
  return <YieldProvider>{id ? <EditYield /> : <CreateYield />}</YieldProvider>;
};

export default YieldParentProvider;
