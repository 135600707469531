import React, { useContext } from 'react';
import { Context } from 'components/Store';
import GrowUIFormField from 'components/Forms/GrowUIFormField';
import { Select } from '@agconnections/grow-ui';
import { soilTypes } from '../DataSets';

const SoilType = () => {
  const [{ isEnableInfoTabEdit }] = useContext(Context);
  return (
    <GrowUIFormField
      control={Select}
      type="text"
      id="soil"
      name="soil"
      items={soilTypes}
      label="Soil Type"
      placeholder="Select soil type"
      disabled={!isEnableInfoTabEdit}
    />
  );
};

export default SoilType;
