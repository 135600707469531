import React from 'react';
import PropTypes from 'prop-types';
import { SimplotContext } from 'screens/Integrations/SimplotIntegration/context/Provider';
import SubmissionFooter from 'screens/Integrations/components/SubmissionFooter';

const SimplotSubmissionFooter = ({
  nextDisabled,
  onNext,
  setModalSubmissionState,
  isLastStep
}) => {
  return (
    <SubmissionFooter
      nextDisabled={nextDisabled}
      onNext={onNext}
      setModalSubmissionState={setModalSubmissionState}
      isLastStep={isLastStep}
      context={SimplotContext}
      integrationType="Simplot"
    />
  );
};

SimplotSubmissionFooter.propTypes = {
  onNext: PropTypes.func,
  setModalSubmissionState: PropTypes.func,
  isLastStep: PropTypes.bool,
  nextDisabled: PropTypes.bool
};

SimplotSubmissionFooter.defaultProps = {
  onNext: () => {},
  setModalSubmissionState: () => {},
  isLastStep: false,
  nextDisabled: false
};

export default SimplotSubmissionFooter;
