import React from 'react';
import PropTypes from 'prop-types';
import FormatListBulletedRoundedIcon from '@material-ui/icons/FormatListBulletedRounded';

import { RadioButtonGroup } from '@agconnections/grow-ui';

import TileIcon from 'components/Icons/components/TileIcon';

const LandingListViewToggle = ({ view, changeView, testId }) => {
  return (
    <div data-testid={testId}>
      <RadioButtonGroup selected={view} onChange={changeView}>
        <RadioButtonGroup.RadioButton name="tileView" value="tile">
          <div>
            <TileIcon />
          </div>
        </RadioButtonGroup.RadioButton>
        <RadioButtonGroup.RadioButton name="listView" value="list">
          <div>
            <FormatListBulletedRoundedIcon />
          </div>
        </RadioButtonGroup.RadioButton>
      </RadioButtonGroup>
    </div>
  );
};

LandingListViewToggle.propTypes = {
  view: PropTypes.string.isRequired,
  changeView: PropTypes.func.isRequired,
  testId: PropTypes.string
};

LandingListViewToggle.defaultProps = {
  testId: 'landing-list-view-toggle'
};

export default LandingListViewToggle;
