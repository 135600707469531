import { useState } from 'react';

import { genProxyResource } from 'utilities/api';

// TODO: deprecate this hook ASAP
const useOrganizationApi = resourceName => {
  const [api] = useState(
    genProxyResource(resourceName, { urlVersion: '/v1/base' })
  );

  return api;
};

export default useOrganizationApi;
