import { useContext, useEffect, useState, useCallback } from 'react';
import axios from 'axios';
import useOrganizationApi from 'hooks/useOrganizationApi';
import catchCancel from 'helpers/catchCancel';
import { parseServerError } from 'helpers/errorHelpers';
import { Context } from 'components/Store';
import { getAccessToken } from 'utilities/base-auth';
import { CROPWISE_PROXY_URL } from 'utilities/apiConstants';

const useCompanies = () => {
  const companiesAPI = useOrganizationApi('companies');
  const [{ organization }, dispatch] = useContext(Context);

  const [companies, setCompanies] = useState([]);

  const [loading, setLoading] = useState(true);

  const [isCreatingCompany, setIsCreatingCompany] = useState(null);

  const loadCompanies = useCallback(() => {
    const { promise } = companiesAPI.fetch();
    promise
      .then(({ data }) => {
        if (data) setCompanies(data);
      })
      .catch(catchCancel)
      .catch(parseServerError(dispatch))
      .catch(err => {
        parseServerError(dispatch)(err);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [companiesAPI, dispatch]);

  useEffect(() => loadCompanies(), [loadCompanies]);

  const createCompany = async values => {
    setIsCreatingCompany(true);
    const args = {
      headers: {
        'Content-Type': 'application/json',
        'cwf-context': JSON.stringify({
          organization_id: organization.id
        }),
        Authorization: `Bearer ${getAccessToken()}`
      }
    };
    const promise = axios.post(
      `${CROPWISE_PROXY_URL}/v1/base/company`,
      values,
      args
    );
    return promise
      .then(({ data }) => {
        setCompanies(companies.concat([data]));
        return data;
      })
      .catch(catchCancel)
      .catch(parseServerError(dispatch))
      .finally(() => {
        setIsCreatingCompany(false);
      });
  };

  return {
    companies,
    loading,
    isCreatingCompany,
    createCompany,
    loadCompanies
  };
};

export default useCompanies;
