import React from 'react';
import PropTypes from 'prop-types';
import { Image, StyleSheet, Text, View } from '@react-pdf/renderer';
import { Applicators } from 'components/PDF/components';
import { displayText } from 'components/PDF/helpers/pdfTools';

const styles = StyleSheet.create({
  taskDetailsContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between'
  },
  taskDetails: {
    width: '50%'
  },
  imageContainer: {
    width: 270,
    height: 198
  },
  row: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginBottom: 20
  },
  fieldContainer: {
    width: '50%'
  },
  fieldText: {
    fontFamily: 'Noto Sans',
    fontSize: 9
  },
  fieldTitle: {
    color: '#363948',
    lineHeight: 2
  },
  fieldValue: {
    color: '#14151C',
    fontWeight: 'medium'
  }
});

const RecDetails = ({ rec }) => {
  return (
    <View style={styles.taskDetailsContainer}>
      <View style={styles.taskDetails}>
        <View>
          <View style={styles.row}>
            <View style={styles.fieldContainer}>
              <Text style={[styles.fieldText, styles.fieldTitle]}>
                Proposed Date
              </Text>
              <Text style={[styles.fieldText, styles.fieldValue]}>
                {displayText(rec.proposedDate)}
              </Text>
            </View>
            <View style={styles.fieldContainer}>
              <Text style={[styles.fieldText, styles.fieldTitle]}>
                Authorized By
              </Text>
              <Text style={[styles.fieldText, styles.fieldValue]}>
                {displayText(rec.authorisedByName)}
              </Text>
            </View>
          </View>
          <View style={styles.row}>
            <View style={styles.fieldContainer}>
              <Text style={[styles.fieldText, styles.fieldTitle]}>
                Authorized On
              </Text>
              <Text style={[styles.fieldText, styles.fieldValue]}>
                {displayText(rec.authorisedOn)}
              </Text>
            </View>
            <View style={styles.fieldContainer}>
              <Text style={[styles.fieldText, styles.fieldTitle]}>
                Expiration Date
              </Text>
              <Text style={[styles.fieldText, styles.fieldValue]}>
                {displayText(rec.expirationDate)}
              </Text>
            </View>
          </View>
          <View style={styles.row}>
            <View style={styles.fieldContainer}>
              <Text style={[styles.fieldText, styles.fieldTitle]}>
                Timing Event
              </Text>
              <Text style={[styles.fieldText, styles.fieldValue]}>
                {displayText(rec.timingEvent)}
              </Text>
            </View>
          </View>
        </View>

        <Applicators applicators={rec.applicators} />
      </View>
      {rec.mapImage ? (
        <View style={styles.imageContainer}>
          <Image src={rec.mapImage} />
        </View>
      ) : null}
    </View>
  );
};

RecDetails.propTypes = {
  rec: PropTypes.shape().isRequired
};

export default RecDetails;
