export default function getFilteredCropsWithinCropSeasons(
  cropSeasons,
  selectedCropSeasons
) {
  if (!cropSeasons?.length) return [];
  const seenIds = [];
  const filteredCrops = cropSeasons
    ?.filter(cropSeason => selectedCropSeasons.includes(cropSeason.id))
    .map(cropSeason => cropSeason.crops)
    .flat()
    .filter(crop => {
      if (crop?.id && !seenIds.includes(crop.id)) {
        seenIds.push(crop.id);
        return true;
      }
      return false;
    })
    .sort((a, b) => a.name.localeCompare(b.name));
  return filteredCrops;
}
