/* eslint-disable global-require */
export default {
  Alfalfa: require('assets/AllCropSVGs/alfalfa.svg'),
  Almond: require('assets/AllCropSVGs/almonds.svg'),
  Amaranth: require('assets/AllCropSVGs/fallow.svg'),
  AnnualRyegrass: require('assets/AllCropSVGs/fallow.svg'),
  Apple: require('assets/AllCropSVGs/apples.svg'),
  Apricot: require('assets/AllCropSVGs/apricots.svg'),
  Aquatic: require('assets/AllCropSVGs/fallow.svg'),
  Artichoke: require('assets/AllCropSVGs/artichokeGlobe.svg'),
  Arugula: require('assets/AllCropSVGs/fallow.svg'),
  Asparagus: require('assets/AllCropSVGs/asparagus.svg'),
  Avocado: require('assets/AllCropSVGs/fallow.svg'),
  BahiaGrass: require('assets/AllCropSVGs/fallow.svg'),
  Barley: require('assets/AllCropSVGs/barley.svg'),
  Basil: require('assets/AllCropSVGs/fallow.svg'),
  Bean: require('assets/AllCropSVGs/broadBeans.svg'),
  Beets: require('assets/AllCropSVGs/beetroot.svg'),
  Bentgrass: require('assets/AllCropSVGs/fallow.svg'),
  Bermudagrass: require('assets/AllCropSVGs/fallow.svg'),
  Bittermelon: require('assets/AllCropSVGs/fallow.svg'),
  Blackberry: require('assets/AllCropSVGs/blackberries.svg'),
  BluePanicGrass: require('assets/AllCropSVGs/fallow.svg'),
  Blueberry: require('assets/AllCropSVGs/berries.svg'),
  Bluegrass: require('assets/AllCropSVGs/fallow.svg'),
  Bluestem: require('assets/AllCropSVGs/fallow.svg'),
  BristleGrass: require('assets/AllCropSVGs/fallow.svg'),
  Broccoli: require('assets/AllCropSVGs/broccoli.svg'),
  BroccoliRaab: require('assets/AllCropSVGs/broccoli.svg'),
  BrusselsSprout: require('assets/AllCropSVGs/brusselSprouts.svg'),
  Buckwheat: require('assets/AllCropSVGs/fallow.svg'),
  Buffelgrass: require('assets/AllCropSVGs/fallow.svg'),
  BundleflowerGrass: require('assets/AllCropSVGs/fallow.svg'),
  Cabbage: require('assets/AllCropSVGs/cabbage.svg'),
  CactusPear: require('assets/AllCropSVGs/fallow.svg'),
  Canola: require('assets/AllCropSVGs/canola.svg'),
  Cantaloupe: require('assets/AllCropSVGs/fallow.svg'),
  Carambola: require('assets/AllCropSVGs/fallow.svg'),
  Carrots: require('assets/AllCropSVGs/carrots.svg'),
  Cauliflower: require('assets/AllCropSVGs/cauliflower.svg'),
  Celery: require('assets/AllCropSVGs/celery.svg'),
  Cherry: require('assets/AllCropSVGs/cherries.svg'),
  Chervil: require('assets/AllCropSVGs/fallow.svg'),
  Chestnut: require('assets/AllCropSVGs/fallow.svg'),
  ChewingFescue: require('assets/AllCropSVGs/fallow.svg'),
  Chickpea: require('assets/AllCropSVGs/chickpeas.svg'),
  Chicory: require('assets/AllCropSVGs/fallow.svg'),
  Chives: require('assets/AllCropSVGs/chives.svg'),
  Chrysanthemum: require('assets/AllCropSVGs/fallow.svg'),
  Cilantro: require('assets/AllCropSVGs/fallow.svg'),
  Clover: require('assets/AllCropSVGs/clover.svg'),
  Coffee: require('assets/AllCropSVGs/coffee.svg'),
  CollardGreens: require('assets/AllCropSVGs/fallow.svg'),
  Coriander: require('assets/AllCropSVGs/coriander.svg'),
  Corn: require('assets/AllCropSVGs/corn.svg'),
  Cotton: require('assets/AllCropSVGs/cotton.svg'),
  CoverCrop: require('assets/AllCropSVGs/fallow.svg'),
  Crabgrass: require('assets/AllCropSVGs/fallow.svg'),
  Cranberry: require('assets/AllCropSVGs/berries.svg'),
  Crawfish: require('assets/AllCropSVGs/fallow.svg'),
  Crenshaw: require('assets/AllCropSVGs/fallow.svg'),
  CREP: require('assets/AllCropSVGs/fallow.svg'),
  CRP: require('assets/AllCropSVGs/fallow.svg'),
  CSP: require('assets/AllCropSVGs/fallow.svg'),
  Cucumber: require('assets/AllCropSVGs/cucumber.svg'),
  Currants: require('assets/AllCropSVGs/fallow.svg'),
  Daffodil: require('assets/AllCropSVGs/fallow.svg'),
  Daikon: require('assets/AllCropSVGs/fallow.svg'),
  Dandelion: require('assets/AllCropSVGs/fallow.svg'),
  Dill: require('assets/AllCropSVGs/dillLeaf.svg'),
  Echinacea: require('assets/AllCropSVGs/fallow.svg'),
  Edamame: require('assets/AllCropSVGs/fallow.svg'),
  Eggplant: require('assets/AllCropSVGs/fallow.svg'),
  Endive: require('assets/AllCropSVGs/endive.svg'),
  Escarole: require('assets/AllCropSVGs/fallow.svg'),
  Fallow: require('assets/AllCropSVGs/fallow.svg'),
  Fennel: require('assets/AllCropSVGs/fallow.svg'),
  Fenugreek: require('assets/AllCropSVGs/fallow.svg'),
  Festulolium: require('assets/AllCropSVGs/fallow.svg'),
  Filbert: require('assets/AllCropSVGs/fallow.svg'),
  FineFescue: require('assets/AllCropSVGs/fallow.svg'),
  Flax: require('assets/AllCropSVGs/fallow.svg'),
  Flowers: require('assets/AllCropSVGs/fallow.svg'),
  ForageSorghum: require('assets/AllCropSVGs/forageCorn.svg'),
  Garlic: require('assets/AllCropSVGs/garlic.svg'),
  Ginseng: require('assets/AllCropSVGs/fallow.svg'),
  Gourd: require('assets/AllCropSVGs/fallow.svg'),
  GrainSorghum: require('assets/AllCropSVGs/fallow.svg'),
  Grapefruit: require('assets/AllCropSVGs/grapes.svg'),
  Grapes: require('assets/AllCropSVGs/grapes.svg'),
  GrassSeedMixed: require('assets/AllCropSVGs/grassesSeed.svg'),
  GreenManure: require('assets/AllCropSVGs/fallow.svg'),
  Guava: require('assets/AllCropSVGs/fallow.svg'),
  Guayule: require('assets/AllCropSVGs/fallow.svg'),
  HardSquash: require('assets/AllCropSVGs/fallow.svg'),
  Hay: require('assets/AllCropSVGs/fallow.svg'),
  Hazelnut: require('assets/AllCropSVGs/hazelnuts.svg'),
  Hemp: require('assets/AllCropSVGs/hemp.svg'),
  Honeydew: require('assets/AllCropSVGs/fallow.svg'),
  Hops: require('assets/AllCropSVGs/hops.svg'),
  Horseradish: require('assets/AllCropSVGs/fallow.svg'),
  Kale: require('assets/AllCropSVGs/fallow.svg'),
  Kenaf: require('assets/AllCropSVGs/fallow.svg'),
  Kiwi: require('assets/AllCropSVGs/fallow.svg'),
  KleinGrass: require('assets/AllCropSVGs/fallow.svg'),
  Kohlrabi: require('assets/AllCropSVGs/fallow.svg'),
  Lavender: require('assets/AllCropSVGs/fallow.svg'),
  Leasedland: require('assets/AllCropSVGs/fallow.svg'),
  Leek: require('assets/AllCropSVGs/leeks.svg'),
  Lemon: require('assets/AllCropSVGs/fallow.svg'),
  Lemongrass: require('assets/AllCropSVGs/fallow.svg'),
  Lentil: require('assets/AllCropSVGs/fallow.svg'),
  Lespedeza: require('assets/AllCropSVGs/fallow.svg'),
  Lettuce: require('assets/AllCropSVGs/lettuces.svg'),
  LillyBulb: require('assets/AllCropSVGs/fallow.svg'),
  LimaBean: require('assets/AllCropSVGs/fallow.svg'),
  Lime: require('assets/AllCropSVGs/fallow.svg'),
  Lovegrass: require('assets/AllCropSVGs/fallow.svg'),
  Lychee: require('assets/AllCropSVGs/fallow.svg'),
  MacadamiaNut: require('assets/AllCropSVGs/fallow.svg'),
  Mandarin: require('assets/AllCropSVGs/fallow.svg'),
  Mango: require('assets/AllCropSVGs/fallow.svg'),
  Marjoram: require('assets/AllCropSVGs/fallow.svg'),
  MeadowFescue: require('assets/AllCropSVGs/fallow.svg'),
  MeadowFoam: require('assets/AllCropSVGs/fallow.svg'),
  MedicGrass: require('assets/AllCropSVGs/fallow.svg'),
  Metaleaf: require('assets/AllCropSVGs/fallow.svg'),
  Millet: require('assets/AllCropSVGs/millet.svg'),
  Mint: require('assets/AllCropSVGs/fallow.svg'),
  Miscanthus: require('assets/AllCropSVGs/fallow.svg'),
  Mizuna: require('assets/AllCropSVGs/fallow.svg'),
  Moqua: require('assets/AllCropSVGs/fallow.svg'),
  Mushrooms: require('assets/AllCropSVGs/mushrooms.svg'),
  Mustard: require('assets/AllCropSVGs/mustardRed.svg'),
  MustardGreens: require('assets/AllCropSVGs/mustardRed.svg'),
  MWD: require('assets/AllCropSVGs/fallow.svg'),
  Nectarine: require('assets/AllCropSVGs/fallow.svg'),
  NonAg: require('assets/AllCropSVGs/fallow.svg'),
  NonCrop: require('assets/AllCropSVGs/fallow.svg'),
  NurseryStock: require('assets/AllCropSVGs/fallow.svg'),
  Oats: require('assets/AllCropSVGs/oats.svg'),
  OilPalm: require('assets/AllCropSVGs/oilseedRape.svg'),
  Okra: require('assets/AllCropSVGs/fallow.svg'),
  Olive: require('assets/AllCropSVGs/fallow.svg'),
  Onions: require('assets/AllCropSVGs/onions.svg'),
  Opo: require('assets/AllCropSVGs/fallow.svg'),
  Orange: require('assets/AllCropSVGs/fallow.svg'),
  Orchardgrass: require('assets/AllCropSVGs/fallow.svg'),
  Oregano: require('assets/AllCropSVGs/fallow.svg'),
  Papaya: require('assets/AllCropSVGs/fallow.svg'),
  Parsley: require('assets/AllCropSVGs/fallow.svg'),
  Parsnip: require('assets/AllCropSVGs/parsnips.svg'),
  PassionFruit: require('assets/AllCropSVGs/fallow.svg'),
  Pasture: require('assets/AllCropSVGs/fallow.svg'),
  Peach: require('assets/AllCropSVGs/fallow.svg'),
  Peanut: require('assets/AllCropSVGs/peanuts.svg'),
  Pear: require('assets/AllCropSVGs/pears.svg'),
  Peas: require('assets/AllCropSVGs/peas.svg'),
  Pecan: require('assets/AllCropSVGs/pecans.svg'),
  Pepper: require('assets/AllCropSVGs/peppers.svg'),
  PerennialRyegrass: require('assets/AllCropSVGs/fallow.svg'),
  Persimmon: require('assets/AllCropSVGs/fallow.svg'),
  Pistachio: require('assets/AllCropSVGs/pistachios.svg'),
  Plantain: require('assets/AllCropSVGs/fallow.svg'),
  Plum: require('assets/AllCropSVGs/plums.svg'),
  Pluot: require('assets/AllCropSVGs/fallow.svg'),
  Pomegranate: require('assets/AllCropSVGs/pomeFruits.svg'),
  Pomelo: require('assets/AllCropSVGs/fallow.svg'),
  Pond: require('assets/AllCropSVGs/fallow.svg'),
  Pongamia: require('assets/AllCropSVGs/fallow.svg'),
  PostHarvest: require('assets/AllCropSVGs/fallow.svg'),
  Potato: require('assets/AllCropSVGs/potato.svg'),
  PreventedPlanting: require('assets/AllCropSVGs/fallow.svg'),
  Pumpkin: require('assets/AllCropSVGs/pumpkins.svg'),
  Quinoa: require('assets/AllCropSVGs/fallow.svg'),
  Radish: require('assets/AllCropSVGs/radicchio.svg'),
  Rape: require('assets/AllCropSVGs/fallow.svg'),
  Raspberry: require('assets/AllCropSVGs/raspberries.svg'),
  Rhubarb: require('assets/AllCropSVGs/rhubarb.svg'),
  Rice: require('assets/AllCropSVGs/rice.svg'),
  Rosemary: require('assets/AllCropSVGs/fallow.svg'),
  Rutabaga: require('assets/AllCropSVGs/fallow.svg'),
  Rye: require('assets/AllCropSVGs/rye.svg'),
  Safflower: require('assets/AllCropSVGs/fallow.svg'),
  Sage: require('assets/AllCropSVGs/fallow.svg'),
  Sainfoin: require('assets/AllCropSVGs/fallow.svg'),
  Savory: require('assets/AllCropSVGs/fallow.svg'),
  SeedProduction: require('assets/AllCropSVGs/fallow.svg'),
  Sesame: require('assets/AllCropSVGs/fallow.svg'),
  SetAside: require('assets/AllCropSVGs/fallow.svg'),
  Sinqua: require('assets/AllCropSVGs/fallow.svg'),
  Sod: require('assets/AllCropSVGs/fallow.svg'),
  Sorrel: require('assets/AllCropSVGs/fallow.svg'),
  Soybeans: require('assets/AllCropSVGs/soybeans.svg'),
  Spelt: require('assets/AllCropSVGs/fallow.svg'),
  Spinach: require('assets/AllCropSVGs/spinach.svg'),
  SpriteMelon: require('assets/AllCropSVGs/fallow.svg'),
  Squash: require('assets/AllCropSVGs/squash.svg'),
  Stevia: require('assets/AllCropSVGs/fallow.svg'),
  Strawberry: require('assets/AllCropSVGs/strawberries.svg'),
  SudanGrass: require('assets/AllCropSVGs/fallow.svg'),
  SugarBeets: require('assets/AllCropSVGs/sugarBeet.svg'),
  Sugarcane: require('assets/AllCropSVGs/sugarcane.svg'),
  Sunflower: require('assets/AllCropSVGs/sunflower.svg'),
  SunnHemp: require('assets/AllCropSVGs/hemp.svg'),
  SweetCorn: require('assets/AllCropSVGs/corn.svg'),
  SweetPotato: require('assets/AllCropSVGs/sweetPotato.svg'),
  SweetSorghum: require('assets/AllCropSVGs/fallow.svg'),
  SwissChard: require('assets/AllCropSVGs/fallow.svg'),
  Switchgrass: require('assets/AllCropSVGs/fallow.svg'),
  TallFescue: require('assets/AllCropSVGs/fallow.svg'),
  Tangelo: require('assets/AllCropSVGs/fallow.svg'),
  Tangerine: require('assets/AllCropSVGs/fallow.svg'),
  Tarragon: require('assets/AllCropSVGs/fallow.svg'),
  Teff: require('assets/AllCropSVGs/fallow.svg'),
  Thyme: require('assets/AllCropSVGs/fallow.svg'),
  Timothy: require('assets/AllCropSVGs/fallow.svg'),
  Tobacco: require('assets/AllCropSVGs/tobacco.svg'),
  Tomatillos: require('assets/AllCropSVGs/fallow.svg'),
  Tomato: require('assets/AllCropSVGs/tomatoes.svg'),
  Trees: require('assets/AllCropSVGs/treeNuts.svg'),
  Triticale: require('assets/AllCropSVGs/triticale.svg'),
  Tulips: require('assets/AllCropSVGs/fallow.svg'),
  TurnRow: require('assets/AllCropSVGs/fallow.svg'),
  TurnipGreens: require('assets/AllCropSVGs/turnips.svg'),
  Turnips: require('assets/AllCropSVGs/turnips.svg'),
  Undecided: require('assets/AllCropSVGs/fallow.svg'),
  Vetch: require('assets/AllCropSVGs/vetch.svg'),
  Walnut: require('assets/AllCropSVGs/walnuts.svg'),
  Watercress: require('assets/AllCropSVGs/fallow.svg'),
  Watermelon: require('assets/AllCropSVGs/watermelon.svg'),
  Wheat: require('assets/AllCropSVGs/wheat.svg'),
  WildlifeRefuge: require('assets/AllCropSVGs/fallow.svg'),
  WindmillGrass: require('assets/AllCropSVGs/fallow.svg'),
  WRP: require('assets/AllCropSVGs/fallow.svg')
};
