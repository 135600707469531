import { cloneDeep, orderBy } from 'lodash';

export default (recommendations, field, direction) => {
  if (!direction) return recommendations;
  const _recommendations = cloneDeep(recommendations);

  switch (field) {
    case 'name':
      return orderBy(
        _recommendations,
        [rec => rec?.title?.toLowerCase()],
        direction
      );
    case 'fields':
      return orderBy(
        _recommendations,
        [rec => rec?.cropZones.length],
        direction
      );
    case 'totalAcres':
      return orderBy(
        _recommendations,
        [rec => rec?.cropZones.reduce((acc, zone) => acc + zone?.areaValue, 0)],
        direction
      );
    case 'products':
      return orderBy(
        _recommendations,
        [rec => rec?.recommendationProductEntries.length],
        direction
      );
    case 'validFrom':
      return orderBy(
        _recommendations,
        [rec => new Date(rec?.proposedDateEpoch)],
        direction
      );
    case 'seasons':
      return orderBy(
        _recommendations,
        [rec => rec?.cropSeasonIds.length],
        direction
      );
    default:
      return _recommendations;
  }
};
