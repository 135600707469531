import React from 'react';

const EmptyProductImage = () => {
  return (
    <svg
      width="166"
      height="128"
      viewBox="0 0 166 128"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M74.6387 110.975H91.9899L105.595 127.164H88.2435L74.6387 110.975ZM79.9197 113.438L89.3846 124.701H100.314L90.8487 113.438H79.9197Z"
        fill="#73DC78"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0.455078 0.491623L34.8691 14.4748H131.131L165.545 0.494863V99.7757L145.062 108.181V127.161H45.0461L33.8786 113.231L0.455078 99.785V0.491623ZM37.1951 113.438L46.2219 124.698H140.465L131.421 113.438L37.1951 113.438ZM133.998 112.721L142.608 123.44V109.189L133.998 112.721ZM130.784 110.975V30.5715L48.7885 30.5715L48.7884 110.973L130.784 110.975ZM46.3335 110.972L46.3336 29.8323L35.8574 18.787V110.972L46.3335 110.972ZM33.4025 16.5363V110.386L2.90995 98.1188V4.14655L33.4025 16.5363ZM37.4925 16.9379L48.0874 28.1084L130.784 28.1084V16.9379H37.4925ZM133.239 16.2759V110.371L163.09 98.1219V4.14948L133.239 16.2759Z"
        fill="#00004B"
      />
    </svg>
  );
};

const EmptyProductsDisplay = () => {
  return (
    <div
      id="empty-products-display"
      className="w-full h-full mt-24 flex flex-col items-center justify-center"
    >
      <EmptyProductImage />
      <span className="mt-6 text-xl">
        Select a product from the list to add it to this task
      </span>
    </div>
  );
};

export default EmptyProductsDisplay;
