import React, { useContext, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Spinner, Tag } from '@agconnections/grow-ui';
import CheckIcon from '@material-ui/icons/Check';
import { INTEGRATIONS } from 'screens/Integrations/helpers/constants';
import { PropertiesMatchContext } from '../../context/PropertiesMatchProvider';

const TopHeader = ({ farmFieldTreeData }) => {
  const {
    state: {
      unmatchedEntities,
      matchEntityType,
      vendorProperties,
      matches,
      integrationType
    },
    updateUnmatchedEntities,
    isLoading
  } = useContext(PropertiesMatchContext);

  const getEntitiesIds = () => {
    const fields = farmFieldTreeData?.flatMap(farm => farm.fields);
    if (integrationType === INTEGRATIONS.agrian)
      return fields?.map(field => field.id);

    return fields
      ?.flatMap(field => field.cropzones)
      .map(cropZone => cropZone.id);
  };

  const refreshTotalUnmatches = () => {
    const matchesEntitiesIds = matches.map(m => m.cwfId);
    const entitiesIds = getEntitiesIds();
    if (!entitiesIds || entitiesIds.length === 0) {
      return;
    }
    const totalUnmatches = entitiesIds?.filter(
      id => !matchesEntitiesIds.includes(id)
    ).length;
    updateUnmatchedEntities(totalUnmatches);
  };

  useEffect(() => {
    refreshTotalUnmatches();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [matches, vendorProperties]);

  return (
    <div data-testid="top-header">
      <div className="w-full flex h-10 mb-2 items-center font-semibold">
        <p className="text-xl">Match {matchEntityType}</p>
      </div>
      <div className="w-full">
        <div className="grid grid-cols-10">
          <div className="col-span-8 justify-self-start">
            <p className="font-normal text-sm ">
              Match selected {matchEntityType} within Cropwise Financials with
              the ones in {integrationType}
            </p>
          </div>
          {isLoading ? (
            <div className="col-span-2 justify-self-end">
              <Spinner size="sm" />
            </div>
          ) : (
            <div className="col-span-2 justify-self-end">
              {unmatchedEntities === 0 ? (
                <div data-testid="all-matched">
                  <Tag key="unmatched-properties-length" color="#DFFBE8">
                    <CheckIcon
                      style={{
                        marginLeft: '7px',
                        fontSize: 16,
                        color: '#DFFBE8',
                        backgroundColor: '#0C612C',
                        borderRadius: '50%'
                      }}
                    />
                    <span className="text-base mr-2 ml-2 font-thin text-green-900">
                      All Matched
                    </span>
                  </Tag>
                </div>
              ) : (
                <div data-testid="unmatched-properties">
                  <Tag key="unmatched-properties-length" color="#FFE4AE">
                    <div className="text-base mr-2 ml-2 text-yellow-700">
                      <span className="font-bold">
                        {Math.max(unmatchedEntities, 0)}
                      </span>
                      <span className="font-thin"> Unmatched</span>
                    </div>
                  </Tag>
                </div>
              )}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

TopHeader.defaultProps = {
  farmFieldTreeData: []
};

TopHeader.propTypes = {
  farmFieldTreeData: PropTypes.arrayOf(PropTypes.object)
};

export default TopHeader;
