import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Redirect, useHistory } from 'react-router-dom';
import { cloneDeep } from 'lodash';

import Paper from 'components/Paper';
import LandingTable from 'components/LandingTable';
import { paths } from 'routes/paths';
import ProductsTableRow from './components/ProductsTableRow';
import productsTableSort from './helpers/productsTableSort';

const ProductsTable = ({
  products,
  totalInventoryProducts,
  setTotalInventoryProducts,
  isLoading
}) => {
  const history = useHistory();
  const [sortField, setSortField] = useState();
  const [sortDir, setSortDir] = useState();
  const [redirect /* , setRedirect */] = useState(null);
  let productsCopy = cloneDeep(products);

  const TABLE_COLUMNS = [
    {
      field: 'name',
      title: `Products (${totalInventoryProducts})`,
      level: '1',
      sortable: true
    },
    {
      field: 'type',
      title: 'Type',
      level: '0',
      sortable: true
    },
    {
      field: 'purchased',
      title: 'Invoiced (Qty)',
      level: '0',
      sortable: true
    },
    {
      field: 'applied',
      title: 'Applied (Qty)',
      level: '0',
      sortable: true
    },
    {
      field: 'available',
      title: 'Available (Qty)',
      level: '0',
      sortable: true
    },
    {
      field: 'avgPricePerUnit',
      title: 'Avg. Price/Unit',
      level: '0',
      sortable: true
    }
  ];

  productsCopy = sortField
    ? productsTableSort(productsCopy, sortField, sortDir)
    : productsCopy; // If no sort selected, return default products list

  const handleAction = (action, product) => {
    const actions = {
      view: () => {
        /* view action */
        history.push(
          `${paths.inventory}/${product?.productId || product?.id}/view`
        );
      },
      edit: () => {
        /* edit action */
      },
      delete: () => {
        /* delete action */
      },
      flag: () => {
        /* flag action */
      },
      duplicate: () => {
        /* duplicate action */
      }
    };
    actions[action]();
  };

  const handleSort = (field, dir) => {
    setSortField(field);
    setSortDir(dir);
  };

  useEffect(() => {
    setTotalInventoryProducts(products.length);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [products]);

  return (
    <div
      className="mx-2 my-4 min-w-content"
      data-testid="products-table"
      style={{ minWidth: 'min-content' }}
    >
      <Paper>
        {redirect && <Redirect push to={`${redirect}`} />}
        <LandingTable
          listType="products"
          routePath={paths.inventory}
          isLoading={isLoading}
          items={productsCopy}
          tableColumns={TABLE_COLUMNS}
          onSort={handleSort}
          sortBy={sortField}
          sortDir={sortDir}
        >
          {({ rows }) =>
            !isLoading ? (
              rows.map(product => (
                <ProductsTableRow
                  key={product}
                  product={product}
                  onAction={handleAction}
                />
              ))
            ) : (
              <div style={{ height: '80vh' }} />
            )
          }
        </LandingTable>
      </Paper>
    </div>
  );
};

ProductsTable.propTypes = {
  products: PropTypes.arrayOf(PropTypes.object),
  totalInventoryProducts: PropTypes.string.isRequired,
  setTotalInventoryProducts: PropTypes.func.isRequired,
  isLoading: PropTypes.bool.isRequired
};

ProductsTable.defaultProps = {
  products: []
};

export default ProductsTable;
