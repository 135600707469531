import formatNumber from 'helpers/formatNumber';
import { Maths } from 'helpers/maths';
import { getAbbreviatedUnit } from 'helpers/unitsAbbreviation';

export default function formatAvgHarvestedQuantity(
  load,
  loadUnit,
  cropzoneTotalArea
) {
  if (load && loadUnit && cropzoneTotalArea) {
    return formatNumber(
      Maths.divideSafeRound(load, cropzoneTotalArea, 2),
      `${getAbbreviatedUnit(loadUnit)} / ac`
    );
  }
  return undefined;
}
