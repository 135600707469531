import React, { useState, useRef } from 'react';
import PropTypes from 'prop-types';
import { FileDrop } from 'react-file-drop';
import { InboxOutlined } from '@material-ui/icons';

import { hasListedFileType } from 'utilities/fileHelpers';

const uploadStates = Object.freeze({
  READY: Symbol('ready'),
  ERROR: Symbol('error'),
  UPLOADING: Symbol('uploading')
});

const UploadingMessage = () => (
  <div className="flex flex-col m-auto items-center content-center">
    <div
      className="absolute left-auto top-auto flex flex-row items-center content-center justify-end text-xs font-medium"
      style={{ width: 98, height: 113 }}
    >
      Uploading...
    </div>
    <svg
      width="98"
      height="113"
      viewBox="0 0 98 113"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <mask id="path-1-inside-1" fill="white">
        <path d="M96.1173 91.3662C97.276 92.387 97.3927 94.1592 96.3154 95.2655C89.5838 102.178 81.1676 107.246 71.8744 109.956C61.685 112.928 50.8608 112.943 40.6633 109.999C30.4658 107.055 21.315 101.274 14.2771 93.3285C7.23919 85.3835 2.60413 75.6019 0.912022 65.1237C-0.780085 54.6456 0.540449 43.9023 4.71974 34.1458C8.89903 24.3893 15.765 16.0214 24.5174 10.0172C33.2699 4.0131 43.5485 0.619962 54.1554 0.23323C63.8293 -0.119485 73.4129 2.04183 81.9782 6.48418C83.349 7.19512 83.7961 8.91399 83.0176 10.2476C82.2391 11.5811 80.5302 12.0237 79.1555 11.3203C71.5088 7.40797 62.9737 5.50731 54.3591 5.82141C44.8114 6.16952 35.5592 9.22384 27.6807 14.6284C19.8022 20.033 13.6219 27.5653 9.85989 36.3476C6.09792 45.1298 4.90925 54.8004 6.43239 64.2323C7.95553 73.6641 12.1278 82.469 18.4629 89.6207C24.798 96.7723 33.0351 101.976 42.2143 104.626C51.3935 107.276 61.1368 107.263 70.3088 104.588C78.5844 102.175 86.0874 97.6841 92.1139 91.5637C93.1973 90.4634 94.9587 90.3455 96.1173 91.3662Z" />
      </mask>
      <path
        d="M96.1173 91.3662C97.276 92.387 97.3927 94.1592 96.3154 95.2655C89.5838 102.178 81.1676 107.246 71.8744 109.956C61.685 112.928 50.8608 112.943 40.6633 109.999C30.4658 107.055 21.315 101.274 14.2771 93.3285C7.23919 85.3835 2.60413 75.6019 0.912022 65.1237C-0.780085 54.6456 0.540449 43.9023 4.71974 34.1458C8.89903 24.3893 15.765 16.0214 24.5174 10.0172C33.2699 4.0131 43.5485 0.619962 54.1554 0.23323C63.8293 -0.119485 73.4129 2.04183 81.9782 6.48418C83.349 7.19512 83.7961 8.91399 83.0176 10.2476C82.2391 11.5811 80.5302 12.0237 79.1555 11.3203C71.5088 7.40797 62.9737 5.50731 54.3591 5.82141C44.8114 6.16952 35.5592 9.22384 27.6807 14.6284C19.8022 20.033 13.6219 27.5653 9.85989 36.3476C6.09792 45.1298 4.90925 54.8004 6.43239 64.2323C7.95553 73.6641 12.1278 82.469 18.4629 89.6207C24.798 96.7723 33.0351 101.976 42.2143 104.626C51.3935 107.276 61.1368 107.263 70.3088 104.588C78.5844 102.175 86.0874 97.6841 92.1139 91.5637C93.1973 90.4634 94.9587 90.3455 96.1173 91.3662Z"
        fill="#4A5568"
        stroke="#787D96"
        strokeWidth="14"
        strokeLinejoin="round"
        mask="url(#path-1-inside-1)"
      />
    </svg>
  </div>
);

const FileForm = ({
  title,
  multiple,
  subtitle,
  fileTypes,
  onFileInput,
  children,
  className
}) => {
  return (
    <form className="w-full">
      <input
        type="file"
        data-testid="file-input-select"
        className="cursor-pointer absolute block opacity-0 top-0 bottom-0 left-0 right-0"
        accept={fileTypes.join(', ')}
        onChange={onFileInput}
        multiple={multiple}
      />
      <div className="mt-6 flex flex-col items-center content-center">
        <InboxOutlined fontSize="large" style={{ color: '#0071CD' }} />

        <div className="text-base font-normal text-neutral-1000 m-1">
          {title}
        </div>
        <div className={`text-sm mb-8 ${className}`}>
          {subtitle || fileTypes.join(', ')}
        </div>
        {children}
      </div>
    </form>
  );
};

FileForm.propTypes = {
  title: PropTypes.string,
  multiple: PropTypes.bool,
  subtitle: PropTypes.string,
  fileTypes: PropTypes.arrayOf(PropTypes.string),
  onFileInput: PropTypes.func.isRequired,
  children: PropTypes.node,
  className: PropTypes.string.isRequired
};

FileForm.defaultProps = {
  title: '',
  subtitle: '',
  fileTypes: [],
  multiple: false,
  children: null
};

const FileInput = ({
  title,
  subtitle,
  fileTypes,
  onSelect,
  children,
  multiple
}) => {
  const [uploadState, setUploadState] = useState(uploadStates.READY);
  const frameRef = useRef();

  const handleFileSelect = (inputFiles, event) => {
    const files = Array.from(inputFiles);
    if (fileTypes) {
      const areValid = files.reduce(
        (valid, file) => valid && hasListedFileType(fileTypes, file),
        true
      );
      if (!areValid) {
        event.preventDefault();
        setUploadState(uploadStates.ERROR);
        return;
      }
    }
    setUploadState(uploadStates.UPLOADING);
    const promise = onSelect(files, event);
    if (promise) {
      promise.then(() => setUploadState(uploadStates.READY));
    } else {
      setUploadState(uploadStates.READY);
    }
  };

  const handleFileDrop = event => {
    const { files } = event.dataTransfer;
    handleFileSelect(files, event);
  };

  const handleFileInput = event => {
    const { files } = event.target;
    return handleFileSelect(files, event);
  };

  const subtitleClasses =
    uploadState === uploadStates.ERROR
      ? 'text-red-600 font-medium'
      : 'text-gray-600';

  return (
    <div
      data-testid="file-input"
      className="file-drop h-auto w-auto bg-neutral-100 rounded-lg p-1 mb-2"
    >
      <div className="h-full w-auto relative border rounded-lg border-dashed border-neutral-300">
        <div
          ref={el => (frameRef.current = el)}
          className="file-drop-target w-auto h-full flex"
        >
          {uploadState !== uploadStates.UPLOADING && (
            <FileForm
              title={title}
              subtitle={subtitle}
              fileTypes={fileTypes}
              onFileInput={handleFileInput}
              className={subtitleClasses}
              frameRef={frameRef.current}
              multiple={multiple}
            >
              {children}
              <FileDrop
                multiple={multiple}
                frame={frameRef.current}
                onFrameDrop={handleFileDrop}
                onDrop={handleFileSelect}
              />
            </FileForm>
          )}
          {uploadState === uploadStates.UPLOADING && <UploadingMessage />}
        </div>
      </div>
    </div>
  );
};

FileInput.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
  fileTypes: PropTypes.arrayOf(PropTypes.string),
  onSelect: PropTypes.func.isRequired,
  children: PropTypes.node,
  multiple: PropTypes.bool
};

FileInput.defaultProps = {
  title: '',
  subtitle: '',
  fileTypes: [],
  children: null,
  multiple: false
};

export default FileInput;
