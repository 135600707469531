import React from 'react';
import PropTypes from 'prop-types';
import { kebabCase } from 'lodash';

const BaseFinancialButton = ({ disabled, id, isSelected, name, onToggle }) => {
  const disabledStyles = `${disabled && 'opacity-25 cursor-not-allowed'}`;

  return (
    <button
      disabled={disabled}
      style={{ width: '154px', height: '64px' }}
      className={`
      border border-cornflower-blue-500 font-medium rounded p-2 rounded mr-2 focus:outline-none
        ${
          isSelected
            ? 'bg-cornflower-blue-500 text-white font-bold'
            : 'hover:bg-opacity-25 active:bg-opacity-50 hover:bg-blue-200 bg-white text-cornflower-blue-500'
        }
        ${disabledStyles}
      `}
      type="button"
      data-testid={id || `financial-${kebabCase(name)}`}
      onClick={onToggle}
    >
      {name}
    </button>
  );
};

BaseFinancialButton.defaultProps = {
  disabled: null,
  id: '',
  isSelected: false,
  name: ''
};

BaseFinancialButton.propTypes = {
  disabled: PropTypes.bool,
  id: PropTypes.string,
  isSelected: PropTypes.bool,
  name: PropTypes.string,
  onToggle: PropTypes.func.isRequired
};

export default BaseFinancialButton;
