import React, { useContext } from 'react';

import PortalModal from 'components/Modals/PortalModal';
import ErrorOutline from 'components/Icons/ErrorOutline';
import { Button } from '@agconnections/grow-ui';

import useSubmissionAnalytics from 'screens/Integrations/hooks/useSubmissionAnalytics';
import { SubmissionSummaryContext } from '../../context/SubmissionSummaryProvider';

const RemoveCropzoneModal = () => {
  const { events, triggerAnalyticsEvent } = useSubmissionAnalytics();
  const {
    removeCropzone,
    setOpenRemoveCropzoneModal,
    state: { openRemoveCropzoneModal, farms, integrationType, selectedFarm }
  } = useContext(SubmissionSummaryContext);

  const handleClose = () => {
    setOpenRemoveCropzoneModal(false);
  };

  const handleRemoveCropzone = () => {
    removeCropzone();
    triggerAnalyticsEvent(events.epic.Integrations.submissionCropZoneRemoved, {
      vendor: integrationType
    });
    setOpenRemoveCropzoneModal(false);
  };

  const cropZonesLength = farms[selectedFarm]?.fields?.reduce(
    (acc, field) => acc + field.cropzones.length,
    0
  );

  return (
    <PortalModal
      open={openRemoveCropzoneModal}
      type="buttonless"
      style={{ paddingLeft: '2rem' }}
    >
      <div
        className="flex flex-line w-full mt-2 justify-between"
        data-testid="remove-cropzone-modal"
      >
        <div className="flex mr-4">
          <ErrorOutline />
        </div>
        <div className="flex flex-col pr-10" style={{ flexGrow: 1 }}>
          {cropZonesLength > 1 && (
            <>
              <div className="flex flex-row justify-items-center pb-4">
                <h3 className="font-bold text-lg leading-6 text-gray-900 w-full">
                  Remove crop zone?
                </h3>
              </div>
              <div className="flex flex-col mb-4">
                Are you sure you want to remove the crop zone from this
                submission?
              </div>
            </>
          )}
          {cropZonesLength === 1 && (
            <>
              <div className="flex flex-row justify-items-center pb-4">
                <h3 className="font-bold text-lg leading-6 text-gray-900 w-full">
                  Remove property?
                </h3>
              </div>
              <div className="flex flex-col mb-4">
                This is the only crop zone within this property for submission.
                Removing it will will remove the entire property from
                submission.
              </div>
            </>
          )}

          <div className="mt-5 flex flex-row items-center justify-end w-full">
            <div className="mr-5">
              <Button type="outline" id="cancel-button" onClick={handleClose}>
                Cancel
              </Button>
            </div>
            <div>
              <Button
                type="primary"
                id="submit-button"
                onClick={handleRemoveCropzone}
              >
                Remove
              </Button>
            </div>
          </div>
        </div>
      </div>
    </PortalModal>
  );
};

export default RemoveCropzoneModal;
