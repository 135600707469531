import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import Breadcrumb from 'components/App/AppShell/components/Breadcrumb';
import { StorageLocationContext } from '../../context/StorageLocationProvider';

const StorageLocationBreadcrumb = ({ children, disabled }) => {
  const { resetLocation } = useContext(StorageLocationContext);

  const handleOrganizationChange = () => {
    resetLocation();
  };

  return (
    <Breadcrumb
      onOrganizationSelect={handleOrganizationChange}
      disabled={disabled}
    >
      {children}
    </Breadcrumb>
  );
};

StorageLocationBreadcrumb.defaultProps = {
  disabled: false
};

StorageLocationBreadcrumb.propTypes = {
  // eslint-disable-next-line
  children: PropTypes.any,
  disabled: PropTypes.bool
};

export default StorageLocationBreadcrumb;
