import { defaultProducts } from 'helpers/transformHelpers';
import { getDefaultFormValues } from '../../../Recommendations/Recommendation/helpers/dataMappers';
import { mapProperties } from '../../ConvertPlanModal/helpers/dataHelper';

export const mapPlanToRec = (
  user,
  plan,
  productsToInclude,
  propertyLandingPageData,
  fieldsAndAreasGeoJSONCollection
) => {
  const newRec = getDefaultFormValues(user);
  const products = productsToInclude.map(product => defaultProducts(product));

  newRec.products = products;
  newRec.properties =
    mapProperties(
      plan.cropZones,
      propertyLandingPageData,
      fieldsAndAreasGeoJSONCollection,
      plan
    ) || [];

  const totalApplied = plan.cropZones.reduce((ta, { areaValue }) => {
    return ta + areaValue;
  }, 0);

  newRec.totalApplied = totalApplied;
  newRec.cropSeasonIds = plan.cropSeasonIds.reduce((ids, currId) => {
    return `${ids}, ${currId}`;
  });
  newRec.source = [{ id: plan.id, name: plan.name }];

  return newRec;
};

export default mapPlanToRec;
