/* eslint-disable react/forbid-prop-types */
import React from 'react';
import PropTypes from 'prop-types';

const TaskList = ({ children, innerRef, isDraggingOver }) => {
  const conditionalClasses = isDraggingOver
    ? 'bg-blue-100 border border-blue-600 border-t-0'
    : 'bg-neutral-150'; // If we want to customize transitional styling

  return (
    <div
      ref={innerRef}
      className={`py-4 h-full border-l border-r border-b rounded-b ${conditionalClasses}`}
    >
      {children}
    </div>
  );
};

TaskList.defaultProps = {
  children: <></>
};

TaskList.propTypes = {
  children: PropTypes.node,
  innerRef: PropTypes.func.isRequired,
  isDraggingOver: PropTypes.bool.isRequired
};

export default TaskList;
