import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { Button } from '@agconnections/grow-ui';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronLeft } from '@fortawesome/free-solid-svg-icons';
import { useHistory } from 'react-router-dom';
import { PropertiesMatchContext } from '../../context/PropertiesMatchProvider';

const PropertiesMatchFooter = ({ setModalSubmissionState }) => {
  const history = useHistory();

  const {
    submitMatches,
    integrationContext,
    state: { unmatchedEntities }
  } = useContext(PropertiesMatchContext);

  const { goBack } = useContext(integrationContext);

  const handleCancel = () => {
    history.goBack();
  };

  return (
    <div
      className="w-full h-24"
      data-testid="properties-match-footer"
      data-html2canvas-ignore
    >
      <div className="flex flex-row border-t-2 bg-white w-auto justify-between px-4 py-6 h-auto">
        <Button type="outline" onClick={handleCancel} data-testid="cancel">
          Cancel
        </Button>
        <div className="flex flex-row gap-6">
          <Button
            type="outline"
            onClick={goBack}
            data-testid="back"
            icon={<FontAwesomeIcon fixedWidth size="1x" icon={faChevronLeft} />}
          >
            Back
          </Button>
          <Button
            type="primary"
            data-testid="submit"
            disabled={unmatchedEntities !== 0}
            onClick={() => {
              submitMatches();
              setModalSubmissionState(true);
            }}
          >
            Next: Review and Submit
          </Button>
        </div>
      </div>
    </div>
  );
};

PropertiesMatchFooter.propTypes = {
  setModalSubmissionState: PropTypes.func
};

PropertiesMatchFooter.defaultProps = {
  setModalSubmissionState: () => {}
};

export default PropertiesMatchFooter;
