import { Decimal } from 'decimal.js';
import { Maths } from 'helpers/maths';

export function convertFifteenPlaces(number) {
  return Maths.parseFloatRound(number, 15, Decimal.ROUND_HALF_UP);
}

export function convertTwoPlaces(number) {
  return Maths.parseFloatRound(number, 2, Decimal.ROUND_HALF_UP);
}

export default convertFifteenPlaces;
