import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { SimpleModal } from '@agconnections/grow-ui';
import { withRouter, useHistory } from 'react-router-dom';
import { company as companyApi } from 'utilities/api';
import catchCancel from 'helpers/catchCancel';
import { paths } from 'routes/paths';

const DeleteCompanyModal = ({ open, close, companyName, companyId }) => {
  const history = useHistory();
  const [isSaving, setIsSaving] = useState(false);
  const handleDelete = async () => {
    setIsSaving(true);
    const { promise } = companyApi.delete(companyId);
    await promise
      .then(() => {
        history.push({
          pathname: paths.companies,
          selectedView: 'comfortable'
        });
        window.location.reload(false);
      })
      .catch(catchCancel)
      .catch(() => {
        history.go({
          pathname: paths.companies,
          selectedView: 'comfortable'
        });
      });
  };

  const handleCancel = () => {
    close();
  };

  const actionPerformed = 'Delete "';
  const closeQuote = '"';

  return (
    <SimpleModal
      open={open}
      title={actionPerformed + companyName + closeQuote}
      close={close}
      cancelLabel="Cancel"
      confirmLabel="Delete"
      onCancel={() => handleCancel()}
      type="error"
      onConfirm={() => handleDelete()}
      isSaving={isSaving}
    >
      <div className="pr-6">
        You are about to permanently delete this Company. This cannot be undone.
        Are you sure you want to proceed?
      </div>
    </SimpleModal>
  );
};

DeleteCompanyModal.defaultProps = {
  companyName: '',
  companyId: ''
};

DeleteCompanyModal.propTypes = {
  open: PropTypes.bool.isRequired,
  close: PropTypes.func.isRequired,
  companyName: PropTypes.string,
  companyId: PropTypes.string
};

export default withRouter(DeleteCompanyModal);
