import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { Button, Spinner } from '@agconnections/grow-ui';
import SavePDFIcon from 'components/Icons/components/SavePDFIcon';
import { IntegrationsContext } from 'screens/Integrations/context/IntegrationsProvider';
import { useFormikContext } from 'formik';
import { INTEGRATIONS } from 'screens/Integrations/helpers/constants';
import useSubmissionAnalytics from 'screens/Integrations/hooks/useSubmissionAnalytics';

const ModalButtons = ({ handleClose }) => {
  const { submitToMcCain, isLoading } = useContext(IntegrationsContext);
  const { events, triggerAnalyticsEvent } = useSubmissionAnalytics();
  const { values } = useFormikContext();
  const handleSubmitButton = () => {
    triggerAnalyticsEvent(events.epic.Integrations.submissionRunning, {
      vendor: INTEGRATIONS.mccain
    });
    submitToMcCain(values);
  };

  return (
    <>
      <div className="flex w-1/5 h-8">
        <Button
          type="outline"
          id="cancel-button"
          onClick={() => {
            handleClose();
          }}
        >
          Cancel
        </Button>
      </div>
      <div className="flex flex-row-reverse justify-start w-full">
        <div className="flex h-8">
          <Button
            type="primary"
            id="submit-button"
            onClick={handleSubmitButton}
            disabled={isLoading}
          >
            {isLoading ? <Spinner size="xs" /> : 'Submit to McCain'}
          </Button>
        </div>
        <div className="flex h-8 px-2">
          <Button id="save-button" type="primary" ghost icon={<SavePDFIcon />}>
            Save as PDF
          </Button>
        </div>
      </div>
    </>
  );
};

ModalButtons.propTypes = {
  handleClose: PropTypes.func.isRequired
};

export default ModalButtons;
