import { useEffect, useMemo } from 'react';
import { useFormikContext } from 'formik';

const useFieldsUpdate = ({ fieldsToWatch, updateFn, fieldToUpdate }) => {
  const { values, touched, setFieldValue } = useFormikContext();

  const deps = fieldsToWatch.map(field => values[field]);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const newValue = useMemo(() => updateFn(values), deps);

  useEffect(() => {
    const watchedFieldsTouchedDirectly = fieldsToWatch.some(
      field => touched[field]
    );
    const fieldToUpdateTouchedDirectly = touched[fieldToUpdate];
    const shouldUpdateField =
      watchedFieldsTouchedDirectly && !fieldToUpdateTouchedDirectly;

    if (shouldUpdateField) {
      setFieldValue(fieldToUpdate, newValue);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [newValue, touched]);
};

export default useFieldsUpdate;
