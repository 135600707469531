import React, { useState, useEffect, useContext } from 'react';
import PropTypes from 'prop-types';
import { Input, Button, Select } from '@agconnections/grow-ui';
import { useAuth } from 'utilities/base-auth';
import useOrganizations from 'hooks/useOrganizations';
import { useParams, Redirect } from 'react-router-dom';
import { Formik } from 'formik';
import GrowUIFormField from 'components/Forms/GrowUIFormField';
import catchCancel from 'helpers/catchCancel';
import { parseServerError } from 'helpers/errorHelpers';
import { Context } from 'components/Store';
import logo from 'assets/cropwise-financials-logo.svg';
import { organization as orgApi } from 'utilities/api';
import { selectOrganization } from 'utilities/updated-permissions';
import { paths } from 'routes/paths';
import schema from './schema';
import image from './assets/welcome.jpg';

const Welcome = () => {
  const { variation } = useParams();
  const [loading, setLoading] = useState(false);
  const [redirect, setRedirect] = useState(null);
  const [uiVariation, setUiVariation] = useState('new-user');
  const [, dispatch] = useContext(Context);
  const { fetchUserProfile, user, logout } = useAuth();
  const { filteredOrganizations } = useOrganizations();
  const [, /* isSaving */ setIsSaving] = useState(false);
  const defaultOrgID = filteredOrganizations?.[0]?.id;
  useEffect(() => {
    setUiVariation(variation);
  }, [variation]);

  const handleCreateOrganization = async formData => {
    setLoading(true);
    setIsSaving(true);
    if (!loading) {
      const { promise } = orgApi.create(formData);
      promise
        .then(({ data }) => {
          // This will reinitialize the application because the user profile has to be reset in order to get permissions
          fetchUserProfile();
          return data;
        })
        .then(data => {
          dispatch({
            type: 'SET_ORGANIZATIONS',
            payload: [{ name: data.name, id: data.id }]
          });
          selectOrganization(dispatch, { name: data.name, id: data.id }, user);
          setRedirect(`${paths.organizations}/${data.id}`);
        })
        .catch(catchCancel)
        .catch(err => {
          setIsSaving(false);
          setLoading(false);
          parseServerError(dispatch)(err);
        });
    }
  };

  const handleRedirect = () => {
    return logout();
  };
  return (
    <div
      data-testid="welcome"
      className="fixed top-0 right-0 z-50 w-full h-screen"
    >
      {redirect && <Redirect push to={redirect} />}
      <div className="flex flex-col-reverse w-full h-full sm:flex-row">
        <div className="sm:flex-1 bg-neutral-1000 bg-opacity-[.7] h-screen w-full ">
          <img
            src={image}
            alt="closeup of crop"
            className="flex flex-1 object-cover w-full h-full"
          />
        </div>
        <div className="flex items-center justify-center h-full bg-white sm:flex-1">
          <div className="max-w-[70%] ">
            <img
              src={logo}
              alt="Cropwise Financials Logo"
              className="max-w-[300px] block mx-auto mb-4"
            />
            <p className="mb-12">
              {uiVariation === 'new-user' ? (
                <>Welcome! Create a new organziation to get started.</>
              ) : (
                <>
                  Looks like you don’t currently belong to an organization.
                  Create a new one to get started.
                </>
              )}
            </p>
            <Formik
              validationSchema={schema}
              initialValues={{}}
              onSubmit={handleCreateOrganization}
            >
              {({ handleSubmit, errors }) => {
                // const errorsArr = Object.values(errors);
                return (
                  <>
                    <div className="mb-12">
                      <GrowUIFormField
                        control={Input}
                        label="Organization Name"
                        name="name"
                        errors={errors.name}
                      />
                    </div>
                    <div className="grid grid-cols-2 gap-4 mb-6 gap-y-12">
                      <div>
                        <GrowUIFormField
                          control={Select}
                          items={[{ key: 'en-US', value: 'en-US' }]}
                          label="Language / Country"
                          name="languageCountry"
                          errors={errors.name}
                        />
                      </div>
                      <div>
                        <GrowUIFormField
                          control={Select}
                          items={[{ key: 'en-US', value: 'en-US' }]}
                          label="Master List Group"
                          name="masterListGroup"
                          errors={errors.name}
                        />
                      </div>
                      <div>
                        <GrowUIFormField
                          control={Select}
                          items={[
                            {
                              value: 'Atlantic Standard Time (GMT-4)',
                              key: '(GMT-4)'
                            },
                            {
                              value: 'Eastern Standard Time (GMT-5)',
                              key: '(GMT-5)'
                            },
                            {
                              value: 'Central Standard Time (GMT-6)',
                              key: '(GMT-6)'
                            },
                            {
                              value: 'Mountain Standard Time (GMT-7)',
                              key: '(GMT-7)'
                            },
                            {
                              value: 'Pacific Standard Time (GMT-8)',
                              key: '(GMT-8)'
                            },

                            {
                              value: 'Alaska Standard Time (GMT-9)',
                              key: '(GMT-9)'
                            },
                            {
                              value: 'Hawaii Standard Time (GMT-10)',
                              key: '(GMT-10)'
                            }
                          ]}
                          label="Time Zone"
                          name="timeZone"
                          errors={errors.name}
                        />
                      </div>
                      <div>
                        <GrowUIFormField
                          control={Select}
                          items={[{ key: 'acres', value: 'acres' }]}
                          label="Area Unit"
                          name="areaUnit"
                          errors={errors.name}
                        />
                      </div>
                    </div>
                    <div className="flex justify-end">
                      <div className="mr-2">
                        {/* route to dashboard */}
                        <Button
                          type="primary"
                          ghost
                          disabled={!defaultOrgID}
                          // onClick={() => history.push('/app/dashboard')}
                          onClick={handleRedirect}
                        >
                          Cancel
                        </Button>
                      </div>
                      {/* handle create */}
                      <Button
                        type="primary"
                        ghost={loading}
                        onClick={handleSubmit}
                        loading={loading}
                      >
                        Create
                      </Button>
                    </div>
                  </>
                );
              }}
            </Formik>
          </div>
        </div>
      </div>
    </div>
  );
};

Welcome.defaultProps = {
  modalTrigger: null
};

Welcome.propTypes = {
  modalTrigger: PropTypes.shape({
    props: {
      onClick: PropTypes.func
    }
  })
};

export default Welcome;
