import { useContext, useEffect, useState, useCallback } from 'react';
import { invoices as invoicesApi } from 'utilities/api';
import catchCancel from 'helpers/catchCancel';
import PAGINATION_SIZE from 'helpers/globalConstants';
import { parseServerError } from 'helpers/errorHelpers';
import { Context } from 'components/Store';
import { orderBy } from 'lodash';

const useInvoiceData = () => {
  const [, dispatch] = useContext(Context);
  const [invoices, setInvoices] = useState([]);
  const [totalInvoices, setTotalInvoices] = useState(0);

  const [loading, setLoading] = useState(true);

  const loadLandingPageInvoiceSummaries = useCallback(
    async invoicePageNumber => {
      setLoading(true);
      const selectedSeasons = JSON.parse(
        localStorage.getItem('selectedCropSeasons')
      );
      const invoicesLandingApi = invoicesApi.createChildApi({
        action: 'invoice/summaries'
      });
      const { promise } = invoicesLandingApi.fetch('', {
        pageNo: invoicePageNumber || 0,
        size: PAGINATION_SIZE,
        seasonIdsCommaSeparated: selectedSeasons.join(',')
      });
      await promise
        .then(({ data }) => {
          if (data.results) {
            setInvoices(
              orderBy(data.results, [({ title }) => title.toLowerCase()], 'asc')
            );
            setTotalInvoices(data.totalElements);
            setLoading(false);
          }
        })
        .catch(catchCancel)
        .catch(err => {
          parseServerError(dispatch)(err);
          setLoading(false);
        });
    },
    [dispatch]
  );
  const getReportInvoices = useCallback(
    async selectedCropSeasons => {
      setLoading(true);
      const invoicesLandingApi = invoicesApi.createChildApi({
        action: 'invoice/summaries'
      });
      const { promise } = invoicesLandingApi.fetch('', {
        seasonIdsCommaSeparated: selectedCropSeasons
      });
      await promise
        .then(({ data }) => {
          if (data.results) {
            setInvoices(data.results);
          }
        })
        .catch(catchCancel)
        .catch(err => {
          parseServerError(dispatch)(err);
        })
        .finally(() => {
          setLoading(false);
        });
    },
    [dispatch]
  );

  useEffect(() => {
    loadLandingPageInvoiceSummaries();
  }, [loadLandingPageInvoiceSummaries]);

  return {
    invoices,
    loading,
    setLoading,
    loadLandingPageInvoiceSummaries,
    totalInvoices,
    getReportInvoices
  };
};

export default useInvoiceData;
