import React from 'react';
import PropTypes from 'prop-types';
import { Input, SimpleModal, RadioButtonGroup } from '@agconnections/grow-ui';

const ViewPropertiesModal = ({ open, onClose, property }) => {
  const displayEmptyCell = value => {
    if (!value || value === 0) {
      return ' ';
      // eslint-disable-next-line no-else-return
    } else {
      return `${value} ac`;
    }
  };
  return (
    <SimpleModal open={open} close={onClose} onConfirm={onClose} size="sm">
      <div className="ml-6">
        <div className="mb-6">
          <div className="text-xl font-bold">{property?.fieldName}</div>
          <span className="text-sm text-neutral-300">
            {property?.cropZoneName}
          </span>
        </div>
        <div className="flex mb-6">
          <div className="mr-6">
            <span className="text-sm text-neutral-300">Area</span>
            <div className="text-xl">{property?.areaValue} ac</div>
          </div>
          <div>
            <span className="text-sm text-neutral-300">Crop</span>
            <div className="text-xl ">{property?.cropName}</div>
          </div>
        </div>
        <div className="mb-12">
          <RadioButtonGroup>
            <RadioButtonGroup.RadioButton disabled selected>
              Reported Area ({displayEmptyCell(property.reportedArea)})
            </RadioButtonGroup.RadioButton>
            <RadioButtonGroup.RadioButton disabled>
              Boundary Area ({property.boundaryArea} ac)
            </RadioButtonGroup.RadioButton>
          </RadioButtonGroup>
        </div>
        <div className="flex">
          <div className="mr-4">
            <Input
              disabled
              label="Applied Area"
              value={`${property.areaValue} ac`}
            />
          </div>
          <Input
            disabled
            label="Coverage %"
            value={`${property.coveragePercent}%`}
          />
        </div>
      </div>
    </SimpleModal>
  );
};

ViewPropertiesModal.defaultProps = {
  open: false,
  onClose: () => {}
};
ViewPropertiesModal.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
  property: PropTypes.shape({
    cropZoneName: PropTypes.string,
    cropName: PropTypes.string,
    fieldName: PropTypes.string,
    areaValue: PropTypes.number,
    areaUnit: PropTypes.string,
    boundaryArea: PropTypes.number,
    reportedArea: PropTypes.number,
    originalAcres: PropTypes.number,
    coveragePercent: PropTypes.oneOfType([PropTypes.number, PropTypes.string])
  }).isRequired
};

export default ViewPropertiesModal;

// {
//   <div>
//     <div className="flex items-center justify-between">
//       <div className="font-bold text-lg">{property.fieldName}</div>
//     </div>

//     <div className="text-neutral-300 text-sm -mt-1">
//       {property.cropZoneName}
//     </div>

//     <div className="w-48 mt-4 flex justify-between">
//       <div>
//         <div className="text-neutral-300 text-sm">Area</div>
//         <div className="font-semibold text-lg">{property.areaValue} ac</div>
//       </div>

//       <div>
//         <div className="text-neutral-300 text-sm">Crop</div>
//         <div className="font-semibold text-lg">{property.cropName}</div>
//       </div>
//     </div>

//     <div className="mt-8">
//       <RadioButtonGroup>
//         <RadioButtonGroup.RadioButton disabled selected>
//           Reported Area ({property.reportedArea} ac)
//         </RadioButtonGroup.RadioButton>
//         <RadioButtonGroup.RadioButton disabled>
//           Boundary Area ({property.boundaryArea} ac)
//         </RadioButtonGroup.RadioButton>
//       </RadioButtonGroup>
//     </div>

//     <div className="flex mt-12 mb-24">
//       <Input disabled label="Applied Area" value={`${property.areaValue} ac`} />
//       <div className="ml-4">
//         <Input
//           disabled
//           label="Coverage %"
//           value={`${property.coveragePercent * 100}%`}
//         />
//       </div>
//     </div>
//   </div>;
// }
