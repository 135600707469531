import React from 'react';
import PropTypes from 'prop-types';

import { Input, Form } from '@agconnections/grow-ui';

function LicenseNumberField({ title, licenseNumber, onChange, errors }) {
  return (
    <Form.Field>
      <div className="flex mr-6">
        <div className="w-full">
          <div className="block text-sm font-medium leading-5 gr-text-gray-600 sm:mt-px sm:pt-2">
            {title}
          </div>
          <div
            data-testid="license-number-field"
            className="block text-sm font-medium leading-5 text-gray-700 sm:mt-px sm:pt-2"
          >
            <Input
              id="license-number-input"
              onChange={onChange}
              defaultValue={licenseNumber}
              name="applicatorLicense"
              error={errors}
              errorMessage={errors}
            />
          </div>
        </div>
      </div>
    </Form.Field>
  );
}

LicenseNumberField.defaultProps = {
  title: '',
  licenseNumber: '',
  errors: null
};

LicenseNumberField.propTypes = {
  title: PropTypes.string,
  licenseNumber: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  errors: PropTypes.string
};

export default LicenseNumberField;
