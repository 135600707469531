const alphanumSort = (arr, property = 'name', order = 'asc') => {
  return arr.sort((a, b) => {
    const valueA = a[property]?.toString().toLowerCase() || '';
    const valueB = b[property]?.toString().toLowerCase() || '';
    if (!valueA) return 1;
    if (!valueB) return -1;
    const comparison = valueA.localeCompare(valueB);
    return order === 'asc' ? comparison : -comparison;
  });
};

export default alphanumSort;
