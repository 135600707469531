import PropTypes from 'prop-types';
import React from 'react';

const SatelliteIcon = ({ size }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={size}
    height={size}
    viewBox="0 0 24 24"
    fill="none"
  >
    <g clipPath="url(#clip0_7716_500311)">
      <path
        d="M21.0001 14.0001H23.0001C23.0001 18.9701 18.9701 23.0001 14.0001 23.0001V21.0001C17.8701 21.0001 21.0001 17.8701 21.0001 14.0001ZM14.0001 17.0001V19.0001C16.7601 19.0001 19.0001 16.7601 19.0001 14.0001H17.0001C17.0001 15.6601 15.6601 17.0001 14.0001 17.0001ZM18.2601 0.590059L21.8001 4.13006C22.5801 4.91006 22.5801 6.18006 21.8001 6.96006L18.6201 10.1401C17.8401 10.9201 16.5701 10.9201 15.7901 10.1401L14.5501 8.90006L13.8401 9.60006L15.0801 10.8401C15.8601 11.6201 15.8601 12.8901 15.0801 13.6701L13.6701 15.0801C12.8901 15.8601 11.6201 15.8601 10.8401 15.0801L9.60012 13.8401L8.89012 14.5501L10.1301 15.7901C10.9101 16.5701 10.9101 17.8401 10.1301 18.6201L6.95012 21.8001C6.17012 22.5801 4.90012 22.5801 4.12012 21.8001L0.580117 18.2601C-0.199883 17.4801 -0.199883 16.2101 0.580117 15.4301L3.76012 12.2501C4.54012 11.4701 5.81012 11.4701 6.59012 12.2501L7.83012 13.4901L8.54012 12.7801L7.30012 11.5501C6.52012 10.7701 6.52012 9.50006 7.30012 8.72006L8.72012 7.30006C9.50012 6.52006 10.7701 6.52006 11.5501 7.30006L12.7901 8.54006L13.5001 7.83006L12.2501 6.60006C11.4701 5.82006 11.4701 4.55006 12.2501 3.77006L15.4301 0.590059C16.2201 -0.199941 17.4801 -0.199941 18.2601 0.590059ZM3.06012 15.7901L2.00012 16.8501L5.54012 20.3901L6.60012 19.3301L3.06012 15.7901ZM5.18012 13.6701L4.12012 14.7301L7.66012 18.2701L8.72012 17.2101L5.18012 13.6701ZM10.1301 8.72006L8.72012 10.1301L12.2601 13.6701L13.6701 12.2601L10.1301 8.72006ZM14.7301 4.12006L13.6701 5.18006L17.2101 8.72006L18.2701 7.66006L14.7301 4.12006ZM16.8501 2.00006L15.7901 3.06006L19.3301 6.60006L20.3901 5.54006L16.8501 2.00006Z"
        fill="#696F88"
      />
    </g>
    <defs>
      <clipPath id="clip0_7716_500311">
        <rect width="24" height="24" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

SatelliteIcon.propTypes = {
  size: PropTypes.number
};

SatelliteIcon.defaultProps = {
  size: 24
};

export default SatelliteIcon;
