import { merge, set } from 'lodash';
import featureConfig from 'config/features.config';

const disabledFeatures = process.env.REACT_APP_DISABLED_FEATURES || '';
const envFeatureOverrides = disabledFeatures
  .split(',')
  .reduce((acc, path) => set(acc, path, 'disabled'), {});

const features = merge(featureConfig, envFeatureOverrides);

const getFeatureConfig = () => features;

export default getFeatureConfig;
