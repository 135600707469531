import React, { useState, useMemo, useEffect, useContext } from 'react';
import PropTypes from 'prop-types';

import { LAST_COORDINATES_STORED } from 'screens/Property/helpers/constants';
import { getStringParsed } from 'utilities/helpers';
import { calculateCentroidByEachProperties } from '../../helpers/mapApiHelpers';
import PropertyLandingContext from '../context';
import PropertyMapWrapper from './PropertyMapWrapper';

const PropertyLandingMap = ({ properties, onSetShowSummaryPanel }) => {
  const {
    selectedProperty,
    selectedField,
    propertyZoom,
    onPropertySetZoom,
    isLoadingFields,
    fieldsAndAreasGeoJSONCollection: geoJSONCollection
  } = useContext(PropertyLandingContext);

  const [manualCoords, setManualCoords] = useState(
    getStringParsed(localStorage.getItem(LAST_COORDINATES_STORED))
  );

  const geoJSONCentroid = useMemo(() => {
    return JSON.stringify(
      calculateCentroidByEachProperties(geoJSONCollection, properties)
    );
  }, [geoJSONCollection, properties]);

  const mapCoordinates = useMemo(
    () => manualCoords || JSON.parse(geoJSONCentroid)?.geometry?.coordinates,
    [geoJSONCentroid, manualCoords]
  );

  useEffect(() => {
    if (selectedField?.length > 0) {
      onSetShowSummaryPanel(true)();
    }
  }, [selectedField, onSetShowSummaryPanel]);

  const labelName = (selectedProperty?.type || 'org')
    .toLowerCase()
    .replace(/\s+/g, '');

  return (
    <div className="relative w-full h-full flex flex-col overflow-y-auto">
      <PropertyMapWrapper
        fieldsLoading={isLoadingFields}
        zoom={propertyZoom}
        setZoom={onPropertySetZoom}
        currentCoordinates={mapCoordinates}
        setCurrentCoordinates={setManualCoords}
        displayOnly
        geoJSONCollection={geoJSONCollection}
        labelName={labelName}
        selectedField={selectedField}
        stretch
      />
    </div>
  );
};

PropertyLandingMap.defaultProps = {
  properties: null,
  onSetShowSummaryPanel: () => () => {}
};

PropertyLandingMap.propTypes = {
  properties: PropTypes.arrayOf(PropTypes.object),
  onSetShowSummaryPanel: PropTypes.func
};

export default PropertyLandingMap;
