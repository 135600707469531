import { useContext, useState } from 'react';
import moment from 'moment';
import catchCancel from 'helpers/catchCancel';
import { parseServerError } from 'helpers/errorHelpers';
import { Context } from 'components/Store';
import {
  getUnmappedCropZonesOperationsNumber as cropZoneCountAPI,
  getUnmappedFarms,
  cropZoneMatcher as cropZoneMatcherAPI,
  mappedOperations as mappedOperationsAPI,
  johnDeereMissingPackageWeight as johnDeereMissingPackageWeightAPI
} from 'utilities/api';
import { AmplitudeContext } from 'utilities/amplitude/useAmplitude';

const useJohnDeereData = () => {
  const [, dispatch] = useContext(Context);
  const amplitude = useContext(AmplitudeContext);
  const [unmappedCropZoneCount, setUnmappedCropZoneCount] = useState(undefined);
  const [unmappedFarms, setUnmappedFarms] = useState([]);
  const [since, setSince] = useState(moment().subtract(18, 'months'));
  const [until, setUntil] = useState(moment());
  const [isLoadingCropZoneCount, setIsLoadingCropZoneCount] = useState(false);
  const [isLoadingFields, setIsLoadingFields] = useState(false);
  const [isLoadingMatchOperation, setIsLoadingMatchOperation] = useState(false);
  const [matchOperationError, setMatchOperationError] = useState(false);
  const [isLoadingMappedOperations, setIsLoadingMappedOperations] = useState(
    false
  );
  const [mappedOperations, setMappedOperations] = useState([]);
  const orgId = Context?._currentValue[0]?.organization?.id;
  const [productsWithoutWeight, setProductsWithoutWeight] = useState([]);
  const [isLoadingWeight, setIsLoadingWeight] = useState(false);
  const loadUnmappedFarms = cropSeasons => {
    setIsLoadingFields(true);
    const { promise } = getUnmappedFarms.fetch(
      undefined,
      { cropSeasons: cropSeasons.toString() },
      {
        headers: {
          'cwf-context': JSON.stringify({
            organization_id: orgId
          })
        }
      }
    );

    return promise
      .then(res => {
        setUnmappedFarms(res.data[0].farms);
      })
      .catch(catchCancel)
      .catch(err => {
        parseServerError(dispatch)(err);
      })
      .finally(() => setIsLoadingFields(false));
  };

  const loadProductsMissingWeight = () => {
    setIsLoadingWeight(true);
    const { promise } = johnDeereMissingPackageWeightAPI.fetch(undefined);

    return promise
      .then(res => {
        const sortedProductsByName = res.data.sort((a, b) => {
          const nameA = a.productName.toUpperCase();
          const nameB = b.productName.toUpperCase();
          if (nameA < nameB) return -1;
          if (nameA > nameB) return 1;
          return 0;
        });
        setProductsWithoutWeight(sortedProductsByName);
      })
      .catch(catchCancel)
      .catch(err => {
        parseServerError(dispatch)(err);
      })
      .finally(() => setIsLoadingWeight(false));
  };

  const loadMappedOperations = () => {
    setIsLoadingMappedOperations(true);
    const { promise } = mappedOperationsAPI.fetch(undefined, {
      since: since?.format('YYYY-MM-DD'),
      until: until?.format('YYYY-MM-DD')
    });
    return promise
      .then(res => {
        const _operations = res.data;
        let _sourceFields = _operations.reduce((accumulator, operation) => {
          const field = operation?.fields?.[0];
          if (!field) return accumulator;
          if (accumulator[field.id]) {
            accumulator[field.id].operations.push(operation);
          } else {
            accumulator[field.id] = {
              fieldId: field.id,
              fieldName: field.description,
              farmName: operation.farms[0].description,
              operations: [operation]
            };
          }
          return accumulator;
        }, {});
        _sourceFields = Object.values(_sourceFields)
          .sort(({ fieldName: fieldA }, { fieldName: fieldB }) =>
            fieldA.localeCompare(fieldB)
          )
          .sort(
            ({ operations: opsA }, { operations: opsB }) =>
              opsB.length - opsA.length
          );
        setMappedOperations(_sourceFields);
      })
      .catch(catchCancel)
      .catch(err => {
        parseServerError(dispatch)(err);
      })
      .finally(() => setIsLoadingMappedOperations(false));
  };

  const loadUnmappedCropZonesCount = id => {
    setIsLoadingCropZoneCount(true);
    const { promise } = cropZoneCountAPI.fetch(id, undefined, {
      headers: {
        'cwf-context': JSON.stringify({
          organization_id: orgId
        })
      }
    });

    return promise
      .then(({ data }) => {
        amplitude.sendEventToAmplitude(
          amplitude.events.epic.Integrations.JohnDeere
            .numberOfOperationAvailable,
          { operations: data?.unmappedCount }
        );
        setUnmappedCropZoneCount(data);
      })
      .catch(catchCancel)
      .catch(err => {
        parseServerError(dispatch)(err);
      })
      .finally(() => setIsLoadingCropZoneCount(false));
  };
  const loadMatchCropZone = (
    operationId,
    cropzoneId,
    cropseasonId,
    creationDate
  ) => {
    setIsLoadingMatchOperation(true);
    const { promise } = cropZoneMatcherAPI.post({
      operationId,
      cropzoneId,
      cropseasonId,
      creationDate
    });
    return promise
      .then(({ data }) => {
        return data;
      })
      .catch(catchCancel)
      .catch(err => {
        parseServerError(dispatch)(err);
        setMatchOperationError(true);
        return [
          {
            status: 400,
            message: 'Bad Request'
          }
        ];
      })
      .finally(() => setIsLoadingMatchOperation(false));
  };

  return {
    setSince,
    since,
    setUntil,
    until,
    isLoadingFields,
    loadUnmappedCropZonesCount,
    isLoadingCropZoneCount,
    unmappedCropZoneCount,
    unmappedFarms,
    loadUnmappedFarms,
    setUnmappedCropZoneCount,
    isLoadingMatchOperation,
    loadMatchCropZone,
    matchOperationError,
    setMatchOperationError,
    loadMappedOperations,
    isLoadingMappedOperations,
    mappedOperations,
    loadProductsMissingWeight,
    isLoadingWeight,
    productsWithoutWeight
  };
};

export default useJohnDeereData;
