import React from 'react';
import SuccessIcon from 'components/Icons/components/SuccessIcon';
import PropTypes from 'prop-types';

const IntegrationSuccess = ({ title, content, children }) => {
  return (
    <div
      className="max-w-full m-8 rounded-lg bg-white top-0 right-0 left-0 absolute"
      style={{ height: 'calc(100vh - 8rem)' }}
    >
      <div className="flex flex-col items-center justify-center h-full">
        <div className="flex flex-col items-center justify-center bg-white w-2/3 h-half">
          <SuccessIcon height={60} width={60} />
          <div className="text-lg font-bold pt-4">{title}</div>
          <div className="flex flex-col items-center pt-2 pb-8">{content}</div>
          {children}
        </div>
      </div>
    </div>
  );
};

IntegrationSuccess.propTypes = {
  title: PropTypes.string.isRequired,
  content: PropTypes.node.isRequired,
  children: PropTypes.node.isRequired
};

export default IntegrationSuccess;
