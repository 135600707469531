import React from 'react';
import PropTypes from 'prop-types';
import { Toast } from '@agconnections/grow-ui';
import PortalModal from 'components/Modals/PortalModal';

const RemoveProductModal = ({
  open,
  close,
  handleRemove,
  isRemoveLoading,
  setToastHasNotBeenClosed,
  toastHasNotBeenClosed,
  toastRenderContents
}) => {
  const handleCancel = () => {
    setToastHasNotBeenClosed(false);
    close();
  };

  return (
    <PortalModal
      open={open}
      title="Remove Product?"
      close={handleCancel}
      cancelLabel="Cancel"
      confirmLabel="Remove"
      onCancel={handleCancel}
      type="error"
      onConfirm={handleRemove}
      isRemoveLoading={isRemoveLoading}
    >
      <div testId="product-removeModal" className="pr-6">
        <p>
          Are you sure you want to remove this product from your Product List?
        </p>
        <br />
        <p>
          Removing a product will not affect any associated records. Products
          can be re-added to your list by selecting “Add Product”.
        </p>
      </div>

      {toastHasNotBeenClosed && (
        <Toast
          icon="error"
          onClose={() => {
            setToastHasNotBeenClosed(false);
          }}
        >
          {toastRenderContents}
        </Toast>
      )}
    </PortalModal>
  );
};

RemoveProductModal.propTypes = {
  open: PropTypes.oneOfType([PropTypes.func, PropTypes.bool]).isRequired,
  close: PropTypes.oneOfType([PropTypes.func, PropTypes.bool]).isRequired,
  handleRemove: PropTypes.func.isRequired,
  isRemoveLoading: PropTypes.bool.isRequired,
  setToastHasNotBeenClosed: PropTypes.bool.isRequired,
  toastHasNotBeenClosed: PropTypes.bool.isRequired,
  toastRenderContents: PropTypes.string.isRequired
};

export default RemoveProductModal;
