import React from 'react';
import PropTypes from 'prop-types';
import { TextArea } from '@agconnections/grow-ui';

const NotesSection = ({ notes }) => {
  return (
    <div data-testid="notes-section" id="rec-pagebreaker-2">
      <div className="flex justify-between pt-4 mb-8">
        <div className="text-2xl font-bold">Notes</div>
      </div>
      <TextArea
        data-testid="recommendationNotes"
        className="h-64 overscroll-auto"
        resizeDisabled
        disabled
        value={notes ?? ''}
      />
    </div>
  );
};

NotesSection.propTypes = {
  notes: PropTypes.string.isRequired
};

export default NotesSection;
