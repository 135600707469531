import { DEFAULT_ROUND, Maths } from 'helpers/maths';
import Decimal from 'decimal.js';
import { applicationOptions } from './constants';

export const filterItems = (item, value) => {
  return item.name.toLowerCase().includes(value.toLowerCase());
};

export const formatTaskDateFilter = daterange => {
  if (!daterange) return { since: null, until: null };
  return {
    since: daterange[0]?.toISOString().split('T')?.[0],
    until: daterange[1]?.toISOString().split('T')?.[0]
  };
};

export const findTask = (tasks, value) => {
  if (value) {
    const filteredItems = [];
    const foundItem = tasks.filter(item => {
      return filterItems(item, value);
    });
    if (foundItem) {
      foundItem.forEach(fi => {
        filteredItems.push(fi);
      });
    }
    return filteredItems;
  }
  return tasks;
};

export const dateRangeFilter = (tasks, filteredDate) => {
  return tasks.filter(ele => {
    return (
      filteredDate[0].getTime() <= ele.startDateEpoch &&
      filteredDate[1].getTime() >= ele.startDateEpoch
    );
  });
};

export const getApplicationType = selectedApplicationOption =>
  applicationOptions.find(option => option.key === selectedApplicationOption);

export const mapProductsToSave = product => ({
  id: product.productId,
  productId: product.productId,
  productName: product.productName,
  manufacturerName: product.manufacturerName,
  areaUnit: product.areaUnit,
  averagePriceAtTimeOfCreation: product.averagePriceAtTimeOfCreation,
  averagePriceUnitAtTimeOfCreation: product.averagePriceUnitAtTimeOfCreation,
  totalProductValue: Maths.parseFloatRound(
    product.totalProductValue,
    DEFAULT_ROUND,
    Decimal.ROUND_HALF_UP
  ),
  totalProductUnit: product.totalProductUnit,
  ratePerAreaValue: Maths.parseFloatRound(
    product.ratePerAreaValue,
    DEFAULT_ROUND,
    Decimal.ROUND_HALF_UP
  ),
  ratePerAreaUnit: product.ratePerAreaUnit,
  ratePerTankValue: Maths.parseFloatRound(
    product.ratePerTankValue,
    DEFAULT_ROUND,
    Decimal.ROUND_HALF_UP
  ),
  ratePerTankUnit: product.ratePerTankUnit,
  totalCostAtTimeOfCreation: product.totalCostAtTimeOfCreation,
  appliedAreaValue: Maths.parseFloatRound(
    product.appliedAreaValue,
    DEFAULT_ROUND,
    Decimal.ROUND_HALF_UP
  ),
  appliedAreaUnit: product.appliedAreaUnit,
  applicationMethod: product.applicationMethod,
  targetPest: product.targetPest,
  specificCostPerUnit: Maths.parseFloatRound(product.specificCostPerUnit),
  specificCostUnit: product.specificCostUnit,
  shareOwnerInformation: product.shareOwnerInformation,
  productDensity: product.density,
  custom: product.custom,
  customProductValue: Maths.parseFloatRound(
    product.customProductValue,
    DEFAULT_ROUND,
    Decimal.ROUND_HALF_UP
  ),
  customProductUnit: product.customProductUnit,
  hasCost: product.hasCost,
  customRateType: product.customRateType,
  associatedProduct: product.associatedProduct,
  coveragePercent: Maths.divideSafeRound(
    product.coveragePercent,
    100,
    Decimal.ROUND_HALF_UP
  )
});
