export function unmaskPrice(amount) {
  return isNaN(amount)
    ? parseFloat(amount.replace(/[$,]/g, ''))
    : parseFloat(amount);
}

export function unmaskCost(event) {
  const { value } = event.target;
  return parseFloat(value.replace(/[,$]/g, '') || 0);
}

export const filterByDateRange = filteredDate => recommendations => {
  if (filteredDate.length === 0) {
    return recommendations;
  }

  const filterStartTime = filteredDate[0].getTime();
  const filterEndTime =
    filteredDate.length > 1 ? filteredDate[1].getTime() : filterStartTime;

  return recommendations.filter(recommendation => {
    const proposedTime = new Date(recommendation.proposedDate).getTime();
    const expirationTime = new Date(recommendation.expirationDate).getTime();

    return proposedTime <= filterEndTime && expirationTime >= filterStartTime;
  });
};

export const filterByFilterItems = filterItems => recommendations => {
  if (filterItems.length === 0) {
    return recommendations;
  }

  return recommendations.filter(recommendation => {
    return filterItems.every(filter => {
      if (filter.property === 'cropSeasonIds') {
        return recommendation.cropSeasonIds.some(id => id === filter.key);
      }

      return recommendation[filter.property] === filter.key;
    });
  });
};

export const filterBySearchTerm = searchTerm => recommendations => {
  if (!searchTerm) {
    return recommendations;
  }

  const lowerCaseSearchTerm = searchTerm.toLowerCase();

  return recommendations.filter(recommendation => {
    return (recommendation.title ?? '')
      .toLowerCase()
      .includes(lowerCaseSearchTerm);
  });
};

export const filterItems = (item, values) => {
  if (values.length === 1) {
    return item.title.toLowerCase().includes(values[0].toLowerCase());
  }
  return item.title.toLowerCase().includes(values[0].toLowerCase());
};

export const dateRangeFilter = (recommendations, filteredDate) => {
  if (filteredDate.length !== 2) {
    return recommendations;
  }

  const startTime = filteredDate[0].getTime();
  const endTime = filteredDate[1].getTime();

  return recommendations.filter(ele => {
    const proposedTime = new Date(ele.proposedDate).getTime();
    const expirationTime = new Date(ele.expirationDate).getTime();
    return proposedTime <= endTime && expirationTime >= startTime;
  });
};

export const findRecommendation = (recommendations, query) => {
  if (!query) {
    return recommendations;
  }
  const words = query.toLowerCase().match(/[^ ]+/g);
  return recommendations.filter(item => {
    const lTitle = item.title.toLowerCase();
    return words.reduce(
      (isFound, word) => isFound && lTitle.includes(word),
      true
    );
  });
};
