import React from 'react';

const AttachedFileIcon = () => (
  <svg
    width="20"
    height="25"
    viewBox="0 0 20 25"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M12.3334 0.807495H3.00002C1.71669 0.807495 0.666687 1.8575 0.666687 3.14083V21.8075C0.666687 23.0908 1.71669 24.1408 3.00002 24.1408H17C18.2834 24.1408 19.3334 23.0908 19.3334 21.8075V7.80749L12.3334 0.807495ZM17 21.8075H3.00002V3.14083H12.3334V7.80749H17V21.8075ZM10 18.3075C8.71669 18.3075 7.66669 17.2575 7.66669 15.9742V9.55749C7.66669 9.23083 7.92335 8.97416 8.25002 8.97416C8.57669 8.97416 8.83335 9.23083 8.83335 9.55749V15.9742H11.1667V9.55749C11.1667 7.94749 9.86002 6.64083 8.25002 6.64083C6.64002 6.64083 5.33335 7.94749 5.33335 9.55749V15.9742C5.33335 18.5525 7.42169 20.6408 10 20.6408C12.5784 20.6408 14.6667 18.5525 14.6667 15.9742V11.3075H12.3334V15.9742C12.3334 17.2575 11.2834 18.3075 10 18.3075Z"
      fill="#696F88"
    />
  </svg>
);

export default AttachedFileIcon;
