/* eslint-disable no-unused-vars */

export default server => {
  server.get('organizations/:id/fields', (schema, request) => {
    const fields = schema.fields.all();

    return fields;
  });

  server.get('organizations/:id/fields/:fieldId', (schema, request) => {
    const { fieldId } = request.params;

    return schema.fields.find(fieldId) || schema.fields.first();
  });
};
