import React, { createContext, useState, useMemo } from 'react';
import PropTypes from 'prop-types';

export const AgrianContext = createContext();

const Provider = ({ children }) => {
  const [step, setStep] = useState(1);
  const [canGoNext, setCanGoNext] = useState(false);
  const blockStepForward = () => setCanGoNext(false);
  const releaseStepForward = () => setCanGoNext(true);

  const selectPropertiesStep = 4; // in properties selection there have a specific logic in other context to enable next step
  const ignoreCanGoNext = step === selectPropertiesStep;

  const goBack = () => {
    setStep(s => Math.max(0, s - 1));
  };

  const goForward = () => {
    setStep(s => s + 1);
  };

  const store = useMemo(
    () => ({
      state: { step, canGoNext: ignoreCanGoNext || canGoNext },
      gotoStep: setStep,
      goBack,
      blockStepForward,
      releaseStepForward,
      goForward
    }),
    [step, canGoNext, ignoreCanGoNext]
  );

  return (
    <AgrianContext.Provider value={store}>{children}</AgrianContext.Provider>
  );
};

Provider.defaultProps = {
  children: null
};

Provider.propTypes = {
  children: PropTypes.node
};

export default Provider;
