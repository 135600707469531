import React, { useEffect, useState } from 'react';
import { Redirect } from 'react-router-dom';
import { paths } from 'routes/paths';

import { useFormikContext } from 'formik';
import { Button } from '@agconnections/grow-ui';
import AddRoundedIcon from '@material-ui/icons/AddRounded';

import usePlans from 'hooks/usePlans';
import GroupByPlans from './components/GroupByPlans';

const Plans = () => {
  const [redirect, setRedirect] = useState(false);

  const { values } = useFormikContext();

  const { plans, getPlans, isLoading } = usePlans({ size: -1 });
  const [checked, setChecked] = useState([]);
  const [calledPlans, setCalledPlans] = useState([]);

  const handleCheck = checkedIds => {
    setChecked(checkedIds);
  };

  useEffect(() => {
    getPlans({
      size: -1,
      seasonIdsCommaSeparated: values.selectedCropSeasons
        .map(cropSeason => cropSeason?.id)
        .join(',')
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values.selectedCropSeasons]);

  useEffect(() => {
    if (values.selectedCropSeasons.length && calledPlans !== plans) {
      setCalledPlans(plans);
    }
    if (!values.selectedCropSeasons.length) {
      setCalledPlans([]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values.selectedCropSeasons, plans]);

  return (
    <div className="flex flex-col items-center bg-white">
      {isLoading || calledPlans.length > 0 ? (
        <div className="w-full">
          <h1
            className="font-body font-semibold text-xl"
            style={{ color: '#14151C' }}
          >
            Plans
          </h1>
          <GroupByPlans
            mappedData={calledPlans}
            loading={isLoading}
            handleCheck={handleCheck}
            checked={checked}
            setChecked={setChecked}
          />
        </div>
      ) : (
        <div>
          {values.selectedCropSeasons.length ? (
            <div className="h-150px w-336px border border-neutral-30 border-solid rounded">
              <div className="pl-16px pr-16px pt-30px text-sm	text-center">
                You need to create plans for the selected seasons before you can
                generate this report.
                <Button
                  style={{
                    margin: 'auto',
                    marginTop: '12px',
                    marginBottom: '12px'
                  }}
                  id="add-plan-button"
                  type="primary"
                  onClick={event => {
                    event.stopPropagation();
                    setRedirect(true);
                  }}
                  icon={<AddRoundedIcon />}
                >
                  Create Plan
                </Button>
              </div>
            </div>
          ) : (
            ''
          )}
        </div>
      )}
      {redirect && (
        <Redirect
          push
          to={{
            pathname: `${paths.calledPlans}/plan`
          }}
        />
      )}
    </div>
  );
};

Plans.defaultProps = {};

Plans.propTypes = {};

export default Plans;
