import shapeJs from 'shpjs';
import { endsWith } from 'lodash';

// takes an array of files and converts each into geoJSON, returning an array of geoJSON objects
export const processInputShapeFiles = files =>
  Promise.all(files.map(file => file.arrayBuffer()))
    .then(parsedFiles => parsedFiles.map(shapeJs))
    .then(res => Promise.all(res));

// returns a boolean indicating whether the file has one of the extensions in the provided array
export const hasListedFileType = (fileTypeList, file) =>
  !!fileTypeList.find(fileType => endsWith(file.name, fileType));
