import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import ErrorIcon from 'components/Icons/ErrorIcon';

import { Table, Checkbox, Button } from '@agconnections/grow-ui';

import RoundedDisplay from 'components/RoundedDisplay';
import PencilEditIcon from 'components/Icons/components/PencilEditIcon';
import DeleteIcon from 'components/Icons/components/DeleteIcon';
import ReloadIcon from 'components/Icons/components/ReloadIcon';

import {
  fieldToFeature,
  calculateFieldsAreaInAcres
} from '../fieldImporterHelper';

import FieldIcon from '../FieldIcon';

const FieldImporterTable = ({
  fields,
  onSelectedField,
  onDeleteField,
  onEditField,
  onReloadField
}) => {
  const [selectedFieldsMap, setSelectedFieldsMap] = useState(new Map());

  const areAllFieldsSelected = () => {
    let fieldsSelectedCount = 0;
    selectedFieldsMap.forEach(({ selected }) => {
      fieldsSelectedCount += selected;
    });

    const importedFieldsCount = fields.reduce(
      (acc, field) => acc + (field.imported ? 1 : 0),
      0
    );

    return fieldsSelectedCount === fields.length - importedFieldsCount;
  };

  useEffect(() => {
    const newSelectedFieldsMap = new Map();
    fields.forEach(field => {
      if (!field.imported) {
        newSelectedFieldsMap.set(`${field.farm}-${field.name}`, {
          field,
          selected: false
        });
      }
    });

    setSelectedFieldsMap(newSelectedFieldsMap);
    onSelectedField([]);
  }, [fields, onSelectedField]);

  useEffect(() => {
    const selectedFields = [];
    selectedFieldsMap.forEach(({ selected, field }) => {
      if (selected) selectedFields.push(field);
    });

    onSelectedField(selectedFields);
  }, [selectedFieldsMap, onSelectedField]);

  const selectAll = () => {
    const newSelectedFieldsMap = new Map();

    fields.forEach(field => {
      if (!field.imported) {
        newSelectedFieldsMap.set(`${field.farm}-${field.name}`, {
          field,
          selected: !areAllFieldsSelected()
        });
      }
    });

    setSelectedFieldsMap(newSelectedFieldsMap);
  };

  const handleSelectField = (fieldKey, field) => {
    const newSelectedFieldsMap = new Map(selectedFieldsMap);
    newSelectedFieldsMap.set(fieldKey, {
      field,
      selected: !selectedFieldsMap.get(fieldKey).selected
    });
    setSelectedFieldsMap(newSelectedFieldsMap);
  };

  return (
    <>
      <div className="flex justify-end">
        <Button
          onClick={() => selectAll()}
          ariaLabel="select all"
          type="primary"
        >
          {!areAllFieldsSelected() ? 'Select all' : 'Deselect All'}
        </Button>
      </div>
      {fields.filter(field => field.error).length > 0 && (
        <div className="bg-red-100 mt-4 p-3 flex">
          <ErrorIcon />
          <div className="ml-2">
            {fields.filter(field => field.error).length} Field
            {fields.filter(field => field.error).length > 1 ? 's' : ''} failed
            to upload. Contact Syngenta Digital Customer Support for assistance.
          </div>
        </div>
      )}
      <div className="overflow-auto my-8" style={{ maxHeight: '25rem' }}>
        <Table>
          {fields.map((field, index) => {
            const { name, farm, imported, error } = field;
            const key = `${farm}-${name}`;
            const selected = selectedFieldsMap.get(key)
              ? selectedFieldsMap.get(key).selected
              : false;
            const nameColor = 'text-neutral-1000';
            let fontColor = 'text-neutral-1000';
            if (imported) fontColor = 'text-green-600';
            if (error) fontColor = 'text-red-600';
            return (
              <Table.Row key={key}>
                <div className="flex justify-between items-center p-2 text-gray-600 hover:bg-gray-100 focus:outline-none">
                  <div className="flex flex-1 items-center ml-2">
                    {!imported ? (
                      <Checkbox
                        checked={selected}
                        ariaLabel={key}
                        onChange={() => {
                          handleSelectField(key, field);
                        }}
                      />
                    ) : (
                      <Checkbox checked={false} readOnly disabled />
                    )}
                    <div className="ml-2 flex">
                      <FieldIcon
                        id={`svg-field-icon-${index}`}
                        features={[fieldToFeature(field)]}
                        color="#696F88"
                      />
                      <div className="flex flex-col justify-center ml-2">
                        <div className={nameColor}>
                          {name}
                          {imported ? (
                            <span className={fontColor}> (IMPORTED) </span>
                          ) : (
                            ''
                          )}
                        </div>
                        {!error && farm && (
                          <div style={{ color: '#707374' }}>{farm}</div>
                        )}
                        {error && (
                          <div className="mb-2 text-xs text-red-600">
                            {error}
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="flex justify-center">
                    {!error && (
                      <RoundedDisplay units="ac.">
                        {calculateFieldsAreaInAcres([field])}
                      </RoundedDisplay>
                    )}
                    {error && (
                      <>
                        <Button
                          type="text"
                          ariaLabel="edit-button"
                          icon={<PencilEditIcon />}
                          onClick={() => {
                            onEditField(field);
                          }}
                        />
                        <Button
                          type="text"
                          ariaLabel="reload-button"
                          icon={<ReloadIcon />}
                          onClick={() => {
                            onReloadField(field, index);
                          }}
                        />
                        <Button
                          type="text"
                          ariaLabel="delete-button"
                          icon={
                            <DeleteIcon
                              color="#696F88"
                              width="24"
                              height="24"
                              widthBox="5"
                              heightBox="6"
                            />
                          }
                          onClick={() => onDeleteField(index)}
                        />
                      </>
                    )}
                  </div>
                </div>
              </Table.Row>
            );
          })}
        </Table>
      </div>
    </>
  );
};

FieldImporterTable.defaultProps = {
  onEditField: () => {},
  onReloadField: () => {}
};

FieldImporterTable.propTypes = {
  fields: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      farm: PropTypes.string.isRequired,
      imported: PropTypes.bool,
      error: PropTypes.string
    })
  ).isRequired,
  onSelectedField: PropTypes.func.isRequired,
  onDeleteField: PropTypes.func.isRequired,
  onEditField: PropTypes.func,
  onReloadField: PropTypes.func
};

export default FieldImporterTable;
