import React, { useContext } from 'react';
import CardInfo from 'components/CardInfo';
import { Context } from 'components/Store';
import PropTypes from 'prop-types';
import ViewIcon from 'assets/open_in_new_blue.svg';
import { Button } from '@agconnections/grow-ui';
import { TLoad } from 'screens/YieldV2/Loads/constants';
import { numberFormatter } from 'screens/Yield/helpers/formatHelpers';
import { customDateFormat } from 'helpers/dateFormat';
import { destinationLoadTypeIcon } from '../../../helpers/constants';
import LoadCrop from '../../LoadCrop';
import getCropSeasonName from '../../../helpers/getCropSeasonName';
import CropZonesMapView from '../../CropZonesMapView';

const LENGTH_THRESHOLD = 9;

const PropertyAsSourceDetails = ({
  load,
  visible,
  showDrawer,
  cropzoneTotalArea
}) => {
  const [{ isSidebarOpen, cropSeasons }] = useContext(Context);
  const sourcesQnt = load.sources?.length || 0;

  const isLarge = load?.load?.toString()?.length >= LENGTH_THRESHOLD;

  const getMapCardSize = () => {
    if (isSidebarOpen) return isLarge ? 'w-2/6' : 'w-2/5';
    return isLarge ? 'w-2/5' : 'w-3/6';
  };

  return (
    <>
      <section
        data-testid="property-as-source-details"
        className="w-3/6 px-8 py-4 bg-white rounded-lg shadow-lg"
      >
        <CardInfo
          labelColWidth="w-2/5 ms"
          valueColWidth="w-3/5"
          label="Source"
          value={`${sourcesQnt} crop zone${
            sourcesQnt > 1 ? 's' : ''
          } | ${numberFormatter.format(cropzoneTotalArea)} ac`}
        />

        <CardInfo
          label="Destination"
          labelColWidth="w-2/5"
          valueColWidth="w-3/5"
          value={
            !load?.destination?.name ? null : (
              <div className="flex items-center">
                <img
                  className="mr-1"
                  width={20}
                  src={destinationLoadTypeIcon[load.destinationType]}
                  alt={`${load.destinationType} icon`}
                />
                {load.destination.name}
              </div>
            )
          }
        />

        <CardInfo
          labelColWidth="w-2/5"
          valueColWidth="w-3/5"
          label="Date & Time"
          value={
            load?.date
              ? customDateFormat(load.date, 'MMM d, yyyy hh:mm a')
              : null
          }
        />

        <CardInfo
          labelColWidth="w-2/5"
          valueColWidth="w-3/5"
          label="Crop Season"
          value={getCropSeasonName(cropSeasons, load.cropSeasonId)}
        />

        <CardInfo
          labelColWidth="w-2/5"
          valueColWidth="w-3/5"
          label="Crop"
          value={<LoadCrop load={load} />}
        />
      </section>

      <section
        className={`${getMapCardSize()} rounded-lg shadow-lg overflow-hidden relative`}
      >
        <Button
          className="top-60 left-60 "
          type="outline"
          style={{
            position: 'absolute',
            top: '12px',
            right: '12px',
            zIndex: `${!visible ? 5000 : 0}`
          }}
          onClick={() => {
            showDrawer('right');
          }}
        >
          <img className="mr-1" src={ViewIcon} alt="view icon" />
          <div className="text-info-dark-blue"> View Details</div>
        </Button>
        <CropZonesMapView
          key={`resize-${isSidebarOpen}`}
          cropzones={load.sources}
        />
      </section>
    </>
  );
};

PropertyAsSourceDetails.propTypes = {
  cropzoneTotalArea: PropTypes.number.isRequired,
  load: TLoad.isRequired,
  visible: PropTypes.bool.isRequired,
  showDrawer: PropTypes.bool.isRequired
};

export default PropertyAsSourceDetails;
