import React from 'react';
import PropTypes from 'prop-types';

const Compass = ({ windSpeed, windDirection, speedUnit }) => {
  return (
    <div className="mt-4 flex justify-center relative">
      <svg
        width="256"
        height="256"
        viewBox="0 0 256 256"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        style={{ transform: `rotate(${windDirection}deg)` }}
        className="absolute"
      >
        <g clipPath="url(#clipA)">
          <g clipPath="url(#clipB)">
            <path
              d="M127.5 39.9999L128.5 39.9999L138 63.9999H118L122.75 51.9999L127.5 39.9999Z"
              fill="#0080FF"
            />
          </g>
        </g>
        <defs>
          <clipPath id="clipA">
            <rect width="256" height="256" fill="none" />
          </clipPath>
          <clipPath id="clipB">
            <rect
              width="24"
              height="24"
              fill="white"
              transform="translate(116 39.9999)"
            />
          </clipPath>
        </defs>
      </svg>
      <svg
        width="256"
        height="256"
        viewBox="0 0 256 256"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M126.5 130.598C127.935 131.426 129.77 130.935 130.598 129.5C131.427 128.065 130.935 126.23 129.5 125.402C128.065 124.573 126.23 125.065 125.402 126.5C124.574 127.935 125.065 129.77 126.5 130.598ZM126 131.464C127.913 132.569 130.36 131.913 131.464 130C132.569 128.087 131.913 125.64 130 124.536C128.087 123.431 125.64 124.087 124.536 126C123.431 127.913 124.087 130.36 126 131.464Z"
          fill="#999999"
        />
        <circle cx="128" cy="128" r="56.5" stroke="white" strokeWidth="7" />
        <circle cx="128" cy="128" r="59.5" stroke="#E5E5E5" />
        <g clipPath="url(#clip0)">
          <path d="M128 128V216" stroke="#E5E5E5" />
          <path d="M128 40V128" stroke="#E5E5E5" />
          <path d="M128 128L40 128" stroke="#E5E5E5" />
          <path d="M216 128L128 128" stroke="#E5E5E5" />
          <circle cx="128" cy="128" r="96" stroke="white" strokeWidth="16" />
          <circle cx="128" cy="128" r="88.5" stroke="#E5E5E5" />
          <circle cx="128" cy="128" r="104.5" stroke="#E5E5E5" />
          <g clipPath="url(#clip1)">
            <path d="M128 24L128 32" stroke="#999999" />
            <path d="M128 224L128 232" stroke="#999999" />
            <path d="M24 128L32 128" stroke="#999999" />
            <path d="M224 128L232 128" stroke="#999999" />
            <g clipPath="url(#clip2)">
              <path d="M118.936 24.3958L119.633 32.3653" stroke="#999999" />
              <path d="M136.367 223.635L137.064 231.604" stroke="#999999" />
              <path d="M24.3957 137.064L32.3653 136.367" stroke="#999999" />
              <path d="M223.635 119.633L231.604 118.936" stroke="#999999" />
            </g>
            <g clipPath="url(#clip3)">
              <path d="M109.941 25.58L111.33 33.4584" stroke="#999999" />
              <path d="M144.67 222.542L146.059 230.42" stroke="#999999" />
              <path d="M25.5801 146.059L33.4585 144.67" stroke="#999999" />
              <path d="M222.542 111.33L230.42 109.941" stroke="#999999" />
            </g>
            <g clipPath="url(#clip4)">
              <path d="M101.083 27.5437L103.153 35.2711" stroke="#999999" />
              <path d="M152.847 220.729L154.917 228.456" stroke="#999999" />
              <path d="M27.5437 154.917L35.2711 152.847" stroke="#999999" />
              <path d="M220.729 103.153L228.456 101.083" stroke="#999999" />
            </g>
            <g clipPath="url(#clip5)">
              <path d="M92.4297 30.272L95.1659 37.7895" stroke="#999999" />
              <path d="M160.834 218.21L163.57 225.728" stroke="#999999" />
              <path d="M30.2718 163.57L37.7893 160.834" stroke="#999999" />
              <path d="M218.21 95.1661L225.728 92.4299" stroke="#999999" />
            </g>
            <g clipPath="url(#clip6)">
              <path d="M84.0475 33.744L87.4285 40.9945" stroke="#999999" />
              <path d="M168.571 215.006L171.952 222.256" stroke="#999999" />
              <path d="M33.7438 171.952L40.9943 168.571" stroke="#999999" />
              <path d="M215.005 87.4286L222.256 84.0477" stroke="#999999" />
            </g>
            <g clipPath="url(#clip7)">
              <path d="M75.9998 37.9333L79.9998 44.8616" stroke="#999999" />
              <path d="M176 211.138L180 218.067" stroke="#999999" />
              <path d="M37.9332 180L44.8614 176" stroke="#999999" />
              <path d="M211.138 80L218.066 76" stroke="#999999" />
            </g>
            <g clipPath="url(#clip8)">
              <path d="M68.3478 42.8082L72.9364 49.3614" stroke="#999999" />
              <path d="M183.063 206.639L187.652 213.192" stroke="#999999" />
              <path d="M42.8079 187.652L49.3611 183.063" stroke="#999999" />
              <path d="M206.638 72.9366L213.192 68.348" stroke="#999999" />
            </g>
            <g clipPath="url(#clip9)">
              <path d="M61.1499 48.3314L66.2922 54.4598" stroke="#999999" />
              <path d="M189.707 201.54L194.85 207.669" stroke="#999999" />
              <path d="M48.3311 194.85L54.4595 189.708" stroke="#999999" />
              <path d="M201.54 66.2924L207.668 61.1501" stroke="#999999" />
            </g>
            <g clipPath="url(#clip10)">
              <path d="M54.4606 54.4609L60.1175 60.1178" stroke="#999999" />
              <path d="M195.882 195.882L201.539 201.539" stroke="#999999" />
              <path d="M54.4606 201.539L60.1175 195.882" stroke="#999999" />
              <path d="M195.882 60.1177L201.539 54.4609" stroke="#999999" />
            </g>
            <g clipPath="url(#clip11)">
              <path d="M48.3312 61.15L54.4596 66.2923" stroke="#999999" />
              <path d="M201.54 189.708L207.668 194.85" stroke="#999999" />
              <path d="M61.1499 207.669L66.2922 201.54" stroke="#999999" />
              <path d="M189.707 54.4597L194.85 48.3314" stroke="#999999" />
            </g>
            <g clipPath="url(#clip12)">
              <path d="M42.8079 68.348L49.3611 72.9366" stroke="#999999" />
              <path d="M206.638 183.063L213.192 187.652" stroke="#999999" />
              <path d="M68.3478 213.192L72.9364 206.639" stroke="#999999" />
              <path d="M183.063 49.3613L187.652 42.8081" stroke="#999999" />
            </g>
            <g clipPath="url(#clip13)">
              <path d="M37.9331 76L44.8613 80" stroke="#999999" />
              <path d="M211.138 176L218.066 180" stroke="#999999" />
              <path d="M75.9998 218.067L79.9998 211.138" stroke="#999999" />
              <path d="M176 44.8616L180 37.9334" stroke="#999999" />
            </g>
            <g clipPath="url(#clip14)">
              <path d="M33.7437 84.0477L40.9942 87.4286" stroke="#999999" />
              <path d="M215.005 168.571L222.256 171.952" stroke="#999999" />
              <path d="M84.0475 222.256L87.4284 215.005" stroke="#999999" />
              <path d="M168.571 40.9944L171.952 33.7439" stroke="#999999" />
            </g>
            <g clipPath="url(#clip15)">
              <path d="M30.2718 92.4299L37.7893 95.166" stroke="#999999" />
              <path d="M218.21 160.834L225.728 163.57" stroke="#999999" />
              <path d="M92.4297 225.728L95.1659 218.21" stroke="#999999" />
              <path d="M160.834 37.7894L163.57 30.2719" stroke="#999999" />
            </g>
            <g clipPath="url(#clip16)">
              <path d="M27.5436 101.083L35.271 103.153" stroke="#999999" />
              <path d="M220.729 152.847L228.456 154.917" stroke="#999999" />
              <path d="M101.083 228.456L103.153 220.729" stroke="#999999" />
              <path d="M152.847 35.2711L154.917 27.5437" stroke="#999999" />
            </g>
            <g clipPath="url(#clip17)">
              <path d="M25.5798 109.941L33.4583 111.33" stroke="#999999" />
              <path d="M222.541 144.67L230.42 146.059" stroke="#999999" />
              <path d="M109.94 230.42L111.33 222.542" stroke="#999999" />
              <path d="M144.67 33.4585L146.059 25.58" stroke="#999999" />
            </g>
            <g clipPath="url(#clip18)">
              <path d="M24.3957 118.936L32.3653 119.633" stroke="#999999" />
              <path d="M223.635 136.367L231.604 137.064" stroke="#999999" />
              <path d="M118.936 231.604L119.633 223.635" stroke="#999999" />
              <path d="M136.367 32.3652L137.064 24.3957" stroke="#999999" />
            </g>
          </g>
          <g clipPath="url(#clip19)">
            <path d="M54.4609 54.4609L65.7746 65.7746" stroke="#333333" />
            <path d="M190.225 190.225L201.539 201.539" stroke="#333333" />
            <path d="M54.4609 201.539L65.7746 190.225" stroke="#333333" />
            <path d="M190.225 65.7746L201.539 54.4609" stroke="#333333" />
          </g>
          <path d="M128 24L128 40" stroke="#333333" />
          <path d="M128 216L128 232" stroke="#333333" />
          <path d="M24 128L40 128" stroke="#333333" />
          <path d="M216 128L232 128" stroke="#333333" />
          <path
            d="M44.2666 50.9756H43.1299L38.4482 43.7861H38.4014C38.4639 44.6299 38.4951 45.4033 38.4951 46.1064V50.9756H37.5752V42.4092H38.7002L43.3701 49.5693H43.417C43.4092 49.4639 43.3916 49.126 43.3643 48.5557C43.3369 47.9814 43.3271 47.5713 43.335 47.3252V42.4092H44.2666V50.9756ZM54.0986 50.9756H53.1143L51.3857 45.2393C51.3037 44.9854 51.2119 44.665 51.1104 44.2783C51.0088 43.8916 50.9561 43.6592 50.9521 43.5811C50.8662 44.0967 50.7295 44.6611 50.542 45.2744L48.8662 50.9756H47.8818L45.6025 42.4092H46.6572L48.0107 47.7002C48.1982 48.4424 48.335 49.1143 48.4209 49.7158C48.5264 49.001 48.6826 48.3018 48.8896 47.6182L50.4248 42.4092H51.4795L53.0908 47.665C53.2783 48.2705 53.4365 48.9541 53.5654 49.7158C53.6396 49.1611 53.7803 48.4854 53.9873 47.6885L55.335 42.4092H56.3896L54.0986 50.9756Z"
            fill="#333333"
          />
          <path
            d="M209.403 212.745C209.403 213.499 209.13 214.087 208.583 214.509C208.036 214.931 207.294 215.142 206.356 215.142C205.341 215.142 204.56 215.011 204.013 214.749V213.788C204.364 213.936 204.747 214.054 205.161 214.14C205.575 214.226 205.985 214.268 206.392 214.268C207.056 214.268 207.556 214.143 207.892 213.893C208.228 213.64 208.395 213.288 208.395 212.839C208.395 212.542 208.335 212.3 208.214 212.112C208.097 211.921 207.897 211.745 207.616 211.585C207.339 211.425 206.915 211.243 206.345 211.04C205.548 210.755 204.978 210.417 204.634 210.026C204.294 209.636 204.124 209.126 204.124 208.497C204.124 207.837 204.372 207.311 204.868 206.921C205.364 206.53 206.021 206.335 206.837 206.335C207.688 206.335 208.472 206.491 209.187 206.804L208.876 207.671C208.169 207.374 207.481 207.226 206.813 207.226C206.286 207.226 205.874 207.339 205.577 207.565C205.28 207.792 205.132 208.106 205.132 208.509C205.132 208.806 205.187 209.05 205.296 209.241C205.405 209.429 205.589 209.602 205.847 209.763C206.108 209.919 206.507 210.093 207.042 210.284C207.94 210.604 208.558 210.948 208.894 211.315C209.233 211.683 209.403 212.159 209.403 212.745ZM215.931 215.024H211.155V206.458H215.931V207.343H212.151V210.102H215.702V210.981H212.151V214.134H215.931V215.024Z"
            fill="#333333"
          />
          <path
            d="M210.03 50.9756H208.894L204.212 43.7861H204.165C204.228 44.6299 204.259 45.4033 204.259 46.1064V50.9756H203.339V42.4092H204.464L209.134 49.5693H209.181C209.173 49.4639 209.155 49.126 209.128 48.5557C209.101 47.9814 209.091 47.5713 209.099 47.3252V42.4092H210.03V50.9756ZM217.161 50.9756H212.386V42.4092H217.161V43.2939H213.382V46.0537H216.933V46.9326H213.382V50.085H217.161V50.9756Z"
            fill="#333333"
          />
          <path
            d="M43.1396 212.745C43.1396 213.499 42.8662 214.087 42.3193 214.509C41.7725 214.931 41.0303 215.142 40.0928 215.142C39.0771 215.142 38.2959 215.011 37.749 214.749V213.788C38.1006 213.936 38.4834 214.054 38.8975 214.14C39.3115 214.226 39.7217 214.268 40.1279 214.268C40.792 214.268 41.292 214.143 41.6279 213.893C41.9639 213.64 42.1318 213.288 42.1318 212.839C42.1318 212.542 42.0713 212.3 41.9502 212.112C41.833 211.921 41.6338 211.745 41.3525 211.585C41.0752 211.425 40.6514 211.243 40.081 211.04C39.2842 210.755 38.7139 210.417 38.3701 210.026C38.0303 209.636 37.8603 209.126 37.8603 208.497C37.8603 207.837 38.1084 207.311 38.6045 206.921C39.1006 206.53 39.7568 206.335 40.5732 206.335C41.4248 206.335 42.208 206.491 42.9228 206.804L42.6123 207.671C41.9053 207.374 41.2178 207.226 40.5498 207.226C40.0225 207.226 39.6103 207.339 39.3135 207.565C39.0166 207.792 38.8682 208.106 38.8682 208.509C38.8682 208.806 38.9228 209.05 39.0322 209.241C39.1416 209.429 39.3252 209.602 39.583 209.763C39.8447 209.919 40.2432 210.093 40.7783 210.284C41.6767 210.604 42.2939 210.948 42.6299 211.315C42.9697 211.683 43.1396 212.159 43.1396 212.745ZM52.3682 215.024H51.3838L49.6553 209.288C49.5732 209.034 49.4814 208.714 49.3799 208.327C49.2783 207.94 49.2256 207.708 49.2217 207.63C49.1357 208.145 48.999 208.71 48.8115 209.323L47.1357 215.024H46.1514L43.8721 206.458H44.9267L46.2803 211.749C46.4678 212.491 46.6045 213.163 46.6904 213.765C46.7959 213.05 46.9521 212.351 47.1592 211.667L48.6943 206.458H49.749L51.3603 211.714C51.5478 212.319 51.706 213.003 51.835 213.765C51.9092 213.21 52.0498 212.534 52.2568 211.737L53.6045 206.458H54.6592L52.3682 215.024Z"
            fill="#333333"
          />
          <path
            d="M131.797 17H129.488L125.762 10.5195H125.709C125.783 11.6641 125.82 12.4805 125.82 12.9688V17H124.197V8.43359H126.488L130.209 14.8496H130.25C130.191 13.7363 130.162 12.9492 130.162 12.4883V8.43359H131.797V17Z"
            fill="#333333"
          />
          <path
            d="M131.33 246.621C131.33 247.395 131.051 248.004 130.492 248.449C129.938 248.895 129.164 249.117 128.172 249.117C127.258 249.117 126.449 248.945 125.746 248.602V246.914C126.324 247.172 126.812 247.354 127.211 247.459C127.613 247.564 127.98 247.617 128.312 247.617C128.711 247.617 129.016 247.541 129.227 247.389C129.441 247.236 129.549 247.01 129.549 246.709C129.549 246.541 129.502 246.393 129.408 246.264C129.314 246.131 129.176 246.004 128.992 245.883C128.812 245.762 128.443 245.568 127.885 245.303C127.361 245.057 126.969 244.82 126.707 244.594C126.445 244.367 126.236 244.104 126.08 243.803C125.924 243.502 125.846 243.15 125.846 242.748C125.846 241.99 126.102 241.395 126.613 240.961C127.129 240.527 127.84 240.311 128.746 240.311C129.191 240.311 129.615 240.363 130.018 240.469C130.424 240.574 130.848 240.723 131.289 240.914L130.703 242.326C130.246 242.139 129.867 242.008 129.566 241.934C129.27 241.859 128.977 241.822 128.688 241.822C128.344 241.822 128.08 241.902 127.896 242.062C127.713 242.223 127.621 242.432 127.621 242.689C127.621 242.85 127.658 242.99 127.732 243.111C127.807 243.229 127.924 243.344 128.084 243.457C128.248 243.566 128.633 243.766 129.238 244.055C130.039 244.438 130.588 244.822 130.885 245.209C131.182 245.592 131.33 246.062 131.33 246.621Z"
            fill="#333333"
          />
          <path
            d="M247.148 133H242.215V124.434H247.148V125.922H244.031V127.803H246.932V129.291H244.031V131.5H247.148V133Z"
            fill="#333333"
          />
          <path
            d="M15.6211 133H13.5527L12.3926 128.5C12.3496 128.34 12.2754 128.01 12.1699 127.51C12.0684 127.006 12.0098 126.668 11.9941 126.496C11.9707 126.707 11.9121 127.047 11.8184 127.516C11.7246 127.98 11.6523 128.312 11.6016 128.512L10.4473 133H8.38477L6.19922 124.434H7.98633L9.08203 129.109C9.27344 129.973 9.41211 130.721 9.49805 131.354C9.52148 131.131 9.57422 130.787 9.65625 130.322C9.74219 129.854 9.82227 129.49 9.89648 129.232L11.1445 124.434H12.8613L14.1094 129.232C14.1641 129.447 14.2324 129.775 14.3145 130.217C14.3965 130.658 14.459 131.037 14.502 131.354C14.541 131.049 14.6035 130.67 14.6895 130.217C14.7754 129.76 14.8535 129.391 14.9238 129.109L16.0137 124.434H17.8008L15.6211 133Z"
            fill="#333333"
          />
          <path
            d="M127.792 30H128.208L132.167 40H123.833L125.813 35L127.792 30Z"
            fill="#333333"
          />
        </g>
        <circle cx="128" cy="128" r="59.5" fill="white" stroke="#D7DAE0" />
        <text
          x="128"
          y="120"
          dominantBaseline="middle"
          textAnchor="middle"
          fill="#2F3031"
          fontSize="2.125em"
          fontWeight="bolder"
        >
          {windSpeed}
        </text>
        <text
          x="128"
          y="146"
          dominantBaseline="middle"
          textAnchor="middle"
          fill="#2F3031"
          fontSize="1em"
        >
          {speedUnit}
        </text>
        <defs>
          <clipPath id="clip0">
            <rect width="256" height="256" fill="white" />
          </clipPath>
          <clipPath id="clip1">
            <rect
              width="208"
              height="208"
              fill="white"
              transform="translate(24 24)"
            />
          </clipPath>
          <clipPath id="clip2">
            <rect
              width="208"
              height="208"
              fill="white"
              transform="translate(15.3316 33.46) rotate(-5)"
            />
          </clipPath>
          <clipPath id="clip3">
            <rect
              width="208"
              height="208"
              fill="white"
              transform="translate(7.52068 43.6394) rotate(-10)"
            />
          </clipPath>
          <clipPath id="clip4">
            <rect
              width="208"
              height="208"
              fill="white"
              transform="translate(0.62648 54.4609) rotate(-15)"
            />
          </clipPath>
          <clipPath id="clip5">
            <rect
              width="208"
              height="208"
              fill="white"
              transform="translate(-5.29831 65.842) rotate(-20)"
            />
          </clipPath>
          <clipPath id="clip6">
            <rect
              width="208"
              height="208"
              fill="white"
              transform="translate(-10.2085 77.6963) rotate(-25)"
            />
          </clipPath>
          <clipPath id="clip7">
            <rect
              width="208"
              height="208"
              fill="white"
              transform="translate(-14.0668 89.9333) rotate(-30)"
            />
          </clipPath>
          <clipPath id="clip8">
            <rect
              width="208"
              height="208"
              fill="white"
              transform="translate(-16.844 102.46) rotate(-35)"
            />
          </clipPath>
          <clipPath id="clip9">
            <rect
              width="208"
              height="208"
              fill="white"
              transform="translate(-18.5188 115.181) rotate(-40)"
            />
          </clipPath>
          <clipPath id="clip10">
            <rect
              width="208"
              height="208"
              fill="white"
              transform="translate(-19.0785 128) rotate(-45)"
            />
          </clipPath>
          <clipPath id="clip11">
            <rect
              width="208"
              height="208"
              fill="white"
              transform="translate(-18.5187 140.819) rotate(-50)"
            />
          </clipPath>
          <clipPath id="clip12">
            <rect
              width="208"
              height="208"
              fill="white"
              transform="translate(-16.8441 153.54) rotate(-55)"
            />
          </clipPath>
          <clipPath id="clip13">
            <rect
              width="208"
              height="208"
              fill="white"
              transform="translate(-14.0669 166.067) rotate(-60)"
            />
          </clipPath>
          <clipPath id="clip14">
            <rect
              width="208"
              height="208"
              fill="white"
              transform="translate(-10.2086 178.304) rotate(-65)"
            />
          </clipPath>
          <clipPath id="clip15">
            <rect
              width="208"
              height="208"
              fill="white"
              transform="translate(-5.29829 190.158) rotate(-70)"
            />
          </clipPath>
          <clipPath id="clip16">
            <rect
              width="208"
              height="208"
              fill="white"
              transform="translate(0.626419 201.539) rotate(-75)"
            />
          </clipPath>
          <clipPath id="clip17">
            <rect
              width="208"
              height="208"
              fill="white"
              transform="translate(7.52039 212.361) rotate(-80)"
            />
          </clipPath>
          <clipPath id="clip18">
            <rect
              width="208"
              height="208"
              fill="white"
              transform="translate(15.3315 222.54) rotate(-85)"
            />
          </clipPath>
          <clipPath id="clip19">
            <rect
              width="208"
              height="208"
              fill="white"
              transform="translate(-19.0782 128) rotate(-45)"
            />
          </clipPath>
        </defs>
      </svg>
    </div>
  );
};

Compass.defaultProps = {
  windSpeed: 0,
  windDirection: 0,
  speedUnit: 'mph'
};
Compass.propTypes = {
  windSpeed: PropTypes.number,
  windDirection: PropTypes.number,
  speedUnit: PropTypes.string
};

export default Compass;
