import React from 'react';
import PropTypes from 'prop-types';

import { Popup } from '@agconnections/grow-ui';

import FarmIcon from '../../../../../components/icons/FarmIcon';

const Properties = ({ propertiesNames, propertiesCount, propertiesArea }) => (
  <Popup
    enableHover
    position="top"
    align="center"
    hasArrow
    bgColor="#232630"
    trigger={
      <div
        className="flex flex-col w-full justify-start"
        data-testid="hover-properties-name"
      >
        <div className="text-sm flex align-center">
          <FarmIcon />
          <span className="ml-1">
            {`${propertiesCount} ${
              propertiesCount > 1 ? 'properties' : 'property'
            }`}
          </span>
        </div>
        <span className="text-xs text-neutral-60 text-left">
          {propertiesArea?.toFixed(2)} ac
        </span>
      </div>
    }
  >
    <div className="gr-w-40 text-xs text-white">
      {propertiesNames.join(', ')}
    </div>
  </Popup>
);

Properties.defaultProps = {
  propertiesNames: [],
  propertiesCount: null,
  propertiesArea: null
};

Properties.propTypes = {
  propertiesNames: PropTypes.arrayOf(PropTypes.string),
  propertiesCount: PropTypes.number,
  propertiesArea: PropTypes.number
};

export default Properties;
