import tableSort from 'helpers/tableSort';

export const getFilteredAndSortedTasks = (
  mappedTasks,
  sortBy,
  sortDir,
  cropSeasons,
  dateRange
) => {
  const getDateWithoutTime = date => {
    const d = new Date(date);
    d.setHours(0, 0, 0, 0);
    return d;
  };
  const filtered = mappedTasks.filter(row => {
    if (!row.completedDate) {
      return false;
    }
    const taskDate = getDateWithoutTime(row.completedDate);
    const cropFilter =
      !cropSeasons.length ||
      row.cropSeasonIds?.some(season => cropSeasons.includes(season));
    let dateFilter = true;
    if (dateRange?.[0] && dateRange[1]) {
      const startDate = getDateWithoutTime(dateRange[0]);
      const endDate = getDateWithoutTime(dateRange[1]);
      dateFilter = taskDate >= startDate && taskDate <= endDate;
    }
    return cropFilter && dateFilter;
  });

  return [...tableSort(filtered, sortBy, sortDir)];
};

export default getFilteredAndSortedTasks;
