import faker from 'faker';
import { Factory } from 'miragejs';

import globalSchema from '../utils/globalSchema';
import {
  counties,
  hydrologyGroups,
  irrigationSystems,
  meridianEnum,
  pumpEnergySources,
  soilTextures,
  states,
  tileDrainages,
  waterSources
} from './helpers/constants';

const Field = {
  name() {
    return `${faker.address.city()} Field`;
  },
  state() {
    return faker.random.arrayElement(states);
  },
  county() {
    return faker.random.arrayElement(
      counties[states.findIndex(val => this.state() === val)]
    );
  },
  notes() {
    return faker.lorem.paragraph();
  },
  propertyOwner() {
    return 'N/A';
  },
  legalInfo() {
    return Array.from({ length: faker.random.number({ max: 8 }) }, () => ({
      name: faker.company.companyName(),
      value: faker.random.boolean() ? '' : faker.random.uuid()
    }));
  },
  calculatedArea: faker.random.number({ min: 20000, max: 100000 }),
  reportedArea() {
    return faker.random.boolean()
      ? {}
      : {
          unit: faker.random.arrayElement(['acre', 'hectare']),
          value: faker.random.number({ min: 20000, max: 100000 })
        };
  },
  centroid() {
    return null;
  },
  cropHistory() {
    const crop = ['Alfalfa', 'Edamame', 'Melon'];
    const tillage = ['reduced', 'intensive', 'conventional', 'no-till'];
    const area = faker.finance.amount(1000, 5000);
    const num = faker.random.number(5);
    const startYear = new Date().getFullYear() - num;
    return Array.from({ length: num }, (el, idx) => ({
      _id: idx,
      year: startYear + idx,
      tillage: faker.random.arrayElement(tillage),
      area,
      crop: faker.random.arrayElement(crop)
    }));
  },
  fsa() {
    return {
      fsaFarmNum: faker.random.number().toString(),
      fsaArea: faker.random.number(),
      fsaTractNum: faker.random.number().toString(),
      fsaRentType: faker.random.arrayElement([
        'cashRent',
        'cropShare',
        'other'
      ]),
      fsaFieldNum: faker.random.number().toString(),
      fsaLandOwnerPercent: faker.random.number({
        min: 0,
        max: 1,
        precision: 0.01
      }),
      fsaCluId: faker.random.number().toString(),
      tenantPercent: faker.random.number({ max: 1, precision: 0.01 })
    };
  },
  landSurvey() {
    return {
      townshipVal: faker.lorem.word(),
      townshipCardinal: faker.random.arrayElement(['', 'N', 'S']),
      range: faker.lorem.word(),
      rangeCardinal: faker.random.arrayElement(['', 'E', 'W']),
      section: faker.lorem.word(),
      meridian: faker.random.arrayElement(meridianEnum),
      taxId: faker.random.number(),
      parcelNum: faker.random.number().toString(),
      shortDescription: faker.lorem.sentence(),
      legalDescription: faker.lorem.sentences()
    };
  },
  landInfo() {
    return {
      slope: faker.random.number({ max: 100 }),
      soilTexture: faker.random.arrayElement(soilTextures),
      slopeLength: faker.random.number(),
      hydrologyGroup: faker.random.arrayElement(hydrologyGroups)
    };
  },
  water() {
    return {
      irrigated: faker.random.boolean(),
      pumpPressure: faker.random.number(),
      waterSource: faker.random.arrayElement(waterSources),
      pumpLift: faker.random.number({ max: 100 }),
      waterOrigin: faker.random.arrayElement(['aquifer', 'watershed']),
      pumpEnergySource: faker.random.arrayElement(pumpEnergySources),
      irrigationSystem: faker.random.arrayElement(irrigationSystems),
      usePolyacrylamide: faker.random.boolean(),
      primaryWaterSource: ''
    };
  },
  tileDrainage() {
    return faker.random.arrayElement(tileDrainages);
  },
  regulatoryPermit() {
    return {
      permitId: faker.random.number().toString(),
      siteId: faker.random.number().toString()
    };
  },
  areas: [],
  geometry(i) {
    if (i === 0) {
      return {
        coordinates: [
          [
            [-90.76199131245554, 36.025170241307976],
            [-90.76210284815565, 36.0215980237914],
            [-90.75333614209377, 36.0215980237914],
            [-90.75331383495374, 36.02455684173634],
            [-90.75425073483788, 36.024574882968764],
            [-90.75478610620095, 36.02502591242671],
            [-90.75487533476105, 36.025170241307976],
            [-90.76199131245554, 36.025170241307976]
          ]
        ],
        type: 'Polygon'
      };
    }
    return {
      coordinates: [
        [
          [-90.78879187678417, 36.062604240615585],
          [-90.78963484462349, 36.062646830198304],
          [-90.79219009088709, 36.06128395211192],
          [-90.79358625637123, 36.06049602744949],
          [-90.79503510734551, 36.0603469597344],
          [-90.79732692615912, 36.06028307348382],
          [-90.79753766811895, 36.060048823456015],
          [-90.79761669635398, 36.054937740165045],
          [-90.79753766811895, 36.0547886619202],
          [-90.79703715596428, 36.05468217728652],
          [-90.78881821952906, 36.05476736500508],
          [-90.78879187678417, 36.062604240615585]
        ]
      ],
      type: 'Polygon'
    };
  },
  ...globalSchema
};
export default Factory.extend(Field);
