import React from 'react';
import PropTypes from 'prop-types';

import ZoomButtons from '../ZoomButtons';

const ControlButtons = ({ setZoom }) => {
  return (
    <div className="absolute flex flex-col z-10 right-0 bottom-0">
      <ZoomButtons setZoom={setZoom} />
    </div>
  );
};

ControlButtons.propTypes = {
  setZoom: PropTypes.func.isRequired
};

export default ControlButtons;
