import { orderBy } from 'lodash';

export const ApiEquipmentModel = () => {
  return {
    id: '',
    name: '',
    make: '',
    model: '',
    year: 0,
    type: '',
    notes: '',
    licenseNumber: '',
    serialNumber: '',
    unitId: '',
    services: [
      {
        productId: '',
        productName: '',
        operationCost: null
      }
    ],
    valuations: [
      {
        type: '',
        amount: null,
        dateEpoch: 0,
        date: ''
      }
    ],
    statusHistory: [
      {
        id: '',
        status: '',
        date: '',
        dateEpoch: 0,
        current: false,
        active: false
      }
    ]
  };
};

export const FormModel = () => {
  return {
    id: '',
    currentStatus: '',
    currentStatusDate: '',
    equipmentType: '',
    licenseNumber: '',
    make: '',
    manufacturedYear: '',
    model: '',
    modelYear: '',
    name: '',
    purchasePrice: '',
    purchasePriceDate: '',
    currentValue: '',
    currentValueDate: '',
    notes: '',
    serialNumber: '',
    unitID: '',
    services: [
      {
        productId: '',
        operationCost: '',
        productName: ''
      }
    ],
    history: {
      purchasePrice: null,
      purchaseDate: '',
      currentValue: null,
      currentValueDate: ''
    }
  };
};

const scrubCurrencyFields = amount => {
  return isNaN(amount) ? Number(amount.replace(/[$,]/g, '')) : Number(amount);
};

const setApiValuation = (valueType, apiAmount, date) => {
  return {
    type: valueType,
    amount: scrubCurrencyFields(apiAmount),
    dateEpoch: new Date(date ?? '').valueOf()
  };
};

export const mapFormToApi = formValues => {
  const apiValues = ApiEquipmentModel();
  const apiValuations = [];
  const services = formValues?.services
    ? formValues?.services.map(data => {
        return {
          productId: data.productId,
          productName: data.productName,
          operationCost: scrubCurrencyFields(data.operationCost)
        };
      })
    : [];

  if (formValues?.purchasePrice) {
    apiValuations.push(
      setApiValuation(
        'purchase',
        formValues?.purchasePrice,
        formValues?.purchasePriceDate
      )
    );
  }

  if (formValues?.currentValue) {
    apiValuations.push(
      setApiValuation(
        'current',
        formValues?.currentValue,
        formValues?.currentValueDate
      )
    );
  }

  apiValues.id = formValues.id;
  apiValues.name = formValues?.name;
  apiValues.type = formValues?.equipmentType;
  apiValues.model = formValues?.model ?? apiValues.model;
  apiValues.year = formValues?.modelYear ?? apiValues.year;
  apiValues.make = formValues?.make ?? apiValues.make;
  apiValues.notes = formValues?.notes ?? apiValues.notes;
  apiValues.licenseNumber =
    formValues?.licenseNumber ?? apiValues.licenseNumber;
  apiValues.serialNumber = formValues?.serialNumber ?? apiValues.serialNumber;
  apiValues.unitId = formValues?.unitID ?? apiValues.unitId;
  apiValues.statusHistory = formValues?.currentStatus
    ? [
        {
          status: formValues?.currentStatus,
          dateEpoch: new Date(formValues?.currentStatusDate ?? '').valueOf(),
          current: true,
          active: true
        }
      ]
    : apiValues.statusHistory;
  apiValues.valuations = apiValuations;
  apiValues.services =
    services.length > 0 ? services.filter(serv => serv.productId !== '') : [];

  return apiValues;
};

const getApiValuationAmount = (valuations, type) => {
  const selectedValuation = valuations?.filter(
    x => x.type.toLowerCase() === type
  );

  return selectedValuation ? selectedValuation[0]?.amount : '';
};

const getApiValuationDate = (valuations, type) => {
  const selectedValuation = valuations?.filter(
    x => x.type.toLowerCase() === type
  );

  return selectedValuation && selectedValuation[0]?.dateEpoch !== 0
    ? new Date(selectedValuation[0]?.dateEpoch)
    : '';
};

const scrubProductId = services => {
  return services?.map(keyValue => {
    return {
      productId: keyValue.productId,
      operationCost: keyValue.operationCost,
      productName: keyValue.productName
    };
  });
};

export const mapApiToForm = apiValues => {
  const formValues = FormModel();

  formValues.id = apiValues?.id;
  formValues.name = apiValues?.name;
  formValues.equipmentType = apiValues?.type ?? '';
  formValues.modelYear = apiValues?.year !== 0 ? apiValues?.year : '';
  formValues.make = apiValues?.make ?? '';
  formValues.model = apiValues?.model ?? '';
  formValues.notes = apiValues?.notes ?? '';
  formValues.licenseNumber = apiValues?.licenseNumber ?? '';
  formValues.serialNumber = apiValues?.serialNumber ?? '';
  formValues.unitID = apiValues?.unitId ?? '';
  formValues.currentStatus =
    apiValues?.statusHistory[0]?.status.toLowerCase() ?? '';
  formValues.currentStatusDate =
    apiValues?.statusHistory[0]?.dateEpoch !== 0
      ? new Date(apiValues?.statusHistory[0]?.dateEpoch)
      : '';
  formValues.currentValue = getApiValuationAmount(
    apiValues?.valuations,
    'current'
  );
  formValues.currentValueDate = getApiValuationDate(
    apiValues?.valuations,
    'current'
  );
  formValues.purchasePrice = getApiValuationAmount(
    apiValues?.valuations,
    'purchase'
  );
  formValues.purchasePriceDate = getApiValuationDate(
    apiValues?.valuations,
    'purchase'
  );
  formValues.services = apiValues?.services
    ? scrubProductId(apiValues?.services)
    : formValues.services;

  return formValues;
};

export const dateFieldResultToString = result =>
  Array.isArray(result) ? result[0].toISOString() : result;

export const addArrayItem = (items, newItem) => [...items, newItem];

export const updateArrayItem = (items, index, newItem) => [
  ...items.slice(0, index),
  newItem,
  ...items.slice(index + 1)
];

export const removeArrayItem = (items, index) => [
  ...items.slice(0, index),
  ...items.slice(index + 1)
];

const parseError = error => ({
  [error.path]: error.message
});

export const parseValidationError = error =>
  error.path
    ? parseError(error)
    : error.inner.reduce((errorObject, innerError) => {
        return {
          ...errorObject,
          ...parseError(innerError)
        };
      }, {});

export const validateModalChanges = (schema, field, value) => {
  return new Promise(resolve => {
    schema.fields[field]
      .validate(value, { abortEarly: false })
      .then(() =>
        resolve(previousErrors => ({
          ...previousErrors,
          [field]: null
        }))
      )
      .catch(error =>
        resolve(previousErrors => ({
          ...previousErrors,
          [field]: error.message
        }))
      );
  });
};

export const searchEquipment = (equpments, value) => {
  if (value) {
    const val = value.toLowerCase(); // search string converted into lower case
    return equpments.filter(item => {
      return (
        item?.name?.toLowerCase().includes(val) ||
        item?.type?.toLowerCase().includes(val) ||
        item?.currentStatus?.toLowerCase().includes(val) ||
        item?.year?.toString().includes(val) ||
        item?.make?.toLowerCase().includes(val) ||
        item?.model?.toLowerCase().includes(val)
      );
    });
  }
  return equpments;
};

export const equipmentTableSort = (equipments, field, direction) => {
  return orderBy(equipments, field, direction); // return sorted array using field
};
