import React from 'react';
import successIllustration from 'assets/illustration-password-ok-a.svg';
import { ReactComponent as PlusCircleIcon } from 'assets/add-circle-outline.svg';
import { ReactComponent as SaveIcon } from 'assets/save.svg';
import PropTypes from 'prop-types';

const CreateNewLoadWidget = ({
  unSavedTicketsCount,
  closeCreateNewLoadWidget,
  onSaveAllLoads,
  widgetTitle
}) => {
  return (
    <div className="flex flex-col items-center pt-8 pb-16">
      <img src={successIllustration} alt="Yield Load created!" />
      <h1 className="mt-4 mb-8 text-2xl leading-8 text-black">{widgetTitle}</h1>
      <div className="flex gap-12">
        <button
          onClick={closeCreateNewLoadWidget}
          type="button"
          className="w-67 group flex flex-col py-6 px-12 items-center text-center rounded shadow-elevation-1 bg-white hover:grayscale hover:border-blue-90 border-1  hover:bg-blue-10"
        >
          <PlusCircleIcon className="text-neutral-60 fill-current group-hover:text-blue-90" />
          <p className="mt-4 mb-1 text-base font-semibold leading-6">
            Create New Load
          </p>
          <p className="text-neutral-60 text-sm leading-6">
            Add a brand new load to this submission
          </p>
        </button>
        <button
          onClick={onSaveAllLoads}
          type="button"
          className="w-67 group flex flex-col py-6 px-8 items-center text-center rounded shadow-elevation-1 bg-white hover:grayscale hover:border-blue-90 border-1  hover:bg-blue-10"
        >
          <SaveIcon className="text-neutral-60 fill-current group-hover:text-blue-90" />
          <p className="mt-4 mb-1 text-base font-semibold leading-6">
            Save All Loads <span>({unSavedTicketsCount})</span>
          </p>
          <p className="text-neutral-60 text-sm leading-6">
            If you’re done adding loads, save all loads on the Loads List
          </p>
        </button>
      </div>
    </div>
  );
};

CreateNewLoadWidget.propTypes = {
  unSavedTicketsCount: PropTypes.number.isRequired,
  closeCreateNewLoadWidget: PropTypes.func,
  onSaveAllLoads: PropTypes.func,
  widgetTitle: PropTypes.string.isRequired
};

CreateNewLoadWidget.defaultProps = {
  onSaveAllLoads: () => {},
  closeCreateNewLoadWidget: () => {}
};

export default CreateNewLoadWidget;
