const windDirections = [
  { key: 'n', value: 'N', code: 50600 },
  { key: 'ne', value: 'NE', code: 50800 },
  { key: 'nne', value: 'NNE', code: 50700 },
  { key: 'nw', value: 'NW', code: 52000 },
  { key: 'nnw', value: 'NNW', code: 52100 },
  { key: 's', value: 'S', code: 51400 },
  { key: 'se', value: 'SE', code: 51200 },
  { key: 'sse', value: 'SSE', code: 51300 },
  { key: 'sw', value: 'SW', code: 51600 },
  { key: 'ssw', value: 'SSW', code: 51500 },
  { key: 'e', value: 'E', code: 51000 },
  { key: 'ene', value: 'ENE', code: 50900 },
  { key: 'ese', value: 'ESE', code: 51100 },
  { key: 'w', value: 'W', code: 51800 },
  { key: 'wnw', value: 'WNW', code: 51900 },
  { key: 'wsw', value: 'WSW', code: 51700 },
  { key: 'unknown', value: 'Unknown', code: NaN },
  { key: 'calm', value: 'Calm', code: 50000 },
  { key: 'lw', value: 'LW', code: 50100 },
  { key: 'lb', value: 'LB', code: 50200 },
  { key: 'windy', value: 'Windy', code: 50300 },
  { key: 'breezy', value: 'Breezy', code: 50400 },
  { key: 'gusty', value: 'Gusty', code: 50500 },
  { key: 'variableWinds', value: 'Variable Winds', code: 52200 }
];

const skyConditions = [
  { key: 'clear', value: 'Clear' },
  { key: 'becomingClear', value: 'Becoming Clear' },
  { key: 'mostlyClear', value: 'Mostly Clear' },
  { key: 'becomingMostlyClear', value: 'Becoming Mostly Clear' },
  { key: 'partlyCloudy', value: 'Partly Cloudy' },
  { key: 'becomingPartlyCloudy', value: 'Becoming Partly Cloudy' },
  { key: 'partlyClear', value: 'Partly Clear' },
  { key: 'becomingPartlyClear', value: 'Becoming Partly Clear' },
  { key: 'mostlyCloudy', value: 'Mostly Cloudy' },
  { key: 'becomingMostlyCloudy', value: 'Becoming Mostly Cloudy' },
  { key: 'cloudy', value: 'Cloudy' },
  { key: 'becomingCloudy', value: 'Becoming Cloudy' },
  { key: 'fair', value: 'Fair' },
  { key: 'becomingFair', value: 'Becoming Fair' },
  { key: 'fewClouds', value: 'Few Clouds' },
  { key: 'fewCloudsDeveloping', value: 'Few Clouds Developing' },
  { key: 'scatteredClouds', value: 'Scattered Clouds' },
  { key: 'scatteredCloudsDeveloping', value: 'Scattered Clouds Developing' },
  { key: 'brokenOvercast', value: 'Broken Overcast' },
  { key: 'becomingBrokenOvercast', value: 'Becoming Broken Overcast' },
  { key: 'overcast', value: 'Overcast' },
  { key: 'becomingOvercast', value: 'Becoming Overcast' },
  { key: 'clouds', value: 'Clouds' },
  { key: 'decreasingClouds', value: 'Decreasing Clouds' },
  { key: 'increasingClouds', value: 'Increasing Clouds' },
  { key: 'variableClouds', value: 'Variable Clouds' },
  { key: 'numerousClouds', value: 'Numerous Clouds' },
  { key: 'numerousCloudsDeveloping', value: 'Numerous Clouds Developing' },
  { key: 'sunny', value: 'Sunny' },
  { key: 'becomingSunny', value: 'Becoming Sunny' },
  { key: 'mostlySunny', value: 'Mostly Sunny' },
  { key: 'becomingMostlySunny', value: 'Becoming Mostly Sunny' },
  { key: 'partlySunny', value: 'Partly Sunny' },
  { key: 'becomingPartlySunny', value: 'Becoming Partly Sunny' },
  { key: 'considerableCloudiness', value: 'Considerable Cloudiness' },
  {
    key: 'becomingConsiderableCloudiness',
    value: 'Becoming Considerable Cloudiness'
  },
  {
    key: 'considerableCloudinessDeveloping',
    value: 'Considerable Cloudiness Developing'
  }
];

const soilMoistures = [
  { key: 'dry', value: 'Dry' },
  { key: 'moist', value: 'Moist' },
  { key: 'wet', value: 'Wet' },
  { key: 'unknown', value: 'Unknown' }
];

export { windDirections, skyConditions, soilMoistures };
