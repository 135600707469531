import React from 'react';

import AgrianLogo from 'assets/integrations/AgrianLogo.svg';
import { INTEGRATIONS } from 'screens/Integrations/helpers/constants';
import LandingPage from 'screens/Integrations/components/LandingPage';
import Instructions from 'screens/Integrations/components/Instructions';

export const AGRIAN_RECOMMENDATION_STEPS = [
  {
    number: 1,
    header: 'Sign In',
    description: 'Authenticate with your vendor credentials'
  },
  {
    number: 2,
    header: 'Select Organization',
    description: 'Select an organization to pull available Recommendations'
  },
  {
    number: 3,
    header: 'Select Records',
    description:
      'Select the Agrian crop year and corresponding crop season, then choose the records to import'
  },
  {
    number: 4,
    header: 'Match Properties',
    description: 'Match your Agrian field with the corresponding crop zones'
  },
  {
    number: 5,
    header: 'Match Products',
    description:
      'Match the products used in your recommendation with those in the Cropwise product list'
  },
  {
    number: 6,
    header: 'Review and Submit',
    description:
      'Review your matches and data, then import the records to Cropwise Financials'
  }
];

const AgrianRecommendationLandingPage = () => {
  return (
    <>
      <LandingPage
        LogoImg={AgrianLogo}
        buttonText="Import my Agrian Recommendations"
        headerText="Agrian Recommendation Integration"
        breadcrumbText="Agrian Recommendation"
        ownerText={INTEGRATIONS.agrian}
        targetPath={INTEGRATIONS.agrianRecommendation}
      />
      <div className="w-full border-t-1 mb-5" />
      <Instructions steps={AGRIAN_RECOMMENDATION_STEPS} />
    </>
  );
};

export default AgrianRecommendationLandingPage;
