import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Table } from '@agconnections/grow-ui';
import FormattedNumber from 'components/FormattedNumber';
import ProductsModal from '../ProductsModal';

const ProductsTableRow = ({ product, pests }) => {
  const [productsModalOpen, setProductsModalOpen] = useState(false);
  return (
    <>
      <ProductsModal
        open={productsModalOpen}
        onClose={() => {
          setProductsModalOpen(false);
        }}
        product={product}
        pests={pests}
      />
      <Table.Row>
        <Table.Cell width="15rem">
          <span className="flex flex-col">
            <span
              className="w-64 pb-2 text-sm font-bold text-left truncate"
              data-testid="product-name"
            >
              {product.productName}
            </span>
            <span
              className="w-64 text-sm text-left text-gray-600 truncate"
              data-testid="product-manufacturer"
            >
              {product.custom && <>Custom</>}
              {product.custom && product.manufacturerName && <> • </>}
              {product.manufacturerName}
            </span>
          </span>
        </Table.Cell>
        <Table.Cell>
          {product.productRatePerAreaValue} {product.productRatePerAreaUnit}
        </Table.Cell>
        <Table.Cell>
          <FormattedNumber roundedTo="2">
            {product.productAppliedAreaValue}
          </FormattedNumber>{' '}
          {product.productAppliedAreaUnit}
        </Table.Cell>
        <Table.Cell>{product.coveragePercent * 100} %</Table.Cell>
        <Table.Cell>
          <FormattedNumber roundedTo="2">
            {product.totalProductValue}
          </FormattedNumber>{' '}
          {product.totalProductUnit}
        </Table.Cell>
      </Table.Row>
    </>
  );
};

ProductsTableRow.propTypes = {
  product: PropTypes.shape({
    applicationMethod: PropTypes.string,
    coveragePercent: PropTypes.number,
    custom: PropTypes.bool,
    manufacturerName: PropTypes.string,
    productAppliedAreaUnit: PropTypes.string,
    productAppliedAreaValue: PropTypes.number,
    productId: PropTypes.string,
    productName: PropTypes.string,
    productRatePerAreaUnit: PropTypes.string,
    productRatePerAreaValue: PropTypes.number,
    productRatePerTankUnit: PropTypes.string,
    productRatePerTankValue: PropTypes.number,
    targetPestId: PropTypes.number,
    totalProductUnit: PropTypes.string,
    totalProductValue: PropTypes.number
  }).isRequired,
  pests: PropTypes.arrayOf(
    PropTypes.shape({
      latinnames: PropTypes.string,
      name: PropTypes.string,
      nameid: PropTypes.number,
      pestid: PropTypes.number
    })
  ).isRequired
};

export default ProductsTableRow;
