const mapAcres = items =>
  items.map(({ _id: id, reportedArea = {}, calculatedArea = 0, ...rest }) => ({
    id,
    acres: reportedArea.value ? reportedArea.value : calculatedArea,
    ...rest
  }));

/**
 * this only maps one level deep, because we only show up to cropzone level on table view
 * That means no need for recursion or funky loops! 😃
 * */
const mapFieldAreasToNestedItems = fields =>
  fields.map(
    ({ _id, areas, reportedArea = {}, calculatedArea = 0, ...rest }) => ({
      id: _id,
      $nestedItems: mapAcres(areas),
      acres: reportedArea.value ? reportedArea.value : calculatedArea,
      ...rest
    })
  );

const groupFieldsAsFarms = fields => {
  return Object.values(
    fields.reduce((acc, field) => {
      if (field.farm) {
        const farmTag = field.farm;
        const { tagValue } = farmTag;

        // add to existing farm group
        if (acc[tagValue]) {
          const farm = acc[tagValue];
          farm.$nestedItems.push(field);
          farm.acres += field.acres;
        }
        // create new entry for this farm
        else {
          acc[tagValue] = {
            id: field.farm._id,
            name: tagValue,
            acres: field.acres,
            $nestedItems: [field]
          };
        }
      }

      return acc;
    }, {})
  );
};

export const groupUnderOrg = (farms, { name, id }) => {
  return [
    {
      acres: farms.reduce((acc, farm) => acc + farm.acres, 0),
      id,
      name,
      $nestedItems: farms,
      $defaultOpen: true
    }
  ];
};

const propertyMapping = (fields, organization) => {
  const mappedFields = mapFieldAreasToNestedItems(fields);
  return groupUnderOrg(groupFieldsAsFarms(mappedFields), organization);
};

export default propertyMapping;
