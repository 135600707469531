import { RestSerializer } from 'miragejs';

const mapRelationshipIds = (arr, name) =>
  arr.map(id => ({ $ref: name, $id: id }));

/**
 * Turns refs ids into correct format simulating real api
 * @param {String} collectionKey - name of collection
 * @param {Array} args - args from serialize
 * @param {String} [singularKey] - key of singular form of resource
 * @example
 * // override default singular form -- head-of-states
 * collectionRefSerializer('heads-of-state', args, 'head-of-state');
 */
const collectionRefSerializer = (collectionKey, child, args, singularKey) => {
  const _serialize = RestSerializer.prototype.serialize;
  let { data, ...rest } = _serialize.apply(child, args);
  const upperFirst = str => {
    return str ? str.charAt(0).toUpperCase() + str.slice(1) : '';
  };
  const refName = singularKey
    ? upperFirst(singularKey)
    : upperFirst(collectionKey.slice(0, -1));

  // data is Array
  if (Array.isArray(data))
    data = data.map(obj => ({
      ...obj,
      ...(obj[collectionKey]
        ? { [collectionKey]: mapRelationshipIds(obj[collectionKey], refName) }
        : null)
    }));
  // data is Object
  else
    data = {
      ...data,
      ...(data[collectionKey]
        ? { [collectionKey]: mapRelationshipIds(data[collectionKey], refName) }
        : null)
    };

  return { ...rest, data };
};
export default collectionRefSerializer;
