import React, { memo } from 'react';
import PropTypes from 'prop-types';
import Accordion from 'components/Accordion';
import CalAgAccordionHeader from '../../../CalAgAccordionHeader';
import CalAgFieldsForm from '../CalAgFieldsForm';

const CalAgAccordion = ({ field, toggleCallback, selectedId }) => {
  const {
    status,
    fieldName,
    cropZoneName,
    fieldId,
    cropZoneId,
    geometry
  } = field;

  return (
    <Accordion
      header={
        <CalAgAccordionHeader
          firstLabel={fieldName}
          secondLabel={cropZoneName}
          status={status}
        />
      }
      isFullWidthButton
      content={
        <CalAgFieldsForm
          name={cropZoneName}
          farm={fieldName}
          geometry={geometry}
        />
      }
      toggleCallback={toggleCallback}
      dynamicToggle
      canExpand={`${fieldId}#${cropZoneId}` === selectedId}
      isProperty={false}
      headerHeight="40px"
    />
  );
};

CalAgAccordion.defaultProps = {
  toggleCallback: () => {},
  selectedId: ''
};

CalAgAccordion.propTypes = {
  field: PropTypes.shape({
    fieldId: PropTypes.string.isRequired,
    fieldName: PropTypes.string.isRequired,
    cropZoneId: PropTypes.string.isRequired,
    cropZoneName: PropTypes.string.isRequired,
    state: PropTypes.string,
    county: PropTypes.string,
    townshipNumber: PropTypes.string,
    townshipDirection: PropTypes.string,
    rangeNumber: PropTypes.string,
    rangeDirection: PropTypes.string,
    geometry: PropTypes.shape({
      type: PropTypes.string.isRequired,
      coordinates: PropTypes.arrayOf(
        PropTypes.arrayOf(PropTypes.arrayOf(PropTypes.number.isRequired))
      ).isRequired
    }).isRequired,
    permitId: PropTypes.string,
    siteId: PropTypes.string,
    status: PropTypes.string.isRequired
  }).isRequired,
  toggleCallback: PropTypes.func,
  selectedId: PropTypes.string
};

export default memo(CalAgAccordion);
