import { useCallback, useContext, useRef, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Context } from 'components/Store';
import catchCancel from 'helpers/catchCancel';
import { parseServerError } from 'helpers/errorHelpers';
import { getAccessToken } from 'utilities/base-auth';
import { CROPWISE_PROXY_V2_URL } from 'utilities/apiConstants';
import axios from 'axios';
import { paths } from 'routes/paths';
import { AmplitudeContext } from 'utilities/amplitude/useAmplitude';
import {
  SET_SELECTED_LOCATION,
  SET_SHOW_TOAST_SALE_LOCATION,
  SET_TOAST_TYPE
} from 'reducers/reducer';
import createAction from 'helpers/createAction';
import replaceEmptyStringsWithFill from '../helpers/replaceEmptyString';

const useSaleLocations = () => {
  const requestNumber = useRef(0);
  const history = useHistory();
  const amplitude = useContext(AmplitudeContext);
  const [{ organization }, dispatch] = useContext(Context);
  const [saleLocationList, setSaleLocationList] = useState([]);
  const [isLoadingSaleLocationList, setIsLoadingSaleLocationList] = useState(
    false
  );
  const [
    isLoadingCreateSaleLocation,
    setIsLoadingCreateSaleLocation
  ] = useState(false);
  const [isLoadingEditSaleLocation, setIsLoadingEditSaleLocation] = useState(
    false
  );
  const [saleLocation, setSaleLocation] = useState();
  const [isFetchingSaleLocation, setIsFetchingSaleLocation] = useState();
  const [isDeletingSaleLocation, setIsDeletingSaleLocation] = useState();
  const [deletedSaleLocation, setDeletedSaleLocation] = useState();
  const [editSaleLocationData, setEditSaleLocationData] = useState();
  const defaultParams = {
    page: 1,
    sortBy: 'name',
    sortDir: 'ASC',
    limit: 0
  };
  const headers = {
    'Content-Type': 'application/json',
    'cwf-context': JSON.stringify({
      organization_id: organization.id
    }),
    Authorization: `Bearer ${getAccessToken()}`
  };
  const success = 'success';
  const fail = 'error';
  const failDelete = 'failDelete';
  const successDelete = 'successDelete';
  const getSaleLocationList = useCallback((_orgId, _params = {}) => {
    setIsLoadingSaleLocationList(true);
    const promise = axios.get(
      `${CROPWISE_PROXY_V2_URL}/yield/sale-location/list`,
      {
        headers: {
          ...headers,
          'cwf-context': JSON.stringify({
            organization_id: _orgId
          })
        },
        params: { ...defaultParams, ..._params }
      }
    );
    promise
      .then(({ data }) => {
        setSaleLocationList(data.data);
      })
      .catch(catchCancel)
      .catch(err => {
        parseServerError(dispatch)(err);
      })
      .finally(() => setIsLoadingSaleLocationList(false));
    // eslint-disable-next-line prettier/prettier, react-hooks/exhaustive-deps
  }, []);

  const createSaleLocation = useCallback(body => {
    setIsLoadingCreateSaleLocation(true);
    dispatch({
      type: SET_SHOW_TOAST_SALE_LOCATION,
      payload: false
    });
    const promise = axios.post(
      `${CROPWISE_PROXY_V2_URL}/yield/sale-location`,
      body,
      {
        headers
      }
    );
    promise
      .then(({ data }) => {
        dispatch({
          type: SET_TOAST_TYPE,
          payload: success
        });
        dispatch({
          type: SET_SHOW_TOAST_SALE_LOCATION,
          payload: true
        });
        createAction(dispatch, SET_SELECTED_LOCATION, data);

        amplitude.sendEventToAmplitude(
          amplitude.events.epic.YieldV2.saleLocations.createSaleLocation,
          replaceEmptyStringsWithFill(body)
        );
        history.push(`${paths.yieldV2}/sale-locations?saleId=${data?.id}`);
      })
      .catch(catchCancel)
      .catch(err => {
        parseServerError(dispatch)(err);
        dispatch({
          type: SET_TOAST_TYPE,
          payload: fail
        });
        dispatch({
          type: SET_SHOW_TOAST_SALE_LOCATION,
          payload: true
        });
      })
      .finally(() => setIsLoadingCreateSaleLocation(false));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const editSaleLocation = (id, body = {}) => {
    setIsLoadingEditSaleLocation(true);
    dispatch({
      type: SET_SHOW_TOAST_SALE_LOCATION,
      payload: false
    });
    setEditSaleLocationData({});
    const promise = axios.put(
      `${CROPWISE_PROXY_V2_URL}/yield/sale-location/${id}`,
      body,
      {
        headers
      }
    );
    promise
      .then(({ data }) => {
        setEditSaleLocationData(data);
        dispatch({
          type: SET_TOAST_TYPE,
          payload: success
        });
        dispatch({
          type: SET_SHOW_TOAST_SALE_LOCATION,
          payload: true
        });
        amplitude.sendEventToAmplitude(
          amplitude.events.epic.YieldV2.saleLocations.editSaleLocation
        );
        history.push(`${paths.yieldV2}/sale-locations?saleId=${id}`);
      })
      .catch(catchCancel)
      .catch(err => {
        parseServerError(dispatch)(err);
        dispatch({
          type: SET_TOAST_TYPE,
          payload: fail
        });
        dispatch({
          type: SET_SHOW_TOAST_SALE_LOCATION,
          payload: true
        });
      })
      .finally(() => setIsLoadingEditSaleLocation(false));
  };

  const getSaleLocationById = useCallback(
    id => {
      setIsFetchingSaleLocation(true);
      requestNumber.current += 1;
      const currentRequestNumber = requestNumber.current;
      const promise = axios.get(
        `${CROPWISE_PROXY_V2_URL}/yield/sale-location/${id}`,
        {
          headers
        }
      );
      promise
        .then(({ data }) => {
          if (requestNumber.current === currentRequestNumber)
            setSaleLocation(data);
        })
        .catch(catchCancel)
        .catch(err => {
          parseServerError(dispatch)(err);
        })
        .finally(() => {
          setIsFetchingSaleLocation(false);
        });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [organization]
  );

  const deleteSaleLocation = (
    id,
    onClose,
    _saleLocationList,
    _setSaleLocationList
  ) => {
    setIsDeletingSaleLocation(true);
    dispatch({
      type: SET_SHOW_TOAST_SALE_LOCATION,
      payload: false
    });
    const promise = axios.delete(
      `${CROPWISE_PROXY_V2_URL}/yield/sale-location/${id}`,
      {
        headers
      }
    );
    promise
      .then(({ data }) => {
        createAction(dispatch, SET_SELECTED_LOCATION, {});
        setDeletedSaleLocation(data);
        const nextElementOnTheList = 1;
        const deletedIndexOfElement = _saleLocationList.findIndex(
          location => location.id === id
        );
        const listBeforeDeletedElement = _saleLocationList;
        const checkIndexOutOfBounce =
          deletedIndexOfElement + nextElementOnTheList <
          listBeforeDeletedElement.length;

        const updatedList = _saleLocationList.filter(obj => obj.id !== id);
        _setSaleLocationList(updatedList);
        if (deletedIndexOfElement !== -1 && checkIndexOutOfBounce) {
          const nextItemId =
            listBeforeDeletedElement[
              deletedIndexOfElement + nextElementOnTheList
            ].id;
          history.replace(
            `${paths.yieldV2}/sale-locations?saleId=${nextItemId}`
          );
        } else {
          history.replace(`${paths.yieldV2}/sale-locations`);
        }

        dispatch({
          type: SET_TOAST_TYPE,
          payload: successDelete
        });
        dispatch({
          type: SET_SHOW_TOAST_SALE_LOCATION,
          payload: true
        });
      })
      .catch(catchCancel)
      .catch(err => {
        parseServerError(dispatch)(err);
        dispatch({
          type: SET_TOAST_TYPE,
          payload: failDelete
        });
        dispatch({
          type: SET_SHOW_TOAST_SALE_LOCATION,
          payload: true
        });
      })
      .finally(() => {
        setIsDeletingSaleLocation(false);
        onClose();
      });
  };

  return {
    getSaleLocationList,
    isLoadingSaleLocationList,
    isLoadingCreateSaleLocation,
    saleLocationList,
    setSaleLocationList,
    createSaleLocation,
    saleLocation,
    editSaleLocation,
    isLoadingEditSaleLocation,
    editSaleLocationData,
    deleteSaleLocation,
    isDeletingSaleLocation,
    deletedSaleLocation,
    getSaleLocationById,
    isFetchingSaleLocation
  };
};

export default useSaleLocations;
