import React from 'react';
import PropTypes from 'prop-types';

const Editor = ({
  label,
  halfWidth,
  children,
  suffix,
  suffixOffset,
  noLabelMarginTop,
  labelMarginTop
}) => {
  const widthClass = halfWidth ? '' : 'col-span-2';
  const marginTop = !label ? noLabelMarginTop : labelMarginTop;
  return (
    <div
      className={`relative flex flex-col ${widthClass} justify-center ${marginTop} mb-2 gap-y-0`}
      data-testid="ui-field-editor"
    >
      {label && <span className="text-sm text-neutral-600">{label}</span>}
      {children}
      {suffix && (
        <div className={`text-sm absolute ${suffixOffset} top-1`}>{suffix}</div>
      )}
    </div>
  );
};

Editor.propTypes = {
  label: PropTypes.string,
  halfWidth: PropTypes.bool,
  children: PropTypes.node.isRequired,
  suffix: PropTypes.string,
  suffixOffset: PropTypes.string,
  noLabelMarginTop: PropTypes.string,
  labelMarginTop: PropTypes.string
};

Editor.defaultProps = {
  halfWidth: false,
  label: '',
  suffix: '',
  suffixOffset: 'right-2',
  noLabelMarginTop: 'mt-8',
  labelMarginTop: 'mt-4'
};

export default Editor;
