import { useState } from 'react';
import { genProxyResource } from 'utilities/api';

const useInventoryApi = resourceName => {
  const [api] = useState(genProxyResource(resourceName, { urlVersion: '/v1' }));

  return api;
};

export default useInventoryApi;
