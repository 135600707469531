import { useContext, useEffect, useState } from 'react';
import catchCancel from 'helpers/catchCancel';
import { parseServerError } from 'helpers/errorHelpers';
import { Context } from 'components/Store';
import { season as seasonApi } from '../utilities/api';

const useCropSeasonData = id => {
  const [, dispatch] = useContext(Context);

  const [cropSeason, setCropSeason] = useState({});
  const [loading, setLoading] = useState(true);
  const [saving, setSaving] = useState(false);

  useEffect(() => {
    const isCreateScreen = id === 'season';
    let cancel;
    if (!isCreateScreen && id) {
      const { promise, cancel: _cancel } = seasonApi.fetch(id);
      cancel = _cancel;
      promise
        .then(({ data }) => {
          setCropSeason(data);
        })
        .catch(catchCancel)
        .catch(err => {
          parseServerError(dispatch)(err);
        })
        .finally(() => setLoading(false));
    } else {
      setLoading(false);
    }
    return () => cancel && cancel();
  }, [dispatch, id]);

  const saveCropSeason = values => {
    const isCreateScreen = id === 'season';
    setSaving(true);

    const saveValues = values;

    const { promise } = isCreateScreen
      ? seasonApi.create(saveValues)
      : seasonApi.update(id, saveValues);

    return promise
      .catch(catchCancel)
      .catch(parseServerError(dispatch))
      .finally(() => {
        setSaving(false);
      });
  };

  return {
    cropSeason,
    loading,
    saving,
    saveCropSeason
  };
};

export default useCropSeasonData;
