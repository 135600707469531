import { CancelToken, create } from 'axios';
import { defer } from 'lodash';
import withCancel from './withCancel';
import deepSerializer from './deepSerializer';
import axios, { cache } from './getAxiosInstance';
import filterGlobalSchema from './filterGlobalSchema';
import urlBuilder from './urlBuilder';
import { defineAxiosRequestInterceptor } from './interceptors';
import { searchModels } from './searchModels';
import {
  fetchFromCache,
  fetchFromCacheById,
  addToCache,
  addToCacheById,
  setCurrentFetch,
  unwrapContainer,
  wrapInDataContainer,
  checkCacheSettings,
  reloadPage401Error
} from './caching';

const createApiFunctions = (
  resourceName,
  axiosInstanceKey,
  urlBuilderInstance,
  cwfContextRegExpExclusions
) => ({
  /**
   * changes the request interceptor settings such as the cwf-context header settings
   * @param {Object} config - any axios config settings
   * @param {Function} contextCallback - a function that returns the value to be used for the cwf-context header
   */
  updateRequestInterceptor(config, contextCallback) {
    Object.assign(axios.interceptors.request.config, config);
    if (contextCallback) {
      axios.interceptors.request.contextCallback = contextCallback;
    }
  },

  /**
   * spawns a child instance of the api functions with the specified urlBuilder config options, usually parentResource options
   * @param {Object} urlBuilderOptions - configuration for parentResources, action, and/or suppressResourceName options
   * @returns {Object} - the new api functions
   */
  createChildApi(urlBuilderOptions) {
    const childUrlBuilder = urlBuilderInstance.createUrlBuilder(
      urlBuilderOptions
    );
    return createApiFunctions(resourceName, axiosInstanceKey, childUrlBuilder);
  },

  /**
   * Performs a GET request for resource.  If an id is passed fetches resource matching id.
   * @param {String} [id] - id of resource (null is interpreted as if no id was passed)
   * @param {Object} [params] - additional params passed to api as query string
   * @param {Number} [params.limit] - limits results returned by query (paginates results)
   * @param {Number} [params.skip] - the number of results to skip.
   * @param {Object} [config] - axios config https://github.com/axios/axios#request-config
   * @param {String} [containerName] - the name of the object containing the results data in the api response
   * @returns {APIResult} returns an APIResult object containing promise and cancellation function (cancel)
   */
  fetch(id, params, config, containerName, ignoreCache = false) {
    const fetchUrl = id ? urlBuilderInstance(id) : urlBuilderInstance(null);
    const cachedPromise = id
      ? fetchFromCacheById(resourceName, id)
      : fetchFromCache(resourceName, {
          url: fetchUrl,
          cwfContextRegExpExclusions,
          containerName
        });
    const { token: cancelToken, cancel } = CancelToken.source();

    const promise = cachedPromise.then(cachedResult => {
      if (!ignoreCache && cachedResult) {
        return cachedResult;
      }

      const axiosConfig = {
        ...config,
        headers: {
          ...config?.headers,
          'Local-Cache': !!config?.headers?.['Local-Cache']
        },
        params,
        cancelToken,
        paramsSerializer: deepSerializer
      };

      const currentFetch = setCurrentFetch(fetchUrl, containerName, () =>
        axios.instances[axiosInstanceKey].api.get(fetchUrl, axiosConfig)
      );
      return currentFetch.then(({ data, ...restOfResponse }) => {
        const results = unwrapContainer(data, containerName);
        defer(() => {
          if (ignoreCache) return;

          if (id) {
            addToCacheById(resourceName, results, {
              url: fetchUrl,
              cwfContextRegExpExclusions
            });
          } else {
            addToCache(resourceName, results, {
              url: fetchUrl,
              cwfContextRegExpExclusions
            });
          }
        });
        return wrapInDataContainer(results, restOfResponse, 'api');
      });
    });
    return withCancel(promise, cancel, `Cancelled ${resourceName} fetch`);
  },

  /**
   * Performs a GET request for resource. Passing query parameters to pass by id
   * @param {Object} query - resource properties to filter against
   * @param {String} serverSearchModel - model used to translate the query for the server request, null indicates that the
   *                              search parameters must be parsed client side
   * @param {String} containerName - the name of the object containing the results data in the api response
   * @param {Object} options - axios config overrides https://github.com/axios/axios#request-config
   * @param config
   * @returns {APIResult} returns an APIResult object containing promise and cancellation function (cancel)
   */
  find(
    query = {},
    { serverSearchModel = null, containerName },
    options = {},
    config = {}
  ) {
    const url = urlBuilderInstance(null);
    // const parentResourceIds = getParentResourceIds(urlBuilderInstance);
    const cachedPromise = fetchFromCache(resourceName, {
      url,
      cwfContextRegExpExclusions,
      query,
      containerName
    });

    const { token: cancelToken, cancel } = CancelToken.source();

    const promise = cachedPromise.then(cachedResult => {
      if (cachedResult) {
        return cachedResult;
      }

      const baseConfig = {
        ...config,
        params: { ...options },
        paramsSerializer: deepSerializer,
        cancelToken
      };
      const axiosConfig =
        serverSearchModel && searchModels[serverSearchModel].onRequest
          ? searchModels[serverSearchModel].onRequest(baseConfig, query)
          : baseConfig;
      const currentFetch = setCurrentFetch(url, containerName, () =>
        axios.instances[axiosInstanceKey].api.get(url, axiosConfig)
      );
      return currentFetch.then(({ data, source, ...restOfResponse }) => {
        const results =
          source === 'lastFetch' ? data : unwrapContainer(data, containerName);
        defer(() => {
          if (source !== 'lastFetch') {
            addToCache(resourceName, results, {
              url,
              cwfContextRegExpExclusions
            });
          }
        });
        const response =
          serverSearchModel && searchModels[serverSearchModel].onResponse
            ? searchModels[serverSearchModel].onResponse(
                results,
                query.sorts,
                query.filters
              )
            : results;
        return wrapInDataContainer(response, restOfResponse, 'api');
      });
    });
    return withCancel(promise, cancel, `Cancelled ${resourceName} find`);
  },

  /**
   * Performs a POST request to create a resource.
   * @param {Object} data - data for new resource
   * @returns {APIResult} returns an APIResult object containing promise and cancellation function (cancel)
   */
  post(data, config, isUsingCache = false) {
    const createURL = urlBuilderInstance(null);
    const _data = filterGlobalSchema(data);
    const { token: cancelToken, cancel } = CancelToken.source();
    const result = axios.instances[axiosInstanceKey].api
      .post(createURL, _data, {
        cancelToken,
        ...config,
        headers: {
          ...config?.headers,
          'Local-Cache': isUsingCache
        }
      })
      .catch(error => {
        if (error.response?.status === 401) {
          reloadPage401Error();
        } else {
          // re-throwing all other errors so that the calling function logic can deal with these errors.
          throw error;
        }
      });
    // for now, clear the cache if an item is created
    checkCacheSettings(resourceName, result);
    return withCancel(result, cancel, `Cancelled ${resourceName} create`);
  },

  create(data, config, isUsingCache = false) {
    return this.post(data, config, isUsingCache);
  },

  fetchByPost(
    data,
    params = {},
    isUsingCache = false,
    includeDanglingFields = false
  ) {
    return this.post(
      data,
      {
        params: { ...params, include_dangling_fields: includeDanglingFields },
        headers: {
          'Fetch-By-Post': true
        }
      },
      isUsingCache
    );
  },

  /**
   * Performs a PUT request to update the specified resource.
   * @param {String} id - id of resource
   * @param {Object} data - data to be updated
   * @returns {APIResult} returns an APIResult object containing promise and cancellation function (cancel)
   */
  update(id, data, config) {
    let updateURL;
    if (id) {
      updateURL = urlBuilderInstance(id);
    } else {
      updateURL = urlBuilderInstance(null);
    }
    const _data = filterGlobalSchema(data, true);
    const { token: cancelToken, cancel } = CancelToken.source();
    const result = axios.instances[axiosInstanceKey].api
      .put(updateURL, _data, {
        cancelToken,
        headers: { ...config?.headers }
      })
      .catch(error => {
        if (error.response.status === 401) {
          reloadPage401Error();
        } else {
          // re-throwing all other errors so that the calling function logic can deal with these errors.
          throw error;
        }
      });
    // for now, clear the cache if an item is modified
    checkCacheSettings(resourceName, result);
    return withCancel(result, cancel, `Cancelled ${resourceName} update`);
  },

  /**
   * Performs a DELETE request to delete the specified resource.
   * @param {String} id - id of resource
   * @returns {APIResult} returns an APIResult object containing promise and cancellation function (cancel)
   */
  delete(id, config = {}) {
    const url = urlBuilderInstance(id);
    const { token: cancelToken, cancel } = CancelToken.source();

    const result = axios.instances[axiosInstanceKey].api
      .delete(url, {
        cancelToken,
        ...config
      })
      .catch(error => {
        if (error.response?.status === 401) {
          reloadPage401Error();
        } else {
          // re-throwing all other errors so that the calling function logic can deal with these errors.
          throw error;
        }
      });
    // for now, clear the cache if an item is deleted
    checkCacheSettings(resourceName, result);
    return withCancel(result, cancel, `Cancelled ${resourceName} delete`);
  },

  patch(id, data, config) {
    const patchUrl = urlBuilderInstance(id);
    const _data = filterGlobalSchema(data, true);
    const { token: cancelToken, cancel } = CancelToken.source();
    const axiosConfig = {
      ...config,
      cancelToken
    };
    const result = axios.instances[axiosInstanceKey].api.patch(
      patchUrl,
      _data,
      axiosConfig
    );
    return withCancel(result, cancel, `Cancelled ${resourceName} patch`);
  }
});

/**
 * creates an api resource interface
 * @param {String} resourceName - resource name associated with resource base API. (i.e. User /users/ resource would be 'users') Do not include forward slashes
 * @param {String} [apiUrl] - base URL for API. not required if target api is a relative path on the same domain.
 * @param parentResources
 * @param action
 * @param suppressResourceName
 * @param cwfContextRegExpExclusions
 * @returns {Object} - returns object with member function for resource CRUD operations
 */
const genResource = (
  resourceName = '',
  apiUrl = '',
  {
    parentResources,
    action,
    suppressResourceName,
    cwfContextRegExpExclusions,
    customCwfContext = false
  } = {}
) => {
  const baseUrlBuilder = urlBuilder(resourceName, apiUrl, {
    parentResources,
    action,
    suppressResourceName
  });
  const axiosInstanceKey = cwfContextRegExpExclusions?.length
    ? resourceName
    : 'default';
  if (axiosInstanceKey !== 'default') {
    axios.instances[axiosInstanceKey] = {
      api: create({ adapter: cache.adapter }),
      cwfContextRegExpExclusions
    };
  }
  defineAxiosRequestInterceptor(
    axios,
    axiosInstanceKey,
    cwfContextRegExpExclusions,
    customCwfContext
  );
  const api = createApiFunctions(
    resourceName,
    axiosInstanceKey,
    baseUrlBuilder,
    cwfContextRegExpExclusions
  );
  api.updateRequestInterceptor({});
  return api;
};

export default genResource;
