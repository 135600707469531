import React from 'react';
import './index.css';
import PropTypes from 'prop-types';
import { Tooltip } from 'syngenta-digital-cropwise-react-ui-kit';

export default function ActionButton({ icon, onClick, text, compact }) {
  const classes = compact
    ? 'compact-action-button hover:text-blue-90 py-6px px-2 rounded'
    : 'action-button hover:text-cucumber-green-700';
  return (
    <button
      className={`flex gap-1 items-center ${classes}`}
      onClick={onClick}
      onKeyDown={event => event.nativeEvent.key === 'Enter' && onClick(event)}
      tabIndex={compact ? undefined : 0}
      type="button"
    >
      <Tooltip className="xl:hidden" title={text}>
        {icon}
      </Tooltip>
      <div className="hidden xl:block">{icon}</div>
      <span className="hidden xl:block">{text}</span>
    </button>
  );
}

ActionButton.defaultProps = {
  icon: <div>icon</div>,
  text: '',
  onClick: () => {},
  compact: false
};

ActionButton.propTypes = {
  icon: PropTypes.node,
  text: PropTypes.string,
  onClick: PropTypes.func,
  compact: PropTypes.bool
};
