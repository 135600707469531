import React from 'react';

const ArrowRight = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="42"
      height="14"
      viewBox="0 0 42 14"
      fill="none"
    >
      <path
        d="M31.2892 5.34282H0V8.65861H31.2892V13.6323L41.6842 7.00072L31.2892 0.369141V5.34282Z"
        fill="#696F88"
      />
    </svg>
  );
};

export default ArrowRight;
