import React, { useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import GrowUIFormField from 'components/Forms/GrowUIFormField';
import { Input, TextArea, Select, Spinner } from '@agconnections/grow-ui';
import { Button, ButtonType } from 'syngenta-digital-cropwise-react-ui-kit';
import { ErrorMessage, Field, Formik } from 'formik';
import infoIcon from 'assets/info.svg';
import replaceEmptyStringsWithFill from 'screens/YieldV2/SaleLocations/helpers/replaceEmptyString';
import useCompanies from 'hooks/useCompanies';
import usePeopleData from 'hooks/usePeopleData';
import FormInputWithPrecisionDisplay from 'screens/YieldV2/AddYield/components/FormInputWithPrecisionDisplay';
import { YIELD_DEFAULT_ROUND } from 'screens/YieldV2/constants';
import { AmplitudeContext } from 'utilities/amplitude/useAmplitude';
import AddNewCompanyPeopleDrawer from 'components/CompanyPeopleDropdown/components/AddNewCompanyPeopleDrawer';
import AddressFields from 'components/CompanyPeopleDropdown/components/AddNewCompanyPeopleDrawer/components/AddressFields';
import { locationFormValidationSchema } from '../../helpers/locationFormValidationSchema';

import OwnerField from '../OwnerField';

import useStorageLocations from '../../hooks/useStorageLocations';
import InfoToast from '../InfoToast';

import prepareLocationValuesToPayload from '../../helpers/prepareLocationValuesToPayload';

const LocationForm = ({ id, onCancel, onSubmit }) => {
  const [explicitOwnerValue, setExplicitOwnerValue] = useState(null);
  const [
    addNewCompanyPeopleDrawerOpen,
    setAddNewCompanyPeopleDrawerOpen
  ] = useState(false);
  const [showToast, setShowToast] = useState(false);
  const [locationLoaded, setLocationLoaded] = useState(false);
  const amplitude = useContext(AmplitudeContext);
  const companiesData = useCompanies();
  const peopleData = usePeopleData();
  const [initialValues, setInitialValues] = useState({
    status: null,
    shape: null,
    fillingSystem: null,
    unloadingSystem: null,
    notes: null
  });
  const {
    saving,
    fetchStorageDetails,
    createStorageLocation,
    updateStorageLocation
  } = useStorageLocations();

  const isLoading =
    (id && !locationLoaded) || companiesData.loading || peopleData.loading;
  const savingMessage = id ? 'Saving Changes' : 'Saving Location';
  const errorMessage = id
    ? `Unable to save changes. An error prevented your location from being updated. Please try again.`
    : `An error prevented your location from being saved. Please try again.`;
  const toastTitle = id ? 'Location Not Updated.' : 'Location Not Saved.';

  useEffect(() => {
    if (
      id &&
      !companiesData.loading &&
      !peopleData.loading &&
      !locationLoaded
    ) {
      const loadLocation = async () => {
        const entry = await fetchStorageDetails(id);
        if (entry.owner) {
          const entries =
            entry.owner.type === 'Company'
              ? companiesData.companies
              : peopleData.people;
          entry.owner.name = entries.find(x => x.id === entry.owner.id)?.name;
        }
        setInitialValues(entry);
        setLocationLoaded(true);
      };
      loadLocation();
    }
  }, [
    id,
    setInitialValues,
    fetchStorageDetails,
    companiesData.loading,
    companiesData.companies,
    locationLoaded,
    peopleData.loading,
    peopleData.people
  ]);

  const save = async values => {
    if (saving) {
      return;
    }
    const saveResult = await createStorageLocation(
      prepareLocationValuesToPayload(values)
    );
    if (saveResult) {
      amplitude.sendEventToAmplitude(
        amplitude.events.epic.YieldV2.storageLocations.saveStorageLocation,
        replaceEmptyStringsWithFill(values)
      );
      onSubmit(saveResult);
    } else {
      setShowToast(true);
    }
  };

  const update = async values => {
    if (saving) {
      return;
    }
    const saveResult = await updateStorageLocation(
      id,
      prepareLocationValuesToPayload(values)
    );
    if (saveResult) {
      onSubmit(saveResult);
    } else {
      setShowToast(true);
    }
  };

  const onOwnerCreated = ownerValue => {
    setExplicitOwnerValue(ownerValue);
    companiesData.loadCompanies();
    peopleData.loadPeople();
  };

  if (isLoading) {
    return (
      <div className="px-4 flex items-center justify-center w-full h-full">
        <Spinner size="xl" />
      </div>
    );
  }

  return (
    <div className="px-4 flex items-center justify-center w-full h-full">
      <InfoToast
        title={toastTitle}
        state="error"
        showToast={showToast}
        setShowToast={setShowToast}
        errorMessage={errorMessage}
      />
      <Formik
        initialValues={initialValues}
        validationSchema={locationFormValidationSchema}
      >
        {({ values, isValid }) => (
          <div
            className="bg-white rounded-lg flex flex-col w-full h-full pb-8
    shadow-sm-card"
            style={{
              height: 'calc(100% - 32px)'
            }}
          >
            {!saving && (
              <div className="flex flex-col flex-1 h-full w-full overflow-y-auto relative">
                <div className="py-6 grid gap-1 grid-cols-4 px-8">
                  <h1 className="col-span-4 my-4 text-lg font-bold">
                    Basic Info
                  </h1>
                  <div className="col-span-2 my-4 mr-4 ">
                    <GrowUIFormField
                      value={values.name}
                      placeholder=""
                      control={Input}
                      type="text"
                      name="name"
                      label={
                        <>
                          <span>Name of Location</span>
                          <span className="text-red-600">*</span>
                        </>
                      }
                    />
                  </div>
                  <div className="col-span-1 my-4 mr-4">
                    <GrowUIFormField
                      value={values.email}
                      placeholder=""
                      control={Input}
                      type="text"
                      name="email"
                      label="Email Address"
                    />
                  </div>
                  <div className="col-span-1 my-4 mr-4">
                    <GrowUIFormField
                      value={values.phone}
                      placeholder=""
                      control={Input}
                      type="text"
                      name="phone"
                      label="Phone Number"
                    />
                  </div>
                  <AddressFields />
                </div>
                <div className="pb-6 grid gap-1 grid-cols-2 px-8">
                  <div className="">
                    <h1 className="col-span-2 text-lg font-bold">Dimensions</h1>
                    <div className="flex">
                      <div className="w-full mr-4">
                        <div className="mt-8 mb-2">
                          <GrowUIFormField
                            value={values.shape}
                            placeholder=""
                            control={Select}
                            type="text"
                            name="shape"
                            label="Shape"
                            items={[
                              { key: null, value: 'None' },
                              { key: 'Round', value: 'Round' },
                              { key: 'Flat', value: 'Flat' }
                            ]}
                          />
                        </div>
                        <div className="mb-8 relative">
                          <div className="flex flex-col weight-input">
                            <label
                              htmlFor="capacity"
                              className="text-neutral-60 tracking-tighter text-sm"
                            >
                              Capacity
                            </label>
                            <FormInputWithPrecisionDisplay
                              fieldName="capacity"
                              inputSuffix={
                                <span className="text-sm text-gray-600">
                                  bu by vol
                                </span>
                              }
                              id="capacity"
                              isRequired={false}
                              round={YIELD_DEFAULT_ROUND}
                            />
                            <ErrorMessage
                              name="capacity"
                              component="div"
                              className="text-remove"
                            />
                          </div>
                        </div>
                        <div className="-mt-4">
                          <img
                            alt="info"
                            src={infoIcon}
                            className="mr-1 -mt-1 inline"
                          />
                          <span className="text-sm mt-1 text-gray-600">
                            bu by vol = 1.24446 cubic ft assuming standard
                            moisture of stored crop
                          </span>
                        </div>
                      </div>
                      <div className="w-full mr-8">
                        {values.shape === 'Flat' && (
                          <>
                            <div className="my-8 relative">
                              <GrowUIFormField
                                value={values.length}
                                placeholder=""
                                control={Input}
                                type="text"
                                name="length"
                                label="Length"
                              />
                              <div className="text-sm text-gray-600 absolute top-0 right-0 mr-2 mt-1">
                                ft
                              </div>
                            </div>
                            <div className="my-8 relative">
                              <GrowUIFormField
                                value={values.width}
                                placeholder=""
                                control={Input}
                                type="text"
                                name="width"
                                label="Width"
                              />
                              <div className="text-sm text-gray-600 absolute top-0 right-0 mr-2 mt-1">
                                ft
                              </div>
                            </div>
                            <div className="my-8 relative">
                              <GrowUIFormField
                                value={values.height}
                                placeholder=""
                                control={Input}
                                type="text"
                                name="height"
                                label="Height"
                              />
                              <div className="text-sm text-gray-600 absolute top-0 right-0 mr-2 mt-1">
                                ft
                              </div>
                            </div>
                            <div className="my-8 relative">
                              <GrowUIFormField
                                value={values.volume}
                                placeholder=""
                                control={Input}
                                type="text"
                                name="volume"
                                label="Volume"
                              />
                              <div className="text-sm text-gray-600 absolute top-0 right-0 mr-2 mt-1">
                                cu. ft
                              </div>
                            </div>
                          </>
                        )}
                        {values.shape === 'Round' && (
                          <>
                            <div className="my-8 relative">
                              <GrowUIFormField
                                value={values.diameter}
                                placeholder=""
                                control={Input}
                                type="text"
                                name="diameter"
                                label="Diameter"
                              />
                              <div className="text-sm text-gray-600 absolute top-0 right-0 mr-2 mt-1">
                                ft
                              </div>
                            </div>
                            <div className="my-8 relative">
                              <GrowUIFormField
                                value={values.height}
                                placeholder=""
                                control={Input}
                                type="text"
                                name="height"
                                label="Height"
                              />
                              <div className="text-sm text-gray-600 absolute top-0 right-0 mr-2 mt-1">
                                ft
                              </div>
                            </div>
                            <div className="my-8 relative">
                              <GrowUIFormField
                                value={values.coneHeight}
                                placeholder=""
                                control={Input}
                                type="text"
                                name="coneHeight"
                                label="Cone Height"
                              />
                              <div className="text-sm text-gray-600 absolute top-0 right-0 mr-2 mt-1">
                                ft
                              </div>
                            </div>
                            <div className="my-8 relative">
                              <GrowUIFormField
                                value={values.volume}
                                placeholder=""
                                control={Input}
                                type="text"
                                name="volume"
                                label="Volume"
                              />
                              <div className="text-sm text-gray-600 absolute top-0 right-0 mr-2 mt-1">
                                cu. ft
                              </div>
                            </div>
                          </>
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="">
                    <h1 className="text-lg font-bold">Systems</h1>
                    <div className="my-8 mr-4">
                      <GrowUIFormField
                        value={values.coolingSystem}
                        placeholder=""
                        control={Input}
                        type="text"
                        name="coolingSystem"
                        label="Cooling System"
                      />
                    </div>
                    <div className="my-8 mr-4">
                      <GrowUIFormField
                        value={values.fillingSystem}
                        placeholder=""
                        control={Select}
                        type="text"
                        name="fillingSystem"
                        label="Filling System"
                        items={[
                          { key: null, value: 'Select Filling System' },
                          { key: 'Auger', value: 'Auger' },
                          { key: 'Bucket Elevator', value: 'Bucket Elevator' },
                          { key: 'Drag Chain', value: 'Drag Chain' },
                          { key: 'Vacuum System', value: 'Vacuum System' }
                        ]}
                      />
                    </div>
                    <div className="my-8 mr-4">
                      <GrowUIFormField
                        value={values.unloadingSystem}
                        placeholder=""
                        control={Select}
                        type="text"
                        name="unloadingSystem"
                        label="Unloading System"
                        items={[
                          { key: null, value: 'Select Unloading System' },
                          { key: 'Auger', value: 'Auger' },
                          { key: 'Bucket Elevator', value: 'Bucket Elevator' },
                          { key: 'Drag Chain', value: 'Drag Chain' },
                          { key: 'Vacuum System', value: 'Vacuum System' }
                        ]}
                      />
                    </div>
                  </div>
                </div>
                <div className="py-6 px-8">
                  <h1 className="my-4 text-lg font-bold">
                    Advanced Attributes
                  </h1>
                  <div className="flex">
                    <div className="mt-2 mb-8 mr-4 w-full relative">
                      <label
                        htmlFor="owner-select"
                        className="text-neutral-60 tracking-tighter text-sm"
                      >
                        Owner
                      </label>
                      <Field name="owner">
                        {({ field, form }) => {
                          if (explicitOwnerValue) {
                            form.setFieldValue(field.name, explicitOwnerValue);
                            setExplicitOwnerValue(null);
                          }

                          return (
                            <OwnerField
                              id="owner-select"
                              value={values.owner}
                              onChange={value => {
                                setExplicitOwnerValue(null);
                                form.setFieldValue(field.name, value);
                              }}
                              onCreateNewOwner={() => {
                                amplitude.sendEventToAmplitude(
                                  amplitude.events.epic.YieldV2.storageLocations
                                    .createCompany
                                );
                                setAddNewCompanyPeopleDrawerOpen(true);
                              }}
                              people={peopleData.people}
                              companies={companiesData.companies}
                            />
                          );
                        }}
                      </Field>
                    </div>
                    <div className="my-8 mr-4 w-full">
                      <GrowUIFormField
                        value={values.status}
                        placeholder=""
                        control={Select}
                        type="text"
                        name="status"
                        label="Status"
                        items={[
                          { key: null, value: 'Select Status' },
                          { key: 'Owned', value: 'Owned' },
                          { key: 'Leased', value: 'Leased' }
                        ]}
                      />
                    </div>
                    <div className="my-8 mr-4 w-full">
                      <GrowUIFormField
                        value={values.yearBuilt}
                        placeholder=""
                        control={Input}
                        type="text"
                        name="yearBuilt"
                        label="Year Built"
                      />
                    </div>
                    <div className="my-8 mr-4 w-full">
                      <GrowUIFormField
                        value={values.quality}
                        placeholder=""
                        control={Input}
                        type="text"
                        name="quality"
                        label="Quality"
                      />
                    </div>
                  </div>
                  <div className="flex">
                    <div className="my-4 mr-4 w-full">
                      <GrowUIFormField
                        value={values.propertyTaxId}
                        placeholder=""
                        control={Input}
                        type="text"
                        name="propertyTaxId"
                        label="Property Tax ID"
                      />
                    </div>
                    <div className="w-full mr-4" />
                    <div className="w-full mr-4" />
                    <div className="w-full mr-4" />
                  </div>
                  <div className="my-8" />
                  <GrowUIFormField
                    value={values.notes}
                    label="Notes"
                    control={TextArea}
                    id="notes"
                    name="notes"
                    className="h-32 overscroll-auto"
                  />
                </div>
              </div>
            )}
            {saving && (
              <div className="flex h-full w-full relative items-center justify-center">
                <div className="">
                  <Spinner size="lg" />
                  <div className="text-lg font-bold inline-box ml-1 mb-1">
                    {savingMessage}
                  </div>
                </div>
              </div>
            )}
            <div className="bottom-0 left-0 flex flex-row w-full space-x-4 p-4 border-t border-solid border-neutral-100 justify-end -mb-8">
              <Button
                type={ButtonType.outline}
                disabled={saving}
                onClick={onCancel}
                size="lg"
              >
                Cancel
              </Button>
              <Button
                disabled={!isValid || saving}
                type={ButtonType.primary}
                onClick={id ? () => update(values) : () => save(values)}
                size="lg"
              >
                Save Location
              </Button>
            </div>
          </div>
        )}
      </Formik>
      <AddNewCompanyPeopleDrawer
        onClose={() => setAddNewCompanyPeopleDrawerOpen(false)}
        onCompanyPeopleAdded={onOwnerCreated}
        open={addNewCompanyPeopleDrawerOpen}
      />
    </div>
  );
};
LocationForm.defaultProps = {
  id: null
};
LocationForm.propTypes = {
  id: PropTypes.number,
  onCancel: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired
};
export default LocationForm;
