export default filters => {
  const params = [];

  filters.forEach(filter => {
    let newParams;
    const name = filter.target.column;
    if (filter.conditions) {
      newParams = filter.conditions.map(({ value }) => ({ name, value }));
      params.push(...newParams);
    } else if (filter.values) {
      newParams = filter.values.map(value => ({ name, value }));
      params.push(...newParams);
    }
  });

  return params;
};
