import React from 'react';
import PropTypes from 'prop-types';
import FieldMapView from 'components/FieldMapView';
import useVendorProperty from 'screens/Integrations/hooks/useVendorProperty';
import './index.css';

const VendorPropertyMapView = ({ vendorId }) => {
  const { isLoading, feature, property } = useVendorProperty(vendorId);

  return (
    <FieldMapView
      isLoading={isLoading}
      className="rounded-lg cwfi-vendor-property-map"
      feature={feature}
      area={property?.area}
      fieldColor="#5EBB7F"
    />
  );
};

VendorPropertyMapView.propTypes = {
  vendorId: PropTypes.string.isRequired
};

export default VendorPropertyMapView;
