import React from 'react';

const TileIcon = () => (
  <svg
    className="m-auto fill-current"
    width="15"
    height="15"
    viewBox="0 0 15 15"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M1.25 0C0.559644 0 0 0.559644 0 1.25V5C0 5.69036 0.559644 6.25 1.25 6.25H5C5.69036 6.25 6.25 5.69036 6.25 5V1.25C6.25 0.559644 5.69036 0 5 0H1.25Z" />
    <path d="M1.25 8.75C0.559644 8.75 0 9.30964 0 10V13.75C0 14.4404 0.559644 15 1.25 15H5C5.69036 15 6.25 14.4404 6.25 13.75V10C6.25 9.30964 5.69036 8.75 5 8.75H1.25Z" />
    <path d="M8.75 1.25C8.75 0.559644 9.30964 0 10 0H13.75C14.4404 0 15 0.559644 15 1.25V5C15 5.69036 14.4404 6.25 13.75 6.25H10C9.30964 6.25 8.75 5.69036 8.75 5V1.25Z" />
    <path d="M10 8.75C9.30964 8.75 8.75 9.30964 8.75 10V13.75C8.75 14.4404 9.30964 15 10 15H13.75C14.4404 15 15 14.4404 15 13.75V10C15 9.30964 14.4404 8.75 13.75 8.75H10Z" />
  </svg>
);

export default TileIcon;
