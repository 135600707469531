import axios from 'axios';
import localforage from 'localforage';
import qs from 'querystring';
import { setupCache } from 'axios-cache-adapter';

const forageStore = localforage.createInstance({
  // List of drivers used
  driver: [localforage.LOCALSTORAGE],

  // Prefix all storage keys to prevent conflicts
  name: 'cwf-cache'
});

export const cache =
  setupCache?.({
    maxAge: 2 * 60 * 1000,
    store: forageStore,
    exclude: {
      query: false,
      methods: ['put', 'patch', 'delete'],
      filter: req => !req.headers['Local-Cache']
    },
    invalidate: (config, req) => {
      const method = req.method.toLowerCase();
      if (
        (method !== 'get' && method !== 'post') ||
        (method === 'post' && !req.headers['Fetch-By-Post'])
      ) {
        config.store.clear();
      }
    },
    key: req =>
      `${req.url}:${req.method}:${qs.stringify(req.params)}:${JSON.stringify(
        req.data
      )}:${req.headers['cwf-context']}`
  }) ?? {};

const instance = axios.create({
  adapter: cache.adapter
});

const getAxiosInstance = () => instance;

const axiosConfig = {
  instances: {
    default: {
      api: getAxiosInstance()
    }
  },
  interceptors: {
    request: {
      config: {
        withCredentials: false
      }
    }
  }
};

export default axiosConfig;
