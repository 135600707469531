import React, { useContext, useEffect, useState } from 'react';
import { INTEGRATIONS } from 'screens/Integrations/helpers/constants';
import { useToast } from 'components/ToastContainer';
import useCompanies from 'hooks/useCompanies';
import useFormikHandler from 'screens/Integrations/hooks/useFormikHandler';
import SubmissionFooter from 'screens/Integrations/components/SubmissionFooter';
import useSubmissionAnalytics from 'screens/Integrations/hooks/useSubmissionAnalytics';
import events from 'utilities/amplitude/events';

import { AgVendMatchContext } from './context';
import { AgVendContext } from '../context/Provider';
import { checkMatchesDuplicates, isNotMatched } from './helpers';
import AgVendMatchBoard from './components/MatchBoard';

const AgVendMatches = () => {
  const [unmatchedProducts, setUnmatchedProducts] = useState([]);
  const [unmatchedRetailers, setUnmatchedRetailers] = useState([]);
  const [productsToMatch, setProductsToMatch] = useState([]);
  const [retailersToMatch, setRetailersToMatch] = useState([]);
  const { triggerAnalyticsEvent } = useSubmissionAnalytics();
  const { loading: isLoadingCompanies } = useCompanies();

  const { selectedInvoices } = useFormikHandler();

  const toast = useToast();

  const { releaseStepForward } = useContext(AgVendContext);
  const {
    isLoading,
    state: { matches },
    fetchMatches,
    submitMatches
  } = useContext(AgVendMatchContext);

  useEffect(() => {
    if (!isLoading) {
      fetchMatches();
    }
    setProductsToMatch(
      selectedInvoices
        ?.flatMap(r => r.products)
        .reduce((acc, cur) => {
          if (acc.findIndex(p => p.externalId === cur.externalId) === -1) {
            acc.push({ ...cur, name: cur.productName });
          }
          return acc;
        }, [])
    );
    setRetailersToMatch(
      selectedInvoices?.reduce((acc, cur) => {
        if (
          acc.findIndex(r => r.externalId === cur.retailerExternalId) === -1
        ) {
          acc.push({
            name: cur.retailerName,
            retailerName: cur.retailerName,
            externalId: cur.retailerExternalId
          });
        }
        return acc;
      }, [])
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setUnmatchedRetailers(
      retailersToMatch?.filter(retailer => isNotMatched(matches, retailer))
    );
    setUnmatchedProducts(
      productsToMatch?.filter(product => isNotMatched(matches, product))
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [matches, retailersToMatch, productsToMatch]);

  const onNextHandler = async () => {
    if (
      !checkMatchesDuplicates(matches, retailersToMatch) ||
      !checkMatchesDuplicates(matches, productsToMatch)
    ) {
      toast.error('Unable to create matches', {
        supportButton: true,
        timeout: 30000,
        content:
          'The same Cropwise Product or Company was selected. Please choose different Product or Company for each match.'
      });
      return;
    }

    await submitMatches();

    triggerAnalyticsEvent(
      events.epic.Integrations.IncomingInvoice.invoiceMatch,
      {
        vendor: INTEGRATIONS.agVend
      }
    );
  };

  useEffect(() => {
    if (!unmatchedProducts?.length && !unmatchedRetailers?.length) {
      releaseStepForward();
    }
  }, [unmatchedProducts, unmatchedRetailers, releaseStepForward]);

  const nextDisabled =
    isLoading ||
    unmatchedProducts?.length ||
    unmatchedRetailers?.length ||
    isLoadingCompanies;

  return (
    <>
      <AgVendMatchBoard
        unMatchedEntities={unmatchedRetailers}
        entitiesToMatch={retailersToMatch}
        matchType="Company"
        title="Match Companies"
        description="Match retailers from your AgVend invoices to companies in your Cropwise Financials list."
      />
      <AgVendMatchBoard
        unMatchedEntities={unmatchedProducts}
        entitiesToMatch={productsToMatch}
        matchType="Product"
        title="Match Products"
        description="Match products from your AgVend invoices to products on the Cropwise Financials product list."
      />
      <SubmissionFooter
        onNext={onNextHandler}
        loading={isLoading}
        nextLabel="Next: Review & Import"
        nextDisabled={nextDisabled}
        integrationType={INTEGRATIONS.agVend}
        isBottomFixed
        isLastStep={false}
        context={AgVendContext}
      />
    </>
  );
};

export default AgVendMatches;
