export const filterItems = (item, values) => {
  if (values.length === 1) {
    return item.name.toLowerCase().includes(values[0].toLowerCase());
  }
  return item.name.toLowerCase().includes(values[0].toLowerCase());
};

export const findCrops = (cropSeason, value) => {
  if (value) {
    const values = value.match(/[^ ]+/g);
    const filteredItems = [];
    const foundItem = cropSeason.filter(item => {
      return filterItems(item, values);
    });
    if (foundItem) {
      foundItem.forEach(fi => {
        filteredItems.push(fi);
      });
    }
    return filteredItems;
  }
  return cropSeason;
};
export const dateRangeFilter = (cropSeasons, filteredDate) => {
  return cropSeasons.filter(ele => {
    return (
      filteredDate[0].getTime() <= ele.createdDateEpoch &&
      filteredDate[1].getTime() >= ele.createdDateEpoch
    );
  });
};

export const filterCropSeason = (selectedCropSeason, cropSeasons = []) => {
  return selectedCropSeason
    ? cropSeasons.filter(season => selectedCropSeason.includes(season.id))
        .length
    : 1;
};
