import { PropTypes } from 'prop-types';
import React, { memo } from 'react';

const StoreIcon = ({ size, color }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      viewBox="0 0 18 16"
      fill="none"
      data-testid="company-icon"
      className={`fill-current text-${color}`}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1 0H17V2H1V0ZM15.96 8L15.36 5H2.64004L2.04004 8H15.96ZM17 3H1L0 8V10H1V16H11V10H15V16H17V10H18V8L17 3ZM3 10V14H9V10H3Z"
      />
    </svg>
  );
};

StoreIcon.propTypes = {
  color: PropTypes.string,
  size: PropTypes.number
};

StoreIcon.defaultProps = {
  color: 'neutral-600',
  size: 20
};

export default memo(StoreIcon);
