import { Factory } from 'miragejs';
import { parse } from 'qs';
import iso from 'iso-3166-1';
import faker from 'faker';
import globalSchema from '../utils/globalSchema';

const TEST_ORG_ID = process.env.REACT_APP_DEVTEST_ORGANIZATIONID;

const User = {
  oktaId() {
    return faker.random.boolean() ? '' : faker.random.uuid();
  },
  oktaEmail() {
    return faker.internet.email();
  },
  organizationId: '',
  licenses() {
    return Array.from(
      { length: faker.random.number({ min: 1, max: 3 }) },
      () => {
        const licenseType = faker.random.arrayElement([
          'Applicator',
          'Planter',
          'Harvestor'
        ]);
        return {
          _id: faker.random.uuid(),
          licenseType,
          value: licenseType.charAt(0).toLowerCase(),
          expiry: faker.date.future(faker.random.number({ max: 5 }))
        };
      }
    );
  },
  contactInfo() {
    const givenName = faker.name.firstName();
    const middleInit = faker.name.firstName().charAt(0);
    const surname = faker.name.lastName();
    const prefix = faker.random.boolean()
      ? faker.random.arrayElement(['Dr', 'Mr', 'Mrs', 'Ms', 'Miss'])
      : '';
    const suffix = faker.random.boolean()
      ? faker.random.arrayElement(['II', 'III', 'IV', 'Jr', 'Sr'])
      : '';
    const email = faker.internet.email(givenName, surname);
    return {
      name: {
        givenName,
        middleInit,
        surname,
        prefix,
        suffix
      },
      notes: '',
      // 80% 1 address : 20% 2 addresses
      addresses: Array.from(
        { length: faker.random.number({ max: 10 }) / 8 <= 1 ? 1 : 2 },
        () => ({
          address1: faker.address.streetAddress(),
          address2: faker.random.boolean()
            ? faker.address.secondaryAddress()
            : '',
          postalCode: faker.address.zipCode(),
          city: faker.address.city(),
          country: iso.whereAlpha2(faker.address.countryCode()).alpha3,
          state: faker.address.stateAbbr(),
          addressType: ''
        })
      ),
      emails: [
        email,
        this.oktaEmail,
        ...(faker.random.boolean() ? [] : [faker.internet.email()])
      ],
      phoneNumbers: Array.from(
        { length: faker.random.boolean() ? 1 : 2 },
        () => ({
          phoneType: faker.random.boolean()
            ? 'mobile'
            : faker.random.arrayElement(['home', 'work', 'fax', 'pager']),
          value: faker.phone.phoneNumber(),
          name: ''
        })
      )
    };
  },
  ...globalSchema,
  afterCreate(user, server) {
    if (user.attrs.organizationId === '') {
      user.update({ organizationId: TEST_ORG_ID });
    }
    server.createList('invitation', 3, {
      name: `${user.attrs.contactInfo.name.givenName} ${user.attrs.contactInfo.name.surname}`,
      email: user.attrs.oktaEmail
    });
    // allow the roles to be specified in the url params when in mirage mode
    const testSettings = window.location.search
      ? parse(window.location.search.substr(1))
      : {};
    server.create('permission', {
      organizationId: user.attrs.organizationId,
      oktaId: user.attrs.oktaId,
      role: testSettings.role || 'admin',
      financial: testSettings.financial || 'none'
    });
  }
};

export default Factory.extend(User);
