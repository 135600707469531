import { MEASUREMENT_UNITS } from 'helpers/unitsAbbreviation';
import { SHRINK_TYPE } from 'screens/YieldV2/constants';
import { decimalToPercent } from 'utilities/numberHelpers';

const convertLoadToFormValues = apiLoadValues => {
  const { weightUnit, ...values } = apiLoadValues;
  const numericKeysToParse = [
    'grossWeight',
    'tareWeight',
    'netWeight',
    'load',
    'salePrice',
    'grossRevenue',
    'testWeight',
    'harvestedQuantity'
  ];

  const decimalValuesToPercent = ['damage', 'foreignMaterial', 'moisture'];

  if (values.shrinkType === SHRINK_TYPE.BY_PERCENT) {
    decimalValuesToPercent.push('shrink');
  } else {
    numericKeysToParse.push('shrink');
  }

  const parsedValues = Object.keys(values).reduce((acc, key) => {
    if (numericKeysToParse.includes(key) && values[key] !== null) {
      acc[key] = parseFloat(values[key]);
    } else if (decimalValuesToPercent.includes(key) && values[key] !== null) {
      acc[key] = decimalToPercent(parseFloat(values[key]));
    } else {
      acc[key] = values[key];
    }
    return acc;
  }, {});

  return {
    ...parsedValues,
    weightUnit: weightUnit || MEASUREMENT_UNITS.POUND
  };
};

export default convertLoadToFormValues;
