export const SET_STEP = 'SET_STEP';
export const SET_VENDER_ID = 'SET_VENDER_ID';
export const SET_PROPERTIES_GROUPED_BY = 'SET_PROPERTIES_GROUPED_BY';
export const SET_SELECTED_PROPERTIES = 'SET_SELECTED_PROPERTIES';
export const SET_OPEN_SUBMISSION_MODAL = 'SET_OPEN_SUBMISSION_MODAL';
export const SET_OPEN_RESUBMISSION_MODAL = 'SET_OPEN_RESUBMISSION_MODAL';
export const SET_SUBMISSION_FLOW_ID = 'SET_SUBMISSION_FLOW_ID';

const reducer = (state, action) => {
  switch (action.type) {
    case SET_STEP:
      return {
        ...state,
        step: action.payload
      };
    case SET_PROPERTIES_GROUPED_BY:
      return {
        ...state,
        propertiesGroupedBy: action.payload
      };
    case SET_SELECTED_PROPERTIES:
      return {
        ...state,
        selectedProperties: action.payload
      };
    case SET_OPEN_SUBMISSION_MODAL:
      return {
        ...state,
        openSubmissionModal: action.payload
      };
    case SET_OPEN_RESUBMISSION_MODAL:
      return {
        ...state,
        openResubmissionModal: action.payload
      };
    case SET_SUBMISSION_FLOW_ID:
      return {
        ...state,
        submissionFlowId: action.payload
      };
    default:
      return state;
  }
};

export default reducer;
