import { Spinner } from '@agconnections/grow-ui';
import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import useYieldData from 'screens/Yield/hooks/useYieldData';
import getSeasons from '../YieldActions/helpers/getSeasons';
import RevenueInformation from './components/RevenueInformation';
import YieldInformation from './components/YieldInformation';

const YieldSummary = ({ selectedCommodity, displayedYieldEntries }) => {
  const {
    loadDashboard,
    isLoadingYieldEntryDashboard,
    yieldDashboardData
  } = useYieldData();

  useEffect(() => {
    if (selectedCommodity.current?.id) {
      loadDashboard({
        seasonIds: getSeasons(),
        commodityId: selectedCommodity.current.id
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedCommodity.current, displayedYieldEntries]);

  return (
    <div
      className="flex flex-row w-full bg-white shadow-documentation-card"
      style={{
        borderRadius: '8px',
        paddingLeft: '34px',
        paddingRight: '34px',
        paddingTop: '24px',
        paddingBottom: '24px'
      }}
      data-testid="yield-summary"
    >
      {isLoadingYieldEntryDashboard ||
      !Object.keys(yieldDashboardData).length ? (
        <Spinner />
      ) : (
        <>
          <RevenueInformation data={yieldDashboardData} />
          <YieldInformation data={yieldDashboardData} />
        </>
      )}
    </div>
  );
};

YieldSummary.defaultProps = {
  selectedCommodity: {
    current: null
  }
};

YieldSummary.propTypes = {
  displayedYieldEntries: PropTypes.shape({
    totalAcreage: PropTypes.number.isRequired,
    totalFields: PropTypes.number.isRequired,
    totalGrossRevenue: PropTypes.number.isRequired,
    units: PropTypes.arrayOf(
      PropTypes.shape({
        unit: PropTypes.string,
        totalYield: PropTypes.number,
        avgPricePerUnit: PropTypes.number
      })
    )
  }).isRequired,
  selectedCommodity: PropTypes.shape({
    current: PropTypes.shape({ id: PropTypes.string })
  })
};

export default YieldSummary;
