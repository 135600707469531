import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { FETCH_TYPES } from 'helpers/constants';
import { useFormikContext } from 'formik';
import usePopulateMapData from 'hooks/usePopulateMapData';
import GroupByProperties from './components/GroupByProperties';

const Properties = ({ cropSeasons }) => {
  const { values, setFieldValue } = useFormikContext();
  const filteredCropSeasons = values.selectedCropSeasons?.map(
    cropSeason => cropSeason?.id
  );

  const { dataSorted, loading, reloadData } = usePopulateMapData({
    fetchType: FETCH_TYPES.cropSeasonsMap
  });
  const [mappedData, setMappedData] = useState({});
  const [checked, setChecked] = useState([]);

  const handleCheck = checkedIds => {
    setChecked(checkedIds);
  };

  useEffect(() => {
    if (filteredCropSeasons.length) {
      reloadData({ seasonIds: filteredCropSeasons });
    }
    // eslint-disable-next-line
  }, [values.selectedCropSeasons]);

  useEffect(() => {
    if (!loading) {
      setMappedData(dataSorted);
    }
    // eslint-disable-next-line
  }, [loading]);

  useEffect(() => {
    if (mappedData.properties?.length > 0 && cropSeasons?.length > 0) {
      const cropzones = [];
      checked.forEach(id => {
        let cropzoneMatch;
        mappedData.properties.forEach(property => {
          property.fields.forEach(field => {
            cropzoneMatch = field.cropzones.find(
              cropzone => cropzone.id === id
            );

            if (!cropzoneMatch) return;

            cropzones.push({
              ...cropzoneMatch,
              propertyName: property.name,
              fieldName: field.name
            });
          });
        });
      });

      setFieldValue('selectedCropzones', cropzones);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [checked]);

  return (
    <div className="flex flex-col items-center bg-white">
      {mappedData.properties?.length > 0 && cropSeasons?.length > 0 && (
        <div className="w-full">
          <h1
            className="font-body font-semibold text-xl"
            style={{ color: '#14151C' }}
          >
            Properties
          </h1>
          <GroupByProperties
            mappedData={mappedData}
            loading={loading}
            handleCheck={handleCheck}
            checked={checked}
            setChecked={setChecked}
          />
        </div>
      )}
    </div>
  );
};

Properties.defaultProps = {
  cropSeasons: []
};

Properties.propTypes = {
  cropSeasons: PropTypes.arrayOf(PropTypes.object)
};

export default Properties;
