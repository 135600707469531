import React from 'react';

import SimplotLogo from 'assets/integrations/SimplotLogo.svg';
import SubmissionHistory from 'screens/Integrations/SubmissionHistory';
import { INTEGRATIONS } from 'screens/Integrations/helpers/constants';
import Provider from 'screens/Integrations/SubmissionHistory/context/Provider';
import LandingPage from 'screens/Integrations/components/LandingPage';
import Instructions from 'screens/Integrations/components/Instructions';

export const SIMPLOT_STEPS = [
  {
    number: 1,
    header: 'Sign In',
    description: 'Authenticate with your vendor credentials'
  },
  {
    number: 2,
    header: 'Select Organization',
    description:
      'Select an organization which you would like to submit Task records'
  },
  {
    number: 3,
    header: 'Select Crop Seasons',
    description:
      'Select the Crop Seasons with which you would like to submit Task records'
  },
  {
    number: 4,
    header: 'Select Properties',
    description:
      'Select the Properties with Tasks records you would like to submit'
  },
  {
    number: 5,
    header: 'Match Properties',
    description:
      'Match the Cropwise Financials properties with the integration vendor'
  },
  {
    number: 6,
    header: 'Match Products',
    description:
      'Match the Cropwise Financials products with the integration vendor'
  },
  {
    number: 7,
    header: 'Review and Submit',
    description:
      'Review your Task records and then submit the information to the processor'
  }
];

const SimplotLandingPage = () => {
  return (
    <>
      <LandingPage
        headerText={`${INTEGRATIONS.simplot} Processor Integration`}
        breadcrumbText={INTEGRATIONS.simplot}
        ownerText={INTEGRATIONS.simplot}
        targetPath={INTEGRATIONS.simplot}
        LogoImg={SimplotLogo}
      />
      <div className="w-full border-t-1 mb-5" />
      <Provider integrationType={INTEGRATIONS.simplot}>
        <SubmissionHistory />
      </Provider>
      <Instructions steps={SIMPLOT_STEPS} />
    </>
  );
};

export default SimplotLandingPage;
