import React from 'react';
import PropTypes from 'prop-types';
import { Button } from '@agconnections/grow-ui';

const DeleteModalTrigger = ({
  isCreateScreen,
  redirectPath,
  openModal,
  setRedirect
}) => {
  return (
    <div>
      <Button
        type="primary"
        ghost
        data-testid={isCreateScreen ? `cancelButton` : `deleteButton`}
        onClick={
          isCreateScreen
            ? () => {
                setRedirect(redirectPath);
              }
            : openModal
        }
      >
        {isCreateScreen ? 'Cancel' : 'Delete'}
      </Button>
    </div>
  );
};

DeleteModalTrigger.defaultProps = {
  isCreateScreen: false,
  redirectPath: ''
};

DeleteModalTrigger.propTypes = {
  isCreateScreen: PropTypes.bool,
  redirectPath: PropTypes.string,
  setRedirect: PropTypes.func.isRequired,
  openModal: PropTypes.func.isRequired
};

export default DeleteModalTrigger;
