import { isUndefined } from 'lodash';

const reduceNestedItems = (itemMap, { id }, nestedItems = [], defaultVal) => {
  let newItems = { [id]: !isUndefined(itemMap[id]) ? itemMap[id] : defaultVal };

  if (nestedItems.length) {
    nestedItems.forEach(el => {
      newItems = {
        ...newItems,
        ...reduceNestedItems(itemMap, el, el.$nestedItems, defaultVal)
      };
    });
  }
  return newItems;
};

const reduceAndMapNestedItems = (itemMap, item, nestedItems = [], field) => {
  const { id } = item;
  let newItems = {
    [id]: !isUndefined(itemMap[id]) ? itemMap[id] : item[field]
  };

  if (nestedItems.length) {
    nestedItems.forEach(el => {
      newItems = {
        ...newItems,
        ...reduceAndMapNestedItems(itemMap, el, el.$nestedItems, field)
      };
    });
  }
  return newItems;
};

export const createAppliedAreaMap = landItems => {
  return landItems.reduce((acc, el) => {
    return { ...acc, ...reduceNestedItems(acc, el, el.$nestedItems, 0) };
  }, {});
};

export const createCoverageMap = landItems => {
  return landItems.reduce((acc, el) => {
    return { ...acc, ...reduceNestedItems(acc, el, el.$nestedItems, 0) };
  }, {});
};

export const createAcresMap = landItems => {
  return landItems.reduce((acc, el) => {
    return {
      ...acc,
      ...reduceAndMapNestedItems(acc, el, el.$nestedItems, 'acres')
    };
  }, {});
};
