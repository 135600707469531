import React from 'react';
import PropTypes from 'prop-types';
import noNotesFound from 'assets/noNotesFound.png';
import { Button } from '@agconnections/grow-ui';
import AddIcon from '@material-ui/icons/Add';

const ProductsSearchModal = ({
  toggleCreateCustomProductModal,
  productsLength,
  searchTextLength,
  isLoading
}) => {
  const displayText =
    (productsLength === 0 && searchTextLength < 3) || isLoading
      ? 'Search a word or phrase and we will show you a list of products to choose from'
      : 'No product found. Try your search again.';

  return (
    <div className="mt-6 border">
      <div className="flex items-center justify-center">
        <img className="w-full" src={noNotesFound} alt="" />
      </div>
      <div className="ml-6 mr-6">
        <p className="text-sm text-center">{displayText}</p>
      </div>
      {productsLength === 0 && searchTextLength > 0 && (
        <div className="m-6 flex justify-center items-center">
          <Button
            id="create-custom-product-button"
            onClick={toggleCreateCustomProductModal}
            size="sm"
            type="primary"
          >
            <AddIcon style={{ fontSize: 16 }} />
            <div className="ml-1 whitespace-no-wrap text-xs">Create Custom</div>
          </Button>
        </div>
      )}
    </div>
  );
};

ProductsSearchModal.propTypes = {
  toggleCreateCustomProductModal: PropTypes.func.isRequired,
  productsLength: PropTypes.number.isRequired,
  searchTextLength: PropTypes.number.isRequired,
  isLoading: PropTypes.bool.isRequired
};

export default ProductsSearchModal;
