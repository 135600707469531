import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import EmptySummary from 'screens/Property/PropertiesLanding/PropertyLandingMap/PropertyMapWrapper/PropertyMap/ChangeSummary/components/EmptySummary';
import { IntegrationsContext } from 'screens/Integrations/context/IntegrationsProvider';
import PropertyMapWrapper from 'screens/Property/PropertiesLanding/PropertyLandingMap/PropertyMapWrapper';
import { getTotalAreaFromFeatures } from 'helpers/getTotalAreaAcres';
import SelectedPropertyInfo from '../SelectedPropertyInfo';

const PropertyMapView = ({
  geoJSONCollection,
  zoom,
  setZoom,
  fieldsLoading
}) => {
  const {
    state: { selectedProperties }
  } = useContext(IntegrationsContext);

  const baseText =
    'Select the properties from the left that you would like to submit';
  return (
    <>
      <div className="w-2/4 flex h-560px" data-testid="property-map-view">
        <PropertyMapWrapper
          fieldsLoading={fieldsLoading}
          geoJSONCollection={geoJSONCollection}
          zoom={zoom}
          setZoom={setZoom}
          customStyles={selectedProperties.length > 0}
          stretch
        />
      </div>
      <div className="w-1/4 overflow-y-auto h-560px">
        <div className="flex flex-row justify-between pl-8 pb-6 font-semibold">
          <span>SELECTED PROPERTIES</span>
          <span>{getTotalAreaFromFeatures(selectedProperties)} ac</span>
        </div>
        <div className="ml-8 border-2">
          {selectedProperties.length === 0 ? (
            <EmptySummary baseText={baseText} />
          ) : (
            selectedProperties.map(property => (
              <SelectedPropertyInfo
                key={property.properties.id}
                property={property.properties}
              />
            ))
          )}
        </div>
      </div>
    </>
  );
};

PropertyMapView.propTypes = {
  fieldsLoading: PropTypes.bool.isRequired,
  geoJSONCollection: PropTypes.shape({
    type: PropTypes.string,
    features: PropTypes.arrayOf(PropTypes.object)
  }),
  zoom: PropTypes.arrayOf(PropTypes.number).isRequired,
  setZoom: PropTypes.func.isRequired
};

PropertyMapView.defaultProps = {
  geoJSONCollection: {
    type: 'FeatureCollection',
    features: []
  }
};

export default PropertyMapView;
