import React, { useState, useEffect } from 'react';
import { Route, Redirect, BrowserRouter as Router } from 'react-router-dom';
import { useFlags, withLDProvider } from 'launchdarkly-react-client-sdk';
import { Spinner } from '@agconnections/grow-ui';
import 'syngenta-digital-cropwise-react-ui-kit/dist/styles/cw-ui-kit.css';

import routes from 'routes';
import Store from 'components/Store';
import { isAccessTokenAvailable, useAuth } from 'utilities/base-auth';
import useZendesk from 'hooks/useZendesk';
import { paths } from 'routes/paths';
import ToastContainer from 'components/ToastContainer';

const ldUser = {
  key: 'user_key',
  name: 'User name',
  email: 'user@email.com'
};

const AppRouter = () => {
  const [isLoading, setIsLoading] = useState(isAccessTokenAvailable());
  const { fetchUserProfile, user, logout } = useAuth();
  const {
    feLandManagementUndergoingMaintenanceMessaging: maintenanceLandingFF
  } = useFlags();

  ldUser.userId = user?.id;
  ldUser.userEmail = user?.email;

  const { createChatWidget } = useZendesk();

  useEffect(() => {
    if (maintenanceLandingFF && maintenanceLandingFF !== '') {
      if (user) logout();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [maintenanceLandingFF]);

  useEffect(() => {
    if (isLoading) {
      fetchUserProfile();
    }
  }, [isLoading, fetchUserProfile]);

  useEffect(() => {
    if (user) {
      setIsLoading(false);
      createChatWidget();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);

  return (
    <Router>
      {isLoading ? (
        <div className="w-full h-screen m-auto">
          <Spinner />
        </div>
      ) : (
        <>
          <Route exact path="/">
            <Redirect to={paths.properties} />
          </Route>

          <Route exact path="/app">
            <Redirect to={paths.properties} />
          </Route>

          {routes.map(route => {
            return route.secure ? (
              <Route
                key={route.key}
                path={route.path}
                exact={route.exact}
                render={() =>
                  isAccessTokenAvailable() ? (
                    <Store>
                      <ToastContainer />
                      <route.component routes={route.routes} />
                    </Store>
                  ) : (
                    <Redirect to="/welcome" />
                  )
                }
              />
            ) : (
              <Route
                key={route.key}
                path={route.path}
                exact={route.exact}
                render={() => <route.component routes={route.routes} />}
              />
            );
          })}
        </>
      )}
    </Router>
  );
};

const App = () => (
  <div data-testid="app" className="min-w-1024">
    <Router>
      <AppRouter />
    </Router>
  </div>
);

export default withLDProvider({
  clientSideID: process.env.REACT_APP_LC_CLIENT_KEY,
  user: ldUser
})(App);
