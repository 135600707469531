import React from 'react';
import { TextArea } from '@agconnections/grow-ui';
import GrowUIFormField from 'components/Forms/GrowUIFormField';

const InvoiceNotesSection = () => {
  return (
    <>
      <GrowUIFormField
        control={TextArea}
        name="notes"
        id="notes"
        maxLength="1000"
        className="h-32 overscroll-auto"
        resizeDisabled
      />
    </>
  );
};

export default InvoiceNotesSection;
