import React from 'react';
import PropTypes from 'prop-types';
import { Button } from '@agconnections/grow-ui';
import AddRoundedIcon from '@material-ui/icons/AddRounded';
import { paths } from 'routes/paths';
import { useHistory } from 'react-router-dom';

const CreateInvoiceButton = ({ hasPermission }) => {
  const history = useHistory();

  if (!hasPermission()) return null;
  return (
    <Button
      id="add-invoice-button"
      type="primary"
      onClick={event => {
        event.stopPropagation();
        history.push(`${paths.invoices}/invoice`);
      }}
      icon={<AddRoundedIcon />}
    >
      Create Invoice
    </Button>
  );
};

CreateInvoiceButton.propTypes = {
  hasPermission: PropTypes.func.isRequired
};

export default CreateInvoiceButton;
