import { useReducer, useCallback } from 'react';
import { isFunction } from 'lodash';

const UPDATE_INTERNAL_CHANGES = 'update_internal';
const UPDATE_EXTERNAL_CHANGES = 'update_external';

const defaultComparator = (oldValue, newValue) => oldValue === newValue;

const initState = {
  internalChanges: undefined,
  externalChanges: undefined,
  value: undefined,
  comparator: defaultComparator
};

const setState = (previousValue, newValue, comparator) => {
  const result = isFunction(newValue) ? newValue(previousValue) : newValue;
  if (comparator(previousValue, result)) {
    return previousValue;
  }
  return result;
};

const reducer = (state, { type, payload }) => {
  if (type === UPDATE_INTERNAL_CHANGES) {
    const internalChanges = setState(state.value, payload, state.comparator);
    return {
      comparator: state.comparator,
      externalChanges: undefined,
      internalChanges,
      value: internalChanges
    };
  }
  if (type === UPDATE_EXTERNAL_CHANGES) {
    const externalChanges = setState(state.value, payload, state.comparator);
    return {
      comparator: state.comparator,
      internalChanges: undefined,
      externalChanges,
      value: externalChanges
    };
  }
  return state;
};

/**
 * can be used instead of useState when you need 2 distinct setState functions for different use cases
 * - an internal setState that also sets the value of internalChanges (e.g. changes to report to the parent onChange prop)
 * - an external setState that sets internalChanges to undefined (e.g. changes that should not trigger onChange)
 * @param initialValue - same usage as the initial value passed to useState
 * @param changeComparator - optional - comparator function to make state changes more efficient and result in fewer renders
 * @returns Array<*> & { 0: {value: *}, 1: {internalSetState: Function}, 2: {externalSetState: Function}, 3: {internalChanges: *}, 4: {externalChanges: *}, length: 5 }
 */
const useTwoWayState = (initialValue = undefined, changeComparator) => {
  const initialState = {
    ...initState,
    value: initialValue,
    comparator: changeComparator || defaultComparator
  };
  const [{ value, internalChanges, externalChanges }, dispatch] = useReducer(
    reducer,
    initialState,
    () => initialState
  );

  const internalSetState = useCallback(newValue => {
    dispatch({
      type: UPDATE_INTERNAL_CHANGES,
      payload: newValue
    });
  }, []);

  const externalSetState = useCallback(newValue => {
    dispatch({
      type: UPDATE_EXTERNAL_CHANGES,
      payload: newValue
    });
  }, []);

  return [
    value,
    internalSetState,
    externalSetState,
    internalChanges,
    externalChanges
  ];
};

export default useTwoWayState;
