import { orderBy } from 'lodash';

export function getArchivedCropZones(propertyLandingPageData) {
  const fn = p => {
    if (p.fields) {
      return p.fields.map(fn).flat();
    }
    if (p.cropzones) {
      return p.cropzones.map(fn).flat();
    }
    return p.archived ? [p.id] : [];
  };
  return propertyLandingPageData?.properties.map(fn).flat();
}

export function filterArchived(propertyLandingPageData, initialCropzoneIds) {
  const orderByArchived = arr => orderBy(arr, 'archived');
  const getAllCropZones = p => {
    if (p.fields) {
      return p.fields.map(getAllCropZones).flat();
    }
    if (p.cropzones) {
      return p.cropzones.map(getAllCropZones).flat();
    }
    return [p.id];
  };
  const isElementAllowed = el => {
    if (!el.archived) {
      return true;
    }
    const cropzones = getAllCropZones(el);
    // want to show archived items anyway? return true here!
    return (
      cropzones.length > 0 &&
      cropzones.find(cz => initialCropzoneIds?.indexOf(cz) !== -1)
    );
  };
  return propertyLandingPageData?.properties
    .filter(isElementAllowed)
    .map(property => ({
      ...property,
      fields: orderByArchived(
        property.fields.filter(isElementAllowed).map(field => ({
          ...field,
          cropzones: orderByArchived(field.cropzones.filter(isElementAllowed))
        }))
      )
    }));
}
