// eslint-disable-next-line import/prefer-default-export
export const handleNameChange = (event, setName, setError) => {
  const regex = /^\s+$/;
  if (!event.target.value > 0 || regex.test(event.target.value)) {
    setError('Required');
    setName(event.target.value);
  } else {
    setError('');
    setName(event.target.value);
  }
};
