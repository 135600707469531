/* eslint-disable react/jsx-pascal-case */
import React from 'react';
import Toast from 'components/Toast';
import PropTypes from 'prop-types';
import SupportButton from 'components/SupportButton';

const SaleLocationToast = ({ showToast, setShowToast, state }) => {
  const renderTitle = _state => {
    let message;
    if (_state === 'error') {
      message = 'Unable to save changes.';
    } else if (_state === 'success') {
      message = 'Changes saved.';
    } else if (_state === 'successDelete') {
      message = 'Location deleted.';
    } else if (_state === 'failDelete') {
      message = 'Unable to delete location.';
    }
    return message;
  };

  const iconType = value => {
    switch (value) {
      case 'failDelete':
        return 'error';
      case 'successDelete':
        return 'success';
      default:
        return value;
    }
  };

  return (
    <Toast
      type={iconType(state)}
      title={renderTitle(state)}
      open={showToast}
      onClose={() => {
        setShowToast(false);
      }}
      timeout={5500}
      isBold
    >
      {(state === 'error' || state === 'failDelete') && (
        <div>
          <p className="text-sm font-normal mb-2">
            {state === 'error'
              ? 'An error prevented your information from being saved. Please try again.'
              : 'An error prevented your location from being deleted. Please try again.'}
          </p>
          <SupportButton />
        </div>
      )}
    </Toast>
  );
};

SaleLocationToast.propTypes = {
  showToast: PropTypes.bool.isRequired,
  setShowToast: PropTypes.func.isRequired,
  state: PropTypes.string.isRequired
};

export default SaleLocationToast;
