import React from 'react';
import PropTypes from 'prop-types';
import NoSearchResults from 'assets/no_search_results_found.svg';
import EmptyTable from 'assets/empty_inventory_table.svg';
import PlusIcon from 'assets/plus.svg';
import { Button } from '@agconnections/grow-ui';

const ProductDetailTableEmptyState = ({
  totalItemCount,
  type,
  onButtonClick
}) => {
  const isEmptyBecauseFiltering = totalItemCount > 0;

  let data = {
    title: 'No Invoices Found',
    desc: 'We were unable to find any invoices including this product.',
    action: 'Create Invoice'
  };

  switch (type) {
    case 'invoices':
      break;
    case 'completed_tasks':
      data = {
        title: 'No Completed Tasks Found',
        desc:
          'We were unable to find any completed tasks including this product.',
        action: 'Create Task'
      };
      break;
    default:
      break;
  }

  return (
    <div className="grid place-items-center w-full h-full">
      <div className="flex flex-col items-center">
        {isEmptyBecauseFiltering ? (
          <>
            <img
              width={260}
              src={NoSearchResults}
              alt="no results found for filter"
            />
            <h3 className="max-w-md text-center break-words">
              We were unable to find any products using the selected filters.
              Try a different crop season and/or date range selection.
            </h3>
          </>
        ) : (
          <>
            <img
              width={260}
              src={EmptyTable}
              alt="no results found for filter"
            />
            <span className="max-w-lg text-center break-words">
              <h3 className="font-semibold text-sm ">{data.title}</h3>
              <p className="text-sm mt-2">{data.desc}</p>
            </span>
            <div className="mt-4">
              <Button
                onClick={onButtonClick}
                icon={<img alt="plus icon" src={PlusIcon} width={18} />}
                type="primary"
              >
                {data.action}
              </Button>
            </div>
          </>
        )}
      </div>
    </div>
  );
};

ProductDetailTableEmptyState.propTypes = {
  totalItemCount: PropTypes.number.isRequired,
  type: PropTypes.string,
  onButtonClick: PropTypes.func
};

ProductDetailTableEmptyState.defaultProps = {
  type: 'invoices',
  onButtonClick: () => {}
};

export default ProductDetailTableEmptyState;
