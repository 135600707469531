/* eslint-disable no-unused-vars */
import { cloneDeep } from 'lodash';

export default server => {
  server.get('organizations/:id/tasks', (schema, request) => {
    return schema.tasks.all();
  });

  server.get('organizations/:id/tasks/:taskId', (schema, request) => {
    const { taskId } = request.params;

    return schema.tasks.find(taskId) || schema.tasks.first();
  });
};
