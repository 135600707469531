import { useContext, useEffect, useState } from 'react';
import axios from 'axios';
import { getAccessToken } from 'utilities/base-auth';
import catchCancel from 'helpers/catchCancel';
import { parseServerError } from 'helpers/errorHelpers';
import { Context } from 'components/Store';
import { MASTERLIST_PROXY_URL } from 'utilities/apiConstants';

const getPests = searchText =>
  axios.get(`${MASTERLIST_PROXY_URL}/pests/search?pestName=${searchText}`, {
    headers: {
      common: {
        Authorization: `Bearer ${getAccessToken()}`
      }
    }
  });

const usePestData = (searchText, minSearchTextLength = 3) => {
  const [, dispatch] = useContext(Context);
  const [pests, setPests] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const trimmedSearchText = (searchText ?? '').trim();
    if (trimmedSearchText.length < minSearchTextLength) {
      return;
    }

    setLoading(true);

    getPests(trimmedSearchText)
      .then(response => {
        setPests(response.data);
      })
      .catch(catchCancel)
      .catch(err => {
        parseServerError(dispatch)(err);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [dispatch, searchText, minSearchTextLength]);

  return { pests, loading };
};

export default usePestData;
