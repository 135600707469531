import React from 'react';
import PropTypes from 'prop-types';
import { Columns, Column } from '@agconnections/grow-ui';
import { startCase } from 'lodash';

import ViewOnlyField from 'components/ViewOnlyField';
import Compass from './components/Compass';

const WeatherCard = ({
  isStart,
  date,
  time,
  windSpeed,
  windDirection,
  speedUnit,
  skyCondition,
  temperature,
  tempUnit,
  soilMoisture,
  humidity
}) => {
  return (
    <div
      className="mt-3 bg-white border rounded pl-4"
      style={{ width: '347px' }}
    >
      <Columns>
        <Column width="w-1/2">
          <div className="my-4 ml-4">
            <ViewOnlyField
              header={`${isStart ? 'Start' : 'End'} Date`}
              data={date}
            />
          </div>
        </Column>
        <Column width="w-1/2">
          <div className="my-4 ml-10">
            <ViewOnlyField
              header={`${isStart ? 'Start' : 'End'} Time`}
              data={time}
            />
          </div>
        </Column>
      </Columns>
      <Compass
        windSpeed={windSpeed}
        windDirection={windDirection}
        speedUnit={speedUnit}
      />
      <Columns>
        <Column width="w-1/2">
          <div className="my-4 ml-4 flex flex-wrap">
            <ViewOnlyField
              header="Sky Condition"
              data={startCase(skyCondition)}
            />
          </div>
          <div className="my-4 ml-4">
            <ViewOnlyField header="Temp" data={`${temperature}° ${tempUnit}`} />
          </div>
        </Column>
        <Column width="w-1/2">
          <div className="my-4 ml-10">
            <ViewOnlyField header="Soil Moisture" data={soilMoisture} />
          </div>
          <div className="my-4 ml-10">
            <ViewOnlyField header="Humidity" data={`${humidity}%`} />
          </div>
        </Column>
      </Columns>
    </div>
  );
};

WeatherCard.defaultProps = {
  isStart: true,
  date: '',
  time: '',
  windSpeed: 0,
  windDirection: 0,
  speedUnit: 'mph',
  skyCondition: '',
  temperature: 0,
  tempUnit: 'F',
  soilMoisture: 'unknown',
  humidity: 0
};
WeatherCard.propTypes = {
  isStart: PropTypes.bool,
  date: PropTypes.string,
  time: PropTypes.string,
  windSpeed: PropTypes.number,
  windDirection: PropTypes.number,
  speedUnit: PropTypes.string,
  skyCondition: PropTypes.string,
  temperature: PropTypes.number,
  tempUnit: PropTypes.oneOf(['F', 'C']),
  soilMoisture: PropTypes.string,
  humidity: PropTypes.number
};

export default WeatherCard;
