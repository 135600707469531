// eslint-disable-next-line import/prefer-default-export
export const Colors = [
  {
    name: 'green',
    hex: '#5EBB7F'
  },
  {
    name: 'yellow',
    hex: '#E99921'
  },
  {
    name: 'orange',
    hex: '#FF785A'
  },
  {
    name: 'purple',
    hex: '#BA99F6'
  },
  {
    name: 'blue',
    hex: '#31B4F2'
  },
  {
    name: 'red',
    hex: '#EB4B4B'
  },
  {
    name: 'teal',
    hex: '#46BBB0'
  },
  {
    name: 'pink',
    hex: '#FF7D96'
  }
];
