import React from 'react';
import PropTypes from 'prop-types';
import { OpenInNewIcon } from 'components/Icons';
import { INTEGRATIONS } from 'screens/Integrations/helpers/constants';

const TextByStatus = ({
  submissionResult,
  integrationType,
  comingFromView
}) => {
  switch (submissionResult?.httpStatusCode) {
    case 207:
      return (
        <>
          <h1
            className="mt-5 text-2xl leading-8 font-semibold"
            style={{ marginBottom: '8px' }}
          >
            Your data was partially sent due to a vendor integration issue.
          </h1>
          <div className=" w-1/2 text-center" style={{ marginBottom: '32px' }}>
            <p className="font-normal text-sm">
              Your records were successfully created in this submission.
              However, some errors occurred with certain records. Please review
              the error reports here for more details.
            </p>
          </div>
        </>
      );
    case 422:
      return (
        <>
          <h1
            className="mt-5 text-2xl leading-8 font-semibold"
            style={{ marginBottom: '8px' }}
          >
            Error reported in this submission.
          </h1>
          <div className=" w-1/2 text-center" style={{ marginBottom: '32px' }}>
            <p className="font-normal text-sm">
              We were unable to send your data due to an integration error with
              the vendor {integrationType}. Please verify the errors in
              submission details.
            </p>
          </div>
        </>
      );
    default:
      return (
        <>
          <h1
            className="text-2xl leading-8 font-semibold"
            style={{ marginBottom: '8px' }}
          >
            Your data has been {comingFromView ? 're' : ''}submitted!
          </h1>
          <div className=" w-1/2 text-center" style={{ marginBottom: '32px' }}>
            <p className="font-normal text-sm">
              {integrationType === INTEGRATIONS.calAgPermits ? (
                <>
                  Your pesticide usage reports have been successfully submitted
                  to CalAg. If you need to edit any part of your submission you
                  can do so at the{' '}
                  <a
                    target="_blank"
                    href={
                      process.env.REACT_APP_INTEGRATIONS_CALAG_WEBSITE ??
                      'https://calagtest.calicosol.com'
                    }
                    rel="noreferrer"
                    className="text-green-700"
                  >
                    <div className="inline-flex gap-1 items-center">
                      CalAg website <OpenInNewIcon fill="#0C612C" />
                    </div>
                  </a>
                </>
              ) : (
                <>
                  Your submission has been sent to {integrationType}
                  <br />
                  You can view the submitted properties via the{' '}
                  {integrationType}
                  portal.
                </>
              )}
            </p>
          </div>
        </>
      );
  }
};

TextByStatus.propTypes = {
  submissionResult: PropTypes.shape({
    httpStatusCode: PropTypes.number,
    id: PropTypes.string
  }),
  integrationType: PropTypes.string,
  comingFromView: PropTypes.string
};

TextByStatus.defaultProps = {
  submissionResult: undefined,
  integrationType: '',
  comingFromView: ''
};

export default TextByStatus;
