import React, {
  createContext,
  useCallback,
  useEffect,
  useRef,
  useMemo,
  useReducer
} from 'react';
import PropTypes from 'prop-types';
import reducer, {
  SET_STORAGE_LOCATIONS,
  SET_LOADING_STATUS,
  SET_FETCHING_DETAILS_STATUS,
  SET_SELECTED,
  ATTACH_DETAILS_TO_ITEM,
  SET_SORTED
} from '../reducer';
import useStorageLocations from '../../hooks/useStorageLocations';

export const initialState = {
  data: [],
  page: 1,
  selectedId: null,
  sortBy: 'name',
  sortDir: 'asc',
  isLoading: true,
  isFetchingDetails: true,
  recentFetchIds: []
};

export const StorageLocationContext = createContext({});

const StorageLocationProvider = ({ children, initialValues }) => {
  const mergedInitialState = {
    ...initialState,
    selectedId: initialValues.selectedId,
    sortBy: initialValues.sortBy || initialState.sortBy,
    sortDir: initialValues.sortDir || initialState.sortDir
  };
  const [state, dispatch] = useReducer(reducer, mergedInitialState);
  const {
    loading,
    loadingDetails,
    fetchStorageLocations,
    fetchStorageDetails
  } = useStorageLocations();
  const currentRequestNumber = useRef(0);
  const selectLocation = useCallback(
    async (id, skipCache = false) => {
      if (!state.recentFetchIds.includes(id) || skipCache) {
        const response = await fetchStorageDetails(id);
        dispatch({ type: ATTACH_DETAILS_TO_ITEM, payload: response });
      }
      dispatch({ type: SET_SELECTED, payload: id });
    },
    [fetchStorageDetails, state.recentFetchIds]
  );

  const fetchData = useCallback(
    async ({ skipCache, initSelected }) => {
      currentRequestNumber.current += 1;
      const requestNumber = currentRequestNumber.current;
      const response = await fetchStorageLocations({
        sortBy: mergedInitialState.sortBy,
        sortDir: mergedInitialState.sortDir
      });
      if (response && currentRequestNumber.current === requestNumber) {
        dispatch({
          type: SET_STORAGE_LOCATIONS,
          payload: response.data?.error ? initialState : response
        });
        if (skipCache && initSelected) {
          selectLocation(initSelected, true);
        } else if (response.data[0] && !state.selectedId) {
          selectLocation(response.data[0].id);
        }
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      fetchStorageLocations,
      mergedInitialState.sortBy,
      mergedInitialState.sortDir
    ]
  );

  useEffect(() => {
    fetchData({
      skipCache: initialValues.skipCache,
      initSelected: initialValues.selectedId
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fetchData]);

  useEffect(() => {
    dispatch({ type: SET_LOADING_STATUS, payload: loading });
    dispatch({ type: SET_FETCHING_DETAILS_STATUS, payload: loadingDetails });
  }, [loading, loadingDetails]);

  const resetLocation = useCallback(() => {
    dispatch({ type: SET_SELECTED, payload: null });
  }, []);

  const applySort = useCallback(
    ({ sortBy, sortDir }) => {
      dispatch({
        type: SET_SORTED,
        payload: { sortBy, sortDir }
      });
      currentRequestNumber.current += 1;
      const requestNumber = currentRequestNumber.current;
      fetchStorageLocations({ sortBy, sortDir }).then(response => {
        if (currentRequestNumber.current === requestNumber) {
          dispatch({ type: SET_STORAGE_LOCATIONS, payload: response });
          selectLocation(state.selectedId, true);
        }
      });
    },
    [fetchStorageLocations, state.selectedId, selectLocation]
  );

  const contextValue = useMemo(() => {
    return {
      state,
      dispatch,
      selectLocation,
      resetLocation,
      applySort,
      refetch: fetchData
    };
  }, [state, dispatch, selectLocation, resetLocation, applySort, fetchData]);

  return (
    <StorageLocationContext.Provider value={contextValue}>
      {children}
    </StorageLocationContext.Provider>
  );
};

StorageLocationProvider.defaultProps = {
  children: null,
  initialValues: {
    sortBy: null,
    sortKey: null,
    selectedId: null,
    skipCache: false
  }
};

StorageLocationProvider.propTypes = {
  children: PropTypes.node,
  initialValues: PropTypes.shape({
    sortBy: PropTypes.string,
    sortDir: PropTypes.string,
    selectedId: PropTypes.string,
    skipCache: PropTypes.bool
  })
};

export default StorageLocationProvider;
