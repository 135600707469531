import React from 'react';
import PropTypes from 'prop-types';

const CircleButton = ({ onClick, children, selected, disabled, className }) => {
  const outerFill = selected ? 'bg-gray-700' : '';
  const textClass = disabled ? 'text-gray-500 cursor-not-allowed' : '';

  return (
    <button
      type="button"
      className={`flex justify-center items-center m-3 w-8 h-8 bg-white shadow-lg rounded-full ${outerFill} ${textClass} ${className}`}
      onClick={onClick}
      disabled={disabled}
    >
      {children}
    </button>
  );
};

CircleButton.defaultProps = {
  onClick: () => {},
  selected: false,
  disabled: false,
  className: ''
};

CircleButton.propTypes = {
  onClick: PropTypes.func,
  children: PropTypes.element.isRequired,
  selected: PropTypes.bool,
  disabled: PropTypes.bool,
  className: PropTypes.string
};

export default CircleButton;
