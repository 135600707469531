import React from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { kebabCase } from 'lodash';

const UserRoleBaseButton = ({
  selected,
  disabled,
  onClick,
  display,
  icon,
  id
}) => {
  const disabledStyles = `${disabled && 'opacity-25 cursor-not-allowed'}`;

  return (
    <button
      id={id}
      data-testid={`user-role-${kebabCase(id)}`}
      // original style
      // style={{ width: '124px', height: '96px' }}
      style={{ width: '154px', height: '96px' }}
      className={`group border-cornflower-blue-500 border p-2 rounded mr-2 focus:outline-none
        ${
          selected
            ? 'bg-cornflower-blue-500 text-white font-bold'
            : 'hover:bg-opacity-25 active:bg-opacity-50 hover:bg-blue-200 bg-white hover:cornflower-blue-200 text-cornflower-blue-500'
        } ${disabledStyles}`}
      type="button"
      disabled={disabled}
      onClick={onClick}
    >
      {icon}
      <div
        className={`whitespace-no-wrap truncate ${
          selected ? 'text-white' : 'text-cornflower-blue-500'
        }`}
      >
        {display}
      </div>
    </button>
  );
};

UserRoleBaseButton.defaultProps = {
  id: '',
  selected: false,
  disabled: false,
  display: '',
  icon: <FontAwesomeIcon size="2x" />
};

UserRoleBaseButton.propTypes = {
  id: PropTypes.string,
  selected: PropTypes.bool,
  disabled: PropTypes.bool,
  display: PropTypes.string,
  icon: PropTypes.element,
  onClick: PropTypes.func.isRequired
};

export default UserRoleBaseButton;
