import * as Yup from 'yup';

export default Yup.object().shape({
  name: Yup.string()
    .trim()
    .required('Required'),
  languageCountry: Yup.string().required('Required'),
  masterListGroup: Yup.string().required('Required'),
  timeZone: Yup.string().required('Required'),
  areaUnit: Yup.string().required('Required')
});
