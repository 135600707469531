import React, { useContext, useEffect, useState } from 'react';

import { INTEGRATIONS } from 'screens/Integrations/helpers/constants';
import extractCropZoneIds from 'screens/Integrations/helpers/extractCropZoneIds';
import useSubmissionAnalytics from 'screens/Integrations/hooks/useSubmissionAnalytics';
import useFormikHandler from 'screens/Integrations/hooks/useFormikHandler';
import { AgrianContext } from '../../../context/Provider';
import PropertyIntegrationBase from '../../../../components/PropertyIntegration';
import { SubmissionSummaryContext } from '../../../../SubmissionSummary/context/SubmissionSummaryProvider';

const AgrianPropertiesSelection = () => {
  const { events, triggerAnalyticsEvent } = useSubmissionAnalytics();
  const { goForward } = useContext(AgrianContext);
  const { updateSubmissionFilterCropZoneIds } = useContext(
    SubmissionSummaryContext
  );
  const [keepCurrentStep, setKeepCurrentStep] = useState(true);
  const { selectedProperties } = useFormikHandler();

  useEffect(() => {
    setKeepCurrentStep(!keepCurrentStep);

    if (selectedProperties && !keepCurrentStep) {
      const cropZoneIds = extractCropZoneIds(selectedProperties);
      updateSubmissionFilterCropZoneIds(cropZoneIds);
      triggerAnalyticsEvent(
        events.epic.Integrations.submissionPropertiesSelected,
        {
          vendor: INTEGRATIONS.agrian,
          cropZonesLength: cropZoneIds.length
        }
      );
      goForward();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedProperties]);

  return <PropertyIntegrationBase integrationName={INTEGRATIONS.agrian} />;
};

export default AgrianPropertiesSelection;
