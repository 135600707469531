import React, { useContext, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import CreateFarmModal from 'screens/Property/PropertiesLanding/PropertyCreate/components/CreateFarmModal';
import useFarmData from 'screens/Property/hooks/useFarmData';
import '../../index.css';
import { Context } from 'components/Store';
import { FINANCIAL_ACCESSES, ROLES, hasAccess } from 'utilities/access';
import Actions from './components/Actions';

const PanelFooter = ({
  showTools,
  reloadFarmData,
  openCreateModal,
  setView
}) => {
  const { createFarm } = useFarmData(null, false);
  const [openFarmModal, setOpenFarmModal] = useState(false);
  const [
    { isMassAssignerActive, loggedInUserOrgPermission, isExportPDFMapsActive }
  ] = useContext(Context);

  const { role, financialAccess } = loggedInUserOrgPermission;

  const withoutAccess = useMemo(
    () =>
      !hasAccess(
        { role, financialAccess },
        {
          minAllowedRole: ROLES.FULL_CONTROL,
          minFinancialAccess: FINANCIAL_ACCESSES.READ
        }
      ),
    [role, financialAccess]
  );
  const handleCreateFarm = async name => {
    await createFarm({
      name,
      timeZone: 'US/Central',
      referencePoint: {
        type: 'Point',
        coordinates: [0, 0]
      }
    });

    reloadFarmData();

    setOpenFarmModal(false);
  };

  if (isMassAssignerActive || isExportPDFMapsActive || withoutAccess)
    return null;
  return (
    <div className="p-4 sticky top-0 z-4 white-bg border-t-1 border-neutral-20">
      <div>
        {showTools && (
          <Actions
            setOpenFarmModal={setOpenFarmModal}
            openCreateModal={openCreateModal}
            setView={setView}
          />
        )}
      </div>
      <CreateFarmModal
        open={openFarmModal}
        onCancel={() => setOpenFarmModal(false)}
        onCreate={handleCreateFarm}
      />
    </div>
  );
};

PanelFooter.defaultProps = {
  showTools: false,
  reloadFarmData: () => {}
};

PanelFooter.propTypes = {
  showTools: PropTypes.bool,
  reloadFarmData: PropTypes.func,
  openCreateModal: PropTypes.func.isRequired,
  setView: PropTypes.func.isRequired
};

export default PanelFooter;
