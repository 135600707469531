import React from 'react';
import PropTypes from 'prop-types';
import { Form } from '@agconnections/grow-ui';
import { StateSelect } from 'components/AddressFields';

function StateField({ state, setFieldValue }) {
  return (
    <Form.Field>
      <div className="block ml-6">
        <div className="w-full">
          <div className="block text-sm font-medium leading-5 gr-text-gray-600 sm:mt-px sm:pt-2">
            State
          </div>
        </div>
        <div className="w-full">
          <StateSelect value={state} onChange={setFieldValue} />{' '}
        </div>
      </div>
    </Form.Field>
  );
}

StateField.defaultProps = {
  state: null
};

StateField.propTypes = {
  state: PropTypes.string,
  setFieldValue: PropTypes.func.isRequired
};

export default StateField;
