import React from 'react';
import { Col } from 'syngenta-digital-cropwise-react-ui-kit';
import PropTypes from 'prop-types';
import AddCircle from 'assets/add_circle.svg';
import CheckIcon from 'assets/check_circle_outline_outlined.svg';
import ProductTypeDisplay from 'screens/ProductList/helpers/ProductTypeDisplay';
import ClickableDiv from 'components/ClickableDiv';

const DrawerBox = ({ product, handleOnEdit }) => {
  return (
    <Col
      span={24}
      className="flex flex-row h-60px items-center border-opacity-50"
      style={{ border: '0.5px solid #D8D8DA' }}
    >
      <Col
        span={4}
        className="flex flex-row justify-center items-center h-full"
      >
        {!product?.isInUse ? (
          <ClickableDiv onClick={() => handleOnEdit(product)}>
            <img className="mr-1" src={AddCircle} alt="circle icon" />
          </ClickableDiv>
        ) : (
          <img className="mr-1" src={CheckIcon} alt="check icon" />
        )}
      </Col>
      <Col span={10} className="flex flex-col justify-center h-full">
        <p className="text-sm font-bold">{product?.name}</p>
        <p>{product?.manufacturer}</p>
      </Col>
      <Col
        span={10}
        className="flex flex-row justify-start items-center h-full"
      >
        <ProductTypeDisplay productType={product.producttype || '-'} />
      </Col>
    </Col>
  );
};

DrawerBox.propTypes = {
  product: PropTypes.shape({
    name: PropTypes.string,
    manufacturer: PropTypes.string,
    producttype: PropTypes.string,
    isInUse: PropTypes.bool
  }).isRequired,
  handleOnEdit: PropTypes.func.isRequired
};

export default DrawerBox;
