import React, { useState, useEffect } from 'react';
import GrowUIFormField from 'components/Forms/GrowUIFormField';
import { useFormikContext } from 'formik';
import { Select } from '@agconnections/grow-ui';

const RangeDirection = () => {
  const { values } = useFormikContext();
  const [captionText, setCaptionText] = useState();

  const isValueEmpty = !values.range;
  useEffect(() => {
    if (isValueEmpty) {
      setCaptionText('Range must be entered first');
    } else {
      setCaptionText(null);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values.range]);
  return (
    <GrowUIFormField
      type="text"
      control={Select}
      name="rangeDirection"
      id="rangeDirection"
      errorMessage={captionText}
      placeholder="Select baseline direction"
      items={[
        { key: 'none', value: 'None' },
        { key: 'east', value: 'East' },
        { key: 'west', value: 'West' }
      ]}
      disabled={isValueEmpty}
    />
  );
};

export default RangeDirection;
