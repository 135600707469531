import { useContext, useState, useEffect, useRef } from 'react';

import { Context } from 'components/Store';
import useDebounce from 'hooks/useDebounce';
import { genProxyResource } from 'utilities/api';
import { PROXY_SEARCH_MODEL } from 'utilities/helpers/searchModels';
import PAGINATION_SIZE from 'helpers/globalConstants';
import catchCancel from '../helpers/catchCancel';
import { parseServerError } from '../helpers/errorHelpers';

const createQuery = (
  sortFields,
  sortDir,
  searchField,
  searchTerm,
  cropSeason,
  productType,
  taskType,
  flag
) => {
  const queryParams = {
    sorts: sortFields.map(sortField => ({ [sortField]: sortDir })),
    filters: []
  };
  if (searchTerm) {
    queryParams.filters.push({ [searchField]: searchTerm });
  }
  if (cropSeason && cropSeason !== 'none') {
    queryParams.filters.push({ 'cropSeason.cropSeasonLabel': cropSeason });
  }
  if (flag && flag !== 'none') {
    queryParams.filters.push({ flag });
  }
  if (taskType && taskType !== 'none') {
    queryParams.filters.push({ taskType });
  }
  if (productType && productType !== 'none') {
    queryParams.filters.push({ productType });
  }
  return queryParams;
};

const useLandingPageData = (
  apiResource,
  sortFields,
  searchField,
  pageNo = 0
) => {
  const [view, setView] = useState('comfortable');
  const [sort, setSort] = useState('asc');
  const [isSearch, setIsSearch] = useState(false);
  const [searchText, setSearchText] = useState(null);
  const [cropSeason, setCropSeason] = useState(null);
  const [productType, setProductType] = useState(null);
  const [taskType, setTaskType] = useState(null);
  const [label, setLabel] = useState(null);
  const [loaded, setLoaded] = useState(false);
  const [, dispatch] = useContext(Context);
  const [items, setItems] = useState([]);
  const [reload, setReload] = useState(false);
  const [totalItems, setTotalItems] = useState(0);

  const api = useRef(genProxyResource(apiResource, { urlVersion: '/v1/base' }));
  // the sortFields dependency drives the useEffect crazy if not saved in this ref
  const savedSortFields = useRef(sortFields);
  const debouncedSearchTerm = useDebounce(searchText, 300);

  useEffect(() => {
    const loadItems = async (sortDir, searchTerm) => {
      if (!api.current) {
        return true;
      }
      const query = createQuery(
        savedSortFields.current,
        sortDir,
        searchField,
        searchTerm,
        cropSeason,
        productType,
        taskType,
        label
      );
      const params = {
        pageNo,
        size: PAGINATION_SIZE
      };
      const { promise, cancel } = await api.current.find(
        query,
        PROXY_SEARCH_MODEL,
        params
      );
      promise
        .then(({ data }) => {
          if (data) setTotalItems(data.totalElements);
          if (sortFields[0] === 'company.name' && searchText?.length > 0) {
            const trueItems = [];
            data.results.forEach(item => {
              if (item.name.includes(searchText)) trueItems.push(item);
            });
            setItems(trueItems);
          } else {
            setItems(data.results);
          }
        })
        .catch(catchCancel)
        .catch(parseServerError(dispatch, { showTryAgain: true }))
        .finally(() => {
          if (searchTerm) {
            setIsSearch(true);
          }
          setLoaded(true);
        });
      return () => {
        cancel();
      };
    };
    setLoaded(false);
    setReload(false);
    if (debouncedSearchTerm) {
      loadItems(sort, debouncedSearchTerm);
    } else {
      loadItems(sort);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    debouncedSearchTerm,
    sort,
    apiResource,
    searchField,
    cropSeason,
    productType,
    taskType,
    label,
    dispatch,
    reload
    // searchText,
    // sortFields
  ]);

  return {
    view,
    setView,
    sort,
    setSort,
    isSearch,
    setSearchText,
    setCropSeason,
    setLabel,
    setTaskType,
    productType,
    setProductType,
    loaded,
    items,
    setItems,
    setReload,
    totalItems
  };
};

export default useLandingPageData;
