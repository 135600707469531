import React from 'react';
import { Form, TextArea } from '@agconnections/grow-ui';
import GrowUIFormField from 'components/Forms/GrowUIFormField';

const NotesField = () => {
  return (
    <Form.Field>
      <GrowUIFormField
        control={TextArea}
        id="notes"
        name="notes"
        style={{ height: '200px' }}
        editable={false}
      />
    </Form.Field>
  );
};

export default NotesField;
