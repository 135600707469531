import React from 'react';
import PropTypes from 'prop-types';
import State from 'screens/Property/PropertiesLanding/PropertyTable/UniqueFields/State';
import County from 'screens/Property/PropertiesLanding/PropertyTable/UniqueFields/County';
import Township from 'screens/Property/PropertiesLanding/PropertyTable/UniqueFields/Township';
import TownshipDirection from 'screens/Property/PropertiesLanding/PropertyTable/UniqueFields/TownshipDirection';
import Range from 'screens/Property/PropertiesLanding/PropertyTable/UniqueFields/Range';
import RangeDirection from 'screens/Property/PropertiesLanding/PropertyTable/UniqueFields/RangeDirection';
import PermitId from 'screens/Property/PropertiesLanding/PropertyTable/UniqueFields/PermitId';
import SiteId from 'screens/Property/PropertiesLanding/PropertyTable/UniqueFields/SiteId';
import Editor from 'screens/Property/PropertiesLanding/components/Editor';
import FieldMapView from 'components/FieldMapView';
import { fieldToFeature } from 'screens/Property/PropertiesLanding/ImportPropertyModal/fieldImporterHelper';
import Section from 'screens/Property/PropertiesLanding/PropertyTable/UniqueFields/Section';

const CalAgFieldsForm = ({ geometry, name, farm }) => {
  return (
    <div className="flex flex-col w-full">
      <div className="flex flex-row w-full justify-start">
        <div className="w-1/2">
          <div className="mt-2 bg-gray-100 rounded-lg overflow-hidden text-left mr-4 p-4">
            <FieldMapView
              key="property-preview-with-vendor=any"
              className="rounded-lg min-h-60"
              feature={fieldToFeature({ name, farm, geometry })}
              containerStyle={{
                height: '310px'
              }}
            />
          </div>
        </div>
        <div className="grid grid-cols-2 w-1/2 gap-x-2">
          <Editor label="State" labelMarginTop="mt-2">
            <State />
          </Editor>
          <Editor label="County" labelMarginTop="mt-2">
            <County />
          </Editor>
          <Editor labelMarginTop="mt-2">
            <Section />
          </Editor>
          <Editor halfWidth>
            <Township />
          </Editor>
          <Editor halfWidth>
            <TownshipDirection />
          </Editor>
          <Editor halfWidth>
            <Range />
          </Editor>
          <Editor halfWidth>
            <RangeDirection />
          </Editor>
        </div>
      </div>
      <div className="grid grid-cols-2 border-t-1 mt-4 gap-x-2">
        <Editor halfWidth htmlFor="permitId">
          <PermitId />
        </Editor>
        <Editor halfWidth>
          <SiteId />
        </Editor>
      </div>
    </div>
  );
};

CalAgFieldsForm.defaultProps = {
  name: 'NOT_DEFINED_NAME',
  farm: 'NOT_DEFINED_FARM'
};

CalAgFieldsForm.propTypes = {
  geometry: PropTypes.shape({
    type: PropTypes.string.isRequired,
    coordinates: PropTypes.arrayOf(
      PropTypes.arrayOf(PropTypes.arrayOf(PropTypes.number.isRequired))
    ).isRequired
  }).isRequired,
  name: PropTypes.string,
  farm: PropTypes.string
};

export default CalAgFieldsForm;
