import { useCallback, useContext, useEffect, useState } from 'react';

import { Context } from 'components/Store';

import catchCancel from 'helpers/catchCancel';
import { parseServerError } from 'helpers/errorHelpers';

import plansApiFunction, { resetOrg } from 'utilities/plansApiFunction';
import PAGINATION_SIZE from 'helpers/globalConstants';

const usePlans = defaultParams => {
  const [{ organization }, dispatch] = useContext(Context);

  const [plans, setPlans] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [plansCount, setPlansCount] = useState(0);
  const [pageNo, setPageNo] = useState(0);
  const [request, setRequest] = useState({
    pageNo,
    size: PAGINATION_SIZE,
    dateRange: [],
    nameQuery: '',
    sortBy: '',
    sortDir: '',
    seasonIdsCommaSeparated: JSON.parse(
      localStorage.getItem('selectedCropSeasons')
    ).join(','),
    ...defaultParams
  });

  const getPlans = useCallback(
    async params => {
      setIsLoading(true);

      const { promise } = await plansApiFunction(
        { ...request, ...params },
        organization
      );

      await promise
        .then(({ data }) => {
          setPlans(data.results);
          setPlansCount(data.totalElements);
        })
        .catch(catchCancel)
        .catch(err => {
          parseServerError(dispatch)(err);
        })
        .finally(() => {
          setIsLoading(false);
        });
    },
    [request, dispatch, organization, setIsLoading]
  );

  const reload = useCallback(async () => {
    getPlans();
  }, [getPlans]);

  const reset = () => {
    setRequest({
      ...request,
      pageNo,
      totalPages: 0,
      size: PAGINATION_SIZE,
      seasonIdsCommaSeparated: JSON.parse(
        localStorage.getItem('selectedCropSeasons')
      ).join(',')
    });
  };

  useEffect(() => {
    return () => {
      // destroy fake api records
      resetOrg();
    };
  }, []);

  useEffect(() => {
    reload();
  }, [reload, request]);

  const filterByNameQuery = useCallback(
    newQuery => {
      setRequest(r => ({ ...r, pageNo: 0, nameQuery: newQuery }));
    },
    [setRequest]
  );

  const filterByDateRange = useCallback(
    newDateRange => {
      if (newDateRange.length === 2 || newDateRange.length === 0) {
        setRequest(r => ({ ...r, pageNo: 0, dateRange: newDateRange }));
      }
    },
    [setRequest]
  );

  const goToPage = useCallback(
    newPage => {
      setPageNo(newPage);
      setRequest(r => ({ ...r, pageNo: newPage }));
    },
    [setRequest]
  );

  const sort = useCallback(
    (field, dir) => {
      setRequest(r => ({ ...r, pageNo: 0, sortBy: field, sortDir: dir }));
    },
    [setRequest]
  );

  return {
    getPlans,
    plans,
    plansCount,
    pageNo,
    goToPage,
    isLoading,
    reset,
    reload,
    filterByNameQuery,
    filterByDateRange,
    sort,
    sortBy: request.sortBy,
    sortDir: request.sortDir
  };
};

export default usePlans;
