export const PropertyTypes = {
  FIELD: 'field',
  FARM: 'farm',
  CROP_ZONE: 'crop zone'
};

export const getCropZonesFromProperties = properties =>
  properties?.flatMap(({ fields }) =>
    fields?.flatMap(field => field.cropzones)
  );

export const addSelectedCropSeasonsToFarms = (properties, cropSeasonIds) =>
  properties?.map(prop => ({ ...prop, selectedCropSeason: cropSeasonIds }));

export const mapFieldsGeoJSONCollectionOnCropZones = (
  fieldsAndAreasGeoJSONCollection,
  cropZoneIds
) => {
  return cropZoneIds.reduce((allMapped, cropZoneId) => {
    const cropZone = fieldsAndAreasGeoJSONCollection.features.find(
      item => item.properties.id === cropZoneId
    );
    const { properties: field } = fieldsAndAreasGeoJSONCollection.features.find(
      item => item.properties.id === cropZone.properties.$parentFieldId
    );

    cropZone.properties.fieldName = field.name;

    allMapped.push(cropZone);

    return allMapped;
  }, []);
};
