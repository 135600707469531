import React from 'react';
import { Form, Select } from '@agconnections/grow-ui';
import GrowUIFormField from 'components/Forms/GrowUIFormField';
import statesItems from '../../../../../components/AddressFields/StateSelect/data';

const StateField = () => {
  return (
    <Form.Field label="State">
      <GrowUIFormField
        control={Select}
        id="state"
        name="address.state"
        items={statesItems}
      />
    </Form.Field>
  );
};

export default StateField;
