import { useState } from 'react';
import { fieldV2 } from 'utilities/api';
import catchCancel from 'helpers/catchCancel';

const useFieldCost = () => {
  const [loading, setLoading] = useState(false);

  const getFieldCost = async ({ id, seasonIds }) => {
    setLoading(true);

    const fieldApi = fieldV2.createChildApi({
      action: `field/${id}/cost`
    });

    const body = {
      seasonIds
    };

    const { promise } = fieldApi.post(body);

    return promise
      .then(({ data }) => {
        setLoading(false);
        return data;
      })
      .catch(catchCancel)
      .catch(() => {
        setLoading(false);

        return [];
      });
  };

  return {
    getFieldCost,
    loading,
    setLoading
  };
};

export default useFieldCost;
