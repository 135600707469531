import React, { createContext, useState, useMemo } from 'react';
import PropTypes from 'prop-types';

export const CalAgContext = createContext();

const Provider = ({ children }) => {
  const [step, setStep] = useState(1);
  const [comingFromStep, setComingFromStep] = useState(1);
  const [canGoNext, setCanGoNext] = useState(true);
  const blockStepForward = () => setCanGoNext(false);
  const releaseStepForward = () => setCanGoNext(true);

  const goBack = () => {
    setComingFromStep(step);
    setStep(s => Math.max(0, s - 1));
  };

  const goForward = () => {
    setComingFromStep(step);
    setStep(s => s + 1);
  };

  const store = useMemo(
    () => ({
      state: { step, comingFromStep, canGoNext },
      gotoStep: setStep,
      goBack,
      blockStepForward,
      releaseStepForward,
      goForward
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [step, canGoNext]
  );

  return (
    <CalAgContext.Provider value={store}>{children}</CalAgContext.Provider>
  );
};

Provider.defaultProps = {
  children: null
};

Provider.propTypes = {
  children: PropTypes.node
};

export default Provider;
