import { PropTypes } from 'prop-types';
import React, { memo } from 'react';

const SubmitIcon = ({ size, color }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      viewBox="0 0 32 32"
      data-testid="submit-icon"
      className={`fill-current text-${color}`}
    >
      <g id="icon (18px)">
        <path
          id="icon/communication/present_to_all_24px"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M28 3.99951H3.99998C2.51998 3.99951 1.33331 5.18618 1.33331 6.66618V25.3328C1.33331 26.8128 2.51998 27.9995 3.99998 27.9995H28C29.48 27.9995 30.6666 26.8128 30.6666 25.3328V6.66618C30.6666 5.18618 29.48 3.99951 28 3.99951ZM28 25.3595H3.99998V6.63951H28V25.3595ZM10.6666 15.9995H13.3333V21.3328H18.6666V15.9995H21.3333L16 10.6662L10.6666 15.9995Z"
        />
      </g>
    </svg>
  );
};

SubmitIcon.propTypes = {
  color: PropTypes.string,
  size: PropTypes.number
};

SubmitIcon.defaultProps = {
  color: 'neutral-600',
  size: 32
};

export default memo(SubmitIcon);
