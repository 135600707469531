import React from 'react';
import Products from 'screens/ProductList';
import ProductListProvider from 'screens/ProductList/context/ProductListProvider';

const ParentProductListProvider = () => {
  return (
    <ProductListProvider>
      <Products />
    </ProductListProvider>
  );
};

export default ParentProductListProvider;
