import RECOMMENDATION_TYPE, {
  RECOMMENDATION_TYPE_DISPLAY_NAME
} from './recommendationType';

function groupRecommendationsByType(recommendations) {
  const recommendationGroups = {
    [RECOMMENDATION_TYPE.landdb]: {
      id: RECOMMENDATION_TYPE.landdb,
      title: RECOMMENDATION_TYPE_DISPLAY_NAME[RECOMMENDATION_TYPE.landdb],
      recommendations: [],
      showStatus: false
    },
    [RECOMMENDATION_TYPE.agrian]: {
      id: RECOMMENDATION_TYPE.agrian,
      title: RECOMMENDATION_TYPE_DISPLAY_NAME[RECOMMENDATION_TYPE.agrian],
      recommendations: [],
      showStatus: true
    }
  };
  recommendations.forEach(recommendation => {
    /* eslint no-unused-expressions: "off", curly: "error" */
    recommendationGroups[recommendation.type]?.recommendations.push(
      recommendation
    );
  });

  return recommendationGroups;
}

export default {
  groupRecommendationsByType
};
