import React from 'react';
import { PropTypes } from 'prop-types';
import { INTEGRATION_INSTRUCTIONS } from 'screens/Integrations/helpers/constants';
import Step from './components/Step';

const Instructions = ({
  steps = INTEGRATION_INSTRUCTIONS,
  footerInstructions,
  headerInstructions
}) => {
  return (
    <div
      className="w-full flex flex-col bg-white rounded-xl border border-gray-400 font-body shadow-2xl mb-10"
      style={{ padding: '2.25rem' }}
    >
      <div className="w-full font-semibold ">
        <p className="text-base">{headerInstructions}</p>
      </div>
      <div className="flex flex-line w-full items-start mt-4 mb-6 gap-12">
        {steps.map(step => (
          <Step
            number={step.number}
            header={step.header}
            description={step.description}
            key={step.number}
          />
        ))}
      </div>
      {footerInstructions && (
        <div className="w-full">
          <p className="text-normal">{footerInstructions}</p>
        </div>
      )}
    </div>
  );
};

Instructions.propTypes = {
  steps: PropTypes.arrayOf(
    PropTypes.shape({
      number: PropTypes.number,
      header: PropTypes.string,
      description: PropTypes.string
    })
  ),
  footerInstructions: PropTypes.string,
  headerInstructions: PropTypes.string
};

Instructions.defaultProps = {
  steps: INTEGRATION_INSTRUCTIONS,
  footerInstructions: 'You can review and resubmit to processors at any point',
  headerInstructions: 'How to submit'
};

export default Instructions;
