import React from 'react';
import PropTypes from 'prop-types';
import { Button } from '@agconnections/grow-ui';
import ArrowForwardRoundedIcon from '@material-ui/icons/ArrowForwardRounded';

import { NavLink } from 'react-router-dom';

const LandingEmpty = ({
  listType,
  isSearch,
  routePath,
  messageHeader,
  message,
  buttonText
}) => {
  return isSearch ? (
    <div className="flex flex-col items-center justify-center h-64 bg-white rounded shadow-md radius">
      <div className="px-4 mb-10 text-xl leading-6 text-center text-gray-800">
        Sorry, no results were found.
      </div>
      <NavLink to={routePath}>
        <Button icon={<ArrowForwardRoundedIcon />} type="primary">
          Back to list
        </Button>
      </NavLink>
    </div>
  ) : (
    <div className="flex flex-col items-center justify-center h-64 bg-white rounded shadow-md radius">
      <div className="px-4 text-xl leading-6 text-center text-gray-800">
        {messageHeader ||
          `This organization doesn’t have ${listType.toLowerCase()} yet.`}
      </div>
      <div className="px-4 mt-2 mb-10 text-base leading-5 text-center text-gray-700">
        {message || 'Add some to include them in tasks and reports.'}
      </div>
      {routePath && (
        <NavLink to={routePath}>
          <Button icon={<ArrowForwardRoundedIcon />} type="primary" iconRight>
            {buttonText || `Start here `}
          </Button>
        </NavLink>
      )}
    </div>
  );
};

LandingEmpty.defaultProps = {
  listType: 'Items',
  isSearch: false,
  routePath: '',
  messageHeader: null,
  message: null,
  buttonText: null
};

LandingEmpty.propTypes = {
  isSearch: PropTypes.bool,
  routePath: PropTypes.string,
  listType: PropTypes.oneOf([
    'Organizations',
    'Property',
    'Crop Seasons',
    'Recommendations',
    'People',
    'Companies',
    'Equipment',
    'Items',
    'Yield Entries',
    'Tasks'
  ]),
  messageHeader: PropTypes.string,
  message: PropTypes.string,
  buttonText: PropTypes.string
};

export default LandingEmpty;
