import { useRef, useState } from 'react';
import html2pdf from 'html2pdf.js';

const usePDFTools = () => {
  const [downloading, setDownloading] = useState(false);
  const prevBlobUrl = useRef();

  const downloadPDF = (
    idElement,
    fileName = 'print.pdf',
    pageBreakers = []
  ) => {
    const element = document.getElementById(idElement);

    if (element) {
      setDownloading(true);

      html2pdf()
        .set({ pagebreak: { before: pageBreakers } })
        .from(element)
        .save(fileName)
        .finally(() => setDownloading(false));
    }
  };

  const mccainDownloadPDF = (elementId, opt) => {
    const element = document.getElementById(elementId);

    if (element) {
      setDownloading(true);

      html2pdf()
        .from(element)
        .set(opt)
        .save()
        .finally(() => setDownloading(false));
    }
  };

  const downloadPDFV2 = (url, fileName) => {
    if (url && url !== prevBlobUrl.current) {
      prevBlobUrl.current = url;
      const link = document.createElement('a');

      link.href = prevBlobUrl.current;
      link.download = fileName;

      document.body.appendChild(link);

      link.dispatchEvent(
        new MouseEvent('click', {
          bubbles: true,
          cancelable: true,
          view: window
        })
      );

      document.body.removeChild(link);
    }
  };

  return {
    downloadPDF,
    downloading,
    mccainDownloadPDF,
    downloadPDFV2,
    setDownloading
  };
};

export default usePDFTools;
