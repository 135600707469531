import React, { useContext } from 'react';
import { Context } from 'components/Store';
import GrowUIFormField from 'components/Forms/GrowUIFormField';
import PropTypes from 'prop-types';
import { Search } from '@agconnections/grow-ui';
import { useFormikContext } from 'formik';
import stateItems from '../../../../../components/AddressFields/StateSelect/data';

const State = ({ setStateSelected }) => {
  const { setFieldValue, values } = useFormikContext();
  const [{ isEnableInfoTabEdit }] = useContext(Context);

  return (
    <GrowUIFormField
      control={Search}
      value={values?.state}
      name="state"
      items={stateItems}
      label="State"
      placeholder="Select State"
      type="text"
      disabled={!isEnableInfoTabEdit}
      data-testid="ui-field-state"
      onSelect={e => {
        setFieldValue('state', e.key);
        setFieldValue('county', '');
        setStateSelected(true);
      }}
    />
  );
};
State.defaultProps = {
  setStateSelected: () => {}
};

State.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  setStateSelected: PropTypes.func
};

export default State;
