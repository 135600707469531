import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { Spinner, Table } from '@agconnections/grow-ui';
import { getFieldSortDir } from 'screens/Plans/helpers/columnHelpers';
import EmptyTableState from 'components/EmptyTableState';

import emptySearch from 'assets/noNotesFound.png';
import noResultsSearch from 'assets/no_search_results_found.svg';
import AgrianRecommendationRow from '../AgrianRecommendationRow';

const AgrianRecommendationTable = ({
  handleSort,
  sortBy,
  sortDir,
  filteredRecommendations,
  handleSelection,
  selectedRecommendations,
  isLoading,
  isCropYearAndCropSeasonSelected,
  PaginationComponent
}) => {
  const getSortDir = useCallback(
    field => getFieldSortDir(field, sortBy, sortDir),
    [sortBy, sortDir]
  );
  return (
    <>
      {!isCropYearAndCropSeasonSelected ? (
        <div className="py-24">
          <EmptyTableState
            title="No Crop Year Selected and Crop Season Selected"
            overlayIcon={emptySearch}
            dataTestId="empty-view-no-recommendation"
          />
        </div>
      ) : (
        <div>
          {isLoading ? (
            <div
              data-testid="loading-agrian-records"
              className="w-full h-full py-24 flex flex-col justify-center items-center"
            >
              <Spinner />
              <p className="text-sm font-semibold mt-4">
                Loading Agrian records...
              </p>
            </div>
          ) : (
            <>
              {filteredRecommendations !== undefined &&
              filteredRecommendations?.length !== 0 ? (
                <div data-testid="agrian-recommendations-table">
                  <Table>
                    <Table.Header>
                      <Table.Cell />
                      <Table.Cell
                        sortable
                        sort={getSortDir('doc_number')}
                        onClick={handleSort('doc_number')}
                      >
                        Recommendation Name
                      </Table.Cell>
                      <Table.Cell
                        sortable
                        sort={getSortDir('start_at')}
                        onClick={handleSort('start_at')}
                      >
                        Proposed Date
                      </Table.Cell>
                      <Table.Cell>Fields</Table.Cell>
                      <Table.Cell>Products</Table.Cell>
                      <Table.Cell>Last Import</Table.Cell>
                    </Table.Header>

                    {filteredRecommendations?.map(rec => (
                      <AgrianRecommendationRow
                        recommendation={rec}
                        selectedRecommendations={selectedRecommendations}
                        handleSelection={handleSelection}
                        key={rec.id}
                      />
                    ))}
                  </Table>
                  <div style={{ minHeight: '35px' }}>
                    {PaginationComponent && <PaginationComponent />}
                  </div>
                </div>
              ) : (
                <div className="py-24">
                  <EmptyTableState
                    title="No Records Found"
                    message="Change the selected crop year or try a different search."
                    overlayIcon={noResultsSearch}
                    dataTestId="empty-view-no-recommendation"
                  />
                </div>
              )}
            </>
          )}
        </div>
      )}
    </>
  );
};

AgrianRecommendationTable.propTypes = {
  filteredRecommendations: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      description: PropTypes.string,
      startDate: PropTypes.string,
      fields: PropTypes.arrayOf(
        PropTypes.shape({
          name: PropTypes.string
        })
      ),
      products: PropTypes.arrayOf(
        PropTypes.shape({
          productName: PropTypes.string
        })
      )
    })
  ).isRequired,
  handleSelection: PropTypes.func.isRequired,
  selectedRecommendations: PropTypes.arrayOf(
    PropTypes.shape({ id: PropTypes.string })
  ).isRequired,
  isLoading: PropTypes.bool.isRequired,
  isCropYearAndCropSeasonSelected: PropTypes.bool.isRequired,
  PaginationComponent: PropTypes.oneOfType([
    PropTypes.elementType, // For React components
    PropTypes.func // For functions that return JSX
  ]).isRequired,
  handleSort: PropTypes.func.isRequired,
  sortBy: PropTypes.string.isRequired,
  sortDir: PropTypes.string.isRequired
};

export default AgrianRecommendationTable;
