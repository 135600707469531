import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Table } from '@agconnections/grow-ui';
import { useFormikContext } from 'formik';
import FormattedNumber from 'components/FormattedNumber';
import { useParams } from 'react-router-dom';
import { getCorrectUnitAbbreviation } from 'helpers/unitsAbbreviation';
import AddProductTaskModal from 'components/ProductSideBar/components/AddProductTaskModal/index';
import useMasterProducts from 'hooks/useMasterProducts';
import EllipseMenu from 'components/EllipseMenu';
import { TAB_OPTIONS } from 'utilities/menus';
import EmptyProductsDisplay from './EmptyProductsDisplay';

const TaskProductTable = ({
  editProduct,
  removeProduct,
  memberRole,
  memberFinancialAccess,
  selectedApplicationOption,
  setCustomProductCreated,
  customProductCreated
}) => {
  const { getStdUnits } = useMasterProducts();
  // eslint-disable-next-line no-unused-vars
  const [sort, setSort] = useState();
  const [productModalOpen, setProductModalOpen] = useState(false);
  const [selectedProduct, setSelectedProduct] = useState(null);
  const [selectedIndex, setSelectedIndex] = useState(null);
  const closeProductModal = () => {
    setProductModalOpen(false);
    editProduct(selectedProduct);
    setSelectedProduct(null);
  };

  const { id } = useParams();
  const [modalType, setModalType] = useState({
    key: 'ratearea',
    value: 'ratePerAreaValue',
    unit: 'ratePerAreaUnit',
    title: 'Rate/Area'
  });

  const { values } = useFormikContext();
  useEffect(() => {
    const applicationOptions = [
      {
        key: 'ratearea',
        value: 'ratePerAreaValue',
        unit: 'ratePerAreaUnit',
        title: 'Rate/Area'
      },
      {
        key: 'totalproduct',
        value: 'totalProductValue',
        unit: 'totalProductUnit',
        title: 'Total Product'
      },
      {
        key: 'ratetank',
        value: 'ratePerTankValue',
        unit: 'ratePerTankUnit',
        title: 'Rate/Tank'
      }
    ];

    const determineModalType = () => {
      return values?.applicationStrategy === 'ByRatePerTank'
        ? 'ratetank'
        : 'ratearea';
    };

    setModalType(
      applicationOptions.find(option => option.key === determineModalType())
    );
  }, [values.applicationStrategy]);

  useEffect(() => {
    if (values.products?.length > 0) {
      const lastIndex = values.products.length - 1;
      const lastProductAdded = values.products[lastIndex];

      if (lastProductAdded.custom && customProductCreated) {
        setSelectedProduct({
          ...lastProductAdded,
          name: lastProductAdded.productName,
          manufacturer: lastProductAdded.manufacturerName,
          id: lastProductAdded.productId
        });
        setSelectedIndex(lastIndex);
        setProductModalOpen(true);
        setCustomProductCreated(false);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values.products, customProductCreated]);

  const handleSort = () => {};

  const mapStdUnits = async product => {
    if (!product.stdunit || !product.stdfactor || !product.stdpackageunit) {
      const productWithUnits = await getStdUnits(product);
      return productWithUnits;
    }

    return product;
  };

  const handleContextMenuAction = (action, product, index) => {
    if (action === 'edit') {
      setProductModalOpen(true);
      mapStdUnits(product).then(mappedProduct => {
        setSelectedProduct({
          ...mappedProduct,
          name: mappedProduct.productName,
          manufacturer: mappedProduct.manufacturerName,
          id: mappedProduct.productId,
          density: mappedProduct.density
        });
        setSelectedIndex(index);
      });
    }
    if (action === 'remove') {
      removeProduct(product);
    }
  };

  const checkForUndefined = value => value || 0;

  const getTotalCost = price => {
    if (memberRole === 'Full control' && memberFinancialAccess === 'none') {
      return '';
    }
    if (
      memberRole === 'Admin' ||
      memberRole === 'View Only' ||
      (memberRole === 'Full control' && memberFinancialAccess === 'WRITE') ||
      (memberRole === 'Full control' &&
        memberFinancialAccess === 'READ' &&
        id !== 'create')
    ) {
      return price;
    }
    if (
      memberRole === 'Full control' &&
      memberFinancialAccess === 'READ' &&
      id === 'create'
    ) {
      return '';
    }
    return '';
  };

  return (
    <div className="mt-8">
      <AddProductTaskModal
        open={productModalOpen}
        product={selectedProduct}
        onClose={closeProductModal}
        isEdit
        prodIndex={selectedIndex}
        memberRole={memberRole}
        memberFinancialAccess={memberFinancialAccess}
        selectedApplicationOption={selectedApplicationOption}
      />
      {!values.products?.length ? (
        <EmptyProductsDisplay />
      ) : (
        <Table>
          <Table.Header>
            <Table.Cell sort={sort} onClick={handleSort}>
              {`Products (${values.products.length})`}
            </Table.Cell>
            <Table.Cell sort={sort} onClick={handleSort}>
              {modalType?.title}
            </Table.Cell>
            <Table.Cell sort={sort} onClick={handleSort}>
              Price/Unit
            </Table.Cell>
            <Table.Cell sort={sort} onClick={handleSort}>
              Appl. Area
            </Table.Cell>
            <Table.Cell sort={sort} onClick={handleSort}>
              Appl. Area %
            </Table.Cell>
            <Table.Cell sort={sort} onClick={handleSort}>
              Total Product
            </Table.Cell>
            <Table.Cell sort={sort} onClick={handleSort}>
              Total Cost
            </Table.Cell>
            <Table.Cell sort={sort} onClick={handleSort}>
              Grower Cost
            </Table.Cell>
            <Table.Cell />
          </Table.Header>
          {values.products.map((product, index) => {
            return (
              <Table.Row key={product.trackingId}>
                <Table.Cell>
                  <div className="text-left">
                    <div className="font-bold">{product.productName}</div>
                    <div className="mt-2 text-neutral-300">
                      {product.manufacturerName}
                    </div>
                  </div>
                </Table.Cell>
                <Table.Cell>{`${parseFloat(product[modalType.value]).toFixed(
                  2
                )} ${getCorrectUnitAbbreviation(
                  product[modalType.unit]
                )}`}</Table.Cell>
                <Table.Cell>
                  <FormattedNumber roundedTo={2} unit="usCurrency">
                    {getTotalCost(product.averagePriceAtTimeOfCreation)}
                  </FormattedNumber>
                  {` per ${getCorrectUnitAbbreviation(
                    product.stdpackageunit || product.specificCostUnit
                  )}`}
                </Table.Cell>
                <Table.Cell>
                  <FormattedNumber roundedTo={2}>
                    {`${product.appliedAreaValue} `}
                  </FormattedNumber>{' '}
                  ac
                </Table.Cell>
                <Table.Cell>{`${Number(product.coveragePercent).toFixed(
                  2
                )}%`}</Table.Cell>
                <Table.Cell>{`${Number(product.totalProductValue).toFixed(
                  2
                )} ${getCorrectUnitAbbreviation(
                  product.totalProductUnit
                )}`}</Table.Cell>
                <Table.Cell>
                  <FormattedNumber roundedTo={2} unit="usCurrency">
                    {getTotalCost(product.totalCostAtTimeOfCreation)}
                  </FormattedNumber>
                </Table.Cell>
                <Table.Cell>
                  <div className="flex w-28 items-center justify-around">
                    <div>
                      <FormattedNumber roundedTo={2} unit="usCurrency">
                        {getTotalCost(
                          product.totalCostAtTimeOfCreation *
                            (checkForUndefined(
                              product.shareOwnerInformation
                                ?.percentGrowerResponsibility
                            ) /
                              100)
                        )}
                      </FormattedNumber>
                    </div>
                  </div>
                </Table.Cell>

                <Table.Cell>
                  <div className="mr-2">
                    <EllipseMenu
                      onAction={action =>
                        handleContextMenuAction(action, product, index)
                      }
                      options={TAB_OPTIONS}
                    />
                  </div>
                </Table.Cell>
              </Table.Row>
            );
          })}
        </Table>
      )}
    </div>
  );
};

TaskProductTable.propTypes = {
  removeProduct: PropTypes.func.isRequired,
  editProduct: PropTypes.func.isRequired,
  memberRole: PropTypes.string.isRequired,
  memberFinancialAccess: PropTypes.string.isRequired,
  selectedApplicationOption: PropTypes.string.isRequired,
  setCustomProductCreated: PropTypes.func.isRequired,
  customProductCreated: PropTypes.bool.isRequired
};

export default TaskProductTable;
