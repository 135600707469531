/* eslint-disable import/prefer-default-export */
import McCainLogo from 'assets/integrations/McCainLogo.png';
import SimplotLogo from 'assets/integrations/SimplotLogo.svg';
import AgrianLogo from 'assets/integrations/AgrianLogo.svg';
import CalAgLogo from 'assets/integrations/CalAgPermitsLogo.png';
import CropwiseProductLogo from 'assets/CropwiseProductLogo.svg';
import AgVendLogo from 'assets/integrations/AgVend.png';

export const determineSVG = integrationType => {
  switch (integrationType) {
    case 'McCain':
      return McCainLogo;
    case 'Simplot':
      return SimplotLogo;
    case 'Agrian':
      return AgrianLogo;
    case 'CalAgPermits':
      return CalAgLogo;
    case 'Connect':
      return CropwiseProductLogo;
    case 'AgVend':
      return AgVendLogo;
    default:
      return null;
  }
};
