/* eslint-disable import/prefer-default-export */
import PropTypes from 'prop-types';

export const TStorageLocationItemProps = PropTypes.shape({
  id: PropTypes.string,
  name: PropTypes.string,
  email: PropTypes.string,
  phone: PropTypes.string,
  company: PropTypes.string,
  addressLineOne: PropTypes.string,
  addressLineTwo: PropTypes.string,
  city: PropTypes.string,
  state: PropTypes.string,
  zipCode: PropTypes.string,
  notes: PropTypes.string,
  shape: PropTypes.string,
  diameter: PropTypes.number,
  height: PropTypes.number,
  coneHeight: PropTypes.number,
  volume: PropTypes.number,
  capacity: PropTypes.number,
  coolingSystem: PropTypes.string,
  fillingSystem: PropTypes.string,
  unloadingSystem: PropTypes.string,
  owner: PropTypes.string,
  status: PropTypes.string,
  yearBuilt: PropTypes.string,
  quality: PropTypes.string,
  propertyTaxId: PropTypes.string,
  advancedNotes: PropTypes.string,
  createdAt: PropTypes.string,
  updatedAt: PropTypes.string
});
