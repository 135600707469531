/* eslint-disable import/prefer-default-export */

export const emailValidation = email => {
  if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(email)) {
    return 'Invalid email address';
  }

  return undefined;
};

export const phoneNumberValidation = phoneNumber => {
  if (
    !/^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/i.test(phoneNumber)
  ) {
    return 'Invalid phone number';
  }

  return undefined;
};

export const postalCodeValidation = postalCode => {
  if (!/(^\d{5}$)|(^\d{5}-\d{4}$)/i.test(postalCode)) {
    return 'Invalid zip/postal code';
  }

  return undefined;
};

export const getValidDate = (date, defaultDate = new Date()) => {
  const customDate = date ? new Date(date) : defaultDate;

  return customDate instanceof Date && !isNaN(customDate)
    ? customDate
    : defaultDate;
};

export const validateNaN = num =>
  !Number.isNaN(Number.parseFloat(num)) ? num : 0;
