import React, { useContext, useEffect, useState } from 'react';
import { useFormikContext } from 'formik';
import { INTEGRATIONS } from 'screens/Integrations/helpers/constants';
import extractCropZoneIds from 'screens/Integrations/helpers/extractCropZoneIds';
import useSubmissionAnalytics from 'screens/Integrations/hooks/useSubmissionAnalytics';
import { CalAgContext } from '../../context/Provider';
import PropertyIntegrationBase from '../../../components/PropertyIntegration';
import { PropertiesMatchContext } from '../../../PropertiesMatch/context/PropertiesMatchProvider';
import { SubmissionSummaryContext } from '../../../SubmissionSummary/context/SubmissionSummaryProvider';

const CalAgPropertiesSelection = () => {
  const { events, triggerAnalyticsEvent } = useSubmissionAnalytics();
  const { goForward } = useContext(CalAgContext);
  const { updateSubmissionFilterCropZoneIds } = useContext(
    SubmissionSummaryContext
  );
  const { updateFarmsMatch } = useContext(PropertiesMatchContext);
  const [keepCurrentStep, setKeepCurrentStep] = useState(true);
  const { values } = useFormikContext();

  useEffect(() => {
    setKeepCurrentStep(!keepCurrentStep);
    updateFarmsMatch(values.selectedProperties);
    if (values.selectedProperties && !keepCurrentStep) {
      updateSubmissionFilterCropZoneIds(
        extractCropZoneIds(values.selectedProperties)
      );

      triggerAnalyticsEvent(
        events.epic.Integrations.submissionPropertiesSelected,
        {
          vendor: INTEGRATIONS.calAgPermits
        }
      );
      goForward();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values.selectedProperties]);

  return (
    <PropertyIntegrationBase integrationName={INTEGRATIONS.calAgPermits} />
  );
};

export default CalAgPropertiesSelection;
