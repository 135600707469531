import { ROLES } from 'utilities/access';

import People from 'screens/People';
import Person from 'screens/People/Person';

import { PeopleIcon } from 'routes/icons';
import { paths } from 'routes/paths';

const routes = [
  {
    id: 'people',
    key: 'people',
    path: paths.people,
    exact: true,
    secure: true,
    disabled: false,
    component: People,
    icon: PeopleIcon,
    nav: 'leftNav'
  },
  {
    id: 'personEntry',
    key: 'personEntry',
    path: `${paths.people}/:id`,
    exact: true,
    secure: true,
    component: Person,
    minPermissions: {
      minAllowedRole: ROLES.FULL_CONTROL
    }
  },
  {
    id: 'personView',
    key: 'personView',
    path: `${paths.people}/:id/:view`,
    exact: true,
    secure: true,
    component: Person
  }
];

export default routes;
