/* eslint-disable react/prop-types */
import React, { useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import { useFormikContext } from 'formik';
import _ from 'lodash';

import SeedSource from 'screens/Property/PropertiesLanding/PropertyTable/UniqueFields/SeedSource';
import InsuranceId from 'screens/Property/PropertiesLanding/PropertyTable/UniqueFields/InsuranceId';
import SeedLotId from 'screens/Property/PropertiesLanding/PropertyTable/UniqueFields/SeedLotId';

import FSAArea from 'screens/Property/PropertiesLanding/PropertyTable/UniqueFields/FSAArea';
import PermitId from 'screens/Property/PropertiesLanding/PropertyTable/UniqueFields/PermitId';
import SiteId from 'screens/Property/PropertiesLanding/PropertyTable/UniqueFields/SiteId';
import CropZoneBlockId from 'screens/Property/PropertiesLanding/PropertyTable/UniqueFields/CropZoneBlockId';
import TillageSystem from 'screens/Property/PropertiesLanding/PropertyTable/UniqueFields/TillageSystem';

import Notes from 'screens/Property/PropertiesLanding/PropertyTable/UniqueFields/Notes';

import Irrigated from 'screens/Property/PropertiesLanding/PropertyTable/UniqueFields/Irrigated';
import WaterSource from 'screens/Property/PropertiesLanding/PropertyTable/UniqueFields/WaterSource';
import WaterOrigin from 'screens/Property/PropertiesLanding/PropertyTable/UniqueFields/WaterOrigin';
import PumpPressure from 'screens/Property/PropertiesLanding/PropertyTable/UniqueFields/PumpPressure';
import PumpLift from 'screens/Property/PropertiesLanding/PropertyTable/UniqueFields/PumpLift';
import PumpEnergySource from 'screens/Property/PropertiesLanding/PropertyTable/UniqueFields/PumpEnergySource';
import IrrigationSystem from 'screens/Property/PropertiesLanding/PropertyTable/UniqueFields/IrrigationSystem';
import UsePolyacrylamide from 'screens/Property/PropertiesLanding/PropertyTable/UniqueFields/UsePolyacrylamide';
import useCompanies from 'hooks/useCompanies';
import Accordion from '../components/Accordion';
import Editor from '../components/Editor';

const EditCropZone = ({ cropZone, farm, field, setEdited }) => {
  const { values, setValues } = useFormikContext();
  const { companies, loading: companiesLoading } = useCompanies();

  const isEverythingLoaded = cropZone && farm && field && !companiesLoading;

  const initialValues = useMemo(
    () =>
      isEverythingLoaded
        ? {
            ...cropZone,
            siteId: cropZone.siteId ?? field.siteId,
            permitId: cropZone.permitId ?? field.permitId ?? farm.permitId
          }
        : {},
    [isEverythingLoaded, field, farm, cropZone]
  );

  useEffect(() => {
    if (isEverythingLoaded) {
      setValues(initialValues);
    }
  }, [initialValues, isEverythingLoaded, setValues]);

  const areValuesEqual = () => {
    const modifiedInitialValues = {
      ...initialValues
    };
    const modifiedValues = {
      ...values
    };
    Object.keys(modifiedInitialValues).forEach(k => {
      modifiedInitialValues[k] = modifiedInitialValues[k] || '';
      modifiedValues[k] = modifiedValues[k] || '';
    });
    Object.keys(values).forEach(k => {
      modifiedInitialValues[k] = modifiedInitialValues[k] || '';
      modifiedValues[k] = modifiedValues[k] || '';
    });
    return _.isEqual(modifiedInitialValues, modifiedValues);
  };

  useEffect(() => {
    if (areValuesEqual() || !isEverythingLoaded) {
      setEdited(false);
    } else {
      setEdited(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initialValues, values]);

  if (!isEverythingLoaded) {
    return <></>;
  }

  const companiesList = companies?.map(company => {
    return { value: company.name, key: company.id };
  });

  return (
    <>
      <Accordion header="Crop">
        <Editor>
          <SeedSource
            companiesList={[{ key: 'none', value: 'None' }, ...companiesList]}
          />
        </Editor>
        <Editor>
          <InsuranceId />
        </Editor>
        <Editor>
          <SeedLotId />
        </Editor>
      </Accordion>
      <Accordion header="FSA">
        <Editor suffix="ac">
          <FSAArea />
        </Editor>
      </Accordion>
      <Accordion header="Regulatory Permit">
        <Editor>
          <PermitId />
        </Editor>
        <Editor>
          <SiteId />
        </Editor>
        <Editor>
          <CropZoneBlockId />
        </Editor>
      </Accordion>
      <Accordion header="Land">
        <Editor>
          <TillageSystem />
        </Editor>
      </Accordion>
      <Accordion header="Water">
        <Editor>
          <Irrigated />
        </Editor>
        <Editor>
          <WaterSource />
        </Editor>
        <Editor>
          <WaterOrigin />
        </Editor>
        <Editor>
          <IrrigationSystem />
        </Editor>
        <Editor suffix="PSI">
          <PumpPressure />
        </Editor>
        <Editor suffix="ft">
          <PumpLift />
        </Editor>
        <Editor>
          <PumpEnergySource />
        </Editor>
        <Editor>
          <UsePolyacrylamide />
        </Editor>
      </Accordion>
      <Accordion header="Notes">
        <Editor>
          <Notes />
        </Editor>
      </Accordion>
    </>
  );
};

EditCropZone.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  cropZone: PropTypes.object.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  field: PropTypes.object.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  farm: PropTypes.object.isRequired,
  setEdited: PropTypes.func.isRequired
};

export default EditCropZone;
