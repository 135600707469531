import { genProxyResource, plans as plansApi } from 'utilities/api';
import {
  dateRangeFilter,
  sort,
  findPlan
} from 'screens/Plans/helpers/dataHelpers';

// eslint-disable-next-line
let currentOrg = null;

const plansSummariesApi = genProxyResource('plan/plansummaries', {
  urlVersion: '/v1'
});

export const resetOrg = () => {
  currentOrg = null;
};

const plansApiFunction = async (
  {
    pageNo,
    size,
    seasonIdsCommaSeparated,
    dateRange,
    nameQuery,
    sortBy,
    sortDir
  },
  organization
) => {
  const plansParams = {
    pageNo,
    size,
    seasonIdsCommaSeparated
  };
  const { promise: p1 } = plansApi.fetch(null, plansParams);
  const { promise: p2 } = plansSummariesApi.fetch(null, plansParams);

  let { rawPlans, totalElements } = await p1.then(({ data }) => {
    return {
      rawPlans: data.results.reduce((acc, plan) => {
        acc[plan.id] = plan;
        return acc;
      }, {}),
      totalElements: data.totalElements
    };
  });

  await p2.then(({ data }) => {
    data.results.forEach(plan => {
      rawPlans[plan.planId] = {
        ...rawPlans[plan.planId],
        ...plan
      };
    });
  });

  rawPlans = Object.values(rawPlans);

  currentOrg = organization.id;

  // Filter by Date range
  let filteredPlans = dateRangeFilter(rawPlans, dateRange);

  // Filter by Name
  filteredPlans = findPlan(filteredPlans, nameQuery);

  // Sort
  filteredPlans = sort(filteredPlans, sortBy, sortDir);
  return {
    promise: Promise.resolve({
      data: {
        results: filteredPlans,
        totalElements
      }
    })
  };
};

export default plansApiFunction;
