import React, { useMemo, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import GrowUIFormField from 'components/Forms/GrowUIFormField';
import { useFormikContext } from 'formik';
import { DatePicker } from '@agconnections/grow-ui';
import sortDates from 'screens/Integrations/helpers/sortDates';

const CropSeasonSingleDateRange = ({ className, isRequired, isDisabled }) => {
  const { values, setFieldValue } = useFormikContext();

  const touchedRef = useRef(null);
  const untouchedSelectionRef = useRef(null);

  const { startDate: sd, endDate: ed } = useMemo(() => {
    const { startDate, endDate } = sortDates(values.selectedCropSeasons);
    return { startDate, endDate };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values.selectedCropSeasons]);

  // eslint-disable-next-line consistent-return
  useEffect(() => {
    if (!touchedRef.current && values.seasonSingleDateRangeInitial) {
      setFieldValue(
        'seasonSingleDateRange',
        values.seasonSingleDateRangeInitial
      );
    } else {
      setFieldValue('seasonSingleDateRange', [sd, ed]);
    }

    if (
      untouchedSelectionRef.current !== null &&
      values.selectedCropSeasons !== untouchedSelectionRef.current
    ) {
      touchedRef.current = true;
    }

    if (values.selectedCropSeasons && untouchedSelectionRef.current === null) {
      untouchedSelectionRef.current = values.selectedCropSeasons;
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sd, ed]);

  return (
    <div className={`w-full ${className}`}>
      <span className="text-sm text-neutral-600">Date Range</span>
      <GrowUIFormField
        control={DatePicker}
        id="seasonSingleDateRange"
        name="seasonSingleDateRange"
        required={isRequired}
        disabled={isDisabled}
        isRangePicker
      />
    </div>
  );
};

CropSeasonSingleDateRange.propTypes = {
  className: PropTypes.string,
  isRequired: PropTypes.bool.isRequired,
  isDisabled: PropTypes.bool.isRequired
};

CropSeasonSingleDateRange.defaultProps = {
  className: ''
};

export default CropSeasonSingleDateRange;
