import React, { useEffect, useContext } from 'react';

import PropTypes from 'prop-types';
import ConfirmationModal from './components/ConfirmationModal';
import TopHeader from './components/TopHeader';
import FarmList from './components/FarmList';
import PropertiesMatchFooter from './components/Footer';
import { PropertiesMatchContext } from './context/PropertiesMatchProvider';
import useFormikHandler from '../hooks/useFormikHandler';

const PropertiesMatch = ({ integrationName }) => {
  const { selectedProperties } = useFormikHandler();

  const {
    state: { unmatchedEntities },
    setOpenConfirmationModal,
    integrationContext
  } = useContext(PropertiesMatchContext);

  const { blockStepForward, releaseStepForward } = useContext(
    integrationContext
  );

  useEffect(() => {
    setOpenConfirmationModal(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (unmatchedEntities === 0) {
      releaseStepForward();
    } else {
      blockStepForward();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [unmatchedEntities]);

  return (
    <>
      <div
        className="w-full bg-white flex flex-col px-6 py-4 rounded-xl font-body"
        data-testid="properties-match-view"
      >
        <div
          className="flex flex-col w-full mb-3"
          id="properties-match-top-header"
        >
          <TopHeader farmFieldTreeData={selectedProperties} />
        </div>
        <div className="w-full mb-5 overflow-y-auto">
          <FarmList
            farmFieldTreeData={selectedProperties}
            integrationName={integrationName}
          />
        </div>
        <PropertiesMatchFooter
          setModalSubmissionState={setOpenConfirmationModal}
        />
      </div>
      <ConfirmationModal />
    </>
  );
};

PropertiesMatch.propTypes = {
  integrationName: PropTypes.string.isRequired
};

export default PropertiesMatch;
