import React, { useEffect, useState } from 'react';
import { useFormikContext } from 'formik';
import ClearIcon from '@material-ui/icons/Clear';
import KeyboardArrowDown from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUp from '@material-ui/icons/KeyboardArrowUp';
import PropTypes from 'prop-types';

import { Popup, Spinner, Checkbox, Radio } from '@agconnections/grow-ui';

const CropSeasonDropdown = ({
  onSelect,
  allCropSeasonsData,
  csLoaded,
  selectedActive,
  isRequired
}) => {
  const { values } = useFormikContext();

  const [categorizedCropSeasons, setCategorizedCropSeasons] = useState({
    active: [],
    upcoming: [],
    past: []
  });
  const [dropDownOpened, setDropDownOpened] = useState(false);
  const [initialLoad, setInitialLoad] = useState(true);

  const [
    selectedActiveCropSeasonIds,
    setSelectedActiveCropSeasonIds
  ] = useState(
    values.selectedCropSeasons
      ? values?.selectedCropSeasons?.map(season => {
          if (season?.active) return season.id;
          return null;
        })
      : []
  );
  const [
    selectedInactiveCropSeasonId,
    setSelectedInactiveCropSeasonId
  ] = useState(
    values.selectedCropSeasons &&
      values?.selectedCropSeasons?.[0]?.active === false
      ? values?.selectedCropSeasons?.[0]?.id
      : null
  );

  const [selectedCropSeasonData, setSelectedCropSeasonData] = useState(
    values?.selectedCropSeasons || []
  );

  const [truncatedItems, setTruncatedItems] = useState(0);

  const offSetTruncatedTextAndArrowIcon = 200;

  useEffect(() => {
    // To Categorize CropSeasons
    if (csLoaded && allCropSeasonsData.length > 0) {
      const currentTime = new Date().getTime();
      const categorizedCropSeasonData = { active: [], upcoming: [], past: [] };
      allCropSeasonsData.forEach(item => {
        if (currentTime > item.startDateEpoch) {
          if (currentTime > item.endDateEpoch) {
            categorizedCropSeasonData.past.push(item);
          } else {
            categorizedCropSeasonData.active.push(item);
          }
        } else {
          categorizedCropSeasonData.upcoming.push(item);
        }
      });
      setCategorizedCropSeasons({ ...categorizedCropSeasonData });
    }
  }, [csLoaded, allCropSeasonsData]);

  useEffect(() => {
    onSelect(selectedCropSeasonData);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedCropSeasonData]);

  useEffect(() => {
    // Set Selected Crop Seasons to Formik and CropSeasonBoxTrigger
    const selectedCS = [];
    if (
      csLoaded &&
      allCropSeasonsData.length > 0 &&
      selectedActiveCropSeasonIds.length > 0
    ) {
      const filteredCSData = allCropSeasonsData.filter(cs =>
        selectedActiveCropSeasonIds.includes(cs.id)
      );
      selectedCS.push(...filteredCSData);
    }
    if (
      csLoaded &&
      allCropSeasonsData.length > 0 &&
      selectedInactiveCropSeasonId !== null &&
      selectedCS.length === 0
    ) {
      const filteredCSData = allCropSeasonsData.find(
        cs => cs.id === selectedInactiveCropSeasonId
      );
      selectedCS.push(filteredCSData);
    }

    if (initialLoad && selectedCS.length) {
      onSelect(selectedCS);
      setInitialLoad(false);
    }

    setSelectedCropSeasonData(selectedCS);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    selectedActiveCropSeasonIds,
    selectedInactiveCropSeasonId,
    allCropSeasonsData,
    csLoaded
  ]);

  useEffect(() => {
    // We check if the selectedActive property is enabled and we initialize the input with the active crop seasons
    if (selectedActive && categorizedCropSeasons.active.length) {
      setSelectedCropSeasonData(categorizedCropSeasons.active);
      setSelectedActiveCropSeasonIds(
        categorizedCropSeasons.active.map(cropSeason => {
          return cropSeason?.id;
        })
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [categorizedCropSeasons]);

  useEffect(() => {
    if (!dropDownOpened) {
      onSelect(selectedCropSeasonData);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dropDownOpened]);

  const calculateFlexibleTruncation = csObject => {
    const widthOfElements = [];
    csObject.forEach(cs => {
      const span = document.createElement('span');

      span.textContent = cs?.name;
      span.style.fontFamily = 'Arial';
      span.style.fontSize = '0.75rem';
      span.style.padding = '6px';

      document.body.appendChild(span);

      widthOfElements.push(span.offsetWidth);

      document.body.removeChild(span);
    });
    return widthOfElements;
  };

  const removeCropSeasonByIdAndCategory = (id, category) => {
    if (category === true)
      setSelectedActiveCropSeasonIds(
        selectedActiveCropSeasonIds?.filter(seasonId => seasonId !== id)
      );
    else setSelectedInactiveCropSeasonId(null);
  };

  const getDropDownWidth = () => {
    const dropDownSelect = document.getElementById('dropDown');
    return dropDownSelect.offsetWidth - offSetTruncatedTextAndArrowIcon;
  };
  const truncateCs = () => {
    const sizeOfElements = calculateFlexibleTruncation(selectedCropSeasonData);
    const sizeOfDropDown = getDropDownWidth();
    let sizeToCompare = 0;
    let toTruncateItem = 0;

    sizeOfElements.every(element => {
      sizeToCompare += Number(element);
      if (sizeToCompare < sizeOfDropDown) {
        toTruncateItem += 1;
        return true;
      }
      toTruncateItem -= 1;
      return false;
    });
    setTruncatedItems(selectedCropSeasonData.slice(toTruncateItem));
  };

  useEffect(() => {
    truncateCs();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedCropSeasonData]);

  const CropSeasonBoxTrigger = (
    <div
      data-testid="crop-season-trigger-box"
      className="w-full"
      role="button"
      tabIndex={0}
      onClick={() => {
        setDropDownOpened(!dropDownOpened);
      }}
      onKeyDown={event =>
        event.nativeEvent.key === 'Enter' && setDropDownOpened(!dropDownOpened)
      }
    >
      <div className="pb-2 w-full">
        <div
          id="dropDown"
          className="text-neutral-600 text-left mb-1 text-sm"
          data-testid="crop-season-heading"
        >
          {isRequired ? 'Crop Season(s)*' : 'Crop Season(s)'}
        </div>
        <div
          className="flex flex-wrap justify-between border border-neutral-30 rounded h-10"
          data-testid="crop-season-select"
        >
          <div className="flex">
            {csLoaded ? (
              selectedCropSeasonData
                .slice(truncatedItems.length)
                ?.map(cropSeason => {
                  return (
                    <div
                      key={cropSeason?.id}
                      data-testid="crop-season-name"
                      className="flex flex-no-wrap bg-gray-100 rounded mx-1 my-2 justify-start"
                    >
                      <div
                        className="whitespace-nowrap p-1 text-xs text-neutral-600 font-semibold"
                        data-testid="crop-season-name"
                      >
                        {cropSeason?.name}
                      </div>
                      <button
                        type="button"
                        data-testid="remove-crop-season-button"
                        onClick={() =>
                          removeCropSeasonByIdAndCategory(
                            cropSeason?.id,
                            cropSeason?.active
                          )
                        }
                      >
                        <ClearIcon
                          style={{
                            fontSize: 12,
                            marginBottom: '3px',
                            marginRight: '2px',
                            color: '#696F88'
                          }}
                        />
                      </button>
                    </div>
                  );
                })
            ) : (
              <Spinner />
            )}
            {truncatedItems.length > 0 ? (
              <div className="flex flex-no-wrap mx-1 my-2 justify-start">
                <span className="whitespace-nowrap p-1 text-xs text-neutral-600 font-semibold">
                  {`+ ${truncatedItems.length} more season(s)`}
                </span>
              </div>
            ) : null}
          </div>
          <div
            className={
              selectedActiveCropSeasonIds.length < 1 ||
              selectedInactiveCropSeasonId === null
                ? 'flex justify-end'
                : ''
            }
          >
            {dropDownOpened ? (
              <KeyboardArrowUp
                style={{
                  color: '#696F88',
                  marginTop: '8px',
                  marginRight: '4px',
                  marginLeft: '6px'
                }}
              />
            ) : (
              <KeyboardArrowDown
                style={{
                  color: '#696F88',
                  marginTop: '8px',
                  marginRight: '4px',
                  marginLeft: '6px'
                }}
              />
            )}
          </div>
        </div>
      </div>
    </div>
  );

  return (
    <Popup
      trigger={CropSeasonBoxTrigger}
      position="bottom"
      align="left"
      hasOffset
      open={dropDownOpened}
      onClose={() => setDropDownOpened(false)}
      containerClasses="w-full"
    >
      <div data-testid="crop-season-popup" className="w-92 h-full text-left">
        {csLoaded ? (
          <>
            <div className="font-semibold text-sm mb-2 ml-2">Crop Seasons</div>
            <div className="font-normal text-xs mb-2 ml-2 text-neutral-600">
              Active Seasons
            </div>
            <ul className="mb-2  ml-2">
              {categorizedCropSeasons.active.length === 0 ? (
                <div className="font-normal text-sm ml-4">
                  No Active Seasons
                </div>
              ) : (
                categorizedCropSeasons.active.map(item => (
                  <Checkbox
                    key={item.id}
                    label={item.name}
                    value={item.id}
                    checked={selectedActiveCropSeasonIds.includes(item.id)}
                    onChange={e => {
                      if (selectedInactiveCropSeasonId)
                        setSelectedInactiveCropSeasonId(null);

                      if (e.target.checked) {
                        setSelectedActiveCropSeasonIds([
                          ...selectedActiveCropSeasonIds,
                          e.target.value
                        ]);
                      } else {
                        setSelectedActiveCropSeasonIds(
                          selectedActiveCropSeasonIds.filter(
                            season => season !== e.target.value
                          )
                        );
                      }
                    }}
                  />
                ))
              )}
            </ul>
            <div className="font-normal text-xs ml-2 mb-2 text-neutral-600">
              Upcoming Seasons
            </div>
            <ul className="mb-2 ml-2">
              {categorizedCropSeasons.upcoming.length === 0 ? (
                <div className="font-normal text-sm ml-4">
                  No Upcoming Seasons
                </div>
              ) : (
                categorizedCropSeasons.upcoming.map(cropSeason => (
                  <Radio
                    key={cropSeason?.id}
                    label={cropSeason?.name}
                    value={cropSeason?.id}
                    checked={selectedInactiveCropSeasonId === cropSeason?.id}
                    onChange={e => {
                      if (selectedActiveCropSeasonIds)
                        setSelectedActiveCropSeasonIds([]);
                      setSelectedInactiveCropSeasonId(e.target.value);
                    }}
                  />
                ))
              )}
            </ul>
            <div className="font-normal text-xs ml-2 mb-2 text-neutral-600">
              Past Seasons
            </div>
            <ul className="mb-2 ml-2">
              {categorizedCropSeasons.past.length === 0 ? (
                <div className="font-normal text-sm ml-4">No Past Seasons</div>
              ) : (
                categorizedCropSeasons.past.map(cropSeason => (
                  <Radio
                    key={cropSeason?.id}
                    label={cropSeason?.name}
                    value={cropSeason?.id}
                    checked={selectedInactiveCropSeasonId === cropSeason?.id}
                    onChange={e => {
                      if (selectedActiveCropSeasonIds)
                        setSelectedActiveCropSeasonIds([]);
                      setSelectedInactiveCropSeasonId(e.target.value);
                    }}
                  />
                ))
              )}
            </ul>
          </>
        ) : (
          <Spinner />
        )}
      </div>
    </Popup>
  );
};
CropSeasonDropdown.defaultProps = {
  allCropSeasonsData: [{}]
};
CropSeasonDropdown.propTypes = {
  csLoaded: PropTypes.bool.isRequired,
  onSelect: PropTypes.func.isRequired,
  allCropSeasonsData: PropTypes.arrayOf(PropTypes.object),
  selectedActive: PropTypes.bool.isRequired,
  isRequired: PropTypes.bool.isRequired
};

export default CropSeasonDropdown;
