import CropIconMapping from 'components/CropIconMapping';
import React from 'react';
/* eslint-disable import/prefer-default-export */

export const checkIfMatchExistsInCropZones = (cropZones, field, matches) => {
  return matches.find(
    match =>
      cropZones.some(cropZone => cropZone.id === match.cwfId) &&
      match.vendorId === field.id
  );
};

export const buildFieldOptionsForSelect = (farm, field) => ({
  label: (
    <span className="text-xs font-semibold">
      {farm.name} / {field.name}
    </span>
  ),
  title: `${farm.name} / ${field.name}`,
  options: field.cropzones.map(cropzone => {
    const icon = CropIconMapping({
      cropObject: {
        name: cropzone.crop.name
      },
      size: 14
    });
    return {
      label: (
        <div className="flex flex-col">
          <span className="font-medium">{cropzone.name}</span>
          <div className="flex items-center font-normal">
            <div className="mr-2px">{icon}</div>
            <small className="text-gray-600">{cropzone.crop.name}</small>
          </div>
        </div>
      ),
      title: cropzone.name,
      value: cropzone.id
    };
  })
});
