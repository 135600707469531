import React, { useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useFormikContext } from 'formik';
import { MAX_PAGINATION_SIZE } from 'helpers/globalConstants';
import useInventoryData from 'screens/Inventory/helpers/useInventoryData';
import { ReportsContext } from 'screens/Reports/context/ReportsProvider';
import { getProductListFilter } from 'screens/Reports/helpers/helperFunctions';
import {
  ReportCategories,
  ReportSubTypes
} from 'screens/Reports/hooks/useReportMetadata';
import useTaskProducts from 'screens/Reports/hooks/useTaskProducts';
import GroupByProducts from './components/GroupByProducts';
import { getProductCount } from '../../helpers/helperFunctions';

const removeDuplicateProducts = _products => [
  ...new Map(_products.map(_p => [_p.productId, _p])).values()
];

const Products = ({ cropSeasons }) => {
  const [mappedData, setMappedData] = useState([]);
  const [products, setProducts] = useState([]);
  const { values } = useFormikContext();
  const { getActiveReport } = useContext(ReportsContext);
  const activeReport = getActiveReport();

  const includeInvoiceProducts =
    activeReport.category === ReportCategories.Products &&
    activeReport.subType === ReportSubTypes.InventorySummary;

  const payload = () => {
    return values.selectedCropSeasons
      ? {
          seasonIdsCommaSeparated: values.selectedCropSeasons
            .map(cs => cs.id)
            .join(',')
        }
      : {};
  };

  const {
    taskProducts,
    loading: isTaskProductsLoading,
    loadTaskProducts
  } = useTaskProducts(payload());
  const {
    products: inventoryProducts,
    loading: isInventoryLoading,
    getInventoryDetails
  } = useInventoryData(false);

  useEffect(() => {
    if (values.selectedCropSeasons.length && activeReport) {
      loadTaskProducts(payload());
      if (includeInvoiceProducts) {
        getInventoryDetails(undefined, MAX_PAGINATION_SIZE);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values.selectedCropSeasons, activeReport]);

  useEffect(() => {
    if (
      !values.selectedCropSeasons.length ||
      !taskProducts ||
      isTaskProductsLoading ||
      (includeInvoiceProducts && (!inventoryProducts || isInventoryLoading))
    )
      return;
    let displayProducts = taskProducts;
    if (includeInvoiceProducts) {
      displayProducts = displayProducts.concat(
        inventoryProducts.filter(p => p.invoicesIds?.length)
      );
      displayProducts = removeDuplicateProducts(displayProducts);
    }
    displayProducts.sort((a, b) => a.productName.localeCompare(b.productName));
    setProducts(displayProducts);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    taskProducts,
    isTaskProductsLoading,
    inventoryProducts,
    isInventoryLoading
  ]);

  useEffect(() => {
    setMappedData(getProductListFilter(products));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values.selectedCropSeasons, products]);

  return (
    <div className="flex flex-col items-center bg-white">
      {cropSeasons?.length > 0 && (
        <div className="h-10 w-full">
          <h1
            data-testid="product-text"
            className="font-body font-semibold text-xl"
            style={{ color: '#14151C' }}
          >
            Products ({getProductCount(mappedData)})
          </h1>
          <GroupByProducts
            mappedData={mappedData}
            loading={
              isTaskProductsLoading ||
              (includeInvoiceProducts && isInventoryLoading)
            }
          />
        </div>
      )}
    </div>
  );
};

Products.defaultProps = {
  cropSeasons: []
};

Products.propTypes = {
  cropSeasons: PropTypes.arrayOf(PropTypes.object)
};

export default Products;
