import React from 'react';
import propTypes from 'prop-types';

import mapIcon from 'assets/properties-map-icon.svg';

const EmptySummary = ({ baseText }) => {
  return (
    <div data-testid="empty-summary">
      <div className="flex justify-center">
        <img src={mapIcon} alt="" style={{ width: '65%' }} />
      </div>
      <div className="flex justify-center pb-4">
        <p className="text-sm w-180px text-center">{baseText}</p>
      </div>
    </div>
  );
};

EmptySummary.propTypes = {
  baseText: propTypes.string
};

EmptySummary.defaultProps = {
  baseText: 'Select one or more properties from the list to assign a crop'
};
export default EmptySummary;
