import { useState } from 'react';
import { cropZoneV2 } from 'utilities/api';
import catchCancel from 'helpers/catchCancel';

const useCropZoneData = () => {
  const [loading, setLoading] = useState(false);

  const getCropZoneCost = async ({ id }) => {
    setLoading(true);

    const cropZoneApi = cropZoneV2.createChildApi({
      action: `cropzone/${id}/cost`
    });

    const { promise } = cropZoneApi.fetch();

    return promise
      .then(({ data }) => {
        setLoading(false);
        return data;
      })
      .catch(catchCancel)
      .catch(() => {
        setLoading(false);

        return [];
      });
  };

  return {
    getCropZoneCost,
    loading,
    setLoading
  };
};

export default useCropZoneData;
