import React from 'react';
import { Button, ButtonType } from 'syngenta-digital-cropwise-react-ui-kit';

const SupportButton = () => {
  return (
    <div data-testid="support-button" className="flex flex-col mt-2">
      <Button
        style={{ alignSelf: 'end' }}
        type={ButtonType.primary}
        onClick={() => {
          window.open(
            'https://www.syngentadigital.com/us-en#support',
            '_blank',
            'noopener'
          );
        }}
      >
        Contact Support
      </Button>
    </div>
  );
};

export default SupportButton;
