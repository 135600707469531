import { SHRINK_TYPE, TEMPLATE_TYPE } from 'screens/YieldV2/constants';
import { percentToDecimal } from 'utilities/numberHelpers';

const processYieldTickets = yieldTickets => {
  const processed = yieldTickets.map(ticket => {
    const {
      sources,
      destination,
      load,
      tareWeight,
      grossWeight,
      grossRevenue,
      netWeight,
      salePrice,
      sourceType,
      templateType,
      harvestedQuantity,
      ...values
    } = ticket;

    const loadAttributes = {};

    if (templateType === TEMPLATE_TYPE.ROW_CROP) {
      loadAttributes.damage = percentToDecimal(values.damage);
      loadAttributes.foreignMaterial = percentToDecimal(values.foreignMaterial);
      loadAttributes.moisture = percentToDecimal(values.moisture);

      if (values.shrinkType === SHRINK_TYPE.BY_PERCENT) {
        loadAttributes.shrink = percentToDecimal(values.shrink);
      } else if (values.shrinkType === SHRINK_TYPE.BY_QUANTITY) {
        loadAttributes.shrink = values.shrink ? parseFloat(values.shrink) : 0;
      }
    }

    if (templateType === TEMPLATE_TYPE.SPECIALTY) {
      loadAttributes.damage = percentToDecimal(values.damage);
      loadAttributes.foreignMaterial = percentToDecimal(values.foreignMaterial);
    }

    return {
      ...values,
      templateType,
      loadTemporaryId: undefined,
      sources: sources.map(source => ({
        id: source.id
      })),
      sourceType,
      destinationId: destination.id,
      destinationType: destination.type,
      load: parseFloat(load),
      harvestedQuantity: harvestedQuantity
        ? parseFloat(harvestedQuantity)
        : null,
      tareWeight: tareWeight ? parseFloat(tareWeight) : null,
      grossWeight: grossWeight ? parseFloat(grossWeight) : null,
      grossRevenue: grossRevenue ? parseFloat(grossRevenue) : null,
      netWeight: netWeight ? parseFloat(netWeight) : null,
      salePrice: salePrice ? parseFloat(salePrice) : null,
      cropZones: null,
      avgYieldPerAcre: 0, // old v1 property, need to be removed on backend
      ...loadAttributes
    };
  });
  return processed;
};

export default processYieldTickets;
