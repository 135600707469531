import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Input, SimpleModal, Toast } from '@agconnections/grow-ui';
import { useFormikContext } from 'formik';

const MccainVendorIdModal = ({ onClose, open }) => {
  const { values, setFieldValue } = useFormikContext();
  const [updatedVendorId, setUpdatedVendorId] = useState(values.mccainVendorId);
  const [error, setError] = useState(null);

  const onReset = () => setError(null);

  const handleSave = () => {
    if (updatedVendorId.length !== 7) {
      setError('Vendor Id must be 7 characters long');
      return;
    }
    setFieldValue('mccainVendorId', updatedVendorId);
    onClose();
    onReset();
  };

  return (
    <div data-testid="mccain-vendor-modal">
      <SimpleModal
        onConfirm={handleSave}
        open={open}
        close={onClose}
        confirmLabel="Save"
        title="Edit McCain Vendor ID"
      >
        {error ? (
          <Toast icon="error" onClose={onReset}>
            {error}
          </Toast>
        ) : null}
        <Input
          className="w-1/2"
          type="text"
          value={updatedVendorId}
          onChange={e => {
            setUpdatedVendorId(e.target.value);
          }}
          imask={{
            mask: 'num',
            blocks: {
              num: {
                mask: Number,
                scale: 0
              }
            }
          }}
        />
      </SimpleModal>
    </div>
  );
};

MccainVendorIdModal.defaultProps = {
  onClose: () => {},
  open: false
};

MccainVendorIdModal.propTypes = {
  onClose: PropTypes.func,
  open: PropTypes.bool
};

export default MccainVendorIdModal;
