import React from 'react';

import { Form, Input } from '@agconnections/grow-ui';
import GrowUIFormField from 'components/Forms/GrowUIFormField';
import NameField from '../NameField';
import CurrentStatusField from '../CurrentStatusField';
import EquipmentTypeField from '../EquipmentTypeField';

const EquipmentDetailsSection = () => (
  <div className="block" data-testid="equipment-item-details-section">
    <div className="text-2xl font-bold">Basic Info</div>
    <div className="flex w-full">
      <div className="w-full mr-16">
        <NameField />
      </div>
      <div className="w-full">
        <EquipmentTypeField />
      </div>
    </div>
    <div className="flex w-full">
      <div className="flex w-1/2 mr-16">
        <div className="w-1/2 mr-8">
          <Form.Field label="Year">
            <GrowUIFormField id="modelYear" name="modelYear" control={Input} />
          </Form.Field>
        </div>
        <div className="w-1/2">
          <Form.Field label="Make">
            <GrowUIFormField control={Input} id="make" name="make" />
          </Form.Field>
        </div>
      </div>
      <div className="w-1/2">
        <Form.Field label="Model">
          <GrowUIFormField control={Input} id="model" name="model" />
        </Form.Field>
      </div>
    </div>
    <div className="flex w-full">
      <div className="w-1/2 mr-16">
        <CurrentStatusField />
      </div>
      <div className="w-1/2">
        <Form.Field label="Unit ID">
          <GrowUIFormField control={Input} id="unitID" name="unitID" />
        </Form.Field>
      </div>
    </div>
    <div className="flex">
      <div className="block w-1/2 mr-16">
        <Form.Field label="Serial Number">
          <GrowUIFormField
            control={Input}
            id="serialNumber"
            name="serialNumber"
          />
        </Form.Field>
      </div>
      <div className="block w-1/2">
        <Form.Field label="License Number">
          <GrowUIFormField
            control={Input}
            id="licenseNumber"
            name="licenseNumber"
          />
        </Form.Field>
      </div>
    </div>
  </div>
);

export default EquipmentDetailsSection;
