import React, { useContext } from 'react';
import { Context } from 'components/Store';
import GrowUIFormField from 'components/Forms/GrowUIFormField';
import { Input } from '@agconnections/grow-ui';

const TenantPercentage = () => {
  const [{ isEnableInfoTabEdit }] = useContext(Context);
  return (
    <GrowUIFormField
      placeholder="Enter tenant percentage"
      control={Input}
      type="text"
      id="tenantPercentage"
      name="tenantPercentage"
      label="Tenant Percentage"
      disabled={!isEnableInfoTabEdit}
    />
  );
};

export default TenantPercentage;
