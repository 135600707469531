import React, { useState, useContext } from 'react';
import PropTypes from 'prop-types';
import ViewOnlyField from 'components/ViewOnlyField';
import DeleteModal from 'components/Modals/DeleteModal';
import EllipseMenu from 'components/EllipseMenu';
import { TextArea } from '@agconnections/grow-ui';
import { format } from 'date-fns';
import { useHistory } from 'react-router-dom';
import { AmplitudeContext } from 'utilities/amplitude/useAmplitude';
import { PEOPLE_OPTIONS_MENU } from 'utilities/menus';
import { paths } from 'routes/paths';
import { mapUserToPerson } from '../helpers';

const PersonView = ({ user, handleDelete }) => {
  const [open, setOpen] = useState(false);
  const openModal = () => setOpen(true);
  const closeModal = () => setOpen(false);
  const history = useHistory();
  const person = mapUserToPerson(user);
  const amplitude = useContext(AmplitudeContext);
  const expDate = Date.parse(
    new Date(user.applicatorLicenseExpirationDateEpoch)
  );
  const formattedDate = expDate ? format(expDate, 'MMM d, yyyy') : '';
  const handleAction = action => {
    const actions = {
      edit: () => {
        amplitude.sendEventToAmplitude(amplitude.events.epic.People.editPerson);
        history.push(`${paths.people}/${user.id}`);
      },
      delete: () => {
        openModal();
      },
      print: () => {}
    };
    actions[action]();
  };

  return (
    <>
      <div className="flex">
        <div className="text-xl w-full font-medium">View Person</div>
        <div>
          <EllipseMenu onAction={handleAction} options={PEOPLE_OPTIONS_MENU} />
        </div>
      </div>
      <div className="py-5">
        <div className="bg-white w-full p-6">
          <div className="w-full">
            <div className="text-xl font-bold">Basic Info</div>
            <div className="flex py-5">
              <ViewOnlyField
                data={person.fullName}
                header="Name"
                className="w-1/3"
                testId="person-fullname"
              />
            </div>
            <div className="flex py-5">
              <ViewOnlyField
                data={person.emailAddress}
                header="Email"
                className="w-1/3"
                testId="person-email"
              />
              <ViewOnlyField
                data={person.phoneNumber}
                header="Phone"
                className="w-1/3"
                testId="person-phone"
              />
            </div>
          </div>
          <div className="w-full border-b py-5" />
          <div className="w-full">
            <div className="text-xl font-bold py-5">Address Info</div>
            {person.streetAddress1 && (
              <div>
                <ViewOnlyField
                  data={person.streetAddress1}
                  testId="person-street1"
                />
                <ViewOnlyField
                  data={person.streetAddress2}
                  testId="person-street2"
                />
                <div className="flex">
                  <ViewOnlyField
                    data={`${person.cityName},`}
                    className="mr-2"
                    testId="person-city"
                  />
                  <ViewOnlyField
                    data={person.state}
                    className="mr-2"
                    testId="person-state"
                  />
                  <ViewOnlyField data={person.postalCode} testId="person-zip" />
                </div>
              </div>
            )}
          </div>
          <div className="w-full border-b py-5" />
          <div className="w-full">
            <div className="text-xl font-bold py-5">License Info</div>
            <div className="flex">
              <ViewOnlyField
                data={person.applicatorLicense.value}
                header="Applicator License"
                className="w-1/3"
                testId="person-license-number"
              />
              <ViewOnlyField
                data={formattedDate}
                header="Valid Through"
                testId="person-expires"
              />
            </div>
          </div>
          <div className="w-full border-b py-5" />
          <div className="w-full">
            <div className="text-xl font-bold py-5">Notes</div>
            <TextArea
              data-testid="person-notes"
              className="h-64 overscroll-auto"
              resizeDisabled
              disabled
              value={person.notes}
            />
          </div>
        </div>
        <DeleteModal
          open={open}
          itemType="Person"
          onCancel={closeModal}
          onDelete={handleDelete}
        />
      </div>
    </>
  );
};

PersonView.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  user: PropTypes.object.isRequired,
  handleDelete: PropTypes.func.isRequired
};

export default PersonView;
