/* eslint-disable no-unused-vars */
import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { Button, Card, Form } from '@agconnections/grow-ui';
import { format } from 'date-fns';

import ExpirationField from './components/ExpirationField';
import LicenseNumberField from './components/LicenseNumberField';

const ApplicatorLicense = ({
  isCreateScreen,
  license,
  onChange,
  handleClick
}) => {
  const [clicked, setClicked] = useState(true);

  const updateLicense = event => {
    const updatedLicense = event.target.value;
    onChange(prevData => {
      return {
        ...prevData,
        value: updatedLicense,
        licenseType: 'Applicator'
      };
    });
  };

  const updateExpiry = event => {
    const newDate = event.target.value[0];
    onChange(prevData => {
      if (event.target) {
        return {
          ...prevData,
          expiry: newDate
            ? Date.parse(event.target.value[0].toISOString())
            : '',
          licenseType: 'Applicator'
        };
      }
      return prevData;
    });
  };

  const parsedExpiry = Date.parse(new Date(license.expiry));
  const formattedExpiry = parsedExpiry
    ? format(parsedExpiry, 'MMM dd, yyyy')
    : '';
  const addEditUI = (
    <div>
      <div className="text-2xl font-bold mt-10">License Info</div>
      <div className="flex" data-testid="applicatorLicenseUI">
        <div className="flex w-full">
          <LicenseNumberField
            title="Applicator License"
            onChange={updateLicense}
            licenseNumber={license.value}
          />
        </div>
        <div className="flex w-full">
          <ExpirationField
            onChange={updateExpiry}
            expiration={license.expiry}
            title="Valid Through"
          />
        </div>
      </div>
    </div>
  );

  const contentClassName =
    'block text-sm font-medium leading-5 text-gray-700 sm:mt-px sm:pt-2';
  return (
    <>
      <Form.Field>{addEditUI}</Form.Field>
    </>
  );
};

ApplicatorLicense.defaultProps = {
  isCreateScreen: false,
  license: {},
  handleClick: () => {}
};

ApplicatorLicense.propTypes = {
  isCreateScreen: PropTypes.bool,
  license: PropTypes.shape({
    value: PropTypes.string,
    expiry: PropTypes.string,
    licenseType: PropTypes.string
  }),
  onChange: PropTypes.func.isRequired,
  handleClick: PropTypes.func
};

export default ApplicatorLicense;
