import React from 'react';
import GrowUIFormField from 'components/Forms/GrowUIFormField';
import { TextArea, Input } from '@agconnections/grow-ui';
import PropTypes from 'prop-types';
import AddressFields from '../AddressFields';

const AddCompany = ({ values, errors, onChange, onBlur }) => {
  return (
    <>
      <div className="flex flex-col gap-1 mb-8 w-1/2 pr-4">
        <label
          htmlFor="owner-name"
          className="text-neutral-60 tracking-tighter text-sm"
        >
          Name<span className="text-remove">*</span>
        </label>
        <Input
          required
          name="name"
          id="owner-name"
          type="text"
          size="middle"
          value={values.name}
          onChange={onChange}
          onBlur={onBlur}
          error={errors}
          errorMessage={errors}
        />
      </div>
      <h3 className="text-base font-semibold block mb-4">Basic Info</h3>

      <div className="flex">
        <div className="flex flex-col gap-1 mb-5 w-full mr-8">
          <label
            htmlFor="legalName"
            className="text-neutral-60 tracking-tighter text-sm"
          >
            Legal Name
          </label>
          <Input
            type="text"
            size="middle"
            name="legalName"
            id="legalName"
            onChange={onChange}
            value={values.legalName}
          />
        </div>
        <div className="flex flex-col gap-1 mb-5 w-full">
          <label
            htmlFor="email"
            className="text-neutral-60 tracking-tighter text-sm"
          >
            Email Address
          </label>
          <Input
            type="text"
            size="middle"
            name="email"
            id="email"
            onChange={onChange}
            value={values.email}
            status={errors?.email && 'error'}
            caption={errors?.email}
          />
        </div>
      </div>
      <div className="flex flex-col gap-1 mb-5 w-1/2 pr-4">
        <div className="flex flex-col gap-1 mb-5 w-full">
          <label
            htmlFor="phone"
            className="text-neutral-60 tracking-tighter text-sm"
          >
            Phone Number
          </label>
          <Input
            type="text"
            size="middle"
            name="phone"
            id="phone"
            value={values.phone}
            onChange={onChange}
          />
        </div>
      </div>
      <h3 className="text-base font-semibold block mb-4">Address</h3>
      <div className="relative py-6 grid gap-4 -mr-4 grid-cols-4">
        <AddressFields />
      </div>
      <div className="my-8 relative">
        <GrowUIFormField
          value={values.notes}
          label="Notes"
          control={TextArea}
          id="notes"
          name="notes"
          className="h-32 overscroll-auto"
        />
      </div>
    </>
  );
};

AddCompany.defaultProps = {
  errors: null
};

AddCompany.propTypes = {
  values: PropTypes.arrayOf(PropTypes.object).isRequired,
  errors: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  onBlur: PropTypes.func.isRequired
};

export default AddCompany;
