import Products from 'screens/Inventory/index';
import ProductInventoryDetails from 'screens/Inventory/InventoryDetails';

import { InventoryIcon } from 'routes/icons';
import { paths } from 'routes/paths';

const routes = [
  {
    id: 'inventory',
    key: 'inventory',
    path: paths.inventory,
    exact: true,
    secure: true,
    disabled: false,
    component: Products,
    icon: InventoryIcon,
    nav: 'leftNav'
  },
  {
    id: 'inventorydetails',
    key: 'inventorydetails',
    path: `${paths.inventory}/:id/view`,
    exact: true,
    secure: true,
    disabled: false,
    component: ProductInventoryDetails
  }
];

export default routes;
