/* eslint-disable import/prefer-default-export */

export const TABLE_COLUMNS = [
  {
    field: 'entryName',
    title: 'Entry Name',
    sortable: true
  },
  {
    field: 'totalYield',
    title: 'Total Yield',
    sortable: true
  },
  {
    field: 'salePrice',
    title: 'Sale Price',
    sortable: true
  },
  {
    field: 'grossRevenue',
    title: 'Gross Revenue',
    sortable: true
  },
  {
    field: 'totalArea',
    title: 'Total Area',
    sortable: true
  },
  {
    field: 'grossRevenuePerAcre',
    title: 'Gross Rev / ac',
    sortable: true
  },
  {
    field: 'source',
    title: 'Source',
    sortable: true
  },
  {
    field: 'menu',
    title: '',
    sortable: false
  }
];
