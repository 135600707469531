import React, { useContext, useEffect } from 'react';
import { Spinner } from '@agconnections/grow-ui';

import { Context } from 'components/Store';
import PortalModal from 'components/Modals/PortalModal';
import ErrorOutline from 'components/Icons/ErrorOutline';

import ModalButtons from './components/ModalButtons';
import { SubmissionSummaryContext } from '../../context/SubmissionSummaryProvider';

const ConfirmationModal = () => {
  const {
    state: { resubmitEnabled, openConfirmationModal, integrationType },
    isLoading,
    isLoadingProductMatching,
    setOpenConfirmationModal
  } = useContext(SubmissionSummaryContext);
  const [{ error }] = useContext(Context);

  const handleClose = () => {
    setOpenConfirmationModal(false);
  };

  useEffect(() => {
    if (error) {
      handleClose();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [error]);

  return (
    <PortalModal
      open={openConfirmationModal}
      type="buttonless"
      style={{ paddingLeft: '2rem' }}
    >
      <div
        className="flex flex-line w-full mt-2 justify-between"
        data-testid="confirmation-modal"
      >
        {isLoadingProductMatching && (
          <div className="pr-12 w-full h-40">
            <p className="text-center">
              Matching your products in Cropwise Financials to {integrationType}
            </p>
            <Spinner />
          </div>
        )}
        {isLoading && (
          <div className="pr-12 w-full h-40">
            <p className="text-center">
              {resubmitEnabled ? 'Resubmiting' : 'Generating Final Submission'}
            </p>
            <Spinner />
          </div>
        )}
        {!isLoading && !isLoadingProductMatching && (
          <>
            <div className="flex mr-4">
              <ErrorOutline />
            </div>
            <div className="flex flex-col pr-10" style={{ flexGrow: 1 }}>
              <div className="flex flex-row justify-items-center pb-4">
                <h3 className="font-bold text-lg leading-6 text-gray-900 w-full">
                  Do you want to {resubmitEnabled ? 'resubmit' : 'submit'} this
                  data?
                </h3>
              </div>
              <div className="flex flex-col mb-4">
                <p>
                  Are you sure you want to{' '}
                  {resubmitEnabled ? 'resubmit' : 'submit'} this data to{' '}
                  {integrationType}?
                </p>
                {resubmitEnabled && (
                  <p>
                    Any changes made directly in the vendor&apos;s portal will
                    be lost.
                  </p>
                )}
              </div>

              <div className="mt-5 flex flex-row items-center justify-end w-full">
                <ModalButtons handleClose={handleClose} />
              </div>
            </div>
          </>
        )}
      </div>
    </PortalModal>
  );
};

export default ConfirmationModal;
