import React from 'react';
import PropTypes from 'prop-types';

import { Tag } from '@agconnections/grow-ui';

const PropertyTag = ({ property, matched }) => {
  if (property.hide) {
    return (
      <Tag color="#F3F4F6">
        <span className=" justify-self-center w-full text-xs mr-5 text-neutral-60 ml-2">
          Hidden
        </span>
      </Tag>
    );
  }

  return matched ? (
    <Tag color="#C3EAD1">
      <span className=" justify-self-center w-full text-xs mr-5 text-green-900 ml-2">
        Matched
      </span>
    </Tag>
  ) : (
    <Tag color="#FFE4AE">
      <span className="justify-self-center w-full text-xs mr-5 text-yellow-700">
        Unmatched
      </span>
    </Tag>
  );
};

PropertyTag.defaultProps = {
  matched: undefined,
  property: {}
};

PropertyTag.propTypes = {
  matched: PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.string,
    reportedArea: PropTypes.number,
    boundaryArea: PropTypes.number
  }),
  property: PropTypes.shape({
    hide: PropTypes.bool
  })
};

export default PropertyTag;
