/* eslint-disable import/prefer-default-export */
import { MEASUREMENT_UNITS } from 'helpers/unitsAbbreviation';

export const YIELD_UNITS = [
  {
    label: 'bushels (bu)',
    value: MEASUREMENT_UNITS.BUSHEL
  },
  {
    label: 'pounds (lbs)',
    value: MEASUREMENT_UNITS.POUND
  },
  {
    label: 'dry ounces (oz)',
    value: MEASUREMENT_UNITS.OUNCE
  },
  {
    label: 'grams (g)',
    value: MEASUREMENT_UNITS.GRAM
  },
  {
    label: 'hundredweights (cwt)',
    value: MEASUREMENT_UNITS.CWT
  },
  {
    label: 'kilograms (kg)',
    value: MEASUREMENT_UNITS.KILOGRAM
  },
  {
    label: 'metric tons (mt)',
    value: MEASUREMENT_UNITS.METRIC_TON
  },
  {
    label: 'tons (t)',
    value: MEASUREMENT_UNITS.TON
  }
];
