import React, { useState, useCallback, useEffect, useContext } from 'react';
import { Context } from 'components/Store';
import PropTypes from 'prop-types';
import { Formik } from 'formik';
import { reduce } from 'lodash';
import { Tabs, Spinner } from '@agconnections/grow-ui';
import { organization as organizationApi } from 'utilities/api';
import catchCancel from 'helpers/catchCancel';
import { parseServerError } from 'helpers/errorHelpers';
import FormNavGuard from 'components/FormNavGuard';
import { useAuth } from 'utilities/base-auth';
import schema from '../../schema';
import tabFieldsMap from './tabFieldsMap';
import {
  PlanDetailsTab,
  PlanPropertiesTab,
  PlanProductsTab,
  TopHeader
} from '../index';
import PlanTabContents from '../PlanTabContents';
import PlanContextWrapper from '../PlanContextWrapper';

const fieldToTabMap = reduce(
  tabFieldsMap,
  (map, fields, key) => ({
    ...map,
    ...fields.reduce((acc, field) => ({ ...acc, [field]: key }), {})
  }),
  {}
);

const PlanForm = ({
  onSubmit,
  plan,
  isSaving,
  readOnly,
  selectedview,
  propertiesSortedData,
  selectedCropSeasonIds,
  propertiesLoading,
  fieldsAndAreasGeoJSONCollection,
  reloadData
}) => {
  const [, dispatch] = useContext(Context);
  const [selectedMember, setSelectedMember] = useState(null);
  const [financialAccess, setFinancialAccess] = useState();
  const { user } = useAuth();
  const getSingleMember = useCallback(async () => {
    const { promise } = organizationApi.fetch();
    await promise
      .then(({ data }) => {
        const filterMember = data?.members?.filter(
          eachMember => eachMember.email === user.email
        )[0];
        setSelectedMember(filterMember);
      })
      .catch(catchCancel)
      .catch(err => {
        parseServerError(dispatch)(err);
      });
  }, [dispatch, user.email]);

  useEffect(() => {
    if (!selectedMember) {
      getSingleMember();
    }

    if (
      selectedMember?.role === 'Full control' &&
      !Object.prototype.hasOwnProperty.call(selectedMember, 'financialAccess')
    ) {
      setFinancialAccess('none');
    } else {
      setFinancialAccess(selectedMember?.financialAccess);
    }
  }, [selectedMember, getSingleMember]);

  useEffect(() => {
    reloadData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const [selected, setSelected] = useState(0);
  const getPlanFixedCost = value => {
    const fixedCost =
      /* eslint-disable camelcase */
      Number(value?.fixedCost?.equipment_PerAc || 0) +
      Number(value?.fixedCost?.insurance_PerAc || 0) +
      Number(value?.fixedCost?.labor_PerAc || 0) +
      Number(value?.fixedCost?.landRent_PerAc || 0) +
      Number(value?.fixedCost?.repairs_PerAc || 0) +
      Number(value?.fixedCost?.returnToMgt_PerAc || 0) +
      Number(value?.fixedCost?.taxes_PerAc || 0);
    return fixedCost * value?.area;
  };
  const getPlanTotProjRevenue = value => {
    const YPA = Number(value?.projectedRevenue?.yieldTotal) || 0;
    const FSAP = Number(value?.projectedRevenue?.fsaPayment) || 0;
    const CP = Number(value?.projectedRevenue?.cropSharePercent) / 100 || 1;
    const result = (YPA + FSAP) * CP;
    return result;
  };

  const getVarCost = value => {
    const varMath = value.planProductEntries?.reduce(
      (prev, curr) => prev + curr.totalProductCost,
      0
    );
    return varMath;
  };

  const getPlanProjNetRevenue = value => {
    const tpjRevenue = getPlanTotProjRevenue(value) || 0;
    const fixCost = getPlanFixedCost(value) || 0;
    const varCost = getVarCost(value) || 0;
    const result = tpjRevenue - fixCost - varCost;
    return result;
  };

  const getPlanProjNetRevenuePerArea = value => {
    const tpjRevenue = getPlanTotProjRevenue(value) || 0;
    const fixCost = getPlanFixedCost(value) || 0;
    const varCost = getVarCost(value) || 0;
    const result = (tpjRevenue - fixCost - varCost) / value?.estimatedAreaValue;
    return result;
  };

  const onValidationError = errors => {
    const firstError = Object.keys(errors).shift();
    const tab = fieldToTabMap[firstError];
    setSelected(tab);
  };

  return (
    <div data-testid="plan-form" className="h-full flex-1 -mx-4">
      {selectedMember && financialAccess ? (
        <Formik
          onSubmit={onSubmit}
          initialValues={plan}
          validationSchema={schema}
          enableReinitialize
        >
          {({
            errors,
            values,
            handleChange,
            handleBlur,
            submitForm,
            setFieldValue
          }) => {
            return (
              <PlanContextWrapper>
                <FormNavGuard
                  data-testid="nav-guard"
                  onValidationError={onValidationError}
                />
                <TopHeader
                  isSaving={isSaving}
                  onSubmit={submitForm}
                  fixedCost={getPlanFixedCost(values)}
                  variableCost={getVarCost(values)}
                  totalProjectedNetRevenue={getPlanProjNetRevenue(values)}
                  perAcreProjectedNetRevenue={getPlanProjNetRevenuePerArea(
                    values
                  )}
                  selectedview={selectedview}
                  values={values}
                  memberRole={selectedMember?.role}
                  memberFinancialAccess={financialAccess}
                />
                <div
                  className="px-6 pt-4 -mx-4 bg-white"
                  style={{ height: 'calc(100% - 100px)' }}
                >
                  <PlanTabContents>
                    {/* the fields tab contains nav buttons that must not be disabled  */}
                    <fieldset data-testid="plan-fieldset" disabled={readOnly}>
                      <Tabs activeTab={selected}>
                        <Tabs.Tab label="Details">
                          <PlanDetailsTab
                            values={values}
                            errors={errors}
                            handleChange={handleChange}
                            handleBlur={handleBlur}
                            setFieldValue={setFieldValue}
                            memberRole={selectedMember?.role}
                            memberFinancialAccess={financialAccess}
                          />
                        </Tabs.Tab>
                        <Tabs.Tab label="Properties">
                          <PlanPropertiesTab
                            errors={errors}
                            handleChange={handleChange}
                            handleBlur={handleBlur}
                            propertyLandingPageData={propertiesSortedData}
                            loading={propertiesLoading}
                            fieldsAndAreasGeoJSONCollection={
                              fieldsAndAreasGeoJSONCollection
                            }
                            selectedCropSeasonIds={selectedCropSeasonIds}
                          />
                        </Tabs.Tab>
                        <Tabs.Tab label="Products">
                          <PlanProductsTab
                            plan={plan}
                            values={values}
                            errors={errors}
                            handleChange={handleChange}
                            handleBlur={handleBlur}
                            setFieldValue={setFieldValue}
                            memberRole={selectedMember?.role}
                            memberFinancialAccess={financialAccess}
                          />
                        </Tabs.Tab>
                      </Tabs>
                    </fieldset>
                  </PlanTabContents>
                </div>
                {/* </div> */}
              </PlanContextWrapper>
            );
          }}
        </Formik>
      ) : (
        <Spinner />
      )}
    </div>
  );
};

PlanForm.propTypes = {
  onSubmit: PropTypes.func,
  plan: PropTypes.shape(),
  isSaving: PropTypes.bool,
  readOnly: PropTypes.bool,
  selectedview: PropTypes.string,
  selectedCropSeasonIds: PropTypes.arrayOf(PropTypes.string).isRequired,
  reloadData: PropTypes.func.isRequired,
  fieldsAndAreasGeoJSONCollection: PropTypes.shape(),
  propertiesLoading: PropTypes.bool.isRequired,
  propertiesSortedData: PropTypes.shape().isRequired
};

PlanForm.defaultProps = {
  onSubmit: () => {},
  plan: {},
  isSaving: false,
  readOnly: false,
  selectedview: '',
  fieldsAndAreasGeoJSONCollection: {}
};

export default PlanForm;
