import React, { useContext } from 'react';
import { Context } from 'components/Store';
import GrowUIFormField from 'components/Forms/GrowUIFormField';
import { Input } from '@agconnections/grow-ui';
import { useFormikContext } from 'formik';

const FarmNumber = () => {
  const [{ isEnableInfoTabEdit }] = useContext(Context);
  const { values } = useFormikContext();

  return (
    <GrowUIFormField
      value={values.farmNumber}
      placeholder="Enter farm number"
      control={Input}
      type="text"
      name="farmNumber"
      label="Farm Number"
      disabled={!isEnableInfoTabEdit}
    />
  );
};

export default FarmNumber;
