import React from 'react';
import PropTypes from 'prop-types';
import { format } from 'date-fns';
import { Image, StyleSheet, Text, View } from '@react-pdf/renderer';
import { Applicators } from 'components/PDF/components';
import { displayText } from 'components/PDF/helpers/pdfTools';
import { taskDetailHeaderTranslations } from '../../utils/headerTranslations';

const styles = StyleSheet.create({
  taskDetailsContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between'
  },
  taskDetails: {
    width: '50%'
  },
  imageContainer: {
    width: 270,
    height: 198
  },
  title: {
    fontFamily: 'Noto Sans',
    fontWeight: 'medium',
    fontSize: 12,
    marginBottom: 12
  },
  row: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginBottom: 20
  },
  fieldContainer: {
    width: '50%'
  },
  fieldText: {
    fontFamily: 'Noto Sans',
    fontSize: 9
  },
  fieldTitle: {
    color: '#363948',
    lineHeight: 2
  },
  fieldValue: {
    color: '#14151C',
    fontWeight: 'medium'
  }
});

const TaskDetails = ({ task, exportLanguage }) => {
  const formatDisplayDate = date => {
    if (!date) return '-';
    if (exportLanguage === 'english')
      return format(date, 'MM/dd/yyyy h:mm:ss aa');
    return format(date, 'dd/MM/yyyy h:mm:ss aaaa');
  };
  return (
    <View style={styles.taskDetailsContainer}>
      <View style={styles.taskDetails}>
        <View>
          <Text style={styles.title}>
            {taskDetailHeaderTranslations.taskDetails[exportLanguage]}
          </Text>
          <View style={styles.row}>
            <View style={styles.fieldContainer}>
              <Text style={[styles.fieldText, styles.fieldTitle]}>
                {taskDetailHeaderTranslations.startTime[exportLanguage]}
              </Text>
              <Text style={[styles.fieldText, styles.fieldValue]}>
                {formatDisplayDate(task.startDateEpoch)}
              </Text>
            </View>
            <View style={styles.fieldContainer}>
              <Text style={[styles.fieldText, styles.fieldTitle]}>
                {taskDetailHeaderTranslations.finishTime[exportLanguage]}
              </Text>
              <Text style={[styles.fieldText, styles.fieldValue]}>
                {formatDisplayDate(task.dueDateEpoch)}
              </Text>
            </View>
          </View>
          <View style={styles.row}>
            <View style={styles.fieldContainer}>
              <Text style={[styles.fieldText, styles.fieldTitle]}>
                {taskDetailHeaderTranslations.authorizedBy[exportLanguage]}
              </Text>
              <Text style={[styles.fieldText, styles.fieldValue]}>
                {displayText(task.authorizedBy)}
              </Text>
            </View>
            <View style={styles.fieldContainer}>
              <Text style={[styles.fieldText, styles.fieldTitle]}>
                {taskDetailHeaderTranslations.authorizedOn[exportLanguage]}
              </Text>
              <Text style={[styles.fieldText, styles.fieldValue]}>
                {formatDisplayDate(task.authorizedDateEpoch)}
              </Text>
            </View>
          </View>
          <View style={styles.row}>
            <View style={styles.fieldContainer}>
              <Text style={[styles.fieldText, styles.fieldTitle]}>
                {taskDetailHeaderTranslations.timingEvent[exportLanguage]}
              </Text>
              <Text style={[styles.fieldText, styles.fieldValue]}>
                {displayText(task.timingEvent)}
              </Text>
            </View>
          </View>
        </View>

        <Applicators applicators={task.applicators} />
      </View>
      <View style={styles.imageContainer}>
        {task.mapImage && <Image src={task.mapImage} />}
      </View>
    </View>
  );
};

TaskDetails.propTypes = {
  task: PropTypes.shape().isRequired,
  exportLanguage: PropTypes.string.isRequired
};

export default TaskDetails;
