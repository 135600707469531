import React, { useState } from 'react';
import PropTypes from 'prop-types';
import PortalModal from 'components/Modals/PortalModal';
import { withRouter } from 'react-router-dom';
import catchCancel from 'helpers/catchCancel';
import { cropZone as cropzoneApi } from 'utilities/api';
import usePlansData from 'screens/Plans/hooks/usePlansData';
import useRecommendations from 'hooks/useRecommendations';
import useTasks from 'hooks/useTasks';
import { useToast } from 'components/ToastContainer';

const DeleteCropzoneModal = ({
  open,
  close,
  cropzoneName,
  cropzoneId,
  setReRenderProperties,
  onDelete
}) => {
  const { plans } = usePlansData();
  const { recommendations } = useRecommendations();
  const { tasks } = useTasks();
  const toast = useToast();
  const [isSaving, setIsSaving] = useState(false);

  const getUserErrorMessage = message => {
    toast.error('Unable to delete Crop Zone.', {
      content: message
    });
    close();
  };

  const hasPlans = () =>
    plans?.some(plan =>
      plan.cropZones?.some(cropZone => cropzoneId === cropZone.cropZoneIdValue)
    );

  const hasRecommendations = () =>
    recommendations
      ?.flatMap(rec => rec.cropZones)
      .some(cropZone => cropZone.cropZoneIdValue === cropzoneId);

  const hasTasks = () =>
    tasks
      ?.flatMap(task => task.properties)
      .some(property => property?.cropZoneId === cropzoneId);

  const handleDelete = async () => {
    const errors = [];

    if (hasPlans()) {
      errors.push('a plan');
    }

    if (hasTasks()) {
      errors.push('a task');
    }

    if (hasRecommendations()) {
      errors.push('a recommendation');
    }

    if (errors.length > 0) {
      const errorMessage = errors.join(', ').replace(/, (a \w+)$/, ' and $1');
      getUserErrorMessage(
        `This Crop Zone cannot be deleted as it belongs to ${errorMessage}`
      );
      return;
    }

    setIsSaving(true);
    const { promise } = cropzoneApi.delete(cropzoneId);
    await promise
      .then(() => {
        setReRenderProperties(true);
        onDelete();
      })
      .catch(catchCancel)
      .catch(err => {
        setIsSaving(false);
        getUserErrorMessage(err.response.data.message);
      });
  };

  return (
    <>
      <PortalModal
        data-testid="delete-cropzone-modal"
        open={open}
        title={cropzoneName && 'Delete this Crop Zone?'}
        close={close}
        cancelLabel="Cancel"
        confirmLabel="Delete"
        onCancel={close}
        type="error"
        onConfirm={handleDelete}
        isSaving={isSaving}
      >
        <div className="pr-6">
          Are you sure you want to delete the Crop Zone {cropzoneName}?
        </div>
      </PortalModal>
    </>
  );
};

DeleteCropzoneModal.defaultProps = {
  setReRenderProperties: () => {},
  onDelete: () => {}
};

DeleteCropzoneModal.propTypes = {
  open: PropTypes.func.isRequired,
  close: PropTypes.func.isRequired,
  cropzoneName: PropTypes.string.isRequired,
  cropzoneId: PropTypes.string.isRequired,
  setReRenderProperties: PropTypes.func,
  onDelete: PropTypes.func
};

export default withRouter(DeleteCropzoneModal);
