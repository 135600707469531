import React, { useContext, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Select } from '@agconnections/grow-ui';
import { CalendarToday } from '@material-ui/icons';
import './index.css';
import { PropertiesMatchContext } from '../../context/PropertiesMatchProvider';
import WarningIcon from '../../../../../components/Icons/components/WarningIcon';

const currentYear = new Date().getFullYear();
const priorYear = currentYear - 1;

const CropYearSelector = ({ integrationName }) => {
  const {
    fetchVendorProperties,
    updateSelectedCropYear,
    updateMatches,
    state: { selectedCropYear }
  } = useContext(PropertiesMatchContext);

  const onChangeCropYear = e => {
    const { key } = JSON.parse(e.target.value);
    updateMatches([]);
    updateSelectedCropYear(key);
  };

  useEffect(() => {
    fetchVendorProperties();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedCropYear]);

  return (
    <div className="SquareDropdown grid grid-flow-row auto-rows-max">
      <div className="justify-self-start">
        <CalendarToday className="calendarCropYear" />
        <span className="font-bold"> Select Crop Year</span>
      </div>
      <span className="font-thin mt-1 -mb-3">{integrationName} Crop Year</span>
      <div className="w-full -mb-2">
        <Select
          onChange={onChangeCropYear}
          value={selectedCropYear}
          items={[
            { key: currentYear, value: currentYear },
            { key: priorYear, value: priorYear }
          ]}
        />
      </div>
      <div className="justify-self-start w-full warningBox grid grid-cols-5">
        <div className="col-span-1">
          <WarningIcon />
        </div>
        <div className="col-span-4">
          <span className="text-xs">
            Changing Crop Year will reset all matched crop zones
          </span>
        </div>
      </div>
    </div>
  );
};

CropYearSelector.defaultProps = {
  integrationName: ''
};

CropYearSelector.propTypes = {
  integrationName: PropTypes.string
};

export default CropYearSelector;
