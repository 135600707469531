import { createRouteHandlers } from './helpers/apiResourceBuilder';

export default server => {
  createRouteHandlers(server, 'crop', { orgRoutes: false });

  // eslint-disable-next-line no-unused-vars
  server.get('crops', (schema, request) => {
    const correctAttributesCrops = [];
    const crops = schema.crops.all();

    for (let i = 0; i < crops.models.length; i += 1) {
      const currentCrop = crops.models[i].attrs;
      currentCrop.Id = currentCrop.id;
      delete currentCrop.id;
      currentCrop.Name = currentCrop.name;
      delete currentCrop.name;

      correctAttributesCrops.push(currentCrop);
    }

    const returnObject = {
      Crops: correctAttributesCrops
    };

    return returnObject;
  });

  server.get('crops/:id', (schema, request) => {
    const { id } = request.params;

    const crop = schema.crops.find(id);

    const currentCrop = crop.attrs;
    currentCrop.Id = currentCrop.id;
    delete currentCrop.id;
    currentCrop.Name = currentCrop.name;
    delete currentCrop.name;

    const returnObject = {
      Crop: currentCrop
    };

    return returnObject;
  });
};
