import React, { memo } from 'react';
import PropTypes from 'prop-types';

const CheckmarkIcon = ({ size = 18, color = '#696F88' }) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      data-testid="checkmark-icon"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.59631 11.9077L3.46881 8.78016L2.40381 9.83766L6.59631 14.0302L15.5963 5.03016L14.5388 3.97266L6.59631 11.9077Z"
        fill={color}
      />
    </svg>
  );
};

CheckmarkIcon.propTypes = {
  color: PropTypes.string,
  size: PropTypes.number
};

CheckmarkIcon.defaultProps = {
  color: '#696F88',
  size: 18
};

export default memo(CheckmarkIcon);
