import React from 'react';
import PropTypes from 'prop-types';

import SquareButton from '../../../../SquareButton';
import { MAX_ZOOM, MIN_ZOOM } from '../../../../../../../helpers/constants';

const ZoomInIcon = () => (
  <svg
    width="10"
    height="10"
    viewBox="0 0 10 10"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M9.66659 5.66659H5.66659V9.66659H4.33325V5.66659H0.333252V4.33325H4.33325V0.333252H5.66659V4.33325H9.66659V5.66659Z"
      fill="#696F88"
    />
  </svg>
);

const ZoomOutIcon = () => (
  <svg
    width="10"
    height="2"
    viewBox="0 0 10 2"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M9.66659 1.66659H0.333252V0.333252H9.66659V1.66659Z"
      fill="#696F88"
    />
  </svg>
);

const ZoomButtons = ({ setZoom }) => {
  const handleZoomIn = () =>
    setZoom(([currentZoom]) => [
      currentZoom + 1 >= MAX_ZOOM ? MAX_ZOOM : currentZoom + 1
    ]);

  const handleZoomOut = () =>
    setZoom(([currentZoom]) => [
      currentZoom - 1 <= MIN_ZOOM ? MIN_ZOOM : currentZoom - 1
    ]);

  return (
    <div data-testid="zoom-buttons">
      <SquareButton className="m-5 mb-0" onClick={handleZoomIn}>
        <ZoomInIcon />
      </SquareButton>
      <SquareButton className="m-5 mt-0" onClick={handleZoomOut}>
        <ZoomOutIcon />
      </SquareButton>
    </div>
  );
};

ZoomButtons.defaultProps = { setZoom: () => {} };

ZoomButtons.propTypes = { setZoom: PropTypes.func };

export default ZoomButtons;
