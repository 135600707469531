import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { Drawer, Table, Button } from 'syngenta-digital-cropwise-react-ui-kit';
import { Spinner } from '@agconnections/grow-ui';
import './index.css';
import convertDateString from 'screens/ProductList/ProductDetails/components/ProductDetailTasksTable/helpers/dateFormat';
import { Link } from 'react-router-dom';
import { paths } from 'routes/paths';
import ViewIcon from 'assets/open_in_new.svg';
import formatNumber from 'helpers/formatNumber';
import { getAbbreviatedUnit } from 'helpers/unitsAbbreviation';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronLeft } from '@fortawesome/free-solid-svg-icons';

const FailedSubmissionDrawer = ({
  submissionsStatus,
  openDrawer,
  onCloseDrawer,
  listPriceUnit,
  loadingRetrySubmission,
  retryAction
}) => {
  const getColSpan = record => (record.requestDateTime ? 0 : 1);
  const getUniqueSubmissionIds = submissions => {
    return Array.from(
      new Set(submissions.map(submission => submission.submissionId))
    );
  };

  const getUniqueTaskIds = submissions => {
    return Array.from(
      new Set(
        submissions.flatMap(submission => submission.tasks.map(task => task.id))
      )
    );
  };
  const columns = [
    {
      key: 'recordInfo',
      dataIndex: 'recordInfo',
      title: 'Record Info',
      width: 250,
      textWrap: 'ellipsis',
      ellipsis: true,
      onCell: record => ({
        colSpan: record.requestDateTime ? 3 : 1
      }),
      render: (recordInfo, row) => {
        if (row?.requestDateTime) {
          return (
            <div className="failed-submission-drawer-table-red-row text-neutral-00 text-base font-semibold">
              Update Request: {convertDateString(row.requestDateTime)}
            </div>
          );
        }
        return (
          <div>
            <p className="truncate">{recordInfo.name}</p>
            <div className="text-neutral-60 text-xs">
              {convertDateString(recordInfo.creationDateTime)}
            </div>
          </div>
        );
      }
    },
    {
      key: 'currentUnitPrice',
      dataIndex: 'currentUnitPrice',
      title: 'Current Unit Price',
      textWrap: 'ellipsis',
      onCell: record => ({
        colSpan: getColSpan(record)
      }),
      render: currentUnitPrice => {
        return (
          <div>
            ${formatNumber(currentUnitPrice)} /{' '}
            {getAbbreviatedUnit(listPriceUnit)}
          </div>
        );
      }
    },
    {
      key: 'link',
      dataIndex: 'link',
      title: '',
      width: 128,
      onCell: record => ({
        colSpan: getColSpan(record)
      }),
      render: (_, record) => {
        if (!record.id) return null;
        return (
          <div className="w-full flex flex-row">
            <Link
              to={`${paths.tasks}/${record.id}/view`}
              className="flex flex-row"
            >
              <img className="mr-1" src={ViewIcon} alt="view icon" />
              <span>View</span>
            </Link>
          </div>
        );
      }
    }
  ];

  const dataSource = useMemo(() => {
    return submissionsStatus.failedSubmissions.flatMap(submission => {
      const expandableRow = {
        key: submission.submissionId,
        requestDateTime: submission.requestDateTime
      };

      return [
        expandableRow,
        ...submission.tasks.map(task => ({
          key: crypto.randomUUID(),
          recordInfo: {
            name: `${task.name}`,
            creationDateTime: task.creationDateTime
          },
          id: task.id,
          currentUnitPrice: task.unitPrice
        }))
      ];
    });
  }, [submissionsStatus.failedSubmissions]);

  return (
    <Drawer
      className="failed-submission-drawer"
      title={
        !loadingRetrySubmission &&
        `Errors with Updating to List Price (${
          dataSource.filter(data => data.id).length
        })`
      }
      placement="right"
      onClose={onCloseDrawer}
      open={openDrawer}
      footer={
        !loadingRetrySubmission && (
          <div className="failed-submission-drawer-footer flex">
            <div className="flex items-center gap-6 flex-grow">
              <Button
                onClick={onCloseDrawer}
                icon={
                  <FontAwesomeIcon
                    data-testid="chevron-left-icon"
                    icon={faChevronLeft}
                    size="xs"
                    style={{ marginRight: '4px' }}
                  />
                }
                type="outline"
              >
                Back
              </Button>
            </div>
            <div className="flex justify-end gap-3">
              <Button
                onClick={() => {
                  window.open(
                    'https://www.syngentadigital.com/us-en#support',
                    '_blank',
                    'noopener'
                  );
                }}
                type="outline"
              >
                Contact Support
              </Button>
              <Button
                type="primary"
                onClick={() => {
                  const uniqueSubmissionIds = getUniqueSubmissionIds(
                    submissionsStatus.failedSubmissions
                  );
                  const uniqueTaskIds = getUniqueTaskIds(
                    submissionsStatus.failedSubmissions
                  );
                  retryAction(uniqueSubmissionIds, uniqueTaskIds);
                }}
              >
                Retry Submission
              </Button>
            </div>
          </div>
        )
      }
    >
      {loadingRetrySubmission ? (
        <div
          data-testid="spinner"
          className="h-full w-full flex flex-col justify-center items-center"
        >
          <div>
            <Spinner size="lg" />
          </div>
          <span className="mt-4 text-base font-semibold">
            Re-submitting request...
          </span>
        </div>
      ) : (
        <Table columns={columns} dataSource={dataSource} pagination={false} />
      )}
    </Drawer>
  );
};

FailedSubmissionDrawer.propTypes = {
  submissionsStatus: PropTypes.shape({
    failedSubmissions: PropTypes.arrayOf(
      PropTypes.shape({
        submissionId: PropTypes.string.isRequired,
        requestDateTime: PropTypes.string,
        tasks: PropTypes.arrayOf(
          PropTypes.shape({
            id: PropTypes.string.isRequired,
            name: PropTypes.string.isRequired,
            creationDateTime: PropTypes.string.isRequired,
            unitPrice: PropTypes.number.isRequired
          })
        ).isRequired
      })
    ).isRequired
  }).isRequired,
  openDrawer: PropTypes.bool.isRequired,
  onCloseDrawer: PropTypes.func.isRequired,
  listPriceUnit: PropTypes.string.isRequired,
  loadingRetrySubmission: PropTypes.bool.isRequired,
  retryAction: PropTypes.func.isRequired
};
export default FailedSubmissionDrawer;
