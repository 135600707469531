import React from 'react';
import PropTypes from 'prop-types';

const ButtonPopupMessage = ({ message, hotkey }) => (
  <div className="flex flex-no-wrap">
    <div className="text-gray-700">{message}</div>
    &nbsp;
    <div className="text-gray-300">({hotkey})</div>
  </div>
);

ButtonPopupMessage.propTypes = {
  message: PropTypes.string.isRequired,
  hotkey: PropTypes.string.isRequired
};

export default ButtonPopupMessage;
