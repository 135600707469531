/* eslint-disable import/prefer-default-export */
export const formatProductManufacturerName = (product = {}) => {
  const labels = [];

  if (product.custom !== false) {
    labels.push('Custom');
  }

  if (product.manufacturerName || product.manufacturer) {
    labels.push(product.manufacturerName || product.manufacturer);
  }

  return labels.join(' • ');
};
