import React, { useContext } from 'react';
import { Formik } from 'formik';
import ProgressBar from 'components/ProgressBar';
import { IntegrationsContext } from 'screens/Integrations/context/IntegrationsProvider';
import { INTEGRATIONS } from 'screens/Integrations/helpers/constants';

import Breadcrumb from 'components/App/AppShell/components/Breadcrumb';
import { mccain } from '../../../helpers/progressBarSteps';
import StepSelector from '../../../components/StepSelector';

const blankValues = {
  selectedProperties: [],
  submitDate: new Date(),
  selectedFarm: 0,
  selectedCropSeasons: [{}],
  mccainVendorId: '',
  cropSeasonStartDate: '',
  cropSeasonEndDate: ''
};

const BaseMccainIntegration = () => {
  const handleOrgChange = () => {
    return null;
  };
  const {
    state: { step },
    goBack: gotoStep
  } = useContext(IntegrationsContext);

  const canGoNext = (values, currentStep) => {
    switch (currentStep) {
      case 1:
        return values.cropSeason && values.startDate && values.endDate;
      default:
        return false;
    }
  };

  return (
    <Formik initialValues={blankValues}>
      {({ values }) => (
        <div>
          <Breadcrumb
            onOrganizationSelect={handleOrgChange}
            hideCropSeasonDropdown
            onCropSeasonSelect={handleOrgChange}
            disabled
          >
            <Breadcrumb.Item title="Integrations" value="McCain" isLast />
          </Breadcrumb>
          <div
            className="z-50 min-h-50 h-full left-0 right-0 bottom-0 top-18"
            data-testid="base-maccain-integration"
          >
            {step > 0 && step < 4 && (
              <ProgressBar
                stepArray={mccain}
                currentStep={step}
                canGoNext={() => canGoNext(values, step)}
                gotoStep={gotoStep}
              />
            )}
            <StepSelector integration={INTEGRATIONS.mccain} />
          </div>
        </div>
      )}
    </Formik>
  );
};

export default BaseMccainIntegration;
