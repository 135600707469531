import React from 'react';

const PrintableAreaBoundary = () => {
  return (
    <div
      data-testid="printable-area-boundary"
      className="absolute left-2 right-2 z-1 m-auto pointer-events-none border-4 border-banner-alert-blue-30"
      style={{
        top: 'calc(50%)',
        width: 'calc(100% - 16px)',
        maxWidth: 'calc((100vh - 132px) * 647 / 420)',
        aspectRatio: '647 / 420',
        transform: 'translateY(-50%)'
      }}
    >
      <div
        className="absolute py-1 px-2 h-7 leading-5 flex items-center justify-center"
        style={{
          top: '-28px',
          left: '-4px',
          backgroundColor: '#82CFFF',
          borderRadius: '4px 4px 0 0',
          fontSize: '12px'
        }}
      >
        Printable area
      </div>
    </div>
  );
};

export default PrintableAreaBoundary;
