import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { Button, ButtonType } from 'syngenta-digital-cropwise-react-ui-kit';
import { Row, Col } from 'antd';

import { ReactComponent as FieldIcon } from 'assets/field_blue_20px.svg';
import { ReactComponent as ChevronRightIcon } from 'assets/chevron_right_green_18px.svg';
import { Context } from 'components/Store';
import useMassAssigner from 'screens/Property/hooks/useMassAssigner';
import formatNumber from 'helpers/formatNumber';
import useExportPDFMapsActions from 'screens/Property/hooks/useExportPDFMapsActions';
import { AmplitudeContext } from 'utilities/amplitude/useAmplitude';

const ExportPDFMapsFooter = ({ step, selectedFields }) => {
  const amplitude = useContext(AmplitudeContext);
  const [
    {
      massiveChangeSummary,
      fieldsToMassiveAssign,
      fetchProperties,
      isMapMoving
    },
    dispatch
  ] = useContext(Context);
  const {
    clearExportForm,
    closeExportFlow,
    goToExportStep,
    initiateExportFlow
  } = useExportPDFMapsActions(dispatch);
  const { getTotalCropZones, getTotalCropZoneArea } = useMassAssigner(
    massiveChangeSummary,
    fieldsToMassiveAssign,
    fetchProperties,
    dispatch
  );

  const handleNext = () => {
    goToExportStep(1);
  };

  const handleCreateAnother = () => {
    amplitude.sendEventToAmplitude(
      amplitude.events.epic.Properties.createAnotherPropertyMap
    );
    initiateExportFlow();
  };

  const handleClose = () => {
    clearExportForm();
    closeExportFlow();
  };

  const isNextButtonDisabled = isMapMoving || !selectedFields.length;
  const totalCropZones = getTotalCropZones();

  if (step === 0)
    return (
      <div className="sticky bottom-0 w-full bg-white border-t-2 p-4 pt-10px items-center z-4">
        <Row gutter={16} className="text-blue-60">
          <Col>
            {totalCropZones} crop zone{totalCropZones !== 1 ? 's' : ''} selected
          </Col>
          <Col>
            <Row>
              <FieldIcon className="mr-1" />
              <span>{formatNumber(getTotalCropZoneArea())} total ac</span>
            </Row>
          </Col>
        </Row>
        <Row justify="center" className="mt-4" gutter={8}>
          <Button
            name="next"
            type={ButtonType.success}
            className={isNextButtonDisabled ? 'border-neutral-20' : ''}
            ghost
            disabled={isNextButtonDisabled}
            style={{ width: '100%' }}
            onClick={handleNext}
          >
            <Row className="w-full" justify="center">
              <span>Next</span>
              <ChevronRightIcon
                className={`mt-2px ml-1 ${
                  isNextButtonDisabled ? 'chevron-disabled' : ''
                }`}
              />
            </Row>
          </Button>
        </Row>
      </div>
    );

  return (
    <div className="sticky bottom-0 w-full bg-white border-t-2 p-4 items-center z-4">
      <Row justify="space-between">
        <Col>
          <Button
            name="close"
            type={ButtonType.outline}
            className="w-80px justify-center"
            onClick={handleClose}
          >
            Close
          </Button>
        </Col>
        <Col>
          <Button
            name="create-another-pdf-map"
            type={ButtonType.primary}
            className="w-218px justify-center"
            onClick={handleCreateAnother}
          >
            Create another PDF map
          </Button>
        </Col>
      </Row>
    </div>
  );
};

ExportPDFMapsFooter.propTypes = {
  step: PropTypes.number.isRequired,
  selectedFields: PropTypes.arrayOf(PropTypes.object).isRequired
};

export default ExportPDFMapsFooter;
