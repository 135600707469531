/* eslint-disable react/no-array-index-key */
import React from 'react';
import PropTypes from 'prop-types';
import { StyleSheet, Text, View } from '@react-pdf/renderer';

const styles = StyleSheet.create({
  container: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    width: '100%',
    padding: '6px 9px'
  },
  text: {
    fontFamily: 'Noto Sans',
    fontSize: 9
  }
});

const TableRow = ({ items, showBorder }) => {
  return (
    <View
      style={[
        styles.container,
        {
          borderBottom: showBorder ? '1px solid #DFE2E7' : 'none'
        }
      ]}
    >
      {items.map((item, idx) => {
        const commonStyles = {
          width: '100%'
        };

        return (
          <View style={{ width: item.styles.width }} wrap={false}>
            <Text
              style={[styles.text, item.styles, commonStyles]}
              key={`${item.label}-${idx}`}
            >
              {item.label}
            </Text>
            {item.subtexts.map(subtext => {
              return (
                <Text style={[subtext.styles, commonStyles]}>
                  {subtext.label}
                </Text>
              );
            })}
          </View>
        );
      })}
    </View>
  );
};

TableRow.propTypes = {
  items: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      styles: PropTypes.shape(),
      subtexts: PropTypes.arrayOf(
        PropTypes.shape({
          label: PropTypes.string.isRequired,
          styles: PropTypes.shape()
        })
      )
    })
  ).isRequired,
  showBorder: PropTypes.bool
};

TableRow.defaultProps = {
  showBorder: false
};

export default TableRow;
