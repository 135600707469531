import React from 'react';
import PropTypes from 'prop-types';
import ViewOnlyField from 'components/ViewOnlyField';
import FormattedNumber from 'components/FormattedNumber';
import { getYieldTotal, getYieldTotalPerAc } from '../../helpers';

const ProjectedRevenueSection = ({ plan, displayFinancials }) => {
  const getPerAcreMath = (unitValue, areaValue) => {
    const result = areaValue / unitValue;
    if (result > 0 && result !== Infinity) {
      return (
        <>
          <FormattedNumber unit="usCurrency" roundedTo={2}>
            {result}
          </FormattedNumber>
        </>
      );
    }
    return 0;
  };
  return (
    <>
      <div className="flex py-5">
        <div className="w-1/3">
          <ViewOnlyField
            header="Yield Total"
            className="w-1/3"
            text="text-lg"
          />
          <span className="text-2xl w-1/3">
            {displayFinancials && (
              <FormattedNumber unit="usCurrency" roundedTo={2}>
                {getYieldTotal(plan)}
              </FormattedNumber>
            )}
          </span>
          <br />
          {displayFinancials && (
            <span className="gr-text-sm">
              <FormattedNumber unit="usCurrency" roundedTo={2}>
                {getYieldTotalPerAc(plan)}
              </FormattedNumber>
              /{plan?.estimatedAreaUnit}
            </span>
          )}
        </div>
        <div className="w-1/3">
          <ViewOnlyField
            header="Price per Unit"
            className="w-1/2"
            text="text-lg"
          />
          <span className="text-2xl w-1/3">
            {displayFinancials && (
              <FormattedNumber unit="usCurrency" roundedTo={2}>
                {plan?.projectedRevenue?.pricePerUnit}
              </FormattedNumber>
            )}
          </span>
          <br />
        </div>
      </div>
      <div className="flex py-5">
        <div className="w-1/3">
          <ViewOnlyField
            header="FSA Payment"
            className="w-1/3"
            text="text-lg"
          />
          <span className="text-2xl w-1/3">
            {displayFinancials && (
              <FormattedNumber unit="usCurrency" roundedTo={2}>
                {plan?.projectedRevenue?.fsaPayment}
              </FormattedNumber>
            )}
          </span>
          <br />
          {displayFinancials && (
            <span className="gr-text-sm">
              {getPerAcreMath(
                plan?.estimatedAreaValue,
                plan?.projectedRevenue?.fsaPayment
              )}
              /{plan?.estimatedAreaUnit}
            </span>
          )}
        </div>
        <div className="w-1/3">
          <ViewOnlyField header="Crop Share" className="w-1/2" text="text-lg" />
          {displayFinancials && (
            <span className="text-2xl w-1/3">
              {plan?.projectedRevenue?.cropSharePercent * 100}%
            </span>
          )}
          <br />
          {/* <span className="gr-text-sm">
              {(
                (plan?.projectedRevenue?.cropSharePercent /
                  plan?.estimatedAreaValue) *
                100
              ).toFixed(2)}
              /{plan?.estimatedAreaUnit}
            </span> */}
        </div>
      </div>
    </>
  );
};

ProjectedRevenueSection.defaultProps = {
  displayFinancials: true
};

ProjectedRevenueSection.propTypes = {
  displayFinancials: PropTypes.bool,
  plan: PropTypes.shape({
    projectedRevenue: PropTypes.shape({
      cropSharePercent: PropTypes.number,
      fsaPayment: PropTypes.number,
      pricePerUnit: PropTypes.number,
      yieldPerArea: PropTypes.number
    }).isRequired,
    estimatedAreaValue: PropTypes.number.isRequired,
    estimatedAreaUnit: PropTypes.string.isRequired
  }).isRequired
};

export default ProjectedRevenueSection;
