/* eslint no-use-before-define: ["error", { "functions": false }] */
import _ from 'lodash';
import orderBy from 'lodash/fp/orderBy';
import { getSeasons, getSubtitle } from 'screens/Recommendations/helpers/list';
import { recommendations as recommendationsApi } from './api';

let currentOrg = null;
let rawRecommendations = [];

export const resetOrg = () => {
  currentOrg = null;
};

const recommendationsFakeApi = async (
  { page, size, cropSeasonIds, dateRange, titleQuery, sortBy, sortDir },
  organization
) => {
  if (currentOrg !== organization.id) {
    const { promise } = recommendationsApi.fetch();

    rawRecommendations = await promise.then(({ data }) => {
      return data.results;
    });
    currentOrg = organization.id;
  }

  let filteredRecommendations = [...rawRecommendations];

  // Filter by CropSeasons
  filteredRecommendations = filteredRecommendations.filter(
    ({ cropSeasonIds: ids }) => _.intersection(cropSeasonIds, ids).length > 0
  );

  // Filter by Date range
  filteredRecommendations = dateRangeFilter(filteredRecommendations, dateRange);

  // Filter by Title
  filteredRecommendations = findRecommendation(
    filteredRecommendations,
    titleQuery
  );

  // Sort
  filteredRecommendations = sort(filteredRecommendations, sortBy, sortDir);

  return {
    promise: new Promise(resolve => {
      resolve({
        data: {
          results: filteredRecommendations.slice(
            page * size,
            (page + 1) * size
          ),
          totalElements: filteredRecommendations.length
        }
      });
    })
  };
};

export default recommendationsFakeApi;

function dateRangeFilter(recommendations, filteredDate) {
  if (filteredDate.length !== 2) {
    return recommendations;
  }

  const startTime = filteredDate[0].getTime();
  const endTime = filteredDate[1].getTime();

  return recommendations.filter(ele => {
    const proposedTime = new Date(ele.proposedDate).getTime();
    const expirationTime = new Date(ele.expirationDate).getTime();
    return proposedTime <= endTime && expirationTime >= startTime;
  });
}

function findRecommendation(recommendations, query) {
  if (!query) {
    return recommendations;
  }
  const words = query.toLowerCase().match(/[^ ]+/g);
  return recommendations.filter(item => {
    const lTitle = item.title.toLowerCase();
    return words.reduce(
      (isFound, word) => isFound && lTitle.includes(word),
      true
    );
  });
}

const COLUMN_SORT_FUNCTIONS = {
  name: (dir, recs) => orderBy([r => r.title], dir, recs),
  fields: (dir, recs) => orderBy([r => r.cropZones.length], dir, recs),
  totalAcres: (dir, recs) =>
    orderBy(
      [r => r.cropZones.reduce((acc, z) => acc + z.areaValue, 0)],
      dir,
      recs
    ),
  products: (dir, recs) =>
    orderBy([r => r.recommendationProductEntries.length], dir, recs),
  validFrom: (dir, recs) => orderBy([r => getSubtitle(r)], dir, recs),
  seasons: (dir, recs, cropSeasons) =>
    orderBy(
      [
        r =>
          getSeasons({
            seasons: cropSeasons,
            seasonIds: r.cropSeasonIds
          })
      ],
      dir,
      recs
    )
};

function sort(recommendations, sortBy, sortDir) {
  const sortFunction = COLUMN_SORT_FUNCTIONS[sortBy];

  if (!sortFunction || !sortDir) {
    return recommendations;
  }

  return sortFunction(sortDir, recommendations);
}
