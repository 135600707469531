import React, { useContext, useEffect } from 'react';
import { Formik } from 'formik';
import ProgressBar from 'components/ProgressBar';
import { Spinner } from '@agconnections/grow-ui';
import { YieldContext } from 'screens/Yield/context/YieldProvider';
import { Context } from 'components/Store';
import Breadcrumb from 'components/App/AppShell/components/Breadcrumb';
import { FETCH_TYPES } from 'helpers/constants';
import usePopulateMapData from 'hooks/usePopulateMapData';
import yieldProgressBarSteps from 'screens/Yield/helpers/yieldProgressBarSteps';
import Toast from 'components/Toast';
import StepSelector from '../components/StepSelector';
import canGoNext from '../helpers/validateNextStep';
import blankValues from '../helpers/blankValues';
import getSeasons from '../helpers/getSeasons';

const SCREEN_WIDTH = window.screen.width;
const REFERENCE_WIDTH = 1792;
const REFERENCE_WIDTH_VALUE = 48;

const CreateYield = () => {
  const selectedCropSeasons = getSeasons();

  const {
    loading,
    zoom,
    setZoom,
    dataSorted: propertyLandingPageData,
    fieldsAndAreasGeoJSONCollection,
    setGeoJSONCollectionFeatures,
    fieldsLoading,
    filterFeatureCollectionByParentId,
    reloadData
  } = usePopulateMapData({ fetchType: FETCH_TYPES.cropSeasonsMap });

  const [, dispatch] = useContext(Context);
  const {
    state: { isLoading }
  } = useContext(YieldContext);

  useEffect(() => {
    reloadData({ seasonIds: getSeasons() });
    return () => {
      dispatch({
        type: 'SELECTED_YIELD_COMMODITY',
        payload: {
          id: '',
          name: ''
        }
      });
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleOrgChange = () => {
    reloadData({ seasonIds: getSeasons() });
  };
  const {
    state: { step, showToast, typeToast, messageToast },
    setShowToast,
    goBack: gotoStep
  } = useContext(YieldContext);

  return (
    <Formik initialValues={blankValues}>
      {({ values }) => (
        <div>
          <Breadcrumb
            onOrganizationSelect={handleOrgChange}
            hideCropSeasonDropdown={false}
            onCropSeasonSelect={handleOrgChange}
            disabled
          >
            <Breadcrumb.Item title="Yield" value="Add Yield" isLast />
          </Breadcrumb>
          {loading || isLoading ? (
            <div
              style={{
                position: 'absolute',
                left: '50%',
                top: '50%',
                transform: 'translate(-50%, -50%)'
              }}
            >
              <Spinner />
            </div>
          ) : (
            <div
              className="z-50 h-full bottom-0 top-18"
              data-testid="create-yield-progressbar"
            >
              {step > 0 && step < 4 && (
                <div className="mx-64">
                  <ProgressBar
                    stepArray={yieldProgressBarSteps}
                    currentStep={step}
                    canGoNext={canGoNext(values, step)}
                    className="pb-5 pt-2"
                    gotoStep={gotoStep}
                    style={{
                      margin: '0 auto',
                      width: `${(SCREEN_WIDTH * REFERENCE_WIDTH_VALUE) /
                        REFERENCE_WIDTH}rem`
                    }}
                  />
                </div>
              )}
              <div className="bottom-0 w-full h-full">
                <StepSelector
                  loading={loading}
                  zoom={zoom}
                  setZoom={setZoom}
                  propertyLandingPageData={propertyLandingPageData}
                  fieldsAndAreasGeoJSONCollection={
                    fieldsAndAreasGeoJSONCollection
                  }
                  setGeoJSONCollectionFeatures={setGeoJSONCollectionFeatures}
                  fieldsLoading={fieldsLoading}
                  filterFeatureCollectionByParentId={
                    filterFeatureCollectionByParentId
                  }
                  selectedCropSeasons={selectedCropSeasons}
                />
              </div>
            </div>
          )}
          {showToast && (
            <Toast
              type={typeToast}
              title={messageToast}
              open={showToast}
              timeout={2500}
              onClose={() => setShowToast(false)}
              isBold
            >
              <></>
            </Toast>
          )}
        </div>
      )}
    </Formik>
  );
};

export default CreateYield;
