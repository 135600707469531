import { fieldBodyGenerator } from 'components/PDF/helpers/tableGenerator';
import {
  convertNumberToDesiredFormat,
  formatedNumberToNumber
} from 'helpers/transformHelpers';
import { getAbbreviatedUnit } from 'helpers/unitsAbbreviation';

const PRODUCT_WIDTH = 19;
const TIMING_WIDTH = 9;
const AREA_WIDTH = 9;
const APPLIED_WIDTH = 9;
const RATE_WIDTH = 13;
const APP_CNT_WIDTH = 5;
const TOTAL_WIDTH = 15;
const COST_AREA_WIDTH = 10;
const COST_WIDTH = 11;

export const PRODUCT_HEADER_ITEMS = [
  { label: 'Product', width: PRODUCT_WIDTH },
  { label: 'Timing', width: TIMING_WIDTH },
  { label: 'Area', width: AREA_WIDTH },
  { label: '%Applied', width: APPLIED_WIDTH },
  { label: 'Rate', width: RATE_WIDTH },
  { label: 'App Cnt', width: APP_CNT_WIDTH },
  { label: 'Total Quantity Applied', width: TOTAL_WIDTH },
  { label: 'Cost/Area', width: COST_AREA_WIDTH },
  { label: 'Cost', width: COST_WIDTH, alignment: 'right' }
];

const EQUIPMENT_WIDTH = 16;
const RENT_WIDTH = 13;
const INSURANCE_WIDTH = 16;
const LABOR_WIDTH = 13;
const REPAIRS_WIDTH = 13;
const TAXES_WIDTH = 13;
const MGT_WIDTH = 16;

export const FIXED_COSTS_HEADER_ITEMS = [
  { label: 'Equipment', width: EQUIPMENT_WIDTH },
  { label: 'Rent', width: RENT_WIDTH },
  { label: 'Insurance', width: INSURANCE_WIDTH },
  { label: 'Labor', width: LABOR_WIDTH },
  { label: 'Repairs', width: REPAIRS_WIDTH },
  { label: 'Taxes', width: TAXES_WIDTH },
  { label: 'Return to Mgt', width: MGT_WIDTH, alignment: 'right' }
];

const FIELD_NAMES_WIDTH = 22;
const FIELD_AREA_WIDTH = 12;

export const FIELDS_HEADER_ITEMS = [
  { label: 'Farm', width: FIELD_NAMES_WIDTH },
  { label: 'Field', width: FIELD_NAMES_WIDTH },
  { label: 'Crop Zone', width: FIELD_NAMES_WIDTH },
  { label: 'Crop', width: FIELD_NAMES_WIDTH },
  { label: 'Area', width: FIELD_AREA_WIDTH }
];

export const mapProductsToTable = planProductEntries => {
  const mappedProducts = planProductEntries.map(product => {
    const productName = fieldBodyGenerator(product.productName, {
      width: `${PRODUCT_WIDTH}%`
    });
    const timing = fieldBodyGenerator(product.timingEvent, {
      width: `${TIMING_WIDTH}%`
    });
    const area = fieldBodyGenerator(
      convertNumberToDesiredFormat(product.areaValue, 'fixed'),
      {
        width: `${AREA_WIDTH}%`
      }
    );
    const appliedArea = fieldBodyGenerator(
      convertNumberToDesiredFormat(product.coveragePercent, 'percent'),
      {
        width: `${APPLIED_WIDTH}%`
      }
    );
    const rate = fieldBodyGenerator(
      `${convertNumberToDesiredFormat(
        product.productRateValue,
        'fixed'
      )} ${getAbbreviatedUnit(product.productRateUnit)}`,
      {
        width: `${RATE_WIDTH}%`
      }
    );
    const appCnt = fieldBodyGenerator(product.applicationCount.toString(), {
      width: `${APP_CNT_WIDTH}%`
    });
    const total = fieldBodyGenerator(
      `${convertNumberToDesiredFormat(
        product.totalProductValue,
        'fixed'
      )} ${getAbbreviatedUnit(product.totalProductUnit)}`,
      {
        width: `${TOTAL_WIDTH}%`
      }
    );
    const costArea = fieldBodyGenerator(
      convertNumberToDesiredFormat(
        product.totalProductCost / product.areaValue,
        'currency'
      ),
      {
        width: `${COST_AREA_WIDTH}%`
      }
    );
    const cost = fieldBodyGenerator(
      convertNumberToDesiredFormat(product.totalProductCost, 'currency'),
      {
        width: `${COST_WIDTH}%`,
        textAlign: 'right'
      }
    );

    return [
      productName,
      timing,
      area,
      appliedArea,
      rate,
      appCnt,
      total,
      costArea,
      cost
    ];
  });

  const totalCost = mappedProducts.reduce((prev, curr) => {
    const { 8: cost } = curr;

    return prev + formatedNumberToNumber(cost.label);
  }, 0);

  mappedProducts.push([
    fieldBodyGenerator(
      `Total Cost: ${convertNumberToDesiredFormat(totalCost, 'currency')}`,
      {
        width: '100%',
        fontSize: 11,
        fontWeight: 'medium',
        paddingVertical: 6
      }
    )
  ]);

  return mappedProducts;
};

export const fixedCostsToTable = (plan, totalFixedCost) => [
  [
    fieldBodyGenerator(
      convertNumberToDesiredFormat(plan.fixedCost.equipment, 'currency'),
      {
        width: `${EQUIPMENT_WIDTH}%`
      }
    ),
    fieldBodyGenerator(
      convertNumberToDesiredFormat(plan.fixedCost.landRent, 'currency'),
      {
        width: `${RENT_WIDTH}%`
      }
    ),
    fieldBodyGenerator(
      convertNumberToDesiredFormat(plan.fixedCost.insurance, 'currency'),
      {
        width: `${INSURANCE_WIDTH}%`
      }
    ),
    fieldBodyGenerator(
      convertNumberToDesiredFormat(plan.fixedCost.labor, 'currency'),
      {
        width: `${LABOR_WIDTH}%`
      }
    ),
    fieldBodyGenerator(
      convertNumberToDesiredFormat(plan.fixedCost.repairs, 'currency'),
      {
        width: `${REPAIRS_WIDTH}%`
      }
    ),
    fieldBodyGenerator(
      convertNumberToDesiredFormat(plan.fixedCost.taxes, 'currency'),
      {
        width: `${TAXES_WIDTH}%`
      }
    ),
    fieldBodyGenerator(
      convertNumberToDesiredFormat(plan.fixedCost.returnToMgt, 'currency'),
      {
        width: `${MGT_WIDTH}%`,
        textAlign: 'right'
      }
    )
  ],
  [
    fieldBodyGenerator(
      `Total Fixed Cost: ${convertNumberToDesiredFormat(
        totalFixedCost,
        'currency'
      )}`,
      {
        width: '100%',
        fontSize: 11,
        fontWeight: 'medium',
        paddingVertical: 6
      }
    )
  ]
];

export const fieldsToTable = fields =>
  fields.map(field => {
    const farmName = fieldBodyGenerator(field.farmName, {
      width: `${FIELD_NAMES_WIDTH}%`
    });
    const fieldName = fieldBodyGenerator(field.fieldName, {
      width: `${FIELD_NAMES_WIDTH}%`
    });
    const cropZoneName = fieldBodyGenerator(field.cropZoneName, {
      width: `${FIELD_NAMES_WIDTH}%`
    });
    const cropName = fieldBodyGenerator(field.cropName, {
      width: `${FIELD_NAMES_WIDTH}%`
    });
    const area = fieldBodyGenerator(
      convertNumberToDesiredFormat(field.areaValue, 'fixed'),
      {
        width: `${FIELD_AREA_WIDTH}%`
      }
    );

    return [farmName, fieldName, cropZoneName, cropName, area];
  });
