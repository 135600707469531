import React, { useEffect, useState, useContext, useCallback } from 'react';
import { Redirect, useHistory } from 'react-router-dom';
import { Button, Spinner } from '@agconnections/grow-ui';
import { noop, isEmpty } from 'lodash';
import Breadcrumb from 'components/App/AppShell/components/Breadcrumb';
import { Context } from 'components/Store';
import LandingSearch from 'components/LandingSearch';
import AddRoundedIcon from '@material-ui/icons/AddRounded';

import EmptyState from 'components/EmptyState';
import useLandingPageData from 'hooks/useLandingPageData';
import useDebounce from 'hooks/useDebounce';
import { FINANCIAL_ACCESSES, hasAccess, ROLES } from 'utilities/access';
import { paths } from 'routes/paths';
import EquipmentList from './components/EquipmentList';
import EquipmentToolbar from './components/EquipmentToolbar';
import { searchEquipment } from './EquipmentItem/helpers/dataHelpers';

const Equipment = () => {
  const [equipmentPageNumber, setEquipmentPageNumber] = useState(0);
  const {
    view,
    setView,
    sort,
    setSort,
    isSearch,
    loaded,
    items: equipments,
    totalItems: totalEquipments,
    setReload,
    setItems
  } = useLandingPageData(
    'equipments',
    ['name', 'equipmentType'],
    'name',
    equipmentPageNumber
  );
  const [{ loggedInUserOrgPermission, loadingOrg }, dispatch] = useContext(
    Context
  );
  const [financialAccess, setFinancialAccess] = useState();
  const [redirect, setRedirect] = useState(false);
  const [searchText, setSearchText] = useState(null);
  const [filteredEquipments, setFilteredEquipments] = useState([]);
  const debouncedSearchEquipment = useDebounce(searchText, 300);
  const history = useHistory();
  const [orgLoading, setOrgLoading] = useState(false);

  const handleOrgChange = () => {
    setEquipmentPageNumber(0);
    setReload(true);
  };

  const setPermission = useCallback(() => {
    return hasAccess(
      {
        role: loggedInUserOrgPermission?.role,
        financialAccess: loggedInUserOrgPermission?.financialAccess
      },
      {
        minAllowedRole: ROLES.FULL_CONTROL,
        minFinancialAccess: FINANCIAL_ACCESSES.WRITE
      }
    );
  }, [
    loggedInUserOrgPermission?.financialAccess,
    loggedInUserOrgPermission?.role
  ]);

  const showSpinner = () => {
    return !loaded || loadingOrg || !orgLoading;
  };

  useEffect(() => {
    if (debouncedSearchEquipment) {
      setFilteredEquipments(
        searchEquipment(equipments, debouncedSearchEquipment)
      );
    } else {
      setFilteredEquipments(searchEquipment(equipments, null));
    }

    dispatch({
      type: 'SET_TOOLBAR',
      payload: (
        <EquipmentToolbar
          view={view}
          changeView={event => setView(event)}
          sort={sort}
          changeSort={event => setSort(event)}
        />
      )
    });
    return () => {
      dispatch({ type: 'SET_TOOLBAR', payload: null });
    };
  }, [
    view,
    setView,
    sort,
    setSort,
    dispatch,
    equipments,
    debouncedSearchEquipment
  ]);

  useEffect(() => {
    if (isEmpty(loggedInUserOrgPermission)) {
      setOrgLoading(false);
    } else {
      setOrgLoading(true);
    }
  }, [loggedInUserOrgPermission]);

  useEffect(() => {
    if (
      (loggedInUserOrgPermission?.role === 'View Only' ||
        loggedInUserOrgPermission?.role === 'Full control') &&
      !Object.prototype.hasOwnProperty.call(
        loggedInUserOrgPermission,
        'financialAccess'
      )
    ) {
      setFinancialAccess('none');
    } else {
      setFinancialAccess(loggedInUserOrgPermission?.financialAccess);
    }
  }, [loggedInUserOrgPermission]);

  let equipIdpassedIn = {};
  let selectedView = '';
  if (location?.selectedView?.length > 0) {
    selectedView = location?.selectedView;
    equipments.forEach(item => {
      if (item.id === location?.itemId) equipIdpassedIn = item;
    });
  }

  const handlePageChange = page => {
    setEquipmentPageNumber(page);
    setReload(true);
  };

  const getViewOrEdit = () => {
    return (
      <div className="w-full h-full" data-testid="equipment">
        <div className="mb-3">
          <div className="flex justify-between flex-1">
            <LandingSearch
              id="search-equipment-input"
              name="search_equipment"
              showLabels={false}
              filterItems={[]}
              selectedItems={[]}
              onChange={event => {
                setSearchText(event.target.value);
              }}
              onFilterChange={noop}
              onChangeProductType={noop}
              onChangeTaskType={noop}
              placeholder="Search Equipment"
              showDateRange={false}
              showCropSeason={false}
              onDateRangeChange={() => {}} // Set to a default func for removing warnings
            />
            <div className="h-8">
              {setPermission() && orgLoading && (
                <Button
                  type="primary"
                  id="add-equipment-button"
                  onClick={event => {
                    event.stopPropagation();
                    setRedirect(true);
                  }}
                  icon={<AddRoundedIcon />}
                >
                  Add Equipment
                </Button>
              )}
            </div>
          </div>
        </div>
        {showSpinner() ? (
          <Spinner />
        ) : (
          <>
            {filteredEquipments && filteredEquipments.length ? (
              <EquipmentList
                view={view}
                equipments={filteredEquipments}
                isSearch={isSearch}
                memberRole={loggedInUserOrgPermission?.role}
                memberFinancialAccess={financialAccess}
                totalEquipments={totalEquipments}
                equipmentPageNumber={equipmentPageNumber}
                handlePageChange={handlePageChange}
                setItems={setItems}
              />
            ) : (
              <EmptyState
                buttonText="Add Equipment"
                onClick={() => history.push(`${paths.equipment}/item`)}
                subtitle="We were unable to find any equipment under this organization."
                setPermission={setPermission}
              />
            )}
          </>
        )}
      </div>
    );
  };

  const bakeBread = () => {
    if (
      selectedView === 'view' ||
      selectedView === 'edit' ||
      selectedView === 'create'
    )
      return (
        <Breadcrumb disabled hideCropSeasonDropdown>
          <Breadcrumb.Item
            title="Equipment"
            value="All Equipment"
            to={paths.equipment}
          />
          <Breadcrumb.Item
            title="Equipment"
            value={equipIdpassedIn?.name}
            isLast
          />
        </Breadcrumb>
      );
    return (
      <Breadcrumb onOrganizationSelect={handleOrgChange} hideCropSeasonDropdown>
        <Breadcrumb.Item title="Equipment" value="All Equipment" isLast />
      </Breadcrumb>
    );
  };

  return (
    <>
      {bakeBread()}
      {redirect && (
        <Redirect
          push
          to={{
            pathname: `${paths.equipment}/item`
          }}
        />
      )}
      {getViewOrEdit()}
    </>
  );
};

export default Equipment;
