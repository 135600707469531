import faker from 'faker';
import { v4 as uuidv4 } from 'uuid';
import { Factory } from 'miragejs';

import globalSchema from '../utils/globalSchema';

let firstBoundedArea = null;

const Area = {
  name() {
    return `Area ${faker.random.alphaNumeric(3).toUpperCase()}`;
  },
  cropName() {
    return 'Alfalfa';
  },
  representedArea() {
    return faker.random.number({ min: 10000, max: 200000 });
  },
  calculatedArea() {
    return faker.random.boolean()
      ? faker.random.number({ min: 10000, max: 200000 })
      : 0;
  },
  boundedArea(i) {
    if (i === 0) {
      const firstUUID = uuidv4();
      firstBoundedArea = `shapes/${firstUUID}`;
      return firstBoundedArea;
    }
    return `shapes/${uuidv4()}`;
  },
  areas() {
    return [];
  },
  centroid() {
    return null;
  },
  sourceId() {
    return faker.random.uuid();
  },
  source() {
    return faker.company.companyName();
  },
  insuranceId() {
    return faker.random.number();
  },
  seedSource() {
    return { name: 'Example Source' };
  },
  seedLotId() {
    return '123';
  },
  label() {
    return faker.lorem.word();
  },
  fsaArea() {
    return faker.random.number();
  },
  permitId() {
    return '123';
  },
  siteId() {
    return '123';
  },
  cropZoneBlockId() {
    return '123';
  },
  tillageSystem() {
    return 'Plow';
  },
  fieldNotes() {
    return faker.lorem.sentence();
  },
  irrigated() {
    return faker.random.boolean();
  },
  pumpPressure() {
    return faker.random.number();
  },
  waterSource() {
    return 'well';
  },
  pumpLift() {
    return faker.random.number({ min: 1, max: 10 });
  },
  waterOrigin() {
    return 'acquifer';
  },
  pumpEnergySource() {
    return 'diesel';
  },
  irrigationSystem() {
    return 'basin';
  },
  usePolyacrylamide() {
    return faker.random.boolean();
  },
  atrazineProhibitionArea() {
    return faker.random.boolean();
  },
  applicationDate() {
    return faker.date.recent();
  },
  neighboringSensitiveCrops() {
    return ['crop 1', 'crop 2'];
  },
  cropSeason() {
    return {
      cropSeasonLabel: '2003',
      startDate: faker.date.recent(),
      endDate: faker.date.recent()
    };
  },
  cropYear() {
    return {
      cropYearLabel: '2003',
      startDate: faker.date.recent(),
      endDate: faker.date.recent()
    };
  },
  active() {
    return globalSchema.active();
  },
  createdAt() {
    return globalSchema.createdAt();
  },
  updatedAt() {
    return globalSchema.updatedAt();
  },
  version() {
    return 1;
  },
  includeOnMaps() {
    return true;
  },
  tags() {
    return [];
  },
  geometry: {
    coordinates: [
      [
        [-90.78879187678417, 36.062604240615585],
        [-90.78963484462349, 36.062646830198304],
        [-90.79219009088709, 36.06128395211192],
        [-90.79358625637123, 36.06049602744949],
        [-90.79503510734551, 36.0603469597344],
        [-90.79732692615912, 36.06028307348382],
        [-90.79753766811895, 36.060048823456015],
        [-90.79761669635398, 36.054937740165045],
        [-90.79753766811895, 36.0547886619202],
        [-90.79703715596428, 36.05468217728652],
        [-90.78881821952906, 36.05476736500508],
        [-90.78879187678417, 36.062604240615585]
      ]
    ],
    type: 'Polygon'
  }
};

export default Factory.extend(Area);
