import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { Button, Spinner } from '@agconnections/grow-ui';

import FlagSelector from 'components/FlagSelector';
import Paper from 'components/Paper';
import EllipseMenu from 'components/EllipseMenu';

const TileCard = ({
  title,
  subtitle,
  onAction,
  testId,
  children,
  menuItems,
  flagSelectorVisible,
  handleFlagSelectorClose,
  linkTo,
  className,
  titleColor,
  contextMenuHide,
  runningProcesses
}) => {
  const showEllipseMenu = () =>
    runningProcesses ? (
      <Spinner size="sm" />
    ) : (
      <EllipseMenu onAction={onAction} options={menuItems} />
    );

  return (
    <div
      data-testid={`${testId}-card`}
      className="w-full pr-8 mt-6 mb-2 lg:w-1/4 md:w-1/2"
    >
      <Paper className={`${className}`}>
        <>
          <div className="flex justify-between w-full">
            <div className="w-5/6">
              <div
                data-testid={`${testId}-card-name`}
                className="font-semibold truncate text-syngenta-navy"
                style={{ color: titleColor }}
              >
                <Link to={linkTo}>{title || 'unnamed'}</Link>
              </div>
              <div
                data-testid={`${testId}-card-crop`}
                className="text-sm text-neutral-300"
              >
                {subtitle}
              </div>
            </div>
            <div data-testid={`${testId}-card-options-menu`} className="h-8">
              {contextMenuHide ? (
                <Button type="link-default" onClick={onAction}>
                  View
                </Button>
              ) : (
                showEllipseMenu()
              )}
              {flagSelectorVisible ? (
                <FlagSelector
                  className="-ml-64"
                  handleFlagSelectorClose={handleFlagSelectorClose}
                />
              ) : null}
            </div>
          </div>
          {children}
        </>
      </Paper>
    </div>
  );
};

TileCard.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  onAction: PropTypes.func,
  testId: PropTypes.string,
  children: PropTypes.node,
  menuItems: PropTypes.arrayOf(PropTypes.shape({})),
  flagSelectorVisible: PropTypes.bool,
  className: PropTypes.string,
  handleFlagSelectorClose: PropTypes.func,
  linkTo: PropTypes.string,
  titleColor: PropTypes.string,
  contextMenuHide: PropTypes.bool,
  runningProcesses: PropTypes.bool
};

TileCard.defaultProps = {
  title: '',
  subtitle: '',
  onAction: () => {},
  testId: 'default',
  children: null,
  menuItems: [],
  flagSelectorVisible: false,
  handleFlagSelectorClose: () => {},
  linkTo: '/',
  className: '',
  titleColor: '',
  contextMenuHide: false,
  runningProcesses: false
};

export default TileCard;
