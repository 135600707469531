import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Toast } from '@agconnections/grow-ui';
import PortalModal from 'components/Modals/PortalModal';
import { withRouter } from 'react-router-dom';
import catchCancel from 'helpers/catchCancel';
import { field as fieldApi } from 'utilities/api';

const DeleteFieldModal = ({ open, close, fieldId, setReRenderProperties }) => {
  const [toastRenderContents, setToastRenderContents] = useState(null);
  const [toastHasNotBeenClosed, setToastHasNotBeenClosed] = useState(true);
  const getUserErrorMessage = message => {
    setToastRenderContents(message);
    setToastHasNotBeenClosed(true);
  };
  const [isSaving, setIsSaving] = useState(false);
  const handleDelete = async () => {
    setIsSaving(true);
    const { promise } = fieldApi.delete(fieldId);
    await promise
      .then(() => {
        setReRenderProperties(true);
      })
      .catch(catchCancel)
      .catch(err => {
        setIsSaving(false);
        getUserErrorMessage(err.response.data.message);
      });
  };

  return (
    <>
      <PortalModal
        data-testid="delete-field-modal"
        open={open}
        title="Delete this Field?"
        close={close}
        cancelLabel="Cancel"
        confirmLabel="Delete"
        onCancel={() => close()}
        type="error"
        onConfirm={() => handleDelete()}
        isSaving={isSaving}
      >
        <div className="pr-6">
          Are you sure you want to delete this field? This action cannot be
          undone.
        </div>
        {toastHasNotBeenClosed ? (
          <Toast
            icon="error"
            onClose={() => {
              setToastHasNotBeenClosed(false);
            }}
          >
            {toastRenderContents}
          </Toast>
        ) : null}
      </PortalModal>
    </>
  );
};

DeleteFieldModal.propTypes = {
  open: PropTypes.func.isRequired,
  close: PropTypes.func.isRequired,
  fieldId: PropTypes.string.isRequired,
  setReRenderProperties: PropTypes.func.isRequired
};

export default withRouter(DeleteFieldModal);
