import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { FarmIcon } from 'components/Icons';
import PropertyView from '../PropertyView';

const FarmList = ({ vendorFarms, cropZones, fftSelectGroups }) => {
  const [openedProperty, setOpenedProperty] = useState(null);

  const openProperty = property => {
    const currentIsOpen = openedProperty === property.id;
    setOpenedProperty(currentIsOpen ? null : property.id);
  };

  return (
    <div className="grid w-full h-full">
      <div className="mb-5">
        {vendorFarms?.map(farm => (
          <div key={farm.id} className="mt-7">
            <div className="justify-self-start">
              <div className="flex flex-row items-center text-2xl font-semibold">
                <FarmIcon />
                <span data-testid="farm-name" className="ml-2">
                  {farm?.name}
                </span>
              </div>
            </div>
            <div>
              {farm.fields.map(field => (
                <PropertyView
                  key={field.id}
                  property={field}
                  cropZones={cropZones}
                  fftSelectGroups={fftSelectGroups}
                  openProperty={openProperty}
                  isOpen={openedProperty === field.id}
                />
              ))}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

FarmList.defaultProps = {
  vendorFarms: [],
  cropZones: [],
  fftSelectGroups: []
};

FarmList.propTypes = {
  vendorFarms: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      name: PropTypes.string,
      fields: PropTypes.arrayOf(
        PropTypes.shape({
          id: PropTypes.string,
          name: PropTypes.string
        })
      )
    })
  ),
  cropZones: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      name: PropTypes.string,
      reportedArea: PropTypes.number,
      boundaryArea: PropTypes.number
    })
  ),
  fftSelectGroups: PropTypes.arrayOf(PropTypes.object)
};

export default FarmList;
