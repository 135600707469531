/* eslint-disable no-useless-return */
import { cloneDeep } from 'lodash';

const onBoardChangeColumn = (changedTask, changedToColumnTitle, updateTask) => {
  const changedTaskCopy = cloneDeep(changedTask);
  changedTaskCopy.status = changedToColumnTitle;

  updateTask(changedTask.id, changedToColumnTitle);
};

const checkIfNoActionNeeded = result => {
  const { destination, source } = result;
  const locationDidNotChange =
    destination?.droppableId === source?.droppableId &&
    destination?.index === source?.index;

  if (!destination) {
    return true;
  }

  if (locationDidNotChange) {
    return true;
  }

  return false;
};

const moveTaskToDifferentColumn = (
  result,
  startColumn,
  finishColumn,
  setColumns
) => {
  const { destination, source, draggableId } = result;

  const startTaskIds = Array.from(startColumn.tasks);
  startTaskIds.splice(source.index, 1);

  const newStartColumn = {
    ...startColumn,
    tasks: startTaskIds
  };

  const finishTaskIds = Array.from(finishColumn.tasks);
  finishTaskIds.splice(destination.index, 0, draggableId);

  const newFinishColumn = {
    ...finishColumn,
    tasks: finishTaskIds
  };

  setColumns(previousColumns => {
    const previousColumnsCopy = cloneDeep(previousColumns);

    const startColumnIndex = previousColumnsCopy.findIndex(
      previousColumnObject => previousColumnObject.id === newStartColumn.id
    );
    const finishColumnIndex = previousColumnsCopy.findIndex(
      previousColumnObject => previousColumnObject.id === newFinishColumn.id
    );

    previousColumnsCopy.splice(startColumnIndex, 1, newStartColumn);
    previousColumnsCopy.splice(finishColumnIndex, 1, newFinishColumn);

    return previousColumnsCopy;
  });
};

const onDragEnd = async (
  result,
  columns,
  setColumns,
  draggingOverTask,
  updateTask,
  updateTaskWeather,
  selectedTask
) => {
  const { destination, source } = result;

  if (checkIfNoActionNeeded(result)) {
    return;
  }

  const startColumn = columns.find(
    columnObject => columnObject.id === source.droppableId
  );
  const finishColumn = columns.find(
    columnObject => columnObject.id === destination.droppableId
  );

  // Reordering in same column not possible, do nothing
  if (startColumn === finishColumn) {
    return;
  }

  moveTaskToDifferentColumn(result, startColumn, finishColumn, setColumns);

  const resultColumnName = columns.filter(
    column => column.id === result.destination.droppableId
  )[0].title;

  await updateTaskWeather({ ...selectedTask }, resultColumnName);

  onBoardChangeColumn(draggingOverTask, resultColumnName, updateTask);
};

export default onDragEnd;
