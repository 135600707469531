import React from 'react';
import { SaleLocationsProvider } from '../../context';
import SaleLocations from '../..';

const ParentProviderSaleLocations = () => {
  return (
    <SaleLocationsProvider>
      <SaleLocations />
    </SaleLocationsProvider>
  );
};

export default ParentProviderSaleLocations;
