import React from 'react';

import CalAgLogo from 'assets/integrations/CalAgPermitsLogo.png';
import SubmissionHistory from 'screens/Integrations/SubmissionHistory';
import { INTEGRATIONS } from 'screens/Integrations/helpers/constants';
import Provider from 'screens/Integrations/SubmissionHistory/context/Provider';
import LandingPage from 'screens/Integrations/components/LandingPage';
import Instructions from 'screens/Integrations/components/Instructions';

export const CALAG_STEPS = [
  {
    number: 1,
    header: 'Select Crop Seasons',
    description:
      'Select the Crop Seasons with which you would like to submit Task records'
  },
  {
    number: 2,
    header: 'Select Properties',
    description:
      'Select the Properties with Tasks records you would like to submit'
  },
  {
    number: 3,
    header: 'Select Products',
    description:
      'Select the products you would like to include in the submission.'
  },
  {
    number: 4,
    header: 'Enter Field Details',
    description: 'Enter remaining field information needed for submission'
  },
  {
    number: 5,
    header: 'Review and Submit',
    description:
      'Review your Task records and then submit the information to the processor'
  }
];

const CalAgLandingPage = () => {
  return (
    <>
      <LandingPage
        headerText={`${INTEGRATIONS.calAgPermits} Regulatory Integration`}
        breadcrumbText={INTEGRATIONS.calAgPermits}
        ownerText={INTEGRATIONS.calAgPermits}
        targetPath={INTEGRATIONS.calAgPermits}
        LogoImg={CalAgLogo}
      />
      <div className="w-full border-t-1 mb-5" />
      <Provider integrationType={INTEGRATIONS.calAgPermits}>
        <SubmissionHistory />
      </Provider>
      <Instructions steps={CALAG_STEPS} />
    </>
  );
};

export default CalAgLandingPage;
