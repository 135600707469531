import React from 'react';
import PropTypes from 'prop-types';
import { Spinner, Table } from '@agconnections/grow-ui';

const LoadingTableRow = ({ numCells }) => {
  return (
    <Table.Row>
      <Table.Cell>
        <Spinner size="sm" />
      </Table.Cell>
      {Array.from(Array(Math.max(numCells - 1, 0)), (_, i) => (
        <Table.Cell key={i} />
      ))}
    </Table.Row>
  );
};

LoadingTableRow.propTypes = {
  numCells: PropTypes.number.isRequired
};

export default LoadingTableRow;
