import React, { useEffect, useState } from 'react';
import { Columns, Column, Button } from '@agconnections/grow-ui';
import PortalModal from 'components/Modals/PortalModal';
import EmptyStateProduct from 'screens/Inventory/components/EmptyStateProduct';
import getCompleteREIPHIData from 'screens/ProductList/ProductDetails/helpers/getCompleteREIPHIData';
import PropTypes from 'prop-types';
import CropCard from 'components/CropCard';
import CropAddEditModal from 'components/Modals/CropAddEditModal';
import { sortBy } from 'lodash';

import './REIPHI.css';

const REIPHI = ({
  dataREIPHI,
  dataDocumentation,
  productId,
  properties,
  saveREIPHI,
  deleteREIPHI,
  updateREIPHI
}) => {
  const [openEdit, setOpenEdit] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [selectedCrop, setSelectedCrop] = useState(null);
  const [openDelete, setOpenDelete] = useState(false);
  const [reiPhiFiltered, setReiPhiFiltered] = useState([]);
  const [prodToDelete, setProdToDelete] = useState(null);

  const openDeleteModal = deleteProd => {
    setProdToDelete(deleteProd);
    setOpenDelete(true);
  };
  const closeDeleteModal = wasDeleted => {
    if (wasDeleted) reiPhiFiltered.splice(prodToDelete?.i, 1);
    setOpenDelete(false);
  };

  const handleDelete = () => {
    deleteREIPHI(prodToDelete, closeDeleteModal);
  };

  const openAddEdit = (edit, cropToEdit) => {
    setSelectedCrop(cropToEdit);
    setIsEdit(edit);
    setOpenEdit(true);
  };

  const closeAddEditModal = modifiedCrop => {
    if (modifiedCrop) {
      if (isEdit) {
        reiPhiFiltered[modifiedCrop.i] = modifiedCrop;
      } else {
        reiPhiFiltered.push(modifiedCrop);
      }
    }
    updateREIPHI(modifiedCrop);
    setOpenEdit(false);
  };

  const footerInfor = () => {
    return (
      <>
        <ul className="list-disc text-m text-neutral-80 leading-7 pt-8 py-2 mt-2">
          <li>
            REI (Restricted Entry Interval): Time limit for access on the field
            after application of the product. Measured in hours.
          </li>
          <li>
            PHI (Pre-Harvested Interval): Time limit for harvesting after
            application. Measured in days.
          </li>
          <li>
            REI/PHI: Is indicated on the label of the preparation and/or on the
            manufacturer&apos;s website.
          </li>
        </ul>
        <p className="text-m text-neutral-1000 leading-10 py-2">
          Note: User is responsible for checking labels for accuracy.
        </p>
        {/* <p className="text-sm text-info-dark-blue leading-5 py-2 px-12">
    VIEW LICENSE AGREEMENT (SECTION 8:D)
    </p> */}
        {openEdit && (
          <CropAddEditModal
            openEdit={openEdit}
            onClose={closeAddEditModal}
            crop={selectedCrop}
            isEdit={isEdit}
            productId={productId}
            saveREIPHI={saveREIPHI}
          />
        )}
      </>
    );
  };

  useEffect(() => {
    const completeREIPHIData = getCompleteREIPHIData(
      dataDocumentation.reiphiitems,
      properties,
      dataREIPHI
    );

    setReiPhiFiltered(completeREIPHIData);
  }, [dataREIPHI, dataDocumentation.reiphiitems, properties]);

  return reiPhiFiltered.length > 0 ? (
    <>
      <div className="grid justify-end py-2 mb-2">
        <Button type="primary" ghost onClick={() => openAddEdit(false)}>
          Add Crop
        </Button>
      </div>
      <div className="crops-container border-b">
        <Columns wrap>
          {sortBy(reiPhiFiltered, 'name').map((product, i) => (
            <Column width="gr-w-1/2 p-4" key={product.cropid}>
              <CropCard
                product={product}
                editClick={() => openAddEdit(true, { ...product, i })}
                deleteClick={() => {
                  openDeleteModal(product);
                }}
              />
            </Column>
          ))}
        </Columns>
      </div>
      <div className="ml-6">{footerInfor()}</div>
      <PortalModal
        open={openDelete}
        title="Delete REI/PHI Information?"
        close={closeDeleteModal}
        cancelLabel="Cancel"
        confirmLabel="Delete"
        onCancel={closeDeleteModal}
        type="error"
        onConfirm={handleDelete}
      >
        <div className="pr-6">
          Are you sure you want to delete this information? This action cannot
          be undone.
        </div>
      </PortalModal>
    </>
  ) : (
    <EmptyStateProduct
      setPermission={() => true}
      openAddEditModal={openAddEdit}
    >
      {footerInfor()}
    </EmptyStateProduct>
  );
};

REIPHI.defaultProps = {
  dataREIPHI: []
};

REIPHI.propTypes = {
  dataREIPHI: PropTypes.shape(),
  dataDocumentation: PropTypes.shape().isRequired,
  productId: PropTypes.string.isRequired,
  properties: PropTypes.arrayOf(PropTypes.object).isRequired,
  saveREIPHI: PropTypes.func.isRequired,
  deleteREIPHI: PropTypes.func.isRequired,
  updateREIPHI: PropTypes.func.isRequired
};

export default REIPHI;
