/* eslint-disable import/prefer-default-export */

export const TABLE_COLUMNS = [
  {
    field: 'name',
    title: 'Invoice',
    sortable: true
  },
  {
    field: 'date',
    title: 'Invoice Date',
    sortable: true
  },
  {
    field: 'dueDate',
    title: 'Due Date',
    sortable: true
  },
  {
    field: 'totalCost',
    title: 'Total Invoice Cost',
    sortable: false
  },
  {
    field: 'productCount',
    title: 'Product Count',
    sortable: false
  },
  {
    field: 'cropSeasons',
    title: 'Seasons',
    sortable: false
  }
];

export const INVOICES_DEFAULT_SORT = {
  by: 'name',
  dir: 'asc'
};

export const INVOICES_DEFAULT_PAGINATION = {
  initialPage: 0,
  size: 50
};

export const INVOICES_DEFAULT_VIEW_MODE = 'tile';

export const REDIRECT_PATH = '/app/invoices';
