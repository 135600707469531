import React from 'react';
import PropTypes from 'prop-types';

import FormattedNumber from 'components/FormattedNumber';

const PriceDisplay = ({
  variableCost,
  fixedCost,
  totalProjectedNetRevenue,
  perAcreProjectedNetRevenue,
  displayByFinancialAccess
}) => {
  return (
    <>
      <div className="flex flex-col mr-4">
        <div className="text-neutral-300 opacity-60">Variable Cost</div>
        <div data-testid="variable-cost" className="text-neutral-300">
          {displayByFinancialAccess && (
            <>
              <FormattedNumber unit="usCurrency" roundedTo={2}>
                {variableCost}
              </FormattedNumber>
            </>
          )}
        </div>
      </div>
      <div className="flex flex-col mr-4">
        <div className="text-neutral-300 opacity-60">Fixed Cost</div>
        <div data-testid="variable-cost" className="text-neutral-300">
          {displayByFinancialAccess && (
            <>
              <FormattedNumber unit="usCurrency" roundedTo={2}>
                {fixedCost}
              </FormattedNumber>
            </>
          )}
        </div>
      </div>
      <div className="flex flex-col mr-4">
        <div className="text-neutral-300 opacity-60">Proj. Net Revenue</div>
        <div data-testid="net-revenue" className="text-neutral-300">
          {displayByFinancialAccess && (
            <>
              <FormattedNumber unit="usCurrency" roundedTo={2}>
                {totalProjectedNetRevenue}
              </FormattedNumber>
            </>
          )}
        </div>
      </div>
      <div className="flex flex-col mr-4">
        <div className="text-neutral-300 opacity-60">
          Proj. Net Revenue Per Area
        </div>
        <div data-testid="net-revenue-per-acre" className="text-neutral-300">
          {isFinite(perAcreProjectedNetRevenue) && displayByFinancialAccess && (
            <>
              <FormattedNumber unit="usCurrency" roundedTo={2}>
                {perAcreProjectedNetRevenue}
              </FormattedNumber>{' '}
              per Acre
            </>
          )}
        </div>
      </div>
    </>
  );
};

PriceDisplay.propTypes = {
  variableCost: PropTypes.number.isRequired,
  fixedCost: PropTypes.number.isRequired,
  totalProjectedNetRevenue: PropTypes.number.isRequired,
  perAcreProjectedNetRevenue: PropTypes.number.isRequired,
  displayByFinancialAccess: PropTypes.bool.isRequired
};

export default PriceDisplay;
