/* eslint-disable import/prefer-default-export */

export const TABLE_COLUMNS = [
  {
    field: 'name',
    title: 'Company Name',
    sortable: true
  },
  {
    field: 'city',
    title: 'City',
    sortable: true
  },
  {
    field: 'state',
    title: 'State',
    sortable: true
  },
  {
    field: '',
    title: '',
    sortable: false
  }
];
