// eslint-disable-next-line import/prefer-default-export
export const filterPropertiesCropZones = (properties, seasonIds, cropId) => {
  const filterCropZones = field => {
    return field.cropzones.filter(
      cz =>
        (!cropId || cz.crop.id === cropId) &&
        cz.cropSeasons.some(season => seasonIds.includes(season.id))
    );
  };

  const mapFields = farm => {
    const newFields = farm.fields.map(field => ({
      ...field,
      cropzones: filterCropZones(field)
    }));

    return {
      ...farm,
      fields: newFields.filter(field => field.cropzones.length > 0)
    };
  };

  const filteredFarms = properties.map(mapFields);

  return filteredFarms.filter(farm => farm.fields.length > 0);
};
