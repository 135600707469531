const addVarietyRow = cropData => {
  return cropData.flatMap(crop => {
    if (crop?.varieties?.length) {
      return crop.varieties.map(variety => ({
        cropname: crop.cropname,
        cropseason: crop.cropseason,
        cropId: crop.cropId,
        cropZoneId: crop.cropZoneId,
        geometry: crop.geometry,
        variety: variety.name,
        plantingDate: variety.plantingDate,
        appliedArea: variety.appliedArea
      }));
    }
    return [
      {
        cropname: crop.cropname,
        cropseason: crop.cropseason,
        cropId: crop.cropId,
        cropZoneId: crop.cropZoneId
      }
    ];
  });
};
export default addVarietyRow;
