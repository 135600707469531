import React from 'react';
import PropTypes from 'prop-types';

const CardGalleryHeader = ({ title, subtitle, icon }) => {
  return (
    <div className="mt-8 mb-10 flex flex-row w-full justify-start">
      <div>
        <div className="bg-green-200 p-2 rounded-full">{icon}</div>
      </div>
      <div className="flex flex-col ml-4">
        <h2 className="text-lg font-semibold text-neutral-1000">{title}</h2>
        <p>{subtitle}</p>
      </div>
    </div>
  );
};

CardGalleryHeader.propTypes = {
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.string.isRequired,
  icon: PropTypes.oneOfType([
    PropTypes.elementType, // For React components
    PropTypes.func // For functions that return JSX
  ]).isRequired
};

export default CardGalleryHeader;
