export function polarToCartesian(centerX, centerY, radius, angleInDegrees) {
  const angleInRadians = ((angleInDegrees - 90) * Math.PI) / 180.0;
  return {
    x: centerX + radius * Math.cos(angleInRadians),
    y: centerY + radius * Math.sin(angleInRadians)
  };
}

export function generatePathSegment({
  innerRadius,
  outerRadius,
  startAngle,
  endAngle
}) {
  const outerStart = polarToCartesian(100, 100, outerRadius, endAngle);
  const outerEnd = polarToCartesian(100, 100, outerRadius, startAngle);

  const innerStart = polarToCartesian(100, 100, innerRadius, endAngle);
  const innerEnd = polarToCartesian(100, 100, innerRadius, startAngle);

  const largeArcFlag = endAngle - startAngle <= 180 ? '0' : '1';

  const d = [
    'M',
    outerStart.x,
    outerStart.y,
    'A',
    outerRadius,
    outerRadius,
    0,
    largeArcFlag,
    0,
    outerEnd.x,
    outerEnd.y,
    'L',
    innerEnd.x,
    innerEnd.y,
    'A',
    innerRadius,
    innerRadius,
    0,
    largeArcFlag,
    1,
    innerStart.x,
    innerStart.y,
    'Z'
  ].join(' ');
  return d;
}
