/* eslint-disable import/prefer-default-export */
export const APPLICATION_OPTIONS = {
  byRatePerArea: {
    key: 'ByRatePerArea',
    value: 'ratePerAreaValue',
    unit: 'ratePerAreaUnit',
    title: 'Rate/Area'
  },
  byTotalProduct: {
    key: 'ByTotalProduct',
    value: 'totalProductValue',
    unit: 'totalProductUnit',
    title: 'Total Product'
  },
  byRatePerTank: {
    key: 'ByRatePerTank',
    value: 'ratePerTankValue',
    unit: 'ratePerTankUnit',
    title: 'Rate/Tank'
  }
};
