import convertStdPackage from './convertStdPackage';

const getTotalInvoiceValue = product => {
  if (!product) return 0;
  const productObj = {
    density: product.density,
    productDensity: product.productDensity,
    stdfactor: product.stdFactor,
    stdunit: product.stdUnit,
    stdpackageunit: product.stdPackageUnit
  };
  const invoiceValue = convertStdPackage(
    product.purchased,
    product.purchasedUnit,
    product.stdPackageUnit,
    productObj
  );

  const invoiceValues = !product.purchased ? 0 : invoiceValue?.Value;

  return invoiceValues;
};

export default getTotalInvoiceValue;
