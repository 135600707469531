import React, { useContext, useState, createRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Spinner } from '@agconnections/grow-ui';

import { useToast } from 'components/ToastContainer';
import { SubmissionSummaryContext } from '../../context/SubmissionSummaryProvider';
import TopHeader from '../TopHeader';
import ConfirmationModal from '../ConfirmationModal';
import SummaryFarms from '../SummaryFarms';
import FieldsView from '../FieldsView';
import RemoveCropzoneModal from '../RemoveCropzoneModal';

const ReviewPage = ({ FooterComponent, loading }) => {
  const [farmRefs, setFarmRefs] = useState([]);
  const {
    setOpenConfirmationModal,
    state: { farms, isReadOnly }
  } = useContext(SubmissionSummaryContext);

  const toast = useToast();

  useEffect(() => {
    setFarmRefs(currentfarmRefs =>
      farms?.map((_, i) => currentfarmRefs[i] || createRef())
    );

    if (!loading && !farms?.length) {
      toast.error('No completed tasks found', {
        supportButton: false,
        timeout: 30000,
        content: 'There is no completed tasks for selected season and property'
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [farms]);

  const scrollToFarm = index => {
    if (farmRefs[index]) {
      farmRefs[index].current.scrollIntoView({
        behavior: 'smooth',
        block: 'center'
      });
    }
  };
  return (
    <>
      <div
        className="w-full h-full bg-white flex flex-col px-6 py-4 rounded-xl font-body"
        data-testid="summary-view"
      >
        <div
          className="flex flex-col w-full mb-7"
          id="submission-summary-top-header"
        >
          <TopHeader />
        </div>
        {loading ? (
          <div className="m-auto">
            <Spinner />
          </div>
        ) : (
          <>
            {farms?.length && (
              <>
                {farms?.map((farm, i) => (
                  <div key={farm.id}>
                    <div
                      className="w-full mb-5"
                      ref={el => {
                        if (farmRefs[i]) {
                          farmRefs[i].current = el;
                          return farmRefs[i].current;
                        }
                        return el;
                      }}
                    >
                      <SummaryFarms farm={farm} selectFarm={scrollToFarm} />
                    </div>

                    <div
                      className="flex flex-row w-full h-full"
                      data-testid="submission-summary-view"
                      id="submission-summary-view"
                    >
                      <div className="w-full">
                        <FieldsView
                          fields={farm.fields?.filter(
                            ({ cropzones }) => cropzones?.length > 0
                          )}
                        />
                      </div>
                    </div>
                  </div>
                ))}
                {!isReadOnly && FooterComponent && (
                  <FooterComponent
                    isLastStep
                    setModalSubmissionState={setOpenConfirmationModal}
                  />
                )}
              </>
            )}
          </>
        )}
      </div>
      <RemoveCropzoneModal />
      <ConfirmationModal />
    </>
  );
};

ReviewPage.defaultProps = {
  FooterComponent: null,
  loading: false
};

ReviewPage.propTypes = {
  FooterComponent: PropTypes.oneOfType([
    PropTypes.elementType, // For React components
    PropTypes.func // For functions that return JSX
  ]),
  loading: PropTypes.bool
};

export default ReviewPage;
