import { ROLES } from 'utilities/access';

import Tasks from 'screens/Tasks';
import Task from 'screens/Tasks/Task';
import TaskView from 'screens/Tasks/Task/TaskView';

import { TasksIcon } from 'routes/icons';
import { paths } from 'routes/paths';

const routes = [
  {
    id: 'tasks',
    key: 'tasks',
    path: paths.tasks,
    exact: true,
    secure: true,
    disabled: false,
    component: Tasks,
    icon: TasksIcon,
    nav: 'leftNav'
  },
  {
    id: 'taskEntry',
    key: 'taskEntry',
    path: `${paths.tasks}/:id`,
    exact: true,
    secure: true,
    component: Task,
    minPermissions: {
      minAllowedRole: ROLES.FULL_CONTROL
    }
  },
  {
    id: 'taskView',
    key: 'taskView',
    path: `${paths.tasks}/:id/view`,
    exact: true,
    secure: true,
    component: TaskView
  },
  {
    id: 'taskConvert',
    key: 'taskConvert',
    path: `${paths.tasks}/:id/convert`,
    exact: true,
    secure: true,
    component: Task,
    minPermissions: {
      minAllowedRole: ROLES.FULL_CONTROL
    }
  },
  {
    id: 'taskDuplicate',
    key: 'taskDuplicate',
    path: `${paths.tasks}/:id/duplicate`,
    exact: true,
    secure: true,
    component: Task,
    minPermissions: {
      minAllowedRole: ROLES.FULL_CONTROL
    }
  }
];

export default routes;
