import AppContainer from 'components/App/AppContainer';

import CompaniesRoutes from './Companies';
import CropSeasonsRoutes from './CropSeasons';
import EquipmentRoutes from './Equipment';
import Integrations from './Integrations';
import InvoicesRoutes from './Invoices';
import InventoryRoutes from './Inventory';
import OrganizationsRoutes from './Organizations';
import PeopleRoutes from './People';
import PlansRoutes from './Plans';
import ProductsRoutes from './Products';
import ProfileRoutes from './Profile';
import PropertiesRoutes from './Properties';
import RecommendationsRoutes from './Recommendations';
import ReportsRoutes from './Reports';
import TasksRoutes from './Tasks';
import WelcomeRoutes from './Welcome';
import YieldRoutes from './Yield';
import YieldV2Routes from './YieldV2';

const appRoutes = [];

appRoutes.push(...OrganizationsRoutes);
appRoutes.push(...PropertiesRoutes);
appRoutes.push(...CropSeasonsRoutes);
appRoutes.push(...PlansRoutes);
appRoutes.push(...RecommendationsRoutes);
appRoutes.push(...TasksRoutes);
appRoutes.push(...InvoicesRoutes);
appRoutes.push(...ProductsRoutes);
appRoutes.push(...PeopleRoutes);
appRoutes.push(...CompaniesRoutes);
appRoutes.push(...EquipmentRoutes);
appRoutes.push(...Integrations);
appRoutes.push(...InventoryRoutes);
appRoutes.push(...ReportsRoutes);
appRoutes.push(...YieldRoutes);
appRoutes.push(...ProfileRoutes);
appRoutes.push(...WelcomeRoutes);
appRoutes.push(...YieldV2Routes);

const routes = [
  {
    id: 'appcontainer',
    key: 'appcontainer',
    path: '/app',
    secure: true,
    component: AppContainer,
    routes: appRoutes
  }
];

export default routes;
export { appRoutes };
