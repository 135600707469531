/* istanbul ignore file */
import { Factory } from 'miragejs';
import faker from 'faker';
import globalSchema from '../utils/globalSchema';

const CostPerAcre = {
  id() {
    return faker.random.uuid();
  },
  crop() {
    return faker.random.arrayElement(['Corn', 'Soybean', 'Tobacco']);
  },
  costPerAcre() {
    return parseFloat(faker.commerce.price());
  },
  ...globalSchema
};

export default Factory.extend(CostPerAcre);
