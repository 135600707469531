import React, { Component } from 'react';
import PropTypes from 'prop-types';

import TryAgainMessage from '../TryAgainMessage';

class ErrorBoundary extends Component {
  logger = console;

  constructor(props) {
    super(props);
    this.state = {
      hasError: false,
      errorMessage: '',
      errorStack: '',
      componentStack: ''
    };
  }

  static getDerivedStateFromError(error) {
    return { hasError: !!error };
  }

  componentDidCatch(error, errorInfo) {
    this.logger.error('error boundary:', error, errorInfo);
    this.setState({
      errorMessage: error.message,
      errorStack: error.stack,
      componentStack: errorInfo.componentStack
    });
  }

  render() {
    const { hasError, errorMessage, errorStack, componentStack } = this.state;
    const { children } = this.props;
    if (hasError) {
      return ['development', 'mirage-development'].includes(
        process.env.REACT_APP_ENV
      ) ? (
        <>
          <h1 className="my-4 h1">An error has occurred.</h1>
          <pre>{errorMessage}</pre>
          <h2 className="my-4 h2">Error Stack</h2>
          <pre>{errorStack}</pre>
          <h2 className="my-4 h2">Component Stack</h2>
          <pre>{componentStack}</pre>
        </>
      ) : (
        <TryAgainMessage
          error={{ status: 'Unknown' }}
          onReset={() => {
            window.location = '/app';
          }}
        />
      );
    }

    return children;
  }
}

ErrorBoundary.propTypes = {
  children: PropTypes.node
};

ErrorBoundary.defaultProps = {
  children: null
};

export default ErrorBoundary;
