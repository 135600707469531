import React from 'react';
import PropTypes from 'prop-types';
import { Button } from '@agconnections/grow-ui';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronLeft } from '@fortawesome/free-solid-svg-icons';

const TagsDelete = ({ backToLanding, resetForm, handleSubmit }) => {
  return (
    <>
      <div data-testid="back-button" className="flex w-full mb-12">
        <Button
          id="back-to-landing"
          type="cancel"
          style={{ width: '100%' }}
          onClick={() => backToLanding(resetForm)}
        >
          <FontAwesomeIcon
            icon={faChevronLeft}
            size="2x"
            style={{
              visibility: 'visible'
            }}
          />
          <div
            data-testid="back-button-label"
            className="text-xl align-middle font-bold ml-4 w-full text-left"
          >
            Delete Tag?
          </div>
        </Button>
      </div>
      <div data-testid="delete-message">
        This will remove the tag from all cards and cannot be undone. Do you
        want to proceed?
      </div>
      <div className="border mt-2 mb-5" />
      <button
        data-testid="delete-button"
        className="border border-solid rounded m-2 p-2 w-full mr-8"
        style={{
          backgroundColor: '#CF3537',
          color: '#FFFFFF'
        }}
        id="delete-tag-button"
        type="button"
        onClick={handleSubmit}
      >
        Delete
      </button>
    </>
  );
};

TagsDelete.propTypes = {
  backToLanding: PropTypes.func.isRequired,
  resetForm: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired
};

export default TagsDelete;
