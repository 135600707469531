import { GLOBAL_SET_ERROR, GLOBAL_LOGOUT_USER } from 'reducers/reducer';
import { generate } from 'shortid';

export const SERVER_ERROR = Symbol('SERVER_ERROR');
export const CLIENT_ERROR = Symbol('CLIENT_ERROR');

const UNAUTHORIZED = 401;
const NOT_FOUND = 404;
const DEFAULT_SERVER_ERROR = 500;

export const HTTP_ERROR_MAP = {
  400: 'Bad Request',
  [UNAUTHORIZED]: 'Unauthorized',
  402: 'Payment Required',
  403: 'Forbidden',
  [NOT_FOUND]: 'Not Found',
  405: 'Method Not Allowed',
  406: 'Not Acceptable',
  407: 'Proxy Authentication Required',
  408: 'Request Timeout',
  409: 'Conflict',
  410: 'Gone',
  411: 'Length Required',
  // we will update this and bring back the standard error of (Precondition Failed) when the vNext API can do the eTag comparison: https://agconnections.atlassian.net/browse/LDBAPP-716
  412: '(Precondition Failed) - Another user has edited this record, please refresh your page.',
  413: 'Payload Too Large',
  414: 'URI Too Long',
  415: 'Unsupported Media Type',
  416: 'Range Not Satisfiable',
  417: 'Expectation Failed',
  418: "I'm a Teapot",
  419: 'Page Expired',
  421: 'Misdirected Request',
  422: 'Unprocessable Entity',
  423: 'Locked',
  424: 'Failed Dependency',
  425: 'Too Early',
  426: 'Upgrade Required',
  428: 'Precondition Required',
  429: 'Too Many Requests',
  431: 'Request Header Fields Too Large',
  451: 'Unavailable For Legal Reasons',
  498: 'Invalid Token',
  499: 'Token Required',
  500: 'Internal Server Error',
  501: 'Not Implemented',
  502: 'Bad Gateway',
  503: 'Service Unavailable',
  504: 'Gateway Timeout',
  505: 'HTTP Version Not Supported',
  506: 'Variant Also Negotiates',
  507: 'Insufficient Storage',
  508: 'Loop Detected',
  510: 'Not Extended',
  511: 'Network Authentication Required'
};

export const createServerError = (options, error) => {
  const showTryAgain = options.showTryAgain || false;
  const message =
    (error.response && error.response.data && error.response.data.message) ||
    error.message;
  const status =
    (error.response && error.response.status) || DEFAULT_SERVER_ERROR;

  return {
    id: generate(),
    type: SERVER_ERROR,
    showTryAgain,
    message,
    displayMessage:
      message ||
      options.displayMessage ||
      HTTP_ERROR_MAP[status] ||
      'Unrecognized Server Error',
    status,
    hideError: options.hideError ?? false
  };
};

export const parseServerError = (dispatch, options = {}) => error => {
  if (error.response?.status === UNAUTHORIZED) {
    dispatch({
      type: GLOBAL_LOGOUT_USER
    });
  } else if (error.response?.status !== NOT_FOUND || !options.noErrorOn404) {
    dispatch({
      type: GLOBAL_SET_ERROR,
      payload: createServerError(options, error)
    });
  }
};

export const parseToastServerError = (
  toast,
  toastContainer,
  dispatch
) => error => {
  const { title, ...rest } = toast;
  toastContainer.error(title, {
    supportButton: false,
    timeout: 30000,
    ...rest
  });

  if (error) {
    parseServerError(dispatch, { hideError: true })(error);
  }
};

export const parseClientError = dispatch => message => {
  dispatch({
    type: GLOBAL_SET_ERROR,
    payload: { id: generate(), type: CLIENT_ERROR, message }
  });
};
