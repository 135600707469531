import React, { memo } from 'react';
import PropTypes from 'prop-types';

const ArrowDropDownIcon = ({ size = 24, color = '#696F88' }) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      data-testid="checkmark-icon"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7 9.5L12 14.5L17 9.5H7Z"
        fill={color}
      />
    </svg>
  );
};

ArrowDropDownIcon.propTypes = {
  color: PropTypes.string,
  size: PropTypes.number
};

ArrowDropDownIcon.defaultProps = {
  color: '#696F88',
  size: 24
};

export default memo(ArrowDropDownIcon);
