import { Maths } from 'helpers/maths';
import sanitizeAndConvertToNumber from './sanitizeAndConvertToNumber';

const calculateAverageQuantity = ({ quantity, area }) => {
  const sanitizedQuantity = sanitizeAndConvertToNumber(quantity);
  const sanitizedArea = sanitizeAndConvertToNumber(area);
  if (!sanitizedQuantity || !sanitizedArea) return 0;
  return Maths.divideSafeRound(sanitizedQuantity, sanitizedArea, 2);
};

export default calculateAverageQuantity;
