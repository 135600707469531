import React, { useContext, useEffect, useState } from 'react';
import priceIcon from 'assets/price.svg';
import { Context } from 'components/Store';
import FormattedNumber from 'components/FormattedNumber';
import useCropZoneData from 'hooks/useCropZoneData';
import useFarmCost from 'hooks/useFarmCost';
import useFieldCost from 'hooks/useFieldCost';
import getSeasons from 'screens/Yield/components/YieldActions/helpers/getSeasons';

const PropertyContributionMargin = () => {
  const [{ selectedProperty }] = useContext(Context);
  const [totalCost, setTotalCost] = useState(0);
  const [costPerAcre, setCostPerAcre] = useState(0);

  const { getCropZoneCost } = useCropZoneData();
  const { getFarmCost } = useFarmCost();
  const { getFieldCost } = useFieldCost();

  const getCost = async () => {
    const seasonIds = getSeasons();

    if (selectedProperty?.type?.toLowerCase() === 'farm') {
      getFarmCost({ id: selectedProperty?.id, seasonIds }).then(data => {
        setTotalCost(data?.totalCost);
        setCostPerAcre(data?.costPerAcre);
      });
    }
    if (selectedProperty?.type?.toLowerCase() === 'field') {
      getFieldCost({ id: selectedProperty?.id, seasonIds }).then(data => {
        setTotalCost(data?.totalCost);
        setCostPerAcre(data?.costPerAcre);
      });
    }
    if (selectedProperty?.type?.toLowerCase() === 'crop zone') {
      getCropZoneCost({ id: selectedProperty?.id }).then(data => {
        setTotalCost(data?.totalCost);
        setCostPerAcre(data?.costPerAcre);
      });
    }
  };

  useEffect(() => {
    getCost();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedProperty]);

  return (
    <div style={{ top: '64px' }} className="absolute w-full z-50 box-border">
      <div className="flex-1 rounded-lg p-4 m-2 bg-white rounded">
        <div className="flex pb-4 items-center">
          <div
            style={{ width: '40px', height: '40px' }}
            className="rounded bg-neutral-100 flex items-center justify-center"
          >
            <img alt="priceIcon" src={priceIcon} />
          </div>
          <div style={{ marginLeft: '12px' }} className="flex-1">
            <h1 className="text-base font-semibold">Contribution Margin</h1>
            <p className="text-xs">
              An overview of your profits for the selected crop season
            </p>
          </div>
        </div>
        <div className="flex text-sm flex-1 border-b border-neutral-20 py-2">
          <div className="flex-1">Input Costs</div>
          <div className="flex-1 flex justify-end gap-x-1 items-end">
            <span className="text-neutral-1000">
              <FormattedNumber unit="usCurrency" roundedTo={2}>
                {costPerAcre}
              </FormattedNumber>
            </span>
            <span className="text-xs text-neutral-60">/ac</span>
          </div>
          <div className="flex-1 flex justify-end gap-x-1 items-end">
            <span className="text-neutral-1000">
              <FormattedNumber unit="usCurrency" roundedTo={2}>
                {totalCost}
              </FormattedNumber>
            </span>
            <span className="text-xs text-neutral-60">total</span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PropertyContributionMargin;
