import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { Spinner } from '@agconnections/grow-ui';
import useCropData from 'hooks/useCropData';

const CropField = ({ cropId, cropStyle, setCrop }) => {
  const { crops, loading } = useCropData(cropId);
  useEffect(() => {
    if (crops) {
      setCrop(crops);
    }
  }, [crops, setCrop]);
  return (
    <>
      {!loading ? (
        <span className={cropStyle}>{crops?.name}</span>
      ) : (
        <Spinner />
      )}
    </>
  );
};

CropField.defaultProps = {
  setCrop: () => {},
  cropStyle: ''
};

CropField.propTypes = {
  cropId: PropTypes.string.isRequired,
  setCrop: PropTypes.func,
  cropStyle: PropTypes.string
};

export default CropField;
