import usePlans from 'hooks/usePlans';
import useRecommendations from 'hooks/useRecommendations';
import useTasksData from '../../../../screens/Tasks/helpers/useTasksData';
import useInvoiceData from '../../../../screens/Invoices/helpers/useInvoiceData';

const LoadRecs = () => {
  const { recommendations, isLoading: recsLoading } = useRecommendations();
  return { recommendations, recsLoading };
};

const LoadInvoices = () => {
  const { invoices, loading: invLoading } = useInvoiceData();
  return { invoices, invLoading };
};

const LoadTasks = () => {
  const { tasks, loading: taskLoading } = useTasksData();
  return { tasks, taskLoading };
};

const LoadPlans = () => {
  const { plans, isLoading: plansLoading } = usePlans();
  return { plans, plansLoading };
};

const useSources = () => {
  const { plans, plansLoading } = LoadPlans();
  const { tasks, taskLoading } = LoadTasks();
  const { invoices, invLoading } = LoadInvoices();
  const { recommendations, recsLoading } = LoadRecs();

  const isLoading = plansLoading || taskLoading || invLoading || recsLoading;

  return { plans, tasks, invoices, recommendations, isLoading };
};

export default useSources;
