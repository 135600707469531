import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import CheckIcon from '@material-ui/icons/Check';
import ClickableDiv from 'components/ClickableDiv';

const StepCard = ({
  stepNumber,
  caption,
  currentStep,
  canGoNext,
  gotoStep
}) => {
  const validNext = useMemo(() => stepNumber === currentStep + 1 && canGoNext, [
    stepNumber,
    currentStep,
    canGoNext
  ]);

  const bgColor =
    currentStep === stepNumber
      ? 'bg-info-dark-blue text-white'
      : 'bg-white text-gray-500';
  const txtColor =
    currentStep > stepNumber ? 'text-blue-600 border-blue-600' : '';
  const textSize =
    currentStep === stepNumber ? 'text-lg font-semibold' : 'text-neutral-60';
  const borderColor =
    currentStep >= stepNumber ? 'border-info-dark-blue' : 'border-gray-400';

  const hover =
    currentStep > stepNumber || validNext
      ? 'hover:text-info-dark-blue text-neutral-600 cursor-pointer'
      : '';

  const handleClick = () => gotoStep(stepNumber);

  return (
    <ClickableDiv
      className="flex"
      onClick={currentStep > stepNumber || validNext ? handleClick : null}
    >
      <div
        data-testid="progress-bar-number"
        className={`h-auto border rounded-3xl ${
          stepNumber >= currentStep ? '' : 'p-2'
        } ${bgColor} ${txtColor} ${borderColor} mr-2 flex`}
        style={stepNumber >= currentStep ? { padding: '3px 10px' } : {}}
      >
        {stepNumber >= currentStep ? (
          stepNumber
        ) : (
          <CheckIcon style={{ fontSize: 12, color: '#0071CD' }} />
        )}
      </div>
      <div
        data-testid="progress-bar-text"
        className={`flex items-center w-full whitespace-no-wrap  ${hover} ${textSize}`}
      >
        {caption}
      </div>
    </ClickableDiv>
  );
};

StepCard.propTypes = {
  stepNumber: PropTypes.number.isRequired,
  caption: PropTypes.string.isRequired,
  currentStep: PropTypes.number.isRequired,
  canGoNext: PropTypes.bool.isRequired,
  gotoStep: PropTypes.func.isRequired
};

export default StepCard;
