import React from 'react';
import PropTypes from 'prop-types';

const OrganizationIcon = ({ size }) => (
  <svg
    data-testid="organization-icon"
    width={size}
    height={size}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12.6646 2.58577L12.0022 2L11.3397 2.58577L0.845703 12H3H3.86489H4V22H20V12H20.1392H21H23.1584L12.6646 2.58577ZM6 19.9167V10.0767L12.0022 4.66979L18 10.0729V19.9167H13V16H14C15.6569 16 17 14.6569 17 13V11H15C13.3431 11 12 12.3431 12 14C12 12.3431 10.6569 11 9 11H7V13C7 14.6569 8.34315 16 10 16H11V19.9167H6ZM10 14C9.44772 14 9 13.5523 9 13C9.55228 13 10 13.4477 10 14ZM14 14C14.5523 14 15 13.5523 15 13C14.4477 13 14 13.4477 14 14Z"
      fill="#696F88"
    />
  </svg>
);

OrganizationIcon.propTypes = {
  size: PropTypes.number.isRequired
};

export default OrganizationIcon;
