import React from 'react';
import PropTypes from 'prop-types';
import './index.css';

const WarningMessage = ({ text, canRender }) => {
  return canRender ? (
    <p data-testid="warning-message" className="message-text">
      {text}
    </p>
  ) : null;
};

WarningMessage.propTypes = {
  text: PropTypes.string,
  canRender: PropTypes.bool
};

WarningMessage.defaultProps = {
  text: '',
  canRender: false
};

export default WarningMessage;
