import React, { useContext } from 'react';
import { Context } from 'components/Store';
import GrowUIFormField from 'components/Forms/GrowUIFormField';
import { Select } from '@agconnections/grow-ui';
import { irrigationSystems } from '../DataSets';

const IrrigationSystem = () => {
  const [{ isEnableInfoTabEdit }] = useContext(Context);
  return (
    <GrowUIFormField
      control={Select}
      id="irrigationSystem"
      name="irrigationSystem"
      items={irrigationSystems}
      label="Irrigation System"
      disabled={!isEnableInfoTabEdit}
      placeholder="Select irrigation system"
    />
  );
};

export default IrrigationSystem;
