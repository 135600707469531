// eslint-disable-next-line import/prefer-default-export
export const mccain = [
  {
    caption: `Select Crop Seasons`
  },
  {
    caption: 'Select Properties'
  },
  {
    caption: 'Review and Submit'
  }
];
