const LBS_BUSHEL_BY_CROP = {
  Alfalfa: 60,
  'Alfalfa : Alfalfa Seed': 60,
  Barley: 48,
  'Bean : Snap Beans': 25,
  'Clover : ALL': 60,
  Corn: 56,
  'Corn : White': 56,
  'Corn : Yellow': 56,
  'Corn : Earlage': 56,
  'Corn : Indian': 56,
  'Corn : Popcorn': 56,
  'Corn : Sweet': 70,
  'Corn : Sweet, Fresh': 70,
  'Corn : Sweet, Processed': 70,
  'Cotton : ALL': 32,
  Flax: 60,
  'Tall Fescue : ALL': 14,
  'Orchard Grass': 14,
  Timothy: 45,
  Millet: 50,
  Rape: 60,
  Oats: 32,
  Soybeans: 60,
  'Grain Sorghum': 56,
  Rye: 56,
  Rice: 45,
  'Rice : Hybrid': 45,
  'Rice : Wild': 45,
  Sunflower: 24,
  'Sweet Potato': 50,
  'Sweet Potato : Commercial': 50,
  'Sweet Potato : Plant Bed': 50,
  Vetch: 60,
  Wheat: 60,
  'Wheat : Club': 60,
  'Wheat : Dark Northern Spring': 60,
  'Wheat : Durum': 60,
  'Wheat : Hard Red': 60,
  'Wheat : Hard Red Spring': 60,
  'Wheat : Hard Red Winter': 60,
  'Wheat : Hard White': 60,
  'Wheat : Hard White Spring': 60,
  'Wheat : Hard White Winter': 60,
  'Wheat : Soft Red': 60,
  'Wheat : Soft Red Winter': 60,
  'Wheat : Soft White Spring': 60,
  'Wheat : Soft White Winter': 60
};

// eslint-disable-next-line import/prefer-default-export
export function getBushelWeight(fullCropName) {
  if (!fullCropName) {
    return undefined;
  }

  const [crop, subCrop] = fullCropName.split(' : ', 2);
  let key = crop;
  if (subCrop) {
    key += ` : ${subCrop}`;
  }

  // Directly check if the key exists in the cropWeights object
  if (LBS_BUSHEL_BY_CROP[key] !== undefined) {
    return LBS_BUSHEL_BY_CROP[key];
  }

  const allKey = `${crop} : ALL`;
  if (LBS_BUSHEL_BY_CROP[allKey] !== undefined) {
    return LBS_BUSHEL_BY_CROP[allKey];
  }

  // If no specific or "ALL" entry found, return undefined

  return undefined;
}
