import React, { useContext, useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { Button, Drawer } from 'syngenta-digital-cropwise-react-ui-kit';

import { SubmissionSummaryContext } from 'screens/Integrations/SubmissionSummary/context/SubmissionSummaryProvider';
import buildPURExtraDataForSubmit from 'screens/Integrations/CalAgIntegration/helpers/extraData';
import Close from 'assets/close.svg';
import useSubmissionAnalytics from 'screens/Integrations/hooks/useSubmissionAnalytics';
import { INTEGRATIONS } from 'screens/Integrations/helpers/constants';
import SubmissionDetails from './components/SubmissionDetails';
import SubmissionCounties from './components/SubmissionCounties';

const CalAgCountiesDrawer = ({ forms }) => {
  const { events, triggerAnalyticsEvent } = useSubmissionAnalytics();
  const {
    state: { openDrawerCounties },
    submitIntegrationWithExtraData,
    setOpenDrawerCounties
  } = useContext(SubmissionSummaryContext);

  const [counties, setCounties] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (forms.length && !counties.length) {
      // First render forms comes clear
      setCounties(
        [
          ...new Set(
            forms
              .filter(form => form.propertyDetails)
              .map(form => form.propertyDetails.county)
          )
        ].map(county => ({
          name: county,
          username: '',
          password: ''
        }))
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [forms]);

  const [step, setStep] = useState(1);

  const onClose = () => {
    setOpenDrawerCounties(false);
  };

  const allCountiesFilled = useMemo(
    () => counties.every(county => !!county.username && !!county.password),
    [counties]
  );

  const submitForm = async () => {
    if (step === 1) {
      triggerAnalyticsEvent(
        events.epic.Integrations.submissionRegulatoryDataReview,
        {
          vendor: INTEGRATIONS.calAgPermits
        }
      );
      setStep(2);
      return;
    }

    setIsLoading(true);
    triggerAnalyticsEvent(
      events.epic.Integrations.submissionCredentialsFilled,
      {
        vendor: INTEGRATIONS.calAgPermits
      }
    );
    triggerAnalyticsEvent(events.epic.Integrations.submissionRunning, {
      vendor: INTEGRATIONS.calAgPermits
    });
    await submitIntegrationWithExtraData(
      buildPURExtraDataForSubmit(forms, counties)
    );
    setIsLoading(false);
  };

  const submitDisabled =
    !forms.length || (step !== 1 && !allCountiesFilled) || isLoading;

  return (
    <Drawer
      maskClosable
      open={openDrawerCounties}
      className="cwf-submit-counties-drawer"
      placement="right"
      closable={false}
      onClose={() => {
        onClose();
      }}
      bodyStyle={{ padding: 0 }}
      footer={
        <div className="flex justify-between">
          <Button
            type="outline"
            onClick={() => {
              onClose();
            }}
          >
            Cancel
          </Button>
          <Button
            disabled={submitDisabled}
            className="owner-add-save-button"
            type="primary"
            onClick={submitForm}
          >
            {step === 1 ? 'Next' : 'Complete Submission'}
          </Button>
        </div>
      }
      width="35%"
    >
      <div className="relative">
        <div className="absolute top-0 right-0 m-3">
          <button
            type="button"
            onClick={() => {
              onClose();
            }}
          >
            <img src={Close} alt="Close icon" width={20} height={20} />
          </button>
        </div>
      </div>
      <div className="px-10 py-10">
        <div
          className="flex gap-4 items-center mb-6"
          data-testid="owner-container"
        >
          <h1 className="text-xl font-semibold block mb-0">
            Submit your pesticide usage reports
          </h1>
        </div>
        {step === 1 && <SubmissionDetails forms={forms} counties={counties} />}
        {step === 2 && (
          <SubmissionCounties
            counties={counties}
            setCounties={setCounties}
            isLoading={isLoading}
          />
        )}
      </div>
    </Drawer>
  );
};

CalAgCountiesDrawer.propTypes = {
  forms: PropTypes.arrayOf(
    PropTypes.shape({
      task: PropTypes.shape({
        products: PropTypes.arrayOf(
          PropTypes.shape({
            productName: PropTypes.string.isRequired
          })
        ).isRequired
      }).isRequired,
      propertyDetails: PropTypes.shape({
        county: PropTypes.string.isRequired
      })
    })
  ).isRequired
};

export default CalAgCountiesDrawer;
