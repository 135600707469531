import React, { useContext } from 'react';
import { Radio } from 'syngenta-digital-cropwise-react-ui-kit';
import { Context } from 'components/Store';
import createAction from 'helpers/createAction';
import { SET_PROPERTY_MAP_FIELDS_CONFIG } from 'reducers/reducer';
import { PROPERTY_MAP_FIELDS_OPTIONS } from 'screens/Property/helpers/constants';
import CollapsibleSection from '../CollapsibleSection';

const BackgroundFieldsSection = () => {
  const [{ propertyMapFieldsConfig }, dispatch] = useContext(Context);

  const handleRadioChange = e => {
    createAction(dispatch, SET_PROPERTY_MAP_FIELDS_CONFIG, e.target.value);
  };

  return (
    <CollapsibleSection title="Background fields">
      <div className="px-4 pb-6">
        <label htmlFor="backgroundFieldsOptions" className="text-neutral-60">
          For fields not selected:
        </label>
        <Radio.Group
          name="backgroundFieldsOptions"
          className="mt-1"
          value={propertyMapFieldsConfig}
          onChange={handleRadioChange}
        >
          <Radio.Button value={PROPERTY_MAP_FIELDS_OPTIONS.HIDE}>
            Hide from view
          </Radio.Button>
          <Radio.Button value={PROPERTY_MAP_FIELDS_OPTIONS.SHOW_BOUNDARIES}>
            Display boundaries
          </Radio.Button>
          <Radio.Button
            value={PROPERTY_MAP_FIELDS_OPTIONS.SHOW_BOUNDARIES_AND_LABELS}
          >
            Display boundaries and labels
          </Radio.Button>
        </Radio.Group>
      </div>
    </CollapsibleSection>
  );
};

export default BackgroundFieldsSection;
