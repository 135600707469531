import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { generate as genShortId } from 'shortid';
import useTwoWayState from '../../hooks/useTwoWayState';

const ToggleButton = ({ id, name, onChange, defaultChecked, color, big }) => {
  const [position, setPosition, controlledSetPosition] = useTwoWayState(
    defaultChecked
  );
  const _onChange = event => {
    setPosition(event.target.checked);
    onChange(event);
  };
  useEffect(() => {
    controlledSetPosition(defaultChecked);
  }, [defaultChecked, controlledSetPosition]);

  // if the color is #RRGGBB then use style, otherwise use the tailwind class
  const backgroundColor =
    color.includes('#') && position ? { backgroundColor: color } : {};

  const leftPosition = () => {
    if (!position) return '0px';
    if (big) return '25px';
    return '16px';
  };

  return (
    <div data-testid="toggle-button">
      <div className="flex">
        <label htmlFor={name} className="flex cursor-pointer">
          <div className="relative">
            <input
              data-testid="toggle-button-input"
              name={name}
              id={id}
              type="checkbox"
              className="hidden"
              onChange={_onChange}
              checked={position}
            />
            <div
              style={{ height: big ? '24px' : '16px', ...backgroundColor }}
              className={`${
                big ? 'w-12' : 'w-8'
              } bg-gray-400 rounded-full shadow-inner relative ${
                position ? `bg-${color}` : 'bg-gray-400'
              }`}
            >
              <div
                style={{
                  height: big ? '21px' : '14px',
                  width: big ? '21px' : '14px',
                  left: leftPosition(),
                  margin: '1px'
                }}
                className={
                  `absolute ${big ? 'w-8 h-8' : 'w-4 h-4'} ` +
                  `bg-white rounded-full shadow inset-y-0 transition-all duration-300`
                }
              />
            </div>
          </div>
        </label>
      </div>
    </div>
  );
};

ToggleButton.propTypes = {
  defaultChecked: PropTypes.bool,
  id: PropTypes.string,
  name: PropTypes.string,
  onChange: PropTypes.func,
  color: PropTypes.string,
  big: PropTypes.bool
};

ToggleButton.defaultProps = {
  defaultChecked: false,
  id: `toggle-${genShortId()}`,
  name: `toggle-${genShortId()}`,
  onChange: undefined,
  color: 'cucumber-green-500',
  big: false
};

export default ToggleButton;
