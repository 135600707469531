import { getConvertUnitFromTo } from 'helpers/unitConversionHelpers';

export const getPlanFixedCost = value => {
  const fixedCost =
    /* eslint-disable camelcase */
    Number(value?.fixedCost?.equipment) +
    Number(value?.fixedCost?.insurance) +
    Number(value?.fixedCost?.labor) +
    Number(value?.fixedCost?.landRent) +
    Number(value?.fixedCost?.repairs) +
    Number(value?.fixedCost?.returnToMgt) +
    Number(value?.fixedCost?.taxes);
  return fixedCost * value?.estimatedAreaValue;
};

export const getYieldTotal = value => {
  const YPA = Number(value?.projectedRevenue?.yieldPerArea) || 0;
  const PPU = Number(value?.projectedRevenue?.pricePerUnit || 0);
  const EAV = Number(value?.estimatedAreaValue) || 1;
  const result = YPA * PPU * EAV;
  return result;
};

export const getYieldTotalPerAc = value => {
  const YT = getYieldTotal(value);
  const EAV = Number(value?.estimatedAreaValue) || 1;
  return YT / EAV;
};

export const getVarCost = value => {
  return value?.planProductEntries
    ?.map(products => products?.totalProductCost)
    .reduce((prev, curr) => prev + curr, 0);
};

export const getPlanProjNetRevenue = value => {
  const {
    cropSharePercent = 1,
    pricePerUnit = 0,
    fsaPayment = 0,
    yieldPerArea = 0
  } = value?.projectedRevenue || {};

  const yieldTotal = yieldPerArea * pricePerUnit * value?.estimatedAreaValue;

  const YPA = Number(yieldTotal) || 0;
  const FSAP = Number(fsaPayment);
  const CP = Number(cropSharePercent);
  const tpjRevenue = (YPA + FSAP) * CP;
  const fixCost = getPlanFixedCost(value) || 0;
  const varCost = getVarCost(value) || 0;

  return tpjRevenue - fixCost - varCost;
};

export const getPlanProjNetRevenuePerArea = value => {
  const tpjRevenue = getPlanProjNetRevenue(value) || 0;
  return tpjRevenue / value?.estimatedAreaValue;
};

export const getProductTotalMath = (rateValue, areaValue, product) => {
  // verify on rebuild
  let result = rateValue * areaValue;
  if (product?.productRateUnit !== product?.totalProductUnit) {
    const conversion = getConvertUnitFromTo(
      Number.parseFloat(product.productRateValue * product.areaValue),
      product.productRateUnit,
      product?.totalProductUnit,
      product
    );
    result = Number.parseFloat(conversion.Value || conversion);
  }

  if (result > 0 && result !== Infinity) {
    return result.toFixed(2);
  }
  return '0.00';
};
