import React, { useContext, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Col, Row } from 'syngenta-digital-cropwise-react-ui-kit';
import { Input } from '@agconnections/grow-ui';
import { useFormikContext } from 'formik';
import ProductTypeDisplay from 'screens/ProductList/helpers/ProductTypeDisplay';
import { getAbbreviatedUnit } from 'helpers/unitsAbbreviation';
import GrowUIFormField from 'components/Forms/GrowUIFormField';
import './index.css';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { Context } from 'components/Store';
import { getConnectIntegrations } from 'screens/Integrations/helpers/processors';
// eslint-disable-next-line import/no-cycle
import { handleValidationAlertIcon } from '../CustomProductListForm';

const formRowClass = 'mb-6';
const formLabelClass = 'text-neutral-60';
const formValueClass = 'h-6 text-base leading-6';

const MasterListProductForm = ({ product }) => {
  const [{ organization }] = useContext(Context);
  const flags = useFlags();

  const hasConnectIntegration = getConnectIntegrations(flags, organization);

  const { values, setValues, setFieldValue, errors } = useFormikContext();
  const handleFieldChange = e => {
    setFieldValue(e.target.name, e.target.value);
  };

  const formatListPrice = e => {
    const formattedListPrice = parseFloat(e.target.value).toFixed(2);
    setFieldValue(
      'averagePricePerUnit',
      isNaN(formattedListPrice) ? '' : formattedListPrice
    );
  };

  useEffect(() => {
    setValues(product);
  }, [setValues, product]);

  const isSeedProduct = [product.producttype, product.productType].includes(
    'Seed'
  );

  return (
    <>
      <Row className={formRowClass} gutter={32}>
        <Col span={12}>
          <div className={formLabelClass}>Product Name</div>
          <div className={formValueClass}>
            {values.productName ?? values.name ?? '---'}
          </div>
        </Col>
        <Col span={12}>
          <div className={formLabelClass}>Manufacturer</div>
          <div className={formValueClass}>
            {values.manufacturerName ?? values.manufacturer ?? '---'}
          </div>
        </Col>
      </Row>
      <Row className={formRowClass} gutter={32}>
        <Col span={12}>
          <div className={formLabelClass}>Product Type</div>
          <Row className={formValueClass}>
            <ProductTypeDisplay
              productType={values.productType ?? values.producttype ?? '---'}
            />
          </Row>
        </Col>
        {!isSeedProduct && (
          <Col span={12}>
            <div className={formLabelClass}>EPA Number</div>
            <div className={formValueClass}>
              {values.registrationNumber?.length ||
              values.registrationnumber?.length
                ? values.registrationNumber ?? values.registrationnumber
                : '---'}
            </div>
          </Col>
        )}
      </Row>
      <Row className={formRowClass} gutter={32}>
        <Col span={12}>
          <div className={formLabelClass}>List Price</div>
          <GrowUIFormField
            style={{ borderRight: 'none', borderLeft: 'none' }}
            control={Input}
            name="averagePricePerUnit"
            value={
              values.averagePricePerUnit === 0
                ? undefined
                : values.averagePricePerUnit
            }
            onChange={handleFieldChange}
            onBlur={formatListPrice}
            attachLeft={<div>$</div>}
            attachRight={
              <div
                style={{ backgroundColor: 'white', borderLeft: 'none' }}
                className="flex w-fit mr-10px"
              >
                <span className="mr-1">
                  per {getAbbreviatedUnit(values.stdUnit ?? values.stdunit)}
                </span>
                {handleValidationAlertIcon(errors, 'averagePricePerUnit')}
              </div>
            }
          />
        </Col>
        <Col span={12}>
          <div className={formLabelClass}>Standard Unit</div>
          <div className={formValueClass}>
            {values.stdUnit ? values.stdUnit : values.stdunit}
          </div>
        </Col>
      </Row>
      {isSeedProduct && hasConnectIntegration && (
        <Row
          data-testid="package-weight-row"
          className={formRowClass}
          gutter={32}
        >
          <Col span={12}>
            <div className={formLabelClass}>Package Weight Density</div>
            <GrowUIFormField
              control={Input}
              name="packageWeight"
              value={
                values.packageWeight === 0 ? undefined : values.packageWeight
              }
              onChange={handleFieldChange}
              attachRight={
                <div
                  style={{ backgroundColor: 'white', borderLeft: 'none' }}
                  className="flex w-fit mr-10px"
                >
                  <span className="mr-1">seeds per lbs</span>
                  {handleValidationAlertIcon(errors, 'packageWeight')}
                </div>
              }
            />
          </Col>

          <Col span={12}>
            <div className={formLabelClass}>Standard Package Unit</div>
            <div data-testid="standard-package-unit" className={formValueClass}>
              {values.stdPackageUnit ?? values.stdpackageunit}
            </div>
          </Col>
        </Row>
      )}
    </>
  );
};

MasterListProductForm.propTypes = {
  product: PropTypes.shape().isRequired
};

export default MasterListProductForm;
