import React from 'react';
import PropTypes from 'prop-types';
import CommoditySelector from './components/CommoditySelector';
import AddYieldButton from '../AddYieldButton';

const YieldSummaryHeader = ({
  disabled,
  cropItems,
  selectedCommodity,
  onSelectCommodity
}) => {
  return (
    <>
      <div
        className="flex flex-row justify-between"
        style={{ marginBottom: '28px', marginTop: '31px' }}
      >
        <div className="flex flex-row items-center flex-1">
          <span className="mr-2 text-2xl" style={{ marginRight: '26.87px' }}>
            Commodity Yield
          </span>
          {!disabled && (
            <CommoditySelector
              cropzones={cropItems}
              value={selectedCommodity}
              onChange={onSelectCommodity}
            />
          )}
        </div>
        <AddYieldButton disabled={disabled} />
      </div>
    </>
  );
};

YieldSummaryHeader.propTypes = {
  disabled: PropTypes.bool,
  cropItems: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  selectedCommodity: PropTypes.shape(),
  onSelectCommodity: PropTypes.func
};

YieldSummaryHeader.defaultProps = {
  disabled: false,
  selectedCommodity: undefined,
  onSelectCommodity: () => {}
};

export default YieldSummaryHeader;
