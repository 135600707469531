import { useState, useEffect, useCallback } from 'react';
import axios from 'axios';
import catchCancel from 'helpers/catchCancel';
import { CROPWISE_PROXY_V2_URL } from 'utilities/apiConstants';
import { getAccessToken } from 'utilities/base-auth';

const useTotalYield = ({ cropSeasonId, cropId }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [totalYieldData, setTotalYieldData] = useState(null);

  const getCropsWithLoads = useCallback(() => {
    if (!cropSeasonId || !cropId) return;

    setIsLoading(true);
    const orgId = localStorage.getItem('selectedOrganizationId');

    const promise = axios.get(
      `${CROPWISE_PROXY_V2_URL}/yield/summary/total-yield?cropId=${cropId}&cropSeasonId=${cropSeasonId}`,
      {
        headers: {
          'Content-Type': 'application/json',
          'cwf-context': JSON.stringify({
            organization_id: orgId
          }),
          Authorization: `Bearer ${getAccessToken()}`
        }
      }
    );

    promise
      .then(({ data }) => {
        setTotalYieldData(data);
      })
      .catch(catchCancel)
      .finally(() => setIsLoading(false));
  }, [cropSeasonId, cropId]);

  useEffect(() => {
    getCropsWithLoads();
  }, [getCropsWithLoads]);

  return {
    isLoading,
    totalYieldData
  };
};

export default useTotalYield;
