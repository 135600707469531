import React from 'react';
import { Form, Input } from '@agconnections/grow-ui';
import GrowUIFormField from 'components/Forms/GrowUIFormField';

const PhoneNumberField = () => {
  return (
    <Form.Field label="Phone Number">
      <GrowUIFormField control={Input} id="phone" name="phone" />
    </Form.Field>
  );
};

export default PhoneNumberField;
