import React, { useEffect } from 'react';
import GrowUIFormField from 'components/Forms/GrowUIFormField';
import { useFormikContext } from 'formik';
import { Input } from '@agconnections/grow-ui';
import { unmaskPriceYield } from 'screens/Yield/helpers/calculateYield';
import { getUnitValue, Imask } from 'screens/Yield/helpers/infoSectionData';

const EmptyInvisibleField = () => <div className="w-1/4 mr-8"> </div>;

const SaleInfoSection = () => {
  const { values, setFieldValue, setFieldTouched } = useFormikContext();

  useEffect(() => {
    if (unmaskPriceYield(values.grossRevenue) && values.totalYield) {
      const salePrice =
        unmaskPriceYield(values.grossRevenue) / values.totalYield;
      setFieldValue('salePrice', parseFloat(salePrice.toFixed(2)));
      setFieldValue(
        'grossRevenue',
        parseFloat(salePrice.toFixed(2)) * values.totalYield
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values.totalYield]);

  return (
    <div className="py-4 px-8">
      <div
        data-testid="yield-sale-info-title"
        className="text-2xl font-semibold py-2 mb-10"
      >
        Sale Info
      </div>
      <div className="flex items-center">
        <div className="w-1/4 mr-8">
          <GrowUIFormField
            control={Input}
            label="Sale Price"
            name="salePrice"
            imask={Imask}
            onBlur={e => {
              setFieldTouched('salePrice');
              setFieldValue(
                'salePrice',
                unmaskPriceYield(e.target.value || '0')
              );
              if (unmaskPriceYield(e.target.value)) {
                setFieldValue(
                  'grossRevenue',
                  parseFloat(
                    unmaskPriceYield(e.target.value) * values?.totalYield
                  ).toFixed(2)
                );
              } else {
                setFieldValue('grossRevenue', unmaskPriceYield(e.target.value));
              }
            }}
          />
          <span className="float-right px-2 -my-7 p-1px relative text-neutral-600 text-sm">
            per {getUnitValue(values.unit)}
          </span>
        </div>
        <div className="w-1/4 mr-8">
          <GrowUIFormField
            control={Input}
            name="grossRevenue"
            label="Gross Revenue"
            imask={Imask}
            onBlur={e => {
              setFieldTouched('grossRevenue');
              setFieldValue('grossRevenue', unmaskPriceYield(e.target.value));
              if (unmaskPriceYield(e.target.value)) {
                setFieldValue(
                  'salePrice',
                  parseFloat(
                    unmaskPriceYield(e.target.value) / values?.totalYield
                  ).toFixed(2)
                );
              } else {
                setFieldValue('salePrice', unmaskPriceYield(e.target.value));
              }
            }}
          />
        </div>
        <EmptyInvisibleField />
        <EmptyInvisibleField />
      </div>
    </div>
  );
};

export default SaleInfoSection;
