import React from 'react';
import NoProductIcon from 'assets/no_products_found.svg';

const NoProductsFound = () => {
  return (
    <div className="flex flex-col justify-center items-center pb-8">
      <img className="px-8 py-8" src={NoProductIcon} alt="no-product-icon" />
      <h2 className="font-semibold font-md ">No Products Found</h2>
    </div>
  );
};

export default NoProductsFound;
