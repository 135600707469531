import React from 'react';
import PropTypes from 'prop-types';
import BasicInfoSection from '../BasicInfoSection';
import WeightSection from '../WeightSection';
import OtherSection from '../OtherSection';
import SaleSection from '../SaleSection';
import YieldSectionContainer from '../YieldSectionContainer';

const DefaultAllCropsTemplate = ({ selectedCropName }) => {
  return (
    <div className="flex flex-col gap-8">
      <BasicInfoSection />
      <YieldSectionContainer selectedCropName={selectedCropName} />
      <SaleSection />
      <WeightSection />

      <OtherSection />
    </div>
  );
};

DefaultAllCropsTemplate.propTypes = {
  selectedCropName: PropTypes.string.isRequired
};

export default DefaultAllCropsTemplate;
