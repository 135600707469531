import React from 'react';
import PropTypes from 'prop-types';
import { Spinner } from '@agconnections/grow-ui';
import ViewOnlyField from 'components/ViewOnlyField';
import useCropSeasonData from 'hooks/useCropSeasonData';

const CropSeasonField = ({ cropseasonId }) => {
  const { cropSeason, loading } = useCropSeasonData(cropseasonId);

  return (
    <>
      <div className="w-1/3">
        <ViewOnlyField header="Crop Seasons" className="w-1/3" text="text-lg" />
        {!loading ? (
          <span className="text-2xl w-1/3">{cropSeason?.name || '--'}</span>
        ) : (
          <Spinner />
        )}
      </div>
    </>
  );
};

CropSeasonField.propTypes = {
  cropseasonId: PropTypes.string.isRequired
};

export default CropSeasonField;
