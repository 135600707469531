import React, { useContext, useEffect, useState } from 'react';

import events from 'utilities/amplitude/events';

import { useLocation } from 'react-router-dom';
import SimplotLogo from 'assets/integrations/SimplotLogo.svg';
import { Context } from 'components/Store';
import { parseServerError } from 'helpers/errorHelpers';
import { getAccessToken } from 'utilities/base-auth';
import { SimplotContext } from 'screens/Integrations/SimplotIntegration/context/Provider';
import { INTEGRATIONS } from 'screens/Integrations/helpers/constants';
import useSubmissionAnalytics from 'screens/Integrations/hooks/useSubmissionAnalytics';
import { IntegrationsContext } from 'screens/Integrations/context/IntegrationsProvider';
import LoadingModal from 'components/Modals/LoadingModal';
import useSimplotOrganizations from '../../../../../hooks/useSimplotOrganizations';

const SimplotAuthenticationPage = () => {
  const {
    state: { submissionFlowId }
  } = useContext(IntegrationsContext);
  const [, dispatch] = useContext(Context);

  const { triggerAnalyticsEvent } = useSubmissionAnalytics({
    onMountTriggerEvent: {
      event: events.epic.Integrations.submissionStarted,
      data: { vendor: INTEGRATIONS.simplot }
    }
  });

  const [loadingOrgAuth, setLoadingOrgAuth] = useState(false);
  const [loadingAuthCheck, setLoadingAuthCheck] = useState(false);
  const [redirectingToExternalAuth, setRedirectingToExternalAuth] = useState(
    false
  );

  const location = useLocation();

  const [{ organization }] = useContext(Context);

  const {
    goForward,
    simplotOauthUrl,
    redirectToSimplotAuthorizeOrgUrl
  } = useContext(SimplotContext);

  const hideServerErrors = true;
  const { fetchSimplotOrganizations } = useSimplotOrganizations(
    hideServerErrors
  );

  useEffect(() => {
    async function checkIfIsAuthenticated() {
      try {
        setLoadingAuthCheck(true);
        const organizations = await fetchSimplotOrganizations();
        if (organizations?.length > 0) {
          triggerAnalyticsEvent(
            events.epic.Integrations.submissionCredentialsFilled,
            { vendor: INTEGRATIONS.simplot, flowId: submissionFlowId }
          );
          goForward();
        }
      } catch (e) {
        setLoadingAuthCheck(false);
        setRedirectingToExternalAuth(true);
        window.location.href = simplotOauthUrl;
      }
    }

    const oauthCode = new URLSearchParams(location.search).get('code');
    if (!oauthCode && submissionFlowId) {
      checkIfIsAuthenticated();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.search, submissionFlowId]);

  useEffect(() => {
    const oauthCode = new URLSearchParams(location.search).get('code');
    if (oauthCode) {
      setLoadingAuthCheck(false);
      setLoadingOrgAuth(true);
      const apiHost =
        process.env.REACT_APP_CROPWISE_PROXY_V2_URL || 'http://localhost:4000';
      fetch(`${apiHost}/integrations/simplot/oauth-code`, {
        method: 'POST',
        headers: {
          Authorization: `Bearer ${getAccessToken()}`,
          'cwf-context': JSON.stringify({ organization_id: organization.id }),
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          code: oauthCode
        })
      }).then(response => {
        if (!response.ok) {
          setLoadingOrgAuth(false);
          parseServerError(dispatch)(response);
          return;
        }
        triggerAnalyticsEvent(
          events.epic.Integrations.submissionCredentialsFilled,
          { vendor: INTEGRATIONS.simplot, flowId: submissionFlowId }
        );
        redirectToSimplotAuthorizeOrgUrl();
      });
    }
    // eslint-disable-next-line
  }, [location.search]);

  return (
    <div className="flex flex-col w-full items-start">
      <div className="mt-3 w-full flex gap-5 items-center rounded-lg p-4 shadow-md bg-white">
        <div className="bg-white rounded-xl border p-6">
          <img src={SimplotLogo} alt="" />
        </div>
        <div className="flex flex-col justify-center">
          <h3 className="font-bold text-2xl">Simplot Processor Integration</h3>
          <h2>By Simplot</h2>
        </div>
        {loadingAuthCheck && (
          <LoadingModal
            isLoading
            message="Checking if you are already logged in..."
            data-testid="spinner-check-logged-in"
          />
        )}
        {loadingOrgAuth && (
          <LoadingModal
            isLoading={loadingOrgAuth}
            message="Redirecting to Authorize your Organizations..."
            data-testid="spinner-authorize-orgs"
          />
        )}
        {redirectingToExternalAuth && (
          <LoadingModal
            isLoading
            message="Redirecting to integration authentication page..."
            data-testid="spinner-redirect-integration-login"
          />
        )}
      </div>
    </div>
  );
};

export default SimplotAuthenticationPage;
