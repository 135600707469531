import React from 'react';
import { Column, Columns, Input } from '@agconnections/grow-ui';
import { useFormikContext } from 'formik';

const ChemicalElementsSection = () => {
  const { setFieldValue } = useFormikContext();
  return (
    <div name="ChemicalSection" data-testid="product-chemical-section">
      <div className="mt-12 font-bold text-lg">Major</div>
      <div className="mt-12">
        <Columns wrap>
          <Column width="w-1/4">
            <div className="pr-2">
              <Input
                id="n-input"
                name="formulation.N"
                label="N"
                placeholder="0%"
                onChange={e => {
                  setFieldValue('formulation.N', e.target.value);
                }}
              />
            </div>
          </Column>
          <Column width="w-1/4">
            <div className="pl-2 pr-2">
              <Input
                id="p-input"
                name="formulation.P"
                label="P"
                placeholder="0%"
                onChange={e => {
                  setFieldValue('formulation.P', e.target.value);
                }}
              />
            </div>
          </Column>
          <Column width="w-1/4">
            <div className="pl-2">
              <Input
                id="k-input"
                name="formulation.K"
                label="K"
                placeholder="0%"
                onChange={e => {
                  setFieldValue('formulation.K', e.target.value);
                }}
              />
            </div>
          </Column>
        </Columns>
      </div>
      <div className="mt-12 font-bold text-lg">Minor</div>
      <div className="mt-12">
        <Columns wrap>
          <Column width="w-1/4">
            <div className="pr-2">
              <Input
                id="c-ainput"
                name="formulation.Ca"
                label="Ca"
                placeholder="0%"
                onChange={e => {
                  setFieldValue('formulation.Ca', e.target.value);
                }}
              />
            </div>
          </Column>
          <Column width="w-1/4">
            <div className="pl-2 pr-2">
              <Input
                id="mg-input"
                name="formulation.Mg"
                label="Mg"
                placeholder="0%"
                onChange={e => {
                  setFieldValue('formulation.Mg', e.target.value);
                }}
              />
            </div>
          </Column>
          <Column width="w-1/4">
            <div className="pl-2">
              <Input
                id="s-input"
                name="formulation.S"
                label="S"
                placeholder="0%"
                onChange={e => {
                  setFieldValue('formulation.S', e.target.value);
                }}
              />
            </div>
          </Column>
        </Columns>
      </div>
      <div className="mt-12 font-bold text-lg">Micro</div>
      <div className="mt-12">
        <Columns wrap>
          <Column width="w-1/4">
            <div className="pr-2">
              <Input
                id="fe-input"
                name="formulation.Fe"
                label="Fe"
                placeholder="0%"
                onChange={e => {
                  setFieldValue('formulation.Fe', e.target.value);
                }}
              />
            </div>
          </Column>
          <Column width="w-1/4">
            <div className="pl-2 pr-2">
              <Input
                id="cu-input"
                name="formulation.Cu"
                label="Cu"
                placeholder="0%"
                onChange={e => {
                  setFieldValue('formulation.Cu', e.target.value);
                }}
              />
            </div>
          </Column>
          <Column width="w-1/4">
            <div className="pl-2">
              <Input
                id="mn-input"
                name="formulation.Mn"
                label="Mn"
                placeholder="0%"
                onChange={e => {
                  setFieldValue('formulation.Mn', e.target.value);
                }}
              />
            </div>
          </Column>
          <Column width="w-1/4">
            <div className="pl-2">
              <Input
                id="zn-input"
                name="formulation.Zn"
                label="Zn"
                placeholder="0%"
                onChange={e => {
                  setFieldValue('formulation.Zn', e.target.value);
                }}
              />
            </div>
          </Column>
        </Columns>
      </div>
      <div className="mt-12">
        <Columns wrap>
          <Column width="w-1/4">
            <div className="pr-2">
              <Input
                id="b-input"
                name="formulation.B"
                label="B"
                placeholder="0%"
                onChange={e => {
                  setFieldValue('formulation.B', e.target.value);
                }}
              />
            </div>
          </Column>
          <Column width="w-1/4">
            <div className="pl-2 pr-2">
              <Input
                id="mo-input"
                name="formulation.Mo"
                label="Mo"
                placeholder="0%"
                onChange={e => {
                  setFieldValue('formulation.Mo', e.target.value);
                }}
              />
            </div>
          </Column>
          <Column width="w-1/4">
            <div className="pl-2">
              <Input
                id="cl-input"
                name="formulation.Cl"
                label="Cl"
                placeholder="0%"
                onChange={e => {
                  setFieldValue('formulation.Cl', e.target.value);
                }}
              />
            </div>
          </Column>
        </Columns>
      </div>
    </div>
  );
};
export default ChemicalElementsSection;
