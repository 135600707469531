export const DISPLAY_PROPERTIES = [
  'type',
  'properties',
  'id',
  'layer',
  'source',
  'sourceLayer',
  'state'
];

export const MIN_ZOOM = 12.25;
export const FETCH_RADIUS = 1;
