import React, { useCallback, useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useFormikContext } from 'formik';
import { Spinner } from '@agconnections/grow-ui';
import RemoveTaskModal from 'screens/Integrations/SubmissionSummary/components/RemoveTaskCropzoneModal';
import useSubmissionAnalytics from 'screens/Integrations/hooks/useSubmissionAnalytics';
import SubmitHeader from 'screens/Integrations/SubmissionSummary/components/TopHeader/components/SubmitHeader';
import {
  INTEGRATIONS,
  SubmissionStatus
} from 'screens/Integrations/helpers/constants';
import { SubmissionSummaryContext } from '../../../SubmissionSummary/context/SubmissionSummaryProvider';
import CalAgFormAccordion from './components/CalAgFormAccordion';
import returnApplicationMethodLabel, {
  definedCalAgGroups,
  groupTasksByProductsApplicationMethod
} from '../../helpers/products';
import CalAgCountiesDrawer from './components/CalAgCountiesDrawer';
import SummaryTag from './components/SummaryTag';
import { translateMethodsToCalAg } from '../../helpers/extraData';

const CalAgReviewAndSubmit = ({ FooterComponent, loading }) => {
  const { events, triggerAnalyticsEvent } = useSubmissionAnalytics();

  const {
    state: {
      tasks,
      fields,
      extraData,
      isReadOnly,
      submissionResult,
      openDrawerCounties,
      pastSubmissions
    },
    isLoading,
    setOpenDrawerCounties
  } = useContext(SubmissionSummaryContext);

  const { values, setValues } = useFormikContext();
  const [selectedId, setSelectedId] = useState('');
  const [forms, setForms] = useState([]);
  const [needInfoTask, setNeedInfoTask] = useState(0);

  const validateMandatoryAttributesForm = form => {
    const mappedKeys = new Map(Object.entries(form));
    const requiredFields = [
      'task',
      'propertyDetails',
      'formId',
      'applicatorType'
    ];
    if (!requiredFields.every(key => mappedKeys.get(key))) {
      return false;
    }

    if (form.applicatorType === 'pco') {
      const requiredPcoFields = ['pco', 'pcoType', 'pcoNumber'];
      if (!requiredPcoFields.every(key => mappedKeys.get(key))) {
        return false;
      }
    }

    if (form.task.products[0].applicationMethod === 'Fumigation') {
      const requiredFumigationFields = ['fumigationCode'];
      if (!requiredFumigationFields.every(key => mappedKeys.get(key))) {
        return false;
      }
    }

    return true;
  };

  useEffect(() => {
    setNeedInfoTask(forms.filter(f => f.status === 'need_info').length);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [forms]);

  const applyExtraDataFields = useCallback(
    (currentForm, formId) => {
      if (!extraData) {
        return currentForm;
      }

      let formWithChanges = {
        ...currentForm
      };
      const { forms: submittedForms } = extraData.purExtraData;
      const submittedForm = submittedForms.find(
        f =>
          formId ===
          `form-task-${f.taskId}-${f.cropZoneId}-${f.applicationMethod}`
      );
      if (submittedForm) {
        formWithChanges = {
          ...formWithChanges,
          ...submittedForm,
          applicatorType: submittedForm.applicator === 1 ? 'grower' : 'pco',
          pco: {
            id: submittedForm.applicatorId,
            name: submittedForm.applicatorName,
            type: submittedForm.applicatorType
          },
          pcoType: submittedForm.applicatorLicenseType,
          pcoNumber: submittedForm.applicatorLicenseNumber
        };
      }

      if (!isReadOnly) {
        formWithChanges.status = validateMandatoryAttributesForm(
          formWithChanges
        )
          ? 'complete'
          : 'need_info';
      }
      return formWithChanges;
    },
    [extraData, isReadOnly]
  );

  const transformTaskToForm = task => {
    if (task?.taskId) {
      const applicationMethodsInTask = returnApplicationMethodLabel(
        task.products
      );
      const calAgApplicationMethod = translateMethodsToCalAg(
        applicationMethodsInTask.split(', ')
      );
      const formId = `form-task-${task.taskId}-${task.cropZoneId}-${calAgApplicationMethod}`;
      let currentForm;
      if (forms) {
        currentForm = forms.find(form => form.formId === formId);
      }
      currentForm = applyExtraDataFields(currentForm, formId);

      let status = 'need_info';
      let errorMessage;
      let applicatorType;

      if (isReadOnly && submissionResult?.response !== null) {
        const taskDetail = submissionResult?.response?.details?.find(
          detail =>
            detail.vendorPayload?.cwfTaskId === task.taskId &&
            detail.vendorPayload?.cwfCropZoneId === task.cropZoneId &&
            detail.vendorPayload?.applicationMethod ===
              definedCalAgGroups(calAgApplicationMethod)
        );

        status = taskDetail?.status;
        errorMessage = taskDetail?.errorMessage;
        applicatorType = taskDetail?.applicatorType;
      }

      const createdForm = {
        formId,
        task,
        applicationMethods: applicationMethodsInTask,
        propertyDetails: fields.find(f => f.cropZoneId === task.cropZoneId),
        applicatorType: applicatorType || 'grower',
        ...currentForm
      };

      if (
        status === SubmissionStatus.ERROR ||
        status === SubmissionStatus.DONE
      ) {
        return {
          ...createdForm,
          status,
          errorMessage
        };
      }

      return {
        ...createdForm,
        status: validateMandatoryAttributesForm(createdForm)
          ? 'complete'
          : 'need_info'
      };
    }
    return {};
  };

  useEffect(() => {
    if (tasks && tasks.length > 0 && fields && fields.length > 0) {
      setForms(
        groupTasksByProductsApplicationMethod(tasks).map(task =>
          transformTaskToForm(task)
        )
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tasks, fields]);

  const updateForms = formIdToUpdate => {
    const newForms = [...forms].map(form => {
      if (form.status === SubmissionStatus.ERROR) {
        return form;
      }

      if (formIdToUpdate === form.formId) {
        const {
          applicatorType,
          pco,
          pcoType,
          pcoNumber,
          fumigationCode
        } = values;
        const newForm = {
          ...form,
          applicatorType,
          pco,
          pcoType,
          pcoNumber,
          fumigationCode
        };

        return {
          ...newForm,
          ...(!isReadOnly && {
            status:
              !isReadOnly && validateMandatoryAttributesForm(newForm)
                ? 'complete'
                : 'need_info'
          })
        };
      }

      return form;
    });

    setForms(newForms);
    return newForms;
  };

  useEffect(() => {
    if (selectedId !== '') {
      updateForms(selectedId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values]);

  const expandForm = form => {
    if (selectedId === form.formId) {
      setValues({
        ...values,
        applicatorType: '',
        pco: {},
        pcoType: '',
        pcoNumber: '',
        fumigationCode: ''
      });
      setSelectedId('');
    } else {
      setValues({
        ...values,
        pco: '',
        pcoType: '',
        pcoNumber: '',
        fumigationCode: '',
        ...form
      });
      setSelectedId(form.formId);
    }
  };

  const onNextHandler = async () => {
    triggerAnalyticsEvent(
      events.epic.Integrations.submissionApplicatorDetailsFilled,
      {
        vendor: INTEGRATIONS.calAgPermits
      }
    );
    setOpenDrawerCounties(true);
  };

  const renderSummaryTag = () => (
    <SummaryTag isLoading={isLoading} missingInfoAmount={needInfoTask} />
  );

  return (
    <>
      <div
        className="flex flex-col bg-white top-30 left-26 w-full rounded-md mb-20 px-6 py-4 shadow-sm"
        data-testid="submission-crop-season-selection"
      >
        <SubmitHeader
          integrationType={INTEGRATIONS.calAgPermits}
          submissionResult={submissionResult}
          subtitle="Review details and enter any missing information."
          RightComponent={renderSummaryTag}
        />

        {!openDrawerCounties && (isLoading || loading) ? (
          <Spinner />
        ) : (
          <>
            {forms.map(form => (
              <CalAgFormAccordion
                form={form}
                toggleCallback={() => expandForm(form)}
                selectedId={selectedId}
                key={form.formId}
                isReadOnly={isReadOnly}
                pastSubmissions={pastSubmissions}
              />
            ))}
          </>
        )}
        {!isReadOnly && FooterComponent && (
          <FooterComponent
            onNext={onNextHandler}
            nextLabel="Submit"
            nextDisabled={needInfoTask !== 0}
          />
        )}
      </div>
      <RemoveTaskModal
        onRemove={() => {
          setSelectedId('');
        }}
      />
      <CalAgCountiesDrawer forms={forms} />
    </>
  );
};

CalAgReviewAndSubmit.defaultProps = {
  FooterComponent: null,
  loading: false
};

CalAgReviewAndSubmit.propTypes = {
  FooterComponent: PropTypes.oneOfType([
    PropTypes.elementType, // For React components
    PropTypes.func // For functions that return JSX
  ]),
  loading: PropTypes.bool
};

export default CalAgReviewAndSubmit;
