import React, { useContext, useEffect, useState } from 'react';
import { Refresh } from '@material-ui/icons';
import { Button, Popup } from '@agconnections/grow-ui';

import { parseServerError } from 'helpers/errorHelpers';
import { Context } from 'components/Store';
import useSimplotOrganizations from 'hooks/useSimplotOrganizations';
import OrganizationsDropdown from 'components/OrganizationsDropdown';
import useSubmissionAnalytics from 'screens/Integrations/hooks/useSubmissionAnalytics';
import SimplotSubmissionFooter from '../SimplotSubmissionFooter';
import { SimplotContext } from '../../context/Provider';
import { INTEGRATIONS } from '../../../helpers/constants';
import usePropertiesMatch from '../../../hooks/usePropertiesMatch';
import useFormikHandler from '../../../hooks/useFormikHandler';

const SimplotOrganization = () => {
  const { events, triggerAnalyticsEvent } = useSubmissionAnalytics();
  const [simplotOrganizations, setSimplotOrganizations] = useState([]);
  const [, dispatch] = useContext(Context);
  const [{ organization }] = useContext(Context);
  const [loaded, setLoaded] = useState(true);
  const { selectedOrganization, setSelectedOrganization } = useFormikHandler();
  const {
    goForward,
    blockStepForward,
    releaseStepForward,
    redirectToSimplotAuthorizeOrgUrl
  } = useContext(SimplotContext);

  const [loadingRedirectAutOrgs, setLoadingRedirectAutOrgs] = useState(false);

  const handleClickRefreshAuthorizedOrgs = () => {
    setLoadingRedirectAutOrgs(true);
    redirectToSimplotAuthorizeOrgUrl();
  };

  const {
    fetchSimplotOrganizations,
    saveSimplotOrganization
  } = useSimplotOrganizations();

  const { getOrganizationMatch } = usePropertiesMatch();

  const handleOrganizationSelect = orgSelected => {
    setSelectedOrganization(orgSelected);
    if (orgSelected) releaseStepForward();
  };

  const onNext = async () => {
    try {
      const result = await saveSimplotOrganization(
        selectedOrganization,
        simplotOrganizations
      );
      if (result) {
        triggerAnalyticsEvent(
          events.epic.Integrations.submissionOrganizationFilled,
          { vendor: INTEGRATIONS.simplot }
        );
        goForward();
      }
    } catch (e) {
      parseServerError(dispatch)(e);
    }
  };

  useEffect(() => {
    blockStepForward();
    setLoaded(false);

    async function fetchInitialData() {
      try {
        const organizations = await fetchSimplotOrganizations();
        setSimplotOrganizations(organizations);
        const orgMatch = await getOrganizationMatch(INTEGRATIONS.simplot);
        if (orgMatch && orgMatch[0]?.cwfEntityId === organization.id) {
          setSelectedOrganization(orgMatch[0].simplotEntityId);
          releaseStepForward();
        } else {
          blockStepForward();
        }
        setLoaded(true);
      } catch (e) {
        parseServerError(dispatch)(e);
        setLoaded(true);
      }
    }

    fetchInitialData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div
      className="flex flex-col self-center bg-white top-30 left-26 h-100 w-140 rounded-md mb-20 p-6 shadow-sm"
      data-testid="simplot-organization-selection"
    >
      <h1 className="mb-6 ml-8 font-body text-base font-normal leading-8 tracking-tight text-left">
        Select an organization for your submission.
      </h1>
      <div className="ml-8 flex  h-full flex-line">
        <div className="w-2/3 ">
          <OrganizationsDropdown
            vendor="Simplot "
            organizations={simplotOrganizations}
            csLoaded={loaded}
            selectedId={selectedOrganization}
            onSelect={handleOrganizationSelect}
            isRequired
          />
        </div>
        <div className="ml-3" style={{ marginTop: '1.55rem' }}>
          <Popup
            enableHover
            position="top"
            align="center"
            hasArrow
            bgColor="#232630"
            trigger={
              <div data-testid="button-refresh-simplot-org">
                <Button
                  type="overlay"
                  loading={loadingRedirectAutOrgs}
                  disabled={loadingRedirectAutOrgs}
                  style={{ width: '2.45rem', height: '2.45rem' }}
                  onClick={handleClickRefreshAuthorizedOrgs}
                  icon={<Refresh />}
                />
              </div>
            }
          >
            <div className="gr-w-64 text-xs text-white">
              Click here to refresh your Authorized Organizations on Trimble
            </div>
          </Popup>
        </div>
      </div>
      <SimplotSubmissionFooter
        isLastStep={false}
        onNext={onNext}
        nextDisabled={!selectedOrganization}
      />
    </div>
  );
};

export default SimplotOrganization;
