import React, { useState, useEffect } from 'react';
import CwFPagination from 'components/CwFPagination';
import PropTypes from 'prop-types';
import { Redirect, useHistory } from 'react-router-dom';
import Paper from 'components/Paper';
import LandingTable from 'components/LandingTable';
import { tableColumns } from 'screens/Equipment/EquipmentItem/helpers/constants';
import LandingList from 'components/LandingList';
import { equipmentTableSort } from 'screens/Equipment/EquipmentItem/helpers/dataHelpers';
import { paths } from 'routes/paths';
import EquipmentTableRow from './EquipmentTableRow';
import DeleteEquipmentModal from '../DeleteEquipmentModal';

const EquipmentList = ({
  equipments,
  isSearch,
  totalEquipments,
  equipmentPageNumber,
  handlePageChange,
  setItems
}) => {
  const [sortField, setSortField] = useState();
  const [sortDir, setSortDir] = useState();
  const [filteredEquipments, setFilteredEquipments] = useState(equipments);
  const [idToDel, setIDtoDel] = useState();
  const [nameToDel, setNametoDel] = useState();
  const history = useHistory();
  // const [redirect, setRedirect] = useState(false);
  const redirect = false;
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const openDeleteModal = () => setDeleteModalOpen(true);
  const closeDeleteModal = () => {
    setDeleteModalOpen(false);
  };
  useEffect(() => {
    setFilteredEquipments(equipments);
  }, [equipments]);

  const handleSort = (field, dir) => {
    setSortField(field);
    setSortDir(dir);
    setFilteredEquipments(equipmentTableSort(filteredEquipments, field, dir));
  };

  const handleAction = async (action, { id, name }) => {
    switch (action) {
      case 'view':
        history.push({
          pathname: `${paths.equipment}/${id}/view`,
          itemId: id
        });
        break;
      case 'edit':
        history.push(`${paths.equipment}/${id}`);
        break;
      case 'delete':
        setIDtoDel(id);
        setNametoDel(name);
        openDeleteModal();
        break;
      default:
        break;
    }
  };
  return (
    <div data-testid="equipment-list">
      <DeleteEquipmentModal
        open={deleteModalOpen}
        close={closeDeleteModal}
        equipmentName={nameToDel}
        equipmentId={idToDel}
        setItems={setItems}
        equipments={equipments}
      />
      {redirect && <Redirect push to={`${redirect}`} />}
      {equipments.length > 0 ? (
        <Paper>
          {CwFPagination(
            totalEquipments,
            equipmentPageNumber,
            handlePageChange
          )}
          <LandingTable
            listType="Equipment"
            routePath={paths.equipment}
            items={filteredEquipments}
            tableColumns={tableColumns}
            onSort={handleSort}
            sortBy={sortField}
            sortDir={sortDir}
          >
            {({ rows }) =>
              rows.map(equipmentObj => {
                return (
                  <EquipmentTableRow
                    key={equipmentObj.id}
                    onAction={handleAction}
                    equipment={equipmentObj}
                  />
                );
              })
            }
          </LandingTable>
          {CwFPagination(
            totalEquipments,
            equipmentPageNumber,
            handlePageChange
          )}
          <div className="mb-12"> </div>
        </Paper>
      ) : (
        <LandingList.LandingEmpty
          listType="Equipments"
          isSearch={isSearch}
          routePath={isSearch ? paths.equipment : `${paths.equipment}/item`}
        />
      )}
    </div>
  );
};

EquipmentList.defaultProps = {
  equipments: [],
  isSearch: 'false'
};

EquipmentList.propTypes = {
  equipments: PropTypes.arrayOf(PropTypes.object),
  isSearch: PropTypes.bool,
  totalEquipments: PropTypes.number.isRequired,
  equipmentPageNumber: PropTypes.number.isRequired,
  handlePageChange: PropTypes.func.isRequired,
  setItems: PropTypes.func.isRequired
};

export default EquipmentList;
