import stateItems from 'components/AddressFields/StateSelect/data';

export default function formatStorageLocationAddress(storage) {
  const lines = [];

  if (storage?.addressLineOne) lines.push(storage.addressLineOne);
  if (storage?.addressLineTwo) lines.push(storage.addressLineTwo);

  const stateEntry = stateItems.find(x => x.key === storage?.state);

  const locationInfo = [
    storage?.city,
    storage?.state || storage?.zipCode
      ? [stateEntry?.value, storage.zipCode].filter(Boolean).join(' ')
      : null
  ]
    .filter(Boolean)
    .join(', ');

  if (locationInfo) lines.push(locationInfo);
  return lines.join('\n');
}
