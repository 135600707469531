import React from 'react';
import PropTypes from 'prop-types';

const Step = ({ number, header, description, key = 'key' }) => {
  return (
    <div className="flex flex-line" key={key}>
      <span className="p-4 h-4 w-4 flex items-center justify-center border rounded-3xl bg-info-dark-blue text-white border-info-dark-blue mr-2 font-semibold">
        {number}
      </span>
      <div className="`flex items-center">
        <div className="flex flex-col">
          <p className="font-bold">{header}</p>
          <p className="text-normal">{description}</p>
        </div>
      </div>
    </div>
  );
};

Step.propTypes = {
  number: PropTypes.number.isRequired,
  header: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  key: PropTypes.string
};

Step.defaultProps = {
  key: 'key'
};

export default Step;
