import React from 'react';

import GrowUIFormField from 'components/Forms/GrowUIFormField';
import { Form, Select, DatePicker } from '@agconnections/grow-ui';
import { statuses } from '../../helpers/constants';

const CurrentStatusField = () => {
  return (
    <div className="flex">
      <div data-testid="equipment-item-current-status" className="w-1/2 mr-8">
        <Form.Field label="Current Status *">
          <GrowUIFormField
            id="currentStatus"
            name="currentStatus"
            control={Select}
            items={statuses}
          />
        </Form.Field>
      </div>
      <div className="w-1/2">
        <Form.Field label="Current Status Date">
          <GrowUIFormField
            id="currentStatusDate"
            name="currentStatusDate"
            control={DatePicker}
            dateFormat="M d, Y"
          />
        </Form.Field>
      </div>
    </div>
  );
};

export default CurrentStatusField;
