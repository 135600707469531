import React, { useContext, useState } from 'react';
import PropTypes from 'prop-types';
import StoreFrontIcon from 'assets/storefront.svg';
import { Spinner } from '@agconnections/grow-ui';
import {
  Button,
  ButtonType,
  Tooltip
} from 'syngenta-digital-cropwise-react-ui-kit';
import EditIcon from 'assets/edit_outlined.svg';
import { useHistory } from 'react-router-dom';
import { ReactComponent as DeleteIcon } from 'assets/delete_outlined.svg';
import { paths } from 'routes/paths';
import { AmplitudeContext } from 'utilities/amplitude/useAmplitude';
import DeleteConfirmationSaleLocation from '../DeleteConfirmationSaleLocation';
import useSaleLocations from '../../hooks/useSaleLocations';

const SaleLocationDetailsHeader = ({
  header,
  saleLocationId,
  saleLocationList,
  setSaleLocationList,
  isFetchingSaleLocation,
  hasAssociatedYields
}) => {
  const history = useHistory();
  const [isOpen, setIsOpen] = useState();
  const amplitude = useContext(AmplitudeContext);
  const edit = () => {
    history.push(`${paths.yieldV2}/sale-locations/${saleLocationId}/edit`);
  };

  const { deleteSaleLocation, isDeletingSaleLocation } = useSaleLocations();

  const openWarning = () => {
    setIsOpen(true);
  };

  const closeWarning = () => {
    setIsOpen(false);
  };

  const handleSubmit = () => {
    amplitude.sendEventToAmplitude(
      amplitude.events.epic.YieldV2.saleLocations.deleteSaleLocation
    );
    deleteSaleLocation(
      saleLocationId,
      closeWarning,
      saleLocationList,
      setSaleLocationList
    );
  };

  return (
    <>
      <div className="flex flex-row items-center bg-white border border-t-0 h-16 justify-between p-6">
        {!isFetchingSaleLocation ? (
          <>
            <div className="flex flex-row">
              <img className="mr-2" src={StoreFrontIcon} alt="Icon" />
              <span className="font-semibold text-2xl">{header}</span>
            </div>
            <div className="flex flex flex-row w-50 justify-end">
              <Button
                onClick={edit}
                type={ButtonType.outline}
                icon={
                  <img
                    className="mr-1"
                    width={18}
                    src={EditIcon}
                    alt="edit icon"
                  />
                }
              >
                Edit
              </Button>
              <div className="w-4" />
              <Tooltip
                overlayClassName="max-w-full"
                title={
                  hasAssociatedYields
                    ? 'Locations that are associated with loads cannot be deleted'
                    : null
                }
                placement="topRight"
              >
                <div>
                  <Button
                    type={ButtonType.outline}
                    disabled={hasAssociatedYields}
                    onClick={openWarning}
                    icon={<DeleteIcon width={18} />}
                  >
                    Delete
                  </Button>
                </div>
              </Tooltip>
            </div>
          </>
        ) : (
          <Spinner size="lg" />
        )}
      </div>
      <DeleteConfirmationSaleLocation
        open={isOpen}
        loading={isDeletingSaleLocation}
        onCancel={closeWarning}
        onSubmit={handleSubmit}
      />
    </>
  );
};

SaleLocationDetailsHeader.propTypes = {
  header: PropTypes.string.isRequired,
  saleLocationId: PropTypes.string.isRequired,
  saleLocationList: PropTypes.shape().isRequired,
  setSaleLocationList: PropTypes.func.isRequired,
  isFetchingSaleLocation: PropTypes.bool.isRequired,
  hasAssociatedYields: PropTypes.bool.isRequired
};

export default SaleLocationDetailsHeader;
