import React, { useState } from 'react';

import { Card, Button } from '@agconnections/grow-ui';
import ProfileFields from './components/ProfileFields';
import ContactFields from './components/ContactFields';

const Profile = () => {
  const [data, setData] = useState({});

  const handleChange = event => {
    const { target } = event;
    const { name } = target;

    let value = '';
    if (target.type === 'checkbox') {
      value = target.checked;
    } else if (target.type === 'file') {
      value = {
        name: target.files[0].name,
        src: URL.createObjectURL(target.files[0])
      };
    } else {
      value = target.value;
    }

    setData(previousData => ({
      ...previousData,
      [name]: value
    }));
  };
  return (
    <>
      <div className="mb-5" data-testid="profile">
        <Card>
          <form onChange={e => handleChange(e)}>
            <div className="p-5">
              <div className="text-2xl font-light text-gray-700">Profile</div>
              <ProfileFields profilePicture={data && data.profilePicture} />
            </div>

            <div className="p-5">
              <div className="text-2xl font-light text-gray-700">
                Contact Info
              </div>
              <ContactFields />
              <div className="text-right">
                <div className="inline-block pl-4" data-testid="save">
                  <Button type="primary"> Save</Button>
                </div>
              </div>
            </div>
          </form>
        </Card>
      </div>
    </>
  );
};

export default Profile;
