import React, { useMemo } from 'react';
import { Select } from 'syngenta-digital-cropwise-react-ui-kit';
import { ReactComponent as ChevronDown } from 'assets/chevron_down.svg';
import getLabelValue from 'screens/YieldV2/AddYield/helpers/getLabelValue';
import PropTypes from 'prop-types';
import { MEASUREMENT_UNITS } from 'helpers/unitsAbbreviation';
import { getBushelWeight } from 'helpers/getBushelWeight';
import { YIELD_UNITS } from './constants';

const UOMDropdown = ({
  value,
  cropName,
  onChange,
  className,
  isRequired,
  minWidth = '165px'
}) => {
  const bushelWeight = useMemo(() => getBushelWeight(cropName), [cropName]);

  const removeBushelIfNoWeight = item =>
    item.value !== MEASUREMENT_UNITS.BUSHEL || bushelWeight !== undefined;

  const isBushelInfoVisible =
    value === MEASUREMENT_UNITS.BUSHEL && bushelWeight;

  return (
    <div
      data-testid="uom-dropdown"
      className="relative flex flex-col"
      style={{ minWidth }}
    >
      <Select
        size="middle"
        suffixIcon={<ChevronDown />}
        onChange={onChange}
        value={value}
        dropdownMatchSelectWidth={false}
        className={className}
        optionLabelProp="label"
        required={isRequired}
        getPopupContainer={triggerNode => triggerNode.parentNode}
      >
        {YIELD_UNITS.filter(removeBushelIfNoWeight).map(option => (
          <Select.Option
            key={option.value}
            value={option.value}
            label={getLabelValue(value, option)}
          >
            {option.label}
          </Select.Option>
        ))}
      </Select>
      <p
        className={`text-neutral text-neutral-60 tracking-tighter text-sm mt-1 ${
          isBushelInfoVisible ? 'visible' : 'invisible'
        }`}
      >
        1.00 bushel = {bushelWeight} lbs
      </p>
    </div>
  );
};

UOMDropdown.propTypes = {
  value: PropTypes.string,
  cropName: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  className: PropTypes.string,
  isRequired: PropTypes.bool,
  minWidth: PropTypes.string
};

UOMDropdown.defaultProps = {
  value: '',
  cropName: '',
  className: '',
  isRequired: false,
  minWidth: '165px'
};

export default UOMDropdown;
