import React, { useContext } from 'react';
import { useHistory } from 'react-router-dom';
import { Popup } from '@agconnections/grow-ui';
import { HelpOutlineOutlined } from '@material-ui/icons';
import { paths } from 'routes/paths';
import StoredQntIcon from 'assets/silo.svg';
import CardWrapper from 'components/CardWrapper';
import { StorageLocationContext } from 'screens/YieldV2/StorageLocations/context/StorageLocationProvider';
import FormattedNumber from 'components/FormattedNumber';
import { Button, ButtonType } from 'syngenta-digital-cropwise-react-ui-kit';
import WarningIcon from 'assets/warning.svg';
import { AmplitudeContext } from 'utilities/amplitude/useAmplitude';
import PieChart from '../../../PieChart';

const StoredQuantity = () => {
  const history = useHistory();
  const amplitude = useContext(AmplitudeContext);
  const {
    state: { selectedId, data = [] }
  } = useContext(StorageLocationContext);

  const selected = data.find(item => item.id === selectedId);
  const totalStored = selected?.totalStoredLoad;
  const available = selected?.capacity - totalStored;
  const pieValue = totalStored ? (totalStored / selected?.capacity) * 100 : 0;

  const isOverloaded = available < 0;
  const extraClasses = isOverloaded
    ? 'rounded-md bg-red-100 text-red-600 pl-2 pr-2'
    : '';

  const onGoToLoads = () => {
    amplitude.sendEventToAmplitude(
      amplitude.events.epic.YieldV2.storageLocations.goToLoads
    );
    history.push(
      `${paths.yieldV2}/loads?filters=${JSON.stringify({
        storageIds: [selectedId]
      })}`
    );
  };

  return (
    <CardWrapper
      header={
        <div className="flex justify-between items-center px-4 py-3 border-b border-neutral-20 border-solid">
          <div className="flex">
            <img className="mr-2" src={StoredQntIcon} alt="Icon" />
            <h4 className="text-neutral-1000 text-base font-normal leading-6 tracking-tight">
              Stored Quantity
            </h4>
          </div>
          <Popup
            bgColor="#232630"
            enableHover
            position="top"
            align="center"
            className="w-30"
            hasArrow
            trigger={
              <div className="pl-3 text-blue-90 text-sm flex items-center">
                <HelpOutlineOutlined
                  style={{ width: '16px', height: '16px' }}
                />
                <span className="ml-1">What is this?</span>
              </div>
            }
          >
            <div className="w-356px text-xs text-white z-50">
              Stored Quantity includes all loads across all crop seasons
            </div>
          </Popup>
        </div>
      }
      className=""
    >
      {!selected?.capacity || selected?.totalStoredLoadUnit === 'lb' ? (
        <div className="flex py-4 pl-8 pr-4 space-x-4">
          <div className="w-full">
            <span
              data-testid="total-currently-stored"
              className="flex flex-col mb-3"
            >
              <span className="text-neutral-60 text-sm mb-1 font-normal leading-5 tracking-tight">
                Total currently stored
              </span>
              <span className="text-neutral-1000 text-3xl font-semibold leading-6 tracking-tight">
                <FormattedNumber roundedTo={2}>{totalStored}</FormattedNumber>{' '}
                <span className="font-normal text-base">
                  {selected?.totalStoredLoadUnit === 'lb' ? 'lbs' : 'bu'}
                </span>
              </span>
            </span>
          </div>
        </div>
      ) : (
        <>
          <div className="flex py-4 pl-8 pr-4 space-x-4">
            <div className="w-1/2">
              <div className="cwfweb-storage-location-usage h-160px w-160px">
                <PieChart value={pieValue} isLarge />
              </div>
            </div>
            <div className="w-1/2">
              <span data-testid="capacity" className="flex flex-col mb-3">
                <span className="text-neutral-60 text-xs font-normal leading-5 tracking-tight">
                  Capacity
                </span>
                <span className="text-neutral-1000 text-base font-semibold leading-6 tracking-tight">
                  <FormattedNumber roundedTo={2}>
                    {selected?.capacity}
                  </FormattedNumber>{' '}
                  <span className="font-normal text-xs">bu</span>
                </span>
              </span>

              <span
                data-testid="total-currently-stored"
                className="flex flex-col mb-3"
              >
                <span className="text-neutral-60 text-sm mb-1 font-normal leading-5 tracking-tight">
                  Total currently stored
                </span>
                <span>
                  <span
                    className={`${
                      isOverloaded ? '' : 'text-neutral-1000'
                    } text-base font-semibold leading-6 tracking-tight ${extraClasses}`}
                  >
                    <FormattedNumber roundedTo={2}>
                      {totalStored}
                    </FormattedNumber>{' '}
                    <span className="font-normal text-xs">bu</span>
                  </span>
                </span>
              </span>
              <span
                data-testid="currently-available"
                className="flex flex-col mb-3"
              >
                <span className="text-neutral-60 text-xs font-normal leading-5 tracking-tight">
                  Currently available
                </span>
                <span className="text-neutral-1000 text-3xl font-semibold leading-6 tracking-tight">
                  <FormattedNumber roundedTo={2}>{available}</FormattedNumber>{' '}
                  <span className="font-normal text-base">bu</span>
                </span>
              </span>
            </div>
          </div>
          {isOverloaded && (
            <div className="flex bg-warning border-yellow-700 border rounded-md p-4 items-center">
              <div className="mr-4">
                <img src={WarningIcon} alt="CropWise Warning Icon" />
              </div>
              <span className="text-sm font-normal">
                Edit loads out of this location
              </span>
              <span className="flex-1" />
              <Button
                onClick={onGoToLoads}
                type={ButtonType.outline}
                size="small"
              >
                Go to Loads
              </Button>
            </div>
          )}
        </>
      )}
    </CardWrapper>
  );
};

export default StoredQuantity;
