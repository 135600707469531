import React, { useCallback, useContext, useMemo, useState } from 'react';
import { Context } from 'components/Store';
import { Checkbox, Select, Spin } from 'syngenta-digital-cropwise-react-ui-kit';
import ChevronDown from 'assets/chevron_down.svg';
import CropIconMapping from 'components/CropIconMapping';
import PropTypes from 'prop-types';
import { AmplitudeContext } from 'utilities/amplitude/useAmplitude';
import './index.css';
import RenderTag from './RenderTag';
import MaxTagPlaceholder from './MaxTagPlaceholder';

export default function FilterByCropsSelectInput({
  cropsFilterValue,
  cropsFilterValueChange
}) {
  const [cropsSelectOpen, setCropsSelectOpen] = useState(false);
  const [{ cropSeasons, isGlobalCropSeasonLoading }] = useContext(Context);
  const amplitude = useContext(AmplitudeContext);

  const cropsOfSelectedSeasons = useMemo(() => {
    const selectedCropSeasons =
      JSON.parse(localStorage.getItem('selectedCropSeasons')) || [];
    if (cropSeasons?.length) {
      const seenIds = [];
      const crops = cropSeasons
        ?.filter(cropSeason => selectedCropSeasons.includes(cropSeason.id))
        .map(cropSeason => cropSeason.crops)
        .flat()
        .filter(crop => {
          if (crop?.id && !seenIds.includes(crop.id)) {
            seenIds.push(crop.id);
            return true;
          }
          return false;
        })
        .sort((a, b) => a.name.localeCompare(b.name));
      if (cropsFilterValue.length) {
        const cropsIds = crops?.map(crop => crop?.id);
        const newSelectedCrops = cropsFilterValue.filter(cropId =>
          cropsIds.includes(cropId)
        );
        cropsFilterValueChange(newSelectedCrops);
      }
      return crops;
    }

    return [];
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cropSeasons]);

  const analyticsOnCropSelect = cropName => {
    amplitude.sendEventToAmplitude(
      amplitude.events.epic.YieldV2.loads.selectCrop,
      {
        crop: cropName
      }
    );
  };

  const onChange = value => {
    const newValue = value?.filter(
      crop => !cropsFilterValue?.includes(crop)
    )?.[0];
    if (newValue) {
      const cropName = cropsOfSelectedSeasons.find(o => o.id === newValue)
        ?.name;
      analyticsOnCropSelect(cropName);
    }
    cropsFilterValueChange(value);
  };

  const tagRender = useCallback(
    ({ value, onClose }) => (
      <RenderTag
        value={value}
        onClose={onClose}
        cropsOfSelectedSeasons={cropsOfSelectedSeasons}
        isGlobalCropSeasonLoading={isGlobalCropSeasonLoading}
      />
    ),
    [cropsOfSelectedSeasons, isGlobalCropSeasonLoading]
  );

  const renderMaxTagPlaceholder = useCallback(
    ommitedValues => <MaxTagPlaceholder omittedValues={ommitedValues} />,
    []
  );

  return (
    <div className="select-container">
      <Select
        notFoundContent={
          isGlobalCropSeasonLoading ? <Spin size="small" /> : 'No data'
        }
        data-testid="filter-by-crops-select-input"
        value={cropsFilterValue}
        onChange={onChange}
        open={cropsSelectOpen}
        onDropdownVisibleChange={open => setCropsSelectOpen(open)}
        showArrow
        suffixIcon={
          <img
            src={ChevronDown}
            style={{ transform: cropsSelectOpen ? 'rotate(180deg)' : null }}
            height={18}
            width={18}
            alt="Select dropdown icon"
          />
        }
        mode="multiple"
        style={{ width: '100%' }}
        placeholder="All Crops"
        tagRender={tagRender}
        optionLabelProp="label"
        maxTagCount="responsive"
        maxTagPlaceholder={renderMaxTagPlaceholder}
        showSearch={false}
      >
        {cropsOfSelectedSeasons.map(option => (
          <Select.Option
            className="select-option"
            key={option.id}
            value={option.id}
            label={option.name}
          >
            <div className="flex items-center h-8 pl-4 py-1.5">
              <Checkbox
                checked={cropsFilterValue.includes(option.id)}
                className="mr-4"
              />
              <CropIconMapping cropObject={option} size={16} />
              <div className="ml-2">{option.name}</div>
            </div>
          </Select.Option>
        ))}
      </Select>
    </div>
  );
}

FilterByCropsSelectInput.defaultProps = {
  cropsFilterValue: [''],
  cropsFilterValueChange: () => {}
};

FilterByCropsSelectInput.propTypes = {
  cropsFilterValue: PropTypes.arrayOf(PropTypes.string),
  cropsFilterValueChange: PropTypes.func
};
