import React, { useState } from 'react';
import PropTypes from 'prop-types';
import ClickableDiv from 'components/ClickableDiv';
import { Table } from '@agconnections/grow-ui';
import { ReactComponent as EditIcon } from 'assets/edit_outlined.svg';
import { ReactComponent as DeleteIcon } from 'assets/delete_outlined.svg';
import { useHistory } from 'react-router-dom';
import { paths } from 'routes/paths';
import { getUnitSymbol } from 'screens/Yield/helpers/infoSectionData';
import FieldListContainer from './FieldListContainer';

const YieldTableRow = ({ yieldEntry, handleDeleteClick }) => {
  const history = useHistory();
  const [showFieldList, setShowFieldList] = useState(false);
  const getSource = () => {
    let fieldNames = yieldEntry?.cropZones?.map(({ fieldName }) => fieldName);
    if (!fieldNames?.length) return '--';

    fieldNames = [...new Set(fieldNames)];
    if (fieldNames.length === 1) return fieldNames[0];

    return `${fieldNames[0]} + ${fieldNames.length - 1} more`;
  };

  const handleFieldList = type => {
    const hasMoreThanOneField = getSource().includes('+');
    if (hasMoreThanOneField) {
      if (type === 'CLICK') {
        setShowFieldList(true);
      } else {
        setShowFieldList(false);
      }
    }
  };
  const handleEditClick = () => {
    history.push(`${paths.yield}/${yieldEntry?.id}/edit`);
  };

  return (
    <>
      <Table.Row>
        <Table.Cell>
          {yieldEntry?.entryName ? `${yieldEntry?.entryName}` : '--'}
        </Table.Cell>
        <Table.Cell>
          {yieldEntry?.totalYield != null
            ? `${yieldEntry?.totalYield.toLocaleString('en')} ${getUnitSymbol(
                yieldEntry?.unit
              )}`
            : '--'}
        </Table.Cell>
        <Table.Cell>
          {yieldEntry?.salePrice != null
            ? `$${yieldEntry?.salePrice.toLocaleString('en')} / ${getUnitSymbol(
                yieldEntry?.unit
              )}`
            : '--'}
        </Table.Cell>
        <Table.Cell>
          {yieldEntry?.grossRevenue != null
            ? `$${yieldEntry?.grossRevenue.toLocaleString('en')}`
            : '--'}
        </Table.Cell>
        <Table.Cell>
          {yieldEntry?.totalArea != null
            ? `${yieldEntry?.totalArea.toLocaleString('en')} ac`
            : '--'}
        </Table.Cell>
        <Table.Cell>
          {yieldEntry?.grossRevenuePerAcre != null
            ? `$${yieldEntry?.grossRevenuePerAcre.toLocaleString('en')}`
            : '--'}
        </Table.Cell>
        <Table.Cell style={{ position: 'relative' }}>
          <ClickableDiv
            onClick={() => handleFieldList('CLICK')}
            onBlur={() => handleFieldList('BLUR')}
          >
            <span
              className={`${showFieldList ? 'text-cucumber-green-700' : ''}`}
              style={showFieldList ? { borderBottom: '1px solid #14803C' } : {}}
            >
              {showFieldList && <FieldListContainer yieldEntry={yieldEntry} />}
              {getSource()}
            </span>
          </ClickableDiv>
        </Table.Cell>
        <Table.Cell>
          <EditIcon
            className="mr-14px cursor-pointer"
            onClick={handleEditClick}
          />
          <DeleteIcon
            className="mr-14px cursor-pointer"
            onClick={() => handleDeleteClick(yieldEntry?.id)}
          />
        </Table.Cell>
      </Table.Row>
    </>
  );
};

export const yieldEntryPropTypes = PropTypes.shape({
  entryName: PropTypes.string,
  unit: PropTypes.string,
  id: PropTypes.string,
  totalYield: PropTypes.number,
  avgYieldPerAcre: PropTypes.number,
  salePrice: PropTypes.number,
  grossRevenue: PropTypes.number,
  totalArea: PropTypes.number,
  grossRevenuePerAcre: PropTypes.number,
  commodityId: PropTypes.string,
  cropZones: PropTypes.arrayOf(
    PropTypes.shape({
      fieldName: PropTypes.string
    })
  )
});

YieldTableRow.propTypes = {
  yieldEntry: yieldEntryPropTypes.isRequired,
  handleDeleteClick: PropTypes.func.isRequired
};

export default YieldTableRow;
