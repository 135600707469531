/* eslint-disable */

import React, { useEffect, useState } from 'react';
import qs from 'querystring';
import { useHistory } from 'react-router-dom';
import {
  performLogin,
  useAuth,
  isAccessTokenAvailable,
  accountsOAuthUrl,
  coreServiceAutoOrgPermissionChecker
} from '.';

import { Spinner } from '@agconnections/grow-ui';

// tslint:disable-next-line:no-big-function
const LoginCallback = () => {
  const history = useHistory();
  const clientId = process.env.REACT_APP_CLIENT_ID;
  const [query, setQuery] = useState();
  const { fetchUserProfile, user } = useAuth();

  useEffect(() => {
    const locationSearch = location.search.replace('?', '');
    const parseValues = qs.parse(locationSearch);

    if (!parseValues.state) {
      setQuery({ code: parseValues.code, state: null });
    } else {
      setQuery(parseValues);
    }
  }, []);

  useEffect(() => {
    if (!query) return;
    if (query.code && !query.state) {
      if (!sessionStorage.getItem(`cod${query.code}`)) {
        handleLogin(query.code);
      }
    } else if (query.code && query.state === 'L2FwcC93b3Jrc3BhY2Vz') {
      if (!sessionStorage.getItem(`cod${query.code}`)) {
        handleLogin(query.code);
        return;
      }
      history.push('/app');
    } else if (isAccessTokenAvailable()) {
      fetchUserProfile();
    } else {
      window.location.href = accountsOAuthUrl;
    }
  }, [query]);

  const checkPermissionsAndRedirect = async () => {
    if (query?.state === 'L2FwcC93b3Jrc3BhY2Vz') {
      setQuery(undefined);
      try {
        await coreServiceAutoOrgPermissionChecker(
          user.auto_created_org_id,
          user.default_workspace_id,
          user.id
        );
        history.push('/app');
      } catch (error) {
        history.push('/app');
      }
    } else {
      history.push('/app');
    }
  };

  useEffect(() => {
    if (query === undefined) {
      return;
    }
    if (user) {
      checkPermissionsAndRedirect();
    }
  }, [user, query]);

  const handleLogin = code => {
    const userDetails = {
      grant_type: 'authorization_code',
      client_id: clientId,
      redirect_uri: `${location.origin}/authenticate`,
      code
    };
    sessionStorage.setItem(`cod${code}`, 'Y');
    performLogin(userDetails).then(() => {
      fetchUserProfile();
    });
  };

  return (
    <div className="m-auto h-screen w-full">
      <Spinner />
    </div>
  );
};

export default LoginCallback;
