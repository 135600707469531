import React, { useContext } from 'react';
import { Context } from 'components/Store';
import GrowUIFormField from 'components/Forms/GrowUIFormField';
import { TextArea } from '@agconnections/grow-ui';
import { useFormikContext } from 'formik';

const Notes = () => {
  const [{ isEnableInfoTabEdit }] = useContext(Context);
  const { values } = useFormikContext();

  return (
    <GrowUIFormField
      value={values.notes}
      control={TextArea}
      id="notes"
      name="notes"
      className="h-32 overscroll-auto"
      disabled={!isEnableInfoTabEdit}
      placeholder="Enter notes"
    />
  );
};

export default Notes;
