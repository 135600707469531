/* eslint-disable react/no-array-index-key */
import React from 'react';
import PropTypes from 'prop-types';
import { StyleSheet, Text, View } from '@react-pdf/renderer';
import TableHeader from './TableHeader';
import TableRow from './TableRow';

const styles = StyleSheet.create({
  tableTitle: {
    fontFamily: 'Noto Sans',
    fontWeight: 'medium',
    fontSize: 12,
    marginBottom: 12
  },
  tableContainer: {
    border: '1px solid #DFE2E7',
    borderTop: '1.5px solid #DFE2E7',
    borderRadius: 2
  },
  tableHeaderContainer: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center'
  }
});

const Table = ({ title, headerItems, bodyItems, titleRight }) => {
  return (
    <View>
      <View style={styles.tableHeaderContainer}>
        {title && <Text style={styles.tableTitle}>{title}</Text>}
        {titleRight && <Text style={styles.tableTitle}>{titleRight}</Text>}
      </View>
      <View style={styles.tableContainer}>
        <TableHeader items={headerItems} />
        {bodyItems.map((row, index) => (
          <TableRow
            items={row}
            showBorder={index + 1 < bodyItems.length}
            key={`${index}-${title}`}
          />
        ))}
      </View>
    </View>
  );
};

Table.propTypes = {
  title: PropTypes.string,
  headerItems: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      width: PropTypes.number.isRequired
    })
  ),
  bodyItems: PropTypes.arrayOf(
    PropTypes.arrayOf(
      PropTypes.shape({
        label: PropTypes.string.isRequired,
        width: PropTypes.number
      })
    )
  ),
  titleRight: PropTypes.string
};

Table.defaultProps = {
  title: null,
  headerItems: [],
  bodyItems: [],
  titleRight: null
};

export default Table;
