export const APPLICATION_OPTIONS = {
  byRatePerArea: {
    key: 'ByRatePerArea',
    value: 'ratePerAreaValue',
    unit: 'ratePerAreaUnit',
    title: 'Rate/Area'
  },
  byTotalProduct: {
    key: 'ByTotalProduct',
    value: 'totalProductValue',
    unit: 'totalProductUnit',
    title: 'Total Product'
  },
  byRatePerTank: {
    key: 'ByRatePerTank',
    value: 'ratePerTankValue',
    unit: 'ratePerTankUnit',
    title: 'Rate/Tank'
  }
};

export const ELLIPSIS_MENU = [
  {
    value: 'edit',
    text: 'Edit',
    minAllowedRole: 'Full control'
  },
  {
    value: 'tag',
    text: 'Tag',
    minAllowedRole: 'Full control'
  },
  {
    value: 'delete',
    text: 'Delete',
    className: 'text-chili-red',
    minAllowedRole: 'Full control'
  }
];

export const APPLICATION_METHODS = [
  { key: 'None', value: 'None' },
  { key: 'Aerial', value: 'Aerial' },
  { key: 'Air Blast', value: 'Air Blast' },
  { key: 'Chemigation', value: 'Chemigation' },
  { key: 'Fertigation', value: 'Fertigation' },
  { key: 'Fumigation', value: 'Fumigation' },
  { key: 'Ground', value: 'Ground' },
  { key: 'Ground - Banded', value: 'Ground - Banded' },
  { key: 'Ground - Broadcast', value: 'Ground - Broadcast' },
  { key: 'Ground - Hooded', value: 'Ground - Hooded' },
  { key: 'Ground - In Furrow', value: 'Ground - In Furrow' },
  { key: 'Ground - Incorporated', value: 'Ground - Incorporated' },
  { key: 'Ground - Seed Treatment', value: 'Ground - Seed Treatment' },
  { key: 'Ground - Spot', value: 'Ground - Spot' },
  { key: 'Ground - Variable Rate', value: 'Ground - Variable Rate' },
  { key: 'Ground - Wipe', value: 'Ground - Wipe' },
  { key: 'Ground Application', value: 'Ground Application' },
  { key: 'Planting', value: 'Planting' },
  { key: 'Re-Planting', value: 'Re-Planting' },
  { key: 'Sidedress / Topdress', value: 'Sidedress' },
  { key: 'Storage', value: 'Storage' },
  { key: 'Topdress', value: 'Topdress' },
  { key: 'Tree Injection', value: 'Tree Injection' },
  { key: 'Water Run', value: 'Water Run' }
];

export const timingOptions = [
  { key: 'None', value: 'None' },
  { key: 'Pre-Plant', value: 'Pre-Plant' },
  { key: 'Planting', value: 'Planting' },
  { key: 'Growing', value: 'Growing' },
  { key: 'Harvest', value: 'Harvest' },
  { key: 'Storage', value: 'Storage' },
  { key: 'Annual', value: 'Annual' }
];

export const productTypes = [
  { key: 'clear', value: '- Clear filter' },
  { key: 'custom', value: 'Custom Product' },
  { key: 'cropProtection', value: 'Crop Protection' },
  { key: 'fertilizer', value: 'Fertilizer' },
  { key: 'seed', value: 'Seed' },
  { key: 'service', value: 'Service' }
];
