import { centroid, featureCollection, bbox } from '@turf/turf';
import axios from 'axios';
import { convertNumberToDesiredFormat } from 'helpers/transformHelpers';
import {
  farm as farmApi,
  cropZone as cropZoneApi,
  field as fieldAPI,
  products as productsApi,
  newProxyReiphisData as reiphisAPI
} from 'utilities/api';
import { MASTERLIST_CROPS_URL } from 'utilities/apiConstants';
import { getAccessToken } from 'utilities/base-auth';
import mapboxgl from 'mapbox-gl';

const ACCESS_TOKEN = process.env.REACT_APP_MAPBOX_ACCESS_TOKEN;
const TOLERANCE = 0.001;

// Creates a GeoJSON Feature object with MultiPoint geometry type using provided coordinates
// Sets properties for marker size and color
export const createGeoJsonFeature = coordinates => ({
  type: 'Feature',
  properties: {
    'marker-size': 'xs',
    'marker-color': '#18A04A'
  },
  geometry: {
    type: 'MultiPoint',
    coordinates
  }
});

const fillGeoJsonOnMap = (map, geoJson, actualPixelRatio) => {
  const bboxGeoJSON = bbox(geoJson);
  const fillColor = '#31B4F2';
  const fillOpacity = 0.37;
  const lineColor = '#31B4F2';
  const lineWidth = 1;
  return new Promise((resolve, reject) => {
    map.on('load', () => {
      try {
        map.addSource('geojson', {
          type: 'geojson',
          data: geoJson
        });
        map.addLayer({
          id: 'geojson',
          type: 'fill',
          source: 'geojson',
          paint: {
            'fill-color': fillColor,
            'fill-opacity': fillOpacity
          }
        });
        map.addLayer({
          id: 'outline',
          type: 'line',
          source: 'geojson',
          paint: {
            'line-color': lineColor,
            'line-width': lineWidth
          }
        });
        map.fitBounds(bboxGeoJSON, { padding: 20 });
        map.once('render', () => {
          map.once('idle', () => {
            resolve(map.getCanvas().toDataURL('image/png'));
            Object.defineProperty(window, 'devicePixelRatio', {
              get() {
                return actualPixelRatio;
              }
            });
          });
        });
      } catch (error) {
        reject(error);
      }
    });
    map.on('error', reject);
  });
};

export const createMapUrl = async geoJson => {
  mapboxgl.accessToken = ACCESS_TOKEN;

  // Calculate pixel ratio
  const actualPixelRatio = window.devicePixelRatio;
  Object.defineProperty(window, 'devicePixelRatio', {
    get() {
      return 150 / 96;
    }
  });

  const container = document.createElement('div');
  const mapStyle = 'mapbox://styles/mapbox/satellite-streets-v11';

  const containerStyle = `
    z-index: 100;
    visibility: hidden;
    position: absolute;
    top: 0;
    height: 198px;
    width: 270px;
  `;
  const map = new mapboxgl.Map({
    container,
    style: mapStyle,
    fadeDuration: 0,
    interactive: false,
    preserveDrawingBuffer: true
  });
  // Set the container's style properties directly for better performance
  map.getContainer().style.cssText = containerStyle;
  return fillGeoJsonOnMap(map, geoJson, actualPixelRatio);
};

export const calculateDynamicTolerance = (
  baseTolerance,
  featureCount,
  scalingFactor = TOLERANCE
) => {
  return baseTolerance / featureCount ** scalingFactor;
};

export const convertFeaturesToMapUri = async features => {
  if (!features?.length) {
    return '';
  }
  const geoJsonData = featureCollection(features);
  const mapUri = await createMapUrl(geoJsonData);

  return mapUri;
};

export const buildFeature = geometry => ({
  type: 'Feature',
  properties: {
    stroke: '#31B4F2',
    'stroke-width': 1,
    fill: '#31B4F2',
    'fill-opacity': 0.37
  },
  geometry
});
export const displayText = text => {
  if (text == null || text === '') return '-';
  return text;
};

export const displayNumber = number => {
  if (number == null) return '-';
  return convertNumberToDesiredFormat(number, 'fixed');
};

export const getPropertyExtraData = async cropZoneId => {
  const { promise: cropZonePromise } = cropZoneApi.fetch(cropZoneId);
  const { data: cropZone } = await cropZonePromise.catch(() => ({ data: {} }));

  const { promise: fieldPromise } = fieldAPI.fetch(cropZone.fieldId);
  const { data: field } = await fieldPromise.catch(() => ({ data: {} }));

  const { promise: farmPromise } = farmApi.fetch(field.propertyId);
  const { data: farm } = await farmPromise.catch(() => ({ data: {} }));

  const cropUrl = `${MASTERLIST_CROPS_URL}/crops/${cropZone.cropId}?location=@CWF`;
  const { data: crop } = await axios
    .get(cropUrl, {
      headers: { common: { Authorization: `Bearer ${getAccessToken()}` } }
    })
    .catch(() => ({ data: {} }));

  const centroId = cropZone.geometry ? centroid(cropZone.geometry) : {};

  return { cropZone, field, farm, crop, centroId };
};

export const getProductsExtraData = async productId => {
  const { promise: productPromise } = productsApi.fetch(productId);
  const { data: masterProduct } = await productPromise.catch(() => ({
    data: {}
  }));

  const { promise: activeIngredientsPromise } = productsApi.fetch(
    `${productId}/activeIngredients`
  );

  const {
    data: activeIngredients
  } = await activeIngredientsPromise.catch(() => ({ data: [] }));

  const workerProtection = {
    productName: masterProduct.name,
    key: masterProduct.id,
    signalword: displayText(masterProduct.signalword),
    rup: displayText(masterProduct.restricteduse?.toString()),
    ppe: displayText(masterProduct.ppe),
    ppereentry: displayText(masterProduct.ppereentry)
  };
  const { promise: reiphisPromise } = reiphisAPI.fetch(productId);
  const { data: reiphisData } = await reiphisPromise.catch(() => ({
    data: []
  }));
  return {
    masterProduct,
    activeIngredients,
    reiphisData,
    workerProtection
  };
};
