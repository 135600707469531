import React, { useContext, useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';
import { usePDF } from '@react-pdf/renderer';
import { format } from 'date-fns';
import { AmplitudeContext } from 'utilities/amplitude/useAmplitude';
import usePlansApi from 'hooks/usePlansApi';
import { season as seasonApi } from 'utilities/api';
import { getPropertyExtraData } from 'components/PDF/helpers/pdfTools';
import PlanPdf from 'components/PDF/PlanPdf';
import usePDFTools from 'hooks/usePDFTools';
import axios from 'axios';
import { MASTERLIST_CROPS_URL } from 'utilities/apiConstants';
import { getAccessToken } from 'utilities/base-auth';
import { Context } from 'components/Store';
import {
  fieldsToTable,
  fixedCostsToTable,
  mapProductsToTable
} from 'components/PDF/PlanPdf/helpers/tableMaker';
import { sumFieldAcres } from 'components/PDF/helpers/tableGenerator';
import { paths } from 'routes/paths';
import {
  getPlanFixedCost,
  getPlanProjNetRevenue,
  getPlanProjNetRevenuePerArea,
  getVarCost
} from '../components/PlanView/helpers';

const usePlanEllipsisOptions = () => {
  const history = useHistory();
  const amplitude = useContext(AmplitudeContext);
  const [{ organization }] = useContext(Context);
  const plansAPI = usePlansApi('plan');
  const { downloadPDFV2, downloading, setDownloading } = usePDFTools();

  const [planToPdf, setPlanToPdf] = useState();
  const [loadingTags, setLoadingTags] = useState(false);

  const [instance, updateInstance] = usePDF({
    document: <PlanPdf plan={planToPdf} />
  });
  const [currentOpenModal, setCurrentOpenModal] = useState(null);

  const prevPdfUrl = useRef(undefined);

  useEffect(() => {
    if (planToPdf) {
      updateInstance();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [planToPdf]);

  useEffect(() => {
    if (
      !instance.loading &&
      instance.url &&
      prevPdfUrl.current !== instance.url &&
      planToPdf
    ) {
      prevPdfUrl.current = instance.url;
      downloadPDFV2(prevPdfUrl.current, `Plan - ${planToPdf.name}.pdf`);
      setPlanToPdf(null);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [instance]);

  const edit = id => {
    history.push({
      pathname: `${paths.plans}/${id}`,
      selectedView: 'edit-fromView'
    });
  };

  const duplicate = id => {
    history.push({
      pathname: `${paths.plans}/${id}`,
      state: {
        selectedView: 'edit-dup'
      }
    });
  };

  const deleteOpt = () => {
    setCurrentOpenModal('delete');
    amplitude.sendEventToAmplitude(amplitude.events.epic.Plans.deletePlan);
  };

  const shareOrPrint = async id => {
    setDownloading(true);

    const getCrop = async cropId => {
      const cropUrl = `${MASTERLIST_CROPS_URL}/crops/${cropId}?location=@CWF`;
      const { data: crop } = await axios
        .get(cropUrl, {
          headers: { common: { Authorization: `Bearer ${getAccessToken()}` } }
        })
        .catch(() => ({}));

      return crop;
    };

    const { promise } = plansAPI.fetch(id);
    const { data: plan } = await promise.catch(() => ({}));

    if (plan) {
      const mappedCropZonesPromise = plan.cropZones.map(async cropZone => {
        const {
          cropZone: cropZoneInfo,
          field,
          farm,
          crop
        } = await getPropertyExtraData(cropZone.cropZoneIdValue);

        return {
          ...cropZone,
          farmName: farm.name,
          fieldName: field.name,
          cropZoneName: cropZoneInfo.name,
          cropName: crop.name
        };
      });

      const { promise: cropSeasonPromise } = seasonApi.fetch(
        plan.cropSeasonIds[0]
      );

      const promises = await Promise.allSettled([
        ...mappedCropZonesPromise,
        cropSeasonPromise,
        getCrop(plan.cropId)
      ]);

      const mappedCropZones = promises.slice(0, -2);
      const cropSeasons = promises.slice(-2, -1);
      const crop = promises.slice(-1);
      const areaSum = sumFieldAcres(plan.cropZones);
      const totalCost = getVarCost(plan);
      const projNetRevPerArea = getPlanProjNetRevenuePerArea(plan);
      const projNetRev = getPlanProjNetRevenue(plan);
      const generatedDate = new Date();
      const year = format(generatedDate, 'yyyy');
      const fullDate = format(generatedDate, 'MM/dd/yyyy h:mm aaa');
      const totalFixedCost = getPlanFixedCost(plan);

      setPlanToPdf({
        ...plan,
        cropZones: fieldsToTable(
          mappedCropZones
            .filter(request => request.status === 'fulfilled')
            .map(request => request.value)
        ),
        organizationName: organization.name,
        cropSeasons: cropSeasons
          .filter(request => request.status === 'fulfilled')
          .map(request => request.value.data.name),
        cropName: crop[0].value?.name,
        planProductEntries: mapProductsToTable(plan.planProductEntries),
        fixedCosts: fixedCostsToTable(plan, totalFixedCost),
        areaSum,
        totalCost,
        costPerArea: totalCost / plan.estimatedAreaValue,
        projNetRevPerArea,
        projNetRev,
        year,
        fullDate,
        totalFixedCost
      });
    }

    setDownloading(false);
  };

  const handleAction = (action, id = null) => {
    const actions = {
      edit,
      tag: () => setCurrentOpenModal('tag'),
      duplicate,
      convertTask: () => setCurrentOpenModal('convertToTask'),
      convertToRec: () => setCurrentOpenModal('convertToRec'),
      shareOrPrint,
      delete: deleteOpt
    };

    actions[action](id);
  };

  return {
    handleAction,
    currentOpenModal,
    setCurrentOpenModal,
    downloadingPDF: downloading,
    shareOrPrint,
    setLoadingTags,
    loadingTags
  };
};

usePlanEllipsisOptions.propTypes = {
  plan: PropTypes.shape({})
};

usePlanEllipsisOptions.defaultProps = {
  plan: {}
};

export default usePlanEllipsisOptions;
