/* eslint-disable import/prefer-default-export */

/**
 * Represents the initial state for any network request.
 * @type {{data: null, isLoading: boolean, isError: boolean, isSuccess: boolean}}
 */
export const initialRequestState = {
  data: null,
  isLoading: false,
  isError: false,
  isSuccess: false
};

/**
 * Updates the state to indicate that a request has started.
 * @param {Object} status - The current state of the request.
 * @returns {Object} The new state object with `isLoading` set to true.
 */
export const onRequestStart = status => ({
  ...status,
  isLoading: true
});

/**
 * Updates the state to indicate that a request has failed.
 * @param {Object} status - The current state of the request.
 * @param {Error} error - The error object encountered during the request.
 * @returns {Object} The new state object with `isError` set to true and includes the error.
 */
export const onRequestFail = (status, error) => ({
  ...status,
  data: null,
  isError: true,
  isSuccess: false,
  error
});

/**
 * Updates the state to indicate that a request has succeeded.
 * @param {Object} status - The current state of the request.
 * @param {Object} [data=null] - The data returned from the request.
 * @returns {Object} The new state object with `isSuccess` set to true and includes the data.
 */
export const onRequestSuccess = (status, data = null) => ({
  ...status,
  isError: false,
  isSuccess: true,
  error: null,
  data
});

/**
 * Updates the state to indicate that a request process has ended.
 * @param {Object} status - The current state of the request.
 * @returns {Object} The new state object with `isLoading` set to false.
 */
export const onRequestEnd = status => ({
  ...status,
  isLoading: false
});
