import PropTypes from 'prop-types';
import React, { useState, useMemo, useEffect } from 'react';
import {
  Input,
  Table,
  Select,
  Toast,
  SimpleModal,
  Spinner
} from '@agconnections/grow-ui';
import { useFormikContext, FieldArray } from 'formik';
import GrowUIFormField from 'components/Forms/GrowUIFormField';
import MapIllustration from 'assets/properties-map-icon.svg';
import FieldIcon from 'components/Icons/FieldIcon';
import ChecklistProperty from 'screens/CropSeasons/CropSeason/components/FormSection/GroupByFilterProperty';
import ChecklistCrop from 'screens/CropSeasons/CropSeason/components/FormSection/GroupByFilterCrop';
import { mappingShape } from 'screens/Property/helpers/propertyDataHelpers';
import {
  calcConversions,
  calcSpecialCase,
  specialCaseUnit,
  unitsAreSame
} from 'helpers/unitConversionHelpers';
import usePropertyData from 'hooks/usePropertyData';
import { adjustZoneAcreValues } from 'utilities/helpers';
import { filterArchived, getArchivedCropZones } from 'helpers/archivedHelpers';

import { TAB_OPTIONS } from 'utilities/menus';
import EllipseMenu from 'components/EllipseMenu';
import { addSelectedCropSeasonsToFarms } from 'helpers/propertyHelpers';
import { roundPercentToArea, roundAreaToPercent } from './helpers';
import PropertiesModalBody from '../PropertiesModalBody';

function PropertiesTab({
  propertyLandingPageData,
  loading,
  fieldsAndAreasGeoJSONCollection,
  selectedCropseasons
}) {
  const { values, setFieldValue, setFieldTouched } = useFormikContext();

  const [selectedCropzoneIds, setSelectedCropzoneIds] = useState(
    values.properties?.map(cz => cz.cropZoneId)
  );
  const [editingProperty, setEditingProperty] = useState(null);

  const [toastRenderContents, setToastRenderContents] = useState(null);
  const [toastHasNotBeenClosed, setToastHasNotBeenClosed] = useState(true);
  const [noCropSeason, setNoCropSeason] = useState(true);

  const { originalTotalArea, setProperties } = usePropertyData();
  const [initialCropzoneIds] = useState(
    values.properties?.map(cz => cz.cropZoneId)
  );
  const [archivedCropZones, setArchivedCropZones] = useState(
    getArchivedCropZones(propertyLandingPageData) ?? []
  );

  const [
    filteredByArchivedPropertyLandingPageData,
    setFilteredByArchivedPropertyLandingPageData
  ] = useState(
    filterArchived(propertyLandingPageData, initialCropzoneIds) ?? []
  );
  const [originalCropzonesValues] = useState(values.properties.length);

  const properties = addSelectedCropSeasonsToFarms(
    filteredByArchivedPropertyLandingPageData,
    values.cropSeasonIds
  );
  useEffect(() => {
    setFilteredByArchivedPropertyLandingPageData(
      filterArchived(propertyLandingPageData, initialCropzoneIds)
    );

    setArchivedCropZones(getArchivedCropZones(propertyLandingPageData));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [propertyLandingPageData]);

  const checkForFFTChanges = () => {
    return (
      originalCropzonesValues !== selectedCropzoneIds.length &&
      setFieldTouched('FFT Changed')
    );
  };

  const selectedCropzones = useMemo(() => {
    const selectedCropzoneData = [];
    if (properties?.length > 0) {
      properties.forEach(property => {
        if (property.fields.length > 0) {
          property.fields.forEach(field => {
            field.cropzones.forEach(cz => {
              const matchingProperty = values.properties.find(
                prop => prop.cropZoneId === cz.id
              );
              if (selectedCropzoneIds?.includes(cz.id)) {
                selectedCropzoneData.push({
                  ...cz,
                  farmId: matchingProperty?.farmId || property.id,
                  fieldName: field.name,
                  features: fieldsAndAreasGeoJSONCollection.features?.filter(
                    feature => field.id === feature.properties.id
                  ),
                  areaValue: matchingProperty?.areaValue,
                  coveragePct: matchingProperty ? values.coveragePercent : 100
                });
              }
            });
          });
        }
      });
    }
    return selectedCropzoneData;

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedCropzoneIds, loading]);

  useEffect(() => {
    if (!values?.cropSeasonIds) {
      setNoCropSeason(true);
      setToastRenderContents(
        'Please select a cropseason on the Details Tab before selecting a property.'
      );
      setToastHasNotBeenClosed(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    // this entire function needs to be simplified and testable
    const sanitizedArray = [];
    const czIds = selectedCropzones.map(cz => cz.id);
    const existingProperties = values.properties
      .filter(property => czIds.includes(property.cropZoneId))
      .filter(property => property.hydrated === true);
    const existingPropertyIds = existingProperties.map(
      property => property.cropZoneId
    );
    const propertiesIdsToAdd = existingPropertyIds.filter(x =>
      czIds.includes(x)
    );

    const getAreaValue = zone => {
      let area = Math.round((zone.boundaryArea + Number.EPSILON) * 100) / 100;
      if (zone.areaValue) {
        area = zone.areaValue;
      } else if (zone.reportedArea > 0) {
        area = Math.round((zone.reportedArea + Number.EPSILON) * 100) / 100;
      }
      return area;
    };
    selectedCropzones
      .filter(cz => !propertiesIdsToAdd.includes(cz.id))
      .forEach(cropzone => {
        const savedAreaValue = selectedCropzones.find(
          prop => prop.id === cropzone.id
        )?.areaValue;

        const areaValue = cropzone.reportedArea || cropzone.boundaryArea;
        const reportedOrBoundaryArea =
          cropzone.reportedArea > 0
            ? cropzone.reportedArea
            : cropzone.boundaryArea;
        const areaValueToUse = savedAreaValue || reportedOrBoundaryArea;

        const obj = {
          farmId: cropzone.farmId,
          cropZoneId: cropzone.id,
          areaUnit: 'acres',
          originalAcres: Math.round((areaValue + Number.EPSILON) * 100) / 100,
          areaValue: getAreaValue(cropzone),
          coveragePct:
            Math.round((areaValueToUse / reportedOrBoundaryArea) * 10000) / 100,
          boundaryArea:
            Math.round((cropzone.boundaryArea + Number.EPSILON) * 100) / 100,
          reportedArea:
            Math.round((cropzone.reportedArea + Number.EPSILON) * 100) / 100,
          areaType: cropzone.areaType || 'reported',
          name: cropzone.name,
          crop: cropzone.crop,
          features: cropzone.features,
          fieldName: cropzone.fieldName,
          hydrated: true
        };

        sanitizedArray.push(obj);
      });

    const props = [...existingProperties.slice(), ...sanitizedArray];
    setFieldValue('properties', props);
    setProperties(props);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedCropzones, setFieldValue]);

  const getTotalArea = () =>
    values.properties.reduce((acc, curr) => {
      return acc + curr.areaValue;
    }, 0);

  useEffect(() => {
    checkForFFTChanges();
    if (values?.cropSeasonIds) {
      setNoCropSeason(false);
    }
    const totalArea = getTotalArea();
    setFieldValue(
      'totalApplied',
      Math.round((totalArea + Number.EPSILON) * 100) / 100
    );
    setFieldValue(
      'totalAreaValue',
      Math.round((totalArea + Number.EPSILON) * 100) / 100
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values.properties]);

  const handlePropertySelect = cropzones => {
    setSelectedCropzoneIds(cropzones);
    if (cropzones.length === 0) {
      setFieldValue('totalApplied', 0);
      setFieldValue('totalAreaValue', 0);
      setFieldValue('coveragePercent', 100);
      setFieldValue('properties', []);
    }
  };

  const handleChange = e => {
    const dropDownValue = JSON.parse(e.target.value);
    setFieldValue('propertiesGroupBy', dropDownValue.key);
  };

  useEffect(() => {
    const mappedProducts = values.products.map(product => {
      const appliedAreaValue =
        (values?.totalAreaValue * product.coveragePercent) / 100;

      const subtotalProduct = appliedAreaValue * product.ratePerAreaValue;
      const specialCase = specialCaseUnit.includes(product.totalProductUnit);
      const totalUnitIsSame = unitsAreSame(
        product.ratePerAreaValue,
        product.totalProductUnit
      );
      const conversionFactor = product.density;
      const rateUnitIsPackageUnit = unitsAreSame(
        product.ratePerAreaUnit,
        product.stdpackageunit
      );

      let totalProduct = 0;

      if (specialCase) {
        totalProduct = calcSpecialCase(
          totalUnitIsSame,
          subtotalProduct,
          1,
          conversionFactor,
          rateUnitIsPackageUnit
        );
      } else {
        totalProduct = calcConversions(
          subtotalProduct,
          product.ratePerAreaUnit,
          product.totalProductUnit,
          totalUnitIsSame,
          product
        );
      }

      return {
        ...product,
        appliedAreaValue,
        totalProductValue: totalProduct
      };
    });
    setFieldValue('products', mappedProducts);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values?.totalAreaValue, setFieldValue]);

  useEffect(() => {
    setFieldValue('cropSeasonIds', selectedCropseasons);
  }, [selectedCropseasons, setFieldValue]);

  const handleAction = (action, property) => {
    const actions = {
      edit: () => setEditingProperty(property),
      remove: () =>
        handlePropertySelect(map =>
          map.filter(item => item !== property.cropZoneId)
        )
    };
    actions[action]();
  };

  useEffect(() => {
    const newPercent = roundAreaToPercent(
      values.totalApplied,
      originalTotalArea
    );

    if (
      newPercent !== Number(values.coveragePercent) &&
      !Number.isNaN(newPercent) &&
      Number.isFinite(newPercent)
    ) {
      setFieldValue('coveragePercent', newPercent);
    }
  }, [
    values?.totalApplied,
    originalTotalArea,
    setFieldValue,
    values?.coveragePercent
  ]);

  return (
    <div className="-mx-2 -mr-6">
      {toastHasNotBeenClosed ? (
        <Toast
          icon="error"
          onClose={() => {
            setToastHasNotBeenClosed(false);
          }}
        >
          {toastRenderContents}
        </Toast>
      ) : null}
      <div className="flex justify-end mb-6">
        <div className="mr-6">
          <GrowUIFormField
            label="Total Applied"
            control={Input}
            name="totalApplied"
            disabled={
              values.properties.length === 0 || values.coveragePercent === 0
            }
            onBlur={e => {
              setFieldTouched('Total Applied');
              setFieldValue('totalApplied', Number(e.target.value));
              if (Number(e.target.value) !== Number(e.target.defaultValue)) {
                const newPercent = roundAreaToPercent(
                  Number(e.target.value),
                  originalTotalArea
                );

                setFieldValue(
                  'properties',
                  adjustZoneAcreValues(
                    newPercent,
                    roundAreaToPercent(
                      Number(e.target.defaultValue),
                      originalTotalArea
                    ),
                    values.properties
                  )
                );
                setFieldValue('coveragePercent', newPercent);
              }
            }}
            imask={{
              mask: 'num',
              lazy: false,
              blocks: {
                num: {
                  mask: Number,
                  scale: 2,
                  signed: false,
                  thousandsSeparator: '',
                  padFractionalZeros: false,
                  normalizeZeros: true,
                  radix: '.',
                  mapToRadix: [','],
                  min: 0
                }
              }
            }}
          />
        </div>
        <GrowUIFormField
          label="Coverage %"
          control={Input}
          name="coveragePercent"
          disabled={values.properties.length === 0}
          onBlur={e => {
            setFieldTouched('Coverage');
            setFieldValue('coveragePercent', Number(e.target.value));
            if (Number(e.target.value) !== Number(e.target.defaultValue)) {
              setFieldValue(
                'properties',
                adjustZoneAcreValues(
                  Number(e.target.value),
                  Number(e.target.defaultValue),
                  values.properties
                )
              );
            }
          }}
          imask={{
            mask: 'num',
            lazy: false,
            blocks: {
              num: {
                mask: Number,
                scale: 2,
                signed: false,
                thousandsSeparator: '',
                padFractionalZeros: false,
                normalizeZeros: false,
                radix: '.',
                mapToRadix: [','],
                min: 0
              }
            }
          }}
        />
      </div>
      <div className="flex">
        <div className="w-1/4 pr-8">
          <div className="mb-3">
            <GrowUIFormField
              // eslint-disable-next-line prettier/prettier
              style={{ whiteSpace: 'nowrap' }}
              control={Select}
              name="propertyFilter"
              placeholder="Filter by"
              data-testid="propertyFilter"
              items={[
                { key: 'Farm', value: 'Farm' },
                { key: 'Season', value: 'Season' },
                { key: 'Crop', value: 'Crop' },
                { key: 'Tag', value: 'Tag' }
              ]}
            />
          </div>
          <div className="mb-3">
            <GrowUIFormField
              control={Select}
              name="propertiesGroupBy"
              items={[
                { key: 'property', value: 'Group By Farms' },
                { key: 'crop', value: 'Group By Crop' }
              ]}
              onChange={e => {
                handleChange(e);
              }}
            />
          </div>
          <div className="mb-3">
            {/* <div className="mb-4 text-sm font-semibold uppercase">
              Properties
            </div> */}
            {!loading ? (
              <>
                {values.propertiesGroupBy === 'property' ? (
                  <ChecklistProperty
                    data={properties}
                    onChange={handlePropertySelect}
                    value={values.properties}
                    cropZonesChecked={selectedCropzoneIds}
                    setCropZonesChecked={setSelectedCropzoneIds}
                    disabled={loading || noCropSeason}
                  />
                ) : (
                  <ChecklistCrop
                    data={properties}
                    onChange={handlePropertySelect}
                    value={values.properties}
                    cropZonesChecked={selectedCropzoneIds}
                    setCropZonesChecked={setSelectedCropzoneIds}
                    disabled={loading || noCropSeason}
                  />
                )}
              </>
            ) : (
              <Spinner />
            )}
          </div>
        </div>
        <div className="flex flex-col w-3/4">
          <div className="flex flex-col h-full">
            <Table>
              <Table.Header>
                <Table.Cell width="30%">
                  Fields ({values?.properties?.length})
                </Table.Cell>
                <Table.Cell>Crops</Table.Cell>
                <Table.Cell>Area</Table.Cell>
                <Table.Cell>Coverage %</Table.Cell>
                <Table.Cell />
              </Table.Header>
              {values.properties?.map(property => {
                const ft = property.features?.[0]
                  ? [mappingShape(property.features[0])]
                  : undefined;

                const isPropertyArchived =
                  archivedCropZones.indexOf(property.cropZoneId) !== -1;

                return (
                  <Table.Row>
                    <Table.Cell>
                      <div className="flex items-center">
                        <FieldIcon id="field-card-icon" features={ft} />
                        <div className="flex flex-col justify-start leading-5 text-left">
                          <span className="font-bold text-neutral-1000">
                            {property.fieldName}
                            {isPropertyArchived ? ' (archived) ' : ''}
                          </span>
                          <span style={{ color: '#707374' }}>
                            {property.name}
                          </span>
                        </div>
                      </div>
                    </Table.Cell>
                    <Table.Cell>{property.crop?.name}</Table.Cell>
                    <Table.Cell>
                      {property.areaValue} {property.areaUnit}
                    </Table.Cell>
                    <Table.Cell>{property?.coveragePct}</Table.Cell>
                    <Table.Cell>
                      <EllipseMenu
                        onAction={action => handleAction(action, property)}
                        options={TAB_OPTIONS}
                      />
                    </Table.Cell>
                  </Table.Row>
                );
              })}
            </Table>
            {!values.properties?.length && (
              <div className="flex flex-col items-center self-stretch justify-center h-full py-32 border border-t-0">
                <img src={MapIllustration} alt="No properties illustration" />
                <p>
                  Select one or more fields from the list to add it to this
                  Recommendation
                </p>
              </div>
            )}
          </div>
        </div>
      </div>
      <>
        {editingProperty && (
          <FieldArray name="properties">
            {({ replace }) => (
              <SimpleModal
                confirmLabel="Save"
                open={editingProperty}
                onConfirm={() => {
                  setFieldTouched('Field');
                  replace(
                    values.properties
                      .map(property => property.cropZoneId)
                      .indexOf(editingProperty?.cropZoneId),
                    editingProperty
                  );
                  setEditingProperty(null);
                }}
                close={() => setEditingProperty(null)}
              >
                <PropertiesModalBody
                  property={editingProperty}
                  readonly={false}
                  onAreaReportedChange={e => {
                    const _area =
                      e === 'boundary'
                        ? editingProperty?.boundaryArea
                        : editingProperty?.reportedArea;
                    setEditingProperty({
                      ...editingProperty,
                      areaType: e,
                      areaValue: _area,
                      coveragePct:
                        editingProperty.areaType === 'boundary'
                          ? roundAreaToPercent(
                              _area,
                              editingProperty?.boundaryArea
                            )
                          : roundAreaToPercent(
                              _area,
                              editingProperty?.reportedArea
                            )
                    });
                  }}
                  onAreaAppliedChange={e => {
                    const area = Number(e.target.value);
                    setEditingProperty({
                      ...editingProperty,
                      areaValue: area,
                      coveragePct:
                        editingProperty.areaType === 'boundary'
                          ? roundAreaToPercent(
                              area,
                              editingProperty.boundaryArea
                            )
                          : roundAreaToPercent(
                              area,
                              editingProperty.reportedArea
                            )
                    });
                  }}
                  onZoneCoveragePercentChange={e => {
                    const coverage = Number(e.target.value);
                    setEditingProperty({
                      ...editingProperty,
                      coveragePct: coverage,
                      areaValue: roundPercentToArea(
                        coverage,
                        editingProperty?.originalAcres
                      )
                    });
                  }}
                />
              </SimpleModal>
            )}
          </FieldArray>
        )}
      </>
    </div>
  );
}

PropertiesTab.propTypes = {
  propertyLandingPageData: PropTypes.shape({
    properties: PropTypes.arrayOf(PropTypes.object)
  }).isRequired,
  loading: PropTypes.bool.isRequired,
  fieldsAndAreasGeoJSONCollection: PropTypes.shape({
    features: PropTypes.arrayOf(
      PropTypes.shape({
        properties: PropTypes.shape({
          id: PropTypes.string
        })
      })
    )
  }).isRequired,
  selectedCropseasons: PropTypes.arrayOf(PropTypes.object).isRequired
};

export default PropertiesTab;
