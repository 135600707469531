import React, { useContext, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { Input, Popup } from '@agconnections/grow-ui';
import { Checkbox } from 'syngenta-digital-cropwise-react-ui-kit';
import ChevronDown from 'assets/chevron_down.svg';
import useCropSeasons from 'hooks/useCropSeasons';
import featureFlagRenderItem from 'helpers/featureFlagRenderItem';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { Context } from 'components/Store';
import CropSeasonsTags from './CropSeasonTags';

const Divider = () => <div className="w-full my-2 h-px bg-neutral-30" />;

const ProductDetailCropSeasonSelect = ({ onChange, values, selectValue }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [search, setSearch] = useState('');
  const { cropSeasons } = useCropSeasons();
  const {
    releaseFeInventoryTaskListPriceUpdate,
    releaseFeInventoryTaskListPriceUpdateByOrg
  } = useFlags();
  const [{ organization }] = useContext(Context);

  const cropSeasonsItems = useMemo(() => {
    return (
      cropSeasons?.map(crop => {
        let type = 'active';
        const now = new Date();
        const startDate = new Date(crop.startDate);

        if (!crop.active && startDate > now) {
          type = 'future';
        } else if (!crop.active) {
          type = 'past';
        }

        return {
          key: crop.id,
          value: crop.name,
          type
        };
      }) ?? []
    );
  }, [cropSeasons]);

  const cropSeasonsSelectItems = useMemo(() => {
    return cropSeasonsItems.filter(
      crop => crop.value.toLowerCase().indexOf(search.toLowerCase()) > -1
    );
  }, [cropSeasonsItems, search]);

  const isChecked = id => values.includes(id);

  const internalOnChange = id => {
    if (onChange) {
      let newValues = [...values];
      const isItemChecked = isChecked(id);

      if (isItemChecked) {
        newValues = newValues.filter(itemId => itemId !== id);
      } else {
        newValues.push(id);
      }

      onChange(newValues);
    }
  };

  const Trigger = (
    <div>
      {selectValue !== 'Completed Tasks' ||
      featureFlagRenderItem(
        releaseFeInventoryTaskListPriceUpdate,
        releaseFeInventoryTaskListPriceUpdateByOrg,
        organization?.id
      ) ? (
        <button
          className="w-full h-8 py-1 px-2 rounded border border-neutral-30 overflow-hidden"
          aria-label="crop season"
          type="button"
          onClick={() => setIsOpen(curr => !curr)}
        >
          <div className="flex w-full h-full items-center justify-between">
            {values.length === 0 ? (
              <span className="text-neutral-60">Filter by season</span>
            ) : (
              <CropSeasonsTags
                ids={values}
                seasons={cropSeasons ?? []}
                onRemove={internalOnChange}
              />
            )}
            <img width={18} height={18} alt="chevron down" src={ChevronDown} />
          </div>
        </button>
      ) : null}
    </div>
  );

  const renderCheckBox = item => {
    const isItemChecked = isChecked(item.key);
    return (
      <li
        key={item.key}
        className={`flex items-center gap-2 px-4 py-1 hover:bg-neutral-100 select-none ${
          isItemChecked ? 'bg-banner-alert-blue-10' : ''
        }`}
      >
        <Checkbox
          id={`checkbox_${item.key}`}
          checked={isItemChecked}
          onChange={() => internalOnChange(item.key)}
        />
        <label htmlFor={`checkbox_${item.key}`}>{item.value}</label>
      </li>
    );
  };

  const listTitleClassname =
    'text-xs text-neutral-60 font-semibold text-left px-3 mb-1';

  const emptyTextClassname = 'text-normal text-neutral-60 text-left px-3';

  const activeCropSeasonItems = cropSeasonsSelectItems.filter(
    item => item.type === 'active'
  );

  const upcomingCropSeasonItems = cropSeasonsSelectItems.filter(
    item => item.type === 'future'
  );

  const pastCropSeasonItems = cropSeasonsSelectItems.filter(
    item => item.type === 'past'
  );

  return (
    <Popup
      containerClasses="w-full"
      position="bottom"
      align="right"
      trigger={Trigger}
      open={isOpen}
      onClose={() => setIsOpen(false)}
    >
      <div className="max-h-330px flex flex-col gap-4 px-2 py-2">
        <div className="w-full">
          <Input
            id="search"
            name="search"
            placeholder="Search crop seasons"
            value={search}
            onChange={e => setSearch(e.target.value)}
          />
        </div>
        <div className="flex-1 rounded border border-neutral-30 overflow-auto py-3">
          <p className={listTitleClassname}>Active Seasons</p>
          {activeCropSeasonItems.length > 0 ? (
            <ul>{activeCropSeasonItems.map(item => renderCheckBox(item))}</ul>
          ) : (
            <p className={emptyTextClassname}>
              There are no active seasons for this organization.
            </p>
          )}
          <Divider />
          <p className={listTitleClassname}>Upcoming Seasons</p>
          {upcomingCropSeasonItems.length > 0 ? (
            <ul>{upcomingCropSeasonItems.map(item => renderCheckBox(item))}</ul>
          ) : (
            <p className={emptyTextClassname}>
              There are no upcoming seasons for this organization.
            </p>
          )}
          <Divider />
          <p className={listTitleClassname}>Past Seasons</p>
          {pastCropSeasonItems.length > 0 ? (
            <ul>{pastCropSeasonItems.map(item => renderCheckBox(item))}</ul>
          ) : (
            <p className={emptyTextClassname}>
              There are no past seasons for this organization.
            </p>
          )}
        </div>
      </div>
    </Popup>
  );
};

ProductDetailCropSeasonSelect.propTypes = {
  onChange: PropTypes.func.isRequired,
  values: PropTypes.arrayOf(PropTypes.string).isRequired,
  selectValue: PropTypes.string.isRequired
};

export default ProductDetailCropSeasonSelect;
