import React from 'react';
import PropTypes from 'prop-types';
import { Form, Input } from '@agconnections/grow-ui';
import GrowUIFormField from 'components/Forms/GrowUIFormField';

const ColumnWithFieldsLeft = ({
  labelTitles,
  LabelInputs,
  divider,
  setValueSelected,
  errorIcon,
  setErrortext
}) => (
  <div className="flex flex-col w-126">
    <div className="flex flex-row">
      <Form.Field
        label={
          <div className="flex flex-row">
            {labelTitles.name}
            <div className="text-remove">*</div>
          </div>
        }
        labelFor={LabelInputs.name}
      >
        <GrowUIFormField
          control={Input}
          id={LabelInputs.name}
          name={LabelInputs.name}
          onChangeCapture={e => setValueSelected(e.target.value)}
          style={
            errorIcon
              ? {
                  background: `url("/static/media/productValidationAlert.1372f4cb.svg") no-repeat right`,
                  backgroundSize: '20px',
                  border: '1px solid red'
                }
              : {}
          }
        />
        <span className="gr-absolute gr-text-red-600 gr-text-xs gr-my-1 gr-mr-2 gr-whitespace-no-wrap gr-truncate gr-visible">
          {setErrortext}
        </span>
      </Form.Field>
      {divider('w-6')}
      <Form.Field label={labelTitles.email} labelFor={LabelInputs.email}>
        <GrowUIFormField
          control={Input}
          id={LabelInputs.email}
          name={LabelInputs.email}
        />
      </Form.Field>
    </div>
    <Form.Field
      label={labelTitles.addressLineOne}
      labelFor={LabelInputs.addressLineOne}
    >
      <GrowUIFormField
        control={Input}
        id={LabelInputs.addressLineOne}
        name={LabelInputs.addressLineOne}
      />
    </Form.Field>
    <Form.Field label={labelTitles.city} labelFor={LabelInputs.city}>
      <GrowUIFormField
        control={Input}
        id={LabelInputs.city}
        name={LabelInputs.city}
      />
    </Form.Field>
  </div>
);
ColumnWithFieldsLeft.defaultProps = {
  errorIcon: false,
  setErrortext: ''
};

ColumnWithFieldsLeft.propTypes = {
  labelTitles: PropTypes.shape({
    name: PropTypes.string.isRequired,
    email: PropTypes.string.isRequired,
    addressLineOne: PropTypes.string.isRequired,
    city: PropTypes.string.isRequired
  }).isRequired,
  LabelInputs: PropTypes.shape({
    name: PropTypes.string.isRequired,
    email: PropTypes.string.isRequired,
    addressLineOne: PropTypes.string.isRequired,
    city: PropTypes.string.isRequired
  }).isRequired,
  divider: PropTypes.func.isRequired,
  setValueSelected: PropTypes.func.isRequired,
  setErrortext: PropTypes.string,
  errorIcon: PropTypes.bool
};

export default ColumnWithFieldsLeft;
