import React from 'react';
import PropTypes from 'prop-types';

const DeleteIcon = ({ color, width, height, widthBox, heightBox }) => (
  <svg
    width={width}
    height={height}
    viewBox={`0 0 ${width - widthBox} ${height - heightBox}`}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    data-testid="delete-icon-svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M11.375 2.25H7.625L6.875 3H4.25V4.5H14.75V3H12.125L11.375 2.25ZM12.5 6.75V14.25H6.5V6.75H12.5ZM5 5.25H14V14.25C14 15.075 13.325 15.75 12.5 15.75H6.5C5.675 15.75 5 15.075 5 14.25V5.25Z"
      fill={`${color}`}
    />
  </svg>
);
DeleteIcon.propTypes = {
  color: PropTypes.string,
  height: PropTypes.string,
  width: PropTypes.string,
  widthBox: PropTypes.string,
  heightBox: PropTypes.string
};

DeleteIcon.defaultProps = {
  color: '#CF3537',
  height: '18',
  width: '19',
  widthBox: 0,
  heightBox: 1
};

export default DeleteIcon;
