import React from 'react';
import PropTypes from 'prop-types';
import ContributionMargin from './components/ContributionMargin';

const PropertyCostAnalysis = ({ selectedCrop }) => {
  return <ContributionMargin selectedCrop={selectedCrop} />;
};

PropertyCostAnalysis.defaultProps = {
  selectedCrop: null
};

PropertyCostAnalysis.propTypes = {
  selectedCrop: PropTypes.string
};

export default PropertyCostAnalysis;
