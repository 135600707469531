import React, { useState, useEffect } from 'react';
import GrowUIFormField from 'components/Forms/GrowUIFormField';
import { Search } from '@agconnections/grow-ui';
import { useFormikContext } from 'formik';
import scus from 'state-counties-us';

const County = () => {
  const { values } = useFormikContext();
  const [captionText, setCaptionText] = useState();

  const countyByState = stateInitials => {
    if (stateInitials?.length > 0) {
      const countiesByState = scus.getCountiesByState(stateInitials) || [];
      const uniqueCounties = countiesByState.map(county => {
        return { key: county, value: county };
      });
      return uniqueCounties;
    }
    return null;
  };

  const isStateEmpty = !values.state || values.state === 'null';

  useEffect(() => {
    if (isStateEmpty) {
      setCaptionText('You must first select a state.');
    } else {
      setCaptionText(null);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values.state]);

  return (
    <GrowUIFormField
      control={Search}
      value={values?.county}
      errorMessage={captionText}
      name="county"
      label="County"
      disabled={isStateEmpty}
      items={countyByState(values?.state)}
      placeholder="Select County"
    />
  );
};
export default County;
