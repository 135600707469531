/* eslint-disable array-callback-return */
import React, { useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import orderBy from 'lodash/fp/orderBy';
import { Table } from '@agconnections/grow-ui';
import { FETCH_TYPES } from 'helpers/constants';
import LoadingTableRow from 'components/LoadingTableRow';
import { getCropZonesFromProperties } from 'helpers/propertyHelpers';
import TableHeader from 'components/LandingTable/components/TableHeader';
import usePopulateMapData from 'hooks/usePopulateMapData';
import PropertyTableRow from './components/PropertyTableRow';

const COLUMN_SORT_FUNCTIONS = {
  fields: (direction, properties) =>
    orderBy([property => property.fieldName], direction, properties),
  crops: (direction, properties) =>
    orderBy([property => property.cropName], direction, properties),
  area: (direction, properties) =>
    orderBy(
      [property => `${property.areaValue}_${property.areaUnit}`],
      direction,
      properties
    ),
  coveragePercent: (direction, properties) =>
    orderBy([property => property.coveragePercent], direction, properties)
};
const fillRows = (
  propertyData,
  recommendation,
  fieldsAndAreasGeoJSONCollection
) => {
  const rowDataObjects = [];
  /* eslint no-unused-expressions: "off", curly: "error" */
  propertyData?.properties?.map(property => {
    /* eslint no-unused-expressions: "off", curly: "error" */
    property.fields?.map(field => {
      /* eslint no-unused-expressions: "off", curly: "error" */
      field.cropzones?.map(zone => {
        /* eslint no-unused-expressions: "off", curly: "error" */
        recommendation?.cropZones?.map(recommendationCropZone => {
          /* eslint no-unused-expressions: "off", curly: "error" */
          if (zone.id === recommendationCropZone.cropZoneIdValue) {
            const row = {
              farmId: property.id,
              cropZoneId: zone.id,
              fieldName: field.name,
              cropZoneName: zone.name,
              cropName: zone.crop?.name,
              areaValue: recommendationCropZone.areaValue,
              areaUnit: recommendationCropZone.areaUnit,
              boundaryArea: zone.boundaryArea,
              reportedArea: zone.reportedArea,
              coveragePercent: recommendation.coveragePercent / 100,
              features: fieldsAndAreasGeoJSONCollection.features?.filter(
                feature => field.id === feature.properties.id
              )
            };
            rowDataObjects.push(row);
          }
        });
      });
    });
  });
  return rowDataObjects;
};
const PropertiesSection = ({ recommendation }) => {
  const [rowsData, setRowsData] = useState([]);

  const {
    dataSorted: propertyData,
    fieldsAndAreasGeoJSONCollection,
    loading: propertiesLoading,
    fieldsLoading,
    reloadData
  } = usePopulateMapData({
    fetchType: FETCH_TYPES.propertiesMap,
    isCacheOn: true
  });

  const [sortBy] = useState();
  const [allCropZones, setAllCropZones] = useState([]);
  const [sortDirection] = useState();
  const [sortedRowsData, setSortedRowsData] = useState(rowsData ?? []);
  const tableColumns = useMemo(() => {
    return [
      {
        field: 'icons',
        title: '',
        sortable: false
      },
      {
        field: 'fields',
        title: `Fields (${recommendation.cropZones?.length ?? 0})`,
        sortable: false
      },
      {
        field: 'crops',
        title: 'Crops',
        sortable: false
      },
      {
        field: 'area',
        title: 'Area',
        sortable: false
      },
      {
        field: 'coveragePercent',
        title: 'Coverage %',
        sortable: false
      },
      {
        field: 'actions',
        title: '',
        sortable: false
      }
    ];
  }, [recommendation.cropZones?.length]);

  useEffect(() => {
    if (propertyData?.properties?.length > 0) {
      const rowDt = fillRows(
        propertyData,
        recommendation,
        fieldsAndAreasGeoJSONCollection
      );
      setAllCropZones(getCropZonesFromProperties(propertyData?.properties));
      setRowsData(rowDt);
    }
  }, [
    propertyData,
    recommendation,
    fieldsAndAreasGeoJSONCollection,
    fieldsLoading
  ]);

  useEffect(() => {
    reloadData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const sortFunction = COLUMN_SORT_FUNCTIONS[sortBy ?? ''];
    if (sortFunction && sortDirection) {
      setSortedRowsData(sortFunction(sortDirection, rowsData));
    } else {
      setSortedRowsData(rowsData);
    }
  }, [rowsData, sortBy, sortDirection]);

  return (
    <div
      data-testid="properties-section"
      className="mb-6"
      id="rec-pagebreaker-1"
    >
      <div className="flex justify-between pt-4 mb-8">
        <div className="text-2xl font-bold">Properties</div>
      </div>
      <Table>
        <TableHeader sortBy={sortBy ?? ''} tableColumns={tableColumns} />
        {fieldsLoading && propertiesLoading ? (
          <LoadingTableRow numCells={tableColumns.length} />
        ) : (
          sortedRowsData.map(row => (
            <PropertyTableRow
              key={row.cropZoneId}
              property={row}
              allCropZones={allCropZones}
            />
          ))
        )}
      </Table>
    </div>
  );
};

PropertiesSection.propTypes = {
  recommendation: PropTypes.shape({
    cropZones: PropTypes.arrayOf(
      PropTypes.shape({
        cropZoneIdValue: PropTypes.string,
        areaValue: PropTypes.number,
        areaUnit: PropTypes.string,
        coveragePct: PropTypes.string
      })
    )
  }).isRequired
};

export default PropertiesSection;
