import React from 'react';
import PropTypes from 'prop-types';

const DetailsContainer = ({ children, optionalClasses }) => {
  return (
    <div
      className={`shadow-card rounded-md bg-white h-auto w-96/100 p-8 ${optionalClasses}`}
      data-testid="details-container"
    >
      {children}
    </div>
  );
};
DetailsContainer.propTypes = {
  children: PropTypes.node.isRequired,
  optionalClasses: PropTypes.string
};

DetailsContainer.defaultProps = {
  optionalClasses: ''
};

export default DetailsContainer;
