import { useContext, useEffect, useMemo, useState } from 'react';
import useFormikHandler from '../../../hooks/useFormikHandler';
import usePopulateMapData from '../../../../../hooks/usePopulateMapData';
import { FETCH_TYPES } from '../../../../../helpers/constants';
import { PropertiesMatchContext } from '../../../PropertiesMatch/context/PropertiesMatchProvider';

const useRecommendationsFarmFieldTree = () => {
  const { selectedCropSeasons, selectedRecommendations } = useFormikHandler();
  const {
    dataSorted: propertyLandingPageData,
    reloadData,
    loading: isLoadingFFT
  } = usePopulateMapData({
    fetchType: FETCH_TYPES.cropSeasonsMap
  });
  const {
    fetchVendorProperties,
    fetchMatches,
    isLoading: isLoadingMatches,
    state: { vendorProperties, matches }
  } = useContext(PropertiesMatchContext);
  const [
    recommendationsFarmFieldTree,
    setRecommendationsFarmFieldTree
  ] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    reloadData({
      seasonIds: selectedCropSeasons?.map(season => season.id)
    });
    fetchVendorProperties();
    fetchMatches();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (isLoadingMatches || isLoadingFFT) {
      setLoading(true);
      return;
    }
    setLoading(false);
  }, [isLoadingMatches, isLoadingFFT]);

  useEffect(() => {
    if (!vendorProperties.length) {
      return;
    }

    const farms = new Map(
      selectedRecommendations.flatMap(r =>
        r.farms.map(farm => [farm.id, { ...farm, fields: [] }])
      )
    );

    const fields = new Map(
      selectedRecommendations.flatMap(r =>
        r.fields.map(field => [field.id, field])
      )
    );

    fields.forEach(fieldInRec => {
      const vendorField = vendorProperties.find(
        vendorProperty =>
          vendorProperty.id === fieldInRec.id &&
          farms.get(vendorProperty.farmId)
      );

      if (!vendorField) {
        return;
      }

      const recommendationFieldEqualToVendor = recField =>
        recField.id === vendorField.id;
      const recommendationWithVendorField = rec =>
        rec.fields.some(recommendationFieldEqualToVendor);
      vendorField.recommendations = selectedRecommendations.filter(
        recommendationWithVendorField
      );

      const cwfCropZoneId = matches?.find(
        match => match.vendorId === vendorField.id
      )?.cwfId;
      vendorField.cwfCropZone = propertyLandingPageData?.properties
        .flatMap(p => p.fields)
        .flatMap(f => f.cropzones)
        .find(c => c.id === cwfCropZoneId);

      if (vendorField) {
        farms.get(vendorField.farmId).fields.push({
          ...fieldInRec,
          ...vendorField
        });
      }
    });
    setRecommendationsFarmFieldTree([...farms].map(([, value]) => value));
  }, [
    vendorProperties,
    selectedCropSeasons,
    selectedRecommendations,
    matches,
    propertyLandingPageData
  ]);

  return useMemo(
    () => ({
      recommendationsFarmFieldTree,
      loading
    }),
    [recommendationsFarmFieldTree, loading]
  );
};

export default useRecommendationsFarmFieldTree;
