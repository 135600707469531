import faker from 'faker';
import { Factory } from 'miragejs';

const Invitation = {
  name: '',
  email: '',
  role() {
    return faker.random.arrayElement([
      'Admin',
      'FullControl',
      'Author',
      'ViewOnly'
    ]);
  },
  financial() {
    return faker.random.arrayElement(['edit', 'view', 'none']);
  },
  status() {
    return faker.random.arrayElement([
      'active',
      'accepted',
      'cancelled',
      'expired',
      'declined',
      'revoked'
    ]);
  },
  memberSince() {
    return faker.date.past(20);
  },
  // the following fields are not returned by GET /invites but are returned by POST /invites
  // and are also listed in the API document for Invitation
  inviteId() {
    return faker.random.uuid();
  },
  organizationId: '',
  invitedUserId() {
    return faker.random.uuid();
  },
  invitedOktaId() {
    return faker.internet.password(10, false, /[0-9A-Z]/);
  },
  senderUserId() {
    return faker.random.uuid();
  },
  senderOktaId() {
    return faker.internet.password(10, false, /[0-9A-Z]/);
  },
  createdAt() {
    return faker.date.recent();
  },
  expiry() {
    return faker.date.future(faker.random.number({ max: 77 }));
  },
  afterCreate(invite, server) {
    if (invite.attrs.organizationId === '') {
      const orgs = server.schema.organizations.all();
      const inviteOrg = faker.random.number({ min: 0, max: orgs.length - 1 });
      const org = orgs.models[inviteOrg];
      invite.update({ organizationId: org && org.attrs.id });
    }
  }
};
export default Factory.extend(Invitation);
