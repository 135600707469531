import React, { useCallback, useContext, useEffect, useState } from 'react';
import useAgrianApi from 'hooks/useAgrianApi';
import OrganizationsDropdown from 'components/OrganizationsDropdown';
import { Context } from 'components/Store';
import { INTEGRATIONS } from 'screens/Integrations/helpers/constants';
import useIntegrationMatching from 'hooks/useIntegrationMatching';
import useSubmissionAnalytics from 'screens/Integrations/hooks/useSubmissionAnalytics';
import { PropTypes } from 'prop-types';
import { AgrianContext } from '../../context/Provider';
import AgrianSubmissionFooter from '../AgrianSubmissionFooter';
import useFormikHandler from '../../../hooks/useFormikHandler';

const AgrianOrganization = ({ integrationType, title }) => {
  const { events, triggerAnalyticsEvent } = useSubmissionAnalytics();
  const { selectedOrganization, setSelectedOrganization } = useFormikHandler();
  const [organizations, setOrganizations] = useState([]);
  const { releaseStepForward, blockStepForward, goForward } = useContext(
    AgrianContext
  );
  const { loading, saveOrganizationMatch, growers } = useAgrianApi();
  const { getMatches, loading: loadingMatches } = useIntegrationMatching();
  const [{ organization }] = useContext(Context);

  useEffect(() => {
    async function growersMatch() {
      const response = await getMatches({
        vendor: INTEGRATIONS.agrian,
        matchTypes: ['Grower']
      });
      if (response?.data?.length) {
        setSelectedOrganization(
          response.data?.find(match => match.cwfEntityId === organization.id)
            ?.agrianEntityId
        );
      }
    }

    async function getGrowers() {
      const response = await growers();
      if (response) {
        setOrganizations(response.data);
        await growersMatch();
      }
    }

    getGrowers();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!selectedOrganization) blockStepForward();
    else releaseStepForward();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedOrganization]);

  const onNext = async () => {
    const selectedGrower = organizations.find(
      agrianOrg => agrianOrg.id === selectedOrganization
    );
    const result = await saveOrganizationMatch({
      growerId: selectedGrower?.id,
      organizationId: selectedGrower?.organization_id,
      growerDescription: selectedGrower?.name
    });
    if (result) {
      triggerAnalyticsEvent(
        events.epic.Integrations.submissionOrganizationFilled,
        { vendor: INTEGRATIONS.agrian }
      );
      goForward();
    }
  };

  const handleOrganizationSelect = useCallback(
    orgSelected => {
      setSelectedOrganization(orgSelected);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  return (
    <div
      className="flex flex-col self-center bg-white top-30 left-26 h-100 w-140 rounded-md mb-20 p-6 shadow-sm"
      data-testid="agrian-organization-selection"
    >
      <h1 className="mb-6 ml-8 font-body text-base font-normal leading-8 tracking-tight text-left">
        {title}
      </h1>
      <div className="w-2/3 ml-8 flex-1 h-full">
        <OrganizationsDropdown
          vendor="Agrian "
          organizations={organizations}
          csLoaded={!loading && !loadingMatches}
          selectedId={selectedOrganization}
          onSelect={handleOrganizationSelect}
          isRequired
        />
      </div>
      <AgrianSubmissionFooter
        nextDisabled={!selectedOrganization}
        onNext={onNext}
        integrationType={integrationType}
      />
    </div>
  );
};

AgrianOrganization.propTypes = {
  integrationType: PropTypes.string,
  title: PropTypes.string
};

AgrianOrganization.defaultProps = {
  integrationType: INTEGRATIONS.agrian,
  title: 'Select an organization for your submission.'
};

export default AgrianOrganization;
