import React from 'react';
import PropTypes from 'prop-types';
import StorageLocationIcon from 'assets/storage.svg';
import SourcePropertiesIcon from 'assets/source_properties.svg';
import { useFormikContext } from 'formik';
import BaseDropdown from 'components/BaseDropdown';

const SourceField = ({
  storageLocations,
  onSelectProperties,
  onCreateNewStorage,
  onChange,
  value,
  id,
  isLoading
}) => {
  const { errors, touched, values } = useFormikContext();

  const valueRenderer = () => {
    if (values.sourceType === 'STORAGE') {
      return (
        <div className="w-full h-full p-2 flex items-center">
          <img className="h-4" alt="Field Icon" src={StorageLocationIcon} />
          <div className="pl-2">
            {value.map(source => source.name).join(' ')}
          </div>
        </div>
      );
    }

    if (values.sourceType === 'CROP_ZONE') {
      const text =
        value.length === 1 ? '1 crop zone' : `${value.length} crop zones`;
      const totalAreaRounded =
        typeof values?.totalArea === 'number'
          ? `${values?.totalArea.toFixed(2)} ac`
          : values?.totalArea;
      return (
        <div className="w-full h-full p-2 flex align-center justify-start">
          <div>{`${text} | ${totalAreaRounded}`}</div>
        </div>
      );
    }
    return null;
  };

  return (
    <BaseDropdown
      valueRenderer={valueRenderer}
      value={value}
      bottomButtonText="Create New Storage"
      onBottomButtonClick={onCreateNewStorage}
      onChange={onChange}
      id={id}
      isInvalid={
        errors?.sources !== undefined && touched?.sources !== undefined
      }
      isLoading={isLoading}
    >
      <BaseDropdown.ClickableElement onItemClick={onSelectProperties}>
        <div className="w-full h-full text-green-600 flex justify-start items-center">
          <img
            className="inline-block"
            alt="select properties"
            src={SourcePropertiesIcon}
          />
          <span className="inline-block pl-1">Select Properties</span>
        </div>
      </BaseDropdown.ClickableElement>
      <div className="text-neutral-60 pb-2">STORAGE</div>
      {storageLocations?.length > 0 ? (
        storageLocations.map(storageLocation => (
          <div
            key={storageLocation.id}
            className="w-full h-full p-2 flex items-center"
            value={{
              type: 'STORAGE',
              id: storageLocation.id,
              name: storageLocation.name,
              totalStoredLoad: storageLocation.totalStoredLoad,
              capacity: storageLocation.capacity
            }}
          >
            <img className="h-4" alt="Field Icon" src={StorageLocationIcon} />
            <div className="pl-2">{storageLocation.name}</div>
          </div>
        ))
      ) : (
        <div className="pl-2">No storage locations found</div>
      )}
    </BaseDropdown>
  );
};

SourceField.propTypes = {
  storageLocations: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      label: PropTypes.string
    })
  ),
  value: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      name: PropTypes.string
    })
  ),
  onChange: PropTypes.func.isRequired,
  onSelectProperties: PropTypes.func.isRequired,
  id: PropTypes.string,
  isLoading: PropTypes.bool,
  onCreateNewStorage: PropTypes.func.isRequired
};

SourceField.defaultProps = {
  value: null,
  id: '',
  isLoading: false,
  storageLocations: []
};

export default SourceField;
