import React from 'react';
import { StyleSheet, Text, View } from '@react-pdf/renderer';
import { format } from 'date-fns';
import PropTypes from 'prop-types';
import { applicatorsHeaderTranslations } from 'components/PDF/TaskPdf/utils/headerTranslations';

const styles = StyleSheet.create({
  title: {
    fontFamily: 'Noto Sans',
    fontWeight: 'medium',
    fontSize: 12,
    marginBottom: 12
  },
  row: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginBottom: 20
  },
  fieldContainer: {
    width: '50%'
  },
  fieldText: {
    fontFamily: 'Noto Sans',
    fontSize: 9
  },
  fieldTitle: {
    color: '#363948',
    lineHeight: 2
  },
  fieldValue: {
    color: '#14151C',
    fontWeight: 'medium'
  }
});

const buildLicenseNumberDisplay = (applicator, exportLanguage) => {
  if (applicator.licenseNumber) {
    if (applicator.expiresDateEpoch) {
      return `${applicator.licenseNumber} - ${
        exportLanguage === 'english'
          ? format(applicator.expiresDateEpoch, 'MM/dd/yyyy')
          : format(applicator.expiresDateEpoch, 'dd/MM/yyyy')
      }`;
    }
    return applicator.licenseNumber;
  }
  return '-';
};

const Applicators = ({ applicators, exportLanguage = 'english' }) => {
  return applicators.length > 0 ? (
    <View>
      <Text style={styles.title}>
        {applicatorsHeaderTranslations.applicators[exportLanguage]}
      </Text>
      {applicators.map(applicator => (
        <View style={styles.row} key={applicator.licenseNumber}>
          <View style={styles.fieldContainer}>
            <Text style={[styles.fieldText, styles.fieldTitle]}>
              {applicatorsHeaderTranslations.nameCompany[exportLanguage]}
            </Text>
            <Text style={[styles.fieldText, styles.fieldValue]}>
              {applicator.personName} / {applicator.companyName ?? '-'}
            </Text>
          </View>
          <View style={styles.fieldContainer}>
            <Text style={[styles.fieldText, styles.fieldTitle]}>
              {applicatorsHeaderTranslations.licenseNo[exportLanguage]}
            </Text>
            <Text style={[styles.fieldText, styles.fieldValue]}>
              {buildLicenseNumberDisplay(applicator, exportLanguage)}
            </Text>
          </View>
        </View>
      ))}
    </View>
  ) : null;
};

Applicators.propTypes = {
  applicators: PropTypes.arrayOf(PropTypes.object),
  exportLanguage: PropTypes.string.isRequired
};

Applicators.defaultProps = {
  applicators: []
};

export default Applicators;
