import React from 'react';
import PropTypes from 'prop-types';
import { noop } from 'lodash';

const ClickableDiv = React.forwardRef(
  ({ children, className, ...rest }, ref) => (
    <div
      data-testid="clickable-div"
      className={`outline-none ${className}`}
      role="button"
      tabIndex={0}
      ref={ref}
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...rest}
    >
      {children}
    </div>
  )
);

ClickableDiv.propTypes = {
  onClick: PropTypes.func.isRequired,
  onKeyDown: PropTypes.func,
  onBlur: PropTypes.func,
  onMouseEnter: PropTypes.func,
  onMouseLeave: PropTypes.func,
  children: PropTypes.node.isRequired,
  className: PropTypes.string
};

ClickableDiv.defaultProps = {
  onKeyDown: noop,
  onBlur: noop,
  onMouseEnter: noop,
  onMouseLeave: noop,
  className: ''
};

export default ClickableDiv;
