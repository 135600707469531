import React from 'react';

const BreadcrumbContainer = () => {
  return (
    <div className="mr-6 flex flex-wrap">
      <div id="cwf-breadcrumb-portal" className="flex items-center" />
    </div>
  );
};

export default BreadcrumbContainer;
