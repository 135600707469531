/* eslint-disable react/prop-types */
import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { Spinner, Table, Tag, TagCloud } from '@agconnections/grow-ui';
import { Link } from 'react-router-dom';
import FormattedNumber from 'components/FormattedNumber';
import { Context } from 'components/Store';
import EllipseMenu from 'components/EllipseMenu';
import { PLANS_LANDING_MENU } from 'utilities/menus';
import { hasFinancialAccess } from 'utilities/access';
import { paths } from 'routes/paths';

const PlanTableRow = ({ plan, fullPlan, onAction, runningProcesses }) => {
  const [{ loggedInUserOrgPermission }] = useContext(Context);
  const canViewFinancialData = hasFinancialAccess(
    loggedInUserOrgPermission.financialAccess,
    'READ'
  );
  const handleAction = action => onAction(action, plan);

  const applyNumberFormatting = (columnValueToFormat, unit, roundingDigit) => (
    <FormattedNumber
      unit={unit === 'USD' ? 'usCurrency' : unit}
      roundedTo={roundingDigit}
    >
      {columnValueToFormat}
    </FormattedNumber>
  );

  return (
    <Table.Row key={plan.planId}>
      <Table.Cell>
        <div className="flex flex-col items-start ">
          <span className="text-sm font-bold">
            <Link to={`${paths.plans}/${plan.planId}/view`}>{plan.name}</Link>
          </span>
          {plan?.flags && (
            <TagCloud>
              {plan?.flags?.map(tag => {
                return (
                  <Tag key={tag.id} color={tag.color}>
                    {tag.name}
                  </Tag>
                );
              })}
            </TagCloud>
          )}
        </div>
      </Table.Cell>
      <Table.Cell>{Number(plan?.estimatedAreaValue).toFixed(2)}</Table.Cell>
      <Table.Cell>{plan?.crop?.name || 'none'}</Table.Cell>
      <Table.Cell>
        {!canViewFinancialData ? (
          ''
        ) : (
          <>
            {applyNumberFormatting(
              plan?.fixedCost / plan?.estimatedAreaValue,
              plan?.currency,
              2
            )}
          </>
        )}
      </Table.Cell>
      <Table.Cell>
        {!canViewFinancialData ? (
          ''
        ) : (
          <>
            {applyNumberFormatting(
              plan?.variableCost / plan?.estimatedAreaValue,
              plan?.currency,
              2
            )}
          </>
        )}
      </Table.Cell>
      <Table.Cell>
        {!canViewFinancialData ? (
          ''
        ) : (
          <>
            {applyNumberFormatting(
              (fullPlan?.projectedRevenue?.yieldPerArea *
                fullPlan?.projectedRevenue?.pricePerUnit *
                fullPlan?.estimatedAreaValue +
                fullPlan?.projectedRevenue?.fsaPayment) /
                plan?.estimatedAreaValue,
              plan?.currency,
              2
            )}
          </>
        )}
      </Table.Cell>
      <Table.Cell>
        <div className="mr-4">{plan?.createdOn}</div>
        <div
          data-testid="plan-table-options-menu"
          className="absolute right-0 flex-none w-10 mr-2"
        >
          {runningProcesses ? (
            <Spinner size="sm" />
          ) : (
            <EllipseMenu onAction={handleAction} options={PLANS_LANDING_MENU} />
          )}
        </div>
      </Table.Cell>
    </Table.Row>
  );
};

PlanTableRow.propTypes = {
  plan: PropTypes.shape({
    onAction: PropTypes.func,
    // _id: PropTypes.string,
    planId: PropTypes.string,
    // area: PropTypes.number,
    estimatedAreaValue: PropTypes.number,
    fixedCost: PropTypes.number,
    variableCost: PropTypes.number,
    projectRevenue: PropTypes.number,
    name: PropTypes.string,
    currency: PropTypes.string,
    // crops: PropTypes.arrayOf(PropTypes.object),
    crop: PropTypes.objectOf(PropTypes.any),
    loadedCrops: PropTypes.arrayOf(PropTypes.object), // Computed value for loaded crop from masterlist
    // createdAt: PropTypes.instanceOf(Date)
    createdOn: PropTypes.oneOfType([
      PropTypes.instanceOf(Date),
      PropTypes.string
    ]),
    flags: PropTypes.arrayOf(PropTypes.object)
  }).isRequired,
  runningProcesses: PropTypes.bool
};

PlanTableRow.defaultProps = {
  runningProcesses: false
};

export default PlanTableRow;
