import { isNil, groupBy, last } from 'lodash';

const safeArray = arr => arr || [];

export const coordinatesFormat = coordinates =>
  coordinates
    .split(',')
    .reverse()
    .join();

export const getFieldById = (fields, fieldId) =>
  safeArray(fields).find(field => field._id === fieldId);

export const getFieldAreaById = (field, areaId) =>
  field ? safeArray(field.areas).find(area => area._id === areaId) : null;

const findCropZoneInFields = (fields, selectedCropZoneId) =>
  fields.reduce((cropZoneAndField, field) => {
    if (cropZoneAndField) {
      return cropZoneAndField;
    }
    const cropZone = field.areas.find(area => area._id === selectedCropZoneId);
    return cropZone ? { field, cropZone } : null;
  }, null);

export const getSelectedField = (fieldId, fields) => {
  if (isNil(fieldId)) {
    return null;
  }
  return fields
    ? fields.find(item => item._id === fieldId)
    : {
        _id: fieldId
      };
};

export const getSelectedCropZone = (cropZoneId, fields) => {
  if (isNil(cropZoneId)) {
    return null;
  }
  return fields
    ? findCropZoneInFields(fields, cropZoneId)
    : {
        cropZone: { _id: cropZoneId }
      };
};

export const getSelectedLand = ({ fieldId, cropZoneId }, fields) => {
  if (cropZoneId) {
    return getSelectedCropZone(cropZoneId, fields);
  }
  if (fieldId) {
    return { field: getSelectedField(fieldId, fields) };
  }
  return {};
};

export const idMatchesCurrentLandScreen = ({ fieldId, cropZoneId }, id) => {
  return (
    (!isNil(fieldId) && fieldId === id) ||
    (!isNil(cropZoneId) && cropZoneId === id)
  );
};

export const getAllFieldCropZones = fields => {
  if (!fields || fields.length === 0) {
    return [];
  }
  return fields.reduce((allAreas, field) => {
    if (field.areas) {
      return allAreas.concat(
        field.areas.filter(area => area.includeOnMaps !== false)
      );
    }
    return allAreas;
  }, []);
};

// search an object for key-value pairs where the key matches one of the search terms
const findEntry = (obj = {}, searchTerms = []) =>
  Object.entries(obj).find(([key]) =>
    searchTerms.find(
      // exclude occurrences of _id, we want field or field_name not field_id
      searchTerm =>
        key.toLowerCase().includes(searchTerm) &&
        !key.toLowerCase().includes('_id')
    )
  ) || [];

// create a leaf item (a parent object that has no children)
const createLeafItem = type => item => ({
  name: item[type],
  type,
  features: [item.feature]
});

// create a hierarchical parent object and attach the children items
const createParentWithChildren = (name, type, children) => ({
  name: name === 'undefined' ? undefined : name,
  type,
  features: children.items.flatMap(item => item.features),
  ...children
});

// create the child items of the hierarchy by calling the specified childCallback
const createPropertyChildren = (items, childCallback) => ({
  items: childCallback(items)
});

// group the items by the specified parent type, and convert the group object to an entries array
const getPropertyGroupEntries = (items, parentType) =>
  Object.entries(groupBy(items, parentType));

// group the property items for the specified parent level of the farm/field/crop zone hierarchy
const groupProperties = (items, parentType, childCallback) => {
  return getPropertyGroupEntries(items, parentType).flatMap(
    ([groupKey, groupItems]) => {
      if (!childCallback) {
        return groupItems.map(createLeafItem(parentType));
      }
      const children = createPropertyChildren(groupItems, childCallback);
      return createParentWithChildren(groupKey, parentType, children);
    }
  );
};

export const getFeatureFarmName = feature =>
  last(findEntry(feature.properties, ['farm']));
export const getFeatureFieldName = feature =>
  last(findEntry(feature.properties, ['field', 'ldblabel']));
export const getFeatureCropZoneName = feature =>
  last(findEntry(feature.properties, ['crop']));

/**
 * parse a geojson feature collection and return a FFT hierarchy array
 * @param fc - a geojson feature collection or an array of feature collections
 * @returns an array of farms, each containing an array of fields, each containing an array of cropZones
 */
export const parseFFTFromFeatureCollection = fc => {
  const rawFeatures = Array.isArray(fc)
    ? fc?.flatMap(({ features }) => features)
    : fc?.features;
  const features = rawFeatures?.map(feature => {
    // pick the farm, field, and crop zone names from the feature properties if available
    const farm = getFeatureFarmName(feature);
    const field = getFeatureFieldName(feature);
    const cropZone = getFeatureCropZoneName(feature);
    return {
      farm,
      field,
      cropZone,
      feature
    };
  });
  return groupProperties(features, 'farm', fields =>
    groupProperties(fields, 'field', cropZones =>
      groupProperties(cropZones, 'cropZone')
    )
  );
};

/**
 * parse a geojson feature collection and return a hierarchy of fields and crop zones
 * @param fc - the geojson feature collection
 * @returns an array of fields with associated farm names, each containing an array of cropZones
 */
export const parsePropertyInfoFromFeatureCollection = fc => {
  const fft = parseFFTFromFeatureCollection(fc);
  return fft?.flatMap(farm =>
    farm.items.map(field => ({ ...field, farmName: farm.name }))
  );
};

// a predicate for finding the item with the specified value for index
export const selectedItemAtIndex = targetIndex => ({ index }) =>
  index === targetIndex;

// in the array of items, did the user change the item at the source index to be different from the item at the destination index
const doCheckedValuesDifferAt = (items, destinationIndex, sourceIndex) => {
  const destination = items.find(selectedItemAtIndex(destinationIndex));
  // user changes are identified by the presence of the event object
  const source = items.find(
    ({ index, event }) => index === sourceIndex && event
  );
  if (!source) {
    return false;
  }
  return !!destination?.checked !== !!source?.checked;
};

// create a change record with the specified index and the same checked value as the item at existingIndex
const createChangeRecord = (items, existingIndex, newIndex) => {
  const item = items.find(selectedItemAtIndex(existingIndex));
  return { index: newIndex, checked: item?.checked };
};

/**
 * search the selected items for selection changes from the user and create corresponding changes to the parent
 * or child item of those changed items
 * @param {Array} parentCrossReference - cross reference child indexes against parent indexes
 * @param {Array} selected - array of { index, checked, event } items indicating selections
 * @returns {Array} list of changes to make to the selected array
 */
export const calculateSelectedChanges = (parentCrossReference, selected) => {
  if (!selected.find(({ event }) => event)) {
    return [];
  }
  const parentChanges = parentCrossReference
    .filter(([childIndex, parentIndex]) =>
      doCheckedValuesDifferAt(selected, parentIndex, childIndex)
    )
    .map(([childIndex, parentIndex]) =>
      createChangeRecord(selected, childIndex, parentIndex)
    );
  const childChanges = parentCrossReference
    .filter(([childIndex, parentIndex]) =>
      doCheckedValuesDifferAt(selected, childIndex, parentIndex)
    )
    .map(([childIndex, parentIndex]) =>
      createChangeRecord(selected, parentIndex, childIndex)
    );
  return parentChanges.concat(childChanges);
};

export const createRowGroups = rows => {
  const parentCrossReference = rows.map((row, index) => [
    row.parentReference ? index - row.parentReference : index,
    index,
    row
  ]);

  const groups = groupBy(parentCrossReference, ([parentIndex]) => parentIndex);

  return Object.values(groups);
};
