import React, { useState } from 'react';
import ClickableDiv from 'components/ClickableDiv';
import { Icon, Checkbox } from '@agconnections/grow-ui';
import PropTypes from 'prop-types';

const FarmsFilter = ({
  farms,
  selectedFarmIds,
  onFarmSelect,
  showArchived
}) => {
  const [areOptionsVisible, setAreOptionsVisible] = useState(
    selectedFarmIds.length > 0
  );
  const hasArchivedFarms = farms.some(farm => farm.archived);

  return (
    <div>
      <ClickableDiv
        className="flex justify-between items-center mb-4 px-5"
        onClick={() => setAreOptionsVisible(prevState => !prevState)}
      >
        <span className="text-black font-semibold">Farms</span>
        <Icon
          icon={areOptionsVisible ? 'chevron-up' : 'chevron-down'}
          color="#515253"
        />
      </ClickableDiv>
      {areOptionsVisible ? (
        <div className="transition-all duration-200 overflow-y-hidden border-b border-neutral-100 flex flex-col px-5 pb-20px">
          {farms
            .filter(farm => !farm.archived)
            .map(item => (
              <div className="p-1" key={item?.id}>
                <Checkbox
                  checked={selectedFarmIds.includes(item?.id)}
                  onChange={onFarmSelect(item?.id)}
                  label={item?.name}
                />
              </div>
            ))}
          {showArchived && hasArchivedFarms && (
            <div className="mt-5">
              <p className="text-neutral-60 mb-2">Archived Farms</p>
              {farms
                ?.filter(farm => farm.archived)
                .map(item => (
                  <div className="p-1" key={item?.id}>
                    <Checkbox
                      checked={selectedFarmIds.includes(item?.id)}
                      onChange={onFarmSelect(item?.id)}
                      label={item?.name}
                    />
                  </div>
                ))}
            </div>
          )}
        </div>
      ) : null}
    </div>
  );
};

FarmsFilter.defaultProps = {
  farms: [],
  selectedFarmIds: [],
  showArchived: false
};

FarmsFilter.propTypes = {
  farms: PropTypes.arrayOf(),
  selectedFarmIds: PropTypes.arrayOf(PropTypes.string),
  onFarmSelect: PropTypes.func.isRequired,
  showArchived: PropTypes.bool
};

export default FarmsFilter;
