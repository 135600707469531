import React from 'react';

import { Modal } from 'syngenta-digital-cropwise-react-ui-kit';
import PropTypes from 'prop-types';
import { useFormikContext } from 'formik';
import GreenCircleCheck from 'assets/green-circle-check.svg';
import warning from 'assets/warning-red.svg';
import './index.css';

const LoadsManagerModal = ({
  addAndCreateNew,
  type,
  modalVisible,
  setModalVisible,
  onSaveAllLoads,
  showCreateNewLoadWidget
}) => {
  const {
    values,
    handleSubmit,
    validateForm,
    setFieldTouched
  } = useFormikContext();

  const onAdd = async () => {
    if (type === 'saveUnsaved') {
      onSaveAllLoads(values);
    }

    if (type === 'overdrawnSource') {
      if (addAndCreateNew) {
        handleSubmit();
        await validateForm();
        setFieldTouched('preventNavigation', true);
      } else {
        handleSubmit();
        const validationErrors = await validateForm();
        if (Object.keys(validationErrors).length === 0) {
          showCreateNewLoadWidget();
          setFieldTouched('preventNavigation', true);
        }
      }
    }
    setModalVisible(false);
  };

  const modalConfig = {
    saveUnsaved: {
      className: 'save-all-modal',
      title: 'Include Additional Load?',
      imgSrc: GreenCircleCheck,
      imgAlt: 'Check Circle',
      okText: 'Yes, Save All Loads',
      cancelText: 'No, Continue Editing',
      content:
        'A load was not added to the Loads List, but all required inputs were completed. Would you like to save this load with your submission?'
    },
    overdrawnSource: {
      className: 'save-all-modal overdrawn-source',
      title: 'Not Enough Yield in Storage',
      imgSrc: warning,
      imgAlt: 'warning',
      okText: 'Save Load',
      cancelText: 'Cancel',
      content: `There is not enough yield in ${values?.sources?.[0]?.name} to fulfill this load. Are you sure you want to save this load?`
    }
  };

  const config = modalConfig?.[type];
  if (!config) return null;

  return (
    <Modal
      className={config.className}
      centered
      closable={false}
      title={
        <div className="flex">
          <img className="mr-4" src={config.imgSrc} alt={config.imgAlt} />
          {config.title}
        </div>
      }
      open={modalVisible}
      okText={config.okText}
      cancelText={config.cancelText}
      onOk={onAdd}
      onCancel={() => {
        setModalVisible(false);
      }}
    >
      <p className="ml-4 font-normal text-sm">{config.content}</p>
    </Modal>
  );
};

LoadsManagerModal.propTypes = {
  type: PropTypes.string.isRequired,
  modalVisible: PropTypes.bool.isRequired,
  setModalVisible: PropTypes.func.isRequired,
  onSaveAllLoads: PropTypes.func,
  addAndCreateNew: PropTypes.bool,
  showCreateNewLoadWidget: PropTypes.func
};

LoadsManagerModal.defaultProps = {
  addAndCreateNew: false,
  showCreateNewLoadWidget: () => {},
  onSaveAllLoads: () => {}
};

export default LoadsManagerModal;
