import React, { useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { Input } from 'syngenta-digital-cropwise-react-ui-kit';
import SearchIcon from 'assets/search.svg';
import { debounce } from 'lodash';

const SimpleProductSearch = ({ setSearchText }) => {
  const setSearchTextDebounced = useRef(debounce(setSearchText, 300));
  const [text, setText] = useState('');
  return (
    <Input
      style={{ margin: '0' }}
      type="default"
      prefix={<img alt="search" src={SearchIcon} />}
      placeholder="Search products"
      allowClear
      size="middle"
      onChange={e => {
        setText(e.target.value);
        setSearchTextDebounced.current(e.target.value);
      }}
      value={text}
    />
  );
};

SimpleProductSearch.propTypes = {
  setSearchText: PropTypes.func.isRequired
};

export default SimpleProductSearch;
