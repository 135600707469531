import { Maths } from 'helpers/maths';
import { SHRINK_TYPE } from 'screens/YieldV2/constants';
import { percentToDecimal } from 'utilities/numberHelpers';
import sanitizeAndConvertToNumber from './sanitizeAndConvertToNumber';

const calculateFinalQuantity = ({ harvestedQuantity, shrink, shrinkType }) => {
  const harvestedQuantityValue = sanitizeAndConvertToNumber(harvestedQuantity);
  const shrinkValue = sanitizeAndConvertToNumber(shrink);
  if (!shrinkValue && harvestedQuantityValue) return harvestedQuantityValue;
  if (!harvestedQuantityValue || !shrinkValue) return 0;

  let adjustedShrinkValue = shrinkValue;

  if (shrinkType === SHRINK_TYPE.BY_PERCENT) {
    adjustedShrinkValue = Maths.multiplyRound(
      percentToDecimal(shrinkValue),
      harvestedQuantityValue
    );
  }

  return Maths.minusRound(harvestedQuantityValue, adjustedShrinkValue, 2);
};

export default calculateFinalQuantity;
