import React from 'react';
import PropTypes from 'prop-types';
import { useField } from 'formik';

import BaseButton from './components/BaseFinancialButton';
import { getAvailableFinancialButtons } from '../../helpers';

const FinancialInformation = ({
  field: { value, onChange, name },
  singleMember
}) => {
  const [{ value: roleValue }] = useField('role');

  const availableButtons = getAvailableFinancialButtons(roleValue);
  const selectFinancial = val => {
    // fake event matches expected Formik format
    const event = { target: { value: val, name } };
    onChange(event);
  };

  return (
    <div className="font-medium text-syngenta-navy">
      <p className="text-lg ">Financial Information Access</p>
      <p className="mb-6 text-gray-600">
        Control how much access a member has to financial information.
      </p>
      <div className="flex justify-between">
        <BaseButton
          disabled={
            !availableButtons.includes('WRITE')
              ? true
              : (singleMember?.role === 'Full control' &&
                  singleMember?.financialAccess === 'READ') ||
                (singleMember?.role === 'Full control' &&
                  !Object.prototype.hasOwnProperty.call(
                    singleMember,
                    'financialAccess'
                  ))
          }
          isSelected={value === 'WRITE'}
          onToggle={() => selectFinancial('WRITE')}
          name="Edit Access"
        />

        <BaseButton
          disabled={!availableButtons.includes('READ')}
          isSelected={value === 'READ'}
          onToggle={() => selectFinancial('READ')}
          name="View Only"
        />

        <BaseButton
          disabled={!availableButtons.includes('none')}
          isSelected={value === 'none'}
          onToggle={() => selectFinancial('none')}
          name="No Access"
        />
      </div>
    </div>
  );
};

FinancialInformation.propTypes = {
  field: PropTypes.shape({
    name: PropTypes.string,
    value: PropTypes.string,
    onChange: PropTypes.func.isRequired
  }).isRequired,
  singleMember: PropTypes.instanceOf(Object).isRequired
};

export default FinancialInformation;
