import React, { useState } from 'react';
import { Input, Select } from 'syngenta-digital-cropwise-react-ui-kit';
import PropTypes from 'prop-types';
import useLandingPageData from 'hooks/useLandingPageData';
import ChevronDown from 'assets/chevron_down.svg';

const AddSale = ({ handleChange, values, setValues, errors }) => {
  const [companiesSelectOpen, setCompaniesSelectOpen] = useState(false);
  const { loaded, items: companies } = useLandingPageData('worker/companies', [
    'company.name'
  ]);

  return (
    <>
      <div className="flex flex-col gap-1 mb-8">
        <label
          htmlFor="location-name"
          className="text-neutral-60 tracking-tighter text-sm"
        >
          Name of Location<span className="text-remove">*</span>
        </label>
        <Input
          required
          name="name"
          id="location-name"
          type="default"
          size="middle"
          value={values.name}
          onChange={handleChange}
          status={errors?.name && 'error'}
          caption={errors?.name}
        />
      </div>
      <h3 className="text-base font-semibold block mb-4">Basic Info</h3>

      <div className="flex flex-col gap-1 mb-5">
        <label
          htmlFor="email"
          className="text-neutral-60 tracking-tighter text-sm"
        >
          Email Address
        </label>
        <Input
          type="default"
          size="middle"
          name="email"
          id="email"
          value={values.email}
          onChange={handleChange}
          status={errors?.email && 'error'}
          caption={errors?.email}
        />
      </div>
      <div className="flex flex-col gap-1 mb-5">
        <label
          htmlFor="phone"
          className="text-neutral-60 tracking-tighter text-sm"
        >
          Phone Number
        </label>
        <Input
          type="default"
          size="middle"
          name="phone"
          id="phone"
          value={values.phone}
          onChange={handleChange}
        />
      </div>
      <div className="flex flex-col gap-1 mb-5">
        <label
          htmlFor="company"
          className="text-neutral-60 tracking-tighter text-sm"
        >
          Company
        </label>
        <Select
          onChange={value =>
            setValues(prevValues => ({
              ...prevValues,
              companyId: value
            }))
          }
          onDropdownVisibleChange={selectOpen =>
            setCompaniesSelectOpen(selectOpen)
          }
          suffixIcon={
            <img
              src={ChevronDown}
              style={{
                transform: companiesSelectOpen ? 'rotate(180deg)' : null
              }}
              height={18}
              width={18}
              alt="Select dropdown icon"
            />
          }
          loading={!loaded}
        >
          {companies?.map(company => (
            <Select.Option key={company.id} value={company.id}>
              {company.name}
            </Select.Option>
          ))}
        </Select>
      </div>
    </>
  );
};

AddSale.propTypes = {
  handleChange: PropTypes.func.isRequired,
  values: PropTypes.shape({
    name: PropTypes.string,
    email: PropTypes.string,
    phone: PropTypes.string,
    company: PropTypes.string
  }).isRequired,
  setValues: PropTypes.func.isRequired,
  errors: PropTypes.objectOf(PropTypes.any).isRequired
};

export default AddSale;
