import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { Button, DatePicker } from 'syngenta-digital-cropwise-react-ui-kit';
import { ArrowRightOutlined } from '@ant-design/icons';

import './antd-override.css';

const TODAY = moment();
const MIN_DATE = moment().subtract(7, 'years');

const DateFilter = ({
  since,
  until,
  setSince,
  applyDateFilter,
  setUntil,
  isLoading
}) => {
  const ranges = useMemo(
    () => ({
      'last month': [moment().subtract(1, 'month'), TODAY],
      'last 6 months': [moment().subtract(6, 'months'), TODAY],
      'last year': [moment().subtract(1, 'year'), TODAY],
      'last 2 years': [moment().subtract(2, 'years'), TODAY]
    }),
    []
  );

  const handleChange = values => {
    if (!values) {
      return;
    }
    setSince(values[0]);
    setUntil(values[1]);
  };

  const disabledDate = useMemo(
    () => current => {
      return current && (current > TODAY || current < MIN_DATE);
    },
    []
  );

  return (
    <div data-testid="date-filtering-connect" className="mb-4">
      <div className="flex items-center justify-between">
        <DatePicker.RangePicker
          size="medium"
          disabled={isLoading}
          data-testid="date_range_picker"
          allowEmpty={[false, false]}
          className="w-4/5"
          popupClassName="date-filtering-connect"
          separator={<ArrowRightOutlined />}
          placeholder={['Since', 'Until']}
          disabledDate={disabledDate}
          hideDisabledOptions
          ranges={ranges}
          defaultValue={[since, until]}
          value={[since, until]}
          onChange={handleChange}
          format="DD MMM YYYY"
        />
        <Button
          disabled={isLoading}
          size="medium"
          type="primary"
          className="px-3"
          data-testid="apply-button"
          onClick={event => {
            event.stopPropagation();
            applyDateFilter();
          }}
        >
          Apply
        </Button>
      </div>
    </div>
  );
};

DateFilter.propTypes = {
  since: PropTypes.instanceOf(moment).isRequired,
  until: PropTypes.instanceOf(moment).isRequired,
  setSince: PropTypes.func.isRequired,
  setUntil: PropTypes.func.isRequired,
  applyDateFilter: PropTypes.func.isRequired,
  isLoading: PropTypes.bool.isRequired
};

export default DateFilter;
