/* eslint-disable react/prop-types */
import React from 'react';
import { SimpleModal, Input } from '@agconnections/grow-ui';
import PropTypes from 'prop-types';
import FormattedNumber from 'components/FormattedNumber';

const EditProductModal = ({ open, handleClose, product, displayFinancial }) => {
  const getProdRate = () => {
    let ppu = '';
    ppu += '$';
    ppu += product?.specificCostValue;
    ppu += '/';
    ppu += product?.totalProductUnit;
    return ppu;
  };

  const getApplArea = () => {
    let applArea = '';
    applArea += product?.areaValue;
    applArea += ' ac';
    return applArea;
  };

  return (
    <SimpleModal close={handleClose} onConfirm={handleClose} open={open}>
      <div className="mb-6">
        <div className="flex w-full justify-between">
          <div className="text-xl font-bold">{product?.productName}</div>
        </div>
        <span className="text-sm text-neutral-300">
          {product?.manufacturerName}
        </span>
      </div>
      <div className="flex mb-6">
        <div className="mr-6">
          <span className="text-sm text-neutral-300">Total Product</span>
          {displayFinancial && (
            <div>
              <div className="text-xl">
                <FormattedNumber roundedTo={2}>
                  {product?.totalProductValue}
                </FormattedNumber>{' '}
                {product?.specificCostUnit}
                {product?.specificCostUnit > 1 ? 's' : ''}
              </div>
            </div>
          )}
        </div>
        <div>
          <span className="text-sm text-neutral-300">Product Cost</span>
          {displayFinancial && (
            <div className="text-xl ">
              <FormattedNumber unit="usCurrency" roundedTo={2}>
                {product?.totalProductCost}
              </FormattedNumber>
            </div>
          )}
        </div>
      </div>
      <br />
      <br />
      <div className="flex">
        <div className="mr-4">
          <Input
            disabled
            className="w-1/4"
            label="Rate"
            id="rate"
            value={product?.productRateValue}
          />
        </div>
        <div className="mr-4">
          <Input
            style={{ whiteSpace: 'nowrap' }}
            disabled
            className="w-1/4"
            label="Unit"
            id="unit"
            value={product?.productRateUnit}
          />
        </div>
        <Input
          disabled
          className="w-1/4"
          label="Price/Unit"
          id="priceUnit"
          value={displayFinancial ? getProdRate() : ''}
        />
      </div>
      <br />
      <br />
      <div className="flex">
        <div className="mr-4">
          <Input
            disabled
            className="w-1/4"
            label="Applied Area"
            id="appliedArea"
            value={getApplArea()}
          />
        </div>
        <div className="mr-4">
          <Input
            disabled
            className="w-1/4"
            label="Applied Area %"
            id="appliedAreaPercent"
            value={`${product?.coveragePercent * 100}%`}
          />
        </div>
        <Input
          disabled
          className="w-1/4"
          label="# of Applications"
          id="numOfApps"
          value={product?.applicationCount}
        />
      </div>
      <br />
      <br />
      <div className="flex">
        <div className="mr-4">
          <Input
            disabled
            className="w-1/4"
            label="Timing"
            id="timing"
            value={product?.timingEvent}
          />
        </div>
        <div className="mr-4">
          <Input
            disabled
            className="w-1/4"
            label="Timing Tag"
            id="timingTag"
            value={product?.timingEventTag}
          />
        </div>
        <Input
          disabled
          className="w-1/4"
          label="Target Date"
          id="targetDate"
          value={product?.targetDate}
        />
        <br />
        <br />
      </div>
    </SimpleModal>
  );
};

EditProductModal.defaultProps = {
  product: null
};

EditProductModal.propTypes = {
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  product: PropTypes.shape({
    specificCostValue: PropTypes.number.isRequired,
    productRateUnit: PropTypes.number.isRequired,
    productName: PropTypes.string.isRequired,
    manufacturerName: PropTypes.string.isRequired,
    totalProductValue: PropTypes.string.isRequired,
    totalProductCost: PropTypes.number.isRequired,
    productRateValue: PropTypes.string.isRequired,
    coveragePercent: PropTypes.number.isRequired,
    applicationCount: PropTypes.number.isRequired,
    timingEvent: PropTypes.string.isRequired,
    targetDateEpoch: PropTypes.number.isRequired
  }),
  displayFinancial: PropTypes.bool.isRequired
};

export default EditProductModal;
