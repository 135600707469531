/* istanbul ignore file */
import { Factory } from 'miragejs';
import faker from 'faker';
import globalSchema from '../utils/globalSchema';

const Crop = {
  id() {
    return faker.random.uuid();
  },
  name(i) {
    return `Plant ${i}`;
  },
  Status() {
    if (faker.random.boolean()) {
      return 'Active';
    }

    return 'Discontinued';
  },
  ...globalSchema
};

export default Factory.extend(Crop);
