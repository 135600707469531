import React from 'react';
import PropTypes from 'prop-types';
import { Button } from '@agconnections/grow-ui';
import { Add, Remove } from '@material-ui/icons';
import ClickableDiv from 'components/ClickableDiv';
import FormattedNumber from 'components/FormattedNumber';

const DocumentCard = ({
  document,
  isAdded,
  documentClick,
  addRemoveDoc,
  clickedId
}) => {
  const bgColor = document.id === clickedId ? 'bg-blue-200' : '';
  return (
    <div className={`border p-4 ${bgColor}`} data-testid="source-document-card">
      <div className="flex justify-between">
        <ClickableDiv
          onClick={() => documentClick()}
          className="text-base font-bold w-full"
        >
          {document.name}
        </ClickableDiv>
        <div className="ml-1" data-testid="add-remove-source-button">
          <Button
            style={
              isAdded
                ? { backgroundColor: '#CF3537' }
                : { backgroundColor: '#009933' }
            }
            id="document-add-button"
            type="overlay"
            circular
            size="sm"
            onClick={() => addRemoveDoc(document.id, document.name)}
            icon={
              isAdded ? (
                <Remove style={{ fontSize: 16, color: 'white' }} />
              ) : (
                <Add style={{ fontSize: 16, color: 'white' }} />
              )
            }
          />
        </div>
      </div>
      <ClickableDiv onClick={() => documentClick()}>
        <div className="text-sm">
          {new Date(document.docDate).toLocaleDateString()}
        </div>
        <div>
          {document.numOfFields >= 0 && (
            <span className="text-sm">{`${document.numOfFields} fields`}</span>
          )}
        </div>
        <div className="text-sm">{document.productCount} products</div>
        <div className="text-sm">
          <FormattedNumber unit="usCurrency" roundedTo={2}>
            {document.amount}
          </FormattedNumber>
        </div>
      </ClickableDiv>
    </div>
  );
};

DocumentCard.propTypes = {
  document: PropTypes.shape({
    type: PropTypes.string,
    id: PropTypes.string,
    name: PropTypes.string,
    numOfFields: PropTypes.number,
    docDate: PropTypes.string,
    productCount: PropTypes.number,
    amount: PropTypes.number
  }).isRequired,
  isAdded: PropTypes.bool,
  documentClick: PropTypes.func,
  addRemoveDoc: PropTypes.func,
  clickedId: PropTypes.string
};

DocumentCard.defaultProps = {
  isAdded: false,
  documentClick: () => {},
  addRemoveDoc: () => {},
  clickedId: null
};

export default DocumentCard;
