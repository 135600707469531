/* eslint-disable react/forbid-prop-types */
import React, { useEffect } from 'react';
import PropTypes from 'prop-types';

import { Input } from '@agconnections/grow-ui';

import GrowUIFormField from 'components/Forms/GrowUIFormField';
import { useFormikContext } from 'formik';

const CropZoneAreaInput = ({ boundaryArea, reportedArea }) => {
  const { setFieldValue } = useFormikContext();

  useEffect(() => {
    if (reportedArea !== 0) {
      const roundedRA = (Math.round(reportedArea * 100) / 100).toFixed(2);
      setFieldValue('reportedArea', roundedRA);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reportedArea]);

  return (
    <>
      <GrowUIFormField
        control={Input}
        name="boundaryArea"
        label="Estimated Boundary Area"
        value={`${boundaryArea} acres`}
        disabled
      />
      <GrowUIFormField
        control={Input}
        name="reportedArea"
        label="Reported Area"
        placeholder="--"
      />
    </>
  );
};

CropZoneAreaInput.propTypes = {
  boundaryArea: PropTypes.number,
  reportedArea: PropTypes.string
};

CropZoneAreaInput.defaultProps = {
  boundaryArea: 0,
  reportedArea: ''
};

export default CropZoneAreaInput;
