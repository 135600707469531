import React from 'react';
import PropTypes from 'prop-types';
import PersonIcon from 'components/Icons/PersonIcon';
import CompanyIcon from 'components/Icons/CompanyIcon';

const CompanyPeopleDropDownItem = ({ item }) => {
  const { value: itemValue, key, label, type } = item;
  if (!itemValue) {
    if (label === 'empty') {
      return (
        <div
          className="px-2 ml-2 text-neutral-60"
          key={key}
          data-testid="item-empty-value"
        >{`No ${type === 'People' ? 'people' : 'companies'} found`}</div>
      );
    }
    return (
      <div
        className="text-neutral-60 p-2 ml-2"
        key={key}
        data-testid="item-without-value"
      >
        {label}
      </div>
    );
  }
  const { type: valueType } = itemValue;
  return (
    <div
      key={key}
      className="w-full h-full px-2 py-1 ml-2 flex items-center"
      data-testid="item-with-value"
    >
      <span>
        {valueType === 'People' ? (
          <PersonIcon size={16} />
        ) : (
          <CompanyIcon size={16} />
        )}
      </span>
      <div className="pl-2">{itemValue.name}</div>
    </div>
  );
};

CompanyPeopleDropDownItem.propTypes = {
  item: PropTypes.shape({
    value: PropTypes.shape({
      name: PropTypes.string,
      type: PropTypes.string
    }),
    key: PropTypes.string,
    label: PropTypes.string,
    type: PropTypes.string
  }).isRequired
};

export default CompanyPeopleDropDownItem;
