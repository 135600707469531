/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from 'react';
import useCropData from 'hooks/useCropData';
import GrowUIFormField from 'components/Forms/GrowUIFormField';
import { useFormikContext } from 'formik';
import { Columns, Column, Input, Form, Search } from '@agconnections/grow-ui';
import usePlanProducts from 'screens/Plans/hooks/usePlanProducts';
import { unmaskPrice } from '../../../../../helpers/dataHelpers';

const PlanInfoSection = () => {
  const {
    values,
    setFieldValue,
    setFieldTouched,
    touched
  } = useFormikContext();
  const { recalculateProducts, recalculateCosts } = usePlanProducts({
    values,
    setFieldValue
  });

  const { crops } = useCropData();
  const cropItems =
    crops?.map(crop => ({
      key: crop.id,
      value: crop.name
    })) || [];

  // OnChanging Planned Area this useEffect will update the calculations of other fields in Details
  useEffect(() => {
    const estimatedAreaValue =
      touched.estimatedAreaValue || !values.totalApplied
        ? values.estimatedAreaValue
        : values.totalApplied;

    if (estimatedAreaValue > 0) {
      const unmaskedAreaValue = unmaskPrice(estimatedAreaValue);

      recalculateProducts(values.planProductEntries, unmaskedAreaValue).then(
        productsUpdated => {
          setFieldValue('planProductEntries', productsUpdated);
        }
      );

      setFieldValue('area', unmaskedAreaValue);

      recalculateCosts(estimatedAreaValue);
    }
  }, [values?.estimatedAreaValue]);

  const getPlansCropForDefault = () => {
    const crop = crops?.find(item => item?.id === values?.cropId);
    return crop?.id || null;
  };

  const onPlannedAreaBlur = e => {
    const newAreaValue = Number.isNaN(unmaskPrice(e.target.value))
      ? 1
      : unmaskPrice(e.target.value);

    if (newAreaValue !== values.estimatedAreaValue) {
      setFieldTouched('estimatedAreaValue');
    }

    if (
      values?.projectedRevenue?.pricePerUnit &&
      values?.projectedRevenue?.yieldPerArea &&
      newAreaValue
    ) {
      setFieldValue(
        'projectedRevenue.yieldTotal',
        parseFloat(
          newAreaValue *
            unmaskPrice(values?.projectedRevenue?.pricePerUnit) *
            unmaskPrice(values?.projectedRevenue?.yieldPerArea)
        ).toFixed(2)
      );
    }

    if (values?.projectedRevenue?.fsaPayment && newAreaValue) {
      setFieldValue(
        'projectedRevenue.fsaPaymentPerAc',
        parseFloat(
          unmaskPrice(values?.projectedRevenue?.fsaPayment) / newAreaValue
        ).toFixed(2)
      );
    }

    setFieldValue('estimatedAreaValue', newAreaValue);
  };

  return (
    <>
      <Columns wrap>
        <Column width="w-full">
          <div className="flex items-center">
            <div className="w-1/3 mr-8">
              <Form.Field label="Plan Name *">
                <GrowUIFormField
                  control={Input}
                  name="name"
                  data-testid="planName"
                />
              </Form.Field>
            </div>
            <div className="w-1/3 mr-8">
              <Form.Field label="Planned Area *">
                <GrowUIFormField
                  control={Input}
                  name="estimatedAreaValue"
                  data-testid="plannedArea"
                  onBlur={onPlannedAreaBlur}
                  imask={{
                    mask: 'num',
                    lazy: false,
                    blocks: {
                      num: {
                        mask: Number,
                        radix: '.',
                        mapToRadix: ['.'],
                        normalizeZeros: false
                      }
                    }
                  }}
                />
              </Form.Field>
            </div>
            <div className="w-1/3 mr-8">
              <Form.Field label="Crop">
                <GrowUIFormField
                  control={Search}
                  name="cropId"
                  items={cropItems}
                  data-testid="cropSelect"
                  selectedValue={getPlansCropForDefault()}
                />
              </Form.Field>
            </div>
          </div>
        </Column>
      </Columns>
    </>
  );
};

export default PlanInfoSection;
