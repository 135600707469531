import { capitalize, has } from 'lodash';
import applyFetchFilters from '../../utils/applyFetchFilters';
import { notFoundResponse } from '../../utils/errorResponses';
import withStandardErrors from './withStandardErrors';
import filterInactive from './filterInactive';

// check if organization is the parent and the entity has an organizationId field
const shouldHandleOrganization = (server, name, parentName) => {
  return (
    parentName === 'organization' &&
    has(server._factoryMap[name].attrs, 'organizationId')
  );
};

// create a key value pair for the parent id field if its not in the query params already
const getParentIdPair = (request, parentName, parentId) => {
  const parentIdField = `${parentName}Id`;
  if (has(request.queryParams, parentIdField)) {
    return {};
  }
  return { [parentIdField]: parentId };
};

/**
 * creates a nested get route for mirage resource
 * @param {*} server - the Mirage server
 * @param {*} name - the name of the resource (matches model name) singular NOT plural
 * @param {*} parentName - the name of the parent resource (matches model name) singular NOT plural
 */
export const genGetNestedResourceRouteHandler = (server, name, parentName) => {
  const resourceName = server.inflector.pluralize(name);
  const parentResourceName = server.inflector.pluralize(parentName);
  const routeHandler = (schema, request) => {
    const { parentId } = request.params;
    const { default: _default } = request.queryParams;

    // Parent Not Found
    const parentResource = schema[parentResourceName].find(parentId);
    if (!parentResource)
      return notFoundResponse(parentId, capitalize(parentResourceName));

    const parentFiltered = !shouldHandleOrganization(server, name, parentName)
      ? schema[resourceName].all()
      : schema[resourceName].where(
          getParentIdPair(request, parentName, parentResource.attrs.id)
        );
    const resource = filterInactive(parentFiltered, _default);
    return applyFetchFilters(resource, request.queryParams);
  };
  return routeHandler;
};

/**
 * creates a nested get by id route for mirage resource
 * @param {*} server - the Mirage server
 * @param {*} name - the name of the resource (matches model name) singular NOT plural
 * @param {*} parentName - the name of the parent resource (matches model name) singular NOT plural
 */
export const genGetNestedResourceByIdRouteHandler = (
  server,
  name,
  parentName
) => {
  const resourceName = server.inflector.pluralize(name);
  const parentResourceName = server.inflector.pluralize(parentName);

  const routeHandler = (schema, request) => {
    const { parentId, id } = request.params;

    // Parent Not Found
    const parentResource = schema[parentResourceName].find(parentId);
    if (!parentResource)
      return notFoundResponse(parentId, capitalize(parentResourceName));

    const resource = schema[resourceName].find(id);
    if (resource) return resource;
    if (process.env.REACT_APP_ENV === 'mirage-development') {
      return schema[resourceName].first();
    }
    return notFoundResponse(id, capitalize(resourceName));
  };
  return routeHandler;
};

/**
 * creates a nested update route for mirage resource
 * @param {*} server - the Mirage server
 * @param {*} name - the name of the resource (matches model name) singular NOT plural
 * @param {*} parentName - the name of the parent resource (matches model name) singular NOT plural
 * @param {*} beforeUpdateHandler - a function that processes the update body before the update is called
 */
export const genUpdateNestedResourceRouteHandler = (
  server,
  name,
  parentName,
  beforeUpdateHandler
) => {
  const resourceName = server.inflector.pluralize(name);
  const parentResourceName = server.inflector.pluralize(parentName);

  const routeHandler = (schema, request) => {
    const { id, parentId } = request.params;

    // Parent Not Found
    const parentResource = schema[parentResourceName].find(parentId);
    if (!parentResource)
      return notFoundResponse(parentId, capitalize(parentResourceName));

    const resource = schema[resourceName].find(id);
    if (resource) {
      const { version, ...rest } = JSON.parse(request.requestBody);
      const body = beforeUpdateHandler ? beforeUpdateHandler(rest) : rest;
      return resource.update({
        ...body,
        version: version + 1,
        updatedAt: new Date()
      });
    }
    return notFoundResponse(id, capitalize(resourceName));
  };
  return withStandardErrors(routeHandler);
};

/**
 * creates a nested create route for mirage resource
 * @param {*} server - the Mirage server
 * @param {*} name - the name of the resource (matches model name) singular NOT plural
 * @param {*} parentName - the name of the parent resource (matches model name) singular NOT plural
 */
export const genCreateNestedResourceRouteHandler = (
  server,
  name,
  parentName
) => {
  const resourceName = server.inflector.pluralize(name);
  const parentResourceName = server.inflector.pluralize(parentName);

  const routeHandler = (schema, request) => {
    const { parentId } = request.params;

    // Parent Not Found
    const parentResource = schema[parentResourceName].find(parentId);
    if (!parentResource)
      return notFoundResponse(parentId, capitalize(parentResourceName));

    const shouldAddParentId = shouldHandleOrganization(
      server,
      name,
      parentName
    );
    const createBody = {
      ...JSON.parse(request.requestBody),
      ...(shouldAddParentId
        ? getParentIdPair(request, parentName, parentId)
        : null)
    };

    return schema[resourceName].create({
      ...createBody,
      active: true,
      createdAt: new Date(),
      version: 0
    });
  };
  return withStandardErrors(routeHandler);
};

/**
 * creates a nested delete route for mirage resource
 * @param {*} server - the Mirage server
 * @param {*} name - the name of the resource (matches model name) singular NOT plural
 * @param {*} parentName - the name of the parent resource (matches model name) singular NOT plural
 */
export const genDeleteNestedResourceRouteHandler = (
  server,
  name,
  parentName
) => {
  const resourceName = server.inflector.pluralize(name);
  const parentResourceName = server.inflector.pluralize(parentName);

  const deleteOrgResource = (schema, request) => {
    const { id, parentId } = request.params;

    // Parent Not Found
    const parentResource = schema[parentResourceName].find(parentId);
    if (!parentResource)
      return notFoundResponse(parentId, capitalize(parentResourceName));

    const resource = schema[resourceName].find(id);
    if (resource) return resource.destroy();
    return notFoundResponse(id, capitalize(resourceName));
  };
  return deleteOrgResource;
};

/**
 * creates a nested soft-delete route for mirage resource
 * @param {*} server - the Mirage server
 * @param {*} name - the name of the resource (matches model name) singular NOT plural
 * @param {*} parentName - the name of the parent resource (matches model name) singular NOT plural
 */
export const genSoftDeleteNestedResourceRouteHandler = (
  server,
  name,
  parentName
) => {
  const resourceName = server.inflector.pluralize(name);
  const parentResourceName = server.inflector.pluralize(parentName);

  const deleteOrgResource = (schema, request) => {
    const { id, parentId } = request.params;

    // Parent Not Found
    const parentResource = schema[parentResourceName].find(parentId);
    if (!parentResource)
      return notFoundResponse(parentId, capitalize(parentResourceName));

    const resource = schema[resourceName].find(id);
    if (!resource) return notFoundResponse(id, capitalize(resourceName));
    return resource.update({ active: false });
  };
  return deleteOrgResource;
};
