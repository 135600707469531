import * as Yup from 'yup';

export default Yup.object().shape({
  date: Yup.array()
    .of(Yup.date())
    .nullable()
    .required(),
  name: Yup.string()
    .trim()
    .required('Required')
});
