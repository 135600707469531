import React from 'react';
import PropTypes from 'prop-types';
import { Table } from '@agconnections/grow-ui';

import FormattedNumber from 'components/FormattedNumber';

const ProductsTableRow = ({
  product,
  applicationType,
  memberRole,
  memberFinancialAccess
}) => {
  return (
    <>
      <Table.Row key={product.id}>
        <Table.Cell width="20rem">
          <span className="flex flex-col">
            <span
              className="w-64 pb-2 text-sm font-bold text-left truncate"
              data-testid="product-name"
            >
              {product.productName}
            </span>
            <span
              className="w-64 text-sm text-left text-gray-600 truncate"
              data-testid="product-manufacturer"
            >
              {product.isCustom && <>Custom</>}
              {product.isCustom && product.manufacturerName && <> • </>}
              {product.manufacturerName}
            </span>
          </span>
        </Table.Cell>
        <Table.Cell>
          {product?.[applicationType]} {product.ratePerAreaUnit}
        </Table.Cell>
        <Table.Cell>
          <FormattedNumber roundedTo="2">
            {product.appliedAreaValue}
          </FormattedNumber>{' '}
          {product.appliedAreaUnit}
        </Table.Cell>
        <Table.Cell>{product.coveragePercent * 100} %</Table.Cell>
        <Table.Cell>
          <FormattedNumber roundedTo="2">
            {product.totalProductValue}
          </FormattedNumber>{' '}
          {product.totalProductUnit}
        </Table.Cell>
        <Table.Cell width="20rem">
          <div className="mr-6">
            {(memberRole === 'Full control' || memberRole === 'View Only') &&
            memberFinancialAccess === 'none' ? null : (
              <FormattedNumber unit="usCurrency" roundedTo="2">
                {product.totalCostAtTimeOfCreation}
              </FormattedNumber>
            )}
          </div>
        </Table.Cell>
      </Table.Row>
    </>
  );
};

ProductsTableRow.propTypes = {
  applicationType: PropTypes.string.isRequired,
  memberRole: PropTypes.string.isRequired,
  memberFinancialAccess: PropTypes.string.isRequired,
  product: PropTypes.shape({
    applicationMethod: PropTypes.string,
    appliedAreaUnit: PropTypes.string,
    appliedAreaValue: PropTypes.number,
    areaUnit: PropTypes.string,
    associatedProduct: PropTypes.bool,
    averagePriceAtTimeOfCreation: PropTypes.bool,
    averagePriceUnitAtTimeOfCreation: PropTypes.string,
    coveragePercent: PropTypes.bool,
    isCustom: PropTypes.bool,
    hasCost: PropTypes.bool,
    id: PropTypes.string,
    manufacturerName: PropTypes.string,
    productId: PropTypes.string,
    productName: PropTypes.string,
    ratePerAreaUnit: PropTypes.string,
    ratePerAreaValue: PropTypes.number,
    ratePerTankUnit: PropTypes.string,
    ratePerTankValue: PropTypes.number,
    shareOwnerInformation: PropTypes.shape({
      percentGrowerResponsibility: PropTypes.number,
      totalFromGrowerInventoryQuantity: PropTypes.number,
      totalFromGrowerInventoryUnit: PropTypes.string
    }),
    specificCostPerUnit: PropTypes.number,
    specificCostUnit: PropTypes.string,
    targetPest: PropTypes.shape({
      latinNames: PropTypes.string,
      name: PropTypes.string
    }),
    totalCostAtTimeOfCreation: PropTypes.number,
    totalProductUnit: PropTypes.string,
    totalProductValue: PropTypes.number
  }).isRequired
};

export default ProductsTableRow;
