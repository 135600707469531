import React, { useContext, useEffect, useState } from 'react';
import Breadcrumb from 'components/App/AppShell/components/Breadcrumb';
import { Button } from 'syngenta-digital-cropwise-react-ui-kit';
import { AddRounded } from '@material-ui/icons';
import LeftSideLayout from 'components/LeftSideLayout';
import searchState from 'assets/search_state.svg';
import EmptyTableState from 'components/EmptyTableState';
import { Context } from 'components/Store';
import { Spinner } from '@agconnections/grow-ui';
import StoreFrontIcon from 'assets/storefront.svg';
import {
  SET_SELECTED_LOCATION,
  SET_SHOW_TOAST_SALE_LOCATION
} from 'reducers/reducer';
import { useHistory } from 'react-router-dom';
import createAction from 'helpers/createAction';
import SalesLocationDetails from './components/SaleLocationDetails';
import SaleLocationToast from './components/SaleLocationToast';
import { SaleLocationsContext } from './context';
import SaleLocationsEmptyState from './components/SaleLocationsEmptyState';
import SaleLocationsHeaderActions from './components/SaleLocationsHeaderActions';
import SaleLocationsListItem from './components/SaleLocationsListItem';
import useSaleLocations from './hooks/useSaleLocations';

const SaleLocations = () => {
  const history = useHistory();

  const {
    getSaleLocationList,
    saleLocationList,
    setSaleLocationList,
    isLoadingSaleLocationList,
    getSaleLocationById,
    isFetchingSaleLocation,
    saleLocation
  } = useSaleLocations();

  const { handleCreate } = useContext(SaleLocationsContext);

  const [
    {
      showToastSaleLocation,
      toastType,
      selectedLocation,
      organization,
      loadingOrg
    },
    dispatch
  ] = useContext(Context);

  const [showToast, setShowToast] = useState();

  const setValueSelected = value => {
    const searchParams = new URLSearchParams(location.search);
    const saleId = searchParams.get('saleId');
    if (value?.id && saleId !== value?.id) {
      const newSearchParams = new URLSearchParams();
      newSearchParams.set('saleId', value.id);

      history.replace({ search: newSearchParams.toString() });
    }

    createAction(dispatch, SET_SELECTED_LOCATION, value);
  };
  const closeToast = value => {
    createAction(dispatch, SET_SHOW_TOAST_SALE_LOCATION, value);
  };

  useEffect(() => {
    if (isLoadingSaleLocationList || !saleLocationList.length || loadingOrg)
      return;
    const searchParams = new URLSearchParams(location.search);
    const saleId = searchParams.get('saleId');
    if (!saleId) {
      setValueSelected(
        selectedLocation?.id ? selectedLocation : saleLocationList[0]
      );
      getSaleLocationById(selectedLocation?.id ?? saleLocationList[0]?.id);
      return;
    }
    const saleLocationValue = saleLocationList.find(sale => sale.id === saleId);

    if (!saleLocationValue) {
      setValueSelected(saleLocationList[0]);
      getSaleLocationById(saleLocationList[0].id);
      return;
    }
    setValueSelected(saleLocationValue);
    getSaleLocationById(saleLocationValue.id);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [saleLocationList, isLoadingSaleLocationList, organization]);

  useEffect(() => {
    const newValue = { ...selectedLocation, ...saleLocation };
    createAction(dispatch, SET_SELECTED_LOCATION, newValue);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [saleLocation]);

  useEffect(() => {
    setShowToast(showToastSaleLocation);
  }, [showToastSaleLocation]);

  return (
    <>
      <Breadcrumb>
        <Breadcrumb.Item title="Yield" value="Sale Locations" isLast />
      </Breadcrumb>
      {!loadingOrg ? (
        <div className="w-full h-full flex flex-row">
          <div>
            <LeftSideLayout
              emptyState={<SaleLocationsEmptyState />}
              type="Sale"
              typeIcon={StoreFrontIcon}
              headerActions={
                <SaleLocationsHeaderActions
                  onCreate={handleCreate}
                  onSort={getSaleLocationList}
                />
              }
              elementList={saleLocationList}
              setValueSelected={setValueSelected}
              valueSelected={selectedLocation}
              isLoadingLeftSide={isLoadingSaleLocationList}
              renderItem={SaleLocationsListItem}
              selectedValueId={selectedLocation?.id}
              getSaleLocationById={getSaleLocationById}
            />
          </div>
          {!isLoadingSaleLocationList &&
            ((saleLocationList.length > 0 && (
              <SalesLocationDetails
                selectedLocation={selectedLocation}
                saleLocationList={saleLocationList}
                setSaleLocationList={setSaleLocationList}
                isFetchingSaleLocation={isFetchingSaleLocation}
              />
            )) || (
              <div className="bg-white m-4 h-356px w-full rounded-lg shadow-sm-card">
                <EmptyTableState
                  overlayIcon={searchState}
                  title="No Sale Locations Found"
                  button={
                    <Button
                      style={{ width: '160px' }}
                      icon={<AddRounded />}
                      type="primary"
                      onClick={handleCreate}
                    >
                      Create Location
                    </Button>
                  }
                />
              </div>
            ))}
        </div>
      ) : (
        <Spinner size="lg" />
      )}

      {showToast && (
        <SaleLocationToast
          showToast={showToast}
          setShowToast={closeToast}
          state={toastType}
        />
      )}
    </>
  );
};
export default SaleLocations;
