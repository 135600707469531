import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Formik } from 'formik';

import { Button, Form, Input } from '@agconnections/grow-ui';

import EditButton from 'components/Buttons/EditButton';
import SectionHeader from 'components/SectionHeader';
import Paper from 'components/Paper';
import GrowUIFormField from 'components/Forms/GrowUIFormField';

import schema from './schema';

const OrganizationDetail = ({ organization, onSave, userDetails, members }) => {
  const intialValues = {
    ...organization,
    primaryContactEmail: organization?.primaryContactEmail || '',
    primaryContactName: organization?.primaryContactName || '',
    primaryContactPhoneNumber: organization?.primaryContactPhoneNumber || '',
    billingAddress: {
      ...organization?.billingAddress,
      address1: organization?.billingAddress?.address1 || ''
    }
  };

  const [memberDetails, setMemberDetails] = useState({
    id: '',
    name: '',
    email: '',
    memberStatus: '',
    role: ''
  });

  const [
    isEditingOrganizationDetails,
    setIsEditingOrganizationDetails
  ] = useState(false);

  const organizationInputIsDisabled = !isEditingOrganizationDetails;

  const toggleIsEditing = () => {
    setIsEditingOrganizationDetails(previousValue => {
      return !previousValue;
    });
  };

  useEffect(() => {
    if (members?.length > 0) {
      const filterMember = members?.filter(
        eachMember => eachMember.accountId === userDetails.id
      )[0];
      if (filterMember?.memberStatus !== 'Pending') {
        setMemberDetails({
          id: filterMember?.accountId || '',
          name: filterMember?.name || '',
          email: filterMember?.email || '',
          memberStatus: filterMember?.memberStatus || '',
          role: filterMember?.role || ''
        });
      }
    }
  }, [members, userDetails.id]);

  const handleSubmit = formData => {
    onSave({
      id: formData.id,
      name: formData.name,
      primaryContactName: formData.primaryContactName || '',
      primaryContactEmail: formData.primaryContactEmail || '',
      primaryContactPhoneNumber: formData.primaryContactPhoneNumber || '',
      billingAddress: {
        address1: formData.billingAddress.address1 || '',
        address2: '',
        postCode: '',
        state: '',
        country: ''
      },
      languageCountry: organization.languageCountry || 'en-US',
      areaUnit: organization.areaUnit || 'acres',
      masterListGroup: 'en-US',
      timeZone: organization.timeZone || 'GMT'
    });
    setIsEditingOrganizationDetails(false);
  };

  const getEditButton = role => {
    if (role.toUpperCase() !== 'FULL CONTROL') {
      return <EditButton onClick={toggleIsEditing} />;
    }

    return <EditButton onClick={toggleIsEditing} disabled />;
  };

  const formatPhoneNumber = (number, setFieldValue) => {
    const cleanedNumber = number.replace(/[^\d]/g, '');

    if (cleanedNumber.length === 10) {
      setFieldValue(
        'primaryContactPhoneNumber',
        `(${cleanedNumber.slice(0, 3)}) ${cleanedNumber.slice(
          3,
          6
        )}-${cleanedNumber.slice(6)}`
      );
    } else if (cleanedNumber.length > 10) {
      setFieldValue('primaryContactPhoneNumber', `+${cleanedNumber}`);
    }
  };

  return (
    <div data-testid="organization-detail" className="mb-6">
      <Formik
        initialValues={intialValues}
        enableReinitialize
        validationSchema={schema}
        onSubmit={handleSubmit}
      >
        {({ submitForm, resetForm, values, setFieldValue }) => (
          <>
            <div className="flex items-center mt-2">
              <SectionHeader title="Organization">
                <svg
                  width="30"
                  height="23"
                  viewBox="0 0 30 23"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M4.5 9.85714C6.15469 9.85714 7.5 8.38371 7.5 6.57143C7.5 4.75915 6.15469 3.28571 4.5 3.28571C2.84531 3.28571 1.5 4.75915 1.5 6.57143C1.5 8.38371 2.84531 9.85714 4.5 9.85714ZM25.5 9.85714C27.1547 9.85714 28.5 8.38371 28.5 6.57143C28.5 4.75915 27.1547 3.28571 25.5 3.28571C23.8453 3.28571 22.5 4.75915 22.5 6.57143C22.5 8.38371 23.8453 9.85714 25.5 9.85714ZM27 11.5H24C23.175 11.5 22.4297 11.8645 21.8859 12.4549C23.775 13.5895 25.1156 15.6379 25.4062 18.0714H28.5C29.3297 18.0714 30 17.3373 30 16.4286V14.7857C30 12.9734 28.6547 11.5 27 11.5ZM15 11.5C17.9016 11.5 20.25 8.9279 20.25 5.75C20.25 2.5721 17.9016 0 15 0C12.0984 0 9.75 2.5721 9.75 5.75C9.75 8.9279 12.0984 11.5 15 11.5ZM18.6 13.1429H18.2109C17.2359 13.6562 16.1531 13.9643 15 13.9643C13.8469 13.9643 12.7688 13.6562 11.7891 13.1429H11.4C8.41875 13.1429 6 15.792 6 19.0571V20.5357C6 21.8962 7.00781 23 8.25 23H21.75C22.9922 23 24 21.8962 24 20.5357V19.0571C24 15.792 21.5812 13.1429 18.6 13.1429ZM8.11406 12.4549C7.57031 11.8645 6.825 11.5 6 11.5H3C1.34531 11.5 0 12.9734 0 14.7857V16.4286C0 17.3373 0.670312 18.0714 1.5 18.0714H4.58906C4.88438 15.6379 6.225 13.5895 8.11406 12.4549Z"
                    fill="#73DB78"
                  />
                </svg>
              </SectionHeader>

              {isEditingOrganizationDetails ? (
                <div className="flex">
                  <Button
                    type="outline"
                    onClick={() => {
                      resetForm();
                      toggleIsEditing();
                    }}
                  >
                    Cancel
                  </Button>
                  <div className="ml-2">
                    <Button type="submit" onClick={submitForm}>
                      Save
                    </Button>
                  </div>
                </div>
              ) : (
                getEditButton(memberDetails?.role)
              )}
            </div>

            <Paper>
              <Form>
                <div className="grid grid-cols-2 gap-6">
                  <Form.Field label="Organization Name">
                    <GrowUIFormField
                      control={Input}
                      name="name"
                      disabled={organizationInputIsDisabled}
                    />
                  </Form.Field>
                  <div />
                  <Form.Field label="Primary Contact Name">
                    <GrowUIFormField
                      control={Input}
                      name="primaryContactName"
                      disabled={organizationInputIsDisabled}
                    />
                  </Form.Field>
                  <Form.Field label="Primary Contact Email">
                    <GrowUIFormField
                      control={Input}
                      name="primaryContactEmail"
                      disabled={organizationInputIsDisabled}
                    />
                  </Form.Field>
                  <Form.Field label="Billing Address">
                    <GrowUIFormField
                      control={Input}
                      name="billingAddress.address1"
                      disabled={organizationInputIsDisabled}
                    />
                  </Form.Field>

                  <Form.Field label="Primary Contact Phone Number">
                    <GrowUIFormField
                      control={Input}
                      name="primaryContactPhoneNumber"
                      disabled={organizationInputIsDisabled}
                      onBlur={() =>
                        formatPhoneNumber(
                          values.primaryContactPhoneNumber,
                          setFieldValue
                        )
                      }
                    />
                  </Form.Field>
                </div>
              </Form>
            </Paper>
          </>
        )}
      </Formik>
    </div>
  );
};

OrganizationDetail.propTypes = {
  organization: PropTypes.shape({
    name: PropTypes.string,
    languageCountry: PropTypes.string,
    areaUnit: PropTypes.string,
    timeZone: PropTypes.string,
    primaryContactEmail: PropTypes.string,
    primaryContactName: PropTypes.string,
    primaryContactPhoneNumber: PropTypes.string,
    billingAddress: PropTypes.instanceOf(Object)
  }),
  onSave: PropTypes.func.isRequired,
  userDetails: PropTypes.instanceOf(Object),
  members: PropTypes.instanceOf(Array)
};

OrganizationDetail.defaultProps = {
  organization: null,
  userDetails: {},
  members: []
};

export default OrganizationDetail;
