import React from 'react';
import PropTypes from 'prop-types';
import SpinnerModal from 'components/SpinnerModal';

const OrgGlobalLoader = ({ route, shouldRenderInRoutes, isLoading }) => {
  const simplifyRoute = _route => {
    const regex = /\/app\/organizations\//;
    if (regex.test(_route)) {
      return route
        .split('/')
        .slice(0, 3)
        .join('/');
    }
    return _route;
  };
  const routeTrimmer = simplifyRoute(route);
  const isToRender = shouldRenderInRoutes.includes(routeTrimmer);
  return (
    isToRender && (
      <SpinnerModal open={isLoading} message="Loading Organization" />
    )
  );
};

OrgGlobalLoader.propTypes = {
  route: PropTypes.string.isRequired,
  shouldRenderInRoutes: PropTypes.arrayOf(PropTypes.string).isRequired,
  isLoading: PropTypes.bool.isRequired
};

export default OrgGlobalLoader;
