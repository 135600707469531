import React, { useState, useContext } from 'react';
import PropTypes from 'prop-types';
import { SimpleModal } from '@agconnections/grow-ui';
import { withRouter, useHistory } from 'react-router-dom';
import { equipment as equipmentApi } from 'utilities/api';
import catchCancel from 'helpers/catchCancel';
// eslint-disable-next-line no-unused-vars
import { AmplitudeContext } from 'utilities/amplitude/useAmplitude';
import { paths } from 'routes/paths';
// import { getByTitle } from '@testing-library/react';

const DeleteEquipmentModal = ({
  open,
  close,
  equipmentName,
  equipmentId,
  setItems,
  equipments,
  label = ''
}) => {
  const history = useHistory();
  const [isSaving, setIsSaving] = useState(false);
  const amplitude = useContext(AmplitudeContext);
  const handleDelete = async () => {
    let newEquipmentList = [];
    if (label !== 'view') {
      newEquipmentList = equipments.filter(
        eachItem => eachItem.id !== equipmentId
      );
    }
    // amplitude trigger
    amplitude.sendEventToAmplitude(
      amplitude.events.epic.Equipment.deleteEquipment
    );
    setIsSaving(true);
    const { promise } = equipmentApi.delete(equipmentId);
    await promise
      .then(() => {
        if (label === 'view') {
          history.replace({
            pathname: paths.equipment
          });
        } else {
          setItems(newEquipmentList);
        }
        setIsSaving(false);
        close();
      })
      .catch(catchCancel);
  };

  const handleCancel = () => {
    close();
  };

  const actionPerformed = 'Delete "';
  const closeQuote = '"';

  return (
    <SimpleModal
      open={open}
      title={actionPerformed + equipmentName + closeQuote}
      close={close}
      cancelLabel="Cancel"
      confirmLabel="Delete"
      onCancel={() => handleCancel()}
      type="error"
      onConfirm={() => handleDelete()}
      isSaving={isSaving}
    >
      <div className="pr-6">
        You are about to permanently delete this equipment item. This cannot be
        undone. Are you sure you want to proceed?
      </div>
    </SimpleModal>
  );
};

DeleteEquipmentModal.propTypes = {
  open: PropTypes.bool.isRequired,
  close: PropTypes.func.isRequired,
  equipmentName: PropTypes.string,
  equipmentId: PropTypes.string,
  setItems: PropTypes.func,
  equipments: PropTypes.arrayOf(PropTypes.object),
  label: PropTypes.string
};

DeleteEquipmentModal.defaultProps = {
  equipmentName: '',
  equipments: [{}],
  setItems: () => {},
  equipmentId: '',
  label: ''
};

export default withRouter(DeleteEquipmentModal);
