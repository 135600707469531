import { useFlags } from 'launchdarkly-react-client-sdk';

const parseEnvId = id => (id?.includes(',') ? id.split(',') : id);

export const ReportCategories = {
  AppliedProducts: 'Applied Products',
  Fields: 'Fields',
  FSA: 'FSA',
  Excel: 'Excel',
  Products: 'Products',
  Properties: 'Properties',
  ProductionRecords: 'Production Records',
  YieldAndProfitability: 'Yield and Profitability'
};
// These values will be displayed in the left nav
export const ReportSubTypes = {
  ByDay: 'By Day',
  ByField: 'By Field',
  CropDetails: 'By Crop Details',
  CropLayout: 'Crop Layout',
  CropZoneInfo: 'Crop Zone Info',
  Details: 'Details',
  Dicamba: 'Dicamba',
  ExcelReport: 'Excel Report',
  FertilizerByCrop: 'Fertilizer Report',
  FSA: 'FSA Report',
  InventorySummary: 'Inventory Summary',
  NoticeOfApplication: 'Notice of Application',
  PesticideUsage: 'Pesticide Use',
  PHI: 'PHI Report',
  PlansSummary: 'Plans Summary',
  Profitability: 'Profitability',
  PrintTasks: 'Print Tasks',
  PrintRecommendations: 'Print Recommendations',
  PrintInvoices: 'Print Invoices',
  PrintPlans: 'Print Plans',
  Regulatory: 'Regulatory',
  REI: 'REI Report',
  Summary: 'Summary',
  YieldProduction: 'Yield Production'
};

export const getReport = (reports, category, subType) =>
  reports.find(
    report => report.category === category && report.subType === subType
  );

export const getReportByName = (reports, _name) =>
  reports.find(({ name }) => name === _name);

export const getReportsByCategory = (reports, _category) =>
  reports.filter(({ category }) => category === _category);

export default () => {
  const {
    feReportOptionByField,
    feReportOptionAppliedProductsByCropDetails,
    feReportOptionAppliedProductsByDay,
    feReportOptionAppliedProductsDetails,
    feReportOptionAppliedProductsDicamba,
    feReportOptionAppliedProductsFertilizerByCrop,
    feReportOptionPesticideUsage,
    feReportOptionAppliedProductsPhi,
    feReportOptionAppliedProductsRei,
    feReportOptionAppliedProductsSummary,
    feReportOptionCropLayout,
    feReportOptionFsa,
    feReportOptionInventorySummary,
    feReportOptionNoticeOfApplication,
    feReportOptionPlansSummary,
    feReportOptionPrintInvoices,
    feReportOptionPrintPlans,
    feReportOptionPrintRecommendations,
    feReportOptionPrintTasks,
    feReportOptionPropertiesByCropZoneInfo,
    feReportOptionRegulatory,
    feReportOptionYieldAndProfitabilityByProfitability,
    feReportOptionExcelReport,
    feReportOptionYieldAndProfitabilityYieldProduction
  } = useFlags();

  const reports = [
    {
      name: 'Applied Products By Field',
      featureFlag: feReportOptionByField,
      category: ReportCategories.AppliedProducts,
      subType: ReportSubTypes.ByField,
      formConfig: {
        showCropSeasons: true,
        showDateRange: true,
        showProperties: true,
        showProducts: true,
        showTimezoneOffset: true
      },
      reportConfig: {
        isPaginated: true,
        groupId: parseEnvId(process.env.REACT_APP_POWERBI_GROUP_ID),
        datasetId: parseEnvId(
          process.env.REACT_APP_APPLIED_PRODUCTS_BY_FIELD_DATASET_ID
        ),
        reportId: parseEnvId(
          process.env.REACT_APP_APPLIED_PRODUCTS_BY_FIELD_EMBED_REPORT_ID
        )
      }
    },
    {
      name: 'Applied Products By Crop Layout',
      featureFlag: feReportOptionAppliedProductsByCropDetails,
      category: ReportCategories.AppliedProducts,
      subType: ReportSubTypes.CropDetails,
      formConfig: {
        showCropSeasons: true,
        showDateRange: true,
        showProperties: true,
        showProducts: true,
        showTimezoneOffset: true
      },
      reportConfig: {
        isPaginated: true,
        groupId: parseEnvId(process.env.REACT_APP_POWERBI_GROUP_ID),
        datasetId: parseEnvId(
          process.env.REACT_APP_APPLIED_PRODUCTS_BY_CROP_DETAILS_DATASET_ID
        ),
        reportId: parseEnvId(
          process.env.REACT_APP_APPLIED_PRODUCTS_BY_CROP_DETAILS_EMBED_REPORT_ID
        )
      }
    },
    {
      name: 'Applied Products By Day',
      featureFlag: feReportOptionAppliedProductsByDay,
      category: ReportCategories.AppliedProducts,
      subType: ReportSubTypes.ByDay,
      formConfig: {
        showCropSeasons: true,
        showDateRange: true,
        showProperties: true,
        showProducts: true,
        showTimezoneOffset: true
      },
      reportConfig: {
        isPaginated: true,
        groupId: parseEnvId(process.env.REACT_APP_POWERBI_GROUP_ID),
        datasetId: parseEnvId(
          process.env.REACT_APP_APPLIED_PRODUCTS_BY_DAY_DATASET_ID
        ),
        reportId: parseEnvId(
          process.env.REACT_APP_APPLIED_PRODUCTS_BY_DAY_EMBED_REPORT_ID
        )
      }
    },
    {
      name: 'Applied Products Details',
      featureFlag: feReportOptionAppliedProductsDetails,
      category: ReportCategories.AppliedProducts,
      subType: ReportSubTypes.Details,
      formConfig: {
        showCropSeasons: true,
        showDateRange: true,
        showProperties: true,
        showProducts: true,
        showTimezoneOffset: true
      },
      reportConfig: {
        isPaginated: true,
        groupId: parseEnvId(process.env.REACT_APP_POWERBI_GROUP_ID),
        datasetId: parseEnvId(
          process.env.REACT_APP_APPLIED_PRODUCTS_DETAILS_DATASET_ID
        ),
        reportId: parseEnvId(
          process.env.REACT_APP_APPLIED_PRODUCTS_DETAILS_EMBED_REPORT_ID
        )
      }
    },
    {
      name: 'Applied Products Dicamba',
      featureFlag: feReportOptionAppliedProductsDicamba,
      category: ReportCategories.AppliedProducts,
      subType: ReportSubTypes.Dicamba,
      formConfig: {
        showCropSeasons: true,
        showDateRange: true,
        showProperties: true,
        showProducts: true,
        showTimezoneOffset: true
      },
      reportConfig: {
        isPaginated: true,
        groupId: parseEnvId(process.env.REACT_APP_POWERBI_GROUP_ID),
        datasetId: parseEnvId(
          process.env.REACT_APP_APPLIED_PRODUCTS_DICAMBA_DATASET_ID
        ),
        reportId: parseEnvId(
          process.env.REACT_APP_APPLIED_PRODUCTS_DICAMBA_EMBED_REPORT_ID
        )
      }
    },
    {
      name: 'Fertilizer Report',
      featureFlag: feReportOptionAppliedProductsFertilizerByCrop,
      category: ReportCategories.AppliedProducts,
      subType: ReportSubTypes.FertilizerByCrop,
      formConfig: {
        showCropSeasons: true,
        showDateRange: true,
        showProperties: true,
        showProducts: true
      },
      reportConfig: {
        isPaginated: true,
        groupId: parseEnvId(process.env.REACT_APP_POWERBI_GROUP_ID),
        datasetId: parseEnvId(
          process.env.REACT_APP_APPLIED_PRODUCTS_FERTILIZER_BY_CROP_DATASET_ID
        ),
        reportId: parseEnvId(
          process.env
            .REACT_APP_APPLIED_PRODUCTS_FERTILIZER_BY_CROP_EMBED_REPORT_ID
        )
      }
    },
    {
      name: 'Pesticide Use',
      featureFlag: feReportOptionPesticideUsage,
      category: ReportCategories.AppliedProducts,
      subType: ReportSubTypes.PesticideUsage,
      formConfig: {
        showCropSeasons: true,
        showDateRange: true,
        showProperties: true,
        showProducts: true,
        showTimezoneOffset: true
      },
      reportConfig: {
        isPaginated: true,
        groupId: parseEnvId(process.env.REACT_APP_POWERBI_GROUP_ID),
        datasetId: parseEnvId(
          process.env.REACT_APP_PESTICIDE_USAGE_BY_CROP_DATASET_ID
        ),
        reportId: parseEnvId(
          process.env.REACT_APP_PESTICIDE_USAGE_EMBED_REPORT_ID
        )
      }
    },
    {
      name: 'PHI Report',
      featureFlag: feReportOptionAppliedProductsPhi,
      category: ReportCategories.AppliedProducts,
      subType: ReportSubTypes.PHI,
      formConfig: {
        showCropSeasons: true,
        showDateRange: true,
        showProperties: true,
        showProducts: false,
        showTimezoneOffset: true
      },
      reportConfig: {
        isPaginated: true,
        groupId: parseEnvId(process.env.REACT_APP_POWERBI_GROUP_ID),
        datasetId: parseEnvId(
          process.env.REACT_APP_APPLIED_PRODUCTS_PHI_DATASET_ID
        ),
        reportId: parseEnvId(
          process.env.REACT_APP_APPLIED_PRODUCTS_PHI_EMBED_REPORT_ID
        )
      }
    },
    {
      name: 'Applied Products Regulatory',
      featureFlag: feReportOptionRegulatory,
      category: ReportCategories.AppliedProducts,
      subType: ReportSubTypes.Regulatory,
      formConfig: {
        showCropSeasons: true,
        showDateRange: true,
        showProperties: true,
        showProducts: true,
        showTimezoneOffset: true
      },
      reportConfig: {
        isPaginated: true,
        groupId: parseEnvId(process.env.REACT_APP_POWERBI_GROUP_ID),
        datasetId: parseEnvId(
          process.env.REACT_APP_APPLIED_PRODUCTS_REGULATORY_DATASET_ID
        ),
        reportId: parseEnvId(
          process.env.REACT_APP_APPLIED_PRODUCTS_REGULATORY_EMBED_REPORT_ID
        )
      }
    },
    {
      name: 'Applied Products REI Report',
      featureFlag: feReportOptionAppliedProductsRei,
      category: ReportCategories.AppliedProducts,
      subType: ReportSubTypes.REI,
      formConfig: {
        showCropSeasons: true,
        showDateRange: true,
        showProperties: true,
        showProducts: true,
        showTimezoneOffset: true
      },
      reportConfig: {
        isPaginated: true,
        groupId: parseEnvId(process.env.REACT_APP_POWERBI_GROUP_ID),
        datasetId: parseEnvId(
          process.env.REACT_APP_APPLIED_PRODUCTS_REI_DATASET_ID
        ),
        reportId: parseEnvId(
          process.env.REACT_APP_APPLIED_PRODUCTS_REI_EMBED_REPORT_ID
        )
      }
    },
    {
      name: 'Applied Products Summary',
      featureFlag: feReportOptionAppliedProductsSummary,
      category: ReportCategories.AppliedProducts,
      subType: ReportSubTypes.Summary,
      formConfig: {
        showCropSeasons: true,
        showDateRange: true,
        showProperties: true,
        showProducts: true,
        showTimezoneOffset: true
      },
      reportConfig: {
        isPaginated: true,
        groupId: parseEnvId(process.env.REACT_APP_POWERBI_GROUP_ID),
        datasetId: parseEnvId(
          process.env.REACT_APP_APPLIED_PRODUCTS_SUMMARY_DETAILS_DATASET_ID
        ),
        reportId: parseEnvId(
          process.env.REACT_APP_APPLIED_PRODUCTS_SUMMARY_EMBED_REPORT_ID
        )
      }
    },
    {
      name: 'Excel Report',
      featureFlag: feReportOptionExcelReport,
      category: ReportCategories.Excel,
      subType: ReportSubTypes.ExcelReport,
      formConfig: {
        showCropSeasons: true,
        showDateRange: true,
        showExcelAlert: true,
        showProperties: true,
        showProducts: false,
        showTimezoneOffset: true
      },
      reportConfig: {
        isPaginated: true,
        groupId: parseEnvId(process.env.REACT_APP_POWERBI_GROUP_ID),
        datasetId: parseEnvId(process.env.REACT_APP_EXCEL_REPORT_DATASET_ID),
        reportId: parseEnvId(process.env.REACT_APP_EXCEL_REPORT_EMBED_REPORT_ID)
      }
    },
    {
      name: 'Crop Layout',
      featureFlag: feReportOptionCropLayout,
      category: ReportCategories.Properties,
      subType: ReportSubTypes.CropLayout,
      formConfig: {
        showCropSeasons: true,
        showDateRange: false,
        showProperties: true,
        showProducts: false
      },
      reportConfig: {
        isPaginated: true,
        groupId: parseEnvId(process.env.REACT_APP_POWERBI_GROUP_ID),
        datasetId: parseEnvId(
          process.env.REACT_APP_FIELDS_BY_CROP_LAYOUT_DATASET_ID
        ),
        reportId: parseEnvId(
          process.env.REACT_APP_FIELDS_BY_CROP_LAYOUT_EMBED_REPORT_ID
        )
      }
    },
    {
      name: 'FSA Report',
      featureFlag: feReportOptionFsa,
      category: ReportCategories.FSA,
      subType: ReportSubTypes.FSA,
      formConfig: {
        showCropSeasons: true,
        showDateRange: false,
        showProperties: true,
        showProducts: false
      },
      reportConfig: {
        isPaginated: true,
        groupId: parseEnvId(process.env.REACT_APP_POWERBI_GROUP_ID),
        datasetId: parseEnvId(process.env.REACT_APP_FSA_REPORT_DATASET_ID),
        reportId: parseEnvId(process.env.REACT_APP_FSA_REPORT_EMBED_REPORT_ID)
      }
    },
    {
      name: 'Notice of Application',
      featureFlag: feReportOptionNoticeOfApplication,
      category: ReportCategories.AppliedProducts,
      subType: ReportSubTypes.NoticeOfApplication,
      formConfig: {
        showCropSeasons: true,
        showDateRange: true,
        showProperties: true,
        showProducts: true,
        showTimezoneOffset: true
      },
      reportConfig: {
        isPaginated: true,
        groupId: parseEnvId(process.env.REACT_APP_POWERBI_GROUP_ID),
        datasetId: parseEnvId(
          process.env.REACT_APP_NOTICE_OF_APPLICATION_DATASET_ID
        ),
        reportId: parseEnvId(
          process.env.REACT_APP_NOTICE_OF_APPLICATION_EMBED_REPORT_ID
        )
      }
    },
    {
      name: 'Print Invoices',
      featureFlag: feReportOptionPrintInvoices,
      category: ReportCategories.ProductionRecords,
      subType: ReportSubTypes.PrintInvoices,
      formConfig: {
        showCropSeasons: true,
        showDateRange: true,
        showProperties: false,
        showProducts: false,
        showTimezoneOffset: true,
        showPrintAlert: true
      },
      reportConfig: {
        isPaginated: true,
        groupId: parseEnvId(process.env.REACT_APP_POWERBI_GROUP_ID),
        datasetId: parseEnvId(
          process.env.REACT_APP_PRODUCTION_RECORDS_PRINT_INVOICES_DATASET_ID
        ),
        reportId: parseEnvId(
          process.env
            .REACT_APP_PRODUCTION_RECORDS_PRINT_INVOICES_EMBED_REPORT_ID
        )
      }
    },
    {
      name: 'Print Plans',
      featureFlag: feReportOptionPrintPlans,
      category: ReportCategories.ProductionRecords,
      subType: ReportSubTypes.PrintPlans,
      formConfig: {
        showCropSeasons: true,
        showDateRange: true,
        showProperties: false,
        showProducts: false,
        showTimezoneOffset: true,
        showPrintAlert: true
      },
      reportConfig: {
        isPaginated: true,
        groupId: parseEnvId(process.env.REACT_APP_POWERBI_GROUP_ID),
        datasetId: parseEnvId(
          process.env.REACT_APP_PRODUCTION_RECORDS_PRINT_PLANS_DATASET_ID
        ),
        reportId: parseEnvId(
          process.env.REACT_APP_PRODUCTION_RECORDS_PRINT_PLANS_EMBED_REPORT_ID
        )
      }
    },
    {
      name: 'Print Recommendations',
      featureFlag: feReportOptionPrintRecommendations,
      category: ReportCategories.ProductionRecords,
      subType: ReportSubTypes.PrintRecommendations,
      formConfig: {
        showCropSeasons: true,
        showDateRange: true,
        showProperties: false,
        showProducts: false,
        showTimezoneOffset: true,
        showPrintAlert: true
      },
      reportConfig: {
        isPaginated: true,
        groupId: parseEnvId(process.env.REACT_APP_POWERBI_GROUP_ID),
        datasetId: parseEnvId(
          process.env
            .REACT_APP_PRODUCTION_RECORDS_PRINT_RECOMMENDATIONS_DATASET_ID
        ),
        reportId: parseEnvId(
          process.env
            .REACT_APP_PRODUCTION_RECORDS_PRINT_RECOMMENDATIONS_EMBED_REPORT_ID
        )
      }
    },

    {
      name: 'Print Tasks',
      featureFlag: feReportOptionPrintTasks,
      category: ReportCategories.ProductionRecords,
      subType: ReportSubTypes.PrintTasks,
      formConfig: {
        showCropSeasons: true,
        showDateRange: true,
        showProperties: false,
        showProducts: false,
        showTimezoneOffset: true,
        showPrintAlert: true
      },
      reportConfig: {
        isPaginated: true,
        groupId: parseEnvId(process.env.REACT_APP_POWERBI_GROUP_ID),
        datasetId: parseEnvId(
          process.env.REACT_APP_PRODUCTION_RECORDS_PRINT_TASKS_DATASET_ID
        ),
        reportId: parseEnvId(
          process.env.REACT_APP_PRODUCTION_RECORDS_PRINT_TASKS_EMBED_REPORT_ID
        )
      }
    },
    {
      name: 'Inventory Summary',
      featureFlag: feReportOptionInventorySummary,
      category: ReportCategories.Products,
      subType: ReportSubTypes.InventorySummary,
      formConfig: {
        showCropSeasons: true,
        showDateRange: true,
        showProperties: false,
        showProducts: true,
        showTimezoneOffset: true
      },
      reportConfig: {
        isPaginated: true,
        groupId: parseEnvId(process.env.REACT_APP_POWERBI_GROUP_ID),
        datasetId: parseEnvId(
          process.env.REACT_APP_PRODUCTS_INVENTORY_SUMMARY_DATASET_ID
        ),
        reportId: parseEnvId(
          process.env.REACT_APP_PRODUCTS_INVENTORY_SUMMARY_EMBED_REPORT_ID
        )
      }
    },
    {
      name: 'Products Plan Summary',
      featureFlag: feReportOptionPlansSummary,
      category: ReportCategories.Products,
      subType: ReportSubTypes.PlansSummary,
      formConfig: {
        showCropSeasons: true,
        showDateRange: false,
        showProperties: false,
        showProducts: false,
        showPlans: true
      },
      reportConfig: {
        isPaginated: true,
        groupId: parseEnvId(process.env.REACT_APP_POWERBI_GROUP_ID),
        datasetId: parseEnvId(
          process.env.REACT_APP_PRODUCTS_PLAN_SUMMARY_DATASET_ID
        ),
        reportId: parseEnvId(
          process.env.REACT_APP_PRODUCTS_PLAN_SUMMARY_EMBED_REPORT_ID
        )
      }
    },
    {
      name: 'Profitability',
      featureFlag: feReportOptionYieldAndProfitabilityByProfitability,
      category: ReportCategories.YieldAndProfitability,
      subType: ReportSubTypes.Profitability,
      formConfig: {
        showCropSeasons: true,
        showDateRange: true,
        showProperties: true,
        showProducts: false,
        showTimezoneOffset: true
      },
      reportConfig: {
        isPaginated: true,
        groupId: parseEnvId(process.env.REACT_APP_POWERBI_GROUP_ID),
        datasetId: parseEnvId(
          process.env.REACT_APP_Y_AND_P_PROFITABILITY_DATASET_ID
        ),
        reportId: parseEnvId(
          process.env.REACT_APP_Y_AND_P_PROFITABILITY_EMBED_REPORT_ID
        )
      }
    },
    {
      name: 'Crop Zone Info',
      featureFlag: feReportOptionPropertiesByCropZoneInfo,
      category: ReportCategories.Properties,
      subType: ReportSubTypes.CropZoneInfo,
      formConfig: {
        showCropSeasons: true,
        showDateRange: true,
        showProperties: true,
        showProducts: false,
        showTimezoneOffset: true
      },
      reportConfig: {
        isPaginated: true,
        groupId: parseEnvId(process.env.REACT_APP_POWERBI_GROUP_ID),
        datasetId: parseEnvId(
          process.env.REACT_APP_PROPERTIES_BY_CROP_ZONE_INFO_DATASET_ID
        ),
        reportId: parseEnvId(
          process.env.REACT_APP_PROPERTIES_BY_CROP_ZONE_INFO_EMBED_REPORT_ID
        )
      }
    },
    {
      name: 'Yield Production',
      featureFlag: feReportOptionYieldAndProfitabilityYieldProduction,
      category: ReportCategories.YieldAndProfitability,
      subType: ReportSubTypes.YieldProduction,
      formConfig: {
        showCropSeasons: true,
        showDateRange: true,
        showProperties: true,
        showProducts: false,
        showTimezoneOffset: true
      },
      reportConfig: {
        isPaginated: true,
        groupId: parseEnvId(process.env.REACT_APP_POWERBI_GROUP_ID),
        datasetId: parseEnvId(
          process.env.REACT_APP_Y_AND_P_YIELD_PRODUCTION_DATASET_ID
        ),
        reportId: parseEnvId(
          process.env.REACT_APP_Y_AND_P_YIELD_PRODUCTION_EMBED_REPORT_ID
        )
      }
    }
  ];

  return reports;
};
