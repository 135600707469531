/* eslint-disable react/prop-types */
import React, { useEffect, useState } from 'react';
import './index.css';
import PropTypes from 'prop-types';
import {
  Button,
  ButtonType,
  Drawer,
  Tree
} from 'syngenta-digital-cropwise-react-ui-kit';
import { featureCollection } from '@turf/turf';
import { FETCH_TYPES } from 'helpers/constants';
import usePopulateMapData from 'hooks/usePopulateMapData';
import FormattedNumber from 'components/FormattedNumber';
import { TLoad } from 'screens/YieldV2/Loads/constants';
import SearchInputLandingProperties from 'screens/Property/PropertiesLanding/components/SearchInputLandingProperties';
import FieldIcon from 'assets/field_filled.svg';
import ChevronLeft from 'assets/chevron_left.svg';
import Divider from 'assets/divider.svg';
import PropertyMapWrapper from 'screens/Property/PropertiesLanding/PropertyLandingMap/PropertyMapWrapper';
import cropzoneToGeoJSONFeature from '../../helpers/cropzoneToGeoJSONFeature';
import {
  filterPropertiesByCropZones,
  propertiesResponseToTreeData
} from '../../helpers/cropZoneDetailsHelpers';

const LoadDetailsDrawer = ({
  visible,
  onClose,
  load,
  cropSeasonHandler,
  sourcesQnt,
  cropzoneTotalArea
}) => {
  const {
    dataSorted: propertyLandingPageData,
    reloadData
  } = usePopulateMapData({ fetchType: FETCH_TYPES.propertiesMap });

  const [treeData, setTreeData] = useState(null);

  useEffect(() => {
    reloadData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const sourceIds = load?.sources?.map(({ id }) => id);

    const filteredProperties = filterPropertiesByCropZones(
      propertyLandingPageData.properties,
      sourceIds
    );
    const mappedData = propertiesResponseToTreeData(filteredProperties);

    setTreeData(mappedData);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [propertyLandingPageData]);

  return (
    <Drawer
      className="load-product-drawer"
      width="80%"
      open={visible}
      placement="right"
      closable
      maskClosable={false}
      onClose={() => {
        onClose('right');
      }}
      footer={
        <Button
          type={ButtonType.outline}
          className="mr-10px"
          onClick={() => {
            onClose('right');
          }}
        >
          <div className="flex flex-row">
            <img className="mr-1" src={ChevronLeft} alt="chevron icon" />
            <div>Back</div>
          </div>
        </Button>
      }
    >
      <div className="flex flex-row h-full">
        <section className="w-1/3 mr-6">
          <h1 className="font-semibold text-xl">View Selected Properties</h1>
          <div className="mt-10">
            <SearchInputLandingProperties
              drawer
              loadDetailsDrawer
              onChange={() => {}}
              placeholder="Search Properties"
            />
          </div>
          <div className="font-semibold text-base mt-4">PROPERTIES</div>
          <div className="mt-4">
            <Tree multiple treeData={treeData} />
          </div>
        </section>
        <section className="w-2/3 h-full">
          <div className="font-normal text-base">
            Crop Season: {cropSeasonHandler()}
          </div>
          <div className="flex flex-row mb-4">
            <div className="mr-1">{`${sourcesQnt} crop zone${
              sourcesQnt > 1 ? 's' : ''
            } selected`}</div>
            <img className="mx-2" src={Divider} alt="divider icon" />
            <img className="ml-1" src={FieldIcon} alt="field icon" />

            <div>
              Total area:{' '}
              <FormattedNumber roundedTo={2}>
                {cropzoneTotalArea}
              </FormattedNumber>
              ac
            </div>
          </div>
          <div className="my-auto ">
            <PropertyMapWrapper
              displayOnly
              geoJSONCollection={featureCollection(
                load.sources?.map(cropzoneToGeoJSONFeature)
              )}
              currentCoordinates={featureCollection(
                load.sources?.map(cropzoneToGeoJSONFeature)
              )}
              visible={visible}
            />
          </div>
        </section>
      </div>
    </Drawer>
  );
};

LoadDetailsDrawer.propTypes = {
  cropzoneTotalArea: PropTypes.number.isRequired,
  load: TLoad.isRequired,
  visible: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  cropSeasonHandler: PropTypes.func.isRequired,
  sourcesQnt: PropTypes.number.isRequired
};

export default LoadDetailsDrawer;
