import { useContext, useState } from 'react';
import catchCancel from 'helpers/catchCancel';

import { parseServerError } from 'helpers/errorHelpers';
import { Context } from 'components/Store';
import {
  yieldDashboard as yieldDashboardApi,
  yieldApi,
  yieldList as yieldListApi
} from 'utilities/api';
import { YieldContext } from '../context/YieldProvider';

const useYieldData = () => {
  const [, dispatch] = useContext(Context);
  const { setIsLoading } = useContext(YieldContext);
  const [yieldEntries, setYieldEntries] = useState([]);
  const [yieldEntriesCount, setYieldEntriesCount] = useState(0);
  const [yieldEntry, setYieldEntry] = useState({});
  const [isDeleteLoading, setIsDeleteLoading] = useState(false);
  const [isLoadingYieldEntry, setIsLoadingYieldEntry] = useState(false);
  const [
    isLoadingYieldEntryDashboard,
    setIsLoadingYieldEntryDashboard
  ] = useState(false);
  const [yieldDashboardData, setYieldDashboardData] = useState({});
  const orgId = Context?._currentValue[0]?.organization?.id;
  const commodityIdSelected =
    Context?._currentValue[0]?.selectedYieldCommodity.id;

  const loadDashboard = ({ seasonIds, commodityId }) => {
    setIsLoadingYieldEntryDashboard(true);
    const { promise, cancel } = yieldDashboardApi.post(
      {
        seasonIds
      },
      {
        headers: {
          'cwf-context': JSON.stringify({
            organization_id: orgId
          })
        },
        params: {
          commodityId
        }
      }
    );
    promise
      .then(({ data }) => {
        setYieldDashboardData(data);
      })
      .catch(catchCancel)
      .catch(err => {
        parseServerError(dispatch)(err);
      })
      .finally(() => setIsLoadingYieldEntryDashboard(false));

    return cancel;
  };

  const deleteYield = async yieldEntryId => {
    setIsDeleteLoading(true);
    const { promise } = yieldApi.delete(yieldEntryId, {
      headers: { 'cwf-context': JSON.stringify({ organization_id: orgId }) }
    });
    return promise
      .then(() => {
        return true;
      })
      .catch(() => {
        return false;
      })
      .finally(() => {
        setIsDeleteLoading(false);
      });
  };

  const loadYieldEntries = ({
    seasonIds,
    commodityId,
    fieldToOrder,
    orderingType,
    page
  }) => {
    setIsLoadingYieldEntry(true);
    const { promise, cancel } = yieldListApi.post(
      {
        seasonIds
      },
      {
        headers: {
          'cwf-context': JSON.stringify({
            organization_id: orgId
          })
        },
        params: {
          commodityId,
          fieldToOrder: orderingType ? fieldToOrder : 'entryName',
          orderingType: orderingType ?? 'asc',
          page: page + 1
        }
      }
    );
    promise
      .then(({ data }) => {
        setYieldEntries(data?.data);
        setYieldEntriesCount(data?.elements ?? 0);
      })
      .catch(catchCancel)
      .catch(err => {
        parseServerError(dispatch)(err);
      })
      .finally(() => setIsLoadingYieldEntry(false));

    return cancel;
  };

  // eslint-disable-next-line no-unused-vars
  const loadYieldItem = id => {
    const { promise } = yieldApi.fetch(id, undefined, {
      headers: {
        'cwf-context': JSON.stringify({
          organization_id: orgId
        })
      }
    });

    return promise
      .then(({ data }) => {
        setYieldEntry(data);
      })
      .catch(catchCancel)
      .catch(err => {
        parseServerError(dispatch)(err);
      })
      .finally(() => setIsLoadingYieldEntry(false));
  };

  const saveYield = (data, isEdit) => {
    setIsLoading(true);
    if (isEdit) {
      const { promise } = yieldApi.update(
        data.id,
        {
          ...data,
          commodityId: commodityIdSelected
        },
        {
          headers: {
            'cwf-context': JSON.stringify({
              organization_id: orgId
            })
          }
        }
      );

      return promise
        .then(() => {
          return true;
        })
        .catch(() => {
          return false;
        })
        .finally(() => {
          setIsLoading(false);
        });
    }

    const { promise } = yieldApi.post(
      {
        ...data,
        commodityId: commodityIdSelected
      },
      {
        headers: {
          'cwf-context': JSON.stringify({
            organization_id: orgId
          })
        }
      }
    );
    return promise
      .then(() => {
        return true;
      })
      .catch(() => {
        return false;
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  return {
    yieldEntries,
    yieldEntriesCount,
    isLoadingYieldEntry,
    loadYieldEntries,
    loadYieldItem,
    yieldEntry,
    saveYield,
    deleteYield,
    isDeleteLoading,
    loadDashboard,
    isLoadingYieldEntryDashboard,
    yieldDashboardData
  };
};

export default useYieldData;
