export const SET_MATCHES = 'SET_MATCHES';
export const SET_FETCHED_MATCHES = 'SET_FETCHED_MATCHES';

const reducer = (state, action) => {
  switch (action.type) {
    case SET_MATCHES:
      return {
        ...state,
        matches: action.payload
      };
    case SET_FETCHED_MATCHES:
      return {
        ...state,
        matches: action.payload,
        fetchedMatches: action.payload
      };
    default:
      return state;
  }
};

export default reducer;
