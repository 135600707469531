import React, { useContext } from 'react';
import { Context } from 'components/Store';
import GrowUIFormField from 'components/Forms/GrowUIFormField';
import { TextArea } from '@agconnections/grow-ui';

const LegalDescription = () => {
  const [{ isEnableInfoTabEdit }] = useContext(Context);
  return (
    <GrowUIFormField
      control={TextArea}
      type="text"
      id="legalName"
      name="legalName"
      label="Legal Description"
      disabled={!isEnableInfoTabEdit}
      placeholder="Enter legal description"
    />
  );
};

export default LegalDescription;
