import React from 'react';
import PropTypes from 'prop-types';

import clipboardIcon from 'assets/clipboardIcon.png';

function EmptyLandingOverlay({ subtitle, children }) {
  return (
    <div className="mt-4 p-10 bg-white border rounded-none sm:rounded border-neutral">
      <div className="flex flex-col justify-center">
        <div className="self-center">
          <img width="356" height="318" src={clipboardIcon} alt="icon" />
        </div>
        <div className="self-center mb-3">
          <p>{subtitle}</p>
        </div>
        <div className="self-center">{children}</div>
      </div>
    </div>
  );
}

EmptyLandingOverlay.defaultProps = {
  children: null
};

EmptyLandingOverlay.propTypes = {
  subtitle: PropTypes.string.isRequired,
  children: PropTypes.node
};

export default EmptyLandingOverlay;
