import React from 'react';
import PropTypes from 'prop-types';
import { Columns, Column, Spinner } from '@agconnections/grow-ui';
import { withRouter } from 'react-router-dom';
import EllipseMenu from 'components/EllipseMenu';
import { PLAN_MENU } from 'utilities/menus';
import PriceDisplay from './PriceDisplay';

const TopHeader = ({
  fixedCost,
  variableCost,
  totalProjectedNetRevenue,
  perAcreProjectedNetRevenue,
  onAction,
  displayByFinancialAccess,
  processing
}) => {
  return (
    <>
      <div className="w-full">
        <Columns>
          <Column width="w-full">
            <div className="flex justify-left ml-4">
              <PriceDisplay
                fixedCost={fixedCost || 0}
                variableCost={variableCost || 0}
                totalProjectedNetRevenue={totalProjectedNetRevenue || 0}
                perAcreProjectedNetRevenue={perAcreProjectedNetRevenue || 0}
                displayByFinancialAccess={displayByFinancialAccess}
              />
            </div>
          </Column>
          <Column>
            <div className="flex justify-end mr-6" data-html2canvas-ignore>
              {processing ? (
                <Spinner size="sm" />
              ) : (
                <EllipseMenu onAction={onAction} options={PLAN_MENU} />
              )}
            </div>
          </Column>
        </Columns>
      </div>
    </>
  );
};

TopHeader.defaultProps = {
  variableCost: null,
  processing: false,
  loggedInUserOrgPermission: undefined
};

TopHeader.propTypes = {
  fixedCost: PropTypes.number.isRequired,
  variableCost: PropTypes.number,
  totalProjectedNetRevenue: PropTypes.number.isRequired,
  perAcreProjectedNetRevenue: PropTypes.number.isRequired,
  onAction: PropTypes.func.isRequired,
  displayByFinancialAccess: PropTypes.bool.isRequired,
  loggedInUserOrgPermission: PropTypes.shape({
    role: PropTypes.string,
    financialAccess: PropTypes.string
  }),
  processing: PropTypes.bool
};

export default withRouter(TopHeader);
