import React, { useContext, useState } from 'react';
import PropTypes from 'prop-types';
import { Toast } from '@agconnections/grow-ui';
import PortalModal from 'components/Modals/PortalModal';
import { withRouter, useHistory } from 'react-router-dom';
import { season as seasonApi } from 'utilities/api';
import catchCancel from 'helpers/catchCancel';
import { Context } from 'components/Store';
import { DELETE_SEASON } from 'reducers/reducer';
import { paths } from 'routes/paths';
import { AmplitudeContext } from 'utilities/amplitude/useAmplitude';
import usePlanData from '../../../Plans/hooks/usePlansData';

const DeleteSeasonModal = ({ open, close, seasonName, seasonId }) => {
  const [, dispatch] = useContext(Context);
  const history = useHistory();
  const [toastRenderContents, setToastRenderContents] = useState(null);
  const [toastHasNotBeenClosed, setToastHasNotBeenClosed] = useState(true);
  const [isSaving, setIsSaving] = useState(false);
  const plansData = usePlanData();

  const amplitude = useContext(AmplitudeContext);

  const hasPlan = () =>
    plansData?.plans?.some(plan => plan.cropSeasonIds.includes(seasonId));

  const getUserErrorMessage = message => {
    setToastRenderContents(message);
    setToastHasNotBeenClosed(true);
  };

  const handleDelete = async () => {
    setIsSaving(true);
    if (hasPlan()) {
      setIsSaving(false);
      getUserErrorMessage(
        ' This crop season has a plan attached thus it can not be deleted.'
      );
    } else {
      const { promise } = seasonApi.delete(seasonId);
      await promise
        .then(() => {
          amplitude.sendEventToAmplitude(
            amplitude.events.epic.CropSeason.deleteCropSeasonSuccess
          );
          dispatch({ type: DELETE_SEASON, payload: seasonId });
          close(true);
          amplitude.sendEventToAmplitude(
            amplitude.events.epic.CropSeason.accessCropSeasons
          );
          history.push(paths.cropSeasons);
        })
        .catch(catchCancel)
        .catch(err => {
          if (err.message === 'Request failed with status code 403') {
            getUserErrorMessage(
              ' This crop season has a task attached thus it can not be deleted.'
            );
          } else getUserErrorMessage(err.message);
        })
        .finally(() => setIsSaving(false));
    }
  };
  const handleCancel = () => {
    amplitude.sendEventToAmplitude(
      amplitude.events.epic.CropSeason.cancelCropSeason
    );
    setToastHasNotBeenClosed(false);
    close();
    amplitude.sendEventToAmplitude(
      amplitude.events.epic.CropSeason.cancelCropSeasonSuccess
    );
  };

  return (
    <PortalModal
      open={open}
      title={seasonName && 'Delete this Crop Season?'}
      close={handleCancel}
      cancelLabel="Cancel"
      confirmLabel="Delete"
      onCancel={() => handleCancel()}
      type="error"
      onConfirm={() => handleDelete()}
      isSaving={isSaving}
    >
      <div testId="crop-seasons-deleteModal" className="pr-6">
        Delete the crop season {seasonName}?
      </div>
      {toastHasNotBeenClosed ? (
        <Toast
          icon="error"
          onClose={() => {
            setToastHasNotBeenClosed(false);
          }}
        >
          {toastRenderContents}
        </Toast>
      ) : null}
    </PortalModal>
  );
};

DeleteSeasonModal.defaultProps = {
  seasonId: '',
  seasonName: ''
};

DeleteSeasonModal.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func
  }).isRequired,
  open: PropTypes.oneOfType([PropTypes.func, PropTypes.bool]).isRequired,
  close: PropTypes.oneOfType([PropTypes.func, PropTypes.bool]).isRequired,
  seasonName: PropTypes.string,
  seasonId: PropTypes.string
};

export default withRouter(DeleteSeasonModal);
