import { PropTypes } from 'prop-types';
import React, { memo } from 'react';

const CompanyIcon = ({ size, color }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      viewBox="0 0 20 20"
      fill="none"
      data-testid="company-icon"
      className={`fill-current text-${color}`}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.99984 5.83333H18.3332V17.5H1.6665V2.5H9.99984V5.83333ZM3.33317 15.8333H4.99984V14.1667H3.33317V15.8333ZM4.99984 12.5H3.33317V10.8333H4.99984V12.5ZM3.33317 9.16667H4.99984V7.5H3.33317V9.16667ZM4.99984 5.83333H3.33317V4.16667H4.99984V5.83333ZM6.6665 15.8333H8.33317V14.1667H6.6665V15.8333ZM8.33317 12.5H6.6665V10.8333H8.33317V12.5ZM6.6665 9.16667H8.33317V7.5H6.6665V9.16667ZM8.33317 5.83333H6.6665V4.16667H8.33317V5.83333ZM16.6665 15.8333V7.5H9.99984V9.16667H11.6665V10.8333H9.99984V12.5H11.6665V14.1667H9.99984V15.8333H16.6665ZM14.9998 9.16667H13.3332V10.8333H14.9998V9.16667ZM13.3332 12.5H14.9998V14.1667H13.3332V12.5Z"
      />
    </svg>
  );
};

CompanyIcon.propTypes = {
  color: PropTypes.string,
  size: PropTypes.number
};

CompanyIcon.defaultProps = {
  color: 'neutral-600',
  size: 20
};

export default memo(CompanyIcon);
