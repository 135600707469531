import React from 'react';
import PropTypes from 'prop-types';

import { SERVER_ERROR, CLIENT_ERROR } from 'helpers/errorHelpers';
import { Toast } from '@agconnections/grow-ui';
import errorShape from '../models/errorShape';

const AlertMessage = ({ error, onReset }) => {
  const isMirageError = error.message && error.message.includes('Mirage:');

  let toastRenderContents;
  if (isMirageError || error.type === CLIENT_ERROR) {
    toastRenderContents = error.message;
  } else if (error.type === SERVER_ERROR) {
    toastRenderContents = `${error.status}: ${error.displayMessage}`;
  } else {
    toastRenderContents = null;
  }

  return (
    <>
      {toastRenderContents ? (
        // need to add it so Toast displays over drawer, we should change it in GrowUI
        <div className="z-1010">
          <Toast icon="error" onClose={onReset}>
            {toastRenderContents}
          </Toast>
        </div>
      ) : null}
    </>
  );
};

AlertMessage.defaultProps = {
  error: undefined
};

AlertMessage.propTypes = {
  error: PropTypes.shape(errorShape),
  onReset: PropTypes.func.isRequired
};

export default AlertMessage;
