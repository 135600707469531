export const getCleanOverrideRegistrationNumberForUX = overrideRegistrationNumber => {
  return overrideRegistrationNumber?.endsWith('-0')
    ? overrideRegistrationNumber?.slice(0, -2)
    : overrideRegistrationNumber;
};

const returnApplicationMethodLabel = products => {
  if (!products?.length) {
    return '';
  }

  if (products[0].applicationMethod === 'Fumigation') {
    return 'Fumigation';
  }

  const uniqueApplicationsMethod = [
    ...new Set(products.map(prod => prod.applicationMethod || 'None'))
  ];

  return uniqueApplicationsMethod.join(', ');
};

const specifiedGroups = new Map([
  ['Fumigation', 'F'],
  ['Aerial', 'A'],
  ['Air Blast', 'A'],
  ['Ground', 'G'],
  ['Ground - Banded', 'G'],
  ['Ground - Broadcast', 'G'],
  ['Ground - Hooded', 'G'],
  ['Ground - In Furrow', 'G'],
  ['Ground - Incorporated', 'G'],
  ['Ground - Seed Treatment', 'G'],
  ['Ground - Spot', 'G'],
  ['Ground - Variable Rate', 'G'],
  ['Ground - Wipe', 'G'],
  ['Ground Application', 'G']
]);

export const definedCalAgGroups = applicationMethod =>
  specifiedGroups.get(applicationMethod) ?? 'O';

const filterProducts = (task, desiredGroup) => {
  const products = task.products.filter(
    prod => definedCalAgGroups(prod.applicationMethod) === desiredGroup
  );

  return products?.length > 0
    ? {
        ...task,
        products
      }
    : null;
};

export const groupTasksByProductsApplicationMethod = tasksToBeSplited => {
  return tasksToBeSplited.reduce((acc, currentTask) => {
    if (!currentTask.products || currentTask.products?.length === 1) {
      return acc.concat([currentTask]);
    }

    const fumigationProducts = filterProducts(currentTask, 'F');
    const airProducts = filterProducts(currentTask, 'A');
    const groundProducts = filterProducts(currentTask, 'G');
    const otherProducts = filterProducts(currentTask, 'O');

    return acc.concat(
      [fumigationProducts, airProducts, groundProducts, otherProducts].filter(
        task => task != null
      )
    );
  }, []);
};

export default returnApplicationMethodLabel;
