/* eslint-disable react/jsx-curly-brace-presence */
import React from 'react';
import PropTypes from 'prop-types';
import ClickableDiv from 'components/ClickableDiv';
import { getTotalAreaAcresCropZones } from 'helpers/getTotalAreaAcres';

const MccainFarm = ({
  farmName,
  farmFields,
  isFarmSelected,
  handleSelectedFarm
}) => {
  return (
    <ClickableDiv onClick={handleSelectedFarm}>
      <div
        className={
          isFarmSelected
            ? 'flex flex-col pl-4 mb-10 border-l-8 text-blue-90'
            : 'flex flex-col pl-4 mb-10 border-l-8'
        }
        style={
          isFarmSelected
            ? { borderColor: '#0092E4' }
            : { borderColor: '#EAF6FF' }
        }
        data-testid="mccain-farm-selection"
      >
        <h3 className="mb-1 font-semibold">{farmName}</h3>
        <div className="flex flex-row justify-items-center gap-2">
          <div>
            <svg
              width="15"
              height="15"
              viewBox="0 0 15 15"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M6.00711 5.25586C5.45426 5.25586 5.00608 5.70357 5.00608 6.25586V8.25586C5.00608 8.80814 5.45426 9.25586 6.00711 9.25586H8.00915C8.562 9.25586 9.01017 8.80814 9.01017 8.25586V6.25586C9.01017 5.70357 8.562 5.25586 8.00915 5.25586H6.00711Z"
                fill={isFarmSelected ? '#0092E4' : '#14151C'}
              />
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M11.0122 1.25586C11.0122 0.703575 11.4604 0.255859 12.0132 0.255859C12.5661 0.255859 13.0143 0.703575 13.0143 1.25586C13.5671 1.25586 14.0153 1.70357 14.0153 2.25586C14.0153 2.80814 13.5671 3.25586 13.0143 3.25586V11.2559C13.5671 11.2559 14.0153 11.7036 14.0153 12.2559C14.0153 12.8081 13.5671 13.2559 13.0143 13.2559C13.0143 13.8081 12.5661 14.2559 12.0132 14.2559C11.4604 14.2559 11.0122 13.8081 11.0122 13.2559H3.00404C3.00404 13.8081 2.55587 14.2559 2.00302 14.2559C1.45017 14.2559 1.002 13.8081 1.002 13.2559C0.44915 13.2559 0.000976562 12.8081 0.000976562 12.2559C0.000976562 11.7036 0.449149 11.2559 1.002 11.2559L1.002 3.25586C0.44915 3.25586 0.000976562 2.80814 0.000976562 2.25586C0.000976562 1.70357 0.449149 1.25586 1.002 1.25586C1.002 0.703575 1.45017 0.255859 2.00302 0.255859C2.55587 0.255859 3.00404 0.703575 3.00404 1.25586L11.0122 1.25586ZM11.0122 11.2559H3.00404V3.25586H11.0122V11.2559Z"
                fill={isFarmSelected ? '#0092E4' : '#14151C'}
              />
            </svg>
          </div>
          <span className="font-normal text-xs">{` fields ${
            farmFields?.length
          } • ${getTotalAreaAcresCropZones(farmFields)}`}</span>
        </div>
      </div>
    </ClickableDiv>
  );
};

MccainFarm.propTypes = {
  farmName: PropTypes.string.isRequired,
  farmFields: PropTypes.arrayOf(PropTypes.object),
  isFarmSelected: PropTypes.bool.isRequired,
  handleSelectedFarm: PropTypes.func
};

MccainFarm.defaultProps = {
  handleSelectedFarm: () => {},
  farmFields: [{}]
};

export default MccainFarm;
