import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import ReactMapboxGl, { GeoJSONLayer } from 'react-mapbox-gl';
import { bbox } from '@turf/turf';
import { Spinner } from '@agconnections/grow-ui';
import { calculateCentroid } from 'screens/Property/helpers/mapApiHelpers';
import FieldArea from './components/FieldArea';

const AccessToken = process.env.REACT_APP_MAPBOX_ACCESS_TOKEN;

const MapBox = ReactMapboxGl({
  accessToken: AccessToken,
  trackResize: true
});

export const dataTestId = {
  MAP: 'field-map-preview',
  FIELD: 'field-map-geojson-layer',
  ERROR: 'field-map-preview-unavailable',
  LOADING: 'field-map-preview-loading'
};

const mapCardStyle = 'flex justify-center items-center h-full bg-gray-200';

const FieldMapView = ({
  feature,
  area,
  className,
  fieldColor,
  isLoading,
  containerStyle
}) => {
  const { centroId, boundingBox } = useMemo(() => {
    if (!feature?.geometry || isLoading) {
      return { centroId: [0, 0], boundingBox: null };
    }
    const calculated = calculateCentroid({ features: [feature] });
    const calculatedBoundingBox = bbox(feature);
    return {
      centroId: calculated?.geometry?.coordinates || [0, 0],
      boundingBox: calculatedBoundingBox
    };
  }, [feature, isLoading]);

  if (isLoading) {
    return (
      <>
        <div
          data-testid={dataTestId.LOADING}
          style={containerStyle}
          className={`${className} ${mapCardStyle}`}
        >
          <Spinner />
        </div>
        <FieldArea area={area} />
      </>
    );
  }

  if (!feature?.geometry) {
    return (
      <>
        <div
          data-testid={dataTestId.ERROR}
          style={containerStyle}
          className={`${className} ${mapCardStyle}`}
        >
          <p className="text-center">Field map preview unavailable.</p>
        </div>
        <FieldArea area={area} />
      </>
    );
  }

  return (
    <>
      <MapBox
        // eslint-disable-next-line react/style-prop-object
        style="mapbox://styles/mapbox/satellite-streets-v11"
        center={centroId}
        containerStyle={containerStyle}
        fitBounds={boundingBox}
        fitBoundsOptions={{ padding: 16 }}
        className={className}
        logoPosition="none"
        data-testid={dataTestId.MAP}
      >
        <GeoJSONLayer
          data={feature}
          fillLayout={{
            visibility: 'visible'
          }}
          fillPaint={{
            'fill-color': fieldColor,
            'fill-opacity': 0.4
          }}
          linePaint={{
            'line-color': fieldColor,
            'line-width': 2
          }}
        />
      </MapBox>

      <FieldArea area={area} />
    </>
  );
};

const GeoJSONFeatureType = PropTypes.shape({
  type: PropTypes.string.isRequired,
  geometry: PropTypes.shape({
    type: PropTypes.string.isRequired,
    coordinates: PropTypes.arrayOf(
      PropTypes.arrayOf(PropTypes.arrayOf(PropTypes.number.isRequired))
    ).isRequired
  }).isRequired,
  properties: PropTypes.shape({
    FIELD: PropTypes.string.isRequired
  }).isRequired
});

FieldMapView.defaultProps = {
  area: null,
  className: '',
  containerStyle: {
    height: '150px',
    width: '100%'
  },
  fieldColor: '#31B4F2',
  isLoading: false,
  feature: null
};

FieldMapView.propTypes = {
  feature: GeoJSONFeatureType,
  area: PropTypes.number,
  className: PropTypes.string,
  containerStyle: PropTypes.shape({
    height: PropTypes.string,
    width: PropTypes.string
  }),
  fieldColor: PropTypes.string,
  isLoading: PropTypes.bool
};

export default FieldMapView;
