import React from 'react';
import PropTypes from 'prop-types';
import InvoiceInfoSection from './components/InvoiceInfoSection';
import InvoiceNotesSection from './components/InvoiceNotesSection';

const InvoiceDetailsTab = ({ memberRole, memberFinancialAccess }) => {
  return (
    <>
      <div
        data-testid="invoice-info-title"
        className="flex justify-between mb-10 font-medium text-lg"
      >
        Invoice Info
      </div>
      <div className="grid items-end grid-cols-2 gap-24 mb-12 gap-y-12">
        <InvoiceInfoSection
          memberRole={memberRole}
          memberFinancialAccess={memberFinancialAccess}
        />
      </div>

      <div
        data-testid="invoice-notes-title"
        className="flex justify-between mb-10 font-medium text-lg"
      >
        Notes
      </div>
      <div className="grid items-end grid-cols-1">
        <InvoiceNotesSection />
      </div>
    </>
  );
};

InvoiceDetailsTab.propTypes = {
  memberRole: PropTypes.string.isRequired,
  memberFinancialAccess: PropTypes.string.isRequired
};

export default InvoiceDetailsTab;
