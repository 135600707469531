import React from 'react';
import { Table } from '@agconnections/grow-ui';
import PropTypes from 'prop-types';
import { mapFarmView } from '../../../utilities/changeSummaryTools';
import CropZoneCell from '../CropZoneCell';

const FarmView = ({ massiveChangeSummary }) => {
  const mappedFarmView = mapFarmView(
    massiveChangeSummary.fieldsToMassiveAssign
  );

  return (
    <Table>
      {Object.entries(mappedFarmView).map(farm => (
        <Table.RowGroup arrowIcon key={farm[0]}>
          <Table.Row>
            <Table.Cell>
              <p className="font-semibold font-body">{farm[0]}</p>
            </Table.Cell>
          </Table.Row>
          {farm[1].map(property => {
            return (
              <Table.RowGroup arrowIcon key={property.id}>
                <Table.Row>
                  <Table.Cell>
                    <p className="font-semibold font-body">{property.name}</p>
                  </Table.Cell>
                </Table.Row>
                <Table.Row>
                  <Table.Cell>
                    <CropZoneCell
                      massiveChangeSummary={massiveChangeSummary}
                      property={property}
                      key={property.id}
                    />
                  </Table.Cell>
                </Table.Row>
              </Table.RowGroup>
            );
          })}
        </Table.RowGroup>
      ))}
    </Table>
  );
};

FarmView.defaultProps = {
  massiveChangeSummary: {}
};

FarmView.propTypes = {
  massiveChangeSummary: PropTypes.shape()
};

export default FarmView;
