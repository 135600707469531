import React, { useState } from 'react';
import ChevronDown from 'assets/chevron_down.svg';
import SaleIcon from 'assets/sale.svg';
import StorageIcon from 'assets/storage.svg';
import { useFormik } from 'formik';
import PropTypes from 'prop-types';
import {
  Alert,
  Button,
  Drawer,
  Select,
  Spin
} from 'syngenta-digital-cropwise-react-ui-kit';
import * as Yup from 'yup';
import useLocations from 'screens/YieldV2/AddYield/hooks/useLocations';
import Close from 'assets/close.svg';
import AddStorage from './AddStorage';
import AddSale from './AddSale';
import { positiveNumberNullable } from '../../helpers/validationUtils';
import './index.css';

const validationSchema = Yup.object().shape({
  name: Yup.string().required('Location name is required'),
  email: Yup.string()
    .email('Email must be a valid email')
    .nullable(),
  phone: Yup.string().nullable(),
  company: Yup.string().nullable(),
  capacity: positiveNumberNullable('Capacity')
});

const AddNewLocationDrawer = ({
  open,
  onClose,
  onLocationAdded,
  isAddStorageOnly
}) => {
  const [locationTypeSelectOpen, setLocationTypeSelectOpen] = useState(false);
  const [locationType, setLocationType] = useState('STORAGE');

  const {
    createSaleLocation,
    isCreateSaleLocationLoading,
    createStorageLocation,
    isCreateStorageLocationLoading
  } = useLocations();

  const {
    errors,
    handleChange,
    values,
    setValues,
    submitForm,
    isValid,
    dirty,
    resetForm
  } = useFormik({
    initialValues: {},
    onSubmit: v => {
      if (locationType === 'SALE') {
        const saleLocationBody = {
          name: v.name,
          email: v.email,
          phone: v.phone,
          companyId: v.companyId
        };
        createSaleLocation(saleLocationBody).then(({ id, name }) => {
          if (id && name) {
            onLocationAdded('SALE', id, name);
            resetForm();
            onClose();
          }
        });
      } else {
        const storageLocationBody = {
          name: v.name,
          capacity: v.capacity ? Number(v.capacity) : undefined,
          email: v.email,
          phone: v.phone
        };
        createStorageLocation(storageLocationBody).then(({ id, name }) => {
          if (id && name) {
            onLocationAdded('STORAGE', id, name);
            resetForm();
            onClose();
          }
        });
      }
    },
    validationSchema
  });

  const onCloseClick = () => {
    resetForm();
    onClose();
  };

  const isSaveButtonDisabled = !isValid || !dirty;

  return (
    <Drawer
      maskClosable
      open={open}
      className="cwf-add-location-drawer"
      placement="right"
      closable={false}
      onClose={onClose}
      bodyStyle={{ padding: 0 }}
      footer={
        <div className="flex justify-between">
          <Button type="outline" onClick={onCloseClick}>
            Cancel
          </Button>
          <Button
            disabled={isSaveButtonDisabled}
            className="location-add-save-button"
            type="primary"
            onClick={submitForm}
          >
            Save
          </Button>
        </div>
      }
      width="472px"
    >
      <div className="relative">
        <div className="absolute top-0 right-0 m-3">
          <button type="button" onClick={onCloseClick}>
            <img src={Close} alt="Close icon" width={20} height={20} />
          </button>
        </div>
      </div>
      <div className="px-8 py-6">
        <div
          className="flex gap-4 items-center mb-6"
          data-testid="add-location-drawer-header"
        >
          <h1 className="text-xl font-semibold block mb-0">
            {isAddStorageOnly
              ? 'Quick Add a Storage Location'
              : 'Quick Add a Location'}
          </h1>
          {!isAddStorageOnly && (
            <Select
              className="w-32"
              onChange={setLocationType}
              value={locationType}
              onDropdownVisibleChange={setLocationTypeSelectOpen}
              suffixIcon={
                <img
                  src={ChevronDown}
                  style={{
                    transform: locationTypeSelectOpen ? 'rotate(180deg)' : null
                  }}
                  height={18}
                  width={18}
                  alt="Select dropdown icon"
                />
              }
            >
              <Select.Option value="STORAGE">
                <div className="flex items-center gap-2">
                  <img
                    src={StorageIcon}
                    alt="Storage icon"
                    className="h-15px"
                  />{' '}
                  Storage
                </div>
              </Select.Option>
              <Select.Option value="SALE">
                <div className="flex items-center gap-2">
                  <img src={SaleIcon} alt="Sale icon" /> Sale
                </div>
              </Select.Option>
            </Select>
          )}
        </div>
        {isCreateSaleLocationLoading || isCreateStorageLocationLoading ? (
          <div
            data-testid="add-location-loading"
            className="flex items-center justify-center h-100"
          >
            <Spin />
          </div>
        ) : (
          <>
            <div className="w-300px mb-8">
              {locationType === 'STORAGE' ? (
                <AddStorage
                  handleChange={handleChange}
                  values={values}
                  errors={errors}
                />
              ) : (
                <AddSale
                  handleChange={handleChange}
                  values={values}
                  setValues={setValues}
                  errors={errors}
                />
              )}
            </div>
            <Alert
              className="items-center custom-info"
              description={
                locationType === 'STORAGE'
                  ? 'Add additional details by visiting Storage Locations after saving your loads.'
                  : 'Add additional details by visiting Sale Locations after saving your loads.'
              }
              type="info"
              showIcon
            />
          </>
        )}
      </div>
    </Drawer>
  );
};

AddNewLocationDrawer.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onLocationAdded: PropTypes.func.isRequired,
  isAddStorageOnly: PropTypes.bool
};

AddNewLocationDrawer.defaultProps = {
  isAddStorageOnly: false
};

export default AddNewLocationDrawer;
