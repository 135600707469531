/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import PropTypes from 'prop-types';
import { kebabCase } from 'lodash';
import { useField, useFormikContext } from 'formik';
import {
  Checkbox,
  DatePicker,
  Input,
  NumberInput,
  Search,
  Select,
  TextArea,
  RadioButtonGroup,
  ColorSelect
} from '@agconnections/grow-ui';

const idFromName = name => kebabCase(name);

const GrowUIFormControls = {
  Checkbox,
  DatePicker,
  Input,
  NumberInput,
  Search,
  Select,
  TextArea,
  RadioButtonGroup,
  RadioButton: RadioButtonGroup.RadioButton,
  ColorSelect
};

const FormikFieldWrapper = ({
  componentName,
  GrowUIFormControl,
  name,
  id,
  ...rest
}) => {
  const [
    { value, onBlur, onChange },
    { error, touched },
    { setValue, setTouched }
  ] = useField(name);
  const {
    setFieldValue,
    setFieldTouched,
    submitCount,
    values
  } = useFormikContext();

  // The react-imask onChange handler doesn't pass synthetic event, so this is required
  let inputProps = null;
  if (rest.imask) {
    inputProps = {
      onBlur: event => {
        setTouched(true);
        setValue(event.target.value);
      },
      onChange: () => {}
    };
  }

  const textAreaProps = {
    onBlur: event => {
      setTouched(true);
      setValue(event.target.value);
    }
  };

  // we need a special onSelect for Select, because the standard change handler will not work.
  const selectProps = {
    onBlur: undefined,
    onChange: (event, combinedItems) => {
      if (!event.target.value?.includes(JSON.stringify(values[name]))) {
        setFieldTouched(name, true);
      }
      if (!combinedItems && event) {
        const parsedTarget = event.target.value.length
          ? JSON.parse(event.target.value)
          : {};
        setFieldValue(name, parsedTarget.key);
        if (rest.onSelect) {
          rest.onSelect(parsedTarget);
        }
      } else {
        onChange({
          target: {
            value: combinedItems
          }
        });
        setFieldValue(name, combinedItems);
        if (rest.onSelect) {
          rest.onSelect(combinedItems);
        }
      }
    }
  };

  const searchProps = {
    onBlur: undefined,
    onChange: event => {
      if (!event?.target?.value) {
        setValue(null);
      }
    },
    selectedValue: value,
    onSelect: item => {
      setFieldTouched(name, true);
      setFieldValue(name, item.key);
    }
  };

  // this is kinda bug in GrowUI the name of the target is moved outside of target in the simulated event
  const datePickerProps = {
    dates: Array.isArray(value) ? value : [value],
    onChange: ({ target, ...event }) => {
      onChange({
        target: {
          value: !rest.isRangePicker ? target.value[0] : target.value,
          name: event.name
        }
      });
    }
  };

  const radioButtonGroupProps = {
    onChange: val => {
      setValue(val);
    }
  };

  const props = {
    id: id || idFromName(name),
    name,
    value,
    ...((touched || submitCount) && error ? { error: true } : { error: false }),
    ...((touched || submitCount) && { errorMessage: error }),
    onBlur,
    onChange,
    ...(componentName === 'Input' ? inputProps : {}),
    ...(componentName === 'NumberInput' ? inputProps : {}),
    ...(componentName === 'Select' || componentName === 'ColorSelect'
      ? selectProps
      : {}),
    ...(componentName === 'Search' ? searchProps : {}),
    ...(componentName === 'DatePicker' ? datePickerProps : {}),
    ...(componentName === 'RadioButtonGroup' ? radioButtonGroupProps : {}),
    ...(componentName === 'TextArea' ? textAreaProps : {}),
    ...rest
  };
  return <GrowUIFormControl {...props} />;
};

FormikFieldWrapper.defaultProps = {
  id: null
};
FormikFieldWrapper.propTypes = {
  componentName: PropTypes.string.isRequired,
  GrowUIFormControl: PropTypes.elementType.isRequired,
  name: PropTypes.string.isRequired,
  id: PropTypes.string
};

const GrowUIFormField = ({ control, name, id, ...rest }) => {
  const [componentName] = Object.entries(GrowUIFormControls).find(
    ([, Comp]) => Comp === control
  ) || [null, null];

  if (!componentName) {
    throw new Error(
      'GrowUIFormField requires a GrowUI Form Control. [Checkbox, DatePicker, Input, Search, Select, TextArea, RadioButton or RadioButtonGroup ]'
    );
  }

  return (
    <FormikFieldWrapper
      GrowUIFormControl={control}
      componentName={componentName}
      name={name}
      id={id}
      {...rest}
    />
  );
};

GrowUIFormField.defaultProps = { id: null };

GrowUIFormField.propTypes = {
  control: PropTypes.elementType.isRequired,
  name: PropTypes.string.isRequired,
  id: PropTypes.string
};

export default GrowUIFormField;
