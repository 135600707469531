import React, { useContext } from 'react';
import { PropTypes } from 'prop-types';
import { INTEGRATIONS } from 'screens/Integrations/helpers/constants';
import SubmissionCropSeason from 'screens/Integrations/components/SubmissionCropSeason';

import SubmissionSummary from 'screens/Integrations/SubmissionSummary';
import { CalAgContext } from '../../context/Provider';
import CalAgPropertiesSelection from '../CalAgPropertiesSelection';
import CalAgSubmissionFooter from '../CalAgSubmissionFooter';
import CalAgFieldDetails from '../CalAgFieldDetails';
import CalAgProductsSelection from '../CalAgProductsSelection';
import CalAgReviewAndSubmit from '../CalAgReviewAndSubmit';

const STEPS = {
  1: (
    <SubmissionCropSeason
      context={INTEGRATIONS.calAgPermits}
      FooterComponent={CalAgSubmissionFooter}
    />
  ),
  2: <CalAgPropertiesSelection />,
  3: <CalAgProductsSelection />,
  4: <CalAgFieldDetails />,
  5: (
    <SubmissionSummary
      FooterComponent={CalAgSubmissionFooter}
      ReviewComponent={CalAgReviewAndSubmit}
      needFields
      needSolveMatch={false}
    />
  )
};

const StepSelector = ({ fixedStep }) => {
  const {
    state: { step }
  } = useContext(CalAgContext);

  return STEPS[fixedStep ?? step];
};

StepSelector.propTypes = {
  fixedStep: PropTypes.number
};

export default StepSelector;
