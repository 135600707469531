import React from 'react';
import PropTypes from 'prop-types';
import { Popup } from '@agconnections/grow-ui';

const ProductNameAndManufacture = ({ product }) => {
  const defaultHtml = (
    <div
      className="flex flex-col justify-start text-left w-40"
      data-testid="product-name-hover"
    >
      <span className="mb-1 overflow-hidden truncate">
        {product.productName}
      </span>
      <span className="text-xs text-gray-600">{product.manufacturerName}</span>
    </div>
  );

  if (product.productName.length > 24) {
    return (
      <Popup
        enableHover
        position="top"
        align="center"
        hasArrow
        bgColor="#232630"
        trigger={defaultHtml}
      >
        <div
          className="gr-w-64 text-xs text-white"
          data-testid="product-name-hover-content"
        >
          {product.productName}
          <br />
          {product.manufacturerName}
        </div>
      </Popup>
    );
  }

  return defaultHtml;
};

ProductNameAndManufacture.propTypes = {
  product: PropTypes.shape({
    productName: PropTypes.string.isRequired,
    manufacturerName: PropTypes.string.isRequired
  }).isRequired
};

export default ProductNameAndManufacture;
