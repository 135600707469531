import React, { useContext } from 'react';
import { INTEGRATIONS } from 'screens/Integrations/helpers/constants';
import { AgrianContext } from 'screens/Integrations/AgrianIntegration/context/Provider';
import useSubmissionAnalytics from 'screens/Integrations/hooks/useSubmissionAnalytics';
import PropertiesMatchProvider from 'screens/Integrations/PropertiesMatch/context/PropertiesMatchProvider';

import ProgressBar from 'components/ProgressBar';
import Breadcrumb from 'components/App/AppShell/components/Breadcrumb';

import StepSelector from '../StepSelector';
import { AGRIAN_RECOMMENDATION_STEPS } from '../..';
import ProductsMatchProvider from '../AgrianMatchProducts/context/ProductsMatchProvider';

const progressBarSteps = AGRIAN_RECOMMENDATION_STEPS.map(step => ({
  caption: step.header
}));

const BaseAgrianRecommendationIntegration = () => {
  const { events, triggerAnalyticsEvent } = useSubmissionAnalytics();
  const {
    state: { step, canGoNext },
    gotoStep
  } = useContext(AgrianContext);

  const onCompletePropertiesMatching = () => {
    triggerAnalyticsEvent(
      events.epic.Integrations.importRecommendationPropertiesMatch,
      {
        vendor: INTEGRATIONS.agrianRecommendation
      }
    );
  };

  const onCompleteProductsMatching = () => {
    triggerAnalyticsEvent(
      events.epic.Integrations.ingestionRecommendationProductsMatch,
      {
        vendor: INTEGRATIONS.agrianRecommendation
      }
    );
  };

  return (
    <div data-testid="base-integration">
      <Breadcrumb hideCropSeasonDropdown disabled>
        <Breadcrumb.Item
          title="Integrations"
          value={INTEGRATIONS.agrianRecommendation}
          isLast
        />
      </Breadcrumb>
      <div className="z-50 min-h-50 h-full left-0 right-0 bottom-0 top-18 flex flex-col items-center mb-48">
        <ProgressBar
          stepArray={progressBarSteps}
          currentStep={step}
          canGoNext={canGoNext}
          gotoStep={gotoStep}
        />
        <PropertiesMatchProvider
          integrationType={INTEGRATIONS.agrianRecommendation}
          onCompleteMatch={onCompletePropertiesMatching}
        >
          <ProductsMatchProvider
            integrationType={INTEGRATIONS.agrianRecommendation}
            onCompleteMatch={onCompleteProductsMatching}
          >
            <StepSelector fixedStep={step} />
          </ProductsMatchProvider>
        </PropertiesMatchProvider>
      </div>
    </div>
  );
};

export default BaseAgrianRecommendationIntegration;
