import React from 'react';
import PropTypes from 'prop-types';
import ExclamationTriangle from 'components/Icons/ExclamationTriangle';
import ErrorIcon from 'components/Icons/ErrorIcon';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckCircle } from '@fortawesome/free-regular-svg-icons';

const IconByStatus = ({ submissionResult }) => {
  switch (submissionResult?.httpStatusCode) {
    case 207:
      return <ExclamationTriangle size={50} color="#d69e2e" />;
    case 422:
      return <ErrorIcon size={50} color="#CF3537" />;
    default:
      return (
        <FontAwesomeIcon
          icon={faCheckCircle}
          size="4x"
          className="text-green-600 mb-5"
          data-testid="check-circle"
        />
      );
  }
};

IconByStatus.propTypes = {
  submissionResult: PropTypes.shape({
    httpStatusCode: PropTypes.number
  })
};

IconByStatus.defaultProps = {
  submissionResult: undefined
};

export default IconByStatus;
