import { useCallback, useContext, useState } from 'react';
import { calAg as calAgApi } from 'utilities/api';
import catchCancel from 'helpers/catchCancel';
import { Context } from 'components/Store';
import { parseServerError } from 'helpers/errorHelpers';

const useCalAgApi = () => {
  const [loading, setLoading] = useState(false);
  const [{ organization }, dispatch] = useContext(Context);

  const getProductsMatches = useCallback(
    ({ submissionId }) => {
      const { promise } = calAgApi
        .createChildApi({
          action: `integrations/calag/submissions/${submissionId}/products`
        })
        .fetch(null, undefined, {
          headers: {
            'cwf-context': JSON.stringify({
              organization_id: organization.id
            })
          }
        });

      setLoading(true);

      return promise
        .then(res => res)
        .catch(catchCancel)
        .catch(parseServerError(dispatch))
        .finally(() => setLoading(false));
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [organization]
  );

  /**
   * @param {array}
   * @example [{"cwfProductId":"b23a0d86-6e1e-4a73-a5b4-1ecd7d347924", "calAgRegistrationNumber":"100-1305-AA"}]
   */
  const updateProductsMatches = useCallback(
    matches => {
      const { promise } = calAgApi
        .createChildApi({
          action: `integrations/calag/match/products`
        })
        .create(matches, {
          headers: {
            'cwf-context': JSON.stringify({
              organization_id: organization.id
            })
          }
        });

      setLoading(true);

      return promise
        .then(res => res)
        .catch(catchCancel)
        .catch(parseServerError(dispatch))
        .finally(() => setLoading(false));
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [organization]
  );

  return {
    getProductsMatches,
    updateProductsMatches,
    loading
  };
};

export default useCalAgApi;
