import React, { useEffect, useState } from 'react';
import infoIcon from 'assets/info.svg';
import { useFormikContext } from 'formik';
import useParameters from 'screens/YieldV2/LoadDetails/components/LoadDetailsInfo/components/LoadDetailsInfoTabs/hook/useParameters';
import formatPricePerUnit from 'helpers/formatPricePerUnit';
import { getAbbreviatedUnit } from 'helpers/unitsAbbreviation';
import { formatCurrency } from 'helpers/formatNumber';
import { labelInputs, tabsLabels } from '../../helpers/constants';
import RenderValue from '../RenderValue';
import PropertiesGrid from '../PropertiesGrid';

const SaleInfoTab = () => {
  const { values } = useFormikContext();
  const { salePrice, loadUnit, grossRevenue, templateType } = values;
  const { removeAttributes } = useParameters(
    tabsLabels.saleInfo,
    { source: true },
    templateType
  );

  const [properties, setProperties] = useState([]);

  useEffect(() => {
    setProperties([
      {
        name: 'salePrice',
        value: formatPricePerUnit(salePrice, getAbbreviatedUnit(loadUnit))
      },
      {
        name: 'grossRevenue',
        value: formatCurrency(grossRevenue)
      }
    ]);
  }, [salePrice, loadUnit, grossRevenue]);
  return (
    <div>
      <PropertiesGrid
        values={values}
        properties={properties}
        removeAttributes={removeAttributes}
      />
      <div className="mt-10">
        <RenderValue
          isRender={!removeAttributes?.includes(labelInputs.infoSection)}
        >
          <p className="flex flex-row">
            <img alt="info" src={infoIcon} className="mr-2" />
            <span className="flex flex-row items-center">
              All yield delivered to a Sale Location will count towards total
              gross revenue.
            </span>
          </p>
        </RenderValue>
      </div>
    </div>
  );
};

export default SaleInfoTab;
