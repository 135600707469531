/* eslint-disable react/prop-types */
import React from 'react';
import PlanInfoSection from './components/PlanInfoSection';
import FixedCostSection from './components/FixedCostSection';
import PlanNotesSection from './components/PlanNotesSection';
import ProjectedRevenueSection from './components/ProjectedRevenueSection';

const PlanDetailsTab = ({ memberRole, memberFinancialAccess }) => {
  return (
    <>
      <div data-testid="more-info-title" className="font-medium text-lg mt-10">
        Plan Info
      </div>
      <PlanInfoSection />
      <div className="border-b" />

      <div data-testid="more-info-title" className="font-medium text-lg mt-10">
        Fixed Cost
      </div>
      <FixedCostSection
        memberRole={memberRole}
        memberFinancialAccess={memberFinancialAccess}
      />
      <div className="border-b" />
      <div data-testid="more-info-title" className="font-medium text-lg mt-10">
        Projected Revenue
      </div>
      <ProjectedRevenueSection
        memberRole={memberRole}
        memberFinancialAccess={memberFinancialAccess}
      />

      <div data-testid="more-info-title" className="font-medium text-lg mt-10">
        Notes
      </div>
      <PlanNotesSection />
    </>
  );
};

export default PlanDetailsTab;
