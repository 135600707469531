import React from 'react';
import PropTypes from 'prop-types';
import { generate } from 'shortid';

const TileCardField = ({ id, title, value, testId, hideValue }) => {
  return (
    <div
      data-testid={`${testId}-card-detail`}
      className="w-1/2 my-2 text-neutral-300"
    >
      <div className="font-bold text-xxs">{title}</div>
      <div id={id} className="truncate">
        {hideValue ? '' : value}
      </div>
    </div>
  );
};

TileCardField.defaultProps = {
  testId: generate(),
  hideValue: false
};

TileCardField.propTypes = {
  id: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  value: PropTypes.node.isRequired,
  testId: PropTypes.string,
  hideValue: PropTypes.bool
};

export default TileCardField;
