import React, { useContext, useEffect } from 'react';
import PropTypes from 'prop-types';
import PortalModal from 'components/Modals/PortalModal';
import { withRouter } from 'react-router-dom';
import { AmplitudeContext } from 'utilities/amplitude/useAmplitude';
import { PropertyTypes } from 'helpers/propertyHelpers';
import { useToast } from 'components/ToastContainer';
import useFarmData from '../../hooks/useFarmData';
import useFieldData from '../../hooks/useFieldData';

const UnarchivePropertyModal = ({
  open,
  close,
  propertyId,
  propertyName,
  propertyType,
  reloadData
}) => {
  const amplitude = useContext(AmplitudeContext);
  const toast = useToast();

  // Only send propertyId to relevant hook to avoid 404 on other hook
  const idMap = { [propertyType]: propertyId };
  const farmData = useFarmData(idMap[PropertyTypes.FARM]);
  const fieldData = useFieldData(idMap[PropertyTypes.FIELD]);
  const propertyData =
    propertyType === PropertyTypes.FARM ? farmData : fieldData;
  const unarchiveProperty =
    propertyType === PropertyTypes.FARM
      ? propertyData.unarchiveFarm
      : propertyData.unarchiveField;

  const handleUnarchive = async () => {
    const event =
      propertyType === PropertyTypes.FARM
        ? amplitude.events.epic.Properties.unarchiveFarm
        : amplitude.events.epic.Properties.unarchiveField;
    amplitude.sendEventToAmplitude(event);
    unarchiveProperty(propertyId);
  };

  useEffect(() => {
    if (propertyData.archive.isLoading) return;
    if (propertyData.archive.isSuccess) {
      toast.success(`${propertyName} unarchived`, {
        content: `You have unarchived your ${propertyType} and all associated ${
          propertyType === PropertyTypes.FARM ? 'fields and ' : ''
        }crop zones`
      });
      reloadData();
      close();
    }
    if (propertyData.archive.isError) {
      toast.error(`${propertyName} was not unarchived`, {
        content: `An error prevented your ${propertyType} from being unarchived. Please try again.`,
        supportButton: true
      });
      close();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [propertyData.archive]);

  return (
    <PortalModal
      open={open}
      title={`Unarchive ${propertyName}?`}
      close={close}
      type="warningConfirmation"
      cancelLabel="Cancel"
      confirmLabel={`Unarchive ${propertyType}`}
      onCancel={close}
      onConfirm={handleUnarchive}
      isSaving={propertyData.archive.isLoading}
    >
      <div data-testid="unarchive-field-modal" className="pr-6">
        This {propertyType} will be unarchived along with all associated{' '}
        {propertyType === PropertyTypes.FARM ? 'fields and' : ''} crop zones.
        Would you like to continue?
      </div>
    </PortalModal>
  );
};

UnarchivePropertyModal.defaultProps = {
  reloadData: () => {}
};

UnarchivePropertyModal.propTypes = {
  open: PropTypes.bool.isRequired,
  close: PropTypes.func.isRequired,
  propertyId: PropTypes.string.isRequired,
  propertyName: PropTypes.string.isRequired,
  propertyType: PropTypes.string.isRequired,
  reloadData: PropTypes.func
};

export default withRouter(UnarchivePropertyModal);
