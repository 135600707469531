import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import FormattedNumber from 'components/FormattedNumber';
import {
  TagsCloudDisplay,
  extraTagsCalculation,
  TagsPopUpMessage
} from 'components/TagsButton';
import { STORAGE_KEYS } from 'utilities/localStorage';
import ListWithPopup from 'components/ListWithPopup';
import ChangeSummaryCropAction from '../ChangeSummaryCropAction';

const CropZoneCell = ({ massiveChangeSummary, property, cropName }) => {
  const [displayTagsObject, setDisplayTagsObject] = useState({
    extraTagsCount: 0,
    extraTagsNames: [],
    tagsToDisplay: []
  });
  const [cropZoneTags] = useState(massiveChangeSummary?.flags || []);
  useEffect(() => {
    setDisplayTagsObject(extraTagsCalculation(cropZoneTags, 18));
  }, [cropZoneTags]);

  return massiveChangeSummary.action === 'Added' ? (
    <div>
      <ChangeSummaryCropAction
        action={massiveChangeSummary?.action}
        crop={massiveChangeSummary?.crop}
        count={displayTagsObject?.tagsToDisplay?.length}
      />
      <p className="text-neutral-600 text-xs pl-6 text-left mt-2">
        <FormattedNumber roundedTo={2}>
          {property?.boundaryArea}
        </FormattedNumber>
        {` ac • ${massiveChangeSummary?.crop?.name}`}
      </p>
    </div>
  ) : (
    <div className="flex flex-col w-full divide-y divide-gray-400">
      {property.cropzones?.map(
        (cropzone, i) =>
          (!cropName || cropName === cropzone.crop.name) && (
            <div className={`w-full pb-2 ${i > 0 && 'pt-3'}`}>
              <ChangeSummaryCropAction
                action={massiveChangeSummary.action}
                crop={cropzone}
                count={displayTagsObject?.tagsToDisplay?.length}
              />
              {massiveChangeSummary.action === 'Changed' && (
                <p className="text-xs pl-6 text-left mt-2">
                  From {cropzone.crop.name} to {massiveChangeSummary.crop.name}
                </p>
              )}

              <p className="text-neutral-600 text-xs pl-6 text-left mt-2">
                <FormattedNumber roundedTo={2}>
                  {cropzone.boundaryArea}
                </FormattedNumber>
                {` ac • ${
                  massiveChangeSummary.action === 'Changed'
                    ? massiveChangeSummary.crop.name
                    : cropzone.crop.name
                }`}
              </p>
              {(massiveChangeSummary.action === 'TagsAdded' ||
                massiveChangeSummary.action === 'TagsDeleted') && (
                <div className="pl-6 mt-2 mr-1">
                  {displayTagsObject.extraTagsNames.length > 0 ? (
                    <ListWithPopup
                      position="bottom"
                      ariaLabel="Task Tags"
                      top="1.5rem"
                      msg={
                        <TagsPopUpMessage
                          extraTagsNames={displayTagsObject.extraTagsNames}
                        />
                      }
                      component={
                        <TagsCloudDisplay
                          displayTagsObject={displayTagsObject}
                          isLandingPage="view"
                          viewKey={STORAGE_KEYS.TASKS_VIEW}
                        />
                      }
                    />
                  ) : (
                    <TagsCloudDisplay
                      displayTagsObject={displayTagsObject}
                      isLandingPage="view"
                      viewKey={STORAGE_KEYS.TASKS_VIEW}
                    />
                  )}
                </div>
              )}
            </div>
          )
      )}
    </div>
  );
};

CropZoneCell.defaultProps = {
  massiveChangeSummary: {},
  property: {},
  cropName: ''
};

CropZoneCell.propTypes = {
  massiveChangeSummary: PropTypes.shape(),
  property: PropTypes.shape(),
  cropName: PropTypes.string
};

export default CropZoneCell;
