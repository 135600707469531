import React, { useState } from 'react';
import PropTypes from 'prop-types';

import {
  Button,
  ColorPallet,
  ColorPicker,
  Input
} from '@agconnections/grow-ui';

const ColorPickerDisplay = ({
  colorPickerValue,
  setColorPickerValue,
  handleColorPickerToggle
}) => {
  const rgbDisplayTextStyle = colorPickerValue
    ? {
        color: `rgba(${colorPickerValue.r},
        ${colorPickerValue.g},
        ${colorPickerValue.b},
        ${colorPickerValue.a})`
      }
    : {};

  return (
    <div className="flex flex-col p-2">
      <div className="text-sm text-gray-600">Color Picker</div>
      {colorPickerValue ? (
        <div className="flex text-lg" style={rgbDisplayTextStyle}>
          <div>{`${colorPickerValue.r}, `}</div>
          <div>{`${colorPickerValue.g}, `}</div>
          <div>{`${colorPickerValue.b}, `}</div>
          <div>{`${colorPickerValue.a}`}</div>
        </div>
      ) : null}

      <div className="mt-4">
        <ColorPicker
          color={colorPickerValue}
          onChange={event => setColorPickerValue(event.rgb)}
        />
      </div>

      <div className="mt-4">
        <Input id="label-name-input" label="Label Name" maxLength="20" />
      </div>

      <div id="cancel-color-picker-button" className="flex justify-center my-3">
        <Button type="outline" onClick={handleColorPickerToggle}>
          Cancel
        </Button>
        <div id="save-from-color-picker-button" className="ml-2">
          <Button type="primary">Save</Button>
        </div>
      </div>
    </div>
  );
};

ColorPickerDisplay.propTypes = {
  colorPickerValue: PropTypes.string.isRequired,
  setColorPickerValue: PropTypes.func.isRequired,
  handleColorPickerToggle: PropTypes.func.isRequired
};

const ColorDisplay = ({
  handleColorPickerChange,
  colorDisplayValue,
  colorDisplayColors,
  handleCustomColorChange,
  customColorValue,
  customColors,
  handleColorSelectorToggle,
  handleColorPickerToggle
}) => {
  return (
    <>
      <div className="text-sm text-gray-600">Color Picker</div>
      <ColorPallet
        onChange={handleColorPickerChange}
        name="color-picker-pallet"
        value={colorDisplayValue}
        colors={colorDisplayColors}
      />

      <div className="text-sm text-gray-600 mt-6">Custom Colors</div>
      <ColorPallet
        onChange={handleCustomColorChange}
        name="custom-color-pallet"
        value={customColorValue}
        colors={customColors}
      />
      <button
        id="more-colors-button"
        type="button"
        className="text-gray-600 text-xs flex self-center"
        onClick={handleColorPickerToggle}
      >
        {`More Colors >`}
      </button>

      <div className="mt-6">
        <Input
          id="color-display-label-name-input"
          label="Label Name"
          maxLength="20"
        />
      </div>

      <div className="flex justify-center my-3">
        <Button
          id="color-display-cancel-button"
          type="outline"
          onClick={handleColorSelectorToggle}
        >
          Cancel
        </Button>
        <div id="color-display-save-button" className="ml-2">
          <Button type="primary">Save</Button>
        </div>
      </div>
    </>
  );
};

ColorDisplay.propTypes = {
  handleColorPickerChange: PropTypes.func.isRequired,
  colorDisplayValue: PropTypes.string.isRequired,
  colorDisplayColors: PropTypes.arrayOf(PropTypes.string).isRequired,
  handleCustomColorChange: PropTypes.func.isRequired,
  customColorValue: PropTypes.string.isRequired,
  customColors: PropTypes.arrayOf(PropTypes.string).isRequired,
  handleColorSelectorToggle: PropTypes.func.isRequired,
  handleColorPickerToggle: PropTypes.func.isRequired
};

const ColorSelector = ({ handleColorSelectorToggle }) => {
  const colorDisplayColors = [
    'rgb(209 82 105)',
    'rgb(50 27 183)',
    'rgb(50 27 183 / 0.7)'
  ];
  const [colorDisplayValue, setColorDisplayValue] = useState();
  const handleColorPickerChange = event => {
    setColorDisplayValue(event.target.value);
  };

  const [colorPickerVisible, setColorPickerVisible] = useState(false);
  const handleColorPickerToggle = () => {
    setColorPickerVisible(previousValue => {
      return !previousValue;
    });
  };

  const customColors = [
    'rgb(209 82 105)',
    'rgb(50 27 183)',
    'rgb(50 27 183 / 0.7)'
  ];
  const [customColorValue, setCustomColorValue] = useState();
  const [colorPickerValue, setColorPickerValue] = useState();
  const handleCustomColorChange = event => {
    setCustomColorValue(event.target.value);
  };

  return (
    <div className="flex flex-col p-2">
      {colorPickerVisible ? (
        <ColorPickerDisplay
          colorPickerValue={colorPickerValue}
          setColorPickerValue={setColorPickerValue}
          handleColorPickerToggle={handleColorPickerToggle}
        />
      ) : (
        <ColorDisplay
          handleColorPickerChange={handleColorPickerChange}
          colorPickerValue={colorDisplayValue}
          colorDisplayColors={colorDisplayColors}
          handleCustomColorChange={handleCustomColorChange}
          customColorValue={customColorValue}
          customColors={customColors}
          handleColorSelectorToggle={handleColorSelectorToggle}
          handleColorPickerToggle={handleColorPickerToggle}
        />
      )}
    </div>
  );
};

ColorSelector.propTypes = {
  handleColorSelectorToggle: PropTypes.func.isRequired
};

export default ColorSelector;
