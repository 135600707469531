/* eslint-disable react/no-array-index-key */
/* eslint-disable react/destructuring-assignment */
import React, { useContext } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { Modal } from '@agconnections/grow-ui';
import { Context } from 'components/Store';
import createIcon from 'assets/new-area.svg';
import folderIcon from 'assets/drive_folder_upload.svg';
// import cluIcon from 'assets/field_detection.svg';
import PropTypes from 'prop-types';
import ModalTileButton from './ModalTileButton';

const CreatePropertyModal = ({
  open,
  closeModal,
  drawFieldsClick,
  uploadFilesClick
  // selectFieldsClick
}) => {
  const [{ selectedProperty }] = useContext(Context);

  const closeModalClick = () => {
    closeModal(false);
  };

  const drawFieldsButtonClick = () => {
    drawFieldsClick(selectedProperty);
  };

  const uploadFilesButtonClick = () => {
    uploadFilesClick();
    closeModal(false);
  };

  // const selectFieldsButtonClick = () => {
  //   selectFieldsClick();
  // };

  const buttonRows = [
    [
      {
        title: 'Draw your fields',
        subTitle: "Draw your farm's fields with the drawing tools",
        imageSource: createIcon,
        buttonClickEvent: drawFieldsButtonClick,
        onKeyDown: drawFieldsButtonClick,
        classname: 'w-92 h-56 mr-4',
        testId: 'create-property-modal-draw-button'
      },
      {
        title: 'Upload field files',
        subTitle: 'Add multiple fields at a time by uploading shapefiles',
        imageSource: folderIcon,
        buttonClickEvent: uploadFilesButtonClick,
        onKeyDown: uploadFilesButtonClick,
        classname: 'w-92 h-56',
        testId: 'create-property-modal-upload-button'
      }
    ]
    // [
    //   {
    //     title: 'CLU',
    //     subTitle: 'Select fields detected on the map',
    //     imageSource: cluIcon,
    //     buttonClickEvent: selectFieldsButtonClick,
    //     onKeyDown: selectFieldsButtonClick,
    //     classname: 'w-92 h-56 mr-4',
    //     testId: 'create-property-modal-select-button'
    //   },
    //   {
    //     title: '',
    //     subTitle: '',
    //     imageSource: '',
    //     buttonClickEvent: '',
    //     onKeyDown: '',
    //     classname: '',
    //     testId: ''
    //   }
    // ]
  ];

  const CreateButtonRow = buttonArray => {
    return (
      <>
        {buttonArray?.buttonArray?.map((button, index) => {
          const addButton = button.title !== '';
          return (
            <>
              {addButton && (
                <button
                  className={button.classname}
                  type="button"
                  data-testid={button.testId}
                  key={index}
                >
                  <ModalTileButton
                    title={button.title}
                    subtitle={button.subTitle}
                    imageSource={button.imageSource}
                    buttonClickEvent={button.buttonClickEvent}
                    onKeyDown={button.onKeyDown}
                  />
                </button>
              )}
              {!addButton && <div className="w-1/2" />}
            </>
          );
        })}
      </>
    );
  };

  const CreateButtonRows = rows => {
    return rows.rows.map(row => {
      return (
        <div className="flex mr-4 ml-4 mb-6">
          <CreateButtonRow buttonArray={row} />
        </div>
      );
    });
  };

  return (
    <>
      <Modal
        open={open}
        data-testid="create-property-modal"
        // size="md"
        fixedSize="75px"
        hideClose
      >
        <div className="ml-4 pt-4 mb-4 pb-4 pr-4">
          <div className="flex mr-4 ml-4 mt-4">
            <div
              className="w-1/2 text-left text-2xl mb-12 font-semibold"
              data-testid="create-property-modal-title"
            >
              <div className="leading-7">Create New Fields</div>
            </div>
            <div className="w-1/2 text-right">
              <button type="button" onClick={closeModalClick}>
                <FontAwesomeIcon
                  icon={faTimes}
                  size="2x"
                  stle={{ visibility: 'visible' }}
                />
              </button>
            </div>
          </div>
          <CreateButtonRows rows={buttonRows} />
        </div>
      </Modal>
    </>
  );
};

CreatePropertyModal.defaultProps = {
  open: true
};

CreatePropertyModal.propTypes = {
  open: PropTypes.bool,
  closeModal: PropTypes.func.isRequired,
  drawFieldsClick: PropTypes.func.isRequired,
  uploadFilesClick: PropTypes.func.isRequired
  // selectFieldsClick: PropTypes.func.isRequired
};

export default CreatePropertyModal;
