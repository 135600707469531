import React, { useContext } from 'react';
import { useHistory } from 'react-router-dom';
import { Button, ButtonType } from 'syngenta-digital-cropwise-react-ui-kit';
import { paths } from 'routes/paths';
import StorageLocationIcon from 'assets/storage.svg';
import { ReactComponent as EditOutlined } from 'assets/edit_outlined.svg';
import { StorageLocationContext } from 'screens/YieldV2/StorageLocations/context/StorageLocationProvider';
import DeleteStorage from '../../../DeleteStorage';

const StorageLocationDetailHeader = () => {
  const history = useHistory();
  const {
    state: { selectedId, data = [], sortBy, sortDir }
  } = useContext(StorageLocationContext);
  const selected = data.find(item => item.id === selectedId);

  return (
    <div className="flex flex-row items-center bg-white border border-t-0 h-16 justify-between py-6 pl-6 pr-4 sticky top-0 z-1">
      <div className="flex flex-row">
        <img className="mr-2" src={StorageLocationIcon} alt="Icon" />
        <span className="font-semibold text-2xl">{selected?.name}</span>
      </div>

      <div className="flex space-x-4 flex-row items-center justify-between">
        <Button
          type={ButtonType.outline}
          icon={<EditOutlined width={18} />}
          onClick={() =>
            history.push(
              `${paths.yieldV2}/storage-locations/edit/${selected?.id}`,
              {
                selectedId,
                sortBy,
                sortDir
              }
            )
          }
        >
          Edit
        </Button>
        <DeleteStorage />
      </div>
    </div>
  );
};

export default StorageLocationDetailHeader;
