/* eslint-disable react/prop-types */
import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useFormikContext } from 'formik';
import _ from 'lodash';
import State from 'screens/Property/PropertiesLanding/PropertyTable/UniqueFields/State';
import County from 'screens/Property/PropertiesLanding/PropertyTable/UniqueFields/County';
import FarmNumber from 'screens/Property/PropertiesLanding/PropertyTable/UniqueFields/FarmNumber';
import PermitId from 'screens/Property/PropertiesLanding/PropertyTable/UniqueFields/PermitId';
import Notes from 'screens/Property/PropertiesLanding/PropertyTable/UniqueFields/Notes';
import Accordion from '../components/Accordion';
import Editor from '../components/Editor';

const EditFarm = ({ farm, setEdited }) => {
  const { values, setValues } = useFormikContext();

  useEffect(() => {
    if (farm) {
      setValues(farm);
    }
  }, [farm, setValues]);
  useEffect(() => {
    if (_.isEqual(farm, values)) {
      setEdited(false);
    } else {
      setEdited(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [farm, values]);

  if (!farm) {
    return <></>;
  }

  return (
    <>
      <Accordion header="Location">
        <Editor label="State">
          <State />
        </Editor>
        <Editor label="County">
          <County />
        </Editor>
      </Accordion>
      <Accordion header="FSA">
        <Editor>
          <FarmNumber />
        </Editor>
      </Accordion>
      <Accordion header="Regulatory Permit">
        <Editor>
          <PermitId />
        </Editor>
      </Accordion>
      <Accordion header="Notes">
        <Editor>
          <Notes />
        </Editor>
      </Accordion>
    </>
  );
};

EditFarm.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  farm: PropTypes.object.isRequired,
  setEdited: PropTypes.func.isRequired
};

export default EditFarm;
