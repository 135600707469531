const extractCropZoneIds = properties => {
  if (!properties) return [];
  const cropZoneIds = properties
    .flatMap(farm => farm.fields ?? [])
    .flatMap(field => field.cropzones ?? [])
    .map(cropZone => cropZone.id);
  return cropZoneIds;
};

export default extractCropZoneIds;
