import React from 'react';
import PropTypes from 'prop-types';
import {
  cssContainerGrid,
  cssMarginContainer,
  labelInputs
} from 'screens/YieldV2/LoadDetails/components/LoadDetailsInfo/components/LoadDetailsInfoTabs/helpers/constants';
import { generate } from 'shortid';
import RenderValuePropertyRow from '../RenderValuePropertyRow';

export default function PropertiesGrid({
  properties,
  values,
  removeAttributes
}) {
  const dataTestId = 'property-grid-item';
  return (
    <div className={cssMarginContainer}>
      <div className={cssContainerGrid}>
        {properties.map(property => {
          const key = generate();
          if (typeof property === 'string') {
            return (
              <RenderValuePropertyRow
                data-testid={dataTestId}
                key={key}
                label={labelInputs[property]}
                removeAttributes={removeAttributes}
                value={values[property]}
                ghostValue={!values[property]}
              />
            );
          }
          return (
            <RenderValuePropertyRow
              data-testid={dataTestId}
              key={key}
              label={labelInputs[property.name]}
              removeAttributes={removeAttributes}
              value={property.value ?? values[property.name]}
              ghostValue={property.ghostValue ?? !values[property.name]}
            />
          );
        })}
      </div>
    </div>
  );
}

PropertiesGrid.propTypes = {
  properties: PropTypes.arrayOf(
    PropTypes.oneOfType([
      PropTypes.string.isRequired,
      PropTypes.shape({
        name: PropTypes.string.isRequired,
        value: PropTypes.node,
        ghostValue: PropTypes.bool
      })
    ])
  ).isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  values: PropTypes.object.isRequired,
  removeAttributes: PropTypes.arrayOf(PropTypes.string).isRequired
};
