import PropTypes from 'prop-types';
import React from 'react';
import { Icon } from '@agconnections/grow-ui';

import InfoIcon from 'components/Icons/InfoIcon';
import ErrorIcon from 'components/Icons/ErrorIcon';
import ExclamationTriangle from 'components/Icons/ExclamationTriangle';
import { SubmissionStatus } from 'screens/Integrations/helpers/constants';
import StatusItem from './components/StatusItem';

export const StatusMessage = ({ submissionResult }) => {
  const { status, errorMessage, response } = submissionResult || {};

  switch (status) {
    case SubmissionStatus.DONE:
      return (
        <StatusItem
          title="Submission Successful"
          message="This data has already been submitted, you can view submitted information at this screen.."
          color="bg-green-200"
          icon={
            <div className="mr-3 w-fit h-fit p-1 rounded-full bg-green-600">
              <Icon size={12} icon="checkmark" color="#FFFFFF" />
            </div>
          }
        />
      );
    case SubmissionStatus.PENDING:
      return (
        <StatusItem
          title="Submission Pending"
          message="This data were not submitted, you can review included information."
          color="bg-teal-100"
          icon={
            <div className="mr-3">
              <InfoIcon size={24} color="#319795" />
            </div>
          }
        />
      );
    case SubmissionStatus.IN_PROGRESS:
      return (
        <StatusItem
          title="Submission in Progress"
          message="We are currently processing your submission. Come back later to view final submission status."
          color="bg-blue-10"
          icon={
            <div className="mr-3">
              <InfoIcon size={24} color="#0078CD" />
            </div>
          }
        />
      );
    case SubmissionStatus.ERROR:
      if (!response) {
        return (
          <StatusItem
            title="Error"
            message={errorMessage}
            color="bg-red-100"
            icon={
              <div className="mr-3">
                <ErrorIcon size={24} color="#CF3537" />
              </div>
            }
          />
        );
      }
      return (
        <StatusItem
          title="Product Errors in Submission"
          message="This data has already been submitted, however we've identified some errors in the products you submitted. Review submitted information and try again."
          color="bg-yellow-100"
          icon={
            <div className="mr-3">
              <ExclamationTriangle size={20} color="#d69e2e" />
            </div>
          }
        />
      );
    default:
      return null;
  }
};

StatusMessage.propTypes = {
  submissionResult: PropTypes.shape({
    status: PropTypes.string,
    errorMessage: PropTypes.string,
    response: PropTypes.objectOf(PropTypes.object)
  })
};

StatusMessage.defaultProps = {
  submissionResult: {}
};

export default StatusMessage;
