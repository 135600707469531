import React from 'react';
import { Input, Columns, Column, Select, Form } from '@agconnections/grow-ui';

const ProfileFields = () => {
  return (
    <>
      <Columns wrap>
        <Column width="w-1/2 pr-5">
          <Form.Field label="Email">
            <Input name="email" value="" />
          </Form.Field>
        </Column>
        <Column width="w-1/2 pl-5">
          <Form.Field label="Phone Number">
            <Input name="phoneNumber" value="" />
          </Form.Field>
        </Column>
        <Column width="w-1/2 pr-5">
          <Form.Field label="Address Line 1">
            <Input name="addressLine1" value="" />
          </Form.Field>
        </Column>
        <Column width="w-1/2 pl-5">
          <Form.Field label="Address Line 2">
            <Input name="addressLine2" value="" />
          </Form.Field>
        </Column>
        <Column width="w-1/2 pr-5">
          <Form.Field label="City">
            <Input name="city" value="" />
          </Form.Field>
        </Column>
        <Column width="w-1/2 pl-5">
          <Form.Field label="State">
            <Input name="state" value="" />
          </Form.Field>
        </Column>
        <Column width="w-1/2 pr-5">
          <Form.Field label="Zip / Postcode">
            <Input name="postcode" value="" />
          </Form.Field>
        </Column>
        <Column width="w-1/2 pl-5">
          <Form.Field label="Country">
            <Select name="country" />
          </Form.Field>
        </Column>
      </Columns>
    </>
  );
};

export default ProfileFields;
