/* eslint-disable import/prefer-default-export */
import { AgrianContext } from '../AgrianIntegration/context/Provider';
import { AgVendContext } from '../AgVend/context/Provider';
import { CalAgContext } from '../CalAgIntegration/context/Provider';
import { SimplotContext } from '../SimplotIntegration/context/Provider';
import { INTEGRATIONS } from './constants';

export const determineIntegrationContext = integrationType => {
  switch (integrationType) {
    case INTEGRATIONS.simplot:
      return SimplotContext;
    case INTEGRATIONS.agrian:
    case INTEGRATIONS.agrianRecommendation:
      return AgrianContext;
    case INTEGRATIONS.calAgPermits:
      return CalAgContext;
    case INTEGRATIONS.agVend:
      return AgVendContext;
    default:
      return null;
  }
};
