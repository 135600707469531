import React from 'react';

const OrganizationImage = () => {
  return (
    <svg
      width="43"
      height="40"
      viewBox="0 0 43 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M42.0206 20C42.0206 30.7434 32.799 39.5 21.3636 39.5C9.92814 39.5 0.706543 30.7434 0.706543 20C0.706543 9.25657 9.92814 0.5 21.3636 0.5C32.799 0.5 42.0206 9.25657 42.0206 20Z"
        fill="#F5F8FC"
        stroke="#E8EAED"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M21.3456 7.72476L33.3552 17.2147C33.8556 17.6142 33.8556 18.2136 33.5554 18.6132C33.2551 19.1126 32.5546 19.1126 32.1542 18.813L20.7451 9.82253L9.33595 18.813C8.93563 19.1126 8.23507 19.0128 7.93483 18.6132C7.63459 18.2136 7.73467 17.5143 8.13499 17.2147L20.1446 7.72476C20.4448 7.42508 20.9453 7.42508 21.3456 7.72476ZM28.7515 19.5122C28.7515 18.9129 29.1518 18.5133 29.7523 18.5133C30.3528 18.5133 30.7531 18.9129 30.7531 19.5122V30.0011C30.7531 30.6004 30.3528 31 29.7523 31H11.7379C11.1374 31 10.7371 30.6004 10.7371 30.0011V19.5122C10.7371 18.9129 11.1374 18.5133 11.7379 18.5133C12.3383 18.5133 12.7387 18.9129 12.7387 19.5122V29.0021H19.7443V24.0074C17.1422 23.9076 14.8403 22.8087 14.7403 20.0117V18.4134V17.5144H15.9412C16.054 17.5144 16.1677 17.5139 16.282 17.5135C17.8917 17.5073 19.6237 17.5006 20.7451 18.7131C21.946 17.3146 23.8476 17.4145 25.5489 17.5144H26.7499V18.4134V20.0117C26.6498 22.7088 24.348 23.9076 21.7459 24.0074V29.0021H28.7515V19.5122ZM16.7419 19.5122C16.7419 21.61 17.9428 22.0096 19.7443 22.0096V20.5112C19.2814 19.4947 18.1335 19.504 17.251 19.5111C17.1795 19.5117 17.1097 19.5122 17.0421 19.5122H16.7419ZM24.7483 19.5122H24.448C24.3718 19.5122 24.2944 19.5116 24.2163 19.5111C23.2684 19.504 22.2082 19.496 21.7459 20.5112V22.0096C23.5473 22.0096 24.7483 21.61 24.7483 19.5122Z"
        fill="#8CC3A3"
      />
    </svg>
  );
};

export default OrganizationImage;
