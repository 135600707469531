import React from 'react';
import PropTypes from 'prop-types';
import { Table } from '@agconnections/grow-ui';
import EllipseMenu from 'components/EllipseMenu';
import { COMPANIES_LANDING_MENU } from 'utilities/menus';

const CompanyTableRow = ({ company, onAction }) => {
  const companyAdress = company?.address;
  const handleAction = action => onAction(action, company);
  return (
    <Table.Row>
      <Table.Cell onClick={() => handleAction('view')}>
        <span className="font-bold">
          {company?.name ? company.name : company.legalName}
        </span>
      </Table.Cell>
      <Table.Cell>{companyAdress?.city}</Table.Cell>
      <Table.Cell>{companyAdress?.state}</Table.Cell>
      <Table.Cell>
        <div
          data-testid="company-table-options-menu"
          className="absolute right-0 flex-none w-10 mr-2"
        >
          <EllipseMenu
            onAction={handleAction}
            options={COMPANIES_LANDING_MENU}
          />
        </div>
      </Table.Cell>
    </Table.Row>
  );
};

CompanyTableRow.defaultProps = {
  companyAdress: {}
};

CompanyTableRow.propTypes = {
  onAction: PropTypes.func.isRequired,
  company: PropTypes.shape({
    name: PropTypes.string,
    address: PropTypes.shape(),
    email: PropTypes.string,
    legalName: PropTypes.string
  }).isRequired,
  companyAdress: PropTypes.shape({})
};

export default CompanyTableRow;
