import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Toast } from '@agconnections/grow-ui';
import PortalModal from 'components/Modals/PortalModal';
import { withRouter } from 'react-router-dom';
import catchCancel from 'helpers/catchCancel';
import { farm as farmApi } from 'utilities/api';

const DeleteFarmModal = ({
  open,
  close,
  farmName,
  farmId,
  setReRenderProperties
}) => {
  const [toastRenderContents, setToastRenderContents] = useState(null);
  const [toastHasNotBeenClosed, setToastHasNotBeenClosed] = useState(true);
  const getUserErrorMessage = message => {
    setToastRenderContents(message);
    setToastHasNotBeenClosed(true);
  };
  const [isSaving, setIsSaving] = useState(false);
  const handleDelete = async () => {
    setIsSaving(true);
    const { promise } = farmApi.delete(farmId);
    await promise
      .then(() => {
        setReRenderProperties(true);
      })
      .catch(catchCancel)
      .catch(err => {
        setIsSaving(false);
        getUserErrorMessage(err.response.data.message);
      });
  };

  return (
    <>
      <PortalModal
        data-testid="delete-farm-modal"
        open={open}
        title={farmName && 'Delete this Farm?'}
        close={close}
        cancelLabel="Cancel"
        confirmLabel="Delete"
        onCancel={() => close()}
        type="error"
        onConfirm={() => handleDelete()}
        isSaving={isSaving}
      >
        {toastHasNotBeenClosed ? (
          <Toast
            icon="error"
            onClose={() => {
              setToastHasNotBeenClosed(false);
            }}
          >
            {toastRenderContents}
          </Toast>
        ) : null}
        <div className="pr-6">
          Are you sure you want to delete the Farm {farmName}?
        </div>
        <div className="pr-6">
          Alert: To delete a farm you must ensure all attached fields and
          cropzones are deleted first.
        </div>
      </PortalModal>
    </>
  );
};

DeleteFarmModal.propTypes = {
  open: PropTypes.func.isRequired,
  close: PropTypes.func.isRequired,
  farmName: PropTypes.string.isRequired,
  farmId: PropTypes.string.isRequired,
  setReRenderProperties: PropTypes.func.isRequired
};

export default withRouter(DeleteFarmModal);
