import React, { useContext } from 'react';
import EmptyTableState from 'components/EmptyTableState';
import NoProductsImage from 'assets/noNotesFound.png';
import NoSearchResults from 'assets/no_search_results_found.svg';
import { Button } from '@agconnections/grow-ui';
import { AddRounded } from '@material-ui/icons';
import PropTypes from 'prop-types';
import FilterOutlinedIcon from 'assets/filter_outlined_white.svg';
import { AmplitudeContext } from 'utilities/amplitude/useAmplitude';

export default function LoadsEmptyState({
  addYieldLoads,
  areFiltersApplied,
  onAdjustFilters,
  onClearFilters,
  isSearchApplied
}) {
  const amplitude = useContext(AmplitudeContext);
  if (isSearchApplied) {
    return (
      <EmptyTableState
        overlayIcon={NoSearchResults}
        title="No Loads Found"
        message="Change the selected season or try a different search."
      />
    );
  }

  if (areFiltersApplied) {
    return (
      <EmptyTableState
        overlayIcon={NoSearchResults}
        title="No Loads Found"
        message="Clear or adjust filter selections to see load information."
        button={
          <div className="flex items-center gap-2">
            <Button
              icon={<img src={FilterOutlinedIcon} alt="Filter button" />}
              type="primary"
              onClick={onAdjustFilters}
            >
              Adjust filters
            </Button>
            <Button type="outline" onClick={onClearFilters}>
              Clear Selections
            </Button>
          </div>
        }
      />
    );
  }

  return (
    <EmptyTableState
      overlayIcon={NoProductsImage}
      title="No Loads Found"
      message="Add a new Yield Load or change the selected season."
      button={
        <Button
          icon={<AddRounded />}
          type="primary"
          onClick={() => {
            amplitude.sendEventToAmplitude(
              amplitude.events.epic.YieldV2.addYield.createYield
            );
            addYieldLoads();
          }}
        >
          Yield Loads
        </Button>
      }
    />
  );
}

LoadsEmptyState.defaultProps = {
  addYieldLoads: () => {},
  onAdjustFilters: () => {},
  onClearFilters: () => {},
  areFiltersApplied: false,
  isSearchApplied: false
};

LoadsEmptyState.propTypes = {
  addYieldLoads: PropTypes.func,
  onAdjustFilters: PropTypes.func,
  onClearFilters: PropTypes.func,
  areFiltersApplied: PropTypes.bool,
  isSearchApplied: PropTypes.bool
};
