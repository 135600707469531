/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useContext, useState } from 'react';
import PropTypes from 'prop-types';
import { extraTagsCalculation } from 'components/TagsButton';
import { Spinner, Columns, Column, TextArea } from '@agconnections/grow-ui';
import { Context } from 'components/Store';
import useTagsData from 'hooks/useTagsData';
import { hasAccess } from 'utilities/access';
import PlanDetailsSection from 'screens/Plans/components/PlanView/components/PlanDetailsSection';
import FixedCostSection from 'screens/Plans/components/PlanView/components/FixedCostSection';
import ProjectedRevenueSection from 'screens/Plans/components/PlanView/components/ProjectedRevenueSection';
import EditProductModal from 'screens/Plans/components/EditProductModal';
import usePlanData from 'screens/Plans/Plan/hooks/usePlanData';
import PropertiesTable from './components/PropertiesTable';
import ProductsTable from './components/ProductsTable';

const PlanView = ({ id }) => {
  const [{ loggedInUserOrgPermission }] = useContext(Context);

  const { plan, loading } = usePlanData(id);
  const [selProduct, setSelProduct] = useState();
  const [currentOpenModal, setCurrentOpenModal] = useState(null);

  // eslint-disable-next-line no-unused-vars
  const [returnedTags, setReturnedTags] = useState([]);
  const { tags } = useTagsData();
  const [updatedTags, setUpdatedTags] = useState(returnedTags || []);
  const [displayTagsObject, setDisplayTagsObject] = useState({
    extraTagsCount: 0,
    extraTagsNames: [],
    tagsToDisplay: []
  });

  useEffect(() => {
    if (tags?.length > 0 && plan?.flagIds?.length > 0) {
      const planTags = tags?.filter(tag =>
        plan?.flagIds?.find(flagId => tag.id === flagId)
      );
      setUpdatedTags(planTags);
    } else setUpdatedTags(returnedTags || []);
  }, [tags, plan.flagIds]);

  useEffect(() => {
    setUpdatedTags(returnedTags || []);
  }, [returnedTags]);

  useEffect(() => {
    setDisplayTagsObject(extraTagsCalculation(updatedTags, 30));
  }, [updatedTags]);

  const closeModal = () => {
    setCurrentOpenModal(null);
  };

  const handleProductModal = product => {
    setSelProduct(product);
    setCurrentOpenModal('product');
  };

  const getDisplayByFinancialAccess = () => {
    return hasAccess(loggedInUserOrgPermission, {
      minAllowedRole: 'View Only',
      minFinancialAccess: 'READ'
    });
  };

  return (
    <>
      {loading ? (
        <Spinner />
      ) : (
        <div>
          <EditProductModal
            open={currentOpenModal === 'product'}
            product={selProduct}
            handleClose={closeModal}
            displayFinancial={getDisplayByFinancialAccess()}
          />
          <div
            className="border rounded overscroll-y-contain p-4"
            data-testid="source-document-plan-view"
          >
            <div className="flex py-5">
              <div className="w-full">
                <Columns>
                  <Column width="w-full">
                    <div
                      data-testid="more-info-title"
                      className="text-2xl font-bold"
                    >
                      Plan Details
                    </div>
                  </Column>
                </Columns>
                <PlanDetailsSection
                  plan={plan}
                  displayTagsObject={displayTagsObject}
                />
                <div className="w-full border-b py-5" />
              </div>
            </div>
            <div className="py-5">
              <div className="w-full">
                <Columns>
                  <Column width="w-full">
                    <div
                      data-testid="more-info-title"
                      className="text-2xl font-bold"
                    >
                      Fixed Cost
                    </div>
                  </Column>
                </Columns>
                <FixedCostSection
                  plan={plan}
                  displayFinancials={getDisplayByFinancialAccess()}
                />
                <div className="w-full border-b py-5" />
              </div>
            </div>
            <div className="py-5">
              <div className="w-full">
                <Columns>
                  <Column width="w-full">
                    <div
                      data-testid="more-info-title"
                      className="text-2xl font-bold"
                    >
                      Projected Revenue
                    </div>
                  </Column>
                </Columns>
                <ProjectedRevenueSection
                  plan={plan}
                  displayFinancials={getDisplayByFinancialAccess()}
                />
                <div className="w-full border-b py-5" />
              </div>
            </div>
            <div className="py-5">
              <div className="w-full">
                <Columns>
                  <Column width="w-full">
                    <div
                      data-testid="more-info-title"
                      className="text-2xl font-bold"
                    >
                      Properties
                    </div>
                    <br />
                  </Column>
                </Columns>
                <PropertiesTable
                  cropZones={plan.cropZones}
                  coveragePercent={plan?.planProductEntries[0]?.coveragePercent}
                />
                <div className="w-full border-b py-5" />
              </div>
            </div>
            <div className="py-5">
              <div className="w-full">
                <Columns>
                  <Column width="w-full">
                    <div
                      data-testid="more-info-title"
                      className="text-2xl font-bold"
                    >
                      Products & Services
                    </div>
                    <div data-testid="more-info-title" className="text-sm">
                      By Rate Application
                    </div>
                    <br />
                  </Column>
                </Columns>
                <ProductsTable
                  products={plan.planProductEntries}
                  handleProductModal={handleProductModal}
                  displayFinancials={getDisplayByFinancialAccess()}
                />
                <div className="w-full border-b py-5" />
              </div>
            </div>
            <div className="py-5">
              <div className="w-full">
                <Columns>
                  <Column width="w-full">
                    <div
                      data-testid="more-info-title"
                      className="text-2xl font-bold"
                    >
                      Notes
                    </div>
                    <br />
                  </Column>
                </Columns>
                <TextArea
                  data-testid="plan-notes"
                  className="h-64 overscroll-auto"
                  resizeDisabled
                  disabled
                  value={plan.notes}
                />
                <div className="w-full border-b py-5" />
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

PlanView.propTypes = {
  id: PropTypes.string.isRequired
};

export default PlanView;
