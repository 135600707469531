import React, { useContext, useState } from 'react';
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';
import { Formik } from 'formik';
import { Column, Columns, Input, SimpleModal } from '@agconnections/grow-ui';
import { Context } from 'components/Store';
import { product as productApi } from 'utilities/api';
import { parseServerError } from 'helpers/errorHelpers';
import catchCancel from 'helpers/catchCancel';
import GrowUIFormField from 'components/Forms/GrowUIFormField';
import FertilizerServiceSelector from './components/FertilizerServiceSelector';
import FertilizerProductForm from './components/FertilizerProductForm';
import ServiceProductForm from './components/ServiceProductForm';
import { fertilizerSchema, serviceSchema } from './schema';

const modalRoot = document.getElementById('cwf-modal-root');

const CustomProductModalContent = ({
  open,
  onClose,
  handleSave,
  setModalTab
}) => {
  const [organization] = useContext(Context);
  const [validationSchema, setValidationSchema] = useState(fertilizerSchema);

  const initialValues = {
    id: '',
    name: '',
    manufacturer: '',
    standardUnit: '',
    type: setModalTab,
    makeUp: '',
    density: 0,
    densityInLbsPerGallon: 0,
    stdFactor: 1,
    stdPackageUnit: '',
    formulation: {
      S: 0,
      B: 0,
      Cl: 0,
      N: 0,
      P: 0,
      K: 0,
      Ca: 0,
      Mg: 0,
      Cu: 0,
      Fe: 0,
      Mn: 0,
      Mo: 0,
      Zn: 0
    },
    organizationId: organization.organization.id
  };

  // may be needed for later use
  /* eslint no-alert: "off", curly: "error" */
  // eslint-disable-next-line no-unused-vars
  const validateMajorMinorMicro = values => {
    if (
      (typeof values.N === 'undefined' || values.N === '') &&
      (typeof values.P === 'undefined' || values.P === '') &&
      (typeof values.K === 'undefined' || values.K === '') &&
      (typeof values.Ca === 'undefined' || values.Ca === '') &&
      (typeof values.Mg === 'undefined' || values.Mg === '') &&
      (typeof values.S === 'undefined' || values.S === '') &&
      (typeof values.Fe === 'undefined' || values.Fe === '') &&
      (typeof values.Cu === 'undefined' || values.Cu === '') &&
      (typeof values.Mn === 'undefined' || values.Mn === '') &&
      (typeof values.Zn === 'undefined' || values.Zn === '') &&
      (typeof values.B === 'undefined' || values.B === '') &&
      (typeof values.Mo === 'undefined' || values.Mo === '') &&
      (typeof values.Cl === 'undefined' || values.Cl === '')
    ) {
      alert(
        'Please ensure at least one item in Major, Minor, or Micro has a value greater than 0 before saving.'
      );
      return false;
    }
    return true;
  };

  const setSchema = selection => {
    if (selection === 'Fertilizer') {
      setValidationSchema(fertilizerSchema);
    } else {
      setValidationSchema(serviceSchema);
    }
  };

  const saveProduct = async (prodValues, resetForm) => {
    const prod = {
      ...prodValues,
      stdPackageUnit: prodValues.standardUnit,
      type: prodValues.type.toUpperCase(),
      makeUp:
        prodValues.type === 'Fertilizer'
          ? prodValues.makeUp.toUpperCase()
          : 'SOLID'
    };
    const { promise } = await productApi.create(prod);
    await promise
      .then(response => {
        handleSave(response.data);
        resetForm();
      })
      .catch(catchCancel)
      .catch(err => {
        parseServerError(err);
      });
  };

  return (
    <Formik
      enableReinitialize
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={(values, { resetForm }) => saveProduct(values, resetForm)}
    >
      {({ values, handleSubmit }) => {
        return open ? (
          <>
            <SimpleModal
              open={open}
              close={onClose}
              onConfirm={handleSubmit}
              confirmLabel="Save"
            >
              <fieldset data-testid="custom-product-form">
                <div className="flex items-center justify-between">
                  <div className="font-bold text-lg">
                    Create a Custom Product
                  </div>
                  <div className="w-1/2 text-sm">
                    <span>
                      Need a custom crop protection or seed product?
                      <a
                        className="pl-2 text-blue-300"
                        href="https://www.syngentadigital.com/us/product-request-form"
                        target="_blank"
                        rel="noreferrer"
                      >
                        Request here.
                      </a>
                    </span>
                  </div>
                </div>
                <FertilizerServiceSelector setValidationSchema={setSchema} />
                <div className="mt-12">
                  <Columns wrap>
                    <Column width="w-1/2">
                      <div className="pr-2">
                        <GrowUIFormField
                          control={Input}
                          id="name"
                          name="name"
                          label="Product Name*"
                        />
                      </div>
                    </Column>
                    <Column width="w-1/2">
                      <div className="pl-2">
                        <GrowUIFormField
                          control={Input}
                          id="manufacturer"
                          name="manufacturer"
                          label="Manufacturer*"
                          placeholder="Unspecified"
                        />
                      </div>
                    </Column>
                  </Columns>
                </div>
                {values?.type !== 'Service' ? (
                  <FertilizerProductForm
                    setValidationSchema={setValidationSchema}
                  />
                ) : (
                  <ServiceProductForm />
                )}
              </fieldset>
            </SimpleModal>
          </>
        ) : null;
      }}
    </Formik>
  );
};

const CustomProductModal = props => {
  if (process.env.JEST_WORKER_ID) {
    // eslint-disable-next-line react/jsx-props-no-spreading
    return <CustomProductModalContent {...props} />;
  }
  return ReactDOM.createPortal(
    // eslint-disable-next-line react/jsx-props-no-spreading
    <CustomProductModalContent {...props} />,
    modalRoot
  );
};

CustomProductModalContent.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  handleSave: PropTypes.func.isRequired,
  setModalTab: PropTypes.string
};

CustomProductModalContent.defaultProps = {
  setModalTab: 'Fertilizer'
};

export default CustomProductModal;
