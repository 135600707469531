import React from 'react';
import PropTypes from 'prop-types';
import { format } from 'date-fns';

import GrowUIFormField from 'components/Forms/GrowUIFormField';

import { useFormikContext } from 'formik';
import { Input, Select } from '@agconnections/grow-ui';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import alphanumSort from 'helpers/sorters';
import ordinal from '../ordinalHelper';
import ApplicatorRowElement from './ApplicatorRowElement';

const Applicators = ({
  people,
  peopleLoading,
  companies,
  companiesLoaded,
  handleRemove,
  memberRole,
  memberFinancialAccess,
  showBufferInfo,
  setFieldTouchedParent
}) => {
  const { values } = useFormikContext();

  const mappedPeopleNames = alphanumSort(
    people
      ? people.map(person => {
          const formattedName = person.licenseNumber
            ? `${person.name} (${person.licenseNumber}, Exp. ${
                person.expirationDate
                  ? format(Date.parse(person.expirationDate), 'MM/dd/yyyy')
                  : ''
              })`
            : person.name;
          return {
            key: person.id,
            value: formattedName
          };
        })
      : [],
    'value'
  );

  const mappedPeopleCompanies = alphanumSort(
    companies
      ? companies.map(company => {
          return { key: company.id, value: company.name };
        })
      : [],
    'value'
  );

  const getDisabled = () => {
    if (
      memberRole === 'Full control' &&
      (memberFinancialAccess === 'none' || memberFinancialAccess === 'READ')
    )
      return true;

    return false;
  };

  const applicatorRow = index => (
    <ApplicatorRowElement
      key={index}
      index={index}
      companiesLoaded={companiesLoaded}
      ordinal={ordinal}
      getDisabled={getDisabled}
      mappedPeopleCompanies={mappedPeopleCompanies}
      mappedPeopleNames={mappedPeopleNames}
      peopleLoading={peopleLoading}
      onButtonClick={() => {
        setFieldTouchedParent('Remove Applicator');
        handleRemove(index);
      }}
    />
  );

  return (
    <>
      {values.applicators && values.applicators.length
        ? values.applicators.map((applicator, index) => applicatorRow(index))
        : applicatorRow(0)}

      {showBufferInfo && (
        <>
          <div className="flex text-xs font-semibold tracking-tight text-blue-700 uppercase">
            <InfoOutlinedIcon style={{ fontSize: 30 }} className="mr-2" />
            Buffer Zone Data serves to instruct applicators and does not affect
            the total area or product totals of the final task
          </div>
          <div />
          <GrowUIFormField
            label="Buffer Zone (FT)"
            control={Input}
            name="bufferZone"
          />
          <GrowUIFormField
            label="Buffer Reason"
            control={Select}
            name="bufferReason"
            items={[
              { key: 'Spray Drift', value: 'Spray Drift' },
              { key: 'Pollen Drift', value: 'Pollen Drift' },
              { key: 'Interim Aquatic', value: 'Interim Aquatic' },
              { key: 'Bee Protection', value: 'Bee Protection ' }
            ]}
          />
        </>
      )}
    </>
  );
};

Applicators.defaultProps = {
  people: [],
  companies: [],
  showBufferInfo: true,
  setFieldTouchedParent: () => {}
};
Applicators.propTypes = {
  people: PropTypes.arrayOf(PropTypes.object),
  peopleLoading: PropTypes.bool.isRequired,
  companies: PropTypes.arrayOf(PropTypes.object),
  companiesLoaded: PropTypes.bool.isRequired,
  handleRemove: PropTypes.func.isRequired,
  memberRole: PropTypes.string.isRequired,
  memberFinancialAccess: PropTypes.string.isRequired,
  showBufferInfo: PropTypes.bool,
  setFieldTouchedParent: PropTypes.func
};

export default Applicators;
