import { feature } from '@turf/turf';
import { cropZone as cropZoneApi } from 'utilities/api';
import { getFeaturesCentroidCoordinates } from './mapApiHelpers';

export default async function sanitizeCropZone(
  selectedCropSeasons,
  cropSeasons,
  data
) {
  if (data?.coordinates) return;
  const geometryToFeature = feature(data.geometry);
  const coordinates = getFeaturesCentroidCoordinates([
    geometryToFeature
  ]).toString();
  // we need to reassigning the coordinates to data in order to not break the Crop Zone Information details.
  // eslint-disable-next-line no-param-reassign
  data.coordinates = coordinates;
  const newData = {
    ...data,
    coordinates
  };

  let cropSeasonIdForCropZone;
  if (selectedCropSeasons?.length === 1) {
    cropSeasonIdForCropZone = selectedCropSeasons.at(0);
  } else {
    cropSeasonIdForCropZone = cropSeasons.find(cropSeason =>
      cropSeason?.cropZoneIds?.includes(newData.id)
    )?.id;
  }
  if (cropSeasonIdForCropZone) {
    await cropZoneApi.update(newData.id, {
      ...newData,
      cropSeasonId: cropSeasonIdForCropZone
    });
  }
}
