import React from 'react';

import PropTypes from 'prop-types';

import SearchBar from 'components/SearchBar';
import SearchInput from 'components/SearchBar/SearchInput';

const PeopleSearchBar = ({ onChange, children }) => {
  return (
    <div className="flex mb-4">
      <SearchBar>
        <SearchInput
          name="search_people"
          placeholder="Search People"
          onChange={onChange}
        />
      </SearchBar>
      {children}
    </div>
  );
};

PeopleSearchBar.defaultProps = {
  children: null
};

PeopleSearchBar.propTypes = {
  onChange: PropTypes.func.isRequired,
  children: PropTypes.node
};

export default PeopleSearchBar;
