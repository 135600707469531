import multiLevelSort from 'helpers/multiLevelSort';

const handleDirection = (
  tableField,
  dir,
  toggleButtonEnabled,
  setCropVariety,
  setCropHistory,
  setSortField,
  setSortDir,
  cropHistoryTableSort
) => {
  const newSortBy = [tableField];
  const newSortDir = [dir];
  const sortParams = {
    sortBy: newSortBy,
    sortDir: newSortDir,
    sorter: cropHistoryTableSort
  };

  setSortField(newSortBy);
  setSortDir(newSortDir);

  const setData = toggleButtonEnabled ? setCropVariety : setCropHistory;
  setData(prevAllData => multiLevelSort({ items: prevAllData, ...sortParams }));
};

export default handleDirection;
