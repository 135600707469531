import axios from 'axios';
import { Context } from 'components/Store';
import { parseServerError } from 'helpers/errorHelpers';
import { useContext, useEffect, useState } from 'react';
import { CROPWISE_PROXY_V2_URL } from 'utilities/apiConstants';
import { getAccessToken } from 'utilities/base-auth';

export const sortingMap = {
  highest_quantity: {
    sortBy: 'quantity',
    sortDir: 'desc'
  },
  lowest_quantity: {
    sortBy: 'quantity',
    sortDir: 'asc'
  },
  highest_revenue: {
    sortBy: 'revenue',
    sortDir: 'desc'
  },
  lowest_revenue: {
    sortBy: 'revenue',
    sortDir: 'asc'
  }
};

const useSalesSummary = ({ cropSeasonId, cropId }) => {
  const [, dispatch] = useContext(Context);

  const [loading, setLoading] = useState(false);
  const [salesSummary, setSalesSummary] = useState(null);

  const [sortingObject, setSortingObject] = useState({
    sortBy: 'quantity',
    sortDir: 'desc'
  });

  useEffect(() => {
    if (cropId && cropSeasonId) {
      setLoading(true);

      const orgId = localStorage.getItem('selectedOrganizationId');

      const promise = axios.get(
        `${CROPWISE_PROXY_V2_URL}/yield/summary/sale?cropId=${cropId}&cropSeasonId=${cropSeasonId}&sortBy=${sortingObject.sortBy}&sortDir=${sortingObject.sortDir}`,
        {
          headers: {
            'Content-Type': 'application/json',
            'cwf-context': JSON.stringify({
              organization_id: orgId
            }),
            Authorization: `Bearer ${getAccessToken()}`
          }
        }
      );

      promise
        .then(data => {
          setSalesSummary(data?.data || null);
        })
        .catch(err => parseServerError(dispatch)(err))
        .finally(() => setLoading(false));
    }
  }, [sortingObject, dispatch, cropSeasonId, cropId]);

  const onSortChange = sortOption => {
    setSortingObject(sortingMap[sortOption]);
  };

  return {
    loading,
    onSortChange,
    salesSummary
  };
};

export default useSalesSummary;
