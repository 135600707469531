import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Row, Col } from 'antd';
import { Popover } from 'syngenta-digital-cropwise-react-ui-kit';

import ClickableDiv from 'components/ClickableDiv';
import ArrowDropDownIcon from 'components/Icons/ArrowDropDownIcon';
import CheckmarkIcon from 'components/Icons/CheckmarkIcon';
import { CROP_COLORS } from 'screens/Property/helpers/constants';

const ColorPickerContent = ({ value, onColorSelect }) => {
  const [hoveredColor, setHoveredColor] = useState();
  const displayCheckmark = color => value === color || hoveredColor === color;

  return (
    <div className="p-2">
      {CROP_COLORS.map((shades, shadeIndex) => (
        <Row
          key={shades[0].primary}
          gutter={4}
          className={shadeIndex > 0 ? 'mt-1' : ''}
        >
          {shades.map(shade => (
            <Col key={shade.primary}>
              <ClickableDiv
                data-testid={`color-picker-option-${shade.primary}`}
                className="h-5 w-5 rounded-sm relative flex items-center justify-center"
                style={{ background: shade.primary }}
                onClick={() => onColorSelect(shade.primary)}
                onMouseEnter={() => setHoveredColor(shade.primary)}
                onMouseLeave={() => setHoveredColor()}
              >
                {displayCheckmark(shade.primary) && (
                  <CheckmarkIcon color={shade.accent} />
                )}
              </ClickableDiv>
            </Col>
          ))}
        </Row>
      ))}
    </div>
  );
};

ColorPickerContent.propTypes = {
  value: PropTypes.string.isRequired,
  onColorSelect: PropTypes.func.isRequired
};

const ColorPicker = ({ cropId, value, onChange }) => {
  const [open, setOpen] = useState(false);

  const onColorSelect = shade => {
    onChange(cropId, shade);
    setOpen(false);
  };

  const handleOpenChange = newOpen => {
    setOpen(newOpen);
  };

  return (
    <Popover
      content={
        <ColorPickerContent value={value} onColorSelect={onColorSelect} />
      }
      trigger="click"
      placement="bottom"
      open={open}
      onOpenChange={handleOpenChange}
    >
      <ClickableDiv
        data-testid="color-picker"
        className={`rounded p-1 ${
          open ? 'bg-blue-10 hover:bg-blue-10' : 'hover:bg-neutral-100'
        }`}
      >
        <Row gutter={2} className="items-center">
          <Col>
            <div
              className={`h-8 w-8 rounded cursor-pointer ${open &&
                'border-2 border-blue-50'}`}
              style={{ background: value }}
            />
          </Col>
          <Col>
            <ArrowDropDownIcon color={open ? '#0092E4' : undefined} />
          </Col>
        </Row>
      </ClickableDiv>
    </Popover>
  );
};

ColorPicker.propTypes = {
  cropId: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired
};

export default ColorPicker;
