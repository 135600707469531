/* eslint-disable no-unreachable */
/* eslint-disable no-lonely-if */
import React, { createContext, useContext, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import useJohnDeereData from 'screens/Integrations/hooks/useJohnDeereData';
import { Context } from 'components/Store';
import {
  SET_CROP_ZONE_MATCHER_INFO,
  SET_CROP_ZONE_MATCHER_TYPE,
  SET_SHOW_TOAST_CROP_ZONE_MATCHER
} from 'reducers/reducer';
import _ from 'lodash';
import { AmplitudeContext } from 'utilities/amplitude/useAmplitude';

const CropZoneMatcherContext = createContext();

const CropZoneMatcherProvider = ({ children }) => {
  const [operationIds, setOperationIds] = useState([]);
  const [fieldId, setCropZoneId] = useState();
  const [fieldsGeometries, setFieldsGeometries] = useState(new Map());
  const [cropSeasonId, setCropSeasonId] = useState();
  const [cropZoneName, setCropZoneName] = useState();
  const [isLoadingMatcher, setIsLoadingMatcher] = useState(false);
  const [sourceFields, setSourceFields] = useState();
  const [selectedSF, setSelectedSF] = useState();
  const [reloadData, setReloadData] = useState(false);
  const amplitude = useContext(AmplitudeContext);

  const { loadMatchCropZone } = useJohnDeereData();

  const [, dispatch] = useContext(Context);

  const getCs = cropSeason => {
    setCropSeasonId(cropSeason);
  };

  const getOperationId = oId => {
    setOperationIds(prev =>
      [...prev, oId].filter(
        (item, index) => [...prev, oId].indexOf(item) === index
      )
    );
  };

  const removeOperationId = oId => {
    setOperationIds(prev => [...prev].filter(item => item !== oId));
  };

  const operationReset = () => {
    setOperationIds([]);
  };

  const getCropZoneId = cropZiD => {
    amplitude.sendEventToAmplitude(
      amplitude.events.epic.Integrations.JohnDeere.selectCropZone
    );
    setCropZoneId(cropZiD);
  };

  const reloadAllData = () => {
    setReloadData(prev => !prev);
  };

  const getCropZoneName = cropName => {
    setCropZoneName(cropName);
  };

  const buttonIsLoading = () => {
    setIsLoadingMatcher(true);
  };

  const buttonIsLoadingIsgDisable = () => {
    setIsLoadingMatcher(false);
  };

  const getSourceFields = sourceArray => {
    setSourceFields(sourceArray);
  };

  const getSelectedSourceField = SFSelected => {
    setSelectedSF(SFSelected);
  };

  const extractFieldsGeometriesFromFarms = farms => {
    if (fieldsGeometries.size === 0 && farms?.length > 0) {
      setFieldsGeometries(
        new Map(
          farms.flatMap(farm =>
            farm.fields?.map(({ id, geometry }) => [id, geometry])
          )
        )
      );
    }
  };

  const showToast = (allStatus, successStatus, errorStatus) => {
    if (successStatus.length && !errorStatus.length) {
      dispatch({
        type: SET_CROP_ZONE_MATCHER_TYPE,
        payload: 'success'
      });

      dispatch({
        type: SET_CROP_ZONE_MATCHER_INFO,
        payload: {
          cropZoneName,
          operationMapped: successStatus.length
        }
      });
    } else if (successStatus.length && errorStatus.length) {
      dispatch({
        type: SET_CROP_ZONE_MATCHER_TYPE,
        payload: 'warning'
      });
      dispatch({
        type: SET_CROP_ZONE_MATCHER_INFO,
        payload: {
          cropZoneName,
          operationMapped: `${successStatus.length} / ${allStatus.length}`
        }
      });
    } else {
      dispatch({
        type: SET_CROP_ZONE_MATCHER_TYPE,
        payload: 'error'
      });
      dispatch({
        type: SET_CROP_ZONE_MATCHER_INFO,
        payload: {
          cropZoneName,
          operationMapped: errorStatus.length
        }
      });
    }
    dispatch({
      type: SET_SHOW_TOAST_CROP_ZONE_MATCHER,
      payload: true
    });
  };

  const redirect = res => {
    const allStatus = _.flatMapDeep(res.map(item => item.value));
    const successStatus = _.flatMapDeep(
      allStatus.filter(
        response => response.status >= 200 && response.status <= 300
      )
    );
    const errorStatus = _.flatMapDeep(
      allStatus.filter(
        response => response.status >= 301 && response.status <= 500
      )
    );
    if (errorStatus.length) {
      amplitude.sendEventToAmplitude(
        amplitude.events.epic.Integrations.JohnDeere.cropZoneMatchFailure
      );
      showToast(allStatus, successStatus, errorStatus);
      reloadAllData();
      return;
    }
    amplitude.sendEventToAmplitude(
      amplitude.events.epic.Integrations.JohnDeere.operationsMatched,
      { operationsMatched: successStatus.length }
    );
    amplitude.sendEventToAmplitude(
      amplitude.events.epic.Integrations.JohnDeere.cropZoneMatchSuccess
    );

    if (selectedSF?.operations.length === operationIds.length) {
      if (sourceFields?.length - 1 === 0) {
        showToast(allStatus, successStatus, errorStatus);
        history.back();
        return;
      }
      if (sourceFields?.length - 1 >= 1) {
        showToast(allStatus, successStatus, errorStatus);
        reloadAllData();
        return;
      }
    }
    showToast(allStatus, successStatus, errorStatus);
    reloadAllData();
  };

  const toMatchCropZone = () => {
    if (!cropSeasonId || !operationIds || !fieldId) {
      buttonIsLoadingIsgDisable();
      return;
    }
    const userTimeZone = Intl.DateTimeFormat().resolvedOptions();
    const currentTimeUTC = new Date();
    const creationDate = currentTimeUTC.toLocaleString(userTimeZone.locale, {
      timeZone: `${userTimeZone.timeZone}`,
      hourCycle: 'h23',
      year: 'numeric',
      month: '2-digit',
      day: '2-digit',
      hour: '2-digit',
      minute: '2-digit',
      second: '2-digit'
    });
    Promise.allSettled(
      operationIds.map(opId => {
        return loadMatchCropZone(opId, fieldId, cropSeasonId, creationDate);
      })
    ).then(res => {
      redirect(res);
      buttonIsLoadingIsgDisable();
    });
  };
  const pro = useMemo(
    () => ({
      getOperationId,
      operationReset,
      removeOperationId,
      fieldId,
      setCropZoneId,
      cropSeasonId,
      toMatchCropZone,
      getCs,
      getCropZoneId,
      operationIds,
      getCropZoneName,
      isLoadingMatcher,
      buttonIsLoading,
      getSourceFields,
      getSelectedSourceField,
      reloadData,
      extractFieldsGeometriesFromFarms,
      fieldsGeometries
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      cropSeasonId,
      fieldId,
      operationIds,
      isLoadingMatcher,
      reloadData,
      fieldsGeometries
    ]
  );

  return (
    <CropZoneMatcherContext.Provider value={pro}>
      {children}
    </CropZoneMatcherContext.Provider>
  );
};

CropZoneMatcherProvider.defaultProps = {
  children: null
};

CropZoneMatcherProvider.propTypes = {
  children: PropTypes.node
};

export { CropZoneMatcherContext, CropZoneMatcherProvider };
