/* eslint-disable react/prop-types */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable func-names */
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import CheckboxTree from 'react-checkbox-tree';
import 'react-checkbox-tree/lib/react-checkbox-tree.css';
import './styles.css';
import _ from 'lodash';

import {
  ArrowRight,
  ArrowDropDown,
  CheckBox,
  CheckBoxOutlineBlank,
  IndeterminateCheckBox
} from '@material-ui/icons';
import { filterCropSeason } from 'screens/CropSeasons/helpers/dataHelpers';

const GroupByFilterCrop = ({
  data,
  value,
  onChange,
  disabled,
  cropZonesChecked,
  setCropZonesChecked
}) => {
  const [checked, setChecked] = useState(value);
  const [expanded, setExpanded] = useState([]);
  const [fft, setFft] = useState([]);
  const [enabledCropIds, setEnabledCropIds] = useState([]);
  const [enableFarmIds, setEnableFarmIds] = useState([]);
  const [enableFieldIds, setEnableFieldIds] = useState([]);
  const traverseCropZones = (selectedCropSeason, field) => {
    let zoneCount = 1;
    if (selectedCropSeason) {
      zoneCount = 0;
      /* eslint no-unused-expressions: "off", curly: "error" */
      field?.cropzones.forEach(cz => {
        zoneCount += filterCropSeason(selectedCropSeason, cz.cropSeasons);
      });
    }

    if (zoneCount > 0) {
      const currentFieldIds = enableFieldIds;
      currentFieldIds.push(field.fieldId);
      setEnableFieldIds(currentFieldIds);
    }
    return zoneCount;
  };

  const traverseFields = (selectedCropSeason, farm) => {
    let fieldCount = 1;
    if (selectedCropSeason) {
      fieldCount = 0;
      /* eslint no-unused-expressions: "off", curly: "error" */
      farm?.children.forEach(field => {
        fieldCount += traverseCropZones(selectedCropSeason, field);
      });
    }

    if (fieldCount > 0) {
      const currentFarmIds = enableFarmIds;
      currentFarmIds.push(farm.farmId);
      setEnableFarmIds(currentFarmIds);
    }
    return fieldCount;
  };

  const traverseFarms = (selectedCropSeason, crop) => {
    let farmCount = 1;
    if (selectedCropSeason) {
      farmCount = 0;
      /* eslint no-unused-expressions: "off", curly: "error" */
      crop?.children.forEach(farm => {
        farmCount += traverseFields(selectedCropSeason, farm);
      });
    }

    if (farmCount > 0) {
      const currentCropIds = enabledCropIds;
      currentCropIds.push(crop.crpId);
      setEnabledCropIds(currentCropIds);
    }
  };

  let mappedFft = [];
  useEffect(() => {
    const flatPropertiesData = _.flatMap(
      data,
      ({ fields, id, name, selectedCropSeason }) => {
        return _.map(fields, field => {
          return _.map(field.cropzones, cropzone => {
            return {
              farms: {
                farmName: name,
                farmId: id,
                field: {
                  fieldName: field.name,
                  fieldId: field.id,
                  farmId: id,
                  cropzones: field.cropzones
                }
              },
              crpId: cropzone.crop.id,
              crpName: cropzone.crop.name,
              selectedCropSeason
            };
          });
        });
      }
    );

    const tempPropertiesData = _.flatMap(flatPropertiesData);

    // Grouping the data with respect to crop
    const groupByCropValues = _.chain(_.flatMap(tempPropertiesData))
      .groupBy('crpName')
      .map((value1, key) => {
        return {
          name: key,
          crpId: value1[0].crpId,
          children: value1.map(val => val.farms),
          selectedCropSeason: value1[0].selectedCropSeason
        };
      })
      .value();

    // Grouping the data with respect to Farm level
    const groupByFarmLevel = _.map(groupByCropValues, child => {
      return {
        crpId: child.crpId,
        crpName: child.name,
        children: _(child.children)
          .groupBy('farmName')
          .map(function(items, key) {
            return {
              farmName: key,
              farmId: items[0].farmId,
              children: _.map(items.map(eachItem => eachItem.field))
            };
          })
          .value(),
        selectedCropSeason: child.selectedCropSeason
      };
    });

    // Grouping the data with respect to Field level
    const groupByFieldLevel = _.map(groupByFarmLevel, child => {
      return {
        crpId: child.crpId,
        crpName: child.crpName,
        children: _(child.children)
          .map(function(items) {
            return {
              farmName: items.farmName,
              farmId: items.farmId,
              children: items.children.filter(
                (eachField, i) =>
                  items.children.findIndex(
                    val => eachField.fieldId === val.fieldId
                  ) === i
              )
            };
          })
          .value(),
        selectedCropSeason: child.selectedCropSeason
      };
    });

    groupByFieldLevel.forEach(crop => {
      traverseFarms(crop.selectedCropSeason, crop);
    });

    const checkForChildrenLength = children => {
      return children?.length > 0;
    };

    // Mapping the crop with the value, label and type for react-checkbox
    mappedFft = groupByFieldLevel?.map((crop, fieldIndex) => ({
      value: crop.crpId,
      label: crop.crpName,
      type: 'crop',
      disabled: crop.selectedCropSeason
        ? !enabledCropIds.includes(crop.crpId)
        : !checkForChildrenLength(crop.children),
      ...(crop?.children?.length > 0
        ? {
            children: crop?.children?.map((eachFarm, farmIndex) => ({
              value: `${eachFarm?.farmId}_${fieldIndex}_${farmIndex}`,
              label: eachFarm.farmName,
              farmId: eachFarm.farmId,
              type: 'farm',
              disabled: crop.selectedCropSeason
                ? !enableFarmIds.includes(eachFarm.farmId)
                : !checkForChildrenLength(eachFarm.children),
              ...(eachFarm?.children?.length > 0
                ? {
                    children: eachFarm?.children?.map(eachField => ({
                      value: `${eachField?.fieldId}_${fieldIndex}`,
                      label: eachField?.fieldName,
                      farmId: eachFarm.farmId,
                      type: 'field',
                      disabled: crop.selectedCropSeason
                        ? !enableFieldIds.includes(eachField.fieldId)
                        : !checkForChildrenLength(eachField?.cropzones),
                      ...(eachField?.cropzones?.length > 0
                        ? {
                            children: eachField?.cropzones
                              ?.filter(
                                eachCropzone =>
                                  eachCropzone.crop.id === crop.crpId
                              )
                              .map(cz => ({
                                value: cz.id,
                                label: cz.name,
                                farmId: eachFarm.farmId,
                                type: 'cropzone',
                                disabled:
                                  filterCropSeason(
                                    crop.selectedCropSeason,
                                    cz.cropSeasons
                                  ) === 0
                              }))
                          }
                        : {})
                    }))
                  }
                : {})
            }))
          }
        : {})
    }));

    if (mappedFft?.length > 0) {
      setFft(mappedFft);
    }
  }, [data]);

  useEffect(() => {}, [fft]);

  const getFarmIds = checkedItems => {
    const flattenTree = array => {
      const thing = array.flatMap(item => {
        if (item.children?.length > 0) {
          return flattenTree(item.children);
        }
        return item;
      });
      return thing;
    };

    const farmIds = [];
    checkedItems?.map(selectedCzId => {
      const allCropzones =
        fft.length > 0 ? flattenTree(fft) : flattenTree(mappedFft);
      return allCropzones.find(cz => {
        const tempValue = cz.value.split('_').shift();
        if (tempValue === selectedCzId && !farmIds.includes(cz.farmId)) {
          farmIds.push(cz.farmId);
        }
        return false;
      });
    });
    return farmIds;
  };

  const handleCheck = async (checkedIds = [], targetNode) => {
    setCropZonesChecked(checkedIds);
    setChecked(checkedIds);
    const farmIds = getFarmIds(checkedIds);
    onChange(checkedIds, farmIds, targetNode);
  };

  useEffect(() => {
    handleCheck(cropZonesChecked);
  }, [cropZonesChecked]);

  return (
    <div className="filter-container" data-testid="cropseason-group-by-crop">
      <CheckboxTree
        nodes={fft}
        checked={checked}
        showNodeIcon={false}
        icons={{
          halfCheck: <IndeterminateCheckBox fontSize="small" />,
          uncheck: (
            <CheckBoxOutlineBlank
              fontSize="small"
              className="text-neutral-600"
            />
          ),
          check: <CheckBox fontSize="small" />,
          expandClose: (
            <ArrowRight fontSize="small" className="text-neutral-600" />
          ),
          expandOpen: (
            <ArrowDropDown fontSize="small" className="text-neutral-600" />
          )
        }}
        expanded={expanded}
        onCheck={(id, targetNode) => handleCheck(id, targetNode)}
        onExpand={ex => setExpanded(ex)}
        showNodeTitle
        disabled={disabled}
      />
    </div>
  );
};

GroupByFilterCrop.defaultProps = {
  onChange: () => {},
  disabled: false,
  value: [],
  setCropZonesChecked: () => {}
};
GroupByFilterCrop.propTypes = {
  onChange: PropTypes.func,
  data: PropTypes.arrayOf(PropTypes.object).isRequired,
  value: PropTypes.arrayOf(PropTypes.string),
  disabled: PropTypes.bool,
  setCropZonesChecked: PropTypes.func
};

export default GroupByFilterCrop;
