import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
  Button,
  ButtonType,
  Tooltip
} from 'syngenta-digital-cropwise-react-ui-kit';

const EditIcon = ({ fill }) => (
  <svg
    width="18"
    height="18"
    viewBox="0 0 18 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M13.244 2.25C13.0565 2.25 12.8615 2.325 12.719 2.4675L11.3465 3.84L14.159 6.6525L15.5315 5.28C15.824 4.9875 15.824 4.515 15.5315 4.2225L13.7765 2.4675C13.6265 2.3175 13.439 2.25 13.244 2.25ZM10.544 6.765L11.234 7.455L4.43902 14.25H3.74902V13.56L10.544 6.765ZM2.24902 12.9386L10.544 4.64355L13.3565 7.45605L5.06152 15.7511H2.24902V12.9386Z"
      fill={fill}
    />
  </svg>
);

EditIcon.propTypes = {
  fill: PropTypes.string.isRequired
};

const EditRowButton = ({ isRowSelected, onClick }) => {
  const [isHovered, setIsHovered] = useState(false);

  const handleMouseEnter = () => {
    setIsHovered(true);
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
  };

  const selectedStyles =
    'bg-transparent border-none shadow-none hover:bg-blue-50';
  const defaultStyles =
    'bg-transparent border-none shadow-none hover:bg-neutral-20';

  return (
    <Tooltip color="#232630" title="Edit">
      <button
        data-testid="edit-row-button"
        type="button"
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
      >
        <Button
          className={`p-6px ${isRowSelected ? selectedStyles : defaultStyles}`}
          type={ButtonType.default}
          onClick={onClick}
          icon={
            <EditIcon
              fill={isRowSelected && isHovered ? '#FFFFFF' : '#4D5165'}
            />
          }
        />
      </button>
    </Tooltip>
  );
};

EditRowButton.propTypes = {
  isRowSelected: PropTypes.bool.isRequired,
  onClick: PropTypes.func.isRequired
};

export default EditRowButton;
