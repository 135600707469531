import React, { useContext } from 'react';
import { Context } from 'components/Store';
import GrowUIFormField from 'components/Forms/GrowUIFormField';
import { Input } from '@agconnections/grow-ui';

const InsuranceId = () => {
  const [{ isEnableInfoTabEdit }] = useContext(Context);
  return (
    <GrowUIFormField
      control={Input}
      type="text"
      name="insuranceId"
      placeholder="Enter insurance ID"
      label="Insurance ID"
      disabled={!isEnableInfoTabEdit}
    />
  );
};

export default InsuranceId;
