import React from 'react';
import PropTypes from 'prop-types';
import { StyleSheet, Text, View } from '@react-pdf/renderer';
import { displayNumber, displayText } from 'components/PDF/helpers/pdfTools';
import { environmentalHeaderTranslations } from '../../utils/headerTranslations';

const styles = StyleSheet.create({
  title: {
    fontFamily: 'Noto Sans',
    fontWeight: 'medium',
    fontSize: 12,
    marginBottom: 12,
    marginTop: 3
  },
  row: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginBottom: 20
  },
  fieldContainer: {
    width: '25%'
  },
  fieldText: {
    fontFamily: 'Noto Sans',
    fontSize: 9
  },
  fieldTitle: {
    color: '#363948',
    lineHeight: 2
  },
  fieldValue: {
    color: '#14151C',
    fontWeight: 'medium'
  }
});

const Environmental = ({ weather, title, exportLanguage = 'english' }) => {
  return (
    <View>
      <View>
        <Text style={styles.title}>{title}</Text>
        <View style={styles.row}>
          <View style={styles.fieldContainer}>
            <Text style={[styles.fieldText, styles.fieldTitle]}>
              {environmentalHeaderTranslations.skyCondition[exportLanguage]}
            </Text>
            <Text style={[styles.fieldText, styles.fieldValue]}>
              {displayText(weather.skyCondition)}
            </Text>
          </View>
          <View style={styles.fieldContainer}>
            <Text style={[styles.fieldText, styles.fieldTitle]}>
              {environmentalHeaderTranslations.windSpeed[exportLanguage]}
            </Text>
            <Text style={[styles.fieldText, styles.fieldValue]}>
              {displayNumber(weather.windSpeed)}
            </Text>
          </View>
          <View style={styles.fieldContainer}>
            <Text style={[styles.fieldText, styles.fieldTitle]}>
              {environmentalHeaderTranslations.windDirection[exportLanguage]}
            </Text>
            <Text style={[styles.fieldText, styles.fieldValue]}>
              {displayText(weather.windDirection)}
            </Text>
          </View>
          <View style={styles.fieldContainer}>
            <Text style={[styles.fieldText, styles.fieldTitle]}>
              {environmentalHeaderTranslations.soilCondition[exportLanguage]}
            </Text>
            <Text style={[styles.fieldText, styles.fieldValue]}>
              {displayText(weather.soilMoisture)}
            </Text>
          </View>
        </View>
        <View style={[styles.row, { justifyContent: 'flex-start' }]}>
          <View style={styles.fieldContainer}>
            <Text style={[styles.fieldText, styles.fieldTitle]}>
              {environmentalHeaderTranslations.temperature[exportLanguage]}
            </Text>
            <Text style={[styles.fieldText, styles.fieldValue]}>
              {displayNumber(weather.temperature)}
            </Text>
          </View>
          <View style={styles.fieldContainer}>
            <Text style={[styles.fieldText, styles.fieldTitle]}>
              {environmentalHeaderTranslations.humidity[exportLanguage]}
            </Text>
            <Text style={[styles.fieldText, styles.fieldValue]}>
              {displayNumber(weather.humidity)}
            </Text>
          </View>
        </View>
      </View>
    </View>
  );
};

Environmental.propTypes = {
  weather: PropTypes.shape({
    skyCondition: PropTypes.string.isRequired,
    windSpeed: PropTypes.number.isRequired,
    windDirection: PropTypes.string.isRequired,
    soilMoisture: PropTypes.string.isRequired,
    temperature: PropTypes.number.isRequired,
    humidity: PropTypes.number.isRequired
  }),
  exportLanguage: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired
};

Environmental.defaultProps = {
  weather: {}
};

export default Environmental;
