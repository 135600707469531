const aToZ = '(A \u2192 Z)';
const sortItems = [
  {
    sortLabel: `Location Name ${aToZ}`,
    sortKey: 'name',
    sortDirection: 'asc'
  },
  { sortLabel: `City ${aToZ}`, sortKey: 'city', sortDirection: 'asc' },
  {
    sortLabel: `Company ${aToZ}`,
    sortKey: 'company',
    sortDirection: 'asc'
  }
];

export default sortItems;
