/* istanbul ignore file */
import { Factory } from 'miragejs';
import faker from 'faker';

const Task = {
  name() {
    return `JS 210521 ${faker.random.number()}`;
  },
  status() {
    return 'ready';
  },
  startDate() {
    return faker.date.recent();
  },
  dueDate() {
    return faker.date.future();
  },
  flags(i) {
    if (i === 0) {
      return [
        { name: 'Corn', color: '#C1462B' },
        { name: 'Tag 2', color: '#9A6412' },
        { name: 'Season', color: '#8354D6' },
        { name: 'Tag 4', color: '#217C74' },
        { name: 'Really Long Tag Name Example', color: '#217C74' },
        { name: 'Corn', color: '#C1462B' }
      ];
    }
    if (i % 2) {
      return [
        { name: 'High Priority', color: '#0071CD' },
        { name: 'Active', color: '#14803C' }
      ];
    }

    return [
      { name: 'Corn', color: '#C1462B' },
      { name: 'Tag 2', color: '#9A6412' }
    ];
  },
  properties() {
    return [{ id: faker.random.uuid() }];
  },
  totalAcres() {
    return faker.random.number({ min: 200, max: 3000 });
  },
  products() {
    return [{ id: faker.random.uuid() }];
  },
  totalTaskCost() {
    return faker.finance.amount();
  },
  cropSeasons() {
    return ['Corn 2021', 'Corn'];
  },
  authorizedDate() {
    return faker.date.recent();
  },
  timingEvent() {
    return 'Harvest';
  },
  source() {
    return 'Example Plan';
  },
  duration() {
    return `${faker.random.number({ min: 1, max: 20 })} hours`;
  },
  type() {
    return 'Spreading';
  },
  createdOn() {
    return faker.date.recent();
  },
  sources() {
    return [
      { type: 'product', id: faker.random.uuid() },
      { type: 'recommendation', id: faker.random.uuid() },
      { type: 'invoice', id: faker.random.uuid() },
      { type: 'plan', id: faker.random.uuid() }
    ];
  },
  bufferZone() {
    return `${faker.random.number({ min: 1, max: 20 })} Feet`;
  },
  bufferReason() {
    return 'Pollen Drift';
  },
  notes() {
    return faker.lorem.paragraph();
  },
  startWeather() {
    return {
      date: '2021-11-25T23:15:30.562Z',
      windSpeed: 11,
      windDirection: 'N',
      skyCondition: 'cloudy',
      temperature: 69,
      soilMoisture: 'Dry',
      humidity: 86
    };
  },
  endWeather() {
    return {
      date: '2021-11-25T23:15:30.562Z',
      windSpeed: 11,
      windDirection: 203,
      skyCondition: 'Cloudy',
      temperature: 69,
      soilMoisture: 10,
      humidity: 86
    };
  },

  afterCreate(task, server) {
    const persons = server.createList('person', 2);
    const members = server.createList('member', 1);

    task.update({
      applicators: persons,
      assignees: members,
      authorizedBy: server.db.people[0]
    });
  }
};

export default Factory.extend(Task);
