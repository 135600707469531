import React from 'react';
import PropTypes from 'prop-types';
import { Column } from '@agconnections/grow-ui';

const CardInfo = ({
  label,
  value,
  wrapperClassName,
  labelColWidth,
  valueColWidth
}) => {
  return (
    <div
      data-testid="card-info"
      className={`flex w-full pb-2 mt-4 border-b-1 border-solid-400 ${wrapperClassName}`}
    >
      <Column width={labelColWidth}>
        <span className="text-neutral-60 opacity-60 text-sm font-normal leading-5 tracking-tight">
          {label}
        </span>
      </Column>
      <Column width={valueColWidth}>
        <div
          className={`text-sm font-normal leading-5 tracking-tight whitespace-pre-line ${
            !value ? 'text-neutral-40 opacity-50' : 'text-neutral-1000'
          }`}
        >
          {value || 'None entered'}
        </div>
      </Column>
    </div>
  );
};

CardInfo.defaultProps = {
  value: null,
  wrapperClassName: 'justify-center',
  labelColWidth: 'w-1/2',
  valueColWidth: 'w-1/2'
};

CardInfo.propTypes = {
  label: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.node
  ]).isRequired,
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.node
  ]),
  wrapperClassName: PropTypes.string,
  labelColWidth: PropTypes.string,
  valueColWidth: PropTypes.string
};

export default CardInfo;
