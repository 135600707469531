import React from 'react';
import PropTypes from 'prop-types';
import { Table } from '@agconnections/grow-ui';
import { startCase, camelCase } from 'lodash';
import EllipseMenu from 'components/EllipseMenu';
import { EQUIPMENT_LANDING_MENU } from 'utilities/menus';

const EquipmentTableRow = ({ equipment, onAction }) => {
  const handleAction = action => onAction(action, equipment);

  return (
    <Table.Row key={equipment.id}>
      <Table.Cell onClick={() => handleAction('view')}>
        <span className="font-bold">{equipment?.name}</span>
      </Table.Cell>
      <Table.Cell>
        {startCase(camelCase(equipment?.type.split('_')))}
      </Table.Cell>
      <Table.Cell>{startCase(camelCase(equipment?.currentStatus))}</Table.Cell>
      <Table.Cell>{equipment?.year}</Table.Cell>
      <Table.Cell>{equipment?.make}</Table.Cell>
      <Table.Cell>{equipment?.model}</Table.Cell>
      <Table.Cell>
        <div
          data-testid="equipment-table-options-menu"
          className="absolute right-0 flex-none w-10 mr-2"
        >
          <EllipseMenu
            onAction={handleAction}
            options={EQUIPMENT_LANDING_MENU}
          />
        </div>
      </Table.Cell>
    </Table.Row>
  );
};

EquipmentTableRow.propTypes = {
  equipment: PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.string,
    type: PropTypes.string,
    currentStatus: PropTypes.string,
    year: PropTypes.number,
    make: PropTypes.string,
    model: PropTypes.string
  }).isRequired,
  onAction: PropTypes.func.isRequired
};

export default EquipmentTableRow;
