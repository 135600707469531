import React from 'react';
import PropTypes from 'prop-types';
import { SimpleModal } from '@agconnections/grow-ui';

const ConfirmationModal = ({
  open,
  handleCancel,
  handleClose,
  handleConfirm,
  cancelLabel,
  confirmationLabel,
  children
}) => {
  return (
    <SimpleModal
      open={open}
      title="Confirm Changes"
      close={handleClose}
      onCancel={handleCancel}
      onConfirm={handleConfirm}
      confirmLabel={confirmationLabel}
      cancelLabel={cancelLabel}
    >
      <div className="pr-6">{children}</div>
    </SimpleModal>
  );
};

ConfirmationModal.defaultProps = {
  open: false,
  cancelLabel: 'No',
  confirmationLabel: 'Yes'
};

ConfirmationModal.propTypes = {
  open: PropTypes.bool,
  cancelLabel: PropTypes.string,
  confirmationLabel: PropTypes.string,
  handleCancel: PropTypes.func.isRequired,
  handleClose: PropTypes.func.isRequired,
  handleConfirm: PropTypes.func.isRequired,
  children: PropTypes.oneOfType([PropTypes.element, PropTypes.string])
    .isRequired
};

export default ConfirmationModal;
