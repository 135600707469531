import Reports from 'screens/Reports';

import { ReportsIcon } from 'routes/icons';
import { paths } from 'routes/paths';

const routes = [
  {
    id: 'reports',
    key: 'reports',
    label: 'Reports',
    path: paths.reports,
    exact: true,
    secure: true,
    disabled: false,
    component: Reports,
    icon: ReportsIcon,
    nav: 'leftNav'
  }
];

export default routes;
