import React, { useState, useEffect, useContext } from 'react';
import { Spinner, Button } from '@agconnections/grow-ui';
import { noop } from 'lodash';
import AddRoundedIcon from '@material-ui/icons/AddRounded';

import { Context } from 'components/Store';
import { GLOBAL_BACKGROUND_COLOR } from 'reducers/reducer';

import { getLocalStorageApi, STORAGE_KEYS } from 'utilities/localStorage';
import LandingSearch from 'components/LandingSearch';
import Breadcrumb from 'components/App/AppShell/components/Breadcrumb';
import EmptyState from 'components/EmptyState';
import CustomProductModal from 'components/Modals/CustomProductModal';
import { hasRoleAccess, ROLES } from 'utilities/access';
import useProducts from 'hooks/useProducts';
import CwFPagination from 'components/CwFPagination';
import LandingListViewToggle from '../../components/LandingListViewToggle';
import useInventoryData from './helpers/useInventoryData';
import ProductTileBoard from './components/ProductTileBoard';
import ProductsTable from './components/ProductsTable';

const productsViewStorageApi = getLocalStorageApi(
  STORAGE_KEYS.INVENTORY_VIEW,
  'tile'
);

const Products = () => {
  const [{ loadingOrg, loggedInUserOrgPermission }, dispatch] = useContext(
    Context
  );
  const defaultProductsView = productsViewStorageApi.get();

  const {
    products,
    getProducts,
    productsCount,
    isLoading,
    pageNo,
    goToPage
  } = useProducts({ sortBy: 'productName', sortDir: 'asc' });

  const {
    loading,
    totalInventoryProducts,
    setTotalInventoryProducts,
    getInventoryDetails
  } = useInventoryData();
  const isTableEmpty = !isLoading && products?.length === 0;
  const [view, setView] = useState(defaultProductsView);
  const [filteredInventoryProducts, setFilteredInventoryProducts] = useState(
    []
  );
  const [productTypes, setProductTypes] = useState([]);
  const [openCustom, setOpenCustom] = useState(false);

  const handlePageChange = page => {
    goToPage(page);
  };

  const handleProdSave = newProduct => {
    const filteredProds = filteredInventoryProducts;

    filteredProds.push({
      productId: newProduct.id,
      purchased: 0,
      applied: 0,
      available: 0,
      averagePricePerUnit: 0,
      productName: newProduct.name,
      manufacturerName: newProduct.manufacturer,
      productType: newProduct.type,
      custom: true
    });
    setFilteredInventoryProducts(filteredProds);
    setOpenCustom(false);
  };

  useEffect(() => {
    getProducts();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (view === 'tile') {
      dispatch({
        type: GLOBAL_BACKGROUND_COLOR,
        payload: '#F5F8FC'
      });
    }

    return () => {
      dispatch({
        type: GLOBAL_BACKGROUND_COLOR,
        payload: null
      });
    };
  }, [view, dispatch]);

  useEffect(() => {
    setFilteredInventoryProducts(products);
  }, [products]);

  const handleSearchText = text => {
    const results = text
      ? products.filter(
          eachInventory =>
            eachInventory.productName
              .toLocaleLowerCase()
              .includes(text.toLocaleLowerCase()) ||
            eachInventory.manufacturerName
              .toLocaleLowerCase()
              .includes(text.toLocaleLowerCase())
        )
      : products;
    setTotalInventoryProducts(results.length);
    setFilteredInventoryProducts(results);
  };

  const showSpinner = () => {
    return loading || loadingOrg;
  };

  const handleFilterChange = (event, value, key = []) => {
    const combinedKey = key.map(item => item.key).join(',');
    setProductTypes(combinedKey);

    const results = products.filter(firstArray =>
      key.some(
        item =>
          firstArray.productType.toLowerCase() ===
          item.value.toLowerCase().replace(/ /gi, '')
      )
    );

    if (!key.length) {
      setTotalInventoryProducts(products.length);
      setFilteredInventoryProducts(products);
    } else {
      setTotalInventoryProducts(results.length);
      setFilteredInventoryProducts(results);
    }
  };

  const handleChangeView = newView => {
    setView(newView);
    productsViewStorageApi.save(newView);
  };

  const setPermission = () =>
    hasRoleAccess(loggedInUserOrgPermission?.role, ROLES.ADMIN);

  return (
    <div className="h-full w-full" data-testid="products">
      <Breadcrumb
        onOrganizationSelect={() => getInventoryDetails()}
        hideCropSeasonDropdowns
      >
        <Breadcrumb.Item title="Products" value="All" isLast disabled />
      </Breadcrumb>
      {showSpinner() ? (
        <Spinner />
      ) : (
        <>
          <div className="flex items-center justify-between">
            <LandingSearch
              id="products-search-input"
              name="search_products"
              placeholder="Search Products"
              filterItems={filteredInventoryProducts}
              selectedProductTypes={productTypes}
              onChange={event => {
                handleSearchText(event.target.value);
              }}
              onDateRangeChange={noop}
              onFilterChange={noop}
              onChangeLabel={noop}
              onChangeProductType={handleFilterChange}
              onChangeTaskType={noop}
              showDateRange={false}
              showCropSeason={false}
              showProductType
            />
            <div className="flex items-center">
              <LandingListViewToggle
                testId="products-view-toggle"
                view={view}
                changeView={handleChangeView}
              />
              {setPermission() && (
                <div className="pl-2 whitespace-no-wrap">
                  <Button
                    type="primary"
                    id="add-products-button"
                    onClick={() => setOpenCustom(true)}
                    icon={<AddRoundedIcon />}
                  >
                    Create Product
                  </Button>
                </div>
              )}
            </div>
          </div>
          <div>
            {filteredInventoryProducts &&
            filteredInventoryProducts.length !== 0 ? (
              <div>
                {view === 'tile' && (
                  <ProductTileBoard
                    products={filteredInventoryProducts}
                    isLoading={isLoading}
                  />
                )}
                {view === 'list' && (
                  <ProductsTable
                    products={filteredInventoryProducts}
                    totalInventoryProducts={totalInventoryProducts}
                    setTotalInventoryProducts={setTotalInventoryProducts}
                    isLoading={isLoading}
                  />
                )}{' '}
                {!isTableEmpty && (
                  <div
                    className={`pr-6 border-t-1 border-neutral-20 ${
                      isLoading ? 'pointer-events-none cursor-not-allowed' : ''
                    }`}
                  >
                    {CwFPagination(productsCount, pageNo, handlePageChange)}
                  </div>
                )}
              </div>
            ) : (
              <EmptyState
                buttonText="Create Product"
                setPermission={setPermission}
                onClick={() => {
                  setOpenCustom(true);
                }}
                subtitle="We were unable to find any products under this organization."
              />
            )}
          </div>
          {openCustom && (
            <CustomProductModal
              open={openCustom}
              onClose={() => setOpenCustom(false)}
              handleSave={handleProdSave}
              setModalTab="Fertilizer"
            />
          )}
        </>
      )}
    </div>
  );
};
export default Products;
