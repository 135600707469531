import React from 'react';
import GrowUIFormField from 'components/Forms/GrowUIFormField';
import { Input } from '@agconnections/grow-ui';

const Range = () => {
  return (
    <GrowUIFormField
      placeholder="Enter range"
      control={Input}
      type="text"
      name="range"
      id="range"
      label="Range"
    />
  );
};

export default Range;
