import React, { useCallback, useEffect, useContext } from 'react';
import { AddRounded, InfoOutlined } from '@material-ui/icons';
import { Button } from '@agconnections/grow-ui';
import { useHistory } from 'react-router-dom';
import { paths } from 'routes/paths';
import UOMDropdown from 'components/UOMDropdown';
import CropSelect from 'components/CropSelect/BorderlessCropSelect';
import { getBushelWeight } from 'helpers/getBushelWeight';
import { MEASUREMENT_UNITS } from 'helpers/unitsAbbreviation';
import PropTypes from 'prop-types';
import './responsive.css';
import { Context } from 'components/Store';
import { AmplitudeContext } from 'utilities/amplitude/useAmplitude';

const SummaryHeader = ({
  selectedUOM,
  setUOM,
  selectedCropId,
  crops,
  setCropId,
  selectedCropName,
  isLoading
}) => {
  const history = useHistory();
  const [{ isSidebarOpen }] = useContext(Context);

  const getIsBushelAvailableForCropId = useCallback(
    cropId => {
      const cropName = crops?.find(crop => crop.id === cropId)?.name;
      const bushelWeight = getBushelWeight(cropName);
      return bushelWeight !== undefined;
    },
    [crops]
  );
  const amplitude = useContext(AmplitudeContext);
  const handleCropChange = useCallback(
    value => {
      setCropId(value);
      // save the selected crop in local storage
      localStorage.setItem('selectedCropId', value);
      const isBushelAvailable = getIsBushelAvailableForCropId(value);
      setUOM(
        isBushelAvailable ? MEASUREMENT_UNITS.BUSHEL : MEASUREMENT_UNITS.POUND
      );
    },
    [getIsBushelAvailableForCropId, setUOM, setCropId]
  );

  useEffect(() => {
    const savedCropId = localStorage.getItem('selectedCropId');
    const isThisCropAvailableInSelectedSeason = crops?.some(
      crop => crop.id === savedCropId
    );
    if (savedCropId && isThisCropAvailableInSelectedSeason) {
      handleCropChange(savedCropId);
      return;
    }
    const firstCrop = crops?.[0];
    if (firstCrop) {
      handleCropChange(firstCrop.id);
    }
  }, [crops, handleCropChange]);

  return (
    <div
      className={`flex justify-between items-start gap-2 pt-6 ${
        isSidebarOpen ? 'px-4' : 'px-14'
      }`}
    >
      <div>
        <div className="flex flex-1 items-center gap-2 ">
          <h1
            className="text-2xl leading-8 whitespace-no-wrap"
            data-testid="page-heading"
          >
            Commodity Summary
          </h1>
          <div className="flex-1">
            <CropSelect
              crops={crops}
              value={selectedCropId}
              onChange={handleCropChange}
              withFormik={false}
              isLoading={isLoading}
            />
          </div>
        </div>
        <div className="flex gap-1 pt-6px">
          <InfoOutlined className="text-blue-60" />
          <p className="text-neutral-1000">
            Data is based on selected commodity and single crop season.
          </p>
        </div>
      </div>
      <div className="uom-btn-container flex gap-2 pt-2 justify-end">
        <div className="uom-container flex items-start gap-2">
          <p className="whitespace-no-wrap mt-1">Display data in</p>
          <UOMDropdown
            value={selectedUOM}
            onChange={setUOM}
            cropName={selectedCropName}
          />
        </div>
        <Button
          icon={<AddRounded />}
          type="primary"
          onClick={() => {
            amplitude.sendEventToAmplitude(
              amplitude.events.epic.YieldV2.addYield.createYield
            );
            history.push(`${paths.yieldV2}/create?cropId=${selectedCropId}`);
          }}
        >
          <span className="whitespace-no-wrap">Yield Loads</span>
        </Button>
      </div>
    </div>
  );
};

SummaryHeader.propTypes = {
  selectedUOM: PropTypes.string.isRequired,
  setUOM: PropTypes.func.isRequired,
  selectedCropId: PropTypes.string.isRequired,
  crops: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      name: PropTypes.string
    })
  ).isRequired,
  selectedSeason: PropTypes.shape({
    crops: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.string,
        name: PropTypes.string
      })
    )
  }),
  setCropId: PropTypes.func.isRequired,
  selectedCropName: PropTypes.string,
  isLoading: PropTypes.bool
};

SummaryHeader.defaultProps = {
  selectedSeason: undefined,
  selectedCropName: undefined,
  isLoading: false
};

export default SummaryHeader;
