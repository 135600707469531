import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
  Button,
  ButtonType,
  Drawer,
  Row,
  Col
} from 'syngenta-digital-cropwise-react-ui-kit';
import CloseIcon from 'assets/close.svg';
import PlusIcon from 'assets/plus_green.svg';
import { TYPE_FILTER_ADD_OPTIONS } from 'screens/ProductList/helpers/tableConstants';
import ProductSearch from '../AddProductSearch';
import AddProductSearchList from './components/AddProductSearchList';
import SortByProductType from '../ProductListTypeFilter';

const AddProductDrawer = ({
  visible,
  onClose,
  setProductToEdit,
  selectedProductsList,
  setSelectedProductsList,
  handleOnAddCustomProduct,
  handleOnEdit,
  handleAddProductsSubmit
}) => {
  const [typeFilters, setTypeFilters] = useState([]);

  const handleTypeFilterChange = (_, __, e) => {
    const selected = e.map(({ key }) => key);
    if (selected.length === 0 || selected.length === 1) {
      setTypeFilters(selected);
    } else {
      const lastSelected = selected.filter(x => typeFilters.indexOf(x) === -1);
      setTypeFilters(lastSelected);
    }
  };
  return (
    <Drawer
      className="add-product-drawer"
      open={visible}
      placement="right"
      closable={false}
      maskClosable={false}
      onClose={onClose}
      width="50%"
      footer={
        <Row justify="end">
          <Button
            type={ButtonType.outline}
            className="mr-10px"
            onClick={onClose}
          >
            Cancel
          </Button>
          {selectedProductsList ? (
            <Button type={ButtonType.primary} onClick={handleAddProductsSubmit}>
              Add to Product List
            </Button>
          ) : (
            <Button type={ButtonType.primary} disabled>
              Add to Product List
            </Button>
          )}
        </Row>
      }
    >
      <div className="h-full">
        <div className="pt-4 pr-4 pb-5 pl-8">
          <Row justify="end">
            <button type="button" onClick={onClose}>
              <img alt="close" src={CloseIcon} />
            </button>
          </Row>
          <div className="ml-2 mb-5 text-xl font-semibold">
            Add Products to Product List
          </div>
          <Row gutter={16}>
            <Col span={12}>
              <ProductSearch typeFilters={typeFilters} />
            </Col>
            <Col span={6}>
              <SortByProductType
                typeFilters={typeFilters}
                handleFilterChange={handleTypeFilterChange}
                width=""
                typeFilterOptions={TYPE_FILTER_ADD_OPTIONS}
              />
            </Col>
            <Col span={6}>
              <Button
                ghost
                type={ButtonType.primary}
                className="px-3"
                onClick={handleOnAddCustomProduct}
              >
                <Row>
                  <img alt="plus" src={PlusIcon} className="mr-1" />
                  <span>Create Custom</span>
                </Row>
              </Button>
            </Col>
          </Row>
        </div>
        <Row flex="auto">
          <AddProductSearchList
            selectedProductsList={selectedProductsList}
            setSelectedProductsList={setSelectedProductsList}
            setProductToEdit={setProductToEdit}
            handleOnEdit={handleOnEdit}
            typeFilters={typeFilters}
          />
        </Row>
      </div>
    </Drawer>
  );
};

AddProductDrawer.propTypes = {
  visible: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  setProductToEdit: PropTypes.func.isRequired,
  selectedProductsList: PropTypes.shape({
    name: PropTypes.string,
    manufacturer: PropTypes.string,
    producttype: PropTypes.string,
    isInUse: PropTypes.bool
  }).isRequired,
  setSelectedProductsList: PropTypes.func.isRequired,
  handleOnEdit: PropTypes.func.isRequired,
  handleAddProductsSubmit: PropTypes.func.isRequired,
  handleOnAddCustomProduct: PropTypes.func.isRequired
};

export default AddProductDrawer;
