const useZendesk = () => {
  const createChatWidget = () => {
    const isZendeskInstantiated = document.getElementById('ze-snippet');

    if (!isZendeskInstantiated && process.env.REACT_APP_ZENDESK_WIDGET) {
      const zendeskScript = document.createElement('script');
      zendeskScript.src = process.env.REACT_APP_ZENDESK_WIDGET;
      zendeskScript.id = 'ze-snippet';
      document.getElementById('cwf-body').appendChild(zendeskScript);
    }
  };

  const destroyChatWidget = () => {
    const zendeskWidget = document.getElementById('ze-snippet');

    if (zendeskWidget) {
      zendeskWidget.remove();
    }
  };

  const openChatWidget = () => {
    if (window.zE) {
      window.zE('messenger', 'open');
    }
  };

  const getUnreadChatMessages = cb => {
    if (window.zE) {
      window.zE('messenger:on', 'unreadMessages', cb);
    }
  };

  return {
    createChatWidget,
    destroyChatWidget,
    openChatWidget,
    getUnreadChatMessages
  };
};

export default useZendesk;
