/* eslint-disable react/jsx-pascal-case */
import React from 'react';
import Toast from 'components/Toast';
import PropTypes from 'prop-types';
import { Button } from '@agconnections/grow-ui';

const PropertyDetailsToast = ({
  selectedProperty,
  showEditToast,
  setShowEditToast,
  state
}) => {
  const renderTitle = _state => {
    let message;
    if (_state === 'success') {
      message = `${selectedProperty.type} Information Saved`;
    }
    if (_state === 'error') {
      message = `${selectedProperty.type} information was not saved`;
    }
    return message;
  };
  return (
    <Toast
      type={state}
      title={renderTitle(state)}
      open={showEditToast}
      onClose={() => {
        setShowEditToast(false);
      }}
      timeout={5500}
    >
      {state === 'error' ? (
        <div>
          <p className="text-sm font-normal mb-2">
            An error prevented your information from being saved. Please try
            again.
          </p>
          <Button
            size="lg"
            type="primary"
            onClick={() =>
              window.open(
                'https://agconnections.zendesk.com/hc/en-us/categories/7419295252627-Cropwise-Financials',
                '_blank',
                'noopener'
              )
            }
            style={{ marginLeft: 'auto' }}
          >
            Contact Support
          </Button>
        </div>
      ) : null}
    </Toast>
  );
};

PropertyDetailsToast.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  selectedProperty: PropTypes.object.isRequired,
  showEditToast: PropTypes.bool.isRequired,
  setShowEditToast: PropTypes.func.isRequired,
  state: PropTypes.string.isRequired
};

export default PropertyDetailsToast;
