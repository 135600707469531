import React from 'react';
import PropTypes from 'prop-types';
import { Tooltip } from 'syngenta-digital-cropwise-react-ui-kit';
import UnarchiveIcon from 'assets/unarchive.svg';

const UnarchiveButton = ({ isOnHover, onUnarchive }) => (
  <div className={isOnHover ? 'visible' : 'invisible'}>
    <Tooltip color="#232630" title="Unarchive">
      <button
        data-testid="unarchive-button"
        className="focus:outline-none"
        type="button"
        onClick={onUnarchive}
      >
        <img src={UnarchiveIcon} alt="unarchive" />
      </button>
    </Tooltip>
  </div>
);

UnarchiveButton.propTypes = {
  isOnHover: PropTypes.bool.isRequired,
  onUnarchive: PropTypes.func.isRequired
};

export default UnarchiveButton;
