import React from 'react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';
import { Button } from '@agconnections/grow-ui';
import { useField } from 'formik';
import FormNavGuard from 'components/FormNavGuard';
import { paths } from 'routes/paths';
import TopHeaderDetails from '../TopHeaderDetails';

function TopHeader({ isSaving, onSubmit }) {
  const history = useHistory();
  const [{ value: proposedDate }] = useField('proposedDate');
  const [{ value: products = [] }] = useField('products');
  const [{ value: totalApplied = 0 }] = useField('totalApplied');

  return (
    <div
      data-testid="recommendations-top-header"
      className="flex items-center justify-between p-3"
    >
      <TopHeaderDetails
        proposedDate={proposedDate}
        numProducts={products?.length ?? 0}
        totalApplied={totalApplied}
      />
      <div className="flex justify-end flex-1">
        <div>
          <Button
            type="primary"
            ghost
            onClick={() => history.push(paths.recommendations)}
            id="cancelRecommendation"
          >
            Cancel
          </Button>
        </div>
        <div className="ml-4">
          <Button
            id="saveRecommendation"
            onClick={onSubmit}
            type="primary"
            ariaLabel="save"
            loading={isSaving}
            disabled={isSaving}
          >
            Save
          </Button>
        </div>
      </div>
      <FormNavGuard data-testid="navigation-block" />
    </div>
  );
}

TopHeader.propTypes = {
  isSaving: PropTypes.bool.isRequired,
  onSubmit: PropTypes.func.isRequired
};

export default TopHeader;
