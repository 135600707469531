import React, { useState, useEffect } from 'react';
import GrowUIFormField from 'components/Forms/GrowUIFormField';
import { useFormikContext } from 'formik';
import { Select } from '@agconnections/grow-ui';

const TownshipDirection = () => {
  const { values } = useFormikContext();
  const [captionText, setCaptionText] = useState();

  const isValueEmpty = !values.township;
  useEffect(() => {
    if (isValueEmpty) {
      setCaptionText('Township must be entered first');
    } else {
      setCaptionText(null);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values.township]);

  return (
    <GrowUIFormField
      control={Select}
      type="text"
      name="townshipDirection"
      id="townshipDirection"
      errorMessage={captionText}
      placeholder="Select principle meridian direction"
      items={[
        { key: 'none', value: 'None' },
        { key: 'north', value: 'North' },
        { key: 'south', value: 'South' }
      ]}
      disabled={isValueEmpty}
    />
  );
};

export default TownshipDirection;
