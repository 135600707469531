import React, {
  createContext,
  useState,
  useMemo,
  useCallback,
  useEffect
} from 'react';
import PropTypes from 'prop-types';

export const SimplotContext = createContext();

const Provider = ({ children }) => {
  const host = process.env.REACT_APP_HOST_URL || 'http://localhost:3000';
  const redirect = `${host}/app/integrations/Simplot`;
  const clientId = process.env.REACT_APP_SIMPLOT_CLIENT_ID;

  const [step, setStep] = useState(1);
  const goBack = () => {
    setStep(s => Math.max(0, s - 1));
  };

  const goForward = () => {
    setStep(s => s + 1);
  };

  const simplotOauthUrl = `https://id.trimble.com/oauth/authorize?scope=Cropwise%20openid&client_id=${clientId}&response_type=code&redirect_uri=${redirect}`;

  const redirectToSimplotAuthorizeOrgUrl = useCallback(() => {
    window.location.href = `https://www.trimbleag.com/ThirdPartyAccess/ThirdPartyAuthorizationRedirect?clientid=${clientId}&redirectionUrl=${redirect}`;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const [canGoNext, setCanGoNext] = useState(false);
  const blockStepForward = () => setCanGoNext(false);
  const releaseStepForward = () => setCanGoNext(true);

  const selectPropertiesStep = 4;

  useEffect(() => {
    if (step === selectPropertiesStep) {
      setCanGoNext(true); // in properties selection there have a specific logic in other context to enable next step
    }
  }, [step]);

  const store = useMemo(
    () => ({
      state: { step, canGoNext },
      gotoStep: setStep,
      goBack,
      goForward,
      simplotOauthUrl,
      redirectToSimplotAuthorizeOrgUrl,
      blockStepForward,
      releaseStepForward
    }),
    [simplotOauthUrl, redirectToSimplotAuthorizeOrgUrl, step, canGoNext]
  );

  return (
    <SimplotContext.Provider value={store}>{children}</SimplotContext.Provider>
  );
};

Provider.defaultProps = {
  children: null
};

Provider.propTypes = {
  children: PropTypes.node
};

export default Provider;
