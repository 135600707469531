import React from 'react';
import PropTypes from 'prop-types';
import ViewOnlyField from 'components/ViewOnlyField';
import FormattedNumber from 'components/FormattedNumber';

const FixedCostSection = ({ plan, displayFinancials }) => {
  const getPerUnitMath = (unitValue, areaValue) => {
    const result = areaValue * unitValue;
    if (result > 0 && result !== Infinity) {
      return (
        <>
          <FormattedNumber unit="usCurrency" roundedTo={2}>
            {result}
          </FormattedNumber>
        </>
      );
    }
    return 0;
  };
  return (
    <>
      <div className="flex py-5">
        <div className="w-1/3">
          <ViewOnlyField header="Land Rent" className="w-1/3" text="text-lg" />
          <span className="text-2xl w-1/3">
            {displayFinancials &&
              getPerUnitMath(
                plan?.fixedCost?.landRent,
                plan?.estimatedAreaValue
              )}
          </span>
          <br />
          {displayFinancials && (
            <span className="gr-text-sm">
              <FormattedNumber unit="usCurrency" roundedTo={2}>
                {plan?.fixedCost?.landRent}
              </FormattedNumber>
              /{plan?.estimatedAreaUnit}
            </span>
          )}
        </div>
        <div className="w-1/3">
          <ViewOnlyField header="Equipment" className="w-1/3" text="text-lg" />
          <span className="text-2xl w-1/3">
            {displayFinancials &&
              getPerUnitMath(
                plan?.fixedCost?.equipment,
                plan?.estimatedAreaValue
              )}
          </span>
          <br />
          {displayFinancials && (
            <span className="gr-text-sm">
              <FormattedNumber unit="usCurrency" roundedTo={2}>
                {plan?.fixedCost?.equipment}
              </FormattedNumber>
              /{plan?.estimatedAreaUnit}
            </span>
          )}
        </div>
        <div className="w-1/3">
          <ViewOnlyField header="Labor" className="w-1/3" text="text-lg" />
          <span className="text-2xl w-1/3">
            {displayFinancials &&
              getPerUnitMath(plan?.fixedCost?.labor, plan?.estimatedAreaValue)}
          </span>
          <br />
          {displayFinancials && (
            <span className="gr-text-sm">
              <FormattedNumber unit="usCurrency" roundedTo={2}>
                {plan?.fixedCost?.labor}
              </FormattedNumber>
              /{plan?.estimatedAreaUnit}
            </span>
          )}
        </div>
      </div>
      <div className="flex py-5">
        <div className="w-1/3">
          <ViewOnlyField header="Repairs" className="w-1/3" text="text-lg" />
          <span className="text-2xl w-1/3">
            {displayFinancials &&
              getPerUnitMath(
                plan?.fixedCost?.repairs,
                plan?.estimatedAreaValue
              )}
          </span>
          <br />
          {displayFinancials && (
            <span className="gr-text-sm">
              <FormattedNumber unit="usCurrency" roundedTo={2}>
                {plan?.fixedCost?.repairs}
              </FormattedNumber>
              /{plan?.estimatedAreaUnit}
            </span>
          )}
        </div>
        <div className="w-1/3">
          <ViewOnlyField header="Taxes" className="w-1/3" text="text-lg" />
          <span className="text-2xl w-1/3">
            {displayFinancials &&
              getPerUnitMath(plan?.fixedCost?.taxes, plan?.estimatedAreaValue)}
          </span>
          <br />
          {displayFinancials && (
            <span className="gr-text-sm">
              <FormattedNumber unit="usCurrency" roundedTo={2}>
                {plan?.fixedCost?.taxes}
              </FormattedNumber>
              /{plan?.estimatedAreaUnit}
            </span>
          )}
        </div>
        <div className="w-1/3">
          <ViewOnlyField header="Insurance" className="w-1/3" text="text-lg" />
          <span className="text-2xl w-1/3">
            {displayFinancials &&
              getPerUnitMath(
                plan?.fixedCost?.insurance,
                plan?.estimatedAreaValue
              )}
          </span>
          <br />
          {displayFinancials && (
            <span className="gr-text-sm">
              <FormattedNumber unit="usCurrency" roundedTo={2}>
                {plan?.fixedCost?.insurance}
              </FormattedNumber>
              /{plan?.estimatedAreaUnit}
            </span>
          )}
        </div>
      </div>
      <div className="flex py-5">
        <div className="w-1/2">
          <ViewOnlyField
            header="Return to Managment"
            className="w-1/3"
            text="text-lg"
          />
          <span className="text-2xl w-1/3">
            {displayFinancials &&
              getPerUnitMath(
                plan?.fixedCost?.returnToMgt,
                plan?.estimatedAreaValue
              )}
          </span>
          <br />
          {displayFinancials && (
            <span className="gr-text-sm">
              <FormattedNumber unit="usCurrency" roundedTo={2}>
                {plan?.fixedCost?.returnToMgt}
              </FormattedNumber>
              /{plan?.estimatedAreaUnit}
            </span>
          )}
        </div>
      </div>
    </>
  );
};

FixedCostSection.defaultProps = {
  displayFinancials: true
};

FixedCostSection.propTypes = {
  displayFinancials: PropTypes.bool,
  plan: PropTypes.shape({
    fixedCost: PropTypes.shape({
      equipment: PropTypes.number,
      insurance: PropTypes.number,
      labor: PropTypes.number,
      landRent: PropTypes.number,
      repairs: PropTypes.number,
      returnToMgt: PropTypes.number,
      taxes: PropTypes.number
    }).isRequired,
    estimatedAreaValue: PropTypes.number.isRequired,
    estimatedAreaUnit: PropTypes.string.isRequired
  }).isRequired
};

export default FixedCostSection;
