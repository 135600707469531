import React from 'react';
import PropTypes from 'prop-types';

export default function MaxTagPlaceholder({ omittedValues }) {
  return (
    <div className="flex h-full items-center px-1 pl-2  rounded border border-neutral-100 bg-neutral-100">
      <div className="text-neutral-60 font-body text-xs font-semibold">
        {`+ ${omittedValues?.length} more`}
      </div>
    </div>
  );
}

MaxTagPlaceholder.defaultProps = {
  omittedValues: []
};

MaxTagPlaceholder.propTypes = {
  omittedValues: PropTypes.arrayOf(PropTypes.string)
};
