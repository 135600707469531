const getUUIDFromString = stringToGetUUIDFrom => {
  const uuidRegex = /[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12}/g;
  const uuid = stringToGetUUIDFrom.match(uuidRegex);

  if (Array.isArray(uuid)) {
    return uuid[0];
  }

  return uuid;
};

export default getUUIDFromString;
