import { CHECKSTATE, NEXT_CHECKSTATE } from 'helpers/checkboxHelpers';

const reduceNestedItems = (items, { id }, nestedItems) => {
  const isUndef = typeof items[id] === 'undefined';
  let newItems = isUndef ? { [id]: CHECKSTATE.unchecked } : { [id]: items[id] };
  if (nestedItems) {
    nestedItems.forEach(el => {
      newItems = {
        ...newItems,
        ...reduceNestedItems(items, el, el.$nestedItems)
      };
    });
  }
  return newItems;
};

export const createParentChildMap = (items, parentId = null) => {
  return items.reduce((acc, { id, $nestedItems = [] }) => {
    return {
      ...acc,
      [id]: { parentId, childrenIds: $nestedItems.map(el => el.id) },
      ...($nestedItems.length ? createParentChildMap($nestedItems, id) : null)
    };
  }, {});
};

/**
 * Takes an array of land sorted heirachically by farms and returns a matching row selection map organized by ids
 * @param {Array} landArr - an array of land. The same passed to Table in FieldTable
 * @param {Object} prevLandMap - will preserve previous selected values, if landArr changes.
 */
export const createLandSelectionMap = (landArr, prevLandMap) => {
  return landArr.reduce((acc, el) => {
    return { ...acc, ...reduceNestedItems(acc, el, el.$nestedItems) };
  }, prevLandMap);
};

const descendantReaction = (id, nextState, parentChildMap) => {
  let newSelection = {};
  const { childrenIds } = parentChildMap[id];

  if (childrenIds.length)
    newSelection = childrenIds.reduce(
      (map, childId) => ({
        ...map,
        [childId]: nextState,
        ...descendantReaction(childId, nextState, parentChildMap)
      }),
      {}
    );

  return newSelection;
};

export const isChecked = val => val === CHECKSTATE.checked;
export const isUnChecked = val => val === CHECKSTATE.unchecked;
export const isIndeterm = val => val === CHECKSTATE.indeterminate;

const childrenChecked = (id, parentId, parentChildMap, selMap) => {
  const { childrenIds } = parentChildMap[parentId];
  const siblingIds = childrenIds.filter(childId => childId !== id);
  return siblingIds.length > 0
    ? siblingIds.every(childId => isChecked(selMap[childId]))
    : true;
};

const childrenUnchecked = (id, parentId, parentChildMap, selMap) => {
  const { childrenIds } = parentChildMap[parentId];
  const siblingIds = childrenIds.filter(childId => childId !== id);
  return siblingIds.length > 0
    ? siblingIds.every(childId => isUnChecked(selMap[childId]))
    : true;
};

/**
 * NOTE: The nextState is not passed into any of these functions, because it is always
 * known what the next state will be based on the current state of a checkbox.
 * Refer to NEXT_CHECKSTATE map at the top for more details.
 * These reactions functions are calculating the nextState off of the known state.
 * the exception to this is indeterminate. It is not possible to determine the next state of an
 * indeterminate value, so an internal variable is used to provide this.
 *
 * @param {*} id
 * @param {*} state
 * @param {*} pcMap
 * @param {*} selMap
 * @param {*} _nextIndState - internal parameter used for the next state of indeterminate values
 *
 * */
const ancestorReaction = (id, state, pcMap, selMap, _nextIndState) => {
  let nextIndState;
  let newSelection = {};
  // some simple helpers
  const allChildrenUnchecked = pId => childrenUnchecked(id, pId, pcMap, selMap);
  const allChildrenChecked = pId => childrenChecked(id, pId, pcMap, selMap);
  const checkIf = val => (val ? CHECKSTATE.checked : CHECKSTATE.indeterminate);
  const uncheckIf = val =>
    val ? CHECKSTATE.unchecked : CHECKSTATE.indeterminate;

  const { parentId } = pcMap[id];
  if (parentId) {
    const parentState = selMap[parentId];
    let nextParentState = CHECKSTATE.indeterminate;

    // checked - next state is unchecked or indeterminate
    if (isChecked(state) && !isIndeterm(_nextIndState))
      nextParentState = uncheckIf(allChildrenUnchecked(parentId));
    // unchecked - next state is checked or indeterminate
    else if (isUnChecked(state) && !isIndeterm(_nextIndState))
      nextParentState = checkIf(allChildrenChecked(parentId));
    // indeterminate -  next state is unknown
    else
      nextParentState = {
        [CHECKSTATE.checked]: () => checkIf(allChildrenChecked(parentId)),
        [CHECKSTATE.unchecked]: () => uncheckIf(allChildrenUnchecked(parentId)),
        [CHECKSTATE.indeterminate]: () => CHECKSTATE.indeterminate
      }[_nextIndState || CHECKSTATE.indeterminate]();

    // NOTE:
    // `isIndeterm(nextParentState)` is used because if the nextParentState is becoming indeterminate
    // it means all of the ancestors all the way up will inherit the indeterminate state.
    // I admit the code looks weird, but without it, there is a bug where a ancestors won't
    // properly propagate indeterminate state, up the tree.
    if (isIndeterm(parentState) || isIndeterm(nextParentState))
      nextIndState = nextParentState;

    newSelection = {
      [parentId]: nextParentState,
      ...ancestorReaction(parentId, parentState, pcMap, selMap, nextIndState)
    };
  }

  return newSelection;
};

export const selectionReaction = (id, selectedLandMap, parentChildMap) => {
  const currState = selectedLandMap[id];
  return {
    ...ancestorReaction(id, currState, parentChildMap, selectedLandMap),
    ...descendantReaction(id, NEXT_CHECKSTATE[currState], parentChildMap)
  };
};
