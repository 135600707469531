import React, { createContext, useMemo } from 'react';
import { useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';
import { paths } from 'routes/paths';

const SaleLocationsContext = createContext();

const SaleLocationsProvider = ({ children }) => {
  const history = useHistory();

  const handleCreate = () => {
    history.push(`${paths.yieldV2}/sale-locations/create`);
  };

  const pro = useMemo(
    () => ({
      handleCreate
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  return (
    <SaleLocationsContext.Provider value={pro}>
      {children}
    </SaleLocationsContext.Provider>
  );
};

SaleLocationsProvider.defaultProps = {
  children: null
};

SaleLocationsProvider.propTypes = {
  children: PropTypes.node
};

export { SaleLocationsContext, SaleLocationsProvider };
