import React from 'react';
import { Select } from '@agconnections/grow-ui';
import PropTypes from 'prop-types';

const ProductListTypeFilter = ({
  typeFilters,
  width,
  handleFilterChange,
  typeFilterOptions
}) => (
  <div className={`w-${width} font-medium max-h-8 whitespace-nowrap`}>
    <Select
      multiple
      id="ProductTypeSelector"
      value={typeFilters.join(',')}
      placeholder="Filter by type"
      items={typeFilterOptions}
      onChange={handleFilterChange}
    />
  </div>
);

ProductListTypeFilter.propTypes = {
  typeFilters: PropTypes.arrayOf(PropTypes.object).isRequired,
  typeFilterOptions: PropTypes.arrayOf(PropTypes.object).isRequired,
  handleFilterChange: PropTypes.func.isRequired,
  width: PropTypes.string.isRequired
};
export default ProductListTypeFilter;
