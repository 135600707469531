import React from 'react';
import PropTypes from 'prop-types';
import PortalModal from 'components/Modals/PortalModal';
import { withRouter } from 'react-router-dom';

const DeleteEntryModal = ({
  open,
  close,
  yieldEntryId,
  handleDeleteConfirmation,
  title,
  confirmLabel,
  isSaving
}) => {
  const handleCancel = () => {
    close();
  };

  return (
    <PortalModal
      open={open}
      title={title}
      close={handleCancel}
      cancelLabel="Cancel"
      confirmLabel={confirmLabel}
      onCancel={() => handleCancel()}
      type="error"
      onConfirm={() => handleDeleteConfirmation(yieldEntryId)}
      isSaving={isSaving}
    >
      <div testId="yield-entry-deleteModal" className="pr-6">
        Are you sure you want to delete this yield data? All of the data
        associated with this entry will be lost.
      </div>
    </PortalModal>
  );
};

DeleteEntryModal.defaultProps = {
  yieldEntryId: '',
  handleDeleteConfirmation: () => {},
  isSaving: false,
  title: '',
  confirmLabel: ''
};

DeleteEntryModal.propTypes = {
  title: PropTypes.string,
  confirmLabel: PropTypes.string,
  history: PropTypes.shape({
    push: PropTypes.func
  }).isRequired,
  open: PropTypes.oneOfType([PropTypes.func, PropTypes.bool]).isRequired,
  close: PropTypes.oneOfType([PropTypes.func, PropTypes.bool]).isRequired,
  yieldEntryId: PropTypes.string,
  handleDeleteConfirmation: PropTypes.func,
  isSaving: PropTypes.bool
};

export default withRouter(DeleteEntryModal);
