import React from 'react';
import PropTypes from 'prop-types';

import { Input, Form } from '@agconnections/grow-ui';

// eslint-disable-next-line react/prop-types
function FullNameField({ fullName, onChange, onBlur, errors }) {
  return (
    <Form.Field>
      <div className="block">
        <div className="w-full">
          <div className="block text-sm font-medium leading-5 gr-text-gray-600 sm:mt-px sm:pt-2">
            Name*
          </div>
        </div>
        <div className="w-full">
          <Input
            id="full-name-input"
            onChange={onChange}
            onBlur={onBlur}
            defaultValue={fullName}
            name="fullName"
            error={errors}
            errorMessage={errors}
          />
        </div>
      </div>
    </Form.Field>
  );
}

FullNameField.defaultProps = {
  fullName: '',
  errors: null
};

FullNameField.propTypes = {
  fullName: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  errors: PropTypes.string
};

export default FullNameField;
