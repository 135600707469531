import React, { useState, useEffect, useContext } from 'react';
import PropTypes from 'prop-types';
import { ContextMenu } from '@agconnections/grow-ui';
import { Context } from 'components/Store';
import { hasAccess } from '../../utilities/access';

function EllipseMenu({ onAction: handleAction, options }) {
  const [allowedOptions, setAllowedOptions] = useState([]);
  const [{ loggedInUserOrgPermission }] = useContext(Context);
  const { role, financialAccess } = loggedInUserOrgPermission;

  useEffect(() => {
    const newOptions = options.filter(
      ({ minAllowedRole, minFinancialAccess }) =>
        hasAccess(
          { role, financialAccess },
          { minAllowedRole, minFinancialAccess }
        )
    );
    setAllowedOptions(newOptions);
  }, [options, role, financialAccess]);

  return (
    <>
      {allowedOptions.length > 0 && (
        <ContextMenu onSelect={handleAction}>
          {allowedOptions.map(({ value, className, text, label }) => {
            return (
              <ContextMenu.Option key={value} value={value} label={label}>
                <span className={className}>{text}</span>
              </ContextMenu.Option>
            );
          })}
        </ContextMenu>
      )}
    </>
  );
}

EllipseMenu.propTypes = {
  onAction: PropTypes.func.isRequired,
  options: PropTypes.arrayOf(PropTypes.object).isRequired
};

export default EllipseMenu;
