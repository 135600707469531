/* eslint-disable jsx-a11y/label-has-associated-control */
import React from 'react';
import PropTypes from 'prop-types';
import { Input } from 'syngenta-digital-cropwise-react-ui-kit';
import useWindowDimensions from 'hooks/useWindowDimensions';
import SearchIcon from 'assets/search.svg';

const SearchInputLandingProperties = ({
  onBlur,
  onChange,
  onFocus,
  placeholder,
  value,
  drawer
}) => {
  const { width } = useWindowDimensions();
  return (
    <div
      data-testid="SearchInputLandingProperties"
      className={!drawer ? 'pr-2' : ''}
    >
      <Input
        data-testid="search-input"
        style={{ margin: 0 }}
        prefix={<img alt="Search icon" src={SearchIcon} />}
        allowClear
        type="default"
        autoComplete="off"
        placeholder={width <= 386 ? 'Search...' : placeholder}
        onChange={onChange}
        {...(onFocus ? { onFocus } : {})} // eslint-disable-line react/jsx-props-no-spreading
        {...(onBlur ? { onBlur } : {})} // eslint-disable-line react/jsx-props-no-spreading
        value={value}
      />
    </div>
  );
};

SearchInputLandingProperties.defaultProps = {
  placeholder: '',
  value: '',
  drawer: false,
  onBlur: undefined,
  onFocus: undefined
};

SearchInputLandingProperties.propTypes = {
  drawer: PropTypes.bool,
  placeholder: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  onFocus: PropTypes.func,
  onBlur: PropTypes.func,
  value: PropTypes.string
};

export default SearchInputLandingProperties;
