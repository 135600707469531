import React, { useContext, useEffect } from 'react';
import PropTypes from 'prop-types';
import analysisIcon from 'assets/analysis.svg';
import { Spinner } from '@agconnections/grow-ui';
import { Alert } from 'syngenta-digital-cropwise-react-ui-kit';
import {
  PROPERTY_TYPE_CROP_ZONE,
  PROPERTY_TYPE_FIELD
} from 'screens/Property/helpers/constants';
import { Context } from 'components/Store';
import PropertyLandingContext from 'screens/Property/PropertiesLanding/context';
import CostItemRow from '../CostItemRow';
import calculateNetRevenue from '../../helpers/calculateNetRevenue';
import useCostAnalysis from '../../hooks/useCostAnalysis';
import './index.css';

const ContributionMargin = ({ selectedCrop }) => {
  const [{ selectedProperty }] = useContext(Context);
  const { isFieldSelected } = useContext(PropertyLandingContext);
  const { contributionMargin, getContributionMargin } = useCostAnalysis();
  const { data, isLoading } = contributionMargin;

  const selectedCropSeasons =
    JSON.parse(localStorage.getItem('selectedCropSeasons')) || [];
  const multiSeasonsSelected = selectedCropSeasons.length > 1;

  useEffect(() => {
    const level = isFieldSelected
      ? PROPERTY_TYPE_FIELD
      : PROPERTY_TYPE_CROP_ZONE;
    getContributionMargin({
      resourceId: selectedProperty.id,
      cropId: selectedCrop,
      level,
      seasonIds: selectedCropSeasons
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedProperty, selectedCrop]);

  return (
    <div className="w-full box-border">
      <div className="flex-1 rounded-lg p-4 m-2 bg-white rounded shadow-lg">
        <div className="flex pb-4 items-center">
          <div
            style={{ width: '40px', height: '40px' }}
            className="rounded bg-neutral-100 flex items-center justify-center"
          >
            <img alt="analysisIcon" src={analysisIcon} />
          </div>
          <div style={{ marginLeft: '12px' }} className="flex-1">
            <h1 className="text-base font-semibold">Contribution Margin</h1>
            <p className="text-xs">
              An overview of your profits for the selected crop season
            </p>
          </div>
        </div>

        {isLoading ? (
          <Spinner />
        ) : (
          <>
            <div className="flex text-sm flex-1 bg-neutral-100 py-2 px-4">
              <div className="flex-1" />
              <div className="flex-1 flex justify-end gap-x-1 items-end">
                <span className="text-xs text-neutral-600 font-semibold">
                  Per Acre
                </span>
              </div>
              <div className="flex-1 flex justify-end gap-x-1 items-end">
                <span className="text-xs text-neutral-600 font-semibold">
                  Total
                </span>
              </div>
            </div>
            <CostItemRow
              title="Yield Revenue"
              perAcre={data?.revenue?.avgRevenuePerAcre}
              totalValue={data?.revenue?.totalRevenue}
              tooltip="Revenue is the sum of all yield loads delivered to sale locations."
            />
            <CostItemRow
              title="Input Costs"
              perAcre={data?.cost?.costPerAcre ? -data?.cost?.costPerAcre : 0}
              totalValue={data?.cost?.totalCost ? -data?.cost?.totalCost : 0}
              tooltip="Input Costs are the sum of all Application costs."
            />
            <CostItemRow
              isResult
              title="Net Revenue"
              perAcre={calculateNetRevenue(
                data?.revenue?.avgRevenuePerAcre,
                data?.cost?.costPerAcre
              )}
              totalValue={calculateNetRevenue(
                data?.revenue?.totalRevenue,
                data?.cost?.totalCost
              )}
            />

            {multiSeasonsSelected && isFieldSelected && (
              <div className="mt-4 text-subtext-sm">
                <Alert
                  showIcon
                  description="Contribution Margin calculation is based on all selected crop seasons."
                  message="Multiple active crop seasons are selected"
                  type="warning"
                />
              </div>
            )}
          </>
        )}
      </div>
    </div>
  );
};

ContributionMargin.defaultProps = {
  selectedCrop: null
};

ContributionMargin.propTypes = {
  selectedCrop: PropTypes.string
};

export default ContributionMargin;
