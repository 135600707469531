import React, { useContext } from 'react';
import { StorageLocationContext } from 'screens/YieldV2/StorageLocations/context/StorageLocationProvider';
import CardInfo from 'components/CardInfo';
import CardWrapper from 'components/CardWrapper';
import formatStorageLocationAddress from 'screens/YieldV2/StorageLocations/helpers/formatStorageLocationAddress';

const StorageContactDetails = () => {
  const {
    state: { selectedId, data = [] }
  } = useContext(StorageLocationContext);
  const selected = data.find(item => item.id === selectedId);
  return (
    <CardWrapper className="py-4 px-8">
      <CardInfo
        labelColWidth="w-2/5"
        valueColWidth="w-3/5"
        label="Email Address"
        value={selected?.email}
      />
      <CardInfo
        labelColWidth="w-2/5"
        valueColWidth="w-3/5"
        label="Phone Number"
        value={selected?.phone}
      />
      <CardInfo
        labelColWidth="w-2/5"
        valueColWidth="w-3/5"
        label="Address"
        value={formatStorageLocationAddress(selected)}
      />
    </CardWrapper>
  );
};

export default StorageContactDetails;
