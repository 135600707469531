import { cloneDeep, orderBy } from 'lodash';

export default (cropSeasons, field, direction) => {
  if (!direction) return cropSeasons;

  const _cropSeasons = cloneDeep(cropSeasons);

  switch (field) {
    case 'name': {
      return orderBy(
        _cropSeasons,
        [cropSeason => cropSeason.name.toLowerCase()],
        direction
      );
    }

    case 'active':
      return orderBy(
        _cropSeasons,
        [cropSeason => cropSeason.active],
        direction
      );

    case 'crop':
      return orderBy(
        _cropSeasons,
        [cropSeason => cropSeason.crops?.map(item => item.name)],
        direction
      );

    case 'totalArea':
      return orderBy(
        _cropSeasons,
        [cropSeason => cropSeason.totalArea?.totalArea],
        direction
      );

    case 'startDate':
      return orderBy(
        _cropSeasons,
        [cropSeason => cropSeason.startDateEpoch],
        direction
      );

    case 'endDate':
      return orderBy(
        _cropSeasons,
        [cropSeason => cropSeason.endDateEpoch],
        direction
      );

    case 'createdAt':
      return orderBy(
        _cropSeasons,
        [cropSeason => cropSeason.createdDateEpoch],
        direction
      );

    default:
      return _cropSeasons;
  }
};
