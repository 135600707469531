import { useContext, useEffect, useState } from 'react';
import axios from 'axios';
import { getAccessToken } from 'utilities/base-auth';
import catchCancel from 'helpers/catchCancel';
import { parseServerError } from 'helpers/errorHelpers';
import { Context } from 'components/Store';
import { MASTERLIST_PROXY_URL } from 'utilities/apiConstants';
import alphanumSort from 'helpers/sorters';

const useProductData = () => {
  const [products, setProducts] = useState();
  const [loading, setLoading] = useState(true);

  const [, dispatch] = useContext(Context);
  useEffect(() => {
    axios
      .get(`${MASTERLIST_PROXY_URL}/products/search?productName=`, {
        headers: {
          common: {
            Authorization: `Bearer ${getAccessToken()}`
          }
        }
      })
      .then(response => {
        const sortedArray = alphanumSort(
          response.data.items.filter(eachData => eachData.status === 'Active')
        ).slice(0, 100);
        setProducts(sortedArray);
        setLoading(false);
      })
      .catch(catchCancel)
      .catch(err => {
        parseServerError(dispatch)(err);
        setLoading(false);
      });
  }, [dispatch]);
  return { products, loading };
};
export default useProductData;
