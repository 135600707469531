import applyFetchFilters from '../utils/applyFetchFilters';
import { createRouteHandlers } from './helpers/apiResourceBuilder';
import filterInactive from './helpers/filterInactive';

export default server => {
  createRouteHandlers(server, 'users', { orgRoutes: false });

  // override get route
  server.get('/users', (schema, { queryParams }) => {
    const { default: _default, organizationId } = queryParams;

    if (organizationId) return schema.users.where({ organizationId });
    const users = filterInactive(schema.users.all(), _default);

    return applyFetchFilters(users, queryParams);
  });
};
