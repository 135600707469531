import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { Button } from '@agconnections/grow-ui';
import { YieldContext } from 'screens/Yield/context/YieldProvider';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronLeft } from '@fortawesome/free-solid-svg-icons';
import { useHistory, useLocation } from 'react-router-dom';
import { currentFormattedDate } from 'screens/Yield/helpers/infoSectionData';
import { useFormikContext } from 'formik';
import useYieldData from 'screens/Yield/hooks/useYieldData';
import buildYieldPayload from '../../helpers/buildYieldPayload';

const FooterButtons = ({ nextButtonDisabled, onNext, onBack }) => {
  const { values, setFieldValue } = useFormikContext();
  const history = useHistory();
  const location = useLocation();
  const { saveYield } = useYieldData();

  const isEdit = location.pathname.split('/').slice(-1)[0] === 'edit';

  const handleSaveYield = () => {
    const regex = /^\d{2}-\d{2}-\d{2} \d{1,}:\d{1,} (AM|PM)$/;
    const str = values?.entryName;
    const isMatch = regex.test(str);
    const payload = isMatch
      ? { ...values, entryName: currentFormattedDate() }
      : values;
    const body = buildYieldPayload(payload);
    return saveYield(body, isEdit);
  };
  const {
    state: { step },
    goForward,
    goBack,
    setResponseToastMessage
  } = useContext(YieldContext);

  const resetValues = () => {
    setFieldValue('avgYieldPerAcre', 0);
    setFieldValue('entryName', '');
    setFieldValue('grossRevenue', 0);
    setFieldValue('salePrice', '0');
    setFieldValue('totalYield', '0');
  };
  const handleNext = () => {
    onNext();
    goForward();
  };

  const handleBack = () => {
    onBack();
    goBack();
  };

  const handleCancel = () => {
    history.goBack();
  };

  const handleSaveAndAddAnother = async () => {
    const isSaved = await handleSaveYield();
    if (isSaved) {
      setResponseToastMessage({
        type: 'success',
        message: 'Yield data saved.',
        isToastActive: true
      });
      resetValues();
      handleBack();
      return;
    }

    setResponseToastMessage({
      type: 'error',
      message: "Yield didn't save. Please try later.",
      isToastActive: true
    });
  };

  const handleSave = async () => {
    const isSaved = await handleSaveYield();
    if (isSaved) {
      history.goBack();
      return;
    }

    setResponseToastMessage({
      type: 'error',
      message: "Yield didn't save. Please try later.",
      isToastActive: true
    });
  };

  const renderFooter = () => {
    switch (step) {
      case 1:
        return (
          <div
            className="flex flex-row border-t border-neutral-20 py-6 justify-end space-x-4"
            data-testid="yield-properties-footer"
          >
            <Button type="outline" onClick={handleCancel}>
              Cancel
            </Button>
            <Button
              type="primary"
              onClick={handleNext}
              disabled={nextButtonDisabled}
            >
              Next
            </Button>
          </div>
        );
      case 2:
        return (
          <div
            className="flex flex-row border-t border-neutral-20 bg-white justify-between py-6"
            data-testid="yield-information-entry-footer"
          >
            <Button
              type="outline"
              onClick={handleBack}
              icon={
                <FontAwesomeIcon fixedWidth size="1x" icon={faChevronLeft} />
              }
            >
              Back
            </Button>
            <div className="flex flex-row gap-6">
              <Button type="outline" onClick={handleCancel}>
                Cancel
              </Button>

              {!isEdit && (
                <Button
                  type="primary"
                  ghost
                  onClick={handleSaveAndAddAnother}
                  disabled={nextButtonDisabled}
                >
                  Save & Add Another
                </Button>
              )}
              <Button
                type="primary"
                onClick={handleSave}
                disabled={nextButtonDisabled}
              >
                Save
              </Button>
            </div>
          </div>
        );

      default:
        return null;
    }
  };

  return renderFooter();
};

FooterButtons.propTypes = {
  nextButtonDisabled: PropTypes.bool,
  onNext: PropTypes.func,
  onBack: PropTypes.func,
  onNextValidation: PropTypes.func
};

FooterButtons.defaultProps = {
  nextButtonDisabled: true,
  onNext: () => {},
  onBack: () => {},
  onNextValidation: () => true
};

export default FooterButtons;
