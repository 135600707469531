import React from 'react';
import PropTypes from 'prop-types';

import { Input, Form } from '@agconnections/grow-ui';

function EmailAddressField({ emailAddress, onChange, errors }) {
  return (
    <Form.Field>
      <div className="block mr-6">
        <div className="w-full">
          <div className="block text-sm font-medium leading-5 gr-text-gray-600 sm:mt-px sm:pt-2">
            Email Address
          </div>
        </div>
        <div className="w-auto">
          <Input
            id="email-address-input"
            onChange={onChange}
            defaultValue={emailAddress}
            name="emailAddress"
            error={errors}
            errorMessage={errors}
          />
        </div>
      </div>
    </Form.Field>
  );
}

EmailAddressField.defaultProps = {
  emailAddress: '',
  errors: null
};

EmailAddressField.propTypes = {
  emailAddress: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  errors: PropTypes.string
};

export default EmailAddressField;
