/* eslint-disable no-unused-expressions */
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { cloneDeep } from 'lodash';
import { Input, Tag, Button, Form, Spinner } from '@agconnections/grow-ui';
import { useFormikContext } from 'formik';
import GrowUIFormField from 'components/Forms/GrowUIFormField';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck, faPen } from '@fortawesome/free-solid-svg-icons';

const TagsLanding = ({
  name,
  tags,
  notFoundMessage,
  showCreate,
  editTag,
  filterTags,
  selectedTags,
  setSelectedTagIds,
  isLoading,
  selectButtonClick,
  setFieldTouchedParent
}) => {
  const [selectedArray, setSelectedArray] = useState([]);
  const { setFieldValue } = useFormikContext();
  const addRemoveSelected = id => {
    const currentSelected = cloneDeep(selectedArray);
    if (selectedArray.includes(id)) {
      currentSelected.splice(currentSelected.indexOf(id), 1);
    } else {
      currentSelected.push(id);
    }
    setSelectedArray(currentSelected);
    setSelectedTagIds(currentSelected);
  };

  const searchFieldChange = searchValue => {
    setFieldValue('tagSearch', searchValue);
    filterTags(searchValue);
  };

  useEffect(() => {
    setSelectedArray(selectedTags || []);
  }, [selectedTags, setSelectedArray]);

  return (
    <>
      <Form>
        <div className="text-xl align-middle font-bold mb-2">Tags</div>
        <div data-testid="task-name" className="text-lg">
          {name}
        </div>
        <div className="mb-8">
          <Form.Field>
            <GrowUIFormField
              control={Input}
              placeholder="Search Tags"
              name="tagSearch"
              id="tagSearch"
              items={[]}
              onChange={e => {
                searchFieldChange(e.target.value);
              }}
              onKeyPress={e => {
                if (e.key === 'Enter') {
                  e.preventDefault();
                }
              }}
            />
          </Form.Field>
        </div>
        <div className="mb-2">Tags</div>
        {isLoading ? (
          <Spinner />
        ) : (
          <ul>
            {tags && tags?.length > 0
              ? tags?.map(tag => {
                  const isSelected = selectedArray?.includes(tag.id);
                  const tagColor = tag.color !== '' ? tag.color : null;
                  return (
                    <li
                      data-testid="custom-tag"
                      className="flex mb-1"
                      key={tag.id}
                    >
                      <Tag
                        data-testid="custom-tag"
                        key={tag.id}
                        id={tag.id}
                        color={tagColor}
                        onClick={() => addRemoveSelected(tag.id)}
                      >
                        <span>{tag.name}</span>
                        <FontAwesomeIcon
                          data-testid="tag-is-marked"
                          icon={faCheck}
                          size="1x"
                          style={{
                            visibility: isSelected ? 'visible' : 'hidden',
                            color: '#FFFFFF'
                          }}
                        />
                      </Tag>
                      <Button
                        data-testid="edit-button"
                        type="cancel"
                        onClick={() => editTag(tag)}
                      >
                        <FontAwesomeIcon
                          icon={faPen}
                          size="1x"
                          style={{
                            visibility: 'visible'
                          }}
                        />
                      </Button>
                    </li>
                  );
                })
              : notFoundMessage}
          </ul>
        )}
        <div className="mt-4 mb-4 border" />
        <div data-testid="create-tag-button" className="w-full">
          <Button
            className="w-full"
            style={{ width: '100%' }}
            id="add-tag-button"
            type="primary"
            onClick={() => showCreate()}
          >
            Create a new tag
          </Button>
        </div>
        <div data-testid="select-tags-button" className="w-full mt-4">
          <Button
            className="w-full"
            style={{ width: '100%' }}
            id="add-tag-button"
            type="primary"
            onClick={() => {
              setFieldTouchedParent('Tags');
              selectButtonClick();
            }}
            disabled={selectedArray?.length === 0}
          >
            Select tag(s)
          </Button>
        </div>
      </Form>
    </>
  );
};
TagsLanding.defaultProps = {
  setFieldTouchedParent: () => {}
};
TagsLanding.propTypes = {
  name: PropTypes.string.isRequired,
  tags: PropTypes.arrayOf(PropTypes.object).isRequired,
  notFoundMessage: PropTypes.string.isRequired,
  showCreate: PropTypes.func.isRequired,
  editTag: PropTypes.func.isRequired,
  filterTags: PropTypes.func.isRequired,
  selectedTags: PropTypes.arrayOf(PropTypes.string).isRequired,
  setSelectedTagIds: PropTypes.func.isRequired,
  isLoading: PropTypes.bool.isRequired,
  selectButtonClick: PropTypes.func.isRequired,
  setFieldTouchedParent: PropTypes.func
};

export default TagsLanding;
