import React, { useContext, useState } from 'react';
import PropTypes from 'prop-types';
import PortalModal from 'components/Modals/PortalModal';
import { withRouter, useHistory } from 'react-router-dom';
import usePlansApi from 'hooks/usePlansApi';
import catchCancel from 'helpers/catchCancel';
import { Context } from 'components/Store';
import { parseServerError } from 'helpers/errorHelpers';
import { paths } from 'routes/paths';

const DeletePlanModal = ({ open, close, planName, planId }) => {
  const plansApi = usePlansApi('plan');
  const history = useHistory();
  const [isSaving, setIsSaving] = useState(false);
  const [, dispatch] = useContext(Context);
  const handleDelete = async () => {
    setIsSaving(true);
    const { promise } = plansApi.delete(planId);

    await promise // not certain why but from view plans page go is needed and from list or tile view push is needed. This is an ugly hack but it fixed the issue of the modal staying open.
      .then(() => {
        history.push({
          pathname: paths.plans
        });
        history.go({
          pathname: paths.plans
        });
      })
      .catch(catchCancel)
      .catch(err => {
        setIsSaving(false);
        parseServerError(dispatch)(err);
      });
  };

  const handleCancel = () => {
    close();
  };

  const actionPerformed = 'Delete "';
  const closeQuote = '"';

  return (
    <PortalModal
      open={open}
      title={actionPerformed + planName + closeQuote}
      close={close}
      cancelLabel="Cancel"
      confirmLabel="Delete"
      onCancel={() => handleCancel()}
      type="error"
      onConfirm={() => handleDelete()}
      isSaving={isSaving}
    >
      <div className="pr-6">
        You are about to permanently delete this plan. This cannot be undone.
        Are you sure you want to proceed?
      </div>
    </PortalModal>
  );
};

DeletePlanModal.defaultProps = {
  planName: '',
  planId: ''
};

DeletePlanModal.propTypes = {
  open: PropTypes.oneOfType([PropTypes.func, PropTypes.bool]).isRequired,
  close: PropTypes.func.isRequired,
  planName: PropTypes.string,
  planId: PropTypes.string
};

export default withRouter(DeletePlanModal);
