import { useReducer, useCallback } from 'react';

import useTwoWayState from 'hooks/useTwoWayState';
import { idsMatch } from '../helpers/propertyDataHelpers';
import {
  mapHistoryReducer,
  resetMapHistory,
  MAP_HISTORY_SET_NEW_FEATURE,
  MAP_HISTORY_SET_FEATURE_PROCESSED
} from '../helpers/mapHistoryReducer';

const useMapData = () => {
  const comparator = useCallback(idsMatch, []);
  const [
    selectedShapeIds,
    setSelectedShapeIds,
    setSelectedShapesExternally,
    internalShapeSelectionChanges
  ] = useTwoWayState([], comparator);
  const [{ mapChangeEvent, newFeatures }, mapHistoryDispatch] = useReducer(
    mapHistoryReducer,
    resetMapHistory(),
    resetMapHistory
  );

  /**
   * add a feature to the mapHistory newFeatures to be added to the map
   * @param featureType - one of the values in MAP_HISTORY_FEATURE_TYPES
   * @param feature - the feature to be added to the map
   * @param featureAttributes - attributes required by the specified featureType
   */
  const addNewMapFeature = useCallback(
    (featureType, feature, featureAttributes) => {
      mapHistoryDispatch({
        type: MAP_HISTORY_SET_NEW_FEATURE,
        payload: {
          featureType,
          feature,
          featureAttributes
        }
      });
    },
    []
  );

  /**
   * add multiple feature to the mapHistory newFeatures to be added to the map
   * @param featureType - one of the values in MAP_HISTORY_FEATURE_TYPES
   * @param features - the features to be added to the map
   * @param featureAttributes - attributes required by the specified featureType
   */
  const addNewMapFeatures = useCallback((featureType, features) => {
    mapHistoryDispatch({
      type: MAP_HISTORY_SET_NEW_FEATURE,
      payload: features.map(({ feature, featureAttributes }) => ({
        featureType,
        feature,
        featureAttributes
      }))
    });
  }, []);

  /**
   * move a feature from the mapHistory newFeatures to archivedFeatures
   * @param mapHistoryFeature
   */
  const archiveMapHistoryFeature = mapHistoryFeature => {
    mapHistoryDispatch({
      type: MAP_HISTORY_SET_FEATURE_PROCESSED,
      payload: {
        event: mapHistoryFeature.mapChangeEvent
      }
    });
  };

  return {
    selectedShapeIds,
    setSelectedShapeIds,
    setSelectedShapesExternally,
    internalShapeSelectionChanges,
    mapChangeEvent,
    newFeatures,
    addNewMapFeature,
    addNewMapFeatures,
    archiveMapHistoryFeature
  };
};

export default useMapData;
