import { TEMPLATE_TYPE } from 'screens/YieldV2/constants';
import _ from 'lodash';
import { tabsLabels } from './constants';

const validateTemplate = (_tabs, templateType, load) => {
  const cloneTabs = _.cloneDeep(_tabs);

  switch (templateType) {
    case TEMPLATE_TYPE.ROW_CROP: {
      if (!load?.shrink) {
        delete cloneTabs[tabsLabels.yieldDetails];
      }
      delete cloneTabs[tabsLabels.counts];
      break;
    }
    case TEMPLATE_TYPE.SPECIALTY: {
      delete cloneTabs[tabsLabels.yieldDetails];
      delete cloneTabs[tabsLabels.counts];
      break;
    }
    default:
      delete cloneTabs[tabsLabels.yieldDetails];
      delete cloneTabs[tabsLabels.loadAttributes];
      delete cloneTabs[tabsLabels.counts];
      break;
  }
  return cloneTabs;
};

export default validateTemplate;
