export const ASC_SORT = 'asc';
export const DESC_SORT = 'desc';

const toggleSort = value => {
  if (!value) {
    return ASC_SORT;
  }
  if (value === ASC_SORT) {
    return DESC_SORT;
  }

  return null;
};

export const pickDir = currentDir =>
  currentDir ? toggleSort(currentDir) : ASC_SORT;

export const getFieldSortDir = (field, sortBy, sortDir) =>
  field === sortBy ? sortDir : null;
