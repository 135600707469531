import React from 'react';

const SavePDFIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="12"
      height="14"
      viewBox="0 0 12 14"
      fill="none"
      className="fill-current w-3 h-3 mr-2"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.25 0.625H3.75V5.125H0.75L6 10.375L11.25 5.125H8.25V0.625ZM6.75006 2.125V6.625H7.62756L6.00006 8.2525L4.37256 6.625H5.25006V2.125H6.75006ZM0.75 13.375V11.875H11.25V13.375H0.75Z"
        fill="#14803C"
      />
    </svg>
  );
};

export default SavePDFIcon;
