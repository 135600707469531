/* eslint-disable import/prefer-default-export */

export const applicationOptions = [
  {
    key: 'ByRatePerArea',
    value: 'ratePerAreaValue',
    unit: 'ratePerAreaUnit',
    title: 'Rate'
  },
  {
    key: 'ByTotalProduct',
    value: 'totalProductValue',
    unit: 'totalProductUnit',
    title: 'Total Product'
  },
  {
    key: 'ByRatePerTank',
    value: 'ratePerTankValue',
    unit: 'ratePerTankUnit',
    title: 'Rate/Tank'
  }
];
