import React, { useContext, useState } from 'react';
import PropTypes from 'prop-types';
import { organization as organizationApi } from 'utilities/api';
import catchCancel from 'helpers/catchCancel';
import { parseServerError } from 'helpers/errorHelpers';
import { Context } from 'components/Store';
import { useParams } from 'react-router-dom';

import { SimpleModal } from '@agconnections/grow-ui';

const RevokeAccessModal = ({ open, onClose, accountId, getOrganization }) => {
  const [, dispatch] = useContext(Context);
  // eslint-disable-next-line no-unused-vars
  const { id } = useParams();
  const [isSaving, setIsSaving] = useState(false);

  const revokeApi = organizationApi.createChildApi({
    action: `organization/account/${accountId}/revoke`
  });

  const revokeAccess = async () => {
    setIsSaving(true);
    const { promise } = revokeApi.delete(null);
    promise
      .then(() => {
        getOrganization();
        setIsSaving(false);
        onClose();
      })
      .catch(catchCancel)
      .catch(err => {
        parseServerError(dispatch)(err);
        setIsSaving(false);
        onClose();
      });
  };
  return (
    <div data-testid="RevokeAccessModal">
      <SimpleModal
        onConfirm={revokeAccess}
        open={open}
        close={onClose}
        title="Revoke Access"
        type="confirmation"
        isSaving={isSaving}
      >
        <div className="pr-6">
          Are you sure you want to revoke this member&apos;s access?
        </div>
      </SimpleModal>
    </div>
  );
};

RevokeAccessModal.defaultProps = {
  open: false,
  onClose: () => {},
  accountId: '',
  getOrganization: () => {}
};

RevokeAccessModal.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
  accountId: PropTypes.string,
  getOrganization: PropTypes.func
};

export default RevokeAccessModal;
