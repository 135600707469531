import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import ClickableDiv from 'components/ClickableDiv';
import SortIcon from 'assets/sort.svg';
import { Button } from '@agconnections/grow-ui';

const SortButton = ({ sortItems, onSort, orgId, sortRules, setSortRules }) => {
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [selectedItemIndex, setSelectedItemIndex] = useState(0);
  const dropdownRef = useRef(null);
  const openDiv = () => {
    setIsDropdownOpen(!isDropdownOpen);
  };

  const handleSelection = (index, type, direction) => {
    setSelectedItemIndex(index);
    setSortRules({ sortBy: type, sortDir: direction });
    setIsDropdownOpen(false);
  };

  useEffect(() => {
    if (sortRules) onSort(orgId, sortRules);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sortRules]);

  useEffect(() => {
    if (sortRules) {
      setSelectedItemIndex(
        sortItems.findIndex(rule => rule?.sortKey === sortRules?.sortBy)
      );
      onSort(orgId, sortRules);
      return () => {};
    }
    setSortRules({
      sortBy: sortItems[selectedItemIndex].sortKey,
      sortDir: sortItems[selectedItemIndex].sortDirection
    });
    return () => {};
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [orgId]);

  useEffect(() => {
    const handleClickOutside = event => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsDropdownOpen(false);
      }
    };

    document.addEventListener('click', handleClickOutside);

    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, []);

  return (
    <div style={{ width: '45%' }} ref={dropdownRef}>
      <Button onClick={openDiv} style={{ width: '100%' }} type="outline" ghost>
        <div className="flex flex-row relative bg-color">
          <img className="mr-1" src={SortIcon} alt="Sort Icon" />
        </div>
        Sort
      </Button>

      {isDropdownOpen && (
        <div className="w-40 h-35 absolute bg-white z-50 mt-2 shadow-card rounded ">
          <div className="w-full h-full flex flex-col justify-center">
            {sortItems?.map((element, index) => (
              <ClickableDiv
                key={element.sortKey}
                className={`${
                  index === selectedItemIndex ? 'bg-selectedBlue' : ''
                } "h-8 `}
                onClick={() =>
                  handleSelection(index, element.sortKey, element.sortDirection)
                }
              >
                <div
                  className={`w-full h-full p-2 ${index === selectedItemIndex &&
                    'font-semibold'}`}
                >
                  {element.sortLabel}
                </div>
              </ClickableDiv>
            ))}
          </div>
        </div>
      )}
    </div>
  );
};

SortButton.propTypes = {
  sortItems: PropTypes.arrayOf(
    PropTypes.shape({
      sortLabel: PropTypes.string.isRequired,
      sortKey: PropTypes.string.isRequired,
      sortDirection: PropTypes.string.isRequired
    })
  ).isRequired,
  onSort: PropTypes.func.isRequired,
  orgId: PropTypes.string.isRequired,
  sortRules: PropTypes.oneOfType([
    PropTypes.shape({
      sortBy: PropTypes.string,
      sortDir: PropTypes.string
    }),
    PropTypes.oneOf([undefined, null, false])
  ]).isRequired,
  setSortRules: PropTypes.func.isRequired
};

export default SortButton;
