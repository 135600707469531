import React, { useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import ItemsDropdown from 'components/ItemsDropdown';
import useDebounce from 'hooks/useDebounce';
import { SubmissionSummaryContext } from 'screens/Integrations/SubmissionSummary/context/SubmissionSummaryProvider';
import SimplotMaterialsDropdownItem from './components/SimplotMaterialsDropdownItem';

const SimplotMaterialsDropdown = ({ onChange, value, id, cleanUpCallback }) => {
  const [searchText, setSearchText] = useState('');
  const [filteredSelectOptions, setFilteredSelectOptions] = useState([
    {
      key: 'empty',
      label: 'empty',
      type: 'PRODUCT'
    }
  ]);
  const [selectedValue, setSelectedValue] = useState(undefined);
  const timeDelay = 300;
  const debouncedSearchTerm = useDebounce(searchText, timeDelay);

  const { getVendorMaterials, isLoading: loading } = useContext(
    SubmissionSummaryContext
  );

  const convertResponseToItems = response => {
    const materials = response.materials?.filter(cleanUpCallback).map(m => ({
      key: m.id,
      label: m.name,
      type: 'MATERIAL',
      value: { ...m, source: 'MATERIAL' }
    }));

    const commonMaterials = response.commonMaterials
      ?.filter(cleanUpCallback)
      .map(m => ({
        key: m.id,
        label: m.name,
        type: 'COMMON',
        value: { ...m, source: 'COMMON' }
      }));

    return [
      {
        key: 'materials',
        label: 'Materials',
        type: 'MATERIAL',
        items:
          materials.length > 0
            ? materials
            : [
                {
                  key: 'empty-materials',
                  label: 'empty-materials',
                  type: 'MATERIAL'
                }
              ]
      },
      {
        key: 'common',
        label: 'Common Materials',
        type: 'COMMON',
        items:
          commonMaterials.length > 0
            ? commonMaterials
            : [
                {
                  key: 'empty-common',
                  label: 'empty-common',
                  type: 'COMMON'
                }
              ]
      }
    ];
  };

  useEffect(() => {
    if (value !== selectedValue) {
      setSelectedValue(value);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value]);

  useEffect(() => {
    async function fetchMaterials() {
      const response = await getVendorMaterials(debouncedSearchTerm);
      if (response) {
        setFilteredSelectOptions(convertResponseToItems(response.data));
      }
    }
    if (debouncedSearchTerm?.length >= 3) {
      fetchMaterials();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedSearchTerm]);

  const handleFilter = (_, textFilter) => {
    setSearchText(textFilter);
  };

  const handleChange = selectedProduct => {
    setSelectedValue(selectedProduct);
    setSearchText(undefined);
    onChange(selectedProduct);
  };

  const valueRenderer = _value => {
    if (!_value) {
      return '';
    }
    return `${_value.name}`;
  };

  return (
    <ItemsDropdown
      rightOffset={0}
      heightClass="h-64"
      mainClass="items-drop-down-field-1"
      value={selectedValue}
      key={selectedValue}
      onChange={handleChange}
      id={id}
      items={
        loading
          ? [
              {
                key: 'loading',
                label: 'loading',
                type: 'PRODUCT'
              }
            ]
          : filteredSelectOptions
      }
      ItemComponent={SimplotMaterialsDropdownItem}
      onFilter={handleFilter}
      isServerSideFilter
      mergeSearchInputWithValue
      searchPlaceholderText="Search by material name"
      valueRenderer={valueRenderer}
      isLoading={loading}
      itemOptionClassName=""
    />
  );
};

SimplotMaterialsDropdown.propTypes = {
  value: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  id: PropTypes.string,
  cleanUpCallback: PropTypes.func
};

SimplotMaterialsDropdown.defaultProps = {
  value: '',
  id: '',
  cleanUpCallback: () => true
};

export default SimplotMaterialsDropdown;
