import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Spinner } from '@agconnections/grow-ui';
import LandingTable from 'components/LandingTable';
import CwFPagination from 'components/CwFPagination';
import useCropZoneProducts from 'screens/Property/hooks/useCropZoneProducts';
import NoProductsFound from 'screens/Property/PropertiesLanding/components/NoProductsFound';
import getSeasons from 'screens/Yield/components/YieldActions/helpers/getSeasons';
import useClientSidePagination from 'screens/Property/hooks/useClientSidePagination';
import PAGINATION_SIZE from 'helpers/globalConstants';
import ActiveIngredientRow from './components/ActiveIngredientRow';
import ingredientTableSort from './helpers/ingredientTableSort';
import { CROPZONE_ACTIVE_INGREDIENTS_TABLE } from './constants';

const { columns, initialSort } = CROPZONE_ACTIVE_INGREDIENTS_TABLE;

const ActiveIngredientSummary = ({ id }) => {
  const [sortField, setSortField] = useState(initialSort.field);
  const [sortDir, setSortDir] = useState(initialSort.dir);

  const {
    currentPageData,
    totalItems,
    handlePageChange,
    updateAllData,
    pageNo
  } = useClientSidePagination(PAGINATION_SIZE);

  const { loading, getActiveIngredients } = useCropZoneProducts({
    cropZoneId: id
  });

  const handleSort = useCallback(
    (field, dir) => {
      setSortField(field);
      setSortDir(dir);
      updateAllData(prevAllData =>
        ingredientTableSort(prevAllData, field, dir)
      );
    },
    [updateAllData]
  );

  useEffect(() => {
    const fetchData = async () => {
      const seasonIds = getSeasons();
      const response = await getActiveIngredients({
        seasonIds
      });
      const sortedData = ingredientTableSort(
        response?.data || [],
        initialSort.field,
        initialSort.dir
      );
      updateAllData(sortedData);
    };

    fetchData();
  }, [getActiveIngredients, updateAllData]);

  const isEverythingLoaded = () => {
    return !loading;
  };

  if (!isEverythingLoaded()) return <Spinner />;
  if (!totalItems) return <NoProductsFound />;
  return (
    <div
      className="min-w-content"
      data-testid="ingredient-table"
      style={{ minWidth: 'min-content' }}
    >
      <LandingTable
        listType="Ingredients"
        items={currentPageData}
        tableColumns={columns}
        onSort={handleSort}
        sortBy={sortField}
        sortDir={sortDir}
        wrapperClassName={`overflow-scroll ${
          currentPageData.length <= 5 ? 'h-full' : 'h-330px'
        }`}
      >
        {({ rows }) =>
          rows.map(entry => (
            <ActiveIngredientRow key={entry.id} ingredient={entry} />
          ))
        }
      </LandingTable>
      <div className="pb-12">
        {CwFPagination(totalItems, pageNo, handlePageChange)}
      </div>
    </div>
  );
};

ActiveIngredientSummary.propTypes = {
  id: PropTypes.string.isRequired
};

export default ActiveIngredientSummary;
