import React from 'react';
import PropTypes from 'prop-types';
import FarmView from '../FarmView';
import CropView from '../CropView';

const ChangeSumaryFFT = ({ massiveChangeSummary, viewBy }) => {
  return (
    <div>
      {viewBy === 'farm' ? (
        <FarmView massiveChangeSummary={massiveChangeSummary} />
      ) : (
        <CropView massiveChangeSummary={massiveChangeSummary} />
      )}
    </div>
  );
};

ChangeSumaryFFT.defaultProps = {
  massiveChangeSummary: {}
};

ChangeSumaryFFT.propTypes = {
  massiveChangeSummary: PropTypes.shape(),
  viewBy: PropTypes.string.isRequired
};

export default ChangeSumaryFFT;
