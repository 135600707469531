import { addDays } from 'date-fns';

export const filterResultByTitle = (result, request) =>
  !request.titleQuery ||
  result.title?.toLowerCase().match(request.titleQuery.toLowerCase());

export const filterResultByDateRange = (result, request) =>
  !request.dateRange.length ||
  (new Date(request.dateRange[0]) <= new Date(result.proposedDateEpoch) &&
    new Date(addDays(request.dateRange[1], 1)) >=
      new Date(result.expirationDateEpoch));
