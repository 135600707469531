import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Button } from '@agconnections/grow-ui';
import ExclamationTriangle from 'components/Icons/ExclamationTriangle';
import MaintenanceModal from 'components/Modals/MaintenanceModal';

const MaintenanceMessage = ({ message }) => {
  const buttonStyle = {
    border: '1px solid #C2C7D0',
    borderRadius: '4px',
    padding: '2px 8px'
  };

  const [openModal, setOpenModal] = useState(false);

  const handleOpenModal = () => {
    setOpenModal(true);
  };

  const handleCloseModal = () => {
    setOpenModal(false);
  };

  return (
    <div className="w-82 flex p-1 mr-2 rounded border border-golden bg-cream">
      <MaintenanceModal
        open={openModal}
        handleClose={handleCloseModal}
        message={message}
      />
      <div className="my-auto mx-1">
        <ExclamationTriangle />
      </div>
      <b className="my-auto mr-2">Upcoming Maintenance</b>
      <Button onClick={handleOpenModal} style={buttonStyle}>
        Learn More
      </Button>
    </div>
  );
};

MaintenanceMessage.propTypes = {
  message: PropTypes.string.isRequired
};

export default MaintenanceMessage;
