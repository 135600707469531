import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { useFlags } from 'launchdarkly-react-client-sdk';

import { Spinner } from '@agconnections/grow-ui';
import EmptyTableState from 'components/EmptyTableState';
import NoDocumentsFound from 'assets/no_documents_found.svg';

import SourceFieldListItem from '../SourceFieldListItem';
import DateFilter from '../DateFilter';

const SourceFieldList = ({
  since,
  until,
  setSince,
  setUntil,
  applyDateFilter,
  isLoading,
  sourceFields,
  selectedSourceField,
  onChange
}) => {
  const {
    releaseBeConnectIntegrationPerformanceImprovement: shouldDisplayDateFilter
  } = useFlags();

  return (
    <div
      data-testid="source-field-list"
      className="w-full pt-6 px-6 h-full flex flex-col"
    >
      <div className="mb-4">
        <span className="text-base">Source Fields</span>
        <p className="text-normal">
          Your fields matched to machinery data. Match operations to your crop
          zones.
        </p>
      </div>
      {shouldDisplayDateFilter && (
        <DateFilter
          since={since}
          until={until}
          setSince={setSince}
          setUntil={setUntil}
          isLoading={isLoading}
          applyDateFilter={applyDateFilter}
        />
      )}
      <div className="flex flex-1 flex-col">
        <div className="pt-3 px-1 h-0 flex-grow overflow-y-auto">
          <div className="flex flex-col gap-y-2">
            {isLoading ? (
              <div className="mt-6">
                <Spinner size="lg" />
                {shouldDisplayDateFilter && (
                  <div className="text-center text-sm font-normal mt-10">
                    <p>Retrieving records from</p>
                    <p>
                      {since.format('DD MMM YYYY')} -{' '}
                      {until.format('DD MMM YYYY')}
                    </p>
                  </div>
                )}
              </div>
            ) : (
              <>
                {shouldDisplayDateFilter && !sourceFields?.length && (
                  <EmptyTableState
                    overlayIcon={NoDocumentsFound}
                    message="Please try a different date range."
                    title="No Operations Found"
                  />
                )}
                {sourceFields?.map(sourceField => (
                  <SourceFieldListItem
                    key={sourceField.fieldId}
                    sourceField={sourceField}
                    selectedSourceField={selectedSourceField}
                    onChange={onChange}
                  />
                ))}
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

SourceFieldList.propTypes = {
  since: PropTypes.instanceOf(moment).isRequired,
  until: PropTypes.instanceOf(moment).isRequired,
  setSince: PropTypes.func.isRequired,
  setUntil: PropTypes.func.isRequired,
  applyDateFilter: PropTypes.func.isRequired,
  isLoading: PropTypes.bool.isRequired,
  sourceFields: PropTypes.arrayOf(PropTypes.object).isRequired,
  selectedSourceField: PropTypes.shape().isRequired,
  onChange: PropTypes.func.isRequired
};

export default SourceFieldList;
