import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState
} from 'react';
import PropTypes from 'prop-types';
import { Table } from 'syngenta-digital-cropwise-react-ui-kit';

import CropZoneIcon from 'assets/crop_zone.svg';
import SaleIcon from 'assets/sale.svg';
import StorageIcon from 'assets/storage.svg';
import CropIconMapping from 'components/CropIconMapping';
import { customDateFormat } from 'helpers/dateFormat';
import formatNumber, { formatCurrency } from 'helpers/formatNumber';
import { getHeaderSortClassName } from 'helpers/tableSort';
import { getAbbreviatedUnit } from 'helpers/unitsAbbreviation';
import formatPricePerUnit from 'helpers/formatPricePerUnit';
import ActionTableCell from '../ActionTableCell';
import './index.css';

export default function LoadsTable({
  tableData,
  onRecordView,
  onRecordDelete,
  onRecordEdit,
  pagination,
  onChange,
  sortBy,
  sortDir,
  compact,
  isCropSortable,
  renderTruckColumn,
  renderDriverColumn
}) {
  const tableWrapperRef = useRef(null);
  const [tableWrapperHeight, setTableWrapperHeight] = useState(0);

  useEffect(() => {
    const updateHeight = () =>
      setTableWrapperHeight(tableWrapperRef.current.clientHeight);
    updateHeight();
    if (tableWrapperRef.current) {
      window.addEventListener('resize', updateHeight);
    }
    return () => window.removeEventListener('resize', updateHeight);
  }, [tableWrapperRef, tableData]);

  const tableColumns = [
    {
      title: 'Load Info',
      dataIndex: 'date',
      key: 'date',
      fixed: compact ? 'left' : undefined,
      sorter: true,
      sortOrder: sortBy === 'date' && sortDir,
      className: getHeaderSortClassName('date', sortBy, sortDir),
      render: (fieldValue, record) => (
        <div className={compact ? 'w-197px' : undefined}>
          <div>{record.name}</div>
          <div className="text-xs text-neutral-60">
            {customDateFormat(fieldValue, 'MMM d, yyyy, hh:mm a')}
          </div>
        </div>
      )
    },
    {
      title: 'Crop',
      dataIndex: 'cropName',
      key: 'cropName',
      sorter: isCropSortable,
      sortOrder: isCropSortable ? sortBy === 'cropName' && sortDir : undefined,
      className: getHeaderSortClassName('cropName', sortBy, sortDir),
      render: fieldValue => (
        <div className={`flex items-start gap-1 ${compact ? 'w-197px' : ''}`}>
          {fieldValue ? (
            <CropIconMapping cropObject={{ name: fieldValue }} />
          ) : (
            ''
          )}
          {fieldValue || '---'}
        </div>
      )
    },
    {
      title: 'Yield Quantity',
      dataIndex: 'load',
      key: 'load',
      sorter: true,
      sortOrder: sortBy === 'load' && sortDir,
      className: getHeaderSortClassName('load', sortBy, sortDir),
      align: 'right',
      render: (fieldValue, record) => (
        <div className={compact ? 'w-180px' : undefined}>
          {fieldValue
            ? formatNumber(fieldValue, getAbbreviatedUnit(record.loadUnit))
            : '---'}
        </div>
      )
    },
    {
      title: 'Sale Info',
      dataIndex: 'grossRevenue',
      key: 'grossRevenue',
      sorter: true,
      sortOrder: sortBy === 'grossRevenue' && sortDir,
      className: getHeaderSortClassName('grossRevenue', sortBy, sortDir),
      align: 'right',
      render: (fieldValue, record) => (
        <div className={`min-w-80px ${compact ? 'w-197px' : ''}`}>
          <div>
            {!isNaN(fieldValue) && fieldValue !== null
              ? formatCurrency(fieldValue)
              : '---'}
          </div>
          <div className="text-xs text-neutral-60">
            {fieldValue && record.salePrice
              ? formatPricePerUnit(
                  record.salePrice,
                  getAbbreviatedUnit(record.loadUnit)
                )
              : '---'}
          </div>
        </div>
      )
    },
    {
      title: 'Source',
      dataIndex: 'sources',
      key: 'sources',
      render: (fieldValue, record) => {
        if (!fieldValue?.length) {
          return '---';
        }
        if (record.sourceType === 'CROP_ZONE') {
          return (
            <div className={compact ? 'w-197px' : undefined}>
              <div className="flex items-start gap-1">
                <img src={CropZoneIcon} alt="Crop zone icon" />
                {fieldValue.length === 1
                  ? '1 crop zone'
                  : `${fieldValue.length} crop zones`}
              </div>
              <div className="text-xs text-neutral-60">
                {Boolean(record.totalArea) &&
                  `${Number(record.totalArea).toFixed(2)} ac`}
              </div>
            </div>
          );
        }
        if (record.sourceType === 'STORAGE') {
          return (
            <div className="flex items-center gap-2">
              <img src={StorageIcon} alt="Storage icon" className="h-15px" />
              {fieldValue[0].name}
            </div>
          );
        }

        return '---';
      }
    },
    {
      title: 'Destination',
      dataIndex: 'destination',
      key: 'destination',
      render: (fieldValue, record) => {
        if (!fieldValue) {
          return '---';
        }
        if (record.destinationType === 'STORAGE') {
          return (
            <div
              className={`flex items-center gap-2 ${compact ? 'w-197px' : ''}`}
            >
              <img src={StorageIcon} alt="Storage icon" className="h-15px" />
              {fieldValue.name}
            </div>
          );
        }

        if (record.destinationType === 'SALE') {
          return (
            <div
              className={`flex items-center gap-2 ${compact ? 'w-197px' : ''}`}
            >
              <img src={SaleIcon} alt="Sale icon" />
              {fieldValue.name}
            </div>
          );
        }
        return '---';
      }
    }
  ];

  if (renderDriverColumn) {
    tableColumns.push({
      title: 'Driver',
      dataIndex: 'driverId',
      key: 'driver',
      render: fieldValue => (
        <div className={compact ? 'w-197px' : undefined}>
          {renderDriverColumn(fieldValue)}
        </div>
      )
    });
  }

  if (renderTruckColumn) {
    tableColumns.push({
      title: 'Truck',
      dataIndex: 'truckId',
      key: 'truck',
      render: fieldValue => (
        <div className={compact ? 'w-197px' : undefined}>
          {renderTruckColumn(fieldValue)}
        </div>
      )
    });
  }

  tableColumns.push({
    key: 'actions',
    render: (_, record) => (
      <ActionTableCell
        onRecordView={() => onRecordView(record.id)}
        onRecordEdit={e => {
          e.stopPropagation();
          onRecordEdit(record.id);
        }}
        onRecordDelete={e => {
          e.stopPropagation();
          onRecordDelete(record.id);
        }}
        compact={compact}
      />
    )
  });

  const isOnlyOnePage = useMemo(() => {
    return pagination.total <= pagination.pageSize;
  }, [pagination]);

  const onRow = useCallback(
    record => {
      return {
        onClick: e => {
          e.stopPropagation();
          if (!compact) {
            onRecordView(record.id);
          }
        }
      };
    },
    [onRecordView, compact]
  );

  return (
    <div
      className={`flex flex-col loads-table ${
        isOnlyOnePage ? 'one-page-table' : ''
      }`}
      style={
        compact
          ? { maxHeight: '384px', height: '384px', minHeight: '384px' }
          : {
              height: 'calc(100vh - 10rem)',
              maxHeight: 'calc(100vh - 10rem)',
              minHeight: 'calc(100vh - 10rem)'
            }
      }
      ref={tableWrapperRef}
    >
      <Table
        data-testid="loads-table"
        rowKey="id"
        columns={tableColumns}
        dataSource={tableData}
        pagination={pagination}
        onChange={onChange}
        scroll={{
          y: `calc(${tableWrapperHeight}px${compact ? ' - 5.5rem' : ' - 9rem'})`
        }}
        tableLayout="auto"
        onRow={record => onRow(record)}
      />
    </div>
  );
}

LoadsTable.defaultProps = {
  tableData: [],
  onRecordView: () => {},
  onRecordDelete: () => {},
  onRecordEdit: () => {},
  sortBy: '',
  sortDir: '',
  compact: false,
  isCropSortable: true,
  renderTruckColumn: undefined,
  renderDriverColumn: undefined
};

LoadsTable.propTypes = {
  tableData: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      date: PropTypes.string,
      crop: PropTypes.shape({
        icon: PropTypes.string,
        name: PropTypes.string
      }),
      yieldQuantity: PropTypes.string,
      totalArea: PropTypes.number,
      totalPrice: PropTypes.string,
      pricePerBu: PropTypes.string,
      source: PropTypes.shape({
        icon: PropTypes.string,
        name: PropTypes.string,
        addnotation: PropTypes.string
      }),
      destination: PropTypes.shape({
        icon: PropTypes.string,
        name: PropTypes.string
      })
    })
  ),
  onRecordView: PropTypes.func,
  onRecordDelete: PropTypes.func,
  onRecordEdit: PropTypes.func,
  onChange: PropTypes.func.isRequired,
  pagination: PropTypes.shape({
    defaultCurrent: PropTypes.number,
    current: PropTypes.number,
    defaultPageSize: PropTypes.number,
    pageSize: PropTypes.number,
    total: PropTypes.number,
    showTotal: PropTypes.func,
    onChange: PropTypes.func,
    showSizeChanger: PropTypes.bool,
    position: PropTypes.arrayOf(PropTypes.string)
  }).isRequired,
  sortBy: PropTypes.string,
  sortDir: PropTypes.string,
  compact: PropTypes.bool,
  isCropSortable: PropTypes.bool,
  renderTruckColumn: PropTypes.func,
  renderDriverColumn: PropTypes.func
};
