import React from 'react';
import PropTypes from 'prop-types';
import { Icon } from '@agconnections/grow-ui';

const SummaryTag = ({ isLoading, missingInfoAmount }) => {
  if (isLoading) {
    return null;
  }
  return (
    <div className="flex h-full items-center">
      <span
        className={`ml-4 p-3 text-base ${
          missingInfoAmount !== 0
            ? ' text-yellow-700 bg-yellow-200'
            : ' text-green-700 bg-green-200'
        } rounded-lg whitespace-no-wrap h-fit flex fle-row`}
      >
        {missingInfoAmount !== 0 ? (
          <>
            <strong data-testid="need-info-fields" className="mr-1">
              {missingInfoAmount}
            </strong>{' '}
            Forms Need Info
          </>
        ) : (
          <>
            <div className="mr-3 w-fit h-fit p-1 rounded-full bg-green-600">
              <Icon size={12} icon="checkmark" color="#FFFFFF" />
            </div>
            <div>Forms Complete</div>
          </>
        )}
      </span>
    </div>
  );
};

SummaryTag.propTypes = {
  missingInfoAmount: PropTypes.number.isRequired,
  isLoading: PropTypes.bool.isRequired
};

export default SummaryTag;
