import { useState, useContext } from 'react';
import { Context } from 'components/Store';
import {
  SET_REMOVE_PRODUCT_FAILURE,
  SET_TOAST_MESSAGE,
  SET_TOAST_TYPE,
  SET_SHOW_TOAST_REMOVE_PRODUCT
} from 'reducers/reducer';
import {
  product as customProductApi,
  inventoryProductPrices as listPriceApi,
  createChildApi,
  inventoryProducts
} from 'utilities/api';

function useInventoryProduct() {
  const orgId = Context?._currentValue[0]?.organization?.id;

  const [isUpdateLoading, setIsUpdateLoading] = useState(false);
  const [updateError, setUpdateError] = useState(null);

  const [isRemoveLoading, setIsRemoveLoading] = useState(false);
  const [removeError, setRemoveError] = useState(null);

  const [, dispatch] = useContext(Context);

  const updateListPrice = payload =>
    listPriceApi.post(Array.isArray(payload) ? payload : [payload], {
      headers: {
        'cwf-context': JSON.stringify({
          organization_id: orgId
        })
      }
    }).promise;

  const addUpdateListPrice = async (payload, onSuccess, onError) => {
    setUpdateError();
    setIsUpdateLoading(true);

    const promise = updateListPrice(payload);
    await promise
      .then(() => {
        if (onSuccess) onSuccess();
      })
      .catch(err => {
        setUpdateError(err.message);
        if (onError) onError();
      })
      .finally(() => {
        setIsUpdateLoading(false);
      });
  };

  const addProducts = async ({ itemsToUpdate = [], itemsToCreate = [] }) => {
    setUpdateError();
    setIsUpdateLoading(true);

    const promises = [];
    promises.push(updateListPrice(itemsToUpdate));
    itemsToCreate.forEach(payload => {
      promises.push(customProductApi.create(payload).promise);
    });
    await Promise.all(promises)
      .catch(err => {
        setUpdateError(err.message);
      })
      .finally(() => {
        setIsUpdateLoading(false);
      });
  };

  const fetchCustomProduct = async id => {
    const { promise } = customProductApi.fetch(id);
    return promise;
  };

  const updateCustomProduct = async payload => {
    setUpdateError();
    setIsUpdateLoading(true);

    const updateCall = () =>
      customProductApi.update(payload.id, payload, {
        headers: {
          'cwf-context': JSON.stringify({
            organization_id: orgId
          })
        }
      });

    const { promise } = updateCall();
    return promise.then(() => {
      const { promise: innerPromise } = updateCall();
      innerPromise
        .catch(err => {
          setUpdateError(err.message);
        })
        .finally(() => {
          setIsUpdateLoading(false);
        });
    });
  };

  const removeProduct = async productId => {
    setRemoveError(null);
    setIsRemoveLoading(true);

    const { promise } = createChildApi(
      inventoryProducts,
      `product/${productId}/hide`
    ).patch();
    await promise
      .catch(err => {
        setRemoveError(err.message);
        setIsRemoveLoading(false);
        dispatch({
          type: SET_REMOVE_PRODUCT_FAILURE,
          payload: true
        });
        dispatch({
          type: SET_TOAST_MESSAGE,
          payload: 'Unable to remove product.'
        });
        dispatch({
          type: SET_TOAST_TYPE,
          payload: 'error'
        });
        dispatch({
          type: SET_SHOW_TOAST_REMOVE_PRODUCT,
          payload: true
        });
      })
      .finally(() => {
        setIsRemoveLoading(false);
      });
  };

  return {
    fetchCustomProduct,
    addUpdateListPrice,
    addProducts,
    updateCustomProduct,
    isUpdateLoading,
    updateError,
    setUpdateError,
    removeProduct,
    isRemoveLoading,
    removeError,
    setRemoveError
  };
}

export default useInventoryProduct;
