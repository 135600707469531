import React, { useContext, useEffect, useState } from 'react';

import PropTypes from 'prop-types';

import { ArrowDropDown, ArrowDropUp } from '@material-ui/icons';

import { Spinner } from '@agconnections/grow-ui';
import ClickableDiv from 'components/ClickableDiv';

import { PropertiesMatchContext } from 'screens/Integrations/PropertiesMatch/context/PropertiesMatchProvider';

import PropertyTag from './components/PropertyTag';
import PropertyContent from './components/PropertyContent';
import { checkIfMatchExistsInCropZones } from '../../helpers';

const PropertyView = ({
  property,
  isOpen,
  openProperty,
  cropZones,
  fftSelectGroups
}) => {
  const {
    isLoading,
    state: { matches }
  } = useContext(PropertiesMatchContext);

  const [matched, setMatched] = useState();

  useEffect(() => {
    if (matches?.length && cropZones?.length) {
      const match = checkIfMatchExistsInCropZones(cropZones, property, matches);
      setMatched(cropZones.find(cz => cz.id === match?.cwfId));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [matches, cropZones]);

  if (isLoading) {
    return (
      <div className="col-span-2 justify-self-end">
        <Spinner size="sm" />
      </div>
    );
  }

  return (
    <div
      data-testid="property-collapse"
      className="text-l rounded-lg p-4 shadow-md mt-1 grid-flow-row auto-rows-max max-w-800 mb-5"
    >
      <ClickableDiv
        className="flex bg-transparent text-info-dark max-w-800"
        onClick={() => openProperty(property)}
      >
        <div className="w-20" data-testid="property-match-status">
          <PropertyTag property={property} matched={matched} />
        </div>
        <div className="flex justify-start ml-2" data-testid="property-title">
          <span
            className={`font-semibold ${
              property.hide ? 'text-neutral-50' : ''
            }`}
          >
            &nbsp;{property?.name}
          </span>
        </div>
        <div className="flex justify-end flex-1">
          {isOpen ? (
            <ArrowDropUp fontSize="small" className="text-neutral-600" />
          ) : (
            <ArrowDropDown fontSize="small" className="text-neutral-600" />
          )}
        </div>
      </ClickableDiv>
      {isOpen && (
        <PropertyContent
          property={property}
          fftSelectGroups={fftSelectGroups}
          cropZones={cropZones}
          matched={matched}
        />
      )}
    </div>
  );
};

PropertyView.defaultProps = {
  cropZones: [],
  fftSelectGroups: [],
  property: {},
  isOpen: false
};

PropertyView.propTypes = {
  cropZones: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      name: PropTypes.string,
      reportedArea: PropTypes.number,
      boundaryArea: PropTypes.number
    })
  ),
  openProperty: PropTypes.func.isRequired,
  isOpen: PropTypes.bool,
  fftSelectGroups: PropTypes.arrayOf(PropTypes.object),
  property: PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.string,
    hide: PropTypes.bool
  })
};

export default PropertyView;
