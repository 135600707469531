export const buildMasterlistProductPayload = values => ({
  productId: values.productId ?? values.id,
  averagePricePerUnit: parseFloat(values.averagePricePerUnit),
  ...(!values?.packageWeight
    ? {}
    : { packageWeight: parseFloat(values?.packageWeight) })
});

export const parseFormulation = formulation =>
  Object.keys(formulation).reduce(
    (result, k) => ({
      ...result,
      [k]: isNaN(formulation[k])
        ? parseFloat(formulation[k].replace(/%/g, ''))
        : formulation[k]
    }),
    {}
  );

export const buildCustomProductPayload = values => ({
  id: values.id ?? values.productId,
  name: values.name,
  manufacturer: values.manufacturer,
  standardUnit: values.standardUnit,
  type: values.type,
  makeUp: values.makeUp,
  density: parseFloat(values.density),
  stdFactor: parseFloat(values.stdFactor),
  stdPackageUnit: values.standardUnit,
  formulation: parseFormulation(values.formulation),
  averagePricePerUnit: parseFloat(values.averagePricePerUnit)
});
