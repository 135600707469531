import React from 'react';
import PropTypes from 'prop-types';
import { format } from 'date-fns';
import { Tag } from '@agconnections/grow-ui';

const InviteStatus = ({ member, status, statusDate }) => {
  let tagTextColor;
  let tagBgColor;
  let statusMessage;

  const isRevokedInvite = () => {
    if (status === 'Revoked' && member?.accountId === undefined)
      return 'Invite';
    return 'Access';
  };
  const date = new Date(statusDate);

  const expired = () => {
    const now = new Date();
    const lastUpdate = new Date(statusDate);

    const lastUpdatePlusThirtyDays = new Date(
      format(lastUpdate.setDate(lastUpdate.getDate() + 30), 'MMMM d, yyyy')
    );
    return status === 'Pending' && now > lastUpdatePlusThirtyDays;
  };
  if (status === 'Joined') {
    tagTextColor = '#14803C';
    tagBgColor = '#8CD0A5';
    statusMessage = `Member Joined on ${format(date, 'MMMM d, yyyy')}`;
  } else if (status === 'Expired' || expired()) {
    tagTextColor = '#787D96';
    tagBgColor = '#D7DAE0';
    statusMessage = `Invitation Expired on ${format(date, 'MMMM d, yyyy')}`;
  } else if (status === 'Pending') {
    tagTextColor = '#E99921';
    tagBgColor = '#FFE4AE';
    statusMessage = `Invite Pending, expires on ${format(
      date.setDate(date.getDate() + 30),
      'MMMM d, yyyy'
    )}`;
  } else if (status === 'Revoked') {
    tagTextColor = '#D95032';
    tagBgColor = '#FFAEAE';
    statusMessage = `${isRevokedInvite()} Revoked on  ${format(
      date,
      'MMMM d, yyyy'
    )}`;
  }

  return (
    <div
      data-testid="member-invite-status"
      className="flex flex-col items-start text-sm leading-5 capitalize text-neutral-600"
    >
      <div>
        <Tag color={tagBgColor}>
          <span style={{ color: tagTextColor }}>
            {expired() ? 'Expired' : status}
          </span>
        </Tag>
      </div>
      <div>{statusMessage}</div>
    </div>
  );
};
InviteStatus.propTypes = {
  status: PropTypes.string,
  statusDate: PropTypes.string,
  member: PropTypes.shape({
    accountId: PropTypes.string
  }).isRequired
};
InviteStatus.defaultProps = {
  status: null,
  statusDate: null
};

export default InviteStatus;
