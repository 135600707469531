import React from 'react';
import PropTypes from 'prop-types';

import EmptySummary from './components/EmptySummary';
import SelectedFields from './components/SelectedFields';

const ChangeSummary = ({ massiveChangeSummary }) => {
  const emptySummary = !massiveChangeSummary.fieldsToMassiveAssign?.length;
  return (
    <div
      className="absolute right-0 flex flex-col z-4 h-full"
      // 64px is height of the map header, 164px is the height of the bottom right map controls, 20px of padding
      style={{ height: 'calc(100% - 64px - 164px - 20px)', top: 64 }}
    >
      <div className="bg-white w-300px m-5 px-5 pb-8 rounded max-h-full flex flex-col">
        <h4 className="font-semibold font-body z-1 sticky top-0 bg-white pt-3">
          Change Summary
        </h4>
        {emptySummary ? (
          <EmptySummary />
        ) : (
          <SelectedFields massiveChangeSummary={massiveChangeSummary} />
        )}
      </div>
    </div>
  );
};

ChangeSummary.defaultProps = {
  massiveChangeSummary: {}
};

ChangeSummary.propTypes = {
  massiveChangeSummary: PropTypes.shape()
};

export default ChangeSummary;
