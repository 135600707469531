import React from 'react';
import PropTypes from 'prop-types';
import { generatePathSegment } from 'helpers/chartCalculations';

const PieChart = ({ isLarge, value }) => {
  const total = 100;
  const usedValue = Math.max(Math.min(value, 99.99999), 0.01);
  const freeValue = total - usedValue;
  let usedColor = '#CF3537';
  if (value === 0) {
    usedColor = 'rgb(195, 199, 207)';
  } else if (value < 90) {
    usedColor = '#19A04B';
  } else if (value < 100) {
    usedColor = '#F0C355';
  }

  // Create path elements for each segment
  const paths = [
    { percentage: usedValue, color: usedColor, key: 'used' },
    { percentage: freeValue, color: 'rgb(195, 199, 207)', key: 'free' }
  ].map(({ percentage, color, key }, index) => {
    const startAngle = index === 0 ? 0 : usedValue * 3.6;
    const endAngle = startAngle + percentage * 3.6;

    const outerRadius = 90;
    const innerRadius = isLarge ? 50 : 60;

    const d = generatePathSegment({
      startAngle,
      endAngle,
      innerRadius,
      outerRadius
    });
    return <path key={key} d={d} fill={color} />;
  });

  // Return the JSX representing the SVG
  let textSize;
  if (isLarge) {
    if (value < -100 || value > 1000) {
      textSize = 'text-sm';
    } else {
      textSize = 'text-lg';
    }
  } else if (value < -100 || value > 1000) {
    textSize = 'text-xs';
  } else {
    textSize = 'text-sm';
  }

  return (
    <div className="w-full h-full relative">
      <svg data-value={value} viewBox="0 0 200 200" className="w-full h-full">
        {paths}
      </svg>
      <div className="absolute left-0 top-0 right-0 bottom-0 flex items-center justify-center">
        <div className={textSize}>{+value.toFixed()}%</div>
      </div>
    </div>
  );
};
PieChart.defaultProps = {
  isLarge: false
};

PieChart.propTypes = {
  value: PropTypes.number.isRequired,
  isLarge: PropTypes.bool
};

export default PieChart;
