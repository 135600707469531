import React from 'react';
import mapIcon from 'assets/properties-map-icon.svg';
import { Button } from '@agconnections/grow-ui';
import { useHistory } from 'react-router-dom';
import { paths } from 'routes/paths';

const YieldSummaryEmptyState = () => {
  const history = useHistory();
  return (
    <div
      className="flex flex-col w-full bg-white shadow-documentation-card items-center justify-center"
      style={{
        height: '500px',
        borderRadius: '8px',
        paddingLeft: '34px',
        paddingRight: '34px',
        paddingTop: '24px',
        paddingBottom: '24px'
      }}
      data-testid="yield-EmptySummary"
    >
      <img
        src={mapIcon}
        alt="A empty map"
        className="w-68"
        style={{ marginTop: '-30px' }}
      />
      <p className="mb-5">
        There are no crop zones for the selected crop season(s).
      </p>
      <Button
        type="primary"
        size="lg"
        style={{ paddingRight: '30px', paddingLeft: '30px' }}
        onClick={() => history.push(paths.properties)}
      >
        Go to Properties
      </Button>
    </div>
  );
};

export default YieldSummaryEmptyState;
