import React from 'react';
import PropTypes from 'prop-types';

import { Select } from '@agconnections/grow-ui';

import {
  createEventHandlerWithTransform,
  getEventSelectedKey
} from 'utilities/formHelpers';

const TaskTypeSelect = ({ taskTypes, onChangeTaskType }) => {
  const handleChange = createEventHandlerWithTransform(
    onChangeTaskType,
    getEventSelectedKey
  );
  return (
    <div data-testid="tasktypeselect">
      <div className="relative w-32 text-gray-400 focus-within:text-gray-600 mr-4">
        <Select
          placeholder="Task Type"
          items={taskTypes}
          onChange={handleChange}
        />
      </div>
    </div>
  );
};

TaskTypeSelect.defaultProps = {
  onChangeTaskType: () => {}
};
TaskTypeSelect.propTypes = {
  taskTypes: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.object)])
    .isRequired,
  onChangeTaskType: PropTypes.func
};

export default TaskTypeSelect;
