const sortOperationsByDate = (operations = []) => {
  return [...operations]?.sort((a, b) => {
    if (a.start_date_time > b.start_date_time) {
      return -1;
    }
    if (a.start_date_time < b.start_date_time) {
      return 1;
    }
    return 0;
  });
};

export default sortOperationsByDate;
