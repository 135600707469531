/* eslint-disable import/prefer-default-export */
import PropTypes from 'prop-types';
import PropertyToStorage from 'assets/loads/property-to-storage.svg';
import PropertyToSale from 'assets/loads/property-to-sale.svg';
import StorageToSale from 'assets/loads/storage-to-sale.svg';
import StorageToStorage from 'assets/loads/storage-to-storage.svg';

export const LARGE_LENGTH_THRESHOLD = 9;

export const LOAD_TYPES = {
  CROP_ZONE: 'CROP_ZONE',
  STORAGE: 'STORAGE',
  SALE: 'SALE'
};

export const DESTINATION_TYPES = {
  STORAGE: 'STORAGE',
  SALE: 'SALE'
};

export const LOAD_DIRECTION = {
  STORAGE_STORAGE: 'STORAGE_STORAGE',
  STORAGE_SALE: 'STORAGE_SALE',
  CROP_ZONE_STORAGE: 'CROP_ZONE_STORAGE',
  CROP_ZONE_SALE: 'CROP_ZONE_SALE'
};

export const ICONS_BY_LOAD_DIRECTION = {
  [LOAD_DIRECTION.STORAGE_STORAGE]: StorageToStorage,
  [LOAD_DIRECTION.STORAGE_SALE]: StorageToSale,
  [LOAD_DIRECTION.CROP_ZONE_STORAGE]: PropertyToStorage,
  [LOAD_DIRECTION.CROP_ZONE_SALE]: PropertyToSale
};

const TLocationData = {
  id: PropTypes.string.isRequired,
  name: PropTypes.string,
  area: PropTypes.number
};

const TCropZone = PropTypes.shape({
  acres: PropTypes.number,
  farmId: PropTypes.string,
  farmName: PropTypes.string,
  fieldId: PropTypes.string,
  fieldName: PropTypes.string,
  seasonId: PropTypes.string,
  cropZoneId: PropTypes.string,
  cropZoneName: PropTypes.string
});

export const TLoad = PropTypes.shape({
  id: PropTypes.string.isRequired,
  name: PropTypes.string,
  date: PropTypes.string,
  sources: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      name: PropTypes.string
    })
  ),
  sourceType: PropTypes.string,
  destination: PropTypes.shape(TLocationData),
  destinationType: PropTypes.string,
  load: PropTypes.string,
  loadUnit: PropTypes.string,
  cropId: PropTypes.string,
  cropName: PropTypes.string,
  grossRevenue: PropTypes.number,
  totalArea: PropTypes.number,
  cropSeason: PropTypes.string,
  salePrice: PropTypes.string,
  entryName: PropTypes.string,
  cropZones: PropTypes.arrayOf(TCropZone),
  unit: PropTypes.string,
  avgYieldPerAcre: PropTypes.string,
  totalYield: PropTypes.number,
  grossRevenuePerAcre: PropTypes.number,
  cropSeasonId: PropTypes.string
});

const treeNodeShape = PropTypes.shape({
  title: PropTypes.string.isRequired,
  key: PropTypes.string.isRequired
});
treeNodeShape.children = PropTypes.arrayOf(treeNodeShape);
export const treeDataPropType = PropTypes.arrayOf(treeNodeShape);
