import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import DeleteModal from 'components/Modals/DeleteModal';
import useLoads from 'hooks/useLoads';
import SpinnerModal from 'components/SpinnerModal';
import { AmplitudeContext } from 'utilities/amplitude/useAmplitude';

const DeleteConfirmation = ({ id, open, onCancel, onResult }) => {
  const { deleteLoad, loading } = useLoads();
  const amplitude = useContext(AmplitudeContext);

  const handleDelete = async () => {
    amplitude.sendEventToAmplitude(
      amplitude.events.epic.YieldV2.loads.deleteYield
    );
    await deleteLoad({ id }).then(response => {
      if (response === 200) {
        onResult({
          type: 'success',
          title: 'Load deleted.',
          message: '',
          showToast: true
        });
      } else {
        onResult({
          type: 'error',
          title: 'Unable to delete load.',
          message:
            'An error prevented your load from being deleted. Please try again.',
          showToast: true
        });
      }
      onCancel(false);
    });
  };

  if (!open) return null;
  if (loading) return <SpinnerModal open message="Deleting Load..." />;
  return (
    <DeleteModal
      open={open}
      type="error"
      itemType="Load"
      onCancel={onCancel}
      onDelete={handleDelete}
    />
  );
};

DeleteConfirmation.propTypes = {
  id: PropTypes.string.isRequired,
  open: PropTypes.bool.isRequired,
  onCancel: PropTypes.func.isRequired,
  onResult: PropTypes.func.isRequired
};

export default DeleteConfirmation;
