import { useState, useCallback, useEffect } from 'react';

function useClientSidePagination(initialPageSize) {
  const [allData, setAllData] = useState([]);
  const [currentPageData, setCurrentPageData] = useState([]);
  const [pageNo, setPageNo] = useState(0);
  const [pageSize, setPageSize] = useState(initialPageSize);

  const updateAllData = useCallback(newData => {
    setAllData(newData);
  }, []);

  const recalculateCurrentPageData = useCallback(() => {
    const startIndex = pageNo * pageSize;
    const pageData = allData.slice(startIndex, startIndex + pageSize);
    setCurrentPageData(pageData);
  }, [allData, pageNo, pageSize]);

  useEffect(() => {
    recalculateCurrentPageData();
  }, [allData, pageNo, recalculateCurrentPageData]);

  const handlePageChange = useCallback(page => {
    setPageNo(page);
  }, []);

  const totalItems = allData.length;

  return {
    allData,
    currentPageData,
    pageNo,
    pageSize,
    totalItems,
    updateAllData,
    recalculateCurrentPageData,
    handlePageChange,
    setPageSize
  };
}

export default useClientSidePagination;
