import { PERMISSION_API_MIRAGE_ROOT_URL } from 'utilities/apiConstants';
import { getToken } from 'utilities/helpers';
import { notFoundResponse } from '../utils/errorResponses';

const permissionToUpdate = (permissions, organizationId, userId) => {
  const filteredPermissions = permissions.where({
    organizationId,
    oktaId: userId
  });
  return filteredPermissions;
};

export default server => {
  server.get(
    `${PERMISSION_API_MIRAGE_ROOT_URL}/organizations`,
    (schema, request) => {
      const token = getToken(request.requestHeaders);
      if (token !== null) {
        const permissionsForUser = schema.permissions
          .all()
          .filter(permission => permission.oktaId === token.uid);

        if (permissionsForUser) return permissionsForUser;
      }
      return notFoundResponse('No permissions found', 'Permission');
    }
  );

  server.get(`${PERMISSION_API_MIRAGE_ROOT_URL}/users`, (schema, request) => {
    const token = getToken(request.requestHeaders);
    if (token !== null) {
      const { organizationId } = request.queryParams;
      const usersAndPermissions = schema.permissions.where({
        organizationId,
        oktaId: token.uid
      });
      if (usersAndPermissions) return usersAndPermissions;
    }
    return notFoundResponse('Permission not found', 'Permission');
  });

  server.put(PERMISSION_API_MIRAGE_ROOT_URL, (schema, request) => {
    const token = getToken(request.requestHeaders);
    if (token !== null) {
      const jsonBody = JSON.parse(request.requestBody);
      const permission = permissionToUpdate(
        schema.permissions,
        jsonBody.organizationId,
        token.uid
      );
      if (permission.length > 0) {
        return permission.update(JSON.parse(request.requestBody));
      }
      return notFoundResponse('Permission not updated', 'Permission');
    }
    return notFoundResponse('Permission not updated', 'Permission');
  });

  server.post(`${PERMISSION_API_MIRAGE_ROOT_URL}`, (schema, request) => {
    const token = getToken(request.requestHeaders);
    if (token !== null) {
      const jsonBody = JSON.parse(request.requestBody);
      jsonBody.oktaId = token.uid;
      jsonBody.version = 0;
      return schema.permissions.create(jsonBody);
    }
    return notFoundResponse('No Bearer Token', 'Permission');
  });

  server.delete(
    `${PERMISSION_API_MIRAGE_ROOT_URL}`,
    (schema, request) => {
      const token = getToken(request.requestHeaders);
      if (token !== null) {
        const jsonBody = JSON.parse(request.requestBody);
        const permission = permissionToUpdate(
          schema.permissions,
          jsonBody.organizationId,
          token.uid
        );
        if (permission) return permission.destroy();
      }
      return notFoundResponse('Permission Not Found', 'Permission');
    },
    200 // there is a hidden status code parameter not defined in docs
  );
};
