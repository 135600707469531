// eslint-disable-next-line import/prefer-default-export
export const condensor = characters => {
  const splitCharacters = characters.split('-');
  let cleanCharacters = splitCharacters.map(character =>
    character.replace(/^0+/, '')
  );
  cleanCharacters = cleanCharacters.map(character =>
    character.replace(/\b[A-Z]{2}/, '')
  );
  cleanCharacters = cleanCharacters.join('-');
  if (cleanCharacters[cleanCharacters.length - 1] === '-') {
    cleanCharacters = cleanCharacters.slice(0, -2);
  }
  return cleanCharacters;
};
