import React from 'react';
import PropTypes from 'prop-types';
import { Table, Checkbox } from '@agconnections/grow-ui';

const ConvertPlanTableHeader = ({
  sort,
  handleSort,
  numProducts,
  markAll,
  allAreMarked
}) => {
  return (
    <Table.Header>
      <Table.Cell>
        <Checkbox
          onChange={event => {
            markAll(event.target.checked);
          }}
          checked={allAreMarked}
        />
      </Table.Cell>
      <Table.Cell sortable sort={sort} onClick={handleSort}>
        {`Product Name (${numProducts})`}
      </Table.Cell>
      <Table.Cell sortable sort={sort} onClick={handleSort}>
        Rate/Area
      </Table.Cell>
      <Table.Cell sortable sort={sort} onClick={handleSort}>
        Area Applied (ac)
      </Table.Cell>
      <Table.Cell sortable sort={sort} onClick={handleSort}>
        Coverage %
      </Table.Cell>
      <Table.Cell sortable sort={sort} onClick={handleSort}>
        Total Product
      </Table.Cell>
      <Table.Cell>Total Cost</Table.Cell>
    </Table.Header>
  );
};

ConvertPlanTableHeader.propTypes = {
  numProducts: PropTypes.number.isRequired,
  sort: PropTypes.string.isRequired,
  handleSort: PropTypes.func.isRequired,
  markAll: PropTypes.func.isRequired,
  allAreMarked: PropTypes.bool.isRequired
};

export default ConvertPlanTableHeader;
