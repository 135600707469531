export const statuses = [
  { key: 'owner', value: 'Owner' },
  { key: 'leased', value: 'Leased' },
  { key: 'contracted', value: 'Contracted' }
];

// contracted, leased, owned, sold, junked, contractTerminated, leaseTerminated

export const types = [
  { key: 'EARTH_MOVING', value: 'Earth Moving' },
  { key: 'FERTILIZER', value: 'Fertilizer' },
  { key: 'FORAGE_AND_HAY', value: 'Forage and Hay' },
  { key: 'HARVESTING', value: 'Harvesting' },
  { key: 'IRRIGATION', value: 'Irrigation' },
  { key: 'MANURE', value: 'Manure' },
  { key: 'MISC', value: 'Miscellaneous' },
  { key: 'PLANTER', value: 'Planter' },
  { key: 'SHOP', value: 'Shop' },
  { key: 'SPRAYER', value: 'Sprayer' },
  { key: 'TILLAGE', value: 'Tillage' },
  { key: 'TRACTOR', value: 'Tractor' },
  { key: 'TRAILER', value: 'Trailer' },
  { key: 'TRUCK', value: 'Truck' },
  { key: 'GENERICAL_VEHICLE', value: 'Generical Vehicle' }
];

export const tableColumns = [
  {
    field: 'name',
    title: 'Name',
    sortable: true
  },
  {
    field: 'type',
    title: 'Type',
    sortable: true
  },
  {
    field: 'currentStatus',
    title: 'Current Status',
    sortable: true
  },
  {
    field: 'year',
    title: 'Year',
    sortable: true
  },
  {
    field: 'make',
    title: 'Make',
    sortable: true
  },
  {
    field: 'model',
    title: 'Model',
    sortable: true
  },
  {
    field: '',
    title: '',
    sortable: false
  }
];
