export const getCentroidFromLatLong = coordinateArr => {
  const data = Array.isArray(coordinateArr?.[0]?.[0])
    ? coordinateArr.flat()
    : coordinateArr;

  if (!(data?.length > 0)) {
    return false;
  }
  const numCoords = data.length;
  let X = 0.0;
  let Y = 0.0;
  /* eslint no-unused-expressions: "off", curly: "error" */
  data?.forEach(item => {
    const lat = item[0];
    const lon = item[1];
    X += lat;
    Y += lon;
  });
  X /= numCoords;
  Y /= numCoords;
  const resultOutput = [];
  if (!isNaN(X) && !isNaN(Y)) {
    resultOutput.push(X, Y);
    return resultOutput;
  }
  return 'NONE';
};

export const getAllLabelsFromArray = (coordinateArr, name = 'Untitled') => [
  getCentroidFromLatLong(coordinateArr.flat()),
  name
];
