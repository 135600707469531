import { CookieStorage } from 'cookie-storage';

const cookieStorage = new CookieStorage();
const clientId = process.env.REACT_APP_CLIENT_ID;
const cropwiseBaseAPIUrl = process.env.REACT_APP_CROPWISE_BASE_API_URL;
const env = process.env.REACT_APP_ENV_NAME;

export const isAccessTokenAvailable = () => !!localStorage.getItem('tokens');
export const setAccessToken = tokens => localStorage.setItem('tokens', tokens);
export const getTokens = () => localStorage.getItem('tokens') || '{}';
export const clearAccessToken = () => {
  window.indexedDB.deleteDatabase('cwf-cache');
  localStorage.clear();
};

export const getAccessToken = () => JSON.parse(getTokens()).access_token;

export const accountsOAuthUrl = `https://${cropwiseBaseAPIUrl}
/oauth/authorize
?response_type=code
&client_id=${clientId}
&redirect_uri=${window.location.origin}/authenticate`;

export const cleanNewUserSession = () => {
  const key = `resetUserSession_${env}`;

  if (cookieStorage.getItem(key)) {
    clearAccessToken();
    document.cookie = `${key}= ; domain=cropwise.com; expires = Thu, 01 Jan 1970 00:00:00 GMT`;
    window.location.href = accountsOAuthUrl;
  }
};
