import React, { useCallback, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';
import { getBushelWeight } from 'helpers/getBushelWeight';
import { paths } from 'routes/paths';
import { MEASUREMENT_UNITS, convertUnit } from 'helpers/unitsAbbreviation';
import StorageLocationsWidget from './StorageLocationsWidget';
import useStorageLocationsSummary from '../hooks/useStorageLocationsSummary';

const ConnectedStorageLocationsWidget = ({
  cropId,
  cropName,
  seasonId,
  unit,
  className
}) => {
  const history = useHistory();
  const summary = useStorageLocationsSummary();
  useEffect(() => {
    if (cropId && seasonId) {
      summary.fetchStorageLocationsSummary({ cropId, seasonId });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cropId, seasonId]);

  const convertQuantity = useCallback(
    quantity => {
      let convertedQuantity;
      if (unit === MEASUREMENT_UNITS.BUSHEL) {
        const poundsPerBushel = getBushelWeight(cropName);
        convertedQuantity = quantity / poundsPerBushel;
      } else {
        convertedQuantity = convertUnit(
          quantity,
          MEASUREMENT_UNITS.POUND,
          unit
        );
      }
      return convertedQuantity.toLocaleString('en-US', {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2
      });
    },
    [unit, cropName]
  );

  const onSeeLoads = () => {
    const filters = {
      loadtype: [
        {
          sourceType: 'CROP_ZONE',
          destinationType: 'STORAGE'
        },
        {
          sourceType: 'STORAGE',
          destinationType: 'STORAGE'
        }
      ]
    };
    history.push(
      `${paths.yieldV2}/loads?cropsFilters=${cropId}&filters=${JSON.stringify(
        filters
      )}`
    );
  };

  return (
    <StorageLocationsWidget
      className={className}
      isLoading={summary.loading}
      cropName={cropName}
      chartValue={
        +(summary.storageLocationsInfo?.cropPercentage * 100).toFixed()
      }
      storageLocationsCount={summary.storageLocationsInfo?.storageCount}
      totalValue={convertQuantity(
        summary.storageLocationsInfo?.totalQuantityStored
      )}
      totalUnit={unit}
      onSeeLoadsClick={onSeeLoads}
    />
  );
};

ConnectedStorageLocationsWidget.propTypes = {
  cropName: PropTypes.string.isRequired,
  cropId: PropTypes.string.isRequired,
  seasonId: PropTypes.string.isRequired,
  unit: PropTypes.string.isRequired,
  className: PropTypes.string.isRequired
};

export default ConnectedStorageLocationsWidget;
