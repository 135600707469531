/* eslint-disable react/jsx-pascal-case */
import React from 'react';
import Toast from 'components/Toast';
import PropTypes from 'prop-types';
import { Button } from '@agconnections/grow-ui';

const InfoToast = ({
  showToast,
  setShowToast,
  state,
  title,
  errorMessage,
  isBold
}) => {
  return (
    <Toast
      type={state}
      title={title}
      open={showToast}
      onClose={() => {
        setShowToast(false);
      }}
      timeout={5500}
      isBold={isBold}
    >
      {state === 'error' && errorMessage ? (
        <div>
          <p className="text-sm font-normal mb-2">{errorMessage}</p>
          <Button
            size="lg"
            type="primary"
            onClick={() =>
              window.open(
                'https://www.syngentadigital.com/us-en',
                '_blank',
                'noopener'
              )
            }
            style={{ marginLeft: 'auto' }}
          >
            Contact Support
          </Button>
        </div>
      ) : null}
    </Toast>
  );
};

InfoToast.defaultProps = {
  errorMessage: '',
  title: '',
  isBold: false
};

InfoToast.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  errorMessage: PropTypes.string,
  title: PropTypes.string,
  showToast: PropTypes.bool.isRequired,
  setShowToast: PropTypes.func.isRequired,
  state: PropTypes.string.isRequired,
  isBold: PropTypes.bool
};

export default InfoToast;
