import React, { useState, useEffect, useContext } from 'react';
import PropTypes from 'prop-types';
import { Select, Spinner, Search } from '@agconnections/grow-ui';
import ChecklistProperty from 'screens/CropSeasons/CropSeason/components/FormSection/GroupByFilterProperty';
import useCropSeasons from 'hooks/useCropSeasons';
import { addSelectedCropSeasonsToFarms } from 'helpers/propertyHelpers';
import { useFormikContext } from 'formik';
import { Context } from 'components/Store';
import { YieldContext } from 'screens/Yield/context/YieldProvider';
import { getTotalAreaFromFeatures } from 'helpers/getTotalAreaAcres';
import YieldMapView from './Sections/YieldMapView';
import FooterButtons from '../FooterButtons';
import getFilteredCropsByCropSeason from '../../helpers/getFilteredCropsByCropSeasons';

const YieldPropertiesTab = ({
  loading,
  zoom,
  setZoom,
  propertyLandingPageData,
  fieldsAndAreasGeoJSONCollection,
  setGeoJSONCollectionFeatures,
  fieldsLoading,
  filterFeatureCollectionByParentId,
  selectedCropSeasons
}) => {
  const [properties, setProperties] = useState([]);
  const { values, setFieldValue } = useFormikContext();
  const [cropZonesChecked, setCropZonesChecked] = useState(
    values.cropZones.map(({ cropZoneId }) => cropZoneId)
  );
  const { cropSeasons, loaded: cropSeasonsLoaded } = useCropSeasons();

  const {
    state: { cropZones, selectedCommodity },
    updateSelectedCommodity,
    updateCropZones
  } = useContext(YieldContext);
  const [{ selectedYieldCommodity }, dispatch] = useContext(Context);
  const validateCommodityData = () => {
    const commodities = getFilteredCropsByCropSeason(
      selectedCropSeasons,
      cropSeasons
    );
    return commodities.some(commodity => !!commodity)
      ? commodities.filter(item => item)
      : [{ key: '', value: '' }];
  };
  getFilteredCropsByCropSeason(selectedCropSeasons, cropSeasons);
  useEffect(() => {
    if (selectedYieldCommodity.id === '') {
      const commodities = validateCommodityData();
      updateSelectedCommodity({
        id: commodities[0].key,
        name: commodities[0].value
      });

      dispatch({
        type: 'SELECTED_YIELD_COMMODITY',
        payload: {
          id: commodities[0].key,
          name: commodities[0].value
        }
      });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cropSeasons]);

  const handleCommodityChange = event => {
    updateSelectedCommodity({
      id: JSON.parse(event.target.value).key,
      name: JSON.parse(event.target.value).value
    });
    dispatch({
      type: 'SELECTED_YIELD_COMMODITY',
      payload: {
        id: JSON.parse(event.target.value).key,
        name: JSON.parse(event.target.value).value
      }
    });
  };

  const handlePropertySelect = propertiesSelected => {
    if (
      fieldsAndAreasGeoJSONCollection.features &&
      propertiesSelected.length !== cropZones.length
    ) {
      setGeoJSONCollectionFeatures(
        filterFeatureCollectionByParentId(
          fieldsAndAreasGeoJSONCollection,
          undefined,
          propertiesSelected.map(id => ({ id }))
        )
      );

      const mappedProperties = propertiesSelected.reduce((prev, curr) => {
        const cropZone = fieldsAndAreasGeoJSONCollection.features.find(
          item => item.properties.id === curr
        );
        const {
          properties: field
        } = fieldsAndAreasGeoJSONCollection.features.find(
          item => item.properties.id === cropZone.properties.$parentFieldId
        );

        cropZone.properties.fieldName = field.name;

        prev.push(cropZone);

        return prev;
      }, []);

      updateCropZones(mappedProperties);
      setFieldValue('cropZones', mappedProperties);
      setFieldValue('totalAcres', getTotalAreaFromFeatures(mappedProperties));
    }
  };

  useEffect(() => {
    if (propertyLandingPageData.properties) {
      const propertiesWithCropSeasons = addSelectedCropSeasonsToFarms(
        propertyLandingPageData.properties,
        selectedCropSeasons
      );

      setProperties(propertiesWithCropSeasons);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [propertyLandingPageData.properties]);

  useEffect(() => {
    if (!cropZones.length) return;

    const checkedCropZones = cropZones.map(property => property.properties.id);

    setCropZonesChecked(checkedCropZones);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cropZones]);

  return !cropSeasonsLoaded || fieldsLoading || loading ? (
    <Spinner />
  ) : (
    <div className="px-8 bg-white">
      <div className="flex flex-row w-full mb-12 h-560px">
        <div className="w-1/4 pr-8">
          <Select
            label="Select Commodity"
            items={validateCommodityData()}
            value={selectedYieldCommodity?.id}
            onChange={e => {
              handleCommodityChange(e);
            }}
          />
          <div className="mt-6">
            <div>
              <h1 className="font-semibold mb-2">PROPERTIES</h1>
            </div>
            <Search
              id="propertySelect"
              name="properties"
              items={[]}
              onSelect={() => {}}
              placeholder="Search"
            />
            <div className="pt-2 overflow-auto h-440px">
              <ChecklistProperty
                data={properties}
                cropZonesChecked={cropZonesChecked}
                setCropZonesChecked={setCropZonesChecked}
                onChange={handlePropertySelect}
              />
            </div>
          </div>
        </div>
        <YieldMapView
          properties={propertyLandingPageData.properties}
          geoJSONCollection={fieldsAndAreasGeoJSONCollection}
          zoom={zoom}
          setZoom={setZoom}
        />
      </div>
      <FooterButtons
        nextButtonDisabled={!cropZones.length || selectedCommodity.id === ''}
        onBack={() => {}}
      />
    </div>
  );
};

YieldPropertiesTab.propTypes = {
  loading: PropTypes.bool.isRequired,
  zoom: PropTypes.arrayOf(PropTypes.number).isRequired,
  setZoom: PropTypes.func.isRequired,
  propertyLandingPageData: PropTypes.shape().isRequired,
  fieldsAndAreasGeoJSONCollection: PropTypes.shape().isRequired,
  setGeoJSONCollectionFeatures: PropTypes.func.isRequired,
  fieldsLoading: PropTypes.bool.isRequired,
  filterFeatureCollectionByParentId: PropTypes.func.isRequired,
  selectedCropSeasons: PropTypes.arrayOf(PropTypes.string).isRequired
};

export default YieldPropertiesTab;
