import React, { useContext, useEffect } from 'react';
import { Input } from 'syngenta-digital-cropwise-react-ui-kit';
import SearchIcon from 'assets/search.svg';
import useDebounce from 'hooks/useDebounce';
import useProductSearch from 'components/ProductSideBar/helpers/useProductSearch';
import { ProductListContext } from 'screens/ProductList/context/ProductListProvider';

const AddProductSearch = () => {
  const {
    setSearchedProducts,
    setLoadingProductSearch,
    setSearchText,
    searchText
  } = useContext(ProductListContext);
  const timeDelay = 300;
  const filterValue = null;
  const debouncedSearchTerm = useDebounce(searchText, timeDelay);
  const debouncedTypeFilter = useDebounce(filterValue, timeDelay);

  const { filteredProducts, loading } = useProductSearch(
    debouncedSearchTerm,
    debouncedTypeFilter
  );

  useEffect(() => {
    setSearchedProducts(filteredProducts);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filteredProducts]);

  useEffect(() => {
    setLoadingProductSearch(loading);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loading, searchText]);

  return (
    <Input
      style={{ margin: '0' }}
      type="default"
      prefix={<img alt="search" src={SearchIcon} />}
      placeholder="Search products"
      allowClear
      size="middle"
      onChange={e => {
        setSearchText(e.target.value);
      }}
      value={!searchText.length ? '' : searchText}
    />
  );
};

export default AddProductSearch;
