import React from 'react';
import PropTypes from 'prop-types';
import shortId from 'shortid';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faChevronRight,
  faUserCircle
} from '@fortawesome/free-solid-svg-icons';

import { NavLink } from 'react-router-dom';

import withPermissions from 'components/_HOC_/withPermissions';

const LandingListItem = ({
  image,
  placeholderImage,
  columnTwo,
  columnThree,
  subIcon,
  isComfortable,
  header,
  detail,
  routeTo,
  handleClick,
  EditIcon,
  EditLink
}) => {
  return (
    <div key={shortId.generate()}>
      <EditLink
        to={routeTo || ''}
        className="block hover:bg-gray-100 focus:outline-none focus:bg-gray-100 transition duration-150 ease-in-out"
        onClick={
          routeTo || handleClick ? handleClick : event => event.preventDefault()
        }
      >
        <div data-testid="landing-list-item" className="flex items-center p-2">
          <div className="min-w-0 flex-1 flex items-center">
            <div className="flex-shrink-0">
              {image ? (
                <img
                  className={`${
                    isComfortable ? 'h-12 w-12' : 'h-8 w-8'
                  } rounded-full`}
                  src={image}
                  alt=""
                />
              ) : (
                <div className="text-gray-400">{placeholderImage}</div>
              )}
            </div>
            <div className="min-w-0 flex-1 px-2 md:grid md:grid-cols-3 md:gap-4">
              <div className={!detail ? `flex items-center` : ``}>
                <div
                  className={`leading-5 font-medium ${
                    isComfortable ? 'text-base' : 'text-sm'
                  } text-gray-800 truncate`}
                >
                  {header}
                </div>
                {detail && (
                  <div
                    className={`${
                      isComfortable ? 'text-sm mt-2' : 'text-xs'
                    } flex items-center leading-5 text-gray-500`}
                  >
                    {subIcon && (
                      <div className="mr-1 h-5 w-5 text-gray-400">
                        {subIcon}
                      </div>
                    )}
                    <span className="truncate">{detail}</span>
                  </div>
                )}
              </div>
              <div className="flex items-center">{columnTwo}</div>
              <div className="flex items-center">{columnThree}</div>
            </div>
          </div>
          <div>
            <div className="h-5 w-5 text-gray-400">
              <EditIcon
                data-testid="landing-list-item-edit-icon"
                icon={faChevronRight}
                size="1x"
              />
            </div>
          </div>
        </div>
      </EditLink>
    </div>
  );
};

// shared by LandingListItem and ProtectedLandingListItem
const sharedDefaultProps = {
  image: undefined,
  placeholderImage: faUserCircle,
  columnTwo: null,
  columnThree: null,
  subIcon: null,
  isComfortable: true,
  header: '',
  detail: '',
  routeTo: null,
  handleClick: null,
  EditIcon: FontAwesomeIcon,
  EditLink: NavLink
};

LandingListItem.defaultProps = sharedDefaultProps;

// shared by LandingListItem and ProtectedLandingListItem
const sharedPropTypes = {
  image: PropTypes.string,
  placeholderImage: PropTypes.element,
  columnTwo: PropTypes.element,
  columnThree: PropTypes.element,
  subIcon: PropTypes.element,
  isComfortable: PropTypes.bool,
  header: PropTypes.string,
  detail: PropTypes.string,
  routeTo: PropTypes.string,
  handleClick: PropTypes.func,
  EditIcon: PropTypes.elementType,
  EditLink: PropTypes.elementType
};

LandingListItem.propTypes = sharedPropTypes;

export default LandingListItem;

// a variation on NavLink that also has the disabled prop for use with permissions
const EditNavLink = ({ to, className, onClick, disabled, children }) => {
  if (disabled) {
    return children;
  }

  return (
    <NavLink to={to} className={className} onClick={onClick}>
      {children}
    </NavLink>
  );
};

EditNavLink.propTypes = {
  to: PropTypes.string.isRequired,
  className: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
  disabled: PropTypes.bool.isRequired,
  children: PropTypes.node.isRequired
};

// wraps LandingListItem with permissions based on the editRoles prop
export const ProtectedLandingListItem = ({ editRoles, ...props }) => {
  const EditIcon = withPermissions(FontAwesomeIcon, {
    visibleRoles: editRoles
  });
  const EditLink = withPermissions(EditNavLink, {
    enabledRoles: editRoles
  });

  // eslint-disable-next-line react/jsx-props-no-spreading
  return <LandingListItem {...props} EditIcon={EditIcon} EditLink={EditLink} />;
};

ProtectedLandingListItem.propTypes = {
  ...sharedPropTypes,
  editRoles: PropTypes.arrayOf(PropTypes.string)
};

ProtectedLandingListItem.defaultProps = {
  ...sharedDefaultProps,
  editRoles: null
};
