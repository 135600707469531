/* eslint-disable react/prop-types */
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import CreateIcon from '@material-ui/icons/Create';
import { Button } from '@agconnections/grow-ui';
import { Redirect, useParams, withRouter } from 'react-router-dom';
import { paths } from 'routes/paths';
import usePlanMeta from '../../../hooks/usePlanMeta';
import PriceDisplay from './PriceDisplay';

const TopHeader = ({
  isSaving,
  history,
  fixedCost,
  totalProjectedNetRevenue,
  onSubmit,
  perAcreProjectedNetRevenue,
  variableCost,
  selectedview,
  values,
  memberRole,
  memberFinancialAccess
}) => {
  const [redirect, setRedirect] = useState();
  const { readOnly } = usePlanMeta();
  const { id } = useParams();

  const handleEdit = () => {
    if (readOnly) {
      setRedirect(`${paths.plans}/${id}`);
    }
  };

  const handleCancel = () => {
    if (selectedview === 'edit-fromView') {
      history.push(`${paths.plans}/${id}/view`);
    } else {
      history.push(paths.plans);
    }
  };

  return (
    <>
      {redirect && <Redirect push to={redirect} />}
      <div
        data-testid="plan-top-header"
        className="flex items-center justify-between px-12"
        style={{ height: '100px' }}
      >
        <PriceDisplay
          fixedCost={fixedCost || 0}
          variableCost={variableCost || 0}
          totalProjectedNetRevenue={totalProjectedNetRevenue || 0}
          perAcreProjectedNetRevenue={perAcreProjectedNetRevenue || 0}
          values={values}
          memberRole={memberRole}
          memberFinancialAccess={memberFinancialAccess}
        />
        <div className="flex justify-end flex-1">
          <div>
            <Button type="primary" ghost onClick={handleCancel} id="cancelPlan">
              Cancel
            </Button>
          </div>
          <div className="ml-4">
            {readOnly ? (
              <Button
                type="primary"
                onClick={handleEdit}
                id="editPlan"
                icon={<CreateIcon style={{ fontSize: '.8rem' }} />}
              >
                Edit
              </Button>
            ) : (
              <Button
                type="primary"
                onClick={onSubmit}
                disabled={isSaving}
                loading={isSaving}
                id="savePlan"
              >
                Save
              </Button>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

TopHeader.defaultProps = {
  values: {
    crop: ''
  },
  isSaving: false
};

TopHeader.propTypes = {
  values: PropTypes.shape({
    crop: PropTypes.string
  }),
  fixedCost: PropTypes.oneOfType([PropTypes.number, PropTypes.string])
    .isRequired,
  totalProjectedNetRevenue: PropTypes.number.isRequired,
  perAcreProjectedNetRevenue: PropTypes.number.isRequired,
  variableCost: PropTypes.number.isRequired,
  onSubmit: PropTypes.func.isRequired,
  selectedview: PropTypes.string.isRequired,
  history: PropTypes.oneOfType([PropTypes.object]).isRequired,
  isSaving: PropTypes.bool
};

export default withRouter(TopHeader);
