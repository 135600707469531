import React, { useState } from 'react';
import PropTypes from 'prop-types';
import ChevronRight from 'assets/chevron_right.svg';
import ChevronDown from 'assets/chevron_down.svg';

const Accordion = ({ header, children }) => {
  const [isExpanded, setIsExpanded] = useState(true);

  const toggleAccordion = () => {
    setIsExpanded(!isExpanded);
  };

  return (
    <div
      className="bg-white rounded mb-4"
      style={{ boxShadow: '0px 2px 8px rgba(0, 0, 0, 0.15)' }}
    >
      <div className="py-6">
        <button
          className="flex flex-row items-center focus:outline-none justify-between w-full"
          type="button"
          onClick={toggleAccordion}
        >
          <span className="ml-4 text-xl font-semibold">{header}</span>
          <img
            className="pr-16px"
            src={isExpanded ? ChevronDown : ChevronRight}
            alt="expand collapse accordion"
          />
        </button>
      </div>

      {isExpanded && (
        <div className="px-4 pb-4 grid grid-cols-2 col-gap-5 relative">
          {children}
        </div>
      )}
    </div>
  );
};

Accordion.propTypes = {
  header: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired
};

export default Accordion;
