import React from 'react';
import { CropZoneMatcherProvider } from '../../context/CropZoneMatcherProvider';
import CropZoneMatcher from '../..';

const ParentCropZoneMatcherProvider = () => {
  return (
    <CropZoneMatcherProvider>
      <CropZoneMatcher />
    </CropZoneMatcherProvider>
  );
};

export default ParentCropZoneMatcherProvider;
