import React, { useContext } from 'react';
import { Context } from 'components/Store';
import GrowUIFormField from 'components/Forms/GrowUIFormField';
import { Input } from '@agconnections/grow-ui';

const PumpPressure = () => {
  const [{ isEnableInfoTabEdit }] = useContext(Context);
  return (
    <GrowUIFormField
      control={Input}
      id="pumpPressure"
      name="pumpPressure"
      label="Pump Pressure"
      disabled={!isEnableInfoTabEdit}
      placeholder="Enter pump pressure"
    />
  );
};

export default PumpPressure;
