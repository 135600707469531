import React from 'react';
import PropTypes from 'prop-types';
import SubmissionFooter from 'screens/Integrations/components/SubmissionFooter';
import { CalAgContext } from '../../context/Provider';

const CalAgSubmissionFooter = ({
  nextDisabled,
  nextLabel,
  onNext,
  loading,
  setModalSubmissionState,
  isLastStep
}) => {
  return (
    <SubmissionFooter
      nextDisabled={nextDisabled}
      onNext={onNext}
      nextLabel={nextLabel}
      loading={loading}
      setModalSubmissionState={setModalSubmissionState}
      isLastStep={isLastStep}
      context={CalAgContext}
      integrationType="CalAgPermits"
    />
  );
};

CalAgSubmissionFooter.propTypes = {
  nextDisabled: PropTypes.bool,
  nextLabel: PropTypes.string,
  loading: PropTypes.bool,
  onNext: PropTypes.func,
  setModalSubmissionState: PropTypes.func,
  isLastStep: PropTypes.bool
};

CalAgSubmissionFooter.defaultProps = {
  nextDisabled: false,
  nextLabel: 'Next',
  loading: false,
  onNext: () => {},
  setModalSubmissionState: () => {},
  isLastStep: false
};

export default CalAgSubmissionFooter;
