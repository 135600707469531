import React, { useEffect, useState } from 'react';
import { useFormikContext } from 'formik';
import { Button } from '@agconnections/grow-ui';
import { useHistory } from 'react-router-dom';
import DeleteIcon from 'components/Icons/components/DeleteIcon';
import PropTypes from 'prop-types';
import DeleteFieldModal from 'screens/Property/PropertiesLanding/DeleteFieldModal';
import { useFlags } from 'launchdarkly-react-client-sdk';
import DropDownButton from 'components/DropDownButton';
import Toast from 'components/Toast';
import { Context } from 'components/Store';
import SaveButton from './components/SaveButton';
import ArchiveFieldModal from '../../../ArchiveFieldModal';

const Toolbar = ({
  saveButtonDisabled,
  cancelClick,
  saveFieldCreateZone,
  saveFieldDirectToProperties,
  label,
  chkSubmitStatus,
  fieldId,
  setChkNavGuardDisabled
}) => {
  const [fieldName, setFieldName] = useState('');
  const orgId = Context?._currentValue[0]?.organization?.id;
  const flags = useFlags();
  const feArchiveField =
    flags.feArchiveField ||
    (flags.feArchiveFieldOrgIds &&
      flags.feArchiveFieldOrgIds.indexOf(orgId) !== -1);
  const history = useHistory();
  const { values, submitForm } = useFormikContext();
  const [reRenderProperties, setReRenderProperties] = useState(false);
  const [deleteFieldModalOpen, setDeleteFieldModalOpen] = useState(false);
  const [archiveFieldModalOpen, setArchiveFieldModalOpen] = useState(false);
  const closeArchiveFieldModal = () => setArchiveFieldModalOpen(false);
  const closeDeleteFieldModal = () => setDeleteFieldModalOpen(false);
  const [showErrorToast, setShowErrorToast] = useState(false);
  const [showSuccessToast, setShowSuccessToast] = useState(false);
  useEffect(() => {
    if (reRenderProperties) {
      setChkNavGuardDisabled(true);
      history.goBack();
      setReRenderProperties(false);
    }
  }, [reRenderProperties, setChkNavGuardDisabled, history]);

  return (
    <div
      data-testid="property-create-toolbar"
      className={`flex w-full ${label === 'create' ? 'justify-end' : '-ml-2'}`}
      style={{ alignItems: 'center' }}
    >
      {label !== 'create' && (
        <div className="flex-col flex-1">
          <div className="text-sm text-neutral-600" />
          <div data-testid="property-create-total-area" className="text-2xl">
            {feArchiveField ? (
              <DropDownButton
                title="Archive"
                saveButtonDisabled={saveButtonDisabled}
                chkSubmitStatus={chkSubmitStatus}
                onPress={() => setArchiveFieldModalOpen(true)}
                options={[
                  {
                    label: 'Delete Field',
                    danger: true,
                    onClick: () => setDeleteFieldModalOpen(true)
                  }
                ]}
              />
            ) : (
              <Button
                danger
                type="text"
                icon={<DeleteIcon />}
                onClick={() => setDeleteFieldModalOpen(true)}
                style={{ padding: 0 }}
              >
                Delete Field
              </Button>
            )}
          </div>
        </div>
      )}
      <div
        data-testid="property-create-buttons"
        className="flex space-x-3 justify-between align-center"
      >
        <Button
          style={{ height: '50px', padding: 0 }}
          onClick={cancelClick}
          type=""
        >
          Cancel
        </Button>
        <SaveButton
          saveButtonDisabled={saveButtonDisabled}
          saveDrawAgain={() => submitForm()}
          saveCreateCZ={() => saveFieldCreateZone()}
          saveDirectToProp={() => saveFieldDirectToProperties()}
          label={label}
          chkSubmitStatus={chkSubmitStatus}
        />
      </div>
      <ArchiveFieldModal
        open={archiveFieldModalOpen}
        close={closeArchiveFieldModal}
        fieldName={values?.field?.name}
        fieldId={fieldId}
        setReRenderProperties={setReRenderProperties}
        onSuccess={_fieldName => {
          setFieldName(_fieldName || values?.field?.name);
          setShowSuccessToast(true);
        }}
        onError={_fieldName => {
          setFieldName(_fieldName || values?.field?.name);
          setShowErrorToast(true);
        }}
      />
      <DeleteFieldModal
        open={deleteFieldModalOpen}
        close={closeDeleteFieldModal}
        fieldName={values?.field?.name}
        fieldId={fieldId}
        setReRenderProperties={setReRenderProperties}
      />
      <Toast
        type="error"
        title={`${fieldName} was not archived`}
        open={showErrorToast}
        onClose={() => {
          setShowErrorToast(false);
        }}
      >
        <div className="flex flex-col gap-4">
          <p>
            An error prevented your field from being archived. Please try again.
          </p>
          <div className="self-end w-fit font-normal text-center">
            <Button
              onClick={() => {
                window.open(
                  'https://www.syngentadigital.com/us-en',
                  '_blank',
                  'noopener'
                );
              }}
              type="primary"
            >
              Contact Support
            </Button>
          </div>
        </div>
      </Toast>
      <Toast
        type="success"
        title={`${fieldName} archived`}
        open={showSuccessToast}
        timeout={2000}
        onClose={() => {
          setShowSuccessToast(false);
          setReRenderProperties(true);
        }}
      >
        You have archived your field and all associated crop zones
      </Toast>
    </div>
  );
};

Toolbar.defaultProps = {
  saveButtonDisabled: false,
  chkSubmitStatus: false
};

Toolbar.propTypes = {
  saveButtonDisabled: PropTypes.bool,
  cancelClick: PropTypes.func.isRequired,
  saveFieldCreateZone: PropTypes.func.isRequired,
  saveFieldDirectToProperties: PropTypes.func.isRequired,
  label: PropTypes.string.isRequired,
  chkSubmitStatus: PropTypes.bool,
  fieldId: PropTypes.string.isRequired,
  setChkNavGuardDisabled: PropTypes.func.isRequired
};

export default Toolbar;
