import React, { useCallback, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';
import { Row } from 'syngenta-digital-cropwise-react-ui-kit';
import { Spinner } from '@agconnections/grow-ui';
import NoDocumentIcon from 'assets/no_documents_found.svg';
import PersonIcon from 'assets/person.svg';
import EquipmentIcon from 'assets/equipment.svg';
import EmptyTableState from 'components/EmptyTableState';
import { PropertyTypes } from 'helpers/propertyHelpers';
import useTablePaginationAndSort from 'hooks/useTablePaginationAndSort';
import useLoads from 'hooks/useLoads';
import usePeopleData from 'hooks/usePeopleData';
import useLandingPageData from 'hooks/useLandingPageData';
import { paths } from 'routes/paths';
import LoadsTable from 'screens/YieldV2/Loads/components/LoadsTable';
import _ from 'lodash';
import showLoadsTablePagination from '../../helpers/showLoadsTablePagination';

const sortDirMap = {
  ascend: 'asc',
  descend: 'desc'
};

const LoadsTab = ({ cropZones, type }) => {
  const { getLoads, loading: isYieldLoading } = useLoads();
  const { people, loading: isPeopleLoading } = usePeopleData();
  const { items: equipment, loaded } = useLandingPageData(
    'equipments',
    ['name', 'equipmentType'],
    'name',
    -1
  );
  const isEquipmentLoading = !loaded;

  const {
    reactUiKitTableOnChange,
    reactUiKitTablePaginationProp,
    page,
    pageSize,
    sortBy,
    sortDir,
    setTotalItemsCount
  } = useTablePaginationAndSort({
    pagination: {
      showTotal: showLoadsTablePagination
    },
    defaultSortBy: 'date',
    defaultSortDir: 'descend'
  });
  const history = useHistory();

  const [tableData, setTableData] = useState([]);

  const onRecordView = useCallback(
    id => {
      history.push(`${paths.yieldV2}/loads/${id}/view`);
    },
    [history]
  );

  const getAreaFromSources = sources => {
    if (type.toLowerCase() === PropertyTypes.CROP_ZONE) {
      return cropZones[0].reportedArea || cropZones[0].boundaryArea;
    }
    const matchingCropZones = _.compact(
      sources.map(src => cropZones.find(cz => cz.id === src.id))
    );
    return _.sumBy(matchingCropZones, cz => cz.reportedArea || cz.boundaryArea);
  };

  const renderDriverColumn = id => {
    if (!id || !people?.length) return <>---</>;
    const person = people.find(_person => _person.id === id);
    if (!person) return <>---</>;

    return (
      <Row>
        <img className="mr-1" src={PersonIcon} alt="driver-icon" />
        {person.name}
      </Row>
    );
  };
  const renderTruckColumn = id => {
    if (!id || !equipment?.length) return <>---</>;
    const truck = equipment.find(vehicle => vehicle.id === id);
    if (!truck) return <>---</>;

    return (
      <>
        <Row>
          <img className="mr-1" src={EquipmentIcon} alt="driver-icon" />
          {truck.name}
        </Row>
        <Row className="text-xs text-neutral-60">
          {truck.year} {truck.make} {truck.model}
        </Row>
      </>
    );
  };

  useEffect(() => {
    getLoads({
      limit: pageSize,
      page: page - 1,
      sortBy,
      sortDir: sortDirMap[sortDir],
      seasonIds: JSON.parse(localStorage.getItem('selectedCropSeasons')),
      cropZoneIds: cropZones.map(({ id }) => id)
    }).then(({ data, elements }) => {
      const refinedData = data.map(load => ({
        ...load,
        totalArea: getAreaFromSources(load.sources)
      }));
      setTableData(refinedData);
      setTotalItemsCount(elements);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cropZones, sortBy, sortDir, page, pageSize, setTotalItemsCount]);

  if ((!tableData && isYieldLoading) || isEquipmentLoading || isPeopleLoading)
    return <Spinner />;
  if (!tableData?.length && !isYieldLoading)
    return (
      <EmptyTableState
        title="No Yield Loads Found"
        overlayIcon={NoDocumentIcon}
      />
    );
  return (
    <LoadsTable
      tableData={tableData}
      onRecordView={onRecordView}
      pagination={reactUiKitTablePaginationProp}
      onChange={reactUiKitTableOnChange}
      sortBy={sortBy}
      sortDir={sortDir}
      compact
      isCropSortable={type.toLowerCase() === PropertyTypes.FIELD}
      renderDriverColumn={renderDriverColumn}
      renderTruckColumn={renderTruckColumn}
    />
  );
};

LoadsTab.propTypes = {
  cropZones: PropTypes.arrayOf().isRequired,
  type: PropTypes.string.isRequired
};

export default LoadsTab;
