import formatNumber from 'helpers/formatNumber';
import { Maths } from 'helpers/maths';
import { getAbbreviatedUnit } from 'helpers/unitsAbbreviation';

export default function formatShrink(load, loadUnit, shrinkPercentage) {
  if (load && loadUnit && shrinkPercentage) {
    return formatNumber(
      Maths.multiplyRound(load, shrinkPercentage, 2),
      getAbbreviatedUnit(loadUnit),
      2
    );
  }
  return undefined;
}
