import React from 'react';
import PropTypes from 'prop-types';

import { Input, Form } from '@agconnections/grow-ui';

function CityField({ cityName, onChange, errors }) {
  return (
    <Form.Field>
      <div className="block mr-6">
        <div className="w-full">
          <div className="block text-sm font-medium leading-5 gr-text-gray-600 sm:mt-px sm:pt-2">
            City
          </div>
        </div>
        <div className="w-full">
          <Input
            id="city-input"
            onChange={onChange}
            defaultValue={cityName}
            name="cityName"
            error={errors}
            errorMessage={errors}
          />
        </div>
      </div>
    </Form.Field>
  );
}

CityField.defaultProps = {
  cityName: '',
  errors: null
};

CityField.propTypes = {
  cityName: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  errors: PropTypes.string
};

export default CityField;
