import React from 'react';
import { useHistory, useParams } from 'react-router-dom';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronLeft } from '@fortawesome/free-solid-svg-icons';
import { paths } from 'routes/paths';

const CropZoneHeader = () => {
  const { action } = useParams();
  const history = useHistory();

  const title = action === 'edit' ? 'Edit' : 'Add';

  return (
    <div className="flex mb-2">
      <div className="mt-1 mr-2">
        <button type="button" onClick={() => history.push(paths.properties)}>
          <FontAwesomeIcon
            icon={faChevronLeft}
            size="lg"
            style={{ visibility: 'visible' }}
          />
        </button>
      </div>
      <div className="flex-1 flex-col mx-2">
        <div className="text-3xl font-semibold leading-none">
          {title} Crop Zone
        </div>
      </div>
    </div>
  );
};

export default CropZoneHeader;
