import React from 'react';
import PropTypes from 'prop-types';
import { Document, Font, Page, StyleSheet, View } from '@react-pdf/renderer';
import { Header, Footer, Table } from '../components';
import RegularFont from '../fonts/Noto_Sans/NotoSans-Regular.ttf';
import MediumFont from '../fonts/Noto_Sans/NotoSans-Medium.ttf';
import SemiBoldFont from '../fonts/Noto_Sans/NotoSans-SemiBold.ttf';
import VendorDetails from './components/VendorDetails';
import Notes from '../components/Notes';
import { INVOICE_PRODUCT_HEADER_ITEMS } from './helpers/tableMaker';

Font.register({
  family: 'Noto Sans',
  fonts: [{ src: RegularFont }, { src: MediumFont }, { src: SemiBoldFont }]
});

const styles = StyleSheet.create({
  body: {
    paddingHorizontal: 20,
    paddingBottom: 85
  },
  overallView: {
    paddingHorizontal: 10
  },
  viewContainer: {
    marginBottom: 27
  },
  vendorDetails: {
    width: '60%',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: 15
  }
});

const InvoicePdf = ({ invoice }) => {
  return invoice ? (
    <Document>
      <Page style={styles.body}>
        <Header
          type="Invoice"
          title={invoice.title}
          organization={invoice.organizationName}
          cropSeasons={invoice.cropSeasons.map(cs => cs.name)}
        />
        <View style={styles.overallView}>
          <View style={styles.vendorDetails}>
            <VendorDetails
              vendorName={invoice.vendorName}
              invoiceDate={
                invoice.invoiceDateEpoch > 0
                  ? new Date(invoice.invoiceDate).toLocaleDateString()
                  : '--'
              }
              invoiceDueDate={
                invoice.invoiceDueDateEpoch > 0
                  ? new Date(invoice.invoiceDueDate).toLocaleDateString()
                  : '--'
              }
            />
          </View>
          <View style={styles.viewContainer}>
            <Table
              headerItems={INVOICE_PRODUCT_HEADER_ITEMS}
              bodyItems={invoice.mappedInvoiceProductEntries}
            />
          </View>

          <View style={styles.viewContainer}>
            <Notes notes={invoice.notes} />
          </View>
        </View>
        <Footer year={invoice.year} fullDate={invoice.fullDate} />
      </Page>
    </Document>
  ) : null;
};

InvoicePdf.propTypes = {
  invoice: PropTypes.shape()
};

InvoicePdf.defaultProps = {
  invoice: null
};

export default InvoicePdf;
