/* eslint-disable react/jsx-props-no-spreading */
import React, { useState } from 'react';
import { Popup } from '@agconnections/grow-ui';
import ClickableDiv from 'components/ClickableDiv';

/**
 * @param {React.Component} WrappedComponent - the component to be wrapped
 * @param {React.Component} msg - message text
 * @param {Object} options - set of options to configure hover popup
 * @param {String} options.position - [bottom, top, left, right] - position of popup relative to wrapped component
 * @param {Number} options.delay - delay in ms (before popup shows)
 * @param {Number} options.ariaLabel - arial label for popup
 * @param {String} options.top - adjust popup position from top
 */
const withHoverPopup = (
  WrappedComponent,
  msg,
  { position = 'left', delay = 2000, ariaLabel, top }
) => {
  const HoverPopupWrapper = props => {
    const [hoverTimeout, setHoverTimeout] = useState(null);
    const [showPopup, setShowPopup] = useState(false);

    const onMouseEnter = () =>
      setHoverTimeout(setTimeout(() => setShowPopup(true), delay));

    const onMouseLeave = () =>
      clearTimeout(hoverTimeout) || setShowPopup(false);

    return (
      <ClickableDiv onMouseEnter={onMouseEnter} onMouseLeave={onMouseLeave}>
        <div className="relative" aria-label={ariaLabel}>
          <div className="absolute" style={{ top }}>
            <Popup
              position={position}
              open={showPopup}
              hasArrow
              trigger={<div />}
            >
              <div className="whitespace-no-wrap">{msg}</div>
            </Popup>
          </div>
        </div>
        <WrappedComponent {...props} />
      </ClickableDiv>
    );
  };

  return HoverPopupWrapper;
};

export default withHoverPopup;
