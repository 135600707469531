const processCropZone = (cropMap, cropzone, farm, field) => {
  const cropId = cropzone.crop.id;
  // Ensure the crop entry exists in the cropMap
  if (!cropMap[cropId]) {
    // eslint-disable-next-line no-param-reassign
    cropMap[cropId] = { name: cropzone.crop.name, farms: [] };
  }
  // Find or create the farm under the crop
  let existingFarm = cropMap[cropId].farms.find(f => f.id === farm.id);
  if (!existingFarm) {
    existingFarm = { ...farm, fields: [] };
    cropMap[cropId].farms.push(existingFarm);
  }
  // Find or create the field under the farm
  let existingField = existingFarm.fields.find(f => f.id === field.id);
  if (!existingField) {
    existingField = { ...field, cropzones: [] };
    existingFarm.fields.push(existingField);
  }
  // Add the cropzone to the field
  existingField.cropzones.push(cropzone);
};

const groupByCrop = data => {
  const cropMap = {};
  data.forEach(farm => {
    farm.fields.forEach(field => {
      field.cropzones.forEach(cropzone => {
        processCropZone(cropMap, cropzone, farm, field);
      });
    });
  });
  return Object.entries(cropMap)
    .map(([id, crop]) => ({ id, ...crop }))
    .sort((a, b) => a.name.localeCompare(b.name));
};

export default groupByCrop;
