import React from 'react';
import PropTypes from 'prop-types';

import { Form } from '@agconnections/grow-ui';

const EditScreenField = ({ label, children }) => {
  return (
    <Form.Field>
      <div className="flex flex-col sm:flex-row">
        <div className="w-1/2">
          <div className="block text-sm font-medium leading-5 text-gray-700 sm:mt-px sm:pt-2 mb-2 sm:mb-0">
            {label}
          </div>
        </div>
        <div className="w-full md:w-1/2">{children}</div>
      </div>
    </Form.Field>
  );
};

EditScreenField.defaultProps = {
  label: ''
};

EditScreenField.propTypes = {
  label: PropTypes.string,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.object),
    PropTypes.object
  ]).isRequired
};

export default EditScreenField;
