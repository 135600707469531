export const getFixedCostValuesStatus = (role, finAccess, editCheck, value) => {
  if (role === 'Full control' && finAccess === 'READ' && editCheck !== 'plan') {
    return value;
  }
  if (role === 'Full control' && finAccess === 'none') {
    return null;
  }
  return value;
};

export const getProjectedRevenueValuesStatus = (
  role,
  finAccess,
  editCheck,
  value
) => {
  if (role === 'Full control' && finAccess === 'READ' && editCheck !== 'plan') {
    return value;
  }
  if (role === 'Full control' && finAccess === 'none') {
    return null;
  }
  return value;
};
