import React from 'react';
import PropTypes from 'prop-types';
import { Col, Row } from 'antd';

import { ReactComponent as MapboxLogo } from 'assets/mapbox_logo_black.svg';
import { ReactComponent as CropwiseFinancialsLogo } from 'assets/cropwise_financials_logo_black.svg';
import PropertyMapFooter from '../PropertyMapFooter';

export const MAP_PREVIEW_ID = 'map-pdf-preview';
export const LEGEND_PREVIEW_ID = 'legend-pdf-preview';

const PDFPage = ({ id, pageNo, children, mapLegend = null }) => {
  const pageWidth = 792; // 11 inches in pixels at 72 DPI
  const pageHeight = 612; // 8.5 inches in pixels at 72 DPI
  const marginSize = 36; // 0.5 inch margins (72px

  return (
    <div
      id={id}
      className="relative flex flex-col bg-white shadow-sm-card"
      style={{
        width: pageWidth,
        height: pageHeight,
        padding: marginSize,
        marginTop: id === MAP_PREVIEW_ID ? 0 : 8
      }}
    >
      <div className="flex flex-grow flex-col">{children}</div>

      <Row className="bg-white h-72px items-end" justify="space-between">
        <PropertyMapFooter />
        <Col>
          <Row className="items-end" gutter={8}>
            <MapboxLogo />
            <CropwiseFinancialsLogo />
            {mapLegend}
            {pageNo && (
              <Col className="ml-2" style={{ fontSize: 10 }}>
                {pageNo}
              </Col>
            )}
          </Row>
        </Col>
      </Row>
    </div>
  );
};

PDFPage.propTypes = {
  id: PropTypes.string,
  pageNo: PropTypes.number,
  children: PropTypes.node.isRequired,
  mapLegend: PropTypes.node
};

PDFPage.defaultProps = {
  id: '',
  pageNo: null,
  mapLegend: null
};

export default PDFPage;
