import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import FormattedNumber from 'components/FormattedNumber';
import { Tooltip } from 'syngenta-digital-cropwise-react-ui-kit';
import HelpOutlineIcon from 'assets/help_outline.svg';
import { Context } from 'components/Store';

const CostItemRow = ({ title, perAcre, totalValue, isResult, tooltip }) => {
  const isCost = totalValue < 0;
  const isRevenue = totalValue > 0;
  let textValueStyle = '';
  if (isCost) textValueStyle = 'text-red-60';
  if (isRevenue) textValueStyle = 'text-green-60';
  if (isResult) textValueStyle += ' font-bold';

  const [{ isSidebarOpen }] = useContext(Context);

  return (
    <div
      className={`flex text-sm flex-1 border-b border-neutral-20 py-3 px-4 ${
        isResult ? 'bg-neutral-20' : ''
      }`}
    >
      <div className={`flex-1 flex gap-1 ${isResult ? 'font-semibold' : ''}`}>
        {title}
        {!!tooltip && (
          <Tooltip title={tooltip} trigger="hover" color="#232630">
            <div className="flex flex-row items-center">
              <img src={HelpOutlineIcon} alt="help outline icon" />
              {!isSidebarOpen && (
                <div
                  data-testid="tooltip-description"
                  className="ml-px text-xs text-blue-60 tracking-tighter"
                >
                  How is this calculated?
                </div>
              )}
            </div>
          </Tooltip>
        )}
      </div>
      <div className="flex-1 flex justify-end gap-x-1 items-end">
        <span className={textValueStyle}>
          <FormattedNumber unit="usCurrency" roundedTo={2}>
            {perAcre}
          </FormattedNumber>
        </span>
        <span className="text-xs text-neutral-80">/ac</span>
      </div>
      <div className="flex-1 flex justify-end gap-x-1 items-end">
        <span className={textValueStyle}>
          <FormattedNumber unit="usCurrency" roundedTo={2}>
            {totalValue}
          </FormattedNumber>
        </span>
      </div>
    </div>
  );
};

CostItemRow.defaultProps = {
  tooltip: null,
  perAcre: 0,
  totalValue: 0
};

CostItemRow.propTypes = {
  title: PropTypes.string.isRequired,
  isResult: PropTypes.bool.isRequired,
  perAcre: PropTypes.number,
  totalValue: PropTypes.number,
  tooltip: PropTypes.string
};

export default CostItemRow;
