import React from 'react';
import PropTypes from 'prop-types';
import { Table } from '@agconnections/grow-ui';
import { customDateFormat } from 'helpers/dateFormat';

import defaultIcon from 'assets/AllCropSVGs/fallow.svg';
import CropIcons from 'helpers/getCropIconByCropName';
import CropIconMapping from 'components/CropIconMapping';
import FieldIcon from '../../../../../../../components/Icons/FieldIcon';
import toCamelCase from '../../helpers/toCamelCase';

const PropertyCropHistoryFullRow = ({ data }) => {
  const roundDecimal = data?.appliedArea?.toFixed(2);
  const empty = '---';
  const regex = / /g;
  const scrubbedProduct = {
    ...data,
    name: data?.cropname?.replace(regex, '')
  };
  const cropName = scrubbedProduct?.name?.includes(':')
    ? scrubbedProduct?.name
        .split(':')
        .map(part => toCamelCase(part.trim()))
        .join('')
    : scrubbedProduct?.name;

  return (
    <Table.Row className="bg-white">
      <Table.Cell>{data?.cropseason ?? empty}</Table.Cell>
      <Table.Cell>
        <div className="flex gap-1">
          {cropName in CropIcons ? (
            <CropIconMapping cropObject={scrubbedProduct} />
          ) : (
            <img src={defaultIcon} alt="crop" />
          )}
          {data?.cropname}
        </div>
      </Table.Cell>
      <Table.Cell>
        <div>{data?.variety ?? empty}</div>
      </Table.Cell>
      <Table.Cell>
        <div>
          {data.plantingDate ? (
            <div>{customDateFormat(data?.plantingDate, 'MMM dd, yyyy')}</div>
          ) : (
            empty
          )}
        </div>
      </Table.Cell>
      <Table.Cell>
        {data?.appliedArea ? (
          <div>
            <div className="flex gap-1 items-start">
              <FieldIcon
                id="field-card-icon"
                width={18}
                height={18}
                features={[{ geometry: data.geometry, type: 'Feature' }]}
              />
              <div>{data?.cropname}</div>
            </div>
            <div className="text-xs text-neutral-60 text-left">
              {`${roundDecimal} ac`}
            </div>
          </div>
        ) : (
          empty
        )}
      </Table.Cell>
    </Table.Row>
  );
};

PropertyCropHistoryFullRow.propTypes = {
  data: PropTypes.shape({
    cropname: PropTypes.string.isRequired,
    cropseason: PropTypes.string.isRequired,
    variety: PropTypes.string,
    plantingDate: PropTypes.string,
    appliedArea: PropTypes.number,
    geometry: PropTypes.objectOf(PropTypes.shape())
  }).isRequired
};

export default PropertyCropHistoryFullRow;
