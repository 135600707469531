/* eslint-disable import/prefer-default-export */
import * as Yup from 'yup';

export const EditCustomProductSchema = Yup.object().shape({
  name: Yup.string().required('Required'),
  makeUp: Yup.string().required('Required'),
  type: Yup.string().required('Required'),
  manufacturer: Yup.string().required('Required'),
  standardUnit: Yup.string().required('Required'),
  averagePricePerUnit: Yup.number().positive(
    'List Price must be a positive number'
  ),
  densityInLbsPerGallon: Yup.number().when('makeUp', {
    is: 'LIQUID',
    then: Yup.number()
      .required('Required')
      .positive('Density must be a positive number'),
    otherwise: Yup.number()
      .nullable()
      .notRequired()
  }),
  density: Yup.number().when('makeUp', {
    is: 'LIQUID',
    then: Yup.number()
      .required('Required')
      .positive('Density must be a positive number'),
    otherwise: Yup.number()
      .nullable()
      .notRequired()
  })
});

export const EditCustomProductSchemaForService = Yup.object().shape({
  type: Yup.string().required('Required'),
  name: Yup.string().required('Required'),
  manufacturer: Yup.string().required('Required'),
  standardUnit: Yup.string().required('Required')
});
