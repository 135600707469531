import { useCallback, useContext } from 'react';
import { useHistory } from 'react-router-dom';
import useQuery from 'helpers/useQuery';
import { AmplitudeContext } from 'utilities/amplitude/useAmplitude';

const useIntegrationsSubmission = () => {
  const { amplitude } = useContext(AmplitudeContext);
  const query = useQuery();
  const history = useHistory();

  const submissionId = query.get('submission');
  const vendor = query.get('vendor');

  const redirectToSubmission = useCallback(() => {
    history.goBack();
    amplitude.sendEventToAmplitude(
      amplitude.events.epic.Tasks.cancelTaskSuccess
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return {
    isComingFromIntegrations: submissionId && vendor,
    redirectToSubmission
  };
};

export default useIntegrationsSubmission;
