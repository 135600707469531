import React from 'react';
import PropTypes from 'prop-types';
import { Spinner, Table } from '@agconnections/grow-ui';
import EmptyTableState from 'components/EmptyTableState';

import emptySearch from 'assets/noNotesFound.png';
import noResultsSearch from 'assets/no_search_results_found.svg';
import AgVendInvoiceTableRow from '../AgVendInvoicesTableRow';

const AgVendInvoiceTable = ({
  filteredInvoices,
  handleSelection,
  selectedInvoices,
  isLoading,
  isCropSeasonSelected = true,
  PaginationComponent
}) => {
  return (
    <>
      {!isCropSeasonSelected ? (
        <div className="py-24">
          <EmptyTableState
            title="No Crop Season Selected"
            overlayIcon={emptySearch}
            dataTestId="empty-view-no-invoice"
          />
        </div>
      ) : (
        <div>
          {isLoading ? (
            <div
              data-testid="loading-agvend-records"
              className="w-full h-full py-24 flex flex-col justify-center items-center"
            >
              <Spinner />
              <p className="text-sm font-semibold mt-4">Loading records...</p>
            </div>
          ) : (
            <>
              {filteredInvoices?.length !== 0 ? (
                <div data-testid="invoices-table">
                  <Table>
                    <Table.Header>
                      <Table.Cell />
                      <Table.Cell>Invoice #</Table.Cell>
                      <Table.Cell>Date</Table.Cell>
                      <Table.Cell>Retailer</Table.Cell>
                      <Table.Cell>Products</Table.Cell>
                      <Table.Cell>Last Import</Table.Cell>
                    </Table.Header>

                    {filteredInvoices?.map(inv => (
                      <AgVendInvoiceTableRow
                        invoice={inv}
                        selectedInvoices={selectedInvoices}
                        handleSelection={handleSelection}
                        key={inv.id}
                      />
                    ))}
                  </Table>
                  <div style={{ minHeight: '35px' }}>
                    {PaginationComponent && <PaginationComponent />}
                  </div>
                </div>
              ) : (
                <div className="py-24">
                  <EmptyTableState
                    title="No Records Found"
                    message="Try a different search."
                    overlayIcon={noResultsSearch}
                    dataTestId="empty-view-no-invoice"
                  />
                </div>
              )}
            </>
          )}
        </div>
      )}
    </>
  );
};

AgVendInvoiceTable.propTypes = {
  filteredInvoices: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      description: PropTypes.string,
      startDate: PropTypes.string,
      fields: PropTypes.arrayOf(
        PropTypes.shape({
          name: PropTypes.string
        })
      ),
      products: PropTypes.arrayOf(
        PropTypes.shape({
          productName: PropTypes.string
        })
      )
    })
  ).isRequired,
  handleSelection: PropTypes.func.isRequired,
  selectedInvoices: PropTypes.arrayOf(PropTypes.shape({ id: PropTypes.string }))
    .isRequired,
  isLoading: PropTypes.bool.isRequired,
  isCropSeasonSelected: PropTypes.bool.isRequired,
  PaginationComponent: PropTypes.oneOfType([
    PropTypes.elementType, // For React components
    PropTypes.func // For functions that return JSX
  ]).isRequired
};

export default AgVendInvoiceTable;
