import React from 'react';
import WarningIcon from 'assets/warning.svg';
import PropTypes from 'prop-types';

const MaintenanceLandingComponentStyle = {
  background: '#FFF3DD',
  border: '1px solid #F0C355',
  padding: '12px 16px 12px 16px',
  bottom: '-11rem'
};

const MaintenanceLandingComponent = ({ featureFlagText }) => {
  return (
    <div
      className="absolute rounded flex"
      style={MaintenanceLandingComponentStyle}
    >
      <div className="w-22 mr-4">
        <img src={WarningIcon} alt="CropWise Warning Icon" />
      </div>
      <div>
        <h2 className="text-neutral-1000 text-base font-bold">
          Undergoing Maintenance
        </h2>
        <p className="text-sm">
          Cropwise Financials is temporarily unavailable due to planned
          maintenance. We expect to be back today around{' '}
          <span className="font-bold">{featureFlagText}</span>. Thank you for
          your patience.
        </p>
      </div>
    </div>
  );
};

MaintenanceLandingComponent.propTypes = {
  featureFlagText: PropTypes.string.isRequired
};

export default MaintenanceLandingComponent;
