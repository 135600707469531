import { Response } from 'miragejs';

// Responses
const badRequest = error => new Response(400, {}, { message: error });
const notAllowed = name => badRequest(`'${name}' is not allowed`);
const isRequired = (name, method) =>
  badRequest(`'${name}' is required on ${method}`);

/**
 * validates POST request for Standard Field errors
 * @param {Request} requestBody - the request body to be validated.
 */
const validatePost = (requestBody, requiredFields) => {
  const badPostFields = ['_id', 'active', 'createdAt', 'updatedAt', 'version'];
  const badField = badPostFields.find(key =>
    Object.prototype.hasOwnProperty.call(requestBody, key)
  );

  if (badField) return notAllowed(badField);
  if (requiredFields) {
    const fieldsRequired = [];
    requiredFields.forEach(item => {
      const foundField = requiredFields.find(() =>
        Object.prototype.hasOwnProperty.call(requestBody, item)
      );
      if (!foundField) fieldsRequired.push(item);
    });
    if (fieldsRequired.length > 0)
      return isRequired(fieldsRequired.join(), 'POST');
  }
  return null;
};

/**
 * validates PUT request for Standard Field errors
 * @param {Request} requestBody - the request body to be validated.
 */
const validatePut = (requestBody, requiredFields) => {
  const badPutFields = ['_id', 'active', 'createdAt', 'updatedAt'];
  const fieldsToCheck = requiredFields !== undefined ? requiredFields : [];
  const badField = badPutFields.find(key =>
    Object.prototype.hasOwnProperty.call(requestBody, key)
  );

  if (badField) return notAllowed(badField);
  fieldsToCheck.push('version');
  const fieldsRequired = [];
  fieldsToCheck.forEach(item => {
    const foundField = fieldsToCheck.find(() =>
      Object.prototype.hasOwnProperty.call(requestBody, item)
    );
    if (!foundField) fieldsRequired.push(item);
  });
  if (fieldsRequired.length > 0)
    return isRequired(fieldsRequired.join(), 'PUT');

  return null;
};

const withStandardErrors = (callback, requiredFields) => {
  const standardErrorWrapper = (schema, request) => {
    const { method, requestBody } = request;
    const parsedBody = JSON.parse(requestBody);
    let error;
    if (method === 'POST') error = validatePost(parsedBody, requiredFields);
    if (method === 'PUT') error = validatePut(parsedBody, requiredFields);
    return error || callback(schema, request);
  };
  return standardErrorWrapper;
};

export default withStandardErrors;
