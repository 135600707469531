import Decimal from 'decimal.js';
import { Maths } from './maths';
import { convertNumberToDesiredFormat } from './transformHelpers';

export default function formatNumber(
  number,
  unit,
  roundedTo = 2,
  roundingType = Decimal.ROUND_HALF_EVEN
) {
  if (number == null) return undefined;

  const unitText = unit ? ` ${unit}` : '';
  return `${convertNumberToDesiredFormat(
    Maths.parseFloatRound(number, roundedTo, roundingType),
    'fixed',
    roundedTo
  )}${unitText}`;
}

export function formatPercentage(percentDecimal) {
  if (!percentDecimal) {
    return undefined;
  }
  return convertNumberToDesiredFormat(
    Maths.parseFloatRound(percentDecimal, 4),
    'percent',
    2
  );
}
export const formatCurrency = amount => {
  if (amount && !Number.isFinite(amount)) {
    try {
      // eslint-disable-next-line no-param-reassign
      amount = new Decimal(amount).toNumber();
    } catch (error) {
      return undefined;
    }
  }
  const { format } = Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD'
  });

  return format(amount);
};
