import React, { useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import { Redirect } from 'react-router-dom';

import { Spinner } from '@agconnections/grow-ui';

import { Context } from 'components/Store';
import Breadcrumb from 'components/App/AppShell/components/Breadcrumb';

const Forbidden403 = ({ redirect }) => {
  const [isLoading, setIsLoading] = useState(true);
  const [{ loadingOrg, loggedInUserOrgPermission }] = useContext(Context);

  useEffect(() => {
    setIsLoading(loadingOrg || loggedInUserOrgPermission?.email === undefined);
  }, [loadingOrg, loggedInUserOrgPermission]);

  const handleOrgChange = () => {
    window.location.reload();
  };

  return (
    <div className="w-full h-full" data-testid="people">
      {/*  Breadcrumb component needs to be called to load user permissions and org */}
      <Breadcrumb onOrganizationSelect={handleOrgChange} />
      {isLoading ? (
        <Spinner />
      ) : (
        <>
          {redirect ? (
            <Redirect
              push
              to={{
                pathname: redirect
              }}
            />
          ) : (
            <div
              className="flex flex-col items-center justify-center h-64 bg-white rounded shadow-md radius"
              data-testid="forbidden-testid"
            >
              <div className="text-xs text-gray-500">Error code: 403</div>
              <div className="px-4 text-xl leading-6 text-center text-gray-800">
                Forbidden
              </div>
              <div className="px-4 mt-2 mb-10 text-base leading-5 text-center text-gray-700">
                You are not authorized to view this page
              </div>
            </div>
          )}
        </>
      )}
    </div>
  );
};

Forbidden403.propTypes = {
  redirect: PropTypes.string
};

Forbidden403.defaultProps = {
  redirect: ''
};

export default Forbidden403;
