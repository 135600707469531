import { useState, useCallback } from 'react';
import {
  products as productsApi,
  product as productApi,
  inventoryProduct
} from 'utilities/api';
import catchCancel from 'helpers/catchCancel';
import { getCompatibleUnits } from 'helpers/unitConversionHelpers';

const useProduct = () => {
  const [product, setProduct] = useState(null);

  const getProduct = useCallback(id => {
    const { promise } = inventoryProduct.fetch(id);
    promise.then(({ data }) => setProduct(data)).catch(() => setProduct(null));
  }, []);

  const getMasterListProductUnits = async id => {
    const { promise } = productsApi.fetch(id);

    return promise
      .then(response => {
        const { stdunit, stdpackageunit, density } = response.data;

        return getCompatibleUnits([stdunit, stdpackageunit], density);
      })
      .catch(catchCancel)
      .catch(() => []);
  };

  const getProductUnits = async id => {
    const { promise } = productApi.fetch(id);

    return promise
      .then(response => {
        const { standardUnit, stdPackageUnit, density } = response.data;

        return getCompatibleUnits([standardUnit, stdPackageUnit], density);
      })
      .catch(catchCancel)
      .catch(() => []);
  };

  return {
    getMasterListProductUnits,
    getProductUnits,
    getProduct,
    product
  };
};

export default useProduct;
