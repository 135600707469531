import React from 'react';
import PropTypes from 'prop-types';
import ChecklistProperty from 'screens/CropSeasons/CropSeason/components/FormSection/GroupByFilterProperty';
import { TProperty } from '../../helpers/constants';

const PropertySelector = ({
  properties,
  setCropZonesChecked,
  cropZonesChecked
}) => {
  return (
    <ChecklistProperty
      data={properties}
      cropZonesChecked={cropZonesChecked}
      setCropZonesChecked={setCropZonesChecked}
      disabled={false}
    />
  );
};

PropertySelector.propTypes = {
  cropZonesChecked: PropTypes.arrayOf(PropTypes.string).isRequired,
  properties: PropTypes.arrayOf(TProperty).isRequired,
  setCropZonesChecked: PropTypes.func.isRequired
};

export default PropertySelector;
