import React, { useMemo, useState } from 'react';
import PropTypes from 'prop-types';

import { Popup, Spinner, Checkbox } from '@agconnections/grow-ui';
import KeyboardArrowUp from '@material-ui/icons/KeyboardArrowUp';
import KeyboardArrowDown from '@material-ui/icons/KeyboardArrowDown';

const OrganizationsDropdown = ({
  onSelect,
  organizations,
  csLoaded,
  selectedId,
  isRequired,
  vendor = ''
}) => {
  const [dropDownOpened, setDropDownOpened] = useState(false);

  const selectedItem = useMemo(
    () => selectedId && organizations.find(o => o.id === selectedId),
    [selectedId, organizations]
  );

  const OrganizationsBoxTrigger = (
    <button
      data-testid="organizations-trigger-box"
      className="w-full focus:outline-none"
      type="button"
      tabIndex={0}
      onClick={() => {
        setDropDownOpened(!dropDownOpened);
      }}
      onKeyDown={event =>
        event.nativeEvent.key === 'Enter' && setDropDownOpened(!dropDownOpened)
      }
    >
      <div className="pb-2 w-full">
        <div
          id="dropDown"
          className="text-neutral-600 text-left mb-1 text-sm"
          data-testid="organizations-heading"
        >
          {vendor}
          {isRequired ? 'Organization*' : 'Organization'}
        </div>
        <div
          className="flex flex-wrap justify-between border border-neutral-30 rounded h-10"
          data-testid="organizations-select"
        >
          <div className="flex">
            {csLoaded ? (
              <div
                data-testid="organizations-name"
                className="flex flex-no-wrap bg-gray-100 rounded mx-1 my-2 justify-start"
              >
                <div
                  className="whitespace-nowrap p-1 text-xs text-neutral-600 font-semibold"
                  data-testid="organizations-name"
                >
                  {selectedItem?.name || 'Select Organization'}
                </div>
              </div>
            ) : (
              <Spinner />
            )}
          </div>
          <div className="flex justify-end">
            {dropDownOpened ? (
              <KeyboardArrowUp
                style={{
                  color: '#696F88',
                  marginTop: '8px',
                  marginRight: '4px',
                  marginLeft: '6px'
                }}
              />
            ) : (
              <KeyboardArrowDown
                style={{
                  color: '#696F88',
                  marginTop: '8px',
                  marginRight: '4px',
                  marginLeft: '6px'
                }}
              />
            )}
          </div>
        </div>
      </div>
    </button>
  );

  return (
    <Popup
      trigger={OrganizationsBoxTrigger}
      position="bottom"
      align="left"
      hasOffset
      open={dropDownOpened}
      onClose={() => setDropDownOpened(false)}
      containerClasses="w-full"
    >
      <div data-testid="organizations-popup" className="w-92 h-full text-left">
        {csLoaded ? (
          <>
            <div className="font-semibold text-sm mb-2 ml-2">Organizations</div>
            <ul className="mb-2  ml-2">
              {organizations.length === 0 ? (
                <div className="font-normal text-sm ml-4">
                  No Available Organizations
                </div>
              ) : (
                organizations.map(item => (
                  <Checkbox
                    key={item.id}
                    label={item.name}
                    value={item.id}
                    checked={selectedId === item.id}
                    onChange={e => {
                      setDropDownOpened(false);
                      onSelect(e.target.checked ? item.id : null);
                    }}
                  />
                ))
              )}
            </ul>
          </>
        ) : (
          <Spinner />
        )}
      </div>
    </Popup>
  );
};
OrganizationsDropdown.defaultProps = {
  organizations: [],
  selectedId: null,
  vendor: ''
};
OrganizationsDropdown.propTypes = {
  csLoaded: PropTypes.bool.isRequired,
  onSelect: PropTypes.func.isRequired,
  organizations: PropTypes.arrayOf(PropTypes.object),
  selectedId: PropTypes.string,
  isRequired: PropTypes.bool.isRequired,
  vendor: PropTypes.string
};

export default OrganizationsDropdown;
