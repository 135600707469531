import React, { useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import { Table } from '@agconnections/grow-ui';

const FieldProductsTable = ({ products }) => {
  const [productsOrdered, setProductsOrdered] = useState(products);
  const [sortField, setSortField] = useState('date');
  const [sortOrder, setSortOrder] = useState('desc');

  const handleSort = useCallback((field, order) => {
    setSortField(field);
    setSortOrder(order);
    setProductsOrdered(
      [...products].sort((product1, product2) => {
        const a = order === 'asc' ? product1 : product2;
        const b = order === 'asc' ? product2 : product1;

        if (field === 'productName') {
          return a.productName?.localeCompare(b.productName);
        }
        return a.appliedDateEpoch - b.appliedDateEpoch;
      })
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Table>
      <Table.Header>
        <Table.Cell
          sort={sortField === 'date' ? sortOrder : ''}
          sortable
          onClick={() =>
            handleSort('date', sortOrder === 'desc' ? 'asc' : 'desc')
          }
        >
          Date
        </Table.Cell>
        <Table.Cell
          sort={sortField === 'productName' ? sortOrder : ''}
          sortable
          onClick={() =>
            handleSort('productName', sortOrder === 'desc' ? 'asc' : 'desc')
          }
        >
          Cropwise Product
        </Table.Cell>
        <Table.Cell>Product Type</Table.Cell>
        <Table.Cell>Application Method</Table.Cell>
        <Table.Cell>Rate (per acre)</Table.Cell>
        <Table.Cell>Total Applied</Table.Cell>
      </Table.Header>
      {productsOrdered?.map(product => {
        // The API does not provide the unit in a separate property.
        // To display the total unit, we extract it from the `product.rate` property.
        // The `product.rate` includes both the value and the unit, e.g., "1123.0 fluid ounce".
        // This logic isolates and returns only the unit part (e.g., "fluid ounce").
        const unit = product.rate
          ?.split(' ')
          ?.slice(1)
          ?.join(' ');
        return (
          <Table.Row key={product.id}>
            <Table.Cell>
              {new Date(product.appliedDate).toLocaleDateString()}
            </Table.Cell>
            <Table.Cell>
              <div className="flex flex-col justify-start text-left">
                <span className="font-bold mb-1" data-testid="product-name">
                  {product.productName ?? product.name}
                </span>
                <span className="text-neutral-600">
                  {product.manufacturerName ?? product.manufacturer}
                </span>
              </div>
            </Table.Cell>
            <Table.Cell>{product.type}</Table.Cell>
            <Table.Cell>{product.applicationMethod}</Table.Cell>
            <Table.Cell>{product.rate}</Table.Cell>
            <Table.Cell>
              {product.total.toFixed(2)} {unit}
            </Table.Cell>
          </Table.Row>
        );
      })}
    </Table>
  );
};

FieldProductsTable.propTypes = {
  products: PropTypes.arrayOf(PropTypes.object)
};

FieldProductsTable.defaultProps = {
  products: []
};

export default FieldProductsTable;
