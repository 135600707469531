import React from 'react';
import PropTypes from 'prop-types';
import { Column, Columns } from '@agconnections/grow-ui';
import ViewOnlyField from 'components/ViewOnlyField';
import { standardFormat } from 'helpers/dateFormat';
import usePeopleData from 'hooks/usePeopleData';
import useCompanies from 'hooks/useCompanies';

const ApplicatorSection = ({ applicators }) => {
  const { people, loading: isLoadingPeople } = usePeopleData();
  const { companies, loading: companiesLoaded } = useCompanies();

  return (
    <div data-testid="applicator-section">
      <div className="flex justify-between pt-4 mb-8">
        <div className="text-2xl font-bold">Applicator(s)</div>
      </div>
      {!isLoadingPeople &&
        !companiesLoaded &&
        applicators.map((applicator, index) => {
          const person = people.find(p => p.id === applicator.personId);
          const company = companies.find(c => c.id === applicator.companyId);
          return (
            <Columns key={applicator.applicatorId}>
              <Column width="w-1/3">
                <div className="mb-6">
                  <ViewOnlyField
                    header="Applicator"
                    data={
                      <div className="flex flex-col">
                        <span>{person?.name ?? ''}</span>
                        <span className="text-gray-600 text-base">
                          {person?.licenseNumber ?? ''}{' '}
                          {person?.expirationDate
                            ? `Exp. ${standardFormat(person.expirationDate)}`
                            : ''}
                        </span>
                      </div>
                    }
                    testId={`applicator${index}`}
                  />
                </div>
              </Column>
              <Column width="w-1/3">
                <div className="mb-6">
                  <ViewOnlyField
                    header="Company"
                    data={company?.name}
                    testId={`companyName${index}`}
                  />
                </div>
              </Column>
            </Columns>
          );
        })}
    </div>
  );
};

ApplicatorSection.propTypes = {
  applicators: PropTypes.arrayOf(
    PropTypes.shape({
      personId: PropTypes.string.isRequired,
      applicatorId: PropTypes.string.isRequired,
      companyName: PropTypes.string.isRequired
    })
  ).isRequired
};

export default ApplicatorSection;
