/* eslint-disable react/destructuring-assignment */
import React from 'react';
import { Col, Row } from 'syngenta-digital-cropwise-react-ui-kit';
import InfoIcon from 'assets/info.svg';

export default (total, range) => (
  <Row
    className="bg-white w-full mt-1 ml-4"
    justify="space-between"
    prefixCls="syt-antd-row"
  >
    <Col>
      <Row>
        <Col className="pt-2px">
          <img src={InfoIcon} alt="info-icon" />
        </Col>
        <Col>
          <div className="w-292px ml-2 text-xs leading-5 text-neutral-1000">
            Individual loads may contain information that apply to crop zones
            outside of your selection.
          </div>
        </Col>
      </Row>
    </Col>
    <Col className="text-neutral-60">
      {range[0]}-{range[1]} of {total}
    </Col>
  </Row>
);
