import { useCallback, useContext, useState } from 'react';

import catchCancel from 'helpers/catchCancel';
import { parseServerError } from 'helpers/errorHelpers';
import axios from 'axios';
import { getAccessToken } from 'utilities/base-auth';
import { CROPWISE_PROXY_V2_URL } from 'utilities/apiConstants';
import { Context } from 'components/Store';

export const sortObjectsByAttribute = (objects, attributeName) => {
  if (!objects?.length) {
    return [];
  }

  return objects.slice().sort((a, b) => {
    return a[attributeName]
      .toLowerCase()
      .localeCompare(b[attributeName].toLowerCase());
  });
};

const useOrganizationV2 = () => {
  const [organizations, setOrganizations] = useState();
  const [organization, setOrganization] = useState();
  const [organizationError, setOrganizationError] = useState(false);
  const [organizationsError, setOrganizationsError] = useState(false);
  const [loadedOrgs, setLoadedOrgs] = useState(false);
  const [loadedOrg, setLoadedOrg] = useState(false);
  const [, dispatch] = useContext(Context);

  const getAllOrgs = useCallback((page, limit) => {
    setLoadedOrgs(false);
    const promise = axios.get(`${CROPWISE_PROXY_V2_URL}/organization/list`, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${getAccessToken()}`
      },
      params: {
        page,
        limit
      }
    });

    return promise
      .then(({ data }) => {
        setOrganizations(sortObjectsByAttribute(data.data, 'name'));
      })
      .catch(catchCancel)
      .catch(() => {
        setOrganizationsError(true);
        parseServerError(dispatch);
      })
      .finally(() => setLoadedOrgs(true));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getOrgById = useCallback(id => {
    setLoadedOrg(false);
    const promise = axios.get(`${CROPWISE_PROXY_V2_URL}/organization/${id}`, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${getAccessToken()}`
      }
    });

    return promise
      .then(({ data }) => {
        setOrganization(data);
      })
      .catch(catchCancel)
      .catch(() => {
        setOrganizationError(true);
        parseServerError(dispatch);
      })
      .finally(() => setLoadedOrg(true));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return {
    organizations,
    organization,
    loadedOrgs,
    loadedOrg,
    getAllOrgs,
    getOrgById,
    organizationError,
    organizationsError
  };
};

export default useOrganizationV2;
