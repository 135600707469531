import React from 'react';
import PropTypes from 'prop-types';

import CLUButton from '../CLUButton';
import SatelliteButton from '../SatelliteButton';
import ImportModal from '../../../../ImportModal';
// LDBAPP-4657: removing by commenting out, may be used later.
// import HistoryButtons from '../HistoryButtons';

const MODE_RESET = 'simple_select';

const TopLeftControlButtons = ({ overlay, setOverlay, modeSelected }) => {
  const handleToggleCluButton = event => {
    const { checked } = event.target;
    setOverlay(checked ? 'CLU' : null);
    modeSelected(MODE_RESET);
  };
  const handleToggleSatelliteButton = event => {
    const { checked } = event.target;
    setOverlay(checked ? 'SAT' : null);
    modeSelected(MODE_RESET);
  };
  return (
    <div className="flex items-center space-x-3">
      <CLUButton value={overlay === 'CLU'} onToggle={handleToggleCluButton} />
      <SatelliteButton
        value={overlay === 'SAT'}
        onToggle={handleToggleSatelliteButton}
      />
      <ImportModal />
      {/* LDBAPP-4657: removing by commenting out, may be used later. */}
      {/* <HistoryButtons /> */}
    </div>
  );
};

TopLeftControlButtons.defaultProps = {
  overlay: null
};

TopLeftControlButtons.propTypes = {
  overlay: PropTypes.string,
  setOverlay: PropTypes.func.isRequired,
  modeSelected: PropTypes.func.isRequired
};

export default TopLeftControlButtons;
