import React from 'react';
import PropTypes from 'prop-types';

import { Select } from '@agconnections/grow-ui';

const LandingFilterSelect = ({
  onFilterChange,
  filterItems,
  filterPlaceholder,
  selectedItems
}) => {
  return (
    <div data-testid="cropseasonselect">
      <div className="w-68 mr-4">
        {filterItems ? (
          <Select
            multiple
            value={selectedItems}
            items={filterItems}
            placeholder={filterPlaceholder}
            onChange={onFilterChange}
          />
        ) : (
          <Select
            value={selectedItems}
            multiple
            items={filterItems}
            placeholder={filterPlaceholder}
            onChange={onFilterChange}
          />
        )}
      </div>
    </div>
  );
};

LandingFilterSelect.defaultProps = {
  onFilterChange: () => {},
  filterItems: null,
  filterPlaceholder: 'Filters',
  selectedItems: ''
};
LandingFilterSelect.propTypes = {
  onFilterChange: PropTypes.func,
  selectedItems: PropTypes.string,
  filterItems: PropTypes.arrayOf(
    PropTypes.oneOfType([
      PropTypes.shape({ key: PropTypes.string, value: PropTypes.string }),
      PropTypes.shape({
        key: PropTypes.string,
        value: PropTypes.string,
        nestedItems: PropTypes.arrayOf(
          PropTypes.shape({ key: PropTypes.string, value: PropTypes.string })
        )
      })
    ])
  ),
  filterPlaceholder: PropTypes.string
};

export default LandingFilterSelect;
