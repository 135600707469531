import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Input } from '@agconnections/grow-ui';

import PortalModal from 'components/Modals/PortalModal';
import { handleNameChange } from '../../helpers.js/propertyCreateHelpers';

const CreateFarmModal = ({ open, onCancel, onCreate }) => {
  const [name, setName] = useState('');
  const [isSaving, setIsSaving] = useState(false);
  const [error, setError] = useState('');

  useEffect(() => {
    if (!open) {
      setName('');
      setError('');
    }
  }, [open]);

  const handleAdd = async () => {
    setIsSaving(true);
    if (name) {
      await onCreate(name);
    }
    setIsSaving(false);
  };

  const handleCancel = () => {
    onCancel();
    setIsSaving(false);
  };

  return (
    <PortalModal
      close={onCancel}
      open={open}
      title="Create New Farm"
      cancelLabel="Cancel"
      onCancel={handleCancel}
      onConfirm={handleAdd}
      confirmLabel="Save"
      isSaving={isSaving}
      hasError={error === 'Required' || name === ''}
    >
      <div data-testid="create-property-modal-body" className="my-8 pr-8">
        <Input
          id="create-property-modal-name"
          label="Farm Name*"
          value={name || ''}
          onChange={e => handleNameChange(e, setName, setError)}
          error={error}
          errorMessage={error}
          autoFocus
        />
      </div>
    </PortalModal>
  );
};

CreateFarmModal.propTypes = {
  open: PropTypes.bool.isRequired,
  onCancel: PropTypes.func.isRequired,
  onCreate: PropTypes.func.isRequired
};

export default CreateFarmModal;
