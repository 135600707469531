import { useState, useEffect, useContext, useCallback, useRef } from 'react';

import { farm as farmApi, farms as farmsApi } from 'utilities/api';
import catchCancel from 'helpers/catchCancel';
import { parseServerError } from 'helpers/errorHelpers';
import { Context } from 'components/Store';
import { isNil } from 'lodash';
import {
  initialRequestState,
  onRequestStart,
  onRequestEnd,
  onRequestFail,
  onRequestSuccess
} from 'utilities/helpers/requestState';
import { NATIONAL_ZOOM } from '../helpers/constants';

const useFarmData = (id, executeFetch = true) => {
  const [state, setState] = useState();
  const [farms, setFarms] = useState([]);
  const [farm, setFarm] = useState();
  const [loading, setLoading] = useState(true);
  const [saving, setSaving] = useState(false);
  const [zoom, setZoom] = useState(NATIONAL_ZOOM);
  const [archive, setArchive] = useState(initialRequestState);
  const savingRef = useRef(false);
  const [, dispatch] = useContext(Context);

  const fetchFarms = useCallback(() => {
    const { promise, cancel } = farmsApi.fetch(
      null,
      null,
      { headers: { 'Local-Cache': true } },
      null,
      false
    );

    promise
      .then(({ data }) => setFarms(data))
      .catch(catchCancel)
      .catch(parseServerError(dispatch))
      .finally(() => setLoading(false));

    return {
      promise,
      cancel
    };
  }, [dispatch]);

  const fetchFarmById = useCallback(() => {
    setLoading(true);
    const { promise, cancel } = farmApi.fetch(id);
    promise
      .then(({ data }) => setFarm(data))
      .catch(catchCancel)
      .catch(parseServerError(dispatch))
      .finally(() => setLoading(false));

    return {
      promise,
      cancel
    };
  }, [dispatch, id]);

  // loads farms or farm depending on id
  useEffect(() => {
    if (executeFetch) {
      if (!isNil(id)) fetchFarmById(id);
      else fetchFarms();
    }
  }, [fetchFarmById, fetchFarms, id, executeFetch]);

  const createFarm = useCallback(
    farmData => {
      if (savingRef.current) {
        return Promise.reject(new Error('save already in progress'));
      }
      savingRef.current = true;
      const { promise } = farmApi.create(farmData);

      return promise
        .then(({ data }) => {
          setFarms(previousFarms => [...previousFarms, data]);
          return data;
        })
        .catch(catchCancel)
        .catch(parseServerError(dispatch))
        .finally(() => {
          savingRef.current = false;
        });
    },
    [dispatch]
  );

  const editFarm = useCallback(
    farmData => {
      setLoading(true);
      setSaving(true);

      if (savingRef.current) {
        setLoading(false);
        setSaving(false);
        return Promise.reject(new Error('edit already in progress'));
      }
      savingRef.current = true;
      const { promise } = farmApi.update(id, farmData);

      return promise
        .then(({ data }) => {
          setFarms(previousFarms =>
            previousFarms.map(currentFarm => {
              if (currentFarm.id === id)
                return {
                  ...currentFarm,
                  ...data
                };
              return currentFarm;
            })
          );
          setState('success');
          setFarm(data);
          return data;
        })
        .catch(catchCancel)
        .catch(parseServerError(dispatch))
        .catch(setState('error'))
        .finally(() => {
          savingRef.current = false;
          setLoading(false);
          setSaving(false);
        });
    },
    [dispatch, id]
  );

  const archiveFarm = useCallback(
    farmId => {
      setArchive(curr => onRequestStart(curr));
      const { promise } = farmApi
        .createChildApi({
          action: `farm/${farmId}/archive`
        })
        .post();

      promise
        .then(() => {
          setArchive(curr => onRequestSuccess(curr));
        })
        .catch(catchCancel)
        .catch(error => {
          setArchive(curr => onRequestFail(curr, error));
          parseServerError(dispatch);
        })
        .finally(() => {
          setArchive(curr => onRequestEnd(curr));
        });
    },
    [dispatch]
  );

  const unarchiveFarm = async farmId => {
    setArchive(curr => onRequestStart(curr));
    const unarchive = farmApi.createChildApi({
      action: `farm/${farmId}/unarchive`
    });
    const { promise } = unarchive.post();
    promise
      .then(() => {
        setArchive(curr => onRequestSuccess(curr));
      })
      .catch(catchCancel)
      .catch(error => {
        setArchive(curr => onRequestFail(curr, error));
        parseServerError(dispatch);
      })
      .finally(() => {
        setArchive(curr => onRequestEnd(curr));
      });
  };

  const deleteFarm = useCallback(() => {
    if (savingRef.current) {
      return Promise.reject(new Error('delete already in progress'));
    }
    savingRef.current = true;
    const { promise } = farmApi.delete(id);

    return promise
      .then(({ data }) => {
        setFarms(previousFarms =>
          previousFarms.filter(currentFarm => currentFarm.id !== id)
        );
        return data;
      })
      .catch(catchCancel)
      .catch(parseServerError(dispatch))
      .finally(() => {
        savingRef.current = false;
        return savingRef.current;
      });
  }, [dispatch, id]);

  return {
    state,
    farms,
    farm,
    loading,
    saving,
    createFarm,
    fetchFarms,
    zoom,
    setZoom,
    fetchFarmById,
    editFarm,
    archive,
    archiveFarm,
    unarchiveFarm,
    deleteFarm
  };
};

export default useFarmData;
