import { useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';
import { getAccessToken } from 'utilities/base-auth';

import catchCancel from 'helpers/catchCancel';
import { parseServerError } from 'helpers/errorHelpers';
import { Context } from 'components/Store';
import { API_CROPS_LIST } from 'utilities/apiConstants';
import alphanumSort from 'helpers/sorters';

const useCropData = id => {
  const [crops, setCrops] = useState();
  const [loading, setLoading] = useState(true);
  const [, dispatch] = useContext(Context);
  const url = !id
    ? `${API_CROPS_LIST}/crops?location=@CWF`
    : `${API_CROPS_LIST}/crops/${id}?location=@CWF`;
  useEffect(() => {
    axios
      .get(url, {
        headers: { common: { Authorization: `Bearer ${getAccessToken()}` } }
      })
      .then(response => {
        setCrops(!id ? alphanumSort(response.data.content) : response.data);
        setLoading(false);
      })
      .catch(catchCancel)
      .catch(parseServerError(dispatch));
  }, [dispatch, url, id]);

  return { crops, loading };
};

useCropData.defaultProps = {
  id: null
};

useCropData.propTypes = {
  id: PropTypes.string
};

export default useCropData;
