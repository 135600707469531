import { Maths } from 'helpers/maths';
import { YIELD_DEFAULT_ROUND } from 'screens/YieldV2/constants';
import sanitizeAndConvertToNumber from './sanitizeAndConvertToNumber';

/**
 * Calculates the sale price based on the given load and gross revenue.
 *
 * @param {Object} params - The parameters for calculating the sale price.
 * @param {number} params.load - The load value.
 * @param {number} params.grossRevenue - The gross revenue value.
 * @returns {number|null} The calculated sale price, or null if either load or grossRevenue is falsy.
 */
const calculateSalePrice = ({ load, grossRevenue }) => {
  if (!load || !grossRevenue) return null;
  const sanitizedGrossRevenue = sanitizeAndConvertToNumber(grossRevenue);
  const sanitizedLoad = sanitizeAndConvertToNumber(load);
  if (sanitizedGrossRevenue === null || sanitizedLoad === null) return null;
  return Maths.divideSafeRound(
    sanitizedGrossRevenue,
    sanitizedLoad,
    YIELD_DEFAULT_ROUND
  );
};

export default calculateSalePrice;
