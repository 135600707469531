import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import tasksTableSort from 'screens/Tasks/helpers/taskTableSort';
import CwFPagination from 'components/CwFPagination';
import Paper from 'components/Paper';
import LandingTable from 'components/LandingTable';

import { paths } from 'routes/paths';
import TaskTableRow from './components/TaskTableRow';

const TaskTable = ({
  tasks,
  memberRole,
  memberFinancialAccess,
  handleAction,
  totalTasks,
  taskPageNumber,
  setTaskPageNumber,
  downloadingPDF,
  rowIndexLoading
}) => {
  const [sortField, setSortField] = useState();
  const [sortDir, setSortDir] = useState();
  const [filteredTasks, setFilteredTasks] = useState(tasks);
  const [, setTagsLoading] = useState(true);

  const TABLE_COLUMNS1 = [
    {
      field: 'task',
      title: `Task (${totalTasks})`,
      sortable: true
    },
    {
      field: 'fields',
      title: 'Fields',
      sortable: true
    },
    {
      field: 'totalAcres',
      title: 'Total Acres',
      sortable: true
    },
    {
      field: 'products',
      title: 'Products',
      sortable: true
    },
    {
      field: 'totalCost',
      title: 'Total Cost',
      sortable: true
    },
    {
      field: 'cropSeason',
      title: 'Crop Season',
      sortable: true
    },
    {
      field: 'startDateTime',
      title: 'Start Date/Time',
      sortable: true
    },
    {
      field: 'status',
      title: 'Status',
      sortable: true
    }
  ];

  useEffect(() => {
    setTagsLoading(false);
    setFilteredTasks(tasks);
  }, [tasks]);

  const handleSort = (field, dir) => {
    setSortField(field);
    setSortDir(dir);
    setFilteredTasks(tasksTableSort(filteredTasks, field, dir));
  };

  return (
    <div
      className="mb-4 min-w-content"
      data-testid="tasks-table"
      style={{ minWidth: 'min-content' }}
    >
      <Paper>
        {CwFPagination(totalTasks, taskPageNumber, setTaskPageNumber)}
        <LandingTable
          listType="Tasks"
          routePath={paths.tasks}
          items={filteredTasks}
          tableColumns={TABLE_COLUMNS1}
          onSort={handleSort}
          sortBy={sortField}
          sortDir={sortDir}
        >
          {({ rows }) =>
            rows.map((task, index) => {
              return (
                <TaskTableRow
                  key={task.id}
                  task={task}
                  onAction={action => handleAction(action, task, index)}
                  flags={
                    task?.flags?.flags !== undefined ||
                    task?.flags?.flags?.length > 0
                      ? task?.flags?.flags
                      : []
                  }
                  memberFinancialAccess={memberFinancialAccess}
                  memberRole={memberRole}
                  runningProcesses={downloadingPDF && rowIndexLoading === index}
                />
              );
            })
          }
        </LandingTable>
        <div className="pb-12">
          {CwFPagination(totalTasks, taskPageNumber, setTaskPageNumber)}
        </div>
      </Paper>
    </div>
  );
};

TaskTable.propTypes = {
  tasks: PropTypes.arrayOf(PropTypes.object),
  memberRole: PropTypes.string.isRequired,
  memberFinancialAccess: PropTypes.string.isRequired,
  handleAction: PropTypes.func.isRequired,
  totalTasks: PropTypes.number.isRequired,
  taskPageNumber: PropTypes.number.isRequired,
  setTaskPageNumber: PropTypes.func.isRequired,
  downloadingPDF: PropTypes.bool,
  rowIndexLoading: PropTypes.number
};

TaskTable.defaultProps = {
  tasks: [],
  downloadingPDF: false,
  rowIndexLoading: -1
};

export default TaskTable;
