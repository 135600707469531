import { useState, useEffect } from 'react';
import { NEXT_CHECKSTATE } from 'helpers/checkboxHelpers';
import {
  selectionReaction,
  createLandSelectionMap,
  createParentChildMap
} from '../helpers/selectionMapping';

const usePropertySelection = farms => {
  const [selectionMap, setSelectionMap] = useState({});
  const [parentChildMap, setParentChildMap] = useState({});

  useEffect(() => {
    setSelectionMap(prevMap => createLandSelectionMap(farms, prevMap));
    setParentChildMap(createParentChildMap(farms));
  }, [farms, setSelectionMap, setParentChildMap]);

  const onSelectRow = id => {
    const currState = selectionMap[id];
    const reactionMap = selectionReaction(id, selectionMap, parentChildMap);

    setSelectionMap({
      ...selectionMap,
      ...reactionMap,
      [id]: NEXT_CHECKSTATE[currState]
    });
  };

  return {
    selectionMap,
    onSelectRow
  };
};

export default usePropertySelection;
