import React from 'react';
import PropTypes from 'prop-types';

import { DatePicker } from '@agconnections/grow-ui';

const DateRangePicker = ({ onDateRangeChange }) => {
  return (
    <div data-testid="daterangepicker">
      <div className="relative w-64 text-gray-400 focus-within:text-gray-600 mr-4">
        <DatePicker onChange={onDateRangeChange} isRangePicker />
      </div>
    </div>
  );
};

DateRangePicker.defaultProps = {
  onDateRangeChange: () => {}
};

DateRangePicker.propTypes = {
  onDateRangeChange: PropTypes.func
};

export default DateRangePicker;
