import React, { useState, useEffect, useContext } from 'react';
import { Dropdown } from 'antd';
import SortIcon from 'assets/sort.svg';
import { Context } from 'components/Store';
import createAction from 'helpers/createAction';
import { SET_IS_SORT_BY_CROPS } from 'reducers/reducer';
import './index.css';

const GROUP_BY_FARM = '1';
const GROUP_BY_CROP = '2';

const PropertySorting = () => {
  const [, dispatch] = useContext(Context);
  const [selectedItemKey, setSelectedItemKey] = useState(GROUP_BY_FARM);

  const renderSvg = ({ isActive }) => (
    <svg
      width="20"
      height="19"
      viewBox="0 0 25 24"
      fill="none"
      className="flex-shrink-0 mr-1 ml-2"
    >
      <path
        d="M8.8273 15.8769L4.6573 11.7069L3.2373 13.1169L8.8273 18.7069L20.8273 6.70687L19.4173 5.29688L8.8273 15.8769Z"
        fill={isActive ? '#0092E4' : 'transparent'}
      />
    </svg>
  );

  const handleMenuClick = e => {
    setSelectedItemKey(e.key);
  };

  useEffect(() => {
    createAction(
      dispatch,
      SET_IS_SORT_BY_CROPS,
      selectedItemKey === GROUP_BY_CROP
    );
  }, [selectedItemKey, dispatch]);

  const sortItems = [
    {
      key: GROUP_BY_FARM,
      label: (
        <div
          className={`flex items-center w-full h-full ant-dropdown-menu-item ${
            selectedItemKey === GROUP_BY_FARM
              ? 'ant-dropdown-menu-item selected-item'
              : ''
          }`}
        >
          {renderSvg({ isActive: selectedItemKey === GROUP_BY_FARM })}
          <span
            className={`px-2 py-1  ${
              selectedItemKey === GROUP_BY_FARM ? 'font-semibold' : ''
            }`}
          >
            Group by Farm
          </span>
        </div>
      )
    },
    {
      key: GROUP_BY_CROP,
      label: (
        <div
          className={`flex items-center w-full h-full ant-dropdown-menu-item ${
            selectedItemKey === GROUP_BY_CROP
              ? 'ant-dropdown-menu-item selected-item'
              : ''
          }`}
        >
          {renderSvg({ isActive: selectedItemKey === GROUP_BY_CROP })}
          <span
            className={`px-2 py-1 ${
              selectedItemKey === GROUP_BY_CROP ? 'font-semibold' : ''
            }`}
          >
            Group by Crop
          </span>
        </div>
      )
    }
  ];

  const menuProps = {
    items: sortItems,
    onClick: handleMenuClick
  };

  return (
    <Dropdown menu={menuProps} trigger={['click']} placement="bottomRight">
      <button
        type="button"
        className="ml-2 w-8 h-8 flex items-center justify-center rounded-md hover:bg-neutral-100 bg-white border border-neutral-30"
      >
        <img src={SortIcon} alt="Sort Icon" />
      </button>
    </Dropdown>
  );
};

export default PropertySorting;
