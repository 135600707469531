import React from 'react';
import PropTypes from 'prop-types';
import CropIcons from 'helpers/getCropIconByCropName';

// Mapping of Crop Icons with Crop Name
const CropIconMapping = ({ cropObject, size = 24 }) => {
  let cropIcon = <div> </div>;
  if (cropObject?.name) {
    const cropName = cropObject.name.includes(':')
      ? cropObject.name
          .split(' ')[0]
          .trim()
          .replace(/\s/g, '')
      : cropObject.name.trim().replace(/\s/g, '');
    if (cropName in CropIcons) {
      cropIcon = (
        <img
          src={CropIcons[cropName].default}
          alt={cropName}
          width={size}
          height={size}
        />
      );
    }
  }

  return cropIcon;
};

CropIconMapping.defaultProps = {
  size: 24,
  cropObject: {}
};

CropIconMapping.propTypes = {
  cropObject: PropTypes.shape({
    name: PropTypes.string
  }),
  size: PropTypes.number
};

export default CropIconMapping;
