/* eslint-disable react/forbid-prop-types */
/* eslint-disable react/jsx-props-no-spreading */
import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { Droppable } from 'react-beautiful-dnd';

import KeyboardArrowUp from '@material-ui/icons/KeyboardArrowUp';
import KeyboardArrowDown from '@material-ui/icons/KeyboardArrowDown';
import UnfoldMore from '@material-ui/icons/UnfoldMore';

import { formatTitle } from 'helpers/textHelpers';

import TaskList from './TaskList';
import Task from './TaskList/Task';

const calculateTitleColor = columnIndex => {
  let returnClass = 'text-yellow-600';

  if (columnIndex === 1) {
    returnClass = 'text-blue-400';
  }
  if (columnIndex === 2) {
    returnClass = 'text-blue-700';
  }

  return returnClass;
};

const ColumnHeader = ({
  column,
  tasks,
  columnIndex,
  sort,
  handleSortChange,
  highlighted
}) => {
  const conditionalHeaderTextClasses = calculateTitleColor(columnIndex);
  const conditionalHeaderContainerClasses = highlighted
    ? 'border-blue-600 border-b-0'
    : '';

  let sortIcon = <UnfoldMore fontSize="small" m={0} />;
  if (sort === 'asc') {
    sortIcon = <KeyboardArrowUp fontSize="small" m={0} />;
  } else if (sort === 'desc') {
    sortIcon = <KeyboardArrowDown fontSize="small" m={0} />;
  }

  return (
    <h3
      className={`bg-white border rounded-t px-2 py-2 flex items-center justify-between ${conditionalHeaderContainerClasses}`}
    >
      <span
        className={`${conditionalHeaderTextClasses} text-lg font-bold`}
      >{`${formatTitle(column.title)} (${tasks.length})`}</span>

      <button
        onClick={() => {
          handleSortChange(column.title);
        }}
        className="flex w-24 justify-between"
        type="button"
      >
        <span>Start Date</span>
        <div>{sortIcon}</div>
      </button>
    </h3>
  );
};

const Column = ({
  column,
  columnIndex,
  tasks,
  onAction,
  setDraggingOverTask,
  handleSortChange,
  sort,
  memberRole,
  memberFinancialAccess
}) => {
  const [highlighted, setHighlighted] = useState();

  return (
    <div
      id={`tasks-column-${column.title}`}
      className="bg-neutral-800 rounded w-64 mx-2 flex flex-col flex-grow"
    >
      <ColumnHeader
        column={column}
        tasks={tasks}
        columnIndex={columnIndex}
        sort={sort}
        handleSortChange={handleSortChange}
        highlighted={highlighted}
      />

      <Droppable droppableId={column.id} isDropDisabled={false}>
        {(provided, snapshot) => {
          let calculatedDraggingOver = snapshot.isDraggingOver;

          if (snapshot.isDraggingOver) {
            const draggingOverTaskId = snapshot?.draggingFromThisWith;
            if (draggingOverTaskId) {
              const taskBeingMoved = tasks.find(
                task => task.id === draggingOverTaskId
              );

              setDraggingOverTask(taskBeingMoved);

              if (column.title === taskBeingMoved.status) {
                calculatedDraggingOver = false;
              }
            }

            if (calculatedDraggingOver) {
              setHighlighted(true);
            }
          } else if (setHighlighted) {
            setHighlighted(false);
          }

          return (
            <TaskList
              innerRef={provided.innerRef}
              {...provided.droppableProps}
              isDraggingOver={calculatedDraggingOver}
            >
              {tasks.map((task, index) => {
                return (
                  <Task
                    key={task?.id}
                    task={task}
                    index={index}
                    onAction={onAction}
                    memberRole={memberRole}
                    memberFinancialAccess={memberFinancialAccess}
                  />
                );
              })}
              {/* This placeholder is for increasing the available space during a drag event */}
              {/* https://github.com/atlassian/react-beautiful-dnd/issues/374#issuecomment-569817782 */}
              <span
                style={{
                  display: 'none'
                }}
              >
                {provided.placeholder}
              </span>
            </TaskList>
          );
        }}
      </Droppable>
    </div>
  );
};

ColumnHeader.defaultProps = {
  sort: null,
  highlighted: false
};

ColumnHeader.propTypes = {
  column: PropTypes.PropTypes.object.isRequired,
  columnIndex: PropTypes.number.isRequired,
  tasks: PropTypes.arrayOf(PropTypes.object).isRequired,
  sort: PropTypes.string,
  handleSortChange: PropTypes.func.isRequired,
  highlighted: PropTypes.bool
};

Column.defaultProps = {
  sort: null
};

Column.propTypes = {
  column: PropTypes.PropTypes.object.isRequired,
  columnIndex: PropTypes.number.isRequired,
  setDraggingOverTask: PropTypes.func.isRequired,
  tasks: PropTypes.arrayOf(PropTypes.object).isRequired,
  onAction: PropTypes.func.isRequired,
  handleSortChange: PropTypes.func.isRequired,
  sort: PropTypes.string,
  memberRole: PropTypes.string.isRequired,
  memberFinancialAccess: PropTypes.string.isRequired
};

export default Column;
