import { useCallback, useState } from 'react';
import catchCancel from 'helpers/catchCancel';
import axios from 'axios';
import { CROPWISE_PROXY_V2_URL } from 'utilities/apiConstants';
import { getAccessToken } from 'utilities/base-auth';

const useLoads = () => {
  const [loading, setLoading] = useState(false);

  const getLoads = async ({
    limit = 50,
    page = 0,
    sortBy = 'name',
    sortDir = 'asc',
    seasonIds = [],
    cropIds,
    searchQuery,
    startDate,
    endDate,
    loadTypes,
    cropZoneIds,
    storageIds,
    saleIds
  }) => {
    setLoading(true);
    const orgId = localStorage.getItem('selectedOrganizationId');

    const body = {
      seasonIds,
      cropIds: cropIds?.length ? cropIds : undefined,
      searchQuery: searchQuery || undefined,
      startDate: startDate || undefined,
      endDate: endDate || undefined,
      loadTypes: loadTypes?.length ? loadTypes : undefined,
      storageIds: storageIds?.length ? storageIds : undefined,
      saleIds: saleIds?.length ? saleIds : undefined,
      cropZoneIds: cropZoneIds?.length ? cropZoneIds : undefined
    };

    return axios
      .post(`${CROPWISE_PROXY_V2_URL}/yield/list`, body, {
        headers: {
          'Content-Type': 'application/json',
          'cwf-context': JSON.stringify({
            organization_id: orgId
          }),
          Authorization: `Bearer ${getAccessToken()}`
        },
        params: {
          limit,
          page,
          sortBy,
          sortDir
        }
      })
      .then(({ data }) => {
        return data;
      })
      .catch(catchCancel)
      .catch(() => {
        return {};
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const getLoadDetails = useCallback(loadId => {
    setLoading(true);
    const orgId = localStorage.getItem('selectedOrganizationId');

    const promise = axios.get(`${CROPWISE_PROXY_V2_URL}/yield/${loadId}`, {
      headers: {
        'Content-Type': 'application/json',
        'cwf-context': JSON.stringify({
          organization_id: orgId
        }),
        Authorization: `Bearer ${getAccessToken()}`
      }
    });

    return promise
      .then(({ data }) => data)
      .catch(catchCancel)
      .catch(() => {})
      .finally(() => setLoading(false));
  }, []);

  const deleteLoad = useCallback(({ id }) => {
    setLoading(true);
    const orgId = localStorage.getItem('selectedOrganizationId');

    const promise = axios.delete(`${CROPWISE_PROXY_V2_URL}/yield/${id}`, {
      headers: {
        'Content-Type': 'application/json',
        'cwf-context': JSON.stringify({
          organization_id: orgId
        }),
        Authorization: `Bearer ${getAccessToken()}`
      }
    });

    return promise
      .then(({ status }) => status)
      .catch(catchCancel)
      .catch(() => {})
      .finally(() => setLoading(false));
  }, []);

  const [yieldLoad, setYieldLoad] = useState();
  const [submitError, setSubmitError] = useState(null);

  const getLoadById = useCallback(id => {
    const orgId = localStorage.getItem('selectedOrganizationId');
    const headers = {
      'Content-Type': 'application/json',
      'cwf-context': JSON.stringify({
        organization_id: orgId
      }),
      Authorization: `Bearer ${getAccessToken()}`
    };
    const promise = axios.get(`${CROPWISE_PROXY_V2_URL}/yield/${id}`, {
      headers
    });
    promise
      .then(({ data }) => {
        setYieldLoad(data);
      })
      .catch(catchCancel);
  }, []);

  const updateLoadById = useCallback((id, updatedData) => {
    setLoading(true);

    const orgId = localStorage.getItem('selectedOrganizationId');
    const headers = {
      'Content-Type': 'application/json',
      'cwf-context': JSON.stringify({
        organization_id: orgId
      }),
      Authorization: `Bearer ${getAccessToken()}`
    };
    const promise = axios.put(
      `${CROPWISE_PROXY_V2_URL}/yield/${id}`,
      updatedData,
      {
        headers
      }
    );
    return promise
      .then(({ data }) => {
        setYieldLoad(data);
      })
      .catch(catchCancel)
      .catch(error => {
        setSubmitError(error);
        throw error;
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);

  const saveAllLoads = useCallback(async items => {
    const orgId = localStorage.getItem('selectedOrganizationId');

    const headers = {
      'Content-Type': 'application/json',
      'cwf-context': JSON.stringify({
        organization_id: orgId
      }),
      Authorization: `Bearer ${getAccessToken()}`
    };

    const promise = axios.post(`${CROPWISE_PROXY_V2_URL}/yield/bulk`, items, {
      headers
    });

    return promise
      .then(({ data }) => data)
      .catch(catchCancel)
      .catch(() => {});
  }, []);

  return {
    getLoadDetails,
    deleteLoad,
    getLoads,
    loading,
    getLoadById,
    yieldLoad,
    submitError,
    updateLoadById,
    saveAllLoads
  };
};

export default useLoads;
