const submitValidationChecker = (actionType, values, submitForm) => {
  const isValidCrop = values.crop.name !== '';
  const isSeasonValid = values.season.name;

  const shouldSubmitForm =
    (actionType === 'Add' && isSeasonValid && isValidCrop) ||
    (actionType !== 'Add' && isValidCrop);

  if (shouldSubmitForm) {
    submitForm();
  }
};

export default submitValidationChecker;
