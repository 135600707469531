import React from 'react';
import { Input } from 'syngenta-digital-cropwise-react-ui-kit';
import PropTypes from 'prop-types';
import InfoIcon from 'assets/info.svg';
import { ErrorMessage } from 'formik';
import { YIELD_DEFAULT_ROUND } from 'screens/YieldV2/constants';
import FormInputWithPrecisionDisplay from '../FormInputWithPrecisionDisplay';

const AddStorage = ({ handleChange, values, errors }) => {
  return (
    <>
      <div className="flex flex-col gap-1 mb-5">
        <label
          htmlFor="location-name"
          className="text-neutral-60 tracking-tighter text-sm"
        >
          Name of Location<span className="text-remove">*</span>
        </label>
        <Input
          required
          name="name"
          id="location-name"
          type="default"
          size="middle"
          value={values.name}
          onChange={handleChange}
          status={errors?.name && 'error'}
          caption={errors?.name}
        />
      </div>
      <div className="flex flex-col gap-1 mb-8">
        <label
          htmlFor="capacity"
          className="text-neutral-60 tracking-tighter text-sm"
        >
          Capacity
        </label>
        <FormInputWithPrecisionDisplay
          fieldName="capacity"
          type="default"
          inputSuffix={<span className="text-sm text-gray-600">bu by vol</span>}
          id="capacity"
          onControlledChange={handleChange}
          isRequired={false}
          round={YIELD_DEFAULT_ROUND}
        />
        <ErrorMessage name="capacity" component="div" className="text-remove" />
        <div className="flex gap-1 items-start">
          <img
            src={InfoIcon}
            alt="Info icon"
            height={16}
            width={16}
            className="mt-2px"
          />
          <div className="text-xs text-neutral-60">
            bu by vol = 1.25 cubic ft assuming standard moisture of stored crop
          </div>
        </div>
      </div>
      <h3 className="text-base font-semibold block mb-4">Basic Info</h3>

      <div className="flex flex-col gap-1 mb-5">
        <label
          htmlFor="email"
          className="text-neutral-60 tracking-tighter text-sm"
        >
          Email Address
        </label>
        <Input
          type="default"
          size="middle"
          name="email"
          id="email"
          value={values.email}
          onChange={handleChange}
          status={errors?.email && 'error'}
          caption={errors?.email}
        />
      </div>
      <div className="flex flex-col gap-1 mb-5">
        <label
          htmlFor="phone"
          className="text-neutral-60 tracking-tighter text-sm"
        >
          Phone Number
        </label>
        <Input
          type="default"
          size="middle"
          name="phone"
          id="phone"
          value={values.phone}
          onChange={handleChange}
        />
      </div>
    </>
  );
};

AddStorage.propTypes = {
  handleChange: PropTypes.func.isRequired,
  values: PropTypes.shape({
    name: PropTypes.string,
    capacity: PropTypes.string,
    email: PropTypes.string,
    phone: PropTypes.string
  }).isRequired,
  errors: PropTypes.objectOf(PropTypes.any).isRequired
};

export default AddStorage;
