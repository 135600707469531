import React, { useEffect, useState } from 'react';
import PropertiesGrid from 'screens/YieldV2/LoadDetails/components/LoadDetailsInfo/components/LoadDetailsInfoTabs/components/PropertiesGrid/index';
import { useFormikContext } from 'formik';
import useParameters from 'screens/YieldV2/LoadDetails/components/LoadDetailsInfo/components/LoadDetailsInfoTabs/hook/useParameters';
import formatNumber, { formatPercentage } from 'helpers/formatNumber';
import { getAbbreviatedUnit } from 'helpers/unitsAbbreviation';
import { tabsLabels } from '../../helpers/constants';

const LoadAttributesTab = () => {
  const { values } = useFormikContext();
  const {
    templateType,
    damage,
    foreignMaterial,
    moisture,
    testWeight,
    testWeightUnit
  } = values;
  const { removeAttributes } = useParameters(
    tabsLabels.loadAttributes,
    { source: true },
    templateType
  );
  const [properties, setProperties] = useState([]);

  useEffect(() => {
    setProperties([
      { name: 'damage', value: formatPercentage(damage) },
      'failingNumber',
      { name: 'foreignMaterial', value: formatPercentage(foreignMaterial) },
      'lintWeight',
      { name: 'moisture', value: formatPercentage(moisture) },
      'plump',
      'protein',
      'quality',
      'seedWeight',
      'slm',
      {
        name: 'testWeight',
        value: formatNumber(testWeight, getAbbreviatedUnit(testWeightUnit))
      }
    ]);
  }, [damage, foreignMaterial, moisture, testWeight, testWeightUnit]);

  return (
    <PropertiesGrid
      removeAttributes={removeAttributes}
      properties={properties}
      values={values}
    />
  );
};

export default LoadAttributesTab;
