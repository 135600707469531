import React, { useContext } from 'react';
import CardInfo from 'components/CardInfo';
import { StorageLocationContext } from 'screens/YieldV2/StorageLocations/context/StorageLocationProvider';
import formatStorageValue from 'screens/YieldV2/StorageLocations/helpers/formatStorageValue';

const StorageRound = () => {
  const {
    state: { selectedId, data = [] }
  } = useContext(StorageLocationContext);

  const selected = data.find(storage => storage.id === selectedId);

  if (!selected?.capacity || selected?.totalStoredLoadUnit === 'lb') {
    return (
      <div className="flex">
        <div className="w-1/2 pr-4">
          <CardInfo label="Shape" value={selected?.shape} />
          <CardInfo
            label="Diameter"
            value={formatStorageValue(selected?.diameter, 'ft')}
          />
          <CardInfo
            label="Cone Height"
            value={formatStorageValue(selected?.coneHeight, 'ft')}
          />
        </div>

        <div className="w-1/2 pl-4">
          <CardInfo
            label="Capacity"
            value={
              selected?.capacity
                ? formatStorageValue(selected?.capacity, 'bu')
                : null
            }
          />
          <CardInfo
            label="Height"
            value={formatStorageValue(selected?.height, 'ft')}
          />
          <CardInfo
            label="Volume"
            value={formatStorageValue(selected?.volume, 'cu. ft')}
          />
        </div>
      </div>
    );
  }

  return (
    <div className="flex">
      <div className="w-1/2 pr-4">
        <CardInfo label="Shape" value={selected?.shape} />
        <CardInfo
          label="Height"
          value={formatStorageValue(selected?.height, 'ft')}
        />
        <CardInfo
          label="Volume"
          value={formatStorageValue(selected?.volume, 'cu. ft')}
        />
      </div>

      <div className="w-1/2 pl-4">
        <CardInfo
          label="Diameter"
          value={formatStorageValue(selected?.diameter, 'ft')}
        />
        <CardInfo
          label="Cone Height"
          value={formatStorageValue(selected?.coneHeight, 'ft')}
        />
      </div>
    </div>
  );
};

export default StorageRound;
