import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useHistory, useParams } from 'react-router-dom';

import { ArrowDropDown } from '@material-ui/icons';
import { Button, Popup } from '@agconnections/grow-ui';
import DeleteIcon from 'components/Icons/components/DeleteIcon';
import { useFormikContext } from 'formik';
import { paths } from 'routes/paths';

const CropZoneActions = ({ children, onSave, disabled, canDelete }) => {
  const history = useHistory();
  const { action } = useParams();
  const { isSubmitting } = useFormikContext();

  const [deleteModalOpen, setDeleteModalOpen] = useState(false);

  const isEditing = action === 'edit';

  const saveStyles = !isEditing
    ? {
        borderTopRightRadius: '0',
        borderBottomRightRadius: '0',
        borderRight: 'none'
      }
    : {};

  return (
    <>
      {isEditing && (
        <div className="absolute left-0">
          <Button
            danger
            type="text"
            icon={<DeleteIcon />}
            onClick={() => setDeleteModalOpen(true)}
            disabled={!canDelete}
          >
            Delete
          </Button>
        </div>
      )}
      <div className="flex space-x-3">
        <Button onClick={() => history.push(paths.properties)}>Cancel</Button>
        <div className="flex top-0 white-bg">
          <Button
            type="primary"
            className="w-full"
            disabled={disabled}
            loading={isSubmitting}
            style={saveStyles}
            onClick={() => onSave()}
          >
            Save
          </Button>
          {!isEditing ? (
            <Popup
              align="right"
              position="top"
              trigger={
                <Button
                  className=""
                  type="primary"
                  disabled={disabled}
                  style={{
                    borderTopLeftRadius: '0',
                    borderBottomLeftRadius: '0',
                    borderLeft: 'none'
                  }}
                >
                  <ArrowDropDown />
                </Button>
              }
            >
              <div
                className="flex flex-col font-normal text-gray-700"
                style={{ minWidth: '300px' }}
              >
                {children}
              </div>
            </Popup>
          ) : (
            children({ deleteModalOpen, setDeleteModalOpen })
          )}
        </div>
      </div>
    </>
  );
};

CropZoneActions.defaultProps = {
  disabled: false,
  canDelete: false
};

CropZoneActions.propTypes = {
  disabled: PropTypes.bool,
  onSave: PropTypes.func.isRequired,
  children: PropTypes.oneOfType([PropTypes.func, PropTypes.node]).isRequired,
  canDelete: PropTypes.bool
};

export default CropZoneActions;
