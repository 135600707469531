import { compact, isNil } from 'lodash';
import { standardFormat } from 'helpers/dateFormat';

export function getSeasons({ seasons, seasonIds }) {
  if (isNil(seasons)) return '';

  return compact(
    seasonIds.map(id => {
      const season = seasons.find(({ id: seasonId }) => seasonId === id);
      if (isNil(season)) return null;
      return season.name;
    })
  ).join(', ');
}

export function getSubtitle({ expirationDateEpoch, proposedDateEpoch }) {
  if (isNil(expirationDateEpoch) && isNil(proposedDateEpoch)) return '';

  const proposed = new Date(proposedDateEpoch);
  const expiration = new Date(expirationDateEpoch);

  if (isNil(expirationDateEpoch)) return standardFormat(proposed);
  if (isNil(proposedDateEpoch)) return standardFormat(expiration);

  const proposedDateStr = standardFormat(proposed);
  const expirationDateStr = standardFormat(expiration);

  if (proposedDateStr === expirationDateStr) {
    return proposedDateStr;
  }

  if (proposed.getFullYear() === expiration.getFullYear()) {
    const options = {
      month: '2-digit',
      day: '2-digit'
    };
    const proposedFormatted = proposed.toLocaleDateString('en-US', options);
    return `${proposedFormatted} - ${expirationDateStr}`;
  }

  return `${proposedDateStr} - ${expirationDateStr}`;
}
