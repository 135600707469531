export const LOCAL_ROOT = 'http://localhost:3000';

export const INVITE_API_MIRAGE_ROOT_URL = '/inviteservice';
export const PERMISSION_API_MIRAGE_ROOT_URL = '/permissionservice';

export const shouldUseMirage =
  process.env.JEST_WORKER_ID !== undefined ||
  process.env.REACT_APP_ENV === 'mirage-development';

export const BASE_API_URL = shouldUseMirage
  ? ''
  : `https://${process.env.REACT_APP_CROPWISE_BASE_API_URL}/v2`;

export const CROPWISE_PROXY_URL = shouldUseMirage
  ? ''
  : `${process.env.REACT_APP_CROPWISE_PROXY_URL}/api`;

export const CROPWISE_PROXY_V2_URL = shouldUseMirage
  ? ''
  : `${process.env.REACT_APP_CROPWISE_PROXY_V2_URL}`;

export const CROPWISE_MASTERDATA = shouldUseMirage
  ? ''
  : `${process.env.REACT_APP_CROPWISE_PROXY_URL}/masterdata/api/v1`;

export const MASTERLIST_PROXY_URL = shouldUseMirage
  ? ''
  : process.env.REACT_APP_MASTERLIST_PROXY_URL;

export const MOCK_API_URL = '';

export const INVITE_API_URL = INVITE_API_MIRAGE_ROOT_URL;

export const PERMISSION_API_URL = shouldUseMirage
  ? PERMISSION_API_MIRAGE_ROOT_URL
  : process.env.REACT_APP_PERMISSIONSERVICE_API_URL;

export const MASTERLIST_CROPS_URL = shouldUseMirage
  ? ''
  : `https://${process.env.REACT_APP_CROPWISE_BASE_API_URL}/v2`;

export const API_CROPS_LIST = shouldUseMirage
  ? ''
  : `https://${process.env.REACT_APP_CROPWISE_BASE_API_CROPS}/v2`;

export const AG_CLEAR_APP_URL = `${process.env.REACT_APP_AG_CLEAR_APP_ROOT_URL}/v1.1`;
