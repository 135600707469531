import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { Input } from '@agconnections/grow-ui';

import GrowUIFormField from 'components/Forms/GrowUIFormField';
import { useFormikContext } from 'formik';

const CropZoneNameInput = ({ name }) => {
  const { setFieldValue } = useFormikContext();

  useEffect(() => {
    setFieldValue('name', name);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [name]);

  return (
    <GrowUIFormField control={Input} name="name" label="Crop Zone Name*" />
  );
};

CropZoneNameInput.propTypes = {
  name: PropTypes.string
};

CropZoneNameInput.defaultProps = {
  name: ''
};

export default CropZoneNameInput;
