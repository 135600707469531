import React, { memo } from 'react';
import PropTypes from 'prop-types';

const CostIcon = ({ size, color }) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className="mt-2px"
      data-testid="cost-icon"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8 0.5C3.86 0.5 0.5 3.86 0.5 8C0.5 12.14 3.86 15.5 8 15.5C12.14 15.5 15.5 12.14 15.5 8C15.5 3.86 12.14 0.5 8 0.5ZM8 14C4.6925 14 2 11.3075 2 8C2 4.6925 4.6925 2 8 2C11.3075 2 14 4.6925 14 8C14 11.3075 11.3075 14 8 14ZM6.4775 6.1025C6.4775 6.65 6.905 7.0175 8.2325 7.355C9.5525 7.7 10.97 8.27 10.9775 9.92C10.97 11.1275 10.07 11.78 8.93 11.9975V13.25H7.175V11.975C6.05 11.7425 5.105 11.0225 5.03 9.7475H6.32C6.3875 10.4375 6.86 10.9775 8.06 10.9775C9.3425 10.9775 9.635 10.3325 9.635 9.935C9.635 9.395 9.3425 8.8775 7.88 8.5325C6.2525 8.1425 5.135 7.4675 5.135 6.125C5.135 4.9925 6.0425 4.2575 7.175 4.0175V2.75H8.9225V4.0325C10.1375 4.3325 10.7525 5.255 10.79 6.26H9.5075C9.4775 5.525 9.0875 5.03 8.0525 5.03C7.07 5.03 6.4775 5.4725 6.4775 6.1025Z"
        fill={color}
      />
    </svg>
  );
};

CostIcon.propTypes = {
  color: PropTypes.string,
  size: PropTypes.number
};

CostIcon.defaultProps = {
  color: '#696F88',
  size: 18
};

export default memo(CostIcon);
