import React, { useEffect, useContext } from 'react';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import Breadcrumb from 'components/App/AppShell/components/Breadcrumb';
import { paths } from 'routes/paths';

import { useToast } from 'components/ToastContainer';
import { AmplitudeContext } from 'utilities/amplitude/useAmplitude';
import StorageLocationBreadcrumb from './components/StorageLocationBreadcrumb';
import StorageLocationList from './components/StorageLocationList';
import StorageLocationDetails from './components/StorageLocationDetails';
import StorageLocationListHeader from './components/StorageLocationList/components/StorageLocationListHeader';
import StorageLocationProvider, {
  StorageLocationContext
} from './context/StorageLocationProvider';
import LocationForm from './components/LocationForm';

const StorageLocations = () => {
  const { state } = useContext(StorageLocationContext);
  const history = useHistory();
  const amplitude = useContext(AmplitudeContext);
  const location = useLocation();
  const params = useParams();
  const toast = useToast();

  const isCreating = params.action === 'create';
  const isEditing = params.action === 'edit';

  // Call it only once
  useEffect(() => {
    if (location.state?.showToast || location.state?.selectedId) {
      window.history.replaceState(null);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="w-full h-full" data-testid="yield-storage-locations">
      {isCreating && (
        <>
          <StorageLocationBreadcrumb disabled>
            <Breadcrumb.Item
              title="Yield"
              value="Create Storage Location"
              isLast
            />
          </StorageLocationBreadcrumb>
          <LocationForm
            onSubmit={id => {
              toast.success('Location created');
              history.push(`${paths.yieldV2}/storage-locations`, {
                selectedId: id,
                sortBy: state.sortBy,
                sortDir: state.sortDir,
                skipCache: true
              });
            }}
            onCancel={() => {
              history.push(`${paths.yieldV2}/storage-locations`, {
                selectedId: state.selectedId,
                sortBy: state.sortBy,
                sortDir: state.sortDir
              });
            }}
          />
        </>
      )}
      {isEditing && (
        <>
          <StorageLocationBreadcrumb disabled>
            <Breadcrumb.Item
              title="Yield"
              value="Edit Storage Location"
              isLast
            />
          </StorageLocationBreadcrumb>
          <LocationForm
            onSubmit={() => {
              amplitude.sendEventToAmplitude(
                amplitude.events.epic.YieldV2.storageLocations
                  .editStorageLocation
              );
              toast.success('Changes saved');
              history.push(`${paths.yieldV2}/storage-locations`, {
                selectedId: params.locationId,
                sortBy: state.sortBy,
                sortDir: state.sortDir,
                skipCache: true
              });
            }}
            onCancel={() => {
              history.push(`${paths.yieldV2}/storage-locations`, {
                selectedId: state.selectedId,
                sortBy: state.sortBy,
                sortDir: state.sortDir
              });
            }}
            id={params.locationId}
          />
        </>
      )}
      {!isEditing && !isCreating && (
        <>
          <StorageLocationBreadcrumb>
            <Breadcrumb.Item title="Yield" value="Storage locations" />
          </StorageLocationBreadcrumb>
          <div className="flex flex-row w-full h-full">
            <div
              className="
min-w-332px h-full bg-white overflow-x-visible flex flex-col border
              "
              style={{ minWidth: 332 }}
            >
              <StorageLocationListHeader
                onCreateNew={() => {
                  amplitude.sendEventToAmplitude(
                    amplitude.events.epic.YieldV2.storageLocations
                      .createStorageLocation
                  );
                  history.push(`${paths.yieldV2}/storage-locations/create`, {
                    selectedId: state.selectedId,
                    sortBy: state.sortBy,
                    sortDir: state.sortDir
                  });
                }}
              />
              <StorageLocationList />
            </div>
            <StorageLocationDetails />
          </div>
        </>
      )}
    </div>
  );
};

const WrappedStorageLocations = () => {
  const { state } = useLocation();
  const initialValues = {
    sortBy: state?.sortBy,
    sortDir: state?.sortDir,
    selectedId: state?.selectedId,
    skipCache: state?.skipCache
  };

  return (
    <StorageLocationProvider initialValues={initialValues}>
      <StorageLocations />
    </StorageLocationProvider>
  );
};

export default WrappedStorageLocations;
