export const filterItems = (item, values) => {
  const checkIfValueIsPresent = value => {
    if ('address' in item && 'city' in item.address && 'state' in item.address)
      return (
        item.address?.city.toLowerCase().includes(value.toLowerCase()) ||
        item.address?.state.toLowerCase().includes(value.toLowerCase()) ||
        item.name.toLowerCase().includes(value.toLowerCase())
      );
    return item.name.toLowerCase().includes(value.toLowerCase());
  };
  if (values.length === 1) {
    return values.find(checkIfValueIsPresent);
  }
  return values.find(checkIfValueIsPresent);
};

const findCompany = (company, value) => {
  if (value) {
    const values = value.match(/[^ ]+/g);
    const filteredItems = [];
    const foundItem = company.filter(item => {
      return filterItems(item, values);
    });
    if (foundItem) {
      foundItem.forEach(fi => {
        filteredItems.push(fi);
      });
    }
    return filteredItems;
  }
  return company;
};

export default findCompany;
