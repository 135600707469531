import _ from 'lodash';

const _sort = (a, b) =>
  a.localeCompare(b, undefined, { sensitivity: 'accent' });

const getProductName = product => {
  return product?.name ?? product?.productName;
};
const getManufacturerName = product => {
  return product?.manufacturer ?? product?.manufacturerName;
};
const filteredProducts = (searchResults, searchText) => {
  const searchTextLowerCase = searchText.toLowerCase();
  const setsFiltered = {
    category1: [],
    category2: [],
    category3: [],
    category4: []
  };
  searchResults.forEach(product => {
    const productNameLowerCase = getProductName(product).toLowerCase();
    const manufacturerLowerCase = getManufacturerName(product).toLowerCase();

    if (productNameLowerCase.startsWith(searchTextLowerCase)) {
      setsFiltered.category1.push(product);
    } else if (productNameLowerCase.includes(searchTextLowerCase)) {
      setsFiltered.category2.push(product);
    } else if (manufacturerLowerCase.startsWith(searchTextLowerCase)) {
      setsFiltered.category3.push(product);
    } else if (manufacturerLowerCase.includes(searchTextLowerCase)) {
      setsFiltered.category4.push(product);
    }
  });
  const filteredResultsSorted = [
    setsFiltered.category1
      .slice()
      .sort((a, b) => _sort(getProductName(a), getProductName(b))),
    setsFiltered.category2
      .slice()
      .sort((a, b) => _sort(getProductName(a), getProductName(b))),
    setsFiltered.category3
      .slice()
      .sort((a, b) => _sort(getManufacturerName(a), getManufacturerName(b))),
    setsFiltered.category4
      .slice()
      .sort((a, b) => _sort(getManufacturerName(a), getManufacturerName(b)))
  ];

  const flattenedResults = _.flatMap(filteredResultsSorted);
  return flattenedResults;
};
export default filteredProducts;
