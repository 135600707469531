import React, { useState, useContext } from 'react';
import CwFPagination from 'components/CwFPagination';
import { useHistory } from 'react-router-dom';
import { Spinner } from '@agconnections/grow-ui';
import PropTypes from 'prop-types';
import { Context } from 'components/Store';
import usePlanEllipsisOptions from 'screens/Plans/hooks/usePlanEllipsisOptions';

import { parseServerError } from 'helpers/errorHelpers';
import catchCancel from 'helpers/catchCancel';
import { plan as planApi } from 'utilities/api';
import TagsModal from 'components/Modals/TagsModal';
import Paper from 'components/Paper';
import LandingTable from 'components/LandingTable';
import { paths } from 'routes/paths';
import { SET_TASK_CREATION_FROM } from 'reducers/reducer';
import createAction from 'helpers/createAction';
import { AmplitudeContext } from 'utilities/amplitude/useAmplitude';
import { TABLE_COLUMNS } from '../../helpers/constants';
import ConvertPlanModal from '../../ConvertPlanModal';
import PlanTableRow from './components/PlanTableRow';
import DeletePlanModal from '../DeletePlanModal';
import ConvertPlanToRecModal from '../../ConvertPlanToRecModal';
import planTableSort from './helpers/planTableSort';

const PlanTable = ({
  plans,
  loadPlanSummaries,
  setTagsLoading,
  totalPlans,
  planPageNumber,
  handlePageChange
}) => {
  const amplitude = useContext(AmplitudeContext);
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const [, dispatch] = useContext(Context);
  const [planToConvert, setPlanToConvert] = useState();
  const [openConvertPlanModal, setOpenConvertPlanModal] = useState(false);
  const [openConvertPlanToRec, setOpenConvertPlanToRec] = useState(false);
  const [openTags, setOpenTags] = useState(false);
  const [planName, setPlanName] = useState('');
  const [planNum, setPlanNum] = useState();
  const [allFlags, setAllFlags] = useState([]);
  const [, setError] = useState(null);
  const openDeleteModal = () => setDeleteModalOpen(true);
  const closeDeleteModal = () => setDeleteModalOpen(false);
  const [sortField, setSortField] = useState();
  const [sortDir, setSortDir] = useState();
  const [filteredPlans, setFilteredPlans] = useState(plans);
  const history = useHistory();
  const { shareOrPrint, downloadingPDF } = usePlanEllipsisOptions();

  const [planToDelete, setPlanToDelete] = useState();
  const [rowIndex, setRowIndex] = useState(-1);

  const closeConvertPlanModal = () => setOpenConvertPlanModal(false);

  const closeConvertPlanToRec = () => setOpenConvertPlanToRec(false);

  const handleSort = (field, dir) => {
    setSortField(field);
    setSortDir(dir);
    setFilteredPlans(planTableSort(filteredPlans, field, dir));
  };

  const handleAction = async (action, plan, index) => {
    const currentPlan = plans?.find(p => p.id === plan?.planId);
    setRowIndex(index);

    const actions = {
      view: () => {
        history.push({
          pathname: `${paths.plans}/${plan.planId}/view`,
          state: {
            selectedView: 'edit-fromView'
          }
        });
      },
      edit: () => {
        history.push({
          pathname: `${paths.plans}/${plan.planId}`,
          selectedView: 'edit'
        });
      },
      tag: () => {
        setPlanName(plan.name);
        setPlanNum(plan.planId);
        setAllFlags(
          plan.flags?.length > 0 || plan.flags !== undefined ? plan.flags : []
        );
        setOpenTags(true);
      },
      duplicate: () => {
        history.push({
          pathname: `${paths.plans}/${plan.planId}`,
          state: {
            selectedView: 'edit-dup'
          }
        });
      },
      delete: () => {
        setPlanToDelete(plan);
        openDeleteModal();
      },
      convertTask: () => {
        createAction(
          dispatch,
          SET_TASK_CREATION_FROM,
          amplitude.events.epic.Tasks.pages.fromPlan
        );
        amplitude.sendEventToAmplitude(amplitude.events.epic.Tasks.createTask, {
          page: amplitude.events.epic.Tasks.pages.fromPlan
        });
        setPlanToConvert(currentPlan);
        setOpenConvertPlanModal(true);
      },
      convertToRec: () => {
        setPlanToConvert(currentPlan);
        setOpenConvertPlanToRec(true);
      },
      shareOrPrint: () => {
        shareOrPrint(plan.planId);
      }
    };
    actions[action]();
  };

  const handleReturnTags = async e => {
    setTagsLoading(true);
    const filterPlans = plans.filter(eachPlan => eachPlan.id === planNum);
    const body = {
      ...filterPlans[0],
      id: filterPlans[0].planId,
      flagIds: e.map(item => item.id)
    };
    delete body.createdOn;
    delete body.variableCost;
    delete body.projectRevenue;
    delete body.netRevenue;
    delete body.planId;
    delete body.cropSeasons;
    delete body.crop;
    delete body.fixedCost;
    delete body.flags;
    const { promise } = await planApi.update(planNum, body);
    await promise
      .then(() => {
        setAllFlags(e);
        setTagsLoading(false);
        loadPlanSummaries();
      })
      .catch(catchCancel)
      .catch(err => {
        if (err.response?.data?.validationErrors) {
          setError(`Error saving task: ${err.response.data.message}`);
        } else if (err.response?.data?.displayErrorMessage) {
          setError(err.response.data.displayErrorMessage);
        } else {
          parseServerError(dispatch)(err);
        }
        setTagsLoading(false);
      });
  };

  return (
    <div
      className="mx-2 my-4 min-w-content"
      data-testid="plans-table"
      style={{
        minWidth: 'min-content'
      }}
    >
      <Paper>
        {CwFPagination(totalPlans, planPageNumber, handlePageChange)}
        <ConvertPlanModal
          open={openConvertPlanModal}
          close={closeConvertPlanModal}
          currentPlan={planToConvert}
        />
        <DeletePlanModal
          open={deleteModalOpen}
          close={closeDeleteModal}
          planName={planToDelete?.name}
          planId={planToDelete?.planId} // as per planSummaries API, we get planId
        />
        <ConvertPlanToRecModal
          open={openConvertPlanToRec}
          close={closeConvertPlanToRec}
          currentPlan={planToConvert}
        />
        <TagsModal
          open={openTags}
          close={() => {
            setOpenTags(false);
          }}
          name={planName}
          selectedTags={allFlags?.reduce((tagIds, tag) => {
            return `${tagIds},${tag.id}`;
          }, '')}
          returnSelectedTags={e => {
            handleReturnTags(e);
          }}
        />
        <LandingTable
          listType="plans"
          routePath="/app/plan"
          items={filteredPlans}
          tableColumns={TABLE_COLUMNS}
          onSort={handleSort}
          sortBy={sortField}
          sortDir={sortDir}
        >
          {({ rows }) =>
            rows.map((item, index) => {
              return (
                <>
                  {item ? (
                    <PlanTableRow
                      key={item.id}
                      onAction={action => {
                        handleAction(action, item, index);
                      }}
                      plan={item}
                      fullPlan={item}
                      runningProcesses={downloadingPDF && rowIndex === index}
                    />
                  ) : (
                    <Spinner />
                  )}
                </>
              );
            })
          }
        </LandingTable>
        {CwFPagination(totalPlans, planPageNumber, handlePageChange)}
        <div className="mb-12"> </div>
      </Paper>
    </div>
  );
};

PlanTable.propTypes = {
  plans: PropTypes.arrayOf(PropTypes.object).isRequired,
  loadPlanSummaries: PropTypes.func.isRequired,
  setTagsLoading: PropTypes.func.isRequired,
  totalPlans: PropTypes.number.isRequired,
  planPageNumber: PropTypes.number.isRequired,
  handlePageChange: PropTypes.func.isRequired
};

export default PlanTable;
