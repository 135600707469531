import React, { useEffect, useState } from 'react';
import { PDFViewer } from '@react-pdf/renderer';

import RecPdf from './RecPdf';

const MOCK_DATA = {
  id: '3c013cb7-027c-4bda-82de-f57919e4faa5',
  title: 'test-rec',
  number: '',
  tankInformation: {
    tankCount: 0,
    tankValueTypeEntered: 'CarrierPerArea',
    tankSizeUnit: 'gallon',
    tankSizeValue: 0,
    carrierPerAreaUnit: 'gallon',
    carrierPerAreaValue: 0,
    totalCarrierUnit: 'gallon',
    totalCarrierValue: 0
  },
  cropZones: [],
  recommendationProductEntries: [
    {
      productId: '72972c93-4224-4db4-86ba-3e36d2a215b1',
      productName: 'Gramoxone SL',
      manufacturerName: 'Syngenta',
      trackingId: 'b354a936-e481-4cf2-996d-a6b0d0f21ae5',
      productRatePerAreaValue: 0,
      productRatePerAreaUnit: 'gallon',
      areaUnit: 'acre',
      productRatePerTankValue: 0,
      productRatePerTankUnit: 'gallon',
      totalProductValue: 0,
      totalProductUnit: 'gallon',
      coveragePercent: 1,
      productAppliedAreaValue: 0,
      productAppliedAreaUnit: 'acre',
      productDensity: 1,
      applicationMethod: '',
      custom: false
    },
    {
      productId: 'd8794335-c41a-413b-85bf-de5b3300e565',
      productName: 'GA-06 (32,500 seed bag)',
      manufacturerName: 'Public',
      trackingId: '3e268c2b-ccae-41e9-ab7b-dc93b8b90942',
      productRatePerAreaValue: 2,
      productRatePerAreaUnit: 'bag',
      areaUnit: 'acre',
      productRatePerTankValue: 0,
      productRatePerTankUnit: 'bag',
      totalProductValue: 0,
      totalProductUnit: 'bag',
      coveragePercent: 1,
      productAppliedAreaValue: 0,
      productAppliedAreaUnit: 'acre',
      productDensity: 32500,
      applicationMethod: '',
      custom: false
    }
  ],
  documentDescriptors: [],
  applicators: [
    {
      applicatorId: 'cc185946-a7d7-4039-ad03-6f3c0eef7b1b',
      companyName: '',
      personName: '',
      personId: '529396fb-9fd9-4e64-a486-d624289447aa',
      companyId: 'f4b27737-44e6-486a-9999-75b2d8141718'
    }
  ],
  cropSeasonIds: ['ee492fb8-b04c-43c5-80df-feb89e3eedf8'],
  flagIds: ['31bc24d8-7a2f-44ef-9ab6-383844e80120'],
  unknownCropZones: [],
  unknownApplicators: [],
  unknownAuthorizations: [],
  unknownRecommendationProducts: [],
  proposedDate: '11/30/2022',
  proposedDateEpoch: 1669788000000,
  expirationDate: '12/26/2022',
  expirationDateEpoch: 1672070079385,
  createdOn: '2022-11-25T15:55:00',
  timingEvent: '',
  organizationId: '72dd40be-405d-4b68-897d-1a6141c98fb0',
  authorisedOn: '11/25/2022',
  authorisedOnEpoch: 1669391679385,
  authorisedByName: 'Andrew Gullixson',
  authorisedById: '1cb5efcd-42eb-4239-8bfc-a8dd871dbfbb',
  type: 'LANDDB',
  applicationStrategy: 'ByRatePerArea',
  coveragePercent: 100
};

const PDF = () => {
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    setTimeout(() => {
      setLoading(false);
    }, 2000);
  }, []);

  return loading ? (
    <>Loading</>
  ) : (
    <div style={{ width: '100%', height: '100vh' }}>
      <PDFViewer width="100%" height="100%">
        <RecPdf rec={MOCK_DATA} />
      </PDFViewer>
    </div>
  );
};

export default PDF;
