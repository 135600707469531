import React from 'react';
import PropTypes from 'prop-types';

import { Input, Form } from '@agconnections/grow-ui';

function StreetAddressField({
  streetAddress,
  title,
  name,
  onChange,
  errors,
  blockMargin,
  id
}) {
  const blockStyle = `block ${blockMargin}`;
  return (
    <Form.Field>
      <div className={blockStyle}>
        <div className="w-full">
          <div className="block text-sm font-medium leading-5 gr-text-gray-600 sm:mt-px sm:pt-2">
            {title}
          </div>
        </div>
        <div className="w-full">
          <Input
            id={id}
            onChange={onChange}
            value={streetAddress}
            name={name}
            error={errors.streetAddress}
            errorMessage={errors.streetAddress}
          />
        </div>
      </div>
    </Form.Field>
  );
}

StreetAddressField.defaultProps = {
  streetAddress: '',
  title: '',
  name: '',
  errors: null,
  blockMargin: '',
  id: 'id'
};

StreetAddressField.propTypes = {
  streetAddress: PropTypes.string,
  name: PropTypes.string,
  title: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  errors: PropTypes.string,
  blockMargin: PropTypes.string,
  id: PropTypes.string
};

export default StreetAddressField;
