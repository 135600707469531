import React, { useState } from 'react';
import PropTypes from 'prop-types';

const ExpandableLongText = ({ text, maxLength }) => {
  const [showMore, setShowMore] = useState(false);
  if (text.length <= maxLength) {
    return text;
  }
  return (
    <>
      {showMore ? text : `${text.substring(0, maxLength)}...`}
      <button
        type="button"
        className="text-blue-50 ml-2 cursor-pointer"
        onClick={() => setShowMore(!showMore)}
        onKeyPress={() => setShowMore(!showMore)}
      >
        {showMore ? 'show less' : 'show more'}
      </button>
    </>
  );
};

ExpandableLongText.propTypes = {
  text: PropTypes.string.isRequired,
  maxLength: PropTypes.number.isRequired
};

export default ExpandableLongText;
