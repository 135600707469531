import React, { useState, useEffect, useContext } from 'react';
import ViewOnlyField from 'components/ViewOnlyField';
import { Spinner } from '@agconnections/grow-ui';
import { useParams, useHistory } from 'react-router-dom';
import useOrganizationApi from 'hooks/useOrganizationApi';
import Paper from 'components/Paper';
import Breadcrumb from 'components/App/AppShell/components/Breadcrumb';
import catchCancel from 'helpers/catchCancel';
import EllipseMenu from 'components/EllipseMenu';
import { COMPANIES_VIEW_OPTIONS_MENU, deleteMenuOption } from 'utilities/menus';
import { Context } from 'components/Store';
import { ROLES } from 'utilities/access';
import { paths } from 'routes/paths';
import DeleteCompanyModal from '../../../components/DeleteCompanyModal';

const CompanyView = () => {
  const { id } = useParams();
  const [company, setCompany] = useState({});
  const [loading, setLoading] = useState(true);
  const workerAPI = useOrganizationApi('worker');
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const openDeleteModal = () => setDeleteModalOpen(true);
  const closeDeleteModal = () => {
    setDeleteModalOpen(false);
  };
  const [{ loggedInUserOrgPermission }] = useContext(Context);

  const menu =
    loggedInUserOrgPermission?.role !== ROLES.VIEW_ONLY
      ? deleteMenuOption(COMPANIES_VIEW_OPTIONS_MENU, 'shareOrPrint')
      : COMPANIES_VIEW_OPTIONS_MENU;

  useEffect(() => {
    setLoading(true);
    const { promise } = workerAPI.fetch(id);
    promise
      .then(({ data }) => {
        if (data) setCompany(data);
        setLoading(false);
      })
      .catch(catchCancel)
      .catch(() => {
        setLoading(false);
      });
  }, [workerAPI, id]);

  const history = useHistory();

  const handleAction = action => {
    switch (action) {
      case 'edit':
        history.push({
          pathname: `${paths.companies}/${company.id}`
        });
        break;
      case 'delete':
        openDeleteModal();
        break;
      case 'share':
        break;
      default:
        break;
    }
  };

  const getDeleteModal = () => {
    return (
      <DeleteCompanyModal
        open={deleteModalOpen}
        close={closeDeleteModal}
        companyName={company?.name}
        companyId={company?.id}
      />
    );
  };
  return (
    <div className="w-full h-full">
      <Breadcrumb disabled>
        <Breadcrumb.Item
          title="Company"
          value="All Companies"
          to={paths.companies}
        />
        <Breadcrumb.Item title="Company" value={company?.name} isLast />
      </Breadcrumb>
      {loading ? (
        <Spinner />
      ) : (
        <div>
          {getDeleteModal()}
          <div className="mb-10">
            <div className="flex items-center">
              <div className="w-full flex mr-4">
                <div className="text-3xl font-med my-6">View Company</div>
              </div>
              <div className="flex">
                <div className="w-1/2 flex mr-4">
                  <div className="flex mx-2">
                    <EllipseMenu onAction={handleAction} options={menu} />
                  </div>
                </div>
              </div>
            </div>
            <Paper>
              <div className="py-5">
                <div className="bg-white w-full p-6">
                  <div className="w-full">
                    <div
                      data-testid="more-info-title"
                      className="text-2xl font-bold "
                    >
                      Basic Info
                    </div>
                    <div className="flex py-5">
                      <ViewOnlyField
                        data={company.name}
                        header="Name *"
                        className="w-1/3"
                        text="text-lg"
                      />
                      <ViewOnlyField
                        data={company.legalName}
                        header="Legal Name"
                        className="w-1/3"
                        text="text-lg"
                      />
                    </div>
                    <div className="flex py-5">
                      <ViewOnlyField
                        data={company.email}
                        header="Email Address"
                        className="w-1/3"
                        text="text-lg"
                      />
                      <ViewOnlyField
                        data={company.phone}
                        header="Phone Number"
                        className="w-1/3"
                        text="text-lg"
                      />
                    </div>
                  </div>
                  <div className="w-full border-b py-5" />
                  <div className="w-full">
                    <div
                      data-testid="more-info-title"
                      className="text-2xl font-bold mt-10"
                    >
                      Address Info
                    </div>
                    <div className="flex py-5">
                      <div>
                        <ViewOnlyField
                          data={company?.address.line1}
                          text="text-lg"
                        />
                        <ViewOnlyField
                          data={company?.address.line2}
                          text="text-lg"
                        />
                        <div className="flex">
                          <ViewOnlyField
                            data={company?.address.city}
                            className="mr-2"
                            text="text-lg"
                          />
                          <ViewOnlyField
                            data={company?.address.state}
                            className="mr-2"
                            text="text-lg"
                          />
                          <ViewOnlyField
                            data={company?.address.zipCode}
                            text="text-lg"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="w-full border-b py-5" />
                  <div className="w-full">
                    <div
                      data-testid="more-info-title"
                      className="text-2xl font-bold mt-10"
                    >
                      Notes
                    </div>
                    <div className="flex py-5">
                      <ViewOnlyField data={company?.notes} text="text-lg" />
                    </div>
                  </div>
                </div>
              </div>
            </Paper>
          </div>
        </div>
      )}
    </div>
  );
};

export default CompanyView;
