import { useCallback, useState } from 'react';
import { createChildApi, cropZoneV2 as cropZoneApiV2 } from 'utilities/api';
import catchCancel from 'helpers/catchCancel';

const useCropZoneProducts = ({ cropZoneId }) => {
  const [loading, setLoading] = useState(false);

  const getActiveIngredients = useCallback(
    body => {
      const childApi = createChildApi(
        cropZoneApiV2,
        `cropzone/${cropZoneId}/activeingredients`
      );
      const { promise } = childApi.create(body);
      setLoading(true);
      return promise
        .then(res => res)
        .catch(catchCancel)
        .catch(e => e.response)
        .finally(() => setLoading(false));
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [cropZoneId]
  );

  return {
    getActiveIngredients,
    loading,
    setLoading
  };
};

export default useCropZoneProducts;
