import React, { useState } from 'react';
import PropTypes from 'prop-types';
import ChevronUp from 'assets/chevron_up.svg';
import ChevronDown from 'assets/chevron_down.svg';

const FilterSection = ({ title, children, collapsible, extraInfo }) => {
  const [isExpanded, setIsExpanded] = useState(false);

  const toggleAccordion = () => {
    setIsExpanded(!isExpanded);
  };

  return (
    <section
      className={`pl-5 pr-28px ${
        collapsible ? '' : 'pb-5'
      } border-b border-neutral-20 border-solid`}
    >
      {collapsible ? (
        <>
          <button
            className="w-full flex flex-row items-center justify-between focus:outline-none py-18px"
            type="button"
            onClick={toggleAccordion}
          >
            <span className="text-black font-sans text-normal font-semibold leading-5 tracking-tight">
              {title}
            </span>

            <img
              className="pr-4"
              src={isExpanded ? ChevronUp : ChevronDown}
              alt="expand collapse accordion"
            />
          </button>

          {isExpanded && <div className="pb-4">{children}</div>}
        </>
      ) : (
        <>
          <h1 className="w-full flex flex-row items-center justify-between py-18px text-black font-sans text-normal font-semibold leading-5 tracking-tight">
            {title}
            {extraInfo}
          </h1>
          {children}
        </>
      )}
    </section>
  );
};

FilterSection.defaultProps = {
  collapsible: false,
  extraInfo: null
};

FilterSection.propTypes = {
  title: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
  extraInfo: PropTypes.node,
  collapsible: PropTypes.bool
};

export default FilterSection;
