import React from 'react';
import PropTypes from 'prop-types';
import { StyleSheet, View } from '@react-pdf/renderer';
import { convertNumberToDesiredFormat } from 'helpers/transformHelpers';

import { Table, Notes } from '../components';
import Profitability from './components/Profitability';
import {
  FIELDS_HEADER_ITEMS,
  FIXED_COSTS_HEADER_ITEMS,
  PRODUCT_HEADER_ITEMS
} from './helpers/tableMaker';
import Layout from '../Layout';

const styles = StyleSheet.create({
  viewContainer: {
    marginBottom: 27
  }
});

const PlanPdf = ({ plan }) => {
  return plan ? (
    <Layout
      headerTitle={plan.name}
      type="Plan"
      orgName={plan.organizationName}
      cropName={plan.cropName}
      cropSeasons={plan.cropSeasons}
      generatedYear={plan.year}
      generatedFullDate={plan.fullDate}
    >
      <View style={styles.viewContainer}>
        <Table
          title="Products"
          headerItems={PRODUCT_HEADER_ITEMS}
          bodyItems={plan.planProductEntries}
        />
      </View>
      <View style={styles.viewContainer}>
        <Table
          title="Fixed Costs(Annual)"
          headerItems={FIXED_COSTS_HEADER_ITEMS}
          bodyItems={plan.fixedCosts}
        />
      </View>
      <View style={styles.viewContainer}>
        <Profitability
          estimatedAreaValue={plan.estimatedAreaValue}
          projectedRevenue={plan.projectedRevenue}
          cost={plan.totalCost}
          projNetRevPerArea={plan.projNetRevPerArea}
          projNetRev={plan.projNetRev}
          totalFixedCost={plan.totalFixedCost}
        />
      </View>
      <View style={styles.viewContainer}>
        <Table
          title="Fields"
          headerItems={FIELDS_HEADER_ITEMS}
          bodyItems={plan.cropZones}
          titleRight={`Area: ${convertNumberToDesiredFormat(
            plan.areaSum,
            'fixed'
          )} ac.`}
        />
      </View>
      <View style={styles.viewContainer}>
        <Notes notes={plan.notes} />
      </View>
    </Layout>
  ) : null;
};

PlanPdf.propTypes = {
  plan: PropTypes.shape()
};

PlanPdf.defaultProps = {
  plan: null
};

export default PlanPdf;
