import React from 'react';
import PropTypes from 'prop-types';
import { Button } from '@agconnections/grow-ui';

const FooterButtons = ({ handleSubmit, handleCancel, isDisabled, divider }) => (
  <div className="h-16 flex flex-row justify-end border-t-2 px-6 items-center">
    <Button size="lg" onClick={handleCancel} ghost>
      Cancel
    </Button>
    {divider('w-4')}
    <Button
      type="primary"
      size="lg"
      onClick={handleSubmit}
      disabled={isDisabled}
    >
      Save Location
    </Button>
  </div>
);

FooterButtons.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  handleCancel: PropTypes.func.isRequired,
  isDisabled: PropTypes.bool.isRequired,
  divider: PropTypes.func.isRequired
};

export default FooterButtons;
