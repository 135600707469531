import React from 'react';
import PropTypes from 'prop-types';

const RemoveCircleOutlinedIcon = ({ color }) => (
  <svg
    data-testid="remove-circle-icon"
    width="18"
    height="18"
    viewBox="0 0 18 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M9 1.5C4.86 1.5 1.5 4.86 1.5 9C1.5 13.14 4.86 16.5 9 16.5C13.14 16.5 16.5 13.14 16.5 9C16.5 4.86 13.14 1.5 9 1.5ZM3 9C3 12.3075 5.6925 15 9 15C12.3075 15 15 12.3075 15 9C15 5.6925 12.3075 3 9 3C5.6925 3 3 5.6925 3 9ZM5.25 8.25V9.75H12.75V8.25H5.25Z"
      fill={color}
    />
  </svg>
);

RemoveCircleOutlinedIcon.defaultProps = {
  color: '#696F88'
};

RemoveCircleOutlinedIcon.propTypes = {
  color: PropTypes.string
};

export default RemoveCircleOutlinedIcon;
