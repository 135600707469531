import * as Yup from 'yup';

export default action =>
  action === 'Add'
    ? Yup.object().shape({
        season: Yup.string().required(),
        crop: Yup.string().required()
      })
    : Yup.object().shape({
        crop: Yup.string().required()
      });
