import React from 'react';
import PropTypes from 'prop-types';
import { Table } from '@agconnections/grow-ui';
import { findGeoJSON } from 'screens/Property/helpers/propertyDataHelpers';
import FarmRow from 'screens/Property/PropertiesLanding/components/FarmRow';
import FieldRow from 'screens/Property/PropertiesLanding/components/FieldRow';
import CropZoneRow from 'screens/Property/PropertiesLanding/components/CropZoneRow';
import CropIconMapping from 'components/CropIconMapping';

const renderCropZoneRow = (
  crop,
  farm,
  field,
  cropZone,
  fieldsAndAreasGeoJSONCollection,
  handlers
) => {
  const cropZoneGeoJSON = findGeoJSON(
    fieldsAndAreasGeoJSONCollection,
    cropZone
  );
  return (
    <Table.Row
      key={cropZone.id}
      onClick={() =>
        handlers.handleSelectedCropZone(farm, field, cropZone, crop)
      }
      selectable
      selected={handlers.isCropZoneSelected(cropZone)}
    >
      <Table.Cell>
        <CropZoneRow
          cropZone={cropZone}
          icon={cropZoneGeoJSON}
          fieldId={field.id}
          isEditable={false}
        />
      </Table.Cell>
    </Table.Row>
  );
};

const renderCropZones = (
  crop,
  farm,
  field,
  fieldsAndAreasGeoJSONCollection,
  handlers
) => {
  return field.cropzones.map(cropZone =>
    renderCropZoneRow(
      crop,
      farm,
      field,
      cropZone,
      fieldsAndAreasGeoJSONCollection,
      handlers
    )
  );
};

const renderFieldRowGroup = (
  crop,
  properties,
  farm,
  field,
  fieldsAndAreasGeoJSONCollection,
  handlers
) => {
  const fieldGeoJSON = findGeoJSON(fieldsAndAreasGeoJSONCollection, field);
  return (
    <Table.RowGroup
      key={field.id}
      onClick={() => handlers.handleSelectedFieldInCropGroup(crop, field)}
      selectable
      selected={handlers.isFieldSelectedInCropGroup(crop, field)}
      arrowIcon
    >
      <Table.Row>
        <Table.Cell>
          <FieldRow
            field={field}
            farm={farm}
            properties={properties}
            icon={fieldGeoJSON}
          />
        </Table.Cell>
      </Table.Row>
      {renderCropZones(
        crop,
        farm,
        field,
        fieldsAndAreasGeoJSONCollection,
        handlers
      )}
    </Table.RowGroup>
  );
};

const renderFields = (
  crop,
  properties,
  farm,
  fieldsAndAreasGeoJSONCollection,
  handlers
) => {
  return farm.fields.map(field =>
    renderFieldRowGroup(
      crop,
      properties,
      farm,
      field,
      fieldsAndAreasGeoJSONCollection,
      handlers
    )
  );
};

const renderFarmRowGroup = (
  crop,
  farm,
  properties,
  fieldsAndAreasGeoJSONCollection,
  handlers
) => {
  return (
    <Table.RowGroup
      key={farm.id}
      onClick={() => handlers.handleSelectedFarmInCropGroup(crop, farm)}
      selectable
      selected={handlers.isFarmSelectedInCropGroup(crop, farm)}
      arrowIcon
    >
      <Table.Row>
        <Table.Cell>
          <FarmRow farm={farm} properties={properties} />
        </Table.Cell>
      </Table.Row>
      {renderFields(
        crop,
        properties,
        farm,
        fieldsAndAreasGeoJSONCollection,
        handlers
      )}
    </Table.RowGroup>
  );
};
// Helper function to render farms
const renderFarms = (
  crop,
  properties,
  fieldsAndAreasGeoJSONCollection,
  handlers
) => {
  return crop.farms.map(farm =>
    renderFarmRowGroup(
      crop,
      farm,
      properties,
      fieldsAndAreasGeoJSONCollection,
      handlers
    )
  );
};
// Helper function to render a single farm row group

const GroupByCrop = ({
  crop,
  properties,
  handleSelectedCropGroup,
  fieldsAndAreasGeoJSONCollection,
  isCropGroupSelected,
  handleSelectedFarmInCropGroup,
  isFarmSelectedInCropGroup,
  handleSelectedFieldInCropGroup,
  isFieldSelectedInCropGroup,
  handleSelectedCropZone,
  isCropZoneSelected
}) => {
  // Group handler functions into a single object
  const handlers = {
    handleSelectedFarmInCropGroup,
    isFarmSelectedInCropGroup,
    handleSelectedFieldInCropGroup,
    isFieldSelectedInCropGroup,
    handleSelectedCropZone,
    isCropZoneSelected
  };
  return (
    <Table.RowGroup
      key={crop.id}
      onClick={() => handleSelectedCropGroup(crop)}
      selectable
      selected={isCropGroupSelected(crop)}
      arrowIcon
      autoOpen={false}
    >
      <Table.Row>
        <Table.Cell>
          <div className="flex items-center space-x-2">
            <CropIconMapping cropObject={crop} />
            <span>{crop.name}</span>
          </div>
        </Table.Cell>
      </Table.Row>
      {renderFarms(crop, properties, fieldsAndAreasGeoJSONCollection, handlers)}
    </Table.RowGroup>
  );
};
GroupByCrop.propTypes = {
  crop: PropTypes.shape({
    id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    farms: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.string.isRequired,
        fields: PropTypes.arrayOf(
          PropTypes.shape({
            id: PropTypes.string.isRequired,
            cropzones: PropTypes.arrayOf(
              PropTypes.shape({
                id: PropTypes.string.isRequired
              }).isRequired
            ).isRequired
          }).isRequired
        ).isRequired
      }).isRequired
    ).isRequired
  }).isRequired,
  handleSelectedCropGroup: PropTypes.func.isRequired,
  fieldsAndAreasGeoJSONCollection: PropTypes.arrayOf(PropTypes.object)
    .isRequired,
  isCropGroupSelected: PropTypes.func.isRequired,
  handleSelectedFarmInCropGroup: PropTypes.func.isRequired,
  isFarmSelectedInCropGroup: PropTypes.func.isRequired,
  handleSelectedFieldInCropGroup: PropTypes.func.isRequired,
  isFieldSelectedInCropGroup: PropTypes.func.isRequired,
  handleSelectedCropZone: PropTypes.func.isRequired,
  isCropZoneSelected: PropTypes.func.isRequired,
  properties: PropTypes.arrayOf(PropTypes.object).isRequired
};
export default GroupByCrop;
