import { initialState } from 'components/Store';
import createAction from 'helpers/createAction';
import {
  EXPORT_PDF_MAPS_STEP,
  FIELDS_TO_MASSIVE_ASSIGN,
  IS_EXPORT_PDF_MAPS_ACTIVE,
  IS_MASS_ASSIGNER_ACTIVE,
  SELECT_PROPERTY,
  SET_MAP_STYLE_IMAGE,
  SET_MAP_LABEL_CONFIG,
  SET_MAP_PRINTABLE_AREA_BBOX,
  SET_PROPERTY_MAP_FIELDS_CONFIG,
  SET_PROPERTY_MAP_FOOTER_CONFIG
} from 'reducers/reducer';

const useExportPDFMapsActions = dispatch => {
  const initiateExportFlow = () => {
    createAction(dispatch, SELECT_PROPERTY, {});
    createAction(dispatch, IS_MASS_ASSIGNER_ACTIVE, false);
    createAction(dispatch, EXPORT_PDF_MAPS_STEP, 0);
    createAction(dispatch, IS_EXPORT_PDF_MAPS_ACTIVE, true);
  };

  const clearExportForm = () => {
    createAction(dispatch, FIELDS_TO_MASSIVE_ASSIGN, []);
    createAction(dispatch, SET_MAP_PRINTABLE_AREA_BBOX, null);
    createAction(
      dispatch,
      SET_PROPERTY_MAP_FIELDS_CONFIG,
      initialState.propertyMapFieldsConfig
    );
    createAction(dispatch, SET_MAP_LABEL_CONFIG, initialState.mapLabelConfig);
    createAction(
      dispatch,
      SET_PROPERTY_MAP_FOOTER_CONFIG,
      initialState.propertyMapFooterConfig
    );
    createAction(dispatch, SET_MAP_STYLE_IMAGE, initialState.mapStyleImage);
  };

  const goToExportStep = step => {
    createAction(dispatch, EXPORT_PDF_MAPS_STEP, step);
  };

  const closeExportFlow = () => {
    createAction(dispatch, IS_MASS_ASSIGNER_ACTIVE, false);
    createAction(dispatch, IS_EXPORT_PDF_MAPS_ACTIVE, false);
    createAction(dispatch, EXPORT_PDF_MAPS_STEP, 0);
  };

  return {
    initiateExportFlow,
    clearExportForm,
    goToExportStep,
    closeExportFlow
  };
};

export default useExportPDFMapsActions;
