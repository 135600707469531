import React from 'react';
import Toast from 'components/Toast';
import PropTypes from 'prop-types';
import SupportButton from 'components/SupportButton';

const ToastHandler = ({
  toastType,
  showToast,
  onCloseHandler,
  title,
  description
}) => {
  return (
    <Toast
      type={toastType}
      isBold
      open={showToast}
      title={title}
      onClose={onCloseHandler}
      timeout={5000}
    >
      {toastType === 'error' && (
        <>
          {!!description && <div>{description}</div>}
          <SupportButton />
        </>
      )}
    </Toast>
  );
};

ToastHandler.defaultProps = {
  toastType: null,
  showToast: false,
  onCloseHandler: () => {},
  title: '',
  description: ''
};

ToastHandler.propTypes = {
  toastType: PropTypes.string,
  showToast: PropTypes.bool,
  onCloseHandler: PropTypes.func,
  title: PropTypes.string,
  description: PropTypes.string
};

export default ToastHandler;
